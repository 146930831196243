import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';

@Component({
    selector: 'app-autotask-create-ticket-modal',
    templateUrl: './autotask-create-ticket-modal.component.html',
    styleUrls: ['./autotask-create-ticket-modal.component.sass']
})
export class AutotaskCreateTicketModalComponent implements OnInit {
    createTicketFormGenInput: IFormGeneratorInput = null;
    autotaskStatusResponse = null;
    widgetRef: Widget;
    clientIdList = null;
    stepData;

    constructor(
        private modalInputData: ModalInjectedData,
        private listHttpService: ListHttpService
    ) {
        this.stepData = modalInputData.data;
        this.autotaskStatusResponse =
            modalInputData.data['autotaskStatusResponse'];
        this.clientIdList = modalInputData.data['clientIdList'];
        this.widgetRef = modalInputData.data['widgetRef'];
    }

    ngOnInit(): void {
        this.createTicketFormGenInput = {
            formName: 'Autotask Create Ticket',
            state: FormState.CREATE,
            submitButton: {
                buttonName: 'Create Ticket',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: (
                    buttonRef: IButtonGeneratorInput,
                    formGroup: FormGroup,
                    modalId: Symbol
                ) => {
                    const input = {
                        ...formGroup.getRawValue()
                    };
                    if (
                        !this.stepData['isPartner'] &&
                        this.stepData['configuredColumnData']
                    ) {
                        input['title'] = this.stepData['configuredColumnData']
                            ? this.stepData['tableRowData'][
                                  this.stepData['configuredColumnData'][
                                      'titleColumn'
                                  ]
                              ]
                            : '';
                    }
                    if (this.autotaskStatusResponse['childInfo']) {
                        input['clientId'] =
                            this.autotaskStatusResponse['childInfo'][
                                'clientId'
                            ];
                        const defaultStatus = this.autotaskStatusResponse[
                            'ownerConfiguredFeilds'
                        ]['status'].find((obj) => obj.label === 'New');
                        input['statusValue'] = defaultStatus['id'];
                        input['childIntegration'] = true;
                    } else {
                        const defaultStatus = this.autotaskStatusResponse[
                            'selfConfiguredFeilds'
                        ]['status'].find((obj) => obj.label === 'New');
                        input['statusValue'] = defaultStatus['id'];
                        input['parentIntegration'] = true;
                    }
                    const apiArgs: IHitApi = {
                        url: ApiUrls.AUTOTASK_CREATE_TICKET,
                        input: input,
                        requestType: RequestType.POST,
                        uniqueIdentity: Symbol(),
                        config: {
                            authorization: AuthorizationType.BEARER_TOKEN
                        },
                        function: (response) => {
                            this.autotaskStatusResponse = response;
                            buttonRef.loader = false;
                            this.widgetRef.modalService.closeModal(
                                null,
                                this.modalInputData.modalId
                            );
                            this.widgetRef.notificationsService.showSnackBar(
                                'Ticket Created Successfully'
                            );
                        },
                        errorFunction: (error) => {
                            buttonRef.loader = false;
                            Helper.showErrorMessage(
                                this.widgetRef.notificationsService,
                                error,
                                'Error Creating Ticket'
                            );
                        }
                    };
                    new HitApi(
                        apiArgs,
                        this.widgetRef.httpService,
                        this.widgetRef.ngZone
                    ).hitApi();
                }
            },
            fields: [
                {
                    name: 'clientId',
                    placeholder: 'Client Id',
                    label: 'Client Id',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    apiInfo:
                        this.stepData && this.stepData.tableRowData
                            ? this.listHttpService.autoTaskClientIds
                            : null,
                    listData:
                        this.stepData && this.stepData.tableRowData
                            ? null
                            : this.autotaskStatusResponse.childIntegration
                            ? null
                            : this.clientIdList,
                    responseValueKey: 'clientList',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Client Id is required'
                        }
                    ],
                    value: this.autotaskStatusResponse.childIntegration
                        ? {
                              id: this.autotaskStatusResponse['childInfo'][
                                  'clientId'
                              ],
                              label: this.autotaskStatusResponse['childInfo'][
                                  'clientId'
                              ]
                          }
                        : null,
                    disabled: this.autotaskStatusResponse.childIntegration
                        ? true
                        : false
                },
                {
                    name: 'desrciption',
                    placeholder: 'Enter Description',
                    label: 'Description',
                    fieldType: FilterType.TEXT
                },
                {
                    name: 'sourceValue',
                    placeholder: 'Select Source',
                    label: 'Source',
                    listData: this.autotaskStatusResponse.ownerConfiguredFeilds
                        ? [
                              this.autotaskStatusResponse[
                                  'ownerConfiguredFeilds'
                              ]['source']
                          ]
                        : this.autotaskStatusResponse.selfConfiguredFeilds
                        ? [
                              this.autotaskStatusResponse[
                                  'selfConfiguredFeilds'
                              ]['source']
                          ]
                        : null,
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Source is required'
                        }
                    ]
                },
                {
                    name: 'priorityValue',
                    placeholder: 'Select Priority',
                    label: 'Priority',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    listData: this.autotaskStatusResponse.ownerConfiguredFeilds
                        ? this.autotaskStatusResponse['ownerConfiguredFeilds'][
                              'priority'
                          ]
                        : this.autotaskStatusResponse.selfConfiguredFeilds
                        ? this.autotaskStatusResponse['selfConfiguredFeilds'][
                              'priority'
                          ]
                        : null,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Priority is required'
                        }
                    ]
                },
                {
                    name: 'queueIdValue',
                    placeholder: 'Select Queue',
                    label: 'Queue',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    listData: this.autotaskStatusResponse.ownerConfiguredFeilds
                        ? this.autotaskStatusResponse['ownerConfiguredFeilds'][
                              'queueId'
                          ]
                        : this.autotaskStatusResponse.selfConfiguredFeilds
                        ? this.autotaskStatusResponse['selfConfiguredFeilds'][
                              'queueId'
                          ]
                        : null,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Queue is required'
                        }
                    ]
                }
            ]
        };
        if (
            this.stepData['isPartner'] ||
            !this.stepData['configuredColumnData']
        ) {
            this.createTicketFormGenInput.fields.unshift({
                name: 'title',
                placeholder: 'Enter Ticket Title',
                label: 'Ticket Title',
                fieldType: FilterType.TEXT,
                required: true,
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'Ticket Title is required'
                    }
                ],
                value: this.stepData['configuredColumnData']
                    ? this.stepData['tableRowData'][
                          this.stepData['configuredColumnData']['titleColumn']
                      ]
                    : '',
                disabled: this.stepData['isPartner']
                    ? this.stepData['isPartner']
                    : false
            });
        }
    }
}
