export enum FrameworkStatus {
    MARKED_FOR_REVIEW = 'MARKED_FOR_REVIEW',
    MARKED_FOR_REWORK = 'MARKED_FOR_REWORK',
    READY_FOR_ASSESSMENT = 'READY_FOR_ASSESSMENT',
    INCOMPLETE = 'INCOMPLETE',
    BLANK = 'BLANK',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    ISSUED = 'Issued',
    IN_PROGRESS = 'In Progress',
    OPEN_ACTIONABLE_ITEM = 'Open Actionable Items',
    COMPLETED = 'COMPLETED' // Added For CSP Scoring
}
