import { WidgetHttpService } from './../../../services/http/widget-http/widget-http.service';
import { Widget } from 'src/app/shared/classes/Widget';
import { HttpService } from './../../../services/http/http-main/http.service';
import { NotificationsService } from './../../../services/notifications/notifications.service';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { Component, NgZone, OnInit } from '@angular/core';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IconType } from 'src/app/shared/enums/IconType';
import { Helper } from 'src/app/shared/classes/Helper';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';

@Component({
    selector: 'app-report-share-table',
    templateUrl: './report-share-table.component.html',
    styleUrls: ['./report-share-table.component.sass'],
})
export class ReportShareTableComponent implements OnInit {
    public readcheck = false;
    public writecheck = false;
    tableInput: ITableGeneratorInput;
    payload: any[] = [];
    searching: boolean = false;
    rootUser: boolean = false;
    isFirstTimeClick: boolean = false;
    searchIcon: IIcon = {
        type: IconType.SVG,
        class: 'search',
    };
    reportShareModelList: any[] = [];
    reportsListResponse: any[] = [];
    bulkSharePayload: {} = {};
    buttonRef: IButtonGeneratorInput;
    buttonGenInput: IButtonGeneratorInput = {
        buttonName: 'Share',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        customClass: 'whitelisting-save-btn',
        showLoader: true,
        function: (buttonRef: IButtonGeneratorInput) => {
            if (!this.isFirstTimeClick) {
                if (
                    (this.selectedRows &&
                        this.selectedRows.length > 0 &&
                        this.rootUser) ||
                    (this.selectedRows &&
                        this.selectedRows.length > 1 &&
                        !this.rootUser)
                ) {
                    this.shareReport(buttonRef);
                } else if (
                    this.bulkSharePayload &&
                    this.bulkSharePayload['reportShareModelList'] &&
                    this.bulkSharePayload['reportShareModelList']?.length > 0
                ) {
                    this.shareReport(buttonRef);
                } else {
                    return Helper.showErrorMessage(
                        this.notificationsService,
                        'Selected atleast one Entry to perform the operation'
                    );
                }
            } else {
                if (this.selectedRows) {
                    this.shareReport(buttonRef);
                } else if (
                    this.bulkSharePayload &&
                    this.bulkSharePayload['reportShareModelList'] &&
                    this.bulkSharePayload['reportShareModelList']?.length > 0
                ) {
                    this.shareReport(buttonRef);
                }
            }
        },
    };
    tabledata: any[] = [];
    copyData: any[] = [];
    cloneDeep: any[] = [];
    widgetRef: Widget;
    updatePayload: {} = {};
    modalLoader: boolean = true;
    displayedColumns: string[] = ['Name', 'Email', 'Read', 'Write'];
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER,
    };
    selectedRows: any[] = [];
    userDefaultAccess: any[] = [];
    disableRead: boolean = false;
    disableWrite: boolean = false;
    constructor(
        private modalInput: ModalInjectedData,
        public notificationsService: NotificationsService,
        public ngZone: NgZone,
        public httpService: HttpService,
        private userDataCacheService: UserDataCacheService
    ) {}

    ngOnInit(): void {
        this.getUsersList();
        if (
            this.userDataCacheService.emailId ===
            this.modalInput.data?.cardata?.createdBy?.email
        ) {
            this.rootUser = true;
        }
    }

    shareReport(buttonRef: IButtonGeneratorInput) {
        this.buttonRef = buttonRef;
        this.buttonRef.loader = true;

        if (this.modalInput.data?.cardata) {
            const reportId = this.modalInput.data?.cardata?.id;
            const hitApi: IHitApi = Helper.generateHitApiConfig(
                this.modalInput.data['widgetRef']['widgetData']['widgetInfo'][
                    'share'
                ]
            );
            hitApi.url = hitApi.url.replace('{report-id}', reportId);
            hitApi.input = this.selectedRows;
            hitApi.function = (response) => {
                if (response?.response === 'false') {
                    this.modalInput.data.widgetRef.modalService.closeModal(
                        null,
                        this.modalInput.modalId
                    );
                    const confirmationModal: IConfirmationModal = {
                        modalName: 'Widget Access Control ',
                        modalIcon: {
                            type: IconType.FONTAWSOME,
                            class: '',
                        },
                        contextIcon: {
                            type: IconType.FONTAWSOME,
                            class: '',
                        },
                        buttonText: 'Continue',
                        buttonColorType: ButtonColorType.PRIMARY,
                        modalAutoHeight: true,
                        fontSize: 0.9,
                        extraClass: 'plan-container',
                        hideCancelButton: false,
                        cancelButtonName: 'Cancel',
                        cancelButtonType: 'PRIMARY',
                        loaderOnExec: true,
                        function: (modalId: Symbol) => {
                            const hitApi: IHitApi = Helper.generateHitApiConfig(
                                this.modalInput.data['widgetRef']['widgetData'][
                                    'widgetInfo'
                                ]['widgetPassed']
                            );
                            hitApi.url = hitApi.url.replace(
                                '{report-id}',
                                reportId
                            );
                            hitApi.input = this.selectedRows;
                            hitApi.function = (response) => {
                                this.hitReportListingApi()
                                    .then((res) => {
                                        const selectedReport =
                                            this.reportsListResponse.filter(
                                                (val) => {
                                                    if (
                                                        val?.id ===
                                                        this.modalInput.data
                                                            ?.cardata?.id
                                                    ) {
                                                        return true;
                                                    } else {
                                                        return false;
                                                    }
                                                }
                                            );
                                        if (
                                            selectedReport &&
                                            selectedReport.length > 0
                                        ) {
                                            this.updatePayload = {
                                                reportName:
                                                    selectedReport[0][
                                                        'reportName'
                                                    ],
                                                widgetIds:
                                                    selectedReport[0][
                                                        'widgetIds'
                                                    ],
                                                reportType:
                                                    selectedReport[0][
                                                        'reportType'
                                                    ],
                                                widgetFilters:
                                                    selectedReport[0][
                                                        'widgetFilters'
                                                    ],
                                                reportFilters:
                                                    selectedReport[0][
                                                        'reportFilters'
                                                    ],
                                                userNameWithWidgetsIds:
                                                    selectedReport[0][
                                                        'userNameWithWidgetsIds'
                                                    ]
                                                        ? selectedReport[0][
                                                              'userNameWithWidgetsIds'
                                                          ]
                                                        : null,
                                                userReportWithAccessControl:
                                                    selectedReport[0][
                                                        'userReportWithAccessControl'
                                                    ]
                                                        ? selectedReport[0][
                                                              'userReportWithAccessControl'
                                                          ]
                                                        : null,
                                            };
                                        }
                                        this.notificationsService.showSnackBar(
                                            'The required views have been created, and the report rule has been successfully shared with the respective users',
                                            false
                                        );
                                        this.hitUpdateApi(reportId);
                                        this.buttonRef.loader = false;
                                        this.modalInput.data.widgetRef.modalService.closeModal(
                                            null,
                                            modalId
                                        );
                                    })
                                    .catch(() => {
                                        Helper.showErrorMessage(
                                            this.notificationsService,
                                            'Something went wrong'
                                        );
                                    });
                            };
                            hitApi.errorFunction = (error) => {
                                this.selectedRows = [];
                                Helper.showErrorMessage(
                                    this.notificationsService,
                                    error
                                );
                                this.modalInput.data.widgetRef.modalService.closeModal(
                                    null,
                                    modalId
                                );
                            };
                            new HitApi(
                                hitApi,
                                this.modalInput.data.widgetRef.httpService,
                                this.modalInput.data.widgetRef.ngZone
                            ).hitApi();
                        },
                        closeCallBack: () => {},
                        confirmationMessage: `Selected users lack access to the 'Report Management' widget. Click 'Continue' to add it to their default view if you own it, or create a new view with the widget if you don't.`,
                    };
                    this.modalInput.data.widgetRef.modalService.openConfirmationModal(
                        confirmationModal
                    );
                } else if (response?.response === 'true') {
                    this.hitReportListingApi()
                        .then((response) => {
                            this.notificationsService.showSnackBar(
                                'Report Rule Saved Successfully'
                            );

                            const selectedReport =
                                this.reportsListResponse.filter((val) => {
                                    if (
                                        val?.id ===
                                        this.modalInput.data?.cardata?.id
                                    ) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                });
                            if (selectedReport && selectedReport.length > 0) {
                                this.updatePayload = {
                                    reportName: selectedReport[0]['reportName'],
                                    widgetIds: selectedReport[0]['widgetIds'],
                                    reportType: selectedReport[0]['reportType'],
                                    widgetFilters:
                                        selectedReport[0]['widgetFilters'],
                                    reportFilters:
                                        selectedReport[0]['reportFilters'],
                                    userNameWithWidgetsIds: selectedReport[0][
                                        'userNameWithWidgetsIds'
                                    ]
                                        ? selectedReport[0][
                                              'userNameWithWidgetsIds'
                                          ]
                                        : null,
                                    userReportWithAccessControl:
                                        selectedReport[0][
                                            'userReportWithAccessControl'
                                        ]
                                            ? selectedReport[0][
                                                  'userReportWithAccessControl'
                                              ]
                                            : null,
                                };
                            }
                            this.hitUpdateApi(reportId);
                            this.buttonRef.loader = false;
                            this.modalInput.data.widgetRef.modalService.closeModal(
                                null,
                                this.modalInput.modalId
                            );
                        })
                        .catch(() => {
                            Helper.showErrorMessage(
                                this.notificationsService,
                                'Something Went Wrong'
                            );
                        });
                }
            };
            hitApi.errorFunction = (error) => {
                this.selectedRows = [];
                Helper.showErrorMessage(this.notificationsService, error);
                this.modalInput.data.widgetRef.modalService.closeModal(
                    null,
                    this.modalInput.modalId
                );
            };
            new HitApi(
                hitApi,
                this.modalInput.data.widgetRef.httpService,
                this.modalInput.data.widgetRef.ngZone
            ).hitApi();
        } else {
            const selectedReportCount =
                this.modalInput.data?.selectedCards.length;
            const hitBulkShare: IHitApi = Helper.generateHitApiConfig(
                this.modalInput.data['widgetRef']['widgetData']['widgetInfo'][
                    'bulkShare'
                ]
            );
            hitBulkShare.input = this.bulkSharePayload;
            hitBulkShare.function = (response) => {
                if (response?.response === 'false') {
                    this.modalInput.data.widgetRef.modalService.closeModal(
                        null,
                        this.modalInput.modalId
                    );
                    const confirmationModal: IConfirmationModal = {
                        modalName: 'Widget Access Control ',
                        modalIcon: {
                            type: IconType.FONTAWSOME,
                            class: '',
                        },
                        contextIcon: {
                            type: IconType.FONTAWSOME,
                            class: '',
                        },
                        buttonText: 'Continue',
                        buttonColorType: ButtonColorType.PRIMARY,
                        modalAutoHeight: true,
                        fontSize: 0.9,
                        extraClass: 'plan-container',
                        hideCancelButton: false,
                        cancelButtonName: 'Cancel',
                        cancelButtonType: 'PRIMARY',
                        loaderOnExec: true,
                        function: (modalId: Symbol) => {
                            const hitApi: IHitApi = Helper.generateHitApiConfig(
                                this.modalInput.data['widgetRef']['widgetData'][
                                    'widgetInfo'
                                ]['widgetPassedForBulkAction']
                            );
                            hitApi.input = this.bulkSharePayload;
                            hitApi.function = (response) => {
                                const idsInArray2 = new Set(
                                    this.modalInput.data?.selectedCards.map(
                                        (obj) => obj.id
                                    )
                                );
                                this.hitReportListingApi()
                                    .then((res) => {
                                        const selectedReports =
                                            this.reportsListResponse.filter(
                                                (val) =>
                                                    idsInArray2.has(val?.id)
                                            );
                                        if (
                                            selectedReports &&
                                            selectedReports.length > 0
                                        ) {
                                            for (
                                                let i = 0;
                                                i < selectedReportCount;
                                                i++
                                            ) {
                                                const id =
                                                    selectedReports[i]['id'];
                                                const payload = {
                                                    reportName:
                                                        selectedReports[i][
                                                            'reportName'
                                                        ],
                                                    widgetIds:
                                                        selectedReports[i][
                                                            'widgetIds'
                                                        ],
                                                    reportType:
                                                        selectedReports[i][
                                                            'reportType'
                                                        ],
                                                    widgetFilters:
                                                        selectedReports[i][
                                                            'widgetFilters'
                                                        ],
                                                    reportFilters:
                                                        selectedReports[i][
                                                            'reportFilters'
                                                        ],
                                                    userNameWithWidgetsIds:
                                                        selectedReports[i][
                                                            'userNameWithWidgetsIds'
                                                        ]
                                                            ? selectedReports[
                                                                  i
                                                              ][
                                                                  'userNameWithWidgetsIds'
                                                              ]
                                                            : null,
                                                    userReportWithAccessControl:
                                                        selectedReports[i][
                                                            'userReportWithAccessControl'
                                                        ]
                                                            ? selectedReports[
                                                                  i
                                                              ][
                                                                  'userReportWithAccessControl'
                                                              ]
                                                            : null,
                                                };
                                                const emailList =
                                                    payload['reportFilters'][
                                                        'sendingMechanism'
                                                    ]['emailRequest'][
                                                        'copyTo'
                                                    ] || [];
                                                const emailSet = new Set(
                                                    emailList
                                                );
                                                this.bulkSharePayload[
                                                    'reportShareModelList'
                                                ]?.forEach((val) => {
                                                    const email =
                                                        val?.userInfo?.email;
                                                    if (
                                                        email &&
                                                        !emailSet.has(email)
                                                    ) {
                                                        emailSet.add(email);
                                                        emailList.push(email);
                                                    }
                                                });
                                                payload['reportFilters'][
                                                    'sendingMechanism'
                                                ]['emailRequest']['copyTo'] =
                                                    Array.from(emailSet);
                                                this.hitUpdateApi(id, payload);
                                            }
                                        }
                                        this.notificationsService.showSnackBar(
                                            'The required views have been created, and the report rule has been successfully shared with the respective users',
                                            false
                                        );
                                        this.bulkSharePayload = {};
                                        this.modalInput.data.widgetRef.modalService.closeModal(
                                            null,
                                            modalId
                                        );
                                    })
                                    .catch(() => {
                                        Helper.showErrorMessage(
                                            this.notificationsService,
                                            'Something Went Wrong'
                                        );
                                    });
                            };
                            hitApi.errorFunction = (error) => {
                                Helper.showErrorMessage(
                                    this.notificationsService,
                                    error
                                );
                                this.modalInput.data.widgetRef.modalService.closeModal(
                                    null,
                                    modalId
                                );
                            };
                            new HitApi(
                                hitApi,
                                this.modalInput.data.widgetRef.httpService,
                                this.modalInput.data.widgetRef.ngZone
                            ).hitApi();
                        },
                        closeCallBack: () => {},
                        confirmationMessage: `Selected users lack access to the 'Report Management' widget. Click 'Continue' to add it to their default view if you own it, or create a new view with the widget if you don't.`,
                    };
                    this.modalInput.data.widgetRef.modalService.openConfirmationModal(
                        confirmationModal
                    );
                } else if (response?.response === 'true') {
                    this.hitReportListingApi()
                        .then((response) => {
                            const idsInArray2 = new Set(
                                this.modalInput.data?.selectedCards.map(
                                    (obj) => obj.id
                                )
                            );
                            const selectedReports =
                                this.reportsListResponse.filter((val) =>
                                    idsInArray2.has(val?.id)
                                );
                            if (selectedReports && selectedReports.length > 0) {
                                for (let i = 0; i < selectedReportCount; i++) {
                                    const id = selectedReports[i]['id'];
                                    const payload = {
                                        reportName:
                                            selectedReports[i]['reportName'],
                                        widgetIds:
                                            selectedReports[i]['widgetIds'],
                                        reportType:
                                            selectedReports[i]['reportType'],
                                        widgetFilters:
                                            selectedReports[i]['widgetFilters'],
                                        reportFilters:
                                            selectedReports[i]['reportFilters'],
                                        userNameWithWidgetsIds: selectedReports[
                                            i
                                        ]['userNameWithWidgetsIds']
                                            ? selectedReports[i][
                                                  'userNameWithWidgetsIds'
                                              ]
                                            : null,
                                        userReportWithAccessControl:
                                            selectedReports[i][
                                                'userReportWithAccessControl'
                                            ]
                                                ? selectedReports[i][
                                                      'userReportWithAccessControl'
                                                  ]
                                                : null,
                                    };
                                    const emailList =
                                        payload['reportFilters'][
                                            'sendingMechanism'
                                        ]['emailRequest']['copyTo'] || [];
                                    const emailSet = new Set(emailList);
                                    this.bulkSharePayload[
                                        'reportShareModelList'
                                    ]?.forEach((val) => {
                                        const email = val?.userInfo?.email;
                                        if (email && !emailSet.has(email)) {
                                            emailSet.add(email);
                                            emailList.push(email);
                                        }
                                    });
                                    payload['reportFilters'][
                                        'sendingMechanism'
                                    ]['emailRequest']['copyTo'] =
                                        Array.from(emailSet);
                                    this.hitUpdateApi(id, payload);
                                }
                            }
                            this.notificationsService.showSnackBar(
                                'Report Rule Saved Successfully'
                            );
                            this.modalInput.data.widgetRef.modalService.closeModal(
                                null,
                                this.modalInput.modalId
                            );
                            this.bulkSharePayload = {};
                        })
                        .catch(() => {
                            Helper.showErrorMessage(
                                this.notificationsService,
                                'Something went wrong'
                            );
                        });
                }
            };
            hitBulkShare.errorFunction = (error) => {
                Helper.showErrorMessage(this.notificationsService, error);
                this.bulkSharePayload = {};
                this.modalInput.data.widgetRef.modalService.closeModal(
                    null,
                    this.modalInput.modalId
                );
            };
            new HitApi(
                hitBulkShare,
                this.modalInput.data.widgetRef.httpService,
                this.modalInput.data.widgetRef.ngZone
            ).hitApi();
        }
    }

    hitReportListingApi() {
        return new Promise((resolve, reject) => {
            const apiArgs: IHitApi = {
                url: ApiUrls.REPORT_ENDPOINT,
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN,
                },
                input: {},
                requestType: RequestType.GET,
                uniqueIdentity: Symbol(),
                function: (response) => {
                    if (response && response?.length) {
                        this.reportsListResponse = response;
                        resolve(response);
                    } else {
                        reject();
                    }
                },
                errorFunction: (error) => {
                    reject(error);
                },
            };
            new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
        });
    }

    hitUpdateApi(reportId, payload?) {
        if (!payload) {
            if (this.selectedRows.length > 0) {
                const selectedEmailSet = new Set(
                    this.selectedRows.map((val) => val?.userInfo?.email)
                );
                const userDefaultAccessEmailSet = new Set(
                    this.userDefaultAccess.map(
                        (value) => value?.userInfo?.email
                    )
                );
                const currentEmails =
                    this.updatePayload['reportFilters']['sendingMechanism'][
                        'emailRequest'
                    ]['copyTo'] || [];
                selectedEmailSet.forEach((email) => {
                    if (!currentEmails.includes(email)) {
                        currentEmails.push(email);
                    }
                });

                this.updatePayload['reportFilters']['sendingMechanism'][
                    'emailRequest'
                ]['copyTo'] = currentEmails.filter((email) => {
                    return (
                        !userDefaultAccessEmailSet.has(email) ||
                        selectedEmailSet.has(email)
                    );
                });
            } else {
                const cloneDeepSet = new Set(
                    this.cloneDeep.map((value) => value?.userInfo?.email)
                );
                const currentEmails: any[] =
                    this.updatePayload['reportFilters']['sendingMechanism'][
                        'emailRequest'
                    ]['copyTo'];
                this.updatePayload['reportFilters']['sendingMechanism'][
                    'emailRequest'
                ]['copyTo'] = currentEmails.filter((email) => {
                    return !cloneDeepSet.has(email);
                });
            }
        }
        const hitApi: IHitApi = Helper.generateHitApiConfig(
            this.modalInput.data['widgetRef']['widgetData']['widgetInfo'][
                'update'
            ]
        );
        hitApi.url = hitApi.url.replace('{report-id}', reportId);
        hitApi.input = payload ? payload : this.updatePayload;
        hitApi.function = (response) => {
            this.modalInput.data?.widgetRef?.refreshWidget();
            if (this.modalInput.data?.cardata) {
                this.selectedRows = [];
                this.updatePayload = {};
                this.userDefaultAccess = [];
                this.tabledata = [];
            }
        };
        hitApi.errorFunction = (error) => {};
        new HitApi(
            hitApi,
            this.modalInput.data.widgetRef.httpService,
            this.modalInput.data.widgetRef.ngZone
        ).hitApi();
    }
    getUsersList() {
        const hitApi: IHitApi = {
            url: ApiUrls.USERS_END_POINT,
            requestType: RequestType.GET,
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
            },

            function: (response) => {
                this.modalLoader = false;
                const currentUserMail = this.userDataCacheService.emailId;
                if (this.modalInput.data?.cardata) {
                    if (response.length > 0) {
                        response.forEach((value) => {
                            if (
                                this.modalInput.data?.cardata
                                    ?.userReportWithAccessControl
                            ) {
                                if (
                                    value?.email ===
                                    this.modalInput.data?.cardata?.createdBy
                                        ?.email
                                ) {
                                    return;
                                }
                                this.modalInput.data?.cardata?.userReportWithAccessControl.forEach(
                                    (val) => {
                                        const email = val?.userInfo?.email;
                                        if (email === currentUserMail) {
                                            const currentUser = {
                                                reportAccessControlFlag:
                                                    val?.reportAccessControlFlag,
                                                userInfo: val?.userInfo,
                                            };
                                            if (
                                                this.selectedRows.findIndex(
                                                    (val) =>
                                                        val?.userInfo?.email ===
                                                        currentUser?.userInfo
                                                            ?.email
                                                ) === -1
                                            ) {
                                                this.selectedRows.push(
                                                    currentUser
                                                );
                                            }
                                            return;
                                        }
                                        if (value?.email === email) {
                                            const obj = {
                                                reportAccessControlFlag:
                                                    val?.reportAccessControlFlag,
                                                userInfo: val?.userInfo,
                                            };
                                            this.selectedRows.push(obj);
                                            this.userDefaultAccess.push(obj);
                                            this.tabledata.push(obj);
                                            this.isFirstTimeClick = true;
                                        }
                                    }
                                );
                                const index = this.tabledata.findIndex(
                                    (item) =>
                                        item?.userInfo?.email === value?.email
                                );
                                if (index === -1) {
                                    if (value?.email !== currentUserMail) {
                                        const obj = {
                                            reportAccessControlFlag: '',
                                            userInfo: value,
                                        };
                                        this.tabledata.push(obj);
                                    }
                                }
                            } else {
                                if (value?.email !== currentUserMail) {
                                    const obj = {
                                        reportAccessControlFlag: '',
                                        userInfo: value,
                                    };
                                    this.tabledata.push(obj);
                                    this.isFirstTimeClick = false;
                                }
                            }
                        });
                    }
                    this.cloneDeep = Helper.cloneDeep(this.selectedRows);
                } else {
                    response.forEach((user) => {
                        if (user?.email !== currentUserMail) {
                            this.tabledata.push(user);
                        }
                    });
                    const ids = [];
                    this.modalInput.data?.selectedCards.map((val) => {
                        ids.push(val?.id);
                    });
                    this.bulkSharePayload['listOfReportIds'] = ids;
                }
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(this.notificationsService, error);
                this.modalInput.data.widgetRef.modalService.closeModal(
                    null,
                    this.modalInput.modalId
                );
            },
            input: {},

            uniqueIdentity: Symbol(),
        };

        new HitApi(
            hitApi,
            this.modalInput.data.widgetRef.httpService,
            this.modalInput.data.widgetRef.ngZone
        ).hitApi();
    }
    isReadChecked(row: any): boolean {
        if (this.modalInput.data?.cardata) {
            return row.reportAccessControlFlag === Permission.READ;
        }
    }

    isWriteChecked(row: any): boolean {
        if (this.modalInput.data?.cardata) {
            return row.reportAccessControlFlag === Permission.WRITE;
        }
    }
    isReadDisabled(row: any): boolean {
        if (this.modalInput?.data?.selectedCards) {
            if (this.disableRead) {
                return true;
            }
        }
    }

    isWriteDisabled(row: any): boolean {
        if (this.modalInput?.data?.selectedCards) {
            if (this.disableWrite) {
                return true;
            }
        }
    }
    handlePermission(event: Event, permission, rowData) {
        const checkbox = event.target as HTMLInputElement;
        if (this.modalInput.data?.cardata) {
            if (checkbox.checked) {
                rowData.reportAccessControlFlag = permission;
                if (!this.selectedRows.includes(rowData)) {
                    this.selectedRows.push(rowData);
                }
            } else if (rowData.reportAccessControlFlag === permission) {
                rowData.reportAccessControlFlag = null;
                const index = this.selectedRows.indexOf(rowData);
                this.selectedRows.splice(index, 1);
            }
        } else {
            if (checkbox.checked && permission === Permission.READ) {
                const row = {
                    reportAccessControlFlag: Permission.READ,
                    userInfo: rowData,
                };
                this.reportShareModelList.push(row);
                this.bulkSharePayload['reportShareModelList'] =
                    this.reportShareModelList;
                this.disableWrite = true;
            } else if (!checkbox.checked && permission === Permission.READ) {
                const row = {
                    reportAccessControlFlag: Permission.READ,
                    userInfo: rowData,
                };
                const index =
                    this.bulkSharePayload['reportShareModelList'].indexOf(row);
                this.bulkSharePayload['reportShareModelList'].splice(index, 1);
                this.disableWrite = true;
                if (this.reportShareModelList.length < 1) {
                    this.disableWrite = false;
                }
            } else if (!checkbox.checked && permission === Permission.WRITE) {
                const row = {
                    reportAccessControlFlag: Permission.WRITE,
                    userInfo: rowData,
                };
                const index =
                    this.bulkSharePayload['reportShareModelList'].indexOf(row);
                this.bulkSharePayload['reportShareModelList'].splice(index, 1);
                this.disableRead = true;
                if (this.reportShareModelList.length < 1) {
                    this.disableRead = false;
                }
            } else if (checkbox.checked && permission === Permission.WRITE) {
                const row = {
                    reportAccessControlFlag: Permission.WRITE,
                    userInfo: rowData,
                };
                this.reportShareModelList.push(row);
                this.bulkSharePayload['reportShareModelList'] =
                    this.reportShareModelList;
                this.disableRead = true;
            } else if (!checkbox.checked && permission === Permission.READ) {
                if (this.reportShareModelList.length < 1) {
                    this.disableWrite = false;
                }
            } else if (!checkbox.checked && permission === Permission.WRITE) {
                if (this.reportShareModelList.length < 1) {
                    this.disableRead = false;
                }
            }
        }
    }
    handleSearch(event: Event, searching) {
        this.searching = searching;
        const target = event.target as HTMLInputElement;
        const searchString = target.value.toLowerCase();
        if (!target.value) {
            // If input is empty, revert to the original data
            this.searching = false;
            this.copyData = [...this.tabledata];
            return;
        }
        if (!this.modalInput.data?.cardata) {
            this.copyData = this.tabledata.filter((val) =>
                val.firstName.toLowerCase().includes(searchString)
            );
        } else {
            this.copyData = this.tabledata.filter((val) =>
                val?.userInfo?.firstName.toLowerCase().includes(searchString)
            );
        }
    }
}
enum Permission {
    READ = 'READ',
    WRITE = 'WRITE',
}
