import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nLevelRoutePipe'
})
export class NLevelRoutePipe implements PipeTransform {
    transform(leafNodes: Map<number, any>): string {
        let path = '';
        let level = 1;
        while (leafNodes.has(level)) {
            path += `/${
                leafNodes.get(level)['name'].includes('/')
                    ? leafNodes.get(level)['name'].replaceAll('/', '_')
                    : leafNodes.get(level)['name']
            }`;
            ++level;
        }
        return path;
    }
}
