<div class="deleteResourceTag">
    <div class="delete-keys">
        <label><strong>Resource tag keys</strong></label>
        <app-multi-select-dropdown
            [filterInfo]="filterInfo"
            (change)="resourceTagKeysToDelete = $event"
        >
        </app-multi-select-dropdown>
    </div>

    <div class="button-container">
        <p class="action-warning">
            <span style="color: red">*</span> This action can't be undone
        </p>

        <app-button-generator [buttonGenInput]="buttonInput">
        </app-button-generator>
    </div>
</div>
