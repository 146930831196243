import { Component, OnInit } from '@angular/core';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Helper } from './../../../../classes/Helper';
import { IconType } from './../../../../enums/IconType';
import { IIcon } from './../../../../interfaces/icon-data/IIcon';
import { IColumnData } from './../../../../interfaces/table-generator/IColumnData';
import { ITableGeneratorInput } from './../../../../interfaces/table-generator/ITableGeneratorInput';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';

@Component({
    selector: 'app-ri-utilization-modal',
    templateUrl: './ri-utilization-modal.component.html',
    styleUrls: ['./ri-utilization-modal.component.sass']
})
export class RiUtilizationModalComponent implements OnInit {
    data;
    dataMap: any;
    consumptionBreakupArray = [];
    consumptionInformation = [];
    consumptionInfoTableData: object[] = [];
    consumptionInfoTableGen: ITableGeneratorInput;
    generalInformationTableData: object[];
    generalInformationTableGen: ITableGeneratorInput;
    consumptionBreakUpTableData: Object[];
    consumptionBreakUpTableGen: ITableGeneratorInput;

    arrowUpIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-up'
    };

    arrowDownIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-down'
    };

    defaultColDef: any = {
        sortable: false,
        filter: false,
        resizable: true
    };

    constructor(
        public modalData: ModalInjectedData,
        public modalService: ModalService
    ) {
        this.data = modalData.data;
    }

    ngOnInit(): void {
        this.consumptionBreakupArray =
            this.data.dataMap['Consumption Information Break Up'];
        this.consumptionInformation =
            this.data.dataMap['Consumption Information'];
        this.initConsumptionTable();
        if (this.data.card['Consumption Information Break Up']) {
            this.initConsumptionBreakUp();
        }
        this.initgeneralInformationTable();
    }
    checkContain(key, data) {
        if (key in data) {
            return true;
        }
        return false;
    }

    checkInstanceOfArray(item) {
        if (item instanceof Array) {
            if (item.length === 1) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    initConsumptionTable() {
        const columns: IColumnData[] = [
            {
                columnKey: 'key',
                columnName: '',
                pinned: 'left',
                cellClass: 'pinned-key',
                minWidth: 180
            },
            {
                columnKey: 'Actual Hours',
                columnName: 'Actual Hours',
                cellClass: 'value-column'
            },
            {
                columnKey: 'Percentage',
                columnName: 'Percentage',
                cellClass: 'value-column'
            }
        ];
        const consumptionInfo = Helper.cloneDeep(
            this.data.card['Consumption Information']
        );
        Object.keys(consumptionInfo).forEach((consumtionKey) => {
            const data = consumptionInfo[consumtionKey];
            data['key'] = consumtionKey;
            this.consumptionInfoTableData.push(data);
        });
        this.consumptionInfoTableGen = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            tableAutoHeight: true,
            columns
        };
    }

    initgeneralInformationTable() {
        const columns: IColumnData[] = [
            {
                columnKey: 'key',
                columnName: 'key',
                pinned: 'left',
                cellClass: 'pinned-key'
            },
            {
                columnKey: 'value',
                columnName: 'value',
                cellClass: (params) => {
                    if (params.data.key === 'ARN') {
                        return 'value-column scrollContent';
                    }
                    return 'value-column';
                }
            }
        ];
        const data = this.data.card['General Information'];
        const tableData: object[] = [];
        Object.keys(data).forEach((colKey) => {
            tableData.push({ key: colKey, value: data[colKey] });
        });
        this.generalInformationTableData = tableData;
        this.generalInformationTableGen = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            tableAutoHeight: true,
            columns,
            headerHeight: 0
        };
    }

    initConsumptionBreakUp() {
        const columns: IColumnData[] = [];
        this.consumptionBreakupArray.forEach((column) => {
            columns.push({
                columnKey: column,
                columnName: column,
                cellClass: 'value-column'
            });
        });
        const consumptionBreakUpData =
            this.data.card['Consumption Information Break Up'];
        this.consumptionBreakUpTableData = [];
        Object.keys(consumptionBreakUpData).forEach((data) => {
            this.consumptionBreakUpTableData.push(consumptionBreakUpData[data]);
        });

        this.consumptionBreakUpTableGen = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            tableAutoHeight: true,
            columns
        };
    }
}
