import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ICustomerProfileApiResponse } from 'src/app/shared/interfaces/api/portlets/ICustomerProfileApiResponse';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { Helper } from './../../../../classes/Helper';
import { HitApi } from './../../../../classes/HitApi';
import { FilterType } from './../../../../enums/FilterType';
import { FormState } from './../../../../enums/FormState';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from './../../../../interfaces/hit-api/IHitApi';

@Component({
    selector: 'app-customer-profile',
    templateUrl: './customer-profile.component.html',
    styleUrls: ['./customer-profile.component.sass']
})
export class CustomerProfileComponent implements OnInit, AfterViewInit {
    // CUSTOMER_PROFILE: 1.0

    widgetRef: Widget;
    FormState = FormState;
    customerInfoFormGenInput: IFormGeneratorInput = null;
    resetCustomerInfo: any;
    constructor(
        widgetData: WidgetInjectedData,
        private formBuilder: FormBuilder,
        private notificationService: NotificationsService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        // Show View Icon
        // this.widgetRef.showViewIcon.next(true);

        // Update Header Icon
        this.widgetRef.headerIcon.next({
            class: 'fas fa-user-alt',
            type: IconType.FONTAWSOME
        });
    }

    bindData(data: ICustomerProfileApiResponse) {
        this.customerInfoFormGenInput = {
            formName: 'Customer Profile',
            state: FormState.IDLE,
            submitButton: {
                buttonName: 'Update',
                showLoader: true,
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                function: (
                    buttonRef: IButtonGeneratorInput,
                    formGroup: FormGroup
                ) => {
                    this.updateCustomerInfo(formGroup.value);
                }
            },
            fields: [
                {
                    label: 'Company Name',
                    placeholder: 'Company Name',
                    name: 'companyName',
                    value: data.companyName ? data.companyName : '',
                    fieldType: FilterType.TEXT,
                    required: true,
                    disabled: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'card_travel'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Company name is required'
                        }
                    ]
                },
                {
                    label: 'Title',
                    placeholder: 'Title',
                    name: 'title',
                    value: data.title ? data.title : '',
                    fieldType: FilterType.TEXT,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'perm_identity'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Title is required'
                        }
                    ]
                },
                {
                    label: 'Name',
                    placeholder: 'Name',
                    name: 'name',
                    value: data.name ? data.name : '',
                    fieldType: FilterType.TEXT,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'perm_identity'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Name is required'
                        }
                    ]
                },
                {
                    label: 'Email Id',
                    placeholder: 'Email Id',
                    name: 'email',
                    value: data.email ? data.email : '',
                    fieldType: FilterType.EMAIL,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'email'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email is required'
                        },
                        {
                            validator: CustomValidators.email,
                            errorMessage: 'Email is invalid'
                        }
                    ]
                },
                {
                    label: 'Phone Number',
                    placeholder: 'Phone Number',
                    name: 'number',
                    value: data.number ? data.number : '',
                    fieldType: FilterType.NUMBER,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'call'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Phone number is required'
                        }
                    ]
                },
                {
                    label: 'Set Password Expiry',
                    placeholder: '0(Days)',
                    name: 'passwordUpdatePolicyDays',
                    value: data.passwordUpdatePolicyDays
                        ? data.passwordUpdatePolicyDays
                        : '',
                    fieldType: FilterType.NUMBER,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'lock_open'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Password expiry is required'
                        }
                    ]
                },
                {
                    label: 'Data Retention',
                    placeholder: '12(Months)',
                    name: 'dataRetentionMonths',
                    value: data.dataRetentionMonths
                        ? data.dataRetentionMonths
                        : '',
                    fieldType: FilterType.NUMBER,
                    disabled: true,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'description'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Data retantion month is required'
                        }
                    ]
                }
            ]
        };

        this.widgetRef.endLoader();
    }
    changeCustomerInfoState(state: FormState) {
        if (this.resetCustomerInfo) {
            this.resetCustomerInfo();
        }
        this.customerInfoFormGenInput.state = state;
        this.customerInfoFormGenInput = Helper.dereference(
            this.customerInfoFormGenInput
        );
    }
    updateCustomerInfo(form) {
        const editCustomerInputJson = form;
        const editApiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.update
        );
        editApiArgs.input = editCustomerInputJson;
        editCustomerInputJson['number'] = `${editCustomerInputJson['number']}`;

        editApiArgs.function = (response) => {
            this.notificationService.showSnackBar(
                'User details successfully updated.'
            );
            this.widgetRef.refreshWidget();
        };
        editApiArgs.errorFunction = (error) => {
            this.notificationService.showSnackBar(
                error && error.error
                    ? error.error.message
                    : 'Error updating user details',
                true
            );

            this.widgetRef.refreshWidget();
        };
        new HitApi(
            editApiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
