<form class="form">
    <div class="form-data">
        <app-filters-handler
            [widgetIds]="widgetsSelected"
            [filterStoreKey]="FilterStoreKey.REPORT_FILTERS"
            (formats)="supportedFormats = $event"
        ></app-filters-handler>
    </div>
    <div class="tw-justify-between form-controls">
        <app-button-generator
            *ngIf="backButton"
            [buttonGenInput]="backButton"
        ></app-button-generator>
        <app-button-generator
            *ngIf="nextButton"
            [buttonGenInput]="nextButton"
        ></app-button-generator>
    </div>
</form>
