import { Component, OnInit } from '@angular/core';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-delete-resource-tags',
    templateUrl: './delete-resource-tags.component.html',
    styleUrls: ['./delete-resource-tags.component.sass']
})
export class DeleteResourceTagsComponent implements OnInit {
    filterInfo: IFilterInfo = null;
    resourceTagKeys;
    resourceTagKeysToDelete;
    buttonInput: IButtonGeneratorInput;
    constructor(
        private modalInputData: ModalInjectedData,
        private notificationsService: NotificationsService
    ) {
        this.resourceTagKeys = JSON.parse(
            JSON.stringify(this.modalInputData.data['resourceTagKeys'])
        );
        this.resourceTagKeys = this.resourceTagKeys.map((key) => {
            const obj = {
                label: key,
                id: key
            };
            return obj;
        });
    }

    ngOnInit(): void {
        this.filterInfo = {
            customInput: null,
            listData: this.resourceTagKeys,
            filters: null,
            label: null,
            placeholder: 'Select keys',
            selector: '',
            type: FilterType.DROPDOWN_MULTIPLE,
            version: '1.0',
            lightMode: true
        };
        this.buttonInput = {
            buttonName: 'Delete Resource Tag(s)',
            buttonColorType: ButtonColorType.WARN,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.submit(buttonRef);
            },
            buttonType: ButtonType.RAISED,
            showLoader: true
        };
    }

    submit(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }

        // resourceTagKeysToDelete
        if (Object.keys(this.resourceTagKeysToDelete).length === 0) {
            this.notificationsService.showSnackBar(
                'Delete Atleast One Resource Tag',
                true
            );
            return;
        }

        buttonRef.loader = true;
        // resourceTags, inputDataFoDeletingResourceTag, loader: BehaviorSubject<boolean>
        this.modalInputData.data['function'](
            this.modalInputData.data['inputDataFoDeletingResourceTag'],
            this.resourceTagKeysToDelete,
            buttonRef,
            this.modalInputData.modalId
        );
    }
}
