import { Component, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { GridOptions, RowEvent } from 'ag-grid-community';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { CartCacheService } from 'src/app/shared/services/cache/cart-cache/cart-cache.service';
import { MultiButtonGeneratorComponent } from '../../../multi-button-generator/multi-button-generator.component';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';
import { FilterType } from './../../../../enums/FilterType';
import { AgGridCellColumnComponent } from './../../../ag-grid-cell-column/ag-grid-cell-column.component';

@Component({
    selector: 'app-master-login-users-list',
    templateUrl: './master-login-users-list.component.html',
    styleUrls: ['./master-login-users-list.component.sass']
})
export class MasterLoginUsersListComponent implements OnInit {
    widgetRef: Widget = null;
    tableGenInput: ITableGeneratorInput = null;
    userMasterLogin = null;
    callBackfunc = null;
    tenantId: string = '';
    modalData;
    agGrid: GridOptions = null;
    viewId: string = '';
    storeViewIdWithIndex = new Map<number, string>();
    storeSelectedViewIdWithId = new Map<string, string>();
    singleSelectDropdDownRef: MatSidenav;

    constructor(
        public modalInputData: ModalInjectedData,
        private cartCacheService: CartCacheService
    ) {
        this.widgetRef = this.modalInputData.data.widgetRef;
        this.callBackfunc = modalInputData.data.funcRef;
        this.tenantId = modalInputData.data.tenantId;
        this.modalData = modalInputData.data;
    }
    agGridBodyScroll($event) {
        if (this.singleSelectDropdDownRef) {
            this.singleSelectDropdDownRef.close();
        }
    }

    ngOnInit(): void {
        this.modalData = this.modalInputData.data;
        this.tableGenInput = {
            listExtraction: { type: 'DIRECT' },
            extraComponents: {
                agCell: AgGridCellColumnComponent
            },
            tableHeight: 350,
            columns: [
                {
                    columnKey: 'firstName',
                    columnName: 'First Name'
                },
                {
                    columnName: 'Last Name',
                    columnKey: 'lastName'
                },

                {
                    columnName: 'User Type',
                    columnKey: 'userType',
                    cellRenderer: (rowData: RowEvent) => {
                        if (rowData.data.userType === 'Root') {
                            return `<span ><span class="yellow-severity-color">Root</span></span>`;
                        } else {
                            return `<span ><span class="blue-usertype-color">Non Root</span></span>`;
                        }
                    }
                },
                {
                    columnName: 'Email Id',
                    columnKey: 'email'
                },
                {
                    columnName: 'View Name',
                    columnKey: 'viewName',
                    cellEditor: true,
                    minWidth: 180,
                    cellEditorParams: {},
                    cellRenderer: 'agCell',
                    cellRendererParams: {
                        callBackFn: ($event, params) => {
                            this.storeViewIdWithIndex.set(
                                params.rowIndex,
                                $event
                            );
                            this.storeSelectedViewIdWithId.set(
                                params.data['id'],
                                $event
                            );
                        },
                        getSelectedData: (params) => {
                            const data = this.getData(params);
                            if (
                                this.storeSelectedViewIdWithId.has(
                                    params.data['id']
                                )
                            ) {
                                return this.storeSelectedViewIdWithId.get(
                                    params.data['id']
                                );
                            }
                            if (params.data['defaultViewId']) {
                                return params.data['defaultViewId'];
                            }

                            return data[0]['id'];
                        },
                        getListData: (params) => {
                            const userData =
                                this.modalInputData.data.users[params.rowIndex][
                                    'userViews'
                                ];
                            userData.forEach((userName) => {
                                userName['hoveredText'] = userName['label'];
                            });
                            return this.getData(params);
                        },
                        dropDownRefFn: ($event: MatSidenav) => {
                            this.singleSelectDropdDownRef = $event;
                        },
                        filterInfo: {
                            type: FilterType.DROPDOWN_SINGLE
                        },
                        appendToClass: 'master-login-container'
                    }
                },
                {
                    columnName: 'User Login',
                    columnKey: 'userLogin',
                    buttonGen: true,
                    cellClass: 'user-login',
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: RowEvent) => {
                        rowData['buttonGenInputs'] = [
                            {
                                buttonName: 'Login',
                                buttonType: ButtonType.STROKED,
                                buttonColorType: ButtonColorType.PRIMARY,
                                customClass: `user-master-login-button user-master-login-button-${rowData.rowIndex} table-button`,
                                showLoader: true,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    if (this.userMasterLogin === null) {
                                        const userId = rowData.data['id'];
                                        if (userId) {
                                            this.cartCacheService.deleteCollection();

                                            document
                                                .querySelector(
                                                    `.user-master-login-button-${rowData.rowIndex}`
                                                )
                                                .classList.add('disabled');

                                            this.userMasterLogin =
                                                rowData.rowIndex;

                                            buttonRef.loader = true;

                                            const resetTable = () => {
                                                buttonRef.loader = false;
                                                document
                                                    .querySelector(
                                                        `.user-master-login-button-${rowData.rowIndex}`
                                                    )
                                                    .classList.remove(
                                                        'disabled'
                                                    );

                                                this.userMasterLogin = null;
                                                document
                                                    .querySelector(
                                                        `.user-master-login-button-${rowData.rowIndex}`
                                                    )
                                                    .classList.remove(
                                                        'disabled'
                                                    );
                                            };

                                            if (this.callBackfunc) {
                                                this.viewId = rowData.data[
                                                    'defaultViewId'
                                                ]
                                                    ? rowData.data[
                                                          'defaultViewId'
                                                      ]
                                                    : rowData.data.userViews[0]
                                                          .id;
                                                if (
                                                    this.storeViewIdWithIndex.has(
                                                        rowData.node.rowIndex
                                                    )
                                                ) {
                                                    this.viewId =
                                                        this.storeViewIdWithIndex.get(
                                                            rowData.node
                                                                .rowIndex
                                                        );
                                                }
                                                if (
                                                    this.storeSelectedViewIdWithId.has(
                                                        rowData.data['id']
                                                    )
                                                ) {
                                                    this.viewId =
                                                        this.storeSelectedViewIdWithId.get(
                                                            rowData.data['id']
                                                        );
                                                }

                                                this.callBackfunc(
                                                    rowData.data,
                                                    this.tenantId,
                                                    resetTable,
                                                    this.viewId
                                                );
                                            }
                                        }
                                    }
                                }
                            }
                        ];
                        const buttonOptions: IMultiButtonOption = {
                            layout: {
                                justifyContent: 'center'
                            }
                        };
                        rowData['options'] = buttonOptions;
                        return rowData;
                    }
                }
            ]
        };
    }
    getData(params) {
        let listData = [];
        this.modalInputData.data.users.every((rowData, rowIndex) => {
            if (rowData['id'] === params['data']['id']) {
                listData =
                    this.modalInputData.data.users[rowIndex]['userViews'];
                return false;
            }
            return true;
        });
        return listData;
    }
}
