import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/shared/classes/ErrorStateMatcher';
import { Helper } from 'src/app/shared/classes/Helper';
import { DropdownSingleFieldAppearance } from 'src/app/shared/enums/AppearanceType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IFilterSelection } from 'src/app/shared/interfaces/filter/IFilterSelection';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
    selector: 'app-single-dropdown-field',
    templateUrl: './single-dropdown-field.component.html',
    styleUrls: ['./single-dropdown-field.component.sass'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: SingleDropdownFieldComponent,
            multi: true
        }
    ]
})
export class SingleDropdownFieldComponent
    implements OnInit, ControlValueAccessor
{
    @Input() field: IFormField;
    @Input() formGeneratorInput: IFormGeneratorInput;
    @Input() formGroup: FormGroup;
    @Input() loading: Map<string, boolean>;
    @Input() compareWith: any;
    @Input() filterData: IFilterSelection;
    @Output() valueChange = new EventEmitter<any>();
    @Output() onScroll = new EventEmitter<any>();
    @Output() onChecked = new EventEmitter<any>();
    FilterType = FilterType;
    FormState = FormState;
    Infinity = Infinity;
    showOverlay = false;
    AppearanceType = DropdownSingleFieldAppearance;
    onChange: any = () => {};
    onTouch: any = () => {};
    control: FormControl;
    matcher = new MyErrorStateMatcher();
    filterInfo: IFilterInfo;
    constructor(private filterService: FiltersService) {}
    ngOnInit(): void {
        const existingAppearance = Object.values(this.AppearanceType);
        if (
            !existingAppearance.includes(
                this.field.appearance as DropdownSingleFieldAppearance
            )
        ) {
            this.field.appearance = Helper.getFieldsAppearance(
                this.field,
                this.field.isFilter ? true : false
            );
        }
        this.control = this.findControl();
        this.filterInfo = this.filterService.currentPageFilterInfo.has(
            this.field.id
        )
            ? this.filterService.currentPageFilterInfo.get(this.field.id)
            : null;
    }
    findControl(): FormControl {
        if (this.field.groupByKey) {
            const formControl = this.formGroup
                .get(this.field.groupByKey)
                .get(this.field.name);
            return formControl as FormControl;
        } else {
            return this.formGroup.get(this.field.name) as FormControl;
        }
    }
    writeValue(obj: any): void {
        this.control = this.findControl();
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }
    setDisabledState?(isDisabled: boolean): void {}
    onValueChange(event) {
        let finalValue = event;
        if (typeof event === 'object') {
            if (this.field.getKey === undefined) {
                finalValue = event['id'];
            } else if (this.field.getKey in event) {
                finalValue = event[this.field.getKey];
            }
        }
        this.valueChange.emit(finalValue);
    }
    scroll(event) {
        this.onScroll.emit(event);
    }
    checkboxWithDropdownToggled(isChecked: boolean) {
        this.onChecked.emit(isChecked);
    }
}
