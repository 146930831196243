import { Component, OnInit } from '@angular/core';
import { Logger } from 'src/app/shared/classes/Logger';
import { TestActions } from 'src/app/shared/enums/TestActions';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';

@Component({
    selector: 'app-test-actions',
    templateUrl: './test-actions.component.html',
    styleUrls: ['./test-actions.component.sass']
})
export class TestActionsComponent implements OnInit {
    actions: string[];
    Actions = TestActions;

    showSubAction = false;
    subActionsCallback;
    subActionHeading;
    subActionInfo;

    constructor(
        private globalDataService: GlobalDataService,
        private httpService: HttpService
    ) {}

    ngOnInit(): void {
        this.actions = Object.keys(TestActions);
    }

    handleAction(action: string) {
        if (action) {
            // Handle Action
            switch (TestActions[action]) {
                case TestActions.TEST_PORTLET:
                    this.subActionHeading = TestActions.TEST_PORTLET;
                    this.subActionInfo = `Enter widget id below and same widget id must be present in db.json with proper JSON syntax.`;
                    this.subActionsCallback = this.testWidget.bind(this);
                    this.showSubAction = true;
                    break;
                case TestActions.ENABLE_LOGS:
                    Logger.showLogs = true;
                    this.globalDataService.testingActionsObs.next(null);
                    break;
                case TestActions.DISABLE_LOGS:
                    Logger.showLogs = false;
                    this.globalDataService.testingActionsObs.next(null);
                    break;
                case TestActions.CHANGE_BASE_URL:
                    this.subActionHeading = TestActions.CHANGE_BASE_URL;
                    this.subActionInfo = `Enter base url (Omit ending /)`;
                    this.subActionsCallback = (baseUrl: string) => {
                        this.httpService.DEFAULT_BASE_URL = baseUrl;
                        this.globalDataService.testingActionsObs.next(null);
                    };
                    this.showSubAction = true;
                    break;
            }
        }
        // DO NOTHING
    }

    testWidget(widgetId: string) {
        this.resetSubAction();
        this.globalDataService.testingActionsObs.next({
            actionType: TestActions.TEST_PORTLET,
            data: widgetId
        });
    }

    goBack() {
        this.resetSubAction();
    }

    resetSubAction() {
        this.subActionHeading = null;
        this.subActionInfo = null;
        this.subActionsCallback = null;
        this.showSubAction = false;
    }
}
