import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class RegexValidator extends CustomValidator {
    key = CustomValidatorKey.REGEX_VALIDATOR;

    constructor(private regex: string) {
        super();
    }

    validatorFunction(control: AbstractControl): ValidationErrors {
        return Validators.pattern(this.regex)(control);
    }
}
