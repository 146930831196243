import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import * as $ from 'jquery';
import { IGraphData, IMapData } from '../../interfaces/graph/IGraphData';
import { Helper } from './../../classes/Helper';
import { IconType } from './../../enums/IconType';
import { ViewType } from './../../enums/ViewType';
import { IIcon } from './../../interfaces/icon-data/IIcon';

@Component({
    selector: 'app-map-chart',
    templateUrl: './map-chart.component.html',
    styleUrls: ['./map-chart.component.sass']
})
export class MapChartComponent implements OnInit, OnDestroy, OnChanges {
    homeIcon: IIcon = {
        type: IconType.SVG,
        class: 'home_filled'
    };
    zoomInIcon: IIcon = {
        type: IconType.SVG,
        class: 'zoom_in'
    };
    zoomOutIcon: IIcon = {
        type: IconType.SVG,
        class: 'zoom_out'
    };

    map: any;
    am4core: any;
    am4maps: any;
    mapData: IMapData[];
    GraphActions = GraphActions;
    ViewType = ViewType;
    graphId: string = Helper.generateUniqueKey(8);
    legendId: string = Helper.generateUniqueKey(8);
    @Input('graphData') graphData: IGraphData;

    constructor() {}

    ngOnInit(): void {
        this.mapData = this.graphData.chartData as IMapData[];
        Helper.loadAm4chartsLibraries().then((resolve) => {
            this.am4core = resolve['am4core'];
            this.am4maps = resolve['am4maps'];
            this.drawMap();
        });
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.graphData &&
            changes.graphData.currentValue !== changes.graphData.previousValue
        ) {
            this.drawMap();
        }
    }

    drawMap() {
        if (!this.am4core || !this.am4maps) return;
        this.disposeChart();

        if (!document.getElementById(this.graphId)) {
            setTimeout(() => {
                this.drawMap();
            }, 100);
            return;
        }

        this.map = this.am4core.create(this.graphId, this.am4maps.MapChart);
        this.map.geodata = am4geodata_worldLow;
        this.map.projection = new this.am4maps.projections.Miller();
        this.map.chartContainer.wheelable = false;

        const polygonSeries = this.map.series.push(
            new this.am4maps.MapPolygonSeries()
        );
        polygonSeries.exclude = ['AQ'];
        polygonSeries.useGeodata = true;

        const polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.fill = this.am4core.color(
            Helper.convertToHexIfHsl(
                Helper.getCssVarValue('regionalGraphColor')
            )
        );
        polygonTemplate.strokeWidth = 0.2;

        const imageSeries = this.map.series.push(
            new this.am4maps.MapImageSeries()
        );
        imageSeries.mapImages.template.propertyFields.longitude = 'longitude';
        imageSeries.mapImages.template.propertyFields.latitude = 'latitude';

        imageSeries.data = this.graphData.chartData;

        this.map.events.on('ready', this.updateMarkers.bind(this));
        this.map.events.on('mappositionchanged', this.updateMarkers.bind(this));
        this.map.svgContainer.SVGContainer.style.overflow = 'visible';

        this.generateLegends();
    }

    onZoomActionPerformed(type: GraphActions) {
        if (!this.map) {
            return;
        }
        switch (type) {
            case GraphActions.Home:
                this.map.goHome();
                break;
            case GraphActions.ZoomIn:
                this.map.zoomIn();
                break;
            case GraphActions.ZoomOut:
                this.map.zoomOut();
                break;
            default:
                this.map.goHome();
                break;
        }
    }

    updateMarkers(event) {
        if (
            event.target.series.values[1] &&
            event.target.series.values[1].mapImages
        ) {
            event.target.series.values[1].mapImages.each((image, index) => {
                if (!image.dummyData || !image.dummyData.externalElement) {
                    image.dummyData = {
                        externalElement: this.createMarker(image, index)
                    };
                }
                const xy = this.map.geoPointToSVG({
                    longitude: image.longitude,
                    latitude: image.latitude
                });
                image.dummyData.externalElement.moveTo({ x: xy.x, y: xy.y });
            });
        }
    }

    pulseAnimation(target) {
        const animation = target.animate(
            [
                { property: 'scale', from: 1, to: 2 },
                { property: 'opacity', from: 1, to: 0 }
            ],
            1000,
            this.am4core.ease.circleOut
        );
        animation.events.on('animationended', ({ target }) => {
            this.pulseAnimation(target.object);
        });
    }

    createMarker(image, index) {
        const worldMap = image.dataItem.component.chart;
        const color = image.dataItem.dataContext['color'];
        const title = image.dataItem.dataContext['title'];
        const markerContainer = worldMap.createChild(this.am4core.Container);
        const markerOuter = markerContainer.createChild(this.am4core.Circle);
        const markerDot = markerContainer.createChild(this.am4core.Circle);
        markerDot.fill = this.am4core.color(color);
        markerOuter.stroke = this.am4core.color(color);
        // This below color cannot be whitelabelled since it is transparent color used in legends
        markerOuter.fill = this.am4core.color('#00000000');
        markerContainer.tooltipText = title;
        markerContainer.cursorOverStyle = this.am4core.MouseCursorStyle.pointer;
        markerContainer.element.attr({
            id: this.graphId + 'map-marker' + index
        });

        if (
            this.graphData.chartData[index] &&
            this.graphData.chartData[index]['regionCost']
        ) {
            switch (index) {
                case 0:
                    markerDot.radius = 9;
                    markerOuter.radius = 15.5;
                    markerOuter.strokeWidth = 4.5;
                    const markerPulse = markerContainer.createChild(
                        this.am4core.Circle
                    );
                    markerPulse.radius = 15.5;
                    markerPulse.fill = color;
                    markerPulse.events.on('inited', ({ target }) => {
                        this.pulseAnimation(target);
                    });
                    break;
                case 1:
                    markerDot.radius = 8;
                    markerOuter.radius = 14;
                    markerOuter.strokeWidth = 4;
                    break;
                case 2:
                    markerDot.radius = 7;
                    markerOuter.radius = 12.5;
                    markerOuter.strokeWidth = 3.5;
                    break;
                case 3:
                    markerDot.radius = 6;
                    markerOuter.radius = 11;
                    markerOuter.strokeWidth = 3;
                    break;
                case 4:
                    markerDot.radius = 5;
                    markerOuter.radius = 9;
                    markerOuter.strokeWidth = 2.5;
                    break;
                default:
                    markerDot.radius = 3;
                    markerOuter.radius = 6;
                    markerOuter.strokeWidth = 2;
                    break;
            }
        } else {
            markerDot.radius = 3;
            markerOuter.radius = 6;
            markerOuter.strokeWidth = 2;
        }

        if (index) {
            markerContainer.events.on('over', () => {
                markerContainer.scale = 1.5;
            });
            markerContainer.events.on('out', () => {
                markerContainer.scale = 1;
            });
        }

        return markerContainer;
    }

    generateLegends() {
        if (!document.getElementById(this.legendId)) {
            setTimeout(() => {
                this.generateLegends();
                return;
            }, 10);
            return;
        }

        const customLegend = document.getElementById(this.legendId);
        customLegend.innerHTML = '';
        for (const i in this.graphData.chartData) {
            if (!('toggle' in this.graphData.chartData[i])) {
                this.graphData.chartData[i]['toggle'] = true;
            }
            const row: any = this.graphData.chartData[i];
            customLegend.innerHTML +=
                '<div class="legend-item" id="' +
                this.graphId +
                'legend-item-' +
                i +
                '" style="padding-bottom: 4px;"><div class="legend-marker" id="' +
                this.graphId +
                'legend-marker-' +
                i +
                '" style="background: ' +
                row.color +
                '"></div><div class="legend-name-cost-holder">' +
                '<p class="legend-name">' +
                row.region +
                '</p>' +
                (row['regionCost']
                    ? '<p class="legend-cost">' +
                      (this.graphData.currencySymbol ?? '') +
                      row['regionCost'] +
                      '</p>'
                    : '') +
                '</div></div>';

            setTimeout(() => {
                $('#' + this.graphId + 'legend-item-' + i).click((event) => {
                    this.toggleLegends(i);
                    event.stopPropagation();
                });
            }, 10);

            if (this.graphData.chartData[i]['regionCost']) {
                switch (i) {
                    case '0':
                        $('#' + this.graphId + 'legend-marker-' + i).css({
                            width: '25px',
                            height: '25px',
                            minWidth: '25px'
                        });
                        break;
                    case '1':
                        $('#' + this.graphId + 'legend-marker-' + i).css({
                            width: '23px',
                            height: '23px',
                            minWidth: '23px'
                        });
                        break;
                    case '2':
                        $('#' + this.graphId + 'legend-marker-' + i).css({
                            width: '21px',
                            height: '21px',
                            minWidth: '21px'
                        });
                        break;
                    case '3':
                        $('#' + this.graphId + 'legend-marker-' + i).css({
                            width: '19px',
                            height: '19px',
                            minWidth: '19px'
                        });
                        break;
                    case '4':
                        $('#' + this.graphId + 'legend-marker-' + i).css({
                            width: '17px',
                            height: '17px',
                            minWidth: '17px'
                        });
                        break;
                }
            }
        }
        setTimeout(() => {
            this.toggleAllLegends();
        }, 500);
    }

    toggleLegends(index) {
        this.mapData[index]['toggle'] = !this.mapData[index]['toggle'];
        if (this.mapData[index]['toggle']) {
            $('#' + this.graphId + 'map-marker' + index).css({
                display: ''
            });
            $('#' + this.graphId + 'legend-marker-' + index).css({
                background: this.mapData[index]['color']
            });
            $('#' + this.graphId + 'legend-item-' + index).css({
                color: 'var(--regionalGraphActiveColor)'
            });
        } else {
            $('#' + this.graphId + 'map-marker' + index).css({
                display: 'none'
            });
            $('#' + this.graphId + 'legend-marker-' + index).css({
                background: 'var(--regionalGraphDisableColor)'
            });
            $('#' + this.graphId + 'legend-item-' + index).css({
                color: 'var(--regionalGraphDisableColor)'
            });
        }
    }

    toggleAllLegends() {
        this.mapData.forEach((row, index) => {
            if (this.graphData.visibleSections.value.has(ViewType.LEGEND)) {
                row['toggle'] = true;
                $('#' + this.graphId + 'map-marker' + index).css({
                    display: ''
                });
                $('#' + this.graphId + 'legend-marker-' + index).css({
                    background: this.mapData[index]['color']
                });
                $('#' + this.graphId + 'legend-item-' + index).css({
                    color: 'var(--regionalGraphActiveColor)'
                });
            } else {
                row['toggle'] = false;
                $('#' + this.graphId + 'map-marker' + index).css({
                    display: 'none'
                });
                $('#' + this.graphId + 'legend-marker-' + index).css({
                    background: 'var(--regionalGraphDisableColor)'
                });
                $('#' + this.graphId + 'legend-item-' + index).css({
                    color: 'var(--regionalGraphDisableColor)'
                });
            }
        });
    }

    disposeChart() {
        if (this.map) {
            this.map.dispose();
            this.map = null;
        }
    }

    ngOnDestroy() {
        this.disposeChart();
    }
}

enum GraphActions {
    Home,
    ZoomIn,
    ZoomOut
}
