import { Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { IconType } from 'src/app/shared/enums/IconType';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';

@Component({
    selector: 'app-terraform-more-info-modal',
    templateUrl: './terraform-more-info-modal.component.html',
    styleUrls: ['./terraform-more-info-modal.component.sass']
})
export class TerraformMoreInfoModalComponent implements OnInit {
    moreInfoData = {};
    getKeys = Object.keys;
    getValues = Object.values;
    containsModalData = false;
    tabDescription: string;
    showData: { [key: string]: Boolean }[] = [];
    totalTabs: number;
    resourcesData: any;
    widgetRef: Widget;
    crossIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fa fa-times'
    };
    logs: string;
    constructor(
        public modalData: ModalInjectedData,
        public modalService: ModalService
    ) {
        this.resourcesData = this.modalData.data['moreInfoData'];
        this.widgetRef = this.modalData.data['widgetRef'];
        this.logs = this.modalData.data['logs'];
        this.moreInfoData = { ...this.modalData.data['moreInfoData'] };
        this.transformData();
        this.tabDescription = this.getKeys(this.moreInfoData)[0];
    }
    transformData() {
        const requestDetail =
            this.modalData.data['moreInfoData']['Request Details'];
        const columns = this.getKeys(requestDetail);
        const data = [this.modalData.data['moreInfoData']['Request Details']];
        this.moreInfoData['Request Details'] = {
            columns: columns,
            data: data
        };
    }
    getLength() {
        this.getKeys(this.moreInfoData).forEach((head, index) => {
            if (this.moreInfoData[head] && this.moreInfoData[head].length > 0) {
                this.totalTabs = index;
            }
        });
    }
    ngOnInit(): void {
        this.setHeadingValues();
        this.getLength();
    }

    showOrNot(head) {
        for (const index in Object.keys(this.showData)) {
            if (Object.keys(this.showData[index])[0] === head) {
                this.showData[index][head] = !this.showData[index][head];
            }
        }
    }
    setHeadingValues() {
        for (const heading of this.getKeys(this.moreInfoData)) {
            this.showData.push({ [heading]: true });
        }
    }
    ifKeyExistInObject(key) {
        return key in this.moreInfoData;
    }
    transformValue(value) {
        if (typeof value === 'object' && !(value instanceof Array)) {
            let str = '';
            const keys = Object.keys(value);
            keys.forEach((key) => {
                str += `${key}(${value[key] + ''}) , `;
            });
            return str;
        }

        return value !== null
            ? typeof value === 'string'
                ? value.trim().replace(/\n/gi, ' ')
                : value
            : '';
    }
    changeTabStyle(tab) {
        this.tabDescription = tab;
        Helper.doScroll(tab, 'start');
    }
}
