import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { State } from 'src/app/shared/enums/State';
import { ViewType } from 'src/app/shared/enums/ViewType';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IValidator } from 'src/app/shared/interfaces/form-generator/IValidator';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IColumnData } from 'src/app/shared/interfaces/table-generator/IColumnData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { SavingsPlanRecommendationModalV2Component } from '../../../modal-templates/savings-plan-recommendation-modal-v2/savings-plan-recommendation-modal-v2.component';
import { SavingsPlanRecommendationModalComponent } from '../../../modal-templates/savings-plan-recommendation-modal/savings-plan-recommendation-modal.component';
import { IAction } from './../../../../interfaces/actions/IAction';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-savings-plan-recommendation',
    templateUrl: './savings-plan-recommendation.component.html',
    styleUrls: ['./savings-plan-recommendation.component.sass']
})
export class SavingsPlanRecommendationComponent
    implements OnInit, AfterViewInit
{
    widgetRef: Widget;
    dataMap;
    showSearchBox: boolean = true;
    objectKeys = Object.keys;
    ViewType = ViewType;
    cardsData: any[] = [];
    State = State;
    enableFix = true;
    filteredCardsData: any[];
    tableInput: ITableGeneratorInput = null;
    tableData: any[];
    recommendationCount: IButtonGeneratorInput;
    isV2: boolean = false;
    potentialSavings: number;

    cardPurDocLoaderIndex = -1;

    cardButtons: IButtonGeneratorInput[] = [];

    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }
    generateTableView(response) {
        const columnsDef: IColumnData[] = this.dataMap['displayEntry'].map(
            (value) => {
                const colObject: IColumnData = {
                    columnKey: value,
                    columnName: value,
                    cellClass: value === 'Best Savings' ? 'savings' : '',
                    columnValueGetter: (params: ValueGetterParams) => {
                        return params.data['displayEntry'][value];
                    }
                };
                if (this.isV2) {
                    colObject.columnValueGetter = (
                        params: ValueGetterParams
                    ) => {
                        return params.data['displayEntry'][value];
                    };
                }

                return colObject;
            }
        );
        const actionColumn: IColumnData[] = [];

        if (this.isV2) {
            actionColumn.push({
                columnKey: 'action',
                columnName: 'Action',
                pinned: 'right',
                headerClass: 'grid-cell-data-centred',
                buttonGen: true,
                componentFramework: MultiButtonGeneratorComponent,
                valueFormatter: (rowData: ValueFormatterParams) => {
                    const buttons: IButtonGeneratorInput[] = [
                        {
                            buttonName: 'More Info',
                            buttonType: ButtonType.TEXT,
                            buttonColorType: ButtonColorType.INFO,
                            function: () => {
                                this.showRiModalV2(rowData.data);
                            }
                        },
                        {
                            buttonName: 'Purchase',
                            buttonType: ButtonType.TEXT,
                            buttonColorType: ButtonColorType.SUCCESS,
                            showLoader: true,
                            function: (buttonRef: IButtonGeneratorInput) => {
                                this.fetchPurchaseSavingsPlanStepForm(
                                    (response) => {
                                        this.parseAndOpenPurchaseModal(
                                            response,
                                            rowData.data
                                        );
                                    },
                                    buttonRef
                                );
                            }
                        }
                    ];
                    const buttonOptions: IMultiButtonOption = {
                        layout: {
                            justifyContent: 'space-evenly'
                        }
                    };
                    rowData['buttonGenInputs'] = buttons;
                    rowData['options'] = buttonOptions;
                    return rowData;
                }
            });
        } else {
            actionColumn.push({
                columnKey: 'action',
                columnName: 'Action',
                pinned: 'right',
                minWidth: 150,
                headerClass: 'grid-cell-data-centred',
                buttonGen: true,
                componentFramework: MultiButtonGeneratorComponent,
                valueFormatter: (rowData: ValueFormatterParams) => {
                    const buttons: IButtonGeneratorInput[] = [
                        {
                            buttonName: 'More Info',
                            buttonType: ButtonType.TEXT,
                            buttonColorType: ButtonColorType.INFO,
                            function: () => {
                                this.showRiModal(rowData.data);
                            }
                        }
                    ];
                    if (this.widgetRef.widgetData.widgetInfo['purchaseDoc']) {
                        buttons.push({
                            buttonName: 'Purchase',
                            buttonType: ButtonType.TEXT,
                            buttonColorType: ButtonColorType.SUCCESS,
                            showLoader: true,
                            function: (buttonRef: IButtonGeneratorInput) => {
                                this.fetchPurchaseSavingsPlanStepForm(
                                    (response) => {
                                        this.parseAndOpenPurchaseV1Modal(
                                            response,
                                            rowData.data
                                        );
                                    },
                                    buttonRef
                                );
                            }
                        });
                    }
                    const buttonOptions: IMultiButtonOption = {
                        layout: {
                            justifyContent: 'space-evenly'
                        }
                    };
                    rowData['buttonGenInputs'] = buttons;
                    rowData['options'] = buttonOptions;
                    return rowData;
                }
            });
        }

        this.tableInput = {
            listExtraction: {
                type: 'DIRECT'
            },
            tableHeight: 300,
            columns: [...columnsDef, ...actionColumn]
        };
    }

    setUpBasics() {
        this.widgetRef.showViewIcon.next(true);

        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set([ViewType.CARDS]);
        this.widgetRef.visibleSections.next(visibleSections);
        if (
            this.widgetRef.widgetConfigDetails &&
            this.widgetRef.widgetConfigDetails.visibleSections
        ) {
            this.widgetRef.visibleSections.next(
                this.widgetRef.widgetConfigDetails.visibleSections
            );
        }
        // Setting Lite State
        if (this.widgetRef.lightState) {
            this.showSearchBox = false;
        }
    }

    bindData(data) {
        if (this.widgetRef.widgetData.widgetInfo.portlet.version === '2.0') {
            this.isV2 = true;
        }
        if (data === null) {
            return;
        }
        this.widgetRef.apiResponse = data;
        this.dataMap = data.dataMap;
        this.enableFix = this.dataMap['enableFix'];
        this.potentialSavings = this.dataMap['potentialSavings'];
        this.recommendationCount = this.dataMap['recommendationCount'];
        // for view modal
        this.cardsData =
            data.dataList && data.dataList.length ? data.dataList : [];
        this.cardsData.forEach((card) => {
            this.cardButtons.push({
                buttonName: 'Purchase',
                buttonColorType: ButtonColorType.INFO,
                buttonType: ButtonType.TEXT,
                showLoader: true,
                function: null
            });
        });
        if (this.cardsData && this.cardsData.length === 0 && !data.message) {
            this.widgetRef.extraMessage.next(Messages.NO_DATA_AVAILABLE);
            this.widgetRef.visibleSections.next(new Set());
            this.widgetRef.endLoader();
            return;
        }
        if (this.cardsData && !this.cardsData.length) {
            this.widgetRef.widgetActions.next([]);
        }
        if ('message' in data && data.message) {
            this.widgetRef.extraMessage.next(data.message);
            this.widgetRef.endLoader();
            return;
        }
        if (this.widgetRef.visibleSections.value.size === 0) {
            this.widgetRef.visibleSections.next(new Set([ViewType.CARDS]));
        }
        this.filteredCardsData = this.cardsData;
        this.tableData = this.cardsData;
        this.generateTableView(data);
        const widgetActions: IAction[] = [
            this.widgetRef.widgetConfigState.actions.card
        ];
        this.widgetRef.widgetActions.next(widgetActions);
        this.recommendationCount = {
            buttonName: `Recommendation Count :${this.cardsData.length}`,
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.INFO,
            preventHoverEffect: true,
            disableRipple: true,
            hoverText: `Recommendation Count :${this.cardsData.length}`,
            function: () => {}
        };
        this.widgetRef.endLoader();
    }

    showRiModal(card) {
        const toggleOnIcon: IIcon = {
            type: IconType.FONTAWSOME,
            class: 'fas fa-toggle-on',
            extraClass: 'switch'
        };
        const modalData: IModalData = {
            noHeader: true,
            modalName: 'Recommendation',
            modalIcon: toggleOnIcon,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.SIDE,
            modalWidthVw: 75,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad:
                            SavingsPlanRecommendationModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                item: card,
                                dataMap: this.dataMap
                            }
                        }
                    },
                    stepName: ''
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    showRiModalV2(cardItemData) {
        const modalData: IModalData = {
            noHeader: true,
            modalName: 'Recommendation',
            modalIcon: null,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.SIDE,
            modalWidthVw: 75,
            noStepPadding: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad:
                            SavingsPlanRecommendationModalV2Component,
                        payload: {
                            data: {
                                itemData: cardItemData,
                                dataMap: this.dataMap
                            }
                        }
                    },
                    stepName: ''
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    fetchPurchaseSavingsPlanStepForm(
        onSuccess,
        buttonRef?: IButtonGeneratorInput,
        index?: number
    ) {
        const apiInfo = this.widgetRef.widgetData.widgetInfo['purchaseDoc'];
        if (apiInfo) {
            if (buttonRef) {
                buttonRef.loader = true;
            }
            if (index !== undefined) {
                this.cardPurDocLoaderIndex = index;
            }
            const apiArgs = Helper.generateHitApiConfig(apiInfo);
            apiArgs.input = {};
            apiArgs.function = (response) => {
                onSuccess(response);
            };
            apiArgs.errorFunction = (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    'Error in fetching form'
                );
            };
            apiArgs.endFunction = () => {
                if (buttonRef) {
                    buttonRef.loader = false;
                }
                if (index !== undefined) {
                    this.cardPurDocLoaderIndex = -1;
                    this.widgetRef.changeDetectorRef.detectChanges();
                }
            };
            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }

    parseAndOpenPurchaseModal(rawStepFormData, cardItemData) {
        if (rawStepFormData) {
            const modalId = Symbol();
            const modalData = Helper.generateMultiStepFormGenModalData(
                {
                    modalName: 'Purchase Compute Savings Plan',
                    sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
                    modalId: modalId
                },
                rawStepFormData,
                (
                    stepNumber,
                    backendFormGenInput,
                    prevStepData,
                    currStepData
                ) => {
                    const commitmentKey = 'commitment';
                    const formGenInput = Helper.formGeneratorBtoFParser(
                        backendFormGenInput,
                        [cardItemData],
                        this.widgetRef.notificationsService,
                        this.widgetRef.httpService,
                        this.widgetRef.changeDetectorRef,
                        {
                            accountId:
                                cardItemData &&
                                cardItemData.description &&
                                cardItemData.description['Account ID']
                                    ? cardItemData.description['Account ID']
                                    : cardItemData.generalInformation &&
                                      cardItemData.generalInformation[
                                          'Account No'
                                      ]
                                    ? cardItemData.generalInformation[
                                          'Account No'
                                      ]
                                    : null
                        },
                        () => {
                            this.modalService.closeModal(null, modalId);
                        },
                        (formGroup: FormGroup) => {
                            if (formGroup) {
                                return {
                                    [commitmentKey]: this.getCostInDollars(
                                        formGroup.get(commitmentKey).value
                                    )
                                };
                            }
                            return null;
                        },
                        prevStepData && prevStepData.formGroupRef
                            ? prevStepData.formGroupRef.getRawValue()
                            : null,
                        (input) => {
                            delete input.totalCost;
                            return input;
                        }
                    );
                    if (this.dataMap && this.dataMap['currencySymbol']) {
                        if (
                            formGenInput &&
                            formGenInput.fields &&
                            formGenInput.fields.length
                        ) {
                            formGenInput.fields.forEach((field) => {
                                if (field.name === commitmentKey) {
                                    field.label += ` (${this.dataMap['currencySymbol']}/Hr)`;
                                    const minValue =
                                        this.getMinCostAsPerCurrency();
                                    const minValueValidator: IValidator = {
                                        errorMessage: `Value should be more than ${minValue}`,
                                        validator:
                                            CustomValidators.minValue(minValue)
                                    };
                                    if (
                                        field.validations &&
                                        field.validations.length
                                    ) {
                                        field.validations.push(
                                            minValueValidator
                                        );
                                    } else {
                                        field.validations = [minValueValidator];
                                    }
                                }
                            });
                        }
                    }
                    if (
                        stepNumber === 2 &&
                        prevStepData &&
                        prevStepData.formGroupRef
                    ) {
                        const paymentTypeKey = 'paymentType';
                        const paymentType =
                            prevStepData.formGroupRef.get(paymentTypeKey).value;
                        const offeringTermKey = 'offeringTerm';
                        const offeringTerm =
                            prevStepData.formGroupRef.get(
                                offeringTermKey
                            ).value;
                        const commPerHr =
                            prevStepData.formGroupRef.get(commitmentKey).value;

                        if (prevStepData.formGenInput) {
                            const paymentTypeValue =
                                prevStepData.formGenInput.fields
                                    .find(
                                        (field) => field.name === paymentTypeKey
                                    )
                                    .listData.find(
                                        (listDataItem) =>
                                            listDataItem.id === paymentType
                                    ).label;
                            const offeringTermsValue =
                                prevStepData.formGenInput.fields
                                    .find(
                                        (field) =>
                                            field.name === offeringTermKey
                                    )
                                    .listData.find(
                                        (listDataItem) =>
                                            listDataItem.id === offeringTerm
                                    ).label;
                            formGenInput.fields.forEach((field) => {
                                field.extraClass = 'savings-plan-content-field';
                                if (
                                    field.populateFromFormData ===
                                    paymentTypeKey
                                ) {
                                    field.contentDisplayValue =
                                        paymentTypeValue;
                                }
                                if (
                                    field.populateFromFormData ===
                                    offeringTermKey
                                ) {
                                    field.contentDisplayValue =
                                        offeringTermsValue;
                                }
                            });
                        }
                        let totalCost = commPerHr * 24 * 365;
                        if (offeringTerm.includes('3')) {
                            totalCost *= 3;
                        }
                        totalCost = Number(Helper.roundOff(totalCost, 2));
                        formGenInput.fields.push({
                            label: 'Total Cost',
                            fieldType: FilterType.CONTENT,
                            name: 'totalCost',
                            placeholder: null,
                            extraClass: 'savings-plan-content-field',
                            value:
                                this.dataMap && this.dataMap['currencySymbol']
                                    ? this.dataMap['currencySymbol'] +
                                      ' ' +
                                      totalCost
                                    : totalCost
                        });
                    }
                    return formGenInput;
                },
                null,
                null,
                (stepNumber) => stepNumber === 1
            );
            this.modalService.openModal(modalData);
        }
    }

    parseAndOpenPurchaseV1Modal(rawStepFormData, cardItemData) {
        if (rawStepFormData) {
            const modalId = Symbol();
            const modalData = Helper.generateMultiStepFormGenModalData(
                {
                    modalName: 'Purchase Savings Plan',
                    sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
                    modalId: modalId
                },
                rawStepFormData,
                (
                    stepNumber,
                    backendFormGenInput,
                    prevStepData,
                    currStepData
                ) => {
                    const commitmentKey = 'commitment';
                    const paymentTypeKey = 'paymentType';
                    const initialInput = {};
                    if (prevStepData && prevStepData.formGroupRef) {
                        initialInput[paymentTypeKey] =
                            prevStepData.formGroupRef.get(paymentTypeKey)
                                ? prevStepData.formGroupRef.get(paymentTypeKey)
                                      .value
                                : null;
                    }
                    const formGenInput = Helper.formGeneratorBtoFParser(
                        backendFormGenInput,
                        [cardItemData],
                        this.widgetRef.notificationsService,
                        this.widgetRef.httpService,
                        this.widgetRef.changeDetectorRef,
                        {
                            accountId: cardItemData.displayEntry['Account No'],
                            ...initialInput
                        },
                        () => {
                            this.modalService.closeModal(null, modalId);
                        },
                        null,
                        prevStepData && prevStepData.formGroupRef
                            ? prevStepData.formGroupRef.getRawValue()
                            : null,
                        (input) => {
                            delete input.totalCost;
                            return input;
                        }
                    );
                    if (this.dataMap && this.dataMap['currencySymbol']) {
                        if (
                            formGenInput &&
                            formGenInput.fields &&
                            formGenInput.fields.length
                        ) {
                            formGenInput.fields.forEach((field) => {
                                if (field.name === commitmentKey) {
                                    field.label += ` (${this.dataMap['currencySymbol']}/Hr)`;
                                }
                            });
                        }
                    }
                    if (
                        stepNumber === 2 &&
                        prevStepData &&
                        prevStepData.formGroupRef
                    ) {
                        const offeringTermKey = 'offeringTerm';
                        const regionKey = 'region';
                        const instanceKey = 'instanceFamily';
                        const getLabelFromList = (formFieldName) => {
                            if (!prevStepData.formGroupRef.get(formFieldName)) {
                                return null;
                            }
                            const formValue =
                                prevStepData.formGroupRef.get(
                                    formFieldName
                                ).value;
                            const formField =
                                prevStepData.formGenInput.fields.find(
                                    (field) => field.name === formFieldName
                                );
                            if (formField && formField.listData) {
                                const labelValue = formField.listData.find(
                                    (listDataItem) =>
                                        listDataItem.id === formValue
                                );
                                return labelValue.label
                                    ? labelValue.label
                                    : formValue;
                            }
                            return formValue;
                        };
                        const commPerHr =
                            prevStepData.formGroupRef.get(commitmentKey).value;

                        const offeringTermsValue =
                            getLabelFromList(offeringTermKey);
                        const paymentTypeValue =
                            getLabelFromList(paymentTypeKey);
                        const regionValue = getLabelFromList(regionKey);
                        const instanceValue = getLabelFromList(instanceKey);
                        if (prevStepData.formGenInput) {
                            formGenInput.fields.forEach((field) => {
                                field.extraClass = 'savings-plan-content-field';
                                if (
                                    field.populateFromFormData ===
                                    offeringTermKey
                                ) {
                                    field.contentDisplayValue =
                                        offeringTermsValue;
                                }
                                if (
                                    paymentTypeKey &&
                                    field.populateFromFormData ===
                                        paymentTypeKey
                                ) {
                                    field.contentDisplayValue =
                                        paymentTypeValue;
                                }
                                if (field.populateFromFormData === regionKey) {
                                    field.contentDisplayValue = regionValue;
                                }
                                if (
                                    field.populateFromFormData === instanceKey
                                ) {
                                    field.contentDisplayValue = instanceValue;
                                }
                            });
                        }
                        let totalCost = commPerHr * 24 * 365;
                        if (offeringTermsValue.includes('3')) {
                            totalCost *= 3;
                        }
                        totalCost = Number(Helper.roundOff(totalCost, 2));
                        formGenInput.fields.push({
                            label: 'Total Cost',
                            fieldType: FilterType.CONTENT,
                            name: 'totalCost',
                            placeholder: null,
                            extraClass: 'savings-plan-content-field',
                            value:
                                this.dataMap && this.dataMap['currencySymbol']
                                    ? this.dataMap['currencySymbol'] +
                                      ' ' +
                                      totalCost
                                    : totalCost
                        });
                    }
                    return formGenInput;
                },
                null,
                null,
                (stepNumber) => stepNumber === 1
            );
            this.modalService.openModal(modalData);
        }
    }

    openPurchaseModalViaCard(data, index?: number) {
        this.fetchPurchaseSavingsPlanStepForm(
            (response) => {
                if (this.isV2) {
                    this.parseAndOpenPurchaseModal(response, data);
                } else {
                    this.parseAndOpenPurchaseV1Modal(response, data);
                }
            },
            null,
            index
        );
    }
    getMinCostAsPerCurrency() {
        const minCostInDollars = 0.001;
        const exchangeRate =
            this.dataMap && this.dataMap['exchangeRate']
                ? this.dataMap['exchangeRate']
                : 1;
        if (exchangeRate < 1) {
            return (
                Math.round(minCostInDollars * exchangeRate * 100000) / 100000
            );
        } else {
            return Math.round(minCostInDollars * exchangeRate * 1000) / 1000;
        }
    }

    getCostInDollars(costInCurrency) {
        const exchangeRate =
            this.dataMap && this.dataMap['exchangeRate']
                ? this.dataMap['exchangeRate']
                : 1;
        try {
            return Math.round((costInCurrency / exchangeRate) * 1000) / 1000;
        } catch (error) {
            return 0;
        }
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
