import { Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { HitApi } from './../../../classes/HitApi';
import { IButtonGeneratorInput } from './../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-widget-catalog-tags-modal',
    templateUrl: './widget-catalog-tags-modal.component.html',
    styleUrls: ['./widget-catalog-tags-modal.component.sass']
})
export class WidgetCatalogTagsModalComponent implements OnInit {
    selectedWidgetsData = null;
    allWidgetsData = null;
    tagsAssigned = [];
    tagsData = [];
    tagsToBeDeleted = [];
    commonTags = [];
    widgetRef: Widget;
    checkedWidgets = [];
    buttonInputs: IButtonGeneratorInput[];
    keyNames = {};
    keysToGetTagsData;
    constructor(
        private modalData: ModalInjectedData,
        private modalService: ModalService
    ) {
        this.keyNames = modalData.data.keyNames;
        this.selectedWidgetsData =
            modalData.data[this.keyNames['selectedData']];

        this.checkedWidgets = [...this.selectedWidgetsData];
        this.allWidgetsData = modalData.data[this.keyNames['allRowsData']];
        this.widgetRef = modalData.data['widgetRef'];
    }

    ngOnInit(): void {
        this.keysToGetTagsData = this.keyNames['keysToGetTagData'].length
            ? this.keyNames['keysToGetTagData'].split('|')
            : null;

        this.allWidgetsData.forEach((widgetData) => {
            if (this.keysToGetTagsData) {
                const data = Helper.extractDataFromObject(
                    this.keyNames['keysToGetTagData'],
                    widgetData
                );

                this.tagsData.push(...data);
            } else {
                this.tagsData.push(widgetData);
            }
        });

        this.tagsData = Helper.removeDuplicateObjetsFromArray(this.tagsData);

        this.findCommonTags();
        this.buttonInputs = [
            {
                buttonName: 'Cancel',
                buttonColorType: ButtonColorType.PRIMARY,
                function: () => {
                    this.closeModal();
                },
                buttonType: ButtonType.FLAT
            },
            {
                buttonName: 'Save',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.save(buttonRef);
                },
                showLoader: true
            }
        ];
    }

    onCheckBoxChanged(event, widget) {
        if (event.checked) {
            this.checkedWidgets.push(widget);
        } else {
            const index = this.checkedWidgets.findIndex(
                (widgetData) =>
                    widgetData[this.keyNames['id']] ===
                    widget[this.keyNames['id']]
            );

            this.checkedWidgets.splice(index, 1);
        }
    }

    checkIfChecked(widgetData) {
        let checked = false;
        this.checkedWidgets.forEach((widgets) => {
            if (
                widgets[this.keyNames['id']] &&
                widgets[this.keyNames['id']] === widgetData[this.keyNames['id']]
            ) {
                checked = true;
            }
        });

        return checked;
    }

    findCommonTags() {
        const commonTagsData = [];

        this.checkedWidgets.forEach((widget) => {
            if (this.keysToGetTagsData) {
                const data = Helper.extractDataFromObject(
                    this.keyNames['keysToGetTagData'],
                    widget
                );
                commonTagsData.push(...data);
            } else {
                commonTagsData.push(...widget[this.keyNames['tagsKeyName']]);
            }
        });

        const commonTagsFrequency = commonTagsData.reduce((acc, obj) => {
            if (acc.hasOwnProperty(obj.name)) {
                acc[obj.name]++;
            } else {
                acc[obj.name] = 1;
            }
            return acc;
        }, {});
        const commonTagsKeys = Object.keys(commonTagsFrequency).filter(
            (key) => commonTagsFrequency[key] === this.checkedWidgets.length
        );
        this.checkedWidgets.forEach((widget) => {
            const allTagsData = this.keysToGetTagsData
                ? Helper.extractDataFromObject(
                      this.keyNames['keysToGetTagData'],
                      widget
                  )
                : widget[this.keyNames['tagsKeyName']];

            allTagsData.forEach((tagsData) => {
                if (
                    commonTagsKeys.includes(tagsData.name) &&
                    !this.commonTags.find(
                        (tagObj) => tagObj.name === tagsData.name
                    )
                ) {
                    this.commonTags.push(tagsData);
                }
            });
        });
    }

    closeModal() {
        this.modalService.closeModal(null, this.modalData.modalId);
    }

    save(buttonRef: IButtonGeneratorInput) {
        const finalTagsData = [];

        const idKeyName = this.keyNames['payloadIdName'];

        if (!this.checkedWidgets.length) {
            this.widgetRef.notificationsService.showSnackBar(
                `Select atleast 1 ${this.keyNames['message']}`,
                true
            );
        } else if (!this.tagsAssigned.length && !this.tagsToBeDeleted.length) {
            this.widgetRef.notificationsService.showSnackBar(
                'Apply atleast 1 tag',
                true
            );
        } else {
            this.checkedWidgets.forEach((widgetData) => {
                let tags;
                if (this.keysToGetTagsData) {
                    const data = Helper.extractDataFromObject(
                        this.keyNames['keysToGetTagData'],
                        widgetData
                    );
                    tags = [...data, ...this.tagsAssigned];
                } else {
                    tags = [
                        ...widgetData[this.keyNames['tagsKeyName']],
                        ...this.tagsAssigned
                    ];
                }
                tags = Helper.removeDuplicateObjetsFromArray(tags);
                if (this.tagsToBeDeleted.length) {
                    this.tagsToBeDeleted.forEach((tagToBeDeleted) => {
                        const tagsIndex = tags.findIndex(
                            (tag) => tag.name === tagToBeDeleted
                        );

                        if (tagsIndex > -1) {
                            tags = Helper.removeIndexfromArray(tagsIndex, tags);
                        }
                    });
                }
                finalTagsData.push({
                    [idKeyName]: widgetData[this.keyNames['id']],
                    tags
                });
            });
            const apiDetails = Helper.extractDataFromObject(
                this.keyNames['apiInfoKey'],
                this.widgetRef.widgetData.widgetInfo
            );

            const apiArgs = Helper.generateHitApiConfig(apiDetails);
            apiArgs.input = finalTagsData;
            buttonRef.loader = true;
            apiArgs.function = (response) => {
                buttonRef.loader = false;
                this.widgetRef.notificationsService.showSnackBar(
                    'Tags updated successfully.'
                );
                this.widgetRef.refreshWidget();
                this.modalService.closeModal(null, this.modalData.modalId);
            };
            apiArgs.errorFunction = (error) => {
                buttonRef.loader = false;
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error
                );
            };
            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }

    updateTags($event) {
        this.tagsAssigned = $event['tagsData'];
        if ($event['tagToBeDeleted']) {
            this.tagsToBeDeleted.push($event['tagToBeDeleted']);
        }
    }
}
