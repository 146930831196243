<div class="compare-view-insights">
    <div class="one">
        <div class="tools comparisonOn" *ngIf="compareViewEnabled">
            <div
                [style.visibility]="
                    compareViewEnabled && !sidenav.opened ? 'visible' : 'hidden'
                "
                matTooltip="Change Widget"
                appIcon
                [data]="sidenav.opened ? '' : widgetIcon"
                class="item line magicFont tool clickable"
                (click)="changeWidget(sidenav)"
            ></div>

            <div
                *ngIf="compareViewEnabled"
                matTooltip="Toggle Filter"
                appIcon
                [data]="filterIcon"
                (click)="filterMenu.toggle()"
                class="filters-collapser item line magicFont tool clickable"
                [class.d-none]="filterMenu.opened"
            ></div>
        </div>
    </div>
    <div
        class="date-filter-container"
        [style.display]="showDateOnTop ? 'flex' : 'none'"
    >
        <!-- <app-date-filter [startDateTime]="startDateTime" [endDateTime]="endDateTime" [minDate]="minDate" [maxDate]="maxDate"
        [startDateValue]="startDateValue" [endDateValue]="endDateValue" [applyDateAndRefresh]="applyDateAndRefresh">
      </app-date-filter> -->
    </div>
    <div class="two">
        <mat-sidenav-container [autosize]="true" class="link-insight">
            <mat-sidenav
                class="sidenav"
                #filterMenu
                mode="over"
                position="end"
                [autoFocus]="false"
            >
                <app-filters
                    [filterMenu]="filterMenu"
                    [filterStoreKey]="filterStoreKey"
                    [isCompareView]="true"
                ></app-filters>
            </mat-sidenav>
            <mat-sidenav
                class="sidenav-menu"
                #sidenav
                mode="over"
                [autoFocus]="false"
            >
                <div class="compare-view-side-menu">
                    <app-n-level-traversal-menu-view
                        [traversalPurpose]="
                            TraversalPurpose.COMPARE_SIDE_MENU_1
                        "
                        [firstLevelData]="
                            globalDataService.sideMenuStepOne.attributes
                        "
                        [sidenav]="sidenav"
                        (leafNodesData)="handleLeafNode($event)"
                    >
                    </app-n-level-traversal-menu-view>
                    <div
                        class="widget-Id"
                        *ngIf="showThirdLevel"
                        [ngClass]="
                            whiteLabelingService.whiteLableData.isCentilytics
                                ? sideMenuType === SideMenuType.DETAILED
                                    ? 'detailed-menu-background-gradient'
                                    : 'icon-menu-background-gradient'
                                : sideMenuType === SideMenuType.DETAILED
                                ? 'detailed-whitelabeling-background'
                                : 'icon-whitelabeling-background'
                        "
                    >
                        <ng-container *ngIf="widgetIDs && widgetIDs.length">
                            <div class="bread-crumbs">
                                <span class="level-back">
                                    <span class="back-level-name">
                                        {{ lastLevelName }}
                                    </span>
                                </span>
                            </div>
                            <div class="menu-list">
                                <ng-container *ngFor="let id of widgetIDs">
                                    <a
                                        (click)="widgetSelection(id, sidenav)"
                                        [class.active]="id.id === widgetId"
                                        class="group-icon"
                                    >
                                        <span class="item-desc">
                                            <span class="text">
                                                {{ id.name }}
                                            </span>
                                        </span>
                                    </a>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container
                            *ngIf="
                                !widgetIDs || (widgetIDs && !widgetIDs.length)
                            "
                        >
                            <div
                                class="overlay"
                                [ngClass]="
                                    whiteLabelingService.whiteLableData
                                        .isCentilytics
                                        ? sideMenuType === SideMenuType.DETAILED
                                            ? 'detailed-menu-background-gradient'
                                            : 'icon-menu-background-gradient'
                                        : sideMenuType === SideMenuType.DETAILED
                                        ? 'detailed-whitelabeling-background'
                                        : 'icon-whitelabeling-background'
                                "
                            >
                                <div class="overlay-content">
                                    <div
                                        appIcon
                                        [data]="spinnerLoader"
                                        class="overlay-loader"
                                    ></div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </mat-sidenav>
            <mat-sidenav-content class="sidenav-content">
                <!-- <div class="link-insight-modal" [style.display]="!showInsightModal ? 'none':'flex'">
            <div class="insight-modal">
              <ng-template appComponentLoader></ng-template>
            </div>
          </div> -->
                <div
                    class="link-insight-generator"
                    [class.comparisionOn]="compareViewEnabled"
                >
                    <ng-container *ngIf="widgetData.has(widgetId)">
                        <app-widget-generator
                            [data]="widgetData.get(widgetId)"
                            [linkingData]="linkingData"
                            [dataStoreKey]="filterStoreKey"
                            [widgetConfig]="widgetConfig"
                        ></app-widget-generator>
                    </ng-container>
                    <div
                        class="overlay"
                        style="background-color: white"
                        *ngIf="!widgetData.has(widgetId)"
                    >
                        <div class="overlay-content">
                            <div
                                appIcon
                                [data]="spinnerLoader"
                                class="overlay-loader"
                            ></div>
                        </div>
                    </div>
                </div>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
</div>
