import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IconType } from 'src/app/shared/enums/IconType';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IWidgetData } from 'src/app/shared/interfaces/widget/IWidgetData';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { WidgetHttpService } from 'src/app/shared/services/http/widget-http/widget-http.service';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-widget-testing',
    templateUrl: './widget-testing.component.html',
    styleUrls: ['./widget-testing.component.sass']
})
export class WidgetTestingComponent implements OnInit {
    @Input() widgetId: string;
    widgetSubs = new SubSink();
    widgetData: Map<string, IWidgetData> = new Map<string, IWidgetData>();
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    showInsight = false;

    constructor(
        private globalDataService: GlobalDataService,
        private widgetHttpService: WidgetHttpService,
        private ref: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        if (this.widgetId && this.widgetId.length) {
            this.widgetHttpService.loadWidgetsData(
                this.widgetHttpService,
                this.widgetSubs,
                this.widgetId,
                this.widgetData,
                this.openWidget.bind(this),
                true
            );
        }
    }

    openWidget() {
        if (
            this.widgetData.has(this.widgetId) &&
            this.widgetData.get(this.widgetId)
        ) {
            this.showInsight = true;
            this.ref.detectChanges();
        } else {
        }
    }

    closeTesting() {
        this.globalDataService.testingActionsObs.next(null);
    }
}
