import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class RequiredTrueValidator extends CustomValidator {
    key = CustomValidatorKey.REQUIRED_TRUE;
    validatorFunction(control: AbstractControl): ValidationErrors {
        return Validators.requiredTrue(control);
    }
}
