<div *ngIf="emailTemplateConfiguredFor" class="email-template-modal">
	<div [ngClass]="isMacrosDisabled ? 'macros disabled' : 'macros'">
		<div class="tabs-container">
			<div class="macros-list-container" cdkVirtualScrollingElement>
				<div class="macros-list-heading">Macros list</div>
				<app-table-search
					[searchFunction]="onQuickFilterChanged.bind(this)"
                    class="macros-search-box"
				>
				</app-table-search>
				<cdk-virtual-scroll-viewport [itemSize]="filteredMacros.length">
					<div
						*cdkVirtualFor="let macro of filteredMacros"
						class="macros-list"
					>
						<div
							class="macros-button-text"
							(click)="
								!this.isMacrosDisabled ? macrosSelected(macro.id) : null
							"
						>
							<span class="macros-text">{{ macro.label }}</span>
							<span class="macros-icon" appIcon [data]="plusIcon"></span>
						</div>
					</div>
				</cdk-virtual-scroll-viewport>
			</div>
		</div>
	</div>
	<div class="email-template-form">
		<app-form-generator
			*ngIf="emailTemplateFormGenInput"
			[formGeneratorInput]="emailTemplateFormGenInput"
			(formGroupRef)="emailTemplateFormGroup = $event"
		>
		</app-form-generator>
		<div class="button">
			<app-button-generator
				[buttonGenInput]="saveEmailTemplateButtonGenInput"
			></app-button-generator>
		</div>
	</div>
</div>

<div *ngIf="!emailTemplateConfiguredFor" class="select-template-type-container">
    <div
        class="button"
        mat-ripple
        (click)="
            selectEmailTemplateTarget(
                EmailTemplateConfiguredForEnum.SIGNUPCONFIG
            )
        "
    >
        Sign up Page
    </div>
    <div
        class="button"
        mat-ripple
        (click)="
            selectEmailTemplateTarget(
                EmailTemplateConfiguredForEnum.ASSESSMENT
            )
        "
    >
        Assessment
    </div>
</div>
