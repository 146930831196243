import {
    Directive,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output,
    Renderer2
} from '@angular/core';

@Directive({
    selector: '[appDragDropFileSelect]'
})
export class DragDropFileSelectDirective implements OnInit {
    @Input('mimeTypes') mimeTypes: string[];
    @Output('onChange') filesChangeEmiter: EventEmitter<File> =
        new EventEmitter();
    @Output('onInvalidSelection') filesInvalidEmiter: EventEmitter<string> =
        new EventEmitter();

    @HostBinding('class.drag-over') isDraggedOver = false;

    inputElement;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngOnInit(): void {
        this.injectInputElement();
    }

    injectInputElement() {
        if (this.elementRef && this.elementRef.nativeElement) {
            let mimeType = '*/*';
            if (this.mimeTypes && this.mimeTypes.length) {
                mimeType = this.mimeTypes.join(', ');
            }
            this.inputElement = this.renderer.createElement('input');
            this.renderer.setStyle(this.inputElement, 'display', 'none');
            this.renderer.setAttribute(this.inputElement, 'type', 'file');
            this.renderer.setAttribute(this.inputElement, 'accept', mimeType);
            this.renderer.listen(this.inputElement, 'change', (event) => {
                if (
                    event &&
                    event.target &&
                    event.target.files &&
                    event.target.files.length
                ) {
                    this.validateAndEmitFile(event.target.files);
                }
            });
            this.elementRef.nativeElement.appendChild(this.inputElement);
        }
    }

    @HostListener('dragover', ['$event']) public onDragOver(event) {
        event.preventDefault();
        event.stopPropagation();
        this.isDraggedOver = true;
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(event) {
        event.preventDefault();
        event.stopPropagation();
        this.isDraggedOver = false;
    }

    @HostListener('drop', ['$event']) public onDrop(event) {
        event.preventDefault();
        event.stopPropagation();
        this.isDraggedOver = false;
        const files = event.dataTransfer.files;
        this.validateAndEmitFile(files);
    }

    @HostListener('click', ['$event']) public onClick(event) {
        event.stopPropagation();
        if (this.inputElement) {
            this.inputElement.click();
        }
    }

    private validateFiles(file) {
        if (this.mimeTypes && this.mimeTypes.length) {
            return this.mimeTypes.includes(file.type);
        }
        return true;
    }

    private validateAndEmitFile(files) {
        if (files.length === 1) {
            const valid_file: File = files[0];
            if (this.validateFiles(valid_file)) {
                this.filesChangeEmiter.emit(valid_file);
            } else {
                this.filesInvalidEmiter.emit('Invalid Files');
            }
        } else {
            this.filesInvalidEmiter.emit('Only 1 File Allowed');
        }
    }
}
