import { Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { FormState } from './../../../../../enums/FormState';
import { IButtonGeneratorInput } from './../../../../../interfaces/button-generator/IButtonGeneratorInput';
import { MultiStepFormService } from './../../../../../services/modal/multi-step-form/multi-step-form.service';

@Component({
    selector: 'app-freshservice-integration-step-three',
    templateUrl: './freshservice-integration-step-three.component.html',
    styleUrls: ['./freshservice-integration-step-three.component.sass']
})
export class FreshserviceIntegrationStepThreeComponent implements OnInit {
    filterInfo: IFilterInfo;
    loader = false;
    selectedCustomers = [];
    widgetRef;
    edit = false;
    firstStepData;
    secondStepData;
    buttonInputs: IButtonGeneratorInput[];
    constructor(
        private modalInputData: ModalInjectedData,
        private listHttpService: ListHttpService,
        private multiStepFormService: MultiStepFormService
    ) {
        if (
            this.modalInputData &&
            this.modalInputData.data &&
            this.modalInputData.data.edit
        ) {
            this.edit = true;
            if (this.modalInputData.data.response['selectedTenants']) {
                this.selectedCustomers =
                    this.modalInputData.data.response['selectedTenants'];
            }
        }
        this.widgetRef = this.modalInputData.data.widgetRef;
        this.firstStepData = multiStepFormService.stepData
            .get(modalInputData.modalId)
            .get(1);
        this.secondStepData = multiStepFormService.stepData
            .get(modalInputData.modalId)
            .get(2);
    }

    ngOnInit(): void {
        this.filterInfo = {
            placeholder: 'Select Customer Name',
            label: '',
            lightMode: true,
            apiInfo: this.listHttpService.listFreshserviceChildCustomers(
                `${this.firstStepData.configurationName}/${
                    this.edit ? FormState.EDIT : 'ADD'
                }`
            ),
            getKey: null
        };
        this.buttonInputs = [
            {
                buttonName: 'Back',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED,
                function: () => {
                    this.back();
                }
            },
            {
                buttonName: 'Save',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED,
                function: () => {
                    this.save();
                }
            }
        ];
    }

    back() {
        this.multiStepFormService.previousStep(this.modalInputData.modalId);
    }

    prepareInputs() {
        const input = this.firstStepData;
        const mandatoryFields = [];
        const optionalFields = [];
        const customFields = [];
        this.secondStepData['mandatoryFields'].forEach((field) => {
            mandatoryFields.push(field.id);
        });
        this.secondStepData['otherFields'].forEach((otherField) => {
            const optionalField = this.secondStepData['optionalFields'].find(
                (element) => element.id === otherField.id
            );
            if (optionalField) {
                optionalFields.push(optionalField.id);
            }

            const customField = this.secondStepData['customFields'].find(
                (element) => element.id === otherField.id
            );
            if (customField) {
                customFields.push(customField.id);
            }
        });
        input['selectedFields'] = {
            mandatoryFields: mandatoryFields,
            optionalFields: optionalFields,
            customFields: customFields
        };
        input['tenantIds'] = this.selectedCustomers;

        return input;
    }

    save() {
        this.loader = true;
        const apiArgs = Helper.generateHitApiConfig(
            this.edit
                ? this.widgetRef.widgetData.widgetInfo.update
                : this.widgetRef.widgetData.widgetInfo.create
        );
        apiArgs.input = this.prepareInputs();
        apiArgs.function = (response) => {
            this.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
            this.widgetRef.notificationsService.showSnackBar(
                this.edit
                    ? 'Freshservice Updated Successfully.'
                    : 'Freshservice Integrated Successfully.'
            );
            this.widgetRef.refreshWidget();
            this.widgetRef.modalService.closeModal(
                null,
                this.modalInputData.modalId
            );
        };
        apiArgs.errorFunction = (error) => {
            this.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error performing action'
            );
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
