import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { HttpService } from '../../http/http-main/http.service';
import { AuditEmailConfigRuleItemType } from 'src/app/shared/interfaces/email-configuration/emailConfigurationTypes';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';

@Injectable({
    providedIn: 'root',
})
export class EmailConfigModalService {
    emailConfigRules: Map<string, AuditEmailConfigRuleItemType[]> = new Map();

    selectedRuleConfig: string = '';

    customUserRoles: Map<string, string[]> = new Map();

    constructor(private httpService: HttpService) {}

    setCustomUserRoles(fieldName: string, values?: string[]): void {
        this.customUserRoles.set(fieldName, values);
    }

    getCustomUserRoles(fieldName: string): string[] {
        return this.customUserRoles.get(fieldName);
    }

    getAllCustomUserRoles(): Map<string, string[]> {
        return this.customUserRoles;
    }

    public clearAllConfigData() {
        this.emailConfigRules.clear();
        this.selectedRuleConfig = '';
        this.customUserRoles.clear();
    }

    public addNewRulesInParallel(
        inputPayloadList: Omit<AuditEmailConfigRuleItemType, 'action'>[]
    ): Observable<void> {
        return new Observable((subscriber) => {
            if (
                !Array.isArray(inputPayloadList) ||
                inputPayloadList.length === 0
            ) {
                subscriber.next();
                subscriber.complete();
            }
            const totalApiListCount = inputPayloadList.length;
            let successCounts = 0;
            let faliureCounts = 0;
            const parallelApis: IHitApi[] = [];
            inputPayloadList.forEach((inputPayload) => {
                const apiArgs: IHitApi = {
                    url: ApiUrls.EMAIL_CONFIG_RULES,
                    requestType: RequestType.POST,
                    intactUrl: ApiUrls.EMAIL_CONFIG_RULES,
                    input: inputPayload,
                    uniqueIdentity: Symbol(),
                    config: {
                        authorization: AuthorizationType.BEARER_TOKEN,
                    },
                    function: () => {
                        successCounts++;
                        if (successCounts === totalApiListCount) {
                            subscriber.next();
                            subscriber.complete();
                        }
                    },
                    errorFunction: () => {
                        faliureCounts++;
                        if (
                            faliureCounts + successCounts ===
                            totalApiListCount
                        ) {
                            subscriber.error();
                            subscriber.complete();
                        }
                    },
                };
                parallelApis.push(apiArgs);
            });
            this.httpService.hitParallelApis(parallelApis);
        });
    }

    public updateRulesInParallel(
        inputPayloadList: Omit<AuditEmailConfigRuleItemType, 'action'>[]
    ): Observable<void> {
        return new Observable((subscriber) => {
            if (
                !Array.isArray(inputPayloadList) ||
                inputPayloadList.length === 0
            ) {
                subscriber.next();
                subscriber.complete();
            }
            const totalApiListCount = inputPayloadList.length;
            let successCounts = 0;
            let faliureCounts = 0;
            const parallelApis: IHitApi[] = [];
            inputPayloadList.forEach((inputPayload) => {
                const apiArgs: IHitApi = {
                    url: ApiUrls.CRUD_EMAIL_CONFIG_RULES,
                    intactUrl: ApiUrls.CRUD_EMAIL_CONFIG_RULES,
                    requestType: RequestType.PATCH,
                    input: inputPayload,
                    uniqueIdentity: Symbol(),
                    config: {
                        authorization: AuthorizationType.BEARER_TOKEN,
                    },
                    function: () => {
                        successCounts++;
                        if (successCounts === totalApiListCount) {
                            subscriber.next();
                            subscriber.complete();
                        }
                    },
                    errorFunction: () => {
                        faliureCounts++;
                        if (
                            faliureCounts + successCounts ===
                            totalApiListCount
                        ) {
                            subscriber.error();
                            subscriber.complete();
                        }
                    },
                };
                apiArgs.url = apiArgs.url.replace(
                    '{email_config_id}',
                    inputPayload['ruleId']
                );
                parallelApis.push(apiArgs);
            });
            this.httpService.hitParallelApis(parallelApis);
        });
    }

    public deleteRulesInParallel(ruleIdList: string[]): Observable<void> {
        return new Observable((subscriber) => {
            if (!Array.isArray(ruleIdList) || ruleIdList.length === 0) {
                subscriber.next();
                subscriber.complete();
            }
            const totalRuleIdListCount = ruleIdList.length;
            let successCounts = 0;
            let faliureCounts = 0;
            const parallelApis: IHitApi[] = [];
            ruleIdList.forEach((ruleId) => {
                const apiArgs: IHitApi = {
                    url: ApiUrls.CRUD_EMAIL_CONFIG_RULES,
                    intactUrl: ApiUrls.CRUD_EMAIL_CONFIG_RULES,
                    requestType: RequestType.DELETE,
                    input: {},
                    uniqueIdentity: Symbol(),
                    config: {
                        authorization: AuthorizationType.BEARER_TOKEN,
                    },
                    function: () => {
                        successCounts++;
                        if (successCounts === totalRuleIdListCount) {
                            subscriber.next();
                            subscriber.complete();
                        }
                    },
                    errorFunction: () => {
                        faliureCounts++;
                        if (
                            faliureCounts + successCounts ===
                            totalRuleIdListCount
                        ) {
                            subscriber.error();
                            subscriber.complete();
                        }
                    },
                };
                apiArgs.url = apiArgs.url.replace('{email_config_id}', ruleId);
                parallelApis.push(apiArgs);
            });
            this.httpService.hitParallelApis(parallelApis);
        });
    }
}

export enum EmailConfigurationUserRolesEnum {
    ASSESSOR = 'assessor',
    ASSESEE = 'assesee',
    PROGRAM_CORDINATOR = 'program_cordinator',
    CUSTOMER = 'customer',
}
