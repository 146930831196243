export enum AuditEmailConfigActionType {
    ADD = 'ADD',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
}

export enum AuditEmailConfigurationRuleType {
    DEFAULT = 'Default',
    CUSTOM = 'Custom',
}

//  "configuredFor"
export enum EmailTemplateConfiguredFor {
    ASSESSMENT = 'ASSESSMENT',
    SIGNUPCONFIG = 'SIGNUPCONFIG',
}

export interface AuditEmailConfigRuleItemType {
    ruleName: string;
    triggerPoints?: string[]; // skippeed in case of custom signup page config
    recipients: string[];
    carbonCopy: string[];
    blindCarbonCopy: string[];
    emailTemplateId: string;
    ruleId?: string;
    auditId?: string;
    type?: AuditEmailConfigurationRuleType;
    action?: AuditEmailConfigActionType; // only for FE use
}
