export const environment = {
    production: true,
    envName: 'dev',
    isSentryEnabled: true,
    sentry_dsn:
        'https://f7283f76cd02096ce6058cab4646cdeb@o4506060051513344.ingest.sentry.io/4506060058132480',
    baseUrl: 'https://api-dev.centilytics.com/v2',
    wsBaseUrl: 'wss://api-dev.centilytics.com/ws',
    mock: false, // set this to true to use mock server to develop a widget
    mockComponent: null // set this to the component to render in case of MOCK_WIDGET PortletType
};
