<form action="" class="form {{ extraClass }}" [formGroup]="formGroup">
    <div class="form-data tw-flex-1">
        <div class="message" style="display: flex" *ngIf="confirmationMessage">
            <div
                style="
                    margin-right: 8px;
                    font-size: 1.2rem;
                    margin-bottom: 15px;
                "
                appIcon
                [data]="contextIcon"
                *ngIf="contextIcon"
            ></div>
            <div class="message" [style.fontSize]="fontSize">
                {{ confirmationMessage }}
            </div>
        </div>

        <ng-container *ngIf="bodyTemplate">
            <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
        </ng-container>

        <ng-container *ngIf="reason">
            <mat-form-field appearance="outline">
                <mat-label>Reason</mat-label>
                <input
                    #reasonInput
                    matInput
                    type="text"
                    placeholder="Reason"
                    name="reason"
                    formControlName="reason"
                />
                <ng-container
                    *ngIf="
                        formGroup.get('reason').touched &&
                        formGroup.get('reason').errors
                    "
                >
                    <mat-error> This field is required </mat-error>
                </ng-container>
            </mat-form-field>
        </ng-container>
    </div>
    <div
        *ngIf="modalParams.hideButtons ? !modalParams.hideButtons : true"
        class="form-controls button-container"
    >
        <app-multi-button-generator
            [buttonGenInputs]="
                customButtonGeneratorInput
                    ? customButtonGeneratorInput.buttonGenInput
                    : buttonInputs
            "
            [options]="
                customButtonGeneratorInput
                    ? customButtonGeneratorInput.options
                    : null
            "
        >
        </app-multi-button-generator>
    </div>
</form>
