import { Component, Input, OnInit } from '@angular/core';
import { ButtonColorType } from '../../enums/ButtonColorType';
import { ButtonType } from '../../enums/ButtonType';
import { IconType } from '../../enums/IconType';
import { IIcon } from '../../interfaces/icon-data/IIcon';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { IButtonGeneratorInput } from './../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-object-creator',
    templateUrl: './object-creator.component.html',
    styleUrls: ['./object-creator.component.sass']
})
export class ObjectCreatorComponent implements OnInit {
    @Input() data;
    selectedList = [];
    jsonObject = {};
    objectKeys = Object.keys;
    objectValues = Object.values;
    tempObj: {};
    buttonInputs: IButtonGeneratorInput[];
    constructor(private notificationsService: NotificationsService) {}
    trashIcon: IIcon = {
        type: IconType.SVG,
        class: 'trash'
    };
    plusIcon: IIcon = {
        type: IconType.SVG,
        class: 'plus_filled'
    };

    ngOnInit() {
        if (Object.keys(this.data.inputData).length > 0) {
            Object.keys(this.data.inputData).forEach((key) => {
                this.jsonObject[key] = this.data.inputData[key];
                this.selectedList.push(key + '|' + this.data.inputData[key]);
            });
        } else {
            this.selectedList = [];
            this.jsonObject = {};
        }
        this.tempObj = JSON.parse(JSON.stringify(this.jsonObject));

        this.buttonInputs = [
            {
                buttonName: '',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonIcon: this.plusIcon,
                function: () => {},
                customClass: 'plus-icon',
                buttonType: ButtonType.ICON,
                preventHoverEffect: true
            },
            {
                buttonName: '',
                buttonColorType: ButtonColorType.WARN,
                customClass: 'trash-icon',
                function: () => {},
                buttonIcon: this.trashIcon,
                buttonType: ButtonType.ICON,
                preventHoverEffect: true
            }
        ];
    }

    deletePair(item, index) {
        delete this.jsonObject[item.split('|')[0]];
        this.selectedList.splice(index, 1);
        this.data.output.emit(this.jsonObject);
    }

    add(k, v) {
        const key = k.value;
        const value = v.value;
        if (!key.length && !value.length) {
            this.notificationsService.showSnackBar('Add key and value', true);
            return;
        }
        if (!key.length) {
            this.notificationsService.showSnackBar('Add key', true);
            return;
        }
        if (!value.length) {
            this.notificationsService.showSnackBar('Add value', true);
            return;
        }
        this.selectedList.push(key + '|' + value);
        k.value = '';
        v.value = '';

        this.data.output.emit(this.jsonObject);
    }

    deleteExistingKey(key) {
        delete this.jsonObject[key.value];
    }
    editPair(k, v, item?, index?) {
        const key = k.value;
        const value = v.value;
        if (!key.length && !value.length) {
            this.notificationsService.showSnackBar('Add key and value', true);
            return;
        }
        if (!key.length) {
            this.notificationsService.showSnackBar('Add key', true);
            return;
        }
        if (!value.length) {
            this.notificationsService.showSnackBar('Add value', true);
            return;
        }
        if (item) {
            const currentKey = item.split('|')[0];
            delete this.jsonObject[currentKey];
            this.jsonObject[key] = value;
            this.selectedList[index] = key + '|' + value;
        } else {
            this.jsonObject[key] = value;
        }
        if (!key.length || !value.length) {
            return;
        }
        this.data.output.emit(this.jsonObject);
    }
}
