export class SvgData {
    static readonly NO_PAGE_FOUND = `<svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
>
    <g>
        <g>
            <g>
                <path
                    d="M469.333,0H42.667C19.114,0.028,0.028,19.114,0,42.667v426.667C0.028,492.886,19.114,511.972,42.667,512h426.667
        c23.552-0.028,42.638-19.114,42.667-42.667V42.667C511.972,19.114,492.886,0.028,469.333,0z M494.933,469.333
        c0,14.138-11.461,25.6-25.6,25.6H42.667c-14.138,0-25.6-11.461-25.6-25.6v-384h477.867V469.333z M494.933,68.267H17.067v-25.6
        c0-14.138,11.462-25.6,25.6-25.6h426.667c14.138,0,25.6,11.462,25.6,25.6V68.267z"
                />
                <path
                    d="M59.733,34.133H51.2c-4.713,0-8.533,3.82-8.533,8.533S46.487,51.2,51.2,51.2h8.533c4.713,0,8.533-3.82,8.533-8.533
        S64.446,34.133,59.733,34.133z"
                />
                <path
                    d="M99.925,36.608c-3.422-3.157-8.695-3.157-12.117,0c-0.753,0.83-1.359,1.782-1.792,2.816
        c-0.911,2.066-0.911,4.419,0,6.485c0.435,1.033,1.041,1.985,1.792,2.816c2.443,2.443,6.126,3.153,9.301,1.792
        c1.033-0.435,1.985-1.041,2.816-1.792c0.751-0.831,1.357-1.783,1.792-2.816c0.91-2.066,0.91-4.419,0-6.485
        C101.284,38.39,100.678,37.438,99.925,36.608z"
                />
                <path
                    d="M134.059,36.608c-3.422-3.157-8.695-3.157-12.117,0c-0.753,0.83-1.359,1.782-1.792,2.816
        c-1.361,3.175-0.651,6.859,1.792,9.301c0.83,0.753,1.782,1.359,2.816,1.792c2.066,0.911,4.419,0.911,6.485,0
        c1.043-0.414,1.999-1.022,2.816-1.792c1.602-1.607,2.494-3.789,2.475-6.059c0.025-1.119-0.208-2.229-0.683-3.243
        C135.418,38.39,134.812,37.438,134.059,36.608z"
                />
                <path
                    d="M460.8,34.133H170.667c-4.713,0-8.533,3.82-8.533,8.533s3.82,8.533,8.533,8.533H460.8c4.713,0,8.533-3.82,8.533-8.533
        S465.513,34.133,460.8,34.133z"
                />
                <path
                    d="M51.2,315.733h119.467c4.713,0,8.533-3.82,8.533-8.533s-3.82-8.533-8.533-8.533H67.14L128,207.36v65.707
        c0,4.713,3.82,8.533,8.533,8.533s8.533-3.821,8.533-8.533V179.2c0-0.282-0.137-0.512-0.162-0.794
        c-0.051-0.58-0.165-1.152-0.341-1.707c-0.154-0.508-0.357-0.999-0.606-1.468c-0.242-0.464-0.528-0.904-0.853-1.314
        c-0.366-0.456-0.781-0.871-1.237-1.237c-0.213-0.179-0.333-0.418-0.563-0.572c-0.23-0.154-0.478-0.162-0.708-0.29
        c-0.512-0.276-1.053-0.497-1.613-0.657c-0.537-0.165-1.088-0.276-1.647-0.333c-0.264-0.068-0.532-0.122-0.802-0.162
        c-0.282,0-0.529,0.137-0.802,0.162c-0.579,0.058-1.15,0.172-1.707,0.341c-0.503,0.155-0.992,0.355-1.459,0.597
        c-0.475,0.257-0.924,0.56-1.34,0.905c-0.444,0.367-0.853,0.776-1.22,1.22c-0.171,0.213-0.418,0.341-0.572,0.572l-85.333,128
        c-1.747,2.619-1.911,5.986-0.426,8.762C45.16,314.001,48.052,315.734,51.2,315.733z"
                />
                <path
                    d="M136.533,332.8c-4.713,0-8.533,3.821-8.533,8.533v25.6c0,4.713,3.82,8.533,8.533,8.533s8.533-3.82,8.533-8.533v-25.6
        C145.067,336.621,141.246,332.8,136.533,332.8z"
                />
                <path
                    d="M332.8,315.733h119.467c4.713,0,8.533-3.82,8.533-8.533s-3.821-8.533-8.533-8.533H348.74l60.86-91.307v65.707
        c0,4.713,3.82,8.533,8.533,8.533s8.533-3.821,8.533-8.533V179.2c0-0.282-0.137-0.512-0.162-0.794
        c-0.051-0.58-0.165-1.152-0.341-1.707c-0.154-0.508-0.357-0.999-0.606-1.468c-0.242-0.464-0.528-0.904-0.853-1.314
        c-0.366-0.456-0.781-0.871-1.237-1.237c-0.213-0.179-0.333-0.418-0.563-0.572c-0.23-0.154-0.478-0.162-0.708-0.29
        c-0.512-0.276-1.053-0.497-1.613-0.657c-0.537-0.165-1.088-0.276-1.647-0.333c-0.264-0.068-0.532-0.122-0.802-0.162
        c-0.282,0-0.529,0.137-0.802,0.162c-0.579,0.056-1.151,0.171-1.707,0.341c-0.508,0.152-1,0.355-1.468,0.606
        c-0.474,0.251-0.92,0.551-1.331,0.896c-0.451,0.363-0.863,0.772-1.229,1.22c-0.171,0.213-0.418,0.341-0.572,0.572l-85.333,128
        c-1.748,2.62-1.911,5.989-0.424,8.766C326.755,314.006,329.651,315.737,332.8,315.733z"
                />
                <path
                    d="M418.133,332.8c-4.713,0-8.533,3.821-8.533,8.533v25.6c0,4.713,3.82,8.533,8.533,8.533s8.533-3.82,8.533-8.533v-25.6
        C426.667,336.621,422.846,332.8,418.133,332.8z"
                />
                <path
                    d="M230.4,375.467h42.667c18.851,0,34.133-15.282,34.133-34.133V204.8c0-18.851-15.282-34.133-34.133-34.133H230.4
        c-18.851,0-34.133,15.282-34.133,34.133v136.533C196.267,360.185,211.549,375.467,230.4,375.467z M213.333,204.8
        c0-9.426,7.641-17.067,17.067-17.067h42.667c9.426,0,17.067,7.641,17.067,17.067v136.533c0,9.426-7.641,17.067-17.067,17.067
        H230.4c-9.426,0-17.067-7.641-17.067-17.067V204.8z"
                />
                <path
                    d="M341.333,418.133H170.667c-4.713,0-8.533,3.82-8.533,8.533s3.82,8.533,8.533,8.533h170.667
        c4.713,0,8.533-3.82,8.533-8.533S346.046,418.133,341.333,418.133z"
                />
                <path
                    d="M307.2,452.267H204.8c-4.713,0-8.533,3.821-8.533,8.533s3.82,8.533,8.533,8.533h102.4c4.713,0,8.533-3.82,8.533-8.533
        S311.913,452.267,307.2,452.267z"
                />
            </g>
        </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
</svg>`;
    static readonly ERROR_LOADING_WIDGETS = ` <svg
    id="bold"
    enable-background="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="m3.25 13.75h-2.5c-.414 0-.414-1.5 0-1.5h2.5c.414 0 .75.336.75.75s-.336.75-.75.75z"
    />
    <path
        d="m3.25 16h-1.5c-.965 0-1.75-.785-1.75-1.75v-2.5c0-.965.785-1.75 1.75-1.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75h-1.5c-.138 0-.25.112-.25.25v2.5c0 .138.112.25.25.25h1.5c.414 0 .75.336.75.75s-.336.75-.75.75z"
    />
    <path
        d="m17.25 16h-.5c-.965 0-1.75-.785-1.75-1.75v-2.5c0-.965.785-1.75 1.75-1.75h.5c.965 0 1.75.785 1.75 1.75v2.5c0 .965-.785 1.75-1.75 1.75zm-.5-4.5c-.138 0-.25.112-.25.25v2.5c0 .138.112.25.25.25h.5c.138 0 .25-.112.25-.25v-2.5c0-.138-.112-.25-.25-.25z"
    />
    <path
        d="m5.75 16c-.414 0-.75-.336-.75-.75v-4.5c0-.414.336-.75.75-.75h1.125c1.172 0 2.125.953 2.125 2.125s-.953 2.125-2.125 2.125h-.375v1c0 .414-.336.75-.75.75zm.75-3.25h.375c.345 0 .625-.28.625-.625s-.28-.625-.625-.625h-.375z"
    />
    <path
        d="m7.03 12.97 1.75 1.75c.293.293.293.768 0 1.061s-.768.293-1.061 0l-1.75-1.75c-.292-.294.915-1.208 1.061-1.061z"
    />
    <path
        d="m10.75 16c-.414 0-.75-.336-.75-.75v-4.5c0-.414.336-.75.75-.75h1.125c1.172 0 2.125.953 2.125 2.125s-.953 2.125-2.125 2.125h-.375v1c0 .414-.336.75-.75.75zm.75-3.25h.375c.345 0 .625-.28.625-.625s-.28-.625-.625-.625h-.375z"
    />
    <path
        d="m12.319 13.012 1.5 1.75c.27.314.233.788-.081 1.058-.314.269-.787.233-1.058-.081l-1.5-1.75c-.269-.315.991-1.15 1.139-.977z"
    />
    <path
        d="m20.75 16c-.414 0-.75-.336-.75-.75v-4.5c0-.414.336-.75.75-.75h1.125c1.172 0 2.125.953 2.125 2.125s-.953 2.125-2.125 2.125h-.375v1c0 .414-.336.75-.75.75zm.75-3.25h.375c.345 0 .625-.28.625-.625s-.28-.625-.625-.625h-.375z"
    />
    <path
        d="m22.319 13.012 1.5 1.75c.27.314.233.788-.081 1.058-.314.269-.788.233-1.058-.081l-1.5-1.75c-.269-.315.991-1.15 1.139-.977z"
    />
    <path
        d="m23.25 17c-.473 0-.918-.198-1.246-.534-.001.001-.003.002-.004.003v3.531c0 .552-.449 1-1 1h-18c-.551 0-1-.448-1-1v-3h-.25c-.668 0-1.273-.249-1.75-.646v3.646c0 1.654 1.346 3 3 3h18c1.654 0 3-1.346 3-3v-3.193c-.235.114-.485.193-.75.193z"
    />
    <path
        d="m1.75 8h.25v-2h20v2.013c.732.022 1.411.238 2 .595v-4.608c0-1.654-1.346-3-3-3h-18c-1.654 0-3 1.346-3 3v4.454c.525-.28 1.115-.454 1.75-.454z"
    />
</svg>`;
}
