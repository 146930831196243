import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as $ from 'jquery';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IIntegrationApiResponse } from 'src/app/shared/interfaces/api/portlets/IIntegrationApiResponse';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IIntegrationInput } from 'src/app/shared/interfaces/integration/IIntegrationInput';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IntegrationCacheService } from 'src/app/shared/services/cache/integration-cache/integration-cache.service';
import { FormGeneratorModalComponent } from '../../../modal-templates/form-generator-modal/form-generator-modal.component';

@Component({
    selector: 'app-zendesk-integration',
    templateUrl: './zendesk-integration.component.html',
    styleUrls: ['./zendesk-integration.component.sass']
})
export class ZendeskIntegrationComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    integrationInput: IIntegrationInput = null;
    widgetRef: Widget;
    isParentIntegrated = false;
    isSelfIntegrated = false;
    isConfigured: Boolean = false;
    formGenInput: IFormGeneratorInput;

    constructor(
        widgetData: WidgetInjectedData,
        private integrationCacheService: IntegrationCacheService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        this.integrationInput = {
            integrationName: this.widgetRef.widgetData.widgetInfo.text,
            integrationCategory: 'ITSM Integration',
            imageUrl: 'assets/integrations/zendesk_2.png',
            buttons: [],
            imagePadding: 4
        };
        const script = this.integrationCacheService.zendeskScript;
        if (script) {
            this.loadScript(script);
        }
    }

    generateButtons(response: IIntegrationApiResponse) {
        this.formGenInput = {
            formName: 'Zendesk',
            state: this.isSelfIntegrated ? FormState.EDIT : FormState.CREATE,
            submitButton: {
                buttonName: this.isSelfIntegrated ? 'Update' : 'Integrate',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: (
                    buttonRef: IButtonGeneratorInput,
                    formGroup: FormGroup,
                    modalId: Symbol
                ) => {
                    if (formGroup.valid) {
                        buttonRef.loader = true;
                        if (this.isSelfIntegrated) {
                            // Update
                            const args = Helper.generateHitApiConfig(
                                this.widgetRef.widgetData.widgetInfo.update
                            );
                            args.input = {
                                ...formGroup.value,
                                integrationType: 'ZENDESK'
                            };
                            args.function = (response) => {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'Zendesk configuration updated successfully'
                                );
                                buttonRef.loader = false;
                                this.widgetRef.refreshWidget();
                                this.widgetRef.modalService.closeModal(
                                    null,
                                    modalId
                                );
                            };
                            args.errorFunction = (error) => {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'Error updating zendesk configuration'
                                );
                                buttonRef.loader = false;
                            };
                            new HitApi(
                                args,
                                this.widgetRef.httpService,
                                this.widgetRef.ngZone
                            ).hitApi();
                        } else {
                            // Create
                            const args = Helper.generateHitApiConfig(
                                this.widgetRef.widgetData.widgetInfo.create
                            );
                            args.input = {
                                ...formGroup.value,
                                integrationType: 'ZENDESK'
                            };
                            args.function = (response) => {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'Zendesk integrated successfully'
                                );
                                buttonRef.loader = false;
                                this.widgetRef.refreshWidget();
                                this.widgetRef.modalService.closeModal(
                                    null,
                                    modalId
                                );
                            };
                            args.errorFunction = (error) => {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'Error integrating zendesk'
                                );
                                buttonRef.loader = false;
                            };
                            new HitApi(
                                args,
                                this.widgetRef.httpService,
                                this.widgetRef.ngZone
                            ).hitApi();
                        }
                    }
                }
            },
            fields: [
                {
                    name: 'email',
                    placeholder: 'Email',
                    label: 'Email',
                    fieldType: FilterType.EMAIL,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email is required'
                        },
                        {
                            validator: CustomValidators.email,
                            errorMessage: 'Invalid email'
                        }
                    ],
                    value: this.isSelfIntegrated
                        ? response['self']['email']
                        : ''
                },
                {
                    name: 'supportedEmails',
                    placeholder: 'Support Email',
                    label: 'Support Email',
                    fieldType: FilterType.MATCHIPLIST,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Support email is required'
                        },
                        {
                            validator: CustomValidators.matChipAllowEmail,
                            errorMessage: 'Invalid email'
                        }
                    ],
                    value: this.isSelfIntegrated
                        ? response['self']['supportedEmails']
                        : ''
                },
                {
                    name: 'apiKey',
                    placeholder: 'API Key',
                    label: 'API Key',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'API key is required'
                        }
                    ],
                    suffixIcon: {
                        hoverText: `Where to get api key`,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Your API Key',
                                content: [
                                    {
                                        type: 'ORDERED_LIST',
                                        data: [
                                            `Login to your Zendesk Account.`,
                                            `Click the Admin icon () in the sidebar, then select Channels > API.`,
                                            `Click the Settings tab, and make sure Token Access is enabled.`,
                                            `Click the + button to the right of Active API Tokens.`,
                                            `Optionally, enter a description under API Token Description. The token is generated, and displayed for you`
                                        ]
                                    }
                                ]
                            });
                        }
                    },
                    value: this.isSelfIntegrated
                        ? response['self']['apiKey']
                        : ''
                },
                {
                    name: 'domainName',
                    placeholder: 'Domain Name',
                    label: 'Domain Name',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Domain name is required'
                        }
                    ],
                    value: this.isSelfIntegrated
                        ? response['self']['domainName']
                        : ''
                },
                {
                    name: 'widgetCode',
                    placeholder: 'Widget Code',
                    label: 'Widget Code',
                    fieldType: FilterType.TEXTAREA,
                    extraClass: 'tw-items-center',
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Widget code is required'
                        }
                    ],
                    suffixIcon: {
                        hoverText: `Where to get widget code`,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Your Widget Code',
                                content: [
                                    {
                                        type: 'ORDERED_LIST',
                                        data: [
                                            `Click the Admin icon () in the sidebar, then select Channels > Widget.`,
                                            `Click the Setup tab, if it is not already selected.`,
                                            `Under the code box, click Copy to clipboard.`
                                        ]
                                    }
                                ]
                            });
                        }
                    },
                    value: this.isSelfIntegrated
                        ? response['self']['widgetCode']
                        : '',
                    fieldStyle: {
                        height: '40%'
                    },
                    textAreaStyles: {
                        'min-height': '80px'
                    }
                }
            ],
            extraClass: 'integration-new-styling add-scroll'
        };
        if (this.isSelfIntegrated || this.isParentIntegrated) {
            if (this.isSelfIntegrated) {
                // Adding play button
                this.integrationInput.buttons.push({
                    buttonName: 'Test',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-play'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    hoverText: 'Test Zendesk',
                    function: () => {
                        const scriptToRender = this.isSelfIntegrated
                            ? response.self.widgetCode
                            : this.isParentIntegrated
                            ? response.parent.widgetCode
                            : null;
                        const sub = scriptToRender.substr(
                            (scriptToRender.indexOf(`src="`) >= 0
                                ? scriptToRender.indexOf(`src="`)
                                : scriptToRender.indexOf(`src='`)) + 5
                        );
                        const url = sub.substr(
                            0,
                            sub.indexOf(`">`) >= 0
                                ? sub.indexOf(`">`)
                                : sub.indexOf(`'>`)
                        );
                        const loadedScript =
                            this.integrationCacheService.zendeskScript;
                        if (!loadedScript) {
                            this.integrationCacheService.zendeskScript = url;
                            this.loadScript(url);
                            return;
                        }

                        if (loadedScript === url) {
                            $('#launcher').show();
                        } else {
                            this.integrationCacheService.zendeskScript = url;
                            window.location.reload();
                        }
                    }
                });

                // Adding edit button
                this.integrationInput.buttons.push({
                    buttonName: 'Edit',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-wrench'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    hoverText: 'Edit Zendesk',
                    function: () => {
                        const modalArgs: IModalData = {
                            modalName: 'Edit Zendesk',
                            sourceId:
                                this.widgetRef.widgetData
                                    .widgetUniqueIdentifier,
                            modalType: ModalType.MIDDLE,
                            modalIcon: {
                                type: IconType.FONTAWSOME,
                                class: 'fas fa-wrench'
                            },
                            extraStepContainerClass: 'remove-form-space',
                            modalSteps: [
                                {
                                    stepName: 'Edit Zendesk',
                                    resetModal: true,
                                    stepData: {
                                        componentToLoad:
                                            FormGeneratorModalComponent,
                                        payload: {
                                            data: this.formGenInput
                                        }
                                    }
                                }
                            ],
                            modalWidthVw: 60,
                            modalHeightVh: 60
                        };
                        this.widgetRef.modalService.openModal(modalArgs);
                    }
                });

                // Adding delete button
                this.integrationInput.buttons.push({
                    buttonName: 'Integrate',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-trash'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.WARN,
                    hoverText: 'Remove Zendesk',
                    function: (modalId: Symbol) => {
                        // Delete integration
                        const args = Helper.generateHitApiConfig(
                            this.widgetRef.widgetData.widgetInfo.delete
                        );
                        args.input = {};
                        args.function = (response) => {
                            this.widgetRef.notificationsService.showSnackBar(
                                `Zendesk integration removed successfully`
                            );
                            this.widgetRef.modalService.closeModal(
                                null,
                                modalId
                            );
                            this.widgetRef.refreshWidget();
                        };
                        args.errorFunction = (error) => {
                            this.widgetRef.notificationsService.showSnackBar(
                                error.error.message,
                                true
                            );
                            this.widgetRef.modalService.closeModal(
                                null,
                                modalId
                            );
                        };
                        new HitApi(
                            args,
                            this.widgetRef.httpService,
                            this.widgetRef.ngZone
                        ).hitApi();
                    },
                    executeFunctionAfterConfirmation: {
                        modalName: 'Remove Zendesk Integration',
                        modalIcon: {
                            type: IconType.FONTAWSOME,
                            class: 'far fa-trash-alt'
                        },
                        contextIcon: {
                            extraClass: 'color-accent',
                            type: IconType.FONTAWSOME,
                            class: 'fas fa-exclamation-triangle'
                        },
                        confirmationMessage: `Are you sure you want to remove zendesk ?`,
                        buttonText: 'Remove',
                        buttonColorType: ButtonColorType.WARN,
                        loaderOnExec: true
                    }
                });
            }

            if (this.isParentIntegrated && !this.isSelfIntegrated) {
                // Adding create button
                this.integrationInput.buttons.push({
                    buttonName: 'Integrate',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-plus'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    hoverText: 'Integrate Zendesk',
                    function: () => {
                        const modalArgs: IModalData = {
                            modalName: 'Integrate Zendesk',
                            sourceId:
                                this.widgetRef.widgetData
                                    .widgetUniqueIdentifier,
                            modalType: ModalType.MIDDLE,
                            modalIcon: {
                                type: IconType.FONTAWSOME,
                                class: 'fas fa-ticket-alt'
                            },
                            extraStepContainerClass: 'remove-form-space',
                            modalSteps: [
                                {
                                    stepName: 'Integrate Zendesk',
                                    stepData: {
                                        componentToLoad:
                                            FormGeneratorModalComponent,
                                        payload: {
                                            data: this.formGenInput
                                        }
                                    }
                                }
                            ],
                            modalWidthVw: 60,
                            modalHeightVh: 60
                        };
                        this.widgetRef.modalService.openModal(modalArgs);
                    }
                });
            }
        } else {
            // Adding create button
            this.integrationInput.buttons.push({
                buttonName: 'Integrate',
                buttonIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-plus'
                },
                buttonType: ButtonType.CIRCLE,
                buttonColorType: ButtonColorType.SUCCESS,
                hoverText: 'Integrate Zendesk',
                function: () => {
                    this.onIntegrate(this.formGenInput);
                }
            });
        }
    }
    onIntegrate(formGenInput) {
        const modalArgs: IModalData = {
            modalName: 'Integrate Zendesk',
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            extraStepContainerClass: 'remove-form-space remove-bottom-padding',
            modalSteps: [
                {
                    stepName: 'Integrate Zendesk',
                    resetModal: true,
                    stepData: {
                        componentToLoad: FormGeneratorModalComponent,
                        payload: {
                            data: formGenInput
                        }
                    }
                }
            ],
            modalWidthVw: 60,
            modalHeightPx: 510,
            noBottomPadding: true
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
    loadScript(url) {
        const node = document.createElement('script');
        node.src = url;
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        node.id = 'ze-snippet';
        document.getElementsByTagName('head')[0].appendChild(node);
    }

    bindData(response: IIntegrationApiResponse): void {
        this.isConfigured = false;
        if ('integration' in response && !response.integration) {
            this.isParentIntegrated = false;
            this.isSelfIntegrated = false;
        }
        if (response.parent && response.parent.integration) {
            this.isParentIntegrated = true;
            this.isSelfIntegrated = false;
        }
        if (response.self && response.self.integration) {
            this.isSelfIntegrated = true;
            this.isConfigured = true;
        }

        this.integrationInput.buttons = [];
        this.generateButtons(response);
        this.widgetRef.endLoader();
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }

    ngOnDestroy() {
        if ($('#launcher')) {
            $('#launcher').hide();
        }
    }
}
