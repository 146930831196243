import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { ButtonType } from '../../enums/ButtonType';
import { FileType } from '../../enums/FileType';
import { ButtonColorType } from './../../enums/ButtonColorType';
import {
    HtmlTargetAttributeValue,
    IButtonGeneratorInput
} from './../../interfaces/button-generator/IButtonGeneratorInput';
import { ModalService } from './../../services/modal/modal-service/modal.service';

@Component({
    selector: 'app-button-generator',
    templateUrl: './button-generator.component.html',
    styleUrls: ['./button-generator.component.sass']
})
export class ButtonGeneratorComponent implements OnInit, OnChanges {
    buttonClass: string;
    @Input() buttonGenInput: IButtonGeneratorInput;
    @Input() disabled: boolean = false;
    @Input() hoverText: string = null;

    @Output() buttonRef = new EventEmitter<any>();
    ButtonType = ButtonType;
    ButtonColorType = ButtonColorType;
    FileType = FileType;
    HtmlTargetAttributeValue = HtmlTargetAttributeValue;
    clickableEffect: boolean = true;
    constructor(private modalService: ModalService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.buttonGenInput && !changes.buttonGenInput.firstChange) {
            this.getButtonClasses(this.buttonGenInput);
        }
    }

    ngOnInit(): void {
        if (this.buttonGenInput) {
            if (this.buttonGenInput.clickableEffect !== undefined) {
                this.clickableEffect = this.buttonGenInput.clickableEffect;
            }
            this.getButtonClasses(this.buttonGenInput);
        }
    }

    getButtonClasses(data: IButtonGeneratorInput) {
        let btnClass: string;
        if (data.buttonType === ButtonType.TEXT) {
            if (data.customClass) {
                btnClass = `text btn-text-${data.buttonColorType} ${data.customClass} filled`;
            } else {
                btnClass = `text btn-text-${data.buttonColorType} filled`;
            }
        } else if (data.buttonType === ButtonType.STROKED) {
            if (data.customClass) {
                btnClass = `btn-stroked-${data.buttonColorType} ${data.customClass} filled`;
            } else {
                btnClass = `btn-stroked-${data.buttonColorType} filled`;
            }
        } else if (data.buttonType === ButtonType.ICON) {
            if (data.customClass) {
                btnClass = `icon btn-icon-${data.buttonColorType} ${data.customClass} filled`;
            } else {
                btnClass = `icon btn-icon-${data.buttonColorType} filled`;
            }
        } else if (data.buttonType === ButtonType.CIRCLE) {
            if (data.customClass) {
                btnClass = `circle-button ${data.customClass} filled`;
            } else {
                btnClass = `circle-button  filled`;
            }
        } else if (data.buttonType === ButtonType.LINK) {
            if (data.customClass) {
                btnClass = `clickable btn-stroked-${data.buttonColorType} ${data.customClass} no-hover filled`;
            } else {
                btnClass = `clickable btn-stroked-${data.buttonColorType}  no-hover filled`;
            }
        } else {
            if (data.customClass) {
                btnClass = `btn-${data.buttonColorType} ${data.customClass} filled`;
            } else {
                btnClass = `btn-${data.buttonColorType} filled`;
            }
        }
        if (this.clickableEffect) {
            btnClass = 'clickable ' + btnClass;
        }

        this.buttonClass = btnClass;
    }
    handleClickEvent(buttonRef) {
        if (this.buttonRef.observers.length) {
            this.buttonRef.emit(buttonRef);
        } else if (buttonRef.executeFunctionAfterConfirmation) {
            if (!buttonRef.executeFunctionAfterConfirmation.function) {
                buttonRef.executeFunctionAfterConfirmation.function =
                    buttonRef.function;
            }
            this.modalService.openConfirmationModal(
                buttonRef.executeFunctionAfterConfirmation
            );
        } else {
            buttonRef.function(buttonRef);
        }
    }
}
