<div
    class="servicenow-var tw-flex tw-flex-col tw-p-2 tw-overflow-auto tw-w-full"
>
    <ng-container *ngFor="let varr of variableList">
        <label
            for="field"
            class="tw-flex tw-items-center tw-p-2"
            *ngIf="varIdVisibilityMap[varr['varId']] && varr['type']"
        >
            <span class="tw-flex-f3"
                >{{ varr["label"]
                }}<span class="tw-pl-1" *ngIf="varr['mandatory']">*</span></span
            >
            <span class="tw-flex-f7 tw-pl-3">
                <ng-container *ngIf="varr['type'] === 'text'">
                    <input
                        [placeholder]="'Select ' + varr['label']"
                        type="text"
                        class="tw-border-0 tw-outline-none tw-w-full tw-text-fs-400"
                        (keyup)="
                            variableResp[varr['id']] = $event.target.value;
                            variableIdResp[varr['varId']] = $event.target.value;
                            generateVisibilityMap()
                        "
                    />
                </ng-container>

                <ng-container *ngIf="varr['type'] === 'checkbox'">
                    <mat-checkbox
                        (change)="
                            variableResp[varr['id']] = $event.checked;
                            variableIdResp[varr['varId']] = $event.checked;
                            generateVisibilityMap()
                        "
                    >
                    </mat-checkbox>
                </ng-container>

                <ng-container *ngIf="varr['type'] === 'textarea'">
                    <textarea
                        [placeholder]="'Select ' + varr['label']"
                        class="tw-border-0 tw-outline-none tw-w-full tw-text-fs-400"
                        (keyup)="
                            variableResp[varr['id']] = $event.target.value;
                            variableIdResp[varr['varId']] = $event.target.value;
                            generateVisibilityMap()
                        "
                    ></textarea>
                </ng-container>

                <ng-container *ngIf="varr['type'] === 'singe_select_dropdown'">
                    <app-single-select-dropdown
                        [filterInfo]="{
                            listData: varr['values'],
                            placeholder: 'Select ' + varr['label'],
                            lightMode: true
                        }"
                        (change)="
                            variableResp[varr['id']] = $event;
                            variableIdResp[varr['varId']] = $event;
                            generateVisibilityMap()
                        "
                    >
                    </app-single-select-dropdown>
                </ng-container>

                <ng-container *ngIf="varr['type'] === 'table'">
                    <div class="table-container">
                        <app-servicenow-table
                            [variableSets]="varr['setVariables']"
                            (change)="
                                variableResp[varr['id']] = $event;
                                variableIdResp[varr['varId']] = $event;
                                generateVisibilityMap()
                            "
                        >
                        </app-servicenow-table>
                    </div>
                </ng-container>

                <ng-container
                    *ngIf="
                        varr['type'] === 'date' || varr['type'] === 'dateTime'
                    "
                >
                    <app-date-picker
                        [data]="{
                            placeholderText: 'Select ' + varr['label'],
                            defaultDate: defDate,
                            includeTime: varr['type'] === 'dateTime'
                        }"
                        [serviceNow]="true"
                        (change)="
                            variableResp[varr['id']] = $event;
                            variableIdResp[varr['varId']] = $event;
                            generateVisibilityMap()
                        "
                    >
                    </app-date-picker>
                </ng-container>
            </span>
        </label>
    </ng-container>
</div>

<div
    class="tw-absolute tw-bottom-2 tw-left-0 tw-right-0 tw-flex tw-justify-end tw-items-center tw-h-10"
>
    <app-button-generator [buttonGenInput]="buttonInput">
    </app-button-generator>
</div>
