<div
    *ngIf="
        widgetUrlInfo &&
        widgetUrlInfo.response &&
        widgetUrlInfo.response['framework']
    "
    class="top-navigation pages"
>
    <ng-template [ngTemplateOutlet]="navigationLink"></ng-template>
</div>
<div
    class="pageNameWithPagination"
    *ngIf="
        !errorLoadingPage &&
        !pageNotFound &&
        widgetUrlInfo &&
        widgetUrlInfo.response &&
        !(
            widgetUrlInfo.response['assesseOverview'] ||
            widgetUrlInfo.response['assessorOverview']
        )
    "
    [ngClass]="
        widgetUrlInfo.response && widgetUrlInfo.response['framework']
            ? 'assessment-heading'
            : ''
    "
>
    <div class="pageName">
        <span
            [matTooltip]="
                widgetUrlInfo.response && widgetUrlInfo.response['framework']
                    ? widgetUrlInfo.pageName
                    : ''
            "
            [class.wrap-page-name]="
                widgetUrlInfo.response && widgetUrlInfo.response['framework']
            "
            [class.tw-font-heading]="
                !widgetUrlInfo.response || !widgetUrlInfo.response['framework']
            "
        >
            {{ widgetUrlInfo.pageName }}
        </span>
        <ng-container
            *ngIf="
                widgetUrlInfo.response['framework'] &&
                widgetUrlInfo.response['framework']['tags'] &&
                widgetUrlInfo.response['framework']['tags'].length
            "
        >
            <ng-template [ngTemplateOutlet]="assessmentTags"></ng-template>
        </ng-container>
    </div>
    <div
        *ngIf="
            widgetUrlInfo &&
            widgetUrlInfo.response &&
            widgetUrlInfo.response['framework'] &&
            !assessmentCacheService.assessmentView
        "
        class="tw-flex tw-flex-row tw-flex-row tw-items-center"
    >
        <ng-template [ngTemplateOutlet]="assessmentStatus"></ng-template>
    </div>
    <div
        class="pages"
        *ngIf="
            widgetUrlInfo &&
            widgetUrlInfo.response &&
            widgetUrlInfo.response.totalPages > 1
        "
    >
        <button
            [style.visibility]="currentPage === 1 ? 'hidden' : 'visible'"
            class="clickable back-page"
            (click)="changePage(currentPage - 1)"
        >
            <i class="fas fa-chevron-left"></i>
        </button>
        <div class="current-page">
            <input
                #field
                type="number"
                [value]="currentPage"
                (keyup.enter)="changePage($event.target.value, $event.target)"
                [min]="1"
                [max]="widgetUrlInfo.response.totalPages"
            />
            <div class="separator">/</div>
            <span class="totalPages">{{
                widgetUrlInfo.response.totalPages
            }}</span>
        </div>
        <button
            [style.visibility]="
                currentPage >= widgetUrlInfo.response.totalPages
                    ? 'hidden'
                    : 'visible'
            "
            class="clickable next-page"
            (click)="changePage(currentPage + 1)"
        >
            <i class="fas fa-chevron-right"></i>
        </button>
    </div>
</div>

<div
    id="widgets-container"
    [style.padding]="
        widgetUrlInfo &&
        widgetUrlInfo.response &&
        widgetUrlInfo.response['framework']
            ? '0 20px 20px'
            : '10px'
    "
    [style.height]="
        widgetUrlInfo &&
        widgetUrlInfo.response &&
        (widgetUrlInfo.response['assesseOverview'] ||
            widgetUrlInfo.response['assessorOverview'])
            ? '100%'
            : null
    "
    [style.max-height]="
        widgetUrlInfo &&
        widgetUrlInfo.response &&
        widgetUrlInfo.response['framework']
            ? 'calc(100% - 100px)'
            : null
    "
    [style.flex]="
        widgetUrlInfo &&
        widgetUrlInfo.response &&
        widgetUrlInfo.response['framework']
            ? '1'
            : null
    "
    [style.flex-direction]="
        widgetUrlInfo &&
        widgetUrlInfo.response &&
        widgetUrlInfo.response['framework']
            ? 'column'
            : null
    "
    *ngIf="!errorLoadingPage && !pageNotFound"
>
    <div class="overlay" *ngIf="loadingWidgetsData.value">
        <div class="overlay-content">
            <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
        </div>
    </div>
    <ng-container *ngIf="!loadingWidgetsData.value && ribbonPortletType">
        <div class="ribbon">
            <div class="ribbon-box">
                <app-carousel-generator
                    [carouselSlideInput]="carouselSlides"
                    [limitToShowDataPerPage]="4"
                ></app-carousel-generator>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!loadingWidgetsData.value">
        <ng-container
            *ngFor="
                let groupId of groupIds;
                trackBy: widgetIdentify;
                index as i
            "
        >
            <ng-container *ngIf="groupWidgetMap.has(groupId)">
                <ng-container
                    *ngIf="
                        groupWidgetMap.get(groupId).length &&
                            groupWidgetMap.get(groupId).length === 1;
                        else groupedWidget
                    "
                >
                    <ng-container
                        *ngIf="
                            !this.assessmentOverview &&
                            widgetUrlInfo.response &&
                            widgetUrlInfo.response.widgets.length &&
                            widgetData.has(groupWidgetMap.get(groupId)[0].id) &&
                            widgetData.get(groupWidgetMap.get(groupId)[0].id)
                                .widgetInfo.portlet.type !== PortletType.RIBBON
                        "
                    >
                        <app-widget-generator
                            [data]="
                                widgetData.get(
                                    groupWidgetMap.get(groupId)[0].id
                                )
                            "
                            [widgetIndex]="i"
                            [class.full-height]="
                                fullHeightPortletTypes.includes(
                                    widgetData.get(
                                        groupWidgetMap.get(groupId)[0].id
                                    ).widgetInfo.portlet.type
                                ) &&
                                widgetIds &&
                                widgetIds.length === 1
                            "
                        ></app-widget-generator>
                    </ng-container>
                </ng-container>
                <ng-template #groupedWidget>
                    <ng-container
                        *ngFor="let groupWidget of groupWidgetMap.get(groupId)"
                    >
                        <app-widget-generator
                            *ngIf="
                                groupWidgetMap.get(groupId)[
                                    groupActiveWidgetMap.get(groupId)
                                ].id === groupWidget.id
                            "
                            [data]="
                                widgetData.get(
                                    groupWidgetMap.get(groupId)[
                                        groupActiveWidgetMap.get(groupId)
                                    ].id
                                )
                            "
                            [widgetTabsRef]="widgetNameTabs"
                            [widgetIndex]="i"
                            [class.full-height]="
                                fullHeightPortletTypes.includes(
                                    widgetData.get(
                                        groupWidgetMap.get(groupId)[
                                            groupActiveWidgetMap.get(groupId)
                                        ].id
                                    ).widgetInfo.portlet.type
                                ) &&
                                widgetIds &&
                                widgetIds.length === 1
                            "
                        ></app-widget-generator>
                    </ng-container>
                    <ng-template #widgetNameTabs>
                        <mat-tab-group
                            (click)="$event.stopPropagation()"
                            [selectedIndex]="groupActiveWidgetMap.get(groupId)"
                            (selectedIndexChange)="
                                onActiveGroupWidgetChanged($event, groupId)
                            "
                        >
                            <mat-tab
                                *ngFor="
                                    let groupWidget of groupWidgetMap.get(
                                        groupId
                                    )
                                "
                            >
                                <ng-template matTabLabel>
                                    <div>
                                        <div
                                            class="ellipsis-text"
                                            [matTooltip]="groupWidget.name"
                                        >
                                            {{ groupWidget.name }}
                                        </div>
                                    </div>
                                </ng-template>
                            </mat-tab>
                        </mat-tab-group>
                    </ng-template>
                </ng-template>
            </ng-container>
        </ng-container>
        <ng-container
            *ngIf="
                (this.assessmentOverview &&
                    widgetUrlInfo.response &&
                    widgetUrlInfo.response['assesseOverview']) ||
                widgetUrlInfo.response['assessorOverview']
            "
        >
            <app-assessment-overview
                *ngIf="widgetUrlInfo"
                [data]="widgetUrlInfo"
            ></app-assessment-overview>
        </ng-container>
        <ng-container
            *ngIf="
                this.assessmentOverview &&
                widgetUrlInfo.response &&
                widgetUrlInfo.response['framework']
            "
        >
            <app-generate-framework
                class="tw-flex-1"
                [frameworkData]="widgetUrlInfo.response"
            ></app-generate-framework>
        </ng-container>
    </ng-container>
</div>

<div
    class="pages bottom-button"
    *ngIf="
        !errorLoadingPage &&
        !pageNotFound &&
        widgetUrlInfo &&
        widgetUrlInfo.response &&
        widgetUrlInfo.response.totalPages > 1
    "
>
    <button
        [style.visibility]="currentPage === 1 ? 'hidden' : 'visible'"
        class="clickable back-page"
        (click)="changePage(currentPage - 1)"
    >
        <i class="fas fa-chevron-left"></i>
    </button>
    <div class="current-page">
        <input
            #field
            type="number"
            [value]="currentPage"
            (keyup.enter)="changePage($event.target.value, $event.target)"
            [min]="1"
            [max]="widgetUrlInfo.response.totalPages"
        />
        <div class="separator">/</div>
        <span class="totalPages">{{ widgetUrlInfo.response.totalPages }}</span>
    </div>
    <button
        [style.visibility]="
            currentPage >= widgetUrlInfo.response.totalPages
                ? 'hidden'
                : 'visible'
        "
        class="clickable next-page"
        (click)="changePage(currentPage + 1)"
    >
        <i class="fas fa-chevron-right"></i>
    </button>
</div>

<div class="page-not-found page-issue" *ngIf="pageNotFound">
    <div
        class="image-container"
        [innerHTML]="SvgData.NO_PAGE_FOUND | safeRender : SanitizeTypes.HTML"
    ></div>
    <div class="message">Page Not Found !!</div>
</div>

<div class="error-loading-widgets page-issue" *ngIf="errorLoadingPage">
    <div
        class="image-container"
        [innerHTML]="
            SvgData.ERROR_LOADING_WIDGETS | safeRender : SanitizeTypes.HTML
        "
    ></div>
    <div class="message">Error loading page data</div>
</div>

<div class="footer" *ngIf="!userDataCacheService.isAssesseeView">
    <app-footer></app-footer>
</div>

<ng-template #navigationLink>
    <button
        matTooltip="Navigate to previous control point"
        class="clickable back-page tw-w-7 tw-h-7 tw-rounded"
        (click)="goToControlPoint(false, shouldPauseNavigation.bind(this))"
    >
        <i class="fa fa-arrow-left"></i>
    </button>
    <div class="navigation-link">
        <div class="navigation-container">
            <ng-container
                *ngFor="let points of navigationPoints; let i = index"
            >
                <span
                    (click)="
                        handleNavigation(
                            points,
                            i,
                            shouldPauseNavigation.bind(this)
                        )
                    "
                    [ngClass]="
                        i === 0
                            ? 'overview-text-color clickable'
                            : i !== navigationPoints.length - 1
                            ? 'disable-text-color'
                            : 'default-text-color'
                    "
                    [style.maxWidth]="
                        i !== 0
                            ? 99 / (navigationPoints.length - 1) + '%'
                            : 'unset'
                    "
                    [class.add-three-dot]="i !== 0"
                    [matTooltip]="points.name"
                >
                    {{ points.name }}
                </span>
                <span
                    class="separator"
                    *ngIf="i !== navigationPoints.length - 1"
                >
                    {{ " >" }}
                </span>
            </ng-container>
        </div>
    </div>
    <button
        [matTooltip]="
            isDisableNextButtonNavInAssessment
                ? 'You are on last control point'
                : 'Navigate to next control point'
        "
        class="clickable next-page tw-w-7 tw-h-7 tw-rounded"
        [ngClass]="{ 'disable-text-color': isDisableNextButtonNavInAssessment }"
        [disabled]="isDisableNextButtonNavInAssessment"
        (click)="goToControlPoint(true, shouldPauseNavigation.bind(this))"
    >
        <i class="fa fa-arrow-right"></i>
    </button>
</ng-template>

<ng-template #assessmentTags>
    <div class="tw-flex tw-ml-5">
        <ng-container
            *ngFor="
                let tag of widgetUrlInfo.response['framework']['tags'];
                let i = index
            "
        >
            <ng-container *ngIf="i < 6">
                <ng-container *ngIf="i < 5; else addOn">
                    <div
                        [matTooltip]="tag.name"
                        class="tag-name"
                        [style.background-color]="tag.colorCode"
                    >
                        {{ tag.name }}
                    </div>
                </ng-container>
                <ng-template #addOn>
                    <div
                        class="tw-invisible tw-pointer-events-none"
                        [matMenuTriggerFor]="matMenu"
                        #triggerMenu="matMenuTrigger"
                    ></div>
                    <div
                        [style.min-width]="'fit-content'"
                        class="tw-text-fs-150 tw-text-neutral"
                        (mouseenter)="toggleMenu.next(true)"
                        (mouseleave)="toggleMenu.next(false)"
                    >
                        +{{
                            widgetUrlInfo.response["framework"]["tags"].length -
                                5
                        }}
                        more
                    </div>

                    <mat-menu
                        #matMenu="matMenu"
                        class="assessment-tag-mat-menu"
                    >
                        <div
                            [ngStyle]="{
                                width: '200px',
                                border: '2px solid var(--accentColor)'
                            }"
                            class="tw-flex tw-justify-start tw-flex-wrap tw-p-2"
                        >
                            <ng-container
                                *ngFor="
                                    let tag of widgetUrlInfo.response[
                                        'framework'
                                    ]['tags'];
                                    let i = index
                                "
                            >
                                <ng-container *ngIf="i > 4">
                                    <div class="tag-container">
                                        <div
                                            [matTooltip]="tag.name"
                                            [ngStyle]="{
                                                'max-width': '53px',
                                                margin: '2px'
                                            }"
                                            class="tw-truncate tw-text-fs-100 tw-font-normal tw-text-secondary tw-rounded tw-px-1"
                                            [style.background-color]="
                                                tag.colorCode
                                            "
                                        >
                                            {{ tag.name }}
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </mat-menu>
                </ng-template>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #assessmentStatus>
    <div
        *ngIf="shouldShowScoreInStatus(controlPointStatus | async)"
        class="tw-flex tw-flex-row tw-flex-row tw-items-center"
    >
        <span class="score-label">Score:&nbsp;</span>
        <div class="score-outer-circle">
            <div class="score-inner-circle">
                {{ (controlPointStatus | async).score }}
            </div>
        </div>
        &nbsp; | &nbsp;
    </div>
    <span class="status-text">{{ "Status: " }}</span>
    <span
        *ngIf="!isOptionalControlPoint"
        class="status"
        [ngStyle]="{
            color:
                (controlPointStatus | async)
                    ? (controlPointStatus | async).statusColor
                    : 'tw-text-accent'
        }"
        [class.tw-text-accent]="
            !widgetUrlInfo.response['framework']['colorCode']
        "
    >
        {{ (controlPointStatus | async)["statusName"] }}
    </span>
    <span
        *ngIf="isOptionalControlPoint"
        class="status"
        [class.tw-text-accent]="true"
    >
        {{ "-" }}
    </span>
</ng-template>
