import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomValidator } from './../classes/CustomValidator';
import { CustomValidatorKey } from './../enums/CustomValidatorKey';
export class DomainInEmailValidator extends CustomValidator {
    key = CustomValidatorKey.DEPENDENT_FIELD_VALIDATOR;
    constructor(private value: string) {
        super();
    }
    validatorFunction(control: AbstractControl): ValidationErrors {
        let domainInEmail;
        if (control && control.value) {
            domainInEmail = control.value.substring(
                control.value.lastIndexOf('@') + 1,
                control.value.lastIndexOf('.')
            );
        }
        if (domainInEmail === this.value) {
            return null;
        }
        const obj = {};
        obj[this.key] = true;
        return obj;
    }
}
