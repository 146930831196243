import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { LoginSection } from 'src/app/shared/enums/LoginSection';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { PseudoConsolePreviewSourceTypes } from 'src/app/shared/enums/PreviewModeSourceType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { ViewHttpService } from 'src/app/shared/services/http/view-http/view-http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { SessionService } from 'src/app/shared/services/session/session.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { WhiteLabelingService } from 'src/app/shared/services/white-labeling/white-labeling.service';
import { ConfigureMfaComponent } from './../../../shared/components/modal-templates/configure-mfa/configure-mfa.component';
import { ButtonColorType } from './../../../shared/enums/ButtonColorType';
import { ButtonType } from './../../../shared/enums/ButtonType';
import { ILoginResponse } from './../../../shared/interfaces/api/portlets/ILoginResponse';
import { IButtonGeneratorInput } from './../../../shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from './../../../shared/interfaces/form-generator/IFormGeneratorInput';
import { CredentialsCacheService } from './../../../shared/services/cache/credentials-cache/credentials-cache.service';
import { HttpService } from './../../../shared/services/http/http-main/http.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
    checkboxSection: boolean = false;
    resetPasswordFields: boolean = false;
    emailRecoveryMessage: boolean;
    licenseAccepted: boolean = false;
    checkedError: boolean = false;
    LoginSection = LoginSection;
    visibleSection: LoginSection;
    lastVisibleSection: LoginSection;
    url = 'iris.centilytics.com';
    loading = false;
    buttonRef;
    domainFormGenInput: IFormGeneratorInput = null;
    domainFormGroup: FormGroup;
    credentialsFormGenInput: IFormGeneratorInput = null;
    credentialsFormGroup: FormGroup;
    emailFormGenInput: IFormGeneratorInput = null;
    emailFormGroup: FormGroup;
    passwordFormGenInput: IFormGeneratorInput = null;
    passwordFormGroup: FormGroup;
    supportFormGenInput: IFormGeneratorInput = null;
    supportFormGroup: FormGroup;
    resetPasswordFormGenInput: IFormGeneratorInput = null;
    resetPasswordFormGroup: FormGroup;
    forgetPasswordFormGenInput: IFormGeneratorInput = null;
    forgetPasswordFormGroup: FormGroup;
    ssoEmailFormGenInput: IFormGeneratorInput = null;
    ssoEmailFormGroup: FormGroup;
    mfaFormGenInput: IFormGeneratorInput = null;
    mfaFormGroup: FormGroup;
    resendOtpCounter: number = 0;
    enableResendbtn: boolean = false;
    timer: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    timerData: number;
    showTimer: boolean = false;
    buttonGenInput: IButtonGeneratorInput[];
    urlToHideCopyRightFor: string[] = [
        'https://cmp.trackmycloud.com/',
        'https://stagel1.click/'
    ];
    hideCopyrightText: boolean = false;
    formSections = [
        LoginSection.DOMAINID,
        LoginSection.EMAIL,
        LoginSection.SSO_EMAIL,
        LoginSection.CREDENTIALS,
        LoginSection.MFA,
        LoginSection.SUPPORT,
        LoginSection.RESET,
        LoginSection.CONFIGURE_MFA
    ];

    twitterIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fab fa-twitter'
    };

    facebookIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fab fa-facebook-f'
    };

    linkedInIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fab fa-linkedin-in'
    };

    loginImage: IIcon = {
        type: IconType.IMAGE,
        class: this.whiteLabelService.whiteLableData.loginImage,
        alt: this.whiteLabelService.whiteLableData.companyName
    };
    logo: IIcon = {
        type: IconType.IMAGE,
        class: this.whiteLabelService.whiteLableData.darkLogo,
        alt: this.whiteLabelService.whiteLableData.companyName
    };
    bottomEllipse: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'bottom_ellipse',
        extraClass: 'svg-accent-fill'
    };

    topEllipse: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'top_ellipse',
        extraClass: 'svg-accent-fill'
    };
    assesseeCreds: any;
    errorMessage: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    ssoAuthCallBackFn: any = null;
    globalConfiguration = GlobalConfiguration;
    public privacyPolicyLink: string = '';
    public isShouldUsePrivacyPolicyUpdate: boolean = 
        GlobalConfiguration.CUSTOMER_TO_SHOW_PRIVACY_POILICY_UPDATED_MESSAGE.includes(
            window.location.hostname
        );

    constructor(
        private notificationsService: NotificationsService,
        private authenticationService: AuthenticationService,
        public whiteLabelService: WhiteLabelingService,
        private viewHttpService: ViewHttpService,
        private sessionService: SessionService,
        private userDataCache: UserDataCacheService,
        private credentialsCacheService: CredentialsCacheService,
        private modalService: ModalService,
        private cd: ChangeDetectorRef,
        private ngZone: NgZone,
        public globalDataService: GlobalDataService,
        private httpService: HttpService
    ) {
        this.globalDataService.assesseeCreds.subscribe((data) => {
            this.assesseeCreds = data;
        });
    }

    ngOnInit(): void {
        if (this.globalDataService.ssoRedirectionCreds) {
            const domainId = this.globalDataService.ssoRedirectionCreds.domainId;
            if (domainId === '3cloud' || domainId === 'centilytics' ||
                domainId === 'singapore' || domainId === 'idealimage') {
                this.globalDataService.fullPageLoader.next(true);
                this.visibleSection = null;
                this.ssoLogin(this.globalDataService.ssoRedirectionCreds);
            }
        }
        this.hideCopyrightTextFn();
        this.visibleSection = LoginSection.DOMAINID;
        this.logInView();
        this.supportView();
        this.resetView();
        this.forgetPasswordView();
        this.ssoEmailView();
        this.mfaView();
        this.globalDataService.ssoAcceptLicenseCallBackFn =
            this.checkIfSsoLicenseAccepted.bind(this);
        this.generateButtons();
        if (this.isPreviewTermsAndServices) {
            this.visibleSection = LoginSection.RESET;
            this.resetPasswordFields = true;
            this.checkboxSection = true;
        }
        if (this.isShouldUsePrivacyPolicyUpdate) {
            this.handlePrivacyPolicyUpdateMessage();
        }
    }

    handlePrivacyPolicyUpdateMessage() {
        const apiArgs: IHitApi = {
            url: ApiUrls.PRIVACY_POLICY_HYPERLINK,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.NOT_AUTHORIZED
            },
            function: (response) => {
                if (response?.privacyPolicyFile) {
                    this.privacyPolicyLink = `https://${response.privacyPolicyFile}`;
                }
            },
            errorFunction: () => {}
        };
        new HitApi(
            apiArgs,
            this.viewHttpService,
            this.viewHttpService.ngZone
        ).hitApi();
    }
    generateButtons(btnRef?) {
        if (btnRef && btnRef.loader) {
            return;
        }
        this.buttonGenInput = [
            {
                buttonName: this.getButtonName(),
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED,
                function: this.authenticate.bind(this),
                showLoader: true
            }
        ];
    }
    getButtonName() {
        switch (this.visibleSection) {
            case LoginSection.RESET:
                if (this.checkboxSection && !this.resetPasswordFields) {
                    return 'SUBMIT';
                }
            case LoginSection.RESET:
                return 'RESET';
            case LoginSection.CREDENTIALS:
                return 'SIGN IN';
            case LoginSection.MFA:
                return 'SIGN IN';
            case LoginSection.EMAIL:
                return 'SUBMIT';
            case LoginSection.SUPPORT:
                return 'SUBMIT';
            case LoginSection.SSO_EMAIL:
                return 'SUBMIT';
            case LoginSection.DOMAINID:
                return 'CONTINUE';
            default:
                return null;
        }
    }
    mfaView() {
        this.mfaFormGenInput = {
            formName: 'MFA',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Verification Code',
                    placeholder: 'Enter Verification Code',
                    name: 'verificationCode',
                    fieldType: FilterType.NUMBER,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Verification code is required'
                        }
                    ]
                }
            ]
        };
    }
    ssoEmailView() {
        this.ssoEmailFormGenInput = {
            formName: 'SSO',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Email Id',
                    placeholder: 'Email Id',
                    name: 'emailId',
                    fieldType: FilterType.EMAIL,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'email'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email is required'
                        },
                        {
                            validator: CustomValidators.email,
                            errorMessage: 'Email is invalid'
                        }
                    ]
                }
            ]
        };
    }
    forgetPasswordView() {
        this.forgetPasswordFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Email Id',
                    placeholder: 'Email Id',
                    name: 'emailId',
                    fieldType: FilterType.EMAIL,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'email'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email Id is required'
                        },
                        {
                            validator: CustomValidators.email,
                            errorMessage: 'Email Id is invalid'
                        }
                    ]
                }
            ]
        };
    }
    resetView() {
        this.resetPasswordFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Old Password',
                    placeholder: 'Old Password',
                    name: 'oldPassword',
                    fieldType: FilterType.PASSWORD,
                    required: true,
                    value:
                        this.credentialsFormGroup &&
                        this.credentialsFormGroup.get('password') &&
                        this.credentialsFormGroup.get('password').value
                            ? this.credentialsFormGroup.get('password').value
                            : null,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'lock'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Old password is required'
                        }
                    ]
                },
                {
                    label: 'New Password',
                    placeholder: 'New Password',
                    name: 'newPassword',
                    fieldType: FilterType.PASSWORD,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'lock'
                        },
                        hoverText: null
                    },
                    validations: Helper.getPasswordValidators(
                        'unequal',
                        'oldPassword'
                    )
                },
                {
                    label: 'Confirm Password',
                    placeholder: 'Confirm Password',
                    name: 'confirmPassword',
                    fieldType: FilterType.PASSWORD,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'lock'
                        },
                        hoverText: null
                    },
                    validations: Helper.getPasswordValidators(
                        'equal',
                        'newPassword'
                    )
                }
            ]
        };
    }
    supportView() {
        this.supportFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Domain Id',
                    placeholder: 'Domain Id',
                    name: 'domainId',
                    fieldType: FilterType.TEXT,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'keyboard'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Domain Id is required'
                        }
                    ]
                },
                {
                    label: 'Email Id',
                    placeholder: 'Email Id',
                    name: 'emailId',
                    fieldType: FilterType.EMAIL,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'email'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email is required'
                        },
                        {
                            validator: CustomValidators.email,
                            errorMessage: 'Email is invalid'
                        }
                    ]
                },
                {
                    label: 'Description',
                    placeholder: 'Description',
                    name: 'description',
                    fieldType: FilterType.TEXT,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'description'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Description is required'
                        }
                    ]
                }
            ]
        };
    }
    logInView() {
        this.domainFormGenInput = {
            formName: 'DomainId',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Domain Id',
                    placeholder: 'Domain Id',
                    name: 'domainId',
                    value:
                        this.assesseeCreds && this.assesseeCreds['domainId']
                            ? this.assesseeCreds['domainId']
                            : this.domainFormGroup
                            ? this.domainFormGroup.value.domainId
                            : '',
                    fieldType: FilterType.TEXT,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'keyboard'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Domain Id is required'
                        }
                    ]
                }
            ]
        };
        this.credentialsFormGenInput = {
            formName: 'credentials',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Email Id',
                    placeholder: 'Email Id',
                    name: 'emailId',
                    fieldType: FilterType.EMAIL,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'email'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email is required'
                        },
                        {
                            validator: CustomValidators.email,
                            errorMessage: 'Email is invalid'
                        }
                    ]
                },
                {
                    label: 'Password',
                    placeholder: 'Password',
                    name: 'password',
                    fieldType: FilterType.PASSWORD,
                    required: true,
                    value:
                        this.assesseeCreds && this.assesseeCreds['password']
                            ? this.assesseeCreds['password']
                            : '',
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'lock'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Password is required'
                        }
                    ]
                }
            ]
        };
        this.generateButtons();
    }

    authenticate(btnRef?) {
        if (this.loading) {
            return;
        }
        if (btnRef && btnRef.loader) {
            return;
        }
        if (this.isPreviewTermsAndServices) {
            return;
        }
        if (btnRef) {
            this.buttonRef = btnRef;
        }

        if (this.ssoAuthCallBackFn) {
            const inputs = {};
            let valid = true;
            if (this.checkboxSection && !this.licenseAccepted) {
                this.checkedError = true;
                valid = false;
            }
            if (!valid) {
                return;
            }
            if (this.checkboxSection) {
                inputs['licenseAgree'] = this.licenseAccepted;
            }
            this.ssoAuthCallBackFn(
                this.httpService.samlAuthorizationToken,
                inputs
            );
            return;
        }

        Helper.markAllFieldAsTouched(this.domainFormGroup);
        if (
            this.visibleSection === LoginSection.DOMAINID &&
            this.domainFormGroup.invalid
        ) {
            this.domainFormGroup.updateValueAndValidity();
            return;
        } else {
            // TODO: To be removed later. Only added for debugging purpose.
            this.userDataCache.domainId =
                this.domainFormGroup.value['domainId'];

            if (this.visibleSection === LoginSection.DOMAINID) {
                const domainID = this.domainFormGroup.value['domainId'];
                this.getDomainIdRegion(btnRef, domainID);
                this.visibleSection = LoginSection.CREDENTIALS;
            } else if (this.visibleSection === LoginSection.CREDENTIALS) {
                Helper.markAllFieldAsTouched(this.credentialsFormGroup);
                if (this.credentialsFormGroup.invalid) {
                    this.credentialsFormGroup.updateValueAndValidity();
                    return;
                } else {
                    this.hitLoginApi();
                }
            } else if (this.visibleSection === LoginSection.EMAIL) {
                Helper.markAllFieldAsTouched(this.forgetPasswordFormGroup);
                if (this.forgetPasswordFormGroup.invalid) {
                    this.forgetPasswordFormGroup.updateValueAndValidity();
                    return;
                } else {
                    this.hitForgetPasswordApi();
                }
            } else if (this.visibleSection === LoginSection.SUPPORT) {
                Helper.markAllFieldAsTouched(this.supportFormGroup);
                if (this.supportFormGroup.invalid) {
                    this.supportFormGroup.updateValueAndValidity();
                    return;
                }
            } else if (this.visibleSection === LoginSection.RESET) {
                let valid = true;
                if (this.checkboxSection && !this.licenseAccepted) {
                    this.checkedError = true;
                    valid = false;
                }
                if (this.resetPasswordFields) {
                    Helper.markAllFieldAsTouched(this.resetPasswordFormGroup);
                    this.resetPasswordFormGroup
                        .get('confirmPassword')
                        .updateValueAndValidity();
                    if (this.resetPasswordFormGroup.invalid) {
                        this.resetPasswordFormGroup.updateValueAndValidity();
                        valid = false;
                    }
                }
                if (!valid) {
                    return;
                }

                if (this.resetPasswordFields) {
                    // First Accept, then reset
                    this.hitLoginApi((response: ILoginResponse) => {
                        if (response.tokens && response.tokens.bearerToken) {
                            this.viewHttpService.bearerToken =
                                response.tokens.bearerToken;
                        } else {
                            this.errorMessage.next(
                                Messages.LOGIN_API_INVALID_RESPONSE
                            );
                            this.endLoader();
                            return;
                        }
                        this.hitChangePasswordApi(true);
                    });
                } else if (this.checkboxSection) {
                    // Only Accept
                    this.hitLoginApi();
                } else if (this.resetPasswordFields) {
                    // Only reset
                    this.hitChangePasswordApi(false);
                }
            } else if (this.visibleSection === LoginSection.MFA) {
                Helper.markAllFieldAsTouched(this.mfaFormGroup);
                if (this.mfaFormGroup.invalid) {
                    this.mfaFormGroup.updateValueAndValidity();
                    return;
                } else {
                    this.verifyMFA();
                }
            } else if (this.visibleSection === LoginSection.SSO_EMAIL) {
                Helper.markAllFieldAsTouched(this.ssoEmailFormGroup);
                if (this.ssoEmailFormGroup.invalid) {
                    this.ssoEmailFormGroup.updateValueAndValidity();
                    return;
                } else {
                    this.startLoader();
                    this.ssoLogin();
                }
            }
        }
        this.errorMessage.next('');
        if (btnRef) {
            this.generateButtons(btnRef);
        }
    }
    getDomainIdRegion(btnRef: any, domainId) {
        if (btnRef) {
            btnRef.showLoader = true;
        }
        const apiArgs: IHitApi = {
            url: `${ApiUrls.GET_DOMAIN_ID_REGION}${domainId}`,
            requestType: RequestType.GET,
            input: {},
            config: {
                authorization: AuthorizationType.NOT_AUTHORIZED,
                skipBypassRegion: true
            },
            uniqueIdentity: Symbol(),
            function: (response) => {
                if (response?.region) {
                    this.globalDataService.domainIdRegion = response.region;
                }
            },
            errorFunction: (err) => {
                if (btnRef) btnRef.showLoader = false;
            },
            endFunction: () => {
                if (btnRef) btnRef.showLoader = false;
            }
        };
        new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
    }
    goBack() {
        if (this.loading) {
            return;
        }
        if (this.isPreviewTermsAndServices) {
            return;
        }
        this.checkboxSection = false;
        this.emailRecoveryMessage = false;
        if (this.ssoAuthCallBackFn) {
            this.visibleSection = LoginSection.SSO_EMAIL;
            this.domainFormGroup
                .get('domainId')
                .setValue(this.userDataCache.domainId);
            this.ssoAuthCallBackFn = null;
        } else if (
            this.visibleSection === LoginSection.CREDENTIALS ||
            this.visibleSection === LoginSection.SSO_EMAIL
        ) {
            this.logInView();
            this.visibleSection = LoginSection.DOMAINID;
        } else if (this.visibleSection === LoginSection.MFA) {
            this.mfaFormGroup.reset();
            this.visibleSection = LoginSection.CREDENTIALS;
        } else if (this.visibleSection === LoginSection.SUPPORT) {
            this.supportFormGroup.reset();
            this.visibleSection = this.lastVisibleSection;
        } else if (this.visibleSection === LoginSection.RESET) {
            this.visibleSection = LoginSection.CREDENTIALS;
        } else if (this.visibleSection === LoginSection.EMAIL) {
            this.credentialsFormGenInput.fields.find(
                (field) => field.name === 'emailId'
            ).value = this.forgetPasswordFormGroup.value.emailId;

            this.visibleSection = LoginSection.CREDENTIALS;
        }
        this.generateButtons();
        this.errorMessage.next('');
    }
    startLoader() {
        if (this.buttonRef && window.innerWidth < 841) {
            this.buttonRef.loader = true;
        } else {
            this.loading = true;
        }
        this.cd.detectChanges();
    }
    endLoader() {
        if (this.buttonRef) {
            this.buttonRef.loader = false;
        }
        this.loading = false;
        this.cd.detectChanges();
    }

    resetDomain() {
        if (this.domainFormGroup && this.domainFormGroup.valid) {
            this.domainFormGroup.reset();
        }
    }

    checkIfMFAEnabled(response: ILoginResponse) {
        if (
            response &&
            (response.expiredLogin || !response.licenseAccepted) &&
            response.root
        ) {
            if (response && response.expiredLogin) {
                this.resetView();
                this.resetPasswordFields = true;
            }
            if (response && !response.licenseAccepted && response.root) {
                this.checkboxSection = true;
                if (response?.licenseAgreementFile) {
                    this.userDataCache.termsOfServicesFile =
                        response?.licenseAgreementFile;
                }
                if (typeof response?.showLicenseAgreement === 'boolean') {
                    this.userDataCache.showLicenseAgreement =
                        response?.showLicenseAgreement;
                } else {
                    this.userDataCache.showLicenseAgreement = true;
                }
            }
            if (response.tokens && response.tokens.bearerToken) {
                this.viewHttpService.bearerToken = response.tokens.bearerToken;
            }
            this.visibleSection = LoginSection.RESET;
            this.generateButtons();
            this.endLoader();
            this.cd.detectChanges();
            return;
        }
        if (
            response &&
            !response.root &&
            !response.licenseAccepted &&
            !response['assessmentId']
        ) {
            this.errorMessage.next(Messages.ROOT_LICENSE_ACCEPT);
            this.endLoader();
            return;
        }
        if (response && !response.root && response.expiredLogin) {
            this.resetView();
            this.resetPasswordFields = true;
            this.visibleSection = LoginSection.RESET;
            this.generateButtons();
            this.endLoader();
            this.cd.detectChanges();
            return;
        }
        this.errorMessage.next('');
        if (response && !response.configuredMfa && response.enabledMfa) {
            this.endLoader();
            this.sessionService.setAuthorizationTokens(response);
            const modalData: IModalData = {
                modalName: 'Multi Factor Authentication',
                modalIcon: {
                    type: IconType.MATICON,
                    class: 'verified_user'
                },
                sourceId: Symbol(),
                modalType: ModalType.MIDDLE,
                modalHeightVh: 85,
                modalWidthVw: 60,
                modalSteps: [
                    {
                        stepData: {
                            componentToLoad: ConfigureMfaComponent,
                            payload: {
                                data: {
                                    response: response,
                                    callbackFn:
                                        this.checkIfMFAEnabled.bind(this)
                                }
                            }
                        },
                        stepName: 'Verification'
                    }
                ]
            };
            this.modalService.openModal(modalData);
            return;
        }

        if (
            'enabledMfa' in response &&
            response.enabledMfa &&
            !response.tokens
        ) {
            this.visibleSection = LoginSection.MFA;
            if (this.resendOtpCounter === 0) {
                this.resendTimer();
                setTimeout(() => {
                    this.resendOtpCounter++;
                    this.enableResendbtn = true;
                }, 60000);
            }
            this.endLoader();
        } else {
            this.credentialsCacheService.masterLoginDiveIn(response);
            this.sessionService.login(
                response,
                this.viewHttpService,
                false,
                () => {
                    this.errorMessage.next(Messages.LOGIN_API_INVALID_RESPONSE);
                    this.credentialsCacheService.masterLoginDiveOut();
                    this.endLoader();
                }
            );
        }
    }
    checkIfSsoLicenseAccepted(response: ILoginResponse, callBackFn?) {
        if (callBackFn) {
            this.ssoAuthCallBackFn = callBackFn;
        }
        if (response && !response.licenseAccepted && response.root) {
            if (response && !response.licenseAccepted && response.root) {
                this.checkboxSection = true;
            }
            if (response.tokens && response.tokens.bearerToken) {
                this.viewHttpService.bearerToken = response.tokens.bearerToken;
            }
            this.visibleSection = LoginSection.RESET;
            this.endLoader();
            this.cd.detectChanges();
            return;
        }
        if (response && !response.root && !response.licenseAccepted) {
            this.visibleSection = LoginSection.SSO_EMAIL;
            this.domainFormGroup
                .get('domainId')
                .setValue(this.userDataCache.domainId);
            this.errorMessage.next(Messages.ROOT_LICENSE_ACCEPT);
            this.endLoader();
            this.ssoAuthCallBackFn = null;
            return;
        }
    }
    verifyMFA() {
        this.startLoader();
        const obj = {
            input: {
                domainId:
                    this.domainFormGroup &&
                    this.domainFormGroup.value &&
                    this.domainFormGroup.value.domainId
                        ? this.domainFormGroup.value.domainId
                        : null,
                root:
                    this.domainFormGroup &&
                    this.domainFormGroup.value &&
                    this.domainFormGroup.value.domainId
                        ? false
                        : true,
                email: this.credentialsFormGroup.value.emailId,
                password: this.credentialsFormGroup.value.password,
                twoFactorAuthCode:
                    this.mfaFormGroup.get('verificationCode').value
            },
            endFunction: this.endLoader.bind(this),
            function: this.checkIfMFAEnabled.bind(this),
            errorMessage: this.errorMessage
        };

        this.authenticationService.login(obj);
    }

    hitLoginApi(callback?) {
        if (this.loading) {
            return;
        }
        const inputs = {
            domainId:
                this.domainFormGroup &&
                this.domainFormGroup.value &&
                this.domainFormGroup.value.domainId
                    ? this.domainFormGroup.value.domainId
                    : null,
            root:
                this.domainFormGroup &&
                this.domainFormGroup.value &&
                this.domainFormGroup.value.domainId
                    ? false
                    : true,
            email: this.credentialsFormGroup.value.emailId,
            password: this.credentialsFormGroup.value.password
        };
        if (this.checkboxSection) {
            inputs['licenseAgree'] = this.licenseAccepted;
        }
        if (this.visibleSection !== LoginSection.MFA) {
            this.startLoader();
        }

        const obj = {
            input: inputs,
            function: callback
                ? callback.bind(this)
                : this.checkIfMFAEnabled.bind(this),
            endFunction: null,
            errorFunction: () => {
                this.endLoader();
                this.showTimer = false;
            },
            errorMessage: this.errorMessage
        };

        this.authenticationService.login(
            obj,
            this.assesseeCreds ? true : false
        );
    }
    hitForgetPasswordApi() {
        if (this.loading) {
            return;
        }
        this.startLoader();
        const obj = {
            input: {
                domainId: this.domainFormGroup.value.domainId,
                email: this.forgetPasswordFormGroup.value.emailId
            },
            function: this.emailRecovery.bind(this),
            endFunction: this.endLoader.bind(this),
            errorMessage: this.errorMessage
        };

        this.authenticationService.forgetPassword(obj);
    }
    emailRecovery() {
        this.errorMessage.next('');
        this.emailRecoveryMessage = true;
    }
    hitChangePasswordApi(inChain?) {
        if (this.loading && !inChain) {
            return;
        }

        this.startLoader();
        const obj = {
            input: {
                oldPassword: this.resetPasswordFormGroup.value.oldPassword,
                newPassword: this.resetPasswordFormGroup.value.newPassword
            },
            function: () => {
                if (this.assesseeCreds) {
                    this.assesseeCreds['password'] = null;
                }
                this.visibleSection = LoginSection.CREDENTIALS;
                this.logInView();
                // if (hitLogin) {
                //     this.credentialsFormGroup
                //         .get('password')
                //         .setValue(
                //             this.resetPasswordFormGroup.value.newPassword
                //         );
                //     this.hitLoginApi();
                // }
            },
            errorFunction: () => {
                this.endLoader();
            },
            endFunction: this.endLoader.bind(this),
            errorMessage: this.errorMessage
        };

        this.authenticationService.changePassword(obj);
    }

    moveTo(section) {
        if (this.loading) {
            return;
        }
        if (section === LoginSection.SUPPORT) {
            this.lastVisibleSection = this.visibleSection;
        }
        if (section === LoginSection.SSO_EMAIL) {
            Helper.markAllFieldAsTouched(this.domainFormGroup);
            if (
                this.visibleSection === LoginSection.DOMAINID &&
                this.domainFormGroup.invalid
            ) {
                this.domainFormGroup.updateValueAndValidity();
                return;
            }
        }
        this.visibleSection = section;
        this.generateButtons();
        this.errorMessage.next('');
    }
    resendOtp() {
        if (!this.enableResendbtn) {
            return;
        }

        if (this.resendOtpCounter === 3) {
            this.enableResendbtn = false;
            this.showTimer = false;
            return;
        }
        this.enableResendbtn = false;
        setTimeout(() => {
            if (this.resendOtpCounter < 2) {
                this.enableResendbtn = true;
                this.showTimer = false;
                this.timerData = 60;
            }
            this.resendOtpCounter++;
        }, 60000);
        this.resendTimer();
        this.hitLoginApi();
    }
    resendTimer() {
        this.timerData = 60;
        this.timer.next(this.timerData);
        this.showTimer = true;
        this.ngZone.runOutsideAngular(() => {
            const timer = setInterval(() => {
                this.ngZone.run(() => {
                    this.timerData--;
                    this.timer.next(this.timerData);
                });
            }, 1000);

            setTimeout(() => {
                clearInterval(timer);
                this.showTimer = false;
            }, 60000);
        });
    }
    hideCopyrightTextFn() {
        const currentUrl = window.location.href;
        if (this.urlToHideCopyRightFor.includes(currentUrl)) {
            this.hideCopyrightText = true;
        }
    }

    ssoLogin(ssoRedirectionCreds?: { domainId: string; email: string }) {
        new HitApi(
            {
                url: ApiUrls.SSO_CHECK,
                uniqueIdentity: Symbol(),
                requestType: RequestType.POST,
                input: {
                    domainId: ssoRedirectionCreds
                        ? ssoRedirectionCreds.domainId
                        : this.domainFormGroup &&
                          this.domainFormGroup.value &&
                          this.domainFormGroup.value.domainId
                        ? this.domainFormGroup.value.domainId
                        : null,
                    email: ssoRedirectionCreds
                        ? ssoRedirectionCreds.email
                        : this.ssoEmailFormGroup &&
                          this.ssoEmailFormGroup.value &&
                          this.ssoEmailFormGroup.value.emailId
                        ? this.ssoEmailFormGroup.value.emailId
                        : null
                },
                config: {
                    authorization: AuthorizationType.NOT_AUTHORIZED,
                    skipBypassRegion: true
                },
                function: (response) => {
                    if (response && response.redirectUrl) {
                        const url =
                            response.redirectUrl +
                            '&redirect_uri=' +
                            window.location.origin;
                        this.endLoader();
                        Helper.navigateTo(url, 'SAME_PAGE');
                    } else {
                        Helper.showErrorMessage(
                            this.notificationsService,
                            null,
                            Messages.LOGIN_API_INVALID_RESPONSE
                        );
                        this.endLoader();
                        this.moveTo(LoginSection.CREDENTIALS);
                    }
                },
                errorFunction: (error) => {
                    Helper.showErrorMessage(
                        this.notificationsService,
                        error,
                        Messages.LOGIN_API_INVALID_RESPONSE
                    );
                    this.endLoader();
                    this.globalDataService.fullPageLoader.next(false);
                    this.moveTo(LoginSection.CREDENTIALS);
                }
            },
            this.viewHttpService,
            this.viewHttpService.ngZone
        ).hitApi();
    }

    get isPreviewTermsAndServices(): boolean {
        return (
            this.globalConfiguration?.PREVIEW_MODE &&
            this.globalConfiguration?.pseudoConsoleConfig?.previewSource ===
                PseudoConsolePreviewSourceTypes.PREVIEW_SOURCE_TERMS_OF_SERVICE
        );
    }

    get isPreviewTermsHidden(): boolean {
        return (
            this.globalConfiguration?.PREVIEW_MODE &&
            this.globalConfiguration?.pseudoConsoleConfig?.shouldHideTerms
        );
    }

    get isShowLicenseAgreement(): boolean {
        return this.userDataCache.showLicenseAgreement;
    }

    get termsOfServicesUrl() {
        let link = this.userDataCache.termsOfServicesFile;
        if (!link?.startsWith('http')) {
            link = 'https://' + link;
        }
        return link;
    }
}
