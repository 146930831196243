import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { ApiResponseCacheService } from '../../cache/api-response-cache/api-response-cache.service';
import { WidgetCacheService } from '../../cache/widget-cache/widget-cache.service';
import { IotService } from '../../iot/iot.service';
import { HttpService } from '../http-main/http.service';

@Injectable({
    providedIn: 'root'
})
export class ViewHttpService extends HttpService {
    constructor(
        http: HttpClient,
        public widgetCacheService: WidgetCacheService,
        iotService: IotService,
        apiResponseCacheService: ApiResponseCacheService,
        ngZone: NgZone
    ) {
        super(http, iotService, apiResponseCacheService, ngZone);
    }

    getSidebarDataForView(viewId, resolve, reject, apiUrl?) {
        const apiArgs: IHitApi = {
            url: `${apiUrl ? apiUrl : ApiUrls.VIEW_SIDE_MENU_DATA}${viewId}`,
            intactUrl: `${
                apiUrl ? apiUrl : ApiUrls.VIEW_SIDE_MENU_DATA
            }{viewId}`,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (response) => {
                resolve(response);
            },
            errorFunction: (error) => {
                reject(error);
            }
        };

        new HitApi(apiArgs, this, this.ngZone).hitApi();
    }
}
