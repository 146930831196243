<div
    *ngIf="iframeData"
    class="tw-h-full tw-w-full tw-overflow-auto tw-px-5 tw-pb-0"
    [innerHTML]="iframeData | safeRender : SanitizeTypes.HTML"
></div>

<ng-container *ngIf="url">
    <div #closeButton class="close-modal-container">
        <div
            class="close-modal"
            (click)="
                modalService.closeModal(null, modalService.modalData.modalId)
            "
        >
            Exit Preview
        </div>
    </div>
    <iframe
        id="preview"
        style="height: 100%; width: 100%"
        [src]="url | safeRender : SanitizeTypes.RESOURCE_URL"
    ></iframe>
</ng-container>
