import { Component, OnInit } from '@angular/core';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IApiInfo } from 'src/app/shared/interfaces/api/IApiInfo';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';

@Component({
    selector: 'app-associated-users',
    templateUrl: './associated-users.component.html',
    styleUrls: ['./associated-users.component.sass']
})
export class AssociatedUsersComponent implements OnInit {
    tableGenInput: ITableGeneratorInput;
    apiInfo: IApiInfo;

    viewId: string;

    constructor(private modalInjectedData: ModalInjectedData) {
        this.viewId = modalInjectedData.data['viewId'];
    }

    ngOnInit(): void {
        this.tableGenInput = {
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'userAssociations'
            },
            columns: [
                {
                    columnKey: 'userName',
                    columnName: 'User Name',
                    filter: false
                },
                {
                    columnKey: 'emailId',
                    columnName: 'Email ID',
                    filter: false
                }
            ]
        };
        this.apiInfo = {
            host: '',
            apiRouteSuffix: ApiUrls.VIEW_ASSOCIATED_USERS_LISTING.replace(
                '{view-id}',
                this.viewId
            ),
            intactUrl: ApiUrls.VIEW_ASSOCIATED_USERS_LISTING,
            authorization: AuthorizationType.BEARER_TOKEN,
            requestType: RequestType.GET
        };
    }
}
