import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { RedirectionHandleService } from 'src/app/shared/services/redirection-handle/redirection-handle.service';
import { SubSink } from 'subsink';
import { Helper } from '../../classes/Helper';
import { ModalInjectedData } from '../../classes/ModalInjectedData';
import { AuthorizationType } from '../../enums/AuthorizationType';
import { ButtonColorType } from '../../enums/ButtonColorType';
import { ButtonType } from '../../enums/ButtonType';
import { IconType } from '../../enums/IconType';
import { PortletType } from '../../enums/PortletType';
import { PortletVersion } from '../../enums/PortletVersion';
import { RequestType } from '../../enums/RequestType';
import { IIcon } from '../../interfaces/icon-data/IIcon';
import { IWidgetData } from '../../interfaces/widget/IWidgetData';
import { CartCacheService } from '../../services/cache/cart-cache/cart-cache.service';
import { CredentialsCacheService } from '../../services/cache/credentials-cache/credentials-cache.service';
import { GlobalDataService } from '../../services/global-data/global-data.service';
import { ViewHttpService } from '../../services/http/view-http/view-http.service';
import { WidgetHttpService } from '../../services/http/widget-http/widget-http.service';
import { ModalService } from '../../services/modal/modal-service/modal.service';
import { SessionService } from '../../services/session/session.service';
import { UserDataCacheService } from '../../services/user-data-cache/user-data-cache.service';
import { IButtonGeneratorInput } from './../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-widget-preview-modal',
    templateUrl: './widget-preview-modal.component.html',
    styleUrls: ['./widget-preview-modal.component.sass']
})
export class WidgetPreviewModalComponent implements OnInit {
    widgetSubs = new SubSink();
    widgetMetaData = null;
    widgetId = null;
    widgetData: Map<string, IWidgetData> = new Map<string, IWidgetData>();
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    showInsight = false;
    redirectionDisabled = true;
    url = null;
    buttonInput: IButtonGeneratorInput;

    hasStaticStyling: boolean = false;

    constructor(
        private modalInjectedData: ModalInjectedData,
        private widgetHttpService: WidgetHttpService,
        private globalDataService: GlobalDataService,
        private modalService: ModalService,
        private router: Router,
        private viewHttpService: ViewHttpService,
        private credentialsCacheService: CredentialsCacheService,
        private userDataCache: UserDataCacheService,
        private sessionService: SessionService,
        private cartCache: CartCacheService,
        private redirectionHandleService: RedirectionHandleService,
        public ngZone: NgZone
    ) {
        this.widgetMetaData = modalInjectedData.data['widgetData'];
        this.widgetId = this.widgetMetaData['Widget Id'];
        if (this.widgetMetaData && this.widgetMetaData['View Id']) {
            this.redirectionDisabled = false;
        }
    }

    ngOnInit(): void {
        if (this.widgetId && this.widgetId.length) {
            this.widgetHttpService.loadWidgetsData(
                this.widgetHttpService,
                this.widgetSubs,
                this.widgetId,
                this.widgetData,
                this.openWidget.bind(this),
                false
            );
        }
        this.buttonInput = {
            buttonName: 'Go To Widget',
            function: () => {
                this.handleRedirection();
            },
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            hoverText: this.redirectionDisabled
                ? 'No view associated with widget'
                : '',
            customClass: 'goTo-btn'
        };
    }

    openWidget() {
        if (
            this.widgetData.has(this.widgetId) &&
            this.widgetData.get(this.widgetId)
        ) {
            this.hasStaticStyling = this.isStaticallyStyled();
            this.showInsight = true;
        }
    }

    handleRedirection() {
        if (this.redirectionDisabled) {
            return;
        }
        let urlPath = '';

        Object.keys(this.widgetMetaData).forEach((key) => {
            if (key.includes('Layer') && this.widgetMetaData[key]) {
                urlPath += '/' + this.widgetMetaData[key];
            }
        });

        this.url = Helper.urlFormatter(urlPath);

        if (
            this.widgetMetaData['View Id'] ===
            this.globalDataService.selectedView
        ) {
            this.performRedirection(this.url);
        } else {
            this.redirectionHandleService.urlToRedirect = this.url;
            this.redirectionHandleService.widgetIdToRedirect = this.widgetId;
            this.globalDataService.changeView(
                this.userDataCache.emailId,
                this.widgetMetaData['View Id'],
                this.credentialsCacheService,
                this.viewHttpService,
                this.sessionService,
                this.cartCache
            );
        }
    }

    performRedirection(url) {
        this.globalDataService.loadWidgetsFromApiArgs.next({
            url: ApiUrls.LOGIN_REDIRECT_URL,
            input: {
                viewId: this.widgetMetaData['View Id'],
                url: Helper.urlFormatter(this.url),
                widgetId: this.widgetId
            },
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            function: null,
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
                extraData: {
                    pageName: null
                }
            }
        });
        this.router.navigateByUrl(Helper.urlFormatter(this.url));
        this.modalService.closeModal(null, this.modalInjectedData.modalId);

        this.globalDataService.allWidgetsLoaded.subscribe((response) => {
            if (response) {
                this.ngZone.runOutsideAngular(() => {
                    setTimeout(() => {
                        if (document.getElementById(this.widgetId)) {
                            Helper.doScroll(this.widgetId, 'end');
                        }
                    }, 1000);
                });
            }
        });
    }

    isStaticallyStyled() {
        const widgetInfoPortlet = this.widgetData.get(this.widgetId).widgetInfo
            .portlet;
        if (widgetInfoPortlet.version === PortletVersion._2) {
            if (widgetInfoPortlet.type === PortletType.WHITELABEL_INTEGRATION) {
                return true;
            }
        }
        return false;
    }
}
