import { Component, OnInit } from '@angular/core';
import { RowEvent } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { FreshserviceIntegrationStepOneComponent } from '../freshservice-integration-step-one/freshservice-integration-step-one.component';
import { FreshserviceIntegrationStepThreeComponent } from '../freshservice-integration-step-three/freshservice-integration-step-three.component';
import { FreshserviceIntegrationStepTwoComponent } from '../freshservice-integration-step-two/freshservice-integration-step-two.component';
import { ButtonColorType } from './../../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../../enums/ButtonType';
import { MultiButtonGeneratorComponent } from './../../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-freshservice-integrations-table',
    templateUrl: './freshservice-integrations-table.component.html',
    styleUrls: ['./freshservice-integrations-table.component.sass']
})
export class FreshserviceIntegrationsTableComponent implements OnInit {
    widgetRef: Widget;
    tableGenInput: ITableGeneratorInput = null;
    tableData = [];
    constructor(private modalInputData: ModalInjectedData) {
        this.widgetRef = modalInputData.data['widgetRef'];
        this.tableData = this.tableData.concat(
            modalInputData.data['response']['childConfigurationListing']
        );
        modalInputData.data['response']['parentConfigurationListing'].forEach(
            (element) => {
                const obj = element;
                obj.disable = true;
                this.tableData.push(obj);
            }
        );
    }

    ngOnInit(): void {
        this.setUpBasics();
    }
    setUpBasics() {
        this.tableGenInput = {
            listExtraction: {
                type: 'DIRECT'
            },
            noDataMessage: 'No Integrations Available',
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-user-plus'
            },
            columns: [
                {
                    columnKey: 'configurationName',
                    columnName: 'Configuration Name'
                },
                {
                    columnKey: 'action',
                    columnName: 'Action',
                    pinned: 'right',
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: RowEvent) => {
                        rowData['buttonGenInputs'] = [
                            {
                                buttonName: 'Edit',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.INFO,
                                hoverText: 'Edit Freshservice',
                                disable: rowData.data.disable,
                                function: () => {
                                    this.openIntegrationModalData(
                                        rowData,
                                        true
                                    );
                                }
                            },
                            // Adding delete Button
                            {
                                buttonName: 'Delete',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.WARN,
                                disable: rowData.data.disable,
                                hoverText: 'Remove Freshservice',
                                function: (modalId: Symbol) => {
                                    // Delete integration

                                    const args = Helper.generateHitApiConfig(
                                        this.widgetRef.widgetData.widgetInfo
                                            .delete
                                    );
                                    args.intactUrl = args.url;
                                    args.url = args.url.replace(
                                        '{configuration-name}',
                                        rowData.data.configurationName
                                    );
                                    args.input = {};
                                    args.function = (response) => {
                                        this.widgetRef.notificationsService.showSnackBar(
                                            `Freshservice integration removed successfully`
                                        );
                                        this.widgetRef.modalService.closeModal(
                                            null,
                                            modalId
                                        );
                                        this.widgetRef.refreshWidget();
                                    };
                                    args.errorFunction = (error) => {
                                        this.widgetRef.notificationsService.showSnackBar(
                                            error.error.message,
                                            true
                                        );
                                        this.widgetRef.modalService.closeModal(
                                            null,
                                            modalId
                                        );
                                    };
                                    new HitApi(
                                        args,
                                        this.widgetRef.httpService,
                                        this.widgetRef.ngZone
                                    ).hitApi();
                                    this.widgetRef.modalService.closeModal(
                                        null,
                                        this.modalInputData.modalId
                                    );
                                },
                                executeFunctionAfterConfirmation: {
                                    modalName:
                                        'Remove Freshservice Integration',
                                    modalIcon: {
                                        type: IconType.FONTAWSOME,
                                        class: 'far fa-trash-alt'
                                    },
                                    contextIcon: {
                                        extraClass: 'color-accent',
                                        type: IconType.FONTAWSOME,
                                        class: 'fas fa-exclamation-triangle'
                                    },
                                    confirmationMessage: `Are you sure you want to remove freshservice ?`,
                                    buttonText: 'Remove',
                                    buttonColorType: ButtonColorType.WARN,
                                    loaderOnExec: true
                                }
                            }
                        ];
                        return rowData;
                    }
                }
            ],
            afterResponse: null,
            selection: 'single'
        };
    }

    openIntegrationModalData(rowData, edit?) {
        const modalArgs: IModalData = {
            modalName: 'Fresh Service Setup',
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalIcon: {
                type: null,
                class: null
            },
            extraStepContainerClass: 'remove-form-space',
            modalSteps: [
                {
                    stepName: 'Credentials',
                    stepData: {
                        componentToLoad:
                            FreshserviceIntegrationStepOneComponent,
                        payload: {
                            data: {
                                edit: edit,
                                response: rowData.data,
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                },
                {
                    stepName: 'Configure Fields',
                    stepData: {
                        componentToLoad:
                            FreshserviceIntegrationStepTwoComponent,
                        payload: {
                            data: {
                                edit: edit,
                                response: rowData.data,
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                },
                {
                    stepName: 'Select Customer',
                    stepData: {
                        componentToLoad:
                            FreshserviceIntegrationStepThreeComponent,
                        payload: {
                            data: {
                                edit: edit,
                                response: rowData.data,
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                }
            ],
            modalWidthVw: 60,
            modalHeightVh: 90
        };
        this.widgetRef.modalService.openModal(modalArgs);
        this.widgetRef.modalService.closeModal(
            null,
            this.modalInputData.modalId
        );
    }
}
