<!--Field type one start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_1">
    <div
        class="date-time-container {{ field.extraClass }}"
        [class.error]="
            field.groupByKey
                ? formGroup.get(field.groupByKey).get(field.name) &&
                  formGroup.get(field.groupByKey).get(field.name).touched &&
                  formGroup.get(field.groupByKey).get(field.name).errors
                : formGroup.get(field.name) &&
                  formGroup.get(field.name).touched &&
                  formGroup.get(field.name).errors
        "
        [class.disabled]="
            formGeneratorInput.state === FormState.IDLE || field.disabled
        "
    >
        <mat-form-field
            #dateFieldRef
            [appearance]="'outline'"
            [color]="'accent'"
            [hideRequiredMarker]="true"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
        >
            <mat-label>
                {{ field.label }}
                <span
                    *ngIf="field.required !== undefined ? field.required : true"
                    class="color-warn"
                >
                    &nbsp;*
                </span>
            </mat-label>

            <input
                matInput
                (click)="
                    formGeneratorInput.state === FormState.IDLE ||
                    field.disabled
                        ? null
                        : picker.open()
                "
                [placeholder]="
                    field.placeholder ? field.placeholder : field.label
                "
                [class.disabled]="
                    formGeneratorInput.state === FormState.IDLE ||
                    field.disabled
                "
                [disabled]="
                    formGeneratorInput.state === FormState.IDLE ||
                    field.disabled
                        ? true
                        : false
                "
                [min]="
                    field.minDate
                        ? field.minDate
                        : field.groupByKey
                        ? field.minDateFromControl &&
                          formGroup
                              .get(field.groupByKey)
                              .get(field.minDateFromControl)
                            ? formGroup
                                  .get(field.groupByKey)
                                  .get(field.minDateFromControl).value
                            : null
                        : field.minDateFromControl &&
                          formGroup.get(field.minDateFromControl)
                        ? formGroup.get(field.minDateFromControl).value
                        : null
                "
                [max]="
                    field.maxDate
                        ? field.maxDate
                        : field.groupByKey
                        ? field.maxDateFromControl &&
                          formGroup
                              .get(field.groupByKey)
                              .get(field.maxDateFromControl)
                            ? formGroup
                                  .get(field.groupByKey)
                                  .get(field.maxDateFromControl).value
                            : null
                        : field.maxDateFromControl &&
                          formGroup.get(field.maxDateFromControl)
                        ? formGroup.get(field.maxDateFromControl).value
                        : null
                "
                [matDatepicker]="picker"
                [required]="field.required"
                [name]="field.name"
                (focus)="
                    field.prefixIcon ? dateFieldRef.updateOutlineGap() : ''
                "
                [value]="
                    field.value && field.value.dateValue
                        ? field.value.dateValue
                        : ''
                "
                (dateChange)="dateChanged($event, field.name, field.groupByKey)"
            />

            <mat-datepicker-toggle
                matSuffix
                [for]="picker"
            ></mat-datepicker-toggle>

            <mat-datepicker #picker></mat-datepicker>

            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.prefixIcon.iconData.class }}
            </mat-icon>
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>
        </mat-form-field>

        <div class="time-picker-form-field">
            <div class="time-picker-container">
                <span class="label">
                    Time
                    <span
                        *ngIf="
                            field.required !== undefined ? field.required : true
                        "
                        class="color-warn"
                        >*
                    </span>
                </span>
                <ngx-mat-timepicker-field
                    #timePicker
                    class="light-mode"
                    [controlOnly]="true"
                    [disabled]="
                        formGeneratorInput.state === FormState.IDLE ||
                        field.disabled
                            ? true
                            : false
                    "
                    (timeChanged)="timeChanged($event, field.name)"
                ></ngx-mat-timepicker-field>
            </div>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </div>

        <input type="hidden" />
    </div>
</ng-container>
<!--Field type one end -->

<!--Field type two start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_2">
    <div
        class="date-time-container {{ field.extraClass }}"
        [class.error]="
            field.groupByKey
                ? formGroup.get(field.groupByKey).get(field.name) &&
                  formGroup.get(field.groupByKey).get(field.name).touched &&
                  formGroup.get(field.groupByKey).get(field.name).errors
                : formGroup.get(field.name) &&
                  formGroup.get(field.name).touched &&
                  formGroup.get(field.name).errors
        "
        [class.disabled]="
            formGeneratorInput.state === FormState.IDLE || field.disabled
        "
    >
        <mat-form-field
            #dateFieldRef
            [appearance]="'outline'"
            [color]="'accent'"
            [hideRequiredMarker]="true"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [floatLabel]="'never'"
        >
            <input
                matInput
                (click)="
                    formGeneratorInput.state === FormState.IDLE ||
                    field.disabled
                        ? null
                        : picker.open()
                "
                [placeholder]="
                    field.placeholder ? field.placeholder : field.label
                "
                [class.disabled]="
                    formGeneratorInput.state === FormState.IDLE ||
                    field.disabled
                "
                [disabled]="
                    formGeneratorInput.state === FormState.IDLE ||
                    field.disabled
                        ? true
                        : false
                "
                [min]="
                    field.minDate
                        ? field.minDate
                        : field.groupByKey
                        ? field.minDateFromControl &&
                          formGroup
                              .get(field.groupByKey)
                              .get(field.minDateFromControl)
                            ? formGroup
                                  .get(field.groupByKey)
                                  .get(field.minDateFromControl).value
                            : null
                        : field.minDateFromControl &&
                          formGroup.get(field.minDateFromControl)
                        ? formGroup.get(field.minDateFromControl).value
                        : null
                "
                [max]="
                    field.maxDate
                        ? field.maxDate
                        : field.groupByKey
                        ? field.maxDateFromControl &&
                          formGroup
                              .get(field.groupByKey)
                              .get(field.maxDateFromControl)
                            ? formGroup
                                  .get(field.groupByKey)
                                  .get(field.maxDateFromControl).value
                            : null
                        : field.maxDateFromControl &&
                          formGroup.get(field.maxDateFromControl)
                        ? formGroup.get(field.maxDateFromControl).value
                        : null
                "
                [matDatepicker]="picker"
                [required]="field.required"
                [name]="field.name"
                (focus)="
                    field.prefixIcon ? dateFieldRef.updateOutlineGap() : ''
                "
                [value]="
                    field.value && field.value.dateValue
                        ? field.value.dateValue
                        : ''
                "
                (dateChange)="dateChanged($event, field.name, field.groupByKey)"
            />

            <mat-datepicker-toggle
                matSuffix
                [for]="picker"
            ></mat-datepicker-toggle>

            <mat-datepicker #picker></mat-datepicker>

            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.prefixIcon.iconData.class }}
            </mat-icon>
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>
        </mat-form-field>

        <div class="time-picker-form-field">
            <div class="time-picker-container">
                <ngx-mat-timepicker-field
                    #timePicker
                    class="light-mode"
                    [controlOnly]="true"
                    [disabled]="
                        formGeneratorInput.state === FormState.IDLE ||
                        field.disabled
                            ? true
                            : false
                    "
                    (timeChanged)="timeChanged($event, field.name)"
                ></ngx-mat-timepicker-field>
            </div>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </div>

        <input type="hidden" />
    </div>
</ng-container>
<!--Field type two end -->

<!--Field type three start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_3">
    <div
        class="date-time-container {{ field.extraClass }}"
        [class.error]="
            field.groupByKey
                ? formGroup.get(field.groupByKey).get(field.name) &&
                  formGroup.get(field.groupByKey).get(field.name).touched &&
                  formGroup.get(field.groupByKey).get(field.name).errors
                : formGroup.get(field.name) &&
                  formGroup.get(field.name).touched &&
                  formGroup.get(field.name).errors
        "
        [class.disabled]="
            formGeneratorInput.state === FormState.IDLE || field.disabled
        "
    >
        <div class="date-time-row">
            <span class="form-label" *ngIf="field.label">
                {{ field.label }}
                <span
                    *ngIf="field.required !== undefined ? field.required : true"
                    class="color-warn"
                >
                    &nbsp;*
                </span>
            </span>
            <div class="form-input">
                <mat-form-field
                    #dateFieldRef
                    [appearance]="'legacy'"
                    [color]="'accent'"
                    [hideRequiredMarker]="true"
                    [class.disabled]="
                        formGeneratorInput.state === FormState.IDLE ||
                        field.disabled
                    "
                    [floatLabel]="'never'"
                >
                    <input
                        matInput
                        (click)="
                            formGeneratorInput.state === FormState.IDLE ||
                            field.disabled
                                ? null
                                : picker.open()
                        "
                        [placeholder]="
                            field.placeholder ? field.placeholder : field.label
                        "
                        [class.disabled]="
                            formGeneratorInput.state === FormState.IDLE ||
                            field.disabled
                        "
                        [disabled]="
                            formGeneratorInput.state === FormState.IDLE ||
                            field.disabled
                                ? true
                                : false
                        "
                        [min]="
                            field.minDate
                                ? field.minDate
                                : field.groupByKey
                                ? field.minDateFromControl &&
                                  formGroup
                                      .get(field.groupByKey)
                                      .get(field.minDateFromControl)
                                    ? formGroup
                                          .get(field.groupByKey)
                                          .get(field.minDateFromControl).value
                                    : null
                                : field.minDateFromControl &&
                                  formGroup.get(field.minDateFromControl)
                                ? formGroup.get(field.minDateFromControl).value
                                : null
                        "
                        [max]="
                            field.maxDate
                                ? field.maxDate
                                : field.groupByKey
                                ? field.maxDateFromControl &&
                                  formGroup
                                      .get(field.groupByKey)
                                      .get(field.maxDateFromControl)
                                    ? formGroup
                                          .get(field.groupByKey)
                                          .get(field.maxDateFromControl).value
                                    : null
                                : field.maxDateFromControl &&
                                  formGroup.get(field.maxDateFromControl)
                                ? formGroup.get(field.maxDateFromControl).value
                                : null
                        "
                        [matDatepicker]="picker"
                        [required]="field.required"
                        [name]="field.name"
                        (focus)="
                            field.prefixIcon
                                ? dateFieldRef.updateOutlineGap()
                                : ''
                        "
                        [value]="
                            field.value && field.value.dateValue
                                ? field.value.dateValue
                                : ''
                        "
                        (dateChange)="
                            dateChanged($event, field.name, field.groupByKey)
                        "
                    />

                    <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                    ></mat-datepicker-toggle>

                    <mat-datepicker #picker></mat-datepicker>

                    <mat-icon
                        *ngIf="field.prefixIcon"
                        matPrefix
                        [matTooltip]="field.prefixIcon.hoverText"
                        class="clickable"
                        (click)="
                            field.prefixIcon.function
                                ? field.prefixIcon.function(field, formGroup)
                                : null
                        "
                    >
                        {{ field.prefixIcon.iconData.class }}
                    </mat-icon>
                    <mat-icon
                        *ngIf="field.suffixIcon"
                        matSuffix
                        [matTooltip]="field.suffixIcon.hoverText"
                        class="clickable"
                        (click)="
                            field.suffixIcon.function
                                ? field.suffixIcon.function(field, formGroup)
                                : null
                        "
                    >
                        {{ field.suffixIcon.iconData.class }}
                    </mat-icon>
                </mat-form-field>
            </div>
        </div>

        <div class="date-time-row">
            <span class="form-label">
                Time
                <span
                    *ngIf="field.required !== undefined ? field.required : true"
                    class="color-warn"
                >
                    &nbsp;*
                </span>
            </span>
            <div class="form-input">
                <div class="time-picker-form-field legacy">
                    <div class="time-picker-container">
                        <ngx-mat-timepicker-field
                            #timePicker
                            class="light-mode"
                            [controlOnly]="true"
                            [disabled]="
                                formGeneratorInput.state === FormState.IDLE ||
                                field.disabled
                                    ? true
                                    : false
                            "
                            (timeChanged)="timeChanged($event, field.name)"
                        ></ngx-mat-timepicker-field>
                    </div>

                    <!-- Handling errors -->
                    <ng-container *ngIf="!field.groupByKey">
                        <ng-template *ngTemplateOutlet="error"></ng-template>
                    </ng-container>
                    <ng-container *ngIf="field.groupByKey">
                        <ng-template
                            *ngTemplateOutlet="groupError"
                        ></ng-template>
                    </ng-container>
                </div>
            </div>
        </div>

        <input type="hidden" />
    </div>
</ng-container>
<!--Field type three end -->

<!--Field type four start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_4">
    <div
        class="date-time-container {{ field.extraClass }}"
        [class.error]="
            field.groupByKey
                ? formGroup.get(field.groupByKey).get(field.name) &&
                  formGroup.get(field.groupByKey).get(field.name).touched &&
                  formGroup.get(field.groupByKey).get(field.name).errors
                : formGroup.get(field.name) &&
                  formGroup.get(field.name).touched &&
                  formGroup.get(field.name).errors
        "
        [class.disabled]="
            formGeneratorInput.state === FormState.IDLE || field.disabled
        "
    >
        <mat-form-field
            #dateFieldRef
            [appearance]="'legacy'"
            [color]="'accent'"
            [hideRequiredMarker]="true"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [floatLabel]="'never'"
        >
            <input
                matInput
                (click)="
                    formGeneratorInput.state === FormState.IDLE ||
                    field.disabled
                        ? null
                        : picker.open()
                "
                [placeholder]="
                    field.placeholder ? field.placeholder : field.label
                "
                [class.disabled]="
                    formGeneratorInput.state === FormState.IDLE ||
                    field.disabled
                "
                [disabled]="
                    formGeneratorInput.state === FormState.IDLE ||
                    field.disabled
                        ? true
                        : false
                "
                [min]="
                    field.minDate
                        ? field.minDate
                        : field.minDateFromControl &&
                          formGroup.get(field.minDateFromControl)
                        ? formGroup.get(field.minDateFromControl).value
                        : null
                "
                [max]="
                    field.maxDate
                        ? field.maxDate
                        : field.maxDateFromControl &&
                          formGroup.get(field.maxDateFromControl)
                        ? formGroup.get(field.maxDateFromControl).value
                        : null
                "
                [matDatepicker]="picker"
                [required]="field.required"
                [name]="field.name"
                (focus)="
                    field.prefixIcon ? dateFieldRef.updateOutlineGap() : ''
                "
                [value]="
                    field.value && field.value.dateValue
                        ? field.value.dateValue
                        : ''
                "
                (dateChange)="dateChanged($event, field.name, field.groupByKey)"
            />

            <mat-datepicker-toggle
                matSuffix
                [for]="picker"
            ></mat-datepicker-toggle>

            <mat-datepicker #picker></mat-datepicker>

            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.prefixIcon.iconData.class }}
            </mat-icon>
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>
        </mat-form-field>

        <div class="time-picker-form-field legacy">
            <div class="time-picker-container">
                <ngx-mat-timepicker-field
                    #timePicker
                    class="light-mode"
                    [controlOnly]="true"
                    [disabled]="
                        formGeneratorInput.state === FormState.IDLE ||
                        field.disabled
                            ? true
                            : false
                    "
                    (timeChanged)="timeChanged($event, field.name)"
                ></ngx-mat-timepicker-field>
            </div>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </div>

        <input type="hidden" />
    </div>
</ng-container>
<!--Field type four end -->

<!--Field type seven start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_7">
    <div
        class="date-time-container type-7-date-time {{ field.extraClass }}"
        [class.error]="
            field.groupByKey
                ? formGroup.get(field.groupByKey).get(field.name) &&
                  formGroup.get(field.groupByKey).get(field.name).touched &&
                  formGroup.get(field.groupByKey).get(field.name).errors
                : formGroup.get(field.name) &&
                  formGroup.get(field.name).touched &&
                  formGroup.get(field.name).errors
        "
        [class.disabled]="
            formGeneratorInput.state === FormState.IDLE || field.disabled
        "
    >
        <span class="form-label" *ngIf="field.label">
            {{ field.label }}
            <span
                *ngIf="field.required !== undefined ? field.required : true"
                class="color-warn"
            >
                &nbsp;*
            </span>
        </span>
        
        <div class="row">
            <mat-form-field
                #dateFieldRef
                [appearance]="'outline'"
                [color]="'accent'"
                [hideRequiredMarker]="true"
                [class.disabled]="
                    formGeneratorInput.state === FormState.IDLE || field.disabled
                "
                [ngClass]="['mat-form-field-type-7-date-time']"
            >
                <input
                    matInput
                    (click)="
                        formGeneratorInput.state === FormState.IDLE ||
                        field.disabled
                            ? null
                            : picker.open()
                    "
                    [placeholder]="
                        field.placeholder ? field.placeholder : field.label
                    "
                    [class.disabled]="
                        formGeneratorInput.state === FormState.IDLE ||
                        field.disabled
                    "
                    [disabled]="
                        formGeneratorInput.state === FormState.IDLE ||
                        field.disabled
                            ? true
                            : false
                    "
                    [min]="
                        field.minDate
                            ? field.minDate
                            : field.groupByKey
                            ? field.minDateFromControl &&
                            formGroup
                                .get(field.groupByKey)
                                .get(field.minDateFromControl)
                                ? formGroup
                                    .get(field.groupByKey)
                                    .get(field.minDateFromControl).value
                                : null
                            : field.minDateFromControl &&
                            formGroup.get(field.minDateFromControl)
                            ? formGroup.get(field.minDateFromControl).value
                            : null
                    "
                    [max]="
                        field.maxDate
                            ? field.maxDate
                            : field.groupByKey
                            ? field.maxDateFromControl &&
                            formGroup
                                .get(field.groupByKey)
                                .get(field.maxDateFromControl)
                                ? formGroup
                                    .get(field.groupByKey)
                                    .get(field.maxDateFromControl).value
                                : null
                            : field.maxDateFromControl &&
                            formGroup.get(field.maxDateFromControl)
                            ? formGroup.get(field.maxDateFromControl).value
                            : null
                    "
                    [matDatepicker]="picker"
                    [required]="field.required"
                    [name]="field.name"
                    (focus)="
                        field.prefixIcon ? dateFieldRef.updateOutlineGap() : ''
                    "
                    [value]="
                        field.value && field.value.dateValue
                            ? field.value.dateValue
                            : ''
                    "
                    (dateChange)="dateChanged($event, field.name, field.groupByKey)"
                />

                <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                ></mat-datepicker-toggle>

                <mat-datepicker #picker></mat-datepicker>

                <mat-icon
                    *ngIf="field.prefixIcon"
                    matPrefix
                    [matTooltip]="field.prefixIcon.hoverText"
                    class="clickable"
                    (click)="
                        field.prefixIcon.function
                            ? field.prefixIcon.function(field, formGroup)
                            : null
                    "
                >
                    {{ field.prefixIcon.iconData.class }}
                </mat-icon>
                <mat-icon
                    *ngIf="field.suffixIcon"
                    matSuffix
                    [matTooltip]="field.suffixIcon.hoverText"
                    class="clickable"
                    (click)="
                        field.suffixIcon.function
                            ? field.suffixIcon.function(field, formGroup)
                            : null
                    "
                >
                    {{ field.suffixIcon.iconData.class }}
                </mat-icon>
            </mat-form-field>

            <div class="time-picker-form-field">
                <div class="time-picker-container">
                    <span class="label">
                        Time
                        <span
                            *ngIf="
                                field.required !== undefined ? field.required : true
                            "
                            class="color-warn"
                            >*
                        </span>
                    </span>
                    <ngx-mat-timepicker-field
                        #timePicker
                        class="light-mode"
                        [controlOnly]="true"
                        [disabled]="
                            formGeneratorInput.state === FormState.IDLE ||
                            field.disabled
                                ? true
                                : false
                        "
                        (timeChanged)="timeChanged($event, field.name)"
                    ></ngx-mat-timepicker-field>
                </div>

                <!-- Handling errors -->
                <ng-container *ngIf="!field.groupByKey">
                    <ng-template *ngTemplateOutlet="error"></ng-template>
                </ng-container>
                <ng-container *ngIf="field.groupByKey">
                    <ng-template *ngTemplateOutlet="groupError"></ng-template>
                </ng-container>
            </div>

            <input type="hidden" />
        </div>
    </div>
</ng-container>
<!--Field type seven end -->

<!-- Error Templates -->
<ng-template #groupError>
    <ng-container
        *ngIf="
            formGroup.get(field.groupByKey).get(field.name).touched &&
            formGroup.get(field.groupByKey).get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation
                        : formGroup.get(field.groupByKey).get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
<ng-template #error>
    <ng-container
        *ngIf="
            formGroup.get(field.name).touched &&
            formGroup.get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation: formGroup.get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
