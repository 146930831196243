<div class="container">
    <app-form-generator
        [formGeneratorInput]="customComplianceFormGenInput"
        (formGroupRef)="customComplianceFormGroup = $event"
    >
    </app-form-generator>

    <app-button-generator
        class="tw-ml-auto"
        [buttonGenInput]="buttonGenInput"
    ></app-button-generator>
</div>
