import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFilterData } from 'src/app/shared/interfaces/filter/IFilterData';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ButtonColorType } from './../../../enums/ButtonColorType';

@Component({
    selector: 'app-ri-purchase-form',
    templateUrl: './ri-purchase-form.component.html',
    styleUrls: ['./ri-purchase-form.component.sass']
})
export class RiPurchaseFormComponent implements OnInit {
    tagName = '';
    currentStep = 0;
    loader = false;
    iotObservable = null;
    apiPath;
    stepKeys = [];
    stepNames = [];
    confirmationSteps = [];
    automationJson = {};
    data = {};
    selectedData = {};
    modalData;
    widgetRef: Widget;
    filtersData = new Map<string, IFilterData>();
    selectedValue;

    backButton: IButtonGeneratorInput = {
        buttonName: 'Back',
        buttonType: ButtonType.RAISED,
        buttonColorType: ButtonColorType.PRIMARY,
        disable: this.loader,
        function: (buttonRef) => {
            this.backButtonClicked();
        }
    };
    nextButton: IButtonGeneratorInput = {
        buttonName: 'Next',
        buttonType: ButtonType.RAISED,
        buttonColorType: ButtonColorType.PRIMARY,
        disable: this.loader,
        function: null
    };
    submitButton: IButtonGeneratorInput = {
        buttonName: 'Submit',
        buttonType: ButtonType.RAISED,
        buttonColorType: ButtonColorType.PRIMARY,
        disable: this.loader,
        function: null
    };

    constructor(
        modalData: ModalInjectedData,
        private filtersService: FiltersService
    ) {
        this.modalData = modalData;
        this.widgetRef = modalData.data.widgetRef;
        this.selectedValue =
            this.modalData.data.rowData['riPurchaseOfferingMap'];
    }

    ngOnInit() {
        if (this.modalData) {
            this.automationJson = this.modalData['data']['automationJson'];
            const id = this.modalData['data'].rowData.node.id;
            this.data = this.modalData['data'].cards[id];

            const filterInfoData: Map<string, IFilterData> =
                this.filtersService.generateFiltersDataWithFilterIds(
                    this.automationJson['riPurchaseOfferingMap']
                );
            this.filtersData = new Map(
                [...this.filtersData].concat([...filterInfoData])
            );
        }

        this.automationJson['steps'].forEach((element, index) => {
            if (element['selector']) {
                this.stepKeys.push(element['selector']);
            }
            if (element['stepName']) {
                this.stepNames.push(element['stepName']);
            }

            if (this.data && this.data['displayEntry'][element['selector']]) {
                this.filtersService.filtersData[element['selector']] =
                    this.data['displayEntry'][element['selector']];
                this.filtersService.filtersDataTemp.set(element['selector'],
                    this.filtersService.filtersData.get(element['selector']));
            }
        });
    }

    backButtonClicked() {
        this.currentStep -= 1;
        $('.step' + this.currentStep).removeClass('active');
    }

    nextButtonClicked(step) {
        if (this.filtersService.filtersData && this.filtersService.filtersData.size > 0) {
            this.filtersService.filtersDataTemp = this.filtersService.filtersData;
        }
        if (this.checkIfStepHasError(step)) {
            //   this.filtersService.checkError.emit(true);

            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Please fill all the mandatory fields.'
            );

            return;
        }
        for (const selector in this.filtersService.filtersData[step]) {
            if (
                this.getMapKey(
                    this.filtersService.filtersData.get(step),
                    selector
                )
            ) {
                const key = this.getMapKey(
                    this.filtersService.filtersData.get(step),
                    selector
                    );
                if (this.filtersService.filtersData.get(step).has(key) &&
                    this.filtersService.filtersData.get(step).get(key).value) {
                    this.filtersService.filtersData[step][selector] =
                        this.filtersService.filtersData.get(step).get(key).value;
                }
            }
        }
        this.selectedData = this.filtersService.filtersData['riPurchaseOfferingMap'];
        $('.step' + this.currentStep).addClass('active');
        this.currentStep += 1;
    }

    submitButtonClicked(step) {
        // if (this.checkIfStepHasError(step)) {
        //     //   this.filtersService.checkError.emit(true);
        //     return;
        // }
        const inputData = {};
        inputData[this.automationJson['outputKey']] =
            this.filtersService.filtersData[this.automationJson['outputKey']];
        this.loader = true;
        const url = this.automationJson['apiPath'].split('/');
        const cloud = url[0];
        const module = url[1];
        const invocationType = url[2];
        const page = url[3];
        const insight = url[4];
        const apiArgs: IHitApi = {
            url: `${this.automationJson['host']}${cloud}/${module}/${invocationType}/${page}/${insight}`,
            intactUrl: `${this.automationJson['host']}{cloud}/{module}/{invocationType}/{page}/{insight}`,
            requestType: RequestType.POST,
            input: inputData,
            function: (response) => {
                this.loader = false;
                this.widgetRef.notificationsService.showSnackBar(
                    'RI Successfully Purchased.'
                );
                this.widgetRef.modalService.closeModal(
                    null,
                    this.modalData.modalId
                );
            },
            errorFunction: (error) => {
                this.toggleModal(false);
                this.widgetRef.notificationsService.showSnackBar(
                    error.error.message,
                    true
                );
            },
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.AUTHORIZATION_TOKEN_V2,
                ignoreBaseUrl: true
            }
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    isTypeString(variable) {
        if (typeof variable === 'string') {
            return true;
        }
        return false;
    }

    checkIfStepHasError(step) {
        const items = this.automationJson[step];

        if (items) {
            for (const item of items) {
                const filterInfo = this.filtersService.filtersData
                    .get(step)
                    .get(item).filterInfo;

                const filterValue = this.filtersService.filtersData
                    .get(step)
                    .get(item).value;
                if (filterInfo.compulsory) {
                    if (
                        filterInfo.type === FilterType.DROPDOWN_SINGLE &&
                        filterInfo['selector'] &&
                        !filterValue
                    ) {
                        return true;
                    } else if (
                        filterInfo.type === FilterType.DROPDOWN_MULTIPLE &&
                        filterValue &&
                        !filterValue.length
                    ) {
                        return true;
                    } else if (
                        filterInfo.type === FilterType.TEXT &&
                        !filterValue
                    ) {
                        return true;
                    } else if (
                        filterInfo.type === FilterType.JSON &&
                        (!filterValue || !Helper.validateJson(filterValue))
                    ) {
                        return true;
                    } else if (
                        filterInfo.type === FilterType.NUMBER &&
                        !filterValue
                    ) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    toggleModal(toggle) {
        const tempObj = {};
        tempObj['toggleModal'] = toggle;
        if (this.modalData && this.modalData['filtersRefreshKey']) {
            tempObj[this.modalData['filtersRefreshKey']] = toggle;
            //  this.globalDataService.toggleModal.emit(tempObj);
        } else {
            //  this.globalDataService.toggleModal.emit(toggle);
        }
    }

    calculateTotalCost() {
        let cost;
        if (
            this.automationJson['calculationType'] &&
            this.automationJson['calculationType'] === 1
        ) {
            let offeringClass;
            if (this.selectedData['offeringClass']) {
                offeringClass =
                    (this.selectedData['offeringClass'] as string)
                        .charAt(0)
                        .toUpperCase() +
                    (this.selectedData['offeringClass'] as string).slice(1);
            }
            let offeringType;
            if (this.selectedData['offeringType']) {
                offeringType = (
                    this.selectedData['offeringType'] &&
                    (this.selectedData['offeringType'] as string)
                ).replace(' ', '-');
            }
            let offeringTerm;
            if (this.selectedData['offeringTerm']) {
                offeringTerm = this.selectedData['offeringTerm'] + 'yr';
            }
            const key = offeringClass + ' ' + offeringType + ' ' + offeringTerm;
            if (this.data['amortizedCost']) {
                cost =
                    this.data['currency'] + Helper.roundOff(
                    (this.data['amortizedCost']['Total RI Cost'][
                        'Total RI Cost (' + this.data['currency'] + ')'
                    ][key] /
                        this.data['generalInformation'][
                            this.automationJson['totalResourceCount']
                        ]) *
                        this.filtersService.filtersData[
                            this.automationJson['outputKey']
                        ]['resourceCount'], 2);
            }
        } else if (
            this.automationJson['calculationType'] &&
            this.automationJson['calculationType'] === 2
        ) {
            const offeringClass = 'Standard';
            let offeringType;
            if (this.selectedData['offeringType']) {
                offeringType = (
                    this.selectedData['offeringType'] &&
                    (this.selectedData['offeringType'] as string)
                ).replace(' ', '-');
            }
            const offeringTerm = this.selectedData['offeringTerm'] + 'yr';
            const key = offeringClass + ' ' + offeringType + ' ' + offeringTerm;
            cost =
                this.data['currency'] + Helper.roundOff(
                (this.data['amortizedCost']['Total RI Cost'][
                    'Total RI Cost (' + this.data['currency'] + ')'
                ][key] /
                    this.data['generalInformation'][
                        this.automationJson['totalResourceCount']
                    ]) *
                    this.filtersService.filtersData[
                        this.automationJson['outputKey']
                    ]['resourceCount'], 2);
        } else if (
            this.automationJson['calculationType'] &&
            this.automationJson['calculationType'] === 3
        ) {
            const offeringClass = 'Standard';
            const offeringType = 'Heavy-Utilization';
            const offeringTerm = this.selectedData['offeringTerm'] + 'yr';
            const key = offeringClass + ' ' + offeringType + ' ' + offeringTerm;
            cost =
                this.data['currency'] + Helper.roundOff(
                (this.data['amortizedCost']['Total RI Cost'][
                    'Total RI Cost (' + this.data['currency'] + ')'
                ][key] /
                    this.data['generalInformation'][
                        this.automationJson['reserveResourceCount']
                    ]) *
                    this.filtersService.filtersData[
                        this.automationJson['outputKey']
                    ]['resourceCount'], 2);
        }
        return cost;
    }

    getMapKey(map, searchValue) {
        for (const [key, value] of map.entries()) {
            if (value.filterInfo.selector === searchValue) return key;
        }
    }
}
