/*
TYPE_1: This type will be used for outline appearance
TYPE_2: This type will be used for outline appearance but without floating label
Type_3: This type will be used for legacy appearance
TYPE_4: This type will be used for the legacy appearance but without label
TYPE_5: This type will be used for filter
TYPE_6: This type is used for other appearance; e.g.,color-filed
TYPE_7: This type is created to have seperate label with outline input style, only for 6 input types used in RequestFormModalComponent
*/

export enum DropdownSingleFieldAppearance {
    TYPE_1,
    TYPE_2,
    TYPE_3,
    TYPE_4,
    TYPE_5,
    TYPE_7 = 'TYPE_7'
}
export enum DropdownGroupSingleFieldAppearance {
    TYPE_1,
    TYPE_2,
    TYPE_3,
    TYPE_4,
    TYPE_5
}
export enum DropdownMultipleFieldAppearance {
    TYPE_1,
    TYPE_2,
    TYPE_3,
    TYPE_4,
    TYPE_5,
    TYPE_7 = 'TYPE_7'
}
export enum DropdownGroupMultipleFieldAppearance {
    TYPE_1,
    TYPE_3,
    TYPE_5
}
export enum DropdownListObjectFieldAppearance {
    TYPE_1,
    TYPE_4
}
export enum TextFieldAppearance {
    TYPE_1,
    TYPE_2,
    TYPE_3,
    TYPE_4,
    TYPE_5,
    TYPE_7 = 'TYPE_7'
}

export enum CheckboxField {
    TYPE_3,
    TYPE_4,
    TYPE_5,
    INDREKA_CHECKBOX
}

export enum WysiwygEditorField {
    TYPE_1 = '1',
    TYPE_2 = '2',
    TYPE_3 = '3'
}

export enum ContentField {
    TYPE_3
}

export enum DateField {
    TYPE_1,
    TYPE_2,
    TYPE_3,
    TYPE_4,
    TYPE_5
}

export enum TimeField {
    TYPE_1,
    TYPE_2,
    TYPE_3,
    TYPE_4,
    TYPE_5
}

export enum DateTimeField {
    TYPE_1,
    TYPE_2,
    TYPE_3,
    TYPE_4,
    TYPE_7 = 'TYPE_7'
}

export enum FileField {
    TYPE_1,
    TYPE_2,
    TYPE_3,
    TYPE_4
}
export enum EmailField {
    TYPE_1,
    TYPE_2,
    TYPE_3,
    TYPE_4
}

export enum PasswordField {
    TYPE_1,
    TYPE_2,
    TYPE_3,
    TYPE_4
}

export enum RadioField {
    TYPE_1,
    TYPE_2,
    TYPE_3,
    TYPE_4
}
export enum URLField {
    TYPE_1,
    TYPE_2,
    TYPE_3,
    TYPE_4
}

export enum NumberField {
    TYPE_1,
    TYPE_2,
    TYPE_3,
    TYPE_4,
    TYPE_5,
    TYPE_6,
    TYPE_7 = 'TYPE_7'
}

export enum TextAreaField {
    TYPE_1,
    TYPE_2,
    TYPE_3,
    TYPE_4,
    TYPE_7 = 'TYPE_7'
}

export enum ColorField {
    TYPE_6
}

export enum ToggleField {
    TYPE_3,
    TYPE_4,
    TYPE_5
}

export enum MatChipField {
    TYPE_1,
    TYPE_2,
    TYPE_3,
    TYPE_4
}

export enum ImageUploadField {
    TYPE_1
}

export enum DateRangeFieldAppearance {
    TYPE_5
}
export enum DoubleRangeAppearance {
    TYPE_5
}
export type FormFieldAppearance =
    | TextFieldAppearance
    | DropdownSingleFieldAppearance
    | DropdownGroupSingleFieldAppearance
    | DropdownMultipleFieldAppearance
    | DropdownGroupMultipleFieldAppearance
    | DropdownListObjectFieldAppearance
    | CheckboxField
    | WysiwygEditorField
    | ContentField
    | DateField
    | TimeField
    | DateTimeField
    | ColorField
    | FileField
    | EmailField
    | PasswordField
    | RadioField
    | RadioField
    | ToggleField
    | URLField
    | NumberField
    | TextAreaField
    | MatChipField
    | ImageUploadField
    | DoubleRangeAppearance
    | DateRangeFieldAppearance;
