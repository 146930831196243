import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { ILoginResponse } from './../../../interfaces/api/portlets/ILoginResponse';
import { ModalService } from './../../../services/modal/modal-service/modal.service';

@Component({
    selector: 'app-configure-mfa',
    templateUrl: './configure-mfa.component.html',
    styleUrls: ['./configure-mfa.component.sass']
})
export class ConfigureMfaComponent implements OnInit {
    mfaFormGenInputs: IFormGeneratorInput;
    mfaFormGroup: FormGroup;
    codeVerificationFormGenInputs: IFormGeneratorInput;
    codeVerificationFormGroup: FormGroup;
    email: string;
    enableResendBtn: boolean = false;
    resendCounter: number = 0;
    timeOut: boolean = false;
    showVerificationForm: boolean = false;
    messages = new BehaviorSubject(new Map<string, string>());
    otpInput: any;
    verificationSuccessful: boolean = false;
    otpVerificationLoader: boolean = false;
    callbackFn: any;
    loader: boolean = false;
    response: ILoginResponse;
    timer: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    timerData: number;
    showTimer: boolean = false;
    constructor(
        public modalData: ModalInjectedData,
        private httpService: HttpService,
        private ngZone: NgZone,
        private cd: ChangeDetectorRef,
        private modalService: ModalService
    ) {
        this.response = modalData.data['response'];
        this.email = modalData.data['response']['email'];
        this.callbackFn = modalData.data['callbackFn'];
    }

    ngOnInit(): void {
        this.mfaFormGenInputs = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Authentication Type',
                    placeholder: 'Authentication Type',
                    name: 'mfaType',
                    fieldType: FilterType.RADIO,
                    listData: [
                        { id: 'EMAIL', label: 'EMAIL' },
                        { id: 'PHONE_NUMBER', label: 'SMS' }
                    ],
                    value: 'EMAIL',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Authentication is required'
                        }
                    ]
                },
                {
                    label: 'Email',
                    placeholder: 'Enter Email',
                    name: 'email',
                    fieldType: FilterType.EMAIL,
                    value: this.email ? this.email : '',
                    required: true,
                    disabled: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email is required.'
                        },
                        {
                            validator: CustomValidators.email,
                            errorMessage: 'Invalid Email.'
                        }
                    ],
                    hiddenDependency: [
                        {
                            controlName: 'mfaType',
                            validations: [
                                {
                                    validator: CustomValidators.required
                                },
                                {
                                    validator:
                                        CustomValidators.controlValueMatch(
                                            'EMAIL'
                                        )
                                }
                            ]
                        }
                    ]
                },
                {
                    label: 'Phone Number',
                    placeholder: 'Enter Phone Number',
                    name: 'phoneNumber',
                    fieldType: FilterType.TEXT,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Phone number is required.'
                        },
                        {
                            validator: CustomValidators.phoneNumber,
                            errorMessage:
                                'Please enter a valid phone number including country code.'
                        }
                    ],
                    hiddenDependency: [
                        {
                            controlName: 'mfaType',
                            validations: [
                                {
                                    validator: CustomValidators.required
                                },
                                {
                                    validator:
                                        CustomValidators.controlValueMatch(
                                            'PHONE_NUMBER'
                                        )
                                }
                            ]
                        }
                    ]
                }
            ]
        };
        this.codeVerificationFormGenInputs = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Enter Verification Code',
                    placeholder: 'Enter Verification Code',
                    name: 'verificationCode',
                    fieldType: FilterType.NUMBER,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Verification code is required.'
                        }
                    ]
                }
            ]
        };
    }
    valueChanged() {
        this.showVerificationForm = false;
        this.messages.next(new Map());
    }
    sendOtp() {
        if (!this.enableResendBtn && this.showVerificationForm) {
            return;
        }
        if (this.resendCounter === 3) {
            this.messages.next(new Map());
            this.messages.next(
                this.messages.value.set(
                    Colors.ERROR_COLOR,
                    'Request timed out! Please try again after 10 minutes.'
                )
            );
            this.enableResendBtn = false;
            this.showTimer = false;
            return;
        }
        this.enableResendBtn = false;
        setTimeout(() => {
            this.resendCounter++;

            if (this.resendCounter < 3) {
                this.showTimer = false;
                this.timerData = 60;
                this.enableResendBtn = true;
            }
        }, 60000);

        const input = {};
        if (this.mfaFormGroup) {
            const fieldVal = this.mfaFormGroup.get('mfaType').value;
            if (fieldVal === 'EMAIL') {
                input['email'] = this.mfaFormGroup.get('email').value;
            } else {
                input['phoneNumber'] = parseInt(
                    this.mfaFormGroup.get('phoneNumber').value
                );
            }
        }
        this.otpInput = input;
        this.showVerificationForm = true;
        this.messages.next(new Map());
        this.messages.value.set(
            Colors.SUCCESS_COLOR,
            `*OTP has been successfully sent to the  ${
                this.otpInput.email
                    ? this.otpInput['email']
                    : this.mfaFormGroup.get('phoneNumber').value
            } `
        );
        this.messages.next(this.messages.value);
        this.hitOtpApi();
        this.resendTimer();
    }
    hitOtpApi() {
        const apiConf: IHitApi = {
            url: ApiUrls.SEND_OTP_AFTER_LOGIN,
            input: this.otpInput,
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            function: () => {},
            errorFunction: (error) => {
                this.messages.next(new Map());
                this.timeOut =
                    error &&
                    error.error.message &&
                    error.error.message.includes('Request timed out')
                        ? true
                        : false;
                if (this.timeOut) {
                    this.messages.next(
                        this.messages.value.set(
                            Colors.ERROR_COLOR,
                            'Request timed out! Please try again after 10 minutes.'
                        )
                    );
                } else {
                    this.messages.value.set(
                        Colors.ERROR_COLOR,
                        error.error.message
                    );

                    this.messages.next(this.messages.value);
                }
            },
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            }
        };

        new HitApi(apiConf, this.httpService, this.ngZone).hitApi();
    }
    verify() {
        if (this.otpVerificationLoader) {
            return;
        }
        Helper.markAllFieldAsTouched(this.codeVerificationFormGroup);
        if (this.codeVerificationFormGroup.invalid) {
            this.codeVerificationFormGroup.updateValueAndValidity();
            return;
        }
        this.otpVerificationLoader = true;
        const code =
            this.codeVerificationFormGroup.get('verificationCode').value;
        const apiConfig: IHitApi = {
            url: `${ApiUrls.VERIFY_OTP_AFTER_LOGIN}/${code}`,
            intactUrl: `${ApiUrls.VERIFY_OTP_AFTER_LOGIN}/{code}`,
            input: {},
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: () => {
                this.verificationSuccessful = true;
                this.mfaFormGenInputs.state = FormState.IDLE;
                this.mfaFormGenInputs = Helper.dereference(
                    this.mfaFormGenInputs
                );

                this.cd.detectChanges();
                this.showVerificationForm = true;
                this.messages.value.set(
                    Colors.SUCCESS_COLOR,
                    'OTP verification Successful.'
                );
                this.messages.next(this.messages.value);
                this.otpVerificationLoader = false;
            },
            errorFunction: (error) => {
                this.otpVerificationLoader = false;
                this.messages.next(new Map());
                this.messages.value.set(
                    Colors.ERROR_COLOR,
                    error.error.message
                );
                this.messages.next(this.messages.value);
            }
        };
        new HitApi(apiConfig, this.httpService, this.ngZone).hitApi();
    }
    enableMfa() {
        if (this.loader) {
            return;
        }
        Helper.markAllFieldAsTouched(this.mfaFormGroup);
        if (this.mfaFormGroup.invalid) {
            this.mfaFormGroup.updateValueAndValidity();
            return;
        }

        this.loader = true;
        const apiConfig: IHitApi = {
            url: ApiUrls.ENABLE_MFA_AFTER_LOGIN,
            input: this.prepareInput(),
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: () => {
                this.loader = false;
                this.response = { ...this.response, configuredMfa: true };

                if (this.callbackFn) {
                    this.callbackFn(this.response);
                }

                this.modalService.closeModal(null, this.modalData.modalId);
            },
            errorFunction: (error) => {
                this.loader = false;
                this.messages.next(new Map());
                this.messages.value.set(
                    Colors.ERROR_COLOR,
                    error.error.message
                );
                this.messages.next(this.messages.value);
            }
        };
        new HitApi(apiConfig, this.httpService, this.ngZone).hitApi();
    }
    prepareInput() {
        const input = {
            phoneNumber: this.mfaFormGroup.get('phoneNumber').value,
            mfaType: this.mfaFormGroup.get('mfaType').value,
            email: this.mfaFormGroup.get('email').value
        };
        return input;
    }
    resendTimer() {
        this.timerData = 60;
        this.timer.next(this.timerData);
        this.showTimer = true;
        this.ngZone.runOutsideAngular(() => {
            const timer = setInterval(() => {
                this.ngZone.run(() => {
                    this.timerData--;
                    this.timer.next(this.timerData);
                });
            }, 1000);
            setTimeout(() => {
                clearInterval(timer);
                this.showTimer = false;
            }, 60000);
        });
    }
}
enum Colors {
    SUCCESS_COLOR = '#218BB5',
    ERROR_COLOR = 'red'
}
