import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { UpdateAction } from 'src/app/shared/enums/UpdateAction';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { IUpdateAction } from './../../../../interfaces/update-action/IUpdateAction';

@Component({
    selector: 'app-create-volume-provisioning',
    templateUrl: './create-volume-provisioning.component.html',
    styleUrls: ['./create-volume-provisioning.component.sass']
})
export class CreateVolumeProvisioningComponent implements OnInit {
    widgetRef: Widget;
    accAndRegFormGenInputs: IFormGeneratorInput = null;
    accAndRegFormGroup: FormGroup;
    volumeTypeFormGenInputs: IFormGeneratorInput = null;
    volumeTypeFormGroup: FormGroup;
    encryptionFormGenInputs: IFormGeneratorInput = null;
    encryptionFormGroup: FormGroup;
    masterKeyFormGenInputs: IFormGeneratorInput = null;
    masterKeyFormGroup: FormGroup;
    iopsFormGenInputs: IFormGeneratorInput = null;
    iopsFormGroup: FormGroup;
    keyValuePairFormGenInputs: IFormGeneratorInput[] = null;
    keyValuePairFormGroup: Map<Symbol, FormGroup> = new Map();
    loader: boolean = false;
    keyValueSet = {};
    lastKeyValuePair: Symbol;
    updateControlInput: IUpdateAction;
    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    constructor(
        widgetData: WidgetInjectedData,
        private listHttpService: ListHttpService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
        this.keyValuePairFormGenInputs = [this.getKeyValueFormGenInput()];
    }
    setUpBasics() {
        this.widgetRef.showViewIcon.next(false);
        this.widgetRef.hideRefreshIcon.next(true);

        this.accAndRegFormGenInputs = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Account Id',
                    placeholder: 'Select Account Id',
                    name: 'accountId',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    showKey: 'label',
                    apiInfo: {
                        ...this.listHttpService.awsAccountIdApiInfo,
                        host: this.widgetRef.widgetData.widgetInfo.create.host
                    },
                    responseValueKey: 'dataList',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Account id is required'
                        }
                    ]
                },
                {
                    label: 'Region',
                    placeholder: 'Select Region',
                    name: 'region',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    showKey: 'label',
                    apiInfo: {
                        ...this.listHttpService.awsRegionIdApiInfo,
                        host: this.widgetRef.widgetData.widgetInfo.create.host
                    },
                    responseValueKey: 'dataList',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Region is required'
                        }
                    ]
                }
            ]
        };
        this.volumeTypeFormGenInputs = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Volume Type',
                    placeholder: 'Select Volume Type',
                    name: 'volumeType',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: false,
                    showKey: 'label',
                    listData: this.volumeTypeList
                },
                {
                    label: 'Size(GIB)',
                    placeholder: 'Select Size',
                    name: 'size',
                    fieldType: FilterType.NUMBER,
                    required: true,
                    suffixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help_outline'
                        },
                        hoverText: 'Min: 4 GiB , Max: 16384 GiB'
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Size is required'
                        },
                        {
                            validator: CustomValidators.minValue(4),
                            errorMessage: 'Minimum size is 4'
                        },
                        {
                            validator: CustomValidators.maxValue(16384),
                            errorMessage: 'Maximum size is 16384'
                        }
                    ]
                }
            ]
        };
        this.iopsFormGenInputs = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'IOPS',
                    placeholder: 'Select IOPS',
                    name: 'iops',
                    fieldType: FilterType.NUMBER,
                    required: false,
                    suffixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help_outline'
                        },
                        hoverText: 'Min: 100 IOPS , Max: 64000 IOPS'
                    }
                }
            ]
        };
    }
    depField(event) {
        const accountId = event?.accountId ? event.accountId : '';
        const region = event?.region ? event.region : '';
        if (accountId && region) {
            this.encryptionFormGenInputs = {
                formName: '',
                state: FormState.CREATE,
                submitButton: null,
                fields: [
                    {
                        label: 'Availability Zone',
                        placeholder: 'Select Availability Zone',
                        name: 'availabilityZone',
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        required: true,
                        showKey: 'label',
                        apiInput: {
                            accountIds: [accountId],
                            regions: [region]
                        },
                        responseValueKey: 'dataList',
                        apiInfo: {
                            apiRouteSuffix: ApiUrls.AWS_AVAILABILITY_ZONE,
                            host: this.widgetRef.widgetData.widgetInfo.create
                                .host,
                            requestType: RequestType.POST,
                            authorization:
                                AuthorizationType.AUTHORIZATION_TOKEN_V2,
                            customInput: null
                        },
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Account id is required'
                            }
                        ]
                    },
                    {
                        label: 'Encryption',
                        placeholder: '',
                        name: 'encryption',
                        fieldType: FilterType.CHECKBOX,
                        required: false
                    }
                ]
            };
            this.masterKeyFormGenInputs = {
                formName: '',
                state: FormState.CREATE,
                submitButton: null,
                fields: [
                    {
                        label: 'Master Key',
                        placeholder: 'Select Master Key',
                        name: 'masterKey',
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        required: false,
                        showKey: 'label',
                        apiInput: {
                            accountIds: [accountId],
                            regions: [region]
                        },
                        responseValueKey: 'dataList',
                        suffixIcon: {
                            iconData: {
                                type: IconType.MATICON,
                                class: 'help_outline'
                            },
                            hoverText: 'Encrypt this volume'
                        },
                        apiInfo: {
                            apiRouteSuffix: ApiUrls.MASTER_KEY,
                            host: this.widgetRef.widgetData.widgetInfo.create
                                .host,
                            requestType: RequestType.POST,
                            authorization:
                                AuthorizationType.AUTHORIZATION_TOKEN_V2,
                            customInput: null
                        }
                    }
                ]
            };
            this.updateControlInput = {
                action: UpdateAction.REFRESH,
                controls: ['availabilityZone']
            };
            if (this.encryptionFormGroup) {
                this.widgetRef.changeDetectorRef.detectChanges();
                this.updateControl.next(this.updateControlInput);
            }
        }
    }
    createInstance() {
        if (this.loader) {
            return;
        }

        Helper.markAllFieldAsTouched(this.accAndRegFormGroup);
        Helper.markAllFieldAsTouched(this.volumeTypeFormGroup);
        if (
            this.accAndRegFormGroup.invalid ||
            this.volumeTypeFormGroup.invalid
        ) {
            this.accAndRegFormGroup.updateValueAndValidity();
            this.volumeTypeFormGroup.updateValueAndValidity();
            return;
        }

        if (this.encryptionFormGroup) {
            Helper.markAllFieldAsTouched(this.encryptionFormGroup);
            if (this.encryptionFormGroup.invalid) {
                this.encryptionFormGroup.updateValueAndValidity();
                return;
            }
        }
        this.loader = true;
        const inputs = this.prepareInput();
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.create
        );
        apiArgs.input = inputs;
        apiArgs.function = () => {
            this.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'Instance Created Successfully'
            );
            this.reset();
        };
        apiArgs.errorFunction = (error) => {
            this.loader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error While Creating Instance'
            );
            this.reset();
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    prepareInput() {
        const accountId = this.accAndRegFormGroup.get('accountId').value;
        const region = this.accAndRegFormGroup.get('region').value;
        const tags = [];
        let configurationProvision = {};
        const volumeType = this.volumeTypeFormGroup?.get('volumeType')?.value
            ? this.volumeTypeFormGroup.get('volumeType').value
            : '';
        this.lastKeyValuePair =
            this.keyValuePairFormGenInputs[
                this.keyValuePairFormGenInputs.length - 1
            ].formId;
        this.keyValuePairFormGroup.forEach((value, index) => {
            if (this.lastKeyValuePair !== index) {
                tags.push(value.value);
            }
        });
        configurationProvision = {
            ebsVolumeType: volumeType,
            ebsVolumeSize: this.volumeTypeFormGroup?.get('size')?.value
                ? this.volumeTypeFormGroup.get('size').value
                : '',
            availabilityZone: this.encryptionFormGroup?.get('availabilityZone')
                ?.value
                ? this.encryptionFormGroup.get('availabilityZone').value
                : '',
            ebsTags: tags,
            kmsId: this.masterKeyFormGroup?.get('masterKey')?.value
                ? this.masterKeyFormGroup.get('masterKey').value
                : '',
            ebsEncrypted: this.encryptionFormGroup?.get('encryption')?.value
                ? this.encryptionFormGroup.get('encryption').value
                : false
        };

        if (this.iopsFormGroup) {
            configurationProvision['ebsIops'] = this.iopsFormGroup?.get('iops')
                ?.value
                ? this.iopsFormGroup.get('iops').value
                : '';
        }

        return {
            configurationProvision: configurationProvision,
            accountIds: accountId ? [accountId] : [],
            regions: region ? [region] : []
        };
    }
    reset() {
        this.accAndRegFormGroup.reset();
        this.volumeTypeFormGroup.reset();
        if (this.masterKeyFormGroup) {
            this.masterKeyFormGroup.reset();
        }
        if (this.encryptionFormGroup) {
            this.encryptionFormGroup.reset();
        }
        if (this.keyValuePairFormGenInputs.length !== 1) {
            this.keyValuePairFormGenInputs.splice(
                0,
                this.keyValuePairFormGenInputs.length - 1
            );
        }
        this.lastKeyValuePair =
            this.keyValuePairFormGenInputs[
                this.keyValuePairFormGenInputs.length - 1
            ].formId;

        this.keyValuePairFormGroup.forEach((fg, id) => {
            if (id !== this.lastKeyValuePair) {
                this.keyValuePairFormGroup.delete(id);
            } else {
                fg.reset();
            }
        });
    }
    volumeTypeList = [
        {
            id: 'gp2',
            label: 'General Purpose SSD (gp2)'
        },
        {
            id: 'standard',
            label: 'Magnetic (standard)'
        },
        {
            id: 'io1',
            label: 'Provisioned IOPS SSD (io1)'
        },
        {
            id: 'sc1',
            label: 'Cold HDD (sc1)'
        },
        {
            id: 'st1',
            label: 'Throughput Optimizied HDD (st1)'
        }
    ];

    getKeyValueFormGenInput(): IFormGeneratorInput {
        return {
            formId: Symbol(),
            formName: 'Key',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'Key',
                    fieldType: FilterType.TEXT,
                    label: 'Key',
                    placeholder: 'Key',
                    required: true,

                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Key is required'
                        }
                    ]
                },
                {
                    name: 'Value',
                    fieldType: FilterType.TEXT,
                    label: 'Value',
                    placeholder: 'Value',
                    required: true,

                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Value is required'
                        }
                    ]
                }
            ]
        };
    }
    idleKeyValueForms() {
        this.keyValuePairFormGenInputs = this.keyValuePairFormGenInputs.map(
            (fg, index) => {
                if (index !== this.keyValuePairFormGenInputs.length - 1) {
                    fg.state = FormState.IDLE;
                }
                return Helper.dereference(fg);
            }
        );
        this.widgetRef.changeDetectorRef.detectChanges();
    }
    lastKeyValueFormUpdate(index: number, formId: Symbol) {
        Helper.markAllFieldAsTouched(this.keyValuePairFormGroup.get(formId));
        if (this.keyValuePairFormGroup.get(formId).invalid) {
            this.keyValuePairFormGroup.get(formId).updateValueAndValidity();
            return;
        }
        if (this.keyValuePairFormGroup.has(formId)) {
            const fg = this.keyValuePairFormGroup.get(formId);
            if (fg.valid) {
                if (
                    this.keyValuePairFormGenInputs &&
                    this.keyValuePairFormGenInputs.length
                ) {
                    if (this.getKey().includes(fg.get('Key').value)) {
                        this.widgetRef.notificationsService.showSnackBar(
                            'Configuration for this key is already present',
                            true
                        );
                    } else {
                        this.keyValuePairFormGenInputs.push(
                            this.getKeyValueFormGenInput()
                        );
                        this.idleKeyValueForms();
                    }
                }
            }
        }
    }
    getKey(): string[] {
        const key = [];
        this.keyValuePairFormGenInputs.forEach((fg, index) => {
            if (index !== this.keyValuePairFormGenInputs.length - 1) {
                if (this.keyValuePairFormGroup.has(fg.formId)) {
                    key.push(
                        this.keyValuePairFormGroup.get(fg.formId).get('Key')
                            .value
                    );
                }
            }
        });
        return key;
    }
    deleteKeyValuePairFormGroup(index: number, formId: Symbol) {
        this.keyValuePairFormGroup.delete(formId);
        this.keyValuePairFormGenInputs = Helper.removeIndexfromArray(
            index,
            this.keyValuePairFormGenInputs
        );
    }
}
