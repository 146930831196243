<div class="form-container tw-overflow-y-auto">
    <ng-container *ngFor="let field of formData">
        <ng-container *ngIf="field.fieldType === FilterType.DROPDOWN_DEPENDENT">
            <app-dependent-dropdowns
                [fieldData]="field"
                [validate]="checkValidation"
                (change)="fieldValueChanged($event)"
                [selectedValue]="fieldValueObj"
                [selectedCustomFieldValue]="customFieldValueObj"
            ></app-dependent-dropdowns>
        </ng-container>
        <ng-container
            *ngIf="
                field.fieldType !== FilterType.DROPDOWN_DEPENDENT &&
                field.fieldType !== FilterType.WYSIWYG_EDITOR &&
                !field.partiallyDependentOn
            "
        >
            <app-field-generator-handler
                [fieldData]="field"
                [validate]="checkValidation"
                (change)="fieldValueChanged($event)"
                [selectedValue]="fieldValueObj"
                [selectedCustomFieldValue]="customFieldValueObj"
            ></app-field-generator-handler>
        </ng-container>
        <ng-container
            *ngIf="
                field.fieldType !== FilterType.DROPDOWN_DEPENDENT &&
                field.fieldType !== FilterType.WYSIWYG_EDITOR &&
                field.partiallyDependentOn
            "
        >
            <app-field-generator-handler
                [fieldData]="field"
                [validate]="checkValidation"
                [listData]="otherFieldListObj"
                (change)="fieldValueChanged($event)"
                [selectedValue]="fieldValueObj"
                [selectedCustomFieldValue]="customFieldValueObj"
            ></app-field-generator-handler>
        </ng-container>
    </ng-container>
    <div
        class="step-loader tw-absolute tw-inset-0 tw-flex-1 tw-flex tw-items-center tw-justify-center"
        *ngIf="modalLoader"
    >
        <mat-spinner [diameter]="25" class="mat-spinner-color"></mat-spinner>
    </div>
</div>

<div class="button tw-mr-5 tw-mb-2">
    <app-button-generator [buttonGenInput]="buttonInput">
    </app-button-generator>
</div>
