import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { IApiInfo } from 'src/app/shared/interfaces/api/IApiInfo';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { WidgetCreationService } from 'src/app/shared/services/widget-creation/widget-creation.service';

@Component({
    selector: 'app-salesforce-integration-step-one',
    templateUrl: './salesforce-integration-step-one.component.html',
    styleUrls: ['./salesforce-integration-step-one.component.sass']
})
export class SalesforceIntegrationStepOneComponent implements OnInit {
    formGenInput: IFormGeneratorInput = null;
    formGroupRef: FormGroup;
    resetForm = null;
    widgetRef: Widget;
    stepData: any;
    isEdit: boolean = false;
    step1Data: IFormGeneratorInput;
    isLoading: boolean = false;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    buttonInputs: IButtonGeneratorInput[];
    constructor(
        private modalInjectedData: ModalInjectedData,
        private modalService: ModalService,
        private multiStepFormService: MultiStepFormService,
        private widgetCreationService: WidgetCreationService
    ) {
        this.widgetRef = modalInjectedData.data['widgetRef'];
        this.isEdit = modalInjectedData.data['isEdit'];
        this.stepData = modalInjectedData.data.response?.['step1'];
    }

    ngOnInit(): void {
        if (
            this.multiStepFormService.stepData
                .get(this.modalInjectedData.modalId)
                .has(1)
        ) {
            this.stepData = this.multiStepFormService.stepData
                .get(this.modalInjectedData.modalId)
                .get(1);
        }
        this.prepareOnboardingForm();
        this.modalService.resetModal.subscribe(() => {
            const formData = {
                configurationName: this.stepData
                    ? this.stepData['configurationName']
                    : null,
                loginUrl: this.stepData ? this.stepData['loginUrl'] : null,
                organizationId: this.stepData ? this.stepData['orgId'] : null,
                username: this.stepData ? this.stepData['username'] : null,
                password: this.stepData ? this.stepData['password'] : null,
                secretToken: this.stepData ? this.stepData['secretToken'] : null
            };
            this.resetForm(formData);
        });
        this.buttonInputs = [
            {
                buttonName: 'Configuration Steps',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.LINK,
                customClass: 'onboard-steps',
                link: 'assets/onboarding-pdf/Salesforce-Integration-Guide.pdf',
                function: () => {}
            },
            {
                buttonName: 'Next',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED,
                showLoader: true,
                customClass: 'save',
                function: (buttonRef: IButtonGeneratorInput) => {
                    if (this.formGroupRef) {
                        Helper.markAllFieldAsTouched(this.formGroupRef);
                        if (this.formGroupRef.invalid) {
                            this.formGroupRef.updateValueAndValidity();
                            return;
                        }
                    }
                    if (this.isEdit) {
                        this.getFields(buttonRef);
                    } else {
                        this.checkExists(
                            this.formGroupRef.get('configurationName').value,
                            () => {
                                this.getFields(buttonRef);
                            }
                        );
                    }
                }
            }
        ];
    }
    getFields(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }
        buttonRef.loader = true;
        const input = this.prepareInput();
        const hitApi: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['getFields']
        );
        hitApi.input = input;
        hitApi.function = (response) => {
            buttonRef.loader = false;
            const form =
                this.widgetCreationService.convertSalesforceField(response);
            this.nextStep(form);
        };
        hitApi.errorFunction = (error) => {
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
            buttonRef.loader = false;
        };
        new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    prepareInput() {
        if (this.isEdit) {
            return {
                step1: {
                    ...this.formGroupRef.value,
                    configurationName:
                        this.stepData && this.stepData.configurationName
                            ? this.stepData.configurationName
                            : ''
                }
            };
        } else {
            return { step1: this.formGroupRef.value };
        }
    }
    prepareOnboardingForm() {
        this.formGenInput = {
            formName: 'Salesforce',
            state: FormState.CREATE,
            submitButton: null,
            fields: this.generateFormFields()
        };
    }

    generateFormFields() {
        const fieldsMetaData = {
            configurationName: {
                label: 'Configuration Name',
                name: 'configurationName',
                placeholder: 'Enter Configuration Name',
                fieldType: FilterType.TEXT,
                required: true,
                showLabel: true,
                appearance: 'legacy',
                disabled: this.isEdit,
                value: this.stepData ? this.stepData['configurationName'] : '',
                onFocusout: () => {
                    if (!this.isEdit) {
                        if (this.formGroupRef.get('configurationName').value) {
                            this.checkExists(
                                this.formGroupRef.get('configurationName').value
                            );
                        }
                    }
                },
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'Configuration name is required'
                    }
                ]
            },
            loginUrl: {
                label: 'Login URL',
                name: 'loginUrl',
                placeholder: 'Enter Login URL',
                fieldType: FilterType.TEXT,
                required: true,
                appearance: 'legacy',
                showLabel: true,
                value: this.stepData ? this.stepData['loginUrl'] : '',
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'Login Url is required'
                    }
                ]
            },
            organizationId: {
                label: 'Organization Id',
                name: 'orgId',
                placeholder: 'Enter Organization ID',
                fieldType: FilterType.TEXT,
                required: true,
                showLabel: true,
                value: this.stepData ? this.stepData['orgId'] : '',
                appearance: 'legacy',
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'Organization ID is required'
                    }
                ]
            },
            username: {
                label: 'Username',
                name: 'username',
                placeholder: 'Enter Username',
                fieldType: FilterType.TEXT,
                required: true,
                showLabel: true,
                appearance: 'legacy',
                value: this.stepData ? this.stepData['username'] : '',
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'Username is required'
                    }
                ]
            },
            password: {
                label: 'Password',
                name: 'password',
                placeholder: 'Enter Password',
                fieldType: FilterType.PASSWORD,
                required: true,
                showLabel: true,
                appearance: 'legacy',
                value: this.stepData ? this.stepData['password'] : '',
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'Password is required'
                    }
                ]
            },
            secretToken: {
                label: 'Secret Token',
                name: 'secretToken',
                placeholder: 'Enter Secret Token',
                fieldType: FilterType.TEXT,
                required: true,
                showLabel: true,
                appearance: 'legacy',
                value: this.stepData ? this.stepData['secretToken'] : '',
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'Secret Token is required'
                    }
                ]
            }
        };
        let fields = [];

        if (this.isEdit) {
            fields.push(fieldsMetaData['configurationName']);
            fields.push(fieldsMetaData['loginUrl']);
            fields.push(fieldsMetaData['organizationId']);
            fields.push(fieldsMetaData['username']);
            fields.push(fieldsMetaData['password']);
            fields.push(fieldsMetaData['secretToken']);
        } else {
            fields = Object.values(fieldsMetaData);
        }
        return fields;
    }

    nextStep(stepTwoData: IFormGeneratorInput) {
        const firstStepData = Helper.cloneDeep(this.formGroupRef.getRawValue());
        this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .set(1, firstStepData);
        this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .set(2, { form: stepTwoData });
        this.multiStepFormService.nextStep(this.modalInjectedData.modalId);
    }

    checkExists(newConfigName, cb?) {
        return this.submitRequest(
            this.widgetRef.widgetData.widgetInfo['checkExists'],
            newConfigName,
            cb
        );
    }
    submitRequest(apiInfo: IApiInfo, newConfigName, cb?) {
        const apiArgs = Helper.generateHitApiConfig(apiInfo);
        apiArgs.input = {
            id: newConfigName
        };
        apiArgs.function = (response) => {
            if (cb) {
                cb();
            }
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
        };
        apiArgs.endFunction = () => {
            this.isLoading = false;
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
export interface StepOneData {
    configurationName: string;
    loginUrl: string;
    orgId: string;
    username: string;
    password: string;
    secretToken: string;
}
