<app-form-generator
    *ngIf="subDomainFormGenInput"
    [formGeneratorInput]="subDomainFormGenInput"
    (formGroupRef)="subDomainFormGroup = $event"
>
</app-form-generator>

<div class="actions">
    <button
        type="button"
        mat-stroked-button
        class="clickable btn-warning-stroked filled"
        color="warn"
        *ngIf="configExist"
        style="margin-right: 8px"
        (click)="deleteSubDomain()"
    >
        Delete
        <mat-spinner
            class="light-theme"
            [diameter]="16"
            *ngIf="deleteButtonLoader"
        ></mat-spinner>
    </button>

    <button
        type="button"
        mat-stroked-button
        class="clickable btn-primary-stroked filled"
        color="primary"
        (click)="saveSubDomain()"
    >
        {{ configExist ? "Update" : "Save" }}
        <mat-spinner
            class="light-theme"
            [diameter]="16"
            *ngIf="saveButtonLoader"
        ></mat-spinner>
    </button>
</div>
