<div
    class="form-row separated-up-down tw-flex tw-items-center tw-justify-between"
>
    <app-form-generator
        *ngIf="excelFormInput"
        class="excel-form tw-h-full privacy-policy-form"
        [formGeneratorInput]="excelFormInput"
        (formGroupRef)="excelForm = $event"
        (valuesChange)="onFormValueChange($event)"
    >
    </app-form-generator>
</div>
<div class="tw-flex tw-justify-end tw-py-3 tw-w-full">
    <app-button-generator [buttonGenInput]="preview"></app-button-generator>
    <app-button-generator [buttonGenInput]="save"></app-button-generator>
</div>
