<form>
    <div class="form-data">
        <app-form-generator
            [formGeneratorInput]="accountListFormGenInput"
            (formGroupRef)="accountListFormGroup = $event"
        ></app-form-generator>
        <ng-container *ngIf="!edit">
            <app-form-generator
                *ngIf="checkBoxFormGenInput"
                [formGeneratorInput]="checkBoxFormGenInput"
                (formGroupRef)="checkBoxFormGroup = $event"
            ></app-form-generator>
            <app-form-generator
                *ngIf="
                    checkBoxFormGroup &&
                    checkBoxFormGroup.value &&
                    checkBoxFormGroup.value.send
                "
                [formGeneratorInput]="emailContentFormGenInputs"
                (formGroupRef)="emailContentFormGroup = $event"
            ></app-form-generator>
        </ng-container>
    </div>
    <div class="tw-content-between">
        <app-multi-button-generator
            [buttonGenInputs]="buttonGenInputs"
            [options]="buttonOptions"
        ></app-multi-button-generator>
    </div>
</form>
