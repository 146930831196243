<div class="container" *ngIf="!isEdit">
    <app-only-table-generator
        [tableInput]="tableGenInput"
        [apiInfo]="
            widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                'myWidgets'
            ]
        "
        [overviewTemplateStart]="overviewTemplateStart"
        (gridRef)="gridRef = $event; handleBackButton()"
        (selectionChange)="handleSelectionChange()"
        [useMaterialIcons]="true"
    >
    </app-only-table-generator>

    <div class="error-message-custom" *ngIf="showMultiSelectWidgetMessage">
        <span appIcon [data]="infoIcon"> </span> Please note that, if widgets
        are multi-selected then same documentation will be attached to all of
        them.
    </div>

    <div class="modal-footer" *ngIf="isDataReceived">
        <app-button-generator [buttonGenInput]="buttonInput">
        </app-button-generator>
    </div>
</div>

<ng-template #overviewTemplateStart>
    <div class="filter">
        <div class="filter-label">
            <p>Widget Status:</p>
        </div>
        <div class="filter-dropdown">
            <app-multi-select-dropdown
                [filterInfo]="widgetStatusFilterInfo"
                (change)="handleWidgetStatusFilterChange($event)"
            >
            </app-multi-select-dropdown>
        </div>
    </div>
</ng-template>
