import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GridOptions, RowEvent } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { ContentType } from 'src/app/shared/enums/ContentType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import {
    HtmlTargetAttributeValue,
    IButtonGeneratorInput
} from './../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-mca-onboarding-bulk-upload',
    templateUrl: './mca-onboarding-bulk-upload.component.html',
    styleUrls: ['./mca-onboarding-bulk-upload.component.sass']
})
export class McaOnboardingBulkUploadComponent implements OnInit {
    tableInput: ITableGeneratorInput;
    widgetRef: Widget;
    tableData: any;
    agGrid: GridOptions;
    excelForm: FormGroup;
    excelFormInput: IFormGeneratorInput = null;
    excelLoader: boolean;
    resetClicked: boolean = false;
    selectedTableData = null;
    isMasterOnboarding: boolean = true;
    excelData;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    onBoardingButtonInputs: IButtonGeneratorInput[];

    constructor(
        private modalInjectedData: ModalInjectedData,
        private modalService: ModalService
    ) {
        this.widgetRef = modalInjectedData['data']['widgetRef'];
        this.selectedTableData = modalInjectedData['data']['selectedTableData'];
        if (
            this.selectedTableData &&
            this.selectedTableData[0]['type'] === 'MASTER'
        ) {
            this.isMasterOnboarding = true;
        } else {
            this.isMasterOnboarding = false;
        }
    }

    ngOnInit(): void {
        this.setUpBasics();
        this.modalService.resetModal.subscribe((reset) => {
            const form = this.excelFormInput;
            this.excelFormInput = null;
            setTimeout(() => {
                this.excelFormInput = form;
            }, 0);

            if (this.agGrid) {
                this.agGrid.api.setRowData([]);
            }
            this.excelLoader = false;
            this.resetClicked = true;
            this.widgetRef.changeDetectorRef.detectChanges();
        });
        this.onBoardingButtonInputs = this.generateButtons();
    }
    setUpBasics() {
        this.tableInput = {
            selection: 'multiple',
            tableAutoHeight: true,
            extraClass: ' upload-container',
            listExtraction: {
                type: 'DIRECT'
            },
            widgetIconData: {
                class: '',
                type: IconType.FONTAWSOME
            },
            columns: [
                {
                    columnName: 'Billing Account ID',
                    columnKey: 'accountNumber',
                    pinned: 'left',
                    filter: false
                },
                {
                    columnName: 'Subscription ID',
                    columnKey: 'subscriptionId',
                    cellRenderer: (rowData) => {
                        if (rowData.data['type'] === 'MASTER') {
                            return '-';
                        } else {
                            if (
                                rowData.data['azureMCAAccountAttributes'] &&
                                rowData.data['azureMCAAccountAttributes'][
                                    'subscriptionId'
                                ]
                            ) {
                                return rowData.data[
                                    'azureMCAAccountAttributes'
                                ]['subscriptionId'];
                            }
                        }
                    },
                    filter: false
                },
                {
                    columnName: 'Account Name',
                    columnKey: 'accountName',
                    filter: false
                },
                {
                    columnName: 'Application(Client) ID',
                    columnKey: 'applicationClientId',
                    cellRenderer: (rowData) => {
                        return rowData.data['azureMCAAccountAttributes'][
                            'applicationClientId'
                        ];
                    },
                    filter: false
                },
                {
                    columnName: 'Secret Key',
                    columnKey: 'secretKey',
                    cellRenderer: (rowData) => {
                        return rowData.data['azureMCAAccountAttributes'][
                            'secretKey'
                        ];
                    },
                    filter: false
                },
                {
                    columnName: 'Domain ID',
                    columnKey: 'domainId',
                    cellRenderer: (rowData) => {
                        return rowData.data['azureMCAAccountAttributes'][
                            'domainId'
                        ];
                    },
                    filter: false
                },
                {
                    columnName: 'Account SPOC',
                    columnKey: 'spoc',
                    filter: false
                },
                {
                    columnName: 'Upload Status',
                    columnKey: 'uploadStatus',
                    pinned: 'right',
                    maxWidth: 100,
                    filter: false,
                    cellRenderer: (rowData: RowEvent) => {
                        if (rowData.data.uploadStatus === true) {
                            return `<span class="green-status">True</span>`;
                        } else {
                            return `<span class="red-status">False</span>`;
                        }
                    }
                }
            ]
        };
        this.excelFormInput = {
            formName: 'Excel Upload',
            submitButton: null,
            fields: [
                {
                    label: 'Excel File Upload',
                    fieldType: FilterType.FILE,
                    required: true,
                    accept: '.xls, .xlsx',
                    suffixButton: {
                        text: 'Browse',
                        show: true,
                        noFill: true
                    },
                    appearance: 'legacy',
                    placeholder: 'Select a file to upload...',
                    name: 'excelFile'
                }
            ],
            state: FormState.CREATE
        };
    }
    excelFile($event) {
        if ($event['excelFile']) {
            this.excelLoader = true;
            this.resetClicked = false;

            const apiArgs: IHitApi = Helper.generateHitApiConfig(
                this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                    'generatePresignedUrl'
                ]
            );
            apiArgs.function = (response) => {
                const preSignedUrl = response['preSignedURL'];
                if (preSignedUrl) {
                    const file = this.excelForm.value.excelFile['_files'][0];
                    const args: IHitApi = {
                        url: preSignedUrl,
                        requestType: RequestType.PUT,
                        config: {
                            ignoreBaseUrl: true,
                            authorization: null,
                            defaultHeaders: {
                                'Content-Type': ContentType.EXCEL
                            }
                        },
                        function: (response) => {
                            const hitApi: IHitApi = Helper.generateHitApiConfig(
                                this.widgetRef.widgetData.widgetInfo[
                                    'additionalApisForWidget'
                                ]['getExcelData']
                            );
                            hitApi.function = (response) => {
                                this.excelLoader = false;
                                this.excelData = response;
                                this.tableData = Helper.cloneDeep(
                                    this.excelData
                                );
                                this.widgetRef.changeDetectorRef.detectChanges();
                            };
                            hitApi.errorFunction = (error) => {
                                this.excelLoader = false;
                                this.tableData = [];
                                this.widgetRef.changeDetectorRef.detectChanges();
                            };
                            hitApi.input = {};
                            hitApi.url =
                                hitApi.url +
                                `?type=${
                                    this.isMasterOnboarding
                                        ? 'MASTER'
                                        : 'SUBSCRIPTION'
                                }`;
                            new HitApi(
                                hitApi,
                                this.widgetRef.httpService,
                                this.widgetRef.ngZone
                            ).hitApi();
                        },
                        errorFunction: (error) => {
                            this.excelLoader = false;
                            this.tableData = [];
                            Helper.showErrorMessage(
                                this.widgetRef.notificationsService,
                                error,
                                'Error uploading excel template.'
                            );
                            this.widgetRef.changeDetectorRef.detectChanges();
                        },
                        input: file,
                        uniqueIdentity: Symbol()
                    };
                    new HitApi(
                        args,
                        this.widgetRef.httpService,
                        this.widgetRef.ngZone
                    ).hitApi();
                }
            };
            apiArgs.input = {};
            apiArgs.errorFunction = (error) => {
                this.tableData = [];
                this.excelLoader = false;
                this.widgetRef.changeDetectorRef.detectChanges();
            };
            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }

    onGridReady($event) {
        this.agGrid = $event;
        this.agGrid.api.selectAll();
    }

    save(buttonRef: IButtonGeneratorInput) {
        Helper.markAllFieldAsTouched(this.excelForm);
        if (this.excelForm.invalid) {
            return;
        }
        if (this.agGrid && !this.agGrid.api.getSelectedNodes().length) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Select at least one entry to perform the action'
            );
            return;
        }

        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.update
        );
        apiArgs.input = this.createPayload();
        if (!apiArgs.input || (apiArgs.input && !apiArgs.input.length)) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Please add valid data to perform the action'
            );
            return;
        }
        buttonRef.loader = true;
        apiArgs.function = (response) => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'Account added successfully'
            );
            this.widgetRef.refreshWidget();
            this.modalService.closeModal(null, this.modalInjectedData.modalId);
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'Error while adding account'
            );
            this.widgetRef.refreshWidget();
            this.modalService.closeModal(null, this.modalInjectedData.modalId);
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    createPayload() {
        const payloadArray = [];
        if (this.agGrid) {
            this.agGrid.api.getSelectedNodes().forEach((rowData, index) => {
                if (rowData.data['uploadStatus']) {
                    rowData.data['type'] = this.isMasterOnboarding
                        ? 'MASTER'
                        : 'SUBSCRIPTION';
                    payloadArray.push(rowData.data);
                }
            });
        }

        return payloadArray;
    }

    callButtons(index): IButtonGeneratorInput {
        const button = this.generateButtons();
        return button[index];
    }
    generateButtons(): IButtonGeneratorInput[] {
        return [
            {
                buttonName: 'Submit',
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.save(buttonRef);
                },
                buttonType: ButtonType.RAISED,
                buttonColorType: ButtonColorType.PRIMARY
            },
            {
                buttonName: 'Download',
                buttonColorType: ButtonColorType.PRIMARY,
                function: () => {},
                buttonType: ButtonType.LINK,
                linkTargetValue: HtmlTargetAttributeValue.SELF,
                link: this.isMasterOnboarding
                    ? 'assets/onboarding-excel/MCA-Master-Onboarding-Sheet.xlsx'
                    : 'assets/onboarding-excel/MCA-subscription-onboarding-sheet.xlsx'
            }
        ];
    }

    ngOnDestroy() {
        this.modalService.resetModal.unsubscribe();
    }
}
