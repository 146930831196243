import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';
import { IRibbonApiResponse } from 'src/app/shared/interfaces/api/portlets/IRibbonApiResponse';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
@Component({
    selector: 'app-ribbon',
    templateUrl: './ribbon.component.html',
    styleUrls: ['./ribbon.component.sass']
})
export class RibbonComponent implements OnInit, AfterViewInit {
    // RIBBON: 1.0

    widgetRef: Widget;
    count;

    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }
    refreshIcon: IIcon = {
        type: IconType.SVG,
        class: 'refresh'
    };

    ngOnInit(): void {}

    curveIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'heading_curve_inventory'
    };

    bindData(data: IRibbonApiResponse) {
        if (data && 'ribbonCount' in data['dataMap']) {
            this.count = data['dataMap']['ribbonCount'];
        } else if (data && 'ribbonCountDecimal' in data['dataMap']) {
            this.count = data['dataMap']['ribbonCountDecimal'];
        } else {
            this.count = '-';
        }
        this.widgetRef.endLoader();
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
