import { object } from '@amcharts/amcharts4/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { IconType } from 'src/app/shared/enums/IconType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IUpdateAction } from 'src/app/shared/interfaces/update-action/IUpdateAction';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { FormState } from './../../../../enums/FormState';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';

@Component({
    selector: 'app-cpu-utilization-modal',
    templateUrl: './cpu-utilization-modal.component.html',
    styleUrls: ['./cpu-utilization-modal.component.sass']
})
export class CpuUtilizationModalComponent implements OnInit {
    rowData: object = [];
    keysToShow: object = {};
    objectValues = Object.values;
    getKeys = Object.keys;
    Helper = Helper;
    metricsFormGenInputs: IFormGeneratorInput = null;
    metricsFormGroup: FormGroup;
    thresholdFormGenInputs: IFormGeneratorInput = null;
    thresholdFormGroup: FormGroup;
    emailFormGenInputs: IFormGeneratorInput = null;
    emailFormGroup: FormGroup;
    monitoredBy: string = '';
    widgetRef: Widget;
    FormState = FormState;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    isParent: string = '';
    highWarning: boolean = false;
    updateControlInput: IUpdateAction;
    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    containsModalData: boolean = false;
    extraInfoData: any;
    descriptionIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-info'
    };
    escalationIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-hourglass-half'
    };
    closeIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-times'
    };
    allowedDecimal: boolean = false;

    saveButton: IButtonGeneratorInput = {
        buttonName: 'Save',
        buttonType: ButtonType.RAISED,
        buttonColorType: ButtonColorType.PRIMARY,
        showLoader: true,
        disable: this.isParent === 'true',
        function: (buttonRef) => {
            this.save(buttonRef);
        }
    };

    constructor(
        public modalData: ModalInjectedData,
        public modalService: ModalService
    ) {
        this.rowData = this.modalData.data.rowData;
        this.keysToShow = this.modalData.data.keysToShow;
        this.widgetRef = this.modalData.data['widgetRef'];
        this.monitoredBy = this.rowData && this.rowData['Monitored By'];
        this.isParent = this.rowData['isParent'];
        this.highWarning = modalData.data['highWarning'];
        this.allowedDecimal = modalData.data['allowedDecimal'];

        if (this.rowData['modalData']) {
            this.containsModalData = true;
            this.extraInfoData = this.rowData['modalData'];
        }
    }

    ngOnInit(): void {
        this.setUpBasics();
    }
    setUpBasics() {
        this.hitEscalationMatrixApi();
        this.hitThresholdApi();
    }
    transformValue(value) {
        return value !== null
            ? typeof value === 'string'
                ? value.trim().replace(/\n/gi, ' ')
                : value
            : '';
    }
    hitThresholdApi() {
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['nagiosUtilization'][
                'listThresholdDetails'
            ]
        );
        apiArgs.input = this.prepareThresholdInputs();
        apiArgs.function = (response) => {
            this.generateThresholdForm(response);
        };
        apiArgs.errorFunction = () => {
            this.generateThresholdForm();
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    generateThresholdForm(data?) {
        this.thresholdFormGenInputs = {
            formName: '',
            state: FormState.IDLE,
            submitButton: null,
            fields: [
                {
                    label: 'Critical',
                    name: 'critical',
                    placeholder: 'Critical',
                    fieldType: FilterType.NUMBER,
                    value:
                        data && data.criticalThreshold
                            ? data.criticalThreshold
                            : '',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This feild is required'
                        }
                    ]
                },
                {
                    label: 'Warning',
                    name: 'warning',
                    placeholder: 'warning',
                    fieldType: FilterType.NUMBER,
                    value:
                        data && data.warningThreshold
                            ? data.warningThreshold
                            : '',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This feild is required'
                        }
                    ]
                }
            ]
        };
    }
    prepareThresholdInputs() {
        const input = {
            isParent: this.isParent,
            integrationName: this.rowData['Integration Name'],
            instanceList: [
                {
                    ipAddress: this.rowData['IP Address'],
                    displayName: this.rowData['Display Name']
                }
            ]
        };
        return input;
    }
    hitEscalationMatrixApi() {
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['nagiosUtilization'][
                'listEscalationMetrics'
            ]
        );
        apiArgs.input = this.prepareEscalationMetricsInputs();
        apiArgs.function = (response) => {
            this.generatescalationMetricsForm(response);
        };
        apiArgs.errorFunction = () => {
            this.generatescalationMetricsForm();
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    prepareEscalationMetricsInputs() {
        const input = {
            integrationName: this.rowData['Integration Name'],
            ipAddress: this.rowData['IP Address'],
            displayName: this.rowData['Display Name']
        };
        return { instanceList: [input], isParent: this.isParent };
    }
    generatescalationMetricsForm(data?) {
        this.metricsFormGenInputs = {
            formName: '',
            state: FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    label: 'Contacts Group',
                    name: 'contactGroup',
                    placeholder: 'Contacts Group',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    listData: this.prepareDataList(
                        data && data.contactGroups ? data.contactGroups : null
                    ),
                    value: this.prepareValue(
                        data && data.contactGroups ? data.contactGroups : null
                    ),
                    disableDropdown: this.isParent === 'true' ? true : false,
                    required: false
                },
                {
                    label: 'Contacts',
                    name: 'contacts',
                    placeholder: 'Contacts',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    listData: this.prepareDataList(
                        data && data.contacts ? data.contacts : null
                    ),
                    disableDropdown: this.isParent === 'true' ? true : false,
                    value: this.prepareValue(
                        data && data.contacts ? data.contacts : null
                    ),

                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Contact is required'
                        }
                    ]
                }
            ]
        };
        this.generateAlertEmails(data);
    }
    generateAlertEmails(data?) {
        this.emailFormGenInputs = {
            formName: '',
            state: FormState.IDLE,
            submitButton: null,
            fields: [
                {
                    label: 'Critical (L1 Email)',
                    name: 'emailCritical',
                    placeholder: 'Critical (L1 Email)',
                    fieldType: FilterType.MATCHIPLIST,
                    value: data && data.criticalEmail ? data.criticalEmail : [],
                    required: false
                },
                {
                    label: 'Warning (L2 Email)',
                    name: 'emailWarning',
                    placeholder: 'Warning (L2 Email)',
                    fieldType: FilterType.MATCHIPLIST,
                    value: data && data.warningEmail ? data.warningEmail : [],
                    required: false
                }
            ]
        };
    }
    hitAlertEmailsApis() {
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['nagiosUtilization'][
                'listAlertEmails'
            ]
        );
        apiArgs.input = this.prepareAlertEmailsInputs();
        apiArgs.function = (response) => {
            this.emailFormGroup
                .get('emailCritical')
                .setValue(response['criticalEmail']);
            this.emailFormGroup
                .get('emailWarning')
                .setValue(response['warningEmail']);
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    prepareAlertEmailsInputs() {
        const input = {
            integrationName: this.rowData['Integration Name'],
            contacts: this.metricsFormGroup.get('contacts').value,
            contactGroups: this.metricsFormGroup.get('contactGroup').value,
            isParent: this.isParent
        };
        return input;
    }
    prepareValue(data) {
        const value = [];
        if (data) {
            const keys = object.keys(data);
            keys.forEach((key) => {
                if (data[key]) {
                    value.push(key);
                }
            });
            return value;
        } else {
            return [];
        }
    }
    prepareDataList(data) {
        if (data) {
            const keys = object.keys(data);
            const listData = [];
            keys.forEach((element) => {
                const listDataObj = {
                    id: element,
                    label: element,
                    disabled: this.isParent === 'true' ? true : false
                };
                listData.push(listDataObj);
            });

            return listData;
        } else {
            return [];
        }
    }
    save(buttonRef) {
        if (buttonRef.loader) {
            return;
        }
        const warningValue = this.thresholdFormGroup.getRawValue()['warning'];
        const criticalValue = this.thresholdFormGroup.getRawValue()['critical'];
        if (!warningValue || !criticalValue) {
            this.widgetRef.notificationsService.showSnackBar(
                "Threasold can't bet empty",
                true
            );
            return;
        }
        if (this.allowedDecimal && warningValue && criticalValue) {
            if (
                Number.isInteger(+warningValue) ||
                Number.isInteger(+criticalValue)
            ) {
                this.widgetRef.notificationsService.showSnackBar(
                    'Kindly re-enter the threshold values. The current values are invalid.',
                    true
                );
                return;
            }
        }
        if (!this.allowedDecimal && warningValue && criticalValue) {
            if (
                !Number.isInteger(+warningValue) ||
                !Number.isInteger(+criticalValue)
            ) {
                this.widgetRef.notificationsService.showSnackBar(
                    'Kindly re-enter the threshold values. The current values are invalid.',
                    true
                );
                return;
            }
        }
        if (this.highWarning) {
            if (warningValue < criticalValue) {
                this.widgetRef.notificationsService.showSnackBar(
                    'Kindly re-enter the threshold values. The current values are invalid.',
                    true
                );
                return;
            }
        }
        if (!this.highWarning && +warningValue > +criticalValue) {
            this.widgetRef.notificationsService.showSnackBar(
                'Kindly re-enter the threshold values. The current values are invalid.',
                true
            );
            return;
        }
        if (this.monitoredBy === 'Cloud') {
            this.widgetRef.notificationsService.showSnackBar(
                'Server’s threshold and escalation metric cannot be edited because it is not present in nagios integration.',
                true
            );
            return;
        }
        Helper.markAllFieldAsTouched(this.thresholdFormGroup);
        if (this.thresholdFormGroup.invalid) {
            this.thresholdFormGroup.updateValueAndValidity();
            return;
        }
        Helper.markAllFieldAsTouched(this.metricsFormGroup);
        if (this.metricsFormGroup.invalid) {
            this.metricsFormGroup.updateValueAndValidity();
            return;
        }
        buttonRef.loader = true;

        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.update
        );
        apiArgs.input = this.prepreInputs();
        apiArgs.function = () => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'Process initiated. Please refresh after 1-2 minutes as it takes time to reflect the data on Nagios.',
                true
            );
            this.widgetRef.modalService.closeModal(
                null,
                this.modalData.modalId
            );
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
            this.widgetRef.modalService.closeModal(
                null,
                this.modalData.modalId
            );
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    prepreInputs() {
        const inputs = {
            warningThreshold: this.thresholdFormGroup.getRawValue()['warning'],
            criticalThreshold:
                this.thresholdFormGroup.getRawValue()['critical'],
            integrationName: this.rowData['Integration Name'],
            instanceList: [
                {
                    ipAddress: this.rowData['IP Address'],
                    displayName: this.rowData['Display Name']
                }
            ],
            contacts: this.metricsFormGroup.get('contacts').value,
            contactGroups: this.metricsFormGroup.get('contactGroup').value,
            isBulk: false
        };
        return inputs;
    }
    changeState(state) {
        this.thresholdFormGenInputs.state = state;
        this.thresholdFormGenInputs = Helper.dereference(
            this.thresholdFormGenInputs
        );
    }
    isObject(val) {
        return typeof val === 'object';
    }
}
