import { NgClass, NgStyle } from '@angular/common';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { ButtonColorType } from '../../enums/ButtonColorType';
import { ICardsGeneratorInput } from './../../interfaces/card-generator/ICardsGeneratorInput';

@Component({
    selector: 'app-cards-generator',
    templateUrl: './cards-generator.component.html',
    styleUrls: ['./cards-generator.component.sass']
})
export class CardsGeneratorComponent implements OnInit {
    @Input() cardInput: ICardsGeneratorInput;
    @Input() widgetRef: Widget;
    @Input() cardInfoTemplate: TemplateRef<any>;
    @Input() cardInfoTemplateStyles: NgStyle;
    @Input() cardInfoTemplateClasses: NgClass;

    ButtonColorType = ButtonColorType;
    cardsData = null;
    filteredCardsData = null;
    Helper = Helper;
    selectAll = false;
    bulkLoader = new BehaviorSubject<boolean>(false);

    selectionChange = true;
    totalSelectedCards = 0;

    constructor() {}

    ngOnInit(): void {
        this.setUpBasics();
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }

    setUpBasics() {
        // Show View Icon
        // this.widgetRef.showViewIcon.next(true);

        // Update Header Icon
        if (this.cardInput.widgetIconData) {
            this.widgetRef.headerIcon.next(this.cardInput.widgetIconData);
        }

        // Setting Visible Sections
        const visibleSections: Set<ViewType> = this.widgetRef.visibleSections
            .value
            ? this.widgetRef.visibleSections.value
            : new Set();
        visibleSections.add(ViewType.CARDS);
        this.widgetRef.visibleSections.next(visibleSections);
    }

    bindData(data): void {
        if (data) {
            // if (
            //     this.cardInput.titleActions &&
            //     this.cardInput.titleActions.length
            // ) {
            //     this.cardInput.titleActions.forEach((titleAction) => {
            //         if (titleAction.showLoader) {
            //             titleAction.loader = false;
            //         }
            //     });
            // }
            if (
                this.cardInput.cardButtons &&
                this.cardInput.cardButtons.length
            ) {
                this.cardInput.cardButtons.forEach((cardButton) => {
                    if (cardButton.showLoader) {
                        cardButton.loader = false;
                    }
                });
            }
            this.widgetRef.apiResponse = data;
            this.selectAll = false;
            this.totalSelectedCards = 0;

            if (this.cardInput.afterResponse) {
                this.cardInput.afterResponse(data);
            }

            if (this.cardInput.listExtraction.type === 'DIRECT') {
                this.cardsData = data;
            } else {
                this.cardsData = Helper.extractDataFromObject(
                    this.cardInput.listExtraction.nestedKey,
                    data
                );
            }

            if (!this.cardsData || !this.cardsData.length) {
                this.widgetRef.extraMessage.next(
                    this.cardInput.noDataMessage
                        ? this.cardInput.noDataMessage
                        : Messages.NO_DATA_AVAILABLE
                );
                this.widgetRef.visibleSections.next(new Set());
                this.widgetRef.attentionRequired(data);
                this.widgetRef.endLoader();
                return;
            }

            if (this.cardInput.selection === 'multiple') {
                this.selectAll = true;
                this.toggleSelection();
            }

            this.filteredCardsData = this.cardsData;
            this.widgetRef.endLoader();
        }
    }

    toggleSelection() {
        if (this.selectAll) {
            // Deselect All
            this.cardsData = this.cardsData.map((card) => {
                card.CGselected = false;
                return card;
            });
            this.selectAll = false;
        } else {
            // Select All
            this.cardsData = this.cardsData.map((card) => {
                card.CGselected = true;
                return card;
            });
            this.selectAll = true;
        }
        this.selectionChange = true;
        this.updateSelectedCardsCount();
    }

    getSelectedCards() {
        return this.cardsData.filter((card) => card.CGselected);
    }

    updateSelectedCardsCount() {
        if (this.selectionChange) {
            const selectedCards = this.getSelectedCards();
            this.selectionChange = false;
            this.totalSelectedCards = selectedCards.length;
            return selectedCards.length;
        }
    }

    handleCardClick(card) {
        card.CGselected = !card.CGselected;
        this.selectionChange = true;
        this.updateSelectedCardsCount();
    }
}
