import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
    CellClassParams,
    GridOptions,
    RowNode,
    ValueFormatterParams
} from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ActionState } from 'src/app/shared/enums/ActionState';
import { ActionVisibility } from 'src/app/shared/enums/ActionVisibility';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { State } from 'src/app/shared/enums/State';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import { IRightsizingApiResponse } from 'src/app/shared/interfaces/api/portlets/IRightsizingApiResponse';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IColumnData } from 'src/app/shared/interfaces/table-generator/IColumnData';
import { DeleteComponent } from '../../../modal-templates/delete/delete.component';
import { RightsizingModalComponent } from '../../../modal-templates/rightsizing/rightsizing-modal/rightsizing-modal.component';
import { ITableGeneratorInput } from './../../../../interfaces/table-generator/ITableGeneratorInput';
import { MultiStepFormService } from './../../../../services/modal/multi-step-form/multi-step-form.service';
import { NotificationsService } from './../../../../services/notifications/notifications.service';
import { RightsizingModalTableComponent } from './../../../modal-templates/rightsizing/rightsizing-modal-table/rightsizing-modal-table.component';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

enum Action {
    UPGRADE = 'Upgrade',
    DOWNGRADE = 'Downgrade'
}
@Component({
    selector: 'app-rightsizing',
    templateUrl: './rightsizing.component.html',
    styleUrls: ['./rightsizing.component.sass']
})
export class RightsizingComponent implements OnInit, AfterViewInit {
    ViewType = ViewType;
    widgetRef: Widget;
    cardsData: any[] = [];
    filteredCardsData: any[];
    filteredTableData: object[];
    State = State;
    selectedResources = [];
    rightsizeOptions = {};
    selectedRightsizing = {};
    tabs;
    dataMap;
    accounts;
    cardCount = {
        upgrade: 0,
        downgrade: 0,
        total: 0
    };
    actionType;
    actionInputJson = {
        manageServices: {}
    };
    tableData: object[];
    tableGen: ITableGeneratorInput;
    agGrid: GridOptions;
    severityButtons: IButtonGeneratorInput[];

    constructor(
        widgetData: WidgetInjectedData,
        private multiStepFormService: MultiStepFormService,
        private notificationService: NotificationsService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(true);

        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set([ViewType.CARDS]);
        this.widgetRef.visibleSections.next(visibleSections);

        const widgetAction: IAction[] = [
            this.widgetRef.widgetConfigState.actions.card
        ];
        this.widgetRef.widgetActions.next(widgetAction);

        this.widgetRef.visibleSections.subscribe(this.viewChanged.bind(this));

        // Actions
        if (
            this.widgetRef.widgetData.automation &&
            this.widgetRef.widgetData.automation.recommended
        ) {
            const actions: IAction[] = [];
            Object.keys(
                this.widgetRef.widgetData.automation.recommended
            ).forEach((action) => {
                if (
                    this.widgetRef.widgetData.automation.recommended[action]
                        .isMaterialIcon
                ) {
                    actions.push({
                        icon: {
                            type: IconType.MATICON,
                            class: this.widgetRef.widgetData.automation
                                .recommended[action].icon,
                            text: this.widgetRef.widgetData.automation
                                .recommended[action].buttonText
                        },
                        message: '',
                        state: ActionState.ENABLED,
                        visibility: ActionVisibility.VISIBLE,
                        function: this.performAction.bind(this),
                        actionId: action
                    });
                } else {
                    actions.push({
                        icon: {
                            type: IconType.FONTAWSOME,
                            class: this.widgetRef.widgetData.automation
                                .recommended[action].icon,
                            text: this.widgetRef.widgetData.automation
                                .recommended[action].buttonText
                        },
                        message: '',
                        state: ActionState.ENABLED,
                        visibility: ActionVisibility.VISIBLE,
                        function: () => {
                            this.performAction(action);
                        },
                        actionId: action
                    });
                }
            });
            this.widgetRef.fixActions.next(actions);
        }
    }

    bindData(data: IRightsizingApiResponse): void {
        if (data === null) {
            return;
        }
        // for view modal
        this.widgetRef.apiResponse = data;
        this.cardCount.upgrade = 0;
        this.cardCount.downgrade = 0;

        this.tabs = data.dataMap[`tabs`];
        this.dataMap = data.dataMap;
        this.accounts =
            data.dataList && data.dataList.length ? data.dataList : [];

        if (this.accounts && this.accounts.length === 0) {
            this.widgetRef.extraMessage.next(
                data.message ? data.message : Messages.NO_DATA_AVAILABLE
            );
            this.severityButtons = null;
            this.widgetRef.visibleSections.next(new Set());
            this.widgetRef.endLoader();
            return;
        }
        this.widgetRef.visibleSections.next(new Set([ViewType.CARDS]));

        this.accounts.forEach((item) => {
            if (item['Display Entry'].Action === 'Upgrade') {
                this.cardCount.upgrade += 1;
            } else if (item['Display Entry'].Action === 'Downgrade') {
                this.cardCount.downgrade += 1;
            }
            item.selected = false;
        });
        this.cardCount.total =
            this.cardCount.upgrade + this.cardCount.downgrade;

        if (this.widgetRef.lightState) {
            const upgrade = [];
            const downgrade = [];

            for (let i = 0; i < this.accounts.length; i++) {
                if (
                    downgrade.length < 2 &&
                    this.accounts[i]['Display Entry']['Action'] === 'Downgrade'
                ) {
                    downgrade.push(this.accounts[i]);
                    this.accounts.splice(i, 1);
                    i--;
                } else if (
                    upgrade.length < 2 &&
                    this.accounts[i]['Display Entry']['Action'] === 'Upgrade'
                ) {
                    upgrade.push(this.accounts[i]);
                    this.accounts.splice(i, 1);
                    i--;
                }
                if (upgrade.length === 2 && downgrade.length === 2) {
                    break;
                }
            }

            this.accounts.unshift(...downgrade);
            this.accounts.unshift(...upgrade);
        }
        this.cardsData = Helper.cloneDeep(this.accounts);
        this.filteredCardsData = Helper.cloneDeep(this.accounts);
        this.initTableGen();
        this.initButtonGen();
        this.widgetRef.endLoader();
    }

    initTableGen() {
        const dataList = Helper.cloneDeep(this.accounts);

        const columns: IColumnData[] = [];
        Object.keys(dataList[0]['Display Entry']).forEach((key) => {
            if (key === 'Action') {
                columns.splice(0, 0, {
                    columnKey: 'Recommendation',
                    columnName: 'Recommendation',
                    cellClass: (cellClassParams: CellClassParams) => {
                        return cellClassParams.value.toLowerCase();
                    }
                });
            } else {
                columns.push({ columnKey: key, columnName: key });
            }
        });

        columns.push({
            columnKey: 'action',
            columnName: 'Action',
            pinned: 'right',
            buttonGen: true,
            componentFramework: MultiButtonGeneratorComponent,
            valueFormatter: (params: ValueFormatterParams) => {
                const buttons: IButtonGeneratorInput[] = [
                    {
                        buttonName: 'More Info',
                        buttonType: ButtonType.TEXT,
                        buttonColorType: ButtonColorType.INFO,
                        function: () => {
                            this.showRightsizingModal(
                                this.filteredCardsData[params.node.rowIndex]
                            );
                        }
                    }
                ];

                params['buttonGenInputs'] = buttons;
                return params;
            }
        });

        this.tableData = [];
        dataList.forEach((data) => {
            const tableData = data['Display Entry'];
            const action = tableData['Action'];
            delete tableData['Action'];
            tableData['Recommendation'] = action;
            this.tableData.push(tableData);
        });
        this.filteredTableData = this.tableData;

        this.tableGen = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            selection: 'multiple',
            columns
        };
    }

    initButtonGen() {
        const buttonData = {
            Upgrade: 0,
            Downgrade: 1
        };

        this.severityButtons = [
            {
                buttonName: `Upgrade : ${this.cardCount.upgrade}`,
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.PRIMARY,
                hoverText: `${this.cardCount.upgrade}`,
                hoverEffect: 'shadow',
                function: (buttoRef: IButtonGeneratorInput) => {
                    if (
                        Helper.changeButtonType(
                            buttonData,
                            this.severityButtons,
                            'Upgrade',
                            this.search.bind(this)
                        )
                    ) {
                        this.search('Upgrade');
                    }
                }
            },
            {
                buttonName: `Downgrade : ${this.cardCount.downgrade}`,
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.SUCCESS,
                hoverText: `${this.cardCount.downgrade}`,
                hoverEffect: 'shadow',
                function: (buttoRef: IButtonGeneratorInput) => {
                    if (
                        Helper.changeButtonType(
                            buttonData,
                            this.severityButtons,
                            'Downgrade',
                            this.search.bind(this)
                        )
                    ) {
                        this.search('Downgrade');
                    }
                }
            }
        ];
    }

    performAction(action: any) {
        this.actionType = action;
        if (!this.selectedResources.length) {
            this.widgetRef.notificationsService.showSnackBar(
                'Select Atleast One Resource.',
                true
            );
            return;
        }
        if (
            this.selectedResources.length >
            this.widgetRef.widgetData.automation.recommended[action].limit
        ) {
            this.widgetRef.notificationsService.showSnackBar(
                'You Can Select Maximum ' +
                    this.widgetRef.widgetData.automation.recommended[action]
                        .limit +
                    ' Resources.',
                true
            );
            return;
        }

        this.prepareInputJson(
            this.widgetRef.widgetData.automation.recommended[action]
                .prepareKeysArray,
            this.widgetRef.widgetData.automation.recommended[action]
                .columnsToChoose
        );

        const rightsizeIcon: IIcon = {
            type: IconType.FONTAWSOME,
            class: 'fas fa-exchange-alt',
            extraClass: 'switch'
        };
        const modalId = Symbol();
        const modalData: IModalData = {
            modalName:
                this.widgetRef.widgetData.automation.recommended[action][
                    `label`
                ],
            modalId: modalId,
            modalIcon: rightsizeIcon,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MINI_MODAL,
            modalHeightVh: this.widgetRef.widgetData.automation.modalHeight
                ? this.widgetRef.widgetData.automation.modalHeight
                : 50,
            modalWidthVw: this.widgetRef.widgetData.automation.modalWidth
                ? this.widgetRef.widgetData.automation.modalWidth
                : 40,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: DeleteComponent,
                        payload: {
                            data: {
                                warning:
                                    this.widgetRef.widgetData.automation
                                        .recommended[action][`warning`],
                                deleteMessage: this.widgetRef.widgetData
                                    .automation.recommended[action][
                                    `modalMessage`
                                ]
                                    ? this.widgetRef.widgetData.automation
                                          .recommended[action][`modalMessage`]
                                    : 'Are you sure you want to perform this action ?',
                                reasonInput: false,
                                buttons: [
                                    {
                                        buttonName: 'Cancel',
                                        customClass: 'btn-primary-stroked',
                                        buttonType: ButtonType.STROKED,
                                        buttonColorType:
                                            ButtonColorType.PRIMARY,
                                        function: () => {
                                            this.widgetRef.modalService.closeModal(
                                                null,
                                                modalId
                                            );
                                        }
                                    },
                                    {
                                        buttonName: this.widgetRef.widgetData
                                            .automation.recommended[action][
                                            'buttonText'
                                        ]
                                            ? this.widgetRef.widgetData
                                                  .automation.recommended[
                                                  action
                                              ]['buttonText']
                                            : 'Confirm',
                                        buttonType: ButtonType.STROKED,
                                        buttonColorType: ButtonColorType.WARN,
                                        showLoader: true,
                                        function: (buttonRef) => {
                                            this.performActionAndRefresh(
                                                buttonRef,
                                                modalId
                                            );
                                        }
                                    }
                                ]
                            }
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    prepareInputJson(prepareKeysArray, columnsToChoose, otherResource?) {
        this.actionInputJson.manageServices = {};
        this.selectedResources.forEach((item) => {
            let prepareKey = '';
            prepareKeysArray.forEach((key, index) => {
                if (!index) {
                    prepareKey = item.Current[key];
                } else {
                    prepareKey = prepareKey + '|' + item.Current[key];
                }
            });
            let prepareValue = '';
            columnsToChoose.forEach((element, index) => {
                if (!index) {
                    prepareValue = item.Current[element];
                } else {
                    prepareValue = prepareValue + '|' + item.Current[element];
                }
            });

            let tempValue = '';
            if (
                typeof this.selectedRightsizing[item.Current['Resource Id']] ===
                'string'
            ) {
                tempValue =
                    this.selectedRightsizing[item.Current['Resource Id']];
            } else if (
                typeof this.selectedRightsizing[item.Current['Resource Id']] ===
                    'object' &&
                this.selectedRightsizing[item.Current['Resource Id']].length
            ) {
                // dynamodb case
                this.widgetRef.widgetData.automation.recommendationTypes.forEach(
                    (recomType, index) => {
                        const value = this.selectedRightsizing[
                            item.Current['Resource Id']
                        ].find((element) => element.includes(recomType));
                        const newValue = value ? value.split('|')[1] : -1;
                        if (!index) {
                            tempValue = newValue;
                        } else {
                            tempValue += '|' + newValue;
                        }
                    }
                );
            }
            const resourceType = otherResource ? otherResource : tempValue;

            if (!(prepareKey in this.actionInputJson.manageServices)) {
                this.actionInputJson.manageServices[prepareKey] = [
                    resourceType + '|' + prepareValue
                ];
            } else {
                this.actionInputJson.manageServices[prepareKey].push(
                    resourceType + '|' + prepareValue
                );
            }
        });
    }

    performActionAndRefresh(buttonRef, modalId: Symbol) {
        let apiPath;
        if (buttonRef && buttonRef['resources']) {
            const firstStep =
                this.widgetRef.widgetData.automation['nonRecommended'][
                    'steps'
                ][0];
            this.prepareInputJson(
                this.widgetRef.widgetData.automation['nonRecommended'][
                    firstStep
                ]['prepareKeysArray'],
                this.widgetRef.widgetData.automation['nonRecommended'][
                    firstStep
                ]['columnsToChoose'],
                buttonRef['resources']
            );
            apiPath =
                this.widgetRef.widgetData.automation['nonRecommended'][
                    'apiPath'
                ];
        } else {
            apiPath =
                this.widgetRef.widgetData.automation['recommended'][
                    this.actionType
                ].apiPath;
        }

        buttonRef.loader = true;

        const args: IHitApi = {
            url: `${this.widgetRef.widgetData.automation['host']}${apiPath}`,
            intactUrl: `${this.widgetRef.widgetData.automation['host']}{apiPath}`,
            input: this.actionInputJson,
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.AUTHORIZATION_TOKEN_V2,
                ignoreBaseUrl: true
            },
            function: (response) => {
                this.widgetRef.notificationsService.showSnackBar(
                    'Action performed successfully'
                );
                this.widgetRef.refreshWidget();
                this.widgetRef.modalService.closeModal(null, modalId);
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    Messages.DEFAULT_ERROR
                );
                buttonRef.loader = false;
                this.widgetRef.endLoader();
            }
        };
        new HitApi(
            args,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    search(text?: string) {
        this.filteredCardsData = Helper.cloneDeep(this.cardsData);
        this.filteredTableData = Helper.cloneDeep(this.tableData);

        if (text) {
            text = text.toLowerCase();
            this.filteredCardsData = this.filteredCardsData.filter((item) =>
                JSON.stringify(item['Display Entry'])
                    .toLowerCase()
                    .includes(text.trim())
            );
            this.filteredTableData = this.tableData.filter((item) => {
                return JSON.stringify(item['Recommendation'])
                    .toLowerCase()
                    .includes(text.trim());
            });
        }

        if (this.widgetRef.visibleSections.value.has(ViewType.TABLE)) {
            this.agGrid.api.setRowData(this.filteredTableData);
            this.agGrid.api.redrawRows();
        }
    }

    showRightsizingModal(card) {
        const toggleOnIcon: IIcon = {
            type: IconType.FONTAWSOME,
            class: 'fas fa-toggle-on',
            extraClass: 'switch'
        };
        const modalData: IModalData = {
            noHeader: true,
            modalName: 'Rightsizing',
            modalIcon: toggleOnIcon,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.SIDE,
            modalWidthVw: 70,
            noStepPadding: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: RightsizingModalComponent,
                        payload: {
                            data: { card, dataMap: this.dataMap }
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    viewChanged(event, visibleSection: Set<ViewType>) {
        if (!this.filteredCardsData) return;
        if (visibleSection && visibleSection.has(ViewType.CARDS)) {
            this.filteredCardsData = Helper.cloneDeep(this.accounts);
        }
    }

    selectedNode(event) {
        this.selectedResources = [];
        this.agGrid.api.getSelectedNodes().forEach((rowNode: RowNode) => {
            this.selectedResources.push(
                this.filteredCardsData[rowNode.rowIndex]
            );
        });
    }

    cardSelected(card, checked) {
        if (checked) {
            this.selectedResources.push(card);
        } else {
            this.selectedResources.splice(
                this.selectedResources.findIndex(
                    (x) =>
                        x.Current['Resource Id'] === card.Current['Resource Id']
                ),
                1
            );
        }
        if (!this.rightsizeOptions[card.Current['Resource Id']]) {
            this.rightsizeOptions[card.Current['Resource Id']] = [];
        } else {
            return;
        }
        if (
            this.widgetRef.widgetData.automation.dropdown &&
            this.widgetRef.widgetData.automation.dropdown.type === 'checkGroup'
        ) {
            // dynamodb
            this.selectedRightsizing[card.Current['Resource Id']] = [];
            this.widgetRef.widgetData.automation.dropdown.recommendationTypeKeys.forEach(
                (item) => {
                    const key = item.split('|');
                    if (card['Display Entry'][key[1]]) {
                        this.selectedRightsizing[
                            card.Current['Resource Id']
                        ].push(key[0] + '|' + card['Display Entry'][key[1]]);
                    }
                }
            );
        } else {
            // other services
            this.selectedRightsizing[card.Current['Resource Id']] =
                card['Display Entry'][
                    this.widgetRef.widgetData.automation.recommendationTypeKeys[0]
                ];
        }

        this.widgetRef.widgetData.automation.recommendationTypes.forEach(
            (item) => {
                if (card[item]) {
                    if (
                        this.widgetRef.widgetData.automation.dropdown &&
                        this.widgetRef.widgetData.automation.dropdown.type ===
                            'checkGroup'
                    ) {
                        // dynamodb service case
                        this.rightsizeOptions[card.Current['Resource Id']].push(
                            {
                                id:
                                    item +
                                    '|' +
                                    card[item].recommendationOrder[1],
                                label:
                                    card[item].recommendationOrder[1] +
                                    ' ' +
                                    this.widgetRef.widgetData.automation
                                        .dropdown.appendKey +
                                    ' (' +
                                    card[item]['Amortized Price']['Savings(%)'][
                                        card[item].recommendationOrder[1]
                                    ] +
                                    '%)',
                                category: item
                            }
                        );
                    } else {
                        // other services case

                        let key = '';
                        if (
                            this.widgetRef.widgetData.automation.dropdown
                                .appendKey
                        ) {
                            key =
                                ' ' +
                                this.widgetRef.widgetData.automation.dropdown
                                    .appendKey;
                        }
                        this.rightsizeOptions[card.Current['Resource Id']].push(
                            {
                                id: card[item].recommendationOrder[1],
                                label:
                                    card[item].recommendationOrder[1] +
                                    key +
                                    ' (' +
                                    card[item]['Amortized Price']['Savings(%)'][
                                        card[item].recommendationOrder[1]
                                    ] +
                                    '%)',
                                category: item
                            }
                        );
                    }
                }
            }
        );
    }

    prepareDefaultValue(value) {
        if (
            this.widgetRef.widgetData.automation.nonRecommended[
                this.widgetRef.widgetData.automation.nonRecommended.steps[0]
            ].displayFields
        ) {
            this.widgetRef.widgetData.automation.nonRecommended[
                this.widgetRef.widgetData.automation.nonRecommended.steps[0]
            ].displayFields.forEach((element, index) => {
                if (value) {
                    const tempValue = value.split('|');
                    if (element.selector === tempValue[0]) {
                        element.defaultValue = tempValue[1];
                    }
                } else {
                    element.defaultValue = null;
                }
            });
        }
    }

    selectedOtherResourceTypes(card) {
        if (!this.widgetRef.widgetData.automation) {
            return;
        }

        if (
            this.widgetRef.widgetData.automation.dropdown &&
            this.widgetRef.widgetData.automation.dropdown.type ===
                'checkGroup' &&
            this.widgetRef.widgetData.automation.dropdown.type === 'checkGroup'
        ) {
            // dynamodb case
            if (
                this.selectedRightsizing[card.Current['Resource Id']] &&
                this.selectedRightsizing[card.Current['Resource Id']].length
            ) {
                this.selectedRightsizing[card.Current['Resource Id']].forEach(
                    (element) => {
                        this.prepareDefaultValue(element);
                    }
                );
            } else {
                this.prepareDefaultValue(null);
            }

            this.widgetRef.widgetData.automation.nonRecommended[
                this.widgetRef.widgetData.automation.nonRecommended.steps[0]
            ].displayFields.forEach((element) => {
                if (card && JSON.stringify(card).includes(element.selector)) {
                    element.isVisible = true;
                } else {
                    element.isVisible = false;
                }
            });
        } else {
            // other services
            if (
                this.widgetRef.widgetData.automation.nonRecommended[
                    this.widgetRef.widgetData.automation.nonRecommended.steps[0]
                ].displayFields
            ) {
                this.widgetRef.widgetData.automation.nonRecommended[
                    this.widgetRef.widgetData.automation.nonRecommended.steps[0]
                ].displayFields[0].defaultValue =
                    this.selectedRightsizing[card.Current['Resource Id']];
                this.widgetRef.widgetData.automation.nonRecommended[
                    this.widgetRef.widgetData.automation.nonRecommended.steps[0]
                ].displayFields[0].isVisible = true;
            }
        }

        const firstStep =
            this.widgetRef.widgetData.automation.nonRecommended.steps[0];
        const secondStep =
            this.widgetRef.widgetData.automation.nonRecommended.steps[1];
        const RightsizeIcon: IIcon = {
            type: IconType.FONTAWSOME,
            class: 'fas fa-exchange-alt'
        };
        const modalId = Symbol();

        const modalData: IModalData = {
            modalName:
                this.widgetRef.widgetData.automation.recommended['change'][
                    `label`
                ],
            modalIcon: RightsizeIcon,
            sourceId: modalId,
            modalType: ModalType.MIDDLE,
            modalHeightVh: this.widgetRef.widgetData.automation.modalHeight
                ? this.widgetRef.widgetData.automation.modalHeight
                : 50,
            modalWidthVw: this.widgetRef.widgetData.automation.modalWidth
                ? this.widgetRef.widgetData.automation.modalWidth
                : 40,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: RightsizingModalTableComponent,
                        payload: {
                            data: {
                                nonRecommended:
                                    this.widgetRef.widgetData.automation[
                                        'nonRecommended'
                                    ],
                                rightsizeOptions:
                                    this.rightsizeOptions[
                                        card.Current['Resource Id']
                                    ],
                                host: this.widgetRef.widgetData.automation[
                                    'host'
                                ]
                            }
                        }
                    },
                    stepName:
                        this.widgetRef.widgetData.automation.nonRecommended[
                            firstStep
                        ].label
                },
                {
                    stepData: {
                        componentToLoad: DeleteComponent,

                        payload: {
                            data: {
                                warning:
                                    this.widgetRef.widgetData.automation
                                        .nonRecommended['confirmationStep'][
                                        `warning`
                                    ],
                                deleteMessage: this.widgetRef.widgetData
                                    .automation.nonRecommended[
                                    'confirmationStep'
                                ][`confirmationMessage`]
                                    ? this.widgetRef.widgetData.automation
                                          .nonRecommended['confirmationStep'][
                                          `confirmationMessage`
                                      ]
                                    : 'Are you sure you want to perform this action ?',
                                reasonInput: false,
                                buttons: [
                                    {
                                        buttonName: 'Back',
                                        buttonType: ButtonType.STROKED,
                                        buttonColorType:
                                            ButtonColorType.PRIMARY,
                                        function: (
                                            buttonRef: IButtonGeneratorInput,
                                            modalId: Symbol
                                        ) => {
                                            this.multiStepFormService.previousStep(
                                                modalId
                                            );
                                        }
                                    },
                                    {
                                        buttonName: this.widgetRef.widgetData
                                            .automation.nonRecommended[
                                            'confirmationStep'
                                        ]['buttonText']
                                            ? this.widgetRef.widgetData
                                                  .automation.nonRecommended[
                                                  'confirmationStep'
                                              ]['buttonText']
                                            : 'Confirm',
                                        buttonType: ButtonType.FLAT,
                                        buttonColorType: ButtonColorType.WARN,
                                        showLoader: true,
                                        resources: 'displayTable',
                                        function: (
                                            buttonRef: IButtonGeneratorInput,
                                            modalId: Symbol
                                        ) => {
                                            this.performActionAndRefresh(
                                                buttonRef,
                                                modalId
                                            );
                                        }
                                    }
                                ]
                            }
                        }
                    },

                    stepName:
                        this.widgetRef.widgetData.automation.nonRecommended[
                            secondStep
                        ].label
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    dropdownSelectedChange(key, value) {
        this.selectedRightsizing[key] = value;
    }

    checkDropdownSelectedChange(key, value) {
        if (!value || !value.length) {
            this.widgetRef.notificationsService.showSnackBar(
                'Atleast one of the recommendation have to be selected.',
                true
            );
        }
        this.selectedRightsizing[key] = value;
    }

    onCheckBoxChanged() {
        this.notificationService.showSnackBar(
            `Maximum of ${this.widgetRef.liteViewCheckSelectionLimit} row can be selected`
        );
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
