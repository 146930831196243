import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class KeyAlreadyExistsValidator extends CustomValidator {
    key = CustomValidatorKey.CONTROL_VALUE_NOT_IN;
    keyExists: boolean;
    constructor(private values, private caseSensitive: boolean) {
        super();
    }
    validatorFunction(control: AbstractControl): ValidationErrors {
        if (this.caseSensitive && !this.values.includes(control.value)) {
            return null;
        } else if (
            !this.caseSensitive &&
            !this.values.includes(
                control.value ? control.value.toLocaleLowerCase() : null
            )
        ) {
            return null;
        }

        const obj = {};
        obj[this.key] = true;
        return obj;
    }
}
