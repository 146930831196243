import { Pipe, PipeTransform } from '@angular/core';
import { Status } from '../../components/modal-templates/assessee-view/assessment-overview/assessment-overview.component';

@Pipe({
    name: 'getAssessmentStatus'
})
export class GetAssessmentStatusPipe implements PipeTransform {
    transform(section, status): boolean {
        let setStatus: boolean = true;
        let sameStatus: boolean = true;
        if (!status || !section) {
            return false;
        }
        const statusData = this.checkLeafSectionStatus(section, status);
        statusData.forEach((attStatus, index) => {
            if (attStatus !== status) {
                setStatus = false;
            }
            if (statusData[0] !== attStatus) {
                sameStatus = false;
            }
        });
        if (status === Status.INCOMPLETE && !setStatus && !sameStatus) {
            setStatus = true;
        }

        return setStatus;
    }
    checkLeafSectionStatus(sections, status) {
        let incompleteSections = [];
        sections.forEach((section) => {
            if (section.leafNode) {
                incompleteSections.push(section.status);
            } else if (
                !section.leafNode &&
                section.attributes &&
                section.attributes.length
            ) {
                const result = this.checkLeafSectionStatus(
                    section.attributes,
                    status
                );
                incompleteSections = [...incompleteSections, ...result];
            }
        });

        return incompleteSections;
    }
}
