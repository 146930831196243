import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { IconLibrary } from 'src/app/shared/classes/IconLibrary';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import {
    IconType,
    SupportedIconTypesForView,
    UINameOfIconType
} from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IDropdownData } from 'src/app/shared/interfaces/dropdown-data/IDropdownData';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { SvgIconNameConverterPipe } from 'src/app/shared/pipes/svg-icon-name-converter.pipe';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';

@Component({
    selector: 'app-edit-view-data',
    templateUrl: './edit-view-data.component.html',
    styleUrls: ['./edit-view-data.component.sass']
})
export class EditViewDataComponent implements OnInit {
    formGenInput: IFormGeneratorInput = null;
    formGroup: FormGroup = null;
    viewData: {
        viewIcon: IIcon;
        viewName: string;
    };
    edit = false;

    buttonGenInputs: IButtonGeneratorInput[] = [
        {
            buttonName: 'Cancel',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.modalService.closeModal(null, this.modalInputData.modalId);
            }
        },
        {
            buttonName: 'Update',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.updateViewData();
            }
        }
    ];

    buttonOptions: IMultiButtonOption = {
        layout: {
            justifyContent: 'space-between',
        },
    };

    constructor(
        public modalInputData: ModalInjectedData,
        public modalService: ModalService
    ) {}

    ngOnInit(): void {
        this.viewData = this.modalInputData.data.viewData;
        this.edit = this.modalInputData.data.edit;
        const svgNamePipe = new SvgIconNameConverterPipe();
        this.formGenInput = {
            formName: 'View Update',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    fieldType: FilterType.TEXT,
                    value: this.viewData.viewName,
                    label: 'View Name',
                    name: 'viewName',
                    placeholder: 'Enter view name',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'View name is required'
                        }
                    ],
                    disabled: this.edit ? true : false
                },
                {
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    value: this.viewData.viewIcon.type,
                    label: 'Icon Source',
                    name: 'iconSource',
                    placeholder: 'Enter icon source',
                    required: true,
                    listData: SupportedIconTypesForView().map(
                        (icon: IconType) => {
                            return { id: icon, label: UINameOfIconType(icon) };
                        }
                    ),
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Icon source is required'
                        }
                    ]
                },
                {
                    fieldType: FilterType.TEXT,
                    value: this.viewData.viewIcon.class,
                    label: 'Icon Name',
                    name: 'iconName',
                    placeholder: 'Enter icon name',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Icon name is required'
                        }
                    ],
                    hiddenDependency: [
                        {
                            controlName: 'iconSource',
                            validations: [
                                {
                                    validator: CustomValidators.controlValueIn([
                                        IconType.FONTAWSOME,
                                        IconType.MATICON
                                    ])
                                }
                            ]
                        }
                    ]
                },
                {
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    value: this.viewData.viewIcon.class,
                    label: 'Image',
                    name: 'image',
                    placeholder: 'Select image',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Image is required'
                        }
                    ],
                    hiddenDependency: [
                        {
                            controlName: 'iconSource',
                            validations: [
                                {
                                    validator:
                                        CustomValidators.controlValueMatch(
                                            IconType.IMAGE
                                        )
                                }
                            ]
                        }
                    ],
                    apiInfo: {
                        host: 'assets/image-list.json',
                        apiRouteSuffix: '',
                        authorization: AuthorizationType.NOT_AUTHORIZED,
                        requestType: RequestType.GET,
                        customInput: null
                    },
                    dropdownItemRenderImageFromId: true,
                    dropdownItemRenderImageType: IconType.IMAGE
                },
                {
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    value: this.viewData.viewIcon.class,
                    label: 'Image',
                    name: 'svgImage',
                    placeholder: 'Select image',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Image is required'
                        }
                    ],
                    hiddenDependency: [
                        {
                            controlName: 'iconSource',
                            validations: [
                                {
                                    validator:
                                        CustomValidators.controlValueMatch(
                                            IconType.SVG
                                        )
                                }
                            ]
                        }
                    ],
                    listData: Object.keys(IconLibrary.icon)
                        .filter((iconName) => !iconName.includes('_filled'))
                        .map((icon) => {
                            return {
                                id: icon,
                                label: svgNamePipe.transform(icon)
                            } as IDropdownData;
                        }),
                    dropdownItemRenderImageFromId: true,
                    dropdownItemRenderImageType: IconType.SVG
                }
            ]
        };
    }

    updateViewData() {
        if (this.formGroup.invalid) {
            Helper.markAllFieldAsTouched(this.formGroup);
            return;
        }
        const formValue = this.formGroup.getRawValue();
        this.viewData.viewName = formValue['viewName'];
        const icon: IIcon = {
            type: formValue['iconSource'],
            class:
                formValue['iconSource'] === IconType.FONTAWSOME ||
                formValue['iconSource'] === IconType.MATICON
                    ? formValue['iconName']
                    : formValue['iconSource'] === IconType.IMAGE
                    ? formValue['image']
                    : formValue['svgImage']
        };
        // Directly updating reference.. Change detection will update UI
        this.viewData.viewIcon = icon;
        this.modalService.closeModal(null, this.modalInputData.modalId);
    }
}
