import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { PortletType } from 'src/app/shared/enums/PortletType';
import { SupportedFormat } from 'src/app/shared/enums/SupportedFormat';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { ConfigCacheService } from './../../../../services/cache/config-cache/config-cache.service';

interface IWidget {
    id: string;
    name: string;
    supportedFormats: SupportedFormat[];
}
@Component({
    selector: 'app-budgeting-modal-step1',
    templateUrl: './budgeting-modal-step1.component.html',
    styleUrls: ['./budgeting-modal-step1.component.sass']
})
export class BudgetingModalStep1Component implements OnInit {
    budgetNameFormGenInput: IFormGeneratorInput = null;
    budgetNameFormGroup: FormGroup;
    widgetsSelected: string[] = [];
    widgetsSelectedMap: Map<string, string> = new Map();
    noWidgetsSelected = false;
    SupportedFormat = SupportedFormat;
    GlobalConfiguration = GlobalConfiguration;
    ApiUrls = ApiUrls;
    edit = false;
    stepData = null;
    widgetRef: Widget = null;
    supportedFormat: SupportedFormat = null;
    nextButton: IButtonGeneratorInput = {
        buttonName: 'Next',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        function: this.goToStepTwo.bind(this)
    };

    constructor(
        private modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        public configCacheService: ConfigCacheService
    ) {}

    ngOnInit(): void {
        this.widgetRef = this.modalInputData.data['widgetRef'];
        if (
            this.widgetRef.widgetData.widgetInfo.portlet.type ===
            PortletType.BUDGET_PIE
        ) {
            this.supportedFormat = SupportedFormat.BUDGET_PIE;
        } else if (
            this.widgetRef.widgetData.widgetInfo.portlet.type ===
            PortletType.BUDGET_REGION
        ) {
            this.supportedFormat = SupportedFormat.BUDGET_REGIONAL;
        } else if (
            this.widgetRef.widgetData.widgetInfo.portlet.type ===
            PortletType.BUDGET_TIMESERIES
        ) {
            this.supportedFormat = SupportedFormat.BUDGET_TIMESERIES;
        }
        if (
            this.modalInputData &&
            this.modalInputData.data &&
            this.modalInputData.data['edit']
        ) {
            this.edit = true;
            this.stepData = this.modalInputData.data['stepData'];
            this.widgetsSelected = this.stepData['widgetIds'];
        }
        let budgetName = this.edit ? this.stepData['budgetName'] : null;
        if (
            this.multiStepFormService.stepData &&
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(1)
        ) {
            const stepData = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1);
            this.widgetsSelected = stepData['widgetsSelected'];
            this.widgetsSelectedMap = stepData['widgetsSelectedMap'];
            budgetName = stepData['budgetName'];
        }
        this.budgetNameFormGenInput = {
            formName: 'BudgetName',
            state: this.edit ? FormState.IDLE : FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    name: 'budgetName',
                    fieldType: FilterType.TEXT,
                    label: 'Budget Name',
                    placeholder: 'Enter Budget Name',
                    required: true,
                    value: budgetName ? budgetName : '',
                    disabled: this.edit,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Budget name is required'
                        },
                        {
                            validator: CustomValidators.noSpecialCharacters,
                            errorMessage:
                                'Budget name can not contain special characters'
                        }
                    ]
                }
            ]
        };
    }

    goToStepTwo() {
        Helper.markAllFieldAsTouched(this.budgetNameFormGroup);
        if (!this.budgetNameFormGroup || this.budgetNameFormGroup.invalid) {
            return;
        }
        if (!this.widgetsSelected || !this.widgetsSelected.length) {
            this.noWidgetsSelected = true;
            return;
        }

        this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .set(1, {
                budgetName: this.budgetNameFormGroup.get('budgetName').value,
                widgetsSelected: this.widgetsSelected,
                widgetsSelectedMap: this.widgetsSelectedMap
            });
        this.multiStepFormService.nextStep(this.modalInputData.modalId);
    }
}
