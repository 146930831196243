<!--Field type one start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_1">
    <div class="form-row legacy {{ field.extraClass }}">
        <fieldset
            [class.error]="
                formGroup.get(field.name).touched &&
                formGroup.get(field.name).errors
            "
            [class.disabled]="formGeneratorInput.state === FormState.IDLE"
            (change)="
                field.onChange
                    ? field.onChange($event, formGroup, field.name)
                    : null
            "
        >
            <legend>
                {{ field.label }}
                <span
                    *ngIf="field.required === undefined || field.required"
                    class="mat-form-field-required-marker"
                >
                    &nbsp;*
                </span>
                <mat-icon
                    *ngIf="field.prefixIcon"
                    matPrefix
                    [matTooltip]="field.prefixIcon.hoverText"
                    class="clickable"
                    (click)="
                        field.prefixIcon.function
                            ? field.prefixIcon.function(field, formGroup)
                            : null
                    "
                >
                    {{ field.prefixIcon.iconData.class }}
                </mat-icon>
            </legend>
            <mat-radio-group
                [formControl]="control"
                [errorStateMatcher]="matcher"
                (change)="updateFormControls()"
            >
                <mat-radio-button
                    *ngFor="let radioButton of field.listData"
                    [value]="radioButton.id"
                    [disabled]="radioButton.disabled"
                    [matTooltip]="
                        radioButton.hoverText ? radioButton.hoverText : null
                    "
                >
                    {{ radioButton.label }}
                </mat-radio-button>
            </mat-radio-group>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </fieldset>
    </div>
</ng-container>
<!--Field type one end -->

<!--Field type two start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_2">
    <div class="form-row legacy {{ field.extraClass }}">
        <fieldset
            [class.error]="
                formGroup.get(field.name).touched &&
                formGroup.get(field.name).errors
            "
            [class.disabled]="formGeneratorInput.state === FormState.IDLE"
            (change)="
                field.onChange
                    ? field.onChange($event, formGroup, field.name)
                    : null
            "
        >
            <mat-radio-group
                [formControl]="control"
                [errorStateMatcher]="matcher"
                (change)="updateFormControls()"
            >
                <mat-radio-button
                    *ngFor="let radioButton of field.listData"
                    [value]="radioButton.id"
                    [disabled]="radioButton.disabled"
                    [matTooltip]="
                        radioButton.hoverText ? radioButton.hoverText : null
                    "
                >
                    {{ radioButton.label }}
                </mat-radio-button>
            </mat-radio-group>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </fieldset>
    </div>
</ng-container>
<!--Field type two end -->

<!--Field type three start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_3">
    <div class="form-row {{ field.extraClass }}">
        <span class="form-label">
            {{ field.label }}

            <span *ngIf="field.required" class="color-warn"> &nbsp;* </span>

            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(
                              field,

                              formGroup
                          )
                        : null
                "
            >
                {{ field.prefixIcon.iconData.class }}
            </mat-icon>
        </span>
        <fieldset
            class="form-input radio-field-set"
            [class.error]="
                formGroup.get(field.name).touched &&
                formGroup.get(field.name).errors
            "
            [class.disabled]="formGeneratorInput.state === FormState.IDLE"
            (change)="
                field.onChange
                    ? field.onChange($event, formGroup, field.name)
                    : null
            "
        >
            <mat-radio-group
                [formControl]="control"
                [errorStateMatcher]="matcher"
                (change)="updateFormControls()"
            >
                <mat-radio-button
                    class="tw-px-1"
                    *ngFor="let radioButton of field.listData"
                    [value]="radioButton.id"
                    [disabled]="radioButton.disabled"
                    [matTooltip]="
                        radioButton.hoverText ? radioButton.hoverText : null
                    "
                >
                    {{ radioButton.label }}
                </mat-radio-button>
            </mat-radio-group>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </fieldset>
    </div>
</ng-container>
<!--Field type three end -->

<!--Field type four start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_4">
    <div class="form-row {{ field.extraClass }} ">
        <fieldset
            class="radio-field-set"
            [class.error]="
                formGroup.get(field.name).touched &&
                formGroup.get(field.name).errors
            "
            [class.disabled]="formGeneratorInput.state === FormState.IDLE"
            (change)="
                field.onChange
                    ? field.onChange($event, formGroup, field.name)
                    : null
            "
        >
            <mat-radio-group
                [formControl]="control"
                [errorStateMatcher]="matcher"
                (change)="updateFormControls()"
            >
                <mat-radio-button
                    class="tw-px-1"
                    *ngFor="let radioButton of field.listData"
                    [value]="radioButton.id"
                    [disabled]="radioButton.disabled"
                    [matTooltip]="
                        radioButton.hoverText ? radioButton.hoverText : null
                    "
                >
                    {{ radioButton.label }}
                </mat-radio-button>
            </mat-radio-group>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </fieldset>
    </div>
</ng-container>
<!--Field type four end -->

<!-- Error Templates -->
<ng-template #groupError>
    <ng-container
        *ngIf="
            formGroup.get(field.groupByKey).get(field.name).touched &&
            formGroup.get(field.groupByKey).get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation
                        : formGroup.get(field.groupByKey).get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
<ng-template #error>
    <ng-container
        *ngIf="
            formGroup.get(field.name).touched &&
            formGroup.get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation: formGroup.get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
