import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { AuthorizationType } from './../../../../../enums/AuthorizationType';
import { ButtonType } from './../../../../../enums/ButtonType';
import { RequestType } from './../../../../../enums/RequestType';
import { IButtonGeneratorInput } from './../../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from './../../../../../interfaces/hit-api/IHitApi';
import { IInfoModal } from './../../../../../interfaces/modal/IInfoModal';
import { NotificationsService } from './../../../../../services/notifications/notifications.service';

@Component({
    selector: 'app-freshservice-integration-step-one',
    templateUrl: './freshservice-integration-step-one.component.html',
    styleUrls: ['./freshservice-integration-step-one.component.sass']
})
export class FreshserviceIntegrationStepOneComponent implements OnInit {
    formGenInput: IFormGeneratorInput = null;
    formGroup: FormGroup;
    edit: boolean = false;
    stepData: any;
    buttonInput: IButtonGeneratorInput;
    infoData: IInfoModal[] = [
        {
            infoHeading: '',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [
                        `Provide all the details as per your Freshservice Portal`
                    ]
                }
            ]
        }
    ];
    constructor(
        private modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        private notificationService: NotificationsService,
        private httpService: HttpService,
        private ngZone: NgZone
    ) {
        if (modalInputData && modalInputData.data && modalInputData.data.edit) {
            this.edit = true;
            this.stepData = modalInputData.data.response;
        }
    }

    ngOnInit(): void {
        if (
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(1)
        ) {
            this.stepData = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1);
        }

        this.settingUpForms();
        this.buttonInput = {
            buttonName: 'Next',
            buttonType: ButtonType.RAISED,
            buttonColorType: ButtonColorType.PRIMARY,
            showLoader: true,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.next(buttonRef);
            }
        };
    }

    settingUpForms() {
        this.formGenInput = {
            formName: 'Credentials',
            state: FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    label: 'Configuration Name',
                    placeholder: 'Configuration Name',
                    name: 'configurationName',
                    value: this.stepData ? this.stepData.configurationName : '',
                    disabled: this.edit,
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Configuration Name is required'
                        }
                    ]
                },
                {
                    label: 'Domain Name',
                    placeholder: 'Domain Name',
                    name: 'url',
                    value: this.stepData ? this.stepData.url : '',
                    fieldType: FilterType.URL,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Domain Name is required'
                        },
                        {
                            validator: CustomValidators.url,
                            errorMessage: 'Invalid URL'
                        }
                    ]
                },
                {
                    label: 'API Key',
                    placeholder: 'API Key',
                    name: 'apiKey',
                    value: this.stepData ? this.stepData.apiKey : '',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'API Key is required'
                        }
                    ]
                }
            ],
            extraClass: 'integration-new-styling'
        };
    }

    next(buttonRef: IButtonGeneratorInput) {
        Helper.markAllFieldAsTouched(this.formGroup);
        if (this.formGroup.invalid) {
            this.formGroup.updateValueAndValidity();
            return;
        } else {
            this.validateCredentials(buttonRef);
        }
    }

    validateCredentials(buttonRef: IButtonGeneratorInput) {
        buttonRef.loader = true;
        const apiConf: IHitApi = {
            url: ApiUrls.FRESHSERVICE_LIST_FIELDS,
            input: {
                url: this.formGroup.get('url').value,
                apiKey: this.formGroup.get('apiKey').value,
                configurationName: this.formGroup.get('configurationName').value
            },
            function: (response) => {
                buttonRef.loader = false;
                if (!response['isCredentialsValid']) {
                    Helper.showErrorMessage(
                        this.notificationService,
                        null,
                        'Invalid Credentials'
                    );
                } else {
                    const firstStepData = this.formGroup.getRawValue();
                    firstStepData['fields'] = response;
                    this.multiStepFormService.stepData
                        .get(this.modalInputData.modalId)
                        .set(1, firstStepData);

                    // next step
                    this.multiStepFormService.nextStep(
                        this.modalInputData.modalId
                    );
                }
            },
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(
                    this.notificationService,
                    error,
                    'Something went wrong'
                );
                buttonRef.loader = false;
            }
        };

        new HitApi(apiConf, this.httpService, this.ngZone).hitApi();
    }
}
