import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { DateFilterDropdownKeys } from 'src/app/shared/enums/DateFilterDropdownKeys';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { IFilterInfo } from './../../../interfaces/filter/IFilterInfo';

const START_DATE = '2020-04-01 00:00:00';
const END_DATE = '2020-06-30 23:59:59';
const USE_STATIC = false;
const MIN_POSSIBLE_TIME = ' 00:00:00';
const MAX_POSSIBLE_TIME = ' 23:59:59';
interface IDateFilterList {
    text;
    id;
    day?;
    month?;
}
enum DateFilterDropdownLabels {
    LAST_WEEK = 'Last 7 days',
    LAST_15_DAYS = 'Last 15 days',
    CURRENT_MONTH = 'Current month',
    LAST_MONTH = 'Last month',
    LAST_THREE_MONTH = 'Last 3 months',
    LAST_QUARTER = 'Last Quarter',
    LAST_SIX_MONTH = 'Last 6 months'
}
@Component({
    selector: 'app-date-range-filter',
    templateUrl: './date-range-filter.component.html',
    styleUrls: ['./date-range-filter.component.sass']
})
export class DateRangeFilterComponent implements OnInit, OnChanges {
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    @Output('dateOnChange') dateOnChange = new EventEmitter();
    @Input('selectedValue') selectedValue;
    @Input('minDateValue') minDateValue;
    @Input('maxDateValue') maxDateValue;
    @Input() lightMode: boolean;
    @Input() filterInfo: IFilterInfo;

    //If date range is just to be shown as a dummy, set its value to true
    @Input() dummy: boolean;

    dateFilterList: IDateFilterList[] = [
        {
            text: DateFilterDropdownLabels.LAST_WEEK,
            id: DateFilterDropdownKeys.LAST_WEEK,
            day: 7
        },
        {
            text: DateFilterDropdownLabels.LAST_15_DAYS,
            id: DateFilterDropdownKeys.LAST_15_DAYS,
            day: 15
        },
        {
            text: DateFilterDropdownLabels.CURRENT_MONTH,
            id: DateFilterDropdownKeys.CURRENT_MONTH
        },
        {
            text: DateFilterDropdownLabels.LAST_MONTH,
            id: DateFilterDropdownKeys.LAST_MONTH
        },
        {
            text: DateFilterDropdownLabels.LAST_THREE_MONTH,
            id: DateFilterDropdownKeys.LAST_THREE_MONTH
        },
        {
            text: DateFilterDropdownLabels.LAST_QUARTER,
            id: DateFilterDropdownKeys.LAST_QUARTER
        },
        {
            text: DateFilterDropdownLabels.LAST_SIX_MONTH,
            id: DateFilterDropdownKeys.LAST_SIX_MONTH
        }
    ];
    dateValueString: string;
    endDateValue: Date;
    startDateValue: Date;
    startDate: string;
    endDate: string;
    showCalender: boolean;
    showDropdown: boolean;
    hoveredDate: NgbDateStruct;
    min: Date;
    minDate: NgbDate;
    max: Date;
    maxDate: NgbDate;
    startDateTime: string;
    endDateTime: string;
    selectedDates: BehaviorSubject<Record<string, Date>> = new BehaviorSubject<
        Record<string, Date>
    >(null);

    constructor(private filtersService: FiltersService) {}

    ngOnInit(): void {
        this.setMinMaxDate();
        this.setInitialStartAndEndDate();
    }

    ngOnChanges(changes) {
        if (changes.selectedValue && !changes.selectedValue.firstChange) {
            this.startDateValue = new Date(
                changes.selectedValue.currentValue.startDateTime
            );
            this.endDateValue = new Date(
                changes.selectedValue.currentValue.endDateTime
            );
            this.prepareAndConvertDateTime();
            this.applyDate();
        }
    }

    setMinMaxDate() {
        this.min = new Date(new Date().setMonth(new Date().getMonth()));
        if (this.minDateValue) {
            this.min = new Date(this.minDateValue);
        }
        this.minDate = this.getNgbDate(this.min);
        this.max = new Date(new Date().setDate(new Date().getDate() - 1));
        if (this.maxDateValue) {
            this.max = new Date(this.maxDateValue);
        }
        this.maxDate = this.getNgbDate(this.max);
    }

    getNgbDate(date: Date): NgbDate {
        return new NgbDate(
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate()
        );
    }

    setInitialStartAndEndDate() {
        if (USE_STATIC) {
            const startDate = START_DATE.split(' ')[0];
            const startTime = START_DATE.split(' ')[1];
            const startDateSplitted: number[] = startDate
                .split('-')
                .map((i) => +i);
            const startTimeSplitted: number[] = startTime
                .split(':')
                .map((i) => +i);
            this.startDateValue = Helper.getDateValue(
                startDateSplitted,
                startTimeSplitted
            );
            const endDate = END_DATE.split(' ')[0];
            const endTime = END_DATE.split(' ')[1];
            const dateSplitted = endDate.split('-').map((i) => +i);
            const timeSplitted = endTime.split(':').map((i) => +i);
            this.endDateValue = Helper.getDateValue(dateSplitted, timeSplitted);
            this.prepareAndConvertDateTime();
            this.applyDate();
        } else if (this.selectedValue) {
            if (this.selectedValue.id) {
                this.selectDateById(this.selectedValue.id);
            } else {
                this.startDateValue = new Date(
                    this.selectedValue.startDateTime
                );
                this.endDateValue = new Date(this.selectedValue.endDateTime);
                this.prepareAndConvertDateTime();
                this.applyDate();
            }
        } else {
            this.dateChanged(
                {
                    text: DateFilterDropdownLabels.LAST_WEEK,
                    id: DateFilterDropdownKeys.LAST_WEEK,
                    day: 7
                },
                true
            );
        }
        this.selectedDates.next({
            startDateValue: this.startDateValue,
            endDateValue: this.endDateValue
        });
    }

    customDateUpdated(ev) {
        const fromDateSplitted = [
            ev.fromDate.year,
            ev.fromDate.month,
            ev.fromDate.day
        ];
        const toDateSplitted = [ev.toDate.year, ev.toDate.month, ev.toDate.day];
        const timeSplitted = [23, 59, 59];
        this.startDateValue = Helper.getDateValue(
            fromDateSplitted,
            timeSplitted
        );
        this.endDateValue = Helper.getDateValue(toDateSplitted, timeSplitted);
        this.prepareAndConvertDateTime();
        this.applyDateAndRefresh();
    }

    selectDateById(id: string) {
        const selection = this.dateFilterList.find((obj) => obj.id === id);
        this.dateChanged(selection, true);
    }

    dateChanged(selection: IDateFilterList, initial?: boolean) {
        const exactDateText = null;
        if ('day' in selection) {
            const obj = Helper.getDateTime(
                selection.day,
                this.filterInfo &&
                    (this.filterInfo.type === FilterType.DATE_RANGE_TODAY ||
                        this.filterInfo.type === FilterType.WIDGET_DATE_RANGE)
                    ? true
                    : false
            );
            this.startDateValue = obj.startDateTimeValue;
            this.endDateValue = obj.endDateTimeValue;
        } else if ('month' in selection) {
            this.endDateValue = new Date();
            this.startDateValue = new Date(
                new Date().setMonth(new Date().getMonth() - selection.month)
            );
        } else {
            switch (selection.text) {
                case DateFilterDropdownLabels.LAST_MONTH:
                    this.updateStartEndDateValue(
                        new Date().getMonth() - 1,
                        1,
                        null,
                        null,
                        1,
                        -1
                    );
                    break;
                case DateFilterDropdownLabels.LAST_THREE_MONTH:
                    this.updateStartEndDateValue(
                        new Date().getMonth() - 3,
                        1,
                        null,
                        new Date().getMonth(),
                        1,
                        -1
                    );
                    break;
                case DateFilterDropdownLabels.LAST_SIX_MONTH:
                    this.updateStartEndDateValue(
                        new Date().getMonth() - 6,
                        1,
                        null,
                        new Date().getMonth(),
                        1,
                        -1
                    );
                    break;
                case DateFilterDropdownLabels.CURRENT_MONTH:
                    this.startDateValue = new Date();
                    this.startDateValue.setDate(1);
                    this.endDateValue =
                        new Date().getDate() === 1
                            ? new Date()
                            : this.filterInfo.type ===
                              FilterType.WIDGET_DATE_RANGE
                            ? new Date(new Date().setDate(new Date().getDate()))
                            : new Date(
                                  new Date().setDate(new Date().getDate() - 1)
                              );
                    break;
                case DateFilterDropdownLabels.LAST_QUARTER:
                    const currentMonth = new Date().getMonth();
                    let lastQuarterMonth = currentMonth - 3;
                    if (lastQuarterMonth < 0) {
                        lastQuarterMonth = 9;
                    }
                    switch (lastQuarterMonth) {
                        case 0:
                        case 1:
                        case 2:
                            this.updateStartEndDateValue(0, 1, null, 3, 1, -1);
                            break;
                        case 3:
                        case 4:
                        case 5:
                            this.updateStartEndDateValue(3, 1, null, 6, 1, -1);
                            break;
                        case 6:
                        case 7:
                        case 8:
                            this.updateStartEndDateValue(6, 1, null, 9, 1, -1);
                            break;
                        case 9:
                        case 10:
                        case 11:
                            this.updateStartEndDateValue(9, 1, null, 12, 1, -1);
                            if (currentMonth <= 2) {
                                this.startDateValue.setFullYear(
                                    new Date().getFullYear() - 1
                                );
                                this.endDateValue.setFullYear(
                                    new Date().getFullYear() - 1
                                );
                            }
                            break;
                        default:
                            break;
                    }
            }
        }

        this.filtersService.selectedDateDropdownId = selection.id;
        this.prepareAndConvertDateTime();

        if (!initial) {
            this.applyDateAndRefresh(exactDateText);
        } else {
            this.applyDate(exactDateText);
        }
    }

    prepareAndConvertDateTime() {
        this.startDateTime =
            Helper.convertDateObjectToRequiredFormat(this.startDateValue) +
            MIN_POSSIBLE_TIME;
        this.endDateTime =
            Helper.convertDateObjectToRequiredFormat(this.endDateValue) +
            MAX_POSSIBLE_TIME;
        this.startDate = Helper.prepareDateWithMonthName(this.startDateValue);
        this.endDate = Helper.prepareDateWithMonthName(this.endDateValue);
        this.selectedDates.next({
            startDateValue: this.startDateValue,
            endDateValue: this.endDateValue
        });
    }

    updateStartEndDateValue(sMonth, sDate, sHours, eMonth, eDate, eHours) {
        this.startDateValue = new Date();
        this.endDateValue = new Date();
        if (sDate) {
            this.startDateValue.setDate(sDate);
        }
        if (sMonth || sMonth === 0) {
            this.startDateValue.setMonth(sMonth);
        }
        if (sHours) {
            this.startDateValue.setHours(sHours);
        }
        if (eMonth) {
            this.endDateValue.setMonth(eMonth);
        }
        if (eDate) {
            this.endDateValue.setDate(eDate);
        }
        if (eHours) {
            this.endDateValue.setHours(eHours);
        }
    }

    applyDate(dateString?) {
        this.dateValueString = dateString
            ? dateString
            : this.startDate + ' - ' + this.endDate;
    }

    applyDateAndRefresh(dateString = null) {
        this.dateValueString = dateString
            ? dateString
            : this.startDate + ' - ' + this.endDate;

        this.dateOnChange.emit({
            startDateTime: this.startDateTime,
            endDateTime: this.endDateTime
        });
    }
}
