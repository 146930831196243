<div
    class="modal-container {{
        modalData.extraClass ? modalData.extraClass : ''
    }}"
    *ngIf="modalData.modalType !== ModalType.COMPARE"
    [class.side]="modalData.modalType === ModalType.SIDE"
    [class.mini]="modalData.modalType === ModalType.MINI_MODAL"
    [ngStyle]="{
        height: modalData.modalHeightPx
            ? modalData.modalHeightPx + 'px'
            : modalData.modalSteps[
                  (multiStepFormService.currentStep.get(modalData.modalId)
                      | async) - 1
              ].stepHeightVh
            ? modalData.modalSteps[
                  (multiStepFormService.currentStep.get(modalData.modalId)
                      | async) - 1
              ].stepHeightVh + 'vh'
            : modalData.modalAutoHeight
            ? 'auto'
            : modalData.modalHeightVh
            ? modalData.modalHeightVh + 'vh'
            : null,
        width: modalData.modalSteps[
            (multiStepFormService.currentStep.get(modalData.modalId) | async) -
                1
        ].stepWidthVw
            ? modalData.modalSteps[
                  (multiStepFormService.currentStep.get(modalData.modalId)
                      | async) - 1
              ].stepWidthVw + 'vw'
            : modalData.modalAutoWidth
            ? 'auto'
            : modalData.modalWidthVw
            ? modalData.modalWidthVw + 'vw'
            : null,
        minHeight: modalData.minHeightVh ? modalData.minHeightVh + 'vh' : '',
        maxHeight: modalData.maxModalHeightPx
            ? modalData.maxModalHeightPx + 'px'
            : modalData.maxHeightVh
            ? modalData.maxHeightVh + 'vh'
            : ''
    }"
>
    <div
        class="modal-head"
        [class.head-left-padding]="modalData.noHeaderLine"
        *ngIf="!modalData.noHeader"
    >
        <div class="icon" *ngIf="modalData.modalIconRequired">
            <div
                appIcon
                [data]="modalData.modalIcon"
                *ngIf="modalData.modalIcon"
                [ngStyle]="{ height: '100%', width: '100%' }"
            ></div>
        </div>
        <div class="text">
            {{
                modalData.modalSteps[
                    (multiStepFormService.currentStep.get(modalData.modalId)
                        | async) - 1
                ].headingText
                    ? modalData.modalSteps[
                          (multiStepFormService.currentStep.get(
                              modalData.modalId
                          ) | async) - 1
                      ].headingText
                    : modalData.modalName
            }}
        </div>
        <div
            class="template-data"
            *ngIf="
                modalData.modalSteps[
                    (multiStepFormService.currentStep.get(modalData.modalId)
                        | async) - 1
                ].headerIconTemplate
            "
        >
            <ng-container
                *ngTemplateOutlet="
                    modalData.modalSteps[
                        (multiStepFormService.currentStep.get(modalData.modalId)
                            | async) - 1
                    ].headerIconTemplate
                "
            ></ng-container>
        </div>
        <div
            *ngIf="
                modalData.modalSteps[
                    (multiStepFormService.currentStep.get(modalData.modalId)
                        | async) - 1
                ].resetModal
                    ? modalData.modalSteps[
                          (multiStepFormService.currentStep.get(
                              modalData.modalId
                          ) | async) - 1
                      ].resetModal
                    : false
            "
            class="reset-modal clickable"
            [class.margin-auto]="
                !modalData.modalSteps[
                    (multiStepFormService.currentStep.get(modalData.modalId)
                        | async) - 1
                ].headerIconTemplate
            "
            (click)="resetModal()"
            appIcon
            [iconName]="iconName.REFRESH"
        ></div>
        <div
            class="close-modal"
            [class.margin-auto]="
                !modalData.modalSteps[
                    (multiStepFormService.currentStep.get(modalData.modalId)
                        | async) - 1
                ].headerIconTemplate &&
                !modalData.modalSteps[
                    (multiStepFormService.currentStep.get(modalData.modalId)
                        | async) - 1
                ].resetModal
                    ? true
                    : false
            "
            appIcon
            [data]="crossIcon"
            (click)="
                modalData.closeModal ? modalData.closeModal() : closeModal()
            "
        ></div>
    </div>
    <div
        class="modal-data-container"
        [class.no-bottom-padding]="modalData.noBottomPadding"
    >
        <div
            class="steps-header"
            *ngIf="
                modalData.modalSteps &&
                modalData.modalSteps.length > 1 &&
                !modalData.hideSteps
            "
        >
            <div class="tw-w-full tw-flex tw-justify-center">
                <div class="tw-w-4/5">
                    <ng-container
                        [ngTemplateOutlet]="stepDetail"
                        [ngTemplateOutletContext]="{
                            currentStepIndex:
                                (multiStepFormService.currentStep.get(
                                    modalData.modalId
                                ) | async) - 1
                        }"
                    ></ng-container>
                    <ng-template #stepDetail let-index="currentStepIndex">
                        <div
                            *ngIf="
                                modalData.modalSteps &&
                                modalData.modalSteps[index]
                            "
                            class="tw-flex tw-justify-between modal-details"
                        >
                            <div>
                                {{ modalData.modalSteps[index].stepName }}
                            </div>
                            <div>
                                {{ index + 1 }} /
                                {{ modalData.modalSteps.length }}
                            </div>
                        </div>
                        <mat-progress-bar
                            class="tw-w-full tw-h-3 tw-items-center tw-flex tw-justify-center"
                            mode="determinate"
                            [value]="
                                index === 0
                                    ? (1 / modalData.modalSteps.length) * 100
                                    : index === modalData.modalSteps.length - 1
                                    ? 90
                                    : ((index + 1) /
                                          modalData.modalSteps.length) *
                                      100
                            "
                        ></mat-progress-bar>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="steps-content">
            <div
                class="steps-container {{
                    modalData.extraStepContainerClass
                        ? modalData.extraStepContainerClass
                        : ''
                }}"
                [class.withHeaderPadding]="!modalData.noHeader"
                [class.noPadding]="modalData.noStepPadding"
                [class.no-top-padding]="modalData.noHeaderLine"
            >
                <div class="overlay" *ngIf="loader | async">
                    <div class="overlay-content">
                        <div
                            *ngIf="loader | async"
                            appIcon
                            [data]="spinnerLoader"
                            class="overlay-loader"
                        ></div>
                    </div>
                </div>
                <ng-container
                    *ngFor="
                        let modalStep of modalData.modalSteps;
                        let i = index
                    "
                >
                    <ng-container
                        *ngIf="
                            (multiStepFormService.currentStep.get(
                                modalData.modalId
                            ) | async) ===
                            i + 1
                        "
                    >
                        <ng-container
                            *ngIf="
                                modalStep.stepData &&
                                modalStep.stepData.componentToLoad
                            "
                        >
                            <ng-container
                                *ngComponentOutlet="
                                    modalStep.stepData.componentToLoad;
                                    injector: stepInjectors.get(
                                        modalStep.stepIdentifier
                                    )
                                "
                            >
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<div
    class="modal-container compare"
    *ngIf="modalData.modalType === ModalType.COMPARE"
>
    <div class="modal-data-container">
        <app-link-widget [modalData]="modalData"></app-link-widget>
    </div>
</div>
