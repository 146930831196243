import { Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';

@Component({
    selector: 'app-preview-tf-file',
    templateUrl: './preview-tf-file.component.html',
    styleUrls: ['./preview-tf-file.component.sass']
})
export class PreviewTfFileComponent implements OnInit {
    file: any;
    closeButtonGenInput: IButtonGeneratorInput;
    constructor(
        private modalInputData: ModalInjectedData,
        private modalService: ModalService
    ) {}

    ngOnInit(): void {
        if (
            this.modalInputData.data &&
            this.modalInputData.data.file &&
            typeof this.modalInputData.data.file === 'string'
        ) {
            this.file = this.modalInputData.data.file;
        } else {
            Helper.stringFileReader(
                this.modalInputData.data,
                this.uploadDocument.bind(this)
            );
        }
        this.generateButtons();
    }
    generateButtons() {
        this.closeButtonGenInput = {
            buttonName: 'Close',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: (buttonRef) => {
                this.close();
            },
            showLoader: false
        };
    }
    uploadDocument(file) {
        this.file = file;
    }
    close() {
        this.modalService.closeModal(null, this.modalInputData.modalId);
    }
}
