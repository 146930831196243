import { Component, OnInit } from '@angular/core';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';

@Component({
    selector: 'app-servicenow-variable-set-form',
    templateUrl: './servicenow-variable-set-form.component.html',
    styleUrls: ['./servicenow-variable-set-form.component.sass']
})
export class ServicenowVariableSetFormComponent implements OnInit {
    variableResp = {};
    stepData = {};
    buttonInput: IButtonGeneratorInput;
    constructor(
        private modalService: ModalService,
        private notificationsService: NotificationsService,
        private modalInputData: ModalInjectedData
    ) {
        if (this.modalInputData && this.modalInputData.data) {
            this.stepData = this.modalInputData.data;
        }
    }

    ngOnInit() {
        this.buttonInput = {
            buttonName: 'Add',
            buttonType: ButtonType.RAISED,
            buttonColorType: ButtonColorType.PRIMARY,
            function: () => {
                this.add();
            }
        };
    }

    add() {
        let valid = true;
        Object.values(this.variableResp).forEach((val) => {
            if (typeof val === 'string' && !val.trim()) {
                valid = false;
            } else if (Array.isArray(val) && !Array.from(val).length) {
                valid = false;
            }
        });
        if (!valid) {
            this.notificationsService.showSnackBar(
                'All fields are necessary',
                true
            );
        }

        this.stepData['func'](this.variableResp);
        this.modalService.closeModal(null, this.modalInputData.modalId);
    }
}
