<ng-container>
    <div [formGroupName]="field.groupByKey">
        <!-- field type one start -->
        <div
            *ngIf="field.appearance === AppearanceType.TYPE_1"
            class="ng-select-dropdown-container"
        >
            <div
                [ngClass]="
                    'ng-select-custom-material-wrapper ng-select-custom-material-wrapper-outline'
                "
                [class.error]="
                    field.groupByKey
                        ? formGroup.get(field.groupByKey).get(field.name)
                              .touched &&
                          formGroup.get(field.groupByKey).get(field.name).errors
                        : formGroup.get(field.name).touched &&
                          formGroup.get(field.name).errors
                "
                [class.disabled]="
                    formGeneratorInput.state === FormState.IDLE ||
                    (field.populateFromControl &&
                        !formGroup.get(field.populateFromControl)) ||
                    (formGroup.get(field.populateFromControl) &&
                        (!formGroup.get(field.populateFromControl).value ||
                            !formGroup.get(field.populateFromControl).value
                                .length))
                "
                [matTooltip]="
                    (field.populateFromControl &&
                        !formGroup.get(field.populateFromControl)) ||
                    (formGroup.get(field.populateFromControl) &&
                        (!formGroup.get(field.populateFromControl).value ||
                            !formGroup.get(field.populateFromControl).value
                                .length))
                        ? 'Select ' +
                          (
                              field.populateFromControl
                              | fieldDataFromControlName
                                  : formGeneratorInput.fields
                          ).label +
                          ' values first'
                        : null
                "
                [matTooltipShowDelay]="1000"
                [matTooltipPosition]="'before'"
            >
                <legend
                    class="dropdown-legend"
                    *ngIf="
                        formGroup.get(field.name).value &&
                        formGroup.get(field.name).value.length &&
                        !field.showLabel &&
                        !field.hideFloatLabel
                    "
                >
                    {{ field.placeholder ? field.placeholder : field.label }}

                    <span
                        *ngIf="
                            field.required !== undefined &&
                            field.required &&
                            !field.showLabel
                        "
                        class="mat-form-field-required-marker"
                        >&nbsp;*</span
                    >
                    <mat-icon
                        *ngIf="field.prefixIcon"
                        matPrefix
                        [matTooltip]="field.prefixIcon.hoverText"
                        class="clickable"
                        (click)="
                            field.prefixIcon.function
                                ? field.prefixIcon.function(field, formGroup)
                                : null
                        "
                        >{{ field.prefixIcon.iconData.class }}</mat-icon
                    >
                </legend>
                <ng-select
                    [class.field-required]="
                        field.required !== undefined && field.required
                    "
                    style="z-index: 10"
                    [appearance]="'outline'"
                    [bindValue]="null"
                    [items]="
                        !loading.get(field.name) && field.objectListData
                            ? field.objectListData
                            : []
                    "
                    [closeOnSelect]="true"
                    [placeholder]="
                        field.placeholder ? field.placeholder : field.label
                    "
                    [loading]="loading.get(field.name)"
                    [appendTo]="'body'"
                    [loadingText]="
                        field.loadingMessage
                            ? field.loadingMessage
                            : 'Loading...'
                    "
                    dropdownPosition="auto"
                    [formControl]="control"
                    [errorStateMatcher]="matcher"
                    [clearable]="true"
                    [multiple]="false"
                    [virtualScroll]="true"
                    (change)="onValueChange($event)"
                    [compareWith]="compareWith"
                    (open)="showOverlay = true"
                    (close)="showOverlay = false"
                >
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                    >
                        <div *ngFor="let key of Object.keys(item)">
                            <div class="key-val">
                                <span>{{ key }}</span> =
                                <span>{{ item[key] + " ;" }}</span> &nbsp;
                                &nbsp;
                            </div>
                        </div>
                    </ng-template>
                    <ng-container>
                        <ng-template
                            ng-multi-label-tmp
                            let-items="selectedValues"
                            let-clear="clear"
                        >
                            <div
                                class="ng-value list-object-label"
                                *ngIf="
                                    field.groupByKey
                                        ? formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value
                                        : formGroup.get(field.name).value
                                "
                            >
                                <span class="ng-value-label"
                                    >{{ "1 selected" }}
                                </span>
                            </div>
                        </ng-template>
                    </ng-container>
                </ng-select>
                <!-- Handling errors -->
                <ng-container *ngIf="field.groupByKey">
                    <ng-template *ngTemplateOutlet="groupError"></ng-template>
                </ng-container>
                <ng-container *ngIf="!field.groupByKey">
                    <ng-template *ngTemplateOutlet="error"></ng-template>
                </ng-container>
            </div>
        </div>
        <!-- field type one end -->

        <!-- field type four start -->
        <div
            *ngIf="field.appearance === AppearanceType.TYPE_4"
            class="ng-select-dropdown-container"
        >
            <div
                [ngClass]="
                    'ng-select-custom-material-wrapper ng-select-custom-material-wrapper-underline'
                "
                [class.error]="
                    field.groupByKey
                        ? formGroup.get(field.groupByKey).get(field.name)
                              .touched &&
                          formGroup.get(field.groupByKey).get(field.name).errors
                        : formGroup.get(field.name).touched &&
                          formGroup.get(field.name).errors
                "
                [class.disabled]="
                    field.groupByKey
                        ? formGeneratorInput.state === FormState.IDLE ||
                          (field.populateFromControl &&
                              !formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl)) ||
                          (formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl) &&
                              (!formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl).value ||
                                  !formGroup
                                      .get(field.groupByKey)
                                      .get(field.populateFromControl).value
                                      .length))
                        : formGeneratorInput.state === FormState.IDLE ||
                          (field.populateFromControl &&
                              !formGroup.get(field.populateFromControl)) ||
                          (formGroup.get(field.populateFromControl) &&
                              (!formGroup.get(field.populateFromControl)
                                  .value ||
                                  !formGroup.get(field.populateFromControl)
                                      .value.length))
                "
                [matTooltip]="
                    field.groupByKey
                        ? (field.populateFromControl &&
                              !formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl)) ||
                          (formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl) &&
                              (!formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl).value ||
                                  !formGroup
                                      .get(field.groupByKey)
                                      .get(field.populateFromControl).value
                                      .length))
                            ? 'Select ' +
                              (
                                  field.populateFromControl
                                  | fieldDataFromControlName
                                      : formGeneratorInput.fields
                              ).label +
                              ' values first'
                            : null
                        : (field.populateFromControl &&
                              !formGroup.get(field.populateFromControl)) ||
                          (formGroup.get(field.populateFromControl) &&
                              (!formGroup.get(field.populateFromControl)
                                  .value ||
                                  !formGroup.get(field.populateFromControl)
                                      .value.length))
                        ? 'Select ' +
                          (
                              field.populateFromControl
                              | fieldDataFromControlName
                                  : formGeneratorInput.fields
                          ).label +
                          ' values first'
                        : null
                "
                [matTooltipShowDelay]="1000"
                [matTooltipPosition]="'before'"
            >
                <ng-select
                    [class.field-required]="
                        field.required !== undefined && field.required
                    "
                    style="z-index: 10"
                    [appearance]="'legacy'"
                    [bindValue]="null"
                    [items]="
                        !loading.get(field.name) && field.objectListData
                            ? field.objectListData
                            : []
                    "
                    [closeOnSelect]="true"
                    [placeholder]="
                        field.placeholder ? field.placeholder : field.label
                    "
                    [loading]="loading.get(field.name)"
                    [appendTo]="'body'"
                    [loadingText]="
                        field.loadingMessage
                            ? field.loadingMessage
                            : 'Loading...'
                    "
                    dropdownPosition="auto"
                    [clearable]="true"
                    [formControl]="control"
                    [errorStateMatcher]="matcher"
                    [multiple]="false"
                    [virtualScroll]="true"
                    (change)="onValueChange($event)"
                    [compareWith]="compareWith"
                    (open)="showOverlay = true"
                    (close)="showOverlay = false"
                >
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                    >
                        <div *ngFor="let key of Object.keys(item)">
                            <div class="key-val">
                                <span>{{ key }}</span> =
                                <span>{{ item[key] + " ;" }}</span> &nbsp;
                                &nbsp;
                            </div>
                        </div>
                    </ng-template>
                    <ng-container>
                        <ng-template
                            ng-multi-label-tmp
                            let-items="selectedValues"
                            let-clear="clear"
                        >
                            <div
                                class="ng-value list-object-label"
                                *ngIf="
                                    field.groupByKey
                                        ? formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value
                                        : formGroup.get(field.name).value
                                "
                            >
                                <span class="ng-value-label"
                                    >{{ "1 selected" }}
                                </span>
                            </div>
                        </ng-template>
                    </ng-container>
                </ng-select>
                <!-- Handling errors -->
                <ng-container *ngIf="field.groupByKey">
                    <ng-template *ngTemplateOutlet="groupError"></ng-template>
                </ng-container>
                <ng-container *ngIf="!field.groupByKey">
                    <ng-template *ngTemplateOutlet="error"></ng-template>
                </ng-container>
            </div>
        </div>
        <!-- field type four end -->
    </div>
</ng-container>

<ng-template #groupError>
    <ng-container
        *ngIf="
            formGroup.get(field.groupByKey).get(field.name).touched &&
            formGroup.get(field.groupByKey).get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation
                        : formGroup.get(field.groupByKey).get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
<ng-template #error>
    <ng-container
        *ngIf="
            formGroup.get(field.name).touched &&
            formGroup.get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation: formGroup.get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>

<div class="dropdown-background-layer" *ngIf="showOverlay"></div>
