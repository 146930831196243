import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { IconType } from './../../../../enums/IconType';
import { IIcon } from './../../../../interfaces/icon-data/IIcon';
import { IColumnData } from './../../../../interfaces/table-generator/IColumnData';
import { ITableGeneratorInput } from './../../../../interfaces/table-generator/ITableGeneratorInput';
// import * as $ from

@Component({
    selector: 'app-azure-ri-recommendation-modal',
    templateUrl: './azure-ri-recommendation-modal.component.html',
    styleUrls: ['./azure-ri-recommendation-modal.component.sass']
})
export class AzureRiRecommendationModalComponent implements OnInit {
    objectKeys = Object.keys;
    data: any[];
    keys: any;
    tabs: any;
    selectedTab: string;
    gridRef: GridOptions;
    tabData: Map<string, object[]> = new Map<string, object[]>();
    flag = 0;
    test: string[];
    descriptionTableData: object[];
    descriptionTableGenInput: ITableGeneratorInput;
    recommendationTableData: object[];
    recommendationTableGenInput: ITableGeneratorInput;
    defaultColDef: any = {
        sortable: false,
        filter: false,
        resizable: false
    };

    arrowUpIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-up'
    };

    arrowDownIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-down'
    };
    constructor(
        public modalData: ModalInjectedData,
        private notificationsService: NotificationsService,
        public modalService: ModalService
    ) {}

    ngOnInit() {
        this.data = this.modalData.data.item;
        this.keys = this.objectKeys(this.data);
        this.tabs =
            this.data && this.data['amortizedCost']
                ? Object.keys(this.data['amortizedCost'])
                : [];

        if (Object.keys(this.data).includes('savingTable')) {
            const tableData = [];
            this.selectedTab = 'savingTable';

            const savingsData = this.data['savingTable'];
            Object.keys(savingsData).forEach((selectedKey) => {
                const data = {};
                const selectedTabData = savingsData[selectedKey];
                Object.keys(selectedTabData).forEach((dataKey) => {
                    if (dataKey.includes('1')) {
                        data['1yr'] = selectedTabData[dataKey];
                    } else if (dataKey.includes('3')) {
                        data['3yr'] = selectedTabData[dataKey];
                    }
                });
                data['costType'] = selectedKey;
                tableData.push(data);
            });
            this.tabData.set(this.selectedTab, tableData);
        } else {
            this.selectedTab = this.tabs[0];
        }

        this.initDescriptionTableGen();
        this.initRecommendationTable();
    }

    displayCondition(key1) {
        for (const element in this.data['savingTable']['Savings (%)']) {
            if (element.includes(key1)) {
                return true;
            }
        }
    }

    isObject(val) {
        return typeof val === 'object';
    }

    changeTab(tab) {
        this.selectedTab = tab;
        this.gridRef.api.setRowData(this.tabData.get(tab));
    }

    initDescriptionTableGen() {
        const columns: IColumnData[] = [
            {
                columnKey: 'key',
                columnName: 'key',
                pinned: 'left',
                cellClass: 'pinned-key'
            },
            {
                columnKey: 'value',
                columnName: 'value',
                cellClass: 'value-column'
            }
        ];
        const data = this.data['generalInformation'];
        const tableData: object[] = [];
        Object.keys(this.data['generalInformation']).forEach((colKey) => {
            tableData.push({ key: colKey, value: data[colKey] });
        });
        this.descriptionTableData = tableData;
        this.descriptionTableGenInput = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            tableAutoHeight: true,
            columns,
            headerHeight: 0
        };
    }

    initRecommendationTable() {
        const columns: IColumnData[] = [
            {
                columnKey: 'costType',
                columnName: '',
                pinned: 'left',
                cellClass: 'pinned-key'
            },
            {
                columnKey: '1yr',
                columnName: '1 year',
                cellClass: 'value-column'
            },
            {
                columnKey: '3yr',
                columnName: '3 year',
                cellClass: 'value-column'
            }
        ];
        const amortizedCost = this.data['amortizedCost'];
        this.tabs.forEach((tab) => {
            const tableData = [];
            const selectedTab = amortizedCost[tab];
            Object.keys(selectedTab).forEach((selectedKey) => {
                const data = {};
                const selectedTabData = selectedTab[selectedKey];
                Object.keys(selectedTabData).forEach((dataKey) => {
                    if (dataKey.includes('1')) {
                        data['1yr'] = selectedTabData[dataKey];
                    } else if (dataKey.includes('3')) {
                        data['3yr'] = selectedTabData[dataKey];
                    }
                });
                data['costType'] = selectedKey;
                tableData.push(data);
            });
            this.tabData.set(tab, tableData);
        });

        this.recommendationTableGenInput = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            tableAutoHeight: true,
            columns
        };
        this.recommendationTableData = this.tabData.get(this.selectedTab);
    }
}
