import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { UpdateAction } from 'src/app/shared/enums/UpdateAction';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IUpdateAction } from 'src/app/shared/interfaces/update-action/IUpdateAction';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { CustomValidators } from './../../../../classes/CustomValidators';
import { Helper } from './../../../../classes/Helper';
import { FilterType } from './../../../../enums/FilterType';
import { FormState } from './../../../../enums/FormState';
import { IconType } from './../../../../enums/IconType';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';

@Component({
    selector: 'app-create-customer-step-one',
    templateUrl: './create-customer-step-one.component.html',
    styleUrls: ['./create-customer-step-one.component.sass']
})
export class CreateCustomerStepOneComponent implements OnInit {
    customerInfoFirstFormGenInput: IFormGeneratorInput = null;
    customerInfoFirstFormGroup: FormGroup;
    retentionPeriodFormGenInput: IFormGeneratorInput = null;
    retentionPeriodFormGroup: FormGroup;
    viewFormGenInput: IFormGeneratorInput = null;
    viewFormGroup: FormGroup;
    stepData: any;
    regions = null;
    edit: boolean = false;
    updateControlInput: IUpdateAction;
    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    aclId;

    nextButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Next',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        function: null
    };

    constructor(
        private modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        private notificationService: NotificationsService,
        private listHttpService: ListHttpService,
        private modalService: ModalService,
        private filtersService: FiltersService,
        private ref: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        if (
            this.modalInputData &&
            this.modalInputData.data &&
            this.modalInputData.data.edit
        ) {
            this.edit = true;
            this.stepData = this.modalInputData.data.customerData;
        }

        if (
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(1)
        ) {
            this.stepData = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1);
        }
        this.settingUpForms();
    }
    settingUpForms() {
        this.customerInfoFirstFormGenInput = {
            formName: 'Create Customer',
            state: FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    label: 'Domain Id',
                    placeholder: 'Domain Id',
                    name: 'domainId',
                    value: this.stepData ? this.stepData.domainId : '',
                    fieldType: FilterType.TEXT,
                    required: true,
                    disabled: this.edit,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'keyboard'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Domain id is required'
                        }
                    ]
                },
                {
                    label: 'Company Name',
                    placeholder: 'Company Name',
                    name: 'companyName',
                    value: this.stepData ? this.stepData.companyName : '',
                    fieldType: FilterType.TEXT,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'corporate_fare'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Company name is required'
                        }
                    ]
                },
                {
                    label: 'Select Region',
                    placeholder: 'Select Region',
                    name: 'dataSourceId',
                    value: this.stepData
                        ? this.stepData.region
                            ? this.stepData.region
                            : this.stepData.dataSourceId
                            ? this.stepData.dataSourceId
                            : ''
                        : '',
                    apiInfo: this.listHttpService.cacRegionsApiInfo,
                    showKey: 'label',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    disabled: this.edit,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Region is required'
                        }
                    ]
                },
                {
                    label: 'Select Price Type',
                    placeholder: 'Select Price Type',
                    name: 'priceType',
                    value: this.stepData ? this.stepData.priceType : [],
                    apiInfo: this.listHttpService.priceTypeApiInfo,
                    showKey: 'label',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Price type is required'
                        }
                    ]
                },
                {
                    label: 'Select Acl',
                    placeholder: 'Select Acl',
                    name: 'aclId',
                    value: this.stepData
                        ? this.stepData.defaultAclId
                            ? this.stepData.defaultAclId
                            : this.stepData.aclId
                            ? this.stepData.aclId
                            : ''
                        : '',
                    apiInfo: this.listHttpService.listAclApiInfo,
                    showKey: 'name',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Acl is required'
                        }
                    ]
                }
            ]
        };

        this.retentionPeriodFormGenInput = {
            formName: 'Create Customer',
            state: FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    label: 'Retention Period',
                    placeholder: 'Retention Period',
                    name: 'dataRetentionMonths',
                    value: this.stepData
                        ? this.stepData.dataRetentionMonths
                        : '',
                    fieldType: FilterType.NUMBER,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'access_time'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Retention period is required'
                        }
                    ]
                }
            ]
        };
    }

    addViewField(value) {
        if (value['aclId'] !== this.aclId) {
            this.aclId = value['aclId'];
            this.viewFormGenInput = {
                formName: 'Create Customer',
                state: FormState.EDIT,
                submitButton: null,
                fields: [
                    {
                        label: 'Select View',
                        placeholder:
                            'Select view that you want to replicate to the customer',
                        name: 'selectViews',
                        getKey: null,
                        value: this.stepData
                            ? this.stepData.viewIds
                                ? this.stepData.viewIds
                                : this.stepData.selectViews
                                ? this.stepData.selectViews.id
                                : null
                            : null,
                        apiInfo:
                            this.listHttpService.listAclDependentViewsApiInfo(
                                value['aclId']
                            ),
                        showKey: 'name',
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        required: true,
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Views are required'
                            }
                        ]
                    }
                ]
            };

            setTimeout(() => {
                this.updateControlInput = {
                    action: UpdateAction.REFRESH,
                    controls: ['selectViews']
                };
                if (this.viewFormGroup) {
                    this.updateControl.next(this.updateControlInput);
                    this.viewFormGroup.get('selectViews').reset();
                }
            }, 0);
        }
    }
    save() {
        if (!this.checkFormValidity()) {
            return;
        }

        this.multiStepFormService.nextStep(this.modalInputData.modalId);
        const viewFormGroupValue = this.viewFormGroup
            ? this.viewFormGroup.value
            : {};
        this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .set(1, {
                ...this.customerInfoFirstFormGroup.getRawValue(),
                ...viewFormGroupValue,
                ...this.retentionPeriodFormGroup.value
            });
    }

    checkFormValidity() {
        Helper.markAllFieldAsTouched(this.customerInfoFirstFormGroup);
        Helper.markAllFieldAsTouched(this.retentionPeriodFormGroup);
        if (this.viewFormGroup) {
            Helper.markAllFieldAsTouched(this.viewFormGroup);
        }
        let valid = true;
        if (this.customerInfoFirstFormGroup.invalid) {
            this.customerInfoFirstFormGroup.updateValueAndValidity();
            valid = false;
        }
        if (!this.edit && this.viewFormGroup.invalid) {
            this.viewFormGroup.updateValueAndValidity();
            valid = false;
        }
        if (this.retentionPeriodFormGroup.invalid) {
            this.retentionPeriodFormGroup.updateValueAndValidity();
            valid = false;
        }
        return valid;
    }
}
