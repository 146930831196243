import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class EqualityPasswordValidator extends CustomValidator {
    key = CustomValidatorKey.EQUALITY_PASSWORD_VALIDATOR;
    constructor(private siblingPasswordControlName: string) {
        super();
    }

    validatorFunction(control: AbstractControl): ValidationErrors {
        if (this.formGenRef && this.formGenRef.formGroup) {
            const siblingPasswordControl = this.formGenRef.formGroup?.get(
                this.siblingPasswordControlName
            );
            if (
                !siblingPasswordControl.value?.length &&
                siblingPasswordControl.pristine
            ) {
                return null;
            }
            if (control.value === siblingPasswordControl.value) {
                if (siblingPasswordControl.hasError(this.key)) {
                    siblingPasswordControl.setErrors(null, {
                        emitEvent: true
                    });
                }
                return null;
            }
        }
        const obj = {};
        obj[this.key] = true;
        return obj;
    }
}
