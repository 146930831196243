import {
	Component,
	OnInit
} from '@angular/core';
import { ColDef, GridOptions, RowEvent, RowNode } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { IApiInfo } from 'src/app/shared/interfaces/api/IApiInfo';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { IAttributeInfo } from 'src/app/shared/interfaces/views/IAttributeInfo';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { Node } from 'src/app/shared/classes/data-structure/tree/Node';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';

@Component({
	selector: 'app-group-widget-modal',
	templateUrl: './group-widget-modal.component.html',
	styleUrls: ['./group-widget-modal.component.sass'],
})
export class GroupWidgetModalComponent implements OnInit {
	tableGenInput: ITableGeneratorInput = null;
	aclApiInfo: IApiInfo = null;
	widgetPermissionData: Map<string, string> = null;
	gridRef: GridOptions = null;
	node: Node<IAttributeInfo> = null;

	cancelButtonGenInput: IButtonGeneratorInput = {
		buttonName: 'Cancel',
		buttonColorType: ButtonColorType.PRIMARY,
		buttonType: ButtonType.FLAT,
		function: null,
	};

	insertButtonGenInput: IButtonGeneratorInput = {
		buttonName: 'Group',
		buttonColorType: ButtonColorType.PRIMARY,
		buttonType: ButtonType.FLAT,
		function: null,
	};

	permissionFormGenInput: IFormGeneratorInput = {
		state: FormState.CREATE,
		submitButton: null,
		formName: 'Permission',
		fields: [
			{
				name: 'permission',
				fieldType: FilterType.DROPDOWN_SINGLE,
				label: 'Permission',
				placeholder: 'Set permission for all widgets',
				required: false,
				listData: [
					{
						id: 'READ',
						label: 'Read',
					},
					{
						id: 'READ_WRITE',
						label: 'Read / Write',
					},
					{
						id: 'DENY',
						label: 'Deny',
					},
				],
			},
		],
	};

	isLoading: boolean = true;
	spinnerLoader: IIcon = {
		type: IconType.SPINNERLOADER,
	};
	defaultColDef: ColDef = {
		sortable: true,
		resizable: true,
	};

	preSelectedGroupedWidgets: Map<string, string> = null;

	readonly GROUP_ID_KEY = 'groupId';
	readonly GROUP_WIDGETS_KEY = 'groupWidgets';

	uniqueCpspsPairList: {
		cloud?: string;
		product?: string;
		subProduct?: string;
		service?: string;
	}[] = [];

	cloudFilterInfo: IFilterInfo;
	cloudSelected: any[] = [];
	cloudSet = new Set<string>();
	productFilterInfo: IFilterInfo;
	productSelected: any[] = [];
	productSet = new Set<string>();
	subProductFilterInfo: IFilterInfo;
	subProductSelected: any[] = [];
	subProductSet = new Set<string>();
	serviceFilterInfo: IFilterInfo;
	serviceSelected: any[] = [];
	serviceSet = new Set<string>();

	permissionFilterInfo: IFilterInfo;
	permissionSelected: any = null;

	readonly ID_KEY = 'id';
	readonly NAME_KEY = 'name';
	readonly CLOUD_KEY = 'cloud';
	readonly PRODUCT_KEY = 'product';
	readonly SUB_PRODUCT_KEY = 'subProduct';
	readonly SERVICE_KEY = 'service';
	widgetRef: any;
	widgets: any;
	selectedWidgets: any;

	constructor(
		public modalInputData: ModalInjectedData,
		public modalService: ModalService,
		private notificationsService: NotificationsService,
	) {
		this.initFilterInfos();
	}

	ngOnInit(): void {
		this.widgetPermissionData = new Map();
		this.node = this.modalInputData?.data['node'];
		this.widgetRef = this.modalInputData?.data['widgetRef'];
		const groupablePortlets = this.modalInputData?.data['groupable'];
		this.widgets = this.modalInputData?.data['stepData'].filter((widget) => {
			return (
				!groupablePortlets.has(widget.portletType) &&
				!widget[this.GROUP_ID_KEY]
			);
		});

		this.preSelectedGroupedWidgets = new Map<string, string>();
		if (this.widgets) {
			// Only showing full group
			this.tableGenInput = {
				afterResponse: (response: any) => {
					if (response) {
						this.isLoading = false;
					}
					this.prepareFilters(response);
				},
				listExtraction: {
					type: 'DIRECT',
				},
				isExternalFilterPresent: this.isExternalFilterAvailable.bind(this),
				doesExternalFilterPass: this.externalFilterFunction.bind(this),
				columns: [
					{
						columnKey: this.NAME_KEY,
						columnName: 'Widgets',
						checkboxSelection: true,
						minWidth: 450,
						headerCheckboxSelection: true,
					},
					{
						columnKey: 'READ_ONLY',
						columnName: 'Read',
						cellClass: 'cell-center',
						headerClass: 'header-center',
						minWidth: 80,
						maxWidth: 80,
						cellRenderer: (rowData: RowEvent) => {
							return this.getRadioRendered(rowData, Permissions.READ);
						},
					},
					{
						columnKey: 'READ_WRITE',
						columnName: 'Read / Write',
						cellClass: 'cell-center',
						headerClass: 'header-center',
						minWidth: 190,
						maxWidth: 190,
						cellRenderer: (rowData: RowEvent) => {
							return this.getRadioRendered(
								rowData,
								Permissions.READ_WRITE,
							);
						},
					},
					{
						columnKey: 'DENY',
						columnName: 'Deny',
						cellClass: 'cell-center',
						headerClass: 'header-center',
						minWidth: 70,
						maxWidth: 70,
						cellRenderer: (rowData: RowEvent) => {
							return this.getRadioRendered(rowData, Permissions.DENY);
						},
					},
				],
				selectionLimit: GlobalConfiguration.WIDGETS_PER_GROUP_LIMIT,
				selectionLimitBreachMessage: `You can group maximum of ${GlobalConfiguration.WIDGETS_PER_GROUP_LIMIT} widgets`,
			};
		}
	}

 /**
  * Creates a radio button element with the specified properties.
  * @param {string} inputName - The name attribute of the radio button.
  * @param {string} inputId - The id attribute of the radio button.
  * @param {function} callback - The callback function to be executed when the radio button is clicked.
  * @param {boolean} [rowSelected=false] - Indicates whether the radio button is selected in a row.
  * @param {boolean} [disabled=false] - Indicates whether the radio button is disabled.
  * @param {boolean} [checked=false] - Indicates whether the radio button is checked.
  * @returns {HTMLElement} The created radio button element.
  */
	getRadioButton(
		inputName,
		inputId,
		callback,
		rowSelected = false,
		disabled = false,
		checked = false,
	) {
		const radioButtonContainer = document.createElement('div');
		const radioButtonSibling = document.createElement('div');
		const radioButton = document.createElement('input');
		radioButton.type = 'radio';
		radioButton.name = inputName;
		radioButton.value = inputId;
		radioButton.disabled = disabled;
		radioButton.checked = checked;
		radioButton.addEventListener('click', (event) => {
			callback(inputName, inputId);
		});
		radioButtonContainer.classList.add('radio-button-container');
		if (disabled) {
			radioButtonContainer.classList.add('disabled');
		}
		radioButtonSibling.classList.add('radio-button');
		radioButtonContainer.appendChild(radioButton);
		radioButtonContainer.appendChild(radioButtonSibling);
		radioButtonContainer.addEventListener('click', (event) => {
			if (rowSelected && !disabled) {
				radioButton.click();
			}
		});
		return radioButtonContainer;
	}

 /**
  * Renders a radio button element based on the provided row data and permission.
  * @param {RowEvent} rowData - The row data containing the data for the radio button.
  * @param {Permissions} permission - The permission to associate with the radio button.
  * @returns The rendered radio button element.
  */
	getRadioRendered(rowData: RowEvent, permission: Permissions) {
		const data = rowData.data;
		const checked =
			this.widgetPermissionData.size &&
			this.widgetPermissionData.has(data.id) &&
			this.widgetPermissionData.get(data.id) === permission;
		const rowSelected = rowData.node.isSelected();
		const disabled = !rowSelected;
		return this.getRadioButton(
			data[this.ID_KEY],
			permission,
			(inputName, inputValue) => {
				this.widgetPermissionData.set(inputName, inputValue);
			},
			rowSelected,
			disabled,
			checked,
		);
	}

 /**
  * Deletes the data of unselected rows from a table.
  * @returns None
  */
	deleteDataOfUnselectedRows() {
		if (this.gridRef && this.gridRef.api) {
			const selectedIds = this.gridRef.api
				.getSelectedRows()
				.map((row) => row.id);
			if (selectedIds.length === 0) {
				this.permissionSelected = null;
			}
			for (const [k, v] of this.widgetPermissionData) {
				if (!selectedIds.includes(k)) {
					this.widgetPermissionData.delete(k);
				}
			}
		}
	}

 /**
  * Retrieves the final list of widgets based on the current state of the grid.
  * @returns {Array} - The final list of selected widgets.
  */
	getFinalWidgetList() {
		if (this.gridRef && this.gridRef.api) {
			const groupMap = new Map<string, any>();
			const selectedWidgets = this.gridRef.api
				.getSelectedRows()
				.map((rowData) => {
					return {
						...rowData,
						permission: this.widgetPermissionData.get(rowData.id),
					};
				});

			if (
				this.preSelectedGroupedWidgets &&
				this.preSelectedGroupedWidgets.size
			) {
				for (let index = 0; index < selectedWidgets.length; index++) {
					const widget = selectedWidgets[index];
					const widgetId = widget.id;
					if (this.preSelectedGroupedWidgets.has(widgetId)) {
						const groupId = this.preSelectedGroupedWidgets.get(widgetId);
						if (groupMap.has(groupId)) {
							const group = groupMap.get(groupId);
							if (group && group[this.GROUP_WIDGETS_KEY]) {
								group[this.GROUP_WIDGETS_KEY].push(widget);
								selectedWidgets.splice(index--, 1);
							}
						} else {
							const group = {
								[this.GROUP_ID_KEY]: groupId,
								[this.GROUP_WIDGETS_KEY]: [widget],
								listIndex: index,
							};
							groupMap.set(groupId, group);
							selectedWidgets.splice(index, 1, group);
						}
					}
				}
				groupMap.forEach((group, groupId) => {
					if (
						group &&
						group[this.GROUP_WIDGETS_KEY] &&
						group[this.GROUP_WIDGETS_KEY].length
					) {
						if (group[this.GROUP_WIDGETS_KEY].length === 1) {
							const listIndex = group['listIndex'];
							selectedWidgets.splice(
								listIndex,
								1,
								group[this.GROUP_WIDGETS_KEY][0],
							);
						} else {
							delete group['listIndex'];
						}
					}
				});
			}
			return selectedWidgets;
		} else {
			return this.node.data.widgets;
		}
	}

 /**
  * Inserts widgets into a module.
  * @param {Module} module - The module to insert the widgets into.
  * @param {Widget[]} widgets - The widgets to insert.
  * @returns None
  */
	insertWidgetsToModule(buttonRef) {
		if (
			this.gridRef &&
			this.gridRef.api &&
			!this.gridRef.api.getSelectedRows().length
		) {
			this.notificationsService.showSnackBar(
				'No widgets selected to group',
				true,
			);
			return;
		}
		if (!this.checkWidgetPermission()) {
			this.notificationsService.showSnackBar(
				'Permission not assigned to all widgets. Provide permission and try again',
				true,
			);
			return;
		}
		this.selectedWidgets = this.getFinalWidgetList();
		this.modalService.modalSelectedData.next(this.selectedWidgets);
		this.modalService.closeModal(null, this.modalInputData.modalId);
	}

 /**
  * Assign permission to access the widget accordingly.
  * @returns {boolean} - true if the user has permission, false otherwise.
  */
	checkWidgetPermission(): boolean {
		if (this.gridRef && this.gridRef.api && this.widgetPermissionData.size) {
			const selectedRows = this.gridRef.api.getSelectedRows();
			let invalid = false;
			for (const row of selectedRows) {
				if (
					!this.widgetPermissionData.has(row.id) ||
					this.widgetPermissionData.get(row.id) === null
				) {
					invalid = true;
				}
			}
			if (invalid) {
				return false;
			}
			return true;
		}
		return false;
	}

	// Below Functions deals with filters
	setCloudFilterInfo(listData?: string[]) {
		this.cloudFilterInfo = {
			placeholder: 'Cloud',
			label: 'Cloud',
			listData: listData ? Helper.mapToDropdownData(listData) : [],
		};
	}

	setProductFilterInfo(listData?: string[]) {
		this.productFilterInfo = {
			placeholder: 'Product',
			label: 'Product',
			listData: listData ? Helper.mapToDropdownData(listData) : [],
		};
	}

	setSubProductFilterInfo(listData?: string[]) {
		this.subProductFilterInfo = {
			placeholder: 'Sub - Product',
			label: 'Sub - Product',
			listData: listData ? Helper.mapToDropdownData(listData) : [],
		};
	}

	setServiceFilterInfo(listData?: string[]) {
		this.serviceFilterInfo = {
			placeholder: 'Service',
			label: 'Service',
			listData: listData ? Helper.mapToDropdownData(listData) : [],
		};
	}

 /**
  * Initializes the filter information for various categories.
  * Sets the cloud filter info, product filter info, sub-product filter info,
  * service filter info, and permission filter info.
  * @returns None
  */
	initFilterInfos() {
		this.setCloudFilterInfo();
		this.setProductFilterInfo();
		this.setSubProductFilterInfo();
		this.setServiceFilterInfo();
		this.permissionFilterInfo = {
			placeholder: 'Permission',
			label: 'Permission',
			listData: [
				{
					id: Permissions.READ,
					label: 'Read',
				},
				{
					id: Permissions.READ_WRITE,
					label: 'Read/Write',
				},
				{
					id: Permissions.DENY,
					label: 'Deny',
				},
			],
		};
	}

 /**
  * Prepares the filters based on the provided widget list.
  * @param {any[]} widgetList - The list of widgets to prepare filters for.
  * @returns None
  */
	prepareFilters(widgetList: any[]) {
		if (widgetList && widgetList.length) {
			this.uniqueCpspsPairList = [];
			const keySet = new Set<string>();
			widgetList.forEach((masterAclWidget) => {
				let cloud = null;
				let product = null;
				let subProduct = null;
				let service = null;
				if (masterAclWidget[this.CLOUD_KEY]) {
					cloud = masterAclWidget[this.CLOUD_KEY];
					this.cloudSet.add(cloud);
				}
				if (masterAclWidget[this.PRODUCT_KEY]) {
					product = masterAclWidget[this.PRODUCT_KEY];
					this.productSet.add(product);
				}
				if (masterAclWidget[this.SUB_PRODUCT_KEY]) {
					subProduct = masterAclWidget[this.SUB_PRODUCT_KEY];
					this.subProductSet.add(subProduct);
				}
				if (masterAclWidget[this.SERVICE_KEY]) {
					service = masterAclWidget[this.SERVICE_KEY];
					this.serviceSet.add(service);
				}
				if (cloud || product || subProduct || service) {
					const uniquePairString = [
						cloud,
						product,
						subProduct,
						service,
					].join('|');
					if (!keySet.has(uniquePairString)) {
						keySet.add(uniquePairString);
						this.uniqueCpspsPairList.push({
							cloud: cloud,
							product: product,
							subProduct: subProduct,
							service: service,
						});
					}
				}
			});
			this.setCloudFilterInfo(Array.from(this.cloudSet.values()));
			this.setProductFilterInfo(Array.from(this.productSet.values()));
			this.setSubProductFilterInfo(Array.from(this.subProductSet.values()));
			this.setServiceFilterInfo(Array.from(this.serviceSet.values()));
		}
	}

 /**
  * Updates the list data for cloud storage.
  * @returns None
  */
	updateListDataForCloud(selectedClouds: string[]) {
		let localProductList = null;
		let localSubProductList = null;
		let localServiceList = null;
		const localProductSet = new Set<string>();
		const localSubProductSet = new Set<string>();
		const localServiceSet = new Set<string>();
		this.uniqueCpspsPairList.forEach((pair) => {
			if (selectedClouds && selectedClouds.length) {
				if (pair.cloud && selectedClouds.includes(pair.cloud)) {
					if (pair.product) {
						localProductSet.add(pair.product);
					}
					if (this.productSelected && this.productSelected.length) {
						if (
							pair.product &&
							(this.productSelected.includes(pair.product) ||
								localProductSet.has(pair.product))
						) {
							if (pair.subProduct) {
								localSubProductSet.add(pair.subProduct);
							}
							if (
								this.subProductSelected &&
								this.subProductSelected.length
							) {
								if (
									pair.subProduct &&
									(this.subProductSelected.includes(pair.subProduct) ||
										localSubProductSet.has(pair.subProduct))
								) {
									if (pair.service) {
										localServiceSet.add(pair.service);
									}
								}
							} else {
								if (pair.service) {
									localServiceSet.add(pair.service);
								}
							}
						}
					} else {
						if (pair.subProduct) {
							localSubProductSet.add(pair.subProduct);
						}
						if (
							this.subProductSelected &&
							this.subProductSelected.length
						) {
							if (
								pair.subProduct &&
								(this.subProductSelected.includes(pair.subProduct) ||
									localSubProductSet.has(pair.subProduct))
							) {
								if (pair.service) {
									localServiceSet.add(pair.service);
								}
							}
						} else {
							if (pair.service) {
								localServiceSet.add(pair.service);
							}
						}
					}
				}
			} else {
				if (pair.product) {
					localProductSet.add(pair.product);
				}
				if (this.productSelected && this.productSelected.length) {
					if (
						pair.product &&
						(this.productSelected.includes(pair.product) ||
							localProductSet.has(pair.product))
					) {
						if (pair.subProduct) {
							localSubProductSet.add(pair.subProduct);
						}
						if (
							this.subProductSelected &&
							this.subProductSelected.length
						) {
							if (
								pair.subProduct &&
								(this.subProductSelected.includes(pair.subProduct) ||
									localSubProductSet.has(pair.subProduct))
							) {
								if (pair.service) {
									localServiceSet.add(pair.service);
								}
							}
						} else {
							if (pair.service) {
								localServiceSet.add(pair.service);
							}
						}
					}
				} else {
					if (pair.subProduct) {
						localSubProductSet.add(pair.subProduct);
					}
					if (this.subProductSelected && this.subProductSelected.length) {
						if (
							pair.subProduct &&
							(this.subProductSelected.includes(pair.subProduct) ||
								localSubProductSet.has(pair.subProduct))
						) {
							if (pair.service) {
								localServiceSet.add(pair.service);
							}
						}
					} else {
						if (pair.service) {
							localServiceSet.add(pair.service);
						}
					}
				}
			}
		});
		localProductList = Array.from(localProductSet.values());
		localSubProductList = Array.from(localSubProductSet.values());
		localServiceList = Array.from(localServiceSet.values());
		this.updateProductSelectedValues(localProductList);
		this.setProductFilterInfo(localProductList);
		this.updateSubProductSelectedValues(localSubProductList);
		this.setSubProductFilterInfo(localSubProductList);
		this.updateServiceSelectedValues(localServiceList);
		this.setServiceFilterInfo(localServiceList);
	}

 /**
  * Updates the list data for the selected products.
  * @param {string[]} selectedProducts - An array of selected product names.
  * @returns None
  */
	updateListDataForProduct(selectedProducts: string[]) {
		let localSubProductList = null;
		let localServiceList = null;
		const localSubProductSet = new Set<string>();
		const localServiceSet = new Set<string>();
		this.uniqueCpspsPairList.forEach((pair) => {
			if (selectedProducts && selectedProducts.length) {
				if (pair.product && selectedProducts.includes(pair.product)) {
					if (this.cloudSelected && this.cloudSelected.length) {
						if (pair.cloud && this.cloudSelected.includes(pair.cloud)) {
							if (pair.subProduct) {
								localSubProductSet.add(pair.subProduct);
							}
							if (
								this.subProductSelected &&
								this.subProductSelected.length
							) {
								if (
									pair.subProduct &&
									(this.subProductSelected.includes(pair.subProduct) ||
										localSubProductSet.has(pair.subProduct))
								) {
									if (pair.service) {
										localServiceSet.add(pair.service);
									}
								}
							} else {
								if (pair.service) {
									localServiceSet.add(pair.service);
								}
							}
						}
					} else {
						if (pair.subProduct) {
							localSubProductSet.add(pair.subProduct);
						}
						if (
							this.subProductSelected &&
							this.subProductSelected.length
						) {
							if (
								pair.subProduct &&
								(this.subProductSelected.includes(pair.subProduct) ||
									localSubProductSet.has(pair.subProduct))
							) {
								if (pair.service) {
									localServiceSet.add(pair.service);
								}
							}
						} else {
							if (pair.service) {
								localServiceSet.add(pair.service);
							}
						}
					}
				}
			} else {
				if (this.cloudSelected && this.cloudSelected.length) {
					if (pair.cloud && this.cloudSelected.includes(pair.cloud)) {
						if (pair.subProduct) {
							localSubProductSet.add(pair.subProduct);
						}
						if (
							this.subProductSelected &&
							this.subProductSelected.length
						) {
							if (
								pair.subProduct &&
								(this.subProductSelected.includes(pair.subProduct) ||
									localSubProductSet.has(pair.subProduct))
							) {
								if (pair.service) {
									localServiceSet.add(pair.service);
								}
							}
						} else {
							if (pair.service) {
								localServiceSet.add(pair.service);
							}
						}
					}
				} else {
					if (pair.subProduct) {
						localSubProductSet.add(pair.subProduct);
					}
					if (this.subProductSelected && this.subProductSelected.length) {
						if (
							pair.subProduct &&
							(this.subProductSelected.includes(pair.subProduct) ||
								localSubProductSet.has(pair.subProduct))
						) {
							if (pair.service) {
								localServiceSet.add(pair.service);
							}
						}
					} else {
						if (pair.service) {
							localServiceSet.add(pair.service);
						}
					}
				}
			}
		});
		localSubProductList = Array.from(localSubProductSet.values());
		localServiceList = Array.from(localServiceSet.values());
		this.updateSubProductSelectedValues(localSubProductList);
		this.setSubProductFilterInfo(localSubProductList);
		this.updateServiceSelectedValues(localServiceList);
		this.setServiceFilterInfo(localServiceList);
	}

 /**
  * Updates the list data for the selected sub-products.
  * @param {string[]} selectedSubProducts - An array of selected sub-products.
  * @returns None
  */
	updateListDataForSubProduct(selectedSubProducts: string[]) {
		let localServiceList = null;
		const localServiceSet = new Set<string>();
		this.uniqueCpspsPairList.forEach((pair) => {
			if (selectedSubProducts && selectedSubProducts.length) {
				if (
					pair.subProduct &&
					selectedSubProducts.includes(pair.subProduct)
				) {
					if (this.cloudSelected && this.cloudSelected.length) {
						if (pair.cloud && this.cloudSelected.includes(pair.cloud)) {
							if (this.productSelected && this.productSelected.length) {
								if (
									pair.product &&
									this.productSelected.includes(pair.product)
								) {
									if (pair.service) {
										localServiceSet.add(pair.service);
									}
								}
							} else {
								if (pair.service) {
									localServiceSet.add(pair.service);
								}
							}
						}
					} else {
						if (this.productSelected && this.productSelected.length) {
							if (
								pair.product &&
								this.productSelected.includes(pair.product)
							) {
								if (pair.service) {
									localServiceSet.add(pair.service);
								}
							}
						} else {
							if (pair.service) {
								localServiceSet.add(pair.service);
							}
						}
					}
				}
			} else {
				if (this.cloudSelected && this.cloudSelected.length) {
					if (pair.cloud && this.cloudSelected.includes(pair.cloud)) {
						if (this.productSelected && this.productSelected.length) {
							if (
								pair.product &&
								this.productSelected.includes(pair.product)
							) {
								if (pair.service) {
									localServiceSet.add(pair.service);
								}
							}
						} else {
							if (pair.service) {
								localServiceSet.add(pair.service);
							}
						}
					}
				} else {
					if (this.productSelected && this.productSelected.length) {
						if (
							pair.product &&
							this.productSelected.includes(pair.product)
						) {
							if (pair.service) {
								localServiceSet.add(pair.service);
							}
						}
					} else {
						if (pair.service) {
							localServiceSet.add(pair.service);
						}
					}
				}
			}
		});
		localServiceList = Array.from(localServiceSet.values());
		this.updateServiceSelectedValues(localServiceList);
		this.setServiceFilterInfo(localServiceList);
	}

 /**
  * Updates the selected values of the product based on the given checklist.
  * @param {Array} checkList - The checklist of selected products.
  * @returns None
  */
	updateProductSelectedValues(checkList) {
		const selected = this.productSelected.filter((productSel) => {
			return checkList.includes(productSel);
		});
		if (Helper.areDistinctLists(selected, this.productSelected)) {
			this.productSelected = selected;
		}
	}

 /**
  * Updates the selected values of the sub-products based on the provided checklist.
  * @param {Array} checkList - The checklist of sub-products to update.
  * @returns None
  */
	updateSubProductSelectedValues(checkList) {
		const selected = this.subProductSelected.filter((subProductSel) => {
			return checkList.includes(subProductSel);
		});
		if (Helper.areDistinctLists(selected, this.subProductSelected)) {
			this.subProductSelected = selected;
		}
	}

 /**
  * Updates the selected values in the serviceSelected array based on the provided checkList.
  * @param {Array} checkList - The list of values to check against the serviceSelected array.
  * @returns None
  */
	updateServiceSelectedValues(checkList) {
		const selected = this.serviceSelected.filter((serviceSel) => {
			return checkList.includes(serviceSel);
		});
		if (Helper.areDistinctLists(selected, this.serviceSelected)) {
			this.serviceSelected = selected;
		}
	}

 /**
  * Updates the filter information list data based on the selected values and type.
  * @param {Array} selectedValues - The selected values for the filter.
  * @param {string} type - The type of filter (CLOUD_KEY, PRODUCT_KEY, SERVICE_KEY).
  * @returns None
  */
	updateFilterInfoListData(selectedValues, type) {
		if (this.gridRef) {
			if (type === this.CLOUD_KEY) {
				if (Helper.areDistinctLists(this.cloudSelected, selectedValues)) {
					this.updateListDataForCloud(selectedValues);
					this.cloudSelected = selectedValues;
					this.gridRef.api.onFilterChanged();
				}
			}
			if (type === this.PRODUCT_KEY) {
				if (Helper.areDistinctLists(this.productSelected, selectedValues)) {
					this.updateListDataForProduct(selectedValues);
					this.productSelected = selectedValues;
					this.gridRef.api.onFilterChanged();
				}
			}
			if (type === this.SUB_PRODUCT_KEY) {
				if (
					Helper.areDistinctLists(this.subProductSelected, selectedValues)
				) {
					this.updateListDataForSubProduct(selectedValues);
					this.subProductSelected = selectedValues;
					this.gridRef.api.onFilterChanged();
				}
			}
			if (type === this.SERVICE_KEY) {
				if (Helper.areDistinctLists(this.serviceSelected, selectedValues)) {
					this.serviceSelected = selectedValues;
					this.gridRef.api.onFilterChanged();
				}
			}
		}
	}

 /**
  * Checks if an external filter is available.
  * @returns {boolean} - True if an external filter is available, false otherwise.
  */
	isExternalFilterAvailable() {
		if (
			(this.cloudSelected && this.cloudSelected.length) ||
			(this.productSelected && this.productSelected.length) ||
			(this.subProductSelected && this.subProductSelected.length) ||
			(this.serviceSelected && this.serviceSelected.length)
		) {
			return true;
		}
		return false;
	}

 /**
  * Determines whether a given row node should be filtered based on the selected cloud and service options.
  * @param {RowNode} node - The row node to be filtered.
  * @returns {boolean} - True if the row node should be included in the filter, false otherwise.
  */
	externalFilterFunction(node: RowNode) {
		const nodeData = node.data;
		let result = true;
		if (this.cloudSelected && this.cloudSelected.length) {
			if (this.cloudSelected.includes(nodeData[this.CLOUD_KEY])) {
				result = result && true;
			} else {
				result = result && false;
				return result;
			}
		}
		if (this.productSelected && this.productSelected.length) {
			if (this.productSelected.includes(nodeData[this.PRODUCT_KEY])) {
				result = result && true;
			} else {
				result = result && false;
				return result;
			}
		}
		if (this.subProductSelected && this.subProductSelected.length) {
			if (this.subProductSelected.includes(nodeData[this.SUB_PRODUCT_KEY])) {
				result = result && true;
			} else {
				result = result && false;
				return result;
			}
		}
		if (this.serviceSelected && this.serviceSelected.length) {
			if (this.serviceSelected.includes(nodeData[this.SERVICE_KEY])) {
				result = result && true;
			} else {
				result = result && false;
			}
		}
		return result;
	}

 /**
  * Sets the gridRef property to the provided event value.
  * @param {Event} event - The event object containing the grid reference.
  * @returns None
  */
	gridRefEvent(event) {
		this.gridRef = event;
	}

 /**
  * Sets the initial selection for the grid.
  * @returns None
  */
	gridSetInitialSelection() {
		if (
			this.gridRef &&
			this.gridRef.api &&
			this.node &&
			this.node.data.widgets &&
			this.node.data.widgets.length &&
			this.widgetPermissionData &&
			this.widgetPermissionData.size
		) {
			const total = this.widgetPermissionData.size;
			let count = 0;
			const nodes: RowNode[] = (this.gridRef.api as any)['rowModel'][
				'rowsToDisplay'
			];
			let index = 0;
			for (const node of nodes) {
				if (
					count < total - 1 &&
					this.widgetPermissionData.has(node.data.id)
				) {
					node.setSelected(true, false, true);
					++count;
				} else if (
					count === total - 1 &&
					this.widgetPermissionData.has(node.data.id)
				) {
					node.setSelected(true);
					++count;
				}
				++index;
			}
			this.gridRef.api.redrawRows();
		}
	}

 /**
  * Handles the event when the selection is changed.
  * Deletes data of unselected rows and redraws the grid rows.
  * @param {any} value - The new value of the selection.
  * @returns None
  */
	selectionChanged(value) {
		this.deleteDataOfUnselectedRows();
		this.gridRef.api.redrawRows();
	}

 /**
  * Event handler for when the quick filter is changed.
  * @param {string} id - The ID of the input element that triggered the event.
  * @returns None
  */
	onQuickFilterChanged(id: string) {
		if (this.gridRef) {
			this.gridRef.api.setQuickFilter(document.getElementById(id)['value']);
		}
	}
}

enum Permissions {
	READ = 'READ',
	READ_WRITE = 'READ_WRITE',
	DENY = 'DENY',
}
