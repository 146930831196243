import { Pipe, PipeTransform } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';

@Pipe({
    name: 'objectDataExtractor'
})
export class ObjectDataExtractorPipe implements PipeTransform {
    transform(key: string, data): any {
        return Helper.extractDataFromObject(key, data);
    }
}
