<div class="container">
    <ng-container *ngIf="complianceInfo; else loader">
        <ng-container *ngIf="complianceInfo.length > 0; else noData">
            <mat-accordion [multi]="true">
                <mat-expansion-panel
                    *ngFor="let data of complianceInfo"
                    [expanded]="true"
                >
                    <mat-expansion-panel-header
                        collapsedHeight="42px"
                        expandedHeight="42px"
                    >
                        <mat-panel-title>
                            <div class="each-container">
                                <div style="margin-bottom: 0; font-weight: 600">
                                    {{ data["complianceName"] }}
                                </div>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="each-container" style="margin-top: 20px">
                        <table>
                            <tr>
                                <td>Custom Compliance Description</td>
                                <td>
                                    <span>
                                        {{
                                            data["complianceDescription"]
                                                ? data["complianceDescription"]
                                                : "-"
                                        }}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>Custom Compliance Color</td>
                                <td>
                                    <div
                                        class="color-box"
                                        [style.backgroundColor]="
                                            data['colorMapping']
                                        "
                                    ></div>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    [class.top-align]="
                                        data['customizeLogic'] &&
                                        getKeys(data['customizeLogic']).length
                                    "
                                >
                                    Custom Compliance Logic
                                </td>
                                <td
                                    [class.no-underline]="
                                        data['customizeLogic'] &&
                                        getKeys(data['customizeLogic']).length
                                    "
                                >
                                    <span
                                        *ngIf="
                                            data['customizeLogic'] &&
                                                getKeys(data['customizeLogic'])
                                                    .length;
                                            else forAllCustomComplianceLogic
                                        "
                                    >
                                        <table>
                                            <tr
                                                *ngFor="
                                                    let key of getKeys(
                                                        data['customizeLogic']
                                                    )
                                                "
                                            >
                                                <td
                                                    [title]="nameLabelPair[key]"
                                                >
                                                    {{ nameLabelPair[key] }}
                                                </td>
                                                <td
                                                    [title]="
                                                        data['customizeLogic'][
                                                            key
                                                        ]
                                                    "
                                                >
                                                    {{
                                                        data["customizeLogic"][
                                                            key
                                                        ]
                                                    }}
                                                </td>
                                            </tr>
                                        </table>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    [class.top-align]="
                                        data['globalWhitelisting'][
                                            'accountIds'
                                        ] &&
                                        data['globalWhitelisting']['accountIds']
                                            .length
                                    "
                                >
                                    Account Ids
                                </td>
                                <td
                                    [class.no-underline]="
                                        data['globalWhitelisting'][
                                            'accountIds'
                                        ] &&
                                        data['globalWhitelisting']['accountIds']
                                            .length
                                    "
                                >
                                    <span
                                        *ngIf="
                                            data['globalWhitelisting'][
                                                'accountIds'
                                            ] &&
                                                data['globalWhitelisting'][
                                                    'accountIds'
                                                ].length;
                                            else forAllAccounts
                                        "
                                    >
                                        <table>
                                            <tr
                                                *ngFor="
                                                    let data of data[
                                                        'globalWhitelisting'
                                                    ]['accountIds']
                                                "
                                            >
                                                <td [title]="data">
                                                    {{ data }}
                                                </td>
                                            </tr>
                                        </table>
                                    </span>
                                </td>
                            </tr>

                            <tr>
                                <td
                                    [class.top-align]="
                                        data['globalWhitelisting']['regions'] &&
                                        data['globalWhitelisting']['regions']
                                            .length
                                    "
                                >
                                    Region
                                </td>
                                <td
                                    [class.no-underline]="
                                        data['globalWhitelisting']['regions'] &&
                                        data['globalWhitelisting']['regions']
                                            .length
                                    "
                                >
                                    <span
                                        *ngIf="
                                            data['globalWhitelisting'][
                                                'regions'
                                            ] &&
                                                data['globalWhitelisting'][
                                                    'regions'
                                                ].length;
                                            else forAllRegions
                                        "
                                    >
                                        <table>
                                            <tr
                                                *ngFor="
                                                    let data of data[
                                                        'globalWhitelisting'
                                                    ]['regions']
                                                "
                                            >
                                                <td [title]="data">
                                                    {{ data }}
                                                </td>
                                            </tr>
                                        </table>
                                    </span>
                                </td>
                            </tr>

                            <tr>
                                <td
                                    [class.top-align]="
                                        data['globalWhitelisting'][
                                            'resourceTags'
                                        ] &&
                                        data['globalWhitelisting'][
                                            'resourceTags'
                                        ].length
                                    "
                                >
                                    Resource Tags
                                </td>
                                <td
                                    [class.no-underline]="
                                        data['globalWhitelisting'][
                                            'resourceTags'
                                        ] &&
                                        data['globalWhitelisting'][
                                            'resourceTags'
                                        ].length
                                    "
                                >
                                    <span
                                        *ngIf="
                                            data['globalWhitelisting'][
                                                'resourceTags'
                                            ] &&
                                                data['globalWhitelisting'][
                                                    'resourceTags'
                                                ].length;
                                            else forAllTags
                                        "
                                    >
                                        <table>
                                            <tr>
                                                <th>Key</th>
                                                <th>Value</th>
                                            </tr>

                                            <tr
                                                *ngFor="
                                                    let data of data[
                                                        'globalWhitelisting'
                                                    ]['resourceTags']
                                                "
                                            >
                                                <td
                                                    [title]="
                                                        data.split('|')[0]
                                                            ? data.split('|')[0]
                                                            : ''
                                                    "
                                                >
                                                    {{
                                                        data.split("|")[0]
                                                            ? data.split("|")[0]
                                                            : ""
                                                    }}
                                                </td>
                                                <td
                                                    [title]="
                                                        data.split('|')[1]
                                                            ? data.split('|')[1]
                                                            : ''
                                                    "
                                                >
                                                    {{
                                                        data.split("|")[1]
                                                            ? data.split("|")[1]
                                                            : ""
                                                    }}
                                                </td>
                                            </tr>
                                        </table>
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-container></ng-container
    >
</div>

<ng-template #loader>
    <div class="overlay-content">
        <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
    </div>
</ng-template>

<ng-template #noData>
    <div class="overlay-content">
        <span> No custom compliance found </span>
    </div>
</ng-template>

<ng-template #forAllAccounts>
    <span> For all account Ids </span>
</ng-template>

<ng-template #forAllRegions>
    <span> For all regions </span>
</ng-template>

<ng-template #forAllTags>
    <span> For all resource tags </span>
</ng-template>

<ng-template #forAllCustomComplianceLogic>
    <span> For all custom compliance logic </span>
</ng-template>
