import { Component, OnInit } from '@angular/core';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { WidgetCreationService } from 'src/app/shared/services/widget-creation/widget-creation.service';
import { Helper } from './../../../../classes/Helper';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IBackendFormGeneratorInput } from './../../../../interfaces/form-generator/IBackendFormGeneratorInput';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';

@Component({
    selector: 'app-form-builder-modal',
    templateUrl: './form-builder-modal.component.html',
    styleUrls: ['./form-builder-modal.component.sass']
})
export class FormBuilderModalComponent implements OnInit {
    loaderGroup = {
        saveAsDraft: false,
        configure: false
    };

    widgetRef: Widget;

    formBuilderOutputJson: IBackendFormGeneratorInput;
    widgetId: any;
    formBuilderError: string = null;
    formData: IBackendFormGeneratorInput;
    widgetData: any;
    buttonsInput: IButtonGeneratorInput[];
    buttonOptions: IMultiButtonOption = {
        layout: { justifyContent: 'flex-end' }
    };
    constructor(
        public modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        private widgetCreationService: WidgetCreationService,
        private modalService: ModalService
    ) {}

    ngOnInit(): void {
        this.widgetRef = this.modalInputData.data['widgetRef'];
        this.widgetId = this.modalInputData.data['widgetId'];
        this.formData = this.modalInputData.data['templateData']['form'];

        this.widgetData = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(1);

        this.handleBackButton();
        this.generateButtons();
    }

    handleBackButton() {
        if (
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(2) &&
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(2).form
        ) {
            this.formData = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(2).form;
        }
    }

    nextStep() {
        this.multiStepFormService.nextStep(this.modalInputData.modalId);
    }
    generateButtons() {
        this.buttonsInput = [
            {
                buttonName: 'Back',
                buttonColorType: ButtonColorType.PRIMARY,
                function: () => {
                    this.back();
                },
                buttonType: ButtonType.FLAT,
                hostClass: 'mr-auto'
            },
            {
                buttonName: 'Save as Draft',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.saveAsDraft(buttonRef);
                }
            },
            {
                buttonName: 'Configure Request Medium',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                function: () => {
                    this.configureRequestMedium();
                }
            }
        ];
    }

    formBuilderOutput(event: IBackendFormGeneratorInput) {
        this.formBuilderOutputJson = event;
    }

    saveAsDraft(buttonRef: IButtonGeneratorInput) {
        if (!this.checkDataValidityAndSetData()) {
            return;
        }

        buttonRef.loader = true;

        const dataForApi = {
            ...this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1),
            form: this.formBuilderOutputJson
        };
        if (dataForApi) {
            this.widgetCreationService.saveAsDraft(
                dataForApi,
                (res) => {
                    buttonRef.loader = false;
                    this.modalService.closeModal(
                        null,
                        this.modalInputData.modalId
                    );
                },
                () => {
                    buttonRef.loader = false;
                },
                this.widgetId
            );
        }
    }

    configureRequestMedium() {
        if (!this.checkDataValidityAndSetData()) {
            return;
        }

        this.multiStepFormService.nextStep(this.modalInputData.modalId);
    }

    back() {
        this.multiStepFormService.previousStep(this.modalInputData.modalId);
    }

    checkDataValidityAndSetData() {
        let isValid = true;

        if (this.formBuilderError) {
            isValid = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                this.formBuilderError
            );
        }

        if (isValid) {
            const dataForApi = { form: this.formBuilderOutputJson };

            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .set(2, dataForApi);
        }

        return isValid;
    }
}
