import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { SubSink } from 'subsink';
import { FilterType } from '../../enums/FilterType';
import { FilterCacheService } from '../../services/cache/filters-cache/filter-cache.service';
import { FilterStoreKey } from './../../enums/FilterStoreKey';
import {
    SupportedFormat,
    SupportFormatFromString
} from './../../enums/SupportedFormat';
import { IFilterData } from './../../interfaces/filter/IFilterData';
import { IWidgetData } from './../../interfaces/widget/IWidgetData';
import { WidgetHttpService } from './../../services/http/widget-http/widget-http.service';
import { NotificationsService } from './../../services/notifications/notifications.service';
import { PortletType } from '../../enums/PortletType';
import { MulticurrencyFilterSelectorService } from '../../services/multicurrency-filter-selector/multicurrency-filter-selector.service';

@Component({
    selector: 'app-filters-handler',
    templateUrl: './filters-handler.component.html',
    styleUrls: ['./filters-handler.component.sass']
})
export class FiltersHandlerComponent implements OnInit, OnChanges {
    @Input() widgetIds: string[];
    @Input() filterIds: string[];
    @Input() lightMode: boolean;
    @Input() setToDefault: boolean;
    @Input() filterStoreKey: FilterStoreKey;
    @Input() validateFilters: Subject<any>;
    @Input() invalidFilters: BehaviorSubject<boolean>;
    @Input() hideLogicalOperatorRadioButtons: boolean;
    @Input() isGovernance: boolean;
    @Output() formats = new EventEmitter<Set<SupportedFormat>>();
    @Output() filtersLoaded = new EventEmitter<boolean>();
    @Input() isFieldDisable?: boolean;
    loadingWidgetsData = false;
    supportedFormats = new Set<SupportedFormat>();
    widgetData = new Map<string, IWidgetData>();
    widgetSubs = new SubSink();
    filtersData: Map<string, IFilterData> = null;
    sortedFiltersIds: string[] = null;
    sectionLoader = true;

    constructor(
        private cd: ChangeDetectorRef,
        private widgetHttpService: WidgetHttpService,
        public filterCacheService: FilterCacheService,
        public filtersService: FiltersService,
        private notificationsService: NotificationsService,
        private multiCurrencySelector: MulticurrencyFilterSelectorService
    ) {}
    ngOnInit(): void {
        if (
            this.setToDefault &&
            this.filtersService.filtersData.get(FilterStoreKey.CONFIGURATION) &&
            !this.filtersService.filtersData.get(this.filterStoreKey)
        ) {
            this.filtersService.filtersDataTemp.set(
                this.filterStoreKey,
                new Map(
                    this.filtersService.filtersData.get(
                        FilterStoreKey.CONFIGURATION
                    )
                )
            );
            this.filtersService.convertTempToMain(this.filterStoreKey, true);
            this.filtersService.updateFilter.next(
                new Map().set(
                    this.filterStoreKey,
                    new Map(
                        this.filtersService.filtersData.get(
                            FilterStoreKey.CONFIGURATION
                        )
                    )
                )
            );
        }

        this.supportedFormats.add(SupportFormatFromString('Excel'));
        this.supportedFormats.add(SupportFormatFromString('CSV'));
        this.supportedFormats.add(SupportFormatFromString('PDF'));

        this.filtersService.filtersPageData.subscribe((filtersPageData) => {
            if (
                filtersPageData &&
                filtersPageData.has(this.filterStoreKey) &&
                filtersPageData.get(this.filterStoreKey)
            ) {
                if (!filtersPageData.get(this.filterStoreKey).size) {
                    this.sortedFiltersIds = [];
                    return;
                }
                const filtersData = filtersPageData.get(this.filterStoreKey);
                const allFilters = Array.from(filtersData.keys());
                this.sortedFiltersIds = Helper.sortFilterIds(
                    this.filterCacheService,
                    allFilters
                );
            } else {
                this.sortedFiltersIds = null;
            }
        });
        if (this.widgetIds) {
            this.widgetIds.forEach((item) => {
                this.widgetHttpService.getWidgetInfo(
                    item,
                    false,
                    this.getSuportedFormat.bind(this)
                );
            });
            this.populateWidgetsData();
        } else if (this.filterIds) {
            const allFilters = this.filterIds;
            // const notFound: string[] = [];
            const sortedFilters = this.filterCacheService.sortedFilters.filter(
                (filterId) => {
                    if (allFilters.includes(filterId)) {
                        return true;
                    }
                    // notFound.push(filterId);
                    return false;
                }
            );
            // sortedFilters.push(...notFound);
            this.sortedFiltersIds = sortedFilters;
            this.filtersData =
                this.filtersService.generateFiltersDataWithFilterIds(
                    this.filterIds
                );
            this.sectionLoader = false;
        }

        if (this.validateFilters) {
            this.validateFilters.subscribe((res) => {
                this.sortedFiltersIds.every((filterId) => {
                    if (
                        this.filtersService.filtersData
                            .get(this.filterStoreKey)
                            .get(filterId) &&
                        this.filtersService.filtersData
                            .get(this.filterStoreKey)
                            .get(filterId).filterInfo.compulsory
                    ) {
                        if (!this.checkFiltersValidity(filterId)) {
                            this.notificationsService.showSnackBar(
                                'Fill in all the fields',
                                true
                            );
                            this.invalidFilters.next(true);
                            return false;
                        }
                        this.invalidFilters.next(false);
                        return true;
                    }
                });
            });
        }
    }
    getSuportedFormat(data) {
        if (data) {
            if (data.reports) {
                ['Excel', 'CSV', 'PDF'].forEach((i) => {
                    if (!(i in data.reports)) {
                        this.supportedFormats.delete(
                            SupportFormatFromString(i)
                        );
                    }
                });
            }
            if (!this.supportedFormats.size) {
                this.supportedFormats.add(SupportFormatFromString('Excel'));
                this.formats.emit(this.supportedFormats);
            } else {
                this.formats.emit(this.supportedFormats);
            }
        } else {
            this.supportedFormats.add(SupportFormatFromString('Excel'));
            this.formats.emit(this.supportedFormats);
        }
    }
    checkFiltersValidity(filterId) {
        if (
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .get(filterId).value instanceof Array &&
            !this.filtersService.filtersData
                .get(this.filterStoreKey)
                .get(filterId).value.length
        ) {
            return false;
        }
        if (
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .get(filterId).value instanceof Object &&
            !Object.keys(
                this.filtersService.filtersData
                    .get(this.filterStoreKey)
                    .get(filterId).value
            ).length
        ) {
            return false;
        }

        // for boolean, string, number
        if (
            !this.filtersService.filtersData
                .get(this.filterStoreKey)
                .get(filterId).value &&
            (this.filtersService.filtersData
                .get(this.filterStoreKey)
                .get(filterId).value !== 0 ||
                this.filtersService.filtersData
                    .get(this.filterStoreKey)
                    .get(filterId).value !== false)
        ) {
            return false;
        }

        // for JSON
        if (
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .get(filterId).filterInfo.type === FilterType.JSON
        ) {
            try {
                JSON.parse(
                    this.filtersService.filtersData
                        .get(this.filterStoreKey)
                        .get(filterId).value
                );
                return true;
            } catch {
                return false;
            }
        }
        return true;
    }

    populateWidgetsData() {
        this.loadingWidgetsData = true;
        const filtersPageData = new Map(
            this.filtersService.filtersPageData.getValue()
        );
        filtersPageData.set(this.filterStoreKey, null);
        this.filtersService.filtersPageData.next(filtersPageData);
        this.widgetData = new Map<string, IWidgetData>();
        this.widgetSubs.unsubscribe();
        if (this.widgetIds) {
            this.widgetIds.forEach((widget) => {
                this.widgetHttpService.loadWidgetsData(
                    this.widgetHttpService,
                    this.widgetSubs,
                    widget,
                    this.widgetData,
                    this.checkIfAllDataCollected.bind(this)
                );
            });
        }
    }

    checkIfAllDataCollected() {
        if (this.widgetIds.length === this.widgetData.size) {
            // Checking for Portlet Type to Display the MultiCurrency Selector
            if (this.filterStoreKey === FilterStoreKey.REPORT_FILTERS)
                this.getPortletType();
            this.filtersService.generateFiltersData(
                this.filterStoreKey,
                this.widgetData
            );
            this.loadingWidgetsData = false;
            this.widgetHttpService.ngZone.runOutsideAngular(() => {
                setTimeout(() => {
                    this.sectionLoader = false;
                    this.filtersLoaded.emit(true);
                    this.cd.detectChanges();
                }, 500);
            });
        }
    }
    
    /**
     * Retrieves the portlet type for each widget and determines whether to show the multi-currency selector.
     * This function iterates through each widget ID in the widgetIds array and checks if the widget's portlet type
     * is compatible with showing multi-currency fields based on governance requirements.
    */
    getPortletType(): void {
        // Iterate through each widget ID
        this.multiCurrencySelector.showMultiCurrencyField = false;
        this.widgetIds.forEach(widgetId => {
            // Retrieve widget data for the current widget ID
            const widgetData = this.widgetData.get(widgetId);
            const widgetPortletType = widgetData.widgetInfo.portlet.type;
            // Check if the portlet type of the widget is compatible with multi-currency selection
            if ((widgetPortletType === PortletType.AGGREGATE || widgetPortletType === PortletType.TIMESERIES ||
                widgetPortletType === PortletType.PIE || widgetPortletType === PortletType.REGIONAL)
            ) {
                // If the portlet type is compatible and governance is enabled, show the multi-currency selector
                this.multiCurrencySelector.showMultiCurrencyField = true;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.filterIds) {
            this.filterIds = changes.filterIds.currentValue;
        }
        this.cd.detectChanges();
    }
}
