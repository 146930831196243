<div class="container">
    <div class="form-container">
        <div class="tw-w-3/4">
            <app-form-generator
                *ngIf="formGenInput && formGenInput.fields.length"
                [formGeneratorInput]="formGenInput"
                (formGroupRef)="updateFormGroup($event)"
                (updateControl)="updateControl = $event"
                (resetFormFunction)="resetForm = $event"
            ></app-form-generator>
        </div>
    </div>

    <div class="buttons">
        <app-multi-button-generator [buttonGenInputs]="buttonInputs">
        </app-multi-button-generator>
    </div>
</div>
