<form class="form">
    <div class="form-data">
        <section class="keyVal-fg tw-rounded tw-my-3 tw-mx-4 tw-py-1 tw-px-3">
            <div class="heading tw-font-semibold">
                Groups
                <mat-icon
                    matPrefix
                    matTooltip="Group name value will be visible in ticket creation form"
                    class="clickable"
                    >{{ "help_outline" }}</mat-icon
                >
            </div>
            <div class="section-content">
                <ng-container
                    *ngFor="
                        let keyValuePair of groupFormGenInputs;
                        let i = index;
                        let last = last
                    "
                >
                    <div class="item tw-flex tw-items-start">
                        <app-form-generator
                            [formGeneratorInput]="keyValuePair"
                            [intitalFormGroup]="
                                groupFormGroup.has(keyValuePair.formId)
                                    ? groupFormGroup.get(keyValuePair.formId)
                                    : null
                            "
                            (formGroupRef)="
                                groupFormGroup.set(keyValuePair.formId, $event)
                            "
                        ></app-form-generator>
                        <mat-icon
                            class="success tw-mt-3 tw-ml-3 tw-text-fs-400 tw-items-center tw-flex tw-justify-center tw-cursor-pointer"
                            *ngIf="last"
                            (click)="
                                lastKeyValueFormUpdate(
                                    groupFormGenInputs,
                                    keyValuePair.formId,
                                    groupFormGroup
                                )
                            "
                            >add</mat-icon
                        >
                        <mat-icon
                            class="error tw-mt-3 tw-ml-3 tw-text-fs-400 tw-items-center tw-flex tw-justify-center tw-cursor-pointer"
                            *ngIf="!last"
                            (click)="
                                deletegroupFormGroup(
                                    i,
                                    keyValuePair.formId,
                                    'group'
                                )
                            "
                            >delete</mat-icon
                        >
                    </div>
                </ng-container>
            </div>
        </section>
        <section class="keyVal-fg tw-rounded tw-my-3 tw-mx-4 tw-py-1 tw-px-3">
            <div class="heading tw-font-semibold">
                Category
                <mat-icon
                    matPrefix
                    matTooltip="Category name value will be visible in ticket creation form"
                    class="clickable"
                    >{{ "help_outline" }}</mat-icon
                >
            </div>
            <div class="section-content">
                <ng-container
                    *ngFor="
                        let keyValuePair of categoryFormGenInputs;
                        let i = index;
                        let last = last
                    "
                >
                    <div class="item tw-flex tw-items-start">
                        <app-form-generator
                            [formGeneratorInput]="keyValuePair"
                            [intitalFormGroup]="
                                categoryFormGroup.has(keyValuePair.formId)
                                    ? categoryFormGroup.get(keyValuePair.formId)
                                    : null
                            "
                            (formGroupRef)="
                                categoryFormGroup.set(
                                    keyValuePair.formId,
                                    $event
                                )
                            "
                        ></app-form-generator>
                        <mat-icon
                            class="success tw-ml-3 tw-mt-3 tw-text-fs-400 tw-items-center tw-flex tw-justify-center tw-cursor-pointer"
                            *ngIf="last"
                            (click)="
                                lastKeyValueFormUpdate(
                                    categoryFormGenInputs,
                                    keyValuePair.formId,
                                    categoryFormGroup
                                )
                            "
                            >add</mat-icon
                        >
                        <mat-icon
                            class="error tw-mt-3 tw-ml-3 tw-text-fs-400 tw-items-center tw-flex tw-justify-center tw-cursor-pointer"
                            *ngIf="!last"
                            (click)="
                                deletegroupFormGroup(
                                    i,
                                    keyValuePair.formId,
                                    'category'
                                )
                            "
                            >delete</mat-icon
                        >
                    </div>
                </ng-container>
            </div>
        </section>
        <app-form-generator
            *ngIf="incidentTypeFormGenInputs"
            [formGeneratorInput]="incidentTypeFormGenInputs"
            (formGroupRef)="incidentTypeFormGroup = $event"
        ></app-form-generator>
    </div>
    <div class="button-container">
        <app-multi-button-generator
            [buttonGenInputs]="buttonInputs"
            [options]="buttonOption"
        >
        </app-multi-button-generator>
    </div>
</form>
