import {
    AfterContentInit,
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IconType } from 'src/app/shared/enums/IconType';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { VerticalScrollingTabDirective } from '../../directives/vertical-scrolling-tab/vertical-scrolling-tab.directive';

@Component({
    selector: 'app-vertical-scrolling-tab-group',
    templateUrl: './vertical-scrolling-tab-group.component.html',
    styleUrls: ['./vertical-scrolling-tab-group.component.sass']
})
export class VerticalScrollingTabGroupComponent
    implements OnInit, AfterContentInit, AfterViewInit
{
    @Input('activeIndex') selectedIndex: number = 0;
    @Input() modalId: Symbol;

    activeIndex: number = 0;

    @Output('activeIndexChanged')
    activeIndexChangedEvent: EventEmitter<number> = new EventEmitter<number>();

    @ContentChildren(VerticalScrollingTabDirective)
    tabContentList: QueryList<VerticalScrollingTabDirective>;

    @ViewChildren('verticalTabContent')
    tabDivList: QueryList<ElementRef<HTMLElement>>;

    scrollSubject: EventEmitter<any> = new EventEmitter<any>();
    tabOffsets: number[];
    isViewRendered: boolean = false;

    tabHeaderList: ITabHeader[];
    eachTabWidth: number;

    scrollSubscription: Subscription;
    crossIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fa fa-times'
    };

    constructor(public modalService: ModalService) {}

    ngOnInit(): void {}

    ngAfterContentInit(): void {
        this.prepareTabHeaderList();
    }

    ngAfterViewInit(): void {
        this.isViewRendered = true;
        this.initScrollSubject();
        this.initVerticalTabOffsets();
        setTimeout(() => {
            if (this.selectedIndex !== 0) {
                this.changeVerticalTab(this.selectedIndex, true);
            }
        }, 500);
    }

    prepareTabHeaderList() {
        if (this.tabContentList && this.tabContentList.length) {
            this.eachTabWidth = (100 - 4) / this.tabContentList.length;
            this.tabOffsets = [];
            this.tabHeaderList = this.tabContentList.map(
                (tabContent, index) => {
                    const tabheader: ITabHeader = {
                        label: tabContent.hasLabel
                            ? tabContent.label
                            : `Tab - ${index + 1}`
                    };
                    if (tabContent.hasLabel && tabContent.showTooltip) {
                        tabheader.showTooltip = true;
                        tabheader.tooltipText = tabContent.tooltipText;
                    }
                    this.tabOffsets.push(0);
                    return tabheader;
                }
            );
        } else {
            this.eachTabWidth = 100;
            this.tabHeaderList = [{ label: 'Tab 1' }];
        }
    }

    initVerticalTabOffsets() {
        this.tabDivList.forEach((elementRef, index) => {
            this.tabOffsets[index] = elementRef.nativeElement.offsetTop - 61;
        });
    }

    initScrollSubject() {
        this.scrollSubscription = this.scrollSubject
            .pipe(debounceTime(150))
            .subscribe((event) => {
                const scrollTopPosition = event.target.scrollTop;
                const bottomPosition = event.target.scrollHeight;
                const offsetHeight = event.target.offsetHeight;
                let maxIndex = 0;
                for (let index = 0; index < this.tabOffsets.length; index++) {
                    if (
                        scrollTopPosition >= this.tabOffsets[index] &&
                        index > maxIndex
                    ) {
                        maxIndex = index;
                    }
                }
                if (scrollTopPosition >= bottomPosition - offsetHeight) {
                    maxIndex = this.tabOffsets.length - 1;
                }
                if (maxIndex !== this.activeIndex) {
                    this.activeIndex = maxIndex;
                }
            });
    }

    changeVerticalTab(index: number, autoScroll?: boolean) {
        if (this.isViewRendered) {
            this.activeIndex = index;
            if (autoScroll) {
                if (this.tabOffsets && this.tabDivList && autoScroll) {
                    const vTabsList = this.tabDivList.toArray();
                    if (vTabsList[index]) {
                        this.scrollSubscription.unsubscribe();
                        vTabsList[index].nativeElement.scrollIntoView({
                            behavior: 'smooth'
                        });

                        setTimeout(() => {
                            this.initScrollSubject();
                        }, 750);
                    }
                }
            }
            this.activeIndexChangedEvent.emit(this.activeIndex);
        }
    }
}

interface ITabHeader {
    label: string;
    showTooltip?: boolean;
    tooltipText?: string;
}
