/**
 * CREATE: You are creating for the first time
 * Behavior would be
 * - All the fields are editable.
 * - Create button would be displayed if not hidden specifically.
 * EDIT: You are editing the form
 * Behavior would be
 * - All the fields are editable.
 * - Update button would be displayed if not hidden specifically.
 * IDLE: It is being displayed only
 * Behavior would be
 * - All the fields are non-editable.
 * - Button would not be displayed.
 */
export enum FormState {
    CREATE = 'CREATE',
    EDIT = 'EDIT',
    IDLE = 'IDLE'
}
