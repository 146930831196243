<div class="container">
    <mat-tab-group
        #tabGroup
        mat-stretch-tabs
        mat-align-tabs="center"
        [disableRipple]="true"
        ><mat-tab label="Define Custom Compliance Logic">
            <div class="tab-content" *ngIf="customizeFormGenInput; else loader">
                <app-form-generator
                    *ngIf="customizeFormGenInput; else loader"
                    [formGeneratorInput]="customizeFormGenInput"
                    (formGroupRef)="customizeFormGroupRef = $event"
                    [modalId]="modalData.modalId"
                ></app-form-generator>
            </div>
        </mat-tab>

        <mat-tab label="Edit Custom Compliance Logic">
            <ng-template matTabContent>
                <div
                    class="tab-content"
                    *ngIf="customizeLogicList; else loader"
                >
                    <ng-container
                        *ngIf="
                            customizeLogicList.length > 0;
                            else noDataMessage
                        "
                    >
                        <div
                            class="tw-py-5 list-content table-dropdown-container"
                            [class.no-min-height]="noMinHeight"
                            *ngIf="customizeLogicList; else loader"
                        >
                            <app-only-table-generator
                                [tableInput]="customizeLogicTableInput"
                                [tableData]="customizeLogicList"
                                (gridRef)="gridRef = $event"
                                [hideControlBar]="true"
                            >
                            </app-only-table-generator>
                        </div>

                        <app-button-generator
                            class="update-button"
                            *ngIf="saveButtonInput"
                            [buttonGenInput]="saveButtonInput"
                        ></app-button-generator>
                    </ng-container>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
<ng-template #loader>
    <div class="step-loader">
        <mat-spinner [diameter]="25" class="mat-spinner-color"></mat-spinner>
    </div>
</ng-template>

<ng-template #noDataMessage>
    <div class="step-loader">
        <span class="no-data">No Customization Logic Found</span>
    </div>
</ng-template>
