<app-table-generator
    [tableInput]="tableInput"
    [widgetRef]="widgetRef"
    (gridRef)="agGrid = $event"
    (cellValueChanged)="onCellValueChanged($event)"
    (selectionChanged)="onSelectionChanged()"
    [overviewTemplateStart]="overviewTemplateStart"
    [overviewTemplateEnd]="overviewTemplateEnd"
></app-table-generator>

<ng-template #overviewTemplateStart>
    <div
        class="overview"
        *ngIf="
            widgetRef.apiResponse &&
            widgetRef.apiResponse['dataMap'] &&
            widgetRef.apiResponse['dataMap']['totalSeverity']
        "
        style="display: flex; align-items: center"
    >
        <app-button-generator
            *ngIf="showConventions && conventionButton"
            [buttonGenInput]="conventionButton"
        ></app-button-generator>
        <app-multi-button-generator
            *ngIf="severityButtons && widgetRef.widgetData.description"
            [buttonGenInputs]="severityButtons"
        ></app-multi-button-generator>
    </div>
</ng-template>

<ng-template #overviewTemplateEnd>
    <div class="automation-buttons">
        <app-multi-button-generator [buttonGenInputs]="buttonInputs">
        </app-multi-button-generator>
    </div>
</ng-template>
