import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';
import { CustomValidator } from './../classes/CustomValidator';
export class caseInsensitiveKeyAlreadyExists extends CustomValidator {
    key = CustomValidatorKey.AZURE_KEY;
    keyExists: boolean = false;
    constructor(
        private values,
        private allkeysAlreadyExists,
        private keys,
        private keyIndex,
        private update
    ) {
        super();
    }
    validatorFunction(control: AbstractControl): ValidationErrors {
        this.keyExists = false;

        this.values.forEach((data, index) => {
            if (control.value) {
                if (data.toLowerCase() === control.value.toLowerCase()) {
                    if (
                        !this.allkeysAlreadyExists.includes(control.value) &&
                        (index !== this.keyIndex || !this.update)
                    ) {
                        this.keyExists = true;
                    }
                }
            }
        });
        this.keys.forEach((data) => {
            if (control.value) {
                if (data.toLowerCase() === control.value.toLowerCase()) {
                    this.keyExists = true;
                }
            }
        });

        if (!this.keyExists) {
            return null;
        }

        const obj = {};
        obj[this.key] = true;
        return obj;
    }
}
