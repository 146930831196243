import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';
import { ITableGeneratorInput } from './../../../../interfaces/table-generator/ITableGeneratorInput';

@Component({
    selector: 'app-custom-compliance-widget-list',
    templateUrl: './custom-compliance-widget-list.component.html',
    styleUrls: ['./custom-compliance-widget-list.component.sass']
})
export class CustomComplianceWidgetListComponent implements OnInit {
    tableGenInput: ITableGeneratorInput = null;
    tableData = null;
    filterInfo: IFilterInfo = {
        listData: [],
        placeholder: 'Select Service',
        lightMode: true
    };
    multipleSelection: boolean = false;
    gridRef: GridOptions = null;
    selectedFilter;
    stepData = {};
    edit: boolean = false;
    selectedNodes = [];
    widgetRef: Widget;

    buttonGenInputs: IButtonGeneratorInput[] = [
        {
            buttonName: 'Back',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.RAISED,
            function: () => {
                this.goToPreviousStep();
            }
        },
        {
            buttonName: 'Next',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.RAISED,
            function: () => {
                this.next();
            }
        }
    ];

    constructor(
        public modalInjectedData: ModalInjectedData,
        public multiStepFormService: MultiStepFormService
    ) {
        this.widgetRef = modalInjectedData.data['widgetRef'];
        this.tableData = modalInjectedData.data['widgetList'];
        this.filterInfo.listData =
            this.modalInjectedData.data['serviceFilterData'];
        this.multipleSelection =
            this.modalInjectedData.data['multipleSelection'];
        if (
            multiStepFormService.stepData.get(modalInjectedData.modalId).has(2)
        ) {
            this.stepData = multiStepFormService.stepData
                .get(modalInjectedData.modalId)
                .get(2);
        } else if (modalInjectedData.data['stepData']) {
            this.stepData = modalInjectedData.data['stepData'];
        }
        this.edit = modalInjectedData.data['edit'];
        if (
            this.stepData['widgetSelected'] &&
            this.stepData['widgetSelected']['widgetList'] &&
            this.stepData['widgetSelected']['widgetList'].length
        ) {
            this.selectedNodes = this.stepData['widgetSelected'][
                'widgetList'
            ].map((widgetData) => widgetData['widgetId']);
        } else if (
            this.stepData['widgetIds'] &&
            this.stepData['widgetIds'].length
        ) {
            this.selectedNodes = this.stepData['widgetIds'];
        }
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        this.tableGenInput = {
            buttons: null,
            listExtraction: {
                type: 'DIRECT'
            },
            selection: this.multipleSelection ? 'multiple' : 'single',
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-list'
            },
            afterResponse: (res) => {
                this.tableData = this.moveSelectedToTop(res);
            },
            tableHeight: 300,
            noDataMessage: 'No Widgets Available',
            columns: [
                {
                    columnKey: 'subProductName',
                    columnName: 'Sub product Name'
                },
                {
                    columnKey: 'serviceName',
                    columnName: 'Service Name'
                },
                {
                    columnKey: 'widgetName',
                    columnName: 'Widget Name'
                }
            ]
        };
    }

    moveSelectedToTop(response) {
        if (response && response.length) {
            const selected = [];
            if (this.selectedNodes && this.selectedNodes.length) {
                for (let i = 0; i < response.length; i++) {
                    const widget = response[i];
                    if (this.selectedNodes.includes(widget['widgetId'])) {
                        selected.push(...response.splice(i, 1));
                        i--;
                    }
                }
                selected.forEach((selWidget) => {
                    response.unshift(selWidget);
                });
            }
            return response;
        }
        return [];
    }

    handleFilterChange(event) {
        if (this.selectedFilter === event) {
            return;
        }
        if (event.length === 0) {
            if (this.gridRef) {
                this.gridRef.api.setRowData(this.tableData);
                this.gridRef.api.redrawRows();
                this.onGridReady();
            }
            return;
        }
        this.selectedFilter = event;
        const tableData = this.tableData.filter((service) => {
            if (event.includes(service['serviceName'])) {
                return service;
            }
        });
        this.gridRef.api.setRowData(tableData);
        this.gridRef.api.redrawRows();
        this.onGridReady();
    }

    goToPreviousStep() {
        const widgetList = this.gridRef.api
            .getSelectedRows()
            .map((row) => row['widgetId']);
        const inputData = {
            widgetIds: widgetList
        };
        this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .set(2, inputData);
        this.multiStepFormService.previousStep(this.modalInjectedData.modalId);
    }

    next() {
        if (!this.gridRef.api.getSelectedRows().length) {
            this.widgetRef.notificationsService.showSnackBar(
                'Select atleast 1 widget.',
                true
            );
            return;
        }
        const widgetList = this.gridRef.api
            .getSelectedRows()
            .map((row) => row['widgetId']);
        const inputData = {
            widgetIds: widgetList
        };
        this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .set(2, inputData);

        this.multiStepFormService.nextStep(this.modalInjectedData.modalId);
    }

    handleSelectionChange() {
        this.selectedNodes = this.gridRef.api
            .getSelectedRows()
            .map((row) => row['widgetId']);
    }

    onGridReady() {
        this.gridRef.api.forEachNode((node) => {
            if (this.selectedNodes.includes(node.data['widgetId'])) {
                node.selectThisNode(true);
            }
        });
    }
}
