<form
    class="form {{ resetHeight ? 'form-view' : '' }}"
    [ngClass]="
        formGeneratorInput.extraClass ? formGeneratorInput.extraClass : ''
    "
    *ngIf="formGeneratorInput"
    [formGroup]="formGroup"
    (ngSubmit)="submitForm($event)"
    (keydown.enter)="submitForm($event)"
>
    <ng-container *ngIf="formGeneratorInput.formMessage">
        <p
            class="form-message"
            [innerHTML]="formGeneratorInput.formMessage"
        ></p>
    </ng-container>
    <div class="form-data">
        <ng-container *ngFor="let field of formGeneratorInput.fields">
            <div
                class="form-row-container"
                [ngStyle]="
                    field.fieldStyle ? field.fieldStyle : { width: '100%' }
                "
                [ngClass]="field.containerClass ? field.containerClass : ''"
            >
                <div
                    *ngIf="field.isFilter && !field.hideFilterHead"
                    class="filter-head"
                >
                    <div [matTooltip]="field.label" class="filter-name">
                        {{ field.label }}
                        <span *ngIf="field.required" class="compulsory">*</span>
                    </div>
                    <div class="filter-controls">
                        <div
                            *ngIf="
                                field.id &&
                                filterData &&
                                filterData.has(field.id) &&
                                filterData.get(field.id).associatedWidgets
                                    .length &&
                                !field.hideFilterInfoIcon
                            "
                            appIcon
                            [matMenuTriggerFor]="associatedWidgetsMenu"
                            [matTooltip]="'Widgets associated with filter'"
                            [data]="infoIcon"
                            class="action"
                        ></div>
                        <mat-menu #associatedWidgetsMenu="matMenu">
                            <div class="more-info-menu">
                                <div class="heading">
                                    Filter is applicable on following insights
                                </div>
                                <div
                                    class="info-item not-clickable"
                                    *ngFor="
                                        let widget of filterData.get(field.id)
                                            ?.associatedWidgets;
                                        let i = index
                                    "
                                >
                                    {{ i + 1 }}.&nbsp;{{ widget.widgetTitle }}
                                </div>
                            </div>
                        </mat-menu>
                    </div>
                    <div
                        *ngIf="
                            filterService.currentPageFilterInfo?.get(field.id)
                                ?.logicalOperatorEnable && showToggleButton
                        "
                        class="dropdown-radio"
                    >
                        <mat-radio-group
                            (change)="
                                setlogicalOperator($event.value, field.id)
                            "
                            [value]="selectedOperator"
                            class="logicalOperators"
                        >
                            <mat-radio-button value="OR">OR</mat-radio-button>
                            <mat-radio-button value="AND">AND</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <!-- TEXT FIELD START -->
                <ng-container
                    *ngIf="
                        field.fieldType === FilterType.TEXT ||
                        field.fieldType === FilterType.SHORT_TEXT
                    "
                >
                    <ng-container *ngTemplateOutlet="text"></ng-container>
                </ng-container>

                <!-- TEXT FIELD END -->

                <!-- PASSWORD FIELD START -->
                <ng-container
                    *ngIf="
                        field.fieldType === FilterType.PASSWORD &&
                        !hiddenField.get(field.name)
                    "
                >
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-password-field
                            *ngIf="formGroup"
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [formControlName]="field.name"
                        ></app-password-field>
                    </ng-container>

                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-password-field
                                *ngIf="formGroup"
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [formControlName]="field.name"
                            ></app-password-field>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- PASSWORD FIELD END -->

                <!-- URL FIELD START -->
                <ng-container *ngIf="field.fieldType === FilterType.URL">
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-url-field
                            *ngIf="formGroup"
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [formControlName]="field.name"
                        ></app-url-field>
                    </ng-container>
                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-url-field
                                *ngIf="formGroup"
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [formControlName]="field.name"
                            ></app-url-field>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- URL FIELD END -->

                <!-- COLOR FIELD START -->
                <ng-container *ngIf="field.fieldType === FilterType.COLOR">
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-color-field
                            *ngIf="formGroup"
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [formControlName]="field.name"
                        ></app-color-field>
                    </ng-container>
                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-color-field
                                *ngIf="formGroup"
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [formControlName]="field.name"
                            ></app-color-field>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- COLOR FIELD END -->

                <!-- FILE FIELD START -->
                <ng-container *ngIf="field.fieldType === FilterType.FILE">
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-file-field
                            *ngIf="formGroup"
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [formControlName]="field.name"
                        ></app-file-field>
                    </ng-container>
                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-file-field
                                *ngIf="formGroup"
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [formControlName]="field.name"
                            ></app-file-field>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- FILE FIELD END -->

                <!-- TEXT AREA START -->
                <ng-container
                    *ngIf="
                        field.fieldType === FilterType.TEXTAREA &&
                        !hiddenField.get(field.name)
                    "
                >
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-text-area
                            *ngIf="formGroup"
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [formControlName]="field.name"
                        ></app-text-area>
                    </ng-container>
                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-text-area
                                *ngIf="formGroup"
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [formControlName]="field.name"
                            ></app-text-area>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- TEXT AREA END -->

                <!-- EMAIL FIELD START -->
                <ng-container
                    *ngIf="
                        field.fieldType === FilterType.EMAIL &&
                        !hiddenField.get(field.name)
                    "
                >
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-email-field
                            *ngIf="formGroup"
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [formControlName]="field.name"
                        ></app-email-field>
                    </ng-container>
                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-email-field
                                *ngIf="formGroup"
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [formControlName]="field.name"
                            ></app-email-field>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- EMAIL FIELD END -->

                <!-- NUMBER FIELD START -->
                <ng-container
                    *ngIf="
                        field.fieldType === FilterType.NUMBER &&
                        !hiddenField.get(field.name)
                    "
                >
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-number-field
                            *ngIf="formGroup"
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [formControlName]="field.name"
                        ></app-number-field>
                    </ng-container>
                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-number-field
                                *ngIf="formGroup"
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [formControlName]="field.name"
                            ></app-number-field>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- NUMBER FIELD END -->

                <!--CHECKBOX START -->
                <ng-container
                    *ngIf="
                        field.fieldType === FilterType.CHECKBOX &&
                        !hiddenField.get(field.name)
                    "
                >
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-checkbox-field
                            *ngIf="formGroup"
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [updateHiddenFields]="updateHiddenFields.bind(this)"
                            [formControlName]="field.name"
                        ></app-checkbox-field>
                    </ng-container>
                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-checkbox-field
                                *ngIf="formGroup"
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [updateHiddenFields]="
                                    updateHiddenFields.bind(this)
                                "
                                [formControlName]="field.name"
                            ></app-checkbox-field>
                        </div>
                    </ng-container>
                </ng-container>
                <!--CHECKBOX END -->

                <!-- SINGLE_DROPDOWN START -->
                <ng-container
                    *ngIf="field.fieldType === FilterType.DROPDOWN_SINGLE"
                >
                    <ng-container *ngTemplateOutlet="singleSelectDropDown">
                    </ng-container>
                </ng-container>
                <!-- SINGLE_DROPDOWN END -->

                <!-- MULTIPLE DROPDOWN START -->
                <ng-container
                    *ngIf="field.fieldType === FilterType.DROPDOWN_MULTIPLE"
                >
                    <ng-container
                        [ngTemplateOutlet]="multiSelectDropDown"
                        [ngTemplateOutletContext]="{
                            formField: field
                        }"
                    ></ng-container>
                </ng-container>

                <!-- MULTIPLE DROPDOWN END -->
                
                <!-- MULTIPLE DROPDOWN WITH INPUT START -->
                <ng-container
                    *ngIf="field.fieldType === FilterType.MULTI_DROPDOWN_WITH_INPUT"
                >
                    <ng-container
                        [ngTemplateOutlet]="multiSelectDropdownWithInput"
                        [ngTemplateOutletContext]="{
                            formField: field
                        }"
                    ></ng-container>
                </ng-container>

                <!-- MULTIPLE DROPDOWN WITH INPUT END -->

                <!-- GROUP SINGLE DROPDOWN START -->
                <ng-container
                    *ngIf="field.fieldType === FilterType.DROPDOWN_GROUP_SINGLE"
                >
                    <ng-container
                        *ngTemplateOutlet="groupSingleDropDown"
                    ></ng-container>
                </ng-container>

                <!-- GROUP SINGLE DROPDOWN END -->

                <!-- GROUP MULTIPLE DROPDOWN START -->
                <ng-container
                    *ngIf="
                        field.fieldType === FilterType.DROPDOWN_GROUP_MULTIPLE
                    "
                >
                    <ng-container
                        *ngTemplateOutlet="groupMultiSelectDropDown"
                    ></ng-container>
                </ng-container>

                <!-- GROUP MULTIPLE DROPDOWN END -->

                <!-- LIST_OBJECT_DROPDOWN START -->
                <ng-container
                    *ngIf="field.fieldType === FilterType.DROPDOWN_LIST_OBJECT"
                >
                    <ng-container
                        *ngTemplateOutlet="listObjectDropdown"
                    ></ng-container>
                </ng-container>
                <!-- LIST_OBJECT_DROPDOWN END -->

                <!-- MAT CHIP LIST START -->
                <ng-container
                    *ngIf="field.fieldType === FilterType.MATCHIPLIST"
                >
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-matchip-field
                            *ngIf="formGroup"
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [formControlName]="field.name"
                            [separatorKeysCodes]="separatorKeysCodes"
                            [addChip]="addChip.bind(this)"
                            [onRemoveChip]="onRemoveChip.bind(this)"
                        ></app-matchip-field>
                    </ng-container>
                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-matchip-field
                                *ngIf="formGroup"
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [formControlName]="field.name"
                                [separatorKeysCodes]="separatorKeysCodes"
                                [addChip]="addChip.bind(this)"
                                [onRemoveChip]="onRemoveChip.bind(this)"
                            ></app-matchip-field>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- MAT CHIP LIST END -->

                <!-- WYSIWYG EDITOR START -->

                <ng-container
                    *ngIf="field.fieldType === FilterType.WYSIWYG_EDITOR"
                >
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-wysiwyg-editor-field
                            *ngIf="formGroup"
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [formControlName]="field.name"
                        ></app-wysiwyg-editor-field>
                    </ng-container>
                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-wysiwyg-editor-field
                                *ngIf="formGroup"
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [formControlName]="field.name"
                            ></app-wysiwyg-editor-field>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- WYSIWYG EDITOR END -->

                <!-- RADIO START -->
                <ng-container
                    *ngIf="
                        field.fieldType === FilterType.RADIO &&
                        !hiddenField.get(field.name)
                    "
                >
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-radio-field
                            *ngIf="formGroup"
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [formControlName]="field.name"
                            [updateFormControls]="updateFormControls.bind(this)"
                        ></app-radio-field>
                    </ng-container>
                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-radio-field
                                *ngIf="formGroup"
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [formControlName]="field.name"
                                [updateFormControls]="
                                    updateFormControls.bind(this)
                                "
                            ></app-radio-field>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- RADIO END -->

                <!-- DATE FIELD START -->
                <ng-container *ngIf="field.fieldType === FilterType.DATE">
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-date-field
                            *ngIf="formGroup"
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [formControlName]="field.name"
                        ></app-date-field>
                    </ng-container>
                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-date-field
                                *ngIf="formGroup"
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [formControlName]="field.name"
                            ></app-date-field>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- DATE FIELD END -->

                <!-- TOGGLE FIELD START -->
                <ng-container *ngIf="field.fieldType === FilterType.TOGGLE">
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-toggle-field
                            *ngIf="formGroup"
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [formControlName]="field.name"
                        ></app-toggle-field>
                    </ng-container>
                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-toggle-field
                                *ngIf="formGroup"
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [formControlName]="field.name"
                            ></app-toggle-field>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- TOGGLE FIELD END -->

                <!-- CONTENT FIELD START -->
                <ng-container
                    *ngIf="
                        field.fieldType === FilterType.CONTENT &&
                        !hiddenField.get(field.name)
                    "
                >
                    <ng-container
                        [ngTemplateOutlet]="contentField"
                        [ngTemplateOutletContext]="{ formField: field }"
                    ></ng-container>
                </ng-container>
                <!-- CONTENT FIELD END -->

                <!-- TABLE FIELD START -->
                <ng-container
                    *ngIf="
                        field.fieldType === FilterType.TABLE &&
                        !hiddenField.get(field.name)
                    "
                >
                    <div class="table-container">
                        <table>
                            <tr class="headings-row">
                                <th
                                    class="heading"
                                    *ngFor="
                                        let heading of field['table'][
                                            'tableHeadings'
                                        ]
                                    "
                                >
                                    {{ heading }}
                                </th>
                            </tr>
                            <tr
                                *ngFor="
                                    let formField of field['table']['rowfields']
                                "
                                class="field-row"
                            >
                                <ng-container>
                                    <td
                                        [class.dropdown]="
                                            formField[heading] &&
                                            formField[heading]['fieldType'] ===
                                                FilterType.DROPDOWN_MULTIPLE
                                        "
                                        class="field"
                                        *ngFor="
                                            let heading of field['table'][
                                                'tableHeadings'
                                            ]
                                        "
                                    >
                                        <ng-container
                                            *ngIf="
                                                formField &&
                                                formField[heading] &&
                                                formField[heading][
                                                    'fieldType'
                                                ] === FilterType.CONTENT
                                            "
                                            [ngTemplateOutlet]="contentField"
                                            [ngTemplateOutletContext]="{
                                                formField: formField[heading]
                                            }"
                                        ></ng-container>

                                        <ng-container
                                            *ngIf="
                                                formField &&
                                                formField[heading] &&
                                                formField[heading][
                                                    'fieldType'
                                                ] ===
                                                    FilterType.DROPDOWN_MULTIPLE
                                            "
                                            [ngTemplateOutlet]="
                                                multiSelectDropDown
                                            "
                                            [ngTemplateOutletContext]="{
                                                formField: formField[heading]
                                            }"
                                        ></ng-container>
                                    </td>
                                </ng-container>
                            </tr>
                        </table>
                    </div>
                </ng-container>
                <!-- TABLE FIELD END -->

                <!-- CALLBACK BUTTON START -->
                <ng-container
                    *ngIf="
                        field.fieldType === FilterType.CALLBACK_BUTTON &&
                        !hiddenField.get(field.name)
                    "
                >
                    <div class="form-row callback-button-input">
                        <label class="form-label" *ngIf="field.showLabel"
                            >{{ field.label }}
                            <span
                                *ngIf="
                                    field.required !== undefined
                                        ? field.required
                                        : true
                                "
                                class="mat-form-field-required-marker"
                                >&nbsp;*</span
                            ><ng-container
                                *ngIf="
                                    field.showSuffixIconInLabel &&
                                    field.suffixIcon
                                "
                            >
                                &nbsp;
                                <mat-icon
                                    *ngIf="
                                        field.showSuffixIconInLabel &&
                                        field.suffixIcon
                                    "
                                    matSuffix
                                    [matTooltip]="field.suffixIcon.hoverText"
                                    class="clickable"
                                    (click)="
                                        field.suffixIcon.function
                                            ? field.suffixIcon.function(
                                                  field,
                                                  formGroup
                                              )
                                            : null
                                    "
                                    >{{
                                        field.suffixIcon.iconData.class
                                    }}</mat-icon
                                >
                            </ng-container>
                        </label>
                        <div class="form-input">
                            <div class="buttons-container">
                                <app-button-generator
                                    [buttonGenInput]="field.buttonGenInput"
                                ></app-button-generator>
                                <ng-container
                                    *ngIf="formGroup.get(field.name).value"
                                >
                                    <app-button-generator
                                        [buttonGenInput]="deleteButtonGenInput"
                                        (buttonRef)="
                                            formGroup.get(field.name).reset();
                                            field.deleteValueCallback &&
                                                field.deleteValueCallback(
                                                    $event
                                                )
                                        "
                                    ></app-button-generator>
                                </ng-container>
                            </div>

                            <!-- Handling errors -->
                            <ng-container
                                *ngIf="
                                    formGroup.get(field.name).touched &&
                                    formGroup.get(field.name).errors
                                "
                            >
                                <mat-error>
                                    {{
                                        field.validations
                                            | formErrorValidation
                                                : formGroup.get(field.name)
                                                      .errors
                                    }}
                                </mat-error>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <!-- CALLBACK_BUTTON END -->

                <!-- IMAGE_UPLOAD START -->
                <ng-container
                    *ngIf="
                        field.fieldType === FilterType.IMAGE_UPLOAD &&
                        field.imageUpload &&
                        !hiddenField.get(field.name)
                    "
                >
                    <ng-container *ngIf="!field.groupByKey">
                        <app-image-upload-field
                            *ngIf="formGroup"
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [openImageUploadToModal]="
                                openImageUploadToModal.bind(this)
                            "
                        ></app-image-upload-field>
                    </ng-container>
                    <ng-container *ngIf="field.groupByKey">
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-image-upload-field
                                *ngIf="formGroup"
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [openImageUploadToModal]="
                                    openImageUploadToModal.bind(this)
                                "
                            ></app-image-upload-field>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- IMAGE_UPLOAD END -->

                <!-- TIME PICKER -->
                <ng-container *ngIf="field.fieldType === FilterType.TIME">
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-time-field
                            *ngIf="formGroup"
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [formControlName]="field.name"
                            [onTimePickerValueChanged]="
                                onTimePickerValueChanged.bind(this)
                            "
                        ></app-time-field>
                    </ng-container>
                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-time-field
                                *ngIf="formGroup"
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [formControlName]="field.name"
                                [onTimePickerValueChanged]="
                                    onTimePickerValueChanged.bind(this)
                                "
                            ></app-time-field>
                        </div>
                    </ng-container>
                </ng-container>

                <!-- TIME PICKER END -->

                <!-- DATE TIME PICKER START  -->

                <ng-container *ngIf="field.fieldType === FilterType.DATE_TIME">
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-date-time-field
                            *ngIf="formGroup"
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [formControlName]="field.name"
                            [timeChanged]="timeChanged.bind(this)"
                            [dateChanged]="dateChanged.bind(this)"
                        ></app-date-time-field>
                    </ng-container>
                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-date-time-field
                                *ngIf="formGroup"
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [formControlName]="field.name"
                                [timeChanged]="timeChanged.bind(this)"
                                [dateChanged]="dateChanged.bind(this)"
                            ></app-date-time-field>
                        </div>
                    </ng-container>
                </ng-container>

                <!-- DATE TIME PICKER END  -->

                <!-- DOUBLE RANGE FIELD START -->

                <ng-container
                    *ngIf="field.fieldType === FilterType.DOUBLE_RANGE"
                >
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-double-range-field
                            [field]="field"
                            [formControlName]="field.name"
                            [formGroup]="formGroup"
                        ></app-double-range-field>
                    </ng-container>

                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-double-range-field
                                [field]="field"
                                [formControlName]="field.name"
                                [formGroup]="formGroup"
                            ></app-double-range-field>
                        </div>
                    </ng-container>
                </ng-container>

                <!-- DOUBLE RANGE FIELD END -->

                <!-- DATE RANGE FIELD START -->
                <ng-container
                    *ngIf="
                        field.fieldType === FilterType.DATE_RANGE ||
                        field.fieldType === FilterType.DATE_RANGE_TODAY ||
                        field.fieldType === FilterType.WIDGET_DATE_RANGE
                    "
                >
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-date-range-field
                            [field]="field"
                            [formControlName]="field.name"
                            [selectedValue]="
                                field.id
                                    | getFilterSelectedValue
                                        : filterService
                                        : filterStoreKey
                                        : filterCacheService
                                        : userCacheService
                                        : configCache
                            "
                            [minDateValue]="
                                filterService.currentPageFilterInfo.has(
                                    field.id
                                ) &&
                                filterService.currentPageFilterInfo.get(
                                    field.id
                                ) &&
                                filterService.currentPageFilterInfo.get(
                                    field.id
                                ).dateRange &&
                                filterService.currentPageFilterInfo.get(
                                    field.id
                                ).dateRange.minDate
                                    ? filterService.currentPageFilterInfo.get(
                                          field.id
                                      ).dateRange.minDate
                                    : {}
                            "
                            [maxDateValue]="
                                filterService.currentPageFilterInfo.has(
                                    field.id
                                ) &&
                                filterService.currentPageFilterInfo.get(
                                    field.id
                                ) &&
                                filterService.currentPageFilterInfo.get(
                                    field.id
                                ).dateRange &&
                                filterService.currentPageFilterInfo.get(
                                    field.id
                                ).dateRange.maxDate
                                    ? filterService.currentPageFilterInfo.get(
                                          field.id
                                      ).dateRange.maxDate
                                    : {}
                            "
                        ></app-date-range-field>
                    </ng-container>

                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-date-range-field
                                [field]="field"
                                [formControlName]="field.name"
                                [selectedValue]="
                                    field.id
                                        | getFilterSelectedValue
                                            : filterService
                                            : filterStoreKey
                                            : filterCacheService
                                            : userCacheService
                                            : configCache
                                "
                                [minDateValue]="
                                    filterService.currentPageFilterInfo.has(
                                        field.id
                                    ) &&
                                    filterService.currentPageFilterInfo.get(
                                        field.id
                                    ) &&
                                    filterService.currentPageFilterInfo.get(
                                        field.id
                                    ).dateRange &&
                                    filterService.currentPageFilterInfo.get(
                                        field.id
                                    ).dateRange.minDate
                                        ? filterService.currentPageFilterInfo.get(
                                              field.id
                                          ).dateRange.minDate
                                        : {}
                                "
                                [maxDateValue]="
                                    filterService.currentPageFilterInfo.has(
                                        field.id
                                    ) &&
                                    filterService.currentPageFilterInfo.get(
                                        field.id
                                    ) &&
                                    filterService.currentPageFilterInfo.get(
                                        field.id
                                    ).dateRange &&
                                    filterService.currentPageFilterInfo.get(
                                        field.id
                                    ).dateRange.maxDate
                                        ? filterService.currentPageFilterInfo.get(
                                              field.id
                                          ).dateRange.maxDate
                                        : {}
                                "
                            ></app-date-range-field>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- DATE RANGE FIELD END -->
                <div
                    class="force-disable"
                    *ngIf="field.disableWithValidation"
                ></div>
                <ng-template #singleSelectDropDown>
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-single-dropdown-field
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [loading]="loading"
                            (valueChange)="dropdownValuesChanged($event, field)"
                            (selectAllValue)="onSelectAll($event, field)"
                            (onScroll)="onScroll($event, field)"
                            [compareWith]="compareWith"
                            [formControlName]="field.name"
                        ></app-single-dropdown-field>
                    </ng-container>
                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-single-dropdown-field
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [loading]="loading"
                                (valueChange)="
                                    dropdownValuesChanged($event, field)
                                "
                                (selectAllValue)="onSelectAll($event, field)"
                                (onScroll)="onScroll($event, field)"
                                [compareWith]="compareWith"
                                [formControlName]="field.name"
                            ></app-single-dropdown-field>
                        </div>
                    </ng-container>
                </ng-template>
                <ng-template #groupSingleDropDown>
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-single-group-dropdown-field
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [loading]="loading"
                            (valueChange)="dropdownValuesChanged($event, field)"
                            [compareWith]="compareWith"
                            [formControlName]="field.name"
                        ></app-single-group-dropdown-field>
                    </ng-container>
                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-single-group-dropdown-field
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [loading]="loading"
                                (valueChange)="
                                    dropdownValuesChanged($event, field)
                                "
                                [compareWith]="compareWith"
                                [formControlName]="field.name"
                            ></app-single-group-dropdown-field>
                        </div>
                    </ng-container>
                </ng-template>

                <ng-template #text>
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-text-field
                            *ngIf="formGroup"
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [formControlName]="field.name"
                        ></app-text-field>
                    </ng-container>

                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-text-field
                                *ngIf="formGroup"
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [formControlName]="field.name"
                            ></app-text-field>
                        </div>
                    </ng-container>
                </ng-template>
                <ng-template #multiSelectDropDown let-field="formField">
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-multi-dropdown-field
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [loading]="loading"
                            [compareWith]="compareWith"
                            (valueChange)="dropdownValuesChanged($event, field)"
                            (selectAllValue)="onSelectAll($event, field)"
                            (getFilteredData)="getFiltredData($event)"
                            (onScroll)="onScroll($event, field)"
                            [formControlName]="field.name"
                            (onChecked)="
                                checkboxWithDropdownToggled(
                                    $event,
                                    field.id,
                                    field
                                )
                            "
                            [filterStoreKey]="filterStoreKey"
                        ></app-multi-dropdown-field>
                    </ng-container>
                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-multi-dropdown-field
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [loading]="loading"
                                [compareWith]="compareWith"
                                (valueChange)="
                                    dropdownValuesChanged($event, field)
                                "
                                (selectAllValue)="onSelectAll($event, field)"
                                (getFilteredData)="getFiltredData($event)"
                                (onScroll)="onScroll($event, field)"
                                [formControlName]="field.name"
                                (onChecked)="
                                    checkboxWithDropdownToggled(
                                        $event,
                                        field.id,
                                        field
                                    )
                                "
                                [filterStoreKey]="filterStoreKey"
                            ></app-multi-dropdown-field>
                        </div>
                    </ng-container>
                </ng-template>
                <!-- NG Template for Multi Dropdown and Input -->
                <ng-template #multiSelectDropdownWithInput let-field="formField">
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-multi-dropdown-with-input
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [loading]="loading"
                            [compareWith]="compareWith"
                            (valueChange)="dropdownValuesChanged($event, field)"
                            (selectAllValue)="onSelectAll($event, field)"
                            (getFilteredData)="getFiltredData($event)"
                            (onScroll)="onScroll($event, field)"
                            [formControlName]="field.name"
                            (onChecked)="
                                checkboxWithDropdownToggled(
                                    $event,
                                    field.id,
                                    field
                                )
                            "
                            [filterStoreKey]="filterStoreKey"
                        ></app-multi-dropdown-with-input>
                    </ng-container>
                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-multi-dropdown-field
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [loading]="loading"
                                [compareWith]="compareWith"
                                (valueChange)="
                                    dropdownValuesChanged($event, field)
                                "
                                (selectAllValue)="onSelectAll($event, field)"
                                (getFilteredData)="getFiltredData($event)"
                                (onScroll)="onScroll($event, field)"
                                [formControlName]="field.name"
                                (onChecked)="
                                    checkboxWithDropdownToggled(
                                        $event,
                                        field.id,
                                        field
                                    )
                                "
                                [filterStoreKey]="filterStoreKey"
                            ></app-multi-dropdown-field>
                        </div>
                    </ng-container>
                </ng-template>
                <ng-template #groupMultiSelectDropDown>
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-multi-group-dropdown-field
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [loading]="loading"
                            [compareWith]="compareWith"
                            (valueChange)="dropdownValuesChanged($event, field)"
                            (selectAllValue)="onSelectAll($event, field)"
                            [formControlName]="field.name"
                        ></app-multi-group-dropdown-field>
                    </ng-container>
                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-multi-group-dropdown-field
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [loading]="loading"
                                [compareWith]="compareWith"
                                (valueChange)="
                                    dropdownValuesChanged($event, field)
                                "
                                (selectAllValue)="onSelectAll($event, field)"
                                [formControlName]="field.name"
                            ></app-multi-group-dropdown-field>
                        </div>
                    </ng-container>
                </ng-template>
                <ng-template #listObjectDropdown>
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-list-object-dropdown-field
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [loading]="loading"
                            [compareWith]="compareWith"
                            (valueChange)="dropdownValuesChanged($event, field)"
                            [formControlName]="field.name"
                        ></app-list-object-dropdown-field>
                    </ng-container>
                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-list-object-dropdown-field
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [loading]="loading"
                                [compareWith]="compareWith"
                                (valueChange)="
                                    dropdownValuesChanged($event, field)
                                "
                                [formControlName]="field.name"
                            ></app-list-object-dropdown-field>
                        </div>
                    </ng-container>
                </ng-template>

                <!-- CONTENT FIELD TEMPLATE STARTS -->
                <ng-template #contentField let-field="formField">
                    <ng-container
                        *ngIf="
                            !hiddenField.get(field.name) && !field.groupByKey
                        "
                    >
                        <app-content-field
                            *ngIf="formGroup"
                            [field]="field"
                            [formGeneratorInput]="formGeneratorInput"
                            [formGroup]="formGroup"
                            [formControlName]="field.name"
                        ></app-content-field>
                    </ng-container>
                    <ng-container
                        *ngIf="!hiddenField.get(field.name) && field.groupByKey"
                    >
                        <div
                            class="form-group"
                            [formGroupName]="field.groupByKey"
                        >
                            <app-content-field
                                *ngIf="formGroup"
                                [field]="field"
                                [formGeneratorInput]="formGeneratorInput"
                                [formGroup]="formGroup"
                                [formControlName]="field.name"
                            ></app-content-field>
                        </div>
                    </ng-container>
                </ng-template>
                <!-- CONTENT FIELD TEMPLATE ENDS -->

                <!-- HIDDEN FIELD -->
                <ng-container *ngIf="field.fieldType === FilterType.HIDDEN">
                    <input
                        type="hidden"
                        [value]="field.value ? field.value : ''"
                        [formControlName]="field.name"
                    />
                </ng-container>
                <!-- HIDDEN FIELD END -->
            </div>
        </ng-container>
    </div>
    <ng-container *ngIf="formGeneratorInput.warningMessage">
        <p
            class="tw-flex tw-justify-center tw-flex-1 tw-items-end tw-font-semibold tw-text-fs-400 tw-text-negative"
            [innerHTML]="formGeneratorInput.warningMessage"
        ></p>
    </ng-container>
    <div
        class="form-controls"
        [class.space-between]="formGeneratorInput.backButton"
        *ngIf="
            formGeneratorInput.submitButton &&
            !formGeneratorInput.submitButton.hide &&
            formGeneratorInput.state !== FormState.IDLE
        "
    >
        <ng-container
            *ngIf="
                formGeneratorInput.backButton &&
                !formGeneratorInput.backButton.hide
            "
        >
            <app-button-generator
                (buttonRef)="
                    formGeneratorInput.backButton.function &&
                        formGeneratorInput.backButton.function(
                            $event,
                            formGroup,
                            modalId
                        )
                "
                [buttonGenInput]="formGeneratorInput.backButton"
            >
            </app-button-generator>
        </ng-container>
        <app-button-generator
            (buttonRef)="submitForm($event)"
            [buttonGenInput]="formGeneratorInput.submitButton"
        >
        </app-button-generator>
    </div>
</form>

<div class="dropdown-background-layer" *ngIf="showOverlay"></div>
