import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { CaCreateTicketModalComponent } from 'src/app/shared/components/modal-templates/integration/ca-sdm-modal/ca-sdm-create-ticket-modal/ca-create-ticket-modal/ca-create-ticket-modal.component';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FileType } from 'src/app/shared/enums/FileType';
import { IconType } from 'src/app/shared/enums/IconType';
import { IntegrationName } from 'src/app/shared/enums/IntegrationName';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { CredentialsCacheService } from 'src/app/shared/services/cache/credentials-cache/credentials-cache.service';
import { IframeOpenerModalComponent } from '../../../modal-templates/iframe-opener-modal/iframe-opener-modal.component';
import { FreshserviceCreateTicketComponent } from '../../../modal-templates/integration/freshservice-modal/freshservice-create-ticket/freshservice-create-ticket.component';
import { SalesforceIntegrationCaseFormComponent } from '../../../modal-templates/integration/salesforce-modal/salesforce-integration-case-form/salesforce-integration-case-form.component';
import { ServicenowTestingModalComponent } from '../../../modal-templates/integration/servicenow-testing-modal/servicenow-testing-modal.component';
import { ServiceCatalogConfigurationComponent } from '../../../modal-templates/service-catalog/service-catalog-configuration/service-catalog-configuration.component';
import { ServiceNowVariablesComponent } from '../../../modal-templates/service-catalog/service-now-variables/service-now-variables.component';
import { Helper } from './../../../../classes/Helper';
import { HitApi } from './../../../../classes/HitApi';
import { AuthorizationType } from './../../../../enums/AuthorizationType';
import { RequestType } from './../../../../enums/RequestType';
import { IHitApi } from './../../../../interfaces/hit-api/IHitApi';
import { IButtonData } from './../../../../interfaces/table-generator/IButtonData';
import { AutotaskCreateTicketModalComponent } from './../../../modal-templates/integration/autotask-modal/autotask-create-ticket-modal/autotask-create-ticket-modal.component';
import { OtrsCreateTicketModalComponent } from './../../../modal-templates/integration/otrs-modal/otrs-create-ticket-modal/otrs-create-ticket-modal.component';
import { ZendeskCreateTicketComponent } from './../../../modal-templates/integration/zendesk-modal/zendesk-create-ticket/zendesk-create-ticket.component';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-service-catalog',
    templateUrl: './service-catalog.component.html',
    styleUrls: ['./service-catalog.component.sass']
})
export class ServiceCatalogComponent implements OnInit {
    widgetRef: Widget;
    tableInput: ITableGeneratorInput;
    masterLoginIndex = null;
    configuredIntegrationType = '';
    isPartner = false;
    tableDataMap = {};
    tableList = [];
    configuredColumnData;
    createReqModalId: symbol;
    integratedItsms = [];

    clientIdList;
    response;
    counter: number;
    integrations = [
        IntegrationName.SERVICENOW,
        IntegrationName.AUTOTASK,
        IntegrationName.OTRS,
        IntegrationName.CASDM,
        IntegrationName.ZOHO,
        IntegrationName.FRESHSERVICE,
        IntegrationName.ZENDESK,
        IntegrationName.SALESFORCE
    ];
    integrationDataFetched = false;

    constructor(
        widgetData: WidgetInjectedData,
        private credentialsCacheService: CredentialsCacheService,
        private changeDetector: ChangeDetectorRef
    ) {
        this.counter = 0;
        this.widgetRef = widgetData.widgetRef;
        this.createReqModalId = Symbol();

        this.getConfiguredColumns(true);
        this.tableInput = {
            afterResponse: this.setTableColumnsAndBtns.bind(this),
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-user-plus'
            },
            buttons: [],
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'dataList'
            },
            columns: []
        };
        Helper.getIntegratedItsms(
            this.integrations,
            this.widgetRef.httpService,
            this.widgetRef.ngZone,
            this.setIntegratedItsms.bind(this)
        );
    }

    ngOnInit(): void {}

    setIntegratedItsms(integratedItsms) {
        this.counter++;
        if (this.counter === this.integrations.length) {
            this.integrationDataFetched = true;
            this.setTableBtns();
            this.changeDetector.detectChanges();
        }
        const itsms = [];

        integratedItsms.forEach((ele) => {
            itsms.push(ele.key);
            this.response = integratedItsms.map((ele) => {
                return { key: ele.key, value: ele.response };
            });
        });
        this.integratedItsms = itsms;
    }

    setTableColumnsAndBtns(response) {
        // set Buttons
        this.tableDataMap = response['dataMap'];
        this.tableList = response['dataList'];
        if (
            response['partner'] ||
            (this.credentialsCacheService.masterLoginInfo &&
                this.credentialsCacheService.masterLoginInfo.length > 1)
        ) {
            this.isPartner = true;
            this.setTableBtns();
        }

        // set columns
        if (!response['dataList'].length) {
            return;
        }
        this.tableInput.columns = [];
        response['dataMap']['columns'].forEach((element) => {
            this.tableInput.columns.push({
                columnName: element,
                columnKey: element
            });
        });
        this.tableInput.columns.push({
            columnName: 'Request To Buy',
            columnKey: 'requestToBuy',
            componentFramework: MultiButtonGeneratorComponent,
            buttonGen: true,
            valueFormatter: (rowData) => {
                rowData['buttonGenInputs'] = [
                    {
                        buttonName: '',
                        buttonIcon: {
                            type: IconType.FONTAWSOME,
                            class: 'fas fa-shopping-bag'
                        },
                        buttonType: ButtonType.ICON,
                        customClass: `req-buy-button req-buy-button-${rowData.rowIndex}`,
                        buttonColorType: ButtonColorType.PRIMARY,

                        hoverText: !this.integrationDataFetched
                            ? 'Please wait while we get the required information'
                            : this.integratedItsms.length &&
                              this.integratedItsms.includes(
                                  IntegrationName.SERVICENOW
                              )
                            ? 'Request To Buy'
                            : 'Create Request not configured',
                        showLoader: true,
                        disable:
                            !this.integratedItsms ||
                            !this.integratedItsms.includes(
                                IntegrationName.SERVICENOW
                            ),
                        function: (buttonRef: IButtonData) => {
                            document
                                .querySelector(`.req-buy-button`)
                                .classList.add('disabled');
                            buttonRef.loader = true;

                            const apiArgs: IHitApi = {
                                url: `/srm-integrations/servicenow-integration/get-item-variables`,
                                requestType: RequestType.POST,
                                input: {
                                    tableRowData: rowData.data
                                },
                                uniqueIdentity: Symbol(),
                                config: {
                                    authorization:
                                        AuthorizationType.BEARER_TOKEN
                                },
                                function: (response) => {
                                    this.variablesRes(rowData, response);
                                },
                                errorFunction: (error) => {
                                    this.widgetRef.notificationsService.showSnackBar(
                                        error.error.message,
                                        true
                                    );
                                },
                                endFunction: () => {
                                    buttonRef.loader = false;
                                    this.widgetRef.changeDetectorRef.detectChanges();
                                    document
                                        .querySelector(`.req-buy-button`)
                                        .classList.remove('disabled');
                                }
                            };
                            new HitApi(
                                apiArgs,
                                this.widgetRef.httpService,
                                this.widgetRef.ngZone
                            ).hitApi();
                        }
                    }
                ];

                return rowData;
            }
        });
        this.tableInput.columns.push({
            columnName: 'Create Incident',
            columnKey: 'createIncident',
            buttonGen: true,

            componentFramework: MultiButtonGeneratorComponent,
            valueFormatter: (rowData) => {
                rowData['buttonGenInputs'] = [
                    {
                        buttonName: '',
                        buttonIcon: {
                            type: IconType.FONTAWSOME,
                            class: 'fas fa-ticket-alt'
                        },
                        buttonType: ButtonType.ICON,

                        buttonColorType: ButtonColorType.PRIMARY,
                        showLoader: true,
                        function: (buttonRef: IButtonData) => {
                            this.createIncident(rowData);
                        },
                        hoverText: !this.integrationDataFetched
                            ? 'Please wait while we get the required information'
                            : !this.integratedItsms.length
                            ? 'Create Incident not configured'
                            : 'Create Incident',
                        disable: !this.integratedItsms.length
                    }
                ];

                return rowData;
            }
        });
    }

    setTableBtns() {
        this.tableInput.buttons = [
            {
                buttonName: 'Upload',
                buttonIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-file-upload'
                },
                fileType: [FileType.EXCEL],
                function: (event) => {
                    this.uploadExcel(event);
                },
                buttonType: ButtonType.FILE,
                buttonColorType: ButtonColorType.PRIMARY,
                loader: false
            },
            {
                buttonName: 'Configure',
                buttonIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-cog'
                },
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                function: (buttonRef) => {
                    this.setConfiguration();
                },
                hide: !this.tableList.length,
                disable: !this.integrationDataFetched ? true : false,
                hoverText: !this.integrationDataFetched
                    ? 'Please wait while we get the required information'
                    : ''
            }
        ];
    }

    createIncident(rowData) {
        let integrationResponse;
        let modalArgs: IModalData;
        if (this.configuredIntegrationType === IntegrationName.SERVICENOW) {
            modalArgs = {
                modalName: 'Servicenow',
                sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
                modalType: ModalType.MIDDLE,
                modalIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-ticket-alt'
                },
                modalSteps: [
                    {
                        stepName: 'Servicenow Create Incident',
                        stepData: {
                            componentToLoad: ServicenowTestingModalComponent,
                            payload: {
                                data: {
                                    tableRowData: rowData.data,
                                    configuredColumnData:
                                        this.configuredColumnData,
                                    isPartner: this.isPartner
                                }
                            }
                        }
                    }
                ],
                modalWidthVw: 60,
                modalHeightVh: 60
            };
        }
        if (this.configuredIntegrationType === IntegrationName.OTRS) {
            this.response.forEach((element) => {
                if (element.key === IntegrationName.OTRS) {
                    integrationResponse = element.value;
                }
            });

            modalArgs = {
                modalName: 'Create Ticket',
                sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
                modalType: ModalType.MIDDLE,
                modalIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-ticket-alt'
                },
                modalSteps: [
                    {
                        stepName: 'OTRS Create Incident',
                        stepData: {
                            componentToLoad: OtrsCreateTicketModalComponent,
                            payload: {
                                data: {
                                    response: integrationResponse,
                                    tableRowData: rowData.data,
                                    configuredColumnData:
                                        this.configuredColumnData,
                                    isPartner: this.isPartner,
                                    widgetRef: this.widgetRef
                                }
                            }
                        }
                    }
                ],
                modalWidthVw: 60,
                modalHeightVh: 60
            };
        }
        if (this.configuredIntegrationType === IntegrationName.AUTOTASK) {
            this.response.forEach((element) => {
                if (element.key === IntegrationName.AUTOTASK) {
                    integrationResponse = element.value;
                }
            });

            modalArgs = {
                modalName: 'Create Ticket',
                sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
                modalType: ModalType.MIDDLE,
                modalIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-ticket-alt'
                },
                modalSteps: [
                    {
                        stepName: 'Autotask Create Incident',
                        stepData: {
                            componentToLoad: AutotaskCreateTicketModalComponent,
                            payload: {
                                data: {
                                    autotaskStatusResponse: integrationResponse,
                                    tableRowData: rowData.data,
                                    configuredColumnData:
                                        this.configuredColumnData,
                                    widgetRef: this.widgetRef,
                                    isPartner: this.isPartner
                                }
                            }
                        }
                    }
                ],
                modalWidthVw: 60,
                modalHeightVh: 60
            };
        }
        if (this.configuredIntegrationType === IntegrationName.CASDM) {
            this.response.forEach((element) => {
                if (element.key === IntegrationName.CASDM) {
                    integrationResponse = element.value;
                }
            });
            modalArgs = {
                modalName: 'Create Ticket',
                sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
                modalType: ModalType.MIDDLE,
                modalIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-ticket-alt'
                },
                modalSteps: [
                    {
                        stepName: 'Autotask Create Incident',
                        stepData: {
                            componentToLoad: CaCreateTicketModalComponent,
                            payload: {
                                data: {
                                    response: integrationResponse,
                                    widgetRef: this.widgetRef,
                                    tableRowData: rowData.data,
                                    configuredColumnData:
                                        this.configuredColumnData,
                                    isPartner: this.isPartner
                                }
                            }
                        }
                    }
                ],
                modalWidthVw: 60,
                modalHeightVh: 60
            };
        }
        if (this.configuredIntegrationType === IntegrationName.ZOHO) {
            this.response.forEach((element) => {
                if (element.key === IntegrationName.ZOHO) {
                    integrationResponse = element.value['self']['widgetCode'];
                }
            });
            modalArgs = {
                modalName: 'Create Ticket',
                sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
                modalType: ModalType.MIDDLE,
                modalIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-ticket-alt'
                },
                modalSteps: [
                    {
                        stepName: 'Create Ticket',
                        stepData: {
                            componentToLoad: IframeOpenerModalComponent,
                            payload: {
                                data: {
                                    widgetCode: integrationResponse,
                                    isHtml: true,
                                    tableRowData: rowData.data,
                                    configuredColumnData:
                                        this.configuredColumnData,
                                    isPartner: this.isPartner
                                }
                            }
                        }
                    }
                ],
                modalWidthVw: 60,
                modalHeightVh: 60
            };
        }
        if (this.configuredIntegrationType === IntegrationName.FRESHSERVICE) {
            this.response.forEach((element) => {
                if (element.key === IntegrationName.FRESHSERVICE) {
                    integrationResponse = element.value;
                }
            });

            modalArgs = {
                modalName: 'Fresh Service Create Ticket',
                sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
                modalType: ModalType.MIDDLE,
                modalIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-ticket-alt'
                },
                modalSteps: [
                    {
                        stepName: 'Fresh Service Create Incident',
                        stepData: {
                            componentToLoad: FreshserviceCreateTicketComponent,
                            payload: {
                                data: {
                                    freshserviceStatusResponse:
                                        integrationResponse,
                                    isServiceCatalog: true,
                                    tableRowData: rowData.data,
                                    configuredColumnData:
                                        this.configuredColumnData,
                                    isPartner: this.isPartner,
                                    widgetRef: this.widgetRef
                                }
                            }
                        }
                    }
                ],
                modalWidthVw: 60,
                modalHeightVh: 90
            };
        }
        if (this.configuredIntegrationType === IntegrationName.ZENDESK) {
            this.response.forEach((element) => {
                if (element.key === IntegrationName.ZENDESK) {
                    integrationResponse = element.value;
                }
            });

            modalArgs = {
                modalName: 'Zendesk Create Ticket',
                sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
                modalType: ModalType.MIDDLE,
                modalIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-ticket-alt'
                },
                modalSteps: [
                    {
                        stepName: 'Zendesk Create Incident',
                        stepData: {
                            componentToLoad: ZendeskCreateTicketComponent,
                            payload: {
                                data: {
                                    tableRowData: rowData.data,
                                    configuredColumnData:
                                        this.configuredColumnData,
                                    widgetRef: this.widgetRef
                                }
                            }
                        }
                    }
                ],
                modalWidthVw: 60,
                modalHeightVh: 90
            };
        }
        if (this.configuredIntegrationType === IntegrationName.SALESFORCE) {
            this.response.forEach((ele) => {
                if (ele.key === IntegrationName.SALESFORCE) {
                    integrationResponse = ele.value;
                }
            });

            if (
                this.configuredColumnData &&
                integrationResponse &&
                integrationResponse.configResponses
            ) {
                let formFields = null;
                integrationResponse.configResponses.forEach(
                    (configResponse) => {
                        if (
                            configResponse &&
                            configResponse.id === this.configuredColumnData.id
                        ) {
                            if (
                                configResponse.step2 &&
                                configResponse.step2.fields
                            ) {
                                formFields = Helper.cloneDeep(
                                    configResponse.step2.fields
                                );
                            }
                        }
                    }
                );
                if (formFields) {
                    formFields.forEach((formField) => {
                        if (this.configuredColumnData[formField.name]) {
                            formField.value =
                                rowData.data[
                                    this.configuredColumnData[formField.name]
                                ];
                            formField.disabled = true;
                        }
                    });

                    modalArgs = {
                        modalName: 'Salesforce Create Ticket',
                        sourceId:
                            this.widgetRef.widgetData.widgetUniqueIdentifier,
                        modalType: ModalType.MIDDLE,
                        modalIcon: {
                            type: IconType.FONTAWSOME,
                            class: 'fas fa-ticket-alt'
                        },
                        modalSteps: [
                            {
                                stepName: 'Salesforce Create Incident',
                                stepData: {
                                    componentToLoad:
                                        SalesforceIntegrationCaseFormComponent,
                                    payload: {
                                        data: {
                                            isCreate: true,
                                            configId:
                                                this.configuredColumnData.id,
                                            formFields: formFields,
                                            widgetRef: this.widgetRef
                                        }
                                    }
                                }
                            }
                        ],
                        modalWidthVw: 60,
                        modalAutoHeight: true,
                        maxHeightVh: 90
                    };
                }
            }
        }
        this.widgetRef.modalService.openModal(modalArgs);
    }
    variablesRes(rowData, response) {
        if (response['dataList'] && response['dataList'].length === 0) {
            this.createRequest(rowData.data, {});
        } else {
            const modalArgs = {
                modalName: 'Create Request',
                sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
                modalId: this.createReqModalId,
                modalType: ModalType.MIDDLE,
                modalIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-shopping-bag'
                },
                modalSteps: [
                    {
                        stepName: '',
                        stepData: {
                            componentToLoad: ServiceNowVariablesComponent,
                            payload: {
                                data: {
                                    tableRowData: rowData.data,
                                    variableList: response['dataList'],
                                    submitFuntion: this.createRequest.bind(this)
                                }
                            }
                        }
                    }
                ],
                modalWidthVw: 60,
                modalHeightVh: 78
            };
            this.widgetRef.modalService.openModal(modalArgs);
        }
    }

    createRequest(tableRowData, variables) {
        if (this.configuredIntegrationType === IntegrationName.SERVICENOW) {
            const apiArgs: IHitApi = {
                url: ApiUrls.SERVICENOW_CREATE_REQUEST,
                requestType: RequestType.POST,
                input: {
                    tableRowData: tableRowData,
                    variables: variables
                },
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                },
                function: (response) => {
                    this.widgetRef.modalService.closeModal(
                        null,
                        this.createReqModalId
                    );
                    this.widgetRef.notificationsService.showSnackBar(
                        'Request submitted successfully'
                    );
                },
                errorFunction: (error) => {
                    this.widgetRef.modalService.closeModal(
                        null,
                        this.createReqModalId
                    );
                    this.widgetRef.notificationsService.showSnackBar(
                        'Error submitting request',
                        true
                    );
                }
            };
            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.httpService.ngZone
            ).hitApi();
        }
    }

    uploadExcel(event) {
        if (
            event.target &&
            event.target.files &&
            event.target.files.length > 1
        ) {
            this.widgetRef.notificationsService.showSnackBar(
                'You can select maximum 1 file',
                true
            );
            return;
        }

        // Generating presigned url
        const apiArgs: IHitApi = {
            url: `srm-integrations/service-catalog/generate-presigned-url`,
            requestType: RequestType.GET,
            input: {},
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: async (response) => {
                const uploadFileApiArgs: IHitApi = {
                    url: response['dataMap']['preSignedURL'],
                    requestType: RequestType.PUT,
                    input: event.target.files[0],
                    uniqueIdentity: Symbol(),
                    config: {
                        authorization: AuthorizationType.NOT_AUTHORIZED,
                        ignoreBaseUrl: true
                    },
                    function: (response) => {
                        this.configuredColumnData = null;
                        this.getConfiguredColumns();
                        this.widgetRef.notificationsService.showSnackBar(
                            'File uploaded successfully'
                        );
                        this.widgetRef.refreshWidget();
                    },
                    errorFunction: (error) => {
                        this.widgetRef.notificationsService.showSnackBar(
                            'Error uploading file',
                            true
                        );
                    }
                };
                new HitApi(
                    uploadFileApiArgs,
                    this.widgetRef.httpService,
                    this.widgetRef.httpService.ngZone
                ).hitApi();
            },
            errorFunction: (error) => {
                this.widgetRef.notificationsService.showSnackBar(
                    'Error uploading file',
                    true
                );
            }
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.httpService.ngZone
        ).hitApi();
    }

    setConfiguration() {
        const modalData: IModalData = {
            modalName: 'Set Configuration',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-chalkboard-teacher'
            },
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 90,
            modalWidthVw: 60,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ServiceCatalogConfigurationComponent,
                        payload: {
                            data: {
                                columns:
                                    this.tableDataMap['columns'] &&
                                    this.tableDataMap['columns'].length
                                        ? this.tableDataMap['columns']
                                        : [],
                                updateConfig:
                                    this.getConfiguredColumns.bind(this),
                                configuredColumnData: this.configuredColumnData,
                                integratedItsms: this.integratedItsms,
                                response: this.response,
                                widgetRef: this.widgetRef
                            }
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    async getConfiguredColumns(notRefresh?) {
        const apiArgs: IHitApi = {
            url: ApiUrls.GET_CONFIGURED_DATA,
            requestType: RequestType.GET,
            input: {},
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (response) => {
                if (
                    response['configuredColumn'] &&
                    Object.keys(response['configuredColumn']).length
                ) {
                    this.configuredColumnData = response['configuredColumn'];
                    this.configuredIntegrationType =
                        response['configuredColumn']['itsmToolName'];
                    // Reload table
                    if (!notRefresh) {
                        this.widgetRef.refreshWidget();
                    }
                }
            },
            errorFunction: (error) => {}
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.httpService.ngZone
        ).hitApi();
    }
}
