<div class="container">
    <mat-tab-group
        mat-stretch-tabs
        (selectedTabChange)="tabChanged($event)"
        [(selectedIndex)]="selectedTabIndex"
    >
        <mat-tab
            [label]="Tab.PARTNER_ACCOUNT"
            [disabled]="edit && selectedTabIndex === 1 ? true : false"
            [class.disabled]="edit && selectedTabIndex === 1 ? true : false"
        >
            <div
                class="tw-overflow-hidden first-form tw-flex tw-justify-center"
            >
                <div class="form-gen">
                    <app-form-generator
                        [formGeneratorInput]="partnerFormInput"
                        (formGroupRef)="partnerFormGroup = $event"
                        (resetFormFunction)="partnerResetForm = $event"
                    >
                    </app-form-generator>
                </div>
            </div>
        </mat-tab>
        <mat-tab
            [label]="Tab.SUBSCRIPTION_ACCOUNT"
            [disabled]="edit && !selectedTabIndex ? true : false"
            [class.disabled]="edit && !selectedTabIndex ? true : false"
        >
            <div
                class="second-form tw-overflow-hidden tw-flex tw-justify-center"
            >
                <div class="form-gen">
                    <app-form-generator
                        [formGeneratorInput]="subscriptionFormInput"
                        (formGroupRef)="subscriptionFormGroup = $event"
                        (resetFormFunction)="subscriptionResetForm = $event"
                        (updateControl)="updateControl = $event"
                        (valuesChange)="hitExportNameApi($event)"
                    >
                    </app-form-generator>
                </div>
            </div>
            <div class="billing-section">
                <span class="bill tw-font-semibold"> Billing Details</span>
                <hr class="tw-mt-3" />
                <div class="tab-bar">
                    <mat-tab-group
                        mat-stretch-tabs
                        [(selectedIndex)]="detailsTabIndex"
                        (selectedTabChange)="billingSectionTab($event)"
                    >
                        <mat-tab [label]="Tab.ONE_CLICK">
                            <div class="tw-flex tw-justify-center">
                                <div class="form-gen section-2">
                                    <app-form-generator
                                        *ngIf="singleFormInput"
                                        [formGeneratorInput]="singleFormInput"
                                        (formGroupRef)="
                                            singleFormGroup = $event
                                        "
                                        (resetFormFunction)="
                                            singleResetForm = $event
                                        "
                                        (updateControl)="
                                            singleFormUpdateControl = $event
                                        "
                                    >
                                    </app-form-generator>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab [label]="Tab.MANUALLY">
                            <div class="tw-flex tw-justify-center">
                                <div class="form-gen section-2">
                                    <app-form-generator
                                        *ngIf="manualDetailsFormInput"
                                        [formGeneratorInput]="
                                            manualDetailsFormInput
                                        "
                                        (formGroupRef)="
                                            manualFormGroup = $event
                                        "
                                        (resetFormFunction)="
                                            manualResetForm = $event
                                        "
                                    >
                                    </app-form-generator>
                                </div></div
                        ></mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
    <div class="action-btn">
        <app-multi-button-generator
            [buttonGenInputs]="buttonsInput"
            [options]="buttonOption"
        >
        </app-multi-button-generator>
    </div>
</div>
