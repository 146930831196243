<section class="servicenow-incidents-container">
    <app-form-generator
        *ngIf="formGenInput"
        [formGeneratorInput]="formGenInput"
        (formGroupRef)="formGroupRef = $event"
        [modalId]="modalInputData.modalId"
        (valuesChange)="valuesChange ? valuesChange($event) : null"
    >
    </app-form-generator>

    <div class="view-rule-container">
        <span class="view-rule" *ngIf="!isActive" (click)="toggleRules(true)"
            >View rules</span
        >
        <span class="view-rule" *ngIf="isActive" (click)="toggleRules(false)"
            >Close rules</span
        >
    </div>

    <div class="table-container" *ngIf="showIncidentsTable">
        <table>
            <thead>
                <th>Impact</th>
                <th>Urgency</th>
                <th>Priority</th>
            </thead>
            <tbody>
                <tr *ngFor="let incident of incidents">
                    <td>{{ incident.impact }}</td>
                    <td>{{ incident.urgency }}</td>
                    <td>{{ incident.priority }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <app-form-generator
        [formGeneratorInput]="priorityIncidentsFormInput"
        (formGroupRef)="incidentPriorityFormGroupRef = $event"
    >
    </app-form-generator>

    <div class="button-container">
        <app-button-generator [buttonGenInput]="submitGenInput">
        </app-button-generator>
    </div>
</section>
