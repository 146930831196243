import { Injectable, NgZone } from '@angular/core';
import { skip, take } from 'rxjs/operators';
import { Messages } from 'src/app/shared/classes/Messages';
import { IWidgetInfo } from 'src/app/shared/interfaces/widget/IWidgetInfo';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { CacheMain } from '../../classes/CacheMain';
import { Helper } from '../../classes/Helper';
import { IntegrationStates } from '../../classes/IntegrationStates';
import { AuthorizationType } from '../../enums/AuthorizationType';
import { RequestType } from '../../enums/RequestType';
import { IAuthorizeResponse } from '../../interfaces/api/portlets/IAuthorizeResponse';
import { FiltersService } from '../filters/filters.service';
import { HttpService } from '../http/http-main/http.service';
import { ApiUrls } from './../../../core/classes/ApiUrls';
import { HitApi } from './../../classes/HitApi';
import { CredentialsCacheService } from './../cache/credentials-cache/credentials-cache.service';
import { GlobalDataService } from './../global-data/global-data.service';
import { ViewHttpService } from './../http/view-http/view-http.service';
import { WidgetHttpService } from './../http/widget-http/widget-http.service';
import { SessionService } from './../session/session.service';

@Injectable({
    providedIn: 'root'
})

/*
 * For integrations only
 */
export class RedirectionHandleService extends CacheMain {
    private readonly WIDGET_ID_USED_FOR_REDIRECTION =
        'WIDGET_ID_USED_FOR_REDIRECTION';

    static readonly URL_TO_REDIRECT = 'URL_TO_REDIRECT';
    static readonly WIDGET_ID_TO_REDIRECT = 'WIDGET_ID_TO_REDIRECT';

    private redirectedWidgetData: IWidgetInfo = null;

    constructor(
        private widgetHttpService: WidgetHttpService,
        private viewHttpService: ViewHttpService,
        private filtersService: FiltersService,
        private notificationService: NotificationsService,
        private httpService: HttpService,
        private ngZone: NgZone,
        private sessionService: SessionService,
        private credentialsCacheService: CredentialsCacheService,
        private globalDataService: GlobalDataService,
        private userDataCacheService: UserDataCacheService
    ) {
        super(`REDIRECTION_CACHE`, true);
    }

    get redirectionUrl(): string {
        return `${window.location.origin}${window.location.pathname}`;
    }

    set widgetIdUsedForRedirection(value: string) {
        this.store(this.WIDGET_ID_USED_FOR_REDIRECTION, value);
    }

    get widgetIdUsedForRedirection() {
        return this.fetch(this.WIDGET_ID_USED_FOR_REDIRECTION);
    }

    set urlToRedirect(value: string) {
        this.store(RedirectionHandleService.URL_TO_REDIRECT, value);
    }

    get urlToRedirect() {
        return this.fetch(RedirectionHandleService.URL_TO_REDIRECT);
    }

    set widgetIdToRedirect(value: string) {
        this.store(RedirectionHandleService.WIDGET_ID_TO_REDIRECT, value);
    }

    get widgetIdToRedirect() {
        return this.fetch(RedirectionHandleService.WIDGET_ID_TO_REDIRECT);
    }

    /**
     *
     * @param redirectionSource Pass widget id from which redirection is occuring
     * @param url url to redirect to
     */
    redirectTo(redirectionSource: string, url: string) {
        this.widgetIdUsedForRedirection = redirectionSource;
        Helper.navigateTo(url, 'SAME_PAGE');
    }

    setRedirectedWidgetData(integrationCallback) {
        if (this.widgetIdUsedForRedirection) {
            const widgetDataObs = this.widgetHttpService.getWidgetInfo(
                this.widgetIdUsedForRedirection
            );
            if (widgetDataObs.value) {
                this.redirectedWidgetData = widgetDataObs.value;
                integrationCallback();
            } else {
                widgetDataObs
                    .pipe(skip(1), take(1))
                    .subscribe((widgetInfoRes) => {
                        this.redirectedWidgetData = widgetInfoRes;
                        integrationCallback();
                    });
            }
        }
    }

    handleRedirections() {
        if (window.location.href.includes('code')) {
            if (window.location.href.includes('error')) {
                this.notificationService.showSnackBar(
                    Messages.INTEGRATION_ERROR,
                    true
                );
                return;
            }
            const handleIntegrationCode = () => {
                if (
                    window.location.href.includes(IntegrationStates.GMAIL_STATE)
                ) {
                    const gmailTempCode = window.location.href
                        .split('code=')[1]
                        .split('&')[0];
                    this.handleGmailCode(gmailTempCode);
                } else if (
                    window.location.href.includes(IntegrationStates.SLACK_STATE)
                ) {
                    const slackTempCode = window.location.href
                        .split('code=')[1]
                        .split('&')[0];

                    this.handleSlackCode(slackTempCode);
                } else if (
                    window.location.href.includes(IntegrationStates.O365_STATE)
                ) {
                    const o365TempCode = window.location.href
                        .split('code=')[1]
                        .split('&')[0];

                    this.handleO365Code(o365TempCode);
                } else if (
                    window.location.href.includes(IntegrationStates.ZOHO_STATE)
                ) {
                    const zohoTempCode = window.location.href
                        .split('code=')[1]
                        .split('&')[0];

                    this.handleZohoCode(zohoTempCode, false);
                } else if (
                    window.location.href.includes(
                        IntegrationStates.ZOHO_STATE_UPDATE
                    )
                ) {
                    const zohoTempCode = window.location.href
                        .split('code=')[1]
                        .split('&')[0];

                    this.handleZohoCode(zohoTempCode, true);
                }
            };
            this.setRedirectedWidgetData(handleIntegrationCode);
        } else if (
            window.location.hash &&
            window.location.hash.includes('id_token=')
        ) {
            const hash = window.location.hash;
            const token = hash.split('&id_token=')[1].split('&token_type')[0];
            this.httpService.samlAuthorizationToken = token;
            this.handleSSOLogin(token);
        } else if (window.location.href.includes('assessment?')) {
            this.globalDataService.beginsAssessmentLogin = true;
            const creds = window.location.href.split('?')[1].split('&');
            const password = creds[0].split('=')[1];
            const domainId = creds[1].split('=')[1];
            const assesseeCreds = { domainId: domainId, password: password };
            this.globalDataService.assesseeCreds.next(assesseeCreds);
            setTimeout(() => {
                this.sessionService.logout(true);
                this.globalDataService.enteredUrlBeforeLogin = [];
            }, 0);
        }
    }

    handleGmailCode(gmailTempCode: string) {
        const apiArgs = Helper.generateHitApiConfig(
            this.redirectedWidgetData.create
        );
        apiArgs.input = {
            integerationCode: gmailTempCode,
            redirectUri: this.redirectionUrl
        };
        apiArgs.function = (response) => {
            this.notificationService.showSnackBar(Messages.GMAIL_INTEGRATED);
            this.refreshRedirectionInitiatorWidget();
        };
        apiArgs.errorFunction = (error) => {
            this.notificationService.showSnackBar(
                Messages.GMAIL_INTEGRATED_ERROR,
                true
            );
            this.refreshRedirectionInitiatorWidget();
        };
        new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
    }

    handleSlackCode(slackTempCode: string) {
        const apiArgs = Helper.generateHitApiConfig(
            this.redirectedWidgetData.create
        );
        apiArgs.input = {
            integrationCode: slackTempCode,
            redirectUri: this.redirectionUrl
        };
        apiArgs.function = (response) => {
            this.notificationService.showSnackBar(Messages.SLACK_INTEGRATED);
            this.refreshRedirectionInitiatorWidget();
        };
        apiArgs.errorFunction = (error) => {
            this.notificationService.showSnackBar(
                Messages.SLACK_INTEGRATED_ERROR,
                true
            );
            this.refreshRedirectionInitiatorWidget();
        };
        new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
    }

    handleO365Code(o365TempCode: string) {
        const apiArgs = Helper.generateHitApiConfig(
            this.redirectedWidgetData.create
        );
        apiArgs.input = {
            integerationCode: o365TempCode,
            redirectUri: this.redirectionUrl
        };
        apiArgs.function = (response) => {
            this.notificationService.showSnackBar(Messages.O365_INTEGRATED);
            this.refreshRedirectionInitiatorWidget();
        };
        apiArgs.errorFunction = (error) => {
            this.notificationService.showSnackBar(
                Messages.O365_INTEGRATED_ERROR,
                true
            );
            this.refreshRedirectionInitiatorWidget();
        };
        new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
    }

    handleZohoCode(zohoTempCode: string, update?: boolean) {
        const input = JSON.parse(localStorage.getItem('zohoFormData'));
        input['integrationCode'] = zohoTempCode;
        input['redirectUri'] = this.redirectionUrl;

        const apiArgs = Helper.generateHitApiConfig(
            this.redirectedWidgetData.create
        );
        apiArgs.input = input;
        apiArgs.function = (response) => {
            this.notificationService.showSnackBar(
                update ? Messages.ZOHO_UPDATED : Messages.ZOHO_INTEGRATED
            );
            this.refreshRedirectionInitiatorWidget();
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.notificationService,
                error,
                Messages.ZOHO_INTEGRATED_ERROR
            );
            this.refreshRedirectionInitiatorWidget();
        };
        new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
    }

    refreshRedirectionInitiatorWidget() {
        this.filtersService.refreshWidget.next(
            new Set([this.widgetIdUsedForRedirection])
        );
        this.deleteKey(this.WIDGET_ID_USED_FOR_REDIRECTION);
    }

    handleSSOLogin(token: string, input?) {
        if (!token) {
            return;
        }
        this.globalDataService.fullPageLoader.next(true);
        new HitApi(
            {
                url: ApiUrls.SSO_AUTHORIZE,
                config: {
                    authorization: AuthorizationType.NOT_AUTHORIZED,
                    defaultHeaders: {
                        samlauthorization: token
                    },
                    skipBypassRegion: true
                },
                input: input ? input : {},
                requestType: RequestType.POST,
                uniqueIdentity: Symbol(),
                function: (response: IAuthorizeResponse) => {
                    if (response.roles && response.roles.length) {
                        this.globalDataService.roles = response.roles;
                        this.globalDataService.roleBasedLogin(
                            response.roles[0],
                            token,
                            this.sessionService,
                            this.credentialsCacheService,
                            this.notificationService,
                            this.widgetHttpService,
                            this.viewHttpService,
                            this.handleSSOLogin.bind(this)
                        );
                    } else if (response.user && response.user.licenseAccepted) {
                        this.globalDataService.userLogin(
                            response.user,
                            this.sessionService,
                            this.credentialsCacheService,
                            this.notificationService,
                            this.viewHttpService
                        );
                    } else if (
                        response.user &&
                        !response.user.licenseAccepted
                    ) {
                        this.userDataCacheService.domainId = response.domainId;
                        this.globalDataService.ssoAcceptLicenseCallBackFn(
                            response.user,
                            this.handleSSOLogin.bind(this)
                        );
                        this.globalDataService.fullPageLoader.next(false);
                    } else {
                        this.sessionService.logout();
                        this.globalDataService.fullPageLoader.next(false);
                        Helper.showErrorMessage(
                            this.notificationService,
                            Messages.LOGIN_API_INVALID_RESPONSE
                        );
                    }
                },
                errorFunction: (error) => {
                    this.sessionService.logout();
                    this.globalDataService.fullPageLoader.next(false);
                    Helper.showErrorMessage(
                        this.notificationService,
                        error,
                        Messages.LOGIN_API_INVALID_RESPONSE
                    );
                }
            },
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }
}
