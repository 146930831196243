export enum ButtonType {
    TEXT = 'text',
    RAISED = 'raised',
    STROKED = 'stroked',
    ICON = 'icon',
    FLAT = 'flat',
    FILE = 'file',
    LINK = 'link',
    CIRCLE = 'circle'
}
