<form class="form">
    <div class="form-data">
        <app-form-generator
            *ngIf="provisioningFormGenInputs"
            [formGeneratorInput]="provisioningFormGenInputs"
            (formGroupRef)="provisioningFormGroup = $event"
            (valuesChange)="addVaultField($event)"
        ></app-form-generator>
        <ng-container
            *ngIf="
                provisioningFormGroup &&
                provisioningFormGroup.get('encryption') &&
                provisioningFormGroup.get('encryption').value
            "
        >
            <ng-container *ngTemplateOutlet="vault"> </ng-container>
        </ng-container>
        <ng-template #vault>
            <app-form-generator
                [formGeneratorInput]="vaultFormGenInputs"
                (formGroupRef)="vaultFormGroup = $event"
                (valuesChange)="addSecretKeyField($event)"
                (updateControl)="updateControl = $event"
            ></app-form-generator>
        </ng-template>

        <ng-container
            *ngIf="
                provisioningFormGroup &&
                provisioningFormGroup.get('encryption') &&
                provisioningFormGroup.get('encryption').value &&
                vaultFormGroup &&
                vaultFormGroup.get('vault') &&
                vaultFormGroup.get('vault').value
            "
        >
            <ng-container *ngTemplateOutlet="secretKey"> </ng-container>
        </ng-container>

        <ng-template #secretKey>
            <app-form-generator
                *ngIf="secretKeyFormGenInputs"
                [formGeneratorInput]="secretKeyFormGenInputs"
                (formGroupRef)="secretKeyFormGroup = $event"
                (updateControl)="updateControl = $event"
            ></app-form-generator>
        </ng-template>
        <section
            class="tw-py-1 tw-px-3 tw-rounded tw-mt-0 tw-mx-3 tw-mb-4 keyVal-fg"
        >
            <div class="tw-font-semibold tw-mb-3 heading">Tags</div>
            <div class="tw-max-h-96 tw-overflow-y-auto tw-py-3 tw-px-0">
                <ng-container
                    *ngFor="
                        let keyValuePair of keyValuePairFormGenInputs;
                        let i = index;
                        let last = last
                    "
                >
                    <div class="tw-flex tw-items-start">
                        <app-form-generator
                            class="tw-flex tw-flex-col tw-flex-1"
                            [formGeneratorInput]="keyValuePair"
                            [intitalFormGroup]="
                                keyValuePairFormGroup.has(keyValuePair.formId)
                                    ? keyValuePairFormGroup.get(
                                          keyValuePair.formId
                                      )
                                    : null
                            "
                            (formGroupRef)="
                                keyValuePairFormGroup.set(
                                    keyValuePair.formId,
                                    $event
                                )
                            "
                        ></app-form-generator>
                        <mat-icon
                            class="
                                tw-ml-3
                                tw-mt-3
                                tw-cursor-pointer
                                tw-text-fs-600
                                tw-flex
                                tw-justify-center
                                tw-items-center
                                tw-rounded-full
                                success
                            "
                            *ngIf="last"
                            (click)="
                                lastKeyValueFormUpdate(i, keyValuePair.formId)
                            "
                            >add</mat-icon
                        >
                        <mat-icon
                            class="
                                tw-ml-3
                                tw-mt-3
                                tw-cursor-pointer
                                tw-text-fs-600
                                tw-flex
                                tw-justify-center
                                tw-items-center
                                tw-rounded-full
                                error
                            "
                            *ngIf="!last"
                            (click)="
                                deleteKeyValuePairFormGroup(
                                    i,
                                    keyValuePair.formId
                                )
                            "
                            >delete</mat-icon
                        >
                    </div>
                </ng-container>
            </div>
        </section>
    </div>
    <div class="form-controls">
        <app-button-generator
            *ngIf="resetButton"
            [buttonGenInput]="resetButton"
        ></app-button-generator>
        <app-button-generator
            *ngIf="submitButton"
            [buttonGenInput]="submitButton"
        ></app-button-generator>
    </div>
</form>
