<div class="form-builder-container">
    <app-form-builder
        [backendFormInput]="formData"
        [widgetData]="widgetData"
        (formBuilderOutput)="formBuilderOutput($event)"
        (errorMessageOutput)="formBuilderError = $event"
        [widgetId]="widgetId"
    >
    </app-form-builder>
</div>

<div class="footer-button-container">
    <div class="clear-fix"></div>

    <div class="buttons">
        <app-multi-button-generator
            [buttonGenInputs]="buttonsInput"
            [options]="buttonOptions"
        >
        </app-multi-button-generator>
    </div>
</div>
