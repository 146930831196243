<div class="tw-flex tw-flex-col tw-h-full tw-pl-6 tw-pr-6">
    <div class="tw-text-lg tw-mb-4">
        {{ previewData.text }}
    </div>
    <div class="preview-description" *ngIf="previewData.description">
        <app-description-generator
            [data]="previewData.description"
        ></app-description-generator>
    </div>
    <div class="tw-flex-1" *ngIf="previewData && !extraMessage">
        <ng-container
            *ngComponentOutlet="
                previewData.componentToLoad;
                injector: componentInjector
            "
        ></ng-container>
    </div>

    <div class="extra-message" *ngIf="extraMessage">
        {{ extraMessage }}
    </div>
</div>
