import * as am4core from '@amcharts/amcharts4/core';
import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import { IPieApiResponse } from 'src/app/shared/interfaces/api/portlets/IPieApiResponse';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IColumnData } from 'src/app/shared/interfaces/table-generator/IColumnData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { FilterCacheService } from 'src/app/shared/services/cache/filters-cache/filter-cache.service';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { IGraphData, IPieData } from '../../../../interfaces/graph/IGraphData';
import { GraphType } from './../../../../enums/GraphType';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';

@Component({
    selector: 'app-pie',
    templateUrl: './pie.component.html',
    styleUrls: ['./pie.component.sass']
})
export class PieComponent implements OnInit, AfterViewInit {
    // PIE: 2.0

    agGrid: GridOptions;
    ViewType = ViewType;
    widgetRef: Widget;
    rowData;
    filteredSearchData;
    colDefs;
    currencySymbol;
    costType: string;
    totalCost;
    unparsedTotalCost;
    optionSelected;
    showGraphOverlay;
    displaySwitchToTable;

    overlayText;
    tableKeys;
    defaultColDef = {
        editable: false,
        sortable: true,
        filter: true,
        resizable: true
    };
    getRowStyle;
    costKey = null;

    agGridIcons = Helper.getAgGridIcons();

    firstLevelRef: Widget;
    drilldownWidgetRefList = [];
    drillDownFilterObject = {};
    filtersStorageObject = {};

    tempDrilldownFilterObject = {};
    tempInputData = {};
    slice;
    totalUsage: any;
    filterInfo: IFilterInfo = {} as IFilterInfo;
    arrowRightIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-chevron-right small-icon'
    };
    tableInputData: ITableGeneratorInput = {
        listExtraction: {
            type: 'DIRECT'
        },
        columns: [],
        tableHeight: 300
    };
    drillDownColor;
    readonly linkingRestrictedServiceList = ['RI Cost'];
    graphData: IGraphData;
    constructor(
        widgetData: WidgetInjectedData,
        private ngZone: NgZone,
        public modalService: ModalService,
        private globalDataService: GlobalDataService,
        private filterCacheService: FilterCacheService,
        private configCache: ConfigCacheService,
        private userDataCache: UserDataCacheService
    ) {
        this.widgetRef = widgetData.widgetRef;
        this.drillDownColor = Helper.convertToHexIfHsl(
            Helper.getCssVarValue('drillDownColor')
        );
    }

    ngOnInit(): void {
        this.setUpBasics();
        if (this.widgetRef.lightState) {
            this.setUpRowClassRules();
        }
    }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(true);

        this.widgetRef.visibleSections.subscribe(() => {
            this.ngZone.runOutsideAngular(() => {
                setTimeout(() => {
                    this.globalDataService.windowResizeEvent.next();
                }, 30);
            });
        });

        // Setting Visible Sections
        // let visibleSections: Set<ViewType> = new Set();
        // if (this.widgetRef.widgetData.widgetInfo.defaultPref && this.widgetRef.widgetData.widgetInfo.defaultPref !== ViewType.BOTH) {
        //   visibleSections.add(this.widgetRef.widgetData.widgetInfo.defaultPref);
        // } else {
        //   visibleSections.add(ViewType.TABLE);
        //   visibleSections.add(ViewType.GRAPH);
        // }
        // this.widgetRef.visibleSections.next(visibleSections);

        // Setting Aggrid Row styling
        this.getRowStyle = this.rowStyling.bind(this);
    }

    getFullAccess() {
        Helper.getFullAccess(
            this.modalService,
            this.widgetRef.widgetData.widgetInfo.portlet.type
        );
    }

    ngAfterViewInit(): void {
        this.widgetRef.isBindDataAssigned = false;
        this.widgetRef.setBindData(this.bindData.bind(this));
        this.widgetRef.setRedrawSection(this.redrawSection.bind(this));
    }

    setUpRowClassRules() {
        this.defaultColDef.sortable = false;
        this.defaultColDef.filter = false;
        Helper.setRowClassRules(this.widgetRef);
    }
    bindData(data: IPieApiResponse): void {
        if (!data) {
            return;
        }

        if (data.dataMap.table && data.dataMap.table.length) {
            this.costKey = Object.keys(data.dataMap.table[0]).find((key) =>
                key.toLowerCase().includes('cost')
            );
        }

        this.widgetRef.apiResponse = data;
        this.rowData = [];
        this.filteredSearchData = [];
        this.colDefs = [];
        this.unparsedTotalCost = null;

        if (
            (data.dataMap && !Object.keys(data.dataMap).length) ||
            (data.dataMap.table && data.dataMap.table.length === 0)
        ) {
            this.widgetRef.extraMessage.next(
                data.message || Messages.NO_DATA_AVAILABLE
            );
            this.widgetRef.visibleSections.next(new Set());
            this.widgetRef.widgetActions.next([]);
            this.widgetRef.loadingData.next(false);
            return;
        }
        this.filterInfo.lightMode = true;
        this.filterInfo.label = 'Service';

        const listData = [];
        data.dataMap.pie.y.forEach((key) => {
            listData.push({
                id: key,
                label: key
            });
        });
        this.filterInfo.listData = listData;

        this.currencySymbol = data.dataMap.currencySymbol
            ? data.dataMap.currencySymbol
            : '';

        if ('totalCost' in data.dataMap) {
            this.unparsedTotalCost = data.dataMap.totalCost;

            this.totalCost = Helper.parseCommaSeperatedStringToNumber(
                data.dataMap.totalCost
            );
        }

        if ('totalUsage' in data.dataMap) {
            this.totalUsage = Helper.parseCommaSeperatedStringToNumber(
                data.dataMap.totalUsage
            );
        }

        const widgetActions: IAction[] = [
            this.widgetRef.widgetConfigState.actions.pieGraph,
            this.widgetRef.widgetConfigState.actions.table
        ];
        this.widgetRef.widgetActions.next(widgetActions);

        this.optionSelected = data.dataMap.pie.y[0];

        // for retaining visible sections value in the compare view
        if (
            this.widgetRef.widgetConfigDetails &&
            this.widgetRef.widgetConfigDetails.visibleSections
        ) {
            this.widgetRef.visibleSections.next(
                this.widgetRef.widgetConfigDetails.visibleSections
            );
        }

        if (
            !this.widgetRef.widgetData.widgetInfo.supportedView ||
            (this.widgetRef.widgetData.widgetInfo.supportedView &&
                ((this.widgetRef.widgetData.widgetInfo.supportedView.includes(
                    ViewType.GRAPH
                ) &&
                    this.widgetRef.widgetData.widgetInfo.supportedView.includes(
                        ViewType.TABLE
                    )) ||
                    this.widgetRef.widgetData.widgetInfo.supportedView.includes(
                        ViewType.BOTH
                    )))
        ) {
            const widgetVisibleSections: Set<ViewType> = new Set();
            if (
                (this.totalCost && this.totalCost === 0) ||
                (this.totalUsage && this.totalUsage === 0)
            ) {
                widgetVisibleSections.add(ViewType.TABLE);
                const widgetAction: IAction[] = [
                    this.widgetRef.widgetConfigState.actions.table
                ];
                this.widgetRef.widgetActions.next(widgetAction);
            } else if (this.widgetRef.widgetData.widgetInfo.defaultPref) {
                widgetVisibleSections.add(
                    this.widgetRef.widgetData.widgetInfo.defaultPref
                );
                if (widgetVisibleSections.has(ViewType.BOTH)) {
                    widgetVisibleSections.delete(ViewType.BOTH);
                    widgetVisibleSections.add(ViewType.GRAPH);
                    widgetVisibleSections.add(ViewType.TABLE);
                }
            } else {
                widgetVisibleSections.add(ViewType.GRAPH);
                widgetVisibleSections.add(ViewType.TABLE);
            }
            this.showGraphOverlay = false;
            this.widgetRef.visibleSections.next(widgetVisibleSections);
            this.preparePieDataDrawPie(this.optionSelected, true);
        } else if (
            this.widgetRef.widgetData.widgetInfo.supportedView &&
            this.widgetRef.widgetData.widgetInfo.supportedView.includes(
                ViewType.GRAPH
            )
        ) {
            this.widgetRef.visibleSections.next(new Set([ViewType.GRAPH]));
            this.widgetRef.widgetActions.next([]);
            this.displaySwitchToTable = false;
            if (
                (this.totalCost && this.totalCost === 0) ||
                (this.totalUsage && this.totalUsage === 0)
            ) {
                this.overlayText =
                    'Unable to display the graph due to zero cost.';
                this.showGraphOverlay = true;
            } else {
                this.preparePieDataDrawPie(this.optionSelected, false);
            }
        } else if (
            this.widgetRef.widgetData.widgetInfo.supportedView &&
            this.widgetRef.widgetData.widgetInfo.supportedView.includes(
                ViewType.TABLE
            )
        ) {
            this.widgetRef.visibleSections.next(new Set([ViewType.TABLE]));
            this.widgetRef.widgetActions.next([]);
            this.drawTable();
        }
        this.widgetRef.endLoader();
    }

    redrawSection(data: IPieApiResponse) {
        this.preparePieDataDrawPie(this.optionSelected, false);
        this.agGrid?.api.sizeColumnsToFit();
    }

    switchToTableView() {
        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);
    }

    preparePieDataDrawPie(selectedResourceTag, drawTable?) {
        const tableDataAfterColSel = [];
        const prepareTempData = {};
        (this.widgetRef.apiResponse as IPieApiResponse).dataMap.table.every(
            (element) => {
                if (element[selectedResourceTag] in prepareTempData) {
                    const roundOff = 2;

                    let tempDataValue = null;
                    let costValue = null;

                    tempDataValue =
                        prepareTempData[element[selectedResourceTag]];
                    costValue =
                        element[
                            (this.widgetRef.apiResponse as IPieApiResponse)
                                .dataMap.pie.x[0]
                        ];

                    tempDataValue =
                        Helper.parseCommaSeperatedStringToNumber(tempDataValue);
                    costValue =
                        Helper.parseCommaSeperatedStringToNumber(costValue);

                    prepareTempData[element[selectedResourceTag]] =
                        Helper.roundOff(tempDataValue + costValue, roundOff);
                } else {
                    prepareTempData[element[selectedResourceTag]] =
                        element[
                            (
                                this.widgetRef.apiResponse as IPieApiResponse
                            ).dataMap.pie.x[0]
                        ];
                }

                if (Object.keys(prepareTempData).length > 200) {
                    if (
                        this.widgetRef.visibleSections &&
                        this.widgetRef.visibleSections.value.has(ViewType.TABLE)
                    ) {
                        this.widgetRef.visibleSections.next(
                            new Set([ViewType.TABLE])
                        );
                    }

                    if (!drawTable) {
                        this.displaySwitchToTable = false;
                    }
                    const widgetActions: IAction[] = [
                        this.widgetRef.widgetConfigState.actions.pieGraph,
                        this.widgetRef.widgetConfigState.actions.table
                    ];
                    this.widgetRef.widgetActions.next(widgetActions);
                    this.overlayText =
                        'Graph is not supported for this range of data.';
                    this.showGraphOverlay = true;
                    return false;
                }

                return true;
            }
        );

        if (this.showGraphOverlay) {
            // prepare dummy data
            for (let index = 0; index < 5; index++) {
                const tempObject = {};
                let costValue = (this.widgetRef.apiResponse as IPieApiResponse)
                    .dataMap.table[index][
                    (this.widgetRef.apiResponse as IPieApiResponse).dataMap.pie
                        .x[0]
                ];

                costValue = Helper.parseCommaSeperatedStringToNumber(costValue);

                if (costValue < 1) {
                    tempObject[`x`] = 40;
                } else {
                    tempObject[`x`] = costValue;
                }
                tempObject[`y`] = (
                    this.widgetRef.apiResponse as IPieApiResponse
                ).dataMap.table[index][selectedResourceTag];
                tableDataAfterColSel.push(tempObject);
            }
        } else {
            Object.keys(prepareTempData).forEach((key) => {
                if (!(prepareTempData[key] < 0)) {
                    const tempObject = {};
                    tempObject[`y`] = key;
                    tempObject[`x`] = prepareTempData[key];
                    tableDataAfterColSel.push(tempObject);
                }
            });
        }

        this.drawChart(tableDataAfterColSel);
        if (drawTable) {
            this.drawTable();
        }
    }

    drawChart(dataProvider) {
        const data: IPieData[] = (dataProvider as object[]).map((pieData) => {
            return { category: pieData['y'], value: pieData['x'] };
        });

        this.graphData = {
            graphType: GraphType.PIE,
            chartData: data,
            liteState: this.widgetRef.lightState,
            onHoverTemplate: (event) => {
                event.target.tooltip.background.stroke = event.target.fill;
                event.target.tooltip.background.strokeOpacity =
                    event.target.opacity;
                if (
                    this.isLinkingRestricted(
                        event.target.dataItem.dataContext.category
                    )
                ) {
                    return;
                }
                if (
                    this.widgetRef.widgetLinkingData &&
                    this.widgetRef.widgetData.linking
                ) {
                    if (this.widgetRef.widgetData.linking[`legendColumn`]) {
                        event.target.cursorOverStyle =
                            am4core.MouseCursorStyle.pointer;
                    } else if (this.widgetRef.widgetData.linking.legendRow) {
                        if (
                            this.widgetRef.widgetData.linking &&
                            this.widgetRef.widgetData.linking.legendRow &&
                            this.widgetRef.widgetData.linking.legendRow[
                                event.target.dataItem.dataContext.category
                            ]
                        ) {
                            event.target.cursorOverStyle =
                                am4core.MouseCursorStyle.pointer;
                        }
                    }
                }
            },
            onClickTemplate: (event) => {
                this.tableRowClicked(
                    event.target.dataItem.dataContext.category,
                    false
                );
            }
        };
    }

    drawTable() {
        this.colDefs = [];
        this.rowData = [];
        this.tableKeys = [];
        this.tableKeys.push(
            ...(this.widgetRef.apiResponse as IPieApiResponse).dataMap.pie.y
        );
        const xArray = [
            ...(this.widgetRef.apiResponse as IPieApiResponse).dataMap.pie.x
        ].reverse();
        this.tableKeys.push(...xArray);
        this.tableKeys.forEach((row) => {
            let obj = {};
            obj = {
                headerName: row,
                field: row
            };

            if (row === this.costKey) {
                obj['valueFormatter'] = this.customValueFormatter.bind(this);
                obj['columnValueGetter'] = this.customValueGetter.bind(this);
            }

            this.colDefs.push(obj);
        });
        this.rowData = (
            this.widgetRef.apiResponse as IPieApiResponse
        ).dataMap.table;
        this.filteredSearchData = this.rowData;
        this.prepareTableData();
    }

    prepareTableData() {
        this.tableInputData.columns = [];

        this.colDefs.forEach((col) => {
            const columnObj: IColumnData = {
                columnName: col.headerName,
                columnKey: col.field,
                pinned: col.pinned
            };
            if (col.field === this.costKey) {
                columnObj['columnValueGetter'] =
                    this.customValueGetter.bind(this);
            }
            this.tableInputData.columns.push(columnObj);
        });
        this.tableInputData.columns[this.tableInputData.columns.length - 1][
            'pinned'
        ] = 'right';
        this.tableInputData.columns[0]['pinned'] = 'left';
    }

    customValueFormatter(params) {
        return params.data[this.costKey];
    }

    customValueGetter(row) {
        let costValue = null;
        costValue = row.data[this.costKey];
        costValue = Helper.parseCommaSeperatedStringToNumber(costValue);
        return costValue;
    }

    onOptionsSelected(selectedOption) {
        this.optionSelected = selectedOption;

        if (
            !this.widgetRef.widgetData.widgetInfo.supportedView ||
            (this.widgetRef.widgetData.widgetInfo.supportedView &&
                ((this.widgetRef.widgetData.widgetInfo.supportedView.includes(
                    ViewType.GRAPH
                ) &&
                    this.widgetRef.widgetData.widgetInfo.supportedView.includes(
                        ViewType.TABLE
                    )) ||
                    this.widgetRef.widgetData.widgetInfo.supportedView.includes(
                        ViewType.BOTH
                    )))
        ) {
            this.preparePieDataDrawPie(selectedOption, true);
        } else if (
            this.widgetRef.widgetData.widgetInfo.supportedView &&
            this.widgetRef.widgetData.widgetInfo.supportedView.includes(
                ViewType.GRAPH
            )
        ) {
            this.preparePieDataDrawPie(selectedOption, false);
        }
    }

    onQuickFilterChanged(id: string) {
        this.agGrid.api.setQuickFilter(document.getElementById(id)[`value`]);
    }

    isLinkingRestricted(rowValue) {
        if (
            this.costType === 'blended' &&
            this.linkingRestrictedServiceList.includes(rowValue)
        ) {
            return true;
        }
    }

    rowStyling(data) {
        if (
            this.widgetRef.widgetLinkingData &&
            this.widgetRef.widgetData &&
            this.widgetRef.widgetData.linking
        ) {
            if (this.isLinkingRestricted(data[`data`][this.tableKeys[0]])) {
                return;
            }
            if (this.widgetRef.widgetData.linking.drillDownType === 'column') {
                if (
                    data[`data`][this.tableKeys[0]] &&
                    data[`data`][this.tableKeys[0]].toLowerCase() === 'total'
                ) {
                    return { fontWeight: 'bold' };
                } else {
                    return {
                        cursor: 'pointer',
                        color: this.drillDownColor + ' !important'
                    };
                }
            } else if (
                this.widgetRef.widgetData.linking.drillDownType === 'row'
            ) {
                if (
                    this.widgetRef.widgetData.linking &&
                    this.widgetRef.widgetData.linking.legendRow &&
                    this.widgetRef.widgetData.linking.legendRow[
                        data[`data`][this.tableKeys[0]]
                    ]
                ) {
                    return {
                        cursor: 'pointer',
                        color: this.drillDownColor + ' !important'
                    };
                } else if (
                    data[`data`][this.tableKeys[0]] &&
                    data[`data`][this.tableKeys[0]].toLowerCase() === 'total'
                ) {
                    return { fontWeight: 'bold' };
                }
            }
        } else if (
            data[`data`][this.tableKeys[0]] &&
            data[`data`][this.tableKeys[0]].toLowerCase() === 'total'
        ) {
            return { fontWeight: 'bold' };
        }
        return '';
    }

    async tableRowClicked(selectedData, isTableRowClicked) {
        if (
            this.widgetRef.widgetLinkingData &&
            this.widgetRef.widgetData.linking &&
            (this.widgetRef.widgetData.linking.legendRow ||
                this.widgetRef.widgetData.linking.legendColumn)
        ) {
            let slice = '';
            if (isTableRowClicked) {
                slice = selectedData[this.tableKeys[0]];
            } else {
                slice = selectedData;
            }

            if (this.isLinkingRestricted(slice)) {
                return;
            }

            // this.prevDefaultPref = this.defaultPref;
            const tempObj = this.prepareNextWidgetData(
                slice,
                isTableRowClicked,
                selectedData
            );
            if (tempObj) {
                tempObj[`viewId`] = this.widgetRef.widgetLinkingData.viewId;
                tempObj[`widgetConfig`] = this.widgetRef.widgetConfigDetails;
                const currentLevel =
                    this.widgetRef.compareViewService.currentLevel[
                        this.widgetRef.widgetLinkingData.viewId
                    ] + 1;
                this.widgetRef.compareViewService.currentLevel[
                    this.widgetRef.widgetLinkingData.viewId
                ] = currentLevel;
                this.widgetRef.compareViewService.nextWidgetView.next(tempObj);
            }
        }
    }

    prepareNextWidgetData(slice, isTableRowClicked, selectedData) {
        let drilldownFilterObject;
        let widgetId;
        const drillDownFiltersData = {};
        const breadCrumbs = this.widgetRef.widgetLinkingData.breadCrumbs
            ? this.widgetRef.widgetLinkingData.breadCrumbs.slice()
            : [];
        const breadCrumbsData = this.widgetRef.widgetLinkingData.breadCrumbsData
            ? this.widgetRef.widgetLinkingData.breadCrumbsData.slice()
            : [];
        if (this.widgetRef.widgetData.apiResponse) {
            this.widgetRef.widgetData.apiResponse = null;
        }
        if (
            this.widgetRef.compareViewService.linkingArray.get(
                this.widgetRef.widgetLinkingData.viewId
            )[
                this.widgetRef.compareViewService.currentLevel[
                    this.widgetRef.widgetLinkingData.viewId
                ] - 1
            ]
        ) {
            const filterInput = this.filterCacheService.getFiltersInfo(
                this.userDataCache.emailId,
                this.configCache.viewId,
                this.widgetRef.filterStoreKey
            );
            this.widgetRef.compareViewService.linkingArray.get(
                this.widgetRef.widgetLinkingData.viewId
            )[
                this.widgetRef.compareViewService.currentLevel[
                    this.widgetRef.widgetLinkingData.viewId
                ] - 1
            ][`filters`] = Helper.cloneDeep(filterInput);
        }
        if (this.widgetRef.widgetData.linking.drillDownType === 'column') {
            if (!this.widgetRef.widgetData.linking.legendColumn[`filters`]) {
                return;
            }
            if (this.widgetRef.widgetData.linking.legendColumn[`widgetId`]) {
                drilldownFilterObject =
                    this.widgetRef.widgetData.linking.legendColumn[`filters`];
                widgetId =
                    this.widgetRef.widgetData.linking.legendColumn[`widgetId`];
            } else {
                return;
            }
        } else if (this.widgetRef.widgetData.linking.drillDownType === 'row') {
            if (this.widgetRef.widgetData.linking.legendRow[slice]) {
                if (
                    this.widgetRef.widgetData.linking.legendRow[slice][
                        `widgetId`
                    ]
                ) {
                    drilldownFilterObject = this.widgetRef.widgetData.linking
                        .legendRow[slice][`filters`]
                        ? this.widgetRef.widgetData.linking.legendRow[slice][
                              `filters`
                          ]
                        : {};
                    widgetId =
                        this.widgetRef.widgetData.linking.legendRow[slice][
                            `widgetId`
                        ];
                } else {
                    return;
                }
            } else {
                return;
            }
        } else {
            return;
        }

        const tempInputData = {};
        if (
            drilldownFilterObject &&
            Object.keys(drilldownFilterObject).length
        ) {
            Object.keys(drilldownFilterObject).forEach((key, index) => {
                const object = breadCrumbsData.find((row) => key in row);
                if (object) {
                    tempInputData[drilldownFilterObject[key]] = [object[key]];
                } else {
                    if (!isTableRowClicked) {
                        if (
                            key ===
                            (this.widgetRef.apiResponse as IPieApiResponse)
                                .dataMap.pie.y[0]
                        ) {
                            tempInputData[drilldownFilterObject[key]] = [slice];
                            const tempObject = {};
                            tempObject[key] = slice;
                            breadCrumbsData.push(tempObject);
                        } else {
                            tempInputData[drilldownFilterObject[key]] = null;
                        }
                    } else {
                        const headerName = this.tableKeys.find(
                            (tableKey) => tableKey === key
                        );
                        if (headerName) {
                            tempInputData[drilldownFilterObject[key]] = [
                                selectedData[headerName]
                            ];
                            if (headerName === this.tableKeys[0]) {
                                const tempObject = {};
                                tempObject[key] = selectedData[headerName];
                                breadCrumbsData.push(tempObject);
                            }
                        } else {
                            tempInputData[drilldownFilterObject[key]] = null;
                        }
                    }
                }
                drillDownFiltersData[drilldownFilterObject[key]] = [
                    tempInputData[drilldownFilterObject[key]][0]
                ];
            });
        } else {
            const tempObject = {};
            const slicedKey = (this.widgetRef.apiResponse as IPieApiResponse)
                ?.dataMap.pie.y[0];
            tempObject[slicedKey] = slice;
            breadCrumbsData.push(tempObject);
        }

        if (!isTableRowClicked) {
            breadCrumbs.push(slice);
        } else {
            if (this.widgetRef.widgetData.linking.titleKeys) {
                let title = '';
                this.widgetRef.widgetData.linking.titleKeys.forEach(
                    (element, index) => {
                        if (selectedData[element]) {
                            if (!index) {
                                title += selectedData[element];
                            } else {
                                title += '|' + selectedData[element];
                            }
                        }
                    }
                );

                breadCrumbs.push(title);
            } else {
                breadCrumbs.push(slice);
            }
        }

        return {
            widgetId,
            input: tempInputData,
            breadCrumbs,
            breadCrumbsData,
            filtersData: drillDownFiltersData
        };
    }

    goBackDrillDown(home?, index?, isLast?) {
        if (isLast) {
            return;
        }
        if (home) {
            this.widgetRef.compareViewService.currentLevel[
                this.widgetRef.widgetLinkingData.viewId
            ] = 1;
        } else {
            const currentLevel =
                this.widgetRef.compareViewService.currentLevel[
                    this.widgetRef.widgetLinkingData.viewId
                ] - index;
            this.widgetRef.compareViewService.currentLevel[
                this.widgetRef.widgetLinkingData.viewId
            ] = currentLevel;
        }

        const tempObj = {};
        tempObj[this.widgetRef.widgetLinkingData.viewId] = true;
        this.widgetRef.compareViewService.previousWidgetView.next(tempObj);
    }
}
