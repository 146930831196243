<div class="bottom-ellipse" appIcon [data]="bottomEllipse"></div>
<div class="top-ellipse" appIcon [data]="topEllipse"></div>
<div class="login-wrapper">
    <div class="logo-container">
        <div class="logo">
            <a
                appIcon
                [data]="logo"
                [href]="
                    whiteLabelService.whiteLableData.organizationalUrl
                        ? whiteLabelService.whiteLableData.organizationalUrl.includes(
                              'http'
                          )
                            ? whiteLabelService.whiteLableData.organizationalUrl
                            : '//' +
                              whiteLabelService.whiteLableData.organizationalUrl
                        : ''
                "
                target="_blank"
                rel="noopener noreferrer"
            >
            </a>
        </div>
    </div>
    <div class="login-container">
        <div appIcon [data]="loginImage" class="work"></div>
        <div class="form">
            <ng-container>
                <div class="form-container">
                    <div class="headingAndBackButton">
                        <span class="form-heading">Sign Up</span>
                        <div class="step-count">
                            Step {{ currentStep }} of
                            {{
                                CustomSignUpFormResponseData?.formSections
                                    ?.length
                            }}
                        </div>
                        <div class="form-name">
                            {{
                                CustomSignUpFormResponseData?.formSections[
                                    this.index
                                ]?.formName
                            }}
                        </div>
                    </div>
                    <div class="login-content">
                        <div class="fieldsWithErrorAndForgotPassword">
                            <div class="fields">
                                <ng-container>
                                    <div
                                        class="terms customer-information-form"
                                        *ngFor="
                                            let step of CustomSignUpFormResponseData?.formSections;
                                            index as i
                                        "
                                    >
                                        <div *ngIf="i === index">
                                            <app-form-generator
                                                [formGeneratorInput]="
                                                    signupFormGenInput
                                                "
                                                (formGroupRef)="
                                                    signupFormGroup = $event
                                                "
                                            ></app-form-generator>
                                        </div>
                                    </div>
                                </ng-container>

                                <div class="submit-button-wrapper">
                                    <app-button-generator
                                        *ngIf="this.index < this.totalSteps - 1"
                                        [buttonGenInput]="nextButtonGenInput"
                                        class="submit-button"
                                    ></app-button-generator>
                                    <app-button-generator
                                        *ngIf="
                                            this.index >= this.totalSteps - 1
                                        "
                                        [buttonGenInput]="submitButtonGenInput"
                                        class="submit-button"
                                    ></app-button-generator>
                                    <app-button-generator
                                        *ngIf="index > 0 && index < totalSteps"
                                        [buttonGenInput]="backButtonGenInput"
                                        class="back-button"
                                    ></app-button-generator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
