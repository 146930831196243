import { Pipe, PipeTransform } from '@angular/core';
import { IApiInfo } from '../../interfaces/api/IApiInfo';
import { IDropdownData } from '../../interfaces/dropdown-data/IDropdownData';
import { IFilterInfo } from '../../interfaces/filter/IFilterInfo';

@Pipe({
    name: 'createFilterInfo'
})
export class CreateFilterInfoPipe implements PipeTransform {
    transform(
        value: IDropdownData[],
        label?: string,
        limit?: number,
        groupBy?: string,
        lightMode?: boolean,
        apiInfo?: IApiInfo
    ): unknown {
        const data: IFilterInfo = {
            listData: value
        };
        if (label) {
            data.label = label;
        }
        if (limit) {
            data.selectionLimit = limit;
        }
        if (groupBy) {
            data.groupBy = groupBy;
        }
        if (lightMode) {
            data.lightMode = lightMode;
        }
        if (apiInfo) {
            data.apiInfo = apiInfo;
        }
        return data;
    }
}
