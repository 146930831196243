<div class="container">
    <div
        class="tw-flex tw-justify-center"
        [ngClass]="!isEdit ? 'form-container' : 'tw-h-full'"
    >
        <div class="tw-w-3/4">
            <app-form-generator
                *ngIf="formGenInput"
                [formGeneratorInput]="formGenInput"
                (formGroupRef)="cspFormGroup = $event"
            ></app-form-generator>
        </div>
    </div>
    <div *ngIf="!isEdit" class="buttons">
        <app-multi-button-generator [buttonGenInputs]="buttonInputs">
        </app-multi-button-generator>
    </div>
</div>
