import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-date-picker-range',
    templateUrl: './date-picker-range.component.html',
    styleUrls: ['./date-picker-range.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class DatePickerRangeComponent implements OnInit {
    @Input() minDate;
    @Input() maxDate;
    @Input() selectedDates: BehaviorSubject<Record<string, Date>>;
    // tslint:disable-next-line: no-output-native
    @Output() dateChanged = new EventEmitter();
    hoveredDate: NgbDate;
    fromDate: NgbDate;
    toDate: NgbDate;

    ngOnInit(): void {
        this.selectedDates.subscribe((res) => {
            this.fromDate = this.getNgbDate(res.startDateValue);
            this.toDate = this.getNgbDate(res.endDateValue);
        });
    }

    onDateSelection(event) {
        const date = new NgbDate(event.year, event.month, event.day);
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (
            this.fromDate &&
            !this.toDate &&
            date.equals(this.fromDate)
        ) {
            // Clicked on single date twice
            this.toDate = this.fromDate;
        } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
            this.toDate = date;
        } else if (
            this.fromDate &&
            !this.toDate &&
            date.before(this.fromDate)
        ) {
            this.toDate = this.fromDate;
            this.fromDate = date;
        } else {
            this.toDate = null;
            this.fromDate = date;
        }
        if (this.fromDate && this.toDate) {
            this.apply();
        }
    }

    isHovered(date) {
        const newDate = new NgbDate(date.year, date.month, date.day);
        return (
            this.fromDate &&
            !this.toDate &&
            this.hoveredDate &&
            ((newDate.after(this.fromDate) &&
                newDate.before(this.hoveredDate)) ||
                (newDate.before(this.fromDate) &&
                    newDate.after(this.hoveredDate)))
        );
    }

    isInside(date) {
        const newDate = new NgbDate(date.year, date.month, date.day);
        if (newDate.after(this.fromDate) && newDate.before(this.toDate)) {
            return true;
        } else if (
            newDate.after(this.toDate) &&
            newDate.before(this.fromDate)
        ) {
            return true;
        } else {
            return false;
        }
    }

    isRange(date) {
        const newDate = new NgbDate(date.year, date.month, date.day);
        return (
            newDate.equals(this.fromDate) ||
            newDate.equals(this.toDate) ||
            this.isInside(newDate) ||
            this.isHovered(newDate)
        );
    }

    getNgbDate(date: Date): NgbDate {
        return new NgbDate(
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate()
        );
    }

    apply() {
        const obj = {
            fromDate: this.fromDate,
            toDate: this.toDate
        };
        this.dateChanged.emit(obj);
    }
}
