import { Clouds } from 'src/app/shared/enums/Clouds';

export class ApiUrls {
    static readonly IS_VIA_IP = false;
    static readonly TEST_IP = 'http://10.0.119.95:';

    static readonly VIEW_AND_API_MGMNT_NAME = '/view-and-api-management';
    static readonly IDENTITY_ACCESS_MGMNT_NAME = '/identity-access-management';
    static readonly SRM_INTEGRATIONS_NAME = '/srm-integrations';
    static readonly SRM_GOVERNANCE_NAME = '/srm-governance';
    static readonly GENERAL_AUTOMATIONS_NAME = '/general-automations';
    static readonly PARTNER_CENTER_PORTAL_NAME = '/partner-centre-portal';
    static readonly SRM_GCP_FILTERS = '/srm-gcp-filters';

    static readonly VIEW_AND_API_MANAGEMENT = ApiUrls.IS_VIA_IP
        ? `${ApiUrls.TEST_IP}8081`
        : ApiUrls.VIEW_AND_API_MGMNT_NAME;

    static readonly IDENTITY_ACCESS_MANAGEMENT = ApiUrls.IS_VIA_IP
        ? `${ApiUrls.TEST_IP}8082`
        : ApiUrls.IDENTITY_ACCESS_MGMNT_NAME;

    static readonly SRM_INTEGRATIONS = ApiUrls.IS_VIA_IP
        ? `${ApiUrls.TEST_IP}8086`
        : ApiUrls.SRM_INTEGRATIONS_NAME;

    static readonly SRM_GOVERNANCE = ApiUrls.IS_VIA_IP
        ? `${ApiUrls.TEST_IP}8087`
        : ApiUrls.SRM_GOVERNANCE_NAME.replace('/', '');

    static readonly GENERAL_AUTOMATIONS = ApiUrls.IS_VIA_IP
        ? `${ApiUrls.TEST_IP}8088`
        : ApiUrls.GENERAL_AUTOMATIONS_NAME;

    static readonly PARTNER_CENTER_PORTAL = ApiUrls.IS_VIA_IP
        ? `${ApiUrls.TEST_IP}8092`
        : ApiUrls.PARTNER_CENTER_PORTAL_NAME;
    static readonly CUSTOMERS_LISTING_URL = `${ApiUrls.IDENTITY_ACCESS_MANAGEMENT}/customer-access-control/all`;
    static readonly LOGIN_REDIRECT_URL = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/tenant/view/sidebar/widgets`;
    static readonly LOGIN_API_PATH = `${ApiUrls.IDENTITY_ACCESS_MANAGEMENT}/login`;
    static readonly ASSESSMENT_LOGIN_API_PATH = `${ApiUrls.IDENTITY_ACCESS_MANAGEMENT}/assessment/login`;
    static readonly FORGET_PASSWORD_API_PATH = `${ApiUrls.IDENTITY_ACCESS_MANAGEMENT}/password/forget-password`;
    static readonly CHANGE_PASSWORD_API_PATH = `${ApiUrls.IDENTITY_ACCESS_MANAGEMENT}/user-profile/change-password`;
    static readonly VIEW_TOKEN_API_PATH = `${ApiUrls.IDENTITY_ACCESS_MANAGEMENT}/auth/login/`; // GET: Append view id at the end
    static readonly WIDGET_INFO_API_PATH = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/widgets/`; // WidgetId should be appended
    static readonly FILTER_INFO_API_PATH = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/filters/`; // FilterId should be appended
    static readonly SORTED_FILTERS_LIST_API_PATH = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/default-config/get-default-filters`; // FilterId should be appended
    static readonly GENERAL_CONFIGURATION_FILTERS_LIST_API_PATH = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/default-config/get-configuration`;
    static readonly GENERAL_CONFIGURATION = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/default-config/get-general-configuration`;
    static readonly LINKING_INFO_API_PATH = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/linking/`; // LinkingId should be appended
    static readonly DESCRIPTION_INFO_API_PATH = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/description/`; // DescriptionId should be appended
    static readonly ACL_LISTING = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/aclv2`;
    static readonly ACL_DESCRIBE = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/aclv2/acl-widget-info/{aclId}`;
    static readonly ACL_DESCRIBE_MASTER = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/aclv2/master-acl-widget-info`; // Master ACL
    static readonly VIEW_ENDPOINT = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/view`; // POST Method for creating view // GET for views listing
    static readonly VIEW_ENDPOINT_HEADER = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/tenant/view/switch-views/list`; // GET for views listing
    static readonly TENANT_VIEW_ENDPOINT = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/tenant/view`;
    static readonly VIEW_SIDE_MENU_DATA = `${ApiUrls.TENANT_VIEW_ENDPOINT}/sidebar/`; // GET and Append view id to get sidemenu data
    static readonly ASSESSMENT_SIDE_MENU_DATA = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/assessment/sidebar/`; // GET and Append Assessment id to get sidemenu data
    static readonly PRICE_TYPE_LIST = `${ApiUrls.IDENTITY_ACCESS_MANAGEMENT}/customer-access-control/price-type`;
    static readonly CAC_REGIONS_LIST = `${ApiUrls.IDENTITY_ACCESS_MANAGEMENT}/customer-access-control/regions`;
    static readonly ASSESSMENT_CUSTOMER_LIST = `${ApiUrls.IDENTITY_ACCESS_MANAGEMENT}/customer-access-control/list-assessment-tenants`;
    static readonly ASSESSE_ASSESSMENT_LIST = `${ApiUrls.IDENTITY_ACCESS_MANAGEMENT}/users/assesse/assessment`;
    static readonly CUSTOMERS_END_POINT = `${ApiUrls.IDENTITY_ACCESS_MANAGEMENT}/customer-access-control`; // POST Method for creating customer // GET for customers listing
    static readonly TENANCY_TYPE = `${ApiUrls.CUSTOMERS_END_POINT}/tenancy-type`;
    static readonly SUSPEND_ACCOUNT = `${ApiUrls.CUSTOMERS_END_POINT}/suspend/tenant-id/`; // PUT append domain id
    static readonly UNSUSPEND_ACCOUNT = `${ApiUrls.CUSTOMERS_END_POINT}/unsuspend/tenant-id/`; // PUT append domain id
    static readonly TENANT_ENDPOINT = `${ApiUrls.CUSTOMERS_END_POINT}/tenant-id/`; // append tenant id, PATCH to update, DELETE to delete
    static readonly ENABLE_RULE = `${ApiUrls.GENERAL_AUTOMATIONS}/automation/enable/widgetId/{widget-id}/automationId`;
    static readonly DISABLE_RULE = `${ApiUrls.GENERAL_AUTOMATIONS}/automation/disable/widgetId/{widget-id}/automationId`;
    static readonly RULE_ENDPOINT = `${ApiUrls.GENERAL_AUTOMATIONS}/automation/widgetId/{widget-id}/automationId`;
    static readonly REPORT_ENDPOINT = `srm-governance/report`;
    static readonly DOWNLOAD_REPORT = `${ApiUrls.REPORT_ENDPOINT}/download`;
    static readonly SEND_REPORT = `${ApiUrls.REPORT_ENDPOINT}/send-report`;
    static readonly ALERT_ENDPOINT = `srm-governance/alert`;
    static readonly BUDGET_ENDPOINT = `srm-governance/budget`;
    static readonly USERS_END_POINT = `${ApiUrls.IDENTITY_ACCESS_MANAGEMENT}/users`;
    static readonly AUTOMATION_ENDPOINT = `${ApiUrls.GENERAL_AUTOMATIONS}/automation`;
    static readonly TAGGING_AUTOMATION_ENDPOINT = `${ApiUrls.GENERAL_AUTOMATIONS}/tagging`;
    static readonly AWS_AVAILABILITY_ZONE = `/aws/miscellaneous/auth/filters/list-availability-zones`;
    static readonly MASTER_KEY = `/aws/miscellaneous/auth/filters/list-kms-id`;
    static readonly AWS_ACCOUNT_IDS = `/aws/miscellaneous/auth/filters/list-aws-account-ids`;
    static readonly AWS_REGION_IDS = `/aws/miscellaneous/auth/filters/list-aws-regions`;
    static readonly AWS_INSTANCE_TYPE = `/aws/miscellaneous/auth/filters/list-aws-instance-types`;
    static readonly AWS_IMAGE_ID = `/aws/miscellaneous/auth/filters/list-ec2-image-id`;
    static readonly AWS_NETWORK_INTERFACE = `/aws/miscellaneous/auth/filters/list-network-interfaces`;
    static readonly SNS_EXISTING_ACCOUNT = `${ApiUrls.SRM_INTEGRATIONS}/integration/sns/exist`;
    static readonly SNS_NEW_ACCOUNT = `${ApiUrls.SRM_INTEGRATIONS}/integration/sns/new`;
    static readonly SNS_EXISTING_ACCOUNT_POLICY = `${ApiUrls.SRM_INTEGRATIONS}/integration/sns/policy`;
    static readonly SNS_NEW_ACCOUNT_POLICY = `${ApiUrls.SRM_INTEGRATIONS}/integration/sns/new-policy`;
    static readonly SNS_EDIT = `${ApiUrls.SRM_INTEGRATIONS}/integration/sns/edit`;
    static readonly SNS_LISTING = `${ApiUrls.SRM_INTEGRATIONS}/integration/sns/get-accounts`;
    static readonly SNS_SEND_MESSAGE = `${ApiUrls.SRM_INTEGRATIONS}/integration/sns/public-message`;
    static readonly SNS_INFO = `${ApiUrls.SRM_INTEGRATIONS}/integration/sns/info`;
    static readonly SSO_CHECK = `${ApiUrls.IDENTITY_ACCESS_MANAGEMENT}/sso/check`;
    static readonly SSO_AUTHORIZE = `${ApiUrls.IDENTITY_ACCESS_MANAGEMENT}/sso/authorize`;
    static readonly SSO_ROLE_BASED_LOGIN = `${ApiUrls.IDENTITY_ACCESS_MANAGEMENT}/sso/list-roles`;
    static readonly AUTOTASK_CREATE_TICKET = `${ApiUrls.SRM_INTEGRATIONS}/autotask-integration/create-ticket`;
    static readonly OTRS_CREATE_TICKET = `${ApiUrls.SRM_INTEGRATIONS}/otrs-integration/create-ticket`;
    static readonly CASDM_CREATE_TICKET = `${ApiUrls.SRM_INTEGRATIONS}/casdm-integration/create-ticket`;
    static readonly GET_CONFIGURATION = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/default-config/get-configuration`;
    static readonly ACL_DEPENDENT_VIEW_ENDPOINT = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/tenant/view/aclid-view/{aclId}`;
    static readonly VIEW_ASSOCIATED_USERS_LISTING = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/tenant/view/user-associated-view/{view-id}`;
    static readonly CUSTOM_COST_MONITORING_WIDGET =
        '/cloud-visibility/custom-cost-monitoring-widget';
    static readonly COLUMN_DATA_LISTING =
        '/cloud-visibility/custom-cost-monitoring-widget/columns/{id}';
    static readonly COLUMN_LISTING =
        '/cloud-visibility/custom-cost-monitoring-widget/columns';
    static readonly CHECK_QUERY =
        '/cloud-visibility/custom-cost-monitoring-widget/check-query';
    static readonly CUSTOM_COST_MONITORING_WIDGET_FILTERS =
        '/cloud-visibility/custom-cost-monitoring-widget/filters';
    static readonly WIDGET_PASSED =
        '/cloud-visibility/custom-cost-monitoring-widget/check-widget-passed';
    static readonly PREVIEW =
        '/cloud-visibility/custom-cost-monitoring-widget/preview';
    static readonly CUSTOM_MONITORING_REPORT =
        '/cloud-visibility/custom-cost-monitoring-widget/report';
    static readonly CUSTOM_MONITORING_BUILD_QUERY_REPORT =
        '/cloud-visibility/custom-cost-monitoring-widget/build-query/report';
    static readonly CUSTOM_MONITORING_REPORTS =
        '/cloud-visibility/custom-cost-monitoring-widget/reports';
    // GET for users listing
    // Append id and DELETE for deleting
    // PUT for creating users

    static readonly INTEGRATIONS_ENDPOINT = `${ApiUrls.SRM_INTEGRATIONS}`;
    static readonly WHITELABELING_ENDPOINT = `${ApiUrls.INTEGRATIONS_ENDPOINT}/whitelabel`;
    static readonly THEMING_DATA = `${ApiUrls.WHITELABELING_ENDPOINT}/themes`;
    static readonly WHITELABEL_DETAILS = `${ApiUrls.WHITELABELING_ENDPOINT}/get-whitelabel-details`;
    static readonly SUBDOMAIN_DETAILS = `${ApiUrls.INTEGRATIONS_ENDPOINT}/sub-domain/get-details`;

    static readonly SLACK_ENDPOINT = `${ApiUrls.INTEGRATIONS_ENDPOINT}/slack-integration`;
    static readonly SLACK_LIST_USERS = `${ApiUrls.SLACK_ENDPOINT}/list-users`;
    static readonly SLACK_LIST_CHANNELS = `${ApiUrls.SLACK_ENDPOINT}/list-channels`;

    static readonly SERVICENOW_ENDPOINT = `${ApiUrls.INTEGRATIONS_ENDPOINT}/servicenow-integration`;
    static readonly SERVICENOW_CREATE_INCIDENT = `${ApiUrls.SERVICENOW_ENDPOINT}/create-incidents`;
    static readonly SERVICENOW_CREATE_REQUEST = `${ApiUrls.SERVICENOW_ENDPOINT}/create-request`;
    static readonly INTEGRATION_STATUS = `${ApiUrls.INTEGRATIONS_ENDPOINT}/integration-status/`; // append integration id
    static readonly MASTER_LOGIN = `${ApiUrls.IDENTITY_ACCESS_MANAGEMENT}/master-login/`; // append tenantId

    static readonly NAGIOS_ENDPOINT = `${ApiUrls.INTEGRATIONS_ENDPOINT}/nagios`;

    static readonly CLOUD_ACCOUNTS_ENDPOINT = `/cloud-accounts/accounts`;
    static readonly PUSH_NOTIFICATIONS_ENDPOINT = `URL....`;

    static readonly AWS_ACCOUNT_IDS_LIST = `${ApiUrls.CLOUD_ACCOUNTS_ENDPOINT}/${Clouds.AWS}/list-account-ids`;
    static readonly CSP_ACCOUNT_IDS_LIST = `${ApiUrls.CLOUD_ACCOUNTS_ENDPOINT}/${Clouds.AZURE_CSP}/list-account-ids`;
    static readonly PLAN_ACCOUNT_IDS_LIST = `${ApiUrls.CLOUD_ACCOUNTS_ENDPOINT}/${Clouds.AZURE_PLAN}/list-account-ids`;
    static readonly AZURE_SPONSORSHIP_ACCOUNT_IDS_LIST = `${ApiUrls.CLOUD_ACCOUNTS_ENDPOINT}/${Clouds.SPONSORSHIP}/list-account-ids`;
    static readonly AZURE_PAY_AS_YOU_GO_ACCOUNT_IDS_LIST = `${ApiUrls.CLOUD_ACCOUNTS_ENDPOINT}/${Clouds.PAY_AS_YOU_GO}/list-account-ids`;
    static readonly EA_ACCOUNT_IDS_LIST = `${ApiUrls.CLOUD_ACCOUNTS_ENDPOINT}/${Clouds.AZURE_EA}/list-account-ids`;
    static readonly MCA_ACCOUNT_IDS_LIST = `${ApiUrls.CLOUD_ACCOUNTS_ENDPOINT}/${Clouds.AZURE_MCA}/list-account-ids`;

    static readonly AWS_SHAREABLE_ACCOUNT_IDS_LIST = `${ApiUrls.CLOUD_ACCOUNTS_ENDPOINT}/${Clouds.AWS}/list-shareable-account-ids`;
    static readonly CSP_SHAREABLE_ACCOUNT_IDS_LIST = `${ApiUrls.CLOUD_ACCOUNTS_ENDPOINT}/${Clouds.AZURE_CSP}/list-shareable-account-ids`;
    static readonly PLAN_SHAREABLE_ACCOUNT_IDS_LIST = `${ApiUrls.CLOUD_ACCOUNTS_ENDPOINT}/${Clouds.AZURE_PLAN}/list-shareable-account-ids`;
    static readonly EA_SHAREABLE_ACCOUNT_IDS_LIST = `${ApiUrls.CLOUD_ACCOUNTS_ENDPOINT}/${Clouds.AZURE_EA}/list-shareable-account-ids`;
    static readonly MCA_SHAREABLE_ACCOUNT_IDS_LIST = `${ApiUrls.CLOUD_ACCOUNTS_ENDPOINT}/${Clouds.AZURE_MCA}/list-shareable-account-ids`;

    static readonly AUTOTASK_CLIENT_IDS = `${ApiUrls.INTEGRATIONS_ENDPOINT}/autotask-integration/list-client-ids`;
    static readonly LIST_TENANT_USERS_ENDPOINT = `${ApiUrls.IDENTITY_ACCESS_MANAGEMENT}/master-login-user-listing/`;
    static readonly USER_MASTER_LOGIN = `${ApiUrls.IDENTITY_ACCESS_MANAGEMENT}/master-login/{tenant-id}/user/{user-id}/view/{view-id}`;
    static readonly LIST_GROUP_ACCOUNT_IDS = `/cloud-accounts/accounts/aws/list-group-account-ids`;
    static readonly CHARGE_TYPE = `${ApiUrls.PARTNER_CENTER_PORTAL}/my-price-configuration/list-charge-type`;
    static readonly PAYER_ACCOUNT_IDS =
        '/aws/miscellaneous/auth/filters/list-aws-independent-account-ids';
    static readonly SEND_OTP = `${ApiUrls.SRM_INTEGRATIONS}/root-user-mfa/send-otp`;
    static readonly SEND_OTP_NON_ROOT_USER = `${ApiUrls.SRM_INTEGRATIONS}/non-root-user-mfa/send-otp`;
    static readonly SEND_OTP_AFTER_LOGIN = `${ApiUrls.SRM_INTEGRATIONS}/non-root-user-mfa/send-otp-after-login`;
    static readonly VERIFY_OTP_AFTER_LOGIN = `${ApiUrls.SRM_INTEGRATIONS}/non-root-user-mfa/verification-after-login`;
    static readonly VERIFY_OTP = `${ApiUrls.SRM_INTEGRATIONS}/root-user-mfa/verification`;
    static readonly VERIFY_OTP_NON_ROOT_USER = `${ApiUrls.SRM_INTEGRATIONS}/non-root-user-mfa/verification`;
    static readonly ENABLE_NON_ROOT_USER_MFA = `${ApiUrls.SRM_INTEGRATIONS}/root-user-mfa/user-mfa-enable`;
    static readonly DISABLE_NON_ROOT_USER_MFA = `${ApiUrls.SRM_INTEGRATIONS}/non-root-user-mfa/user-mfa-disable`;
    static readonly DISABLE_ROOT_USER_MFA = `${ApiUrls.SRM_INTEGRATIONS}/root-user-mfa/user-mfa-disable`;
    static readonly ENABLE_MFA_AFTER_LOGIN = `${ApiUrls.SRM_INTEGRATIONS}/non-root-user-mfa/enable-after-login`;

    static readonly GET_WIDGET_CATALOG =
        '/view-and-api-management/widget-catalog';
    static readonly VIEW_FILTER =
        '/view-and-api-management/widget-catalog/filter-view';
    static readonly LAYER_FILTER =
        '/view-and-api-management/widget-catalog/filter-layer';
    static readonly ADD_COMMENT = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/assessment/framework/comments`;
    static readonly EDIT_COMMENT = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/assessment/attribute/comments`;
    static readonly ADD_DATA_TO_S3 = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/audit/get-presigned-url/`;
    static readonly FRAMEWORK_PATH_API = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/assessment/attribute/{attribute-id}/path`;
    static readonly SUBMIT_ASSESSMENT_API = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/assessment/submit`;
    static readonly COLUMN_ORDER = `/view-and-api-management/widgets/{widgetId}/column-order`;
    static readonly DELETE_COMMENT_API = `view-and-api-management/assessment/framework/comments/{commentId}`;
    static readonly GET_TECHNICAL_DESCRIPTION = `/view-and-api-management/assessment/framework/{framework-id}/technical-summary`;
    static readonly CREATE_FRAMEWORK = `/view-and-api-management/assessment/{assessment-id}/attribute/{attribute-id}/framework`;
    static readonly GET_COMMENT_URL = `/view-and-api-management/assessment/framework/{framework-id}/comments`;
    static readonly GET_STATUS = `/view-and-api-management/assessment/framework/{framework-id}/status`;
    static readonly GET_WIDGETS_FRAMEWORK = `/view-and-api-management/assessment/framework/{framework-id}/widgets`;
    static readonly GET_TAGS_FRAMEWORK = `/view-and-api-management/assessment/framework/{framework-id}/tags`;
    static readonly FRAMEWORK_STATUS_API = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/assessment/status/{action-enum}`;
    static readonly FRAMEWORK_STATUS_API_V2 = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/assessment/change-status/{action-enum}`;
    static readonly FRAMEWORK_CHANGE_SCORE_API = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/assessment/change-score`;
    static readonly ASSESSE_LANDING_PAGE_API = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/assessment/sidebar/attribute`;
    static readonly ASSESSMENT_EVALUATE_API = `${ApiUrls.IDENTITY_ACCESS_MANAGEMENT}/assessment/login/{assessment-id}/widget/{widget-id}/evaluate`;
    static readonly CHANGE_STATUS_OF_ASSESSMENT = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/assessment/{assessment-id}/widget/{widget-id}/`;
    static readonly CANCEL_ASSESSMENT_BULK = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/assessment/action/VOID`;
    static readonly ASSESSMENT_EMAIL_TEMPLATE = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/email-template/type/{email-template-type}`;
    static readonly ASSESSMENT_EMAIL_TEMPLATE_EXISTING = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/email-template/type/{email-template-type-existing}`;
    static readonly ASSESSMENT_USER_EMAIL_TEMPLATE = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/email-template/type/ASSESSMENT_ASSESSE_USER`;
    static readonly ASSESSOR_EMAIL_TEMPLATE = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/email-template/type/ASSESSMENT_ASSESSOR`;
    static readonly ASSIGN_ASSESSMENT_API = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/assessment/assign/widget/{widget-id}/assessment/{assessment-id}`;
    static readonly IOT_FAILURE_LOGGER =
        'https://apistaging.centilytics.com/v2/identity-access-management/iot/logs/{domainId}/{emailId}';
    static readonly MANAGED_REPORTS_SIDEBAR = `${ApiUrls.VIEW_ENDPOINT}/get-managed-sidebar`;
    static readonly WHITELABELING_BASE_URL =
        'https://configurations.centilytics.com/';
    static readonly GET_DEFAULT_WHITELABELING_DATA = `${ApiUrls.WHITELABELING_BASE_URL}{region}/defaultWebsiteForSrm/meta-data.json`;
    static readonly UPDATED_GET_WHITELABELING_DATA =
        '/srm-integrations/whitelabel/get-whitelabel-details';
    static readonly GET_DOMAIN_EXCEPTION_API = `${ApiUrls.WHITELABELING_BASE_URL}1/domainExceptions.json`;
    static readonly ZENDESK_CONFIG_FORM =
        '/srm-integrations/zendesk/list-form/{widgetId}';
    static readonly GET_CONFIGURED_DATA = `srm-integrations/service-catalog/configured-column-list`;
    static readonly ZENDESK_SEND_TICKET_API = `/srm-integrations/zendesk/send-ticket/{widget-id}`;
    static readonly ZENDESK_GET_FORM_API = `/srm-integrations/zendesk/get-form/form-id/{form-id}/widget-id/{widget-id}`;
    static readonly ZENDESK_GET_FIELD_API = `/srm-integrations/zendesk/get-field/{widget-id}`;
    // Service Now
    static readonly SERVICENOW_CATEGORY =
        '/srm-integrations/servicenow-integration/servicenow-category-list/{id}';
    static readonly SERVICENOW_ITEMS =
        '/srm-integrations/servicenow-integration/servicenow-items-list/{id}/{catalogId}';
    static readonly SERVICENOW_FIELDS =
        '/srm-integrations/servicenow-integration/servicenow-fields-list/{item-id}';
    static readonly SERVICENOW_FIELDS_REFERENCE =
        '/srm-integrations/servicenow-integration/servicenow-table-reference/{reference-name}';
    static readonly SERVICENOW_CATALOG =
        '/srm-integrations/servicenow-integration/servicenow-catalog-list';
    static readonly ADD_DATA_TO_S3_WHITELABEL = `${ApiUrls.SRM_INTEGRATIONS}/whitelabel/generate-presigned-url/{name}`;

    // Widget Documentation

    static readonly WIDGET_DOCUMENTATION_PRESIGNED_URL =
        '/view-and-api-management/widget-document/get-presigned-url';

    static readonly WIDGET_DOCUMENTATION_SAVE_AS_DRAFT =
        '/view-and-api-management/widget-document/save-as-draft';

    static readonly WIDGET_DOCUMENTATION_CREATE =
        '/view-and-api-management/widget-document';

    static readonly WIDGET_DOCUMENTATION_EDIT =
        '/view-and-api-management/widget-document/{widgetId}';

    static readonly WIDGET_DOCUMENTATION_REMOVE_MEDIA_FILE =
        '/view-and-api-management/widget-document/remove-media-file/{widgetId}';

    static readonly WIDGET_DOCUMENTATION_MORE_INFO =
        '/view-and-api-management/widget-document/more-info/{widgetId}';

    static readonly WIDGET_DOCUMENTATION_DRAFT_DESCRIPTION =
        '/view-and-api-management/widget-document/description/{widgetId}';

    static readonly WIDGET_DOCUMENTATION_DRAFT_DETAILED_DESCRIPTION =
        '/view-and-api-management/widget-document/widget-more-info/{widgetId}';

    static readonly WIDGET_DOCUMENTATION_PUBLISHED_DESCRIPTION =
        '/view-and-api-management/widget-document/published-description/{widgetId}';

    static readonly WIDGET_DOCUMENTATION_PUBLISHED_DETAILED_DESCRIPTION =
        '/view-and-api-management/widget-document/published-widget-more-info/{widgetId}';
    static readonly GOVERNANCE_SIDE_MENU_DATA_API_PATH = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/view/governance/`;
    static readonly FRESHSERVICE_LIST_FIELDS = `${ApiUrls.SRM_INTEGRATIONS}/freshservice/list-fields`;
    static readonly INTEGRATION_STATUS_V2 = `${ApiUrls.SRM_INTEGRATIONS}/freshservice/configuration-listing/{integration-type}`;
    static readonly FRESHSERVICE_CONFIGURED_FIELDS = `${ApiUrls.SRM_INTEGRATIONS}/freshservice/configured-fields/{alerting}/{budgeting}/{widgetId}`;
    static readonly FRESHSERVICE_CHILD_CUSTOMERS = `${ApiUrls.SRM_INTEGRATIONS}/freshservice/get-child-customers/{configuration-name}/{action}`;
    static readonly FRESHSERVICE_CREATE_TICKET = `${ApiUrls.SRM_INTEGRATIONS}/freshservice/create-ticket/{widgetId}`;

    static readonly TIMEZONE_ID =
        '/admin/miscellaneous/auth/filters/list-time-zones';
    static readonly ASSESSMENT_REPORT_DOWNLOAD = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/assessment/{assessment-id}/report`;
    static readonly ASSESSMENT_SUMMARY_REPORT_DOWNLOAD = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/assessment/{assessment-id}/summaryreport`;

    // SALESFORCE

    static readonly SALESFORCE_INTEGRATION_STATUS_CHECK = `${ApiUrls.SRM_INTEGRATIONS}/salesforce-integration/status`;
    static readonly SALESFORCE_INTEGRATION_GET_CONFIG = `${ApiUrls.SRM_INTEGRATIONS}/salesforce-integration/get-config/{id}`;
    static readonly SALESFORCE_INTEGRATION_GET_CONFIG_WIDGET = `${ApiUrls.SRM_INTEGRATIONS}/salesforce-integration/get-config-widget/{id}`;
    static readonly SALESFORCE_INTEGRATION_CREATE_TICKET = `${ApiUrls.SRM_INTEGRATIONS}/salesforce-integration/create-ticket-integration/{id}`;

    // Error logging
    static readonly API_LOGGER = `${ApiUrls.PARTNER_CENTER_PORTAL}/srm-logs`;
    static readonly SAVE_ACCESS_REPORT_ASSESSMENT = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/assessment/{assessment-id}/can-download-report`;

    // Notification Api
    static readonly CUSTOM_NOTIFICATION_API = `${ApiUrls.WHITELABELING_BASE_URL}{region}/custom-notification-console/notification.json`;
    //sign-up anaplan
    static readonly ANAPLAN_SIGN_UP = `${ApiUrls.IDENTITY_ACCESS_MGMNT_NAME}/customer-access-control/sign-up/anaplan`;
    static readonly GOOGLE_SIGN_UP = `${ApiUrls.IDENTITY_ACCESS_MGMNT_NAME}/customer-access-control/sign-up/google`;
    static readonly HPE = `${ApiUrls.IDENTITY_ACCESS_MGMNT_NAME}/customer-access-control/sign-up/hpe`;
    static readonly JUNIPER_SIGN_UP = `${ApiUrls.IDENTITY_ACCESS_MGMNT_NAME}/customer-access-control/sign-up/juniper`;
    // Billing Markup and Markdown APIs
    static readonly BILLING_MARKUP_CUSTOMER_LIST_API = `${ApiUrls.PARTNER_CENTER_PORTAL}/billing-markdown-configuration/list-customer`;
    static readonly BILLING_MARKUP_PRICE_TYPE_API = `${ApiUrls.PARTNER_CENTER_PORTAL}/billing-markdown-configuration/list-mark-type`;
    // Cache System
    static readonly CACHE_SYSTEM_REMOVE_DATA =
        '/cache-services/remove-data-from-cache';
    static readonly NEW_TIMEZONE_API = `${ApiUrls.SRM_GCP_FILTERS}/list-time-zones`;
    static readonly KUBERNETES_JOBS_API = `/kubernetes-jobs-trigger/`;
    static readonly TIME_RANGE = `/srm-governance/report/get-time-range`;
    static readonly GET_DOMAIN_ID_REGION = `${ApiUrls.LOGIN_API_PATH}/get-region/`;
    // Email Config API
    static readonly EMAIL_CONFIG_RULES = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/email-config`;
    static readonly GET_EMAIL_CONFIG_TRIGGER_POINTS = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/email-config/get-assessment-email-triggers`;
    static readonly GET_EMAIL_TEMPLATE_MACROS_FOR_ASSESSMENT = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/email-config/get-assessment-email-macros`;
    static readonly GET_EMAIL_TEMPLATE_MACROS_FOR_SIGNUP = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/email-config/get-signupconfig-email-macros`;
    static readonly GET_DEFAULT_EMAIL_CONFIG_RULES = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/email-config/get/default`;
    static readonly CRUD_EMAIL_CONFIG_RULES = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/email-config/{email_config_id}`;
    // Email Template API's
    static readonly GET_EMAIL_TEMPLATES = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/email-templates`;
    // Custom Signup Form API's
    static readonly GET_EMAIL_TEMPLATES_SIGNUP_FORM = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/email-templates?configuredFor=SIGNUPCONFIG`;
    static readonly CUSTOM_SIGNUP_FORM = `${ApiUrls.VIEW_AND_API_MANAGEMENT}/signup-config/get-by-url`;
    static readonly CUSTOM_SIGNUP_FORM_SUBMIT_API = `${ApiUrls.IDENTITY_ACCESS_MANAGEMENT}/customer-access-control/customer/sign-up`;
    //Privacy Policy Hyperlink Api to show Hyperlink on Login Page
    static readonly PRIVACY_POLICY_HYPERLINK = `${ApiUrls.SRM_INTEGRATIONS}/whitelabel/get-privacy-policy-for-domain`;
}
