<div class="overlay-container" *ngIf="showLoader | async">
    <div class="overlay-content">
        <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
    </div>
</div>
<app-table-generator
    *ngIf="tableGenInput"
    [tableInput]="tableGenInput"
    [tableData]="tableData"
    [widgetRef]="widgetRef"
    [overviewTemplateEnd]="overviewTemplateEnd"
></app-table-generator>

<ng-template #overviewTemplateEnd>
    <app-single-select-dropdown
        [filterInfo]="statusFilterInfo | async"
        (change)="statusChange($event)"
        [selectedValue]="defaultStatusValue"
    >
    </app-single-select-dropdown>
    <ng-container *ngIf="showTemplate | async">
        <app-single-select-dropdown
            [filterInfo]="clientFilterInfo | async"
            (change)="clientChange($event)"
            [selectedValue]="defaultClientId"
        >
        </app-single-select-dropdown>
    </ng-container>
</ng-template>
