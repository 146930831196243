export enum DescriptionPresignedUrlKeys {
    DESCRIPTION = 'descriptionPresignedUrl',
    DETAILED_DESCRIPTION = 'detailDescriptionPresignedUrl',
    REFERENCE = 'referenceLinksPresignedUrl',
    FAQ = 'faqsPresignedUrl',
    USE_CASES = 'useCasePresignedUrl',
    IMAGES = 'imageUrlKeys',
    VIDEOS = 'videoKey'
}
export interface IWidgetDescription {
    oldDescription?: IDescription;
    presignedUrl?: {
        [DescriptionPresignedUrlKeys.DESCRIPTION]: string;
    };
}

export interface IDescription {
    baseDescription: {
        type: string;
        heading: string;
        showHeading: boolean;
        value: string;
    }[];
    sections?;
    defaultPlaceholders?;
}

export enum DocumentTypes {
    IMAGES = 'Images',
    VIDEOS = 'Videos',
    DESCRIPTION = 'Description',
    DETAILED_DESCRIPTION = 'Detailed Description',
    REFERENCE = 'Reference Links',
    FAQ = 'FAQs',
    USE_CASES = 'Use Cases'
}

export interface IDetailedDescription {
    type: DocumentTypes;
    content: string;
    links?: IDetailedDescriptionLinks[];
    selectedType?: DocumentTypes;
    mediaData?: any;
}

export interface IDetailedDescriptionLinks {
    name: string;
    link: string;
}

/*
// -------Divide the description generator Ui into 2 types---------------------
// -------Type 1 is our the default UI with toggle button----------------------
// -------Type 2 is of only text description Ui used in Integration widget-----

*/

export enum DescriptionUiTypes {
    TYPE_1,
    TYPE_2
}

export type DescriptionAppearance = DescriptionUiTypes;
