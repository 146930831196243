<ng-container *ngIf="bodyTemplate">
    <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
</ng-container>
<app-form-generator
    *ngIf="formGenInput"
    [formGeneratorInput]="formGenInput"
    (formGroupRef)="formGroupRef = $event"
    [modalId]="modalInputData.modalId"
    (valuesChange)="
        valuesChange
            ? valuesChange(stepNumber, $event, formGenInput, formGroupRef)
            : null
    "
    [intitalFormGroup]="currentStepData && currentStepData.formGroupRef"
></app-form-generator>
