<div
	class="breadcrumb"
	[hidden]="
		(widgetRef.loadingData | async) ||
		!(
			widgetRef.widgetLinkingData &&
			widgetRef.widgetLinkingData.breadCrumbs &&
			widgetRef.widgetLinkingData.breadCrumbs.length
		)
	"
>
	<div
		class="patch"
		*ngIf="
			widgetRef.widgetLinkingData &&
			widgetRef.widgetLinkingData.breadCrumbs &&
			widgetRef.widgetLinkingData.breadCrumbs.length
		"
	>
		<span
			class="item-name"
			(click)="$event.stopPropagation(); goBackDrillDown(true)"
			>Home
			<span class="arrow-icon" appIcon [data]="arrowRightIcon"></span>
		</span>

		<span
			class="item-name"
			[class.large]="isLast"
			*ngFor="
				let item of widgetRef.widgetLinkingData.breadCrumbs;
				index as i;
				last as isLast;
				count as count
			"
			(click)="
				$event.stopPropagation();
				goBackDrillDown(null, count - (i + 1), isLast)
			"
			>{{ item }}
			<span
				class="arrow-icon"
				*ngIf="!isLast"
				appIcon
				[data]="arrowRightIcon"
			></span>
		</span>
	</div>
</div>

<div
	[ngClass]="
		(widgetRef.compareViewService.isCompareViewEnabled | async)
			? 'compare-view'
			: 'widget-data-container'
	"
	*ngIf="!(widgetRef.loadingData | async) && rowData && rowData.length"
>
	<div
		[ngClass]="
			widgetRef.widgetLinkingData
				? 'table-container-compare-view'
				: 'table-container'
		"
		appResizeObserver
		*ngIf="
			!(widgetRef.loadingData | async) &&
			(widgetRef.visibleSections | async).has(ViewType.TABLE)
		"
	>
		<app-only-table-generator
			[widgetRef]="widgetRef"
			[tableInput]="tableInputData"
			[tableData]="filteredSearchData"
			[getRowStyle]="getRowStyle"
			[showSearchBox]="true"
			(rowClicked)="tableRowClicked($event['data'], true)"
			(gridRef)="agGrid = $event"
			[autoFitColumns]="!(tableInputData.columns.length === 5)"
			[defaultColDef]="defaultColDef"
		></app-only-table-generator>
	</div>
</div>
