import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { Widget } from '../../classes/Widget';
import { ButtonColorType } from '../../enums/ButtonColorType';
import { ButtonType } from '../../enums/ButtonType';
import { IconType } from '../../enums/IconType';
import { IButtonGeneratorInput } from '../../interfaces/button-generator/IButtonGeneratorInput';
import { IIntegrationInput } from '../../interfaces/integration/IIntegrationInput';
import {
    DescriptionUiTypes,
    IDetailedDescription
} from '../../interfaces/widget/IWidgetDescription';
import { ModalService } from '../../services/modal/modal-service/modal.service';
import { IIcon } from './../../interfaces/icon-data/IIcon';

@Component({
    selector: 'app-integration',
    templateUrl: './integration.component.html',
    styleUrls: ['./integration.component.sass']
})
export class IntegrationComponent implements OnInit, OnChanges {
    @Input() integrationInput: IIntegrationInput;
    @Input() widgetRef: Widget;
    @Input() isConfigured: Boolean = false;
    @Input() description: any;
    @Output() onIntegrate = new EventEmitter<any>();
    readonly BACKGROUND_GRADIENT = `linear-gradient(315deg, #d9d9d9a1 0%, #f6f2f242 74%)`;
    buttonInputs: IButtonGeneratorInput;
    IconType = IconType;
    DescriptionUiTypes = DescriptionUiTypes;
    widgetMoreInfo: IDetailedDescription[];
    settingIcon: IIcon = {
        type: IconType.SVG,
        class: 'settings',
        extraClass: 'svg-white-fill'
    };
    constructor(public modalService: ModalService) {}

    ngOnInit(): void {
        this.buttonInputs = {
            buttonName: 'Add Integration',
            buttonType: ButtonType.FLAT,
            buttonColorType: ButtonColorType.PRIMARY,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.onIntegrate.emit(buttonRef);
            }
        };
    }
    ngOnChanges(changes: SimpleChanges) {
        if (
            this.integrationInput &&
            this.integrationInput.buttons &&
            this.integrationInput.buttons.length
        ) {
            this.filterButtons();
        }
    }
    executeButtonFunction(button: IButtonGeneratorInput) {
        if (button.executeFunctionAfterConfirmation) {
            if (!button.executeFunctionAfterConfirmation.function) {
                button.executeFunctionAfterConfirmation.function =
                    button.function;
            }
            this.widgetRef.modalService.openConfirmationModal(
                button.executeFunctionAfterConfirmation
            );
        } else {
            button.function(button);
        }
    }
    filterButtons(): void {
        const buttons = [
            'Integrate',
            'Add New Integrate',
            'List',
            'View',
            'Delete',
            'Edit',
            'Test'
        ];
        let sortedButtons = [];
        buttons.forEach((buttonKey) => {
            const buttonNames = this.integrationInput.buttons.map(
                (ele) => ele.buttonName
            );
            const indexOf = (arr, val) =>
                arr.reduce(
                    (acc, ele, i) =>
                        ele === val || ele.includes(val) ? [...acc, i] : acc,
                    []
                );
            const indexs = indexOf(buttonNames, buttonKey);
            if (indexs && indexs.length) {
                indexs.forEach((index) => {
                    sortedButtons.push(this.integrationInput.buttons[index]);
                });
            }
        });
        if (sortedButtons && sortedButtons.length) {
            sortedButtons = sortedButtons.map(
                (button: IButtonGeneratorInput) => {
                    if (button.buttonName === 'Integrate') {
                        button.buttonName = 'Add New Integrate';
                    }
                    return button;
                }
            );
        }
        this.integrationInput.buttons = Helper.cloneDeep(sortedButtons);
    }
}
