<div
    class="filter-handler-content"
    [style.overflowY]="sectionLoader ? 'hidden' : 'auto'"
>
    <ng-container
        *ngIf="
            widgetIds &&
            widgetIds.length &&
            filterStoreKey &&
            (filtersService.filtersPageData | async) &&
            (filtersService.filtersPageData | async).get(filterStoreKey) &&
            sortedFiltersIds &&
            sortedFiltersIds.length
        "
    >
        <ng-container *ngFor="let filter of sortedFiltersIds">
            <app-field-generator
                [hideLogicalOperatorRadioButtons]="
                    hideLogicalOperatorRadioButtons
                "
                [lightMode]="lightMode !== undefined ? lightMode : true"
                [enableTemp]="true"
                [filterStoreKey]="filterStoreKey"
                [filterData]="
                    filtersService.filtersPageData
                        .getValue()
                        .get(filterStoreKey)
                        .get(filter)
                "
                [style.pointer-events]="isFieldDisable? 'none': 'auto'"
            >
            </app-field-generator>
        </ng-container>
    </ng-container>

    <ng-container
        *ngIf="
            sortedFiltersIds &&
            sortedFiltersIds.length &&
            filtersData &&
            filtersData.size &&
            sortedFiltersIds &&
            sortedFiltersIds.length
        "
    >
        <ng-container *ngFor="let filter of sortedFiltersIds">
            <app-field-generator
                [hideLogicalOperatorRadioButtons]="
                    hideLogicalOperatorRadioButtons
                "
                [lightMode]="lightMode !== undefined ? lightMode : true"
                [enableTemp]="true"
                [filterStoreKey]="filterStoreKey"
                [filterData]="filtersData.get(filter)"
                [style.pointer-events]="isFieldDisable? 'none': 'auto'"
            >
            </app-field-generator>
        </ng-container>
    </ng-container>

    <div
        class="loader"
        style="
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        "
        *ngIf="sectionLoader"
    >
        <mat-spinner [diameter]="22"></mat-spinner>
    </div>

    <div
        style="
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        "
        *ngIf="sortedFiltersIds && !sortedFiltersIds.length"
    >
        No Applicable Filters
    </div>
</div>
