import { DOCUMENT } from '@angular/common';
import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GridOptions } from 'ag-grid-community';
import * as $ from 'jquery';
import { Helper } from 'src/app/shared/classes/Helper';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { ServicenowVariableSetFormComponent } from '../servicenow-variable-set-form/servicenow-variable-set-form.component';
import { ButtonType } from './../../../../enums/ButtonType';

@Component({
    selector: 'app-servicenow-table',
    templateUrl: './servicenow-table.component.html',
    styleUrls: ['./servicenow-table.component.sass']
})
export class ServicenowTableComponent implements OnInit {
    @ViewChild('agGrid', { static: false }) agGrid: GridOptions;
    @Input('variableSets') variableSets;
    @Output('change') change = new EventEmitter();
    agGridIcons = Helper.getAgGridIcons();
    styles = getComputedStyle(this.document.documentElement);
    tableData = [];
    colDefs = [];
    buttonInput: IButtonGeneratorInput;

    constructor(
        public dialog: MatDialog,
        @Inject(DOCUMENT) private document: Document,
        private modalService: ModalService
    ) {}

    ngOnInit() {
        this.variableSets.forEach((varr) => {
            this.colDefs.push({
                headerName: varr['label'],
                field: varr['id']
            });
        });

        this.colDefs.push({
            headerName: '',
            field: 'remove',
            cellRenderer: (data) => {
                const scope = this;
                const div = document.createElement('div');
                const btn = document.createElement('button');
                btn.innerHTML = `<i class='fas fa-times'></i>`;
                $(btn).hover(function () {
                    $(this).css({
                        background: scope.styles.getPropertyValue('--accent'),
                        color: 'white'
                    });
                });
                $(btn).mouseleave(function () {
                    $(this).css({
                        background: '0',
                        color: scope.styles.getPropertyValue('--accent')
                    });
                });
                $(btn).css({
                    border:
                        '1px solid ' +
                        scope.styles.getPropertyValue('--accent'),
                    background: '0',
                    color: scope.styles.getPropertyValue('--accent'),
                    padding: '4px',
                    cursor: 'pointer',
                    margin: '1px 3px'
                });

                $(btn).on('click', () => {
                    scope.deleteRow(data.rowIndex);
                });

                div.appendChild(btn);
                return div;
            }
        });
        this.buttonInput = {
            buttonName: 'Add',
            showLoader: false,
            buttonType: ButtonType.RAISED,
            buttonColorType: ButtonColorType.PRIMARY,
            function: this.addRowOpenModal.bind(this)
        };
    }

    addRowOpenModal() {
        const modalData: IModalData = {
            modalName: 'Set Configuration',
            modalIcon: {
                type: null,
                class: ''
            },
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 55,
            modalWidthVw: 45,
            noHeader: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ServicenowVariableSetFormComponent,
                        payload: {
                            data: {
                                data: this.variableSets,
                                func: this.addRow.bind(this)
                            }
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.modalService.openModal(modalData);
        // this.dialog.open(ServicenowVariableSetFormComponent, {
        //     data: {
        //         data: this.variableSets,
        //         func: this.addRow.bind(this),
        //     },
        // });
    }

    addRow(data) {
        this.tableData.push(data);
        this.agGrid.api.setRowData(this.tableData);
        this.change.emit(this.tableData);
    }

    deleteRow(index) {
        this.tableData.splice(index, 1);
        this.agGrid.api.setRowData(this.tableData);
        this.change.emit(this.tableData);
    }
}
