import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IIntegrationApiResponse } from 'src/app/shared/interfaces/api/portlets/IIntegrationApiResponse';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IIntegrationInput } from 'src/app/shared/interfaces/integration/IIntegrationInput';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { RedirectionHandleService } from 'src/app/shared/services/redirection-handle/redirection-handle.service';
import { FormGeneratorModalComponent } from '../../../modal-templates/form-generator-modal/form-generator-modal.component';
import { IframeOpenerModalComponent } from '../../../modal-templates/iframe-opener-modal/iframe-opener-modal.component';
import { IntegrationStates } from './../../../../classes/IntegrationStates';
import { ButtonType } from './../../../../enums/ButtonType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-zoho-integration',
    templateUrl: './zoho-integration.component.html',
    styleUrls: ['./zoho-integration.component.sass']
})
export class ZohoIntegrationComponent implements OnInit {
    integrationInput: IIntegrationInput = null;
    widgetRef: Widget;
    isParentIntegrated = false;
    isSelfIntegrated = false;
    isConfigured: Boolean = false;
    formGenInput: IFormGeneratorInput;

    constructor(
        widgetData: WidgetInjectedData,
        private redirectionHandleService: RedirectionHandleService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        this.integrationInput = {
            integrationName: this.widgetRef.widgetData.widgetInfo.text,
            integrationCategory: 'ITSM Integration',
            imageUrl: 'assets/integrations/zoho_2.png',
            buttons: [],
            imagePadding: 8
        };
    }

    generateButtons(response) {
        this.formGenInput = {
            formName: 'Zoho',
            state: this.isSelfIntegrated ? FormState.EDIT : FormState.CREATE,
            submitButton: {
                buttonName: this.isSelfIntegrated ? 'Update' : 'Integrate',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: (
                    buttonRef: IButtonGeneratorInput,
                    formGroup: FormGroup,
                    modalId: Symbol
                ) => {
                    if (formGroup.valid) {
                        buttonRef.loader = true;
                        const input = {
                            ...formGroup.value,
                            integrationType: 'ZOHO'
                        };
                        localStorage.setItem(
                            'zohoFormData',
                            JSON.stringify(input)
                        );
                        this.redirectionHandleService.widgetIdUsedForRedirection =
                            this.widgetRef.widgetData.widgetId;
                        const url =
                            `https://accounts.zoho.` +
                            formGroup.get('region').value +
                            `/oauth/v2/auth?client_id=${
                                GlobalConfiguration.ZOHO_CLIENT_ID
                            }&response_type=code&redirect_uri=${
                                this.redirectionHandleService.redirectionUrl
                            }&scope=Desk.tickets.WRITE,Desk.tickets.READ&state=${
                                this.isSelfIntegrated
                                    ? IntegrationStates.ZOHO_STATE_UPDATE
                                    : IntegrationStates.ZOHO_STATE
                            }&access_type=offline`;
                        this.redirectionHandleService.redirectTo(
                            this.widgetRef.widgetData.widgetId,
                            url
                        );
                    }
                }
            },
            fields: [
                {
                    name: 'zohoOrgId',
                    placeholder: 'Enter Organization ID',
                    label: 'Organization ID',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Organization ID is required'
                        }
                    ],
                    value: this.isSelfIntegrated
                        ? response['self']['zohoOrgId']
                        : ''
                },
                {
                    name: 'region',
                    placeholder: 'Select a region',
                    label: 'Region',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    apiInfo: this.widgetRef.widgetData.widgetInfo['region'],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Region is required'
                        }
                    ],
                    value: this.isSelfIntegrated
                        ? response['self']['region']
                        : ''
                },
                {
                    name: 'widgetCode',
                    placeholder: 'Widget Code',
                    label: 'Widget Code',
                    fieldType: FilterType.TEXTAREA,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    extraClass: 'tw-items-center',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Widget code is required'
                        }
                    ],
                    value: this.isSelfIntegrated
                        ? response['self']['widgetCode']
                        : '',
                    fieldStyle: {
                        height: '40%'
                    },
                    textAreaStyles: {
                        'min-height': '60px'
                    }
                }
            ],
            extraClass: 'integration-new-styling'
        };
        if (this.isSelfIntegrated || this.isParentIntegrated) {
            // Adding play button
            this.integrationInput.buttons.push({
                buttonName: 'Test',
                buttonIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-play'
                },
                buttonType: ButtonType.CIRCLE,
                buttonColorType: ButtonColorType.SUCCESS,
                hoverText: 'Test Zoho',
                function: () => {
                    const modalArgs: IModalData = {
                        modalName: 'Create Ticket',
                        sourceId:
                            this.widgetRef.widgetData.widgetUniqueIdentifier,
                        modalType: ModalType.MIDDLE,
                        modalIcon: {
                            type: IconType.FONTAWSOME,
                            class: 'fas fa-play'
                        },
                        extraStepContainerClass: 'remove-form-space',
                        modalSteps: [
                            {
                                stepName: 'Create Ticket',
                                stepData: {
                                    componentToLoad: IframeOpenerModalComponent,
                                    payload: {
                                        data: {
                                            widgetCode: this.isSelfIntegrated
                                                ? response.self.widgetCode
                                                : this.isParentIntegrated
                                                ? response.parent.widgetCode
                                                : null,
                                            isHtml: true
                                        }
                                    }
                                }
                            }
                        ],
                        modalWidthVw: 60,
                        modalHeightVh: 60
                    };
                    this.widgetRef.modalService.openModal(modalArgs);
                }
            });

            if (this.isSelfIntegrated) {
                // Adding edit button

                this.integrationInput.buttons.push({
                    buttonName: 'Edit',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-wrench'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    hoverText: 'Edit Zoho',
                    function: () => {
                        const modalArgs: IModalData = {
                            modalName: 'Edit Zoho',
                            sourceId:
                                this.widgetRef.widgetData
                                    .widgetUniqueIdentifier,
                            modalType: ModalType.MIDDLE,
                            modalIcon: {
                                type: IconType.FONTAWSOME,
                                class: 'fas fa-wrench'
                            },
                            extraStepContainerClass: 'remove-form-space',
                            modalSteps: [
                                {
                                    stepName: 'Edit Zoho',
                                    resetModal: true,
                                    stepData: {
                                        componentToLoad:
                                            FormGeneratorModalComponent,
                                        payload: {
                                            data: this.formGenInput
                                        }
                                    }
                                }
                            ],
                            modalWidthVw: 60,
                            modalHeightVh: 57,
                            noBottomPadding: true
                        };
                        this.widgetRef.modalService.openModal(modalArgs);
                    }
                });

                // Adding delete button
                this.integrationInput.buttons.push({
                    buttonName: 'Delete',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-trash'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.WARN,
                    hoverText: 'Remove Zoho',
                    function: (modalId: Symbol) => {
                        // Delete integration
                        const args = Helper.generateHitApiConfig(
                            this.widgetRef.widgetData.widgetInfo.delete
                        );
                        args.input = {
                            integrationType: 'ZOHO'
                        };
                        args.function = (response) => {
                            this.widgetRef.notificationsService.showSnackBar(
                                `Zoho integration removed successfully`
                            );
                            this.widgetRef.modalService.closeModal(
                                null,
                                modalId
                            );
                            this.widgetRef.refreshWidget();
                        };
                        args.errorFunction = (error) => {
                            this.widgetRef.notificationsService.showSnackBar(
                                error.error.message,
                                true
                            );
                            this.widgetRef.modalService.closeModal(
                                null,
                                modalId
                            );
                        };
                        new HitApi(
                            args,
                            this.widgetRef.httpService,
                            this.widgetRef.ngZone
                        ).hitApi();
                    },
                    executeFunctionAfterConfirmation: {
                        modalName: 'Remove Zoho Integration',
                        modalIcon: {
                            type: IconType.FONTAWSOME,
                            class: 'far fa-trash-alt'
                        },
                        contextIcon: {
                            extraClass: 'color-accent',
                            type: IconType.FONTAWSOME,
                            class: 'fas fa-exclamation-triangle'
                        },
                        confirmationMessage: `Are you sure you want to remove zoho ?`,
                        buttonText: 'Remove',
                        buttonColorType: ButtonColorType.WARN,
                        loaderOnExec: true
                    }
                });
            }

            if (this.isParentIntegrated && !this.isSelfIntegrated) {
                // Adding create button
                this.integrationInput.buttons.push({
                    buttonName: 'Integrate',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-plus'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    hoverText: 'Integrate Zoho',
                    function: () => {
                        const modalArgs: IModalData = {
                            modalName: 'Integrate Zoho',
                            sourceId:
                                this.widgetRef.widgetData
                                    .widgetUniqueIdentifier,
                            modalType: ModalType.MIDDLE,
                            modalIcon: {
                                type: IconType.FONTAWSOME,
                                class: 'fas fa-ticket-alt'
                            },
                            extraStepContainerClass: 'remove-form-space',
                            modalSteps: [
                                {
                                    stepName: 'Integrate Zoho',
                                    stepData: {
                                        componentToLoad:
                                            FormGeneratorModalComponent,
                                        payload: {
                                            data: this.formGenInput
                                        }
                                    }
                                }
                            ],
                            modalWidthVw: 60,
                            modalHeightVh: 57
                        };
                        this.widgetRef.modalService.openModal(modalArgs);
                    }
                });
            }
        }
    }

    onIntegrate(formGenInput) {
        const modalArgs: IModalData = {
            modalName: 'Integrate Zoho',
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            extraStepContainerClass:
                'remove-form-space add-custom-bottom-padding',
            modalSteps: [
                {
                    stepName: 'Integrate Zoho',
                    resetModal: true,
                    stepData: {
                        componentToLoad: FormGeneratorModalComponent,
                        payload: {
                            data: formGenInput
                        }
                    }
                }
            ],
            modalWidthVw: 60,
            modalHeightPx: 400
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
    bindData(response: IIntegrationApiResponse): void {
        this.isConfigured = false;
        if ('integration' in response && !response.integration) {
            this.isParentIntegrated = false;
            this.isSelfIntegrated = false;
        }
        if (response.parent && response.parent.integration) {
            this.isParentIntegrated = true;
            this.isSelfIntegrated = false;
        }
        if (response.self && response.self.integration) {
            this.isSelfIntegrated = true;
            this.isConfigured = true;
        }

        this.integrationInput.buttons = [];
        this.generateButtons(response);
        this.widgetRef.endLoader();
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
