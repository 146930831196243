import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IconType } from 'src/app/shared/enums/IconType';
import { IFilterData } from 'src/app/shared/interfaces/filter/IFilterData';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { MulticurrencyFilterSelectorService } from 'src/app/shared/services/multicurrency-filter-selector/multicurrency-filter-selector.service';

@Component({
    selector: 'app-currency-filter',
    templateUrl: './currency-filter.component.html',
    styleUrls: ['./currency-filter.component.sass']
})
export class CurrencyFilterComponent implements OnInit, OnChanges {
    @Input() filterInfo: IFilterInfo;
    @Input() filterData: IFilterData;
    @Input() disabled;
    @Input() disableMessage;
    @Input() filterStoreKey;
    @Input() enableTemp: boolean;
    @Input() selectedValue;

    //If date range is just to be shown as a dummy, set its value to true
    @Input() dummy: boolean;

    refreshListing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    exchangeRate;
    customExchangeRate = false;
    defaultExchangeRate;
    resetFilters$ = null;
    infoIcon: IIcon = {
        type: IconType.MATICON,
        class: 'info'
    };

    constructor(
        private filtersService: FiltersService,
        private multiCurrencyFilterService: MulticurrencyFilterSelectorService
    ) { }

    ngOnInit(): void {
        if (this.selectedValue) {
            this.defaultExchangeRate = this.selectedValue.exchangeRate;
            this.exchangeRate = this.selectedValue.exchangeRate;
        }
        this.resetFilters$ = this.filtersService.resetFilters.subscribe(
            (res) => {
                if (!res) {
                    this.customExchangeRate = false;
                }
            }
        );
    }

    refreshFilter() {
        this.refreshListing.next(true);
    }

    singleDropdownValueChange($event) {
        this.customExchangeRate = false;
        this.selectedValue = $event;
        this.defaultExchangeRate = this.selectedValue.exchangeRate;
        this.exchangeRate = this.selectedValue.exchangeRate;
        
        // Handling for Multi Currency Selector only.
        if (this.filterStoreKey === 'REPORT_FILTERS') {
            if (this.multiCurrencyFilterService.duplicateCurrency.length === 0) {
                this.multiCurrencyFilterService.duplicateCurrency.push($event.label);
                this.multiCurrencyFilterService.multiCurrencyFilters.clear();
            } else {
                this.multiCurrencyFilterService.duplicateCurrency.shift();
                this.multiCurrencyFilterService.duplicateCurrency.splice(0, 0, $event.label);
            }   
        }
        this.multiCurrencyFilterService.checkDuplicateFilter();
        const value = $event;
        value.defaultCurrencyValue = this.customExchangeRate;
        if (this.enableTemp) {
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value,
                    filterInfo: this.filterInfo
                });
        } else {
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value,
                    filterInfo: this.filterInfo
                });
        }
        if (
            !this.filtersService.filterChanged.includes(
                this.filterData.filterId
            )
        ) {
            this.filtersService.filterChanged.push(this.filterData.filterId);
        }
    }

    ngOnChanges(change) {
        if (change.selectedValue) {
            this.selectedValue = change.selectedValue.currentValue;
            this.exchangeRate = this.selectedValue.exchangeRate;
        }
        if (change.filterInfo) {
            this.filterInfo = change.filterInfo.currentValue;
        }
    }

    exchangeRateChanged($event) {
        if (!$event || $event < 0) {
            return;
        }
        this.exchangeRate = $event;
        const value = JSON.parse(
            JSON.stringify(
                this.filtersService.filtersDataTemp
                    .get(this.filterStoreKey)
                    .get(this.filterData.filterId).value
            )
        );
        value.exchangeRate = this.exchangeRate;
        value.defaultCurrencyValue = this.customExchangeRate;

        if (this.enableTemp) {
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value,
                    filterInfo: this.filterInfo
                });
        } else {
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value,
                    filterInfo: this.filterInfo
                });
        }
        if (
            !this.filtersService.filterChanged.includes(
                this.filterData.filterId
            )
        ) {
            this.filtersService.filterChanged.push(this.filterData.filterId);
        }
    }

    defaultExchangeRateChanged($event) {
        if (!$event.checked && this.exchangeRate) {
            this.exchangeRate = this.defaultExchangeRate;
        }
    }

    ngOnDestroy() {
        if (this.resetFilters$) {
            this.resetFilters$.unsubscribe();
        }
    }
}
