import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { ButtonColorType } from './../../../../enums/ButtonColorType';

@Component({
    selector: 'app-slack-testing-modal',
    templateUrl: './slack-testing-modal.component.html',
    styleUrls: ['./slack-testing-modal.component.sass']
})
export class SlackTestingModalComponent implements OnInit {
    executionFunc = null;
    formGenInput: IFormGeneratorInput = null;
    stepData: Object = null;
    resetForm: Function = null;
    resetSubscription: Subscription;
    constructor(
        private modalInputData: ModalInjectedData,
        public modalService: ModalService
    ) {
        this.stepData = modalInputData.data['stepData'];
    }

    ngOnInit(): void {
        this.executionFunc = this.modalInputData.data;
        this.setFormData();
        this.resetSubscription = this.modalService.resetModal.subscribe(() => {
            const form = {
                slackChannelId: this.stepData
                    ? this.stepData['slackChannelId']
                    : [],
                slackMessageInput: this.stepData
                    ? this.stepData['slackMessageInput']
                    : ''
            };
            this.resetForm(form);
        });
    }

    setFormData() {
        this.formGenInput = {
            formName: 'Email Testing',
            state: FormState.CREATE,
            submitButton: {
                buttonName: 'Send',
                showLoader: true,
                function: (buttonRef, formGroup: FormGroup) => {
                    this.executionFunc(
                        buttonRef,
                        formGroup,
                        this.modalInputData.modalId
                    );
                },
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY
            },
            fields: [
                {
                    label: 'Users',
                    name: 'users',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    placeholder: 'Select Users',
                    showLabel: true,
                    appearance: 'legacy',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Select atleast one user'
                        }
                    ],
                    apiInfo: {
                        requestType: RequestType.GET,
                        customInput: null,
                        host: null,
                        apiRouteSuffix: ApiUrls.SLACK_LIST_USERS,
                        authorization: AuthorizationType.BEARER_TOKEN
                    },
                    showKey: 'label',
                    getKey: 'id',
                    responseValueKey: 'usersInfo'
                },
                {
                    label: 'Channels',
                    name: 'channels',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    placeholder: 'Select Channels',
                    showLabel: true,
                    appearance: 'legacy',
                    validations: null,
                    apiInfo: {
                        requestType: RequestType.GET,
                        customInput: null,
                        host: null,
                        apiRouteSuffix: ApiUrls.SLACK_LIST_CHANNELS,
                        authorization: AuthorizationType.BEARER_TOKEN
                    },
                    showKey: 'label',
                    getKey: 'id',
                    responseValueKey: 'channalsInfo'
                },
                {
                    label: 'Message',
                    name: 'message',
                    fieldType: FilterType.TEXTAREA,
                    extraClass: 'tw-items-center',
                    placeholder: 'Enter message',
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Message is required'
                        }
                    ]
                }
            ],
            extraClass: 'integration-styling'
        };
    }

    ngOnDestroy() {
        this.resetSubscription.unsubscribe();
    }
}
