import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as ipaddr from 'ipaddr.js';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class MultiCIDRValidator extends CustomValidator {
    key = CustomValidatorKey.MULTI_CIDR;
    customErrorMessage: string = null;

    validatorFunction(control: AbstractControl): ValidationErrors {
        this.customErrorMessage = null;

        if (!control.value) {
            return null;
        }

        const obj = { [this.key]: true };

        const IPv4 =
            /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2])){0,1}$/;
        const IPv6 =
            /^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*(\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))?$/;

        const ipValues: string[] = control.value.split(',');
        const validatedIPList: string[] = [];
        const ipStruct: {
            ip: string;
            prefix: number;
            cidr: string;
            isCidr: boolean;
        }[] = [];

        if (ipValues.length) {
            let isIPv4Present: boolean = false;
            let isIPv6Present: boolean = false;
            for (let index = 0; index < ipValues.length; index++) {
                const ip = ipValues[index].trim();
                if (ip.match(IPv4) && !validatedIPList.includes(ip)) {
                    validatedIPList.push(ip);
                    isIPv4Present = true;
                } else if (ip.match(IPv6) && !validatedIPList.includes(ip)) {
                    validatedIPList.push(ip);
                    isIPv6Present = true;
                } else {
                    return obj;
                }

                if (isIPv4Present === true && isIPv6Present === true) {
                    return obj;
                }

                const ipKeyValue = ip.split('/');
                ipStruct.push({
                    ip: ipKeyValue[0],
                    prefix: ipKeyValue[1] ? +ipKeyValue[1] : null,
                    cidr: ip,
                    isCidr: ip.includes('/'),
                });
            }
        }

        if (ipStruct.length > 1) {
            for (let i = 0; i < ipStruct.length; i++) {
                const ipData_i = ipStruct[i];
                for (let j = 0; j < ipStruct.length; j++) {
                    if (i !== j) {
                        const ipData_j = ipStruct[j];
                        const addr = ipaddr.parse(ipData_i.ip);
                        if (
                            ipData_j.isCidr &&
                            addr.match(ipaddr.parseCIDR(ipData_j.cidr))
                        ) {
                            this.customErrorMessage = `"${ipData_i.cidr}" overlaps the subnet "${ipData_j.cidr}"`;
                            return obj;
                        }
                    }
                }
            }
        }
        return null;
    }
}
