<div class="billing-markup-wrapper">
    <app-table-generator
        [widgetRef]="widgetRef"
        [tableInput]="tableGeneratorInput"
        (gridRef)="agGrid = $event"
    ></app-table-generator>
</div>

<ng-template #deleteTemplate>
    <div class="template-wrapper">
        <span class="warning-icon" appIcon [data]="warningIcon"></span>
        <div class="content-wrapper">
            <span class="tw-font-bold"> Note** </span>
            By selecting delete , the selected custom price Configuration will
            be deleted for all the associated customers. Are you sure you want
            to delete the configuration?
        </div>
    </div>
</ng-template>
