<div
    class="controls"
    *ngIf="!(widgetRef.loadingData | async) && rowData && rowData.length"
>
    <div class="table-search">
        <app-table-search
            [searchFunction]="onQuickFilterChanged.bind(this)"
        ></app-table-search>
    </div>
</div>

<div
    class="tw-flex tw-items-center tw-mb-1 tw-relative misc"
    *ngIf="!(widgetRef.loadingData | async) && tableHeading.value"
>
    <div class="tw-absolute navigation-buttons">
        <span
            class="
                tw-inline-flex
                tw-my-0
                tw-mx-1
                tw-h-8
                tw-w-8
                tw-rounded-full
                tw-justify-center
                tw-items-center
                tw-text-fs-200
                tw-cursor-pointer
                drillIcon
            "
            (click)="goBackDrillDown(true)"
        >
            <mat-icon>home</mat-icon>
        </span>
        <span
            class="
                tw-inline-flex
                tw-my-0
                tw-mx-1
                tw-h-8
                tw-w-8
                tw-rounded-full
                tw-justify-center
                tw-items-center
                tw-text-fs-200
                tw-cursor-pointer
                drillIcon
            "
            (click)="goBackDrillDown()"
        >
            <mat-icon>arrow_back</mat-icon>
        </span>
    </div>
    <div
        class="
            tw-my-0
            tw-mx-auto
            tw-flex
            tw-text-fs-200
            tw-font-semibold
            tw-rounded-full
            tw-py-2
            tw-px-3
            table-heading
        "
    >
        <div class="heading-name">{{ tableHeading.key }} :</div>
        <div class="tw-break-normal heading-value">
            &nbsp;{{ tableHeading.value }}
        </div>
    </div>
</div>
<ag-grid-angular
    *ngIf="!(widgetRef.loadingData | async) && !widgetRef.extraMessage.value"
    #agGrid
    style="width: 100%; min-height: 150px"
    [style.height.px]="215"
    class="ag-theme-balham"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [animateRows]="true"
    [rowHeight]="37"
    [rowSelection]="rowSelection"
    [gridAutoHeight]="rowData.length > 3 ? 'false' : 'true'"
    [suppressFieldDotNotation]="true"
    (rowClicked)="tableRowClicked($event['data'])"
    (gridReady)="onGridReady($event)"
    [defaultColDef]="defaultColDef"
    [icons]="agGridIcons"
    [rowClassRules]="widgetRef.tableRowClassRules"
    [frameworkComponents]="frameworkComponents"
>
</ag-grid-angular>
