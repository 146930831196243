<div class="button-container" *ngIf="!(widgetRef.loadingData | async)">
    <button
        type="button"
        mat-stroked-button
        class="clickable btn-primary-stroked filled"
        color="primary"
        (click)="requestToBuy()"
        [class.disabled]="!isAllowed"
        [matTooltip]="message"
    >
        Request To Buy
        <mat-spinner
            *ngIf="buyLoader"
            style="margin-left: 8px"
            class="light-theme"
            [diameter]="16"
        ></mat-spinner>
    </button>
</div>
