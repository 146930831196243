export class Messages {
    static readonly NO_DATA_AVAILABLE = 'No data available';
    static readonly DEFAULT_ERROR =
        'Error loading widget. Please contact support if issue persists.';
    static readonly NO_FILTERS = 'No Applicable Filters';
    static readonly FEATURE_DISABLE_MESSAGE =
        'Upgrade to premium version to use this feature';
    static readonly IOT_MESSAGES = [
        'Please wait as our best minds work on your data.'
        // 'Please wait, your data is being processed.',
        // 'It looks like this is taking longer than you expected',
        // 'Things worth having are worth waiting for.',
        // 'Thank you for being patient…Your data is being processed.',
        // 'Be a little patient…your data is on the way…',
        // 'Hold your horses…you are almost there',
        // 'Please wait your data is almost ready.',
        // 'Sorry for the delay…',
    ];
    static readonly NO_CONVENTIONS_FOUND = 'No conventions found';
    static readonly ALL_FILTERS_APPLIED = 'All filters are already applied.';
    static readonly GRAPH_NOT_SUPPORTED =
        'Graph is not supported for this range of data.';
    static readonly GRAPH_ZERO_COST =
        'Unable to display the graph due to zero cost.';
    static readonly FILL_ALL_FIELDS = 'Please fill in all the required fields';
    static readonly NO_WIDGET_MAPPED_TO_PORTLET =
        'No widget mapped with portlet. Check portlet type and versioning again';
    static readonly LOGIN_API_INVALID_RESPONSE =
        'Invalid information received.Please contact suppport.';
    static readonly ROOT_LICENSE_ACCEPT =
        'Please contact your root user for accepting the license agreement.';
    static readonly INTEGRATION_ERROR = 'Error while integration. Try again';
    static readonly DELAY_MESSAGE =
        'Please be patient , it may take some time.';
    static readonly GMAIL_INTEGRATED = 'Gmail integrated successfully';
    static readonly GMAIL_INTEGRATED_ERROR = 'Error integrating gmail';
    static readonly SLACK_INTEGRATED = 'Slack integrated successfully';
    static readonly SLACK_INTEGRATED_ERROR = 'Error integrating slack';
    static readonly O365_INTEGRATED = 'O365 integrated successfully';
    static readonly O365_INTEGRATED_ERROR = 'Error integrating O365';
    static readonly ZOHO_INTEGRATED = 'Zoho integrated successfully';
    static readonly ZOHO_UPDATED = 'Zoho updated successfully';
    static readonly ZOHO_INTEGRATED_ERROR = 'Error integrating Zoho';
    static readonly NAGIOS_INTEGRATED = 'Nagios integrated successfully.';
    static readonly NAGIOS_INTEGRATED_ERROR = 'Error integrating Nagios.';
    static readonly ACCOUNT_ONBOARDING_SUCCESS = 'Account added successfully';
    static readonly ACCOUNT_UPDATE_LIST = 'Account details succesfully updated';
    static readonly ACCOUNT_DELETE_ERROR = 'Error deleting account';
    static readonly B_TO_F_FORM_GEN_SUCCESS = 'Action performed successfully';
    static readonly NO_CUSTOM_WIDGET = 'CREATE YOUR FIRST CUSTOM WIDGET';
    static readonly NO_CUSTOM_QUERY = 'CREATE YOUR FIRST CUSTOM QUERY';
    static readonly WIDGET_CREATION_SAVE_AS_DRAFT = 'Saved successfully';
    static readonly FORM_BUILDER_ERRORS = {
        EMPTY_DROPDOWN_LIST: 'All the dropdown must have atleast one value',
        EMPTY_FIELD_LIST: 'Add atleast one field to proceed'
    };
    static readonly REQUEST_FORM_SUBMITTED =
        'Request form submitted successfully';
    static readonly NO_WIDGET_DOCUMENTATION =
        'CREATE YOUR WIDGET DOCUMENTATION';
    static readonly WHITELIST_SUCCESS = 'Whitelisting successful';
    static readonly WHITELIST_ERROR =
        'Unable to update the compliance. Please Try Again';
    static readonly CIDR_INVALID = 'Invalid CIDR';
    static readonly WIDGET_ACTION_NO_RESOURCE_SELECTED =
        'Select atleast one resource to perform any action';
    static readonly WIDGET_ACTION_BULK_REMEDIATE = 'Fix Security Rule';
    static readonly SECURITY_REMEDIATE_SUCCESS =
        'Security rule(s) updated successfully for account(s) with sufficient permission(s).';
    static readonly ACCOUNT_TEMPORARILY_SUSPENDED =
        'The account has been temporarily suspended. Please contact support team to resume the account.';
}
