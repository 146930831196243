import { Component, OnInit } from '@angular/core';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IIntegrationApiResponse } from 'src/app/shared/interfaces/api/portlets/IIntegrationApiResponse';
import { IIntegrationInput } from 'src/app/shared/interfaces/integration/IIntegrationInput';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { FreshserviceIntegrationStepOneComponent } from '../../../modal-templates/integration/freshservice-modal/freshservice-integration-step-one/freshservice-integration-step-one.component';
import { FreshserviceIntegrationStepThreeComponent } from '../../../modal-templates/integration/freshservice-modal/freshservice-integration-step-three/freshservice-integration-step-three.component';
import { FreshserviceIntegrationStepTwoComponent } from '../../../modal-templates/integration/freshservice-modal/freshservice-integration-step-two/freshservice-integration-step-two.component';
import { FreshserviceIntegrationsTableComponent } from '../../../modal-templates/integration/freshservice-modal/freshservice-integrations-table/freshservice-integrations-table.component';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';

@Component({
    selector: 'app-freshservice-integration-v2',
    templateUrl: './freshservice-integration-v2.component.html',
    styleUrls: ['./freshservice-integration-v2.component.sass']
})
export class FreshserviceIntegrationV2Component implements OnInit {
    integrationInput: IIntegrationInput = null;
    widgetRef: Widget;
    isParentIntegrated = false;
    isConfigured: Boolean = false;
    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        this.integrationInput = {
            integrationName: this.widgetRef.widgetData.widgetInfo.text,
            integrationCategory: 'ITSM Integration',
            imageUrl: 'assets/integrations/freshservice_2.png',
            buttons: [],
            imagePadding: 4
        };
    }

    generateButtons(response: IIntegrationApiResponse) {
        // Adding create button
        this.integrationInput.buttons.push({
            buttonName: 'Integrate',
            buttonColorType: ButtonColorType.SUCCESS,
            buttonType: ButtonType.CIRCLE,
            buttonIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-plus'
            },
            hoverText: 'Integrate Freshservice',
            function: () => {
                this.openIntegrationModalData();
            }
        });

        //Adding list icon

        if (
            response['childIntegrationConfigured'] ||
            response['parentIntegrationConfigured']
        ) {
            this.integrationInput.buttons.push({
                buttonName: 'View Existing',
                buttonType: ButtonType.CIRCLE,
                buttonColorType: ButtonColorType.SUCCESS,
                buttonIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-bars '
                },

                hoverText: 'Fresh Service Integrations',
                function: () => {
                    this.openIntegrationsListingModal(response);
                }
            });
        }
    }

    openIntegrationsListingModal(response) {
        const modalArgs: IModalData = {
            modalName: 'Fresh Service Integration',
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalIcon: {
                type: null,
                class: null
            },
            modalSteps: [
                {
                    stepName: 'Integrations List',
                    stepData: {
                        componentToLoad: FreshserviceIntegrationsTableComponent,
                        payload: {
                            data: {
                                response: response,
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                }
            ],
            modalWidthVw: 40,
            modalHeightVh: 60
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
    onIntegrate() {
        this.openIntegrationModalData();
    }
    openIntegrationModalData() {
        const modalArgs: IModalData = {
            modalName: 'Fresh Service Setup',
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            extraStepContainerClass: 'remove-form-space',
            modalSteps: [
                {
                    stepName: 'Credentials',
                    stepData: {
                        componentToLoad:
                            FreshserviceIntegrationStepOneComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                },
                {
                    stepName: 'Configure Fields',
                    stepData: {
                        componentToLoad:
                            FreshserviceIntegrationStepTwoComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                },
                {
                    stepName: 'Select Customer',
                    stepData: {
                        componentToLoad:
                            FreshserviceIntegrationStepThreeComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                }
            ],
            modalWidthVw: 60,
            modalHeightPx: 500
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }

    bindData(response: IIntegrationApiResponse): void {
        if ('integration' in response && !response.integration) {
            this.isParentIntegrated = false;
            this.isConfigured = false;
        }
        if (
            response.parentIntegrationConfigured &&
            response.parentIntegrationConfigured
        ) {
            this.isParentIntegrated = true;
            this.isConfigured = true;
        }
        if (
            response.childIntegrationConfigured &&
            response.childIntegrationConfigured
        ) {
            this.isConfigured = true;
        }
        this.integrationInput.buttons = [];
        this.generateButtons(response);
        this.widgetRef.endLoader();
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
