<textarea
    class="inputJson"
    placeholder="Enter Widget Json"
    [(ngModel)]="widgetInput"
    (input)="verifiedJson = false"
></textarea>

<div class="buttons">
    <button
        mat-stroked-button
        class="btn-primary-stroked"
        (click)="widgetInput = ''; verifiedJson = false"
    >
        Reset
    </button>
    <button
        mat-stroked-button
        class="btn-primary-stroked"
        (click)="validateJson()"
    >
        Push Widget
        <mat-spinner
            *ngIf="pushWidgetLoader"
            class="light-theme"
            [diameter]="16"
        ></mat-spinner>
    </button>
</div>

<div class="validate-json" *ngIf="verifiedJson">
    <span appIcon [data]="validateJsonIcon"></span>
    <span class="validate-text">Verified</span>
</div>
