<div class="tw-relative tw-h-full tw-w-full">
    <app-vertical-scrolling-tab-group [modalId]="modalData.modalId">
        <ng-template appVerticalScrollingTab [tabLabel]="'Description'">
            <mat-accordion displayMode="flat" multi>
                <mat-expansion-panel
                    class="tw-py-2"
                    #description
                    hideToggle="true"
                    [expanded]="true"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ "Description" }}
                        </mat-panel-title>
                        <mat-panel-description>
                            <span
                                *ngIf="!description.expanded"
                                appIcon
                                [data]="arrowDownIcon"
                            ></span>
                            <span
                                *ngIf="description.expanded"
                                appIcon
                                [data]="arrowUpIcon"
                            ></span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="table-container">
                        <app-only-table-generator
                            *ngIf="descriptionTableData"
                            [tableData]="descriptionTableData"
                            [tableInput]="descriptionTableGenInput"
                            [hideControlBar]="true"
                        ></app-only-table-generator>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-template>
        <ng-template appVerticalScrollingTab [tabLabel]="'Recommendation'">
            <mat-accordion displayMode="flat" multi>
                <mat-expansion-panel
                    class="tw-py-2"
                    #recommendation
                    hideToggle="true"
                    [expanded]="true"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ "Recommendation" }}
                        </mat-panel-title>
                        <mat-panel-description>
                            <span
                                *ngIf="!recommendation.expanded"
                                appIcon
                                [data]="arrowDownIcon"
                            ></span>
                            <span
                                *ngIf="recommendation.expanded"
                                appIcon
                                [data]="arrowUpIcon"
                            ></span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="tabs">
                        <div class="tab-header">
                            <div
                                class="tab-header-item"
                                *ngIf="
                                    objectKeys(selectedItem).includes(
                                        'savingsTable'
                                    )
                                "
                                [class.active]="selectedTab === 'savingsTable'"
                                (click)="changeTab('savingsTable')"
                            >
                                Savings(%)
                            </div>
                            <div
                                class="tab-header-item"
                                *ngFor="let tab of tabs"
                                [class.active]="selectedTab === tab"
                                (click)="changeTab(tab)"
                            >
                                {{ tab }}
                            </div>
                        </div>
                        <div
                            #recommendationTable
                            *ngIf="recommendationTableGenInput"
                            class="recommendation-table"
                        >
                            <app-only-table-generator
                                [defaultColDef]="defaultColDef"
                                [hideControlBar]="true"
                                [tableInput]="recommendationTableGenInput"
                                [tableData]="recommendationTableData"
                                (gridRef)="gridRef = $event"
                            ></app-only-table-generator>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-template>
    </app-vertical-scrolling-tab-group>
</div>
