import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GridOptions, RowEvent } from 'ag-grid-community';
import { Node } from 'src/app/shared/classes/data-structure/tree/Node';
import { Tree } from 'src/app/shared/classes/data-structure/tree/Tree';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IInfoContent } from 'src/app/shared/interfaces/modal/IInfoContent';
import { IAttributeInfo } from 'src/app/shared/interfaces/views/IAttributeInfo';
import { CartCacheService } from 'src/app/shared/services/cache/cart-cache/cart-cache.service';
import { CredentialsCacheService } from 'src/app/shared/services/cache/credentials-cache/credentials-cache.service';
import { ViewHttpService } from 'src/app/shared/services/http/view-http/view-http.service';
import { SessionService } from 'src/app/shared/services/session/session.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { AssociatedUsersComponent } from '../../../modal-templates/views/associated-users/associated-users.component';
import { CreateViewComponent } from '../../../modal-templates/views/create-view/create-view.component';
import { MultiButtonGeneratorComponent } from '../../../multi-button-generator/multi-button-generator.component';
import { Helper } from './../../../../classes/Helper';
import { HitApi } from './../../../../classes/HitApi';
import { ModalType } from './../../../../enums/ModalType';
import { IModalData } from './../../../../interfaces/modal/IModalData';
import { ITableGeneratorInput } from './../../../../interfaces/table-generator/ITableGeneratorInput';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';
import { NotificationsService } from './../../../../services/notifications/notifications.service';
import { CreateDuplicateViewComponent } from '../../../modal-templates/views/create-duplicate-view/create-duplicate-view.component';

@Component({
    selector: 'app-views',
    templateUrl: './views.component.html',
    styleUrls: ['./views.component.sass']
})
export class ViewsComponent implements OnInit {
    // VIEWS: 1.0

    widgetRef: Widget;
    tableInput: ITableGeneratorInput = null;

    readonly GROUP_ID_KEY = 'groupId';
    readonly GROUP_WIDGETS_KEY = 'groupWidgets';
    readonly GROUP_KEY = 'group';
    readonly POSITION_KEY = 'position';
    deleteViewList: any[];
    isViewDeletable: boolean = true;
    @ViewChild('deleteTemplateRef') deleteTemplateRef: TemplateRef<any>;

    agGrid: GridOptions;

    readonly JSON_ID_KEY = 'id';
    readonly JSON_NAME_KEY = 'name';
    readonly JSON_CREATED_KEY = 'createdOn';
    readonly JSON_TYPE_KEY = 'viewType';
    readonly JSON_OWNER_KEY = 'viewOwner';
    readonly JSON_VIEW_PASSED_KEY = 'viewPassed';

    constructor(
        widgetData: WidgetInjectedData,
        private credentialsCacheService: CredentialsCacheService,
        private viewHttpService: ViewHttpService,
        private sessionService: SessionService,
        private modalService: ModalService,
        private notificationService: NotificationsService,
        private userDataCache: UserDataCacheService,
        private cartCache: CartCacheService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.widgetRef.refreshWidgetCallback = () => {
            this.widgetRef.showNotificationBadge.next(false);
        };

        this.tableInput = {
            buttons: [
                {
                    buttonName: 'Add User View',
                    buttonColorType: ButtonColorType.PRIMARY,
                    buttonType: ButtonType.FLAT,
                    function: (buttonRef) => {
                        this.createView();
                    }
                }
            ],
            widgetIconData: {
                class: 'fas fa-eye',
                type: IconType.FONTAWSOME
            },
            listExtraction: {
                type: 'DIRECT'
            },
            columns: [
                {
                    columnKey: this.JSON_NAME_KEY,
                    columnName: 'View Name',
                    flex: 1,
                    minWidth: 160
                },
                {
                    columnKey: this.JSON_ID_KEY,
                    columnName: 'View Id',
                    flex: 1,
                    minWidth: 160
                },
                {
                    columnKey: this.JSON_CREATED_KEY,
                    columnName: 'View Creation Date'
                },
                {
                    columnKey: this.JSON_OWNER_KEY,
                    columnName: 'View Owner'
                },
                {
                    columnKey: this.JSON_TYPE_KEY,
                    columnName: 'View Type'
                },
                {
                    columnName: 'Associated Users',
                    columnKey: '',
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: RowEvent) => {
                        const itemData = rowData.data;
                        const button: IButtonGeneratorInput = {
                            buttonName: 'View',
                            buttonType: ButtonType.TEXT,
                            buttonColorType: ButtonColorType.SUCCESS,
                            function: () => {
                                const modalData: IModalData = {
                                    modalName: 'Associated Users',
                                    modalHeightVh: 90,
                                    modalWidthVw: 55,
                                    modalIcon: null,
                                    modalType: ModalType.MIDDLE,
                                    sourceId:
                                        this.widgetRef.widgetData
                                            .widgetUniqueIdentifier,
                                    modalSteps: [
                                        {
                                            stepName: 'Associated Users',
                                            stepData: {
                                                componentToLoad:
                                                    AssociatedUsersComponent,
                                                payload: {
                                                    data: {
                                                        viewId: itemData.id
                                                    }
                                                }
                                            }
                                        }
                                    ]
                                };
                                this.modalService.openModal(modalData);
                            }
                        };
                        rowData['buttonGenInputs'] = [button];
                        return rowData;
                    }
                },
                {
                    columnName: 'Action',
                    columnKey: 'action',
                    pinned: 'right',
                    filter: false,
                    sortable: false,
                    headerClass: 'grid-cell-data-centred',
                    minWidth: 300,
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: RowEvent) => {
                        const viewId = rowData.data[this.JSON_ID_KEY];
                        const rowNodeData = rowData.data;
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'Switch',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.SUCCESS,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    // Switch to viewId
                                    if (
                                        this.widgetRef.globalDataService
                                            .selectedView === viewId
                                    ) {
                                        return;
                                    }
                                    this.widgetRef.globalDataService.changeView(
                                        this.userDataCache.emailId,
                                        viewId,
                                        this.credentialsCacheService,
                                        this.viewHttpService,
                                        this.sessionService,
                                        this.cartCache
                                    );
                                }
                            },
                            {
                                buttonName: 'Edit',
                                showLoader: true,
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.INFO,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    if (
                                        rowNodeData[this.JSON_TYPE_KEY] ===
                                        ViewType.INHERITED
                                    ) {
                                        this.showInfoModal(rowNodeData, true);
                                        return;
                                    }
                                    // Edit View
                                    buttonRef.loader = true;
                                    const apiArgs = Helper.generateHitApiConfig(
                                        this.widgetRef.widgetData.widgetInfo
                                            .update
                                    );
                                    apiArgs.intactUrl = apiArgs.url;
                                    apiArgs.url = apiArgs.url.replace(
                                        '{viewId}',
                                        rowData.data[this.JSON_ID_KEY]
                                    );
                                    apiArgs.requestType = RequestType.GET;
                                    apiArgs.input = {};
                                    apiArgs.function = (response) => {
                                        this.editView(response);
                                    };
                                    apiArgs.errorFunction = (error) => {
                                        this.notificationService.showSnackBar(
                                            error.message,
                                            true
                                        );
                                    };
                                    apiArgs.endFunction = () => {
                                        buttonRef.loader = false;
                                        this.widgetRef.changeDetectorRef.detectChanges();
                                    };
                                    new HitApi(
                                        apiArgs,
                                        this.viewHttpService,
                                        this.viewHttpService.ngZone
                                    ).hitApi();
                                }
                            },
                            {
                                buttonName: 'Duplicate',
                                showLoader: true,
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.PRIMARY,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    if (
                                        rowNodeData[this.JSON_TYPE_KEY] ===
                                        ViewType.INHERITED
                                    ) {
                                        this.showInfoModal(rowNodeData, true);
                                        return;
                                    }
                                    // Duplicate View
                                    buttonRef.loader = true;
                                    buttonRef.disable = true;
                                    const apiArgs = Helper.generateHitApiConfig(
                                        this.widgetRef.widgetData.widgetInfo
                                            .update
                                    );
                                    apiArgs.intactUrl = apiArgs.url;
                                    apiArgs.url = apiArgs.url.replace(
                                        '{viewId}',
                                        rowData.data[this.JSON_ID_KEY]
                                    );
                                    apiArgs.requestType = RequestType.GET;
                                    apiArgs.input = {};
                                    apiArgs.function = (response) => {
                                        this.duplicateView(response);
                                    };
                                    apiArgs.errorFunction = (error) => {
                                        this.notificationService.showSnackBar(
                                            error.message,
                                            true
                                        );
                                    };
                                    apiArgs.endFunction = () => {
                                        buttonRef.loader = false;
                                        buttonRef.disable = false;
                                        this.widgetRef.changeDetectorRef.detectChanges();
                                    };
                                    new HitApi(
                                        apiArgs,
                                        this.viewHttpService,
                                        this.viewHttpService.ngZone
                                    ).hitApi();
                                }
                            },
                            {
                                buttonName: 'Delete',
                                showLoader: true,
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.WARN,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    this.confirmAndDeleteViews(
                                        [rowData.data],
                                        buttonRef
                                    );
                                }
                            }
                        ];
                        const buttonOptions: IMultiButtonOption = {
                            layout: {
                                justifyContent: 'space-around'
                            }
                        };
                        rowData['buttonGenInputs'] = buttons;
                        rowData['options'] = buttonOptions;
                        return rowData;
                    }
                }
            ],
            selection: 'single'
        };
    }

    showInfoModal(singleView, isEdit?: boolean) {
        let data: IInfoContent[] = [];
        if (singleView[this.JSON_TYPE_KEY] === ViewType.OWNER) {
            data = [
                {
                    type: 'PARAGRAPH',
                    data: [
                        ` View ${singleView[this.JSON_NAME_KEY]} cannot be ${
                            isEdit ? 'edited' : 'deleted'
                        } because it is attached to users.`
                    ]
                }
            ];
        } else {
            data = [
                {
                    type: 'PARAGRAPH',
                    data: [
                        `View ${singleView[this.JSON_NAME_KEY]} cannot be ${
                            isEdit ? 'edited' : 'deleted'
                        } because it is owned by the ${
                            singleView[this.JSON_OWNER_KEY]
                        }`
                    ]
                }
            ];
        }
        this.modalService.openInfoModal({
            infoHeading: isEdit ? 'Edit User View' : 'Delete User View',
            content: data
        });
    }

    confirmAndDeleteViews(viewsList: any[], buttonRef?, actionId?) {
        this.isViewDeletable = true;
        if (viewsList && viewsList.length) {
            const viewIds = [];
            let viewDeletable = true;
            viewsList.forEach((view) => {
                if (
                    view[this.JSON_TYPE_KEY] === ViewType.INHERITED ||
                    view[this.JSON_VIEW_PASSED_KEY]
                ) {
                    viewDeletable = false;
                }
                viewIds.push(view[this.JSON_ID_KEY]);
            });
            if (viewIds.length === 1) {
                if (!viewDeletable) {
                    this.showInfoModal(viewsList[0]);
                    return;
                }
            }
            this.deleteViewList = viewsList;
            this.isViewDeletable = viewDeletable;
            this.modalService.openConfirmationModal({
                buttonText: 'Confirm',
                buttonColorType: ButtonColorType.PRIMARY,
                confirmationMessage:
                    'Are you sure you want to delete the following view(s)?',
                contextIcon: null,
                modalIcon: null,
                modalName: 'Delete User Views',
                modalHeightVh: 45,
                modalWidthVw: 50,
                bodyTemplate: this.deleteTemplateRef,
                hideCancelButton: true,
                loaderOnExec: true,
                function: (modalId) => {
                    this.deleteViews(viewIds, buttonRef, actionId, () => {
                        this.modalService.closeModal(null, modalId);
                    });
                }
            });
        } else {
            this.notificationService.showSnackBar(
                'Select atleast one view for deletion'
            );
        }
    }

    deleteViews(
        viewIds: string[],
        buttonRef?: IButtonGeneratorInput,
        actionId?,
        callback?
    ) {
        if (buttonRef) {
            buttonRef.loader = false;
        }
        if (actionId) {
            this.widgetRef.operationLoaders.set(actionId, true);
        }
        const deleteArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.delete
        );
        deleteArgs.input = {
            viewIds: viewIds
        };
        deleteArgs.function = (response) => {
            this.widgetRef.notificationsService.showSnackBar(
                'View deleted successfully'
            );
            this.widgetRef.refreshWidget();
        };
        deleteArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error deleting view'
            );
        };
        deleteArgs.endFunction = () => {
            if (buttonRef) {
                buttonRef.loader = false;
                this.widgetRef.changeDetectorRef.detectChanges();
            }
            if (actionId) {
                this.widgetRef.operationLoaders.set(actionId, false);
            }
            if (callback) {
                callback();
            }
        };
        new HitApi(
            deleteArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    createView() {
        const modalData: IModalData = {
            modalName: 'Create View',
            modalIcon: null,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 100,
            modalWidthVw: 100,
            noHeader: true,
            noStepPadding: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: CreateViewComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef
                            }
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    editView(response) {
        let landingPage = null;
        const tree = this.parseJsonToTree(response, (node) => {
            landingPage = node;
        });
        const modalData: IModalData = {
            modalName: 'Create View',
            modalIcon: null,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 100,
            modalWidthVw: 100,
            noHeader: true,
            noStepPadding: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: CreateViewComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                edit: true,
                                tree,
                                rootNode: tree.root,
                                menuParentNode: tree.root,
                                viewData: {
                                    viewIcon: {
                                        type: response.viewIconType,
                                        class: response.viewIcon
                                    },
                                    viewName: response.viewName
                                },
                                selectedAcl: response.acl,
                                landingPage,
                                viewId: response.id
                            }
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    /**
     * Duplicates a view by opening a modal with the specified data.
     * @param {any} response - The response object containing the view data.
     * @returns None
     */
    duplicateView(response) {
        let landingPage = null;
        const tree = this.parseJsonToTree(response, (node) => {
            landingPage = node;
        });
        const modalData: IModalData = {
            modalName: 'Duplicate View',
            modalIcon: null,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 100,
            modalWidthVw: 100,
            noHeader: true,
            noStepPadding: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: CreateDuplicateViewComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                duplicate: true,
                                tree,
                                rootNode: tree.root,
                                menuParentNode: tree.root,
                                viewData: {
                                    viewIcon: {
                                        type: response.viewIconType,
                                        class: response.viewIcon
                                    },
                                    viewName: response.viewName + "_1"
                                },
                                selectedAcl: response.acl,
                                landingPage
                            }
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    parseJsonToTree(response, callback): Tree<IAttributeInfo> {
        const tree = new Tree<IAttributeInfo>();
        const rootNode = tree.addNode(
            {
                attributeName: 'ROOT',
                iconType: IconType.FONTAWSOME,
                icon: 'fas fa-stroopwafel'
            },
            null
        );
        this.addNodesToTree(tree, rootNode, response.attributes, callback);
        return tree;
    }

    addNodesToTree(
        tree: Tree<IAttributeInfo>,
        parentNode: Node<IAttributeInfo>,
        nodesData: any[],
        callback
    ) {
        nodesData.forEach((nodeData) => {
            const data: IAttributeInfo = {
                attributeName: nodeData.attributeName,
                icon: nodeData.icon,
                iconType: nodeData.iconType
            };
            const node = tree.addNode(data, parentNode.nodeId);
            if (nodeData.ishomePage) {
                callback(node);
            }
            if (nodeData.widgets && nodeData.widgets.length) {
                node.data.widgets = this.prepareWidgetGroup(nodeData.widgets);
            } else if (nodeData.attributes && nodeData.attributes.length) {
                this.addNodesToTree(tree, node, nodeData.attributes, callback);
            }
        });
    }

    prepareWidgetGroup(widgetList) {
        const finalWidgetList = [];
        for (let index = 0; index < widgetList.length; index++) {
            const groupWidgets = [widgetList[index]];
            index++;
            while (
                index < widgetList.length &&
                widgetList[index][this.POSITION_KEY] > 0
            ) {
                groupWidgets.splice(
                    widgetList[index][this.POSITION_KEY],
                    0,
                    widgetList[index]
                );
                index++;
            }
            if (groupWidgets.length === 1) {
                finalWidgetList.push(groupWidgets[0]);
            } else {
                finalWidgetList.push({
                    [this.GROUP_ID_KEY]: Helper.generateUniqueKey(10),
                    [this.GROUP_WIDGETS_KEY]: groupWidgets
                });
            }
            index--;
        }
        return finalWidgetList;
    }

    onSelectionChanged(event) {
        if (this.agGrid) {
            this.agGrid.api.getSelectedNodes().length
                ? this.widgetRef.showNotificationBadge.next(true)
                : this.widgetRef.showNotificationBadge.next(false);
        }
    }
}

enum ViewType {
    INHERITED = 'Inherited',
    OWNER = 'Owner'
}
