<div
    *ngIf="accounts && accounts.length && !(widgetRef.loadingData | async)"
    class="button-gen"
>
    <div class="tw-flex tw-relative consumption-heading">
        <app-multi-button-generator
            *ngIf="severityButtons"
            [buttonGenInputs]="severityButtons"
        ></app-multi-button-generator>
    </div>
</div>

<ng-container
    *ngIf="
        accounts &&
        accounts.length > 0 &&
        widgetRef.visibleSections.value.has(ViewType.CARDS)
    "
>
    <div class="tw-flex-col controls">
        <div class="table-search" *ngIf="!(widgetRef.loadingData | async)">
            <app-table-search
                [data]="accounts"
                (changed)="filteredCardsData = $event"
            ></app-table-search>
        </div>
        <div
            class="tw-flex-col tw-w-full controls"
            *ngIf="!(widgetRef.loadingData | async)"
        >
            <div
                class="widget-data-container"
                [id]="widgetRef.widgetTableId"
                *ngIf="!(widgetRef.loadingData | async)"
            >
                <div
                    *ngIf="filteredCardsData && filteredCardsData.length > 0"
                    class="tw-flex tw-flex-wrap tw-justify-start tw-w-full tw-overflow-auto cards-container"
                >
                    <div
                        *ngFor="let card of filteredCardsData; let i = index"
                        class="tw-text-fs-200 tw-my-5 tw-mr-2 tw-flex tw-flex-col tw-cursor-pointer tw-relative card"
                        (click)="showRiModal(card)"
                    >
                        <div
                            class="tw-z-10 tw-flex-1 tw-w-full tw-text-fs-800 head"
                            [class.red-gradient]="
                                colorGradient(
                                    card['Display Entry'][dataLists.Consumption]
                                ) === utilsationColors.RED
                            "
                            [class.green-gradient]="
                                colorGradient(
                                    card['Display Entry'][dataLists.Consumption]
                                ) === utilsationColors.GREEN
                            "
                            [class.yellow-gradient]="
                                colorGradient(
                                    card['Display Entry'][dataLists.Consumption]
                                ) === utilsationColors.YELLOW
                            "
                        >
                            <div
                                class="tw-flex tw-text-fs-800 tw-my-0 tw-mx-auto tw-justify-center"
                            >
                                <div
                                    class="tw-flex tw-w-20 tw-h-20 tw-top-3 tw-relative tw-rounded-full tw-justify-center tw-items-center card-circle"
                                    [class.redGradientCircle]="
                                        colorGradient(
                                            card['Display Entry'][
                                                dataLists.Consumption
                                            ]
                                        ) === utilsationColors.RED
                                    "
                                    [class.greenGradientCircle]="
                                        colorGradient(
                                            card['Display Entry'][
                                                dataLists.Consumption
                                            ]
                                        ) === utilsationColors.GREEN
                                    "
                                    [class.yellowGradientCircle]="
                                        colorGradient(
                                            card['Display Entry'][
                                                dataLists.Consumption
                                            ]
                                        ) === utilsationColors.YELLOW
                                    "
                                >
                                    <span
                                        class="tw-absolute tw-text-fs-200 tw-font-bold tw-flex tw-max-w-full"
                                    >
                                        <span
                                            class="tw-flex-1"
                                            *ngIf="
                                                card['Display Entry'][
                                                    dataLists.Consumption
                                                ]
                                            "
                                        >
                                            <div
                                                [class]="
                                                    getIcon(card, dataLists)
                                                        .class
                                                "
                                                appIcon
                                                [data]="
                                                    getIcon(card, dataLists)
                                                        .iconData
                                                "
                                            ></div>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            class="tw-flex-f8 tw-relative tw-pt-14 tw-pb-4 tw-px-4 content"
                        >
                            <ng-container *ngFor="let item of viewOrder">
                                <div class="item-class">{{ item }} :</div>
                                <ng-container
                                    *ngIf="
                                        item !== 'Subscription ID' &&
                                        item !== 'Subscription Name'
                                    "
                                >
                                    <div class="item-class item-value">
                                        {{ card["Display Entry"][item] }}
                                    </div>
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        item === 'Subscription ID' ||
                                        item === 'Subscription Name'
                                    "
                                >
                                    <ng-container
                                        *ngFor="
                                            let data of card['Display Entry'][
                                                item
                                            ].split(';');
                                            index as i
                                        "
                                    >
                                        <ng-container *ngIf="i < 3">
                                            <ng-container
                                                *ngIf="i < 2; else addMore"
                                            >
                                                <div
                                                    class="item-class item-value"
                                                >
                                                    {{ data }}
                                                    {{
                                                        i === 0 &&
                                                        card["Display Entry"][
                                                            item
                                                        ].split(";").length !==
                                                            1
                                                            ? ","
                                                            : ""
                                                    }}
                                                </div>
                                            </ng-container>
                                            <ng-template #addMore>
                                                <div
                                                    class="item-class item-value"
                                                >
                                                    +
                                                    {{
                                                        card["Display Entry"][
                                                            item
                                                        ].split(";").length - 2
                                                    }}
                                                    more
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <div class="tw-h-2"></div>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <ng-container
                    *ngIf="
                        filteredCardsData &&
                        !filteredCardsData.length &&
                        !(widgetRef.loadingData | async)
                    "
                >
                    <div
                        class="tw-h-10 tw-text-center tw-text-fs-800 tw-w-full tw-flex tw-justify-center tw-items-center tw-m-auto message-container"
                    >
                        {{ Messages.NO_DATA_AVAILABLE }}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="widgetRef.visibleSections.value.has(ViewType.TABLE)">
    <div
        *ngIf="
            widgetRef.visibleSections.value.has(ViewType.TABLE) &&
            accounts &&
            accounts.length &&
            !(widgetRef.loadingData | async)
        "
        class="table-generate"
    >
        <div
            *ngIf="
                filteredCardsData &&
                filteredCardsData.length &&
                tableInput &&
                tableData &&
                tableData.length
            "
        >
            <app-only-table-generator
                [tableInput]="tableInput"
                [tableData]="tableData"
                (gridRef)="gridRef = $event"
            >
            </app-only-table-generator>
        </div>

        <div
            *ngIf="
                filteredCardsData &&
                !filteredCardsData.length &&
                widgetRef.visibleSections.value.has(ViewType.TABLE) &&
                !(widgetRef.loadingData | async)
            "
            class="tw-h-96 tw-text-center tw-text-fs-800 tw-w-full tw-flex tw-justify-center tw-items-center tw-m-auto message-container"
        >
            {{ Messages.NO_DATA_AVAILABLE }}
        </div>
    </div>
</ng-container>
