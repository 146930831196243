<div class="form-container">
    <app-form-generator
        *ngIf="mfaFormGenInputs"
        [formGeneratorInput]="mfaFormGenInputs"
        (formGroupRef)="mfaFormGroup = $event"
        (valuesChange)="valueChanged()"
    ></app-form-generator>
    <div (click)="sendOtp()" *ngIf="!showVerificationForm" class="sendOtpBtn">
        <button mat-stroked-button>Send OTP</button>
    </div>
    <div *ngIf="showVerificationForm" class="verification-form">
        <app-form-generator
            *ngIf="codeVerificationFormGenInputs && !verificationSuccessful"
            [formGeneratorInput]="codeVerificationFormGenInputs"
            (formGroupRef)="codeVerificationFormGroup = $event"
        ></app-form-generator>
        <div class="messages">
            <div *ngIf="messages | async">
                <div *ngFor="let val of messages | async | keyvalue">
                    <span [style.color]="val.key">{{ val.value }}</span>
                </div>
            </div>
        </div>
        <div *ngIf="!verificationSuccessful" class="buttons">
            <div class="resendOtpBtn">
                <button
                    [class.disabled]="
                        !enableResendBtn || timeOut || otpVerificationLoader
                    "
                    (click)="sendOtp()"
                    mat-stroked-button
                >
                    Resend OTP
                </button>
            </div>

            <div class="verifyOtp">
                <button
                    [class.disabled]="verificationSuccessful"
                    (click)="verify()"
                    mat-stroked-button
                >
                    Verify
                    <mat-spinner
                        *ngIf="otpVerificationLoader"
                        class="dark-theme"
                        [diameter]="16"
                    ></mat-spinner>
                </button>
            </div>
        </div>
        <div class="timer" *ngIf="showTimer && !verificationSuccessful">
            <span *ngIf="!timeOut && timer | async"
                >{{ timer | async }} Sec</span
            >
        </div>
    </div>
</div>
<div class="save-btn">
    <button
        type="button"
        mat-stroked-button
        class="clickable btn-accent-stroked filled"
        color="accent"
        [class.disabled]="otpVerificationLoader || !verificationSuccessful"
        (click)="enableMfa()"
    >
        Save
        <mat-spinner
            *ngIf="loader"
            class="light-theme"
            [diameter]="16"
        ></mat-spinner>
    </button>
</div>
