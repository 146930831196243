import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { IConfirmationModal } from '../../interfaces/modal/IConfirmationModal';
import { ButtonColorType } from '../../enums/ButtonColorType';
import { IModalData } from '../../interfaces/modal/IModalData';
import { ModalType } from '../../enums/ModalType';
import { ConfirmationModalComponent } from '../../components/modal-templates/confirmation-modal/confirmation-modal.component';
import { ModalService } from '../modal/modal-service/modal.service';
import { IButtonGeneratorInput } from '../../interfaces/button-generator/IButtonGeneratorInput';

@Injectable({
    providedIn: 'root'
})
export class AuditEvidenceSaveConfirmationService {
    private isUnsavedDataPresent = new BehaviorSubject<boolean>(false);
    private isNavigationTriggeredWithoutSave = new BehaviorSubject<any>(false);

    constructor(private modalService: ModalService) {}

    /**
     * To be used for removing disable last control point next button
     * functionality temporarily in case of some audits
     */
    public isInvalidateSectionDataObservable = new Subject<boolean>();

    public getIsUnsavedDataPresent(): boolean {
        return this.isUnsavedDataPresent.getValue();
    }

    public setIsUnsavedDataPresent(newState: boolean): void {
        this.isUnsavedDataPresent.next(newState);
    }

    public getIsNavigationTriggeredWithoutSave(): Observable<any> {
        return this.isNavigationTriggeredWithoutSave
            .asObservable()
            .pipe(distinctUntilChanged());
    }

    public setIsNavigationTriggeredWithoutSave(newState: any): void {
        this.isNavigationTriggeredWithoutSave.next(newState);
    }

    public continuePausedNavigation() {
        const rest: any[] =
            this.isNavigationTriggeredWithoutSave?.getValue()
                ?.navFunctionArgsToCache;
        const continueFunction: Function =
            this.isNavigationTriggeredWithoutSave?.getValue()?.continueFunction;
        if (typeof continueFunction === 'function') {
            continueFunction(...rest);
        }
    }

    public navigateAwayConfirmationModal(
        onSuccess?: (
            modalId: Symbol,
            formGroup: FormGroup,
            buttonRef: IButtonGeneratorInput
        ) => void,
        onCancel?: Function
    ) {
        const confirmationModalData: IConfirmationModal = {
            function: onSuccess,
            cancelFunction: onCancel,
            modalName: 'Save Changes',
            confirmationMessage:
                'Do you want to save the changes made in Control Point?\nYour changes will be lost if you click Continue.',
            buttonText: 'Save & Continue',
            buttonColorType: ButtonColorType.PRIMARY,
            cancelButtonText: 'Continue',
            loaderOnExec: true
        };
        const modalData: IModalData = {
            modalName: confirmationModalData.modalName,
            modalIcon: confirmationModalData.modalIcon,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 30,
            modalWidthVw: 50,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ConfirmationModalComponent,
                        payload: {
                            data: {
                                function: confirmationModalData.function,
                                cancelFunction: onCancel,
                                params: confirmationModalData
                            }
                        }
                    },
                    stepName: 'Confirmation'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }
}
