import { Pipe, PipeTransform } from '@angular/core';
import { Helper } from '../classes/Helper';

@Pipe({
    name: 'svgIconNameConverter'
})
export class SvgIconNameConverterPipe implements PipeTransform {
    transform(value: String): String {
        return value.split('_').map(Helper.capitalizeWord).join(' ');
    }
}
