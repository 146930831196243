<div class="content-container">
    <div class="preview-container">
        <app-form-generator
            [formGeneratorInput]="accountIdsFormGenInputs"
            (formGroupRef)="accountIdsFormGroup = $event"
            (valuesChange)="accountSelected($event)"
        ></app-form-generator>
        <app-form-generator
            [formGeneratorInput]="regionFormGenInputs"
            (formGroupRef)="regionFormGroup = $event"
            (updateControl)="updateControl = $event"
            (valuesChange)="regionSelected($event)"
        ></app-form-generator>
    </div>
    <div *ngIf="tableInputs" class="table">
        <app-table-generator
            [tableInput]="tableInputs"
            [tableData]="tablesData"
            [widgetRef]="widgetRef"
        ></app-table-generator>
    </div>
    <div *ngIf="showMessage" class="no-data-text">
        <span>Resource(s) not found. Check logs to view error. </span>
    </div>
</div>
<ng-container *ngIf="isPreview">
    <div class="action-container">
        <app-button-generator
            [buttonGenInput]="closeButtonGenInput"
        ></app-button-generator>
    </div>
</ng-container>
