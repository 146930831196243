<div class="container">
    <div class="form-container">
        <div class="overlay" *ngIf="isLoading">
            <div class="overlay-content">
                <div
                    appIcon
                    [data]="spinnerLoader"
                    class="overlay-loader"
                ></div>
            </div>
        </div>
        <div class="form-wrapper">
            <app-form-generator
                *ngIf="formGenInput"
                [formGeneratorInput]="formGenInput"
                (formGroupRef)="formGroupRef = $event"
                (resetFormFunction)="resetForm = $event"
            ></app-form-generator>
        </div>
        <div class="modal-info">
            <div class="info-icon">
                <i class="custom-fa-icon fa fa-info-circle"></i>
            </div>
            <div class="info-message">
                <p>
                    Configuration Name will be used as the widget name of the
                    salesforce desk created via these credentials. Widget will
                    be saved in master ACL.
                </p>
            </div>
        </div>
    </div>

    <div class="buttons">
        <app-multi-button-generator [buttonGenInputs]="buttonInputs">
        </app-multi-button-generator>
    </div>
</div>
