import { Component, OnInit } from '@angular/core';
import { FullAccessData } from 'src/app/shared/classes/FullAccessData';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { PortletType } from 'src/app/shared/enums/PortletType';
import { IDataInterface } from './../../../classes/FullAccessData';

@Component({
    selector: 'app-get-full-access',
    templateUrl: './get-full-access.component.html',
    styleUrls: ['./get-full-access.component.sass']
})
export class GetFullAccessComponent implements OnInit {
    fullAccessDescription: string;
    fullAccessPoints: Map<PortletType, IDataInterface>;
    portlet: PortletType;
    contactUs: string = null;

    contactUsMap: Map<string, string> = new Map()
        .set('https://cmp.trackmycloud.com', 'trackmycloud@redingtongulf.com')
        .set('https://stagel1.click', 'trackmycloud@redingtongulf.com');

    constructor(private modalInputData: ModalInjectedData) {
        this.portlet = this.modalInputData.data;
        this.fullAccessPoints = FullAccessData.fullAccessMap;
    }
    ngOnInit(): void {
        this.checkContactUsData();
    }

    checkContactUsData() {
        const currentUrl = window.location.origin;
        if (this.contactUsMap.has(currentUrl)) {
            this.contactUs = this.contactUsMap.get(currentUrl);
        }
    }
}
