import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
    MaterialCssVariables,
    MaterialCssVarsService
} from 'angular-material-css-vars';
import { BehaviorSubject } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IWhiteLabelData } from 'src/app/shared/interfaces/white-labeling-data/IWhiteLabelData';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { Logger } from '../../classes/Logger';
import { AuthorizationType } from '../../enums/AuthorizationType';
import { RequestType } from '../../enums/RequestType';
import { WhitelabelingColorPalette } from '../../enums/WhitelabelingColorPalette';
import { Helper } from './../../classes/Helper';
import { GlobalDataService } from './../global-data/global-data.service';
declare function require(name: string);

@Injectable({
    providedIn: 'root'
})
export class WhiteLabelingService {
    centilyticsData: IWhiteLabelData = {} as IWhiteLabelData;
    whiteLableData: IWhiteLabelData;
    isSubdomain: boolean;
    configLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
    themeError: BehaviorSubject<boolean> = new BehaviorSubject(false);
    styles = getComputedStyle(this.document.documentElement);
    whitelabelingResponse: IWhiteLabelData;
    counter: number = 1;
    accentColorInHex: string;
    primaryColorInHex: string;
    exceptionDomain: string[];
    warnColorInHex: string;
    constructor(
        public materialCssVarsService: MaterialCssVarsService,
        private httpService: HttpService,
        private ngZone: NgZone,
        @Inject(DOCUMENT) private document: Document,
        private userDataCacheService: UserDataCacheService,
        private titleService: Title,
        private globalDataService: GlobalDataService
    ) {
        this.centilyticsData['companyName'] = 'Centilytics';
    }

    get origin() {
        return window.location.hostname;
    }

    get fullOrigin() {
        return window.location.origin;
    }

    getWhitelabelingData(callback) {
        const apiConfig: IHitApi = {
            function: (response) => {
                if (
                    response.domainExceptions &&
                    response.domainExceptions.length
                ) {
                    this.exceptionDomain = response.domainExceptions;
                    this.setWhitelabelData(callback);
                } else {
                    this.themeError.next(true);
                    this.initiateLoadingPhase();
                }
            },
            errorFunction: () => {
                this.themeError.next(true);
                this.initiateLoadingPhase();
            },
            url: ApiUrls.GET_DOMAIN_EXCEPTION_API,
            input: {},
            requestType: RequestType.GET,
            config: {
                authorization: AuthorizationType.NOT_AUTHORIZED,
                ignoreBaseUrl: true
            },
            uniqueIdentity: Symbol()
        };
        new HitApi(
            apiConfig,
            this.httpService,
            this.httpService.ngZone
        ).hitApi();
    }
    setWhitelabelData(callback) {
        const domain = this.origin;
        this.initiateLoadingPhase();
        if (
            this.exceptionDomain.includes(domain) ||
            this.origin.includes('cloudfront.net')
        ) {
            this.getDefaultData(callback);
            return;
        }
        const args: IHitApi = {
            function: (response) => {
                if (response?.whitelabel === false) {
                    this.themeError.next(true);
                    return;
                }
                this.whitelabelingResponse = response;
                this.whitelabelingData(response, callback);
                this.themeError.next(false);
            },
            errorFunction: () => {
                if (!this.whitelabelingResponse) {
                    this.themeError.next(true);
                }
            },
            url: ApiUrls.UPDATED_GET_WHITELABELING_DATA,
            input: {},
            requestType: RequestType.POST,
            config: {
                authorization: AuthorizationType.NOT_AUTHORIZED,
                defaultHeaders: {
                    'Content-Type': 'application/json',
                },
            },
            uniqueIdentity: Symbol(),
        };
        new HitApi(args, this.httpService, this.ngZone).hitApi();
    }
    getDefaultData(callback) {
        let region = 1;
        const parallelApis: IHitApi[] = [];
        while (region <= 6) {
            const apiConfig: IHitApi = {
                function: (response, extraData) => {
                    if (!this.whitelabelingResponse) {
                        this.whitelabelingResponse = response;
                        this.whitelabelingData(
                            response,
                            callback,
                            extraData.baseUrl,
                            true
                        );
                        this.themeError.next(false);
                    }
                },
                errorFunction: () => {
                    if (!this.whitelabelingResponse) {
                        this.themeError.next(true);
                    }
                },
                url: ApiUrls.GET_DEFAULT_WHITELABELING_DATA,
                intactUrl: ApiUrls.GET_DEFAULT_WHITELABELING_DATA,
                input: {},
                requestType: RequestType.GET,
                config: {
                    authorization: AuthorizationType.NOT_AUTHORIZED,
                    ignoreBaseUrl: true,
                    extraData: {
                        baseUrl: `${ApiUrls.WHITELABELING_BASE_URL}${region}`
                    }
                },
                uniqueIdentity: Symbol()
            };
            apiConfig.url = apiConfig.url.replace('{region}', '' + region);
            parallelApis.push(apiConfig);
            region++;
        }
        this.httpService.hitParallelApis(parallelApis);
    }
    whitelabelingData(response, callback, baseUrl?: string, isDefaultData?) {
        response = this.globalDataService.whiteLabelingPreviewData ?? response;
        const isPreview: boolean = this.globalDataService
            .whiteLabelingPreviewData
            ? true
            : false;
        const obj: IWhiteLabelData = {
            companyName: '',
            domain: this.origin,
            lightLogo:
                isPreview &&
                response &&
                response.orgProprietary &&
                response.orgProprietary.lightLogo
                    ? response.orgProprietary.lightLogo
                    : response?.logos?.lightLogoUrl
                    ? response.logos.lightLogoUrl
                    : baseUrl
                    ? `${baseUrl}/defaultWebsiteForSrm/lightLogo.png`
                    : '',
            darkLogo:
                isPreview &&
                response &&
                response.orgProprietary &&
                response.orgProprietary.darkLogo
                    ? response.orgProprietary.darkLogo
                    : response?.logos?.darkLogoUrl
                    ? response.logos.darkLogoUrl
                    : baseUrl
                    ? `${baseUrl}/defaultWebsiteForSrm/darkLogo.png`
                    : '',
            loginImage:
                isPreview &&
                response &&
                response.orgProprietary &&
                response.orgProprietary.loginImage
                    ? response.orgProprietary.loginImage
                    : response?.logos?.loginImageUrl
                    ? response.logos.loginImageUrl
                    : baseUrl
                    ? `${baseUrl}/defaultWebsiteForSrm/loginImage.png`
                    : '',
            miniLogo:
                isPreview &&
                response &&
                response.orgProprietary &&
                response.orgProprietary.miniLogo
                    ? response.orgProprietary.miniLogo
                    : response?.logos?.miniLogoUrl
                    ? response.logos.miniLogoUrl
                    : baseUrl
                    ? `${baseUrl}/defaultWebsiteForSrm/miniLogo.png`
                    : '',

            organizationalUrl:
                response['socialMediaUrl'] &&
                response['socialMediaUrl']['organizationalUrl']
                    ? response['socialMediaUrl']['organizationalUrl']
                    : null,
            linkedinUrl:
                response['socialMediaUrl'] &&
                response['socialMediaUrl']['linkedinUrl']
                    ? response['socialMediaUrl']['linkedinUrl']
                    : null,
            twitterUrl:
                response['socialMediaUrl'] &&
                response['socialMediaUrl']['twitterUrl']
                    ? response['socialMediaUrl']['twitterUrl']
                    : null,
            facebookUrl:
                response['socialMediaUrl'] &&
                response['socialMediaUrl']['facebookUrl']
                    ? response['socialMediaUrl']['facebookUrl']
                    : null,
            knowledgeBaseUrl:
                response['socialMediaUrl'] &&
                response['socialMediaUrl']['knowledgeBaseUrl']
                    ? response['socialMediaUrl']['knowledgeBaseUrl']
                    : null,
            theme: {},
            isCentilytics: false
        };
        const themeConfig = response['themeConfigMap'];
        this.primaryColorInHex = themeConfig[WhitelabelingColorPalette.PRIMARY];
        this.accentColorInHex = themeConfig[WhitelabelingColorPalette.ACCENT];
        this.warnColorInHex =
            themeConfig[WhitelabelingColorPalette.NEGATIVE_COLOR];
        obj.theme[WhitelabelingColorPalette.PRIMARY] = themeConfig[
            WhitelabelingColorPalette.PRIMARY
        ].includes('#')
            ? Helper.getHsl(themeConfig[WhitelabelingColorPalette.PRIMARY])
            : themeConfig[WhitelabelingColorPalette.PRIMARY];
        obj.theme[WhitelabelingColorPalette.ACCENT] = themeConfig[
            WhitelabelingColorPalette.ACCENT
        ].includes('#')
            ? Helper.getHsl(themeConfig[WhitelabelingColorPalette.ACCENT])
            : themeConfig[WhitelabelingColorPalette.ACCENT];
        obj.theme[WhitelabelingColorPalette.ACCENTWITHOPACITY5] = themeConfig[
            WhitelabelingColorPalette.ACCENT
        ].includes('#')
            ? Helper.addOpacityToColor(
                  Helper.getHsl(themeConfig[WhitelabelingColorPalette.ACCENT]),
                  5
              )
            : themeConfig[WhitelabelingColorPalette.ACCENT];
        obj.theme[WhitelabelingColorPalette.ACCENTWITHOPACITY12] = themeConfig[
            WhitelabelingColorPalette.ACCENT
        ].includes('#')
            ? Helper.addOpacityToColor(
                  Helper.getHsl(themeConfig[WhitelabelingColorPalette.ACCENT]),
                  12
              )
            : themeConfig[WhitelabelingColorPalette.ACCENT];
        obj.theme[WhitelabelingColorPalette.DEFAULT_TEXT_COLOR] = themeConfig[
            WhitelabelingColorPalette.DEFAULT_TEXT_COLOR
        ].includes('#')
            ? Helper.getHsl(
                  themeConfig[WhitelabelingColorPalette.DEFAULT_TEXT_COLOR]
              )
            : themeConfig[WhitelabelingColorPalette.DEFAULT_TEXT_COLOR];
        obj.theme[WhitelabelingColorPalette.PRIMARY_BUTTON_COLOR] = themeConfig[
            WhitelabelingColorPalette.PRIMARY_BUTTON_COLOR
        ].includes('#')
            ? Helper.getHsl(
                  themeConfig[WhitelabelingColorPalette.PRIMARY_BUTTON_COLOR]
              )
            : themeConfig[WhitelabelingColorPalette.PRIMARY_BUTTON_COLOR];
        obj.theme[WhitelabelingColorPalette.SECONDARY_BUTTON_COLOR] =
            themeConfig[
                WhitelabelingColorPalette.SECONDARY_BUTTON_COLOR
            ].includes('#')
                ? Helper.getHsl(
                      themeConfig[
                          WhitelabelingColorPalette.SECONDARY_BUTTON_COLOR
                      ]
                  )
                : themeConfig[WhitelabelingColorPalette.SECONDARY_BUTTON_COLOR];
        obj.theme[WhitelabelingColorPalette.AGGREGATE_DEFAULT_COLOR] =
            themeConfig[
                WhitelabelingColorPalette.AGGREGATE_DEFAULT_COLOR
            ].includes('#')
                ? Helper.getHsl(
                      themeConfig[
                          WhitelabelingColorPalette.AGGREGATE_DEFAULT_COLOR
                      ]
                  )
                : themeConfig[
                      WhitelabelingColorPalette.AGGREGATE_DEFAULT_COLOR
                  ];
        obj.theme[WhitelabelingColorPalette.AGGREGATE_DEFAULT_TEXT_COLOR] =
            themeConfig[
                WhitelabelingColorPalette.AGGREGATE_DEFAULT_TEXT_COLOR
            ].includes('#')
                ? Helper.getHsl(
                      themeConfig[
                          WhitelabelingColorPalette.AGGREGATE_DEFAULT_TEXT_COLOR
                      ]
                  )
                : themeConfig[
                      WhitelabelingColorPalette.AGGREGATE_DEFAULT_TEXT_COLOR
                  ];
        obj.theme[WhitelabelingColorPalette.MENU_PRIMARY_COLOR] = themeConfig[
            WhitelabelingColorPalette.MENU_PRIMARY_COLOR
        ].includes('#')
            ? Helper.getHsl(
                  themeConfig[WhitelabelingColorPalette.MENU_PRIMARY_COLOR]
              )
            : themeConfig[WhitelabelingColorPalette.MENU_PRIMARY_COLOR];
        obj.theme[WhitelabelingColorPalette.MENU_ACCENT_COLOR] = themeConfig[
            WhitelabelingColorPalette.MENU_ACCENT_COLOR
        ].includes('#')
            ? Helper.getHsl(
                  themeConfig[WhitelabelingColorPalette.MENU_ACCENT_COLOR]
              )
            : themeConfig[WhitelabelingColorPalette.MENU_ACCENT_COLOR];
        obj.theme[WhitelabelingColorPalette.MENU_TEXT_COLOR] = themeConfig[
            WhitelabelingColorPalette.MENU_TEXT_COLOR
        ].includes('#')
            ? Helper.getHsl(
                  themeConfig[WhitelabelingColorPalette.MENU_TEXT_COLOR]
              )
            : themeConfig[WhitelabelingColorPalette.MENU_TEXT_COLOR];
        obj.theme[WhitelabelingColorPalette.MENU_HOVER_COLOR] = themeConfig[
            WhitelabelingColorPalette.MENU_HOVER_COLOR
        ].includes('#')
            ? Helper.getHsl(
                  themeConfig[WhitelabelingColorPalette.MENU_HOVER_COLOR]
              )
            : themeConfig[WhitelabelingColorPalette.MENU_HOVER_COLOR];
        obj.theme[WhitelabelingColorPalette.HEADER_COLOR] = themeConfig[
            WhitelabelingColorPalette.HEADER_COLOR
        ].includes('#')
            ? Helper.getHsl(themeConfig[WhitelabelingColorPalette.HEADER_COLOR])
            : themeConfig[WhitelabelingColorPalette.HEADER_COLOR];
        obj.theme[WhitelabelingColorPalette.WIDGET_COLOR] = themeConfig[
            WhitelabelingColorPalette.WIDGET_COLOR
        ].includes('#')
            ? Helper.getHsl(themeConfig[WhitelabelingColorPalette.WIDGET_COLOR])
            : themeConfig[WhitelabelingColorPalette.WIDGET_COLOR];
        obj.theme[WhitelabelingColorPalette.WIDGET_TEXT_COLOR] = themeConfig[
            WhitelabelingColorPalette.WIDGET_TEXT_COLOR
        ].includes('#')
            ? Helper.getHsl(
                  themeConfig[WhitelabelingColorPalette.WIDGET_TEXT_COLOR]
              )
            : themeConfig[WhitelabelingColorPalette.WIDGET_TEXT_COLOR];
        obj.theme[WhitelabelingColorPalette.FOOTER_COLOR] = themeConfig[
            WhitelabelingColorPalette.FOOTER_COLOR
        ].includes('#')
            ? Helper.getHsl(themeConfig[WhitelabelingColorPalette.FOOTER_COLOR])
            : themeConfig[WhitelabelingColorPalette.FOOTER_COLOR];
        obj.theme[WhitelabelingColorPalette.FOOTER_TEXT_COLOR] = themeConfig[
            WhitelabelingColorPalette.FOOTER_TEXT_COLOR
        ].includes('#')
            ? Helper.getHsl(
                  themeConfig[WhitelabelingColorPalette.FOOTER_TEXT_COLOR]
              )
            : themeConfig[WhitelabelingColorPalette.FOOTER_TEXT_COLOR];
        obj.theme[WhitelabelingColorPalette.BACKGROUND_COLOR] = themeConfig[
            WhitelabelingColorPalette.BACKGROUND_COLOR
        ].includes('#')
            ? Helper.getHsl(
                  themeConfig[WhitelabelingColorPalette.BACKGROUND_COLOR]
              )
            : themeConfig[WhitelabelingColorPalette.BACKGROUND_COLOR];
        obj.theme[WhitelabelingColorPalette.NEGATIVE_COLOR] = themeConfig[
            WhitelabelingColorPalette.NEGATIVE_COLOR
        ].includes('#')
            ? Helper.getHsl(
                  themeConfig[WhitelabelingColorPalette.NEGATIVE_COLOR]
              )
            : themeConfig[WhitelabelingColorPalette.NEGATIVE_COLOR];
        obj.theme[WhitelabelingColorPalette.POSITEVE_COLOR] = themeConfig[
            WhitelabelingColorPalette.POSITEVE_COLOR
        ].includes('#')
            ? Helper.getHsl(
                  themeConfig[WhitelabelingColorPalette.POSITEVE_COLOR]
              )
            : themeConfig[WhitelabelingColorPalette.POSITEVE_COLOR];
        obj.theme[WhitelabelingColorPalette.NEUTRAL_COLOR] = themeConfig[
            WhitelabelingColorPalette.NEUTRAL_COLOR
        ].includes('#')
            ? Helper.getHsl(
                  themeConfig[WhitelabelingColorPalette.NEUTRAL_COLOR]
              )
            : themeConfig[WhitelabelingColorPalette.NEUTRAL_COLOR];
        this.whiteLableData = obj;
        this.whiteLableData['companyName'] =
            response['emailConfig']['companyName'];
        if (this.whiteLableData['companyName'] === 'Centilytics') {
            this.whiteLableData.isCentilytics = GlobalConfiguration.PREVIEW_MODE
                ? false
                : true;
        } else {
            this.whiteLableData.isCentilytics = false;
        }

        this.whiteLableData['reportContent'] =
            response['emailConfig']['report']['content'];
        this.whiteLableData['reportSubject'] =
            response['emailConfig']['report']['subject'];
        this.whiteLableData['alertContent'] =
            response['emailConfig']['alert']['content'];
        this.whiteLableData['budgetContent'] =
            response['emailConfig']['budget']['content'];
        this.whiteLableData['customerContent'] =
            response['emailConfig']['customer']['content'];
        this.whiteLableData['userContent'] =
            response['emailConfig']['user']['content'];
        this.whiteLableData['passwordContent'] =
            response['emailConfig']['forgetPassword']['content'];
        this.whiteLableData['alertSubject'] =
            response['emailConfig']['alert']['subject'];
        this.whiteLableData['budgetSubject'] =
            response['emailConfig']['budget']['subject'];
        this.whiteLableData['customerSubject'] =
            response['emailConfig']['customer']['subject'];
        this.whiteLableData['userSubject'] =
            response['emailConfig']['user']['subject'];
        this.whiteLableData['passwordSubject'] =
            response['emailConfig']['forgetPassword']['subject'];
        this.whiteLableData['organizationalUrl'] =
            response['socialMediaUrl'] &&
            response['socialMediaUrl']['organizationalUrl']
                ? response['socialMediaUrl']['organizationalUrl']
                : null;
        this.whiteLableData['linkedinUrl'] =
            response['socialMediaUrl'] &&
            response['socialMediaUrl']['linkedinUrl']
                ? response['socialMediaUrl']['linkedinUrl']
                : null;
        this.whiteLableData['twitterUrl'] =
            response['socialMediaUrl'] &&
            response['socialMediaUrl']['twitterUrl']
                ? response['socialMediaUrl']['twitterUrl']
                : null;
        this.whiteLableData['facebookUrl'] =
            response['socialMediaUrl'] &&
            response['socialMediaUrl']['facebookUrl']
                ? response['socialMediaUrl']['facebookUrl']
                : null;
        this.whiteLableData['knowledgeBaseUrl'] =
            response['socialMediaUrl'] &&
            response['socialMediaUrl']['knowledgeBaseUrl']
                ? response['socialMediaUrl']['knowledgeBaseUrl']
                : null;
        this.titleService.setTitle(
            this.whiteLableData.companyName + ' | Cloud Management'
        );
        this.getSubDomainDetails();
        if (isDefaultData) {
            this.centilyticsData = this.whiteLableData;
        }
        this.setTheme();
        if (this.whiteLableData.miniLogo) {
            this.changeFavIcon(this.whiteLableData.miniLogo);
        }
        callback();
    }
    getSubDomainDetails() {
        const args: IHitApi = {
            url: ApiUrls.SUBDOMAIN_DETAILS,
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            input: {},
            config: {
                authorization: AuthorizationType.NOT_AUTHORIZED,
                isCached: true
            },
            function: (response) => {
                if (response['subDomainConfigured']) {
                    this.isSubdomain = true;
                    if (response['logo']) {
                        this.whiteLableData.darkLogo = response['logo'];
                    }
                    if (response['miniLogo']) {
                        this.whiteLableData.miniLogo = response['miniLogo'];
                    }
                    if (response['companyName']) {
                        this.whiteLableData.companyName =
                            response['companyName'];
                        this.titleService.setTitle(
                            this.whiteLableData.companyName +
                                ' | Cloud Management'
                        );
                    }
                }
                if (this.whiteLableData.miniLogo) {
                    this.changeFavIcon(this.whiteLableData.miniLogo);
                }

                this.configLoaded.next(true);
                try {
                    this.setCustomManifest();
                } catch (error) {
                    Logger.codeLog(error);
                }
            },
            errorFunction: (error) => {
                this.themeError.next(true);
            }
        };
        new HitApi(args, this.httpService, this.ngZone).hitApi();
    }
    setTheme() {
        const cssText = `
        ${WhitelabelingColorPalette.PRIMARY}: ${
            this.whiteLableData.theme[WhitelabelingColorPalette.PRIMARY]
        };
        ${WhitelabelingColorPalette.ACCENT}: ${
            this.whiteLableData.theme[WhitelabelingColorPalette.ACCENT]
        };      
        ${WhitelabelingColorPalette.ACCENTWITHOPACITY5}: ${
            this.whiteLableData.theme[WhitelabelingColorPalette.ACCENTWITHOPACITY5]
        };
        ${WhitelabelingColorPalette.ACCENTWITHOPACITY12}: ${
            this.whiteLableData.theme[WhitelabelingColorPalette.ACCENTWITHOPACITY12]
        };
        ${WhitelabelingColorPalette.DEFAULT_TEXT_COLOR}: ${
            this.whiteLableData.theme[
                WhitelabelingColorPalette.DEFAULT_TEXT_COLOR
            ]
        };
        ${WhitelabelingColorPalette.PRIMARY_BUTTON_COLOR}: ${
            this.whiteLableData.theme[
                WhitelabelingColorPalette.PRIMARY_BUTTON_COLOR
            ]
        };
        ${WhitelabelingColorPalette.SECONDARY_BUTTON_COLOR}: ${
            this.whiteLableData.theme[
                WhitelabelingColorPalette.SECONDARY_BUTTON_COLOR
            ]
        };
        ${WhitelabelingColorPalette.BACKGROUND_COLOR}: ${
            this.whiteLableData.theme[
                WhitelabelingColorPalette.BACKGROUND_COLOR
            ]
        };
        ${WhitelabelingColorPalette.WIDGET_COLOR}: ${
            this.whiteLableData.theme[WhitelabelingColorPalette.WIDGET_COLOR]
        };
        ${WhitelabelingColorPalette.WIDGET_TEXT_COLOR}: ${
            this.whiteLableData.theme[
                WhitelabelingColorPalette.WIDGET_TEXT_COLOR
            ]
        };
        ${WhitelabelingColorPalette.AGGREGATE_DEFAULT_COLOR}: ${
            this.whiteLableData.theme[
                WhitelabelingColorPalette.AGGREGATE_DEFAULT_COLOR
            ]
        };
        ${WhitelabelingColorPalette.AGGREGATE_DEFAULT_TEXT_COLOR}: ${
            this.whiteLableData.theme[
                WhitelabelingColorPalette.AGGREGATE_DEFAULT_TEXT_COLOR
            ]
        };
        ${WhitelabelingColorPalette.MENU_PRIMARY_COLOR}: ${
            this.whiteLableData.theme[
                WhitelabelingColorPalette.MENU_PRIMARY_COLOR
            ]
        };
        ${WhitelabelingColorPalette.MENU_ACCENT_COLOR}: ${
            this.whiteLableData.theme[
                WhitelabelingColorPalette.MENU_ACCENT_COLOR
            ]
        };
        ${WhitelabelingColorPalette.MENU_HOVER_COLOR}: ${
            this.whiteLableData.theme[
                WhitelabelingColorPalette.MENU_HOVER_COLOR
            ]
        };
        ${WhitelabelingColorPalette.MENU_TEXT_COLOR}: ${
            this.whiteLableData.theme[WhitelabelingColorPalette.MENU_TEXT_COLOR]
        };
        ${WhitelabelingColorPalette.HEADER_COLOR}: ${
            this.whiteLableData.theme[WhitelabelingColorPalette.HEADER_COLOR]
        };
        ${WhitelabelingColorPalette.FOOTER_COLOR}: ${
            this.whiteLableData.theme[WhitelabelingColorPalette.FOOTER_COLOR]
        };
        ${WhitelabelingColorPalette.FOOTER_TEXT_COLOR}: ${
            this.whiteLableData.theme[
                WhitelabelingColorPalette.FOOTER_TEXT_COLOR
            ]
        };
        ${WhitelabelingColorPalette.NEGATIVE_COLOR}: ${
            this.whiteLableData.theme[WhitelabelingColorPalette.NEGATIVE_COLOR]
        };
        ${WhitelabelingColorPalette.POSITEVE_COLOR}: ${
            this.whiteLableData.theme[WhitelabelingColorPalette.POSITEVE_COLOR]
        };
        ${WhitelabelingColorPalette.NEUTRAL_COLOR}: ${
            this.whiteLableData.theme[WhitelabelingColorPalette.NEUTRAL_COLOR]
        };
        --accentColorHslValues: ${
            this.whiteLableData.theme[WhitelabelingColorPalette.ACCENT]
                .split('(')[1]
                .split(')')[0]
        };
        --menuAccentHslValues: ${
            this.whiteLableData.theme[
                WhitelabelingColorPalette.MENU_ACCENT_COLOR
            ]
                .split('(')[1]
                .split(')')[0]
        };
        --positiveColorHslValues: ${
            this.whiteLableData.theme[WhitelabelingColorPalette.POSITEVE_COLOR]
                .split('(')[1]
                .split(')')[0]
        };
        --negativeColorHslValues: ${
            this.whiteLableData.theme[WhitelabelingColorPalette.NEGATIVE_COLOR]
                .split('(')[1]
                .split(')')[0]
        };
        --neutralColorHslValues: ${
            this.whiteLableData.theme[WhitelabelingColorPalette.NEUTRAL_COLOR]
                .split('(')[1]
                .split(')')[0]
        };
        --defaultTextColorHslValues: ${
            this.whiteLableData.theme[
                WhitelabelingColorPalette.DEFAULT_TEXT_COLOR
            ]
                .split('(')[1]
                .split(')')[0]
        };
        `;
        this.document.documentElement.style.cssText = cssText;
        this.materialCssVarsService.setPrimaryColor(this.primaryColorInHex);
        this.materialCssVarsService.setAccentColor(this.accentColorInHex);
        this.materialCssVarsService.setWarnColor(this.warnColorInHex);
        this.materialCssVarsService.setVariable(
            MaterialCssVariables.BackgroundSelectedButton,
            this.accentColorInHex
        );
    }

    initiateLoadingPhase() {
        // Changing favIcon to loadin
        this.changeFavIcon('assets/loader.png');
    }

    changeFavIcon(icon) {
        const favIcon = document.querySelector('#websiteFavIcon');
        favIcon.setAttribute('href', icon);
    }

    setCustomManifest() {
        let fullOrigin = this.fullOrigin;
        if (fullOrigin.charAt(fullOrigin.length - 1) !== '/') {
            fullOrigin += '/';
        }
        const manifestJSON = {
            name: this.whiteLableData.companyName,
            short_name: this.whiteLableData.companyName,
            theme_color: '#1976d2',
            background_color: '#fafafa',
            display: 'standalone',
            scope: fullOrigin,
            start_url: fullOrigin,
            icons: [
                {
                    src: this.whiteLableData.miniLogo,
                    sizes: '72x72 96x96 128x128 144x144 152x152 192x192 384x384'
                },
                {
                    src: this.whiteLableData.darkLogo,
                    sizes: '512x512'
                }
            ]
        };
        const manifestString = JSON.stringify(manifestJSON);
        const manifestBlob = new Blob([manifestString], {
            type: 'application/json'
        });
        const manifestURL = URL.createObjectURL(manifestBlob);
        this.document
            .getElementById('manifest-webmanifest')
            .setAttribute('href', manifestURL);
    }
}
