import { Component, OnInit } from '@angular/core';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';

@Component({
    selector: 'app-conventions-modal',
    templateUrl: './conventions-modal.component.html',
    styleUrls: ['./conventions-modal.component.sass']
})
export class ConventionsModalComponent implements OnInit {
    conventions = null;
    getKeys = Object.keys;

    constructor(modalInputData: ModalInjectedData) {
        this.conventions = modalInputData.data;
    }

    ngOnInit(): void {}
}
