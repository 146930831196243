<div class="form-container tw-flex-1 tw-flex tw-py-2 tw-px-5">
    <div class="first-container tw-flex-1 tw-flex tw-flex-col">
        <span class="label tw-text-fs-400"> Fields For Ticket Creation </span>
        <span class="info tw-mt-5 tw-text-fs-200 tw-py-4 tw-px-2">
            Mandatory fields are already selected.<br />Add more fields for
            tickets creation.
        </span>
    </div>
    <div
        class="second-container tw-flex-f3 tw-flex tw-flex-col tw-py-0 tw-px-7 tw-relative tw-ml-11"
    >
        <mat-chip-list
            aria-label="field selection"
            class="tw-p-4 tw-overflow-y-auto tw-overflow-x-hidden"
        >
            <ng-container *ngFor="let field of mandatoryFields">
                <mat-chip [selectable]="true">{{ field.label }}</mat-chip>
            </ng-container>
            <ng-container *ngFor="let field of otherFields">
                <mat-chip
                    [selectable]="true"
                    [removable]="true"
                    (removed)="remove(field)"
                    >{{ field.label }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </ng-container>
        </mat-chip-list>
        <div
            class="add-btn tw-absolute tw-bottom-4 tw-right-4 tw-flex tw-justify-end"
        >
            <button
                (click)="openFieldSelectionModal()"
                class="tw-text-fs-600 tw-h-8 tw-w-8 tw-cursor-pointer tw-m-0"
            >
                <i class="fas fa-plus noSpace"></i>
            </button>
        </div>
    </div>
</div>
<div class="btn tw-mx-5 tw-mb-5">
    <app-multi-button-generator
        *ngIf="buttonsInput"
        [buttonGenInputs]="buttonsInput"
    >
    </app-multi-button-generator>
</div>
