<div class="container">
    <div class="tw-py-5">
        <app-modal-info-template
            [infoData]="infoData"
        ></app-modal-info-template>
    </div>
    <mat-tab-group mat-stretch-tabs (selectedTabChange)="tabChanged($event)">
        <mat-tab [label]="Tab.TAB_1">
            <div class="form-container">
                <div class="tw-w-3/4">
                    <app-form-generator
                        *ngIf="formGenInput"
                        [formGeneratorInput]="formGenInput"
                        (formGroupRef)="eaFormGroup = $event"
                    ></app-form-generator>
                </div>
            </div>
        </mat-tab>
        <mat-tab [label]="Tab.TAB_2">
            <div class="form-container">
                <div class="tw-w-3/4">
                    <app-form-generator
                        *ngIf="newMethodFormGenInput"
                        [formGeneratorInput]="newMethodFormGenInput"
                        (formGroupRef)="newEaFormGroup = $event"
                    ></app-form-generator>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
    <div class="buttons">
        <app-multi-button-generator [buttonGenInputs]="buttonInputs">
        </app-multi-button-generator>
    </div>
</div>
