import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import {
    APPLICATION_JSON,
    BufferEncoders,
    MESSAGE_RSOCKET_COMPOSITE_METADATA,
    MESSAGE_RSOCKET_ROUTING,
    RSocketClient,
    encodeCompositeMetadata
} from 'rsocket-core';
import { ConnectionStatus, ReactiveSocket } from 'rsocket-types';
import RSocketWebSocketClient from 'rsocket-websocket-client';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CacheMain } from 'src/app/shared/classes/CacheMain';
import { IIotInput } from 'src/app/shared/interfaces/iot/IIotInput';
import { HitApi } from '../../../classes/HitApi';
import { Logger } from '../../../classes/Logger';
import { AuthorizationType } from '../../../enums/AuthorizationType';
import { RequestType } from '../../../enums/RequestType';
import { IHitApi } from '../../../interfaces/hit-api/IHitApi';
import { IHitApiConfig } from '../../../interfaces/hit-api/IHitApiConfig';
import { ApiResponseCacheService } from '../../cache/api-response-cache/api-response-cache.service';
import { IotService } from '../../iot/iot.service';
import { environment } from './../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HttpService extends CacheMain {
    // readonly DEFAULT_BASE_URL = `http://api-prod.centilytics.com`;
    // readonly DEFAULT_BASE_URL = `http://api-stage.centilytics.com`;

    readonly BASE_URL_LOCAL_STORAGE_KEY = `baseUrl`;
    readonly WS_BASE_URL_LOCAL_STORAGE_KEY = `wsBaseUrl`;
    readonly TOKEN_LOCAL_STORAGE_KEY = `token`;
    readonly BEARER_TOKEN_LOCAL_STORAGE_KEY = `bearerToken`;
    readonly IOT_CONFIG_DATA_STORAGE_KEY = `iotConfig`;
    readonly SAML_TOKEN_LOCAL_STORAGE_KEY = `samlToken`;

    get DEFAULT_BASE_URL(): string {
        if (localStorage.getItem('DEFAULT_BASE_URL')) {
            return localStorage.getItem('DEFAULT_BASE_URL');
        }
        return environment.baseUrl;
    }

    set DEFAULT_BASE_URL(baseUrl: string) {
        localStorage.setItem('DEFAULT_BASE_URL', baseUrl);
    }

    get DEFAULT_WS_BASE_URL(): string {
        if (localStorage.getItem('DEFAULT_WS_BASE_URL')) {
            return localStorage.getItem('DEFAULT_WS_BASE_URL');
        }
        return environment.wsBaseUrl;
    }

    set DEFAULT_WS_BASE_URL(wsBaseUrl: string) {
        localStorage.setItem('DEFAULT_WS_BASE_URL', wsBaseUrl);
    }

    insightIOTDataEmitter: Subject<any> = new Subject();

    apiTimeMapper: Record<symbol, number> = {};
    streamTimeMapper: Map<symbol, number> = new Map();
    jobIdConfigMapper: Record<string, IHitApi> = {};
    apiMapper = {};
    resetIotTask: Subject<Symbol> = new Subject<Symbol>();
    activeStreams: Set<Symbol | String> = new Set();

    constructor(
        private http: HttpClient,
        public iotService: IotService,
        public apiResponseCacheService: ApiResponseCacheService,
        public ngZone: NgZone
    ) {
        super(`API_CONFIG_DATA`, true);

        this.iotService.iotDataReceived.subscribe((iotData) => {
            iotData['preSignedUrl'] = JSON.parse(
                `{"result":"${iotData['preSignedUrl']}"}`
            )['result'];
            if (iotData.jobId in this.jobIdConfigMapper) {
                let options = {};
                if (iotData['responseType']) {
                    options = {
                        responseType: iotData['responseType']
                    };
                }
                function iotSuccess(response) {
                    const args = this.jobIdConfigMapper[iotData.jobId];
                    if (
                        this.apiTimeMapper[args.uniqueIdentity] &&
                        args.config.time ===
                            this.apiTimeMapper[args.uniqueIdentity].time
                    ) {
                        const returnObj = {};
                        returnObj[args.uniqueIdentity] = {
                            args: args,
                            data:
                                response instanceof Blob
                                    ? response
                                    : response['body']
                                    ? JSON.parse(response['body'])
                                    : response,
                            preSignedUrl: iotData['preSignedUrl']
                        };
                        this.insightIOTDataEmitter.next(returnObj);
                    }
                }
                if (
                    iotData['preSignedUrl'] &&
                    iotData['preSignedUrl'] !== 'undefined'
                ) {
                    this.http.get(iotData['preSignedUrl'], options).subscribe(
                        (response) => {
                            iotSuccess.bind(this)(response);
                        },
                        (error) => {
                            const args = this.jobIdConfigMapper[iotData.jobId];
                            if (
                                this.apiTimeMapper[args.uniqueIdentity] &&
                                args.config.time ===
                                    this.apiTimeMapper[args.uniqueIdentity].time
                            ) {
                                const returnObj = {};
                                returnObj[args.uniqueIdentity] = {
                                    args: args,
                                    data: null,
                                    preSignedUrl: iotData['preSignedUrl']
                                };
                                this.insightIOTDataEmitter.next(returnObj);
                            }
                        }
                    );
                } else {
                    iotSuccess.bind(this)({});
                }
            }
        });
        // this.iotService.httpIotErrorCallback =
        //     this.hitIotErrorLogApi.bind(this);
    }

    get authorizationHeaders(): HttpHeaders {
        return new HttpHeaders();
    }

    get baseUrl(): string {
        return this.fetch(this.BASE_URL_LOCAL_STORAGE_KEY)
            ? this.fetch(this.BASE_URL_LOCAL_STORAGE_KEY)
            : this.DEFAULT_BASE_URL;
    }

    set baseUrl(baseUrl: string) {
        this.store(this.BASE_URL_LOCAL_STORAGE_KEY, baseUrl);
    }

    get wsBaseUrl(): string {
        return this.fetch(this.WS_BASE_URL_LOCAL_STORAGE_KEY)
            ? this.fetch(this.WS_BASE_URL_LOCAL_STORAGE_KEY)
            : this.DEFAULT_WS_BASE_URL;
    }

    set wsBaseUrl(wsBaseUrl: string) {
        this.store(this.WS_BASE_URL_LOCAL_STORAGE_KEY, wsBaseUrl);
    }

    get authorizationToken(): string {
        return this.fetch(this.TOKEN_LOCAL_STORAGE_KEY);
    }

    set authorizationToken(value: string) {
        this.store(this.TOKEN_LOCAL_STORAGE_KEY, value);
    }

    get bearerToken(): string {
        return this.fetch(this.BEARER_TOKEN_LOCAL_STORAGE_KEY);
    }

    set bearerToken(value: string) {
        this.store(this.BEARER_TOKEN_LOCAL_STORAGE_KEY, value);
    }

    get iotConfig(): any {
        return this.fetch(this.IOT_CONFIG_DATA_STORAGE_KEY);
    }

    set iotConfig(value: any) {
        this.store(this.IOT_CONFIG_DATA_STORAGE_KEY, value);
    }

    get samlAuthorizationToken(): string {
        return this.fetch(this.SAML_TOKEN_LOCAL_STORAGE_KEY);
    }

    set samlAuthorizationToken(value: string) {
        this.store(this.SAML_TOKEN_LOCAL_STORAGE_KEY, value);
    }

    hitApi(
        url: string,
        requestType: RequestType,
        input: any,
        config: IHitApiConfig
    ): Observable<any> {
        if (ApiUrls.IS_VIA_IP) {
            config.ignoreBaseUrl = true;
        }
        const apiUrl = url;
        Logger.codeLog(`Hitting Url: ${apiUrl}`);

        if (config.isCached) {
            const output = this.apiResponseCacheService.fetch(url);
            if (output) {
                return new Observable<any>((res) => {
                    this.ngZone.runOutsideAngular(() => {
                        setTimeout(() => {
                            res.next(output['output']);
                        }, 0);
                    });
                });
            }
        }

        const options = {
            headers: config.defaultHeaders ? config.defaultHeaders : {}
        };
        options.headers = {
            ...options.headers,
            websiteOrigin: location.origin
        };
        if (config.authorization === AuthorizationType.BEARER_TOKEN) {
            options.headers['Authorization'] = `Bearer ${this.bearerToken}`;
        } else if (
            config.authorization === AuthorizationType.AUTHORIZATION_TOKEN
        ) {
            options.headers[
                'authorizationToken'
            ] = `${this.authorizationToken}`;
        } else if (
            config.authorization === AuthorizationType.AUTHORIZATION_TOKEN_V2
        ) {
            options.headers[
                'authorizationToken'
            ] = `Bearer ${this.bearerToken}`;
        }

        if (
            (requestType === RequestType.GET ||
                requestType === RequestType.DELETE) &&
            input !== null
        ) {
            options.headers['Content-Type'] = `application/json`;
            // url += new URLSearchParams(input).toString();
            if (requestType === RequestType.DELETE) {
                options['body'] = input;
            }
        }

        if (config.downloadable) {
            options['responseType'] = 'blob';
        }

        if (config.responseType) {
            options['responseType'] = config.responseType;
        }

        options['observe'] = 'response';
        if (requestType === RequestType.POST) {
            return this.http.post(apiUrl, input, options);
        } else if (requestType === RequestType.GET) {
            return this.http.get(apiUrl, options);
        } else if (requestType === RequestType.PUT) {
            return this.http.put(apiUrl, input, options);
        } else if (requestType === RequestType.PATCH) {
            return this.http.patch(apiUrl, input, options);
        } else if (requestType === RequestType.DELETE) {
            return this.http.delete(apiUrl, options);
        }
    }

    hitStreamApi(
        apiConfig: IHitApi,
        partialDatahandle,
        errorHandle,
        allDataCollected
    ): {
        client: RSocketClient<BufferSource, BufferSource>;
        socket: ReactiveSocket<BufferSource, BufferSource>;
        connectionStatus: Subject<ConnectionStatus>;
    } {
        let streamHost = null;
        if (apiConfig.config && apiConfig.config.appendWebSocketBaseUrl) {
            streamHost =
                this.wsBaseUrl + '/' + apiConfig.streamData.streamHost + '/';
        } else {
            streamHost = apiConfig.streamData.streamHost;
        }
        let socketRef = null;
        const connectionStatus: Subject<ConnectionStatus> = new Subject();
        // Only Bearer token is handled as of now
        const client = new RSocketClient<BufferSource, BufferSource>({
            setup: {
                keepAlive: 60000,
                lifetime: 1000000,
                dataMimeType: APPLICATION_JSON.string,
                metadataMimeType: MESSAGE_RSOCKET_COMPOSITE_METADATA.string
            },
            transport: new RSocketWebSocketClient(
                {
                    debug: true,
                    url: streamHost,
                    wsCreator: (url) => {
                        return new WebSocket(url);
                    }
                },
                BufferEncoders
            )
        });
        client.connect().then(
            (socket: ReactiveSocket<BufferSource, BufferSource>) => {
                socketRef = socket;
                socket
                    .requestStream({
                        data: Buffer.from(JSON.stringify(apiConfig.input)),
                        metadata: encodeCompositeMetadata([
                            [
                                MESSAGE_RSOCKET_ROUTING,
                                Buffer.from(
                                    String.fromCharCode(
                                        apiConfig.streamData.streamRouteSuffix
                                            .length
                                    ) + apiConfig.streamData.streamRouteSuffix
                                )
                            ],
                            [
                                'message/x.rsocket.authentication.bearer.v0',
                                Buffer.from(this.bearerToken)
                            ],
                            [
                                'websiteOrigin',
                                Buffer.from(window.location.origin)
                            ]
                        ])
                    })
                    .subscribe({
                        onComplete: () => {
                            socket.close();
                            client.close();
                            allDataCollected();
                        },
                        onError: (error) => {
                            client.close();
                            socket.close();
                            errorHandle(error);
                        },
                        onNext: (value) => {
                            const data = new TextDecoder('utf-8').decode(
                                value.data
                            );
                            partialDatahandle(data);
                        },
                        onSubscribe: (sub) => {
                            const MAX = 2147483647;
                            sub.request(MAX);
                        }
                    });
                socket.connectionStatus().subscribe((event) => {
                    connectionStatus.next(event);
                });
            },
            (error) => {
                errorHandle(error);
            }
        );
        return {
            client,
            socket: socketRef,
            connectionStatus
        };
    }

    hitParallelApis(apis: IHitApi[]): Subject<Map<symbol | string, any>> {
        const responses: Map<symbol | string, any> = new Map();
        const responsesObs: Subject<Map<symbol | string, any>> = new Subject();
        apis.forEach((api) => {
            if (!api.errorFunction) {
                api.errorFunction = () => {
                    responses.set(api.uniqueIdentity, null);
                    if (apis.length === responses.size) {
                        // All responses captured
                        responsesObs.next(responses);
                    }
                };
            }
            const hitApi = new HitApi(api, this, this.ngZone);
            const resObs = hitApi.hitApi();
            resObs?.pipe(take(2)).subscribe((response) => {
                responses.set(api.uniqueIdentity, response);
                if (apis.length === responses.size) {
                    // All responses captured
                    responsesObs.next(responses);
                }
            });
        });
        return responsesObs;
    }

    getDataFromS3(url, callback) {
        this.http.get(url).subscribe((res) => {
            callback(JSON.parse(res['body']));
        });
    }

    // hitIotErrorLogApi(errorType, email, domainId, exception?) {
    //     let parsedException = null;
    //     if (exception) {
    //         try {
    //             parsedException = JSON.stringify(exception);
    //         } catch (error) {
    //             parsedException = exception;
    //         }
    //     }

    //     if (email) {
    //         email = email.replace('"', '');
    //         email = email.replace('"', '');

    //         email = `${email}-${errorType}`;
    //     }

    //     const url = ApiUrls.IOT_FAILURE_LOGGER;

    //     const apiArgs: IHitApi = {
    //         url: ApiUrls.IOT_FAILURE_LOGGER.replace(
    //             '{domainId}',
    //             domainId
    //         ).replace('{emailId}', email),
    //         intactUrl: ApiUrls.IOT_FAILURE_LOGGER,
    //         config: {
    //             authorization: null,
    //             ignoreBaseUrl: true,
    //             defaultHeaders: {
    //                 'Content-Type': 'text/plain',
    //             },
    //         },
    //         requestType: RequestType.POST,
    //         input: `${errorType}---${parsedException}`,
    //         uniqueIdentity: Symbol(),
    //         function: (res) => {},
    //     };

    //     new HitApi(apiArgs, this, this.ngZone).hitApi();
    // }

    /**
     * Function is responible hit api for logging all the errors and API responses.
     * @param ngZone NgZone reference.
     * @param isJobId Boolean if the response is coming from IOT.
     * @param inputData Data that needs to be logged.
     * @param isFrontendError Boolean if the error is frontend specific error.
     * @param apiPath Path of API which we are logging.
     */

    hitLogApi(
        ngZone: NgZone,
        isJobId: boolean,
        inputData: any,
        isFrontendError: boolean,
        apiPath: string
    ) {
        return;
        if (this.bearerToken) {
            const apiArgs: IHitApi = {
                url: ApiUrls.API_LOGGER,
                input: {
                    jobId: isJobId,
                    fe: isFrontendError,
                    apiPath
                },
                requestType: RequestType.POST,
                function: (response) => {
                    if (response && response.url) {
                        const apiArgs: IHitApi = {
                            url: response.url,
                            input: inputData,
                            requestType: RequestType.PUT,
                            function: () => {},
                            uniqueIdentity: Symbol(),
                            config: {
                                authorization: AuthorizationType.NOT_AUTHORIZED,
                                ignoreBaseUrl: true
                            }
                        };
                        new HitApi(apiArgs, this, ngZone).hitApi(true);
                    }
                },
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                }
            };
            new HitApi(apiArgs, this, ngZone).hitApi(true);
        }
    }

    hitIotStream(iotInput: IIotInput) {
        return this.iotService.iotServiceNew.makeConnection(iotInput);
    }
}
