import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GridOptions } from 'ag-grid-community';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { FormGeneratorModalComponent } from 'src/app/shared/components/modal-templates/form-generator-modal/form-generator-modal.component';
import { MultiButtonGeneratorComponent } from 'src/app/shared/components/multi-button-generator/multi-button-generator.component';
import { ActionState } from 'src/app/shared/enums/ActionState';
import { ActionVisibility } from 'src/app/shared/enums/ActionVisibility';
import {
    DropdownMultipleFieldAppearance,
    DropdownSingleFieldAppearance,
    TextFieldAppearance
} from 'src/app/shared/enums/AppearanceType';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IDropdownData } from 'src/app/shared/interfaces/dropdown-data/IDropdownData';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { CustomValidators } from './../../../../classes/CustomValidators';
import { Helper } from './../../../../classes/Helper';
import { IIcon } from './../../../../interfaces/icon-data/IIcon';
import { IConfirmationModal } from './../../../../interfaces/modal/IConfirmationModal';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';

@Component({
    selector: 'app-billing-markup',
    templateUrl: './billing-markup.component.html',
    styleUrls: ['./billing-markup.component.sass']
})
export class BillingMarkupComponent implements OnInit {
    constructor(
        private widgetData: WidgetInjectedData,
        private modalService: ModalService
    ) {}
    widgetRef: Widget;
    tableGeneratorInput: ITableGeneratorInput;
    agGrid: GridOptions;
    @ViewChild('deleteTemplate')
    deleteTemplateRef: TemplateRef<any>;
    warningIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-exclamation-triangle',
        extraClass: 'yellow-severity-color'
    };
    ngOnInit(): void {
        this.widgetRef = this.widgetData.widgetRef;
        this.widgetData.widgetRef.operationalActions.next(
            new Map().set('Bulk Action', [
                {
                    state: ActionState.ENABLED,
                    visibility: ActionVisibility.VISIBLE,
                    message: '',
                    function: () => {
                        this.openDeleteConfirmationModal(null, true);
                    },
                    icon: {
                        type: IconType.SVG,
                        class: 'trash_icon_filled',
                        text: 'Bulk Delete',
                        extraClass: 'inline-fix-box-1 svg-black-fill'
                    }
                }
            ])
        );
        this.tableGeneratorInput = {
            selection: 'multiple',
            columns: [
                {
                    columnKey: 'ruleName',
                    columnName: 'Policy Name'
                },
                {
                    columnKey: 'customers',
                    columnName: 'Customers',
                    cellRenderer: (rowData) => {
                        return `${rowData.data.customers.length} selected`;
                    }
                },
                {
                    columnKey: 'percentage',
                    columnName: 'Percentage',
                    cellRenderer: (rowData) => {
                        return `${rowData.data.percentage}%`;
                    }
                },
                {
                    columnKey: 'priceType',
                    columnName: 'Price Type'
                },
                {
                    columnKey: 'lastModifiedDate',
                    columnName: 'Last Modified Date & Time'
                },
                {
                    columnKey: 'markType',
                    columnName: 'Charge Type'
                },
                {
                    columnKey: '',
                    columnName: 'Action',
                    componentFramework: MultiButtonGeneratorComponent,
                    buttonGen: true,
                    pinned: 'right',
                    valueFormatter: (rowData: any) => {
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'Edit',
                                buttonColorType: ButtonColorType.INFO,
                                buttonType: ButtonType.TEXT,
                                showLoader: true,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    this.openAddCustomPriceModal(
                                        true,
                                        rowData,
                                        buttonRef
                                    );
                                }
                            },
                            {
                                buttonName: 'Delete',
                                buttonColorType: ButtonColorType.WARN,
                                buttonType: ButtonType.TEXT,
                                function: () => {
                                    this.openDeleteConfirmationModal(rowData);
                                }
                            }
                        ];
                        rowData['buttonGenInputs'] = buttons;
                        return rowData;
                    }
                }
            ],
            listExtraction: {
                type: 'DIRECT'
            },
            buttons: [
                {
                    buttonName: 'Add',
                    function: (buttonRef: IButtonGeneratorInput) => {
                        this.openAddCustomPriceModal(false, null, buttonRef);
                    },
                    buttonColorType: ButtonColorType.PRIMARY,
                    showLoader: true,
                    buttonType: ButtonType.FLAT
                }
            ]
        };
    }
    openAddCustomPriceModal(
        isEdit?: boolean,
        rowData?,
        buttonRef?: IButtonGeneratorInput
    ) {
        buttonRef.loader = true;
        const priceTypeValue = [];
        let markTypeValue;
        if (isEdit) {
            if (rowData.data.priceType.includes(PriceType.LISTPRICE)) {
                priceTypeValue.push({
                    id: 'listPrice'
                });
            }
            if (rowData.data.priceType.includes(PriceType.MYPRICE)) {
                priceTypeValue.push({
                    id: 'myPrice'
                });
            }
            if (rowData.data.markType.includes(MarkType.MARKDOWN)) {
                markTypeValue = {
                    id: 'MARKDOWN'
                };
            }
            if (rowData.data.markType.includes(MarkType.MARKUP)) {
                markTypeValue = {
                    id: 'MARKUP'
                };
            }
        }
        this.getCustomersList(
            (customersList: {
                listCustomer: IDropdownData[];
                alreadySelectedCustomer: IDropdownData[];
            }) => {
                const _listCustomer = [];
                customersList.listCustomer.map((customer) => {
                    customersList.alreadySelectedCustomer.map(
                        (alreadyCustomer) => {
                            if (customer.id === alreadyCustomer.id) {
                                customer.disabled = true;
                                if (isEdit && rowData) {
                                    rowData.data.customers.forEach((c) => {
                                        if (customer.id === c.id) {
                                            customer.disabled = false;
                                        }
                                    });
                                }
                            }
                        }
                    );
                    _listCustomer.push(customer);
                });
                // Get Charge Type
                const hitApiArgs: IHitApi = {
                    url: ApiUrls.BILLING_MARKUP_PRICE_TYPE_API,
                    function: (chargeType) => {
                        const formGenInput: IFormGeneratorInput = {
                            formName: '',
                            state: FormState.CREATE,
                            fields: [
                                {
                                    name: 'ruleName',
                                    fieldType: FilterType.TEXT,
                                    appearance: TextFieldAppearance.TYPE_3,
                                    label: 'Policy Name',
                                    placeholder: 'Enter Policy Name',
                                    disabled: isEdit,
                                    showLabel: true,
                                    required: true,
                                    preventFieldReset: isEdit ? true : false,
                                    validations: [
                                        {
                                            validator:
                                                CustomValidators.required,
                                            errorMessage:
                                                'Policy name is required'
                                        }
                                    ],
                                    value: isEdit ? rowData.data.ruleName : ''
                                },
                                {
                                    name: 'tenantIds',
                                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                                    appearance:
                                        DropdownMultipleFieldAppearance.TYPE_3,
                                    label: 'Customers',
                                    placeholder: 'Select Customers',
                                    showLabel: true,
                                    listData: _listCustomer,
                                    required: true,
                                    validations: [
                                        {
                                            validator:
                                                CustomValidators.required,
                                            errorMessage:
                                                'Please select at least one customer'
                                        }
                                    ],
                                    value: isEdit
                                        ? rowData.data.customers.map(
                                              (customer) => customer.id
                                          )
                                        : ''
                                },
                                {
                                    name: 'priceType',
                                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                                    appearance:
                                        DropdownMultipleFieldAppearance.TYPE_3,
                                    label: 'Price Type',
                                    placeholder: 'Select Price Type',
                                    showLabel: true,
                                    required: true,
                                    listData: [
                                        {
                                            id: 'listPrice',
                                            label: 'List Price'
                                        },
                                        { id: 'myPrice', label: 'My Price' }
                                    ],
                                    validations: [
                                        {
                                            validator:
                                                CustomValidators.required,
                                            errorMessage:
                                                'Price Type is Required'
                                        }
                                    ],
                                    value: isEdit
                                        ? priceTypeValue.map((val) => val.id)
                                        : ''
                                },
                                {
                                    name: 'markType',
                                    fieldType: FilterType.DROPDOWN_SINGLE,
                                    appearance:
                                        DropdownSingleFieldAppearance.TYPE_3,
                                    label: 'Charge Type',
                                    placeholder: 'Select Charge Type',
                                    showLabel: true,
                                    required: true,
                                    listData: chargeType,
                                    validations: [
                                        {
                                            validator:
                                                CustomValidators.required,
                                            errorMessage:
                                                'Charge Type is Required'
                                        }
                                    ],
                                    value: isEdit ? markTypeValue.id : ''
                                },
                                {
                                    name: 'percentage',
                                    fieldType: FilterType.NUMBER,
                                    appearance: TextFieldAppearance.TYPE_3,
                                    label: 'Percentage',
                                    placeholder: 'Select Percentage',
                                    showLabel: true,
                                    required: true,
                                    validations: [
                                        {
                                            validator:
                                                CustomValidators.required,
                                            errorMessage:
                                                'Percentage is Required'
                                        },
                                        {
                                            validator:
                                                CustomValidators.numberValidator,
                                            errorMessage: 'Invalid value'
                                        }
                                    ],
                                    value: isEdit ? rowData.data.percentage : ''
                                }
                            ],
                            submitButton: {
                                buttonName: 'Save',
                                buttonColorType: ButtonColorType.PRIMARY,
                                buttonType: ButtonType.FLAT,
                                showLoader: true,
                                function: (
                                    buttonRef: IButtonGeneratorInput,
                                    formGroup: FormGroup,
                                    modalId
                                ) => {
                                    Helper.markAllFieldAsTouched(
                                        formGroup,
                                        true
                                    );
                                    if (formGroup.invalid) {
                                        return;
                                    }
                                    buttonRef.loader = true;
                                    const addApiConfig =
                                        Helper.generateHitApiConfig(
                                            isEdit
                                                ? this.widgetRef.widgetData
                                                      .widgetInfo['update']
                                                : this.widgetRef.widgetData
                                                      .widgetInfo['create']
                                        );

                                    if (isEdit) {
                                        addApiConfig.url =
                                            addApiConfig.url.replace(
                                                '{id}',
                                                rowData.data.id
                                            );
                                    }
                                    addApiConfig.input = this.generatePayload(
                                        isEdit ? 'edit' : 'add',
                                        formGroup,
                                        rowData
                                    );
                                    addApiConfig.function = () => {
                                        this.widgetRef.notificationsService.showSnackBar(
                                            isEdit
                                                ? 'Rule Updated Successfully'
                                                : 'Rule Added Successfully'
                                        );
                                        buttonRef.loader = false;
                                        this.modalService.closeModal(
                                            null,
                                            modalId
                                        );
                                        this.widgetRef.refreshWidget();
                                    };
                                    addApiConfig.errorFunction = (err) => {
                                        Helper.showErrorMessage(
                                            this.widgetRef.notificationsService,
                                            err,
                                            'An Error Occured'
                                        );
                                        buttonRef.loader = false;
                                        this.modalService.closeModal(
                                            null,
                                            modalId
                                        );
                                    };
                                    new HitApi(
                                        addApiConfig,
                                        this.widgetRef.httpService,
                                        this.widgetRef.ngZone
                                    ).hitApi();
                                }
                            }
                        };
                        const modalData: IModalData = {
                            modalName: isEdit
                                ? 'Edit Custom Price Configuration'
                                : 'Add Custom Price Configuration',
                            modalIcon: null,
                            modalType: ModalType.MIDDLE,
                            sourceId: Symbol(),
                            modalWidthVw: 55,
                            modalSteps: [
                                {
                                    resetModal: true,
                                    stepName: '',
                                    stepData: {
                                        componentToLoad:
                                            FormGeneratorModalComponent,
                                        payload: {
                                            data: formGenInput
                                        }
                                    }
                                }
                            ]
                        };
                        this.modalService.openModal(modalData);
                        buttonRef.loader = false;
                        this.widgetRef.changeDetectorRef.detectChanges();
                    },
                    requestType: RequestType.GET,
                    input: {},
                    uniqueIdentity: Symbol(),
                    config: {
                        authorization: AuthorizationType.BEARER_TOKEN
                    }
                };

                new HitApi(
                    hitApiArgs,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            }
        );
    }

    getCustomersList(callbackFn?) {
        const hitApiArgs: IHitApi = {
            url: ApiUrls.BILLING_MARKUP_CUSTOMER_LIST_API,
            function: (res) => {
                callbackFn(res);
            },
            requestType: RequestType.GET,
            input: {},
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            }
        };

        new HitApi(
            hitApiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    generatePayload(payloadFor: string, formGroup: FormGroup, rowData?) {
        let finalPayload;
        switch (payloadFor) {
            case 'add':
                finalPayload = formGroup.value;
                break;
            case 'edit':
                finalPayload = formGroup.value;
                finalPayload['ruleName'] = rowData.data.ruleName;
                break;
        }
        return finalPayload;
    }

    openDeleteConfirmationModal(rowData?, bulkDelete?: boolean) {
        const idsToBedeleted = [];
        if (bulkDelete && this.agGrid && this.agGrid.api) {
            const selectedNodes = this.agGrid.api.getSelectedNodes();
            if (!selectedNodes.length) {
                this.widgetRef.notificationsService.showSnackBar(
                    'Please select at least one rule',
                    true
                );
                return;
            }
            selectedNodes.map((node) => {
                idsToBedeleted.push(node.data.id);
            });
        }
        if (!bulkDelete) {
            idsToBedeleted.push(rowData.data.id);
        }
        const modalData: IConfirmationModal = {
            modalName: 'Delete Custom Price Configuration',
            confirmationMessage: '',
            bodyTemplate: this.deleteTemplateRef,
            buttonText: 'Delete',
            buttonColorType: ButtonColorType.WARN,
            loaderOnExec: true,
            function: () => {
                const apiArgs = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo.delete
                );
                apiArgs.input = { idsToBedeleted };
                apiArgs.function = (buttonRef: IButtonGeneratorInput) => {
                    buttonRef.loader = false;
                    this.modalService.closeModal(null, modalId);
                    this.widgetRef.refreshWidget();
                    this.widgetRef.notificationsService.showSnackBar(
                        'Rule Deleted Successfully'
                    );
                };
                apiArgs.errorFunction = (err) => {
                    this.widgetRef.notificationsService.showSnackBar(err, true);
                    this.modalService.closeModal(null, modalId);
                };
                new HitApi(
                    apiArgs,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            },
            cancelButtonName: 'Cancel',
            cancelButtonType: 'ACCENT',
            cancelButtonAttribute: 'filled'
        };
        const modalId = this.modalService.openConfirmationModal(modalData);
    }
}
enum PriceType {
    MYPRICE = 'My Price',
    LISTPRICE = 'List Price'
}
enum MarkType {
    MARKUP = 'Mark Up',
    MARKDOWN = 'Mark Down'
}
