<app-table-generator
    [tableInput]="tableGenInput"
    [widgetRef]="widgetRef"
    [isRowSelectable]="isRowSelectable.bind(this)"
    (gridRef)="agGrid = $event; onGridReady()"
    (selectionChanged)="onSelectionChanged()"
></app-table-generator>
<ng-template #confirmAclDeleteTemplate>
    <div
        *ngIf="deleteAclList && deleteAclList.length"
        class="tw-ml-8 tw-font-bold"
    >
        <li *ngFor="let deleteAcl of deleteAclList">{{ deleteAcl["name"] }}</li>
    </div>
    <div class="tw-mt-3 tw-text-negative">
        *ACL associated with the customer or view cannot be deleted.
    </div>
</ng-template>
