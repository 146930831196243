import { Component, OnInit } from '@angular/core';
import { IconType } from 'src/app/shared/enums/IconType';

@Component({
    selector: 'app-global-error',
    templateUrl: './global-error.component.html',
    styleUrls: ['./global-error.component.sass']
})
export class GlobalErrorComponent implements OnInit {
    errorIcon = {
        type: IconType.SVG_ASSETS,
        class: 'global_error'
    };
    constructor() {}

    ngOnInit(): void {}
}
