<mat-tab-group>
    <mat-tab *ngFor="let tab of tabContentList['_results']">
        <ng-template mat-tab-label>
            <div class="label-wrapper" (click)="getActiveTab(label.innerText)">
                <h2 #label>{{ tab.label }}</h2>
            </div>
        </ng-template>
        <div class="content-wrapper">
            <ng-container [ngTemplateOutlet]="tab.templateRef"></ng-container>
        </div>
    </mat-tab>
</mat-tab-group>
