import { Injectable } from '@angular/core';
import { AsyncIndexedDbCacheMain } from 'src/app/shared/classes/AsyncIndexedDbCacheMain';

@Injectable({
    providedIn: 'root'
})
/**
 * This service is created for redirection of SSO Login Redirection.
 * Caution: Don't use deleteCollection in this service as this service has critical dependency
 * */
export class MiscellaneousCacheService extends AsyncIndexedDbCacheMain {
    constructor() {
        const MISCELLANEOUS_CACHE_PREFIX = 'MISCELLANEOUS_CACHE';
        super(MISCELLANEOUS_CACHE_PREFIX, true);
    }
}

export const SSO_REDIRECT_URL = 'SSO_REDIRECT_URL';
