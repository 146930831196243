<div
    *ngIf="formGenInput"
    class="tw-overflow-x-auto tw-overflow-scroll color-heading"
>
    <div *ngFor="let item of formGenInput">
        <span class="tw-text-fs-400 tw-font-semibold tw-pl-5">
            {{ item.heading }}
        </span>
        <app-form-generator
            [formGeneratorInput]="item.formInput"
            (formGroupRef)="setValues($event)"
        >
        </app-form-generator>
    </div>
</div>

<div class="tw-flex tw-justify-end tw-py-3">
    <app-button-generator [buttonGenInput]="preview"></app-button-generator>
    <app-button-generator [buttonGenInput]="save"></app-button-generator>
</div>
