import { SubSink } from 'subsink';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
	AfterViewInit,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, shareReplay } from 'rxjs/operators';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Tree } from 'src/app/shared/classes/data-structure/tree/Tree';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { PortletType } from 'src/app/shared/enums/PortletType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { IAttributeInfo } from 'src/app/shared/interfaces/views/IAttributeInfo';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { WhiteLabelingService } from 'src/app/shared/services/white-labeling/white-labeling.service';
import { EditModuleIconComponent } from '../edit-module-icon/edit-module-icon.component';
import { EditViewDataComponent } from '../edit-view-data/edit-view-data.component';
import { LandingPageSelectionComponent } from '../landing-page-selection/landing-page-selection.component';
import { ModuleWidgetAdditionComponent } from '../module-widget-addition/module-widget-addition.component';
import { Widget } from 'src/app/shared/classes/Widget';
import { Node } from 'src/app/shared/classes/data-structure/tree/Node';
import { ModalAction } from 'src/app/shared/enums/ModalAction';
import { AclModalStepTwoComponent } from '../../acl-modals/acl-modal-step-two/acl-modal-step-two.component';
import { AclModalStepOneComponent } from '../../acl-modals/acl-modal-step-one/acl-modal-step-one.component';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { GroupWidgetModalComponent } from '../group-widget-modal/group-widget-modal.component';

@Component({
	selector: 'app-create-duplicate-view',
	templateUrl: './create-duplicate-view.component.html',
	styleUrls: ['./create-duplicate-view.component.sass'],
})
export class CreateDuplicateViewComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	widgetRef: Widget;
	companyLogo: IIcon;

	folderIcon: IIcon = {
		type: IconType.SVG,
		class: 'open_folder',
		extraClass: 'svg-white-fill',
	};
	toggleCloseIcon: IIcon = {
		type: IconType.SVG,
		class: 'collapse_menu',
		extraClass: 'svg-white-fill',
	};
	toggleOpenIcon: IIcon = {
		type: IconType.SVG,
		class: 'menu_icon',
		extraClass: 'svg-white-fill',
	};
	trashIcon: IIcon = {
		type: IconType.SVG,
		class: 'trash',
		extraClass: 'svg-black-fill',
	};
	trashIconWarn: IIcon = {
		type: IconType.SVG,
		class: 'trash',
		extraClass: 'svg-warning-fill',
	};
	moveIcon: IIcon = {
		type: IconType.SVG,
		class: 'move',
		extraClass: 'svg-black-fill',
	};
	menuDots: IIcon = {
		type: IconType.SVG,
		class: 'vertical_menu_dots',
		extraClass: 'svg-black-fill',
	};
	backArrow: IIcon = {
		type: IconType.SVG,
		class: 'back_arrow_circle',
		extraClass: 'svg-white-fill',
	};
	saveDisk: IIcon = {
		type: IconType.SVG,
		class: 'save_disk',
		extraClass: 'svg-primary-fill',
	};
	exitArrow: IIcon = {
		type: IconType.SVG,
		class: 'exit_arrow',
		extraClass: 'svg-primary-fill',
	};
	tree: Tree<IAttributeInfo> = null;
	rootNode: Node<IAttributeInfo> = null;
	menuParentNode: Node<IAttributeInfo> = null;
	viewData: {
		viewIcon: IIcon;
		viewName: string;
	} = {
		viewIcon: {
			type: IconType.SVG,
			class: 'view',
			extraClass: 'svg-black-fill',
		},
		viewName: 'View Name',
	};

	aclFormGenInput: IFormGeneratorInput = null;
	aclFormGroup: FormGroup = null;
	selectedAcl: string = null;
	GlobalConfiguration = GlobalConfiguration;
	widgetSelected: any[] = null;
	widgetSelectedMap: Map<string, number> = null;
	widgetIndexSelectedInGroup: Map<string, number> = null;
	nonGroupablePortlets: Set<PortletType> = null;
	showPaginations = false;
	errorMessage: string = 'Some error occured';
	isDuplicateView: boolean = false;
	selectedPermission: string = 'READ';
	@ViewChild('widgetListingRef') widgetListingRef: ElementRef;

	readonly PORTLET_TYPE_KEY = 'portletType';
	readonly GROUP_ID_KEY = 'groupId';
	readonly GROUP_WIDGETS_KEY = 'groupWidgets';

	readonly GROUP_KEY = 'group';
	readonly POSITION_KEY = 'position';

	readonly TAB_JOIN_STRING = '-tab-';

	breakpointForMenuOverlay: Observable<boolean> = this.breakpointObserver
		.observe('(max-width: 1050px)')
		.pipe(
			debounceTime(500),
			map((result) => result.matches),
			shareReplay(),
		);

	selectWidgetButtonGenInput: IButtonGeneratorInput = {
		buttonName: 'Edit Widgets',
		buttonColorType: ButtonColorType.INFO,
		buttonType: ButtonType.TEXT,
		function: () => {
			this.addWidgetsToModule();
		},
	};
	editACLButtonGenInput: IButtonGeneratorInput = {
		buttonName: 'Edit ACL',
		buttonColorType: ButtonColorType.INFO,
		buttonType: ButtonType.TEXT,
		function: () => {
			this.openEditAclModal();
		},
	};

	addModuleButtonGenInput: IButtonGeneratorInput = {
		buttonName: 'ADD MODULE',
		buttonColorType: ButtonColorType.SECONDARY,
		buttonType: ButtonType.FLAT,
		function: () => {
			this.moduleDetailsUpdate(null);
		},
	};

	groupWidgetButtonGenInput: IButtonGeneratorInput = {
		buttonName: 'Group Widgets',
		buttonColorType: ButtonColorType.INFO,
		buttonType: ButtonType.TEXT,
		function: null,
	};
	unGroupWidgetButtonGenInput: IButtonGeneratorInput = {
		buttonName: 'Ungroup Widgets',
		buttonColorType: ButtonColorType.INFO,
		buttonType: ButtonType.TEXT,
		function: null,
	};
	apiResponse: any;
	itemData: any;
	isMaster: boolean = false;
	readonly MASTER_ACL = 'Master';
	readonly ACL_TYPE_KEY = 'acltype';
	@ViewChild(GroupWidgetModalComponent)
	groupWidgetComponent: GroupWidgetModalComponent;
	filteredWidgets: Node<IAttributeInfo> = null;
	searchText: string = '';
	private subSink: SubSink = new SubSink();

	constructor(
		public modalInputData: ModalInjectedData,
		public whiteLabelingService: WhiteLabelingService,
		private listHttpService: ListHttpService,
		private breakpointObserver: BreakpointObserver,
		private modalService: ModalService,
	) {
		this.widgetRef = this.modalInputData.data.widgetRef;
		this.widgetIndexSelectedInGroup = new Map<string, number>();
		this.widgetSelectedMap = new Map<string, number>();
		this.populateNonGroupablePortlets();
	}

	ngOnInit(): void {
		this.widgetSelected = [];
		this.companyLogo = {
			type: IconType.IMAGE,
			class:
				this.whiteLabelingService.whiteLableData.lightLogo ||
				this.whiteLabelingService.whiteLableData.darkLogo,
		};
		if (this.modalInputData.data && this.modalInputData.data.duplicate) {
			this.tree = this.modalInputData.data.tree;
			this.rootNode = this.modalInputData.data.rootNode;
			this.menuParentNode = this.modalInputData.data.menuParentNode;
			this.viewData = this.modalInputData.data.viewData;
			this.selectedAcl = this.modalInputData.data.selectedAcl;
			this.isDuplicateView = this.modalInputData.data.duplicate;
		} else {
			this.initTree();
		}

		window.addEventListener('click', (event) => {
			document
				.querySelectorAll('.menu-item .actions')
				.forEach((actionContainer) => {
					if (
						actionContainer &&
						!actionContainer.contains(event.target as globalThis.Node)
					) {
						actionContainer.classList.remove('triggered');
					}
				});
		});
		this.initAclListing();
	}

	/**
	 * Lifecycle hook that is called after the view has been initialized.
	 * It calls the onResizeListing method to handle any necessary resizing of the listing.
	 * It also subscribes to the modalSelectedData observable from the modal service and updates
	 * the widgetSelected and widgetSelectedMap properties accordingly.
	 * Finally, it logs the widgetSelectedMap to the console and calls the groupSelectedWidgets method.
	 * @returns None
	 */
	ngAfterViewInit() {
		this.onResizeListing();
		this.subSink.add(
			this.modalService.modalSelectedData.subscribe((data) => {
				this.widgetSelected = data;
				data.forEach((widget, i) => {
					this.widgetSelectedMap.set(widget.id, i);
				});
				this.groupSelectedWidgets();
			}),
		);
	}

	initAclListing() {
		this.aclFormGenInput = {
			formName: 'ACL Listing',
			state: FormState.CREATE,
			submitButton: null,
			fields: [
				{
					fieldType: FilterType.DROPDOWN_SINGLE,
					label: 'Select ACL Listing',
					name: 'acl',
					placeholder: 'Select ACL',
					apiInfo: this.listHttpService.listAclApiInfo,
					required: true,
					showKey: 'name',
					value: this.selectedAcl ? this.selectedAcl : '',
					showLabel: true,
					appearance: 'legacy',
					validations: [
						{
							validator: CustomValidators.required,
							errorMessage: 'ACL is required',
						},
					],
					disabled: this.modalInputData.data.edit ? true : false,
					labelStyles: {
						'padding-top': '15px',
					},
					afterResponse: (response) => {
						this.apiResponse = response;

						if (this.apiResponse) {
							this.apiResponse.forEach((resp) => {
								if (resp.id === this.selectedAcl) {
									this.itemData = resp;
									this.isMaster =
										this.itemData[this.ACL_TYPE_KEY] ===
										this.MASTER_ACL;
								}
							});
						}
					},
				},
			],
		};
	}

	aclUpdate() {
		if (this.aclFormGroup) {
			if (
				this.selectedAcl !== null &&
				this.selectedAcl !== this.aclFormGroup.get('acl').value
			) {
				// ACL already selected
				const modalId = this.widgetRef.modalService.openConfirmationModal({
					buttonText: 'Continue',
					buttonColorType: ButtonColorType.WARN,
					confirmationMessage: `Changing ACL will result in removal of previously added widgets in any module`,
					contextIcon: {
						type: IconType.FONTAWSOME,
						class: 'fas fa-info-circle',
						extraClass: 'error-message-custom',
					},
					modalIcon: {
						type: IconType.FONTAWSOME,
						class: 'fas fa-exclamation-triangle',
					},
					modalName: 'Update ACL',
					function: (buttonRef: IButtonData) => {
						this.removeAllLeafWidgets();
						this.selectedAcl = this.aclFormGroup.get('acl').value;
						if (this.apiResponse) {
							this.apiResponse.forEach((resp) => {
								if (resp.id === this.selectedAcl) {
									this.itemData = resp;
									this.isMaster =
										this.itemData[this.ACL_TYPE_KEY] ===
										this.MASTER_ACL;
								}
							});
						}
						this.widgetRef.modalService.closeModal(null, modalId);
						this.onQuickFilterChanged(null, this.searchText);
					},
					cancelFunction: () => {
						this.aclFormGroup.get('acl').setValue(this.selectedAcl);
					},
				});
				return;
			}
			this.selectedAcl = this.aclFormGroup.get('acl').value;
		}
	}

	removeAllLeafWidgets() {
		this.tree.traverseTree(
			(
				node: Node<IAttributeInfo>,
				parentNode: Node<IAttributeInfo>,
				stopTraversalFunction,
			) => {
				if (
					node &&
					(!node.children || !node.children.length) &&
					node.data &&
					node.data.widgets &&
					node.data.widgets.length
				) {
					node.data.widgets = [];
					this.filteredWidgets.data.widgets = [];
				}
			},
		);
	}

	initTree() {
		// Creating Tree
		this.tree = new Tree<IAttributeInfo>();
		// Adding root node
		this.rootNode = this.tree.addNode(
			{
				attributeName: 'ROOT',
				iconType: IconType.FONTAWSOME,
				icon: 'fas fa-stroopwafel',
			},
			null,
		);
		this.menuParentNode = this.rootNode;
	}

	addModule(data: IAttributeInfo) {
		// Adding new module
		const parentNodeId = this.menuParentNode
			? this.menuParentNode.nodeId
			: this.rootNode.nodeId;
		if (parentNodeId) {
			if (this.getExistingModuleNames().includes(data.attributeName)) {
				this.widgetRef.notificationsService.showSnackBar(
					'Module name already exists',
					true,
				);
				return;
			}
			this.tree.addNode(data, parentNodeId);
		} else {
			this.moduleDetailsUpdate(null);
		}
	}

	updateModule(node: Node<IAttributeInfo>) {
		this.tree.updateNodeData(node.data, node.nodeId);
	}

	diveInNode(node: Node<IAttributeInfo>) {
		this.menuParentNode = node;
		this.filteredWidgets = Helper.cloneDeep(node);
	}

	deleteModule(node: Node<IAttributeInfo>) {
		const modalId = this.widgetRef.modalService.openConfirmationModal({
			buttonText: 'Remove',
			buttonColorType: ButtonColorType.WARN,
			confirmationMessage: `Are you sure you want to remove ${node.data.attributeName}`,
			contextIcon: {
				type: IconType.FONTAWSOME,
				class: 'fas fa-trash-alt',
				extraClass: 'error-message-custom',
			},
			modalIcon: {
				type: IconType.FONTAWSOME,
				class: 'fas fa-exclamation-triangle',
			},
			modalName: 'Remove Module',
			function: (buttonRef: IButtonData) => {
				this.tree.deleteNode(node.nodeId);
				this.widgetRef.modalService.closeModal(null, modalId);
			},
		});
	}

	getExistingModuleNames(): string[] {
		return this.menuParentNode &&
			this.menuParentNode.children &&
			this.menuParentNode.children.length
			? this.menuParentNode.children.map(
					(child) => child.data['attributeName'],
			  )
			: [];
	}

	moduleDetailsUpdate(node: Node<IAttributeInfo>) {
		const level = this.tree.getLevelOfNode(this.menuParentNode);
		if (level > GlobalConfiguration.MAX_LAYERS_LIMIT) {
			this.widgetRef.notificationsService.showSnackBar(
				`You can create maximum of ${GlobalConfiguration.MAX_LAYERS_LIMIT} nested layers of module`,
			);
			return;
		}

		let existingModules = this.getExistingModuleNames();

		if (node && node.data && node.data.attributeName) {
			existingModules = Helper.removeStringfromArray(
				node.data.attributeName,
				existingModules,
			);
		}

		const performAction = () => {
			this.widgetRef.modalService.openModal({
				noHeader: true,
				modalIcon: null,
				modalName: 'Icon Update',
				modalType: ModalType.MIDDLE,
				sourceId: this.widgetRef.uniqueIdentity,
				modalAutoHeight: true,
				modalWidthVw: 45,
				modalSteps: [
					{
						stepName: 'Step 1',
						stepData: {
							componentToLoad: EditModuleIconComponent,
							payload: {
								data: {
									tree: this.tree,
									node,
									addModuleFunction: this.addModule.bind(this),
									updateModuleFunction: this.updateModule.bind(this),
									existingModules,
								},
							},
						},
					},
				],
			});
		};

		// if node is null, add new node
		if (
			node === null &&
			this.menuParentNode.data.widgets &&
			this.menuParentNode.data.widgets.length
		) {
			// Children exist.. confirm before adding
			const modalId = this.widgetRef.modalService.openConfirmationModal({
				buttonText: 'Confirm',
				buttonColorType: ButtonColorType.WARN,
				confirmationMessage: `Adding new module to ${this.menuParentNode.data.attributeName} will delete all the already added widgets`,
				contextIcon: {
					type: IconType.FONTAWSOME,
					class: 'fas fa-trash-alt',
					extraClass: 'error-message-custom',
				},
				modalIcon: {
					type: IconType.FONTAWSOME,
					class: 'fas fa-exclamation-triangle',
				},
				modalName: 'Remove Module',
				function: (buttonRef: IButtonData) => {
					this.menuParentNode.data.widgets = [];
					this.widgetRef.modalService.closeModal(null, modalId);
					performAction();
				},
			});
		} else {
			performAction();
		}
	}

	goToParentNode() {
		const node = this.tree.getNodeById(this.menuParentNode.parentNodeId);
		if (node.parentNodeId === null) {
			this.menuParentNode = this.rootNode;
		} else {
			this.menuParentNode = node;
			this.filteredWidgets = Helper.cloneDeep(node);
		}
	}

	moduleDropped(event: CdkDragDrop<Node<IAttributeInfo>[]>) {
		moveItemInArray(
			this.menuParentNode.children,
			event.previousIndex,
			event.currentIndex,
		);
	}

	updateViewInfo() {
		this.widgetRef.modalService.openModal({
			noHeader: true,
			modalIcon: null,
			modalName: 'View Info Update',
			modalType: ModalType.MIDDLE,
			sourceId: this.widgetRef.uniqueIdentity,
			modalAutoHeight: true,
			modalWidthVw: 45,
			modalSteps: [
				{
					stepName: 'Step 1',
					stepData: {
						componentToLoad: EditViewDataComponent,
						payload: {
							data: {
								viewData: this.viewData,
								edit: this.modalInputData.data.edit,
							},
						},
					},
				},
			],
		});
	}

	/**
	 * Adds widgets to a module based on certain conditions.
	 * @returns None
	 */
	addWidgetsToModule() {
		if (
			this.menuParentNode.children.length ||
			this.menuParentNode.nodeId === this.rootNode.nodeId ||
			this.filteredWidgets.nodeId === this.rootNode.nodeId
		) {
			return;
		}
		if (!this.selectedAcl) {
			this.widgetRef.notificationsService.showSnackBar(
				'Select ACL first',
				true,
			);
			Helper.markAllFieldAsTouched(this.aclFormGroup);
			return;
		}
		this.widgetRef.modalService.openModal({
			modalIcon: null,
			modalName: 'Select Widget',
			modalType: ModalType.MIDDLE,
			sourceId: this.widgetRef.uniqueIdentity,
			modalWidthVw: 95,
			modalHeightVh: 97,
			noStepPadding: true,
			modalSteps: [
				{
					stepName: 'Step 1',
					stepData: {
						componentToLoad: ModuleWidgetAdditionComponent,
						payload: {
							data: {
								tree: this.tree,
								node: this.menuParentNode,
								acl: this.selectedAcl,
								updateWidgetsListInNode:
									this.updateWidgetsListInNode.bind(this),
								isDuplicateView: true,
							},
						},
					},
				},
			],
		});
	}

	/**
	 * Handles the event when a widget is dropped using the CdkDragDrop directive.
	 * Moves the widget within the array of widgets in the menuParentNode data.
	 * Updates the filteredWidgets property with a deep clone of the menuParentNode.
	 * @param {CdkDragDrop<any[]>} event - The drag and drop event object.
	 * @returns None
	 */
	widgetDropped(event: CdkDragDrop<any[]>) {
		moveItemInArray(
			this.menuParentNode.data.widgets,
			event.previousIndex,
			event.currentIndex,
		);
		this.filteredWidgets = Helper.cloneDeep(this.menuParentNode);
	}

	changeWidgetPermission(widget, permission) {
		widget.permission = permission;
		this.menuParentNode.data.widgets = this.filteredWidgets.data.widgets =
			this.menuParentNode.data.widgets.slice(0);
	}

	/**
	 * Updates the selected permissions for a widget based on the given event and bulk action flag.
	 * @param {any} widget - The widget object to update the permission for.
	 * @param {MatRadioChange} event - The event object containing the new permission value.
	 * @param {boolean} bulkAction - A flag indicating whether the action is a bulk action or not.
	 * @returns None
	 */
	selectedPermissions(widget, event: MatRadioChange, bulkAction: boolean) {
		if (!bulkAction) {
			widget.permission = event.value;
			this.menuParentNode.data.widgets =
				this.menuParentNode.data.widgets.slice(0);
		} else {
			this.filteredWidgets.data.widgets.forEach((widget) => {
				widget.permission = event.value;
				if (widget['groupWidgets']) {
					widget['groupWidgets'].forEach((groupWidget) => {
						groupWidget.permission = event.value;
					});
				}
			});
		}
		this.menuParentNode = Helper.cloneDeep(this.filteredWidgets);
	}

	returnToConsole() {
		this.widgetRef.modalService.closeModal(null, this.modalInputData.modalId);
	}

	returnToMainMenu() {
		this.menuParentNode = this.rootNode;
	}

	deleteWidget(widget) {
		this.menuParentNode.data.widgets =
			this.menuParentNode.data.widgets.filter(
				(item) => item.id !== widget.id,
			);
		this.deleteFromSelectedList(widget.id);
	}

	/**
	 * Saves the current view of the application.
	 * @returns None
	 */
	saveView() {
		if (this.leafNodesWithoutWidgets().length) {
			this.widgetRef.notificationsService.showSnackBar(
				'Leaf nodes must have widgets',
				true,
			);
		} else {
			this.widgetRef.modalService.openModal({
				noHeader: true,
				modalIcon: null,
				modalName: 'Landing Page',
				modalType: ModalType.MIDDLE,
				sourceId: this.widgetRef.uniqueIdentity,
				modalAutoHeight: true,
				modalWidthVw: 45,
				modalSteps: [
					{
						stepName: 'Step 1',
						stepData: {
							componentToLoad: LandingPageSelectionComponent,
							payload: {
								data: {
									tree: this.tree,
									rootNode: this.rootNode,
									parentModalId: this.modalInputData.modalId,
									widgetRef: this.widgetRef,
									acl: this.selectedAcl,
									view: this.viewData,
									landingPage: this.modalInputData.data.landingPage
										? this.modalInputData.data.landingPage
										: null,
									edit: this.modalInputData.data.edit ? true : false,
									viewId: this.modalInputData.data.viewId,
									prepareGroupedInputFn:
										this.prepareAllGroupedWidgets.bind(this),
								},
							},
						},
					},
				],
			});
		}
	}

	/**
	 * Returns a list of leaf nodes in a tree structure that do not have any widgets attached to them.
	 * @returns {Array} - A list of leaf nodes without any widgets.
	 */
	leafNodesWithoutWidgets(): Node<IAttributeInfo>[] {
		const leafNodesWithoutWidgets: Node<IAttributeInfo>[] = [];
		this.tree.traverseTree(
			(
				node: Node<IAttributeInfo>,
				parentNode: Node<IAttributeInfo>,
				stopCallback,
			) => {
				if (
					node &&
					parentNode &&
					!node.children.length &&
					(!node.data.widgets || !node.data.widgets.length)
				) {
					leafNodesWithoutWidgets.push(node);
				}
			},
		);

		return leafNodesWithoutWidgets;
	}

	prepareAllGroupedWidgets(widgetList) {
		const groupIndexMap = new Map<number, any[]>();
		const finalWidgetList = Helper.cloneDeep(widgetList);
		finalWidgetList.forEach((widget, index) => {
			if (
				widget &&
				widget[this.GROUP_ID_KEY] &&
				widget[this.GROUP_WIDGETS_KEY] &&
				widget[this.GROUP_WIDGETS_KEY].length
			) {
				widget[this.GROUP_WIDGETS_KEY].forEach((widget, groupIndex) => {
					widget[this.GROUP_KEY] = index;
					widget[this.POSITION_KEY] = groupIndex;
				});
				groupIndexMap.set(index, widget[this.GROUP_WIDGETS_KEY]);
			} else {
				widget[this.GROUP_KEY] = index;
				widget[this.POSITION_KEY] = 0;
			}
		});
		const indeces = Array.from(groupIndexMap.keys()).sort((a, b) => a - b);
		for (let i = indeces.length - 1; i >= 0; i--) {
			const index = indeces[i];
			const groupWidgets = groupIndexMap.has(index)
				? groupIndexMap.get(index)
				: [];
			finalWidgetList.splice(index, 1, ...groupWidgets);
		}
		return finalWidgetList;
	}

	validateSelectedWidgets() {
		let widgetCount = 0;
		for (let i = 0; i < this.widgetSelected.length; i++) {
			if (
				this.widgetSelected[i][this.PORTLET_TYPE_KEY] &&
				this.nonGroupablePortlets.has(
					this.widgetSelected[i][this.PORTLET_TYPE_KEY],
				)
			) {
				this.errorMessage = `Selected widgets cannot be grouped`;
				return false;
			}
			if (
				this.widgetSelected[i][this.GROUP_ID_KEY] &&
				this.widgetSelected[i][this.GROUP_WIDGETS_KEY] &&
				this.widgetSelected[i][this.GROUP_WIDGETS_KEY].length
			) {
				widgetCount +=
					this.widgetSelected[i][this.GROUP_WIDGETS_KEY].length;
			} else {
				widgetCount++;
			}
			if (widgetCount > GlobalConfiguration.WIDGETS_PER_GROUP_LIMIT) {
				this.errorMessage = `Maximum of ${GlobalConfiguration.WIDGETS_PER_GROUP_LIMIT} widgets can be grouped.`;
				return false;
			}
		}
		if (widgetCount > GlobalConfiguration.WIDGETS_PER_GROUP_LIMIT) {
			this.errorMessage = `Maximum of ${GlobalConfiguration.WIDGETS_PER_GROUP_LIMIT} widgets can be grouped.`;
			return false;
		}
		return true;
	}

	groupSelectedWidgets() {
		if (
			this.widgetSelected.length &&
			this.widgetSelected.length > 1 &&
			this.validateSelectedWidgets()
		) {
			const widgetsList = [];
			let index = 0;
			this.widgetSelected.forEach((widget, i) => {
				if (
					widget &&
					widget[this.GROUP_ID_KEY] &&
					widget[this.GROUP_WIDGETS_KEY] &&
					widget[this.GROUP_WIDGETS_KEY].length
				) {
					widgetsList.push(...widget[this.GROUP_WIDGETS_KEY]);
					if (
						i === 0 &&
						this.widgetSelectedMap.has(widget[this.GROUP_ID_KEY])
					) {
						index = this.widgetSelectedMap.get(widget[this.GROUP_ID_KEY]);
					}
					this.deleteWidgetGroup(widget[this.GROUP_ID_KEY]);
				} else {
					widgetsList.push(widget);
					if (i === 0 && this.widgetSelectedMap.has(widget.id)) {
						index = this.widgetSelectedMap.get(widget.id);
					}
					this.deleteWidget(widget);
				}
			});
			if (widgetsList.length > 1) {
				const newGroup = {
					[this.GROUP_ID_KEY]: Helper.generateUniqueKey(10),
					[this.GROUP_WIDGETS_KEY]: widgetsList,
				};
				this.menuParentNode.data.widgets.splice(index, 0, newGroup);
				this.filteredWidgets = Helper.cloneDeep(this.menuParentNode);
				this.onSelectedGroupIndexChanged(0, newGroup);
				this.widgetSelected = [];
				this.widgetSelectedMap.clear();
			}
		}
	}

	openWidgetGroupModal() {
		const widgets = this.filteredWidgets.data.widgets;
		const modalData: IModalData = {
			modalName: 'Group Widgets',
			modalHeightPx: 546,
			modalWidthVw: 60,
			maxModalHeightPx: 546,
			modalIcon: null,
			modalType: ModalType.MIDDLE,
			modalPurpose: 'Widgets Grouping',
			noStepPadding: true,
			sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
			modalSteps: [
				{
					stepName: 'Group Widgets',
					stepData: {
						componentToLoad: GroupWidgetModalComponent,
						payload: {
							data: {
								widgetRef: this.widgetRef,
								stepData: widgets,
								tree: this.tree,
								node: this.menuParentNode,
								acl: this.selectedAcl,
								groupable: this.nonGroupablePortlets,
							},
						},
					},
				},
			],
		};
		this.modalService.openModal(modalData);
	}

	onSelectedGroupIndexChanged(indexEvent, group) {
		if (group && group[this.GROUP_ID_KEY]) {
			this.widgetIndexSelectedInGroup.set(
				group[this.GROUP_ID_KEY],
				indexEvent,
			);
		}
	}

	deleteWidgetGroup(groupId) {
		if (groupId) {
			this.widgetIndexSelectedInGroup.delete(groupId);
			this.menuParentNode.data.widgets =
				this.menuParentNode.data.widgets.filter(
					(item) => item[this.GROUP_ID_KEY] !== groupId,
				);
			this.deleteFromSelectedList(groupId, true);
		}
	}

	deleteFromSelectedList(id, isGroup?: boolean) {
		this.widgetSelectedMap.delete(id);
		this.widgetSelected = this.widgetSelected.filter((widgetSelected) => {
			if (isGroup && widgetSelected[this.GROUP_ID_KEY]) {
				if (widgetSelected[this.GROUP_ID_KEY] === id) {
					return false;
				}
			} else {
				if (widgetSelected.id === id) {
					return false;
				}
			}
			return true;
		});
	}

	onResizeListing() {
		if (this.widgetListingRef) {
			if (this.widgetListingRef.nativeElement.offsetWidth <= 940) {
				if (!this.showPaginations) {
					this.showPaginations = true;
				}
			} else {
				if (this.showPaginations) {
					this.showPaginations = false;
				}
			}
		}
	}

	openEditAclModal() {
		this.handleAclModal(
			{ name: 'Edit ACL', purpose: ModalAction.UPDATE },
			this.itemData,
		);
	}

	handleAclModal(
		modalInfo: { name: string; purpose: ModalAction },
		stepData?: any,
	) {
		const modalData: IModalData = {
			modalName: modalInfo.name,
			modalHeightVh: 97,
			modalWidthVw: 95,
			modalIcon: null,
			modalType: ModalType.MIDDLE,
			modalPurpose: modalInfo.purpose,
			noStepPadding: true,
			sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
			modalSteps: [
				{
					stepName: 'Name & Description',
					stepData: {
						componentToLoad: AclModalStepOneComponent,
						payload: {
							data: {
								widgetRef: this.widgetRef,
								stepData: stepData,
								aclNameList:
									this.apiResponse && this.apiResponse.length
										? this.apiResponse.map((each) => each['name'])
										: null,
								isEdit: modalInfo.purpose === ModalAction.UPDATE,
							},
						},
					},
				},
				{
					stepName: 'Widget Selection',
					stepData: {
						componentToLoad: AclModalStepTwoComponent,
						payload: {
							data: {
								widgetRef: this.widgetRef,
								stepData: stepData,
								isEdit: modalInfo.purpose === ModalAction.UPDATE,
								isDuplicateViewACL: true,
							},
						},
					},
				},
			],
		};
		this.modalService.openModal(modalData);
	}

	/**
	 * Updates the filtered widgets based on the provided search text.
	 * @param {string} id - The ID of the quick filter.
	 * @param {string} searchText - The search text to filter the widgets.
	 * @returns None
	 */
	onQuickFilterChanged(id: string, searchText: string) {
		this.searchText = searchText;
		if (searchText) {
			this.filteredWidgets.data.widgets =
				this.menuParentNode.data.widgets.filter((item) =>
					JSON.stringify(item)
						.toLowerCase()
						.includes(searchText.toLowerCase().trim()),
				);
		} else {
			this.filteredWidgets.data.widgets = this.menuParentNode.data.widgets;
		}
	}

	/**
	 * Updates the widgets list in the node by assigning the provided widget list to the menuParentNode's data property.
	 * Then triggers the onQuickFilterChanged event with null as the first argument and the current searchText as the second argument.
	 * @param {Array} widgetList - The new list of widgets to assign to the menuParentNode's data property.
	 * @returns None
	 */
	private updateWidgetsListInNode(widgetList) {
		this.menuParentNode.data.widgets = widgetList;
		this.onQuickFilterChanged(null, this.searchText);
	}

	/**
	 * Ungroups a selected widget by removing it from its parent group and updating the filtered widgets.
	 * @param {Array} widgetSelectedForUngroup - The array of widgets to ungroup.
	 * @returns None
	 */
	ungroupWidget(widgetSelectedForUngroup) {
		// Add widgets to menuParentNode.data.widgets
		this.menuParentNode.data.widgets.unshift(...widgetSelectedForUngroup);
		const idsToRemove = widgetSelectedForUngroup.map((widget) => widget.id);

		idsToRemove.forEach((idToRemove) => {
			const indexToRemove = this.menuParentNode.data.widgets.findIndex(
				(widgetGroup) => {
					if (widgetGroup['groupWidgets']) {
						return widgetGroup['groupWidgets'].some(
							(widget) => widget.id === idToRemove,
						);
					}
				},
			);
			if (indexToRemove !== -1) {
				this.menuParentNode.data.widgets.splice(indexToRemove, 1);
			}
		});
		this.filteredWidgets = Helper.cloneDeep(this.menuParentNode);
	}

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }

	/**
	 * Populates the `nonGroupablePortlets` set with various types of portlets that cannot be grouped.
	 * @returns None
	 */
	populateNonGroupablePortlets() {
		this.nonGroupablePortlets = new Set<PortletType>();
		this.nonGroupablePortlets.add(PortletType.AGGREGATE);
		this.nonGroupablePortlets.add(PortletType.RIBBON);
		this.nonGroupablePortlets.add(PortletType.WHITELABEL_INTEGRATION);
		this.nonGroupablePortlets.add(PortletType.GMAIL_INTEGRATION);
		this.nonGroupablePortlets.add(PortletType.O365_INTEGRATION);
		this.nonGroupablePortlets.add(PortletType.SLACK_INTEGRATION);
		this.nonGroupablePortlets.add(PortletType.FRESHDESK_INTEGRATION);
		this.nonGroupablePortlets.add(PortletType.FRESHSERVICE_INTEGRATION);
		this.nonGroupablePortlets.add(PortletType.ZENDESK_INTEGRATION);
		this.nonGroupablePortlets.add(PortletType.SNS_INTEGRATION);
		this.nonGroupablePortlets.add(PortletType.SERVICENOW_INTEGRATION);
		this.nonGroupablePortlets.add(PortletType.NAGIOS_INTEGRATION);
		this.nonGroupablePortlets.add(PortletType.CSP_INTEGRATION);
		this.nonGroupablePortlets.add(PortletType.EA_INTEGRATION);
		this.nonGroupablePortlets.add(PortletType.PLAN_INTEGRATION);
		this.nonGroupablePortlets.add(PortletType.AWS_INTEGRATION);
		this.nonGroupablePortlets.add(PortletType.ZOHO_INTEGRATION);
		this.nonGroupablePortlets.add(PortletType.JIRADESK_INTEGRATION);
		this.nonGroupablePortlets.add(PortletType.OTRS_INTEGRATION);
		this.nonGroupablePortlets.add(PortletType.AUTOTASK_INTEGRATION);
		this.nonGroupablePortlets.add(PortletType.SAML_INTEGRATION);
		this.nonGroupablePortlets.add(PortletType.CASDM_INTEGRATION);
		this.nonGroupablePortlets.add(PortletType.GCP_INTEGRATION);
	}
}
