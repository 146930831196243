<div class="container">
    <div class="form-container">
        <app-form-generator
            *ngIf="formGenInput && executionFunc"
            [formGeneratorInput]="formGenInput"
            (formGroupRef)="formGroup = $event"
        ></app-form-generator>
    </div>
    <div class="buttons">
        <app-button-generator
            [buttonGenInput]="buttonsInput"
        ></app-button-generator>
    </div>
</div>
