import { ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { AuthorizationType } from '../enums/AuthorizationType';
import { RequestType } from '../enums/RequestType';
import { TraversalPurpose } from '../enums/TraversalPurpose';
import { IHitApi } from '../interfaces/hit-api/IHitApi';
import { HitApi } from './HitApi';
import { AssessmentAuditTypes, AssessmentCacheService } from '../services/cache/assessment-cache/assessment-cache.service';

export class DetailedSideNavigation {
    private isAIAudit: boolean;

    constructor(private assessmentCacheService: AssessmentCacheService) {
        this.isAIAudit = assessmentCacheService.assessmentAuditType === AssessmentAuditTypes.AI_HIDE_SHOW_DYNAMIC;
    }
    
    static lastLevelHandle(
        event,
        item,
        sideNavigationData,
        firstLevelData,
        activeAttributes,
        selectedAttribues,
        selectedPathIds,
        selectedPath,
        showLastLevel: Map<string, boolean>,
        leafNodesData,
        traversalPurpose,
        globalDataService,
        shouldPauseNavigation?: (...argsToCache: any) => boolean | void,
        isActive?: boolean
    ) {
        event.preventDefault();
        event.stopPropagation();

        if (
            !isActive &&
            typeof shouldPauseNavigation === 'function' &&
            shouldPauseNavigation({
                continueFunction: this.lastLevelHandle.bind(this),
                navFunctionArgsToCache: [
                    event,
                    item,
                    sideNavigationData,
                    firstLevelData,
                    activeAttributes,
                    selectedAttribues,
                    selectedPathIds,
                    selectedPath,
                    showLastLevel,
                    leafNodesData,
                    traversalPurpose,
                    globalDataService
                ]
            })
        ) {
            return;
        }

        const leafNodeData: Map<number, any> = this.generateLeafNodeData(
            item,
            sideNavigationData,
            firstLevelData
        );
        if (
            item.lastLevel &&
            item.level === GlobalConfiguration.MAX_LAYERS_LIMIT - 1
        ) {
            showLastLevel.set(item['id'], false);
        }

        if (this.differentLeafTraversed(leafNodeData, selectedPath)) {
            this.handleAttributeSelection(
                item['level'],
                item['id'],
                activeAttributes,
                true
            );
            this.updateSelectedAttributes(leafNodeData, selectedAttribues);
            this.handleSelectedPath(leafNodeData, selectedPathIds);
            leafNodesData.emit(leafNodeData);
            if (traversalPurpose === TraversalPurpose.SIDE_MENU) {
                globalDataService.pageSwitch.next({
                    leafNodeData,
                    switched: true
                });
            }
        }
    }

    static goNextLevel(
        item,
        currentLevel: BehaviorSubject<number>,
        showLastLevel: Map<string, boolean>,
        sideNavigationData,
        activeAttributes: Map<number, string[]>,
        subMenuLoader,
        lastLevelLoader: Map<string, boolean>,
        sideMenuCache,
        httpService,
        ngZone,
        changeDetectorRef: ChangeDetectorRef,
        lastLevelData: Map<string, any>
    ) {
        this.handleAttributeSelection(
            item['level'],
            item['id'],
            activeAttributes,
            true
        );
        if (
            currentLevel.getValue() ===
                GlobalConfiguration.MAX_LAYERS_LIMIT - 1 ||
            currentLevel.getValue() + 1 ===
                GlobalConfiguration.MAX_LAYERS_LIMIT - 1
        ) {
            if (
                item.name ===
                sideNavigationData.get(currentLevel.getValue()).currentLevelName
            ) {
                let showLastLevelBoolean: boolean = showLastLevel.get(
                    item['id']
                );
                showLastLevelBoolean = !showLastLevelBoolean;
                showLastLevel.forEach((value, key, map) => map.set(key, false));
                showLastLevel.set(item['id'], showLastLevelBoolean);
            } else {
                showLastLevel.forEach((value, key, map) => map.set(key, false));
                showLastLevel.set(item['id'], true);
            }
            if (!showLastLevel.get(item['id'])) {
                activeAttributes.clear();
                return;
            }
        }
        if (
            sideNavigationData.get(item['level']) &&
            sideNavigationData.get(item['level']).level === 1 &&
            (!sideNavigationData.get(item['level']).currentLevelData ||
                sideNavigationData.get(item['level']).currentLevelName !==
                    item.name)
        ) {
            // First time initiating
            subMenuLoader.next(true);
            this.hitNextUrl(
                item['nextUrl'],
                sideMenuCache,
                httpService,
                ngZone,
                (response) => {
                    if (response) {
                        response.forEach(
                            (menuItem) => (menuItem['parentId'] = item['id'])
                        );
                        sideNavigationData.get(item['level']).currentLevelName =
                            item['name'];
                        sideNavigationData.get(item['level']).currentLevelData =
                            response;
                        subMenuLoader.next(false);
                        currentLevel.next(item['level']);
                    } else {
                        subMenuLoader.next(false);
                    }
                }
            );
        } else if (
            sideNavigationData.has(item['level'] - 1) &&
            sideNavigationData.get(item['level'] - 1).currentLevelData
        ) {
            // Drilling
            if (
                item.level === GlobalConfiguration.MAX_LAYERS_LIMIT - 1 &&
                !item.lastLevel
            ) {
                lastLevelLoader.set(item['id'], true);
            } else {
                subMenuLoader.next(true);
            }
            this.hitNextUrl(
                item['nextUrl'],
                sideMenuCache,
                httpService,
                ngZone,
                (response) => {
                    if (response) {
                        response.forEach(
                            (menuItem) => (menuItem['parentId'] = item['id'])
                        );
                        sideNavigationData.set(item['level'], {
                            previousLevelData: JSON.parse(
                                JSON.stringify(
                                    sideNavigationData.get(item['level'] - 1)
                                        .currentLevelData
                                )
                            ),
                            level: item['level'],
                            currentLevelData: response,
                            currentLevelName: item['name']
                        });
                        lastLevelData.set(item['name'], response);
                        subMenuLoader.next(false);
                        lastLevelLoader.set(item['id'], false);
                        currentLevel.next(item['level']);
                    } else {
                        subMenuLoader.next(false);
                        lastLevelLoader.set(item['id'], false);
                    }
                }
            );
        } else {
            // Do Nothing
        }
        changeDetectorRef.detectChanges();
    }

    static handleAttributeSelection(
        level: number,
        attributeId: string,
        activeAttributes,
        clearAdvanceLevels?
    ) {
        if (!activeAttributes.has(level)) {
            activeAttributes.set(level, []);
        }

        if (clearAdvanceLevels) {
            activeAttributes.set(level, []);

            let lev = level + 1;
            while (activeAttributes.has(lev)) {
                activeAttributes.delete(lev++);
            }
        }

        activeAttributes.get(level).push(attributeId);
    }

    static hitNextUrl(
        url: string,
        sideMenuCache,
        httpService,
        ngZone,
        callback
    ) {
        // If isAIAudit then hitting the api to get response instead returning it from cache.
        const isAIAudit = new DetailedSideNavigation(new AssessmentCacheService).isAIAudit;
        if (sideMenuCache.fetch(url) && !isAIAudit) {
            callback(sideMenuCache.fetch(url));
        } else {
            if (url.charAt(0) !== '/') {
                url = `/${url}`;
            }
            const args: IHitApi = {
                url,
                input: {},
                requestType: RequestType.GET,
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                },
                function: (response) => {
                    sideMenuCache.store(url, response);
                    callback(response);
                },
                errorFunction: (error) => {
                    callback(null);
                },
                uniqueIdentity: Symbol()
            };
            new HitApi(args, httpService, ngZone).hitApi();
        }
    }

    static goBackToLevel(
        level: number,
        currentLevel: BehaviorSubject<number>,
        sideNavigationData,
        showLastLevel: Map<string, boolean>,
        activeAttributes: Map<number, string[]>
    ) {
        showLastLevel.clear();
        if (
            currentLevel.getValue() ===
            GlobalConfiguration.MAX_LAYERS_LIMIT - 1
        ) {
            level--;
        }
        currentLevel.next(level);

        this.clearUnusedLevels(
            currentLevel,
            sideNavigationData,
            activeAttributes
        );
    }

    static clearUnusedLevels(
        currentLevel: BehaviorSubject<number>,
        sideNavigationData,
        activeAttributes: Map<number, string[]>
    ) {
        let level = currentLevel.getValue() + 1;
        while (sideNavigationData.has(level)) {
            sideNavigationData.delete(level++);
        }

        activeAttributes.clear();
    }

    static updateSelectedAttributes(
        leafNodesData: Map<number, any>,
        selectedAttribues
    ) {
        selectedAttribues.splice(0, selectedAttribues.length);
        for (const item of leafNodesData.values()) {
            selectedAttribues.push(item['id']);
        }
    }

    static generateLeafNodeData(
        item,
        sideNavigationData,
        firstLevelData
    ): Map<number, any> {
        const leafNodeData: Map<number, any> = new Map();
        let level = item['level'];
        let parentId = item['parentId'];
        while (level >= 1) {
            if (level === item['level']) {
                leafNodeData.set(level--, item);
            } else {
                leafNodeData.set(
                    level,
                    sideNavigationData
                        .get(level)
                        .previousLevelData.find(
                            (prevVal) => prevVal['id'] === parentId
                        )
                );
                parentId = leafNodeData.get(level)['parentId'];
                level -= 1;
            }
        }

        if (leafNodeData.has(2)) {
            leafNodeData.set(
                1,
                firstLevelData.find(
                    (val) => val['id'] === leafNodeData.get(2)['parentId']
                )
            );
        }
        return leafNodeData;
    }

    static differentLeafTraversed(
        leafNodeData: Map<number, any>,
        selectedPath
    ): boolean {
        let path = '';
        let level = 1;
        while (leafNodeData.has(level)) {
            path += `>>${leafNodeData.get(level++)['id']}`;
        }

        if (selectedPath.getValue() && selectedPath.getValue() === path) {
            return false;
        }
        selectedPath.next(path);
        return true;
    }

    static handleSelectedPath(leafNodeData: Map<number, any>, selectedPathIds) {
        // selectedPathIds = [];
        const ids = [];
        for (const [key, value] of leafNodeData.entries()) {
            ids.push(value['id']);
        }
        selectedPathIds.next(ids);
    }
}
