import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from 'ag-grid-angular';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NumberFieldCellEditorComponent } from '../../components/ag-grid-cell-editor/number-field-cell-editor/number-field-cell-editor.component';
import { SeverityEditorComponent } from '../../components/ag-grid-cell-editor/severity-editor/severity-editor.component';
import { AgGridCollapsibleColumnHeaderComponent } from '../../components/ag-grid-collapsible-column-header/ag-grid-collapsible-column-header.component';
import { ButtonGeneratorComponent } from '../../components/button-generator/button-generator.component';
import { DatePickerRangeComponent } from '../../components/date-picker-range/date-picker-range.component';
import { SingleSelectDropdownComponent } from '../../components/dropdowns/single-select-dropdown/single-select-dropdown.component';
import { MultiButtonGeneratorComponent } from '../../components/multi-button-generator/multi-button-generator.component';
import { OnlyTableGeneratorComponent } from '../../components/only-table-generator/only-table-generator.component';
import { TableColumnOrderComponent } from '../../components/table-column-order/table-column-order.component';
import { TableGeneratorComponent } from '../../components/table-generator/table-generator.component';
import { TableSearchComponent } from '../../components/table-search/table-search.component';
import { CommonPipesModule } from '../common-pipes/common-pipes.module';
import { MaterialModule } from '../material/material-module.module';
import { BootstrapModule } from './../bootstrap/bootstrap.module';

const components = [
    SeverityEditorComponent,
    ButtonGeneratorComponent,
    MultiButtonGeneratorComponent,
    TableGeneratorComponent,
    OnlyTableGeneratorComponent,
    DatePickerRangeComponent,
    TableSearchComponent,
    NumberFieldCellEditorComponent,
    AgGridCollapsibleColumnHeaderComponent,
    TableColumnOrderComponent,
    SingleSelectDropdownComponent
];

const modules = [
    CommonModule,
    NgSelectModule,
    BrowserModule,
    MaterialModule,
    AgGridModule.withComponents([
        SeverityEditorComponent,
        MultiButtonGeneratorComponent,
        NumberFieldCellEditorComponent,
        AgGridCollapsibleColumnHeaderComponent
    ]),
    CommonPipesModule,
    MaterialFileInputModule,
    FormsModule,
    BootstrapModule
];
@NgModule({
    declarations: [...components],
    imports: [...modules],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [...components]
})
export class HelperComponentModule {}
