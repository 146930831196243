import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class JsonValidator extends CustomValidator {
    key = CustomValidatorKey.JSON_VALIDATOR;

    validatorFunction(control: AbstractControl): ValidationErrors {
        const obj = {};
        obj[this.key] = true;
        const controlValue = control.value;
        try {
            return isNaN(JSON.parse(controlValue))
                ? Object.keys(JSON.parse(controlValue)).length === 0
                    ? obj
                    : null
                : obj;
        } catch (e) {
            return obj;
        }
    }
}
