import { NgStyle } from '@angular/common';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Helper } from '../../classes/Helper';
import { Messages } from '../../classes/Messages';
import { Widget } from '../../classes/Widget';
import { ViewType } from '../../enums/ViewType';
import { IKanbanGeneratorInput } from './../../interfaces/kanbanGenerator/IKanbanGeneratorInput';

@Component({
    selector: 'app-kanban-generator',
    templateUrl: './kanban-generator.component.html',
    styleUrls: ['./kanban-generator.component.sass']
})
export class KanbanGeneratorComponent implements OnInit {
    @Input() widgetRef: Widget;
    @Input() kanbanInput: IKanbanGeneratorInput;
    @Input() kanbanInfoTemplate: TemplateRef<any>;
    @Input() kanbanInfoTemplateStyles: NgStyle;
    kanbanData = new Map<string, [any]>();
    Helper = Helper;
    messages = Messages;
    data = null;
    emptyFilteredData = false;

    constructor() {}

    ngOnInit(): void {
        if (this.widgetRef) {
            this.setUpBasics();
        }
    }

    ngAfterViewInit(): void {
        if (this.widgetRef) {
            this.widgetRef.setBindData(this.bindData.bind(this));
        }
    }

    setUpBasics() {
        // Setting Visible Sections
        const visibleSections: Set<ViewType> = this.widgetRef.visibleSections
            .value
            ? this.widgetRef.visibleSections.value
            : new Set();
        visibleSections.add(ViewType.CARDS);
        this.widgetRef.visibleSections.next(visibleSections);
    }

    bindData(data): void {
        if (data) {
            if (
                this.kanbanInput.cardButtons &&
                this.kanbanInput.cardButtons.length
            ) {
                this.kanbanInput.cardButtons.forEach((cardButton) => {
                    if (cardButton.showLoader) {
                        cardButton.loader = false;
                    }
                });
            }
            this.widgetRef.apiResponse = data;

            if (this.kanbanInput.afterResponse) {
                this.kanbanInput.afterResponse(data);
            }

            if (this.kanbanInput.listExtraction.type === 'DIRECT') {
                this.data = data;
                this.setUpKanbanData(data);
            } else {
                this.setUpKanbanData(
                    Helper.extractDataFromObject(
                        this.kanbanInput.listExtraction.nestedKey,
                        data
                    )
                );
                this.data = Helper.extractDataFromObject(
                    this.kanbanInput.listExtraction.nestedKey,
                    data
                );
            }
            if (!this.kanbanData || !this.kanbanData.size) {
                this.widgetRef.extraMessage.next(
                    this.kanbanInput.noDataMessage
                        ? this.kanbanInput.noDataMessage
                        : Messages.NO_DATA_AVAILABLE
                );
                this.widgetRef.visibleSections.next(new Set());
                this.widgetRef.attentionRequired(data);
                this.widgetRef.endLoader();
                return;
            }
            this.widgetRef.endLoader();
        }
    }

    setUpKanbanData(data) {
        this.emptyFilteredData = false;
        if (data && data.length && this.kanbanInput.boardTitle) {
            const key = this.kanbanInput.boardTitle.infoKey;
            this.kanbanData.clear();
            data.forEach((card) => {
                if (card[key]) {
                    if (this.kanbanData.has(card[key])) {
                        this.kanbanData.get(card[key]).push(card);
                    } else {
                        this.kanbanData.set(card[key], [card]);
                    }
                }
            });
        } else if (data && !data.length) {
            this.kanbanData.clear();
        }

        if (!this.kanbanData.size) {
            this.emptyFilteredData = true;
        }
    }

    handleTableSearch(id: string) {
        const inputValue = document.getElementById(id)['value'];
        let filteredKanbanData = [];
        filteredKanbanData = this.data.filter((card) => {
            if (card[this.kanbanInput.boardTitle.infoKey]) {
                return (
                    card[this.kanbanInput.boardTitle.infoKey]
                        .toLowerCase()
                        .includes(inputValue.toLowerCase()) ||
                    card[this.kanbanInput.cardTitle.infoKey]
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                );
            } else if (card[this.kanbanInput.cardTitle.infoKey]) {
                return card[this.kanbanInput.cardTitle.infoKey]
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
            } else {
                return false;
            }
        });
        this.setUpKanbanData(filteredKanbanData);
    }
}
