<app-form-generator
    *ngIf="userInfoFormGenInput"
    class="tw-flex-1"
    [formGeneratorInput]="userInfoFormGenInput"
    (formGroupRef)="userInfoFormGroup = $event"
></app-form-generator>
<app-button-generator
    class="tw-ml-auto tw-mb-4 tw-mr-5"
    [buttonGenInput]="nextButtonGenInput"
    (buttonRef)="save()"
></app-button-generator>
