import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { ModalComponent } from 'src/app/core/components/modal/modal.component';
import { ConfirmationModalComponent } from 'src/app/shared/components/modal-templates/confirmation-modal/confirmation-modal.component';
import { ModalType } from '../../../enums/ModalType';
import { IModalData } from '../../../interfaces/modal/IModalData';
import { MultiStepFormService } from '../multi-step-form/multi-step-form.service';
import { InfoModalComponent } from './../../../components/modal-templates/info-modal/info-modal.component';
import { IConfirmationModal } from './../../../interfaces/modal/IConfirmationModal';
import { IInfoModal } from './../../../interfaces/modal/IInfoModal';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    private modalRef: Map<any, MatDialogRef<ModalComponent>> = new Map();
    modalData: IModalData;
    loader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    modalResponse: Subject<any> = new Subject();
    currentlyOpenedModals = [];
    resetModal: Subject<void> = new Subject<void>();
    modalSelectedData: Subject<any> = new Subject<any>();

    constructor(
        private dialog: MatDialog,
        private multiFormStepService: MultiStepFormService
    ) {}

    openModal(modalData: IModalData, reset = true): Symbol {
        if (!modalData) {
            return;
        }
        // Resetting
        // if (reset) {
        //     this.multiFormStepService.stepData = new Map();
        // }
        if (!modalData.modalId) {
            modalData.modalId = Symbol();
        }
        this.modalData = modalData;
        this.currentlyOpenedModals.push(modalData.modalId);

        if (modalData.modalSteps) {
            modalData.modalSteps = modalData.modalSteps.map((modalStep) => {
                modalStep.stepIdentifier = modalStep.stepIdentifier
                    ? modalStep.stepIdentifier
                    : Symbol();
                return modalStep;
            });
        }
        const modalConf = {
            data: {
                modalData: modalData,
                loader: this.loader
            }
        };
        if (modalData.modalType === ModalType.SIDE) {
            modalConf['position'] = {
                top: '0',
                right: '0'
            };
        }

        if (modalData.modalType === ModalType.COMPARE) {
            modalConf['maxWidth'] = '98vw';
        }

        if (modalData.panelClass) {
            modalConf['panelClass'] = modalData.panelClass;
        }

        if (modalData.modalPosition) {
            modalConf['position'] = modalData.modalPosition;
        }

        if (modalData.disableClose) {
            modalConf['disableClose'] = modalData.disableClose;
        }

        if (this.modalData.modalClass) {
            modalConf['backdropClass'] = this.modalData.modalClass;
        }
        this.modalRef.set(
            modalData.modalId,
            this.dialog.open(ModalComponent, modalConf)
        );
        this.modalRef
            .get(modalData.modalId)
            .afterClosed()
            .subscribe((ev) => {
                this.closeModal(null, modalData.modalId);
                if (this.modalData.closeCallback) {
                    this.modalData.closeCallback();
                }
            });

        return modalData.modalId;
    }

    closeModal(result: any, modalId: Symbol) {
        this.modalRef.get(modalId).close(result);
        this.modalResponse.next(result);
        this.multiFormStepService.stepData.delete(modalId);
        this.currentlyOpenedModals = this.currentlyOpenedModals.filter(
            (mId) => mId !== modalId
        );
    }

    openConfirmationModal(data: IConfirmationModal): Symbol {
        const modalData: IModalData = {
            modalName: data.modalName,
            modalIcon: data.modalIcon,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            noHeader: data.noHeader ?? false,
            disableClose: data.disableClose ?? false,
            modalHeightVh: data.modalHeightVh ? data.modalHeightVh : 30,
            modalAutoHeight: data.modalAutoHeight
                ? data.modalAutoHeight
                : false,
            modalWidthVw: data.modalWidthVw ? data.modalWidthVw : 50,
            extraClass: data.extraClass ?? null,
            closeCallback: data.closeCallBack,
            noHeaderLine: data.noHeaderLine ?? false,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ConfirmationModalComponent,
                        payload: {
                            data: {
                                function: data.function,
                                cancelFunction: data.cancelFunction,
                                params: data
                            }
                        }
                    },
                    stepName: 'Confirmation'
                }
            ]
        };
        return this.openModal(modalData);
    }

    openInfoModal(data: IInfoModal): Symbol {
        const modalData: IModalData = {
            modalName: data.infoHeading,
            modalIcon: null,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalWidthVw: 40,
            modalAutoHeight: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: InfoModalComponent,
                        payload: {
                            data
                        }
                    },
                    stepName: 'Information'
                }
            ]
        };
        return this.openModal(modalData);
    }
}
