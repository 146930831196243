<div
    class="toasts-container"
    *ngIf="
        (notificationsService.toastSub | async) &&
        (notificationsService.toastSub | async).length
    "
>
    <div
        class="toast"
        *ngFor="
            let toast of notificationsService.toastSub | async;
            trackBy: identify
        "
        [style.backgroundColor]="toast.background"
        [style.borderColor]="toast.borderColor"
        [style.color]="toast.textColor"
        [style.cursor]="toast.clickAction ? 'cursor' : 'auto'"
        [@toastAnimation]="
            currentToasts.includes(toast.toastId) ? 'show' : 'hide'
        "
    >
        <div class="toast-head">
            <div
                class="toast-icon"
                [style.color]="toast.borderColor"
                appIcon
                [data]="toast.toastIcon"
            ></div>
            <div class="toast-title">
                {{ toast.title }}
            </div>
            <i
                *ngIf="toast.showTime !== Infinity"
                [style.color]="toast.textColor"
                (click)="closeToast(toast)"
                class="toast-close fas fa-times"
            ></i>
        </div>
        <div
            class="toast-content"
            [innerHTML]="toast.message | safeRender: SanitizeTypes.HTML"
        ></div>
    </div>
</div>
