import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FileInput } from 'ngx-material-file-input';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IButtonData } from './../../../../interfaces/table-generator/IButtonData';

@Component({
    selector: 'app-sub-domain',
    templateUrl: './sub-domain.component.html',
    styleUrls: ['./sub-domain.component.sass']
})
export class SubDomainComponent implements OnInit {
    subDomainFormGenInput: IFormGeneratorInput = null;
    subDomainFormGroup: FormGroup;
    widgetRef: Widget;
    configExist = false;
    deleteButtonLoader = false;
    saveButtonLoader = false;
    widgetIcon = {
        class: 'fas fa-globe',
        type: IconType.FONTAWSOME
    };
    readonly MORE_INFO_HOVER_TEXT = `Click here for more info`;
    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        // Show View Icon
        this.widgetRef.showViewIcon.next(false);
    }

    bindData(response) {
        if (response && response['subDomainConfigured']) {
            this.configExist = true;
        } else {
            this.configExist = false;
        }
        this.setUpSubDomainForm(response);
        this.widgetRef.endLoader();
    }

    setUpSubDomainForm(response) {
        this.subDomainFormGenInput = {
            formName: 'Configure Sub-Domain',
            submitButton: null,
            fields: [
                {
                    name: 'subDomainName',
                    label: 'Sub Domain',
                    fieldType: FilterType.TEXT,
                    placeholder: 'Enter Sub Domain',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Sub Domain is required'
                        }
                    ],
                    value: this.configExist ? response['subDomainName'] : '',
                    suffixText: '.centilytics.com',
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Logo',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Enter the full name of your registered website. This is the address on which WhiteLabel will be set up and your customers will visit this domain to access Centilytics.`,
                                            `<b>Recommended Format:</b> (yourdomain).centilytics.com
                                            (* This field take inputs only as lower case letters, numbers, and following characters: " _ - ")`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                },
                {
                    label: 'Upload Logo',
                    fieldType: FilterType.FILE,
                    accept: 'image/png',
                    name: 'logo',
                    placeholder: 'Upload Logo',
                    required: true,
                    value: this.configExist ? response['logo'] : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Logo is required'
                        }
                    ],
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Logo',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Upload your company’s trademark logo. This logo will appear in the header, login screen and as element wherever required.`,
                                            `<b>Recommended Dimension:</b> 350px X 100px.`,
                                            `<b>Recommended Size:</b> 100KB.`,
                                            `<b>Recommended Format:</b> PNG.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                },
                {
                    label: 'Upload Favicon',
                    fieldType: FilterType.FILE,
                    accept: 'image/png',
                    name: 'miniLogo',
                    placeholder: 'Upload Favicon',
                    required: true,
                    value: this.configExist ? response['miniLogo'] : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Favicon is required'
                        }
                    ],
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Logo',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Upload an image as an icon for your whitelabel domain. This image will be displayed as the browser tab icon.`,
                                            `<b>Edit:</b> If you want to change the submission, you can select ‘Edit Insight’ option on top right.`,
                                            `<b>Recommended Dimension:</b> 11px x 20px.`,
                                            `<b>Recommended Size:</b> 100KB.`,
                                            `<b>Recommended Format:</b> PNG.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                }
            ],
            state: FormState.CREATE
        };
    }

    saveSubDomain() {
        if (this.saveButtonLoader) {
            return;
        }
        if (this.subDomainFormGroup.invalid) {
            this.widgetRef.notificationsService.showSnackBar(
                Messages.FILL_ALL_FIELDS,
                true
            );
            return;
        }
        const input = {
            ...this.subDomainFormGroup.value
        };
        input['subDomainName'] = input['subDomainName'];
        const fields = Object.keys(input);
        const files = fields.filter((key) => input[key] instanceof FileInput);
        if (files.length) {
            files.forEach((key, index) => {
                if (input[key] instanceof FileInput) {
                    Helper.convertImageToBase64(input[key]).then((response) => {
                        input[key] = response;
                        if (index === files.length - 1) {
                            this.hitSaveApi(input);
                        }
                    });
                }
            });
        } else {
            this.hitSaveApi(input);
        }
    }

    hitSaveApi(input) {
        this.saveButtonLoader = true;
        this.widgetRef.changeDetectorRef.detectChanges();
        const args = Helper.generateHitApiConfig(
            this.configExist
                ? this.widgetRef.widgetData.widgetInfo.update
                : this.widgetRef.widgetData.widgetInfo.create
        );
        args.input = input;
        args.config = {
            authorization: AuthorizationType.BEARER_TOKEN,
            ignoreBaseUrl: true
        };
        args.function = (response) => {
            this.saveButtonLoader = false;
            this.widgetRef.notificationsService.showSnackBar(
                `Sub domain configuration saved successfully`
            );
            this.widgetRef.refreshWidget();
        };
        args.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error configuring sub domain.'
            );
            this.saveButtonLoader = false;
        };
        new HitApi(
            args,
            this.widgetRef.widgetHttpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    deleteSubDomain() {
        if (this.deleteButtonLoader) {
            return;
        }
        if (this.configExist) {
            this.widgetRef.modalService.openConfirmationModal({
                buttonText: 'Remove',
                buttonColorType: ButtonColorType.WARN,
                loaderOnExec: true,
                confirmationMessage:
                    'Are you sure you want to delete sub domain configuration ?',
                contextIcon: {
                    extraClass: 'color-accent',
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-exclamation-triangle'
                },
                modalIcon: this.widgetIcon,
                modalName: 'Remove sub domain configuration',
                function: (modalId: Symbol, buttonRef: IButtonData) => {
                    buttonRef.loader = true;
                    this.deleteButtonLoader = true;
                    const args = Helper.generateHitApiConfig(
                        this.widgetRef.widgetData.widgetInfo.delete
                    );
                    args.input = {};
                    args.config = {
                        authorization: AuthorizationType.BEARER_TOKEN,
                        ignoreBaseUrl: true
                    };
                    args.function = (response) => {
                        buttonRef.loader = false;
                        this.widgetRef.notificationsService.showSnackBar(
                            `Sub domain configuration deleted successfully`
                        );
                        this.subDomainFormGenInput = null;
                        this.widgetRef.modalService.closeModal(null, modalId);
                        this.widgetRef.refreshWidget();
                        this.deleteButtonLoader = false;
                    };
                    args.errorFunction = (error) => {
                        Helper.showErrorMessage(
                            this.widgetRef.notificationsService,
                            error,
                            'Error performing action'
                        );
                        this.deleteButtonLoader = false;
                        buttonRef.loader = false;
                        this.widgetRef.modalService.closeModal(null, modalId);
                    };
                    new HitApi(
                        args,
                        this.widgetRef.widgetHttpService,
                        this.widgetRef.ngZone
                    ).hitApi();
                }
            });
        }
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
