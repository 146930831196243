import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
    GridOptions,
    RowEvent,
    ValueGetterParams,
    ValueSetterParams
} from 'ag-grid-community';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IBackendFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IBackendFormGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IValidator } from 'src/app/shared/interfaces/form-generator/IValidator';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { NumberFieldCellEditorComponent } from '../../../ag-grid-cell-editor/number-field-cell-editor/number-field-cell-editor.component';
import { ConfirmationModalComponent } from '../../confirmation-modal/confirmation-modal.component';
import { IMultiButtonOption } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IFormField } from './../../../../interfaces/form-generator/IFormField';
import { IColumnData } from './../../../../interfaces/table-generator/IColumnData';
import { ITableGeneratorInput } from './../../../../interfaces/table-generator/ITableGeneratorInput';
import { AgGridCellColumnComponent } from './../../../ag-grid-cell-column/ag-grid-cell-column.component';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-customization-modal',
    templateUrl: './customization-modal.component.html',
    styleUrls: ['./customization-modal.component.sass']
})
export class CustomizationModalComponent implements OnInit {
    widgetRef: Widget;
    resources: any;
    customizeFormGenInput: IFormGeneratorInput = null;
    customizeForm: IBackendFormGeneratorInput;
    customizeFormGroupRef: FormGroup;
    customizeLogicList = null;
    customizeLogicTableInput: ITableGeneratorInput;
    gridRef: GridOptions;
    saveButtonInput: IButtonGeneratorInput = {
        buttonName: 'Save',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.RAISED,
        showLoader: true,
        function: (buttonRef: IButtonGeneratorInput) => {
            this.update(buttonRef);
        }
    };
    noMinHeight: boolean = false;
    columnFilterType: object;

    editRowsMap: Map<string, Function>;
    editingColIndex: number = Number.MAX_VALUE;

    constructor(public modalData: ModalInjectedData) {
        if (this.modalData['data']) {
            this.widgetRef = this.modalData['data']['widgetRef'];
            this.resources = this.modalData['data']['resources'];
        }
    }

    ngOnInit(): void {
        this.setUpDefaultForm();
    }

    setupTable() {
        if (this.customizeLogicList) {
            this.noMinHeight = this.customizeLogicList.length < 5;
        }
        this.editRowsMap = new Map();
        this.columnFilterType = {};
        this.customizeLogicTableInput = {
            buttons: null,
            listExtraction: {
                type: 'DIRECT'
            },
            extraComponents: {
                agCell: AgGridCellColumnComponent
            },
            selection: 'single',
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-list'
            },
            tableAutoHeight: this.noMinHeight,
            noDataMessage: 'No Customize Logic found',
            editType: 'fullRow',
            columns: [
                {
                    columnKey: 'complianceName',
                    columnName: 'Custom Compliance Name',
                    filter: false,
                    minWidth: 100
                },
                {
                    columnKey: 'action',
                    columnName: 'Action',
                    pinned: 'right',
                    filter: false,
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    headerClass: 'grid-cell-data-centred',
                    minWidth: 120,
                    valueFormatter: (rowData: RowEvent) => {
                        const buttons: IButtonGeneratorInput[] = [];
                        const editButton: IButtonGeneratorInput = {
                            buttonName: 'Edit',
                            buttonIcon: {
                                type: IconType.FONTAWSOME,
                                class: ''
                            },
                            buttonColorType: ButtonColorType.INFO,
                            buttonType: ButtonType.TEXT,
                            showLoader: true,
                            function: () => {
                                this.customizeForm.fields.forEach((column) => {
                                    if (
                                        this.columnFilterType[column.name] !==
                                            FilterType.DROPDOWN_SINGLE &&
                                        this.columnFilterType[column.name] !==
                                            FilterType.DROPDOWN_MULTIPLE
                                    ) {
                                        rowData.api.startEditingCell({
                                            rowIndex: rowData.node.rowIndex,
                                            colKey: column.name
                                        });
                                    } else {
                                        if (
                                            this.editRowsMap &&
                                            this.editRowsMap.size
                                        ) {
                                            const key =
                                                column.name +
                                                '_' +
                                                rowData.node.rowIndex;
                                            if (
                                                this.editRowsMap.has(key) &&
                                                this.editRowsMap.get(key)
                                            ) {
                                                this.editRowsMap.get(key)();
                                                this.editRowsMap.delete(key);
                                            }
                                        }
                                    }
                                });
                            }
                        };

                        const deleteButton: IButtonGeneratorInput = {
                            buttonName: 'Delete',
                            buttonIcon: {
                                type: IconType.FONTAWSOME,
                                class: ''
                            },
                            buttonType: ButtonType.TEXT,
                            buttonColorType: ButtonColorType.WARN,
                            showLoader: true,
                            function: () => {
                                this.deleteLogic(
                                    rowData.data['complianceId'],
                                    rowData.rowIndex
                                );
                            }
                        };

                        buttons.push(editButton);
                        buttons.push(deleteButton);

                        const buttonOptions: IMultiButtonOption = {
                            layout: { justifyContent: 'space-evenly' }
                        };
                        rowData['options'] = buttonOptions;
                        rowData['buttonGenInputs'] = buttons;
                        return rowData;
                    }
                }
            ]
        };

        const dynamicColumns: IColumnData[] = [];

        this.customizeForm.fields.map((each, index) => {
            this.columnFilterType[each.name] = each.fieldType;

            const eachColumn: IColumnData = {
                columnName: each.label,
                columnKey: each.name,
                editable: true,
                filter: false,
                minWidth: 90,
                columnValueSetter: (params: ValueSetterParams) => {
                    if (
                        Helper.agGridCellValueValidator(
                            params,
                            this.customizeForm,
                            this.widgetRef.notificationsService
                        )
                    ) {
                        const rowIndex = params.node.rowIndex;
                        this.customizeLogicList[rowIndex]['customization'][
                            params.colDef.field
                        ] = params.newValue;

                        return true;
                    } else {
                        return false;
                    }
                },
                columnValueGetter: (params: ValueGetterParams) => {
                    return params.data['customization'] &&
                        params.data['customization'][each.name]
                        ? params.data['customization'][each.name]
                        : '';
                }
            };
            if (
                this.columnFilterType[each.name] === FilterType.DROPDOWN_SINGLE
            ) {
                eachColumn.cellClass = 'table-dropdown-container';
                eachColumn.editable = false;
                eachColumn.cellRenderer = 'agCell';
                eachColumn.minWidth = 180;
                eachColumn.cellRendererParams = {
                    appendToClass: 'modal-with-custom-dropdown-panel',
                    callBackFn: (event, params) => {
                        this.customizeLogicList[params.rowIndex][
                            'customization'
                        ][params.colDef.field] = event;
                    },
                    getSelectedData: (params) => {
                        return params.data.customization[each.name];
                    },
                    getListData: (params) => {
                        const data = each.listData.map((dataList) => {
                            return {
                                id: dataList.id,
                                label: dataList.id
                            };
                        });
                        return data;
                    }
                };
            }
            if (
                this.columnFilterType[each.name] ===
                FilterType.DROPDOWN_MULTIPLE
            ) {
                eachColumn.cellClass = 'table-dropdown-container';
                eachColumn.editable = false;
                eachColumn.cellRenderer = 'agCell';
                eachColumn.minWidth = 180;
                eachColumn.cellRendererParams = {
                    appendToClass: 'modal-with-custom-dropdown-panel',
                    filterInfo: {
                        type: FilterType.DROPDOWN_MULTIPLE,
                        placeholder: each.placeholder,
                        label: each.label
                    },
                    setEditableFn: (params, callbackFn) => {
                        if (this.editRowsMap) {
                            this.editRowsMap.set(
                                each.name + '_' + params.rowIndex,
                                callbackFn
                            );
                        }
                    },
                    callBackFn: (event, params) => {
                        this.customizeLogicList[params.rowIndex][
                            'customization'
                        ][params.colDef.field] = event;
                    },
                    getSelectedData: (params) => {
                        if (each.name in params.data.customization) {
                            return params.data.customization[each.name];
                        }
                        return [];
                    },
                    getListData: (params) => {
                        return each.listData;
                    }
                };
            }

            if (this.columnFilterType[each.name] === FilterType.NUMBER) {
                eachColumn.cellEditor = true;
                eachColumn.componentFramework = NumberFieldCellEditorComponent;
                eachColumn.cellEditorParams = {
                    index: index,
                    field: each,
                    isFocused: (index, forced: boolean = false) => {
                        if (forced) {
                            this.editingColIndex = index;
                            return true;
                        }

                        if (index === this.editingColIndex) {
                            return true;
                        } else if (index < this.editingColIndex) {
                            this.editingColIndex = index;
                            return true;
                        } else {
                            return false;
                        }
                    },
                    onValueChange: (updatedValue, rowIndex, fieldName) => {
                        this.customizeLogicList[rowIndex]['customization'][
                            fieldName
                        ] = updatedValue;
                    }
                };
            }

            dynamicColumns.push(eachColumn);
        });

        this.customizeLogicTableInput.columns.splice(1, 0, ...dynamicColumns);
    }

    setUpListing() {
        const hitApi: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['securityCheckResponse'][
                'listCustomizeLogic'
            ]
        );
        hitApi.function = (response) => {
            this.customizeLogicList = response;
            this.setupTable();
        };
        hitApi.config.defaultHeaders = {
            'content-type': 'application/json'
        };
        hitApi.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while fetching the data.'
            );
        };
        hitApi.input = {
            widgetId: this.widgetRef.widgetData.widgetId
        };
        new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    setUpDefaultForm() {
        const hitApi: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['securityCheckResponse'][
                'customizeForm'
            ]
        );
        hitApi.function = (response) => {
            this.customizeForm = response;
            this.generateRemediationForm(response, this.resources);
            this.setUpListing();
        };
        hitApi.config.defaultHeaders = {
            'content-type': 'application/json'
        };
        hitApi.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error getting generating form.'
            );
        };
        new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    generateRemediationForm(
        response: IBackendFormGeneratorInput,
        rowData: any[]
    ) {
        const formGenInput: IFormGeneratorInput =
            Helper.formGeneratorBtoFParser(
                response,
                rowData,
                this.widgetRef.notificationsService,
                this.widgetRef.httpService,
                this.widgetRef.changeDetectorRef,
                null,
                () => {
                    this.widgetRef.modalService.closeModal(
                        null,
                        this.modalData.modalId
                    );
                    this.widgetRef.refreshWidget(true);
                },
                (formGroup: FormGroup) => {
                    delete formGroup.value['complianceIdList'];
                    return {
                        customizationObject: formGroup.value
                    };
                }
            );
        this.customizeFormGenInput = formGenInput;

        const customComplianceField: IFormField = {
            fieldType: FilterType.DROPDOWN_MULTIPLE,
            label: 'Custom Compliance',
            placeholder: 'Select Custom Compliance',
            name: 'complianceIdList',
            required: true,
            apiInfo:
                this.widgetRef.widgetData.widgetInfo['securityCheckResponse'][
                    'listCustomCompliance'
                ],
            apiInput: { widgetId: this.widgetRef.widgetData.widgetId },
            showLabel: true,
            appearance: 'legacy',
            showKey: 'value',
            validations: [
                {
                    errorMessage: 'This field is required.',
                    validator: CustomValidators.required
                }
            ]
        };

        this.customizeFormGenInput.fields.unshift(customComplianceField);
    }

    update(buttonRef: IButtonGeneratorInput) {
        buttonRef.loader = true;
        if (this.gridRef) {
            this.gridRef.api.redrawRows();
        }
        const customLogicList = Helper.cloneDeep(this.customizeLogicList);
        if (!this.isUpdateValid(customLogicList)) {
            buttonRef.loader = false;
            return;
        }
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['securityCheckResponse'][
                'editCustomCompliance'
            ]
        );
        apiArgs.input = customLogicList.map((each) => {
            const customizeObject = each['customization'];
            each['customizationObject'] = customizeObject;
            delete each['customization'];
            delete each['complianceName'];
            return each;
        });
        apiArgs.function = () => {
            this.widgetRef.notificationsService.showSnackBar(
                'Updated successfully'
            );
            this.widgetRef.modalService.closeModal(
                null,
                this.modalData.modalId
            );
            this.widgetRef.refreshWidget(true);
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while updating.'
            );
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    deleteLogic(data, index) {
        const confirmationModalData: IConfirmationModal = {
            function: (modalId: Symbol) => {
                const apiArgs: IHitApi = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo[
                        'securityCheckResponse'
                    ]['deleteCustomizeLogic']
                );
                apiArgs.input = {
                    complianceIdList: [data]
                };
                (apiArgs.function = () => {
                    this.widgetRef.notificationsService.showSnackBar(
                        'Deleted successfully'
                    );
                    this.widgetRef.modalService.closeModal(null, modalId);
                    this.customizeLogicList.splice(index, 1);
                    this.gridRef.api.setRowData(this.customizeLogicList);
                    this.widgetRef.refreshWidget(true);
                }),
                    (apiArgs.errorFunction = (error) => {
                        this.widgetRef.modalService.closeModal(null, modalId);
                        Helper.showErrorMessage(
                            this.widgetRef.notificationsService,
                            error,
                            'Error Deleting.'
                        );
                    }),
                    new HitApi(
                        apiArgs,
                        this.widgetRef.httpService,
                        this.widgetRef.ngZone
                    ).hitApi();
            },
            modalName: 'Delete Logic',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            buttonColorType: ButtonColorType.WARN,
            contextIcon: {
                extraClass: 'color-accent',
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclamation-triangle'
            },
            confirmationMessage: `Are you sure you want to delete ?`,
            buttonText: 'Delete',
            loaderOnExec: true
        };

        const modalData: IModalData = {
            modalName: 'Customize',
            modalIcon: null,
            modalType: ModalType.MINI_MODAL,
            sourceId: this.widgetRef.uniqueIdentity,
            modalHeightVh: 30,
            modalWidthVw: 30,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ConfirmationModalComponent,
                        payload: {
                            data: {
                                function: confirmationModalData.function,
                                params: confirmationModalData
                            }
                        }
                    },
                    stepName: 'Customize'
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    isUpdateValid(customizeLogicList) {
        if (
            customizeLogicList &&
            customizeLogicList.length &&
            this.customizeForm &&
            this.customizeForm.fields &&
            this.customizeForm.fields.length
        ) {
            const errorMap = {};

            const formGroup: FormGroup = new FormGroup({});

            this.customizeForm.fields.forEach((field) => {
                const control = new FormControl();

                const validations = [];

                errorMap[field.name] = {};

                Helper.generateBtoFValidations(field.validations).forEach(
                    (validation: IValidator) => {
                        validation.validator.formGenRef = {
                            formGroup: formGroup
                        };

                        validations.push(
                            validation.validator.validatorFunction.bind(
                                validation.validator
                            )
                        );

                        errorMap[field.name][validation.validator.key] =
                            validation.errorMessage;
                    }
                );

                control.setValidators(validations);

                formGroup.addControl(field.name, control);
            });

            return customizeLogicList.every((customizeLogic) => {
                const customizationLogic = customizeLogic['customization'];
                this.customizeForm.fields.forEach((field) => {
                    if (field.fieldType === FilterType.NUMBER) {
                        formGroup
                            .get(field.name)
                            .setValue(+customizationLogic[field.name]);
                    } else {
                        formGroup
                            .get(field.name)
                            .setValue(customizationLogic[field.name]);
                    }
                });
                const result = formGroup.valid;
                if (!result) {
                    return Object.keys(formGroup.controls).every(
                        (fieldName) => {
                            if (formGroup.get(fieldName).errors) {
                                return Object.keys(
                                    formGroup.get(fieldName).errors
                                ).every((errorKey) => {
                                    if (
                                        errorMap[fieldName] &&
                                        errorMap[fieldName][errorKey]
                                    ) {
                                        Helper.showErrorMessage(
                                            this.widgetRef.notificationsService,
                                            errorMap[fieldName][errorKey],
                                            'Invalid entries'
                                        );
                                        return false;
                                    }
                                    return true;
                                });
                            }
                            return true;
                        }
                    );
                }
                return result;
            });
        }
    }
}
