<div
    class="container tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-between"
>
    <div class="instance-container tw-m-5 tw-overflow-x-hidden">
        <mat-accordion>
            <mat-expansion-panel
                [expanded]="isFirst"
                (opened)="activeInstance = i"
                *ngFor="
                    let instance of selectedInstances;
                    index as i;
                    first as isFirst
                "
            >
                <mat-expansion-panel-header collapsedHeight="64px">
                    <mat-panel-title>
                        <div
                            class="name tw-text-fs-400 tw-font-semibold tw-my-5 tw-mx-4"
                        >
                            <span> {{ instance["identifier"] }} </span>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="form tw-flex tw-flex-1 tw-justify-evenly">
                    <div class="name tw-text-fs-400 tw-font-semibold tw-mt-10">
                        <span> Resource Tag Keys</span>
                    </div>

                    <app-form-generator
                        class="form-gen tw-h-full tw-mt-1"
                        *ngIf="instance.form"
                        [formGeneratorInput]="instance.form"
                        (formGroupRef)="
                            instance.selectedValues =
                                $event.value.configurationName
                        "
                    >
                    </app-form-generator>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="bottom-buttons tw-flex tw-items-center tw-justify-between">
        <span class="delete-text tw-text-fs-400 tw-font-semibold tw-ml-14">
            * This action can't be undone.
        </span>
        <div class="buttons tw-float-right tw-my-4 tw-mx-5">
            <app-multi-button-generator [buttonGenInputs]="buttonInputs">
            </app-multi-button-generator>
        </div>
    </div>
</div>
