import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IScoringAssessmentModal } from 'src/app/shared/interfaces/modal/IScoringAssessmentModal';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';

@Component({
    selector: 'app-scoring-assessment-modal',
    templateUrl: './scoring-assessment-modal.component.html',
    styleUrls: ['./scoring-assessment-modal.component.sass']
})
export class ScoringAssessmentModalComponent implements OnInit, AfterViewInit {
    public assignAssessmentFormGroup: FormGroup;
    private functionToExecute = null;
    private valuesAllowedAsScore: string[] = ['0', '1'];
    public modalParams: IScoringAssessmentModal = null;
    public descriptionText: string;
    private loaderOnExec: boolean;
    public buttonInputs: IButtonGeneratorInput[];
    public fontSize: number | string;
    public extraClass: string;
    private documentStyles: CSSStyleDeclaration;
    public customButtonGeneratorInput: {
        buttonGenInput: IButtonGeneratorInput[];
        options?: IMultiButtonOption;
    };
    public assignAssessmentFormGenInputs: IFormGeneratorInput = {
        formName: 'Assign Assessment',
        state: FormState.CREATE,
        submitButton: null,
        fields: [
            {
                label: 'Score',
                placeholder: 'Enter score as 0 or 1',
                name: 'score',
                fieldType: FilterType.TEXT,
                required: true,
                appearance: 'legacy',
                showLabel: true,
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'Score is required.'
                    },
                    {
                        validator: CustomValidators.regexValidator('^[01]$'),
                        errorMessage: 'Score should be 0 or 1'
                    }
                ]
            }
        ]
    };

    constructor(
        public modalInputData: ModalInjectedData,
        public modalService: ModalService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.documentStyles = getComputedStyle(this.document.documentElement);
    }

    ngOnInit(): void {
        this.functionToExecute = this.modalInputData['data']['function'];
        this.modalParams = this.modalInputData['data']['params'];
        this.descriptionText = this.modalParams['descriptionText'];
        this.fontSize = this.modalParams['fontSize']
            ? this.modalParams['fontSize']
            : 0.875;
        this.fontSize = this.getFontSize(this.fontSize);
        this.loaderOnExec = this.modalParams['loaderOnExec'];
        this.extraClass = this.modalParams['extraClass'];
        this.customButtonGeneratorInput =
            this.modalParams['customButtonGeneratorInput'];
        if (this.modalParams?.['valuesAllowedAsScore']?.length) {
            this.valuesAllowedAsScore =
                this.modalParams['valuesAllowedAsScore'];
        }
        if (this.modalParams?.['formGenInputs']) {
            this.assignAssessmentFormGenInputs =
                this.modalParams?.formGenInputs;
        }
        this.buttonInputs = [
            {
                buttonName: this.modalParams['buttonText'],
                buttonType: ButtonType.FLAT,
                buttonColorType:
                    this.modalParams['buttonColorType'] || ButtonColorType.WARN,
                function: (buttonRef: IButtonGeneratorInput) => {
                    if (this?.assignAssessmentFormGroup?.valid) {
                        if (this.loaderOnExec) {
                            buttonRef.loader = true;
                        }
                        this.functionToExecute(
                            this.modalInputData.modalId,
                            this.assignAssessmentFormGroup,
                            buttonRef
                        );
                    }
                },
                showLoader: this.loaderOnExec
            }
        ];
    }

    ngAfterViewInit(): void {
        const scoreFormControl = this.assignAssessmentFormGroup?.get('score');
        const maxOptionLength = this.getMaxOptionLength();
        scoreFormControl?.valueChanges?.subscribe((val: string | null) => {
            if (maxOptionLength && !this.valuesAllowedAsScore.includes(val)) {
                let valueToPatch = null;
                if (val?.length > maxOptionLength) {
                    valueToPatch = val?.slice(0, maxOptionLength);
                }
                scoreFormControl.patchValue(valueToPatch, { emitEvent: false });
            }
        });
    }

    private getMaxOptionLength(): number | null {
        let maxLength: number | null = null;
        this.valuesAllowedAsScore.forEach((el) => {
            if (!maxLength || maxLength < el?.length) {
                maxLength = el.length;
            }
        });
        return maxLength;
    }

    private getFontSize(value: number | string) {
        if (typeof value === 'string') {
            this.fontSize = this.documentStyles.getPropertyValue(`--${value}`);
            return this.fontSize;
        } else {
            return value + 'rem';
        }
    }
}
