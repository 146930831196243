<div class="form">
    <app-form-generator
        *ngIf="!isShowStaticFormForTabularForms && requestFormGenInput"
        [formGeneratorInput]="requestFormGenInput"
        (formGroupRef)="requestFormGroupRef = $event"
        (valuesChange)="staticUpdateFields($event)"
        (updateControl)="updateControl = $event"
    ></app-form-generator>
    <app-assessment-google-msp-forms
        *ngIf="isShowStaticFormForTabularForms"
        [isEdit]="isEdit"
        [modalId]="this.modalInputData.modalId"
        [widgetRef]="widgetRef"
        [formPreSavedDataInEdit]="this.modalInputData.data.formPreSavedData"
    >
    </app-assessment-google-msp-forms>
</div>
