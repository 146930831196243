import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { IconType } from 'src/app/shared/enums/IconType';
import { IWysiwygEditorInsertOperation } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { FormState } from './../../../../enums/FormState';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import {
    WidgetCreationActivityType,
    WidgetCreationService
} from './../../../../services/widget-creation/widget-creation.service';

@Component({
    selector: 'app-request-widget-template-modal',
    templateUrl: './request-widget-template-modal.component.html',
    styleUrls: ['./request-widget-template-modal.component.sass']
})
export class RequestWidgetTemplateModalComponent implements OnInit, OnDestroy {
    templateData: any;
    titleFormInput: IFormGeneratorInput;
    descriptionFormInput: IFormGeneratorInput;
    titleFormGroupRef: FormGroup;
    descriptionFormGroupRef: FormGroup;
    loaderGroup = {
        saveAsDraft: false,
        configure: false
    };
    widgetRef: Widget;
    pencilIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-pencil-alt',
        extraClass: 'pencil-icon'
    };
    isEdit: boolean = true;
    isPreview: any;
    widgetId: any;
    hideRequestButton: boolean;
    hideDescriptionHeader: boolean;
    isTerraform: boolean = false;
    isCMS: boolean;
    isEditingName: boolean = true;
    widgetName: string;
    buttonsInput: IButtonGeneratorInput[];
    buttonOptions: IMultiButtonOption;
    requestButton: IButtonGeneratorInput;
    alreadyExistWidgetNames: string[];
    requestButtonMessage: string;
    resetModal: Subscription;

    constructor(
        private modalInputData: ModalInjectedData,
        private widgetCreationService: WidgetCreationService,
        private multiStepFormService: MultiStepFormService,
        private modalService: ModalService
    ) {
        this.templateData = modalInputData.data['templateData'];
        this.widgetName = this.templateData['widgetName'];
        this.widgetRef = modalInputData.data['widgetRef'];
        this.isEdit = modalInputData.data['isEdit'];
        this.isPreview = modalInputData.data['isPreview'];
        this.widgetId = modalInputData.data['widgetId'];
        this.hideDescriptionHeader =
            modalInputData.data['hideDescriptionHeader'];
        this.hideRequestButton = modalInputData.data['hideRequestButton'];
        this.isTerraform = modalInputData.data['isTerraform'];
        this.isCMS = modalInputData.data['isCMS'];
        this.alreadyExistWidgetNames =
            this.modalInputData.data['alreadyExistWidgetNames'];
        this.requestButtonMessage =
            this.modalInputData.data['requestButtonMessage'];

        if (
            this.widgetName &&
            this.alreadyExistWidgetNames &&
            this.alreadyExistWidgetNames.length
        ) {
            const index = this.alreadyExistWidgetNames.findIndex(
                (widgetName) =>
                    widgetName === this.widgetName.toLocaleLowerCase()
            );
            if (index !== -1) {
                this.alreadyExistWidgetNames.splice(index, 1);
            }
        }
    }

    ngOnInit(): void {
        this.handleBackButton();
        this.initForms();
        this.resetModal = this.modalService.resetModal.subscribe((data) => {
            this.titleFormGroupRef.reset();
            this.descriptionFormGroupRef.reset();
            const description =
                this.templateData['description'] &&
                this.templateData['description']['baseDescription'] &&
                this.templateData['description']['baseDescription'].length
                    ? this.templateData['description']['baseDescription'][0]
                          .value
                    : '';
            this.descriptionFormGroupRef
                .get('description')
                .setValue(description);
            this.templateData['widgetName'] = this.widgetName;
            this.initTitleForm();
            this.isEditingName = true;
        });
        this.generateButtons();
    }
    generateButtons() {
        this.buttonOptions = {
            layout: { justifyContent: 'flex-end' }
        };

        this.buttonsInput = [
            {
                buttonName: 'Save as Draft',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.saveAsDraft(buttonRef);
                }
            },
            {
                buttonName: this.isTerraform
                    ? 'Upload TF Files'
                    : 'Configure Request Form',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                function: () => {
                    this.configureRequestForm();
                }
            }
        ];
        this.requestButton = {
            buttonName: 'Request',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: null,
            customClass: this.isEdit
                ? 'request-button-container fix'
                : 'request-button-container top',
            hoverText: this.requestButtonMessage
        };
    }

    handleBackButton() {
        if (
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(1)
        ) {
            this.templateData = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1);

            this.templateData.description['baseDescription'] = [];

            if (
                this.templateData &&
                this.templateData.description &&
                this.templateData.description.length
            )
                this.templateData.description['baseDescription'].push({
                    value: this.templateData.description[0].value
                });
        }
    }

    initTitleForm() {
        this.titleFormInput = null;
        const titleFormInput = {
            formName: 'titleForm',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Widget Title',
                    placeholder: 'Widget Name',
                    name: 'widgetName',
                    fieldType: FilterType.TEXT,
                    required: true,
                    value: this.templateData['widgetName'],
                    onFocusout: () => {
                        if (this.titleFormGroupRef.value.widgetName) {
                            if (
                                this.alreadyExistWidgetNames.includes(
                                    this.titleFormGroupRef.value.widgetName.toLocaleLowerCase()
                                )
                            ) {
                                Helper.markAllFieldAsTouched(
                                    this.titleFormGroupRef
                                );
                                if (this.titleFormGroupRef.invalid) {
                                    this.titleFormGroupRef.updateValueAndValidity();
                                    return;
                                }
                            }
                            this.isEditingName = false;
                            if (!this.templateData) {
                                this.templateData = {};
                                this.templateData['widgetName'] =
                                    this.titleFormGroupRef.value.widgetName;
                            } else {
                                this.templateData['widgetName'] =
                                    this.titleFormGroupRef.value.widgetName;
                            }
                        }
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Widget Name is required'
                        },
                        {
                            validator: CustomValidators.keyAlreadyExists(
                                this.alreadyExistWidgetNames,
                                false
                            ),
                            errorMessage: 'Enter unique widget name'
                        }
                    ]
                }
            ]
        };

        this.widgetRef.ngZone.run(() => {
            setTimeout(() => {
                this.titleFormInput = titleFormInput;
            }, 0);
        });
    }

    initForms() {
        this.initTitleForm();

        this.descriptionFormInput = {
            formName: 'descriptionForm',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Widget Description',
                    placeholder: 'Widget description goes here...',
                    name: 'description',
                    fieldType: FilterType.WYSIWYG_EDITOR,
                    hideWysiwygEditorInsertOperation: [
                        IWysiwygEditorInsertOperation.PICTURE,
                        IWysiwygEditorInsertOperation.TABLE
                    ],
                    required: false,
                    value:
                        this.templateData['description'] &&
                        this.templateData['description']['baseDescription'] &&
                        this.templateData['description']['baseDescription']
                            .length
                            ? this.templateData['description'][
                                  'baseDescription'
                              ][0].value
                            : ''
                }
            ]
        };
    }

    saveAsDraft(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }

        if (!this.checkDataValidityAndSetData()) {
            return;
        }

        const dataForApi = this.prepareData();

        if (dataForApi) {
            buttonRef.loader = true;

            this.widgetCreationService.saveAsDraft(
                dataForApi,
                (res) => {
                    buttonRef.loader = false;

                    this.modalService.closeModal(
                        null,
                        this.modalInputData.modalId
                    );
                },
                () => {
                    buttonRef.loader = false;
                },
                this.widgetId
            );
        }
    }

    configureRequestForm() {
        if (!this.checkDataValidityAndSetData()) {
            return;
        }

        this.multiStepFormService.nextStep(this.modalInputData.modalId);
    }

    checkDataValidityAndSetData() {
        let isValid = true;

        if (this.titleFormGroupRef.invalid) {
            this.titleFormGroupRef.markAllAsTouched();
            isValid = false;
        }

        if (isValid) {
            const dataForApi = this.prepareData();

            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .set(1, dataForApi);
        }
        return isValid;
    }

    prepareData() {
        if (this.loaderGroup.saveAsDraft || this.loaderGroup.configure) {
            return;
        }

        const draftKey = {
            extraInfo: {
                activityType: WidgetCreationActivityType.WIDGET_DRAFT
            }
        };

        const description = [
            {
                type: 'HTML',
                heading: 'Description',
                showHeading: false,
                value: this.descriptionFormGroupRef.value['description']
            }
        ];

        const inputs = {
            widgetName: this.titleFormGroupRef.value.widgetName,

            description: this.descriptionFormGroupRef.value['description']
                ? description
                : [],

            ...draftKey
        };

        if (this.isTerraform) {
            inputs['widgetType'] =
                this.widgetRef.widgetData.widgetInfo.widgetType;
        }

        return inputs;
    }
    ngOnDestroy(): void {
        this.resetModal.unsubscribe();
    }
}
