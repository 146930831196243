<div class="auth-container tw-flex">
    <div class="form-field">
        <app-form-generator
            *ngIf="otpFormGenInput"
            [formGeneratorInput]="otpFormGenInput"
            (formGroupRef)="otpFormGroup = $event"
        ></app-form-generator>
        <div *ngIf="errorMsg | async">
            <span class="tw-text-negative">{{ errorMsg.value }}</span>
        </div>
        <span *ngIf="!timeOut && !invalideOtp" class="tw-text-neutral"
            >*OTP has been successfully sent to the
            <u>{{
                otpInput.email
                    ? otpInput["email"]
                    : "+" + otpInput["phoneNumber"]
            }}</u></span
        >
    </div>
    <div
        class="
            tw-flex-1 tw-flex tw-flex-col tw-items-end tw-justify-start tw-pb-5
        "
    >
        <div>
            <app-button-generator
                [disabled]="!this.enableResendBtn || this.timeOut"
                [buttonGenInput]="resendOtpButtonGenInput"
                (buttonRef)="sendOtp()"
            ></app-button-generator>
        </div>

        <div
            class="tw-text-neutral tw-w-full tw-pr-2"
            *ngIf="showTimer && !timeOut"
        >
            <span
                *ngIf="!timeOut && timer | async"
                class="tw-w-full tw-flex tw-justify-end tw-font-bold tw-mt-3"
                >{{ timer | async }} Sec</span
            >
        </div>
    </div>
</div>
<div class="tw-flex tw-justify-end tw-mt-5">
    <app-button-generator
        [disabled]="this.timeOut && !this.invalideOtp"
        [buttonGenInput]="verifyOtpButtonGenInput"
        (buttonRef)="
            $event.loader = true;
            functionToExecute(
                modalData.modalId,
                otpFormGroup,
                otpVerification.bind(this)
            )
        "
    ></app-button-generator>
</div>
