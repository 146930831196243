import { Pipe, PipeTransform } from '@angular/core';
import {
    DomSanitizer,
    SafeHtml,
    SafeResourceUrl,
    SafeScript,
    SafeStyle,
    SafeUrl
} from '@angular/platform-browser';
import { SanitizeTypes } from '../enums/SanitizeTypes';

@Pipe({
    name: 'safeRender'
})
export class SafeRenderPipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) {}

    public transform(
        value: any,
        type: SanitizeTypes
    ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        switch (type) {
            case SanitizeTypes.HTML:
                return this.sanitizer.bypassSecurityTrustHtml(value);
            case SanitizeTypes.STYLE:
                return this.sanitizer.bypassSecurityTrustStyle(value);
            case SanitizeTypes.SCRIPT:
                return this.sanitizer.bypassSecurityTrustScript(value);
            case SanitizeTypes.URL:
                return this.sanitizer.bypassSecurityTrustUrl(value);
            case SanitizeTypes.RESOURCE_URL:
                return this.sanitizer.bypassSecurityTrustResourceUrl(value);
            default:
                throw new Error(`Invalid safe type specified: ${type}`);
        }
    }
}
