import { Component, OnInit } from '@angular/core';
import { skip, take } from 'rxjs/operators';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterStoreKey } from 'src/app/shared/enums/FilterStoreKey';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFilterData } from 'src/app/shared/interfaces/filter/IFilterData';
import { IInfoModal } from 'src/app/shared/interfaces/modal/IInfoModal';
import { FiltersHttpService } from 'src/app/shared/services/http/filters-http/filters-http.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { ModalAction } from './../../../../enums/ModalAction';
import { FiltersService } from './../../../../services/filters/filters.service';

enum Configurations {
    OVERWRITE = 'Overwrite',
    APPEND = 'Append',
    IGNORE = 'Ignore'
}
@Component({
    selector: 'app-advanced-automation-modal-congifuration',
    templateUrl: './advanced-automation-modal-congifuration.component.html',
    styleUrls: ['./advanced-automation-modal-congifuration.component.sass']
})
export class AdvancedAutomationModalCongifurationComponent implements OnInit {
    infoData: IInfoModal[] = [
        {
            infoHeading: '',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [
                        'Pick the resource that needs to be tagged automatically as per the resource chosen in the previous step.'
                    ]
                }
            ]
        },
        {
            infoHeading: '<strong>Overwrite  :</strong>',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [
                        ' This will replace the already existing key with the chosen key-value pair. (eg. "Owner" : John -> "Owner": "Stephen")'
                    ]
                }
            ]
        },
        {
            infoHeading: '<strong>Append :</strong>',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [
                        ' This will add the new value to the existing key-value pair. (eg. "Owner" : "John" -> "Owner": "John || Stephen")'
                    ]
                }
            ]
        },
        {
            infoHeading: '<strong>Ignore :</strong>',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [
                        ' Avoid any change to the corresponding key-value pair. (eg. "Owner" : "John" -> "Owner": "John")'
                    ]
                }
            ]
        }
    ];

    data;
    cardData;
    labels;
    Configurations = Configurations;
    defaultRadio = Configurations.OVERWRITE;
    configurationType = [];
    filterStoreKey = FilterStoreKey.AUTOMATION_FILTERS;
    filtersData = new Map<string, IFilterData>();
    configurationAutomation = {};
    selectedValue: Map<string, {}> = new Map();
    buttonGenInput: IButtonGeneratorInput[];
    constructor(
        private multiStepFormService: MultiStepFormService,
        private modalData: ModalInjectedData,
        private filtersService: FiltersService,
        private filtersHttpService: FiltersHttpService
    ) {
        this.data = modalData.data;
        this.cardData = this.data['cardData'];
        this.labels = this.data.widgetInfo.schedulingAutomation.configuration;
        this.setSelectedValue();
    }

    ngOnInit(): void {
        const filterInfoData: Map<string, IFilterData> =
            this.filtersService.generateFiltersDataWithFilterIds(
                this.labels['filters']
            );
        this.filtersData = new Map(
            [...this.filtersData].concat([...filterInfoData])
        );
        this.generateButton();
    }
    generateButton() {
        this.buttonGenInput = [
            {
                buttonName: 'Back',
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.PRIMARY,
                function: (buttonRef) => {
                    this.previouStep();
                },
                showLoader: true
            },
            {
                buttonName: 'Next',
                buttonType: ButtonType.RAISED,
                buttonColorType: ButtonColorType.PRIMARY,
                function: (buttonRef) => {
                    this.nextStep();
                },
                showLoader: true
            }
        ];
    }
    setSelectedValue() {
        this.labels['filters'].forEach((filter) => {
            const filterInfo = this.filtersHttpService.getFilterInfo(filter);
            filterInfo.pipe(skip(1), take(1)).subscribe((response) => {
                this.filterInfoParser(response, filter);
            });
        });
    }
    filterInfoParser(response, filter) {
        const val = response.selector;
        if (this.data['purpose'] === ModalAction.UPDATE) {
            const filterObj =
                this.data['cardData']['automationFilter']['configurationValue'];

            if (filterObj.hasOwnProperty(val)) {
                this.selectedValue.set(filter, {
                    [val]: {
                        id:
                            filterObj[val].charAt(0).toLowerCase() +
                            filterObj[val].slice(1),
                        label: filterObj[val]
                    }
                });
            } else {
                this.selectedValue.set(filter, {
                    [val]: {
                        id: this.defaultRadio.toLowerCase(),
                        label: this.defaultRadio
                    }
                });
            }
        } else {
            this.selectedValue.set(filter, {
                [val]: {
                    id: this.defaultRadio.toLowerCase(),
                    label: this.defaultRadio
                }
            });
        }
    }

    nextStep() {
        this.filtersService.convertTempToMain(this.filterStoreKey, true);
        const filters = this.filtersService.prepareInput(
            Array.from(
                this.filtersService.filtersData.get(this.filterStoreKey).keys()
            ),
            this.filterStoreKey
        );
        let showAppendKey = false;
        //Preparing Configuration Automation object
        Object.keys(filters).forEach((label) => {
            if (filters[label].id) {
                this.configurationAutomation[label] = filters[label].label;
            }
        });
        //Append Key for next modal step
        Object.values(filters).forEach((o) => {
            if (o.id && o.id === 'append') {
                showAppendKey = true;
            }
        });

        this.multiStepFormService.stepData.get(this.modalData.modalId).set(2, {
            showAppendKey: showAppendKey,
            configurationAutomation: this.configurationAutomation,
            widgetFilters: this.labels
        });

        this.multiStepFormService.nextStep(this.modalData.modalId);
    }

    previouStep() {
        this.multiStepFormService.previousStep(this.modalData.modalId);
    }
}
