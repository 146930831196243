<ng-template #loader>
    <div class="overlay" style="z-index: 3">
        <div class="overlay-content">
            <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
        </div>
    </div>
</ng-template>

<div class="root-wrapper">
    <div class="integration-name-wrapper">
        <div class="integration-label">Group Name</div>
        <div class="integration-name">
            {{ integrationName }}
        </div>
    </div>

    <div class="bottom-wrapper">
        <div class="select-contact">
            <div class="select-contact-top">
                <p>Select Contact</p>
                <p
                    *ngIf="contactsToSave && contactsToSave.length"
                    class="select-all-btn"
                    (click)="
                        contactsToSaveSelectAll = true;
                        selectContact(filteredContactsToSave, null, true)
                    "
                >
                    Select All
                </p>
            </div>

            <div class="contacts-wrapper">
                <ng-container *ngIf="!leftLoader; else loader">
                    <div class="search-wrapper">
                        <app-table-search
                            [data]="contactsToSave"
                            (changed)="filteredContactsToSave = $event"
                        ></app-table-search>
                    </div>
                    <ul class="contacts-list">
                        <ng-container
                            *ngFor="
                                let contact of filteredContactsToSave;
                                let i = index
                            "
                        >
                            <li>
                                <mat-checkbox
                                    (change)="selectContact(contact, i)"
                                >
                                    <span class="label">
                                        {{
                                            contact["name"]
                                                ? contact["name"]
                                                : contact["contactName"]
                                        }}
                                    </span>
                                </mat-checkbox>
                            </li>
                        </ng-container>
                    </ul>
                </ng-container>
            </div>
        </div>
        <div class="selected-contact">
            <div class="select-contact-top">
                <p>Selected Contact</p>
                <p
                    class="select-all-btn"
                    (click)="
                        contactNamesSelectAll = !contactNamesSelectAll;
                        selectContactsToSaveFn(
                            null,
                            filteredContactNames,
                            null,
                            true
                        )
                    "
                >
                    {{ contactNamesSelectAll ? "Unselect All" : "Select All" }}
                </p>
            </div>

            <div class="contacts-wrapper">
                <ng-container *ngIf="!rightLoader; else loader">
                    <div class="search-wrapper">
                        <app-table-search
                            [data]="contactNames"
                            (changed)="filteredContactNames = $event"
                        ></app-table-search>
                    </div>
                    <ul class="contacts-list">
                        <ng-container
                            *ngFor="
                                let contact of filteredContactNames;
                                let i = index
                            "
                        >
                            <li>
                                <mat-checkbox
                                    [checked]="contactNamesSelectAll"
                                    (change)="
                                        selectContactsToSaveFn(
                                            $event,
                                            contact,
                                            i
                                        )
                                    "
                                >
                                    <span class="label">
                                        {{
                                            contact["name"]
                                                ? contact["name"]
                                                : contact["contactName"]
                                        }}
                                    </span>
                                </mat-checkbox>
                                <span
                                    appIcon
                                    [data]="trashIcon"
                                    class="trash-icon-container"
                                    (click)="deleteContacts(contact, i)"
                                ></span>
                            </li>
                        </ng-container>
                    </ul>
                </ng-container>
                <span
                    class="delete-btn"
                    [class.disabled]="
                        !(
                            selectedContactsToSave &&
                            selectedContactsToSave.length
                        )
                    "
                    (click)="deleteContacts(selectedContactsToSave, null, true)"
                    >Delete All</span
                >
            </div>
        </div>
    </div>
</div>
<div class="action-btn-wrapper">
    <app-multi-button-generator
        [buttonGenInputs]="buttonGenInputs"
        [options]="buttonOptions"
    ></app-multi-button-generator>
</div>
