import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Widget } from './Widget';

@Injectable()
export class SubWidgetInjectedData {
    resetObserver: Subject<string>;
    subWidgetId: string;
    parentWidgetRef: Widget;
    data: any;
}
