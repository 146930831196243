import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IDropdownData } from 'src/app/shared/interfaces/dropdown-data/IDropdownData';
import {
    AuditEmailConfigActionType,
    AuditEmailConfigRuleItemType,
    AuditEmailConfigurationRuleType,
    EmailTemplateConfiguredFor,
} from 'src/app/shared/interfaces/email-configuration/emailConfigurationTypes';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import {
    CustomSignUpActionEnum,
    CustomSignUpFormResponseType,
} from 'src/app/shared/interfaces/ICustomSignTypes';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import {
    EmailConfigModalService,
    EmailConfigurationUserRolesEnum,
} from 'src/app/shared/services/assessment/email-config-service/email-config-modal.service';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';

@Component({
    selector: 'app-email-configuration-modal',
    templateUrl: './email-configuration-modal.component.html',
    styleUrls: ['./email-configuration-modal.component.sass'],
})
export class EmailConfigurationModalComponent implements OnInit {
    public readonly ConfigurationType = AuditEmailConfigurationRuleType;
    public selectedRuleType: AuditEmailConfigurationRuleType =
        AuditEmailConfigurationRuleType.DEFAULT;
    public plusIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'plus_icon_enlarge',
        extraClass: 'action-icon',
    };
    public removeIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'cross_icon',
        extraClass: 'action-icon',
    };
    public emailRulesConfigFormGenInput: IFormGeneratorInput;
    public emailRulesConfigFormGroup: FormGroup;
    public addEmailConfigRuleButtonGenInput: IButtonGeneratorInput;
    public updateEmailConfigRuleButtonGenInput: IButtonGeneratorInput;
    public saveEmailConfigRuleButtonGenInput: IButtonGeneratorInput;
    public submitButtonGenInputSignupFlow: IButtonGeneratorInput;
    public backButtonGenInputSignupFlow: IButtonGeneratorInput;
    public rulesList: AuditEmailConfigRuleItemType[];
    public rulesToBeDeletedList: AuditEmailConfigRuleItemType[] = [];
    public currentRuleConfigIndex: number;
    public isInEditState: boolean = false;
    public isSignupFlow: boolean; // Indicates is component being used in custom signup flow
    private handleSubmit: (
        buttonRef: IButtonGeneratorInput,
        formDataSignUp?: CustomSignUpFormResponseType,
        action?: 'CREATE' | 'EDIT'
    ) => void = null; // To be used when working with custom signup
    private timeoutId: NodeJS.Timeout;
    private widgetRef: Widget;
    private isEdit: boolean = false; // Indicated weather are we editing email config or creating new config while editing assessment
    private auditId: string;
    private isUnsavedChanges: boolean = false;
    public emailparseloader: boolean = false;
    public readonly spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER,
    };
    constructor(
        private modalData: ModalInjectedData,
        private emailConfigService: EmailConfigModalService,
        private modalService: ModalService,
        private listHttpService: ListHttpService,
        private multiStepFormService: MultiStepFormService,
        private cdref: ChangeDetectorRef
    ) {
        this.modalService.modalData.modalHeightVh = 96;
        this.modalService.modalData.modalWidthVw = 60;
        this.modalService.modalData.modalAutoHeight = false;
        this.modalService.modalData.modalAutoWidth = false;

        this.widgetRef = this.modalData.data.widgetRef;
        this.isEdit = this.modalData.data['isEdit'];
        this.isSignupFlow = this.modalData.data['isSignupFlow'] || false;
        if (this.isSignupFlow) {
            this.selectedRuleType = AuditEmailConfigurationRuleType.CUSTOM;
            this.handleSubmit = this.modalData.data['handleSubmit'] || null;
            this.selectedRuleType =
                this.emailConfigService.selectedRuleConfig ||
                this.modalData.data['selectedRuleType'];
            this.rulesList =
                this.emailConfigService.emailConfigRules.get(
                    this.selectedRuleType
                ) || this.modalData.data['emailConfigRulesData'];
        } else {
            this.rulesList = this.modalData.data['emailConfigRulesData'];
            this.selectedRuleType = this.modalData.data['selectedRuleType'];
        }
        this.auditId = this.modalData.data['auditId'];
    }

    ngOnInit(): void {
        // Callback functions that will run as soon as the response from
        // two of the API's (emailtemplates & trigger points) is received.
        this.parseEmailTemplates((res: IDropdownData[], err) => {
            this.emailRulesConfigFormGenInput = {
                formName: 'defaultEmailForm',
                state: FormState.CREATE,
                submitButton: null,
                fields: [
                    {
                        name: 'ruleName',
                        fieldType: FilterType.TEXT,
                        label: 'Rule Name',
                        placeholder: 'Input Rule Name',
                        required: true,
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Rule Name is mandatory',
                            },
                        ],
                    },
                    {
                        label: 'Rule Trigger Points',
                        placeholder: 'Select Trigger Points',
                        name: 'triggerPoints',
                        fieldType: FilterType.DROPDOWN_GROUP_MULTIPLE,
                        required: false,
                        groupBy: 'group',
                        value: '',
                        listData: res['triggerPoints'],
                        containerClass: 'preferredLanguage',
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage:
                                    'Please select at least one trigger point',
                            },
                        ],
                    },
                    {
                        label: 'To',
                        placeholder: 'To',
                        name: 'recipients',
                        fieldType: FilterType.MULTI_DROPDOWN_WITH_INPUT,
                        required: true,
                        showLabelInDropdownLegend: true,
                        value: '',
                        listData: this.isSignupFlow
                            ? [
                                  {
                                      id: EmailConfigurationUserRolesEnum.CUSTOMER,
                                      label: 'Customer',
                                  },
                              ]
                            : [
                                  {
                                      id: EmailConfigurationUserRolesEnum.ASSESSOR,
                                      label: 'Assessor',
                                  },
                                  {
                                      id: EmailConfigurationUserRolesEnum.ASSESEE,
                                      label: 'Assessee',
                                  },
                                  {
                                      id: EmailConfigurationUserRolesEnum.PROGRAM_CORDINATOR,
                                      label: 'Program Coordinator',
                                  },
                              ],
                        isInputField: true,
                        customInputField: {
                            name: 'emailTo',
                            fieldType: FilterType.MATCHIPLIST,
                            type: 'email',
                            value: [],
                            label: 'User Email',
                            placeholder: 'Input User Email',
                            required: false,
                            parentField: 'recipients',
                            validations: [
                                {
                                    validator:
                                        CustomValidators.matChipAllowEmail,
                                    errorMessage: 'Enter valid email',
                                },
                            ],
                        },
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage:
                                    'Please select at least one role or user',
                            },
                        ],
                    },
                    {
                        label: 'CC',
                        placeholder: 'CC',
                        name: 'carbonCopy',
                        fieldType: FilterType.MULTI_DROPDOWN_WITH_INPUT,
                        required: false,
                        showLabelInDropdownLegend: true,
                        value: '',
                        listData: this.isSignupFlow
                            ? []
                            : [
                                  {
                                      id: EmailConfigurationUserRolesEnum.ASSESSOR,
                                      label: 'Assessor',
                                  },
                                  {
                                      id: EmailConfigurationUserRolesEnum.ASSESEE,
                                      label: 'Assessee',
                                  },
                                  {
                                      id: EmailConfigurationUserRolesEnum.PROGRAM_CORDINATOR,
                                      label: 'Program Coordinator',
                                  },
                              ],
                        isInputField: true,
                        customInputField: {
                            name: 'emailCC',
                            fieldType: FilterType.MATCHIPLIST,
                            type: 'email',
                            value: [],
                            label: 'User Email',
                            placeholder: 'Input User Email',
                            required: false,
                            parentField: 'carbonCopy',
                            validations: [
                                {
                                    validator:
                                        CustomValidators.matChipAllowEmail,
                                    errorMessage: 'Enter valid email',
                                },
                            ],
                        },
                    },
                    {
                        label: 'BCC',
                        placeholder: 'BCC',
                        name: 'blindCarbonCopy',
                        fieldType: FilterType.MULTI_DROPDOWN_WITH_INPUT,
                        required: false,
                        showLabelInDropdownLegend: true,
                        value: '',
                        listData: this.isSignupFlow
                            ? []
                            : [
                                  {
                                      id: EmailConfigurationUserRolesEnum.ASSESSOR,
                                      label: 'Assessor',
                                  },
                                  {
                                      id: EmailConfigurationUserRolesEnum.ASSESEE,
                                      label: 'Assessee',
                                  },
                                  {
                                      id: EmailConfigurationUserRolesEnum.PROGRAM_CORDINATOR,
                                      label: 'Program Coordinator',
                                  },
                              ],
                        isInputField: true,
                        customInputField: {
                            name: 'emailBCC',
                            fieldType: FilterType.MATCHIPLIST,
                            type: 'email',
                            value: [],
                            label: 'User Email',
                            placeholder: 'Input User Email',
                            required: false,
                            parentField: 'blindCarbonCopy',
                            validations: [
                                {
                                    validator:
                                        CustomValidators.matChipAllowEmail,
                                    errorMessage: 'Enter valid email',
                                },
                            ],
                        },
                    },
                    {
                        label: 'Select Template',
                        placeholder: 'Select Template',
                        name: 'emailTemplateId',
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        required: true,
                        showLabelInDropdownLegend: true,
                        value: '',
                        listData: res['emailTemplates'],
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'E-mail template is mandatory',
                            },
                        ],
                    },
                ],
            };
            if (this.isSignupFlow) {
                this.emailRulesConfigFormGenInput.fields.splice(1, 1);
            }
        });

        if (
            !this.isSignupFlow &&
            this.selectedRuleType === AuditEmailConfigurationRuleType.DEFAULT &&
            (!this.rulesList || this.rulesList.length == 0)
        ) {
            this.getDefaultConfiguration();
        }

        this.addEmailConfigRuleButtonGenInput = {
            buttonName: 'Add',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.addEmailConfigRules();
            },
            customClass: 'add-button',
        };

        this.updateEmailConfigRuleButtonGenInput = {
            buttonName: 'Update',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.STROKED,
            disable: true,
            showLoader: true,
            loader: false,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.updateEmailConfigRules(buttonRef);
            },
            customClass: 'update-button',
        };

        this.saveEmailConfigRuleButtonGenInput = {
            buttonName: 'Save Configuration',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            showLoader: true,
            loader: false,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.saveEmailConfigRules(buttonRef);
            },
            customClass: 'save-email-config-button',
        };

        this.submitButtonGenInputSignupFlow = {
            buttonName: 'Submit',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            showLoader: true,
            loader: false,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.saveEmailConfigRulesForSignup(buttonRef, false);
            },
        };

        this.backButtonGenInputSignupFlow = {
            buttonName: 'Back',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.STROKED,
            showLoader: true,
            loader: false,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.saveEmailConfigRulesForSignup(buttonRef, true);
            },
        };
    }

    // Function call on clicking + button
    public handleClickPlusRuleButton() {
        this.updateEmailConfigRuleButtonGenInput.disable = true;
        this.addEmailConfigRuleButtonGenInput.disable = false;
        this.isInEditState = false;
        this.emailRulesConfigFormGroup.reset();
    }

    // Function calls on add button click
    private addEmailConfigRules() {
        if (
            this.emailRulesConfigFormGroup &&
            this.emailRulesConfigFormGroup.invalid
        ) {
            Helper.markAllFieldAsTouched(this.emailRulesConfigFormGroup);
            return;
        }
        Helper.markAllFieldAsTouched(this.emailRulesConfigFormGroup);
        if (
            !this.validateRepeatedRuleName(
                this.emailRulesConfigFormGroup.value?.ruleName,
                false
            )
        ) {
            return;
        }
        const emailRuleConfigFormValue = this.emailRulesConfigFormGroup.value;
        this.prepareEmailRuleInput(emailRuleConfigFormValue);
        this.updateEmailConfigRuleButtonGenInput.disable = true;
        this.emailConfigService.customUserRoles.clear();
        this.emailRulesConfigFormGroup.reset();
        this.isUnsavedChanges = true;
        this.isInEditState = false;
    }

    // Everytime function calls, when there is need to update or add entry in rulesList variable
    private prepareEmailRuleInput(
        emailRuleConfigFormValue,
        index?: number
    ): void {
        const isUpdatingInList = index !== undefined; // Indicates are we changing an entry already being displayed to user
        let {
            blindCarbonCopy,
            carbonCopy,
            emailBCC,
            emailCC,
            emailTemplateId,
            emailTo,
            recipients,
            ruleName,
            triggerPoints,
        } = emailRuleConfigFormValue;
        emailBCC = emailBCC || [];
        emailCC = emailCC || [];
        emailTo = emailTo || [];
        blindCarbonCopy = blindCarbonCopy || [];
        carbonCopy = carbonCopy || [];
        recipients = recipients || [];

        blindCarbonCopy = blindCarbonCopy.filter(
            (bcc: EmailConfigurationUserRolesEnum) =>
                Object.values(EmailConfigurationUserRolesEnum).includes(bcc)
        );
        carbonCopy = carbonCopy.filter((cc: EmailConfigurationUserRolesEnum) =>
            Object.values(EmailConfigurationUserRolesEnum).includes(cc)
        );
        recipients = recipients.filter((to: EmailConfigurationUserRolesEnum) =>
            Object.values(EmailConfigurationUserRolesEnum).includes(to)
        );

        if (this.isEdit) {
            let patchData: Partial<AuditEmailConfigRuleItemType> = {
                ruleName,
                triggerPoints,
                recipients: [...recipients, ...emailTo],
                carbonCopy: [...carbonCopy, ...emailCC],
                blindCarbonCopy: [...blindCarbonCopy, ...emailBCC],
                emailTemplateId,
                type: AuditEmailConfigurationRuleType.CUSTOM,
                auditId: this.auditId,
            };
            if (isUpdatingInList) {
                const currentItemEntry = this.rulesList[index];
                if (
                    currentItemEntry.action === AuditEmailConfigActionType.ADD
                ) {
                    patchData.action = AuditEmailConfigActionType.ADD; // If a rule is unsaved and we try to edit it, keeping action as ADD
                } else {
                    patchData.action = AuditEmailConfigActionType.UPDATE; // If a rule is edited adding UPDATE action inside
                }
                this.rulesList[index] = { ...currentItemEntry, ...patchData };
            } else {
                patchData.action = AuditEmailConfigActionType.ADD;
                this.rulesList.push(patchData as AuditEmailConfigRuleItemType);
            }
        } else {
            const allVariables: AuditEmailConfigRuleItemType = {
                ruleName,
                triggerPoints,
                recipients: [...recipients, ...emailTo],
                carbonCopy: [...carbonCopy, ...emailCC],
                blindCarbonCopy: [...blindCarbonCopy, ...emailBCC],
                emailTemplateId,
                type: AuditEmailConfigurationRuleType.CUSTOM,
                action: AuditEmailConfigActionType.ADD, // In this case ACTION will always be ADD
            };
            if (isUpdatingInList) {
                this.rulesList[index] = allVariables;
            } else {
                this.rulesList.push(allVariables);
            }
        }
    }

    // function calls on Remove Icon, and will remove the entry from Rules List.
    public removeEmailConfigRule(index: number) {
        if (this.isInEditState && this.currentRuleConfigIndex === index) {
            return;
        }
        const rule = this.rulesList[index];
        if (rule.action !== AuditEmailConfigActionType.ADD) {
            // on action type ADD we don't have to delete from BE
            this.rulesToBeDeletedList.push(rule);
        }
        this.isUnsavedChanges = true;
        this.rulesList.splice(index, 1);
    }

    // This function will call upon click in Edit sate, whenever user click on Any created rule.
    public editEmailRuleConfig(index: number) {
        this.updateEmailConfigRuleButtonGenInput.disable = false;
        this.addEmailConfigRuleButtonGenInput.disable = true;
        this.isInEditState = true;
        this.timeoutId = setTimeout(() => {
            this.currentRuleConfigIndex = index;
            const emailConfigRule = this.rulesList[index];
            const formGroupValue: AuditEmailConfigRuleItemType =
                this.seprateEmail(emailConfigRule);
            this.emailRulesConfigFormGroup.setValue(formGroupValue);
            this.emailRulesConfigFormGroup.markAllAsTouched();
            this.cdref.detectChanges();
        }, 0);
    }

    // This function is calls every time while preparing input for formgroup,
    // as it will be separating out the emails from the entered field, so that it will be passed to form group.
    private seprateEmail(emailConfigRuleValue): AuditEmailConfigRuleItemType {
        const {
            blindCarbonCopy = [],
            carbonCopy = [],
            emailTemplateId,
            recipients = [],
            ruleName,
            triggerPoints,
        }: AuditEmailConfigRuleItemType = emailConfigRuleValue;

        const emailBCC =
            blindCarbonCopy?.filter((bcc) => bcc.includes('@')) || [];
        const emailTo = recipients?.filter((to) => to.includes('@')) || [];
        const emailCC = carbonCopy?.filter((cc) => cc.includes('@')) || [];

        const allVariables = {
            blindCarbonCopy,
            carbonCopy,
            emailTemplateId,
            recipients,
            ruleName,
            emailBCC,
            emailTo,
            emailCC,
        };
        if (!this.isSignupFlow) {
            allVariables['triggerPoints'] = triggerPoints;
        }
        return allVariables;
    }

    // Function calls everytime user clicks on Update Button.
    private updateEmailConfigRules(buttonRef: IButtonGeneratorInput) {
        if (
            this.emailRulesConfigFormGroup &&
            this.emailRulesConfigFormGroup.invalid
        ) {
            Helper.markAllFieldAsTouched(this.emailRulesConfigFormGroup);
            return;
        }
        Helper.markAllFieldAsTouched(this.emailRulesConfigFormGroup);
        if (
            !this.validateRepeatedRuleName(
                this.emailRulesConfigFormGroup.value?.ruleName,
                true
            )
        ) {
            return;
        }
        this.prepareEmailRuleInput(
            this.emailRulesConfigFormGroup.value,
            this.currentRuleConfigIndex
        );

        // Reseting forms and Clearing out the things
        this.emailRulesConfigFormGroup.reset();
        this.addEmailConfigRuleButtonGenInput.disable = false;
        this.updateEmailConfigRuleButtonGenInput.disable = true;
        this.isInEditState = false;
        this.emailConfigService.customUserRoles.clear();
        clearTimeout(this.timeoutId);
        this.isUnsavedChanges = true;
        this.cdref.detectChanges();
    }

    // Validate and return false if any repeated rule name is used
    private validateRepeatedRuleName(
        additionalName?: string,
        isUpdate?: boolean
    ): boolean {
        const allRulesList = this.rulesList.map((el) =>
            el.ruleName.toLowerCase()
        );
        if (!isUpdate && additionalName.length) {
            allRulesList.push(additionalName?.toLowerCase());
        } else if (isUpdate && additionalName.length) {
            allRulesList.splice(
                this.currentRuleConfigIndex,
                1,
                additionalName?.toLowerCase()
            );
        }
        const allRulesSet = new Set(allRulesList);
        if (allRulesList.length > allRulesSet.size) {
            const modalData: IConfirmationModal = {
                modalName: 'Validation Error',
                confirmationMessage: `The rule name must be unique`,
                buttonText: 'OK',
                buttonColorType: ButtonColorType.PRIMARY,
                hideCancelButton: true,
                loaderOnExec: true,
                function: (modalId: Symbol) => {
                    this.modalService.closeModal(null, modalId);
                },
                modalWidthVw: 40,
                modalHeightVh: 30,
            };
            this.modalService.openConfirmationModal(modalData);
            return false;
        }
        return true;
    }

    // This function calls whenever user clicks on Save Configuration Button.
    private saveEmailConfigRules(buttonref: IButtonGeneratorInput) {
        this.emailConfigService.emailConfigRules.clear();
        if (!this.isUnsavedChanges) {
            return;
        }

        const inputToBeAdded = this.rulesList
            .filter((el) => el.action === AuditEmailConfigActionType.ADD)
            .map((el) => {
                const rule = { ...el };
                delete rule.action;
                return rule;
            });
        const inputToBeUpdated = this.rulesList
            .filter((el) => el.action === AuditEmailConfigActionType.UPDATE)
            .map((el) => {
                const rule = { ...el };
                delete rule.action;
                return rule;
            });
        const inputToBeDeletedRuleIdList = this.rulesToBeDeletedList.map(
            (el) => el.ruleId
        );
        if (this.isEdit) {
            buttonref.loader = true;

            forkJoin([
                this.emailConfigService.updateRulesInParallel(inputToBeUpdated),
                this.emailConfigService.addNewRulesInParallel(inputToBeAdded),
                this.emailConfigService.deleteRulesInParallel(
                    inputToBeDeletedRuleIdList
                ),
            ]).subscribe({
                next: () => {
                    buttonref.loader = false;
                    this.widgetRef.notificationsService.showSnackBar(
                        'Email Configuration saved successfully',
                        false,
                        '',
                        {
                            duration: 3000,
                        }
                    );
                    this.modalService.closeModal(null, this.modalData.modalId);
                },
                error: () => {
                    buttonref.loader = false;
                    this.widgetRef.notificationsService.showSnackBar(
                        'Unable to update email configuration',
                        true,
                        '',
                        {
                            duration: 3000,
                        }
                    );
                },
            });
        } else {
            this.emailConfigService.emailConfigRules.set(
                this.selectedRuleType,
                inputToBeAdded
            );
            this.emailConfigService.selectedRuleConfig = this.selectedRuleType;
            buttonref.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'Email Configuration saved successfully',
                false,
                '',
                {
                    duration: 3000,
                }
            );
            this.modalService.closeModal(null, this.modalData.modalId);
        }
    }

    private saveEmailConfigRulesForSignup(
        buttonRef: IButtonGeneratorInput,
        isGoBackSignup?: boolean
    ) {
        this.emailConfigService.emailConfigRules.clear();
        const inputs = this.rulesList.map((el) => {
            const rule = { ...el };
            delete rule.action;
            return rule;
        });
        if (
            this.multiStepFormService.stepData
                .get(this.modalData.modalId)
                .get(1)['signUpAction'] === CustomSignUpActionEnum.SEND_EMAIL &&
            this.rulesList.length < 1
        ) {
            this.modalService.openInfoModal({
                infoHeading: 'Alert',
                content: [
                    {
                        data: ['Set up at least one rule before proceeding.'],
                        type: 'PARAGRAPH',
                        listStyleType: 'none',
                    },
                ],
            });
            return;
        }
        this.emailConfigService.emailConfigRules.set(this.selectedRuleType, [
            ...inputs,
        ]);
        this.emailConfigService.selectedRuleConfig = this.selectedRuleType;
        if (isGoBackSignup) {
            this.multiStepFormService.previousStep(this.modalData.modalId);
        } else {
            this.handleSubmit(
                buttonRef,
                this.modalData.data?.['formDataSignUp'],
                this.modalData.data?.['action']
            );
        }
    }

    // This function trigger whenever the radio button selection changes.
    private changeSelectedRuleType(value: AuditEmailConfigurationRuleType) {
        this.selectedRuleType = value;
        if (value === AuditEmailConfigurationRuleType.CUSTOM) {
            this.rulesList = [];
        } else if (value === AuditEmailConfigurationRuleType.DEFAULT) {
            // rules already present in custom rulesList will be deleted
            this.rulesToBeDeletedList = this.rulesToBeDeletedList.concat(
                this.rulesList.filter(
                    (el) => !(el.action === AuditEmailConfigActionType.ADD)
                )
            );
            this.getDefaultConfiguration();
        }
    }

    public handleRuleSelectionChange(event) {
        event.preventDefault();
        event.stopPropagation();
        const modalData: IConfirmationModal = {
            modalName: 'Change Configuration Type',
            confirmationMessage: `Would you like to change the configuration type? If you switch, the existing rules will be deleted.`,
            buttonText: 'Confirm',
            buttonColorType: ButtonColorType.PRIMARY,
            hideCancelButton: true,
            loaderOnExec: true,
            function: (modalId: Symbol) => {
                const target = event.target as HTMLElement;
                const radioButton = target.closest('mat-radio-button');

                if (radioButton) {
                    const value = radioButton.getAttribute(
                        'value'
                    ) as AuditEmailConfigurationRuleType;
                    this.changeSelectedRuleType(value);
                    this.emailRulesConfigFormGroup.reset();
                    this.modalService.closeModal(null, modalId);
                    this.addEmailConfigRuleButtonGenInput.disable = false;
                    this.updateEmailConfigRuleButtonGenInput.disable = true;
                    this.cdref.detectChanges();
                }
            },
            modalWidthVw: 40,
            modalHeightVh: 30,
        };
        this.modalService.openConfirmationModal(modalData);
    }

    // This function is responsible to return the result in id lable form
    // for the emailTemplateName dropdown field, in the emailconfig form
    private parseEmailTemplates(cb) {
        this.emailparseloader = true;
        Promise.all([
            this.getEmailTemplates(),
            ...(this.isSignupFlow ? [] : [this.getEmailTriggerPoints()]),
        ])
            .then(([emailTemplates, triggerPoints]) => {
                this.emailparseloader = false;
                const apiResponses = {
                    emailTemplates: emailTemplates,
                    triggerPoints: triggerPoints,
                };
                cb(apiResponses, null);
            })
            .catch((error) => {
                this.emailparseloader = false;
                cb(null, error);
            });
    }

    // Function to get the default email configuration
    private getDefaultConfiguration() {
        const apiArgs: IHitApi = {
            url: ApiUrls.GET_DEFAULT_EMAIL_CONFIG_RULES,
            requestType: RequestType.GET,
            input: {},
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
            },
            function: (data: AuditEmailConfigRuleItemType[]) => {
                this.rulesList = data;
                this.rulesList = this.rulesList.map((rule) => {
                    rule.action = AuditEmailConfigActionType.ADD;
                    if (this.auditId) {
                        rule.auditId = this.auditId;
                    } else {
                        delete rule.auditId;
                    }
                    return rule;
                });
                this.isUnsavedChanges = true;
            },
            errorFunction: (error) => {
                // In case of error, setting default rule with some of the default configuration that will trigger the email config.
                this.rulesList = [
                    {
                        ruleName: 'Default Rule',
                        triggerPoints: [
                            'assessor_submit_approve',
                            'assessor_submit_reject',
                            'assessee_submit',
                        ],
                        recipients: ['assessor', 'assesee'],
                        carbonCopy: ['program_cordinator'],
                        blindCarbonCopy: [],
                        emailTemplateId: 'c3fd3f47-b56d-430b-b3f9-909ea9bf63c8',
                    },
                ];
                this.isUnsavedChanges = true;
            },
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    // Function that will return the email template name in ID, Label Format.
    private getEmailTemplates(): Promise<IDropdownData[]> {
        return new Promise((resolve, reject) => {
            let emailTemplates: IDropdownData[] = [];
            const apiArgs = Helper.generateHitApiConfig(
                this.isSignupFlow
                    ? this.listHttpService.emailTemplatesForSignupConfig
                    : this.listHttpService.emailTemplates
            );
            apiArgs.input = {};
            apiArgs.function = (emailTemplatesResponse) => {
                emailTemplatesResponse.forEach((emailTemplateResponse) => {
                    if (
                        emailTemplateResponse &&
                        emailTemplateResponse['templateName']
                    ) {
                        let emailTemplateData: IDropdownData = {
                            id: emailTemplateResponse['id'],
                            label: emailTemplateResponse['templateName'],
                        };
                        if (
                            !this.isSignupFlow ||
                            (this.isSignupFlow &&
                                emailTemplateResponse['configuredFor'] ===
                                    EmailTemplateConfiguredFor.SIGNUPCONFIG)
                        ) {
                            emailTemplates.push(emailTemplateData);
                        }
                    }
                });
                resolve(emailTemplates);
            };
            apiArgs.errorFunction = (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error
                );
                reject(error);
            };
            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        });
    }

    // Function which hit api for trigger points and return the response
    // if api fails then return the default trigger points.
    private getEmailTriggerPoints(): Promise<IDropdownData[]> {
        return new Promise((resolve, reject) => {
            let emailTriggerPoints: IDropdownData[] = [];
            const apiArgs = Helper.generateHitApiConfig(
                this.listHttpService.emailTriggerPoints
            );
            apiArgs.input = {};
            apiArgs.function = (response: IDropdownData[]) => {
                emailTriggerPoints = response;
                resolve(emailTriggerPoints);
            };
            apiArgs.errorFunction = () => {
                emailTriggerPoints = this.defaultTriggerPoints();
                resolve(emailTriggerPoints);
            };
            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        });
    }

    // Function will return the default trigger point if the api to fetch
    // trigger point fails to fetch response.
    private defaultTriggerPoints(): IDropdownData[] {
        const triggerPoints = [
            {
                id: 'issue',
                label: 'Issued',
                group: 'Assessment Status',
            },
            {
                id: 'in_progress',
                label: 'In Progress',
                group: 'Assessment Status',
            },
            {
                id: 'edit_assessment',
                label: 'Edit Assessment',
                group: 'Assessment Status',
            },
            {
                id: 'assessor_submit_approve',
                label: 'Assessor Approved',
                group: 'Assessment Status',
            },
            {
                id: 'assessor_submit_reject',
                label: 'Assessor Rejected',
                group: 'Assessment Status',
            },
            {
                id: 'assessee_submit',
                label: 'Assesee Submited',
                group: 'Assessment Status',
            },
            {
                id: 'met',
                label: 'Met',
                group: 'Control Points Status',
            },
            {
                id: 'open_actionable_item',
                label: 'Open Actionable Item',
                group: 'Control Points Status',
            },
            {
                id: 'not_met',
                label: 'Not Met',
                group: 'Control Points Status',
            },
        ];
        return triggerPoints;
    }
}
