import { Component, OnInit } from '@angular/core';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { RequestWidgetTemplateModalComponent } from '../../widget-creation-modal/request-widget-template-modal/request-widget-template-modal.component';

@Component({
    selector: 'app-widget-documentation-info-modal',
    templateUrl: './widget-documentation-info-modal.component.html',
    styleUrls: ['./widget-documentation-info-modal.component.sass']
})
export class WidgetDocumentationInfoModalComponent implements OnInit {
    loader: boolean = false;
    widgetRef: Widget;
    widgetId: string;
    infoData: any;
    loaderGroup = {
        description: false,
        detailedDescription: false
    };
    constructor(private modalInputData: ModalInjectedData) {
        this.widgetRef = modalInputData.data['widgetRef'];
        this.widgetId = modalInputData.data['widgetId'];
    }

    ngOnInit(): void {
        this.loader = true;
        const hitApi: IHitApi = {
            url: ApiUrls.WIDGET_DOCUMENTATION_MORE_INFO.replace(
                '{widgetId}',
                this.widgetId
            ),
            intactUrl: ApiUrls.WIDGET_DOCUMENTATION_MORE_INFO,
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (response) => {
                this.infoData = response.cmsWidgetInfo;
                this.loader = false;
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    'Error getting data.'
                );
            },
            input: {}
        };

        new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    openDescription() {
        if (
            this.loaderGroup.description === true ||
            this.loaderGroup.detailedDescription === true
        ) {
            return;
        }

        this.loaderGroup.description = true;
        const hitApi: IHitApi = {
            url: ApiUrls.WIDGET_DOCUMENTATION_DRAFT_DESCRIPTION.replace(
                '{widgetId}',
                this.widgetId
            ),
            intactUrl: ApiUrls.WIDGET_DOCUMENTATION_DRAFT_DESCRIPTION,
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (response) => {
                this.loaderGroup.description = false;

                const openModal = (templateData) => {
                    const modalData: IModalData = {
                        modalName: this.infoData.name,
                        modalIcon: null,
                        modalType: ModalType.MIDDLE,
                        sourceId: this.widgetRef.uniqueIdentity,
                        modalWidthVw: 75,
                        modalAutoHeight: true,
                        hideSteps: true,
                        disableClose: true,
                        modalSteps: [
                            {
                                stepData: {
                                    componentToLoad:
                                        RequestWidgetTemplateModalComponent,
                                    payload: {
                                        data: {
                                            templateData,
                                            widgetRef: this.widgetRef,
                                            isEdit: false,
                                            isPreview: true,
                                            widgetId: this.widgetId,
                                            hideDescriptionHeader: true,
                                            hideRequestButton: true,
                                            isCMS: true
                                        }
                                    }
                                },
                                stepName: ''
                            }
                        ]
                    };
                    this.widgetRef.modalService.openModal(modalData);
                };

                if (
                    response &&
                    response['presignedUrl'] &&
                    response['presignedUrl']['descriptionPresignedUrl']
                ) {
                    const hitApi: IHitApi = {
                        url: response['presignedUrl'][
                            'descriptionPresignedUrl'
                        ],
                        requestType: RequestType.GET,
                        uniqueIdentity: Symbol(),
                        config: {
                            authorization: AuthorizationType.NOT_AUTHORIZED,
                            ignoreBaseUrl: true,
                            responseType: 'text'
                        },

                        function: (res) => {
                            const templateData = {
                                widgetName: this.infoData.name,
                                description: Helper.convertS3ToDescription(
                                    Helper.convertMarkdownToHtml(res ? res : '')
                                )
                            };

                            openModal(templateData);
                        },
                        errorFunction: (error) => {
                            Helper.showErrorMessage(
                                this.widgetRef.notificationsService,
                                null,
                                'No description found with this widget'
                            );
                        },
                        input: {}
                    };

                    new HitApi(
                        hitApi,
                        this.widgetRef.httpService,
                        this.widgetRef.ngZone
                    ).hitApi();
                } else if (response && response['oldDescription']) {
                    const templateData = {
                        widgetName: this.infoData.name,
                        description: response['oldDescription']
                    };
                    openModal(templateData);
                } else {
                    Helper.showErrorMessage(
                        this.widgetRef.notificationsService,
                        null,
                        'No description found with this widget'
                    );
                }
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    'Error getting data.'
                );
            },
            input: {}
        };

        new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    openMoreInfo() {
        if (
            this.loaderGroup.description === true ||
            this.loaderGroup.detailedDescription === true
        ) {
            return;
        }

        this.loaderGroup.detailedDescription = true;
        const hitApi: IHitApi = {
            url: ApiUrls.WIDGET_DOCUMENTATION_DRAFT_DETAILED_DESCRIPTION.replace(
                '{widgetId}',
                this.widgetId
            ),
            intactUrl: ApiUrls.WIDGET_DOCUMENTATION_DRAFT_DETAILED_DESCRIPTION,
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (response) => {
                const showError = () => {
                    Helper.showErrorMessage(
                        this.widgetRef.notificationsService,
                        null,
                        'No more information found with this widget'
                    );
                };

                if (response && response['presignedUrl']) {
                    this.widgetRef.generateWidgetMoreInfoData(
                        response['presignedUrl']
                    );
                } else {
                    showError();
                }

                this.loaderGroup.detailedDescription = false;
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    'Error getting data.'
                );
            },
            input: {}
        };

        new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
