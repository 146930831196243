import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { ControlValueNotInValidator } from 'src/app/shared/validators/ControlValueNotInValidator';

@Component({
    selector: 'app-acl-modal-step-one',
    templateUrl: './acl-modal-step-one.component.html',
    styleUrls: ['./acl-modal-step-one.component.sass']
})
export class AclModalStepOneComponent implements OnInit {
    widgetRef: Widget;
    isEdit: boolean;
    stepData: any;
    aclNameList: string[];

    stepOneFormGenInput: IFormGeneratorInput;
    stepOneFormGroup: FormGroup;

    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };

    nextButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Next',
        buttonType: ButtonType.FLAT,
        buttonColorType: ButtonColorType.PRIMARY,
        function: () => {
            this.nextStep();
        }
    };

    constructor(
        private modalInjectedData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService
    ) {
        this.widgetRef = modalInjectedData.data['widgetRef'];
        this.isEdit = modalInjectedData.data['isEdit'];
        this.aclNameList = modalInjectedData.data['aclNameList'];
        if (
            multiStepFormService.stepData.get(modalInjectedData.modalId).has(1)
        ) {
            this.stepData = multiStepFormService.stepData
                .get(modalInjectedData.modalId)
                .get(1);
        } else if (modalInjectedData.data['stepData']) {
            this.stepData = modalInjectedData.data['stepData'];
        }
    }

    ngOnInit(): void {
        this.stepOneFormGenInput = {
            formName: this.isEdit ? 'Edit ACL' : 'Create ACL',
            state: this.isEdit ? FormState.EDIT : FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'ACL Name',
                    fieldType: FilterType.TEXT,
                    name: 'name',
                    placeholder: 'ACL Name...',
                    required: true,
                    disabled: this.isEdit,
                    value: this.stepData ? this.stepData['name'] : null,
                    appearance: 'legacy',
                    showLabel: true,
                    validations: [
                        {
                            errorMessage: 'ACL name should be unique.',
                            validator: new ControlValueNotInValidator(
                                this.aclNameList,
                                true
                            )
                        },
                        {
                            errorMessage: 'ACL name is required',
                            validator: CustomValidators.required
                        }
                    ]
                },
                {
                    label: 'Description',
                    fieldType: FilterType.TEXT,
                    name: 'description',
                    placeholder: 'Description...',
                    required: false,
                    value: this.stepData ? this.stepData['description'] : null,
                    appearance: 'legacy',
                    showLabel: true
                }
            ]
        };
    }

    nextStep() {
        if (this.stepOneFormGroup) {
            if (this.stepOneFormGroup.invalid) {
                Helper.markAllFieldAsTouched(this.stepOneFormGroup);
                this.stepOneFormGroup.updateValueAndValidity();
                return;
            }
            const firstStepData = Helper.cloneDeep(
                this.stepOneFormGroup.getRawValue()
            );
            this.multiStepFormService.stepData
                .get(this.modalInjectedData.modalId)
                .set(1, firstStepData);
            this.multiStepFormService.nextStep(this.modalInjectedData.modalId);
        }
    }
}
