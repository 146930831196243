import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { CustomValidator } from '../../classes/CustomValidator';

@Pipe({
    name: 'formErrorValidation'
})
export class FormErrorValidationPipe implements PipeTransform {
    transform(
        definedErrors: {
            errorMessage: string;
            validator: CustomValidator;
        }[],
        existingError: ValidationErrors
    ): string {
        let errorMessage = null;
        if (existingError && definedErrors && definedErrors.length > 0) {
            const error = Object.keys(existingError)[0];
            definedErrors.every((err) => {
                if (err.validator.key === error) {
                    errorMessage =
                        err.validator.customErrorMessage ?? err.errorMessage;
                    return false;
                }
                return true;
            });
        }
        return errorMessage;
    }
}
