import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { HitApi } from './../../../../classes/HitApi';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';
import { FilterStoreKey } from './../../../../enums/FilterStoreKey';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';

@Component({
    selector: 'app-automation-modal-scheduler',
    templateUrl: './automation-modal-scheduler.component.html',
    styleUrls: ['./automation-modal-scheduler.component.sass']
})
export class AutomationModalSchedulerComponent implements OnInit {
    widgetRef: Widget = null;
    edit = false;
    automationData = null;

    widgetFilters: {
        heading: string;
        filters: string[];
    }[] = null;

    frequencyFormGenInput: IFormGeneratorInput = null;
    frequencyFormGroup: FormGroup = null;

    dateFormGenInput: IFormGeneratorInput = null;
    dateFormGroup: FormGroup = null;

    timeFormGenInput: IFormGeneratorInput = null;
    timeFormGroup: FormGroup = null;
    previousStartDate: string;

    hourlyFormGenInput: IFormGeneratorInput = null;
    hourlyFormGroup: FormGroup = null;

    dailyFormGenInput: IFormGeneratorInput = null;
    dailyFormGroup: FormGroup = null;

    weeklyFormGenInput: IFormGeneratorInput = null;
    weeklyFormGroup: FormGroup = null;

    monthlyFormGenInput: IFormGeneratorInput = null;
    monthlyFormGroup: FormGroup = null;

    timeZoneFormGenInput: IFormGeneratorInput = null;
    timeZoneFormGroup: FormGroup = null;
    timeZoneValue: string;

    createLoader = false;

    backButton: IButtonGeneratorInput = {
        buttonName: 'Back',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.RAISED,
        function: () => {
            this.multiStepFormService.previousStep(this.modalInputData.modalId);
        }
    };

    createUpdateButton: IButtonGeneratorInput;
    nextStepButton: IButtonGeneratorInput = {
        buttonName: 'Next',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.RAISED,
        function: () => {
            this.nextStep();
        }
    };

    constructor(
        public multiStepFormService: MultiStepFormService,
        public modalInputData: ModalInjectedData,
        private listHttpService: ListHttpService
    ) {}

    ngOnInit(): void {
        this.widgetRef = this.modalInputData.data['widgetRef'];
        this.widgetFilters =
            this.widgetRef.widgetData.widgetInfo.schedulingAutomation[
                'widgetFilters'
            ];
        let cronExpression = null;
        let endDate = null;
        let startDate = null;
        let scheduleTime = null;
        if (this.modalInputData.data.stepData) {
            this.edit = true;
            this.automationData = this.modalInputData.data.stepData;
            cronExpression = this.automationData['cronExpression'];
            this.timeZoneValue =
                this.automationData['automationFilter']['timeZoneId'];
            endDate =
                this.automationData['automationFilter']['endDateForSchedular'];
            startDate =
                this.automationData['automationFilter'][
                    'startDateForSchedular'
                ];
            scheduleTime =
                this.automationData['automationFilter']['scheduleTime'];
            if (scheduleTime) {
                scheduleTime = Helper.formatDate(scheduleTime, 'hh:mm a');
            }
        }

        if (
            this.multiStepFormService.stepData.get(
                this.modalInputData.modalId
            ) &&
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(2)
        ) {
            const stepData = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(2);
            cronExpression = stepData['cronExpression'];
            endDate = stepData['endDate'];
            startDate = stepData['startDate'];
            scheduleTime = stepData['scheduleTime'];
            if (scheduleTime) {
                scheduleTime = Helper.formatDate(scheduleTime, 'hh:mm a');
            }
            this.timeZoneValue = stepData['timeZoneId'];
        }

        this.createUpdateButton = {
            buttonName: this.edit ? 'Update' : 'Create',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.RAISED,
            showLoader: true,
            function: (buttonRef) => {
                this.createRule(buttonRef);
            }
        };

        // Breaking cron expression
        let frequency = '';
        const cronParts = cronExpression ? cronExpression.split(' ') : null;

        // Hourly: 0 0 0/23 * * ? *
        let hour = 0;
        let minutes = 0;

        // Daily: 0 20 12 1/20 * ? *
        let dailyDay = null;

        // Weekly: 0 15 10 ? * 1,2 *
        let weeklyDays = [];

        // Monthly: 0 30 11 1,2,3,4,29,30 1/9 ? *
        let monthlyDays = [];
        let monthlyMonth = null;

        if (
            cronParts &&
            +cronParts[0] === 0 &&
            (cronParts[2] === '*' || cronParts[2].includes('/')) &&
            cronParts[3] === '*' &&
            cronParts[4] === '*' &&
            cronParts[5] === '?' &&
            cronParts[6] === '*'
        ) {
            frequency = 'hourly';
            if (cronParts[2] === '*') {
                hour = 0;
            } else {
                hour = +cronParts[2].split('/')[1];
            }
        } else if (
            cronParts &&
            +cronParts[0] === 0 &&
            (cronParts[3] === '*' || cronParts[3].includes('/')) &&
            cronParts[4] === '*' &&
            cronParts[5] === '?' &&
            cronParts[6] === '*'
        ) {
            // 0 0 10 * * ? *
            frequency = 'daily';
            if (cronParts[3] === '*') {
                dailyDay = 1;
            } else {
                dailyDay = +cronParts[3].split('/')[1];
            }
            hour = +cronParts[2];
            minutes = +cronParts[1];
        } else if (
            cronParts &&
            +cronParts[0] === 0 &&
            cronParts[3] === '?' &&
            cronParts[4] === '*' &&
            cronParts[6] === '*'
        ) {
            frequency = 'weekly';
            weeklyDays = cronParts[5].split(',');
            hour = +cronParts[2];
            minutes = +cronParts[1];
        } else if (
            cronParts &&
            +cronParts[0] === 0 &&
            cronParts[5] === '?' &&
            cronParts[6] === '*'
        ) {
            // 0 0 1 1 * ? *
            frequency = 'monthly';
            if (cronParts[4] === '*') {
                monthlyMonth = 1;
            } else {
                monthlyMonth = +cronParts[4].split('/')[1];
            }
            monthlyDays = cronParts[3].split(',');
            hour = +cronParts[2];
            minutes = +cronParts[1];
        }
        this.frequencyFormGenInput = {
            formName: 'Frequency',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'frequency',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'Frequency',
                    placeholder: 'Frequency',
                    value: frequency ? frequency : 'hourly',
                    listData: [
                        {
                            id: 'hourly',
                            label: 'Hourly'
                        },
                        {
                            id: 'daily',
                            label: 'Daily'
                        },
                        {
                            id: 'weekly',
                            label: 'Weekly'
                        },
                        {
                            id: 'monthly',
                            label: 'Monthly'
                        }
                    ],
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Frequency is required'
                        }
                    ]
                }
            ]
        };

        const startDateValue = startDate ? startDate : moment().toDate();
        this.dateFormGenInput = {
            formName: 'Date',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'startDate',
                    fieldType: FilterType.DATE,
                    label: 'Start Date',
                    placeholder: 'Start Date',
                    value: startDateValue,
                    minDate: moment().toDate(),
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Start date is required'
                        }
                    ]
                },
                {
                    name: 'endDate',
                    fieldType: FilterType.DATE,
                    label: 'End Date',
                    placeholder: 'End Date',
                    minDateFromControl: 'startDate',
                    value: endDate ? endDate : moment().add(1, 'day').toDate(),
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'End date is required'
                        }
                    ]
                }
            ]
        };

        this.previousStartDate = startDateValue;
        if (!scheduleTime && this.edit) {
            scheduleTime = Helper.formatDate(
                `${hour}:${minutes}`,
                'hh:mm a',
                'HH:mm'
            );
        }
        this.setTimeFormGenInput(scheduleTime, startDateValue);

        this.hourlyFormGenInput = {
            formName: 'Frequency Hourly',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'every',
                    fieldType: FilterType.NUMBER,
                    label: 'Every',
                    placeholder: 'Hour(s)',
                    value: hour ? hour : null,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Hourly frequency is required'
                        },
                        {
                            validator: CustomValidators.maxValue(23),
                            errorMessage: 'Maximum value allowed is 23'
                        },
                        {
                            validator: CustomValidators.minValue(1),
                            errorMessage: 'Minimum value allowed is 1'
                        }
                    ]
                }
            ]
        };
        this.dailyFormGenInput = {
            formName: 'Frequency Daily',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'every',
                    fieldType: FilterType.NUMBER,
                    label: 'Every',
                    placeholder: 'Day(s)',
                    value: dailyDay ? dailyDay : null,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Daily frequency is required'
                        },
                        {
                            validator: CustomValidators.maxValue(31),
                            errorMessage: 'Maximum value allowed is 31'
                        },
                        {
                            validator: CustomValidators.minValue(1),
                            errorMessage: 'Minimum value allowed is 1'
                        }
                    ]
                }
            ]
        };

        this.weeklyFormGenInput = {
            formName: 'Frequency Daily',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'day',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    label: 'Day',
                    placeholder: 'Select day of week',
                    value: weeklyDays ? weeklyDays : [],
                    required: true,
                    listData: [
                        {
                            id: '2',
                            label: 'Monday'
                        },
                        {
                            id: '3',
                            label: 'Tuesday'
                        },
                        {
                            id: '4',
                            label: 'Wednesday'
                        },
                        {
                            id: '5',
                            label: 'Thursday'
                        },
                        {
                            id: '6',
                            label: 'Friday'
                        },
                        {
                            id: '7',
                            label: 'Saturday'
                        },
                        {
                            id: '1',
                            label: 'Sunday'
                        }
                    ],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Day of week is required'
                        }
                    ]
                }
            ]
        };

        this.monthlyFormGenInput = {
            formName: 'Frequency Daily',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'every',
                    fieldType: FilterType.NUMBER,
                    label: 'Every',
                    placeholder: 'Month(s)',
                    value: monthlyMonth ? monthlyMonth : null,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Monthly frequency is required'
                        },
                        {
                            validator: CustomValidators.maxValue(12),
                            errorMessage: 'Maximum value allowed is 12'
                        },
                        {
                            validator: CustomValidators.minValue(1),
                            errorMessage: 'Minimum value allowed is 1'
                        }
                    ]
                },
                {
                    name: 'dayOfMonth',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    label: 'Day',
                    placeholder: 'Select day of month',
                    value: monthlyDays ? monthlyDays : [],
                    required: true,
                    listData: Array(31)
                        .fill(1)
                        .map((value, index) => {
                            return {
                                id: `${index + 1}`,
                                label: `${index + 1}`
                            };
                        }),
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Day of month is required'
                        }
                    ]
                }
            ]
        };
        this.timeZoneFormGenInput = {
            formName: 'Time zone',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'timeZone',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'Time Zone',
                    placeholder: 'Select Timezone',
                    value: this.timeZoneValue ? this.timeZoneValue : null,
                    apiInfo: this.listHttpService.timeZoneApiInfo,
                    responseValueKey: 'dataList',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Time Zone is required'
                        }
                    ]
                }
            ]
        };
    }

    /**
     * This function calculates the rounded time + {threshold} minutes to the floor of minutes
     * Example: If Time: 1:43 PM then output will be
     * Output: 2:00 PM
     * @param roundValue Input for rounded of value
     * @returns return formatted time in 12 hours format
     */
    getRoundedMinutesTime(roundValue: number) {
        const timeMoment = moment();
        const minutesThreshold = 30;
        let currMinutes = timeMoment.minutes();
        if (currMinutes % roundValue !== 0) {
            currMinutes -= currMinutes % roundValue;
            currMinutes += minutesThreshold;
            timeMoment.minutes(currMinutes);
        }
        return timeMoment.format('hh:mm a');
    }

    setTimeFormGenInput(scheduleTime, startDate) {
        let minTime = null;
        const minutesGap = 15;
        const today = Helper.formatDate(null, 'DD/MM/YYYY');
        const dated = Helper.formatDate(startDate, 'DD/MM/YYYY');
        if (today === dated) {
            minTime = this.getRoundedMinutesTime(minutesGap);
        }
        this.timeFormGenInput = {
            formName: 'Time',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'scheduleTime',
                    fieldType: FilterType.TIME,
                    label: 'Schedule Time',
                    placeholder: 'Schedule Time',
                    value: scheduleTime
                        ? scheduleTime
                        : minTime
                        ? minTime
                        : '12:00 AM',
                    minTime: '12:00 AM',
                    timePickerMinutesGap: minutesGap,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Schedule time is required'
                        }
                    ]
                }
            ]
        };

        /**
         * Update form group value for only one condition.
         * form group time < min time
         */
        if (this.timeFormGroup) {
            const currentFormTime =
                this.timeFormGroup.get('scheduleTime').value;
            if (currentFormTime && minTime) {
                const currentTimeInMS = Helper.formatDate(
                    currentFormTime,
                    'unix',
                    'hh:mm a'
                );
                const minTimeInMS = Helper.formatDate(
                    minTime,
                    'unix',
                    'hh:mm a'
                );
                if (currentTimeInMS < minTimeInMS) {
                    this.timeFormGroup.get('scheduleTime').setValue(minTime);
                }
            }
        }
    }

    onDateChange(event) {
        if (event.startDate !== this.previousStartDate) {
            this.setTimeFormGenInput(null, event.startDate);
            this.previousStartDate = event.startDate;
        }
    }

    createRule(buttonRef) {
        if (buttonRef.loader) {
            return;
        }
        if (this.frequencyFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.frequencyFormGroup);
            return;
        }
        const frequency = this.frequencyFormGroup.get('frequency').value;
        if (frequency === 'hourly' && this.hourlyFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.hourlyFormGroup);
            return;
        } else if (frequency === 'daily' && this.dailyFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.dailyFormGroup);
            return;
        } else if (frequency === 'weekly' && this.weeklyFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.weeklyFormGroup);
            return;
        } else if (frequency === 'monthly' && this.monthlyFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.monthlyFormGroup);
            return;
        }
        if (this.dateFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.dateFormGroup);
            return;
        }
        if (this.timeFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.timeFormGroup);
            return;
        }
        if (this.timeZoneFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.timeZoneFormGroup);
            return;
        }
        buttonRef.loader = true;
        const createArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.create
        );
        const updateArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.update
        );
        if (this.automationData) {
            updateArgs.intactUrl = updateArgs.url;
            updateArgs.url = updateArgs.url.replace(
                '{id}',
                this.automationData['id']
            );
            updateArgs.url = updateArgs.url.replace(
                '{automation-id}',
                this.automationData['id']
            );
        }
        createArgs.input = this.generateRuleCreationInputs();
        updateArgs.input = this.generateRuleCreationInputs();
        createArgs.function = (response) => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'Rule created successfully'
            );
            this.widgetRef.refreshWidget();
            this.widgetRef.modalService.closeModal(
                null,
                this.modalInputData.modalId
            );
        };
        updateArgs.function = (response) => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'Rule updated successfully'
            );
            this.widgetRef.refreshWidget();
            this.widgetRef.modalService.closeModal(
                null,
                this.modalInputData.modalId
            );
        };
        createArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error creating rule'
            );
        };
        updateArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error updating rule'
            );
        };
        new HitApi(
            this.edit ? updateArgs : createArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    generateRuleCreationInputs() {
        const step1Data = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(1);
        const ruleInput = {
            automationName: step1Data.automationName,
            cronExpression: this.generateCronExpression(),
            automationFilter: {
                endDateForSchedular: this.dateFormGroup.get('endDate').value,
                startDateForSchedular:
                    this.dateFormGroup.get('startDate').value,
                scheduleTime: Helper.formatDate(
                    this.timeFormGroup.get('scheduleTime').value,
                    'unix',
                    'hh:mm a'
                ),
                notificationActive: false,
                notifyBefore: null,
                notifyType: null,
                timeZoneId: this.timeZoneFormGroup.get('timeZone')
                    ? this.timeZoneFormGroup.get('timeZone').value
                    : null
            },
            widgetFilter: this.widgetFilters.map((widgetFilter) => {
                const filtersData = Helper.dereference(widgetFilter);
                const filters = this.widgetRef.filterService.prepareInput(
                    widgetFilter.filters,
                    FilterStoreKey.AUTOMATION_FILTERS
                );
                filtersData.filters = filters;
                return filtersData;
            })
        };
        return ruleInput;
    }

    generateCronExpression() {
        // <seconds> <minutes> <hours> <days of month> <months> <days of week> <years>
        const sec = '0'; // ignoring seconds by default
        let min = '0'; // * means every minute, 0/4 means every 4 minutes
        let hour = '0';
        const year = '*'; // every year by default
        let dow = '?';
        let month = '*';
        let dom = '*';

        let startDD = '1';
        let startMM = '1';

        const scheduledTime = Helper.formatDate(
            this.timeFormGroup.get('scheduleTime').value,
            'HH:mm',
            'hh:mm a'
        );
        const startDate = Helper.formatDate(
            this.dateFormGroup.get('startDate').value,
            'DD/MM'
        );

        if (typeof scheduledTime === 'string') {
            const splittedTime = scheduledTime.split(':');
            hour = '' + +splittedTime[0];
            min = '' + +splittedTime[1];
        }

        if (startDate && typeof startDate === 'string') {
            const splittedDate = startDate.split('/');
            startDD = '' + +splittedDate[0];
            startMM = '' + +splittedDate[1];
        }

        switch (this.frequencyFormGroup.get('frequency').value) {
            case 'hourly':
                // ever n hour
                if (this.hourlyFormGroup.get('every').value >= 0) {
                    hour = hour + '/' + this.hourlyFormGroup.get('every').value;
                } else {
                    hour = '*';
                }
                break;
            case 'daily':
                if (true) {
                    // every n day
                    const ndom = this.dailyFormGroup.get('every').value;
                    if (ndom > 1) {
                        dom = startDD + '/' + ndom;
                    }
                }
                break;

            case 'weekly':
                const ndow = this.weeklyFormGroup.get('day').value;
                dow = '*';
                dom = '?';
                if (ndow.length < 7 && ndow.length > 0) {
                    dow = ndow.join(',');
                } else {
                    dow = ndow[0];
                }
                break;

            case 'monthly':
                month = '*';
                const nmonth = this.monthlyFormGroup.get('every').value; // 1 -12
                dom = this.monthlyFormGroup.get('dayOfMonth').value; // 1-31
                dow = '?';

                if (nmonth > 1) {
                    month = startMM + '/' + nmonth;
                }
                break;

            default:
                break;
        }
        return [sec, min, hour, dom, month, dow, year].join(' ');
    }

    nextStep() {
        if (this.frequencyFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.frequencyFormGroup);
            return;
        }
        const frequency = this.frequencyFormGroup.get('frequency').value;
        if (frequency === 'hourly' && this.hourlyFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.hourlyFormGroup);
            return;
        } else if (frequency === 'daily' && this.dailyFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.dailyFormGroup);
            return;
        } else if (frequency === 'weekly' && this.weeklyFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.weeklyFormGroup);
            return;
        } else if (frequency === 'monthly' && this.monthlyFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.monthlyFormGroup);
            return;
        }
        if (this.dateFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.dateFormGroup);
            return;
        }
        if (this.timeFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.timeFormGroup);
            return;
        }
        if (this.timeZoneFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.timeZoneFormGroup);
            return;
        }
        this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .set(2, {
                cronExpression: this.generateCronExpression(),
                endDate: this.dateFormGroup.get('endDate').value,
                startDate: this.dateFormGroup.get('startDate').value,
                scheduleTime: Helper.formatDate(
                    this.timeFormGroup.get('scheduleTime').value,
                    'unix',
                    'hh:mm a'
                ),
                timeZoneId: this.timeZoneFormGroup.get('timeZone')
                    ? this.timeZoneFormGroup.get('timeZone').value
                    : null
            });
        this.multiStepFormService.nextStep(this.modalInputData.modalId);
    }
}
