import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomValidator } from './../classes/CustomValidator';
import { CustomValidatorKey } from './../enums/CustomValidatorKey';
export class GcpKeyNumberValidator extends CustomValidator {
    key = CustomValidatorKey.GCP_NUMBER_VALIDATOR;
    validatorFunction(control: AbstractControl): ValidationErrors {
        const GCP_KEY_NUMBER_REGEX = new RegExp(
            /^[^0-9][a-z-0-9@!+_#$%^&?><={}()]*$/
        );

        if (GCP_KEY_NUMBER_REGEX.test(control.value)) {
            return null;
        }

        const obj = {};
        obj[this.key] = true;
        return obj;
    }
}
