import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { UpdateAction } from 'src/app/shared/enums/UpdateAction';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IUpdateAction } from 'src/app/shared/interfaces/update-action/IUpdateAction';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';

@Component({
    selector: 'app-assign-assessment',
    templateUrl: './assign-assessment.component.html',
    styleUrls: ['./assign-assessment.component.sass']
})
export class AssignAssessmentComponent implements OnInit {
    assignAssessmentFormGenInputs: IFormGeneratorInput;
    assignAssessmentFormGroup: FormGroup;
    emailContentFormGenInput: IFormGeneratorInput;
    emailContentFormGroup: FormGroup;
    loader: boolean = false;
    widgetRef: Widget;
    assessmentId: string;
    updateControlInput: IUpdateAction;
    template: string;
    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    modalLoader: boolean = false;
    assignAssessmentButtonGenInput: IButtonGeneratorInput;
    buttonRef: IButtonGeneratorInput;
    constructor(
        private modalData: ModalInjectedData,
        private configCacheService: ConfigCacheService,
        private listHttpService: ListHttpService,
        private notificationService: NotificationsService
    ) {
        this.widgetRef = this.modalData.data.widgetRef;
        this.assessmentId = this.modalData.data.assessmentId;
        this.template = this.configCacheService.assessorEmailTemplate;
    }

    ngOnInit(): void {
        this.generateForm();
        if (!this.template) {
            this.getAssessormEmailTemplate();
        } else {
            this.generateForm();
        }
        this.assignAssessmentButtonGenInput = {
            buttonName: 'Issue Assessment',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: (buttonRef) => {
                this.buttonRef = buttonRef;
                this.assign();
            },
            showLoader: true
        };
    }
    getAssessormEmailTemplate() {
        this.modalLoader = true;
        const apiArgs: IHitApi = {
            url: ApiUrls.ASSESSOR_EMAIL_TEMPLATE,
            requestType: RequestType.GET,
            input: {},
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (data) => {
                this.configCacheService.assessorEmailTemplate = data.id;
                this.template = this.configCacheService.assessorEmailTemplate;
                this.modalLoader = false;
                this.generateForm();
            },
            errorFunction: (error) => {
                this.modalLoader = false;
            }
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    generateForm() {
        this.assignAssessmentFormGenInputs = {
            formName: 'Assign Assessment',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'User',
                    placeholder: 'Select User',
                    name: 'user',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    getKey: null,
                    apiInfo: this.listHttpService.userListApi,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This field is required.'
                        }
                    ]
                },
                {
                    label: 'User Email Address',
                    placeholder: 'Select User',
                    name: 'emailId',
                    fieldType: FilterType.EMAIL,
                    required: true,
                    populateFromControl: 'user',
                    showKey: 'email',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email is required.'
                        }
                    ]
                }
            ]
        };
        this.emailContentFormGenInput = {
            formName: 'Email Body',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Email Body',
                    placeholder: 'Email Body',
                    name: 'emailBody',
                    fieldType: FilterType.WYSIWYG_EDITOR,
                    value: this.template,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This field is required.'
                        }
                    ]
                }
            ]
        };
    }
    assign() {
        Helper.markAllFieldAsTouched(this.assignAssessmentFormGroup);
        if (this.assignAssessmentFormGroup.invalid) {
            this.assignAssessmentFormGroup.updateValueAndValidity();
            return;
        } else {
            this.buttonRef.loader = true;
            const apiArgs: IHitApi = {
                url: ApiUrls.ASSIGN_ASSESSMENT_API,
                input: {
                    emailBody:
                        this.emailContentFormGroup.get('emailBody').value,
                    userId: this.assignAssessmentFormGroup.get('user').value[
                        'id'
                    ],
                    emailSubject: 'Assessment'
                },
                requestType: RequestType.POST,
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                },
                function: (data) => {
                    this.buttonRef.loader = false;
                    this.notificationService.showSnackBar(
                        'Assessment assigned successfully.'
                    );
                    this.widgetRef.modalService.closeModal(
                        null,
                        this.modalData.modalId
                    );
                    this.widgetRef.refreshWidget();
                },
                errorFunction: (error) => {
                    this.buttonRef.loader = false;
                    Helper.showErrorMessage(this.notificationService, error);
                    this.widgetRef.modalService.closeModal(
                        null,
                        this.modalData.modalId
                    );
                    this.widgetRef.refreshWidget();
                }
            };
            apiArgs.intactUrl = apiArgs.url;
            apiArgs.url = apiArgs.url.replace(
                '{widget-id}',
                this.widgetRef.widgetData.widgetId
            );
            apiArgs.url = apiArgs.url.replace(
                '{assessment-id}',
                this.assessmentId
            );
            new HitApi(
                apiArgs,
                this.widgetRef.widgetHttpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }
    userSelected(event) {
        if (event && event.user) {
            this.updateControlInput = {
                action: UpdateAction.UPDATE_VALUE,
                controls: ['emailBody'],
                value: this.template.replace('<User Name>', event.user.label)
            };
            this.updateControl.next(this.updateControlInput);
        }
    }
}
