import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';

@Component({
    selector: 'app-create-user-step-one',
    templateUrl: './create-user-step-one.component.html',
    styleUrls: ['./create-user-step-one.component.sass']
})
export class CreateUserStepOneComponent implements OnInit, AfterViewInit {
    userInfoFormGenInput: IFormGeneratorInput = null;
    userInfoFormGroup: FormGroup;
    edit: boolean = false;
    stepData: any;
    password: any;
    confirmPassword: any;
    emailValidations = [
        {
            validator: CustomValidators.required,
            errorMessage: 'Email is required'
        },
        {
            validator: CustomValidators.email,
            errorMessage: 'Email is invalid'
        }
    ];

    nextButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Next',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        function: null
    };

    constructor(
        private modalInputData: ModalInjectedData,
        private notificationService: NotificationsService,
        private listHttpService: ListHttpService,
        private ngZone: NgZone,
        private modalService: ModalService,
        private filtersService: FiltersService,
        private multiStepFormService: MultiStepFormService,
        private userDataCacheService: UserDataCacheService
    ) {}

    ngOnInit(): void {
        if (
            this.modalInputData &&
            this.modalInputData.data &&
            this.modalInputData.data.edit
        ) {
            this.edit = true;
            this.stepData = this.modalInputData.data.userData;
        }
        if (
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(1)
        ) {
            this.stepData = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1);
        }

        this.settingUpForms();
    }
    settingUpForms() {
        if (this.userDataCacheService.allowDomainBasedUsers) {
            this.emailValidations.push({
                validator: CustomValidators.checkDomainInEmail(
                    this.userDataCacheService.domainId
                ),
                errorMessage: `Email Address should have the Domain ID ${this.userDataCacheService.domainId} `
            });
        }
        this.userInfoFormGenInput = {
            formName: 'Create User',
            state: FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    label: 'Email Id',
                    placeholder: 'Email Id',
                    name: 'email',
                    value: this.stepData ? this.stepData.email : '',
                    fieldType: FilterType.EMAIL,
                    required: true,
                    disabled: this.edit,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'email'
                        },
                        hoverText: null
                    },
                    validations: this.emailValidations
                },
                {
                    label: 'First Name',
                    placeholder: 'First Name',
                    name: 'firstName',
                    value: this.stepData ? this.stepData.firstName : '',
                    fieldType: FilterType.TEXT,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'perm_identity'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'First name is required'
                        }
                    ]
                },
                {
                    label: 'Last Name',
                    placeholder: 'Last Name',
                    name: 'lastName',
                    value: this.stepData ? this.stepData.lastName : '',
                    fieldType: FilterType.TEXT,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'perm_identity'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Last name is required'
                        }
                    ]
                },
                {
                    label: 'Phone Number',
                    placeholder: 'Phone Number',
                    name: 'phoneNumber',
                    value: this.stepData ? this.stepData.phoneNumber : '',
                    fieldType: FilterType.NUMBER,
                    required: false,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'call'
                        },
                        hoverText: null
                    }
                },
                {
                    label: 'Select Views',
                    placeholder: 'Select Views',
                    name: 'userViewIds',
                    value: this.stepData ? this.stepData.userViewIds : [],
                    getKey: null,
                    apiInfo: this.listHttpService.userListViewsApiInfo,
                    showKey: 'name',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Views are required'
                        }
                    ]
                },
                {
                    label: 'Default View',
                    placeholder: 'Default View',
                    name: 'defaultView',
                    value: this.stepData ? this.stepData.defaultView : null,
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    populateFromControl: 'userViewIds',
                    showKey: 'name',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Default view is required'
                        }
                    ]
                }
            ]
        };
        if (!this.edit) {
            this.userInfoFormGenInput.fields.push({
                label: 'Password',
                placeholder: 'Password',
                name: 'password',
                fieldType: FilterType.PASSWORD,
                required: true,
                value: this.stepData ? this.stepData.password : '',
                prefixIcon: {
                    iconData: {
                        type: IconType.MATICON,
                        class: 'lock'
                    },
                    hoverText: null
                },
                suffixIcon: {
                    iconData: {
                        type: IconType.MATICON,
                        class: 'edit'
                    },
                    hoverText: 'Generate password',
                    function: (
                        buttonRef: IButtonData,
                        formGroup: FormGroup
                    ) => {
                        this.generatePassword(formGroup);
                    }
                },
                validations: Helper.getPasswordValidators()
            });

            this.userInfoFormGenInput.fields.push({
                label: 'Confirm Password',
                placeholder: 'Confirm password',
                name: 'confirmPassword',
                fieldType: FilterType.PASSWORD,
                required: true,
                value: this.stepData ? this.stepData.password : '',
                prefixIcon: {
                    iconData: {
                        type: IconType.MATICON,
                        class: 'lock'
                    },
                    hoverText: null
                },
                validations: Helper.getPasswordValidators('equal', 'password')
            });
        }
    }
    generatePassword(formGroup: FormGroup) {
        let password = Helper.generateUniqueKey(16);
        password += 'a@B1';
        formGroup.get('password').setValue(password);
        formGroup.get('confirmPassword').setValue(password);
    }
    save() {
        Helper.markAllFieldAsTouched(this.userInfoFormGroup);
        if (this.userInfoFormGroup.invalid) {
            this.userInfoFormGroup.updateValueAndValidity();
            return;
        } else {
            this.multiStepFormService.nextStep(this.modalInputData.modalId);
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .set(1, this.userInfoFormGroup.getRawValue());
        }
    }
    ngAfterViewInit() {}
}
