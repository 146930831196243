import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { SubWidgetInjectedData } from 'src/app/shared/classes/SubWidgetInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { FormGeneratorModalComponent } from 'src/app/shared/components/modal-templates/form-generator-modal/form-generator-modal.component';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IApiInfo } from 'src/app/shared/interfaces/api/IApiInfo';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';

@Component({
    selector: 'app-whitelabeling-domain',
    templateUrl: './whitelabeling-domain.component.html',
    styleUrls: ['./whitelabeling-domain.component.sass']
})
export class WhitelabelingDomainComponent implements OnInit, OnDestroy {
    resetObservable: Subject<string>;
    resetSubscription: Subscription;
    subWidgetId: string;
    parentWidgetRef: Widget;
    saveAsDraftApiInfo: IApiInfo;

    isDomainRegistered: boolean = false;
    whiteLabelingData: any;
    whiteLabelingDraft: any;

    formGenInput: IFormGeneratorInput;
    formGroupRef: FormGroup;

    reInitializeWhiteLabelData: Function;
    onFormGroupCreated: Function;

    readonly JSON_DOMAIN_CONFIG_KEY = 'domainConfig';

    readonly DOMAIN_NAME = 'domainName';
    readonly CERTIFICATE_BODY = 'certificateBody';
    readonly PRIVATE_KEY = 'privateKey';
    readonly CERTIFICATE_CHAIN = 'certificateChain';

    readonly CERTIFICATES_NOT_REQUIRED = 'certificateNotRequired';

    readonly inputButtonNames = {
        [this.CERTIFICATE_BODY]: 'Certificate',
        [this.PRIVATE_KEY]: 'Private Key',
        [this.CERTIFICATE_CHAIN]: 'Certificate Chain'
    };

    constructor(private subWidgetData: SubWidgetInjectedData) {
        this.subWidgetId = subWidgetData.subWidgetId;
        this.resetObservable = subWidgetData.resetObserver;
        this.parentWidgetRef = subWidgetData.parentWidgetRef;
        this.isDomainRegistered = subWidgetData.data.isDomainRegistered;
        this.whiteLabelingData = subWidgetData.data.whiteLabelingData;
        this.whiteLabelingDraft = subWidgetData.data.whiteLabelingDraft;
        this.saveAsDraftApiInfo = subWidgetData.data.saveAsDraftApiInfo;
        this.reInitializeWhiteLabelData =
            subWidgetData.data.reInitializeWhiteLabelData;
        this.onFormGroupCreated = subWidgetData.data.onFormGroupCreated;
    }

    ngOnInit(): void {
        this.initResetObservable();
        this.initFormGenInput();
    }

    initResetObservable() {
        if (this.resetObservable) {
            this.resetSubscription = this.resetObservable.subscribe(
                (targetId) => {
                    if (targetId === this.subWidgetId) {
                        if (this.formGroupRef) {
                            if (
                                this.whiteLabelingData &&
                                this.whiteLabelingData[
                                    this.JSON_DOMAIN_CONFIG_KEY
                                ]
                            ) {
                                this.resetFormToLastState();
                            } else {
                                this.formGroupRef.reset();
                            }
                        }
                    }
                }
            );
        }
    }

    initDraftData() {
        this.whiteLabelingDraft[this.DOMAIN_NAME] = this.whiteLabelingData[
            this.DOMAIN_NAME
        ]
            ? this.whiteLabelingData[this.DOMAIN_NAME]
            : null;

        this.whiteLabelingDraft[this.CERTIFICATE_BODY] = null;
        this.whiteLabelingDraft[this.PRIVATE_KEY] = null;
        this.whiteLabelingDraft[this.CERTIFICATE_CHAIN] = null;
        if (this.whiteLabelingData[this.JSON_DOMAIN_CONFIG_KEY]) {
            if (
                this.whiteLabelingData[this.JSON_DOMAIN_CONFIG_KEY][
                    this.CERTIFICATE_BODY
                ]
            ) {
                this.whiteLabelingDraft[this.CERTIFICATE_BODY] =
                    this.whiteLabelingData[this.JSON_DOMAIN_CONFIG_KEY][
                        this.CERTIFICATE_BODY
                    ];
            }
            if (
                this.whiteLabelingData[this.JSON_DOMAIN_CONFIG_KEY][
                    this.PRIVATE_KEY
                ]
            ) {
                this.whiteLabelingDraft[this.PRIVATE_KEY] =
                    this.whiteLabelingData[this.JSON_DOMAIN_CONFIG_KEY][
                        this.PRIVATE_KEY
                    ];
            }
            if (
                this.whiteLabelingData[this.JSON_DOMAIN_CONFIG_KEY][
                    this.CERTIFICATE_CHAIN
                ]
            ) {
                this.whiteLabelingDraft[this.CERTIFICATE_CHAIN] =
                    this.whiteLabelingData[this.JSON_DOMAIN_CONFIG_KEY][
                        this.CERTIFICATE_CHAIN
                    ];
            }
        }
    }

    initFormGenInput() {
        const isAllFieldRequired = this.whiteLabelingData[
            this.CERTIFICATES_NOT_REQUIRED
        ]
            ? false
            : true;

        this.initDraftData();

        this.formGenInput = {
            formName: 'Domain Configuration',
            state: FormState.CREATE,
            submitButton: {
                buttonName: 'Save As Draft',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: (buttonRef, formGroupRef) => {
                    this.saveAsDraft(buttonRef);
                }
            },
            extraClass: 'domain-configuration',
            fields: [
                {
                    fieldType: FilterType.TEXT,
                    label: 'Domain Name',
                    name: this.DOMAIN_NAME,
                    placeholder: 'Domain Name',
                    required: true,
                    disabled: this.isDomainRegistered,
                    showLabel: true,
                    showSuffixIconInLabel: true,
                    value:
                        this.whiteLabelingData &&
                        this.whiteLabelingData[this.DOMAIN_NAME]
                            ? this.whiteLabelingData[this.DOMAIN_NAME]
                            : null,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Domain name is required'
                        },
                        {
                            validator: CustomValidators.domain,
                            errorMessage: 'Invalid domain name'
                        }
                    ],
                    suffixIcon: this.getSuffixIconButton(this.DOMAIN_NAME)
                },
                {
                    fieldType: FilterType.CALLBACK_BUTTON,
                    label: 'Certificate Body',
                    name: this.CERTIFICATE_BODY,
                    placeholder: null,
                    showLabel: true,
                    required: isAllFieldRequired,
                    showSuffixIconInLabel: true,
                    value:
                        this.whiteLabelingData &&
                        this.whiteLabelingData[this.JSON_DOMAIN_CONFIG_KEY] &&
                        this.whiteLabelingData[this.JSON_DOMAIN_CONFIG_KEY][
                            this.CERTIFICATE_BODY
                        ]
                            ? this.whiteLabelingData[
                                  this.JSON_DOMAIN_CONFIG_KEY
                              ][this.CERTIFICATE_BODY]
                            : null,
                    validations: isAllFieldRequired
                        ? [
                              {
                                  validator: CustomValidators.required,
                                  errorMessage: 'Certificate body is required'
                              }
                          ]
                        : [],
                    suffixIcon: this.getSuffixIconButton(this.CERTIFICATE_BODY),
                    buttonGenInput: {
                        buttonName: this.getButtonTextFor(
                            this.CERTIFICATE_BODY,
                            this.whiteLabelingData &&
                                this.whiteLabelingData[
                                    this.JSON_DOMAIN_CONFIG_KEY
                                ] &&
                                this.whiteLabelingData[
                                    this.JSON_DOMAIN_CONFIG_KEY
                                ][this.CERTIFICATE_BODY]
                        ),
                        buttonType: ButtonType.RAISED,
                        buttonColorType: ButtonColorType.SECONDARY,
                        function: (buttonRef: IButtonGeneratorInput) => {
                            this.openTextAreaFormModal(
                                'Certificate Body',
                                this.CERTIFICATE_BODY,
                                'Enter certificate body here...'
                            );
                        }
                    },
                    deleteValueCallback: (buttonRef) =>
                        this.toggleButtonText(this.CERTIFICATE_BODY)
                },
                {
                    fieldType: FilterType.CALLBACK_BUTTON,
                    label: 'Private Key',
                    name: this.PRIVATE_KEY,
                    placeholder: null,
                    showLabel: true,
                    required: isAllFieldRequired,
                    showSuffixIconInLabel: true,
                    value:
                        this.whiteLabelingData &&
                        this.whiteLabelingData[this.JSON_DOMAIN_CONFIG_KEY] &&
                        this.whiteLabelingData[this.JSON_DOMAIN_CONFIG_KEY][
                            this.PRIVATE_KEY
                        ]
                            ? this.whiteLabelingData[
                                  this.JSON_DOMAIN_CONFIG_KEY
                              ][this.PRIVATE_KEY]
                            : null,
                    validations: isAllFieldRequired
                        ? [
                              {
                                  validator: CustomValidators.required,
                                  errorMessage: 'Private key is required'
                              }
                          ]
                        : [],
                    suffixIcon: this.getSuffixIconButton(this.PRIVATE_KEY),
                    buttonGenInput: {
                        buttonName: this.getButtonTextFor(
                            this.PRIVATE_KEY,
                            this.whiteLabelingData &&
                                this.whiteLabelingData[
                                    this.JSON_DOMAIN_CONFIG_KEY
                                ] &&
                                this.whiteLabelingData[
                                    this.JSON_DOMAIN_CONFIG_KEY
                                ][this.PRIVATE_KEY]
                        ),
                        buttonType: ButtonType.RAISED,
                        buttonColorType: ButtonColorType.SECONDARY,
                        function: (buttonRef: IButtonGeneratorInput) => {
                            this.openTextAreaFormModal(
                                'Private Key',
                                this.PRIVATE_KEY,
                                'Enter private key here...'
                            );
                        }
                    },
                    deleteValueCallback: (buttonRef) =>
                        this.toggleButtonText(this.PRIVATE_KEY)
                },
                {
                    fieldType: FilterType.CALLBACK_BUTTON,
                    label: 'Certificate Chain',
                    name: this.CERTIFICATE_CHAIN,
                    placeholder: null,
                    showLabel: true,
                    required: isAllFieldRequired,
                    showSuffixIconInLabel: true,
                    value:
                        this.whiteLabelingData &&
                        this.whiteLabelingData[this.JSON_DOMAIN_CONFIG_KEY] &&
                        this.whiteLabelingData[this.JSON_DOMAIN_CONFIG_KEY][
                            this.CERTIFICATE_CHAIN
                        ]
                            ? this.whiteLabelingData[
                                  this.JSON_DOMAIN_CONFIG_KEY
                              ][this.CERTIFICATE_CHAIN]
                            : null,
                    validations: isAllFieldRequired
                        ? [
                              {
                                  validator: CustomValidators.required,
                                  errorMessage: 'Certificate chain is required'
                              }
                          ]
                        : [],
                    suffixIcon: this.getSuffixIconButton(
                        this.CERTIFICATE_CHAIN
                    ),
                    buttonGenInput: {
                        buttonName: this.getButtonTextFor(
                            this.CERTIFICATE_CHAIN,
                            this.whiteLabelingData &&
                                this.whiteLabelingData[
                                    this.JSON_DOMAIN_CONFIG_KEY
                                ] &&
                                this.whiteLabelingData[
                                    this.JSON_DOMAIN_CONFIG_KEY
                                ][this.CERTIFICATE_CHAIN]
                        ),
                        buttonType: ButtonType.RAISED,
                        buttonColorType: ButtonColorType.SECONDARY,
                        function: (buttonRef: IButtonGeneratorInput) => {
                            this.openTextAreaFormModal(
                                'Certificate Chain',
                                this.CERTIFICATE_CHAIN,
                                'Enter certificate chain here...'
                            );
                        }
                    },
                    deleteValueCallback: (buttonRef) =>
                        this.toggleButtonText(this.CERTIFICATE_CHAIN)
                }
            ]
        };
    }

    getSuffixIconButton(fieldName) {
        const moreInfoMessage = {
            [this.DOMAIN_NAME]: {
                heading: 'Domain Name',
                data: [
                    `Enter the URL address of your registered website. This will be the address of your console, and your customers can visit this domain to access the console.`,
                    `<b>Recommended Format:</b> "(yourdomain).(com)" This field only inputs lower case letters, numbers, and the following characters: ' _-.'`
                ]
            },
            [this.CERTIFICATE_BODY]: {
                heading: 'Certificate Body',
                data: [
                    `An SSL (Secure Sockets Layer) certificate is a digital certificate that authenticates the identity of a website and encrypts information.`,
                    `Please submit the SSL certificate of your registered domain to validate the domain you have submitted for whiteLabeling. `
                ]
            },
            [this.PRIVATE_KEY]: {
                heading: 'Private Key',
                data: [
                    `The private key is a separate file that's used in the encryption/decryption of data sent between your server and the connecting clients.`,
                    `Submit the private key of your registered domain, which will validate the SSL certificate for the domain that you have already submitted for whiteLabeling. `
                ]
            },
            [this.CERTIFICATE_CHAIN]: {
                heading: 'Certificate Chain',
                data: [
                    `Submit the certificate chain I.e. from root certificate to the end-user certificate, for your registered domain.`,
                    `The certificates in the chain should be issued by trusted certificate authorities(CAs).`,
                    `These certificates will be used for validating end-to-end trusted connection on your registered domain.`
                ]
            }
        };
        return {
            hoverText: 'More Info',
            iconData: {
                type: IconType.MATICON,
                class: 'info'
            },
            function: (field: IFormField, formGroup: FormGroup) => {
                this.parentWidgetRef.modalService.openInfoModal({
                    infoHeading: moreInfoMessage[fieldName].heading,
                    content: [
                        {
                            type: 'UNORDERED_LIST',
                            data: moreInfoMessage[fieldName].data,
                            listStyleType: 'disc'
                        }
                    ]
                });
            }
        };
    }

    openTextAreaFormModal(modalName: string, field, placeholder) {
        const modalId = Symbol();
        const label = modalName;
        const largeTextAreaFormInput: IFormGeneratorInput = {
            formName: field,
            state: FormState.CREATE,
            extraClass: 'large-text-body-form',
            submitButton: {
                buttonName: 'Update',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                function: (buttonRef, formGroupRef: FormGroup) => {
                    if (this.formGroupRef) {
                        this.formGroupRef.patchValue({
                            [field]: formGroupRef.get(field).value
                        });
                        this.toggleButtonText(field, true);
                        this.parentWidgetRef.modalService.closeModal(
                            null,
                            modalId
                        );
                    }
                }
            },
            fields: [
                {
                    label: label,
                    name: field,
                    placeholder: placeholder,
                    fieldType: FilterType.TEXTAREA,
                    hideFloatLabel: true,
                    value: this.formGroupRef
                        ? this.formGroupRef.get(field).value
                        : null
                }
            ]
        };
        const modalData: IModalData = {
            modalIcon: null,
            modalName: modalName,
            modalType: ModalType.MIDDLE,
            modalWidthVw: 70,
            modalHeightVh: 70,
            modalId: modalId,
            sourceId: this.parentWidgetRef.widgetData.widgetUniqueIdentifier,
            noStepPadding: true,
            modalSteps: [
                {
                    stepName: modalName,
                    stepData: {
                        componentToLoad: FormGeneratorModalComponent,
                        payload: {
                            data: largeTextAreaFormInput
                        }
                    }
                }
            ]
        };
        this.parentWidgetRef.modalService.openModal(modalData);
    }

    getButtonTextFor(fieldInputName?: string, state?: boolean) {
        if (state) {
            return 'View ' + this.inputButtonNames[fieldInputName];
        } else {
            return 'Add ' + this.inputButtonNames[fieldInputName];
        }
    }

    toggleButtonText(fieldInputName?: string, state?: boolean) {
        if (this.formGenInput) {
            const buttonName = this.getButtonTextFor(fieldInputName, state);
            this.formGenInput.fields.find(
                (field) => field.name === fieldInputName
            ).buttonGenInput.buttonName = buttonName;
        }
    }

    resetFormToLastState() {
        if (!this.isDomainRegistered) {
            if (this.whiteLabelingData[this.DOMAIN_NAME]) {
                this.formGroupRef
                    .get(this.DOMAIN_NAME)
                    .setValue(this.whiteLabelingData[this.DOMAIN_NAME]);
            } else {
                this.formGroupRef.get(this.DOMAIN_NAME).reset();
            }
        }
        const domainConfig =
            this.whiteLabelingData[this.JSON_DOMAIN_CONFIG_KEY];
        if (this.whiteLabelingData[this.JSON_DOMAIN_CONFIG_KEY]) {
            this.formGroupRef.patchValue({
                [this.CERTIFICATE_BODY]: domainConfig[this.CERTIFICATE_BODY],
                [this.PRIVATE_KEY]: domainConfig[this.PRIVATE_KEY],
                [this.CERTIFICATE_CHAIN]: domainConfig[this.CERTIFICATE_CHAIN]
            });
            this.toggleButtonText(this.CERTIFICATE_BODY, true);
            this.toggleButtonText(this.PRIVATE_KEY, true);
            this.toggleButtonText(this.CERTIFICATE_CHAIN, true);
        } else {
            this.formGroupRef.get(this.CERTIFICATE_BODY).reset();
            this.formGroupRef.get(this.PRIVATE_KEY).reset();
            this.formGroupRef.get(this.CERTIFICATE_CHAIN).reset();
        }
    }

    saveAsDraft(buttonRef: IButtonGeneratorInput) {
        if (this.formGroupRef && this.saveAsDraftApiInfo) {
            const clonedDraft = Helper.cloneDeep(this.whiteLabelingDraft);
            clonedDraft[this.DOMAIN_NAME] = this.formGroupRef.get(
                this.DOMAIN_NAME
            ).value;
            clonedDraft[this.CERTIFICATE_BODY] = this.formGroupRef.get(
                this.CERTIFICATE_BODY
            ).value;
            clonedDraft[this.PRIVATE_KEY] = this.formGroupRef.get(
                this.PRIVATE_KEY
            ).value;
            clonedDraft[this.CERTIFICATE_CHAIN] = this.formGroupRef.get(
                this.CERTIFICATE_CHAIN
            ).value;

            const apiConfig = Helper.generateHitApiConfig(
                this.saveAsDraftApiInfo
            );
            buttonRef.loader = true;
            apiConfig.input = clonedDraft;
            apiConfig.function = (response) => {
                if (!this.whiteLabelingData[this.JSON_DOMAIN_CONFIG_KEY]) {
                    this.whiteLabelingData[this.JSON_DOMAIN_CONFIG_KEY] = {};
                }
                this.whiteLabelingData[this.DOMAIN_NAME] =
                    clonedDraft[this.DOMAIN_NAME];
                this.whiteLabelingData[this.JSON_DOMAIN_CONFIG_KEY][
                    this.CERTIFICATE_BODY
                ] = clonedDraft[this.CERTIFICATE_BODY];
                this.whiteLabelingData[this.JSON_DOMAIN_CONFIG_KEY][
                    this.PRIVATE_KEY
                ] = clonedDraft[this.PRIVATE_KEY];
                this.whiteLabelingData[this.JSON_DOMAIN_CONFIG_KEY][
                    this.CERTIFICATE_CHAIN
                ] = clonedDraft[this.CERTIFICATE_CHAIN];
                if (this.reInitializeWhiteLabelData) {
                    this.reInitializeWhiteLabelData();
                }
                this.formGenInput = null;
                this.subWidgetData.parentWidgetRef.changeDetectorRef.detectChanges();
                this.initFormGenInput();
                this.parentWidgetRef.notificationsService.showSnackBar(
                    'Configuration saved successfully. Click on publish to deploy these changes.',
                    false,
                    '',
                    { duration: 3500 }
                );
            };
            apiConfig.errorFunction = (error) => {
                Helper.showErrorMessage(
                    this.parentWidgetRef.notificationsService,
                    error,
                    'Error in saving Domain Configuration'
                );
            };
            apiConfig.endFunction = () => {
                buttonRef.loader = false;
                this.parentWidgetRef.changeDetectorRef.detectChanges();
            };
            new HitApi(
                apiConfig,
                this.parentWidgetRef.httpService,
                this.parentWidgetRef.ngZone
            ).hitApi();
        } else {
            buttonRef.loader = false;
        }
    }

    ngOnDestroy(): void {
        if (this.resetSubscription) {
            this.resetSubscription.unsubscribe();
        }
    }
}
