<div
    class="breadcrumb"
    [hidden]="
        (widgetRef.loadingData | async) ||
        !(
            widgetRef.widgetLinkingData &&
            widgetRef.widgetLinkingData.breadCrumbs &&
            widgetRef.widgetLinkingData.breadCrumbs.length
        )
    "
>
    <div
        class="patch"
        *ngIf="
            widgetRef.widgetLinkingData &&
            widgetRef.widgetLinkingData.breadCrumbs &&
            widgetRef.widgetLinkingData.breadCrumbs.length
        "
    >
        <span
            class="item-name"
            (click)="$event.stopPropagation(); goBackDrillDown(true)"
            >Home
            <span class="arrow-icon" appIcon [data]="arrowRightIcon"></span>
        </span>

        <span
            class="item-name"
            [class.large]="isLast"
            *ngFor="
                let item of widgetRef.widgetLinkingData.breadCrumbs;
                index as i;
                last as isLast;
                count as count
            "
            (click)="
                $event.stopPropagation();
                goBackDrillDown(null, count - (i + 1), isLast)
            "
            >{{ item }}
            <span
                class="arrow-icon"
                *ngIf="!isLast"
                appIcon
                [data]="arrowRightIcon"
            ></span>
        </span>
    </div>
</div>

<div
    class="widget-data-container"
    *ngIf="!(widgetRef.loadingData | async) && rowData && rowData.length"
>
    <div
        class="tw-flex-row graph-container"
        *ngIf="
            (!(widgetRef.loadingData | async) &&
                (widgetRef.visibleSections | async).has(ViewType.GRAPH)) ||
            (widgetRef.visibleSections | async).has(ViewType.STACKED_BAR_GRAPH)
        "
    >
        <app-graph-generator [graphData]="graphChartData"></app-graph-generator>

        <div
            *ngIf="widgetRef.lightState"
            class="lightOrFull clickable"
            (click)="getFullAccess()"
            [class.lightFullGraphOverlay]="
                showGraphOverlay &&
                widgetRef.visibleSections.value &&
                widgetRef.visibleSections.value.has(ViewType.GRAPH)
            "
        >
            <p>Get Full Access!</p>
            <p class="description">Graph Details in full version</p>
        </div>
        <div
            class="graph-overlay"
            [class.getFullAccessState]="
                widgetRef.lightState &&
                showGraphOverlay &&
                widgetRef.visibleSections.value &&
                (widgetRef.visibleSections.value.has(ViewType.GRAPH) ||
                    widgetRef.visibleSections.value.has(
                        ViewType.STACKED_BAR_GRAPH
                    ))
            "
            *ngIf="
                showGraphOverlay &&
                widgetRef.visibleSections.value &&
                (widgetRef.visibleSections.value.has(ViewType.GRAPH) ||
                    widgetRef.visibleSections.value.has(
                        ViewType.STACKED_BAR_GRAPH
                    ))
            "
        >
            <div class="overlay-text">
                <span>
                    <i class="fas fa-exclamation-triangle"></i>
                    Graph is not supported for this range of data.<span
                        class="underline"
                        (click)="switchToTableView()"
                        >Switch&nbsp;to&nbsp;Table&nbsp;View</span
                    >
                    <span
                        class="tw-no-underline tw-font-normal tw-cursor-default hover:tw-text-secondary tw-pl-0"
                        *ngIf="
                            widgetRef.visibleSections.value.has(
                                ViewType.STACKED_BAR_GRAPH
                            ) &&
                            (aggregateType === AggregateType.MONTHS ||
                                aggregateType === AggregateType.YEARS)
                        "
                        >or&nbsp;Download&nbsp;PDF&nbsp;report</span
                    >
                </span>
            </div>
            <span class="graph-disclaimer">
                <i class="fas fa-exclamation-triangle"></i>
                {{ graphDisclaimerText }}
            </span>
        </div>
    </div>
    <div
        class="table-container"
        *ngIf="
            !(widgetRef.loadingData | async) &&
            (widgetRef.visibleSections | async).has(ViewType.TABLE)
        "
    >
        <app-only-table-generator
            [widgetRef]="widgetRef"
            [tableInput]="tableInputData"
            [tableData]="filteredSearchData"
            [getRowStyle]="getRowStyle"
            (rowClicked)="tableRowClicked($event['data'][tableKeys[0]])"
            [autoFitColumns]="
                !(
                    tableInputData.columns.length > 5 &&
                    tableInputData.columns.length < 7
                )
            "
            [defaultColDef]="defaultColDef"
        ></app-only-table-generator>
    </div>
</div>
