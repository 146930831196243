import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ValueFormatterParams } from 'ag-grid-community';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { Widget } from 'src/app/shared/classes/Widget';
import { MultiButtonGeneratorComponent } from 'src/app/shared/components/multi-button-generator/multi-button-generator.component';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IIntegrationApiResponse } from 'src/app/shared/interfaces/api/portlets/IIntegrationApiResponse';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { SalesforceIntegrationCaseFormComponent } from '../salesforce-integration-case-form/salesforce-integration-case-form.component';
import { SalesforceIntegrationStepFourComponent } from '../salesforce-integration-step-four/salesforce-integration-step-four.component';
import { SalesforceIntegrationStepTwoComponent } from '../salesforce-integration-step-two/salesforce-integration-step-two.component';
import { SalesforceIntegrationStepOneComponent } from '../salesforce-integrations-step-one/salesforce-integration-step-one.component';
import { SalesforceIntegrationsStepThreeComponent } from '../salesforce-integrations-step-three/salesforce-integrations-step-three.component';
import { HitApi } from './../../../../../classes/HitApi';
import { ModalInjectedData } from './../../../../../classes/ModalInjectedData';
import { IConfirmationModal } from './../../../../../interfaces/modal/IConfirmationModal';

@Component({
    selector: 'app-salesforce-integrations-table',
    templateUrl: './salesforce-integrations-table.component.html',
    styleUrls: ['./salesforce-integrations-table.component.sass']
})
export class SalesforceIntegrationsTableComponent implements OnInit {
    widgetRef: Widget;
    stepData: StepData;
    tableGenInput: ITableGeneratorInput = null;
    confiName: string;
    tableData;
    configureFields: IFormField[];
    @ViewChild('deleteConfiguration')
    deleteConfigurationRef: TemplateRef<any>;
    constructor(
        private modalInjectedData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        private modalService: ModalService
    ) {
        this.widgetRef = modalInjectedData.data.widgetRef;
        this.tableData = modalInjectedData.data.response;
    }
    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }

    ngOnInit(): void {
        this.setUpBasics();
    }
    setUpBasics() {
        this.tableGenInput = {
            listExtraction: {
                type: 'DIRECT'
            },
            noDataMessage: 'No Integrations Available',
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-user-plus'
            },
            columns: [
                {
                    columnKey: 'configurationName',
                    columnName: 'Configuration Name'
                },
                {
                    columnKey: 'action',
                    columnName: 'Action',
                    pinned: 'right',
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: ValueFormatterParams) => {
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'Edit',
                                buttonColorType: ButtonColorType.INFO,
                                buttonType: ButtonType.TEXT,
                                showLoader: true,
                                disable: !rowData['data']['canEdit']
                                    ? true
                                    : false,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    if (buttonRef.loader) {
                                        return;
                                    }
                                    buttonRef.loader = true;
                                    const configDetails = rowData['data'];
                                    const apiConf: IHitApi = {
                                        url: `${ApiUrls.SRM_INTEGRATIONS_NAME}/salesforce-integration/get-config/${configDetails['id']}`,
                                        input: {},
                                        config: {
                                            authorization:
                                                AuthorizationType.BEARER_TOKEN
                                        },
                                        requestType: RequestType.GET,
                                        uniqueIdentity: Symbol(),
                                        function: (response) => {
                                            this.openIntegrationsModal(
                                                response,
                                                true
                                            );
                                        },
                                        errorFunction: (error) => {
                                            Helper.showErrorMessage(
                                                this.widgetRef
                                                    .notificationsService,
                                                error
                                            );
                                        },
                                        endFunction: () => {
                                            buttonRef.loader = false;
                                        }
                                    };
                                    new HitApi(
                                        apiConf,
                                        this.widgetRef.httpService,
                                        this.widgetRef.ngZone
                                    ).hitApi();
                                }
                            },
                            {
                                buttonName: 'Create Case',
                                buttonColorType: ButtonColorType.SUCCESS,
                                buttonType: ButtonType.TEXT,
                                showLoader: true,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    if (buttonRef.loader) {
                                        return;
                                    }
                                    buttonRef.loader = true;
                                    const configDetails = rowData['data'];
                                    const apiConf: IHitApi = {
                                        url: `${ApiUrls.SRM_INTEGRATIONS_NAME}/salesforce-integration/get-config/${configDetails['id']}`,
                                        input: {},
                                        config: {
                                            authorization:
                                                AuthorizationType.BEARER_TOKEN
                                        },
                                        requestType: RequestType.GET,
                                        uniqueIdentity: Symbol(),
                                        function: (response) => {
                                            this.configureFields =
                                                response.step2.fields;
                                            this.openCaseHandlingModal(
                                                this.configureFields,
                                                true,
                                                configDetails['id']
                                            );
                                        },
                                        errorFunction: (error) => {
                                            Helper.showErrorMessage(
                                                this.widgetRef
                                                    .notificationsService,
                                                error
                                            );
                                        },
                                        endFunction: () => {
                                            buttonRef.loader = false;
                                        }
                                    };
                                    new HitApi(
                                        apiConf,
                                        this.widgetRef.httpService,
                                        this.widgetRef.ngZone
                                    ).hitApi();
                                }
                            },
                            {
                                buttonName: 'Delete',
                                buttonColorType: ButtonColorType.WARN,
                                buttonType: ButtonType.TEXT,
                                function: () => {
                                    this.confiName =
                                        rowData.data.configurationName;
                                    const modalData: IConfirmationModal = {
                                        modalName: 'Delete Integration',
                                        confirmationMessage:
                                            'Are you sure want to delete the following configuration?',
                                        buttonText: 'Delete',
                                        buttonColorType:
                                            ButtonColorType.PRIMARY,
                                        loaderOnExec: true,
                                        hideCancelButton: true,
                                        bodyTemplate:
                                            this.deleteConfigurationRef,
                                        function: (modalId: Symbol) => {
                                            const configName = rowData['data'];
                                            const apiConf: IHitApi = {
                                                url: `${ApiUrls.SRM_INTEGRATIONS_NAME}/salesforce-integration/remove/${configName['id']}`,
                                                input: {},
                                                config: {
                                                    authorization:
                                                        AuthorizationType.BEARER_TOKEN
                                                },
                                                requestType: RequestType.DELETE,
                                                uniqueIdentity: Symbol(),
                                                function: () => {
                                                    this.widgetRef.notificationsService.showSnackBar(
                                                        `${configName['configurationName']} permanently deleted`
                                                    );
                                                    this.modalService.closeModal(
                                                        null,
                                                        modalId
                                                    );
                                                    this.modalService.closeModal(
                                                        null,
                                                        this.modalInjectedData
                                                            .modalId
                                                    );
                                                    this.widgetRef.refreshWidget();
                                                },
                                                errorFunction: (error) => {
                                                    this.modalService.closeModal(
                                                        null,
                                                        modalId
                                                    );
                                                    Helper.showErrorMessage(
                                                        this.widgetRef
                                                            .notificationsService,
                                                        error,
                                                        `Error deleting ${configName['configurationName']}`
                                                    );
                                                }
                                            };
                                            new HitApi(
                                                apiConf,
                                                this.widgetRef.httpService,
                                                this.widgetRef.ngZone
                                            ).hitApi();
                                        },
                                        modalWidthVw: 45,
                                        modalHeightVh: 40
                                    };
                                    this.widgetRef.modalService.openConfirmationModal(
                                        modalData
                                    );
                                }
                            }
                        ];
                        const buttonOptions: IMultiButtonOption = {
                            layout: {
                                justifyContent: 'space-evenly'
                            }
                        };
                        rowData['buttonGenInputs'] = buttons;
                        rowData['options'] = buttonOptions;
                        return rowData;
                    },
                    buttonGen: true,
                    maxWidth: 240
                }
            ]
        };
    }
    openIntegrationsModal(response, isEdit?: boolean) {
        const modalArgs: IModalData = {
            modalName: 'Salesforce Integration',
            modalType: ModalType.MIDDLE,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            extraStepContainerClass: 'remove-form-space',
            modalSteps: [
                {
                    stepName: 'Credentials',
                    resetModal: true,
                    stepData: {
                        componentToLoad: SalesforceIntegrationStepOneComponent,
                        payload: {
                            data: {
                                isEdit,
                                response: response,
                                widgetRef: this.widgetRef,
                                configurationNameList:
                                    this.tableData && this.tableData.length
                                        ? this.tableData.map(
                                              (each) =>
                                                  each['configurationName']
                                          )
                                        : null
                            }
                        }
                    },
                    stepHeightVh: 95,
                    stepWidthVw: 60
                },
                {
                    stepName: 'Configure Form',
                    resetModal: true,
                    stepData: {
                        componentToLoad: SalesforceIntegrationStepTwoComponent,
                        payload: {
                            data: {
                                response: response,
                                widgetRef: this.widgetRef,
                                isEdit
                            }
                        }
                    },
                    stepHeightVh: 85,
                    stepWidthVw: 60
                },
                {
                    stepName: 'User Access',
                    resetModal: true,
                    stepData: {
                        componentToLoad:
                            SalesforceIntegrationsStepThreeComponent,
                        payload: {
                            data: {
                                response: response,
                                widgetRef: this.widgetRef,
                                isEdit
                            }
                        }
                    },
                    stepHeightVh: 85,
                    stepWidthVw: 50
                },
                {
                    stepName: 'Customer Access',
                    resetModal: true,
                    stepData: {
                        componentToLoad: SalesforceIntegrationStepFourComponent,
                        payload: {
                            data: {
                                response: response,
                                widgetRef: this.widgetRef,
                                isEdit
                            }
                        }
                    },
                    stepHeightVh: 85,
                    stepWidthVw: 55
                }
            ],
            modalHeightVh: 80,
            modalWidthVw: 70,
            modalIcon: null
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
    openCaseHandlingModal(
        configureFields,
        isCreate?: boolean,
        configId?: string
    ) {
        const modalArgs: IModalData = {
            modalName: 'New Case',
            modalIcon: undefined,
            modalType: ModalType.MIDDLE,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            extraStepContainerClass: 'remove-form-space',
            modalSteps: [
                {
                    stepName: 'New Case',
                    stepData: {
                        componentToLoad: SalesforceIntegrationCaseFormComponent,
                        payload: {
                            data: {
                                formFields: configureFields,
                                widgetRef: this.widgetRef,
                                isCreate,
                                configId
                            }
                        }
                    }
                }
            ],
            modalHeightVh: 65,
            modalWidthVw: 50
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
    bindData(response: IIntegrationApiResponse): void {
        this.widgetRef.endLoader();
    }
}
export interface StepData {
    form: IFormGeneratorInput;
    isRequired: Map<string, boolean>;
    configureFields: IFormField[];
    sideFields: IFormGeneratorInput;
    isChecked: Map<string, boolean>;
}
