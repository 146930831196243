<div class="json-creator-container">
    <div class="json-creator-content">
        <div class="selected-list" *ngIf="objectKeys(keyValueData).length > 0">
            <div
                class="list-item"
                *ngFor="
                    let item of objectKeys(keyValueData);
                    let isLastIndex = last
                "
            >
                <label for="key">
                    <div class="label-item">
                        <span>Key</span>
                        <div class="input">
                            <input
                                placeholder="Add key"
                                class="disabled"
                                type="text"
                                [value]="item"
                                readonly
                            />
                        </div>
                    </div>
                    <div class="label-item label-item-value">
                        <span>Value</span>
                        <mat-chip-list #chipList99>
                            <mat-chip
                                *ngFor="let value of keyValueData[item]"
                                [selectable]="selectable"
                                [removable]="removable"
                                (removed)="removeValueIfExisting(value, item)"
                            >
                                {{ value }}
                                <mat-icon matChipRemove *ngIf="removable"
                                    >cancel</mat-icon
                                >
                            </mat-chip>
                            <input
                                placeholder="Add Tag"
                                type="text"
                                [matChipInputFor]="chipList99"
                                [matChipInputAddOnBlur]="addOnBlur"
                                [matChipInputSeparatorKeyCodes]="
                                    separatorKeysCodes
                                "
                                (matChipInputTokenEnd)="
                                    addValueInExisting($event, item)
                                "
                            />
                        </mat-chip-list>
                    </div>
                    <div class="buttons">
                        <app-button-generator
                            [buttonGenInput]="selectedListButtonInputs[0]"
                            (buttonRef)="deletePair(item)"
                        >
                        </app-button-generator>
                        <app-button-generator
                            *ngIf="isLastIndex && !showEmptyKeyValueField"
                            [buttonGenInput]="selectedListButtonInputs[1]"
                            (buttonRef)="showEmptyKeyValueField = true"
                        >
                        </app-button-generator>
                    </div>
                </label>
            </div>
        </div>
        <div class="add-new" *ngIf="showEmptyKeyValueField">
            <div class="list-item">
                <label for="key">
                    <div class="label-item">
                        <span>Key</span>
                        <div class="input">
                            <input
                                placeholder="Add Key"
                                type="text"
                                #key
                                (keyup)="addNewPair(key)"
                            />
                        </div>
                    </div>
                    <div class="label-item label-item-value">
                        <span>Value</span>
                        <mat-chip-list #chipList class="fix-height">
                            <mat-chip
                                *ngFor="let value of valueList"
                                [selectable]="selectable"
                                [removable]="removable"
                                (removed)="removeValue(value)"
                            >
                                {{ value }}
                                <mat-icon matChipRemove *ngIf="removable"
                                    >cancel</mat-icon
                                >
                            </mat-chip>
                            <input
                                placeholder="Add Tag"
                                type="text"
                                [matChipInputFor]="chipList"
                                [matChipInputAddOnBlur]="true"
                                [matChipInputSeparatorKeyCodes]="
                                    separatorKeysCodes
                                "
                                (matChipInputTokenEnd)="addValue(key, $event)"
                            />
                        </mat-chip-list>
                    </div>
                    <div class="buttons">
                        <app-button-generator
                            [buttonGenInput]="addNewButtonInput"
                            (buttonRef)="createNewKeyValueField(key)"
                        >
                        </app-button-generator>
                    </div>
                </label>
            </div>
        </div>
        <div class="empty-error" *ngIf="errorMessage">
            <div class="empty-error-div"></div>
            <div class="error-message-custom">
                <i class="fas fa-exclamation-triangle"></i>{{ errorMessage }}
            </div>
        </div>
    </div>
</div>
