<div class="single-select-dropdown" *ngIf="filterInfo" [class.disabled]="dummy">
    <legend class="legend-container" *ngIf="dropdownLabel">
        {{ dropdownLabel }}
    </legend>
    <ng-select
        #select
        [class.disabled]="dummy"
        style="z-index: 10"
        [ngClass]="[
            filterInfo.lightMode ? 'light' : 'custom',
            dropdownLabel ? 'outline' : ''
        ]"
        [items]="filteredList"
        bindLabel="label"
        [bindValue]="filterInfo.getFullObj ? null : 'id'"
        [virtualScroll]="true"
        [multiple]="false"
        [clearable]="showLoading ? false : clearable"
        [closeOnSelect]="true"
        [loading]="showLoading"
        [placeholder]="
            filterInfo.placeholder && !selectedValue
                ? filterInfo.placeholder
                : 'Select ' + filterInfo.label
        "
        [disabled]="dummy"
        (change)="selectionChange(); select.blur()"
        appendTo="{{ appendTo ? appendTo : 'body' }}"
        (scroll)="onScroll($event)"
        [loadingText]="loadingMessage ? loadingMessage : 'Loading...'"
        dropdownPosition="auto"
        [clearable]="true"
        (scrollToEnd)="onScrollToEnd()"
        [(ngModel)]="selectedValue"
        (clear)="emitChange('clear')"
        (close)="isOpen = false; emitChange('close')"
        (open)="isOpen = true; dropDownRef.emit(select)"
        [searchable]="
            filterInfo.searchable !== undefined ? filterInfo.searchable : true
        "
    >
        <ng-template ng-option-tmp let-item="item">
            <div
                title="{{ item['hoveredText'] ? item['hoveredText'] : '' }}"
                [class.disabled]="dummy"
            >
                <span class="common-label" [class.disabled]="dummy">{{
                    item.label
                }}</span>
            </div>
        </ng-template>
    </ng-select>

    <div class="dropdown-background-layer" *ngIf="isOpen"></div>
</div>
