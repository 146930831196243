import { Pipe, PipeTransform } from '@angular/core';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';

@Pipe({
    name: 'fieldDataFromControlName'
})
export class FieldDataFromControlNamePipe implements PipeTransform {
    transform(controlName: string, fields: IFormField[]): IFormField {
        return fields.find((field) => field.name === controlName);
    }
}
