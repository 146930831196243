import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { VariableFieldType } from 'src/app/shared/enums/VariableFieldType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { WidgetCreationService } from 'src/app/shared/services/widget-creation/widget-creation.service';
import { FormState } from './../../../../enums/FormState';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';
import { NotificationsService } from './../../../../services/notifications/notifications.service';
import { PreviewTfFileComponent } from './../preview-tf-file/preview-tf-file.component';
@Component({
    selector: 'app-upload-tf-files-modal',
    templateUrl: './upload-tf-files-modal.component.html',
    styleUrls: ['./upload-tf-files-modal.component.sass']
})
export class UploadTfFilesModalComponent implements OnInit, OnDestroy {
    cloudFormGenInput: IFormGeneratorInput;
    locationConfigFormGenInputs: IFormGeneratorInput;
    cloudFormGroup: FormGroup;
    locationConfigFormGroup: FormGroup;
    terraformFiles: Map<string, any[]> = new Map<string, any[]>();
    Script = Script;
    LoaderType = LoaderType;
    widgetRef: Widget;
    removeFile: IIcon = {
        type: IconType.SVG,
        class: 'cross_icon_v2',
        extraClass: 'svg-white-fill'
    };
    infoIcon: IIcon = {
        type: IconType.MATICON,
        class: 'info'
    };
    infoHeading: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-exclamation-circle'
    };
    response: any;
    stepOneData: any;
    counter: number = 0;
    widgetId: string;
    loader: Map<string, boolean> = new Map<string, boolean>();
    currentStepData: any;
    mainTfFileKeys: string[] = [];
    varTfFileKeys: string[] = [];
    tfVarsFiles: string[] = [];
    isEdit: boolean = false;
    editResponse: any;
    selectedCloud: string;
    newFileAdded: boolean = false;
    loadPage: boolean = false;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    actionButtonGenInput: IButtonGeneratorInput[];
    backButtonGenInput: IButtonGeneratorInput;
    infoMessages: object = {};
    resetModal: Subscription;
    isNewFileUploaded: boolean = false;
    showToggleField: boolean = false;
    configureLocation: boolean = false;
    constructor(
        private notificationService: NotificationsService,
        private modalService: ModalService,
        private multiStepFormService: MultiStepFormService,
        private modalData: ModalInjectedData,
        private widgetCreationService: WidgetCreationService
    ) {
        this.widgetId = modalData.data['widgetId'];
        this.widgetRef = modalData.data['widgetRef'];
        this.stepOneData = this.multiStepFormService.stepData
            .get(this.modalData.modalId)
            .get(1);
        if (
            this.multiStepFormService.stepData
                .get(this.modalData.modalId)
                .has(2)
        ) {
            this.currentStepData = this.multiStepFormService.stepData
                .get(this.modalData.modalId)
                .get(2);

            this.terraformFiles = this.currentStepData['tfFiles'];
        }
        if (modalData.data && modalData.data['isEdit']) {
            this.isEdit = modalData.data['isEdit'];
            this.editResponse = modalData.data['response'];
            this.selectedCloud =
                this.modalData.data['response']['extraInfo']['terraformCloud'];
            if (
                !this.multiStepFormService.stepData
                    .get(this.modalData.modalId)
                    .has(2)
            ) {
                this.getFileFromS3();
            }
            this.configureLocation = this.editResponse['configureLocation'];
        }
        if (this.currentStepData && this.currentStepData['configureLocation']) {
            this.configureLocation = this.currentStepData['configureLocation'];
        }
    }
    ngOnInit(): void {
        this.setUpBasics();
        this.setUpLoactionField();
        this.setInfoMessages();
        this.resetModal = this.modalService.resetModal.subscribe((data) => {
            this.counter = 0;
            if (
                this.loader.get(LoaderType.SAVE_AS_DRAFT) ||
                this.loader.get(LoaderType.MAP_VARIABLES)
            ) {
                return;
            }
            this.terraformFiles.clear();
            if (this.modalData.data && this.modalData.data['isEdit']) {
                this.isEdit = this.modalData.data['isEdit'];
                this.editResponse = this.modalData.data['response'];
                this.selectedCloud =
                    this.modalData.data['response']['extraInfo'][
                        'terraformCloud'
                    ];
                this.cloudFormGroup.get('cloud').setValue(this.selectedCloud);
                this.getFileFromS3();
            } else {
                this.cloudFormGroup.reset();
            }
        });
        this.generateButtons();
    }
    setInfoMessages() {
        this.infoMessages['mainTfFiles'] =
            "The file will contain main set of configuration for your module. Do not upload the provider file or mention it's parameters in any other file.";
        this.infoMessages['variableFile'] =
            "The file will contain variables for your module. Do not upload the provider file or mention it's parameters in any other file.";
        this.infoMessages['varFile'] =
            "The file will contain TfVars for your module. Do not upload the provider file or mention it's parameters in any other file.";
    }
    generateButtons() {
        this.actionButtonGenInput = [
            {
                buttonName: 'Save as Draft',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                function: (buttonRef) => {
                    this.uploadTfFiles(LoaderType.SAVE_AS_DRAFT, buttonRef);
                },
                showLoader: true
            },
            {
                buttonName: 'Configure Request Form',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                function: (buttonRef) => {
                    this.uploadTfFiles(LoaderType.MAP_VARIABLES, buttonRef);
                },
                showLoader: true
            }
        ];
        this.backButtonGenInput = {
            buttonName: 'Back',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: (buttonRef) => {
                this.goToPreviousStep();
            },
            showLoader: false
        };
    }
    setUpBasics() {
        this.cloudFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Cloud',
                    placeholder: 'Select Cloud',
                    appearance: 'legacy',
                    showLabel: true,
                    name: 'cloud',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    value: this.selectedCloud
                        ? this.selectedCloud
                        : this.currentStepData && this.currentStepData.cloud
                        ? this.currentStepData.cloud
                        : '',
                    listData: [
                        {
                            id: 'aws',
                            label: 'AWS'
                        },
                        {
                            id: 'azure-csp',
                            label: 'AZURE CSP'
                        },
                        {
                            id: 'azure-plan',
                            label: 'AZURE PLAN'
                        },
                        {
                            id: 'azure-ea',
                            label: 'AZURE EA'
                        },
                        {
                            id: 'gcp',
                            label: 'GCP'
                        }
                    ],
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This field is required.'
                        }
                    ]
                }
            ]
        };
    }
    setUpLoactionField() {
        this.locationConfigFormGenInputs = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Allow end-user to configure location',
                    placeholder: '',
                    appearance: 'legacy',
                    showLabel: true,
                    name: 'configureLocation',
                    fieldType: FilterType.RADIO,
                    value: this.configureLocation
                        ? ConfigureLocation.TAKE_INPUT
                        : ConfigureLocation.SCRIPT,
                    listData: [
                        {
                            id: 'script',
                            label: 'No, use the location in script.'
                        },
                        {
                            id: 'input',
                            label: 'Yes, provide a custom location input to user.'
                        }
                    ],
                    required: true
                }
            ]
        };
    }
    fileSelected(files, script?) {
        for (const file of files.target.files) {
            const fileExist = this.checkIfFileExist(file.name, script);
            if (fileExist) {
                this.notificationService.showSnackBar(
                    'File already exist.',
                    true
                );
            } else if (
                this.terraformFiles.has(script) &&
                this.terraformFiles.get(script).length &&
                (script === Script.MAIN_TF_FILE ||
                    script === Script.VARIABLE_FILE)
            ) {
                this.terraformFiles.get(script).push(file);
            } else {
                this.terraformFiles.set(script, [file]);
            }
        }
        files.target.value = '';
    }
    checkIfFileExist(fileName, script): boolean {
        let isExist: boolean = false;
        if (this.terraformFiles.has(script)) {
            const tfFiles = this.terraformFiles.get(script);
            tfFiles.forEach((file) => {
                if (fileName === file.name) {
                    isExist = true;
                }
            });
        }

        return isExist;
    }
    removeTfFile(fileName, script) {
        if (
            this.loader.get(LoaderType.SAVE_AS_DRAFT) ||
            this.loader.get(LoaderType.MAP_VARIABLES)
        ) {
            return;
        }
        if (this.terraformFiles.has(script)) {
            const totalFiles = this.terraformFiles.get(script);
            const index = totalFiles.findIndex(
                (file) => file.name === fileName
            );
            if (index !== -1) {
                this.terraformFiles.get(script).splice(index, 1);
            }
        }
    }
    preview(modalName, file) {
        const modalData: IModalData = {
            modalName: modalName + ' (preview)',
            modalIcon: null,
            modalType: ModalType.MIDDLE,
            sourceId: Symbol(),
            modalWidthVw: 85,
            modalHeightVh: 85,
            hideSteps: true,
            disableClose: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: PreviewTfFileComponent,
                        payload: {
                            data: file
                        }
                    },
                    stepName: 'Create'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }
    goToNextLevel(variables, warning?) {
        this.multiStepFormService.stepData
            .get(this.modalData.modalId)
            .set(3, variables);
        this.multiStepFormService.stepData.get(this.modalData.modalId).set(2, {
            tfFiles: this.terraformFiles,
            cloud: this.cloudFormGroup.get('cloud').value,
            mainTfs: this.mainTfFileKeys,
            variableFiles: this.varTfFileKeys,
            tfVarsFiles: this.tfVarsFiles,
            warning: warning,
            deleteFilesCallBackFn: this.deleteUploadedFiles.bind(this),
            newKeys: this.isNewFileUploaded,
            configureLocation:
                this.locationConfigFormGroup &&
                this.locationConfigFormGroup.get('configureLocation').value ===
                    ConfigureLocation.TAKE_INPUT
                    ? true
                    : false
        });
        this.multiStepFormService.nextStep(this.modalData.modalId);
    }
    uploadTfFiles(action, buttonRef) {
        if (
            this.loader.get(LoaderType.SAVE_AS_DRAFT) ||
            this.loader.get(LoaderType.MAP_VARIABLES)
        ) {
            return;
        }

        Helper.markAllFieldAsTouched(this.cloudFormGroup);
        if (this.cloudFormGroup.invalid) {
            this.cloudFormGroup.updateValueAndValidity();
            return;
        }
        if (
            !this.terraformFiles.has(Script.MAIN_TF_FILE) ||
            (this.terraformFiles.has(Script.MAIN_TF_FILE) &&
                !this.terraformFiles.get(Script.MAIN_TF_FILE).length)
        ) {
            this.notificationService.showSnackBar(
                `Please Upload Main TF Files.`,
                true
            );
            return;
        }
        if (!this.checkIfNewFileAdded()) {
            if (action === LoaderType.SAVE_AS_DRAFT) {
                this.saveAsDraft(buttonRef);
            } else {
                if (
                    this.editResponse &&
                    this.editResponse.form &&
                    this.editResponse.form.fields &&
                    this.editResponse.form.fields.length
                ) {
                    this.generateTfFilesKey();
                    this.goToNextLevel(this.editResponse.form.fields);
                } else {
                    buttonRef.loader = true;
                    this.getParsedVariables(buttonRef);
                }
            }
            return;
        }
        if (this.isEdit) {
            this.isNewFileUploaded = true;
        }
        this.loader.set(action, true);
        buttonRef.loader = true;
        this.newFileAdded = true;
        const input = this.prepareInputs();
        const apiConfig = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                'generatePresignedUrl'
            ]
        );
        apiConfig.input = input;
        apiConfig.function = (response) => {
            this.counter = 0;
            this.response = response;
            this.uploadFilesOnS3(action, buttonRef);
        };
        apiConfig.errorFunction = (error) => {
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
            this.loader.set(action, false);
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
        };
        const id = Helper.generateUniqueKey(10);
        apiConfig.intactUrl = apiConfig.url;
        apiConfig.url = apiConfig.url.replace('{requestId}', id);
        new HitApi(
            apiConfig,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    prepareInputs() {
        const input = {};
        const value = Object.values(Script);
        value.forEach((fileName) => {
            if (
                this.terraformFiles.has(fileName) &&
                this.terraformFiles.get(fileName) &&
                this.terraformFiles.get(fileName).length
            ) {
                const files = this.terraformFiles
                    .get(fileName)
                    .map((file) => file.name);
                input[fileName] = files;
            }
        });
        return input;
    }
    checkIfNewFileAdded(): boolean {
        let newFileAdded: boolean = false;
        const value = Object.values(Script);
        value.forEach((fileName) => {
            if (
                this.terraformFiles.has(fileName) &&
                this.terraformFiles.get(fileName) &&
                this.terraformFiles.get(fileName).length &&
                (fileName === Script.MAIN_TF_FILE ||
                    fileName === Script.VARIABLE_FILE)
            ) {
                this.terraformFiles.get(fileName).forEach((file) => {
                    if (!file.isEdit) {
                        newFileAdded = true;
                    }
                });
            } else if (
                this.terraformFiles.get(fileName) &&
                this.terraformFiles.get(fileName)[0] &&
                !this.terraformFiles.get(fileName)[0].isEdit
            ) {
                newFileAdded = true;
            }
        });

        return newFileAdded;
    }
    uploadFilesOnS3(action, buttonRef) {
        const keys = Object.keys(this.response);
        let totalApis = 0;
        keys.forEach((file) => {
            totalApis += this.response[file].length;
        });
        keys.forEach((fileLabel) => {
            this.response[fileLabel].forEach((fileResponse) => {
                let file = this.terraformFiles.get(fileLabel).find((file) => {
                    if (file['name'] === fileResponse['fileName']) {
                        return file;
                    }
                });
                if (file.isEdit) {
                    file = file.file;
                }

                if (file) {
                    this.hitPresignedApi(
                        fileResponse['presignedUrl'],
                        file,
                        totalApis,
                        action,
                        buttonRef
                    );
                }
            });
        });
    }

    hitPresignedApi(url, input, numberOfApis, action, buttonRef) {
        const hitApi: IHitApi = {
            url: url,
            requestType: RequestType.PUT,
            config: {
                ignoreBaseUrl: true,
                authorization: null
            },

            function: (response) => {
                this.counter++;
                if (this.counter === numberOfApis) {
                    if (action === this.LoaderType.MAP_VARIABLES) {
                        this.getParsedVariables(buttonRef);
                        this.generateTfFilesKey();
                    } else {
                        this.saveAsDraft(buttonRef);
                    }
                }
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error
                );
                this.loader.set(action, false);
                buttonRef.loader = false;
            },
            input: input,

            uniqueIdentity: Symbol()
        };

        new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    saveAsDraft(buttonRef) {
        const inputs = this.prepareDraftInput();
        this.widgetCreationService.saveAsDraft(
            inputs,
            (res) => {
                this.loader.set(this.LoaderType.SAVE_AS_DRAFT, false);
                this.modalService.closeModal(null, this.modalData.modalId);
                buttonRef.loader = false;
            },
            (error) => {
                this.loader.set(this.LoaderType.SAVE_AS_DRAFT, false);
                buttonRef.loader = false;
            },
            this.widgetId
        );
    }
    prepareDraftInput() {
        this.generateTfFilesKey();
        const input = { ...this.stepOneData };
        input['newKeys'] = this.isNewFileUploaded;
        input['extraInfo']['terraformCloud'] =
            this.cloudFormGroup.get('cloud').value;
        input[Script.MAIN_TF_FILE] = this.mainTfFileKeys;
        input[Script.VARIABLE_FILE] = this.varTfFileKeys;
        input['configureLocation'] =
            this.locationConfigFormGroup &&
            this.locationConfigFormGroup.get('configureLocation').value ===
                ConfigureLocation.TAKE_INPUT
                ? true
                : false;
        if (this.tfVarsFiles && this.tfVarsFiles.length) {
            input[Script.TF_VAR_FILE] = this.tfVarsFiles;
        }
        return input;
    }
    generateTfFilesKey() {
        // main tf File keys addition logic start
        if (this.response && this.response[Script.MAIN_TF_FILE]) {
            this.mainTfFileKeys = this.response[Script.MAIN_TF_FILE].map(
                (file) => {
                    return file.key;
                }
            );
        }

        if (
            this.editResponse &&
            this.editResponse['filesDetails'] &&
            this.editResponse['filesDetails'][Script.MAIN_TF_FILE] &&
            !this.newFileAdded
        ) {
            const uploadedFileKey = this.editResponse['filesDetails'][
                Script.MAIN_TF_FILE
            ].map((file) => {
                return file.key;
            });
            this.mainTfFileKeys = [...this.mainTfFileKeys, ...uploadedFileKey];
        }
        // main tf files addition logic end.

        // variable file keys addition logic start
        if (this.response && this.response[Script.VARIABLE_FILE]) {
            this.varTfFileKeys = this.response[Script.VARIABLE_FILE].map(
                (file) => {
                    return file.key;
                }
            );
        }
        if (
            this.editResponse &&
            this.editResponse['filesDetails'] &&
            this.editResponse['filesDetails'][Script.VARIABLE_FILE] &&
            !this.newFileAdded
        ) {
            const uploadedFileKey = this.editResponse['filesDetails'][
                Script.VARIABLE_FILE
            ].map((file) => {
                return file.key;
            });
            this.varTfFileKeys = [...this.varTfFileKeys, ...uploadedFileKey];
        }
        // variables files addition logic end.

        // TF vars file key addition logic start
        if (
            this.editResponse &&
            this.editResponse['filesDetails'] &&
            this.editResponse['filesDetails'][Script.TF_VAR_FILE] &&
            this.editResponse['filesDetails'][Script.TF_VAR_FILE].length
        ) {
            this.tfVarsFiles = this.editResponse['filesDetails'][
                Script.TF_VAR_FILE
            ].map((file) => {
                return file.key;
            });
        }

        if (this.response && this.response[Script.TF_VAR_FILE].length) {
            this.tfVarsFiles = this.response[Script.TF_VAR_FILE].map((file) => {
                return file.key;
            });
        }
        // Tf vars file addition logic end.
    }
    getParsedVariables(buttonRef) {
        const input = this.generateParsedVariablesInput();
        const apiConfig = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                'parseVariableFile'
            ]
        );
        apiConfig.input = input;
        apiConfig.function = (response) => {
            if (this.isMapFieldWithoutDefault(response['formAttributeData'])) {
                this.widgetRef.notificationsService.showSnackBar(
                    'Default value not found for map type variable.',
                    true
                );
            } else {
                let warning: string = '';
                if (response.warnings && response.warnings.length) {
                    warning = response.warnings.join(',').trim();
                }
                this.goToNextLevel(response['formAttributeData'], warning);
            }

            this.loader.set(LoaderType.MAP_VARIABLES, false);
            this.counter = 0;
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
        };
        apiConfig.errorFunction = (error) => {
            this.loader.set(LoaderType.MAP_VARIABLES, false);
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
        };
        const id = Helper.generateUniqueKey(10);
        apiConfig.intactUrl = apiConfig.url;
        apiConfig.url = apiConfig.url.replace('{requestId}', id);
        new HitApi(
            apiConfig,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    isMapFieldWithoutDefault(fields) {
        let isMapFieldWithoutDefault: boolean = false;
        fields.forEach((field) => {
            let variableType = field['variableType'];
            variableType = variableType.split(' ').join('').split('=')[1];
            const variableFieldType = this.getType(variableType);
            if (variableFieldType === VariableFieldType.MAP) {
                if (!field.defaultValue) {
                    isMapFieldWithoutDefault = true;
                }
            }
        });
        return isMapFieldWithoutDefault;
    }
    getType(type) {
        const variableType = type;
        switch (true) {
            case variableType.includes('list(string)'):
                return VariableFieldType.LIST_STRING;
            case variableType.includes('list(number)'):
                return VariableFieldType.LIST_NUMBER;
            case variableType.includes('list(object'):
                return VariableFieldType.LIST_OBJECT;
            case variableType.includes('list'):
                return VariableFieldType.LIST_STRING;
            case variableType.includes('string'):
                return VariableFieldType.STRING;
            case variableType.includes('map'):
                return VariableFieldType.MAP;
            case variableType.includes('number'):
                return VariableFieldType.NUMBER;
            case variableType.includes('boolean'):
                return VariableFieldType.BOOLEAN;
            case variableType.includes('object'):
                return VariableFieldType.OBJECT;
            default:
                return VariableFieldType.STRING;
        }
    }
    generateParsedVariablesInput() {
        const input = {};
        let varFilesKeys = [];
        let tfVarsFileKey = [];
        if (this.response && this.response[Script.VARIABLE_FILE]) {
            varFilesKeys = this.response[Script.VARIABLE_FILE].map((file) => {
                return file.key;
            });
        }
        if (
            this.editResponse &&
            this.editResponse['filesDetails'] &&
            this.editResponse['filesDetails'][Script.VARIABLE_FILE] &&
            !this.newFileAdded
        ) {
            const uploadedFileKey = this.editResponse['filesDetails'][
                Script.VARIABLE_FILE
            ].map((file) => {
                return file.key;
            });
            varFilesKeys = [...varFilesKeys, ...uploadedFileKey];
        }
        if (this.response && this.response[Script.MAIN_TF_FILE]) {
            let mainTfFileKeys = [];
            mainTfFileKeys = this.response[Script.MAIN_TF_FILE].map((file) => {
                return file.key;
            });
            varFilesKeys = [...varFilesKeys, ...mainTfFileKeys];
        }

        if (
            this.editResponse &&
            this.editResponse['filesDetails'] &&
            this.editResponse['filesDetails'][Script.MAIN_TF_FILE] &&
            !this.newFileAdded
        ) {
            const uploadedFileKey = this.editResponse['filesDetails'][
                Script.MAIN_TF_FILE
            ].map((file) => {
                return file.key;
            });
            varFilesKeys = [...varFilesKeys, ...uploadedFileKey];
        }
        if (this.response && this.response[Script.TF_VAR_FILE]) {
            let tfVarsFileKeys = [];
            tfVarsFileKeys = this.response[Script.TF_VAR_FILE].map((file) => {
                return file.key;
            });
            tfVarsFileKey = [...tfVarsFileKey, ...tfVarsFileKeys];
        }

        if (
            this.editResponse &&
            this.editResponse['filesDetails'] &&
            this.editResponse['filesDetails'][Script.TF_VAR_FILE] &&
            !this.newFileAdded
        ) {
            const UploaderTfVarsFileKeys = this.editResponse['filesDetails'][
                Script.TF_VAR_FILE
            ].map((file) => {
                return file.key;
            });
            tfVarsFileKey = [...tfVarsFileKey, ...UploaderTfVarsFileKeys];
        }
        input['id'] = varFilesKeys;
        input['tfvars'] = tfVarsFileKey;
        return input;
    }
    deleteUploadedFiles(canDelete) {
        if (canDelete) {
            const input = this.prepareDelateFilesInput();
            const apiConfig = Helper.generateHitApiConfig(
                this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                    'deleteFilesFromS3'
                ]
            );
            apiConfig.input = input;
            apiConfig.function = (response) => {};
            apiConfig.errorFunction = (error) => {};
            new HitApi(
                apiConfig,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }
    prepareDelateFilesInput() {
        let ids = [...this.mainTfFileKeys, ...this.varTfFileKeys];
        if (this.tfVarsFiles && this.tfVarsFiles.length) {
            ids = [...ids, ...this.tfVarsFiles];
        }
        const value = Object.values(Script);
        if (this.editResponse && this.editResponse['filesDetails']) {
            value.forEach((fileLabel) => {
                this.editResponse['filesDetails'][fileLabel].forEach(
                    (fileResponse) => {
                        if (fileResponse) {
                            const index = ids.findIndex((id) =>
                                id.includes(fileResponse.fileName)
                            );
                            if (index !== -1) {
                                ids.splice(index, 1);
                            }
                        }
                    }
                );
            });
        }
        return { id: ids };
    }
    getFileFromS3() {
        const keys = Object.keys(this.editResponse['filesDetails']);
        let totalApis = 0;
        keys.forEach((file) => {
            totalApis += this.editResponse['filesDetails'][file].length;
        });
        keys.forEach((fileLabel) => {
            this.editResponse['filesDetails'][fileLabel].forEach(
                (fileResponse) => {
                    if (fileResponse) {
                        this.loadPage = true;
                        this.hitPresignedApiForFiles(
                            fileResponse['presignedUrl'],
                            fileResponse['fileName'],
                            fileLabel,
                            totalApis
                        );
                    }
                }
            );
        });
    }
    hitPresignedApiForFiles(url, key, fileLabel, numberOfApis) {
        const hitApi: IHitApi = {
            url: url,
            requestType: RequestType.GET,
            config: {
                ignoreBaseUrl: true,
                authorization: AuthorizationType.NOT_AUTHORIZED,
                extraData: { name: key, script: fileLabel },
                responseType: 'text'
            },
            function: (response, extraData) => {
                const file = {
                    name: extraData.name,
                    file: response,
                    isEdit: true
                };
                if (
                    this.terraformFiles.has(extraData.script) &&
                    this.terraformFiles.get(extraData.script).length &&
                    (extraData.script === Script.MAIN_TF_FILE ||
                        extraData.script === Script.VARIABLE_FILE)
                ) {
                    this.terraformFiles.get(extraData.script).push(file);
                } else {
                    this.terraformFiles.set(extraData.script, [file]);
                }
                this.counter++;
                if (this.counter === numberOfApis) {
                    this.loadPage = false;
                }
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error
                );
            },
            input: null,
            uniqueIdentity: Symbol()
        };

        new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    goToPreviousStep() {
        if (
            this.loader.get(LoaderType.SAVE_AS_DRAFT) ||
            this.loader.get(LoaderType.MAP_VARIABLES)
        ) {
            return;
        }
        this.generateTfFilesKey();
        this.multiStepFormService.stepData.get(this.modalData.modalId).set(2, {
            tfFiles: this.terraformFiles,
            cloud: this.cloudFormGroup.get('cloud').value,
            mainTfs: this.mainTfFileKeys,
            variableFiles: this.varTfFileKeys,
            tfVarsFiles: this.tfVarsFiles,
            deleteFilesCallBackFn: this.deleteUploadedFiles.bind(this),
            newKeys: this.isNewFileUploaded,
            configureLocation:
                this.locationConfigFormGroup &&
                this.locationConfigFormGroup.get('configureLocation').value ===
                    ConfigureLocation.TAKE_INPUT
                    ? true
                    : false
        });
        this.multiStepFormService.previousStep(this.modalData.modalId);
    }
    cloudValueChange(event) {
        if (event && event.cloud && event.cloud.includes('azure')) {
            this.showToggleField = true;
            this.setUpLoactionField();
        } else {
            this.showToggleField = false;
            this.locationConfigFormGenInputs = null;
            this.locationConfigFormGroup = null;
            this.configureLocation = false;
        }
    }
    ngOnDestroy(): void {
        this.resetModal.unsubscribe();
    }
}
enum Script {
    MAIN_TF_FILE = 'mainTfs',
    VARIABLE_FILE = 'variableFiles',
    TF_VAR_FILE = 'tfVarsFiles'
}
enum LoaderType {
    SAVE_AS_DRAFT = 'SAVE_AS_DRAFT',
    MAP_VARIABLES = 'MAP_VARIABLES'
}
enum ConfigureLocation {
    SCRIPT = 'script',
    TAKE_INPUT = 'input'
}
