<div class="form-container" *ngIf="!modalLoader">
    <span class="details">User Email Details</span>
    <app-form-generator
        *ngIf="emailContentFormGenInput"
        [formGeneratorInput]="emailContentFormGenInput"
        (formGroupRef)="emailContentFormRef = $event"
    ></app-form-generator>
</div>
<div class="tw-mt-auto tw-mb-3">
    <app-multi-button-generator
        *ngIf="!modalLoader"
        [buttonGenInputs]="buttonGenInputs"
    ></app-multi-button-generator>
</div>

<div class="overlay" *ngIf="modalLoader">
    <div class="overlay-content">
        <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
    </div>
</div>
