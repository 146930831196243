<form>
    <div class="form-data">
        <app-form-generator
            *ngIf="
                generalConfigFormGenInputs && !(widgetRef.loadingData | async)
            "
            [formGeneratorInput]="generalConfigFormGenInputs"
            (formGroupRef)="generalConfigFormGroup = $event"
        ></app-form-generator>
    </div>
    <div class="form-controls tw-mt-3">
        <app-button-generator
            [buttonGenInput]="saveButtonGenInput"
            (buttonRef)="save($event)"
        ></app-button-generator>
    </div>
</form>
