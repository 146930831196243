<div class="widget" [ngClass]="isCMS ? 'cms-fix' : 'widget-fix'">
    <div class="widget-head" *ngIf="!hideDescriptionHeader">
        <div class="widget-title">
            <div
                [ngClass]="
                    isEditingName
                        ? 'widget-name edit-able'
                        : 'widget-name not-edit-able'
                "
            >
                <ng-container *ngIf="!isEditingName">
                    {{ templateData["widgetName"] }}
                </ng-container>

                <ng-container *ngIf="isEdit && isEditingName">
                    <app-form-generator
                        *ngIf="titleFormInput"
                        [formGeneratorInput]="titleFormInput"
                        (formGroupRef)="titleFormGroupRef = $event"
                    >
                    </app-form-generator>
                </ng-container>

                <span
                    appIcon
                    [data]="pencilIcon"
                    (click)="isEditingName = true; this.initTitleForm()"
                    *ngIf="!isPreview"
                >
                </span>
            </div>
        </div>
    </div>
    <div [ngClass]="isPreview ? 'widget-content' : 'widget-content clear-fix'">
        <div
            class="description-container"
            [ngClass]="isCMS ? 'overflow-fix' : ''"
        >
            <ng-container *ngIf="!isEdit">
                <app-description-generator
                    *ngIf="templateData['description']"
                    [data]="{ oldDescription: templateData['description'] }"
                ></app-description-generator>
            </ng-container>

            <ng-container *ngIf="isEdit">
                <app-form-generator
                    [formGeneratorInput]="descriptionFormInput"
                    (formGroupRef)="descriptionFormGroupRef = $event"
                >
                </app-form-generator>
            </ng-container>
        </div>

        <div class="widget-data" *ngIf="!hideRequestButton">
            <div class="widget-container">
                <app-button-generator [buttonGenInput]="requestButton">
                </app-button-generator>
            </div>
        </div>
    </div>
</div>

<div class="widget-footer" *ngIf="!isPreview">
    <app-multi-button-generator
        [buttonGenInputs]="buttonsInput"
        [options]="buttonOptions"
    >
    </app-multi-button-generator>
</div>
