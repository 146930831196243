import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { GridOptions, RowEvent } from 'ag-grid-community';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { MultiButtonGeneratorComponent } from '../../multi-button-generator/multi-button-generator.component';
import {
    IButtonGeneratorInput,
    IMultiButtonOption,
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { SignupConfigStepOneComponent } from '../signup-config-step-one/signup-config-step-one.component';
import { SignupConfigStepTwoComponent } from '../signup-config-step-two/signup-config-step-two.component';
import { EmailConfigurationModalComponent } from '../../modal-templates/email-configuration-modal/email-configuration-modal.component';
import {
    AuditEmailConfigRuleItemType,
    AuditEmailConfigurationRuleType,
} from 'src/app/shared/interfaces/email-configuration/emailConfigurationTypes';
import { EmailConfigModalService } from 'src/app/shared/services/assessment/email-config-service/email-config-modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import {
    CustomSignUpFormRequestPayloadType,
    CustomSignUpFormResponseType,
    CustomSignUpFormStepOneType,
    CustomSignUpFormStepTwoType,
    CustomSignUpStatusEnum,
} from 'src/app/shared/interfaces/ICustomSignTypes';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Observable } from 'rxjs';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import { ActionState } from 'src/app/shared/enums/ActionState';
import { ActionVisibility } from 'src/app/shared/enums/ActionVisibility';

@Component({
    selector: 'app-custom-signup-configuration-list',
    templateUrl: './custom-signup-configuration-list.component.html',
    styleUrls: ['./custom-signup-configuration-list.component.sass'],
})
export class CustomSignupConfigurationListComponent implements OnInit {
    public widgetRef: Widget;
    public tableGenInput: ITableGeneratorInput = null;
    public gridRef: GridOptions;
    private modalId: Symbol;

    constructor(
        private widgetData: WidgetInjectedData,
        private multiStepFormService: MultiStepFormService,
        private cdr: ChangeDetectorRef,
        private emailConfigService: EmailConfigModalService
    ) {
        this.submitSignUpItem = this.submitSignUpItem.bind(this);
        this.widgetRef = this.widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
        this.setUpBulkActions();
    }

    private setUpBasics() {
        this.tableGenInput = {
            buttons: [
                {
                    buttonName: 'Create Sign up',
                    buttonType: ButtonType.FLAT,
                    buttonColorType: ButtonColorType.PRIMARY,
                    function: () => {
                        this.openSignUpPageConfigModal();
                    },
                    showLoader: false,
                },
            ],

            listExtraction: {
                type: 'DIRECT',
            },
            refreshWidgetCallback: () => {
                this.widgetRef.showNotificationBadge.next(false);
            },
            noDataMessage: 'No Signup Pages Available',

            columns: [
                {
                    columnName: 'Sign up Page Name',
                    columnKey: 'name',
                },
                {
                    columnKey: 'status',
                    columnName: 'Status',
                },
                {
                    columnKey: 'createdByEmail',
                    columnName: 'Created By',
                },
                {
                    columnKey: 'createdOn',
                    columnName: 'Created On',
                },
                {
                    columnKey: 'updatedOn',
                    columnName: 'Last modified On',
                },
                {
                    columnKey: 'viewId',
                    columnName: 'Associated View',
                },
                {
                    columnKey: 'url',
                    columnName: 'URL',
                },
                {
                    columnKey: 'action',
                    columnName: 'Action',
                    pinned: 'right',
                    filter: false,
                    minWidth: 320,
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: RowEvent) => {
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'Edit',
                                buttonColorType: ButtonColorType.INFO,
                                buttonType: ButtonType.TEXT,
                                showLoader: true,
                                disable: !rowData.data.isEditable,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    buttonRef.loader = true;
                                    this.cdr.detectChanges();
                                    this.fetchPreviewData(
                                        rowData.data.id
                                    ).subscribe({
                                        next: (res) => {
                                            buttonRef.loader = false;
                                            this.cdr.detectChanges();
                                            this.openSignUpPageConfigModal(
                                                res,
                                                'EDIT'
                                            );
                                        },
                                        error: (err) => {
                                            buttonRef.loader = false;
                                            this.cdr.detectChanges();
                                            this.widgetRef.notificationsService.showSnackBar(
                                                `${
                                                    err?.message ||
                                                    'Something went wrong'
                                                }`
                                            );
                                        },
                                    });
                                },
                            },
                            {
                                buttonName: 'Duplicate',
                                buttonColorType: ButtonColorType.SUCCESS,
                                buttonType: ButtonType.TEXT,
                                showLoader: true,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    buttonRef.loader = true;
                                    this.cdr.detectChanges();
                                    this.fetchPreviewData(
                                        rowData.data.id
                                    ).subscribe({
                                        next: (res) => {
                                            buttonRef.loader = false;
                                            this.cdr.detectChanges();
                                            this.handleDuplicate(res);
                                        },
                                        error: (err) => {
                                            buttonRef.loader = false;
                                            this.cdr.detectChanges();
                                            this.widgetRef.notificationsService.showSnackBar(
                                                `${
                                                    err?.message ||
                                                    'Something went wrong'
                                                }`
                                            );
                                        },
                                    });
                                },
                            },
                            {
                                buttonName: 'Preview',
                                buttonColorType: ButtonColorType.INFO,
                                buttonType: ButtonType.TEXT,
                                disable:
                                    rowData.data.status !==
                                    CustomSignUpStatusEnum.Active,
                                hoverText:
                                    rowData.data.status !==
                                    CustomSignUpStatusEnum.Active
                                        ? 'To preview, please activate the sign-up form'
                                        : '',
                                function: () => {
                                    if (rowData.data?.url) {
                                        Helper.navigateTo(
                                            rowData.data?.url,
                                            'NEW_PAGE'
                                        );
                                    }
                                },
                            },
                            {
                                buttonName: 'Delete',
                                buttonColorType: ButtonColorType.WARN,
                                buttonType: ButtonType.TEXT,
                                function: () => {
                                    this.deleteRow(rowData.data.id);
                                },
                                disable: !rowData.data.isEditable,
                            },
                        ];
                        const buttonOptions: IMultiButtonOption = {
                            layout: {
                                justifyContent: 'space-evenly',
                            },
                        };
                        rowData['options'] = buttonOptions;
                        rowData['buttonGenInputs'] = buttons;
                        return rowData;
                    },
                },
            ],

            afterResponse: null,
            selection: 'multiple',
            columnResizeCount: 8,
        };
    }

    private deleteRow(id: string) {
        this.widgetRef.modalService.openConfirmationModal({
            modalName: 'Delete Signup page',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash',
            },
            contextIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclaimation-triangle',
            },
            confirmationMessage: `Are you sure you want to delete selected signup page ? `,
            buttonColorType: ButtonColorType.WARN,
            buttonText: 'Delete',
            loaderOnExec: true,
            function: (modalId: Symbol) => {
                const deleteApiConfig = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo.delete
                );
                deleteApiConfig.config = {
                    ...deleteApiConfig.config,
                    defaultHeaders: {
                        'content-type': 'application/json',
                    },
                };
                deleteApiConfig.url = deleteApiConfig.url.replace('{id}', id);
                deleteApiConfig.function = () => {
                    this.widgetRef.notificationsService.showSnackBar(
                        'Deleted successfully'
                    );
                    this.widgetRef.refreshWidget();
                    this.widgetRef.modalService.closeModal(null, modalId);
                };
                deleteApiConfig.errorFunction = (err) => {
                    this.widgetRef.notificationsService.showSnackBar(
                        `${err?.message || 'Something went wrong'}`
                    );
                    this.widgetRef.refreshWidget();
                    this.widgetRef.modalService.closeModal(null, modalId);
                };
                new HitApi(
                    deleteApiConfig,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            },
        });
    }

    private setUpBulkActions() {
        const actions: IAction[] = [];
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.bulkDelete();
            },
            icon: {
                type: IconType.SVG,
                class: 'trash_icon_filled',
                text: 'Bulk Delete',
                extraClass: 'inline-fix-box-1 svg-black-fill',
            },
        });
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: 'Set as Inactive',
            function: () => {
                this.bulkUpdatePageStates();
            },
            icon: {
                type: IconType.SVG,
                class: 'suspend',
                text: 'Bulk Inactive',
                extraClass: 'inline-fix-box-1 svg-black-fill',
            },
        });
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: 'Set as Active',
            function: () => {
                this.bulkUpdatePageStates(true);
            },
            icon: {
                type: IconType.SVG,
                class: 'resume',
                text: 'Bulk Active',
                extraClass: 'inline-fix-box-1 svg-black-fill',
            },
        });
        this.widgetRef.operationalActions.value.set('Bulk Action', actions);
        this.widgetRef.operationalActions.next(
            this.widgetRef.operationalActions.value
        );
    }

    private bulkDelete() {
        if (!this.gridRef.api.getSelectedRows().length) {
            this.widgetRef.notificationsService.showSnackBar(
                'Select atleast 1 signup page',
                true
            );
            return;
        }
        const inputRowsIdList: string[] = [];
        this.gridRef.api.getSelectedRows().forEach((row) => {
            if (row.isEditable) {
                inputRowsIdList.push(row.id);
            }
        });

        if (!inputRowsIdList.length) {
            this.widgetRef.notificationsService.showSnackBar(
                'The active sign-up form cannot be deleted.',
                true
            );
            return;
        }

        if (
            inputRowsIdList.length !== this.gridRef.api.getSelectedRows().length
        ) {
            this.widgetRef.notificationsService.showSnackBar(
                'Some of the selected sign-up forms are currently active. To delete them, please click "Continue" to deactivate them first.',
                true
            );
            return;
        }

        this.widgetRef.modalService.openConfirmationModal({
            modalName: 'Delete Signup page',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash',
            },
            contextIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclaimation-triangle',
            },
            confirmationMessage: `Are you sure you want to delete selected signup pages ? `,
            buttonColorType: ButtonColorType.WARN,
            buttonText: 'Delete',
            loaderOnExec: true,
            function: (modalId: Symbol) => {
                const apiArgs: IHitApi = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo['batchDelete']
                );
                apiArgs.input = inputRowsIdList;
                apiArgs.function = (response) => {
                    this.widgetRef.notificationsService.showSnackBar(
                        'Signup pages deleted successfully'
                    );
                    this.widgetRef.refreshWidget();
                    this.widgetRef.showNotificationBadge.next(false);
                    this.widgetRef.modalService.closeModal(null, modalId);
                };
                apiArgs.errorFunction = (error) => {
                    this.widgetRef.notificationsService.showSnackBar(
                        error && error.error && error.error.message
                            ? error.error.message
                            : 'Error deleting signup pages',
                        true
                    );
                    this.widgetRef.refreshWidget();
                    this.widgetRef.showNotificationBadge.next(false);
                    this.widgetRef.modalService.closeModal(null, modalId);
                };
                new HitApi(
                    apiArgs,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            },
        });
    }

    public bulkUpdatePageStates(isSetToActive?: boolean) {
        if (!this.gridRef.api.getSelectedRows().length) {
            this.widgetRef.notificationsService.showSnackBar(
                'Select atleast 1 signup page',
                true
            );
            return;
        }
        const inputRowsIdList = this.gridRef.api
            .getSelectedRows()
            .map((row) => row['id']);
        this.widgetRef.modalService.openConfirmationModal({
            modalName: 'Update status',
            contextIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclaimation-triangle',
            },
            confirmationMessage: isSetToActive
                ? 'Are you sure you want to update selected page status to Active'
                : 'Are you sure you want to update selected page status to IActive',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonText: 'Continue',
            loaderOnExec: true,
            function: (modalId: Symbol) => {
                const apiArgs: IHitApi = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo['batchUpdate']
                );
                const params = new URLSearchParams({
                    status: `${!!isSetToActive}`,
                });
                apiArgs.url = apiArgs.url + `?${params.toString()}`;
                apiArgs.config = {
                    ...apiArgs.config,
                    defaultHeaders: {
                        'content-type': 'application/json',
                    },
                };
                apiArgs.input = inputRowsIdList;
                apiArgs.function = (response) => {
                    this.widgetRef.notificationsService.showSnackBar(
                        'Status updated successfully'
                    );
                    this.widgetRef.refreshWidget();
                    this.widgetRef.showNotificationBadge.next(false);
                    this.widgetRef.modalService.closeModal(null, modalId);
                };
                apiArgs.errorFunction = (error) => {
                    this.widgetRef.notificationsService.showSnackBar(
                        error && error.error && error.error.message
                            ? error.error.message
                            : 'Error updating status',
                        true
                    );
                    this.widgetRef.refreshWidget();
                    this.widgetRef.showNotificationBadge.next(false);
                    this.widgetRef.modalService.closeModal(null, modalId);
                };
                new HitApi(
                    apiArgs,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            },
        });
    }

    private handleDuplicate(formData?: CustomSignUpFormResponseType) {
        const cleanFormData = Helper.cloneDeep(
            formData
        ) as CustomSignUpFormResponseType;
        delete cleanFormData.id;
        delete cleanFormData.createdByEmail;
        delete cleanFormData.updatedOn;
        delete cleanFormData.lastModifiedBy;
        delete cleanFormData.createdOn;
        delete cleanFormData.createdByEmail;
        cleanFormData.formSections = cleanFormData.formSections.map((step) => {
            delete step['id'];
            step.fields = step.fields.map((field) => {
                delete field.id;
                return field;
            });
            return step;
        });
        this.openSignUpPageConfigModal(cleanFormData, 'CREATE');
    }

    private openSignUpPageConfigModal(
        formData?: CustomSignUpFormResponseType,
        action: 'CREATE' | 'EDIT' = 'CREATE'
    ) {
        const isEdit = action === 'EDIT';

        const alreadyUsedNameList = [];
        const alreadyUsedUrlList = [];
        const apiResponse = this.widgetRef.apiResponse as unknown as Array<any>;
        apiResponse?.forEach((el) => {
            if (isEdit) {
                el?.name &&
                    el?.name !== formData?.name &&
                    alreadyUsedNameList.push(el?.name);
                el?.url &&
                    el?.url !== formData?.url &&
                    alreadyUsedUrlList.push(el?.url);
            } else {
                el?.name && alreadyUsedNameList.push(el?.name);
                el?.url && alreadyUsedUrlList.push(el?.url);
            }
        });
        this.emailConfigService.clearAllConfigData();
        const rulesConfig = formData?.emailRules || [];
        this.modalId = Symbol();
        const modalArgs: IModalData = {
            modalName: 'Sign up Page Configuration',
            sourceId: this.widgetRef.uniqueIdentity,
            modalType: ModalType.MIDDLE,
            modalId: this.modalId,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-chalkboard-teacher',
            },
            extraStepContainerClass: 'remove-form-space',
            hideSteps: true,
            maxHeightVh: 100,
            modalSteps: [
                {
                    stepName: 'Sign up Page Configuration',
                    stepData: {
                        componentToLoad: SignupConfigStepOneComponent,
                        payload: {
                            data: {
                                widgetInfo:
                                    this.widgetRef.widgetData.widgetInfo,
                                formData,
                                alreadyUsedNameList,
                                alreadyUsedUrlList,
                            },
                        },
                    },
                },
                {
                    stepName: 'Sign up Page Configuration',
                    stepData: {
                        componentToLoad: SignupConfigStepTwoComponent,
                        payload: {
                            data: {
                                formData,
                                isEdit,
                                handleSubmit: this.submitSignUpItem,
                                action,
                            },
                        },
                    },
                },
                {
                    stepName: 'Sign up Page Configuration',
                    stepData: {
                        componentToLoad: EmailConfigurationModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                emailConfigRulesData:
                                    rulesConfig && rulesConfig.length > 0
                                        ? rulesConfig
                                        : [],
                                selectedRuleType:
                                    AuditEmailConfigurationRuleType.CUSTOM,
                                isSignupFlow: true,
                                formDataSignUp: formData,
                                handleSubmit: this.submitSignUpItem,
                                action,
                            },
                        },
                    },
                },
            ],
            modalAutoHeight: true,
            modalAutoWidth: true,
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }

    private submitSignUpItem(
        buttonRef: IButtonGeneratorInput,
        formDataSignUp?: CustomSignUpFormResponseType,
        action: 'CREATE' | 'EDIT' = 'CREATE'
    ) {
        buttonRef.loader = true;
        this.cdr.detectChanges();
        const selectedRuleConfigType =
            this.emailConfigService.selectedRuleConfig;
        const rulesConfig: Omit<
            AuditEmailConfigRuleItemType,
            'triggerPoints'
        >[] =
            this.emailConfigService.emailConfigRules
                .get(selectedRuleConfigType)
                ?.map((rule) => {
                    const ruleClone = Helper.cloneDeep(
                        rule
                    ) as AuditEmailConfigRuleItemType;
                    delete ruleClone.triggerPoints;
                    return ruleClone;
                }) || [];
        const stepDataMap = this.multiStepFormService.stepData.get(
            this.modalId
        );
        const stepOneData: CustomSignUpFormStepOneType = stepDataMap.get(1);
        const stepTwoData: CustomSignUpFormStepTwoType = stepDataMap.get(2);

        const submitRequest = (imageUrl?: string) => {
            const payload: CustomSignUpFormRequestPayloadType = {
                ...stepOneData,
                formSections: stepTwoData.formSections,
                emailRules: rulesConfig,
                isActive: false,
            };
            if (imageUrl) {
                payload.imageS3Url = imageUrl;
            }
            const ApiConfig = Helper.generateHitApiConfig(
                action === 'EDIT'
                    ? this.widgetRef.widgetData.widgetInfo.update
                    : this.widgetRef.widgetData.widgetInfo.create
            );
            if (action === 'EDIT') {
                payload['id'] = formDataSignUp.id;
                ApiConfig.url = ApiConfig.url.replace(
                    '{id}',
                    formDataSignUp.id
                );
            }
            ApiConfig.input = payload;
            ApiConfig.function = () => {
                buttonRef.loader = false;
                this.cdr.detectChanges();
                this.widgetRef.modalService.closeModal(null, this.modalId);
                this.widgetRef.refreshWidget();
                this.widgetRef.notificationsService.showSnackBar(
                    'Sign up created/updated successfully',
                    false,
                    '',
                    {
                        duration: 3000,
                    }
                );
            };
            ApiConfig.errorFunction = (err) => {
                buttonRef.loader = false;
                this.cdr.detectChanges();
                this.widgetRef.notificationsService.showSnackBar(
                    `${err?.message || 'Something went wrong'}`,
                    true,
                    '',
                    {
                        duration: 3000,
                    }
                );
            };
            new HitApi(
                ApiConfig,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        };

        if (stepTwoData.file) {
            this.uploadFile(stepTwoData.file).subscribe({
                next: (imageS3Url) => {
                    submitRequest(imageS3Url);
                },
                error: (err) => {
                    buttonRef.loader = false;
                    this.cdr.detectChanges();
                    this.widgetRef.notificationsService.showSnackBar(
                        `${err?.message || 'Something went wrong'}`,
                        true,
                        '',
                        {
                            duration: 3000,
                        }
                    );
                },
            });
        } else {
            submitRequest();
        }
    }

    private uploadFile(file: File): Observable<string> {
        return new Observable((subscriber) => {
            const getPreSignedUrlApiConfig = Helper.generateHitApiConfig(
                this.widgetRef.widgetData.widgetInfo.additionalApisForWidget[
                    'generatePresignedUrl'
                ]
            );

            const searchParams = new URLSearchParams({
                'image-name': file.name,
            });
            getPreSignedUrlApiConfig.config = {
                ...getPreSignedUrlApiConfig.config,
                defaultHeaders: { 'content-type': 'application/json' },
            };
            getPreSignedUrlApiConfig.url += `?${searchParams.toString()}`;
            getPreSignedUrlApiConfig.function = (res) => {
                const uploadedFileApiConfig: IHitApi = {
                    url: res['preSignedUrl'],
                    requestType: RequestType.PUT,
                    input: file,
                    uniqueIdentity: Symbol(),
                    config: {
                        authorization: AuthorizationType.NOT_AUTHORIZED,
                        ignoreBaseUrl: true,
                    },
                    function: () => {
                        subscriber.next(res['key']);
                        subscriber.complete();
                    },
                    errorFunction: () => {
                        subscriber.error();
                        subscriber.complete();
                    },
                };
                new HitApi(
                    uploadedFileApiConfig,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            };
            getPreSignedUrlApiConfig.errorFunction = (err) => {
                subscriber.error(err);
                subscriber.complete();
            };
            new HitApi(
                getPreSignedUrlApiConfig,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        });
    }

    private fetchPreviewData(
        id: string
    ): Observable<CustomSignUpFormResponseType> {
        return new Observable((subscriber) => {
            const previewApiConfig = Helper.generateHitApiConfig(
                this.widgetRef.widgetData.widgetInfo.preview
            );
            previewApiConfig.config = {
                ...previewApiConfig.config,
                defaultHeaders: {
                    'content-type': 'application/json',
                },
            };
            previewApiConfig.url = previewApiConfig.url.replace('{id}', id);
            previewApiConfig.function = (res) => {
                subscriber.next(res);
                subscriber.complete();
            };
            previewApiConfig.errorFunction = (err) => {
                subscriber.error(err);
                subscriber.complete();
            };
            new HitApi(
                previewApiConfig,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        });
    }
}
