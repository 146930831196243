<div class="container" *ngIf="!loadPage">
    <div class="cloud-container">
        <div class="drop-down">
            <app-form-generator
                [formGeneratorInput]="cloudFormGenInput"
                (formGroupRef)="cloudFormGroup = $event"
                (valuesChange)="cloudValueChange($event)"
            ></app-form-generator>
        </div>
    </div>
    <ng-container *ngIf="showToggleField">
        <div class="location-config">
            <app-form-generator
                [formGeneratorInput]="locationConfigFormGenInputs"
                (formGroupRef)="locationConfigFormGroup = $event"
            ></app-form-generator>
        </div>
    </ng-container>
    <div class="script-container">
        <div class="script-heading">
            <span>Upload the following terraform scripts here</span>
        </div>
        <div class="info">
            <span
                appIcon
                [data]="infoHeading"
                class="info-icon clickable"
            ></span
            ><span
                >Do not upload the provider file or mention it's parameters in
                the files. Also make sure that the “map” type variables must
                include default value in the files.</span
            >
        </div>
        <div class="tf-file-container">
            <div class="tf-file">
                <div class="file-label">
                    Upload the main TF Files<span
                        class="mat-form-field-required-marker"
                        >&nbsp;*</span
                    >
                    <span
                        appIcon
                        [matTooltip]="infoMessages['mainTfFiles']"
                        [data]="infoIcon"
                        class="info-icon clickable"
                    ></span>
                </div>
                <div
                    class="file-container"
                    *ngIf="terraformFiles.get(Script.MAIN_TF_FILE)"
                >
                    <div
                        class="test"
                        *ngFor="
                            let file of terraformFiles.get(Script.MAIN_TF_FILE)
                        "
                    >
                        <div class="file-name">
                            <div class="name">{{ file.name }}</div>
                            <div
                                class="cross-icon"
                                [matTooltip]="'Remove File'"
                                (click)="
                                    removeTfFile(file.name, Script.MAIN_TF_FILE)
                                "
                                appIcon
                                [data]="removeFile"
                            ></div>
                        </div>
                        <div (click)="preview(file.name, file)" class="preview">
                            Preview
                        </div>
                    </div>
                </div>
                <div class="button">
                    <input
                        #inp
                        type="file"
                        (change)="fileSelected($event, Script.MAIN_TF_FILE)"
                        name=""
                        id="mainTfs"
                        accept=".tf"
                        multiple
                    />
                    <label
                        for="mainTfs"
                        class="text"
                        [class.pointer-null]="
                            loader.get(LoaderType.SAVE_AS_DRAFT) ||
                            loader.get(LoaderType.MAP_VARIABLES)
                        "
                    >
                        Upload Files
                    </label>
                </div>
            </div>
            <div class="tf-file">
                <div class="file-label">
                    Upload Variable Files
                    <span
                        appIcon
                        [matTooltip]="infoMessages['variableFile']"
                        [data]="infoIcon"
                        class="info-icon clickable"
                    ></span>
                </div>
                <div
                    class="file-container"
                    *ngIf="terraformFiles.get(Script.VARIABLE_FILE)"
                >
                    <div
                        class="test"
                        *ngFor="
                            let file of terraformFiles.get(Script.VARIABLE_FILE)
                        "
                    >
                        <div class="file-name">
                            <div class="name">{{ file.name }}</div>
                            <div
                                class="cross-icon"
                                [matTooltip]="'Remove File'"
                                (click)="
                                    removeTfFile(
                                        file.name,
                                        Script.VARIABLE_FILE
                                    )
                                "
                                appIcon
                                [data]="removeFile"
                            ></div>
                        </div>
                        <div (click)="preview(file.name, file)" class="preview">
                            Preview
                        </div>
                    </div>
                </div>
                <div class="button">
                    <input
                        #inp
                        type="file"
                        (change)="fileSelected($event, Script.VARIABLE_FILE)"
                        name=""
                        id="var-file-reader"
                        multiple
                        accept=".tf"
                    />
                    <label
                        for="var-file-reader"
                        class="text"
                        [class.pointer-null]="
                            loader.get(LoaderType.SAVE_AS_DRAFT) ||
                            loader.get(LoaderType.MAP_VARIABLES)
                        "
                    >
                        Upload Files
                    </label>
                </div>
            </div>
            <div class="tf-file">
                <div class="file-label">
                    Upload TFVARS File
                    <span
                        appIcon
                        [matTooltip]="infoMessages['varFile']"
                        [data]="infoIcon"
                        class="info-icon clickable"
                    ></span>
                </div>
                <div
                    class="file-container"
                    *ngIf="terraformFiles.get(Script.TF_VAR_FILE)"
                >
                    <div
                        class="test"
                        *ngFor="
                            let file of terraformFiles.get(Script.TF_VAR_FILE)
                        "
                    >
                        <div class="file-name">
                            <div class="name">{{ file.name }}</div>
                            <div
                                class="cross-icon"
                                [matTooltip]="'Remove File'"
                                (click)="
                                    removeTfFile(file.name, Script.TF_VAR_FILE)
                                "
                                appIcon
                                [data]="removeFile"
                            ></div>
                        </div>
                        <div (click)="preview(file.name, file)" class="preview">
                            Preview
                        </div>
                    </div>
                </div>
                <div class="button">
                    <input
                        #inp
                        type="file"
                        (change)="fileSelected($event, Script.TF_VAR_FILE)"
                        name=""
                        id="tf-var-file"
                        accept=".tf"
                    />
                    <label
                        for="tf-var-file"
                        class="text"
                        [class.pointer-null]="
                            loader.get(LoaderType.SAVE_AS_DRAFT) ||
                            loader.get(LoaderType.MAP_VARIABLES)
                        "
                    >
                        Upload Files
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="controls" *ngIf="!loadPage">
    <div class="back-button">
        <app-button-generator
            [buttonGenInput]="backButtonGenInput"
        ></app-button-generator>
    </div>
    <div class="action-button">
        <app-multi-button-generator [buttonGenInputs]="actionButtonGenInput">
        </app-multi-button-generator>
    </div>
</div>
<div class="overlay" *ngIf="loadPage">
    <div class="overlay-content">
        <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
    </div>
</div>
