<!-- field type one start -->
<div
    *ngIf="field.appearance === AppearanceType.TYPE_1"
    class="ng-select-dropdown-container"
>
    <div
        [class]="
            'ng-select-custom-material-wrapper ng-select-custom-material-wrapper-outline'
        "
        [class.error]="
            field.groupByKey
                ? formGroup.get(field.groupByKey).get(field.name) &&
                  formGroup.get(field.groupByKey).get(field.name).touched &&
                  formGroup.get(field.groupByKey).get(field.name).errors
                : formGroup.get(field.name) &&
                  formGroup.get(field.name).touched &&
                  formGroup.get(field.name).errors
        "
        [class.disabled]="
            field.groupByKey
                ? formGeneratorInput.state === FormState.IDLE ||
                  (field.populateFromControl &&
                      !formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl)) ||
                  (formGroup
                      .get(field.groupByKey)
                      .get(field.populateFromControl) &&
                      (!formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl).value ||
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value.length))
                : formGeneratorInput.state === FormState.IDLE ||
                  (field.populateFromControl &&
                      !formGroup.get(field.populateFromControl)) ||
                  (formGroup.get(field.populateFromControl) &&
                      (!formGroup.get(field.populateFromControl).value ||
                          !formGroup.get(field.populateFromControl).value
                              .length))
        "
        [matTooltip]="
            field.groupByKey
                ? (field.populateFromControl &&
                      !formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl)) ||
                  (formGroup
                      .get(field.groupByKey)
                      .get(field.populateFromControl) &&
                      (!formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl).value ||
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value.length))
                    ? 'Select ' +
                      (
                          field.populateFromControl
                          | fieldDataFromControlName : formGeneratorInput.fields
                      ).label +
                      ' values first'
                    : null
                : (field.populateFromControl &&
                      !formGroup.get(field.populateFromControl)) ||
                  (formGroup.get(field.populateFromControl) &&
                      (!formGroup.get(field.populateFromControl).value ||
                          !formGroup.get(field.populateFromControl).value
                              .length))
                ? 'Select ' +
                  (
                      field.populateFromControl
                      | fieldDataFromControlName : formGeneratorInput.fields
                  ).label +
                  ' values first'
                : null
        "
        [matTooltipShowDelay]="1000"
        [matTooltipPosition]="'before'"
    >
        <legend
            class="dropdown-legend"
            *ngIf="
                field.groupByKey
                    ? formGroup.get(field.groupByKey).get(field.name) &&
                      formGroup.get(field.groupByKey).get(field.name).value
                    : formGroup.get(field.name) &&
                      formGroup.get(field.name).value
            "
        >
            {{
                field.showLabelInDropdownLegend && field.label
                    ? field.label
                    : field.placeholder
                    ? field.placeholder
                    : field.label
            }}
            <span
                *ngIf="
                    field.required !== undefined &&
                    field.required &&
                    !field.showLabel
                "
                class="mat-form-field-required-marker"
                >&nbsp;*</span
            >
            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
                >{{ field.prefixIcon.iconData.class }}</mat-icon
            >
        </legend>
        <ng-select
            [class.field-required]="
                field.required !== undefined && !field.showLabel
                    ? field.required
                    : false
            "
            style="z-index: 10"
            [appearance]="'outline'"
            [bindValue]="
                field.getKey || field.getKey === null ? field.getKey : 'id'
            "
            [bindLabel]="field.showKey ? field.showKey : 'label'"
            [items]="
                !loading.get(field.name) && field.listData
                    ? field.listData
                    : null
            "
            [class.error]="
                field.groupByKey
                    ? formGroup.get(field.groupByKey).get(field.name).touched &&
                      formGroup.get(field.groupByKey).get(field.name).errors
                    : formGroup.get(field.name).touched &&
                      formGroup.get(field.name).errors
            "
            [closeOnSelect]="true"
            [placeholder]="field.placeholder ? field.placeholder : field.label"
            [loading]="loading.get(field.name)"
            [appendTo]="'body'"
            [loadingText]="
                field.loadingMessage ? field.loadingMessage : 'Loading...'
            "
            dropdownPosition="auto"
            [clearable]="true"
            [formControl]="control"
            [errorStateMatcher]="matcher"
            [multiple]="false"
            (scroll)="scroll($event)"
            [virtualScroll]="true"
            (change)="
                onValueChange($event);
                field.onChange
                    ? field.onChange($event, formGroup, field.name)
                    : null
            "
            [compareWith]="compareWith"
            (open)="showOverlay = true"
            (close)="showOverlay = false"
        >
            <ng-container *ngIf="field.dropdownItemRenderImageFromId">
                <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-index="index"
                    let-search="searchTerm"
                >
                    <div
                        class="image-container"
                        appIcon
                        [data]="
                            field.dropdownItemRenderImageType
                                | attributeToIconDirective : item.id
                        "
                    ></div>
                    {{ item.label }}
                </ng-template>
            </ng-container>
            <ng-container *ngIf="!field.dropdownItemRenderImageFromId">
                <ng-template ng-option-tmp let-item="item">
                    <div
                        title="{{
                            item['hoveredText']
                                ? item['hoveredText']
                                : item[field.showKey]
                                ? item[field.showKey]
                                : item['label']
                                ? item['label']
                                : ''
                        }}"
                    >
                        <span class="common-label">{{
                            item[field.showKey]
                                ? item[field.showKey]
                                : item.label
                        }}</span>
                    </div>
                </ng-template>
            </ng-container>
        </ng-select>
        <!-- Handling errors -->
        <ng-container *ngIf="!field.groupByKey">
            <ng-template *ngTemplateOutlet="error"></ng-template>
        </ng-container>
        <ng-container *ngIf="field.groupByKey">
            <ng-template *ngTemplateOutlet="groupError"></ng-template>
        </ng-container>
    </div>
</div>
<!-- field type one end -->

<!-- field type two start -->
<div
    *ngIf="field.appearance === AppearanceType.TYPE_2"
    class="ng-select-dropdown-container"
>
    <div
        [class]="
            'ng-select-custom-material-wrapper ng-select-custom-material-wrapper-outline'
        "
        [class.error]="
            field.groupByKey
                ? formGroup.get(field.groupByKey).get(field.name) &&
                  formGroup.get(field.groupByKey).get(field.name).touched &&
                  formGroup.get(field.groupByKey).get(field.name).errors
                : formGroup.get(field.name) &&
                  formGroup.get(field.name).touched &&
                  formGroup.get(field.name).errors
        "
        [class.disabled]="
            field.groupByKey
                ? formGeneratorInput.state === FormState.IDLE ||
                  (field.populateFromControl &&
                      !formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl)) ||
                  (formGroup
                      .get(field.groupByKey)
                      .get(field.populateFromControl) &&
                      (!formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl).value ||
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value.length))
                : formGeneratorInput.state === FormState.IDLE ||
                  (field.populateFromControl &&
                      !formGroup.get(field.populateFromControl)) ||
                  (formGroup.get(field.populateFromControl) &&
                      (!formGroup.get(field.populateFromControl).value ||
                          !formGroup.get(field.populateFromControl).value
                              .length))
        "
        [matTooltip]="
            field.groupByKey
                ? (field.populateFromControl &&
                      !formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl)) ||
                  (formGroup
                      .get(field.groupByKey)
                      .get(field.populateFromControl) &&
                      (!formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl).value ||
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value.length))
                    ? 'Select ' +
                      (
                          field.populateFromControl
                          | fieldDataFromControlName : formGeneratorInput.fields
                      ).label +
                      ' values first'
                    : null
                : (field.populateFromControl &&
                      !formGroup.get(field.populateFromControl)) ||
                  (formGroup.get(field.populateFromControl) &&
                      (!formGroup.get(field.populateFromControl).value ||
                          !formGroup.get(field.populateFromControl).value
                              .length))
                ? 'Select ' +
                  (
                      field.populateFromControl
                      | fieldDataFromControlName : formGeneratorInput.fields
                  ).label +
                  ' values first'
                : null
        "
        [matTooltipShowDelay]="1000"
        [matTooltipPosition]="'before'"
    >
        <ng-select
            [class.field-required]="
                field.required !== undefined && !field.showLabel
                    ? field.required
                    : false
            "
            style="z-index: 10"
            [appearance]="'outline'"
            [bindValue]="
                field.getKey || field.getKey === null ? field.getKey : 'id'
            "
            [bindLabel]="field.showKey ? field.showKey : 'label'"
            [items]="
                !loading.get(field.name) && field.listData
                    ? field.listData
                    : null
            "
            [class.error]="
                field.groupByKey
                    ? formGroup.get(field.groupByKey).get(field.name).touched &&
                      formGroup.get(field.groupByKey).get(field.name).errors
                    : formGroup.get(field.name).touched &&
                      formGroup.get(field.name).errors
            "
            [closeOnSelect]="true"
            [placeholder]="field.placeholder ? field.placeholder : field.label"
            [loading]="loading.get(field.name)"
            [appendTo]="'body'"
            [loadingText]="
                field.loadingMessage ? field.loadingMessage : 'Loading...'
            "
            dropdownPosition="auto"
            [clearable]="true"
            [formControl]="control"
            [errorStateMatcher]="matcher"
            [multiple]="false"
            (scroll)="scroll($event)"
            [virtualScroll]="true"
            (change)="
                onValueChange($event);
                field.onChange
                    ? field.onChange($event, formGroup, field.name)
                    : null
            "
            [compareWith]="compareWith"
            (open)="showOverlay = true"
            (close)="showOverlay = false"
        >
            <ng-container *ngIf="field.dropdownItemRenderImageFromId">
                <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-index="index"
                    let-search="searchTerm"
                >
                    <div
                        class="image-container"
                        appIcon
                        [data]="
                            field.dropdownItemRenderImageType
                                | attributeToIconDirective : item.id
                        "
                    ></div>
                    {{ item.label }}
                </ng-template>
            </ng-container>
            <ng-container *ngIf="!field.dropdownItemRenderImageFromId">
                <ng-template ng-option-tmp let-item="item">
                    <div
                        title="{{
                            item['hoveredText']
                                ? item['hoveredText']
                                : item[field.showKey]
                                ? item[field.showKey]
                                : item['label']
                                ? item['label']
                                : ''
                        }}"
                    >
                        <span class="common-label">{{
                            item[field.showKey]
                                ? item[field.showKey]
                                : item.label
                        }}</span>
                    </div>
                </ng-template>
            </ng-container>
        </ng-select>
        <!-- Handling errors -->
        <ng-container *ngIf="!field.groupByKey">
            <ng-template *ngTemplateOutlet="error"></ng-template>
        </ng-container>
        <ng-container *ngIf="field.groupByKey">
            <ng-template *ngTemplateOutlet="groupError"></ng-template>
        </ng-container>
    </div>
</div>
<!-- field type two end -->

<!-- field type three start -->
<div
    *ngIf="field.appearance === AppearanceType.TYPE_3"
    class="ng-select-dropdown-container"
>
    <span
        class="ng-select-label"
        *ngIf="field.showLabel"
        [ngStyle]="field.labelStyles"
    >
        {{ field.label }}
        <span *ngIf="field.required" class="color-warn"> &nbsp;* </span>
    </span>
    <div
        [class]="
            'ng-select-custom-material-wrapper ng-select-custom-material-wrapper-underline'
        "
        [class.error]="
            field.groupByKey
                ? formGroup.get(field.groupByKey).get(field.name) &&
                  formGroup.get(field.groupByKey).get(field.name).touched &&
                  formGroup.get(field.groupByKey).get(field.name).errors
                : formGroup.get(field.name) &&
                  formGroup.get(field.name).touched &&
                  formGroup.get(field.name).errors
        "
        [class.disabled]="
            field.groupByKey
                ? formGeneratorInput.state === FormState.IDLE ||
                  (field.populateFromControl &&
                      !formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl)) ||
                  (formGroup
                      .get(field.groupByKey)
                      .get(field.populateFromControl) &&
                      (!formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl).value ||
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value.length))
                : formGeneratorInput.state === FormState.IDLE ||
                  (field.populateFromControl &&
                      !formGroup.get(field.populateFromControl)) ||
                  (formGroup.get(field.populateFromControl) &&
                      (!formGroup.get(field.populateFromControl).value ||
                          !formGroup.get(field.populateFromControl).value
                              .length))
        "
        [matTooltip]="
            field.groupByKey
                ? (field.populateFromControl &&
                      !formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl)) ||
                  (formGroup
                      .get(field.groupByKey)
                      .get(field.populateFromControl) &&
                      (!formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl).value ||
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value.length))
                    ? 'Select ' +
                      (
                          field.populateFromControl
                          | fieldDataFromControlName : formGeneratorInput.fields
                      ).label +
                      ' values first'
                    : null
                : (field.populateFromControl &&
                      !formGroup.get(field.populateFromControl)) ||
                  (formGroup.get(field.populateFromControl) &&
                      (!formGroup.get(field.populateFromControl).value ||
                          !formGroup.get(field.populateFromControl).value
                              .length))
                ? 'Select ' +
                  (
                      field.populateFromControl
                      | fieldDataFromControlName : formGeneratorInput.fields
                  ).label +
                  ' values first'
                : null
        "
        [matTooltipShowDelay]="1000"
        [matTooltipPosition]="'before'"
    >
        <ng-select
            [class.field-required]="
                field.required !== undefined && !field.showLabel
                    ? field.required
                    : false
            "
            style="z-index: 10"
            [appearance]="'legacy'"
            [bindValue]="
                field.getKey || field.getKey === null ? field.getKey : 'id'
            "
            [bindLabel]="field.showKey ? field.showKey : 'label'"
            [items]="
                !loading.get(field.name) && field.listData
                    ? field.listData
                    : null
            "
            [class.error]="
                field.groupByKey
                    ? formGroup.get(field.groupByKey).get(field.name).touched &&
                      formGroup.get(field.groupByKey).get(field.name).errors
                    : formGroup.get(field.name).touched &&
                      formGroup.get(field.name).errors
            "
            [closeOnSelect]="true"
            [placeholder]="field.placeholder ? field.placeholder : field.label"
            [loading]="loading.get(field.name)"
            [appendTo]="'body'"
            [loadingText]="
                field.loadingMessage ? field.loadingMessage : 'Loading...'
            "
            dropdownPosition="auto"
            [clearable]="true"
            [formControl]="control"
            [errorStateMatcher]="matcher"
            [multiple]="false"
            (scroll)="scroll($event)"
            [virtualScroll]="true"
            (change)="
                onValueChange($event);
                field.onChange
                    ? field.onChange($event, formGroup, field.name)
                    : null
            "
            [compareWith]="compareWith"
            (open)="showOverlay = true"
            (close)="showOverlay = false"
        >
            <ng-container *ngIf="field.dropdownItemRenderImageFromId">
                <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-index="index"
                    let-search="searchTerm"
                >
                    <div
                        class="image-container"
                        appIcon
                        [data]="
                            field.dropdownItemRenderImageType
                                | attributeToIconDirective : item.id
                        "
                    ></div>
                    {{ item.label }}
                </ng-template>
            </ng-container>
            <ng-container *ngIf="!field.dropdownItemRenderImageFromId">
                <ng-template ng-option-tmp let-item="item">
                    <div
                        title="{{
                            item['hoveredText']
                                ? item['hoveredText']
                                : item[field.showKey]
                                ? item[field.showKey]
                                : item['label']
                                ? item['label']
                                : ''
                        }}"
                    >
                        <span class="common-label">{{
                            item[field.showKey]
                                ? item[field.showKey]
                                : item.label
                        }}</span>
                    </div>
                </ng-template>
            </ng-container>
        </ng-select>
        <!-- Handling errors -->
        <ng-container *ngIf="!field.groupByKey">
            <ng-template *ngTemplateOutlet="error"></ng-template>
        </ng-container>
        <ng-container *ngIf="field.groupByKey">
            <ng-template *ngTemplateOutlet="groupError"></ng-template>
        </ng-container>
    </div>
</div>
<!-- field type three end -->

<!-- field type four start -->
<div
    *ngIf="field.appearance === AppearanceType.TYPE_4"
    class="ng-select-dropdown-container"
>
    <div
        [class]="
            'ng-select-custom-material-wrapper ng-select-custom-material-wrapper-underline'
        "
        [class.error]="
            field.groupByKey
                ? formGroup.get(field.groupByKey).get(field.name) &&
                  formGroup.get(field.groupByKey).get(field.name).touched &&
                  formGroup.get(field.groupByKey).get(field.name).errors
                : formGroup.get(field.name) &&
                  formGroup.get(field.name).touched &&
                  formGroup.get(field.name).errors
        "
        [class.disabled]="
            field.groupByKey
                ? formGeneratorInput.state === FormState.IDLE ||
                  (field.populateFromControl &&
                      !formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl)) ||
                  (formGroup
                      .get(field.groupByKey)
                      .get(field.populateFromControl) &&
                      (!formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl).value ||
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value.length))
                : formGeneratorInput.state === FormState.IDLE ||
                  (field.populateFromControl &&
                      !formGroup.get(field.populateFromControl)) ||
                  (formGroup.get(field.populateFromControl) &&
                      (!formGroup.get(field.populateFromControl).value ||
                          !formGroup.get(field.populateFromControl).value
                              .length))
        "
        [matTooltip]="
            field.groupByKey
                ? (field.populateFromControl &&
                      !formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl)) ||
                  (formGroup
                      .get(field.groupByKey)
                      .get(field.populateFromControl) &&
                      (!formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl).value ||
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value.length))
                    ? 'Select ' +
                      (
                          field.populateFromControl
                          | fieldDataFromControlName : formGeneratorInput.fields
                      ).label +
                      ' values first'
                    : null
                : (field.populateFromControl &&
                      !formGroup.get(field.populateFromControl)) ||
                  (formGroup.get(field.populateFromControl) &&
                      (!formGroup.get(field.populateFromControl).value ||
                          !formGroup.get(field.populateFromControl).value
                              .length))
                ? 'Select ' +
                  (
                      field.populateFromControl
                      | fieldDataFromControlName : formGeneratorInput.fields
                  ).label +
                  ' values first'
                : null
        "
        [matTooltipShowDelay]="1000"
        [matTooltipPosition]="'before'"
    >
        <ng-select
            [class.field-required]="
                field.required !== undefined && !field.showLabel
                    ? field.required
                    : false
            "
            style="z-index: 10"
            [appearance]="'legacy'"
            [bindValue]="
                field.getKey || field.getKey === null ? field.getKey : 'id'
            "
            [bindLabel]="field.showKey ? field.showKey : 'label'"
            [items]="
                !loading.get(field.name) && field.listData
                    ? field.listData
                    : null
            "
            [class.error]="
                field.groupByKey
                    ? formGroup.get(field.groupByKey).get(field.name).touched &&
                      formGroup.get(field.groupByKey).get(field.name).errors
                    : formGroup.get(field.name).touched &&
                      formGroup.get(field.name).errors
            "
            [closeOnSelect]="true"
            [placeholder]="field.placeholder ? field.placeholder : field.label"
            [loading]="loading.get(field.name)"
            [appendTo]="'body'"
            [loadingText]="
                field.loadingMessage ? field.loadingMessage : 'Loading...'
            "
            dropdownPosition="auto"
            [clearable]="true"
            [formControl]="control"
            [errorStateMatcher]="matcher"
            [multiple]="false"
            (scroll)="scroll($event)"
            [virtualScroll]="true"
            (change)="
                onValueChange($event);
                field.onChange
                    ? field.onChange($event, formGroup, field.name)
                    : null
            "
            [compareWith]="compareWith"
            (open)="showOverlay = true"
            (close)="showOverlay = false"
        >
            <ng-container *ngIf="field.dropdownItemRenderImageFromId">
                <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-index="index"
                    let-search="searchTerm"
                >
                    <div
                        class="image-container"
                        appIcon
                        [data]="
                            field.dropdownItemRenderImageType
                                | attributeToIconDirective : item.id
                        "
                    ></div>
                    {{ item.label }}
                </ng-template>
            </ng-container>
            <ng-container *ngIf="!field.dropdownItemRenderImageFromId">
                <ng-template ng-option-tmp let-item="item">
                    <div
                        title="{{
                            item['hoveredText']
                                ? item['hoveredText']
                                : item[field.showKey]
                                ? item[field.showKey]
                                : item['label']
                                ? item['label']
                                : ''
                        }}"
                    >
                        <span class="common-label">{{
                            item[field.showKey]
                                ? item[field.showKey]
                                : item.label
                        }}</span>
                    </div>
                </ng-template>
            </ng-container>
        </ng-select>
        <!-- Handling errors -->
        <ng-container *ngIf="!field.groupByKey">
            <ng-template *ngTemplateOutlet="error"></ng-template>
        </ng-container>
        <ng-container *ngIf="field.groupByKey">
            <ng-template *ngTemplateOutlet="groupError"></ng-template>
        </ng-container>
    </div>
</div>
<!-- field type four end -->

<!-- field type five start -->
<div *ngIf="field.appearance === AppearanceType.TYPE_5">
    <ng-select
        class="custom"
        [class.field-required]="
            field.required !== undefined && !field.showLabel
                ? field.required
                : false
        "
        style="z-index: 10"
        [appearance]="'legacy'"
        [bindValue]="
            field.getKey || field.getKey === null ? field.getKey : 'id'
        "
        [bindLabel]="field.showKey ? field.showKey : 'label'"
        [items]="
            !loading.get(field.name) && field.listData ? field.listData : null
        "
        [class.error]="
            field.groupByKey
                ? formGroup.get(field.groupByKey).get(field.name).touched &&
                  formGroup.get(field.groupByKey).get(field.name).errors
                : formGroup.get(field.name).touched &&
                  formGroup.get(field.name).errors
        "
        [closeOnSelect]="true"
        [placeholder]="field.placeholder ? field.placeholder : field.label"
        [loading]="loading.get(field.name)"
        [appendTo]="'body'"
        [loadingText]="
            field.loadingMessage ? field.loadingMessage : 'Loading...'
        "
        dropdownPosition="auto"
        [clearable]="false"
        [formControl]="control"
        [errorStateMatcher]="matcher"
        [multiple]="false"
        (scroll)="scroll($event)"
        [virtualScroll]="true"
        (change)="
            onValueChange($event);
            field.onChange
                ? field.onChange($event, formGroup, field.name)
                : null
        "
        [compareWith]="compareWith"
        (open)="showOverlay = true"
        (close)="showOverlay = false"
    >
        <ng-template ng-option-tmp let-item="item">
            <div
                title="{{
                    item['hoveredText']
                        ? item['hoveredText']
                        : item['label']
                        ? item['label']
                        : ''
                }}"
            >
                <span class="common-label">{{ item.label }}</span>
            </div>
        </ng-template>
    </ng-select>
</div>
<!-- field type five end -->

<!-- field type seven start -->
<div
    *ngIf="field.appearance === AppearanceType.TYPE_7"
    class="ng-select-dropdown-container column"
>
    <span
        class="ng-select-label width-unset"
        *ngIf="field.label"
        [ngStyle]="field.labelStyles"
    >
        {{ field.label }}
        <span *ngIf="field.required" class="color-warn"> &nbsp;* </span>
    </span>
    <div
        [class]="
            'ng-select-custom-material-wrapper ng-select-custom-material-wrapper-outline'
        "
        [class.error]="
            field.groupByKey
                ? formGroup.get(field.groupByKey).get(field.name) &&
                  formGroup.get(field.groupByKey).get(field.name).touched &&
                  formGroup.get(field.groupByKey).get(field.name).errors
                : formGroup.get(field.name) &&
                  formGroup.get(field.name).touched &&
                  formGroup.get(field.name).errors
        "
        [class.disabled]="
            field.groupByKey
                ? formGeneratorInput.state === FormState.IDLE ||
                  (field.populateFromControl &&
                      !formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl)) ||
                  (formGroup
                      .get(field.groupByKey)
                      .get(field.populateFromControl) &&
                      (!formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl).value ||
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value.length))
                : formGeneratorInput.state === FormState.IDLE ||
                  (field.populateFromControl &&
                      !formGroup.get(field.populateFromControl)) ||
                  (formGroup.get(field.populateFromControl) &&
                      (!formGroup.get(field.populateFromControl).value ||
                          !formGroup.get(field.populateFromControl).value
                              .length))
        "
        [matTooltip]="
            field.groupByKey
                ? (field.populateFromControl &&
                      !formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl)) ||
                  (formGroup
                      .get(field.groupByKey)
                      .get(field.populateFromControl) &&
                      (!formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl).value ||
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value.length))
                    ? 'Select ' +
                      (
                          field.populateFromControl
                          | fieldDataFromControlName : formGeneratorInput.fields
                      ).label +
                      ' values first'
                    : null
                : (field.populateFromControl &&
                      !formGroup.get(field.populateFromControl)) ||
                  (formGroup.get(field.populateFromControl) &&
                      (!formGroup.get(field.populateFromControl).value ||
                          !formGroup.get(field.populateFromControl).value
                              .length))
                ? 'Select ' +
                  (
                      field.populateFromControl
                      | fieldDataFromControlName : formGeneratorInput.fields
                  ).label +
                  ' values first'
                : null
        "
        [matTooltipShowDelay]="1000"
        [matTooltipPosition]="'before'"
    >
        <ng-select
            [class.field-required]="
                field.required !== undefined && !field.showLabel
                    ? field.required
                    : false
            "
            style="z-index: 10"
            [appearance]="'legacy'"
            [bindValue]="
                field.getKey || field.getKey === null ? field.getKey : 'id'
            "
            [bindLabel]="field.showKey ? field.showKey : 'label'"
            [items]="
                !loading.get(field.name) && field.listData
                    ? field.listData
                    : null
            "
            [class.error]="
                field.groupByKey
                    ? formGroup.get(field.groupByKey).get(field.name).touched &&
                      formGroup.get(field.groupByKey).get(field.name).errors
                    : formGroup.get(field.name).touched &&
                      formGroup.get(field.name).errors
            "
            [closeOnSelect]="true"
            [placeholder]="field.placeholder"
            [loading]="loading.get(field.name)"
            [appendTo]="'body'"
            [loadingText]="
                field.loadingMessage ? field.loadingMessage : 'Loading...'
            "
            dropdownPosition="auto"
            [clearable]="true"
            [formControl]="control"
            [errorStateMatcher]="matcher"
            [multiple]="false"
            (scroll)="scroll($event)"
            [virtualScroll]="true"
            (change)="
                onValueChange($event);
                field.onChange
                    ? field.onChange($event, formGroup, field.name)
                    : null
            "
            [compareWith]="compareWith"
            (open)="showOverlay = true"
            (close)="showOverlay = false"
        >
            <ng-container *ngIf="field.dropdownItemRenderImageFromId">
                <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-index="index"
                    let-search="searchTerm"
                >
                    <div
                        class="image-container"
                        appIcon
                        [data]="
                            field.dropdownItemRenderImageType
                                | attributeToIconDirective : item.id
                        "
                    ></div>
                    {{ item.label }}
                </ng-template>
            </ng-container>
            <ng-container *ngIf="!field.dropdownItemRenderImageFromId">
                <ng-template ng-option-tmp let-item="item">
                    <div
                        title="{{
                            item['hoveredText']
                                ? item['hoveredText']
                                : item[field.showKey]
                                ? item[field.showKey]
                                : item['label']
                                ? item['label']
                                : ''
                        }}"
                    >
                        <span class="common-label">{{
                            item[field.showKey]
                                ? item[field.showKey]
                                : item.label
                        }}</span>
                    </div>
                </ng-template>
            </ng-container>
        </ng-select>
        <!-- Handling errors -->
        <ng-container *ngIf="!field.groupByKey">
            <ng-template *ngTemplateOutlet="error"></ng-template>
        </ng-container>
        <ng-container *ngIf="field.groupByKey">
            <ng-template *ngTemplateOutlet="groupError"></ng-template>
        </ng-container>
    </div>
</div>
<!-- field type seven end -->

<ng-template #groupError>
    <ng-container
        *ngIf="
            formGroup.get(field.groupByKey).get(field.name).touched &&
            formGroup.get(field.groupByKey).get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation
                        : formGroup.get(field.groupByKey).get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
<ng-template #error>
    <ng-container
        *ngIf="
            formGroup.get(field.name).touched &&
            formGroup.get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation : formGroup.get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
<div class="dropdown-background-layer" *ngIf="showOverlay"></div>
