import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IPieApiResponse } from 'src/app/shared/interfaces/api/portlets/IPieApiResponse';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IColumnData } from 'src/app/shared/interfaces/table-generator/IColumnData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { FilterCacheService } from 'src/app/shared/services/cache/filters-cache/filter-cache.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';

@Component({
  selector: 'app-ri-coverage-v2',
  templateUrl: './ri-coverage-v2.component.html',
  styleUrls: ['./ri-coverage-v2.component.sass']
})
export class RiCoverageV2Component implements OnInit {
    // RI Coverage V2
    agGrid: GridOptions;
    ViewType = ViewType;
    widgetRef: Widget;
    rowData: any;
    colDefs: any;
    gridApi: any;
    tableKeys: string[];
    defaultColDef = {
        editable: false,
        sortable: true,
        filter: true,
        resizable: true
    };
    getRowStyle: any;
    agGridIcons = Helper.getAgGridIcons();
    drillDownFilterObject = {};
    tempInputData = {};
    filterInfo: IFilterInfo = {} as IFilterInfo;
    arrowRightIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-chevron-right small-icon'
    };
    tableInputData: ITableGeneratorInput = {
        listExtraction: {
            type: 'DIRECT'
        },
        columns: [],
        tableHeight: 300
    };
    drillDownColor: any;
    filteredSearchData: any;
    serviceResponse: any;
    roundOff: number;
    preparedTableData: any = [];
    constructor(
        widgetData: WidgetInjectedData,
        private filterCacheService: FilterCacheService,
        private configCache: ConfigCacheService,
        private userDataCache: UserDataCacheService
    ) {
        this.widgetRef = widgetData.widgetRef;
        this.drillDownColor = Helper.convertToHexIfHsl(
            Helper.getCssVarValue('drillDownColor')
        );
    }

    ngOnInit(): void {
        this.setUpBasics();
        // Setting up the widget state on the basis of LITE OR FULL
        if (this.widgetRef.lightState) {
            this.setUpRowClassRules();
        }
    }

    setUpRowClassRules() {
        this.defaultColDef.sortable = false;
        this.defaultColDef.filter = false;
        Helper.setRowClassRules(this.widgetRef);
    }

    setUpBasics() {
        this.widgetRef.showViewIcon.next(true);
        this.getRowStyle = this.rowStyling.bind(this);
    }

    ngAfterViewInit(): void {
        this.widgetRef.isBindDataAssigned = false;
        this.widgetRef.setBindData(this.bindData.bind(this));
    }

    bindData(data: IPieApiResponse): void {
        if (!data) {
            return;
        }
        // Assigning data to WidgetRef.
        this.widgetRef.apiResponse = data;
        this.rowData = [];
        this.colDefs = [];
        if (
            (data.dataMap && !Object.keys(data.dataMap).length) ||
            (data.dataMap.table && data.dataMap.table.length === 0)
        ) {
            this.widgetRef.extraMessage.next(Messages.NO_DATA_AVAILABLE);
            this.widgetRef.loadingData.next(false);
            return;
        }
        this.widgetRef.visibleSections.next(new Set([ViewType.TABLE]));
        this.drawTable();
        this.widgetRef.endLoader();
    }

    drawTable() {
        this.colDefs = [];
        this.rowData = [];
        this.tableKeys = [];
        // Pushing table keys from the response for columns to display.
        this.tableKeys.push(
            ...(this.widgetRef.apiResponse as IPieApiResponse).dataMap.tableKeys
        );
        this.tableKeys.forEach((row) => {
            let obj = {};
            obj = {
                headerName: row,
                field: row
            };

            this.colDefs.push(obj);
        });
        this.rowData = (
            this.widgetRef.apiResponse as IPieApiResponse
        ).dataMap.table;
        // Providing table data for searching.
        this.filteredSearchData = this.rowData;
        // Will generate the table data.
        this.prepareTableData();
    }

    prepareTableData() {
        this.tableInputData.columns = [];
        // Pushing column keys to tableInputData
        this.colDefs.forEach((col) => {
            let columnObj: IColumnData = {
                columnName: col.headerName,
                columnKey: col.field,
                sortable: true
            };
            this.tableInputData.columns.push(columnObj);
        });
        // Table row selction to single
        this.tableInputData.rowSelection = 'single';
        // Pinning 1st column to left.
        this.tableInputData.columns[0]['pinned'] = 'left';
    }

    onQuickFilterChanged(id: string) {
        this.agGrid.api.setQuickFilter(document.getElementById(id)[`value`]);
    }

    rowStyling(data) {
        if (
            this.widgetRef.widgetLinkingData &&
            this.widgetRef.widgetData &&
            this.widgetRef.widgetData.linking
        ) {

            if (this.widgetRef.widgetData.linking.drillDownType === 'column') {
                if (
                    data[`data`][this.tableKeys[0]] &&
                    data[`data`][this.tableKeys[0]].toLowerCase() === 'total'
                ) {
                    return { fontWeight: 'bold' };
                } else {
                    return {
                        cursor: 'pointer',
                        color: this.drillDownColor + ' !important'
                    };
                }
            } else if (
                this.widgetRef.widgetData.linking.drillDownType === 'row'
            ) {
                if (
                    this.widgetRef.widgetData.linking &&
                    this.widgetRef.widgetData.linking.legendRow &&
                    this.widgetRef.widgetData.linking.legendRow[
                    data[`data`][this.tableKeys[0]]
                    ]
                ) {
                    return {
                        cursor: 'pointer',
                        color: this.drillDownColor + ' !important'
                    };
                } else if (
                    data[`data`][this.tableKeys[0]] &&
                    data[`data`][this.tableKeys[0]].toLowerCase() === 'total'
                ) {
                    return { fontWeight: 'bold' };
                }
            }
        } else if (
            data[`data`][this.tableKeys[0]] &&
            data[`data`][this.tableKeys[0]].toLowerCase() === 'total'
        ) {
            return { fontWeight: 'bold' };
        }
        return '';
    }

    async tableRowClicked(selectedData, isTableRowClicked) {
        if (
            this.widgetRef.widgetLinkingData &&
            this.widgetRef.widgetData.linking &&
            (this.widgetRef.widgetData.linking.legendRow ||
                this.widgetRef.widgetData.linking.legendColumn)
        ) {
            let slice = '';
            if (isTableRowClicked) {
                slice = selectedData[this.tableKeys[0]];
            } else {
                slice = selectedData;
            }

            // this.prevDefaultPref = this.defaultPref;
            const tempObj = this.prepareNextWidgetData(
                slice,
                isTableRowClicked,
                selectedData
            );
            if (tempObj) {
                tempObj[`viewId`] = this.widgetRef.widgetLinkingData.viewId;
                tempObj[`widgetConfig`] = this.widgetRef.widgetConfigDetails;
                const currentLevel =
                    this.widgetRef.compareViewService.currentLevel[
                        this.widgetRef.widgetLinkingData.viewId
                    ] + 1;
                this.widgetRef.compareViewService.currentLevel[
                    this.widgetRef.widgetLinkingData.viewId
                ] = currentLevel;
                this.widgetRef.compareViewService.nextWidgetView.next(tempObj);
            }
        }
    }

    prepareNextWidgetData(slice, isTableRowClicked, selectedData) {
        let drilldownFilterObject;
        let widgetId;
        const drillDownFiltersData = {};
        const breadCrumbs = this.widgetRef.widgetLinkingData.breadCrumbs
            ? this.widgetRef.widgetLinkingData.breadCrumbs.slice()
            : [];
        const breadCrumbsData = this.widgetRef.widgetLinkingData.breadCrumbsData
            ? this.widgetRef.widgetLinkingData.breadCrumbsData.slice()
            : [];
        if (this.widgetRef.widgetData.apiResponse) {
            this.widgetRef.widgetData.apiResponse = null;
        }
        if (
            this.widgetRef.compareViewService.linkingArray.get(
                this.widgetRef.widgetLinkingData.viewId
            )[
                this.widgetRef.compareViewService.currentLevel[
                    this.widgetRef.widgetLinkingData.viewId
                ] - 1
            ]
        ) {
            const filterInput = this.filterCacheService.getFiltersInfo(
                this.userDataCache.emailId,
                this.configCache.viewId,
                this.widgetRef.filterStoreKey
            );
            this.widgetRef.compareViewService.linkingArray.get(
                this.widgetRef.widgetLinkingData.viewId
            )[
                this.widgetRef.compareViewService.currentLevel[
                    this.widgetRef.widgetLinkingData.viewId
                ] - 1
            ][`filters`] = Helper.cloneDeep(filterInput);
        }
        if (this.widgetRef.widgetData.linking.drillDownType === 'column') {
            if (!this.widgetRef.widgetData.linking.legendColumn[`filters`]) {
                return;
            }
            if (this.widgetRef.widgetData.linking.legendColumn[`widgetId`]) {
                drilldownFilterObject =
                    this.widgetRef.widgetData.linking.legendColumn[`filters`];
                widgetId =
                    this.widgetRef.widgetData.linking.legendColumn[`widgetId`];
            } else {
                return;
            }
        } else if (this.widgetRef.widgetData.linking.drillDownType === 'row') {
            if (this.widgetRef.widgetData.linking.legendRow[slice]) {
                if (
                    this.widgetRef.widgetData.linking.legendRow[slice][
                        `widgetId`
                    ]
                ) {
                    drilldownFilterObject = this.widgetRef.widgetData.linking
                        .legendRow[slice][`filters`]
                        ? this.widgetRef.widgetData.linking.legendRow[slice][
                              `filters`
                          ]
                        : {};
                    widgetId =
                        this.widgetRef.widgetData.linking.legendRow[slice][
                            `widgetId`
                        ];
                } else {
                    return;
                }
            } else {
                return;
            }
        } else {
            return;
        }

        const tempInputData = {};
        if (
            drilldownFilterObject &&
            Object.keys(drilldownFilterObject).length
        ) {
            Object.keys(drilldownFilterObject).forEach((key, index) => {
                const object = breadCrumbsData.find((row) => key in row);
                if (object) {
                    tempInputData[drilldownFilterObject[key]] = [object[key]];
                } else {
                    if (!isTableRowClicked) {
                        if (
                            key ===
                            (this.widgetRef.apiResponse as IPieApiResponse)
                                .dataMap.tableKeys[0]
                        ) {
                            tempInputData[drilldownFilterObject[key]] = [slice];
                            const tempObject = {};
                            tempObject[key] = slice;
                            breadCrumbsData.push(tempObject);
                        } else {
                            tempInputData[drilldownFilterObject[key]] = null;
                        }
                    } else {
                        const headerName = this.tableKeys.find(
                            (tableKey) => tableKey === key
                        );
                        if (headerName) {
                            tempInputData[drilldownFilterObject[key]] = [
                                selectedData[headerName]
                            ];
                            if (headerName === this.tableKeys[0]) {
                                const tempObject = {};
                                tempObject[key] = selectedData[headerName];
                                breadCrumbsData.push(tempObject);
                            }
                        } else {
                            tempInputData[drilldownFilterObject[key]] = null;
                        }
                    }
                }
                drillDownFiltersData[drilldownFilterObject[key]] = [
                    tempInputData[drilldownFilterObject[key]][0]
                ];
            });
        } else {
            const tempObject = {};
            const slicedKey = (this.widgetRef.apiResponse as IPieApiResponse)
                .dataMap.tableKeys[0];
            tempObject[slicedKey] = slice;
            breadCrumbsData.push(tempObject);
        }

        if (!isTableRowClicked) {
            breadCrumbs.push(slice);
        } else {
            if (this.widgetRef.widgetData.linking.titleKeys) {
                let title = '';
                this.widgetRef.widgetData.linking.titleKeys.forEach(
                    (element, index) => {
                        if (selectedData[element]) {
                            if (!index) {
                                title += selectedData[element];
                            } else {
                                title += '|' + selectedData[element];
                            }
                        }
                    }
                );

                breadCrumbs.push(title);
            } else {
                breadCrumbs.push(slice);
            }
        }

        return {
            widgetId,
            input: tempInputData,
            breadCrumbs,
            breadCrumbsData,
            filtersData: drillDownFiltersData
        };
    }

    goBackDrillDown(home?, index?, isLast?) {
        if (isLast) {
            return;
        }
        if (home) {
            this.widgetRef.compareViewService.currentLevel[
                this.widgetRef.widgetLinkingData.viewId
            ] = 1;
        } else {
            const currentLevel =
                this.widgetRef.compareViewService.currentLevel[
                    this.widgetRef.widgetLinkingData.viewId
                ] - index;
            this.widgetRef.compareViewService.currentLevel[
                this.widgetRef.widgetLinkingData.viewId
            ] = currentLevel;
        }

        const tempObj = {};
        tempObj[this.widgetRef.widgetLinkingData.viewId] = true;
        this.widgetRef.compareViewService.previousWidgetView.next(tempObj);
    }
}
