<mat-sidenav-container
    [autosize]="true"
    class="create-audit-container"
    *ngIf="!loader"
>
    <mat-sidenav
        class="sidenav"
        #sidenav
        [opened]="true"
        [mode]="(breakpointForMenuOverlay | async) ? 'over' : 'side'"
    >
        <div class="side-nav-container">
            <div
                class="detailed-side-nav-container"
                [class.centilyticsSideNav]="
                    whiteLabelingService.whiteLableData.isCentilytics
                "
            >
                <div class="menu-top">
                    <div class="logo-container">
                        <div class="logo" appIcon [data]="companyLogo"></div>
                        <div
                            class="menu-toggle clickable"
                            appIcon
                            [data]="
                                sidenav.opened
                                    ? toggleCloseIcon
                                    : toggleOpenIcon
                            "
                            (click)="sidenav.toggle()"
                        ></div>
                    </div>
                    <div class="actions">
                        <span class="menu-header">{{
                            menuParentNode.data.attributeName
                        }}</span>
                    </div>
                    <div
                        *ngIf="
                            (menuParentNode &&
                                menuParentNode.parentNodeId !== null) ||
                            hideAssessmentModule
                        "
                        class="back-buttons"
                    >
                        <div
                            class="back-icon"
                            (click)="goToRootNode()"
                            *ngIf="
                                (menuParentNode &&
                                    menuParentNode.parentNodeId !== null) ||
                                hideAssessmentModule
                            "
                            appIcon
                            [data]="doubleBackArrow"
                        ></div>
                        <div
                            class="back-icon"
                            (click)="goToParentNode()"
                            *ngIf="
                                menuParentNode &&
                                menuParentNode.parentNodeId !== null
                            "
                            appIcon
                            [data]="backArrow"
                        ></div>
                    </div>
                </div>
                <div class="menu-content">
                    <div
                        class="menu-items"
                        cdkDropList
                        (cdkDropListDropped)="sectionDropped($event)"
                        *ngIf="
                            menuParentNode &&
                            menuParentNode.children &&
                            hideAssessmentModule
                        "
                    >
                        <div
                            class="menu-item"
                            cdkDrag
                            cdkDragBoundary=".menu-items"
                            cdkDragLockAxis="y"
                            *ngFor="let node of menuParentNode.children"
                        >
                            <div *cdkDragPreview></div>
                            <div
                                class="icon background-circle"
                                appIcon
                                [data]="
                                    node.data.iconType
                                        | attributeToIconDirective
                                            : node.data.icon
                                            : 'svg-black-fill menu-icon'
                                "
                            ></div>
                            <div class="name">
                                <div
                                    class="edit-icon"
                                    (click)="
                                        sectionDetailsUpdate(
                                            node,
                                            'Enter Section Details'
                                        )
                                    "
                                >
                                    <i class="fas fa-pencil-alt"></i>
                                </div>
                                {{ node.data.attributeName }}
                            </div>
                            <div
                                class="actions"
                                id="{{ node.nodeId }}"
                                #actions
                            >
                                <div
                                    class="icon"
                                    appIcon
                                    [data]="folderIcon"
                                    (click)="diveInNode(node)"
                                ></div>
                                <div class="icon-group">
                                    <div
                                        class="icon"
                                        appIcon
                                        [data]="trashIcon"
                                        (click)="deleteSection(node)"
                                    ></div>
                                    <div
                                        cdkDragHandle
                                        class="icon"
                                        appIcon
                                        [data]="moveIcon"
                                    ></div>
                                </div>
                                <div
                                    class="icon icon-group-trigger"
                                    appIcon
                                    [data]="menuDots"
                                    (click)="actions.classList.add('triggered')"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="menu-items"
                        cdkDropList
                        (cdkDropListDropped)="sectionDropped($event)"
                        *ngIf="
                            assessmentData &&
                            tree.getLevelOfNode(menuParentNode) === 1 &&
                            !hideAssessmentModule
                        "
                    >
                        <div
                            class="menu-item"
                            cdkDrag
                            cdkDragBoundary=".menu-items"
                            cdkDragLockAxis="y"
                        >
                            <div *cdkDragPreview></div>
                            <div
                                class="icon background-circle"
                                appIcon
                                [data]="
                                    assessmentData.data.iconType
                                        | attributeToIconDirective
                                            : assessmentData.data.icon
                                            : 'svg-black-fill menu-icon'
                                "
                            ></div>
                            <div class="name">
                                <div
                                    class="edit-icon"
                                    (click)="
                                        sectionDetailsUpdate(
                                            assessmentData,
                                            'Enter Assessment Details'
                                        )
                                    "
                                >
                                    <i class="fas fa-pencil-alt"></i>
                                </div>
                                {{ assessmentData.data.attributeName }}
                            </div>
                            <div
                                class="actions"
                                id="{{ assessmentData.data.nodeId }}"
                                #actions
                            >
                                <div
                                    class="icon"
                                    appIcon
                                    [data]="folderIcon"
                                    (click)="diveInNode(assessmentData)"
                                ></div>
                                <div class="icon-group">
                                    <div
                                        class="icon"
                                        appIcon
                                        [data]="trashIcon"
                                        [matTooltip]="
                                            'You cannot pertform this action'
                                        "
                                        [class.disabled]="true"
                                        (click)="deleteSection(null)"
                                    ></div>
                                    <div
                                        cdkDragHandle
                                        class="icon"
                                        appIcon
                                        [data]="moveIcon"
                                    ></div>
                                </div>
                                <div
                                    class="icon icon-group-trigger"
                                    appIcon
                                    [data]="menuDots"
                                    (click)="actions.classList.add('triggered')"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="
                            (menuParentNode &&
                                menuParentNode.parentNodeId !== null) ||
                            hideAssessmentModule
                        "
                        class="add-section-container tw-flex tw-justify-center tw-my-2 tw-mx-auto"
                    >
                        <app-button-generator
                            [buttonGenInput]="addSectionButtonGenInput"
                            [disabled]="
                                tree.getLevelOfNode(menuParentNode) >
                                GlobalConfiguration.MAX_LAYERS_LIMIT
                            "
                            [hoverText]="
                                tree.getLevelOfNode(menuParentNode) >
                                GlobalConfiguration.MAX_LAYERS_LIMIT
                                    ? 'Maximum layers created'
                                    : null
                            "
                        ></app-button-generator>
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="action-acl-container">
            <div class="action-header">
                <div class="widget-btn-container">
                    <div
                        class="menu-toggle clickable"
                        appIcon
                        *ngIf="!sidenav.opened"
                        [data]="
                            sidenav.opened ? toggleCloseIcon : toggleOpenIcon
                        "
                        (click)="sidenav.toggle()"
                    ></div>
                </div>

                <div class="general-actions hide-on-mobile">
                    <span
                        [matMenuTriggerFor]="emailConfiguration"
                        class="auidt-configuration clickable"
                    >
                        Audit Configuration
                    </span>
                    <mat-menu #emailConfiguration="matMenu" class="email-config-mat-menu">
                        <div class="email-config-menu">
                            <app-button-generator
                                *ngIf="emailConfigurationButtonGenInput"
                                [buttonGenInput]="emailConfigurationButtonGenInput"
                            ></app-button-generator>
                        </div>
                    </mat-menu>
                    <span
                        (click)="returnToConsole()"
                        class="return-to-console clickable"
                    >
                        Return To Console
                    </span>
                    <span (click)="saveAudit()" class="save-icon">
                        <div
                            class="icon clickable"
                            appIcon
                            [data]="saveDisk"
                        ></div>
                        <span class="clickable">Save</span>
                    </span>
                </div>

                <div class="general-actions-menu show-on-mobile">
                    <div
                        class="icon clickable"
                        appIcon
                        [data]="menuDots"
                        [matMenuTriggerFor]="generalActionsMenu"
                    ></div>
                    <mat-menu #generalActionsMenu>
                        <div class="more-info-menu">
                            <div
                                (click)="saveAudit()"
                                class="info-item action-btn"
                            >
                                <div
                                    class="icon clickable"
                                    appIcon
                                    [data]="saveDisk"
                                ></div>
                                <span class="save-text">Save</span>
                            </div>
                            <div
                                class="info-item action-btn"
                                (click)="returnToConsole()"
                            >
                                <div
                                    class="icon"
                                    appIcon
                                    [data]="exitArrow"
                                ></div>
                                <span class="return-to-console"
                                    >Return To Console</span
                                >
                            </div>
                        </div>
                    </mat-menu>
                </div>
            </div>
            <div
                *ngIf="
                    !showTechnicalSection &&
                    menuParentNode?.data &&
                    hideAssessmentModule &&
                    !menuParentNode?.children.length &&
                    !menuParentNode?.data['framework']
                "
                class="content-body"
            >
                <div class="add-framework">
                    <div class="text">Add Control Points</div>
                </div>
                <div class="add-framework-btn">
                    <app-button-generator
                        [buttonGenInput]="addFrameworkButtonGenInput"
                        [class.disabled]="
                            this.menuParentNode.children.length ||
                            tree.getLevelOfNode(this.menuParentNode) === 1
                        "
                        [class.decrease-opacity]="
                            this.menuParentNode.children.length ||
                            tree.getLevelOfNode(this.menuParentNode) === 1
                        "
                        [matTooltip]="
                            this.menuParentNode.children.length ||
                            this.menuParentNode.nodeId === this.rootNode.nodeId
                                ? 'Control point can only be added to non-root section without children'
                                : null
                        "
                    ></app-button-generator>
                </div>
            </div>
            <div
                *ngIf="
                    showTechnicalSection ||
                    (menuParentNode.data && menuParentNode.data['framework'])
                "
                class="technicalSection-container"
            >
                <div [style.width.%]="95" class="tw-m-auto">
                    <div class="section-info tw-pt-9">
                        <div
                            class="section-name"
                            *ngIf="rootNode.nodeId !== menuParentNode.nodeId"
                            [matTooltip]="menuParentNode.data.attributeName"
                        >
                            {{ menuParentNode.data.attributeName }}
                        </div>
                        <div class="tw-flex tw-items-center tw-pl-5">
                            <ng-container *ngIf="tags && tags.length">
                                <ng-container
                                    *ngFor="let tag of tags; let i = index"
                                >
                                    <ng-container *ngIf="i < 6">
                                        <ng-container *ngIf="i < 5; else addOn">
                                            <div
                                                class="tag-name"
                                                [style.background-color]="
                                                    tag.colorCode
                                                "
                                                [matTooltip]="tag.name"
                                            >
                                                <div class="name">
                                                    {{ tag.name }}
                                                </div>
                                                <div
                                                    (click)="
                                                        deleteTag(tag.name, i)
                                                    "
                                                    class="tw-px-1 icon tw-text-secondary"
                                                >
                                                    <i class="fas fa-times"></i>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-template #addOn>
                                            <div
                                                [style.top.px]="11"
                                                class="tw-invisible tw-pointer-events-none tw-relative"
                                                [matMenuTriggerFor]="matMenu"
                                                #triggerMenu="matMenuTrigger"
                                            ></div>
                                            <div
                                                [style.min-width]="
                                                    'fit-content'
                                                "
                                                class="tw-text-fs-150 tw-text-neutral"
                                                (mouseover)="
                                                    triggerMenu.openMenu()
                                                "
                                            >
                                                +{{ tags.length - 5 }} more
                                            </div>
                                            <mat-menu
                                                #matMenu="matMenu"
                                                class="assessment-tag-mat-menu"
                                            >
                                                <div
                                                    [ngStyle]="{
                                                        width: '200px',
                                                        border: '2px solid var(--accentColor)'
                                                    }"
                                                    class="tw-flex tw-justify-start tw-flex-wrap tw-p-2"
                                                >
                                                    <ng-container
                                                        *ngFor="
                                                            let tag of tags;
                                                            let i = index
                                                        "
                                                    >
                                                        <ng-container
                                                            *ngIf="i > 4"
                                                        >
                                                            <div
                                                                [ngStyle]="{
                                                                    'max-width':
                                                                        '53px',
                                                                    margin: '2px'
                                                                }"
                                                                class="tw-flex tw-text-secondary tw-rounded tw-px-1 tag-name"
                                                                [style.background-color]="
                                                                    tag.colorCode
                                                                "
                                                                [matTooltip]="
                                                                    tag.name
                                                                "
                                                            >
                                                                <div
                                                                    [ngStyle]="{
                                                                        'max-width':
                                                                            '44px'
                                                                    }"
                                                                    class="tw-truncate tw-text-fs-100"
                                                                >
                                                                    {{
                                                                        tag.name
                                                                    }}
                                                                </div>
                                                                <div
                                                                    (click)="
                                                                        deleteTag(
                                                                            tag.name,
                                                                            i
                                                                        );
                                                                        $event.stopPropagation()
                                                                    "
                                                                    class="icon tw-px-1 tw-text-secondary"
                                                                >
                                                                    <i
                                                                        class="fas fa-times"
                                                                    ></i>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </mat-menu>
                                        </ng-template>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <app-tag-generator
                                [extraClass]="'tagMenuResize'"
                                [selectedTags]="tags && tags.length ? tags : []"
                                (tagsDataEmitter)="
                                    $event && $event.callback
                                        ? $event.callback('SUCCESS')
                                        : null;
                                    tags = $event?.tagsData
                                "
                                [customContent]="true"
                                [showSaveButton]="true"
                                [showTagsList]="false"
                            >
                                <ng-container
                                    *ngIf="
                                        !(tags && tags.length);
                                        else editTags
                                    "
                                >
                                    <div class="add-tags">
                                        <div class="add-tag-icon">
                                            <span
                                                appIcon
                                                [data]="editIcon"
                                            ></span>
                                        </div>
                                        <span
                                            class="tag-title-text tw-text-fs-100 tw-font-bold"
                                        >
                                            Add Tag
                                        </span>
                                    </div>
                                </ng-container>
                                <ng-template #editTags>
                                    <div
                                        class="edit-tags"
                                        appIcon
                                        [data]="{
                                            type: 'SVG',
                                            class: 'editIcon'
                                        }"
                                    ></div>
                                </ng-template>
                            </app-tag-generator>
                        </div>
                    </div>
                </div>
                <div class="technicalSection">
                    <mat-tab-group
                        #tabGroup
                        mat-stretch-tabs
                        mat-align-tabs="center"
                        [disableRipple]="true"
                        (selectedTabChange)="tabChanged($event)"
                    >
                        <mat-tab [label]="Tab.TECHNICAL_DESCRIPTION">
                            <div
                                class="tab-content technical-descriprion"
                                *ngIf="activeTabIndex === 0"
                            >
                                <section *ngIf="summaryFormGenInputs">
                                    <div class="section-heading">
                                        <div class="tittle">Summary</div>
                                        <div class="actions">
                                            <div
                                                *ngIf="
                                                    summaryFormGenInputs.state ===
                                                    FormState.IDLE
                                                "
                                            >
                                                <i
                                                    class="fas fa-edit"
                                                    (click)="
                                                        changeSummaryformState(
                                                            FormState.EDIT
                                                        )
                                                    "
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="section-content tw-relative">
                                        <app-form-generator
                                            *ngIf="summaryFormGenInputs"
                                            [formGeneratorInput]="
                                                summaryFormGenInputs
                                            "
                                            (formGroupRef)="
                                                summaryFormGroup = $event
                                            "
                                        ></app-form-generator>
                                        <div
                                            *ngIf="
                                                summaryFormGenInputs.state ===
                                                FormState.EDIT
                                            "
                                            class="form-save"
                                        >
                                            <app-button-generator
                                                *ngIf="saveSummaryButton"
                                                [buttonGenInput]="
                                                    saveSummaryButton
                                                "
                                            ></app-button-generator>
                                        </div>
                                    </div>
                                </section>
                                <section
                                    [ngClass]="
                                        summaryFormGenInputs.state !==
                                        FormState.IDLE
                                            ? 'tw-relative'
                                            : ''
                                    "
                                    class="tw-pb-5"
                                    *ngIf="requiredEvidenceFormGenInputs"
                                >
                                    <div class="section-heading">
                                        <div class="tittle">
                                            Required Evidence
                                        </div>
                                        <div class="actions">
                                            <div
                                                *ngIf="
                                                    requiredEvidenceFormGenInputs.state ===
                                                    FormState.IDLE
                                                "
                                            >
                                                <i
                                                    class="fas fa-edit"
                                                    (click)="
                                                        changeReqEvidenceformState(
                                                            FormState.EDIT
                                                        )
                                                    "
                                                >
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="section-content tw-relative">
                                        <app-form-generator
                                            *ngIf="
                                                requiredEvidenceFormGenInputs
                                            "
                                            [formGeneratorInput]="
                                                requiredEvidenceFormGenInputs
                                            "
                                            (formGroupRef)="
                                                requiredEvidenceFormGroup =
                                                    $event
                                            "
                                        ></app-form-generator>
                                        <div
                                            *ngIf="
                                                requiredEvidenceFormGenInputs.state ===
                                                FormState.EDIT
                                            "
                                            class="form-save"
                                        >
                                            <app-button-generator
                                                *ngIf="saveEvidenceButton"
                                                [buttonGenInput]="
                                                    saveEvidenceButton
                                                "
                                            ></app-button-generator>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </mat-tab>
                        <mat-tab
                            [disabled]="true"
                            [label]="Tab.UPLOAD_EVIDENCE"
                            class="disabled"
                        >
                        </mat-tab>
                        <mat-tab [label]="Tab.TECHNICAL_ASSESSMENT">
                            <div
                                class="tab-content technical-assessment"
                                *ngIf="activeTabIndex === 2"
                            >
                                <div class="acl-data">
                                    <div class="top-info">
                                        <div class="acl-selection">
                                            <app-form-generator
                                                *ngIf="aclFormGenInput"
                                                [formGeneratorInput]="
                                                    aclFormGenInput
                                                "
                                                (formGroupRef)="
                                                    aclFormGroup = $event
                                                "
                                                (valuesChange)="aclUpdate()"
                                            ></app-form-generator>
                                        </div>
                                        <app-button-generator
                                            [buttonGenInput]="
                                                selectWidgetButtonGenInput
                                            "
                                            [matTooltip]="
                                                this.menuParentNode.children
                                                    .length ||
                                                this.menuParentNode.nodeId ===
                                                    this.rootNode.nodeId
                                                    ? 'Widgets can only be added to non-root section without children'
                                                    : null
                                            "
                                            [class.disabled]="
                                                this.menuParentNode.children
                                                    .length ||
                                                this.menuParentNode.nodeId ===
                                                    this.rootNode.nodeId
                                            "
                                            [class.decrease-opacity]="
                                                this.menuParentNode.children
                                                    .length ||
                                                this.menuParentNode.nodeId ===
                                                    this.rootNode.nodeId
                                            "
                                        ></app-button-generator>
                                    </div>
                                    <div
                                        class="widget-listing"
                                        cdkDropList
                                        (cdkDropListDropped)="
                                            widgetDropped($event)
                                        "
                                    >
                                        <ng-container>
                                            <ng-container
                                                *ngFor="
                                                    let widget of menuParentNode
                                                        .data.widgets;
                                                    let i = index
                                                "
                                            >
                                                <div
                                                    cdkDrag
                                                    cdkDragBoundary=".widget-listing"
                                                    cdkDragLockAxis="y"
                                                    class="widget"
                                                >
                                                    <div *cdkDragPreview></div>
                                                    <div class="action">
                                                        <div
                                                            cdkDragHandle
                                                            class="icon clickable"
                                                            appIcon
                                                            [data]="moveIcon"
                                                        ></div>
                                                    </div>
                                                    <div
                                                        [matTooltip]="
                                                            this.modalInputData
                                                                .data.edit
                                                                ? widget.label
                                                                : widget.name
                                                        "
                                                        class="widget-name"
                                                    >
                                                        {{
                                                            this.modalInputData
                                                                .data.edit
                                                                ? isString(
                                                                      widget.label
                                                                  )
                                                                    ? widget.label
                                                                    : widget.name
                                                                : widget.name
                                                        }}
                                                    </div>

                                                    <div
                                                        class="delete-widget"
                                                        (click)="
                                                            deleteWidget(widget)
                                                        "
                                                    >
                                                        <div
                                                            class="icon clickable"
                                                            appIcon
                                                            [data]="
                                                                trashIconWarn
                                                            "
                                                        ></div>
                                                    </div>
                                                </div>
                                                <ng-container
                                                    *ngIf="
                                                        (
                                                            menuParentNode.data
                                                                .widgets.length
                                                            | widgetsPageSeparation
                                                        ).includes(i)
                                                    "
                                                >
                                                    <span
                                                        class="page-separation"
                                                    >
                                                        Page
                                                        {{
                                                            (
                                                                menuParentNode
                                                                    .data
                                                                    .widgets
                                                                    .length
                                                                | widgetsPageSeparation
                                                            ).indexOf(i) + 1
                                                        }}
                                                    </span>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
<div class="overlay" *ngIf="loader">
    <div class="overlay-content">
        <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
    </div>
</div>