<div
    *ngIf="
        !(widgetRef.loadingData | async) &&
        rowData &&
        rowData.length &&
        accountRegionCost
    "
    class="tw-w-full tw-text-center tw-text-positive tw-p-2 tw-my-2 tw-rounded-md general"
>
    <span class="tw-text-fs-200 tw-font-semibold">
        Potential Savings: &nbsp;
    </span>
    <span class="tw-text-fs-400 tw-font-bold"> {{ accountRegionCost }} </span>
</div>
<div
    class="controls"
    *ngIf="
        !(widgetRef.loadingData | async) &&
        rowData &&
        rowData.length &&
        widgetRef.visibleSections.value.has(ViewType.TABLE)
    "
>
    <app-multi-button-generator
        *ngIf="severityButtons && widgetRef.widgetData.description"
        [buttonGenInputs]="severityButtons"
    ></app-multi-button-generator>

    <div class="table-search" *ngIf="!this.widgetRef.lightState">
        <app-table-search
            [searchFunction]="onQuickFilterChanged.bind(this)"
        ></app-table-search>
    </div>
</div>
<div
    class="widget-data-container"
    [id]="widgetRef.widgetTableId"
    *ngIf="
        !(widgetRef.loadingData | async) &&
        rowData &&
        rowData.length &&
        widgetRef.visibleSections.value.has(ViewType.TABLE)
    "
>
    <div
        [ngClass]="
            widgetRef.widgetLinkingData
                ? 'table-container-compare-view'
                : 'table-container'
        "
    >
        <ag-grid-angular
            #agGrid
            class="ag-theme-balham"
            [rowData]="filteredSearchData"
            [columnDefs]="colDefs"
            [animateRows]="true"
            [rowHeight]="37"
            [rowSelection]="'multiple'"
            [suppressFieldDotNotation]="true"
            [icons]="agGridIcons"
            [rowClassRules]="widgetRef.tableRowClassRules"
            [defaultColDef]="defaultColDef"
            (gridReady)="agGrid.api.sizeColumnsToFit()"
        >
        </ag-grid-angular>
    </div>
</div>
