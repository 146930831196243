<section
    *ngIf="customerInfoFormGenInput && !(widgetRef.loadingData | async)"
    class="tw-border-t-0 tw-overflow-hidden"
>
    <div class="tw-flex tw-py-2 tw-px-0">
        <div class="tw-ml-auto">
            <div
                *ngIf="
                    customerInfoFormGenInput.state === FormState.IDLE;
                    then edit;
                    else idle
                "
            ></div>
            <ng-template #edit>
                <i
                    (click)="changeCustomerInfoState(FormState.EDIT)"
                    class="fas fa-edit"
                >
                </i>
            </ng-template>
            <ng-template #idle>
                <i
                    (click)="changeCustomerInfoState(FormState.IDLE)"
                    class="fas fa-times"
                ></i>
            </ng-template>
        </div>
    </div>
    <div class="tw-w-full">
        <app-form-generator
            *ngIf="customerInfoFormGenInput"
            [formGeneratorInput]="customerInfoFormGenInput"
            (resetFormFunction)="resetCustomerInfo = $event"
        ></app-form-generator>
    </div>
</section>
