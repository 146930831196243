import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class NoWhiteSpaceValidator extends CustomValidator {
    key = CustomValidatorKey.NO_WHITE_SPACE;
    validatorFunction(control: AbstractControl): ValidationErrors {
        if (
            control.value &&
            (control.value as string).length !== 0 &&
            (control.value as string).trim().length === 0
        ) {
            return { noWhiteSpace: true };
        }
        return null;
    }
}
