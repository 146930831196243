import { Component, OnInit } from '@angular/core';
import { RowEvent } from 'ag-grid-community';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { Helper } from './../../../../../classes/Helper';
import { HitApi } from './../../../../../classes/HitApi';
import { ButtonType } from './../../../../../enums/ButtonType';
import { IntegrationName } from './../../../../../enums/IntegrationName';
import { ModalType } from './../../../../../enums/ModalType';
import { IModalData } from './../../../../../interfaces/modal/IModalData';
import { MultiButtonGeneratorComponent } from './../../../../multi-button-generator/multi-button-generator.component';
import { AutotaskChildIntegrationComponent } from './../autotask-child-integration/autotask-child-integration.component';
import { AutotaskParentIntegrationStepOneComponent } from './../autotask-parent-integration-step-one/autotask-parent-integration-step-one.component';
import { AutotaskParentIntegrationStepTwoComponent } from './../autotask-parent-integration-step-two/autotask-parent-integration-step-two.component';

@Component({
    selector: 'app-autotask-integrations-table',
    templateUrl: './autotask-integrations-table.component.html',
    styleUrls: ['./autotask-integrations-table.component.sass']
})
export class AutotaskIntegrationsTableComponent implements OnInit {
    widgetRef: Widget;
    tableGenInput: ITableGeneratorInput = null;
    tableData = [];
    response;
    constructor(private modalInputData: ModalInjectedData) {
        this.response = modalInputData.data['response'];
        if (this.response['childInfo']) {
            this.tableData.push({
                ...this.response['childInfo'],
                ownerDeskName: this.response['ownerDeskName']
            });
        }
        if (this.response['parentCred']) {
            this.tableData.push(this.response['parentCred']);
        }

        this.widgetRef = modalInputData.data['widgetRef'];
    }

    ngOnInit(): void {
        this.setUpBasics();
    }
    setUpBasics() {
        this.tableGenInput = {
            listExtraction: {
                type: 'DIRECT'
            },
            noDataMessage: 'No Integrations Available',
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-user-plus'
            },
            columns: [
                {
                    columnKey: 'childInfo',
                    columnName: 'Integration desk name',
                    cellRenderer: (rowData: RowEvent) => {
                        return rowData.data.ownerDeskName
                            ? rowData.data.ownerDeskName +
                                  ' (Child Integration)'
                            : rowData.data.deskName + ' (Parent Integration)';
                    }
                },

                {
                    columnKey: 'action',
                    columnName: 'Action',
                    pinned: 'right',
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: RowEvent) => {
                        rowData['buttonGenInputs'] = [
                            // EDIT BUTTON
                            {
                                buttonName: 'Edit',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.INFO,
                                customClass: 'icon-height',
                                showLoader: false,
                                function: () => {
                                    const modalArgs: IModalData = {
                                        modalName: 'Edit Autotask',
                                        sourceId:
                                            this.widgetRef.widgetData
                                                .widgetUniqueIdentifier,
                                        modalType: ModalType.MIDDLE,
                                        modalIcon: {
                                            type: IconType.FONTAWSOME,
                                            class: 'fas fa-wrench'
                                        },
                                        extraStepContainerClass:
                                            'remove-form-space',
                                        modalSteps: rowData.data.deskName
                                            ? [
                                                  {
                                                      stepName: 'Credentials',
                                                      stepData: {
                                                          componentToLoad:
                                                              AutotaskParentIntegrationStepOneComponent,
                                                          payload: {
                                                              data: {
                                                                  response:
                                                                      this
                                                                          .response,
                                                                  widgetRef:
                                                                      this
                                                                          .widgetRef
                                                              }
                                                          }
                                                      }
                                                  },
                                                  {
                                                      stepName:
                                                          'Configure Fields',
                                                      stepData: {
                                                          componentToLoad:
                                                              AutotaskParentIntegrationStepTwoComponent,
                                                          payload: {
                                                              data: {
                                                                  response:
                                                                      this
                                                                          .response,
                                                                  widgetRef:
                                                                      this
                                                                          .widgetRef
                                                              }
                                                          }
                                                      }
                                                  }
                                              ]
                                            : [
                                                  {
                                                      stepName:
                                                          'Configure Fields',
                                                      stepData: {
                                                          componentToLoad:
                                                              AutotaskChildIntegrationComponent,
                                                          payload: {
                                                              data: {
                                                                  response:
                                                                      this
                                                                          .response,
                                                                  widgetRef:
                                                                      this
                                                                          .widgetRef
                                                              }
                                                          }
                                                      }
                                                  }
                                              ],
                                        modalWidthVw: 60,
                                        modalHeightVh: 60
                                    };
                                    this.widgetRef.modalService.openModal(
                                        modalArgs
                                    );
                                    this.widgetRef.modalService.closeModal(
                                        null,
                                        this.modalInputData.modalId
                                    );
                                }
                            },
                            // DELETE BUTTON
                            {
                                buttonName: 'Delete',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.WARN,
                                customClass: 'icon-height',
                                showLoader: true,
                                function: (modalId: Symbol) => {
                                    // Delete integration
                                    const args = Helper.generateHitApiConfig(
                                        this.widgetRef.widgetData.widgetInfo
                                            .delete
                                    );
                                    args.input = {
                                        integrationType:
                                            IntegrationName.AUTOTASK
                                    };
                                    args.input['parentIntegration'] = rowData
                                        .data.deskName
                                        ? true
                                        : false;
                                    args.input['childIntegration'] = rowData
                                        .data.accountName
                                        ? true
                                        : false;
                                    args.function = (response) => {
                                        this.widgetRef.notificationsService.showSnackBar(
                                            `Autotask integration removed successfully`
                                        );
                                        this.widgetRef.modalService.closeModal(
                                            null,
                                            modalId
                                        );
                                        this.widgetRef.refreshWidget();
                                    };
                                    args.errorFunction = (error) => {
                                        Helper.showErrorMessage(
                                            this.widgetRef.notificationsService,
                                            error,
                                            'Error removing autotask'
                                        );
                                        this.widgetRef.modalService.closeModal(
                                            null,
                                            modalId
                                        );
                                    };
                                    new HitApi(
                                        args,
                                        this.widgetRef.httpService,
                                        this.widgetRef.ngZone
                                    ).hitApi();
                                    this.widgetRef.modalService.closeModal(
                                        null,
                                        this.modalInputData.modalId
                                    );
                                },
                                executeFunctionAfterConfirmation: {
                                    modalName: 'Remove Autotask Integration',
                                    modalIcon: {
                                        type: IconType.FONTAWSOME,
                                        class: 'far fa-trash-alt'
                                    },
                                    contextIcon: {
                                        extraClass: 'color-accent',
                                        type: IconType.FONTAWSOME,
                                        class: 'fas fa-exclamation-triangle'
                                    },
                                    confirmationMessage: `Are you sure you want to remove autotask ?`,
                                    buttonText: 'Remove',
                                    buttonColorType: ButtonColorType.WARN,
                                    loaderOnExec: true
                                }
                            }
                        ];
                        return rowData;
                    }
                }
            ],
            afterResponse: null,
            selection: 'single'
        };
    }
}
