import { Component, OnInit } from '@angular/core';
import {
    CellClassParams,
    GridOptions,
    ICellRendererParams
} from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { IconType } from 'src/app/shared/enums/IconType';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IColumnData } from 'src/app/shared/interfaces/table-generator/IColumnData';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';
import { ITableGeneratorInput } from './../../../../interfaces/table-generator/ITableGeneratorInput';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';

@Component({
    selector: 'app-rightsizing-v2-more-info-modal',
    templateUrl: './rightsizing-v2-more-info-modal.component.html',
    styleUrls: ['./rightsizing-v2-more-info-modal.component.sass']
})
export class RightsizingV2MoreInfoModalComponent implements OnInit {
    readonly SCROLL_DEBOUNCE_TIME = 150;

    TableType = TableType;

    itemData: any;
    modalLayout = [];
    defaultModalLayout = [];

    recommendationOrder: string[];
    amortizedPriceSideHeadings: any[];

    configurationGrid: GridOptions;
    amortizedGrid: GridOptions;
    selectedTab = null;
    tabs: string[] = [];
    configurationTabData: Map<string, object[]> = new Map<string, object[]>();
    amortizedTabData: Map<string, object[]> = new Map<string, object[]>();
    tabDataListKeyValue: object = {};

    arrowUpIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-up'
    };

    arrowDownIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-down'
    };

    recommandationOrderMap: Map<number, any[]> = new Map();
    tempIndex: number = 0;
    activeIndex: number = 0;
    configurationTableData: object[];
    configurationTableGen: ITableGeneratorInput;
    amortizedTableData: object[];
    amortizedTableGen: ITableGeneratorInput;
    descriptionTableData: object[];
    descriptionTableGen: ITableGeneratorInput;
    constructor(
        public modalData: ModalInjectedData,
        private modalService: ModalService
    ) {
        this.itemData = this.modalData.data.itemData;
        this.defaultModalLayout = this.modalData.data.modalLayout;
        this.modalLayout = Helper.cloneDeep(this.defaultModalLayout);
    }

    ngOnInit(): void {
        this.setLayoutData();
        this.selectedTab = this.tabs[0];
        this.initConfigurationTable();
        this.initAmortizedTable();
        this.initDescriptionTable();
        this.configurationTableGen.gridOptions.alignedGrids.push(
            this.amortizedTableGen.gridOptions
        );
        this.amortizedTableGen.gridOptions.alignedGrids.push(
            this.configurationTableGen.gridOptions
        );
    }

    changeTab(tab) {
        this.selectedTab = tab;
        this.configurationGrid.api.setRowData(
            this.configurationTabData.get(this.selectedTab)
        );
        this.configurationGrid.api.redrawRows();
        this.amortizedGrid.api.setRowData(
            this.amortizedTabData.get(this.selectedTab)
        );
        this.amortizedGrid.api.redrawRows();
    }

    setLayoutData() {
        this.modalLayout.forEach((layout) => {
            if (layout && layout['data'] && layout['data'].length) {
                for (let index = 0; index < layout['data'].length; index++) {
                    if (
                        !this.itemData.hasOwnProperty(
                            this.getCorrespondingKey(layout['data'][index])
                        ) &&
                        layout['data'][index].type === 'tab'
                    ) {
                        layout['data'].splice(index, 1);
                        index--;
                    }
                }
                layout['data'].forEach((layoutData, index) => {
                    if (layoutData && layoutData['type']) {
                        layout['type'] = layoutData['type'];
                        if (layout['type'] === 'tab') {
                            this.tabs.push(layoutData['heading']);
                        }
                    }
                    if (
                        layoutData &&
                        layoutData['sections'] &&
                        layoutData['sections'].length
                    ) {
                        layoutData['sections'].forEach((layoutDataSection) => {
                            this.handleLayoutDataSection(layoutDataSection);
                        });
                    }
                });
            }
        });
    }

    initConfigurationTable() {
        const pinnedColumn = 'VCore Type';
        const columns: IColumnData[] = [
            {
                columnKey: pinnedColumn,
                columnName: pinnedColumn,
                pinned: 'left',
                cellClass: 'pinned-key',
                minWidth: 250,
                maxWidth: 250
            }
        ];
        const key = this.tabDataListKeyValue[this.selectedTab];
        this.itemData[key]['recommendationOrder'].forEach((key) => {
            if (key.includes('Current')) {
                columns.push({
                    columnKey: key,
                    columnName: key,
                    minWidth: 250,
                    maxWidth: 250,
                    cellClass: 'value-column'
                });
            } else {
                columns.push({
                    columnKey: key,
                    columnName: key,
                    minWidth: 300,
                    maxWidth: 300
                });
            }
        });
        this.tabs.forEach((tab) => {
            const tableData = [];
            const tabKey = this.tabDataListKeyValue[tab];
            const configuration = this.itemData[tabKey]['configuration'];
            Object.keys(configuration).forEach((configKey) => {
                const tempData = configuration[configKey];
                tempData[pinnedColumn] = configKey;
                tableData.push(tempData);
            });
            this.configurationTabData.set(tab, tableData);
        });

        this.configurationTableGen = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            columns,
            tableAutoHeight: true,
            gridOptions: {
                alignedGrids: []
            }
        };
        this.configurationTableData = this.configurationTabData.get(
            this.selectedTab
        );
    }

    initAmortizedTable() {
        const pinnedColumn = 'VCore Type';
        const columns: IColumnData[] = [
            {
                columnKey: pinnedColumn,
                columnName: '',
                pinned: 'left',
                cellClass: 'pinned-key',
                minWidth: 250,
                maxWidth: 250
            }
        ];
        const key = this.tabDataListKeyValue[this.selectedTab];
        this.itemData[key]['recommendationOrder'].forEach((key) => {
            if (key.includes('Current')) {
                columns.push({
                    columnKey: key,
                    columnName: key,
                    cellRenderer: (rowData: ICellRendererParams) => {
                        return rowData.data[key];
                    },
                    minWidth: 250,
                    maxWidth: 250,
                    cellClass: 'value-column'
                });
            } else {
                const tempColumns: IColumnData[] = [
                    {
                        columnName: 'On Demand',
                        columnKey: key,
                        colSpan: () => 2,
                        cellRenderer: (rowData: ICellRendererParams) => {
                            const data = rowData.data[key].split('||');
                            const cellData =
                                `<span>${data[0]}</span>` +
                                (data[1] ?? `<span>-</span>`);
                            return cellData;
                        },
                        minWidth: 150,
                        maxWidth: 150
                    },
                    {
                        columnName: 'Savings',
                        columnKey: null,
                        minWidth: 150,
                        maxWidth: 150
                    }
                ];
                columns.push(...tempColumns);
            }
        });

        this.tabs.forEach((tab) => {
            const tableData = [];
            const tabKey = this.tabDataListKeyValue[tab];
            const amortizedPrice = Helper.cloneDeep(
                this.itemData[tabKey]['amortizedPrice']
            );
            Object.keys(amortizedPrice).forEach((amortizedPriceKey) => {
                const tempData = amortizedPrice[amortizedPriceKey];
                tempData[pinnedColumn] = amortizedPriceKey;
                tableData.push(tempData);
            });
            this.amortizedTabData.set(tab, tableData);
        });

        this.amortizedTableGen = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            columns,
            tableAutoHeight: true,
            gridOptions: {
                alignedGrids: []
            }
        };
        this.amortizedTableData = this.amortizedTabData.get(this.selectedTab);
    }

    initDescriptionTable() {
        const columns: IColumnData[] = [
            {
                columnKey: 'key',
                columnName: 'key',
                pinned: 'left',
                cellClass: 'pinned-key'
            },
            {
                columnKey: 'value',
                columnName: 'value',
                cellClass: (cellClassParams: CellClassParams) => {
                    if (cellClassParams.data.key === 'Database Family') {
                        return 'scroll-content';
                    }
                    return 'value-column';
                }
            }
        ];

        this.descriptionTableData = [];
        this.modalLayout.forEach((layout) => {
            if (layout['heading'] === 'Description') {
                layout['data'].forEach((layoutData) => {
                    layoutData['sections'].forEach((tableSection) => {
                        const tempTableData = [];
                        tableSection['data']['tableHeadings'].forEach(
                            (data) => {
                                tempTableData.push({
                                    key: data['label'],
                                    value: data['values'] ?? 'N.A.'
                                });
                            }
                        );
                        this.descriptionTableData.push(...tempTableData);
                    });
                });
            }
        });

        this.descriptionTableGen = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            tableAutoHeight: true,
            columns,
            headerHeight: 0
        };
    }

    handleLayoutDataSection(layoutDataSection: any) {
        if (
            layoutDataSection &&
            layoutDataSection['data'] &&
            layoutDataSection['data']['tableHeadings'] &&
            layoutDataSection['data']['tableHeadings'].length
        ) {
            layoutDataSection['data']['tableHeadings'].forEach(
                (tableHeading) => {
                    tableHeading['values'] = this.getRespectiveValueList(
                        tableHeading['rowData']
                    );
                }
            );
            if (layoutDataSection['data']['tableHeadings'][0]) {
                layoutDataSection['data']['sideHeadings'] =
                    this.getPriceColumnHeadings(
                        layoutDataSection['data']['tableHeadings'][0]
                    );
            }
        }
    }

    getRespectiveValueList(rowData: string) {
        const rowValues = Helper.extractDataFromObject(rowData, this.itemData);
        if (this.isString(rowValues)) {
            return rowValues;
        }
        if (this.isArray(rowValues)) {
            if (rowData.includes('recommendationOrder')) {
                this.recommendationOrder = rowValues;
                this.recommandationOrderMap.set(this.tempIndex++, rowValues);
            }
            return rowValues;
        }
        if (
            this.isObject(rowValues) &&
            this.recommendationOrder &&
            this.recommendationOrder.length
        ) {
            return this.recommendationOrder.map((recommendation) => {
                if (rowValues[recommendation]) {
                    return rowValues[recommendation].split('||');
                }
                return null;
            });
        }
        return null;
    }

    getPriceColumnHeadings(firstRowConfig: any) {
        const onDemandCol = 'On-Demand';
        const savingCol = 'Saving';
        if (
            firstRowConfig['rowData'] &&
            firstRowConfig['rowData'].includes('amortizedPrice') &&
            firstRowConfig['values'] &&
            firstRowConfig['values'].length
        ) {
            return firstRowConfig['values'].map((cols) => {
                if (this.isArray(cols)) {
                    const obj = [onDemandCol];
                    if (cols.length === 2) {
                        obj.push(savingCol);
                    }
                    return obj;
                } else {
                    return [onDemandCol];
                }
            });
        }
        return [];
    }

    isString(param) {
        return typeof param === 'string';
    }

    isArray(param) {
        return Array.isArray(param);
    }

    isObject(param) {
        return param instanceof Object;
    }

    private getCorrespondingKey(data: any) {
        if (
            data['sections'] &&
            data['sections'].length &&
            data['sections'][0]['data']
        ) {
            const rowData =
                data['sections'][0]['data']['tableHeadings'][0]['rowData'];

            const correspondingKey = rowData.split('|')[0];
            if (data['heading']) {
                this.tabDataListKeyValue[data['heading']] = correspondingKey;
            }
            return correspondingKey;
        }
    }
}

enum TableType {
    TABLE_INVERTED = 'TABLE_INVERTED'
}
