import { Component, OnInit } from '@angular/core';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { ModalInjectedData } from './../../../../../classes/ModalInjectedData';
import { ButtonColorType } from './../../../../../enums/ButtonColorType';
import { IconType } from './../../../../../enums/IconType';
import { IButtonGeneratorInput } from './../../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IIcon } from './../../../../../interfaces/icon-data/IIcon';
import { ModalService } from './../../../../../services/modal/modal-service/modal.service';

@Component({
    selector: 'app-freshservice-add-field',
    templateUrl: './freshservice-add-field.component.html',
    styleUrls: ['./freshservice-add-field.component.sass']
})
export class FreshserviceAddFieldComponent implements OnInit {
    fieldList = [];
    selectedFields = [];
    filteredFields = [];
    searchIcon: IIcon = {
        type: IconType.SVG,
        class: 'search'
    };
    buttonInput: IButtonGeneratorInput;

    constructor(
        private modalService: ModalService,
        private modalInputData: ModalInjectedData
    ) {
        if (modalInputData.data.fieldList) {
            this.fieldList = modalInputData.data.fieldList;
            this.filteredFields = this.fieldList;
        }
        if (modalInputData.data.selectedFields) {
            this.selectedFields = modalInputData.data.selectedFields;
        }
    }

    ngOnInit(): void {
        this.buttonInput = {
            buttonName: 'Add Selected',
            buttonType: ButtonType.RAISED,
            buttonColorType: ButtonColorType.PRIMARY,
            function: () => {
                this.save();
            }
        };
    }

    search(value) {
        if (value) {
            this.filteredFields = this.fieldList.filter((item) =>
                JSON.stringify(item).toLowerCase().includes(value.trim())
            );
        } else {
            this.filteredFields = this.fieldList;
        }
    }

    save() {
        this.modalInputData.data.updateFieldMethod(this.selectedFields);
        this.modalService.closeModal(null, this.modalInputData.modalId);
    }
}
