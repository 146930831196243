<div class="multi-step-form-container">
    <ul class="progressbar">
        <li
            *ngFor="let step of stepNames; let i = index"
            class="step{{ i }}"
            [class.current]="currentStep === i"
        >
            {{ step }}
        </li>
    </ul>
    <div class="content">
        <ng-container *ngFor="let step of stepKeys; let i = index">
            <div
                class="cont"
                *ngIf="i === currentStep && step !== 'confirmationStep'"
            >
                <ng-container *ngFor="let item of automationJson[step]">
                    <app-field-generator
                        *ngIf="currentStep === i && !isTypeString(item)"
                        [filterStoreKey]="step"
                        [filterData]="filtersData.get(item)"
                        [lightMode]="true"
                        [customSelectedValue]="selectedValue"
                    ></app-field-generator>
                    <app-field-generator
                        *ngIf="currentStep === i && isTypeString(item)"
                        [filterStoreKey]="step"
                        [filterData]="filtersData.get(item)"
                        [lightMode]="true"
                        [customSelectedValue]="selectedValue"
                    ></app-field-generator>
                </ng-container>
            </div>
            <div
                class="cont"
                *ngIf="i === currentStep && step === 'confirmationStep'"
            >
                <div class="confirmation-msg">
                    <div class="warning-icon">
                        <img
                            src="../../../../../assets/yellow_check.png"
                            alt="WARNING"
                        />
                    </div>
                    <span>{{ automationJson[step]["confirmationText"] }}</span>
                </div>
                <ng-container
                    *ngFor="let item of automationJson[step]['keysToDisplay']"
                >
                    <div class="confirmation-content">
                        <span class="key">{{ item["label"] }}: </span>
                        <span class="value" *ngIf="item['isTableColumn']">
                            {{
                                selectedData[item["selector"]]
                                    ? selectedData[item["selector"]]
                                    : data[item["label"]]
                                    ? data[item["label"]]
                                    : ""
                            }}
                        </span>
                        <span class="value" *ngIf="!item['isTableColumn']">
                            {{ calculateTotalCost() }}
                        </span>
                    </div>
                </ng-container>
            </div>
            <div
                class="button-container"
                *ngIf="i === currentStep"
                [class.firstStep]="currentStep === 0"
                [class.notFirstStep]="currentStep !== 0"
            >
                <app-button-generator
                    *ngIf="backButton && step !== stepKeys[0]"
                    [buttonGenInput]="backButton"
                ></app-button-generator>
                <app-button-generator
                    *ngIf="step !== stepKeys[stepKeys.length - 1] && nextButton"
                    [buttonGenInput]="nextButton"
                    (buttonRef)="nextButtonClicked(step)"
                ></app-button-generator>
                <app-button-generator
                    *ngIf="
                        step === stepKeys[stepKeys.length - 1] && submitButton
                    "
                    [buttonGenInput]="submitButton"
                    (buttonRef)="submitButtonClicked(step)"
                ></app-button-generator>
            </div>
        </ng-container>
    </div>
</div>
