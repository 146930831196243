<form action="" class="form {{ extraClass }}">
    <div class="form-data tw-flex-1">
        <div
            class="message"
            style="display: flex"
            *ngIf="modalParams?.descriptionText"
        >
            <div class="message" [style.fontSize]="fontSize">
                {{ modalParams?.descriptionText }}
            </div>
        </div>
        <app-form-generator
            *ngIf="assignAssessmentFormGenInputs"
            [formGeneratorInput]="assignAssessmentFormGenInputs"
            (formGroupRef)="assignAssessmentFormGroup = $event"
        ></app-form-generator>
    </div>
    <div class="form-controls button-container">
        <app-multi-button-generator
            [buttonGenInputs]="
                customButtonGeneratorInput
                    ? customButtonGeneratorInput.buttonGenInput
                    : buttonInputs
            "
            [options]="
                customButtonGeneratorInput
                    ? customButtonGeneratorInput.options
                    : null
            "
        >
        </app-multi-button-generator>
    </div>
</form>
