<div
    class="catalogConfig-container tw-w-full tw-h-full tw-relative tw-z-1 tw-flex tw-flex-f7"
>
    <div class="integrationType-container tw-h-full tw-flex-f2">
        <ng-container *ngFor="let item of integrationTypes">
            <div
                [matTooltip]="
                    integratedItsmTools.includes(item.title)
                        ? item.title
                        : item.title + ' not Integrated'
                "
                class="integrationType tw-h-10 tw-cursor-pointer tw-text-fs-400 tw-w-full tw-flex tw-justify-center tw-flex-col tw-items-center tw-fs-400"
                (click)="setCurrentTab(item.title)"
                [class.activated]="
                    integrationType === item.title &&
                    integratedItsmTools.includes(item.title)
                "
                [class.disabled]="!integratedItsmTools.includes(item.title)"
            >
                <img [src]="item.icon" class="tw-p-1 tw-h-5/6" />
            </div>
        </ng-container>
    </div>
    <div class="setConfig-form tw-h-full tw-p-1 tw-ml-1 tw-flex-f5">
        <ng-container *ngIf="integrationType === IntegrationName.SERVICENOW">
            <app-form-generator
                *ngIf="serviceNowFormGenInput"
                [formGeneratorInput]="serviceNowFormGenInput"
                (formGroupRef)="serviceNowFormGroup = $event"
            ></app-form-generator>
        </ng-container>
        <ng-container *ngIf="integrationType === IntegrationName.AUTOTASK">
            <app-form-generator
                *ngIf="autoTaskFormGenInput"
                [formGeneratorInput]="autoTaskFormGenInput"
                (formGroupRef)="autoTaskFormGroup = $event"
            ></app-form-generator>
        </ng-container>
        <ng-container *ngIf="integrationType === IntegrationName.OTRS">
            <app-form-generator
                *ngIf="otrsFormGenInput"
                [formGeneratorInput]="otrsFormGenInput"
                (formGroupRef)="otrsFormGroup = $event"
            ></app-form-generator>
        </ng-container>
        <ng-container *ngIf="integrationType === IntegrationName.CASDM">
            <app-form-generator
                *ngIf="casdmFormGenInput"
                [formGeneratorInput]="casdmFormGenInput"
                (formGroupRef)="casdmFormGroup = $event"
            ></app-form-generator>
        </ng-container>
        <ng-container *ngIf="integrationType === IntegrationName.ZOHO">
            <app-form-generator
                *ngIf="zohoFormGenInput"
                [formGeneratorInput]="zohoFormGenInput"
                (formGroupRef)="zohoFormGroup = $event"
            ></app-form-generator>
        </ng-container>
        <ng-container *ngIf="integrationType === IntegrationName.FRESHSERVICE">
            <div class="form tw-flex tw-flex-col">
                <app-form-generator
                    class="first-form"
                    *ngIf="freshServiceConfigInput"
                    [formGeneratorInput]="freshServiceConfigInput"
                    (formGroupRef)="freshServiceConfigFormGroup = $event"
                    (valuesChange)="freshServiceConfigValueChanged($event)"
                ></app-form-generator>
                <app-form-generator
                    class="second-form tw-overflow-y-auto tw-flex-1"
                    *ngIf="freshServiceFormGenInput && !extraMessage"
                    [formGeneratorInput]="freshServiceFormGenInput"
                    (formGroupRef)="freshServiceFormGroup = $event"
                ></app-form-generator>
                <div
                    class="step-loader tw-flex tw-justify-center tw-items-center tw-flex-1"
                    *ngIf="modalLoader"
                >
                    <mat-spinner
                        [diameter]="25"
                        class="mat-spinner-color"
                    ></mat-spinner>
                </div>
                <div class="extra-message tw-py-0 tw-px-7" *ngIf="extraMessage">
                    {{ extraMessage }}
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="integrationType === IntegrationName.ZENDESK">
            <div class="form zendesk-form tw-flex tw-flex-col">
                <app-form-generator
                    class="first-form"
                    *ngIf="zendeskFormGenInput"
                    [formGeneratorInput]="zendeskFormGenInput"
                    (formGroupRef)="zendeskFormGroup = $event"
                    (updateControl)="updateControl = $event"
                ></app-form-generator>
                <div class="overlay" *ngIf="modalLoader">
                    <div class="overlay-content">
                        <div
                            appIcon
                            [data]="spinnerLoader"
                            class="overlay-loader"
                        ></div>
                    </div>
                </div>
                <div class="extra-message" *ngIf="extraMessage">
                    {{ extraMessage }}
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="integrationType === IntegrationName.SALESFORCE">
            <div class="form salesforce-form tw-flex tw-flex-col">
                <app-form-generator
                    [formGeneratorInput]="salesforceFormGenInput"
                    (formGroupRef)="salesforceFormGroup = $event"
                    (updateControl)="updateControl = $event"
                ></app-form-generator>
            </div>
        </ng-container>
        <div class="extra-message" *ngIf="!integrationType">
            Kindly Integrate to set configuration
        </div>

        <div
            class="button-container tw-bottom-2 tw-w-full tw-absolute tw-justify-end tw-flex tw-right-2"
        >
            <app-button-generator
                class="tw-mr-2"
                [buttonGenInput]="buttonInput"
            >
            </app-button-generator>
        </div>
    </div>
</div>
