import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { Helper } from '../../classes/Helper';
import { IconType } from '../../enums/IconType';

@Component({
    selector: 'app-table-search',
    templateUrl: './table-search.component.html',
    styleUrls: ['./table-search.component.sass']
})
export class TableSearchComponent implements OnInit {
    @Input() data: [];
    @Input() searchPlaceholder: string;
    @Input() applyOutlineStyling: boolean = false;
    filteredSearch = [];
    // tslint:disable-next-line: no-output-native
    @Output() changed = new EventEmitter();

    searchIcon: IIcon = {
        class: 'search',
        type: IconType.SVG,
        extraClass: 'svg-primary-fill'
    };

    @Input() searchFunction;

    inputId: string;

    constructor() {
        this.inputId = Helper.generateUniqueKey(10);
    }

    ngOnInit(): void {}

    search(value) {
        if (this.searchFunction) {
            this.searchFunction(this.inputId, value);
            return;
        }
        if (value) {
            this.filteredSearch = this.data.filter((item) =>
                JSON.stringify(item)
                    .toLowerCase()
                    .includes(value.toLowerCase().trim())
            );
        } else {
            this.filteredSearch = this.data;
        }
        this.changed.emit(this.filteredSearch);
    }
}
