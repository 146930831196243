import { Component, OnInit } from '@angular/core';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterStoreKey } from 'src/app/shared/enums/FilterStoreKey';
import { IFilterSelection } from 'src/app/shared/interfaces/filter/IFilterSelection';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { SupportedFormat } from './../../../../enums/SupportedFormat';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { MulticurrencyFilterSelectorService } from 'src/app/shared/services/multicurrency-filter-selector/multicurrency-filter-selector.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';

@Component({
    selector: 'app-create-report-second-step',
    templateUrl: './create-report-second-step.component.html',
    styleUrls: ['./create-report-second-step.component.sass']
})
export class CreateReportSecondStepComponent implements OnInit {
    widgetsSelected: string[] = [];
    FilterStoreKey = FilterStoreKey;
    supportedFormats: Set<SupportedFormat> = null;
    disableNextButton = true;
    isReadAccess: boolean = false;
    backButton: IButtonGeneratorInput = {
        buttonName: 'Back',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        function: this.previousStep.bind(this)
    };
    nextButton: IButtonGeneratorInput = {
        buttonName: 'Next',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        function: this.nextStep.bind(this)
    };

    constructor(
        public modalInputData: ModalInjectedData,
        public multiStepFormService: MultiStepFormService,
        public filtersService: FiltersService,
        private multiCurrencyFilterService: MulticurrencyFilterSelectorService,
        private userDataCacheService: UserDataCacheService
    ) {}

    ngOnInit(): void {
        const step1Data = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(1);
        this.widgetsSelected = step1Data['widgetsSelected'];
        const currentStepData = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(2);
        if (
            this.modalInputData &&
            this.modalInputData.data &&
            this.modalInputData.data['stepData'] &&
            !this.filtersService.filtersData.get(FilterStoreKey.REPORT_FILTERS)
        ) {
            const widgetFilters =
                this.modalInputData.data['stepData']['widgetFilters'];
            const filtersMap = new Map(Object.entries(widgetFilters));
            this.filtersService.filtersData.set(
                FilterStoreKey.REPORT_FILTERS,
                filtersMap as Map<string, IFilterSelection>
            );
        }
        this.multiCurrencyFilterService.duplicateCurrency = [];
        // if (currentStepData) {
        // this.filtersService.filtersDataTemp.set(
        //     FilterStoreKey.REPORT_FILTERS,
        //     currentStepData['widgetFilters']
        // );
        // }
        this.isReadOrWriteAccess(this.modalInputData.data?.stepData);
    }

    handleKeyDown(event: KeyboardEvent) {
        if (this.isReadAccess) {
            if (event.key === 'Tab' || event.key === 'Enter') {
                event.preventDefault();
            }
        }
    }

    isReadOrWriteAccess(carData) {
        const currentUser = this.userDataCacheService.emailId;
        if (currentUser === carData?.createdBy?.email) {
            return;
        } else {
            carData?.userReportWithAccessControl.map((user) => {
                const userEmail = user?.userInfo?.email;
                if (
                    currentUser === userEmail &&
                    user?.reportAccessControlFlag === 'READ'
                ) {
                    this.isReadAccess = true;
                    return;
                }
            });
        }
    }

    updateData() {
        const filterIds = Array.from(
            this.filtersService.filtersPageData
                .getValue()
                .get(FilterStoreKey.REPORT_FILTERS)
                .keys()
        );
        filterIds.push(this.multiCurrencyFilterService.multiCurrencyFilterId);
        const filters = this.filtersService.prepareInput(
            filterIds,
            FilterStoreKey.REPORT_FILTERS
        );
        this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .set(2, {
                widgetFilters: new Map(Object.entries(filters)),
                supportedFormats: this.supportedFormats
            });
    }
    previousStep() {
        this.filtersService.convertTempToMain(
            FilterStoreKey.REPORT_FILTERS,
            false
        );
        this.multiStepFormService.previousStep(this.modalInputData.modalId);
    }
    nextStep() {
        if (this.disableNextButton) {
            return;
        }
        this.filtersService.convertTempToMain(
            FilterStoreKey.REPORT_FILTERS,
            false
        );
        let isCurrencyFieldPresent: boolean = false;
        this.filtersService.filtersData
            .get('REPORT_FILTERS')
            .forEach((value) => {
                if (value.filterInfo.selector === 'currency') {
                    isCurrencyFieldPresent = true;
                }
            });
        if (isCurrencyFieldPresent) {
            const isDuplicateCurrency =
                this.multiCurrencyFilterService.checkDuplicateFilter();
            if (isDuplicateCurrency) {
                return;
            }
        }
        this.updateData();
        this.multiStepFormService.nextStep(this.modalInputData.modalId);
    }
}
