<div *ngIf="accountRegionCost" class="tw-my-2 tw-rounded-md general">
    <span class="header-web-view tw-font-semibold">
        Potential Savings: &nbsp;</span
    >
    <span class="value tw-font-bold">{{ accountRegionCost }}</span>
</div>
<ng-template #overviewTemplateStart>
    <div
        class="controls"
        *ngIf="
            !(widgetRef.loadingData | async) &&
            rowData &&
            rowData.length &&
            widgetRef.visibleSections.value.has(ViewType.TABLE)
        "
    >
        <div class="overview">
            <div class="tw-flex tw-relative consumption-heading">
                <app-multi-button-generator
                    *ngIf="severityButtons"
                    [buttonGenInputs]="severityButtons"
                ></app-multi-button-generator>
            </div>
        </div>
    </div>
</ng-template>

<ng-container
    *ngIf="
        !(widgetRef.loadingData | async) &&
        rowData &&
        rowData.length &&
        widgetRef.visibleSections.value.has(ViewType.TABLE)
    "
>
    <div class="widget-data-container" [id]="widgetRef.widgetTableId">
        <div
            [ngClass]="
                widgetRef.widgetLinkingData
                    ? 'table-container-compare-view'
                    : 'table-container'
            "
            *ngIf="
                !(widgetRef.loadingData | async) &&
                rowData &&
                rowData.length &&
                widgetRef.visibleSections.value.has(ViewType.TABLE)
            "
        >
            <app-only-table-generator
                [showSearchBox]="showSearchBox"
                [tableData]="rowData"
                [tableInput]="tableInput"
                (gridRef)="agGrid = $event"
                [overviewTemplateStart]="overviewTemplateStart"
            ></app-only-table-generator>
        </div>
    </div>
</ng-container>
