import { Component, OnInit } from '@angular/core';
import { ColDef, GridOptions, RowEvent, RowNode } from 'ag-grid-community';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { Node } from 'src/app/shared/classes/data-structure/tree/Node';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import {
    CloudLabel,
    getLogoPathOfCloudLabel
} from 'src/app/shared/enums/CloudLabel';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IApiInfo } from 'src/app/shared/interfaces/api/IApiInfo';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { IAttributeInfo } from 'src/app/shared/interfaces/views/IAttributeInfo';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';

@Component({
    selector: 'app-module-widget-addition',
    templateUrl: './module-widget-addition.component.html',
    styleUrls: ['./module-widget-addition.component.sass']
})
export class ModuleWidgetAdditionComponent implements OnInit {
    tableGenInput: ITableGeneratorInput = null;
    aclApiInfo: IApiInfo = null;
    widgetPermissionData: Map<string, string> = null;
    gridRef: GridOptions = null;
    node: Node<IAttributeInfo> = null;
    private updateWidgetsListInNode: Function;

    cancelButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Cancel',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        function: null
    };

    insertButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Insert',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        function: null
    };

    permissionFormGenInput: IFormGeneratorInput = {
        state: FormState.CREATE,
        submitButton: null,
        formName: 'Permission',
        fields: [
            {
                name: 'permission',
                fieldType: FilterType.DROPDOWN_SINGLE,
                label: 'Permission',
                placeholder: 'Set permission for all widgets',
                required: false,
                listData: [
                    {
                        id: 'READ',
                        label: 'Read'
                    },
                    {
                        id: 'READ_WRITE',
                        label: 'Read / Write'
                    },
                    {
                        id: 'DENY',
                        label: 'Deny'
                    }
                ]
            }
        ]
    };

    isLoading: boolean = true;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    defaultColDef: ColDef = {
        width: 100,
        filter: false,
        editable: false,
        sortable: false,
        resizable: false
    };

    preSelectedGroupedWidgets: Map<string, string> = null;

    readonly GROUP_ID_KEY = 'groupId';
    readonly GROUP_WIDGETS_KEY = 'groupWidgets';

    uniqueCpspsPairList: {
        cloud?: string;
        product?: string;
        subProduct?: string;
        service?: string;
    }[] = [];

    cloudFilterInfo: IFilterInfo;
    cloudSelected: any[] = [];
    cloudSet = new Set<string>();
    productFilterInfo: IFilterInfo;
    productSelected: any[] = [];
    productSet = new Set<string>();
    subProductFilterInfo: IFilterInfo;
    subProductSelected: any[] = [];
    subProductSet = new Set<string>();
    serviceFilterInfo: IFilterInfo;
    serviceSelected: any[] = [];
    serviceSet = new Set<string>();

    permissionFilterInfo: IFilterInfo;
    permissionSelected: any = null;

    readonly ID_KEY = 'id';
    readonly NAME_KEY = 'name';
    readonly CLOUD_KEY = 'cloud';
    readonly PRODUCT_KEY = 'product';
    readonly SUB_PRODUCT_KEY = 'subProduct';
    readonly SERVICE_KEY = 'service';

    constructor(
        public modalInputData: ModalInjectedData,
        public modalService: ModalService,
        private notificationsService: NotificationsService
    ) {
        this.initFilterInfos();
    }

    selectionChanged(value) {
        this.deleteDataOfUnselectedRows();
        this.gridRef.api.redrawRows();
    }

    ngOnInit(): void {
        this.widgetPermissionData = new Map();
        const acl = this.modalInputData.data['acl'];
        this.node = this.modalInputData.data['node'];
        if (this.modalInputData.data['isDuplicateView'])
            this.updateWidgetsListInNode =
                this.modalInputData.data['updateWidgetsListInNode'];
        this.preSelectedGroupedWidgets = new Map<string, string>();
        if (
            this.node &&
            !this.node.children.length &&
            this.node.data.widgets &&
            this.node.data.widgets.length
        ) {
            this.handleDefaults();
        }
        if (this.node && acl) {
            // Only showing full group
            this.tableGenInput = {
                afterResponse: (response: any) => {
                    if (response) {
                        this.isLoading = false;
                    }
                    this.prepareFilters(response);
                },
                listExtraction: {
                    type: 'DIRECT'
                },
                isExternalFilterPresent:
                    this.isExternalFilterAvailable.bind(this),
                doesExternalFilterPass: this.externalFilterFunction.bind(this),
                columns: [
                    {
                        columnKey: this.NAME_KEY,
                        columnName: 'Widget Name',
                        checkboxSelection: true,
                        headerCheckboxSelection: true,
                        tooltipField: this.NAME_KEY,
                        minWidth: 250,
                        flex: 4
                    },
                    {
                        columnKey: this.CLOUD_KEY,
                        columnName: '',
                        cellClass: 'cloud-icon-cell grid-cell-data-centred',
                        minWidth: 40,
                        flex: 1,
                        cellRenderer: (rowData: RowEvent) => {
                            let image = '';
                            let alt = '';
                            if (rowData.data.label.includes(CloudLabel.AWS)) {
                                image = getLogoPathOfCloudLabel(CloudLabel.AWS);
                                alt = CloudLabel.AWS;
                            } else if (
                                rowData.data.label.includes(
                                    CloudLabel.AZURE_CSP
                                )
                            ) {
                                image = getLogoPathOfCloudLabel(
                                    CloudLabel.AZURE_CSP
                                );
                                alt = CloudLabel.AZURE_CSP;
                            } else if (
                                rowData.data.label.includes(CloudLabel.AZURE_EA)
                            ) {
                                image = getLogoPathOfCloudLabel(
                                    CloudLabel.AZURE_EA
                                );
                                alt = CloudLabel.AZURE_EA;
                            } else if (
                                rowData.data.label.includes(
                                    CloudLabel.AZURE_PLAN
                                )
                            ) {
                                image = getLogoPathOfCloudLabel(
                                    CloudLabel.AZURE_PLAN
                                );
                                alt = CloudLabel.AZURE_PLAN;
                            } else if (
                                rowData.data.label.includes(CloudLabel.GCP)
                            ) {
                                image = getLogoPathOfCloudLabel(CloudLabel.GCP);
                                alt = CloudLabel.GCP;
                            } else if (
                                rowData.data.label.includes(CloudLabel.AZURE_SP)
                            ) {
                                image = getLogoPathOfCloudLabel(
                                    CloudLabel.AZURE_SP
                                );
                                alt = CloudLabel.AZURE_SP;
                            } else if (
                                rowData.data.label.includes(CloudLabel.AZURE_PG)
                            ) {
                                image = getLogoPathOfCloudLabel(
                                    CloudLabel.AZURE_PG
                                );
                                alt = CloudLabel.AZURE_PG;
                            } else if (
                                rowData.data.label.includes(
                                    CloudLabel.AZURE_MCA
                                )
                            ) {
                                image = getLogoPathOfCloudLabel(
                                    CloudLabel.AZURE_MCA
                                );
                                alt = CloudLabel.AZURE_MCA;
                            }

                            return `
                                <div class="image-container" style="width: 36px; height: 28px; display: flex; justify-content: center; align-items: center;">
                                    <img style="max-height: 100%; max-width: 100%;" src="${image}" alt="${alt}" />
                                </diV>
                            `;
                        }
                    },
                    {
                        columnKey: 'READ_ONLY',
                        columnName: 'Read',
                        cellClass: 'cell-center',
                        headerClass: 'header-center',
                        minWidth: 60,
                        flex: 1,
                        cellRenderer: (rowData: RowEvent) => {
                            return this.getRadioRendered(
                                rowData,
                                Permissions.READ
                            );
                        }
                    },
                    {
                        columnKey: 'READ_WRITE',
                        columnName: 'Read / Write',
                        cellClass: 'cell-center',
                        headerClass: 'header-center',
                        minWidth: 60,
                        flex: 1,
                        cellRenderer: (rowData: RowEvent) => {
                            return this.getRadioRendered(
                                rowData,
                                Permissions.READ_WRITE
                            );
                        }
                    },
                    {
                        columnKey: 'DENY',
                        columnName: 'Deny',
                        cellClass: 'cell-center',
                        headerClass: 'header-center',
                        minWidth: 60,
                        flex: 1,
                        cellRenderer: (rowData: RowEvent) => {
                            return this.getRadioRendered(
                                rowData,
                                Permissions.DENY
                            );
                        }
                    }
                ],
                noDataMessage: 'No widgets available in ACL',
                selection: 'single',
                selectionLimit: GlobalConfiguration.WIDGETS_PER_SERVICE_LIMIT,
                selectionLimitBreachMessage: `You can add maximum of ${GlobalConfiguration.WIDGETS_PER_SERVICE_LIMIT} widgets per module`
            };
            this.aclApiInfo = {
                host: '',
                apiRouteSuffix: ApiUrls.ACL_DESCRIBE.replace('{aclId}', acl),
                authorization: AuthorizationType.BEARER_TOKEN,
                customInput: null,
                requestType: RequestType.GET,
                intactUrl: ApiUrls.ACL_DESCRIBE
            };
        }
    }

    getRadioButton(
        inputName,
        inputId,
        callback,
        rowSelected = false,
        disabled = false,
        checked = false
    ) {
        const radioButtonContainer = document.createElement('div');
        const radioButtonSibling = document.createElement('div');
        const radioButton = document.createElement('input');
        radioButton.type = 'radio';
        radioButton.name = inputName;
        radioButton.value = inputId;
        radioButton.disabled = disabled;
        radioButton.checked = checked;
        radioButton.addEventListener('click', (event) => {
            callback(inputName, inputId);
        });
        radioButtonContainer.classList.add('radio-button-container');
        if (disabled) {
            radioButtonContainer.classList.add('disabled');
        }
        radioButtonSibling.classList.add('radio-button');
        radioButtonContainer.appendChild(radioButton);
        radioButtonContainer.appendChild(radioButtonSibling);
        radioButtonContainer.addEventListener('click', (event) => {
            if (rowSelected && !disabled) {
                radioButton.click();
            }
        });
        return radioButtonContainer;
    }

    getRadioRendered(rowData: RowEvent, permission: Permissions) {
        const data = rowData.data;
        const checked =
            this.widgetPermissionData.size &&
            this.widgetPermissionData.has(data.id) &&
            this.widgetPermissionData.get(data.id) === permission;
        const rowSelected = rowData.node.isSelected();
        const disabled = !rowSelected;
        return this.getRadioButton(
            data[this.ID_KEY],
            permission,
            (inputName, inputValue) => {
                this.widgetPermissionData.set(inputName, inputValue);
            },
            rowSelected,
            disabled,
            checked
        );
    }

    handleDefaults() {
        for (const widget of this.node.data.widgets) {
            if (
                widget[this.GROUP_ID_KEY] &&
                widget[this.GROUP_WIDGETS_KEY] &&
                widget[this.GROUP_WIDGETS_KEY].length
            ) {
                widget[this.GROUP_WIDGETS_KEY].forEach((groupWidget) => {
                    this.preSelectedGroupedWidgets.set(
                        groupWidget.id,
                        widget[this.GROUP_ID_KEY]
                    );
                    this.widgetPermissionData.set(
                        groupWidget.id,
                        groupWidget.permission
                    );
                });
            } else {
                this.widgetPermissionData.set(widget.id, widget.permission);
            }
        }
    }

    gridSetInitialSelection() {
        if (
            this.gridRef &&
            this.gridRef.api &&
            this.node &&
            this.node.data.widgets &&
            this.node.data.widgets.length &&
            this.widgetPermissionData &&
            this.widgetPermissionData.size
        ) {
            const total = this.widgetPermissionData.size;
            let count = 0;
            const nodes: RowNode[] = (this.gridRef.api as any)['rowModel'][
                'rowsToDisplay'
            ];
            let index = 0;
            for (const node of nodes) {
                if (
                    count < total - 1 &&
                    this.widgetPermissionData.has(node.data.id)
                ) {
                    node.setSelected(true, false, true);
                    ++count;
                } else if (
                    count === total - 1 &&
                    this.widgetPermissionData.has(node.data.id)
                ) {
                    node.setSelected(true);
                    ++count;
                }
                ++index;
            }
            this.gridRef.api.redrawRows();
        }
    }

    deleteDataOfUnselectedRows() {
        if (this.gridRef && this.gridRef.api) {
            const selectedIds = this.gridRef.api
                .getSelectedRows()
                .map((row) => row.id);
            if (selectedIds.length === 0) {
                this.permissionSelected = null;
            }
            for (const [k, v] of this.widgetPermissionData) {
                if (!selectedIds.includes(k)) {
                    this.widgetPermissionData.delete(k);
                }
            }
        }
    }

    getFinalWidgetList() {
        if (this.gridRef && this.gridRef.api) {
            const groupMap = new Map<string, any>();
            const selectedWidgets = this.gridRef.api
                .getSelectedRows()
                .map((rowData) => {
                    return {
                        ...rowData,
                        permission: this.widgetPermissionData.get(rowData.id)
                    };
                });

            if (
                this.preSelectedGroupedWidgets &&
                this.preSelectedGroupedWidgets.size
            ) {
                for (let index = 0; index < selectedWidgets.length; index++) {
                    const widget = selectedWidgets[index];
                    const widgetId = widget.id;
                    if (this.preSelectedGroupedWidgets.has(widgetId)) {
                        const groupId =
                            this.preSelectedGroupedWidgets.get(widgetId);
                        if (groupMap.has(groupId)) {
                            const group = groupMap.get(groupId);
                            if (group && group[this.GROUP_WIDGETS_KEY]) {
                                group[this.GROUP_WIDGETS_KEY].push(widget);
                                selectedWidgets.splice(index--, 1);
                            }
                        } else {
                            const group = {
                                [this.GROUP_ID_KEY]: groupId,
                                [this.GROUP_WIDGETS_KEY]: [widget],
                                listIndex: index
                            };
                            groupMap.set(groupId, group);
                            selectedWidgets.splice(index, 1, group);
                        }
                    }
                }
                groupMap.forEach((group, groupId) => {
                    if (
                        group &&
                        group[this.GROUP_WIDGETS_KEY] &&
                        group[this.GROUP_WIDGETS_KEY].length
                    ) {
                        if (group[this.GROUP_WIDGETS_KEY].length === 1) {
                            const listIndex = group['listIndex'];
                            selectedWidgets.splice(
                                listIndex,
                                1,
                                group[this.GROUP_WIDGETS_KEY][0]
                            );
                        } else {
                            delete group['listIndex'];
                        }
                    }
                });
            }
            return selectedWidgets;
        } else {
            return this.node.data.widgets;
        }
    }

    insertWidgetsToModule() {
        if (
            this.gridRef &&
            this.gridRef.api &&
            !this.gridRef.api.getSelectedRows().length
        ) {
            this.notificationsService.showSnackBar(
                'No widgets selected to add',
                true
            );
            return;
        }
        if (!this.checkWidgetPermission()) {
            this.notificationsService.showSnackBar(
                'Permission not assigned to all widgets. Provide permission and try again',
                true
            );
            return;
        }
        this.node.data.widgets = this.getFinalWidgetList();
        if (this.modalInputData.data['isDuplicateView'])
            this.updateWidgetsListInNode(this.node.data.widgets);
        this.modalService.closeModal(null, this.modalInputData.modalId);
    }

    checkWidgetPermission(): boolean {
        if (
            this.gridRef &&
            this.gridRef.api &&
            this.widgetPermissionData.size
        ) {
            const selectedRows = this.gridRef.api.getSelectedRows();
            let invalid = false;
            for (const row of selectedRows) {
                if (
                    !this.widgetPermissionData.has(row.id) ||
                    this.widgetPermissionData.get(row.id) === null
                ) {
                    invalid = true;
                }
            }
            if (invalid) {
                return false;
            }
            return true;
        }
        return false;
    }

    changePermissionOfAllWidgets(event) {
        if (event === this.permissionSelected) {
            return;
        }
        this.permissionSelected = event;
        if (this.permissionSelected) {
            if (this.gridRef && this.gridRef.api) {
                this.gridRef.api.getSelectedRows().forEach((row) => {
                    this.widgetPermissionData.set(
                        row.id,
                        this.permissionSelected
                    );
                });
                this.gridRef.api.redrawRows();
            }
        } else {
            this.permissionSelected = null;
        }
    }

    // Below Functions deals with filters

    setCloudFilterInfo(listData?: string[]) {
        this.cloudFilterInfo = {
            placeholder: 'Cloud',
            label: 'Cloud',
            listData: listData ? Helper.mapToDropdownData(listData) : []
        };
    }

    setProductFilterInfo(listData?: string[]) {
        this.productFilterInfo = {
            placeholder: 'Product',
            label: 'Product',
            listData: listData ? Helper.mapToDropdownData(listData) : []
        };
    }

    setSubProductFilterInfo(listData?: string[]) {
        this.subProductFilterInfo = {
            placeholder: 'Sub - Product',
            label: 'Sub - Product',
            listData: listData ? Helper.mapToDropdownData(listData) : []
        };
    }

    setServiceFilterInfo(listData?: string[]) {
        this.serviceFilterInfo = {
            placeholder: 'Service',
            label: 'Service',
            listData: listData ? Helper.mapToDropdownData(listData) : []
        };
    }

    initFilterInfos() {
        this.setCloudFilterInfo();
        this.setProductFilterInfo();
        this.setSubProductFilterInfo();
        this.setServiceFilterInfo();
        this.permissionFilterInfo = {
            placeholder: 'Permission',
            label: 'Permission',
            listData: [
                {
                    id: Permissions.READ,
                    label: 'Read'
                },
                {
                    id: Permissions.READ_WRITE,
                    label: 'Read/Write'
                },
                {
                    id: Permissions.DENY,
                    label: 'Deny'
                }
            ]
        };
    }

    prepareFilters(widgetList: any[]) {
        if (widgetList && widgetList.length) {
            this.uniqueCpspsPairList = [];
            const keySet = new Set<string>();
            widgetList.forEach((masterAclWidget) => {
                let cloud = null;
                let product = null;
                let subProduct = null;
                let service = null;
                if (masterAclWidget[this.CLOUD_KEY]) {
                    cloud = masterAclWidget[this.CLOUD_KEY];
                    this.cloudSet.add(cloud);
                }
                if (masterAclWidget[this.PRODUCT_KEY]) {
                    product = masterAclWidget[this.PRODUCT_KEY];
                    this.productSet.add(product);
                }
                if (masterAclWidget[this.SUB_PRODUCT_KEY]) {
                    subProduct = masterAclWidget[this.SUB_PRODUCT_KEY];
                    this.subProductSet.add(subProduct);
                }
                if (masterAclWidget[this.SERVICE_KEY]) {
                    service = masterAclWidget[this.SERVICE_KEY];
                    this.serviceSet.add(service);
                }
                if (cloud || product || subProduct || service) {
                    const uniquePairString = [
                        cloud,
                        product,
                        subProduct,
                        service
                    ].join('|');
                    if (!keySet.has(uniquePairString)) {
                        keySet.add(uniquePairString);
                        this.uniqueCpspsPairList.push({
                            cloud: cloud,
                            product: product,
                            subProduct: subProduct,
                            service: service
                        });
                    }
                }
            });
            this.setCloudFilterInfo(Array.from(this.cloudSet.values()));
            this.setProductFilterInfo(Array.from(this.productSet.values()));
            this.setSubProductFilterInfo(
                Array.from(this.subProductSet.values())
            );
            this.setServiceFilterInfo(Array.from(this.serviceSet.values()));
        }
    }

    updateListDataForCloud(selectedClouds: string[]) {
        let localProductList = null;
        let localSubProductList = null;
        let localServiceList = null;
        const localProductSet = new Set<string>();
        const localSubProductSet = new Set<string>();
        const localServiceSet = new Set<string>();
        this.uniqueCpspsPairList.forEach((pair) => {
            if (selectedClouds && selectedClouds.length) {
                if (pair.cloud && selectedClouds.includes(pair.cloud)) {
                    if (pair.product) {
                        localProductSet.add(pair.product);
                    }
                    if (this.productSelected && this.productSelected.length) {
                        if (
                            pair.product &&
                            (this.productSelected.includes(pair.product) ||
                                localProductSet.has(pair.product))
                        ) {
                            if (pair.subProduct) {
                                localSubProductSet.add(pair.subProduct);
                            }
                            if (
                                this.subProductSelected &&
                                this.subProductSelected.length
                            ) {
                                if (
                                    pair.subProduct &&
                                    (this.subProductSelected.includes(
                                        pair.subProduct
                                    ) ||
                                        localSubProductSet.has(pair.subProduct))
                                ) {
                                    if (pair.service) {
                                        localServiceSet.add(pair.service);
                                    }
                                }
                            } else {
                                if (pair.service) {
                                    localServiceSet.add(pair.service);
                                }
                            }
                        }
                    } else {
                        if (pair.subProduct) {
                            localSubProductSet.add(pair.subProduct);
                        }
                        if (
                            this.subProductSelected &&
                            this.subProductSelected.length
                        ) {
                            if (
                                pair.subProduct &&
                                (this.subProductSelected.includes(
                                    pair.subProduct
                                ) ||
                                    localSubProductSet.has(pair.subProduct))
                            ) {
                                if (pair.service) {
                                    localServiceSet.add(pair.service);
                                }
                            }
                        } else {
                            if (pair.service) {
                                localServiceSet.add(pair.service);
                            }
                        }
                    }
                }
            } else {
                if (pair.product) {
                    localProductSet.add(pair.product);
                }
                if (this.productSelected && this.productSelected.length) {
                    if (
                        pair.product &&
                        (this.productSelected.includes(pair.product) ||
                            localProductSet.has(pair.product))
                    ) {
                        if (pair.subProduct) {
                            localSubProductSet.add(pair.subProduct);
                        }
                        if (
                            this.subProductSelected &&
                            this.subProductSelected.length
                        ) {
                            if (
                                pair.subProduct &&
                                (this.subProductSelected.includes(
                                    pair.subProduct
                                ) ||
                                    localSubProductSet.has(pair.subProduct))
                            ) {
                                if (pair.service) {
                                    localServiceSet.add(pair.service);
                                }
                            }
                        } else {
                            if (pair.service) {
                                localServiceSet.add(pair.service);
                            }
                        }
                    }
                } else {
                    if (pair.subProduct) {
                        localSubProductSet.add(pair.subProduct);
                    }
                    if (
                        this.subProductSelected &&
                        this.subProductSelected.length
                    ) {
                        if (
                            pair.subProduct &&
                            (this.subProductSelected.includes(
                                pair.subProduct
                            ) ||
                                localSubProductSet.has(pair.subProduct))
                        ) {
                            if (pair.service) {
                                localServiceSet.add(pair.service);
                            }
                        }
                    } else {
                        if (pair.service) {
                            localServiceSet.add(pair.service);
                        }
                    }
                }
            }
        });
        localProductList = Array.from(localProductSet.values());
        localSubProductList = Array.from(localSubProductSet.values());
        localServiceList = Array.from(localServiceSet.values());
        this.updateProductSelectedValues(localProductList);
        this.setProductFilterInfo(localProductList);
        this.updateSubProductSelectedValues(localSubProductList);
        this.setSubProductFilterInfo(localSubProductList);
        this.updateServiceSelectedValues(localServiceList);
        this.setServiceFilterInfo(localServiceList);
    }

    updateListDataForProduct(selectedProducts: string[]) {
        let localSubProductList = null;
        let localServiceList = null;
        const localSubProductSet = new Set<string>();
        const localServiceSet = new Set<string>();
        this.uniqueCpspsPairList.forEach((pair) => {
            if (selectedProducts && selectedProducts.length) {
                if (pair.product && selectedProducts.includes(pair.product)) {
                    if (this.cloudSelected && this.cloudSelected.length) {
                        if (
                            pair.cloud &&
                            this.cloudSelected.includes(pair.cloud)
                        ) {
                            if (pair.subProduct) {
                                localSubProductSet.add(pair.subProduct);
                            }
                            if (
                                this.subProductSelected &&
                                this.subProductSelected.length
                            ) {
                                if (
                                    pair.subProduct &&
                                    (this.subProductSelected.includes(
                                        pair.subProduct
                                    ) ||
                                        localSubProductSet.has(pair.subProduct))
                                ) {
                                    if (pair.service) {
                                        localServiceSet.add(pair.service);
                                    }
                                }
                            } else {
                                if (pair.service) {
                                    localServiceSet.add(pair.service);
                                }
                            }
                        }
                    } else {
                        if (pair.subProduct) {
                            localSubProductSet.add(pair.subProduct);
                        }
                        if (
                            this.subProductSelected &&
                            this.subProductSelected.length
                        ) {
                            if (
                                pair.subProduct &&
                                (this.subProductSelected.includes(
                                    pair.subProduct
                                ) ||
                                    localSubProductSet.has(pair.subProduct))
                            ) {
                                if (pair.service) {
                                    localServiceSet.add(pair.service);
                                }
                            }
                        } else {
                            if (pair.service) {
                                localServiceSet.add(pair.service);
                            }
                        }
                    }
                }
            } else {
                if (this.cloudSelected && this.cloudSelected.length) {
                    if (pair.cloud && this.cloudSelected.includes(pair.cloud)) {
                        if (pair.subProduct) {
                            localSubProductSet.add(pair.subProduct);
                        }
                        if (
                            this.subProductSelected &&
                            this.subProductSelected.length
                        ) {
                            if (
                                pair.subProduct &&
                                (this.subProductSelected.includes(
                                    pair.subProduct
                                ) ||
                                    localSubProductSet.has(pair.subProduct))
                            ) {
                                if (pair.service) {
                                    localServiceSet.add(pair.service);
                                }
                            }
                        } else {
                            if (pair.service) {
                                localServiceSet.add(pair.service);
                            }
                        }
                    }
                } else {
                    if (pair.subProduct) {
                        localSubProductSet.add(pair.subProduct);
                    }
                    if (
                        this.subProductSelected &&
                        this.subProductSelected.length
                    ) {
                        if (
                            pair.subProduct &&
                            (this.subProductSelected.includes(
                                pair.subProduct
                            ) ||
                                localSubProductSet.has(pair.subProduct))
                        ) {
                            if (pair.service) {
                                localServiceSet.add(pair.service);
                            }
                        }
                    } else {
                        if (pair.service) {
                            localServiceSet.add(pair.service);
                        }
                    }
                }
            }
        });
        localSubProductList = Array.from(localSubProductSet.values());
        localServiceList = Array.from(localServiceSet.values());
        this.updateSubProductSelectedValues(localSubProductList);
        this.setSubProductFilterInfo(localSubProductList);
        this.updateServiceSelectedValues(localServiceList);
        this.setServiceFilterInfo(localServiceList);
    }

    updateListDataForSubProduct(selectedSubProducts: string[]) {
        let localServiceList = null;
        const localServiceSet = new Set<string>();
        this.uniqueCpspsPairList.forEach((pair) => {
            if (selectedSubProducts && selectedSubProducts.length) {
                if (
                    pair.subProduct &&
                    selectedSubProducts.includes(pair.subProduct)
                ) {
                    if (this.cloudSelected && this.cloudSelected.length) {
                        if (
                            pair.cloud &&
                            this.cloudSelected.includes(pair.cloud)
                        ) {
                            if (
                                this.productSelected &&
                                this.productSelected.length
                            ) {
                                if (
                                    pair.product &&
                                    this.productSelected.includes(pair.product)
                                ) {
                                    if (pair.service) {
                                        localServiceSet.add(pair.service);
                                    }
                                }
                            } else {
                                if (pair.service) {
                                    localServiceSet.add(pair.service);
                                }
                            }
                        }
                    } else {
                        if (
                            this.productSelected &&
                            this.productSelected.length
                        ) {
                            if (
                                pair.product &&
                                this.productSelected.includes(pair.product)
                            ) {
                                if (pair.service) {
                                    localServiceSet.add(pair.service);
                                }
                            }
                        } else {
                            if (pair.service) {
                                localServiceSet.add(pair.service);
                            }
                        }
                    }
                }
            } else {
                if (this.cloudSelected && this.cloudSelected.length) {
                    if (pair.cloud && this.cloudSelected.includes(pair.cloud)) {
                        if (
                            this.productSelected &&
                            this.productSelected.length
                        ) {
                            if (
                                pair.product &&
                                this.productSelected.includes(pair.product)
                            ) {
                                if (pair.service) {
                                    localServiceSet.add(pair.service);
                                }
                            }
                        } else {
                            if (pair.service) {
                                localServiceSet.add(pair.service);
                            }
                        }
                    }
                } else {
                    if (this.productSelected && this.productSelected.length) {
                        if (
                            pair.product &&
                            this.productSelected.includes(pair.product)
                        ) {
                            if (pair.service) {
                                localServiceSet.add(pair.service);
                            }
                        }
                    } else {
                        if (pair.service) {
                            localServiceSet.add(pair.service);
                        }
                    }
                }
            }
        });
        localServiceList = Array.from(localServiceSet.values());
        this.updateServiceSelectedValues(localServiceList);
        this.setServiceFilterInfo(localServiceList);
    }

    updateProductSelectedValues(checkList) {
        const selected = this.productSelected.filter((productSel) => {
            return checkList.includes(productSel);
        });
        if (Helper.areDistinctLists(selected, this.productSelected)) {
            this.productSelected = selected;
        }
    }

    updateSubProductSelectedValues(checkList) {
        const selected = this.subProductSelected.filter((subProductSel) => {
            return checkList.includes(subProductSel);
        });
        if (Helper.areDistinctLists(selected, this.subProductSelected)) {
            this.subProductSelected = selected;
        }
    }

    updateServiceSelectedValues(checkList) {
        const selected = this.serviceSelected.filter((serviceSel) => {
            return checkList.includes(serviceSel);
        });
        if (Helper.areDistinctLists(selected, this.serviceSelected)) {
            this.serviceSelected = selected;
        }
    }

    updateFilterInfoListData(selectedValues, type) {
        if (this.gridRef) {
            if (type === this.CLOUD_KEY) {
                if (
                    Helper.areDistinctLists(this.cloudSelected, selectedValues)
                ) {
                    this.updateListDataForCloud(selectedValues);
                    this.cloudSelected = selectedValues;
                    this.gridRef.api.onFilterChanged();
                }
            }
            if (type === this.PRODUCT_KEY) {
                if (
                    Helper.areDistinctLists(
                        this.productSelected,
                        selectedValues
                    )
                ) {
                    this.updateListDataForProduct(selectedValues);
                    this.productSelected = selectedValues;
                    this.gridRef.api.onFilterChanged();
                }
            }
            if (type === this.SUB_PRODUCT_KEY) {
                if (
                    Helper.areDistinctLists(
                        this.subProductSelected,
                        selectedValues
                    )
                ) {
                    this.updateListDataForSubProduct(selectedValues);
                    this.subProductSelected = selectedValues;
                    this.gridRef.api.onFilterChanged();
                }
            }
            if (type === this.SERVICE_KEY) {
                if (
                    Helper.areDistinctLists(
                        this.serviceSelected,
                        selectedValues
                    )
                ) {
                    this.serviceSelected = selectedValues;
                    this.gridRef.api.onFilterChanged();
                }
            }
        }
    }

    isExternalFilterAvailable() {
        if (
            (this.cloudSelected && this.cloudSelected.length) ||
            (this.productSelected && this.productSelected.length) ||
            (this.subProductSelected && this.subProductSelected.length) ||
            (this.serviceSelected && this.serviceSelected.length)
        ) {
            return true;
        }
        return false;
    }

    externalFilterFunction(node: RowNode) {
        const nodeData = node.data;
        let result = true;
        if (this.cloudSelected && this.cloudSelected.length) {
            if (this.cloudSelected.includes(nodeData[this.CLOUD_KEY])) {
                result = result && true;
            } else {
                result = result && false;
                return result;
            }
        }
        if (this.productSelected && this.productSelected.length) {
            if (this.productSelected.includes(nodeData[this.PRODUCT_KEY])) {
                result = result && true;
            } else {
                result = result && false;
                return result;
            }
        }
        if (this.subProductSelected && this.subProductSelected.length) {
            if (
                this.subProductSelected.includes(nodeData[this.SUB_PRODUCT_KEY])
            ) {
                result = result && true;
            } else {
                result = result && false;
                return result;
            }
        }
        if (this.serviceSelected && this.serviceSelected.length) {
            if (this.serviceSelected.includes(nodeData[this.SERVICE_KEY])) {
                result = result && true;
            } else {
                result = result && false;
            }
        }
        return result;
    }

    onQuickFilterChanged(id: string) {
        if (this.gridRef) {
            this.gridRef.api.setQuickFilter(
                document.getElementById(id)['value']
            );
        }
    }
}

enum Permissions {
    READ = 'READ',
    READ_WRITE = 'READ_WRITE',
    DENY = 'DENY'
}
