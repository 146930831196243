import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GridOptions, RowEvent } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { ContentType } from 'src/app/shared/enums/ContentType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { HitApi } from './../../../../classes/HitApi';
import {
    HtmlTargetAttributeValue,
    IButtonGeneratorInput
} from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from './../../../../interfaces/hit-api/IHitApi';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-add-azure-sponsorship-account',
    templateUrl: './add-azure-sponsorship-account.component.html',
    styleUrls: ['./add-azure-sponsorship-account.component.sass']
})
export class AddAzureSponsorshipAccountComponent implements OnInit {
    tableInput: ITableGeneratorInput;
    widgetRef: Widget;
    tableData: any;
    agGrid: GridOptions;
    excelForm: FormGroup;
    excelFormInput: IFormGeneratorInput = null;
    excelLoader: boolean;
    loader: boolean;
    resetClicked: boolean = false;
    Tab = Tab;
    selectedIndexForTab = 0;
    activeTabIndex = 0;
    buttonInputs: IButtonGeneratorInput[];

    constructor(
        private modalInjectedData: ModalInjectedData,
        private modalService: ModalService,
        private ngZone: NgZone
    ) {
        this.widgetRef = modalInjectedData['data']['widgetRef'];
    }
    excelData;
    originalExcelData;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };

    ngOnInit(): void {
        this.setUpBasics();
        this.modalService.resetModal.subscribe((reset) => {
            this.resetExcelForm();
        });
        this.buttonInputs = [
            {
                buttonName: 'Download',
                buttonType: ButtonType.LINK,
                buttonColorType: ButtonColorType.PRIMARY,
                link: 'assets/onboarding-excel/azure-sponsorship-onboarding.xlsx',
                function: () => {},
                linkTargetValue: HtmlTargetAttributeValue.SELF,
                fileName: 'Template.xlsx'
            },
            {
                buttonName: 'Submit',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.save(buttonRef);
                }
            }
        ];
    }
    resetExcelForm() {
        const form = this.excelFormInput;
        this.excelFormInput = null;
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.excelFormInput = form;
            }, 0);
        });

        if (this.agGrid) {
            this.agGrid.api.setRowData([]);
        }
        this.excelLoader = false;
        this.resetClicked = true;
        this.widgetRef.changeDetectorRef.detectChanges();
    }
    setUpBasics() {
        this.tableInput = {
            tableAutoHeight: true,
            extraClass: ' upload-container',
            listExtraction: {
                type: 'DIRECT'
            },
            widgetIconData: {
                class: '',
                type: IconType.FONTAWSOME
            },
            columns: [
                {
                    columnName: 'Subscription ID',
                    pinned: 'left',
                    columnKey: 'subscriptionId',
                    filter: false,
                    minWidth: 165,
                    maxWidth: 165
                },
                {
                    columnName: 'Application (Client) ID',
                    columnKey: 'appId',
                    filter: false,
                    minWidth: 170
                },
                {
                    columnName: 'Account Name',
                    columnKey: 'accountName',
                    filter: false,
                    minWidth: 170
                },
                {
                    columnName: 'Account SPOC',
                    columnKey: 'spoc',
                    filter: false,
                    minWidth: 170
                },

                {
                    columnName: 'Secret Key',
                    columnKey: 'clientSecretKey',
                    filter: false,
                    minWidth: 170
                },
                {
                    columnName: 'Domain ID',
                    columnKey: 'domain',
                    filter: false
                },
                {
                    columnName: 'Export Name',
                    columnKey: 'exportName',
                    filter: false
                },
                {
                    columnName: 'Storage Account Name',
                    columnKey: 'storageAccountName',
                    filter: false
                },
                {
                    columnName: 'Container Name',
                    columnKey: 'containerName',
                    filter: false
                },
                {
                    columnName: 'Storage Directory',
                    columnKey: 'storageDirectory',
                    filter: false
                },
                {
                    columnName: 'Upload Status',
                    columnKey: 'uploadStatus',
                    pinned: 'right',
                    filter: false,
                    minWidth: 141,
                    cellRenderer: (rowData: RowEvent) => {
                        if (rowData.data.uploadStatus === true) {
                            return `<span class="green-status">True</span>`;
                        } else {
                            return `<span class="red-status">False</span>`;
                        }
                    }
                },
                {
                    columnName: 'Actions',
                    columnKey: 'actions',
                    filter: false,
                    pinned: 'right',
                    minWidth: 100,
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: RowEvent) => {
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'Delete',
                                buttonColorType: ButtonColorType.WARN,
                                buttonType: ButtonType.TEXT,
                                function: this.deleteRowFromUpload.bind(
                                    this,
                                    rowData.data
                                )
                            }
                        ];
                        rowData['buttonGenInputs'] = buttons;
                        return rowData;
                    }
                }
            ]
        };
        this.excelFormInput = {
            formName: 'Excel Upload',
            submitButton: null,
            fields: [
                {
                    label: 'Excel File Upload',
                    fieldType: FilterType.FILE,
                    required: true,
                    accept: '.xls, .xlsx',
                    suffixButton: {
                        text: 'Browse',
                        show: true
                    },
                    appearance: 'legacy',
                    placeholder: 'Select a file to upload...',
                    name: 'excelFile'
                }
            ],
            state: FormState.CREATE
        };
    }
    excelFile($event) {
        if ($event['excelFile']) {
            this.excelLoader = true;
            this.resetClicked = false;

            const apiArgs: IHitApi = Helper.generateHitApiConfig(
                this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                    'generatePresignedUrl'
                ]
            );
            apiArgs.function = (response) => {
                const saveResponse = response;
                const preSignedUrl = response['preSignedURL'];
                if (preSignedUrl) {
                    const file = this.excelForm.value.excelFile['_files'][0];
                    const args: IHitApi = {
                        url: preSignedUrl,
                        requestType: RequestType.PUT,
                        config: {
                            ignoreBaseUrl: true,
                            authorization: null,
                            defaultHeaders: {
                                'Content-Type': ContentType.EXCEL
                            }
                        },
                        function: (response) => {
                            const hitApi: IHitApi = Helper.generateHitApiConfig(
                                this.widgetRef.widgetData.widgetInfo[
                                    'additionalApisForWidget'
                                ]['getExcelData']
                            );
                            hitApi.function = (response) => {
                                this.excelLoader = false;
                                this.excelData = response;
                                this.getExcelData();
                            };
                            hitApi.errorFunction = (error) => {
                                this.excelLoader = false;
                                this.tableData = [];
                                Helper.showErrorMessage(
                                    this.widgetRef.notificationsService,
                                    error,
                                    'Error uploading excel template.'
                                );
                                this.widgetRef.changeDetectorRef.detectChanges();
                            };
                            hitApi.input = {};
                            new HitApi(
                                hitApi,
                                this.widgetRef.httpService,
                                this.widgetRef.ngZone
                            ).hitApi();
                        },
                        errorFunction: (error) => {
                            this.excelLoader = false;
                            this.tableData = [];
                            Helper.showErrorMessage(
                                this.widgetRef.notificationsService,
                                error,
                                'Error uploading excel template.'
                            );
                            this.widgetRef.changeDetectorRef.detectChanges();
                        },
                        input: file,
                        uniqueIdentity: Symbol()
                    };
                    new HitApi(
                        args,
                        this.widgetRef.httpService,
                        this.widgetRef.ngZone
                    ).hitApi();
                }
            };
            apiArgs.input = {};
            apiArgs.errorFunction = (error) => {
                this.tableData = [];
                this.excelLoader = false;
                this.widgetRef.changeDetectorRef.detectChanges();
            };
            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }
    onGridReady($event) {
        this.agGrid = $event;

        this.agGrid.api.selectAll();
    }
    getExcelData() {
        this.originalExcelData = Helper.cloneDeep(this.excelData);

        this.excelData.forEach((data, index) => {
            for (const key of Object.keys(
                this.excelData[index]['azureSponsorshipPayAsYouGoAttributes']
            )) {
                this.excelData[index][key] =
                    this.excelData[index][
                        'azureSponsorshipPayAsYouGoAttributes'
                    ][key];
            }
            this.tableData = Helper.cloneDeep(this.excelData);
            this.widgetRef.changeDetectorRef.detectChanges();
        });
    }
    changeTab($event) {
        if (this.activeTabIndex === $event.index) {
            return;
        }
        const confirmationModal: IConfirmationModal = {
            modalName: 'Message',

            modalIcon: {
                type: IconType.FONTAWSOME,
                class: ''
            },
            contextIcon: {
                type: IconType.FONTAWSOME,
                class: ''
            },
            buttonColorType: ButtonColorType.PRIMARY,
            buttonText: 'Continue',
            fontSize: 1.12,
            modalHeightVh: 25,
            modalWidthVh: 47,
            hideCancelButton: true,
            customClass: 'confirm-message',
            disableClose: true,
            function: (modalId: Symbol) => {
                this.activeTabIndex = $event.index;
                this.resetExcelForm();
                this.modalService.closeModal(null, modalId);
            },
            cancelFunction: (modalId: Symbol) => {
                this.selectedIndexForTab = this.activeTabIndex;
            },
            closeCallBack: () => {
                this.selectedIndexForTab = this.activeTabIndex;
            },
            confirmationMessage:
                'Switching the tabs will discard all the entered information.'
        };
        this.modalService.openConfirmationModal(confirmationModal);
    }

    save(buttonRef: IButtonGeneratorInput) {
        Helper.markAllFieldAsTouched(this.excelForm);
        if (this.excelForm.invalid) {
            return;
        }
        if (this.agGrid && !this.agGrid.api.getSelectedNodes().length) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Select at least one entry to perform the action'
            );
            return;
        }
        buttonRef.loader = true;
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                'uploadBatchAccount'
            ]
        );
        apiArgs.input = this.createPayload();
        apiArgs.function = (response) => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'Account Onboarded Successfully'
            );
            this.widgetRef.refreshWidget();
            this.modalService.closeModal(null, this.modalInjectedData.modalId);
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error While adding account'
            );
            this.widgetRef.refreshWidget();
            this.modalService.closeModal(null, this.modalInjectedData.modalId);
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    createPayload() {
        const payloadArray = [];
        if (this.agGrid) {
            this.agGrid.api.getSelectedNodes().forEach((rowData, index) => {
                const finalPayloadData = {
                    accountName: rowData.data['accountName'],
                    spoc: rowData.data['spoc'],
                    type: !this.activeTabIndex
                        ? 'azure_pay_as_you_go'
                        : 'sponsorship',
                    azureSponsorshipPayAsYouGoAttributes: {},
                    detailsEnteredManually:
                        rowData.data['detailsEnteredManually']
                };

                for (const val in rowData.data[
                    'azureSponsorshipPayAsYouGoAttributes'
                ]) {
                    if (val !== ('accountName' || 'accountSpoc')) {
                        finalPayloadData[
                            'azureSponsorshipPayAsYouGoAttributes'
                        ][val] = rowData.data[val];
                    }
                }
                payloadArray.push(finalPayloadData);
            });
        }

        return payloadArray;
    }
    deleteRowFromUpload(rowData) {
        this.agGrid.api.applyTransaction({
            remove: new Array(rowData)
        });
    }
}
enum Tab {
    PAY_AS_YOU_GO = 'Pay As You Go Account',
    SPONSORSHIP = 'Sponsorship Account'
}
