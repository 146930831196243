export enum IntegrationName {
    DOWNLOAD_REPORT = 'DOWNLOAD_REPORT',
    EMAIL = 'EMAIL',
    GMAIL = 'GMAIL',
    O365 = 'O365',
    SLACK = 'SLACK',
    FRESHDESK = 'FRESHDESK',
    FRESHSERVICE = 'FRESHSERVICE',
    SERVICENOW = 'SERVICENOW',
    ZENDESK = 'ZENDESK',
    JIRADESK = 'JIRADESK',
    SNS = 'SNS',
    AUTOTASK = 'AUTOTASK',
    AUTOTASK_CHILD = 'AUTOTASK_CHILD',
    AUTOTASK_PARENT = 'AUTOTASK_PARENT',
    OTRS = 'OTRS',
    OTRS_USER = 'OTRS_USER',
    OTRS_PARENT = 'OTRS_PARENT',
    CASDM = 'CASDM',
    CASDM_CHILD = 'CASDM_CHILD',
    CASDM_PARENT = 'CASDM_PARENT',
    SSO = 'SSO',
    ZOHO = 'ZOHO',
    SALESFORCE = 'SALESFORCE'
}
