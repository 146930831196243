import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { NotificationsService } from './../../../../services/notifications/notifications.service';

@Component({
    selector: 'app-rightsizing-modal-table',
    templateUrl: './rightsizing-modal-table.component.html',
    styleUrls: ['./rightsizing-modal-table.component.sass']
})
export class RightsizingModalTableComponent implements OnInit {
    @ViewChild('agGrid', { static: false }) agGrid: GridOptions;
    columnDefs = [];
    defaultColDef = {};
    rowData = [];
    data = {
        inputData: {
            resources: ''
        }
    };
    loader = false;
    gridApi;
    gridColumnApi;
    isTable = true;
    fields = [];
    inputData = {};
    nextBtnClicked = false;
    agGridIcons = Helper.getAgGridIcons();
    nextButton: IButtonGeneratorInput = {
        buttonName: 'Next',
        buttonType: ButtonType.RAISED,
        buttonColorType: ButtonColorType.PRIMARY,
        function: () => {
            this.nextButtonClicked();
        }
    };

    constructor(
        private modalData: ModalInjectedData,
        private notificationsService: NotificationsService,
        private httpService: HttpService,
        private ngZone: NgZone,
        public multiStepFormService: MultiStepFormService
    ) {}

    ngOnInit(): void {
        if (
            this.modalData['data']['nonRecommended'][
                this.modalData['data']['nonRecommended']['steps'][0]
            ]['displayTable']
        ) {
            this.loader = true;
            const args: IHitApi = {
                url: `${this.modalData.data['host']}${this.modalData.data['nonRecommended']['selectResourceTypes']['displayTable']['apiPath']}`,
                intactUrl: `${this.modalData.data['host']}{apiPath}`,
                input: {},
                requestType: RequestType.POST,
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.AUTHORIZATION_TOKEN_V2,
                    ignoreBaseUrl: true
                },
                function: (response) => {
                    this.loader = false;
                    this.drawTable(response['dataList']);
                },
                errorFunction: (error) => {
                    this.notificationsService.showSnackBar(
                        error.error.message,
                        true
                    );
                }
            };
            new HitApi(args, this.httpService, this.ngZone).hitApi();
        } else if (
            this.modalData['data']['nonRecommended'][
                this.modalData['data']['steps'][0]
            ]['displayFields']
        ) {
            this.fields =
                this.modalData['data'][this.modalData['data']['steps'][0]][
                    'displayFields'
                ];
            this.isTable = false;
            this.fields.forEach((element) => {
                this.inputData[element['selector']] = element['defaultValue'];
            });
        }
        if (
            this.multiStepFormService.stepData &&
            this.multiStepFormService.stepData
                .get(this.modalData.modalId)
                .has(1)
        ) {
            this.data = this.multiStepFormService.stepData
                .get(this.modalData.modalId)
                .get(1);
        }
    }
    drawTable(tableData) {
        let topData;
        this.modalData.data['rightsizeOptions'].forEach((element) => {
            tableData = tableData.filter((item) => {
                if (element.id === item.id) {
                    topData = item;
                    return false;
                } else {
                    return true;
                }
            });
        });
        if (topData) {
            tableData.unshift(topData);
        }
        this.columnDefs = [];
        this.defaultColDef = {};
        this.defaultColDef['resizable'] = true;
        this.defaultColDef['sortable'] = true;
        this.defaultColDef['filter'] = true;
        const row = {
            headerName: 'Select Resource',
            field: 'label',
            checkboxSelection: true
        };

        row['cellRenderer'] = (item) => {
            const isColoredRow = this.modalData.data['rightsizeOptions'].find(
                (rowItem) => {
                    if (rowItem['id'] === item['data']['id']) {
                        return true;
                    } else {
                        return false;
                    }
                }
            );
            if (isColoredRow) {
                return `<div
                            style="color: var(--positiveColor);
                            text-align: left;
                            background: hsla(var(--positiveColorHslValues), 0.5);
                            padding: 4px 8px;
                            width: 100%;
                            font-weight: bold;
                            border: 1px solid var(--positiveColor);">${item.data['label']}</div>`;
            } else {
                return item['data']['label'];
            }
        };

        this.columnDefs.push(row);
        this.rowData = tableData;
        setTimeout((fun) => {
            this.agGrid.api.forEachNode((node) => {
                this.modalData.data['rightsizeOptions'].find((col) => {
                    node.setSelected(node.data.id === col['id']);
                });
            });
        }, 1000);
    }

    nextButtonClicked() {
        this.nextBtnClicked = true;
        if (this.isTable) {
            if (
                !this.agGrid ||
                this.agGrid.api.getSelectedNodes().length === 0
            ) {
                this.notificationsService.showSnackBar(
                    'Select One Resource.',
                    true
                );
                return;
            }
            const selectedRow = this.agGrid.api.getSelectedNodes();

            this.data.inputData['resources'] = selectedRow[0]['data']['id'];
        } else {
            if (this.fields.length >= 2) {
                for (let index = 0; index < this.fields.length; index++) {
                    if (
                        this.fields[index]['isVisible'] &&
                        !this.inputData[this.fields[index]['selector']]
                    ) {
                        return;
                    }
                    if (!index) {
                        this.data.inputData['resources'] = this.fields[index][
                            'isVisible'
                        ]
                            ? this.inputData[this.fields[index]['selector']]
                            : '-1';
                    } else {
                        this.data.inputData['resources'] +=
                            '|' +
                            (this.fields[index]['isVisible']
                                ? this.inputData[this.fields[index]['selector']]
                                : '-1');
                    }
                }
            } else {
                if (!this.inputData[this.fields[0]['selector']]) {
                    return;
                }
                this.data.inputData['resources'] =
                    this.inputData[this.fields[0]['selector']];
            }
        }
        this.multiStepFormService.stepData
            .get(this.modalData.modalId)
            .set(1, this.data);
        //this.data.outputData.emit('go ahead');
        this.multiStepFormService.nextStep(this.modalData.modalId);
    }

    numberFieldChanged(key, value, numberField: HTMLInputElement, minValue?) {
        value = +value;
        if (minValue) {
            if (value < minValue) {
                numberField.value = null;
                this.inputData[key] = null;
            } else {
                this.inputData[key] = +value;
                this.changeDefaultValue(key, value);
            }
        } else {
            this.inputData[key] = +value;
        }
    }

    changeDefaultValue(key, value) {
        for (let index = 0; index < this.fields.length; index++) {
            if (this.fields[index]['selector'] === key) {
                this.fields[index]['defaultValue'] = value;
                return;
            }
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
        const ref = this;
        this.gridApi.forEachNode((node) => {
            if (node && node.data.id === ref.data.inputData['resources']) {
                node.setSelected(true);
            }
        });
    }
}
