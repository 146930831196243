import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { UpdateAction } from 'src/app/shared/enums/UpdateAction';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IUpdateAction } from 'src/app/shared/interfaces/update-action/IUpdateAction';
import { IButtonGeneratorInput } from './../../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-zendesk-create-ticket',
    templateUrl: './zendesk-create-ticket.component.html',
    styleUrls: ['./zendesk-create-ticket.component.sass']
})
export class ZendeskCreateTicketComponent implements OnInit {
    zendeskFormGenInputs: IFormGeneratorInput;
    zendeskFormGroup: FormGroup;
    updateControlInput: IUpdateAction;
    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    zendeskFormId: string;
    zendeskWidgetId: string;
    widgetRef: Widget;
    modalLoader: boolean = false;
    nextField: Map<string, any> = new Map<string, any>();
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    existingFields: string[] = [];
    loader: boolean = false;
    customeField: Map<string, boolean> = new Map<string, boolean>();
    dependentFieldId: Map<string, string[]> = new Map<string, string[]>();
    buttonGenInput: IButtonGeneratorInput;
    constructor(private modalInputData: ModalInjectedData) {
        this.widgetRef = this.modalInputData.data['widgetRef'];
        this.zendeskFormId =
            this.modalInputData.data['configuredColumnData']['itsmFormId'];
        this.zendeskWidgetId =
            this.modalInputData.data['configuredColumnData'][
                'configuration_widgetId'
            ];
    }

    ngOnInit(): void {
        this.getFormFields();
        this.generateButton();
    }
    generateButton() {
        this.buttonGenInput = {
            buttonName: 'Create',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: (buttonRef) => {
                this.createTicket(buttonRef);
            },
            showLoader: true
        };
    }
    getFormFields() {
        this.modalLoader = true;
        const apiConf: IHitApi = {
            url: ApiUrls.ZENDESK_GET_FORM_API,
            input: {},
            function: (response) => {
                this.generateForm(response);
                this.modalLoader = false;
            },
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error
                );
                this.widgetRef.modalService.closeModal(
                    null,
                    this.modalInputData.modalId
                );
                this.modalLoader = false;
            }
        };
        apiConf.intactUrl = apiConf.url;
        apiConf.url = apiConf.url.replace('{form-id}', this.zendeskFormId);
        apiConf.url = apiConf.url.replace('{widget-id}', this.zendeskWidgetId);
        new HitApi(
            apiConf,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    generateForm(formData, dependentFieldName?, dependentFieldValue?) {
        const fields = [];
        this.setNextFieldData(formData);
        this.existingFields = [...this.existingFields, ...formData['isExist']];
        formData.form.forEach((field: IFormField) => {
            const value =
                this.modalInputData.data['tableRowData'][
                    this.modalInputData.data['configuredColumnData'][field.name]
                ];
            let newField: IFormField = {
                label: field.label,
                placeholder: field.label + ' Column',
                name: field.name,
                value: value,
                disabled: value ? true : false,
                fieldType: field.fieldType,
                required: field.required,
                validations: [],
                onChange: (event, fg, formName) => {
                    this.getDynamicForm(event, fg, formName, field.fieldType);
                }
            };
            if (field.required) {
                newField['validations'].push({
                    validator: CustomValidators.required,
                    errorMessage: `${field.label} is required.`
                });
            }
            if (
                field.fieldType === FilterType.DROPDOWN_SINGLE ||
                field.fieldType === FilterType.DROPDOWN_MULTIPLE
            ) {
                newField['listData'] = field.listData;
            }
            if (
                field.fieldType === FilterType.TEXT ||
                field.fieldType === FilterType.NUMBER ||
                field.fieldType === FilterType.DATE ||
                field.fieldType === FilterType.TEXTAREA
            ) {
                newField = Helper.renameKey(
                    newField,
                    'onChange',
                    'onFocusout'
                ) as IFormField;
                if (value) {
                    newField.onFocusout(
                        value,
                        this.zendeskFormGroup,
                        field.name
                    );
                }
                if (field['regexExp']) {
                    newField['validations'].push({
                        validator: CustomValidators.regexValidator(
                            field['regexExp']
                        ),
                        errorMessage: `${field.label} is required.`
                    });
                }
            }
            if (field.fieldType === FilterType.NUMBER && field['limit']) {
                const limitValidator = {
                    validator: CustomValidators.maxValue(9999),
                    errorMessage: `Maximum ${field['limit']} characters is allowed.`
                };
                newField['validations'].push(limitValidator);
            }
            if (dependentFieldName) {
                if (this.checkIfFieldExist(newField['name'])) {
                    this.handleHiddenDepField(
                        newField['name'],
                        dependentFieldValue,
                        null,
                        dependentFieldName
                    );
                } else {
                    newField['hiddenDependency'] = [
                        {
                            controlName: dependentFieldName,
                            validations: [
                                {
                                    validator:
                                        CustomValidators.controlValueMatch(
                                            dependentFieldValue
                                        )
                                }
                            ]
                        }
                    ];
                }
            }
            if (!this.checkIfFieldExist(newField['name'])) {
                fields.push(newField);
            }
        });
        if (!dependentFieldName) {
            this.zendeskFormGenInputs = {
                formName: '',
                state: FormState.CREATE,
                submitButton: null,
                fields: fields
            };
        } else {
            if (fields && fields.length) {
                this.updateForm(fields);
            }
        }
    }
    updateForm(fields) {
        this.updateControlInput = {
            action: UpdateAction.UPDATE_FORM_FIELD,
            fields: fields
        };
        this.updateControl.next(this.updateControlInput);
    }
    setNextFieldData(formData) {
        formData['form'].forEach((field) => {
            if (field && field.nextField) {
                this.nextField.set(field.name, field.nextField);
            }
            if (field['customField'] !== undefined) {
                this.customeField.set(field.name, field['customField']);
            }
        });
    }
    checkIfFieldExist(newFieldName) {
        let isExist = false;
        if (this.zendeskFormGenInputs) {
            this.zendeskFormGenInputs.fields.forEach((field, index) => {
                if (field.name === newFieldName) {
                    isExist = true;
                }
            });
        }

        return isExist;
    }
    getDynamicForm(event, fg?, fieldName?, fieldType?) {
        this.setValue(event, fieldType, fieldName);
        if (this.nextField.has(fieldName) && event) {
            if (this.dependentFieldId.has(fieldName)) {
                this.removeDepFieldIds(fieldName);
            }
            const input = this.generateInput(event, fieldName, fieldType);
            if (input) {
                this.modalLoader = true;
            }
            const apiConf: IHitApi = {
                url: ApiUrls.ZENDESK_GET_FIELD_API,
                input: input,
                function: (response) => {
                    const getKey = this.getKey(event, fieldType);
                    this.setDependentFieldId(fieldName, getKey);
                    this.generateForm(response, fieldName, getKey);
                    this.modalLoader = false;
                },
                requestType: RequestType.POST,
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                },
                errorFunction: (error) => {
                    this.modalLoader = false;
                }
            };
            apiConf.intactUrl = apiConf.url;
            apiConf.url = apiConf.url.replace(
                '{widget-id}',
                this.zendeskWidgetId
            );
            if (input) {
                new HitApi(
                    apiConf,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            }
        } else if (!event) {
            this.removeDepFieldIds(fieldName);
        }
    }
    setDependentFieldId(depFieldName, depFieldVal) {
        const depFieldValueId = this.nextField.get(depFieldName)[depFieldVal];
        if (depFieldValueId) {
            this.dependentFieldId.set(depFieldName, depFieldValueId);
        }
    }
    removeDepFieldIds(depFieldName) {
        const depFieldIds = this.dependentFieldId.get(depFieldName);
        this.removeHiddenDepField(depFieldName, depFieldIds);
        if (depFieldIds) {
            depFieldIds.forEach((id) => {
                if (this.existingFields.includes(id)) {
                    const index = this.existingFields.indexOf(id);
                    this.existingFields.splice(index, 1);
                }
            });
        }
    }
    removeHiddenDepField(depFieldName, depFieldIds) {
        if (!depFieldIds) {
            return;
        }
        depFieldIds.forEach((ele) => {
            this.zendeskFormGenInputs.fields.forEach((field) => {
                if (field.name === ele) {
                    if (
                        field['hiddenDependency'] &&
                        field['hiddenDependency'].length > 1
                    ) {
                        const index = field['hiddenDependency'].findIndex(
                            (hiddenDepObject) =>
                                hiddenDepObject['controlName'] === depFieldName
                        );

                        if (index !== -1) {
                            field['hiddenDependency'].splice(index, 1);
                        }
                    } else {
                        this.removeHiddenField(ele);
                    }
                }
            });
        });
        this.updateControlInput = {
            action: UpdateAction.UPDATE_HIDDEN_FIELD,
            fields: this.zendeskFormGenInputs.fields
        };
        this.updateControl.next(this.updateControlInput);
    }
    setValue(event, fieldType, fieldName) {
        if (event && this.zendeskFormGenInputs) {
            const value = this.getKey(event, fieldType);
            this.zendeskFormGenInputs.fields.forEach((field) => {
                if (field.name === fieldName) {
                    field.value = value;
                }
            });
        } else if (!event && this.zendeskFormGenInputs) {
            this.zendeskFormGenInputs.fields.forEach((field) => {
                if (field.name === fieldName) {
                    field.value = null;
                }
            });
        }
    }
    generateInput(event, fieldName, fieldType) {
        const nextFieldId = [];
        const key = this.getKey(event, fieldType);
        const nextField = this.nextField.get(fieldName)[key];
        if (this.checkIfFieldExist(fieldName)) {
            if (nextField) {
                nextField.forEach((fieldId) => {
                    if (!this.existingFields.includes(fieldId)) {
                        nextFieldId.push(fieldId);
                    } else if (this.existingFields.includes(fieldId)) {
                        this.handleHiddenDepField(fieldName, key, nextField);
                        if (this.modalLoader) {
                            this.modalLoader = false;
                        }
                    }
                });
            }
        }

        if (nextFieldId.length) {
            return { field: nextFieldId, formId: this.zendeskFormId };
        } else {
            this.setDependentFieldId(fieldName, key);
            return null;
        }
    }
    handleHiddenDepField(fieldName, key, nextField?, controlName?) {
        if (nextField) {
            nextField.forEach((ele) => {
                this.zendeskFormGenInputs.fields.forEach((field) => {
                    if (field.name === ele) {
                        field['hiddenDependency'].push({
                            controlName: fieldName,
                            validations: [
                                {
                                    validator:
                                        CustomValidators.controlValueMatch(key)
                                }
                            ]
                        });
                    }
                });
            });
        } else {
            this.zendeskFormGenInputs.fields.forEach((field) => {
                if (field.name === fieldName) {
                    const index = field['hiddenDependency'].findIndex(
                        (control) => control['controlName']
                    );
                    if (index !== -1) {
                        field['hiddenDependency'].push({
                            controlName: controlName,
                            validations: [
                                {
                                    validator:
                                        CustomValidators.controlValueMatch(key)
                                }
                            ]
                        });
                    }
                }
            });
        }
    }
    getKey(event, fieldType) {
        const type = typeof event;

        if (
            type === 'object' &&
            (fieldType === FilterType.DROPDOWN_SINGLE ||
                fieldType === FilterType.TOGGLE)
        ) {
            return event['id'];
        } else if (type === 'object' && fieldType === FilterType.CHECKBOX) {
            return event['checked'];
        } else if (
            type === 'string' &&
            (fieldType === FilterType.TEXT || fieldType === FilterType.TEXTAREA)
        ) {
            return event;
        } else {
            return event.target.value;
        }
    }
    createTicket(buttonRef) {
        if (this.loader || this.modalLoader) {
            return;
        }

        Helper.markAllFieldAsTouched(this.zendeskFormGroup);
        if (this.zendeskFormGroup.invalid) {
            this.zendeskFormGroup.updateValueAndValidity();
            return;
        }
        buttonRef.loader = true;
        this.loader = true;
        const input = this.generateCreateTicketInput();

        const apiConf: IHitApi = {
            url: ApiUrls.ZENDESK_SEND_TICKET_API,
            input: input,
            function: () => {
                this.loader = false;
                buttonRef.loader = false;
                this.widgetRef.notificationsService.showSnackBar(
                    'Ticket Created Successfully'
                );
                this.widgetRef.modalService.closeModal(
                    null,
                    this.modalInputData.modalId
                );
            },
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            errorFunction: (error) => {
                buttonRef.loader = false;
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error
                );
                this.loader = false;
            }
        };
        apiConf.intactUrl = apiConf.url;
        apiConf.url = apiConf.url.replace('{widget-id}', this.zendeskWidgetId);
        new HitApi(
            apiConf,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    generateCreateTicketInput() {
        const inputs = [];
        const fieldsValue = this.zendeskFormGroup.getRawValue();
        this.zendeskFormGenInputs.fields.forEach((field) => {
            const input = {};
            input['name'] = field.name;
            input['value'] = fieldsValue[field.name];
            input['customField'] = this.customeField.has(field.name)
                ? this.customeField.get(field.name)
                : false;
            inputs.push(input);
        });
        return inputs;
    }
    removeHiddenField(hiddenDepFieldName) {
        let controlName = '';
        const field: IFormField = this.zendeskFormGenInputs.fields.find(
            (field) => field.name === hiddenDepFieldName
        );
        const index = this.zendeskFormGenInputs.fields.findIndex(
            (field) => field.name === hiddenDepFieldName
        );
        const hiddenDepCons = field.hiddenDependency;
        hiddenDepCons.forEach((hiddenDepCon) => {
            controlName = hiddenDepCon.controlName;
            hiddenDepCon.validations.forEach((data) => {
                this.zendeskFormGenInputs.fields.forEach((field) => {
                    if (
                        field.name === controlName &&
                        field.value !== data.validator['value']
                    ) {
                        this.deleteField(index);
                    }
                });
            });
        });
    }
    deleteField(index) {
        const depField = this.zendeskFormGenInputs.fields[index];
        if (depField) {
            if (this.dependentFieldId.has(depField['name'])) {
                this.zendeskFormGenInputs.fields.splice(index, 1);
                const subDepField = this.dependentFieldId.get(depField['name']);
                subDepField.forEach((field) => {
                    const indexOfField = this.findIndex(field);
                    if (index !== -1) {
                        this.deleteField(indexOfField);
                    }
                });
            }
            this.zendeskFormGenInputs.fields.splice(index, 1);
        }
    }
    findIndex(fieldName) {
        const index = this.zendeskFormGenInputs.fields.findIndex(
            (field) => field.name === fieldName
        );
        return index;
    }
}
