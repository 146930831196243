<div class="allocation-conventions-container tw-w-full tw-h-full">
    <div
        class="convention-message tw-text-fs-400 tw-mb-2 tw-flex tw-justify-center tw-font-semibold"
    >
        Conventions set for the following insight
    </div>
    <mat-accordion>
        <mat-expansion-panel
            *ngFor="let convention of conventions; first as isFirst"
            [expanded]="isFirst"
        >
            <mat-expansion-panel-header
                collapsedHeight="48px"
                expandedHeight="48px"
            >
                <mat-panel-title class="tw-font-normal">
                    Convention Name:&nbsp;<b>{{ convention.ruleSetName }}</b>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div
                class="table-container tw-flex tw-justify-evenly tw-overflow-x-auto"
            >
                <div>
                    <table class="tw-border-separate">
                        <thead>
                            <th colspan="2" class="tw-border-0">Conventions</th>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Key</th>
                                <th>Value</th>
                            </tr>
                            <tr
                                *ngFor="
                                    let conventionKey of getKeys(
                                        convention.keyValueSet
                                    )
                                "
                            >
                                <th>{{ conventionKey }}</th>
                                <td>
                                    <ul>
                                        <li
                                            *ngFor="
                                                let conventionValue of convention
                                                    .keyValueSet[conventionKey]
                                            "
                                            class="text-center tw-list-none"
                                        >
                                            {{ conventionValue }}
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div></div>
                <div>
                    <table class="tw-border-separate">
                        <thead>
                            <th colspan="2" class="tw-border-0">
                                Convention Filters
                            </th>
                        </thead>
                        <tbody>
                            <tr>
                                <th>
                                    {{
                                        widgetRef.widgetData.widgetInfo
                                            .cloudIcon &&
                                        widgetRef.widgetData.widgetInfo
                                            .cloudIcon === cloudLabel.GCP
                                            ? "Project Id"
                                            : "Account Id"
                                    }}
                                </th>
                                <th>Region</th>
                            </tr>
                            <tr>
                                <td>
                                    <ul
                                        *ngIf="
                                            convention.accountIds &&
                                                convention.accountIds.length >
                                                    0;
                                            else displayNoData
                                        "
                                        class="text-center tw-list-none"
                                    >
                                        <li
                                            *ngFor="
                                                let accountId of convention.accountIds
                                            "
                                            class="text-center tw-list-none"
                                        >
                                            {{ accountId }}
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul
                                        *ngIf="
                                            convention.regions &&
                                                convention.regions.length > 0;
                                            else displayNoData
                                        "
                                    >
                                        <li
                                            *ngFor="
                                                let region of convention.regions
                                            "
                                            class="text-center"
                                        >
                                            {{ region }}
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<ng-template #displayNoData>
    <li style="text-align: center" class="tw-list-none">No Filters Applied</li>
</ng-template>
