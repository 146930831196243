import { TitleCasePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { GraphType } from 'src/app/shared/enums/GraphType';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IBargraphApiResponse } from 'src/app/shared/interfaces/api/portlets/IBargraphApiResponse';
import { IGraphData } from 'src/app/shared/interfaces/graph/IGraphData';

@Component({
    selector: 'app-bargraph',
    templateUrl: './bargraph.component.html',
    styleUrls: ['./bargraph.component.sass'],
    providers: [TitleCasePipe]
})
export class BargraphComponent implements OnInit, AfterViewInit {
    widgetRef: Widget;
    ViewType = ViewType;
    warningSeverityColor;
    okSeverityColor;
    criticalSeverityColor;
    okSeverityLightColor;
    graphChartData: IGraphData;

    constructor(
        widgetData: WidgetInjectedData,
        private titlePipe: TitleCasePipe
    ) {
        this.widgetRef = widgetData.widgetRef;
        this.graphColorInitialization();
    }

    ngOnInit(): void {
        this.setUpBasics();
    }
    graphColorInitialization() {
        this.okSeverityColor = Helper.convertToHexIfHsl(
            Helper.getCssVarValue('okSeverityColor')
        );
        this.warningSeverityColor = Helper.convertToHexIfHsl(
            Helper.getCssVarValue('warningSeverityColor')
        );
        this.criticalSeverityColor = Helper.convertToHexIfHsl(
            Helper.getCssVarValue('criticalSeverityColor')
        );
        this.okSeverityLightColor = Helper.convertToHexIfHsl(
            Helper.getCssVarValue('okSeverityLightColor')
        );
    }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(true);
        const visibleSections = new Set<ViewType>();
        visibleSections.add(ViewType.GRAPH);
        this.widgetRef.visibleSections.next(visibleSections);
    }

    bindData(data: IBargraphApiResponse) {
        if (!data) {
            return;
        }

        // for view modal
        this.widgetRef.apiResponse = data;
        if (data.dataMap && Object.keys(data.dataMap).length === 0) {
            this.widgetRef.extraMessage.next(Messages.NO_DATA_AVAILABLE);
            this.widgetRef.visibleSections.next(new Set());
            this.widgetRef.endLoader();
            return;
        }

        const requiredArrayOfObjects = [];
        Object.entries(data.dataMap).forEach(([module, value]) => {
            if (
                value['okCount'] === 0 &&
                value['warningCount'] === 0 &&
                value['criticalCount'] === 0
            ) {
            } else {
                const requiredObj = {
                    category: module,
                    ok: value['okCount'],
                    warning: value['warningCount'],
                    critical: value['criticalCount']
                };
                requiredArrayOfObjects.push(requiredObj);
            }
        });

        this.widgetRef.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.loadBargraph(requiredArrayOfObjects);
            }, 0);
        });

        this.widgetRef.endLoader();
    }
    loadBargraph(requiredArrayOfObjects) {
        const colors = {
            ok: this.okSeverityColor,
            warning: this.warningSeverityColor,
            critical: this.criticalSeverityColor
        };
        const data: IGraphData = {
            graphType: GraphType.STACK_BAR,
            liteState: this.widgetRef.lightState,
            toggleLegend: false,
            disableScrollBarX: true,
            disableScrollBarY: true,
            categoryAxis: 'y',
            labelText: {
                categoryY: 'category',
                yAxesTitle: null,
                xAxesTitle: null,
                valueAxesMin: 0,
                valueAxesMax: 100
            },
            chartData: requiredArrayOfObjects,
            seriesList: ['ok', 'warning', 'critical'],
            disableLegend: true,
            customizeSeries: (seriesValue, series, modules) => {
                series.columns.template.fill = modules.am4core.color(
                    colors[seriesValue]
                );
            },
            tooltiptext: (chart, legendKeys) => {
                return `${this.titlePipe.transform(legendKeys)} : {valueX}`;
            }
        };
        this.graphChartData = data;
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
