import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GridOptions, RowEvent, ValueGetterParams } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { ContentType } from 'src/app/shared/enums/ContentType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import {
    HtmlTargetAttributeValue,
    IButtonGeneratorInput
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { IHitApi } from './../../../../interfaces/hit-api/IHitApi';
import { IColumnData } from './../../../../interfaces/table-generator/IColumnData';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
@Component({
    selector: 'app-bulk-upload-azure-ea',
    templateUrl: './bulk-upload-azure-ea.component.html',
    styleUrls: ['./bulk-upload-azure-ea.component.sass']
})
export class BulkUploadAzureEaComponent implements OnInit {
    tableInput: ITableGeneratorInput;
    widgetRef: Widget;
    tableData: [] = [];
    agGrid: GridOptions;
    excelForm: FormGroup;
    excelFormInput: IFormGeneratorInput = null;
    excelLoader: boolean;
    resetClicked: boolean = false;
    buttonsInput: IButtonGeneratorInput[];
    linkAccountFormGroup: FormGroup;
    excelData: [] = [];
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    originalExcelData: [] = [];
    Tab = Tab;
    selectedIndexForTab: number = 0;
    activeTabIndex: number = 0;
    bulkUpdateSelected: boolean;
    accountType: string;
    bulkUpdateRowData: [] = [];
    changeTabViaBulkUpload: boolean = false;

    constructor(
        private modalInputData: ModalInjectedData,
        private notificationService: NotificationsService
    ) {
        this.widgetRef = modalInputData['data']['widgetRef'];
        this.bulkUpdateSelected = modalInputData['data']['bulkUpdate'];
        this.accountType = modalInputData['data']['accountType'];
        this.bulkUpdateRowData = modalInputData['data']['rowsData'];
    }

    ngOnInit(): void {
        this.setupBasics();
        this.widgetRef.modalService.resetModal.subscribe((reset) => {
            this.resetForm();
        });

        this.generateButtons();
    }
    prepareColumnsBasedOnTab(): IColumnData[] {
        if (this.selectedIndexForTab) {
            return [
                {
                    columnName: 'Enrollment Number',
                    pinned: 'left',
                    columnKey: 'enrollmentNumber',
                    filter: false,
                    maxWidth: 163
                },
                {
                    columnName: 'Application (Client) ID',
                    columnKey: 'appId',
                    filter: false
                },
                {
                    columnKey: 'clientSecretKey',
                    columnName: 'Secret Key',
                    filter: false,
                    columnValueGetter: (rowData: ValueGetterParams) => {
                        return '-';
                    }
                },
                {
                    columnKey: 'accountName',
                    columnName: 'Account Name',
                    filter: false
                },
                {
                    columnName: 'Domain ID',
                    columnKey: 'domainName',
                    filter: false
                },
                {
                    columnName: 'Upload Status',
                    columnKey: 'uploadStatus',
                    pinned: 'right',
                    filter: false,
                    maxWidth: 141,
                    cellRenderer: (rowData: RowEvent) => {
                        if (rowData.data.uploadStatus === true) {
                            return `<span class="green-status">True</span>`;
                        } else {
                            return `<span class="red-status">False</span>`;
                        }
                    }
                }
            ];
        } else {
            if (this.bulkUpdateSelected) {
                return [
                    {
                        columnName: 'Enrollment/Subscription ID',
                        columnKey:
                            this.accountType === 'INDEPENDENT'
                                ? 'enrollmentNumber'
                                : 'subscriptionId',
                        filter: false
                    },
                    {
                        columnKey: 'clientSecretKey',
                        columnName: 'Secret Key',
                        filter: false,
                        columnValueGetter: (rowData: ValueGetterParams) => {
                            return '-';
                        },
                    },
                    {
                        columnKey: 'domainName',
                        columnName: 'Domain ID'
                    },
                    {
                        columnKey: 'accountName',
                        columnName: 'Account Name',
                        filter: false
                    },
                    {
                        columnKey: 'type',
                        columnName: 'Account Type',
                        filter: false,
                        columnValueGetter: (rowData: ValueGetterParams) => {
                            if (rowData.data['type'] === Tab.PARTNER_ACCOUNT) {
                                return 'Independent';
                            } else if (
                                rowData.data['type'] ===
                                Tab.SUBSCRIPTION_ACCOUNT
                            ) {
                                return 'Consolidated';
                            } else {
                                return 'Linked Account';
                            }
                        }
                    },
                    {
                        columnName: 'Upload Status',
                        columnKey: 'uploadStatus',
                        pinned: 'right',
                        filter: false,
                        maxWidth: 141,
                        cellRenderer: (rowData: RowEvent) => {
                            if (rowData.data.uploadStatus === true) {
                                return `<span class="green-status">True</span>`;
                            } else {
                                return `<span class="red-status">False</span>`;
                            }
                        }
                    }
                ];
            } else {
                return [
                    {
                        columnKey: 'enrollmentNumber',
                        columnName: 'Enrollment Number',
                        filter: false,
                        maxWidth: 163
                    },
                    {
                        columnKey: 'accountName',
                        columnName: 'Account Name',
                        filter: false
                    },
                    {
                        columnKey: 'type',
                        columnName: 'Account Type',
                        filter: false,
                        columnValueGetter: (rowData: ValueGetterParams) => {
                            if (rowData.data['type'] === Tab.PARTNER_ACCOUNT) {
                                return 'Independent';
                            } else if (
                                rowData.data['type'] ===
                                Tab.SUBSCRIPTION_ACCOUNT
                            ) {
                                return 'Consolidated';
                            } else {
                                return 'Linked Account';
                            }
                        }
                    },
                    {
                        columnName: 'Upload Status',
                        columnKey: 'uploadStatus',
                        pinned: 'right',
                        filter: false,
                        maxWidth: 141,
                        cellRenderer: (rowData: RowEvent) => {
                            if (rowData.data.uploadStatus === true) {
                                return `<span class="green-status">True</span>`;
                            } else {
                                return `<span class="red-status">False</span>`;
                            }
                        }
                    }
                ];
            }
        }
    }
    generateButtons() {
        this.buttonsInput = [
            {
                buttonName: 'Download',
                buttonType: ButtonType.LINK,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                link: !this.selectedIndexForTab
                    ? 'assets/onboarding-excel/azure-ea-old-way-onBordingtemplate.xlsx'
                    : 'assets/onboarding-excel/azure-ea-new-way-Onbording.xlsx',
                function: (buttonRef: IButtonGeneratorInput) => {
                    if (this.bulkUpdateSelected) {
                        this.getExcelTemplate(buttonRef);
                    }
                },
                linkTargetValue: HtmlTargetAttributeValue.SELF
            },
            {
                buttonName: 'Submit',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.save(buttonRef);
                }
            }
        ];
        if (this.bulkUpdateSelected) {
            delete this.buttonsInput[0].link;
        }
    }
    setupBasics() {
        this.tableInput = {
            selection: 'multiple',
            tableAutoHeight: true,
            extraClass: 'upload-container',
            listExtraction: {
                type: 'DIRECT'
            },
            widgetIconData: {
                class: '',
                type: IconType.FONTAWSOME
            },
            columns: this.prepareColumnsBasedOnTab()
        };
        this.excelFormInput = {
            formName: 'Excel Upload',
            submitButton: null,
            fields: [
                {
                    label: 'Excel File Upload',
                    fieldType: FilterType.FILE,
                    required: true,
                    accept: '.xls, .xlsx',
                    suffixButton: {
                        text: 'Browse',
                        show: true
                    },
                    appearance: 'legacy',
                    placeholder: 'Select a file to upload...',
                    name: 'excelFile'
                }
            ],
            state: FormState.CREATE
        };
    }
    excelFile($event) {
        if ($event['excelFile']) {
            this.excelLoader = true;
            this.resetClicked = false;
            this.tableInput.columns = this.prepareColumnsBasedOnTab();

            const apiArgs: IHitApi = Helper.generateHitApiConfig(
                this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                    'generatePresignedUrl'
                ]
            );
            apiArgs.function = (response) => {
                const saveResponse = response;
                const preSignedUrl = response['preSignedURL'];
                if (preSignedUrl) {
                    const file = this.excelForm.value.excelFile['_files'][0];
                    const args: IHitApi = {
                        url: preSignedUrl,
                        requestType: RequestType.PUT,
                        config: {
                            ignoreBaseUrl: true,
                            authorization: null,
                            defaultHeaders: {
                                'Content-Type': ContentType.EXCEL
                            }
                        },
                        function: (response) => {
                            const hitApi: IHitApi = Helper.generateHitApiConfig(
                                this.widgetRef.widgetData.widgetInfo[
                                    'additionalApisForWidget'
                                ]['getExcelData']
                            );

                            hitApi.function = (response) => {
                                if (!this.bulkUpdateSelected) {
                                    if (this.selectedIndexForTab === 1) {
                                        if (
                                            response[0]?.azureEaAccountsInfo
                                                ?.token ||
                                            response[0]?.azureEaAccountsInfo
                                                ?.appId === null
                                        ) {
                                            Helper.showErrorMessage(
                                                this.notificationService,
                                                'Error uploading excel template.'
                                            );
                                            this.excelLoader = false;
                                            this.resetForm();
                                            return;
                                        }
                                    } else if (this.selectedIndexForTab === 0) {
                                        if (
                                            response[0]?.azureEaAccountsInfo
                                                ?.appId !== null ||
                                            response[0]?.azureEaAccountsInfo
                                                ?.token.length === 0
                                        ) {
                                            Helper.showErrorMessage(
                                                this.notificationService,
                                                'Error uploading excel template.'
                                            );
                                            this.excelLoader = false;
                                            this.resetForm();
                                            return;
                                        }
                                    }
                                }
                                this.excelLoader = false;
                                this.excelData = response;
                                if (response && response.length) {
                                    const prevIndex = this.selectedIndexForTab;
                                }
                                this.tableInput.columns =
                                    this.prepareColumnsBasedOnTab();
                                this.getExcelData();
                            };
                            hitApi.errorFunction = (error) => {
                                this.excelLoader = false;
                                this.tableData = [];
                                Helper.showErrorMessage(
                                    this.widgetRef.notificationsService,
                                    error,
                                    'Error uploading excel template.'
                                );
                                this.widgetRef.changeDetectorRef.detectChanges();
                            };
                            hitApi.input = {};
                            new HitApi(
                                hitApi,
                                this.widgetRef.httpService,
                                this.widgetRef.ngZone
                            ).hitApi();
                        },
                        errorFunction: (error) => {
                            this.excelLoader = false;
                            this.tableData = [];
                            Helper.showErrorMessage(
                                this.widgetRef.notificationsService,
                                error,
                                'Error uploading excel template.'
                            );
                            this.widgetRef.changeDetectorRef.detectChanges();
                        },
                        input: file,
                        uniqueIdentity: Symbol()
                    };
                    new HitApi(
                        args,
                        this.widgetRef.httpService,
                        this.widgetRef.ngZone
                    ).hitApi();
                }
            };
            apiArgs.input = {};
            apiArgs.errorFunction = (error) => {
                this.tableData = [];
                this.excelLoader = false;
                this.widgetRef.changeDetectorRef.detectChanges();
            };
            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }
    getExcelTemplate(buttonRef: IButtonGeneratorInput) {
        buttonRef.loader = true;

        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                'getExcelTemplate'
            ]
        );
        const payLoadData = {};

        payLoadData['azureEaAccounts'] = this.bulkUpdateRowData;
        apiArgs.input = payLoadData;
        apiArgs.config.downloadable = true;
        apiArgs.config.defaultHeaders = {
            Accept: ContentType.EXCEL
        };
        apiArgs.function = (response) => {
            if (apiArgs.config.downloadable) {
                Helper.saveAsBlob(
                    response,
                    apiArgs.config.defaultHeaders.Accept,
                    'Template'
                );
            }

            buttonRef.loader = false;
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error downloading excel template.'
            );
            buttonRef.loader = false;
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    onGridReady($event) {
        this.agGrid = $event;

        this.agGrid.api.selectAll();
    }
    resetForm() {
        const form = this.excelFormInput;
        this.excelFormInput = null;
        this.widgetRef.ngZone.run(() => {
            setTimeout(() => {
                this.excelFormInput = form;
            }, 0);
            if (this.agGrid) {
                this.agGrid.api.setRowData([]);
            }
            this.excelLoader = false;
            this.resetClicked = true;
        });
    }
    getExcelData() {
        this.originalExcelData = Helper.cloneDeep(this.excelData);
        this.excelData.forEach((data, index) => {
            for (const key of Object.keys(
                this.excelData[index][EaAttributeKeys.EA_ACCOUNT_ATTRIBUTE]
            )) {
                this.excelData[index][key] =
                    this.excelData[index][EaAttributeKeys.EA_ACCOUNT_ATTRIBUTE][
                        key
                    ];
            }
        });

        this.tableData = Helper.cloneDeep(this.excelData);
        this.widgetRef.changeDetectorRef.detectChanges();
    }
    save(buttonRef: IButtonGeneratorInput) {
        Helper.markAllFieldAsTouched(this.excelForm);
        if (this.excelForm.invalid) {
            return;
        }
        if (this.agGrid && !this.agGrid.api.getSelectedNodes().length) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Select at least One entry to perform the Action'
            );
            return;
        }
        buttonRef.loader = true;
        const selectedApi = this.bulkUpdateSelected
            ? this.widgetRef.widgetData.widgetInfo[`${'bulkUpdate'}`]
            : this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                  'uploadBatchAccount'
              ];

        const apiArgs: IHitApi = Helper.generateHitApiConfig(selectedApi);

        apiArgs.input = this.createPayload();
        apiArgs.function = (response) => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                `Account  ${
                    this.bulkUpdateSelected ? 'edited' : 'added'
                } successfully`
            );
            this.widgetRef.refreshWidget();
            this.widgetRef.modalService.closeModal(
                null,
                this.modalInputData.modalId
            );
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                `Error While ${
                    this.bulkUpdateSelected ? 'edited' : 'adding'
                } account`
            );
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    createPayload() {
        const payloadArray = [];
        if (this.agGrid) {
            this.agGrid.api.getSelectedNodes().forEach((rowData, index) => {
                const finalPayloadData = {
                    accountName: rowData.data[EaAttributeKeys.ACCOUNT_NAME],
                    accountNumber: rowData.data['accountNumber'],
                    azureEaAccountsInfo: {}
                };
                if (rowData.data['accountType'] === Tab.SUBSCRIPTION_ACCOUNT) {
                    (finalPayloadData[EaAttributeKeys.SPOC] =
                        rowData.data[EaAttributeKeys.SPOC]),
                        (finalPayloadData['type'] = 'CONSOLIDATED');
                } else {
                    finalPayloadData['type'] = 'INDEPENDENT';
                }
                for (const val in rowData.data[
                    EaAttributeKeys.EA_ACCOUNT_ATTRIBUTE
                ]) {
                    if (val !== EaAttributeKeys.ACCOUNT_NAME) {
                        if (val !== EaAttributeKeys.ACCOUNT_SPOC) {
                            finalPayloadData[
                                EaAttributeKeys.EA_ACCOUNT_ATTRIBUTE
                            ][val] = rowData.data[val];
                        }
                    }
                }

                payloadArray.push(finalPayloadData);
            });
        }
        return payloadArray;
    }

    changeTab($event) {
        if (this.activeTabIndex === $event.index) {
            return;
        }
        const confirmationModal: IConfirmationModal = {
            modalName: 'Message',

            modalIcon: {
                type: IconType.FONTAWSOME,
                class: ''
            },
            contextIcon: {
                type: IconType.FONTAWSOME,
                class: ''
            },
            buttonText: 'Continue',
            buttonColorType: ButtonColorType.PRIMARY,
            modalHeightVh: 25,
            fontSize: 1,
            extraClass: 'plan-container',
            hideCancelButton: true,
            disableClose: !this.changeTabViaBulkUpload,
            hideButtons: this.changeTabViaBulkUpload,
            function: (modalId: Symbol) => {
                this.activeTabIndex = $event.index;
                this.selectedIndexForTab = this.activeTabIndex;
                this.generateButtons();
                this.resetForm();
                this.changeTabViaBulkUpload = false;
                this.widgetRef.modalService.closeModal(null, modalId);
            },
            closeCallBack: () => {
                this.selectedIndexForTab = this.activeTabIndex;
                this.generateButtons();
                this.resetForm();
                this.changeTabViaBulkUpload = false;
            },
            confirmationMessage: this.changeTabViaBulkUpload
                ? 'Invalid account type found. Kindly change the tab and re-upload file.'
                : 'Switching the tabs will discard all the entered information.'
        };
        this.widgetRef.modalService.openConfirmationModal(confirmationModal);
    }

    callButtons(index): IButtonGeneratorInput {
        this.generateButtons();
        return this.buttonsInput[index];
    }
}
enum Tab {
    PARTNER_ACCOUNT = 'INDEPENDENT',
    SUBSCRIPTION_ACCOUNT = 'CONSOLIDATED'
}
enum EaAttributeKeys {
    EA_ACCOUNT_ATTRIBUTE = 'azureEaAccountsInfo',
    ACCOUNT_NAME = 'accountName',
    ACCOUNT_NUMBER = 'partnerId',
    SPOC = 'spoc',
    ACCOUNT_SPOC = 'accountSpoc',
    STATUS = 'status',
    ACCOUNT_STATUS = 'accountStatus'
}
