import { Component, OnInit } from '@angular/core';
import { GridOptions, RowEvent, ValueGetterParams } from 'ag-grid-community';
import * as $ from 'jquery';
import { BehaviorSubject } from 'rxjs';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ActionState } from 'src/app/shared/enums/ActionState';
import { ActionVisibility } from 'src/app/shared/enums/ActionVisibility';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { CloudLabel } from 'src/app/shared/enums/CloudLabel';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalAction } from 'src/app/shared/enums/ModalAction';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { AddResourceTagsV2Component } from '../../../modal-templates/allocation-checks-modal-v2/add-resource-tags-v2/add-resource-tags-v2.component';
import { ConventionsModalV2Component } from '../../../modal-templates/allocation-checks-modal-v2/conventions-modal-v2/conventions-modal-v2.component';
import { DeleteResourceTagsV2Component } from '../../../modal-templates/allocation-checks-modal-v2/delete-resource-tags-v2/delete-resource-tags-v2.component';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IColumnData } from './../../../../interfaces/table-generator/IColumnData';
import { CreateTagConfigurationFormStepOne } from './../../../modal-templates/create-tag-configuration-Form/create-tag-ConfigurationForm-step-one/create-tag-ConfigurationForm-step-one';
import { CreateTagConfigurationFormStepTwo } from './../../../modal-templates/create-tag-configuration-Form/create-tag-configurationForm-step-two/create-tag-ConfigurationForm-step-two';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-allocation-checks-v2',
    templateUrl: './allocation-checks-v2.component.html',
    styleUrls: ['./allocation-checks-v2.component.sass']
})
export class AllocationChecksV2Component implements OnInit {
    tableInput: ITableGeneratorInput;
    widgetRef: Widget;
    capitalLettersRegex = /[A-Z]/;
    specialCharacterRegex = /^[a-z0-9][a-z0-9_-]*$/;
    agGrid: GridOptions = null;
    cellValueChanged = null;
    selectionChanged = null;
    resourceTags = [];
    conventionButton: IButtonGeneratorInput;
    selectAll = false;
    showConvention = false;
    showButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    allocationChecksApiResponse = null;
    conventions: any[];
    resourceTagColumns: IColumnData[] = [];
    actionColumnInserted: boolean = false;
    lightState: boolean;
    severityButtons: IButtonGeneratorInput[];

    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService
    ) {
        this.widgetRef = widgetData.widgetRef;
        this.lightState = this.widgetRef.lightState;
        this.setUpBasics();
    }

    ngOnInit(): void {
        this.applyTagOperation();
    }

    applyTagOperation() {
        const actions: IAction[] = [];
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: 'No Resource',
            function: () => {
                this.addResourceTag();
            },
            icon: {
                type: IconType.SVG,
                class: 'add_tags_filled',
                text: 'Add Tags',
                extraClass: 'inline-fix-box-1'
            }
        });

        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: 'No Resource',
            function: () => {
                this.addResourceTag(true);
            },
            icon: {
                type: IconType.SVG,
                class: 'update_tags_filled',
                text: 'Update Tags',
                extraClass: 'inline-fix-box-1'
            }
        });
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: 'No Resource',
            function: () => {
                this.deleteResourceTags();
            },
            icon: {
                type: IconType.SVG,
                class: 'trash_icon_filled',
                text: 'Delete Tags',
                extraClass: 'inline-fix-box-1 svg-black-fill'
            }
        });

        const setConvention: IAction[] = [];
        setConvention.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: 'No Resource',
            function: () => {
                this.createForm();
            },
            icon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-copyright',
                text: 'Set Convention'
            }
        });

        this.widgetRef.operationalActions.value
            .set('Set Convention', setConvention)
            .set('Tag Operations', actions);
        this.widgetRef.showNotificationBadge.next(false);
        this.widgetRef.operationalActions.next(
            this.widgetRef.operationalActions.value
        );
    }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(true);

        this.tableInput = {
            afterPartialResponse: (response) => {
                this.setUpTable(response);
            },
            afterResponse: (response) => {
                this.refreshWidgetCallback();
                this.setUpTable(response);
            },
            selection: 'multiple',
            buttons: null,
            tableAutoHeight: false,
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'dataList'
            },
            rowSelection: this.lightState ? 'single' : null,
            columns: [],
            refreshWidgetCallback: this.refreshWidgetCallback.bind(this),
            refreshColumnAfterResponse: true,
            onStreamClosed: (rowData) => {
                this.tableInput.columns.push(...this.resourceTagColumns);
                setTimeout(() => {
                    if (this.agGrid) {
                        this.agGrid.api.setRowData(rowData);
                        this.agGrid.api.refreshCells();
                    }
                }, 0);

                const maxSeverity = this.maxSeverityCount(
                    this.allocationChecksApiResponse.dataMap['severityCount']
                );

                if (maxSeverity) {
                    this.widgetRef.headerIcon.next({
                        class: `assets/${maxSeverity}_check.png`,
                        type: IconType.IMAGE
                    });
                }
            },
            rowsToShow: this.widgetRef.liteViewLimit,
            handleAttentionRequired: true,
            showViewIcon: true
        };

        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);
        this.setUpRowClassRules();
        this.conventionButton = {
            buttonName: '',
            buttonType: ButtonType.ICON,
            buttonColorType: ButtonColorType.PRIMARY,
            function: () => {
                this.showConventions();
            },
            buttonIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-copyright'
            },
            hoverText: 'Show conventons',
            customClass: 'conventions'
        };
    }

    maxSeverityCount(severityCount) {
        if (severityCount['Critical']) {
            return 'red';
        }
        if (severityCount['Warning']) {
            return 'yellow';
        } else return 'green';
    }

    refreshWidgetCallback() {
        this.allocationChecksApiResponse = null;
        this.resourceTags = [];
        this.widgetRef.showNotificationBadge.next(false);
        this.conventions = [];
        this.resourceTagColumns = [];
        this.tableInput.columns = [];
        this.widgetRef.headerIcon.next(null);
    }

    setUpTable(response) {
        if (!this.allocationChecksApiResponse) {
            this.allocationChecksApiResponse = response;
            this.widgetRef.apiResponse = this.allocationChecksApiResponse;
            this.addTableColumns(response);
            this.addResourceTags(response);
            this.setConvention(response);
            this.showButton.next(true);
        } else {
            if (response && response['dataMap']) {
                if (response['dataMap']['severityCount']) {
                    Object.keys(response.dataMap['severityCount']).map(
                        (each) => {
                            if (
                                this.allocationChecksApiResponse.dataMap &&
                                this.allocationChecksApiResponse.dataMap[
                                    'severityCount'
                                ] &&
                                this.allocationChecksApiResponse.dataMap[
                                    'severityCount'
                                ][each]
                            ) {
                                this.allocationChecksApiResponse.dataMap[
                                    'severityCount'
                                ][each] =
                                    Number(
                                        this.allocationChecksApiResponse
                                            .dataMap['severityCount'][each]
                                    ) +
                                    Number(
                                        response.dataMap['severityCount'][each]
                                    );
                            } else {
                                if (!this.allocationChecksApiResponse.dataMap) {
                                    this.allocationChecksApiResponse[
                                        'dataMap'
                                    ] = {};

                                    this.allocationChecksApiResponse['dataMap'][
                                        'severityCount'
                                    ] = {};
                                    this.allocationChecksApiResponse['dataMap'][
                                        'severityCount'
                                    ][each] = 0;
                                }

                                if (
                                    !this.allocationChecksApiResponse.dataMap[
                                        'severityCount'
                                    ]
                                ) {
                                    this.allocationChecksApiResponse['dataMap'][
                                        'severityCount'
                                    ] = {};
                                }

                                if (
                                    !this.allocationChecksApiResponse[
                                        'dataMap'
                                    ]['severityCount'][each]
                                ) {
                                    this.allocationChecksApiResponse['dataMap'][
                                        'severityCount'
                                    ][each] = 0;
                                }

                                this.allocationChecksApiResponse.dataMap[
                                    'severityCount'
                                ][each] = Number(
                                    response.dataMap['severityCount'][each]
                                );
                            }
                        }
                    );
                    this.setConvention(response);
                }

                if (response['dataMap']['tableKeys']) {
                    if (!this.allocationChecksApiResponse.dataMap) {
                        this.allocationChecksApiResponse['dataMap'] = {};
                    }

                    this.allocationChecksApiResponse.dataMap['tableKeys'] =
                        response['dataMap']['tableKeys'];
                }
            }

            if (this.tableInput.columns.length === 0) {
                this.addTableColumns(response);
            }

            this.allocationChecksApiResponse['dataList'].push(
                ...response.dataList
            );

            this.widgetRef.apiResponse = this.allocationChecksApiResponse;
            this.addResourceTags(response);
        }
        this.initButtonGenInput();
    }

    initButtonGenInput() {
        const buttonData = {};
        let index = 0;

        const criticalButton: IButtonGeneratorInput = {
            buttonName: `Critical : ${this.allocationChecksApiResponse['dataMap']['severityCount']['Critical']}`,
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.WARN,
            hoverText: 'Critical',
            hoverEffect: 'shadow',
            function: (buttoRef: IButtonGeneratorInput) => {
                if (this.lightState) {
                    return;
                }
                if (
                    Helper.changeButtonType(
                        buttonData,
                        this.severityButtons,
                        'Critical',
                        this.severityFilter.bind(this)
                    )
                ) {
                    this.severityFilter('Critical');
                }
            }
        };

        const warningButton: IButtonGeneratorInput = {
            buttonName: `Warning : ${this.allocationChecksApiResponse['dataMap']['severityCount']['Warning']}`,
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.PRIMARY,
            hoverText: 'Warning',
            hoverEffect: 'shadow',
            function: (buttoRef: IButtonGeneratorInput) => {
                if (this.lightState) {
                    return;
                }
                if (
                    Helper.changeButtonType(
                        buttonData,
                        this.severityButtons,
                        'Warning',
                        this.severityFilter.bind(this)
                    )
                ) {
                    this.severityFilter('Warning');
                }
            }
        };

        const okButton: IButtonGeneratorInput = {
            buttonName: `OK : ${this.allocationChecksApiResponse['dataMap']['severityCount']['Ok']}`,
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.SUCCESS,
            hoverText: 'OK',
            hoverEffect: 'shadow',
            function: (buttoRef: IButtonGeneratorInput) => {
                if (this.lightState) {
                    return;
                }
                if (
                    Helper.changeButtonType(
                        buttonData,
                        this.severityButtons,
                        'OK',
                        this.severityFilter.bind(this)
                    )
                ) {
                    this.severityFilter('OK');
                }
            }
        };

        this.severityButtons = [];

        if (
            this.allocationChecksApiResponse['dataMap']['severityCount'][
                'Critical'
            ]
        ) {
            this.severityButtons.push(criticalButton);
            buttonData['Critical'] = index++;
        }
        if (
            this.allocationChecksApiResponse['dataMap']['severityCount'][
                'Warning'
            ]
        ) {
            this.severityButtons.push(warningButton);
            buttonData['Warning'] = index++;
        }
        if (
            this.allocationChecksApiResponse['dataMap']['severityCount']['Ok']
        ) {
            this.severityButtons.push(okButton);
            buttonData['OK'] = index++;
        }
    }

    setUpRowClassRules() {
        this.widgetRef.tableRowClassRules = {
            agRowMod: (params) => {
                if (
                    this.agGrid &&
                    this.agGrid?.api?.getSelectedNodes()?.length >=
                        GlobalConfiguration.RESOURCE_SELECTION_LIMIT_V2
                ) {
                    return params.node.selected === false;
                }
                return false;
            }
        };
    }

    onSelectionChanged() {
        const selectionLimit = this.lightState
            ? this.widgetRef.liteViewCheckSelectionLimit
            : GlobalConfiguration.RESOURCE_SELECTION_LIMIT_V2;

        if (this.agGrid?.api?.getSelectedNodes()?.length > selectionLimit) {
            // Select All case
            this.selectAll = !this.selectAll;
            if (this.selectAll) {
                this.agGrid.api.deselectAll();
                this.agGrid.api.forEachNode((node) =>
                    node.rowIndex >= selectionLimit ? 0 : node.setSelected(true)
                );
            } else {
                this.agGrid.api.deselectAll();
            }
            this.agGrid.api.redrawRows();
        } else if (
            this.agGrid?.api?.getSelectedNodes()?.length === selectionLimit
        ) {
            this.widgetRef.notificationsService.showSnackBar(
                `Maximum of ${selectionLimit} row can be selected`
            );
            this.agGrid.api.redrawRows();
        } else {
            this.selectAll = false;
        }
        this.agGrid?.api?.getSelectedNodes()?.length
            ? this.widgetRef.showNotificationBadge.next(true)
            : this.widgetRef.showNotificationBadge.next(false);
    }

    onCellValueChanged(data) {
        if (data.newValue.length === 0) {
            this.widgetRef.notificationsService.showSnackBar(
                `Value field can't be empty.`,
                true
            );
        }

        if (data.newValue.length > 0) {
            const headerName = data.colDef.headerName;
            // Traversing Applied Tags
            for (
                let i = 0, appliedTags = data.data.appliedTags;
                i < appliedTags.length;
                ++i
            ) {
                if (appliedTags[i].split('|')[0].trim() === headerName) {
                    appliedTags[i] = headerName + ' | ' + data.newValue;
                }
            }
            // Traversing Extra Tags
            for (
                let i = 0, extraTags = data.data.extraTags;
                i < extraTags.length;
                ++i
            ) {
                if (extraTags[i].split('|')[0].trim() === headerName) {
                    extraTags[i] = headerName + ' | ' + data.newValue;
                }
            }
            // Traversing Missing Tags
            for (
                let i = 0, missingTags = data.data.missingTags;
                i < missingTags.length;
                ++i
            ) {
                if (missingTags[i].split('|')[0].trim() === headerName) {
                    missingTags[i] = headerName + ' | ' + data.newValue;
                }
            }
            this.agGrid.api.redrawRows();
            const inputData = {};
            let accountsData = {};

            if (
                this.widgetRef &&
                this.widgetRef.apiResponse &&
                this.widgetRef.apiResponse['dataMap'] &&
                this.widgetRef.apiResponse['dataMap']['tableKeys'] &&
                this.widgetRef.apiResponse['dataMap']['tableKeys'].length > 0
            ) {
                this.widgetRef.apiResponse['dataMap']['tableKeys'].forEach(
                    (each) => {
                        accountsData[each] = data.data[each];
                    }
                );
            } else {
                if (
                    this.widgetRef.widgetData.widgetInfo.cloudIcon ===
                    CloudLabel.AWS
                ) {
                    accountsData = {
                        resourceId: data.data.identifier,
                        regionName: data.data.region,
                        accountNumber: data.data.accountId
                    };
                }
            }

            inputData['resourceTaggingList'] = [accountsData];
            inputData['tagsToApply'] = {};
            const fieldName = data['column']['userProvidedColDef']['field'];
            inputData['tagsToApply'][fieldName] = data.data[fieldName];

            const apiArgs = Helper.generateHitApiConfig(
                this.widgetRef.widgetData.widgetInfo.create
            );

            apiArgs.input = inputData;

            apiArgs.function = (response) => {
                this.widgetRef.notificationsService.showSnackBar(
                    data.column.colId +
                        'Resource Tag Updated Successfully. Kindly refresh the insight to reflect changes.'
                );
            };
            apiArgs.errorFunction = () => {
                this.widgetRef.notificationsService.showSnackBar(
                    'Error while editing resource tag. Refresh the insight to see accurate data',
                    true
                );
            };
            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }

    addTableColumns(response) {
        const columns: IColumnData[] = [];
        if (
            response &&
            response['dataMap'] &&
            response['dataMap']['keysToShow'] &&
            response['dataMap']['keysToShow'].length
        ) {
            response['dataMap']['keysToShow'].forEach((colObj) => {
                if (colObj['id'] === 'severity') {
                    columns.push({
                        columnName: colObj['label'],
                        columnKey: colObj['id'],
                        cellRenderer: this.columnRenderer.bind(this),
                        sortable: false,
                        filter: false,
                        pinned: 'left',
                        lockPinned: true,
                        maxWidth: 115,
                        cellClass: 'remove'
                    });
                    this.tableInput.disableDragColumns = [colObj['label']];
                    this.tableInput.fixedColumnPosition = [
                        { columnKey: colObj['id'], index: 0 }
                    ];
                } else {
                    columns.push({
                        columnName: colObj['label'],
                        columnKey: colObj['id']
                    });
                }
            });

            if (!this.allocationChecksApiResponse.dataMap) {
                this.allocationChecksApiResponse['dataMap'] = {};
                this.allocationChecksApiResponse['dataMap']['keysToShow'] = [];
            }

            this.allocationChecksApiResponse['dataMap']['keysToShow'] =
                response['dataMap']['keysToShow'];

            if (!this.actionColumnInserted) {
                const actionColumn: IColumnData = {
                    columnKey: 'action',
                    columnName: 'Action',
                    filter: false,
                    pinned: 'right',
                    maxWidth: 180,
                    minWidth: 180,
                    lockPinned: true,
                    headerClass: 'grid-cell-data-centred',
                    columnValueGetter: (params: ValueGetterParams) => {
                        return JSON.stringify(params.data);
                    },
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: RowEvent) => {
                        const buttons: IButtonGeneratorInput[] = [];
                        buttons.push({
                            buttonName: 'Add',
                            buttonType: ButtonType.TEXT,
                            buttonColorType: ButtonColorType.SUCCESS,
                            showLoader: true,
                            function: (buttonRef: IButtonGeneratorInput) => {
                                this.addResourceTag(false, rowData.data);
                            }
                        });

                        buttons.push({
                            buttonName: 'Update',
                            buttonType: ButtonType.TEXT,
                            buttonColorType: ButtonColorType.INFO,
                            showLoader: true,
                            function: (buttonRef: IButtonData) => {
                                this.addResourceTag(true, rowData.data);
                            }
                        });

                        buttons.push({
                            buttonName: 'Delete',
                            buttonType: ButtonType.TEXT,
                            buttonColorType: ButtonColorType.WARN,
                            showLoader: true,
                            function: (buttonRef: IButtonData) => {
                                this.deleteResourceTags(rowData.data);
                            }
                        });
                        rowData['buttonGenInputs'] = buttons;
                        return rowData;
                    }
                };
                columns.push(actionColumn);
                this.tableInput.fixedColumnPosition.push({
                    columnKey: 'action',
                    index: 'lastIndex'
                });
            }

            this.tableInput.columns = columns;
        }
    }

    addResourceTags(response) {
        if (response && response['resourceTags']) {
            response['resourceTags'].forEach((tag) => {
                if (!this.resourceTags.includes(tag)) {
                    this.resourceTags.push(tag);
                    this.resourceTagColumns.push({
                        columnName: tag,
                        columnKey: tag,
                        cellRenderer: this.tagsRenderer.bind(this),
                        editable: true,
                        columnValueGetter: (rowData: ValueGetterParams) => {
                            return String(
                                this.tagsRenderer(rowData, true)
                            ).trim();
                        }
                    });
                }
            });
        }
    }

    showConventions() {
        if (this.conventions && this.conventions.length > 0) {
            const modalData: IModalData = {
                modalName: 'Conventions',
                modalIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-copyright'
                },
                modalType: ModalType.MINI_MODAL,
                sourceId: this.widgetRef.uniqueIdentity,
                modalSteps: [
                    {
                        stepData: {
                            componentToLoad: ConventionsModalV2Component,
                            payload: {
                                data: {
                                    conventions: this.conventions,
                                    widgetRef: this.widgetRef
                                }
                            }
                        },
                        stepName: 'Conventions'
                    }
                ]
            };
            this.modalService.openModal(modalData);
        } else {
            this.widgetRef.notificationsService.showSnackBar(
                Messages.NO_CONVENTIONS_FOUND,
                true
            );
        }
    }

    setConvention(response) {
        //Checking for Convention

        if (response && response['dataMap']) {
            if (!this.conventions) {
                const conventions = response.dataMap['Conventions']
                    ? response.dataMap['Conventions']
                    : response.dataMap['conventions'];

                if (Object.keys(conventions).length > 0) {
                    this.conventions = conventions;
                    this.widgetRef.changeDetectorRef.detectChanges();
                }
            } else {
                const conventions = response.dataMap['Conventions']
                    ? response.dataMap['Conventions']
                    : response.dataMap['conventions'];

                const existedConventions = this.conventions.map(
                    (convention) => convention.ruleSetName
                );
                conventions.map((each) => {
                    if (!existedConventions.includes(each.ruleSetName)) {
                        this.conventions.push(each);
                    }
                });
            }
        }

        if (this.conventions && this.conventions.length) {
            const conventionLength = this.conventions.length;
            this.conventionButton = {
                buttonName: `Conventions : ${conventionLength}`,
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.SECONDARY,
                hoverText: 'Conventions',
                function: this.showConventions.bind(this)
            };
        }
    }

    severityFilter(severityType?: string) {
        if (severityType) {
            let filteredRows;

            if (
                this.allocationChecksApiResponse &&
                this.allocationChecksApiResponse['dataList']
            ) {
                filteredRows = this.allocationChecksApiResponse[
                    'dataList'
                ].filter(
                    (rowData) =>
                        rowData['severity'].toLowerCase() ===
                        severityType.toLowerCase()
                );
            }

            this.agGrid.api.setRowData(filteredRows);
            this.agGrid.api.refreshCells();
        } else {
            if (
                this.allocationChecksApiResponse &&
                this.allocationChecksApiResponse['dataList']
            ) {
                this.agGrid.api.setRowData(
                    this.allocationChecksApiResponse['dataList']
                );
            }

            this.agGrid.api.refreshCells();
        }
    }

    columnRenderer(rowData: RowEvent) {
        const sev = rowData.data['severity'];
        if (sev === 'Critical') {
            return `<span>
            <i class="fas fa-times-circle red-severity-color"></i>
                        </span>`;
        } else if (sev === 'Warning') {
            return `<span>
            <i class="fas fa-exclamation-triangle yellow-severity-color"></i>
                    </span>`;
        } else {
            return `<span>
            <i class="fas fa-check-circle green-severity-color"></i>
                    </span>`;
        }
    }

    tagsRenderer(
        rowData: RowEvent | ValueGetterParams,
        isColValGetter?: boolean
    ) {
        const headerName = rowData['colDef']['headerName'];
        // Traversing Applied Tags
        for (
            let i = 0, appliedTags = rowData.data.appliedTags;
            i < appliedTags.length;
            ++i
        ) {
            if (appliedTags[i].split('|')[0].trim() === headerName) {
                const value = appliedTags[i].split('|')[1];
                if (isColValGetter) {
                    return value ? value : '';
                }
                const div = document.createElement('div');
                $(div).css({
                    color: Helper.getCssVarValue('secondaryTextColor'),
                    'text-align': 'center',
                    background: Helper.getCssVarValue('okSeverityColor'),
                    padding: '4px 8px'
                });

                $(div).append(value);
                return div;
            }
        }
        // Traversing Extra Tags
        for (
            let i = 0, extraTags = rowData.data.extraTags;
            i < extraTags.length;
            ++i
        ) {
            if (extraTags[i].split('|')[0].trim() === headerName) {
                const value = extraTags[i].split('|')[1];
                if (isColValGetter) {
                    return value ? value : '';
                }
                const div = document.createElement('div');
                $(div).css({
                    color: Helper.getCssVarValue('secondaryTextColor'),
                    'text-align': 'center',
                    background: Helper.getCssVarValue('graySeverityColor')
                });

                $(div).append(value);
                return div;
            }
        }
        // Traversing Missing Tags
        for (
            let i = 0, missingTags = rowData.data.missingTags;
            i < missingTags.length;
            ++i
        ) {
            if (missingTags[i].split('|')[0].trim() === headerName) {
                const value = missingTags[i].split('|')[1];
                if (isColValGetter) {
                    return value ? value : '';
                }
                const div = document.createElement('div');
                $(div).css({
                    color: Helper.getCssVarValue('secondaryTextColor'),
                    'text-align': 'center',
                    background: Helper.getCssVarValue('criticalSeverityColor')
                });

                $(div).append(value);
                return div;
            }
        }
    }

    addResourceTag(isUpdate = false, rowData?: RowEvent) {
        const inputDataForCreatingResourceTag = [];
        if (!rowData) {
            if (this.agGrid?.api?.getSelectedNodes()?.length === 0) {
                this.widgetRef.notificationsService.showSnackBar(
                    'Select Atleast One Resource To Add/Update Resource Tag',
                    true
                );
                return;
            }
            if (
                this.agGrid?.api?.getSelectedNodes()?.length >
                GlobalConfiguration.RESOURCE_SELECTION_LIMIT_V2
            ) {
                this.widgetRef.notificationsService.showSnackBar(
                    `You Can Select Maximum ${GlobalConfiguration.RESOURCE_SELECTION_LIMIT_V2} Resources To Add/Updated Resource Tag`,
                    true
                );
                return;
            }

            this.agGrid?.api?.getSelectedNodes()?.forEach((item) => {
                inputDataForCreatingResourceTag.push(item.data);
            });
        } else {
            inputDataForCreatingResourceTag.push(rowData);
        }
        const modalData: IModalData = {
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-tag'
            },
            modalName: `${isUpdate ? 'Update' : 'Add'} Tags`,
            modalType: ModalType.MIDDLE,
            sourceId: this.widgetRef.uniqueIdentity,
            modalWidthVw: 72,
            modalHeightVh: 77,
            modalSteps: [
                {
                    stepName: `${isUpdate ? 'Update' : 'Add'} Tags`,
                    stepData: {
                        componentToLoad: AddResourceTagsV2Component,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                inputDataForCreatingResourceTag,
                                isUpdate,
                                function:
                                    this.addResourceTagAndRefresh.bind(this),
                                excelFunction: this.processExcelData.bind(this),
                                singleRowSelected:
                                    this.agGrid?.api?.getSelectedNodes()
                                        .length === 1
                                        ? true
                                        : false
                            }
                        }
                    }
                }
            ]
        };

        this.modalService.openModal(modalData);
    }

    addResourceTagAndRefresh(
        inputDataForCreatingResourceTag,
        buttonRef: IButtonGeneratorInput,
        modalId: Symbol,
        isUpdate = false
    ) {
        inputDataForCreatingResourceTag.forEach((element) => {
            const tags = Object.keys(element['tagsToApply']);
            if (tags && tags.length) {
                tags.forEach((each) => {
                    element['tagsToApply'][each] = element['tagsToApply'][each]
                        ? element['tagsToApply'][each].toString()
                        : '';
                });
            }
        });

        const dataForApi = {
            resourceTaggingList: inputDataForCreatingResourceTag
        };

        const apiArgs = Helper.generateHitApiConfig(
            isUpdate
                ? this.widgetRef.widgetData.widgetInfo.update
                : this.widgetRef.widgetData.widgetInfo.create
        );

        apiArgs.input = dataForApi;
        apiArgs.function = (response) => {
            if (
                response['generalException'].length > 0 ||
                response['insufficientPermission'].length > 0 ||
                response['invalidCredentials'].length > 0 ||
                response['noCredentialsDB'].length > 0
            ) {
                this.widgetRef.notificationsService.showSnackBar(
                    'Resource Tag(s) have been added/updated for account(s) with sufficient permission(s).'
                );
            } else {
                this.widgetRef.notificationsService.showSnackBar(
                    'Resource Tag(s) Added/Updated Successfully'
                );
            }
            this.widgetRef.refreshWidget(true);
            buttonRef.loader = false;
            this.modalService.closeModal(null, modalId);
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error While Adding/Updating Resource Tag(s)'
            );
            buttonRef.loader = false;
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    deleteResourceTags(rowData?: RowEvent) {
        const inputDataFoDeletingResourceTag = {};
        inputDataFoDeletingResourceTag['selectedNodes'] = [];

        if (!rowData) {
            if (this.agGrid?.api?.getSelectedNodes().length === 0) {
                this.widgetRef.notificationsService.showSnackBar(
                    'Select Atleast One Resource To Delete Resource Tag',
                    true
                );
                return;
            }
            if (
                this.agGrid?.api?.getSelectedNodes()?.length >
                GlobalConfiguration.RESOURCE_SELECTION_LIMIT_V2
            ) {
                this.widgetRef.notificationsService.showSnackBar(
                    `You Can Select Maximum ${GlobalConfiguration.RESOURCE_SELECTION_LIMIT_V2} Resources To Delete Resource Tag`,
                    true
                );
                return;
            }

            this.agGrid?.api?.getSelectedNodes()?.forEach((item) => {
                inputDataFoDeletingResourceTag['selectedNodes'].push(item.data);
            });
        } else {
            inputDataFoDeletingResourceTag['selectedNodes'].push(rowData);
        }
        const modalData: IModalData = {
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-tag'
            },
            modalName: 'Delete Tags',
            modalType: ModalType.MINI_MODAL,
            sourceId: this.widgetRef.uniqueIdentity,
            modalHeightVh: 70,
            modalWidthVw: 60,
            modalSteps: [
                {
                    stepName: 'Delete Tags',
                    stepData: {
                        componentToLoad: DeleteResourceTagsV2Component,
                        payload: {
                            data: {
                                inputDataFoDeletingResourceTag,
                                widgetRef: this.widgetRef,
                                resourceTagKeys:
                                    this.allocationChecksApiResponse[
                                        'resourceTags'
                                    ],
                                function:
                                    this.deleteResourceTagsAndRefresh.bind(this)
                            }
                        }
                    }
                }
            ]
        };

        this.modalService.openModal(modalData);
    }

    deleteResourceTagsAndRefresh(apiInputData, modalId: Symbol) {
        const deleteApiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.delete
        );
        deleteApiArgs.input = apiInputData;
        deleteApiArgs.function = (response) => {
            if (
                response['generalException'].length > 0 ||
                response['insufficientPermission'].length > 0 ||
                response['invalidCredentials'].length > 0 ||
                response['noCredentialsDB'].length > 0
            ) {
                this.widgetRef.notificationsService.showSnackBar(
                    'Resource Tag(s) have been deleted for account(s) with sufficient permission(s).'
                );
            } else {
                this.widgetRef.notificationsService.showSnackBar(
                    'Resource Tag(s) Deleted Successfully'
                );
            }
            this.modalService.closeModal(null, modalId);
            this.widgetRef.refreshWidget(true);
        };
        deleteApiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error While Deleting Resource Tag(s)'
            );
            this.modalService.closeModal(null, modalId);
        };

        new HitApi(
            deleteApiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    processExcelData(
        preSignedURL,
        isUpdate,
        buttonRef: IButtonGeneratorInput,
        modalId: Symbol
    ) {
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                'readAddTagExcel'
            ]
        );

        apiArgs.input = {
            preSignedURL,
            type: isUpdate ? 'update' : 'add'
        };

        apiArgs.function = (response) => {
            if (
                response['generalException'].length > 0 ||
                response['insufficientPermission'].length > 0 ||
                response['invalidCredentials'].length > 0 ||
                response['noCredentialsDB'].length > 0
            ) {
                this.widgetRef.notificationsService.showSnackBar(
                    'Resource Tag(s) have been added/updated for account(s) with sufficient permission(s).'
                );
            } else {
                this.widgetRef.notificationsService.showSnackBar(
                    'Resource Tag(s) Added/Updated Successfully'
                );
            }
            this.widgetRef.refreshWidget(true);
            buttonRef.loader = false;
            this.modalService.closeModal(null, modalId);
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error While Adding/Updating Resource Tag(s)'
            );
            buttonRef.loader = false;
            this.modalService.closeModal(null, modalId);
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    createForm() {
        const modalInfo = {
            label: 'Set Convention',
            widgetRef: this.widgetRef,
            purpose: ModalAction.CREATE
        };
        this.setModalDataAndOpen(modalInfo);
    }

    setModalDataAndOpen(modalInfo) {
        const modalArgs: IModalData = {
            modalName: modalInfo.label,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalIcon: {
                class: 'fas fa-file-invoice',
                type: IconType.FONTAWSOME
            } as IIcon,

            modalPurpose: modalInfo.purpose,
            modalHeightVh: 95,
            modalWidthVw: 65,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: CreateTagConfigurationFormStepOne,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                edit: false,
                                filterIds:
                                    this.widgetRef.widgetData.widgetInfo
                                        .schedulingAutomation[
                                        'widgetFilters'
                                    ][0]['filters'],
                                existingConventionsList: [],
                                hideServiceFilter: true,
                                applicableResources:
                                    this.widgetRef.widgetData.widgetInfo[
                                        'applicableResource'
                                    ]
                            }
                        }
                    },
                    stepName: 'Filters'
                },
                {
                    stepData: {
                        componentToLoad: CreateTagConfigurationFormStepTwo,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                edit: false
                            }
                        }
                    },
                    stepName: 'Configuration'
                }
            ]
        };
        this.modalService.openModal(modalArgs);
    }
}
