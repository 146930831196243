<div
    class="assesseViewContainer"
    class="tw-flex-1 tw-overflow-y-hidden tw-overflow-x-hidden"
>
    <div class="overview-header">
        <span class="pageName">Assessment Overview</span>
        <span
            *ngIf="!assessmentCacheService.assessmentView"
            class="status-text"
            >{{ "Status: " }}</span
        >
        <span
            *ngIf="!assessmentCacheService.assessmentView"
            class="status"
            [ngStyle]="{
                color: this.data.response['colorCode']
                    ? this.data.response['colorCode']
                    : ''
            }"
            [class.tw-text-accent]="!this.data.response['colorCode']"
            >{{ this.data.response["status"] }}</span
        >
    </div>

    <div *ngIf="!loader" class="assessee-overview">
        <div class="overviewSection">
            <mat-tab-group
                #tabGroup
                mat-stretch-tabs
                mat-align-tabs="center"
                [disableRipple]="true"
                (selectedTabChange)="tabChanged($event.tab.textLabel)"
                [selectedIndex]="
                    userDataCacheService.isAssessorView &&
                    data.response['assesseOverview']
                        ? 1
                        : userDataCacheService.isAssessorView &&
                          data.response['assessorOverview']
                        ? -1
                        : 0
                "
            >
                <mat-tab
                    *ngIf="data.response['assesseOverview']"
                    [label]="Tab.ASSESSE_OVERVIEW"
                >
                    <div class="tab-content assesseeOverview">
                        <div class="card-container">
                            <div
                                (click)="filterCards(Status.TOTAL_SECTION)"
                                class="total card"
                                [class.active-card]="
                                    activeCard.get(Status.TOTAL_SECTION)
                                "
                            >
                                <div class="text">
                                    <span>Total Control Points</span>
                                    <div class="count">
                                        {{ overviewData["totalSections"] }}
                                    </div>
                                </div>
                            </div>
                            <div
                                (click)="
                                    filterCards(
                                        Status.READY_FOR_ASSESSMENT,
                                        overviewData['readyForAudit']
                                    )
                                "
                                class="readySection card"
                                [class.active-card]="
                                    activeCard.get(Status.READY_FOR_ASSESSMENT)
                                "
                            >
                                <div class="text">
                                    <span>Completed Control Points</span>
                                    <div class="count">
                                        {{ overviewData["readyForAudit"] }}
                                    </div>
                                </div>
                                <div class="status light-green">
                                    <div
                                        class="icon"
                                        appIcon
                                        [data]="readySectionIcon"
                                    ></div>
                                </div>
                            </div>
                            <div
                                (click)="
                                    filterCards(
                                        Status.INCOMPLETE,
                                        overviewData['incompleteSections']
                                    )
                                "
                                class="incompleteSection card"
                                [class.active-card]="
                                    activeCard.get(Status.INCOMPLETE)
                                "
                            >
                                <div class="text">
                                    <span>Not Completed Control Points</span>
                                    <div class="count">
                                        {{ overviewData["incompleteSections"] }}
                                    </div>
                                </div>
                                <div class="status light-red">
                                    <div
                                        class="icon"
                                        appIcon
                                        [data]="incompleteSectionIcon"
                                    ></div>
                                </div>
                            </div>
                            <div
                                (click)="
                                    filterCards(
                                        Status.MARKED_FOR_REVIEW,
                                        overviewData['markedForReview']
                                    )
                                "
                                class="markedSection card"
                                [class.active-card]="
                                    activeCard.get(Status.MARKED_FOR_REVIEW)
                                "
                            >
                                <div class="text">
                                    <span>Marked For Review</span>
                                    <div class="count">
                                        {{ overviewData["markedForReview"] }}
                                    </div>
                                </div>
                                <div class="status light-blue">
                                    <div
                                        class="icon"
                                        appIcon
                                        [data]="reviewSectionIcon"
                                    ></div>
                                </div>
                            </div>
                            <div
                                (click)="
                                    filterCards(
                                        Status.MARKED_FOR_REWORK,
                                        overviewData['markedForRework']
                                    )
                                "
                                class="markedSection card"
                                [class.active-card]="
                                    activeCard.get(Status.MARKED_FOR_REWORK)
                                "
                            >
                                <div class="text">
                                    <span>Open Action Items</span>
                                    <div class="count">
                                        {{
                                            overviewData["markedForRework"]
                                                ? overviewData[
                                                      "markedForRework"
                                                  ]
                                                : 0
                                        }}
                                    </div>
                                </div>
                                <div class="status light-accent">
                                    <div
                                        class="icon"
                                        appIcon
                                        [data]="reworkSectionIcon"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-assessment-info-bar
                        *ngIf="
                            isAIAudit &&
                            infoMessage &&
                            !data.response['assessorOverview']
                        "
                        [infoMessage]="infoMessage"
                        [extraClass]="'overview-message'"
                    >
                    </app-assessment-info-bar>
                    <div *ngIf="sections" class="section-container">
                        <div *ngFor="let section of sections">
                            <div class="section-heading">
                                <div class="checkbox">
                                    <mat-checkbox
                                        *ngIf="!section?.optionalSection"
                                        name="checkbox"
                                        [checked]="
                                            isChecked.has(selectedTab) &&
                                            isChecked.get(selectedTab) &&
                                            isChecked
                                                .get(selectedTab)
                                                .get(section.id)
                                                ? isChecked
                                                      .get(selectedTab)
                                                      .get(section.id)[
                                                      'checked'
                                                  ]
                                                : false
                                        "
                                        (change)="selected(section, $event)"
                                        [disabled]="
                                            this.assessmentCacheService
                                                .assessmentView
                                                ? true
                                                : this.disableSubmitButton
                                        "
                                        [class.disabled]="
                                            this.assessmentCacheService
                                                .assessmentView
                                                ? true
                                                : this.disableSubmitButton
                                        "
                                    ></mat-checkbox>
                                </div>
                                <span>
                                    {{ section["icon"] }} -
                                    {{ section["attributeName"] }}</span
                                >
                            </div>
                            <div
                                *ngIf="section['attributes']"
                                class="sub-section-container"
                            >
                                <div
                                    class="child-section"
                                    *ngFor="
                                        let subSection of section['attributes']
                                    "
                                >
                                    <div
                                        class="linking"
                                        *ngIf="
                                            subSection['attributes'] &&
                                            showSubSections.get(
                                                subSection['attributeName']
                                            )
                                        "
                                    ></div>
                                    <div
                                        class="stack sub-section"
                                        *ngIf="
                                            subSection['attributes'] &&
                                            !showSubSections.get(
                                                subSection['attributeName']
                                            )
                                        "
                                    >
                                        <div class="checkbox">
                                            <mat-checkbox
                                                *ngIf="
                                                    !subSection?.optionalSection
                                                "
                                                name="checkbox"
                                                [checked]="
                                                    isChecked.has(
                                                        selectedTab
                                                    ) &&
                                                    isChecked.get(
                                                        selectedTab
                                                    ) &&
                                                    isChecked
                                                        .get(selectedTab)
                                                        .get(subSection.id)
                                                        ? isChecked
                                                              .get(selectedTab)
                                                              .get(
                                                                  subSection.id
                                                              )['checked']
                                                        : false
                                                "
                                                (change)="
                                                    selected(
                                                        subSection,
                                                        $event,
                                                        section['id']
                                                    )
                                                "
                                                [disabled]="
                                                    this.assessmentCacheService
                                                        .assessmentView
                                                        ? true
                                                        : this
                                                              .disableSubmitButton
                                                "
                                                [class.disabled]="
                                                    this.assessmentCacheService
                                                        .assessmentView
                                                        ? true
                                                        : this
                                                              .disableSubmitButton
                                                "
                                            ></mat-checkbox>
                                        </div>
                                        <div class="sub-section-text">
                                            <div class="sub-section-numbers">
                                                {{ subSection["icon"] }}
                                            </div>
                                            <div class="sub-section-heading">
                                                <span
                                                    >{{
                                                        subSection[
                                                            "attributeName"
                                                        ]
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                        <ng-container
                                            *ngIf="!subSection?.optionalSection"
                                        >
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.READY_FOR_ASSESSMENT ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.READY_FOR_ASSESSMENT)
                                                "
                                                class="status light-green"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="readySectionIcon"
                                                ></div>
                                            </div>
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.MARKED_FOR_REVIEW ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.MARKED_FOR_REVIEW)
                                                "
                                                class="status light-blue"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="reviewSectionIcon"
                                                ></div>
                                            </div>
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.MARKED_FOR_REWORK ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.MARKED_FOR_REWORK)
                                                "
                                                class="status light-accent"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="reworkSectionIcon"
                                                ></div>
                                            </div>
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.INCOMPLETE ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.INCOMPLETE)
                                                "
                                                class="status light-red"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="
                                                        incompleteSectionIcon
                                                    "
                                                ></div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div
                                        class="stack-mid-layer sub-section"
                                        *ngIf="
                                            subSection['attributes'] &&
                                            !showSubSections.get(
                                                subSection['attributeName']
                                            )
                                        "
                                    >
                                        <div class="checkbox">
                                            <mat-checkbox
                                                *ngIf="
                                                    !subSection?.optionalSection
                                                "
                                                name="checkbox"
                                                [checked]="
                                                    isChecked.has(
                                                        selectedTab
                                                    ) &&
                                                    isChecked.get(
                                                        selectedTab
                                                    ) &&
                                                    isChecked
                                                        .get(selectedTab)
                                                        .get(subSection.id)
                                                        ? isChecked
                                                              .get(selectedTab)
                                                              .get(
                                                                  subSection.id
                                                              )['checked']
                                                        : false
                                                "
                                                (change)="
                                                    selected(
                                                        subSection,
                                                        $event,
                                                        section['id']
                                                    )
                                                "
                                                [disabled]="
                                                    this.assessmentCacheService
                                                        .assessmentView
                                                        ? true
                                                        : this
                                                              .disableSubmitButton
                                                "
                                                [class.disabled]="
                                                    this.assessmentCacheService
                                                        .assessmentView
                                                        ? true
                                                        : this
                                                              .disableSubmitButton
                                                "
                                            ></mat-checkbox>
                                        </div>
                                        <div class="sub-section-text">
                                            <div class="sub-section-numbers">
                                                {{ subSection["icon"] }}
                                            </div>
                                            <div class="sub-section-heading">
                                                <span
                                                    >{{
                                                        subSection[
                                                            "attributeName"
                                                        ]
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                        <ng-container
                                            *ngIf="!subSection?.optionalSection"
                                        >
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.READY_FOR_ASSESSMENT ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.READY_FOR_ASSESSMENT)
                                                "
                                                class="status light-green"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="readySectionIcon"
                                                ></div>
                                            </div>
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.MARKED_FOR_REVIEW ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.MARKED_FOR_REVIEW)
                                                "
                                                class="status light-blue"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="reviewSectionIcon"
                                                ></div>
                                            </div>
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.MARKED_FOR_REWORK ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.MARKED_FOR_REWORK)
                                                "
                                                class="status light-accent"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="reworkSectionIcon"
                                                ></div>
                                            </div>
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.INCOMPLETE ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.INCOMPLETE)
                                                "
                                                class="status light-red"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="
                                                        incompleteSectionIcon
                                                    "
                                                ></div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="sub-section">
                                        <div class="checkbox">
                                            <mat-checkbox
                                                *ngIf="
                                                    !subSection?.optionalSection
                                                "
                                                name="checkbox"
                                                [checked]="
                                                    isChecked.has(
                                                        selectedTab
                                                    ) &&
                                                    isChecked.get(
                                                        selectedTab
                                                    ) &&
                                                    isChecked
                                                        .get(selectedTab)
                                                        .get(subSection.id)
                                                        ? isChecked
                                                              .get(selectedTab)
                                                              .get(
                                                                  subSection.id
                                                              )['checked']
                                                        : false
                                                "
                                                (change)="
                                                    selected(
                                                        subSection,
                                                        $event,
                                                        section['id']
                                                    )
                                                "
                                                [disabled]="
                                                    this.assessmentCacheService
                                                        .assessmentView
                                                        ? true
                                                        : this
                                                              .disableSubmitButton
                                                "
                                                [class.disabled]="
                                                    this.assessmentCacheService
                                                        .assessmentView
                                                        ? true
                                                        : this
                                                              .disableSubmitButton
                                                "
                                            ></mat-checkbox>
                                        </div>
                                        <div
                                            [matTooltip]="
                                                subSection['leafNode']
                                                    ? 'Navigate To ' +
                                                      subSection[
                                                          'attributeName'
                                                      ]
                                                    : ''
                                            "
                                            (click)="
                                                subSection['leafNode']
                                                    ? navigateToSubSection(
                                                          subSection
                                                      )
                                                    : toggleSubSections(
                                                          subSection[
                                                              'attributeName'
                                                          ]
                                                      )
                                            "
                                            class="sub-section-text"
                                        >
                                            <div class="sub-section-numbers">
                                                {{ subSection["icon"] }}
                                            </div>
                                            <div class="sub-section-heading">
                                                <span
                                                    >{{
                                                        subSection[
                                                            "attributeName"
                                                        ]
                                                    }}
                                                </span>
                                            </div>
                                            <div class="link-to-sub-section">
                                                <span class="navigation-text">{{
                                                    subSection["leafNode"]
                                                        ? "Click to view control point"
                                                        : "Click to view"
                                                }}</span>
                                            </div>
                                        </div>
                                        <ng-container
                                            *ngIf="!subSection?.optionalSection"
                                        >
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.READY_FOR_ASSESSMENT ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.READY_FOR_ASSESSMENT)
                                                "
                                                class="status light-green"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="readySectionIcon"
                                                ></div>
                                            </div>
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.MARKED_FOR_REVIEW ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.MARKED_FOR_REVIEW)
                                                "
                                                class="status light-blue"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="reviewSectionIcon"
                                                ></div>
                                            </div>
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.MARKED_FOR_REWORK ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.MARKED_FOR_REWORK)
                                                "
                                                class="status light-accent"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="reworkSectionIcon"
                                                ></div>
                                            </div>
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.INCOMPLETE ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.INCOMPLETE)
                                                "
                                                class="status light-red"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="
                                                        incompleteSectionIcon
                                                    "
                                                ></div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div
                                        *ngIf="
                                            subSectionsGroup.has(
                                                subSection['attributeName']
                                            ) &&
                                            showSubSections.get(
                                                subSection['attributeName']
                                            )
                                        "
                                    >
                                        <div class="border"></div>
                                        <div
                                            *ngFor="
                                                let subSection of subSectionsGroup.get(
                                                    subSection['attributeName']
                                                )
                                            "
                                        >
                                            <div class="sub-section">
                                                <div class="checkbox">
                                                    <mat-checkbox
                                                        *ngIf="
                                                            subSection?.optionalSection
                                                        "
                                                        name="checkbox"
                                                        [checked]="
                                                            isChecked.has(
                                                                selectedTab
                                                            ) &&
                                                            isChecked.get(
                                                                selectedTab
                                                            ) &&
                                                            isChecked
                                                                .get(
                                                                    selectedTab
                                                                )
                                                                .get(
                                                                    subSection.id
                                                                )
                                                                ? isChecked
                                                                      .get(
                                                                          selectedTab
                                                                      )
                                                                      .get(
                                                                          subSection.id
                                                                      )[
                                                                      'checked'
                                                                  ]
                                                                : false
                                                        "
                                                        (change)="
                                                            selected(
                                                                subSection,
                                                                $event,
                                                                section['id']
                                                            )
                                                        "
                                                        [disabled]="
                                                            this
                                                                .assessmentCacheService
                                                                .assessmentView
                                                                ? true
                                                                : this
                                                                      .disableSubmitButton
                                                        "
                                                        [class.disabled]="
                                                            this
                                                                .assessmentCacheService
                                                                .assessmentView
                                                                ? true
                                                                : this
                                                                      .disableSubmitButton
                                                        "
                                                    ></mat-checkbox>
                                                </div>
                                                <div
                                                    [matTooltip]="
                                                        subSection['leafNode']
                                                            ? 'Navigate To ' +
                                                              subSection[
                                                                  'attributeName'
                                                              ]
                                                            : ''
                                                    "
                                                    (click)="
                                                        subSection['leafNode']
                                                            ? navigateToSubSection(
                                                                  subSection
                                                              )
                                                            : toggleSubSections(
                                                                  subSection[
                                                                      'attributeName'
                                                                  ]
                                                              )
                                                    "
                                                    class="sub-section-text"
                                                >
                                                    <div
                                                        class="sub-section-numbers"
                                                    >
                                                        {{ subSection["icon"] }}
                                                    </div>
                                                    <div
                                                        class="sub-section-heading"
                                                    >
                                                        <span
                                                            >{{
                                                                subSection[
                                                                    "attributeName"
                                                                ]
                                                            }}
                                                        </span>
                                                    </div>
                                                    <div
                                                        class="link-to-sub-section"
                                                    >
                                                        <span
                                                            class="navigation-text"
                                                            >{{
                                                                subSection[
                                                                    "leafNode"
                                                                ]
                                                                    ? "Click to view control point"
                                                                    : "Click to view"
                                                            }}</span
                                                        >
                                                    </div>
                                                </div>
                                                <ng-container
                                                    *ngIf="
                                                        !subSection?.optionalSection
                                                    "
                                                >
                                                    <div
                                                        *ngIf="
                                                            subSection[
                                                                'status'
                                                            ] ===
                                                                Status.READY_FOR_ASSESSMENT ||
                                                            (subSection[
                                                                'attributes'
                                                            ]
                                                                | getAssessmentStatus
                                                                    : Status.READY_FOR_ASSESSMENT)
                                                        "
                                                        class="status light-green"
                                                    >
                                                        <div
                                                            class="sub-section-icon"
                                                            appIcon
                                                            [data]="
                                                                readySectionIcon
                                                            "
                                                        ></div>
                                                    </div>
                                                    <div
                                                        *ngIf="
                                                            subSection[
                                                                'status'
                                                            ] ===
                                                                Status.MARKED_FOR_REVIEW ||
                                                            (subSection[
                                                                'attributes'
                                                            ]
                                                                | getAssessmentStatus
                                                                    : Status.MARKED_FOR_REVIEW)
                                                        "
                                                        class="status light-blue"
                                                    >
                                                        <div
                                                            class="sub-section-icon"
                                                            appIcon
                                                            [data]="
                                                                reviewSectionIcon
                                                            "
                                                        ></div>
                                                    </div>
                                                    <div
                                                        *ngIf="
                                                            subSection[
                                                                'status'
                                                            ] ===
                                                                Status.MARKED_FOR_REWORK ||
                                                            (subSection[
                                                                'attributes'
                                                            ]
                                                                | getAssessmentStatus
                                                                    : Status.MARKED_FOR_REWORK)
                                                        "
                                                        class="status light-accent"
                                                    >
                                                        <div
                                                            class="sub-section-icon"
                                                            appIcon
                                                            [data]="
                                                                reworkSectionIcon
                                                            "
                                                        ></div>
                                                    </div>
                                                    <div
                                                        *ngIf="
                                                            subSection[
                                                                'status'
                                                            ] ===
                                                                Status.INCOMPLETE ||
                                                            (subSection[
                                                                'attributes'
                                                            ]
                                                                | getAssessmentStatus
                                                                    : Status.INCOMPLETE)
                                                        "
                                                        class="status light-red"
                                                    >
                                                        <div
                                                            class="sub-section-icon"
                                                            appIcon
                                                            [data]="
                                                                incompleteSectionIcon
                                                            "
                                                        ></div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                *ngIf="section['leafNode']"
                                class="sub-section-container"
                            >
                                <div class="sub-section">
                                    <div class="checkbox">
                                        <mat-checkbox
                                            *ngIf="!section?.optionalSection"
                                            name="checkbox"
                                            [checked]="
                                                isChecked.has(selectedTab) &&
                                                isChecked.get(selectedTab) &&
                                                isChecked
                                                    .get(selectedTab)
                                                    .get(section.id)
                                                    ? isChecked
                                                          .get(selectedTab)
                                                          .get(section.id)[
                                                          'checked'
                                                      ]
                                                    : false
                                            "
                                            (change)="selected(section, $event)"
                                            [disabled]="
                                                this.assessmentCacheService
                                                    .assessmentView
                                                    ? true
                                                    : this.disableSubmitButton
                                            "
                                            [class.disabled]="
                                                this.assessmentCacheService
                                                    .assessmentView
                                                    ? true
                                                    : this.disableSubmitButton
                                            "
                                        ></mat-checkbox>
                                    </div>
                                    <div
                                        [matTooltip]="
                                            section['leafNode']
                                                ? 'Navigate To ' +
                                                  section['attributeName']
                                                : ''
                                        "
                                        (click)="
                                            section['leafNode']
                                                ? navigateToSubSection(section)
                                                : toggleSubSections(
                                                      section['attributeName']
                                                  )
                                        "
                                        class="sub-section-text"
                                    >
                                        <div class="sub-section-numbers">
                                            {{ section["icon"] }}
                                        </div>
                                        <div class="sub-section-heading">
                                            <span
                                                >{{ section["attributeName"] }}
                                            </span>
                                        </div>
                                        <div class="link-to-sub-section">
                                            <span class="navigation-text">{{
                                                section["leafNode"]
                                                    ? "Click to view control point"
                                                    : "Click to view"
                                            }}</span>
                                        </div>
                                    </div>

                                    <ng-container
                                        *ngIf="!section?.optionalSection"
                                    >
                                        <div
                                            *ngIf="
                                                section['status'] ===
                                                Status.READY_FOR_ASSESSMENT
                                            "
                                            class="status light-green"
                                        >
                                            <div
                                                class="sub-section-icon"
                                                appIcon
                                                [data]="readySectionIcon"
                                            ></div>
                                        </div>
                                        <div
                                            *ngIf="
                                                section['status'] ===
                                                Status.MARKED_FOR_REVIEW
                                            "
                                            class="status light-blue"
                                        >
                                            <div
                                                class="sub-section-icon"
                                                appIcon
                                                [data]="reviewSectionIcon"
                                            ></div>
                                        </div>
                                        <div
                                            *ngIf="
                                                section['status'] ===
                                                Status.INCOMPLETE
                                            "
                                            class="status light-red"
                                        >
                                            <div
                                                class="sub-section-icon"
                                                appIcon
                                                [data]="incompleteSectionIcon"
                                            ></div>
                                        </div>
                                        <div
                                            *ngIf="
                                                section['status'] ===
                                                Status.MARKED_FOR_REWORK
                                            "
                                            class="status light-accent"
                                        >
                                            <div
                                                class="sub-section-icon"
                                                appIcon
                                                [data]="reworkSectionIcon"
                                            ></div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab
                    [label]="Tab.ASSESSOR_OVERVIEW"
                    [disabled]="
                        !userDataCacheService.isAssessorView &&
                        !assessmentCacheService.assessmentView
                            ? this.data.response['assesseeSubmitted'] &&
                              this.data.response['assessorSubmitted']
                                ? false
                                : true
                            : false
                    "
                >
                    <ng-template mat-tab-label>
                        <label
                            [class.disabled]="
                                !userDataCacheService.isAssessorView &&
                                !assessmentCacheService.assessmentView
                                    ? this.data.response['assesseeSubmitted'] &&
                                      this.data.response['assessorSubmitted']
                                        ? false
                                        : true
                                    : false
                            "
                            [ngStyle]="
                                !userDataCacheService.isAssessorView &&
                                !assessmentCacheService.assessmentView
                                    ? this.data.response['assesseeSubmitted'] &&
                                      this.data.response['assessorSubmitted']
                                        ? ''
                                        : { color: 'var(--grayTextColor)' }
                                    : ''
                            "
                            class="labelHeading clickable"
                            [matTooltip]="
                                !userDataCacheService.isAssessorView &&
                                !assessmentCacheService.assessmentView
                                    ? this.data.response['assesseeSubmitted'] &&
                                      this.data.response['assessorSubmitted']
                                        ? ''
                                        : ' This section will be accessed post Assessment Evaluation'
                                    : ''
                            "
                            >{{ Tab.ASSESSOR_OVERVIEW }}</label
                        >
                    </ng-template>
                    <div
                        *ngIf="data.response['assessorOverview']"
                        class="tab-content assesseeOverview"
                    >
                        <div
                            class="card-container"
                            [ngClass]="{ 'scoring-audit': isScoringAudit }"
                        >
                            <div
                                class="total-score card"
                                *ngIf="isScoringAudit"
                            >
                                <div class="text">
                                    <span>Score Achieved</span>
                                    <div class="count">
                                        {{
                                            assessorOverviewData[
                                                "scoreAchieved"
                                            ]
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div
                                (click)="filterCards(Status.TOTAL_SECTION)"
                                class="total card"
                                [class.active-card]="
                                    activeCard.get(Status.TOTAL_SECTION)
                                "
                            >
                                <div class="text">
                                    <span>Total Control Points</span>
                                    <div class="count">
                                        {{
                                            assessorOverviewData[
                                                "totalSections"
                                            ]
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div
                                (click)="
                                    filterCards(
                                        isScoringAudit || isGoogleMsp
                                            ? Status.EVALUATED
                                            : Status.APPROVED,
                                        assessorOverviewData['readyForAudit']
                                    )
                                "
                                class="readySection card"
                                [class.active-card]="
                                    activeCard.get(
                                        isScoringAudit || isGoogleMsp
                                            ? Status.EVALUATED
                                            : Status.APPROVED
                                    )
                                "
                            >
                                <div class="text">
                                    <span>{{
                                        isScoringAudit || isGoogleMsp
                                            ? "Evaluated Control Points by Assessor"
                                            : "Met Control Points"
                                    }}</span>
                                    <div class="count">
                                        {{
                                            assessorOverviewData[
                                                "readyForAudit"
                                            ]
                                        }}
                                    </div>
                                </div>
                                <div class="status light-green">
                                    <div
                                        class="icon"
                                        appIcon
                                        [data]="readySectionIcon"
                                    ></div>
                                </div>
                            </div>
                            <div
                                (click)="
                                    filterCards(
                                        Status.REJECTED,
                                        assessorOverviewData['rejected']
                                    )
                                "
                                class="incompleteSection card"
                                [class.active-card]="
                                    activeCard.get(Status.REJECTED)
                                "
                                *ngIf="!(isScoringAudit || isGoogleMsp)"
                            >
                                <div class="text">
                                    <span>Not Met Control Points</span>
                                    <div class="count">
                                        {{ assessorOverviewData["rejected"] }}
                                    </div>
                                </div>
                                <div class="status light-red">
                                    <div
                                        class="icon"
                                        appIcon
                                        [data]="incompleteSectionIcon"
                                    ></div>
                                </div>
                            </div>
                            <div
                                class="not-evaluated card"
                                (click)="
                                    filterCards(
                                        isScoringAudit || isGoogleMsp
                                            ? Status.NOT_EVALUATED
                                            : Status.INCOMPLETE,
                                        assessorOverviewData[
                                            'incompleteSections'
                                        ]
                                    )
                                "
                                [class.active-card]="
                                    activeCard.get(
                                        isScoringAudit || isGoogleMsp
                                            ? Status.NOT_EVALUATED
                                            : Status.INCOMPLETE
                                    )
                                "
                            >
                                <div class="text">
                                    <span>{{
                                        isScoringAudit || isGoogleMsp
                                            ? "Not Evaluated Control Points by Assessor"
                                            : "Not Evaluated Control Points"
                                    }}</span>
                                    <div class="count">
                                        {{
                                            assessorOverviewData[
                                                "incompleteSections"
                                            ]
                                        }}
                                    </div>
                                </div>
                                <div class="status light-blue">
                                    <div
                                        class="icon"
                                        appIcon
                                        [data]="notEvaluatedIcon"
                                    ></div>
                                </div>
                            </div>
                            <div
                                *ngIf="!isGoogleMsp"
                                (click)="
                                    filterCards(
                                        Status.MARKED_FOR_REWORK,
                                        assessorOverviewData['markedForRework']
                                    )
                                "
                                [class.active-card]="
                                    activeCard.get(Status.MARKED_FOR_REWORK)
                                "
                                class="rework card"
                            >
                                <div class="text">
                                    <span>Open Action Items</span>
                                    <div class="count">
                                        {{
                                            assessorOverviewData[
                                                "markedForRework"
                                            ]
                                                ? assessorOverviewData[
                                                      "markedForRework"
                                                  ]
                                                : 0
                                        }}
                                    </div>
                                </div>
                                <div class="status light-accent">
                                    <div
                                        class="icon"
                                        appIcon
                                        [data]="reworkSectionIcon"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-assessment-info-bar
                        *ngIf="infoMessage && isAIAudit"
                        [infoMessage]="infoMessage"
                        [extraClass]="'overview-message'"
                    >
                    </app-assessment-info-bar>
                    <div *ngIf="sections" class="section-container">
                        <div *ngFor="let section of sections">
                            <div class="section-heading">
                                <div class="checkbox">
                                    <mat-checkbox
                                        *ngIf="!section?.optionalSection"
                                        name="checkbox"
                                        [checked]="
                                            isChecked.has(selectedTab) &&
                                            isChecked.get(selectedTab) &&
                                            isChecked
                                                .get(selectedTab)
                                                .get(section.id)
                                                ? isChecked
                                                      .get(selectedTab)
                                                      .get(section.id)[
                                                      'checked'
                                                  ]
                                                : false
                                        "
                                        (change)="selected(section, $event)"
                                        [disabled]="
                                            this.assessmentCacheService
                                                .assessmentView
                                                ? true
                                                : this.disableActionButtons
                                        "
                                        [class.disabled]="
                                            this.assessmentCacheService
                                                .assessmentView
                                                ? true
                                                : this.disableActionButtons
                                        "
                                    ></mat-checkbox>
                                </div>
                                <span>
                                    {{ section["icon"] }} -
                                    {{ section["attributeName"] }}</span
                                >
                            </div>

                            <div
                                *ngIf="section['attributes']"
                                class="sub-section-container"
                            >
                                <div
                                    class="child-section"
                                    *ngFor="
                                        let subSection of section['attributes']
                                    "
                                    [class.disabled]="
                                        isJuniperAudit && subSection['disabled']
                                    "
                                    [matTooltip]="
                                        isJuniperAudit && subSection['disabled']
                                            ? globalDataService.getTooltipMessage(
                                                  subSection
                                              )
                                            : ''
                                    "
                                >
                                    <div
                                        class="linking"
                                        *ngIf="
                                            subSection['attributes'] &&
                                            showSubSections.get(
                                                subSection['attributeName']
                                            )
                                        "
                                    ></div>
                                    <div
                                        class="stack sub-section"
                                        *ngIf="
                                            subSection['attributes'] &&
                                            !showSubSections.get(
                                                subSection['attributeName']
                                            )
                                        "
                                    >
                                        <div class="checkbox">
                                            <mat-checkbox
                                                *ngIf="
                                                    !subSection?.optionalSection
                                                "
                                                name="checkbox"
                                                [checked]="
                                                    isChecked.has(
                                                        selectedTab
                                                    ) &&
                                                    isChecked.get(
                                                        selectedTab
                                                    ) &&
                                                    isChecked
                                                        .get(selectedTab)
                                                        .get(subSection.id)
                                                        ? isChecked
                                                              .get(selectedTab)
                                                              .get(
                                                                  subSection.id
                                                              )['checked']
                                                        : false
                                                "
                                                (change)="
                                                    selected(
                                                        subSection,
                                                        $event,
                                                        section['id']
                                                    )
                                                "
                                                [disabled]="
                                                    this.assessmentCacheService
                                                        .assessmentView
                                                        ? true
                                                        : this
                                                              .disableActionButtons
                                                "
                                                [class.disabled]="
                                                    this.assessmentCacheService
                                                        .assessmentView
                                                        ? true
                                                        : this
                                                              .disableActionButtons
                                                "
                                            ></mat-checkbox>
                                        </div>
                                        <div class="sub-section-text">
                                            <div class="sub-section-numbers">
                                                {{ subSection["icon"] }}
                                            </div>
                                            <div class="sub-section-heading">
                                                <span
                                                    >{{
                                                        subSection[
                                                            "attributeName"
                                                        ]
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                        <ng-container
                                            *ngIf="!subSection?.optionalSection"
                                        >
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.APPROVED ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.APPROVED)
                                                "
                                                class="status light-green"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="readySectionIcon"
                                                ></div>
                                            </div>
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.REJECTED ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.REJECTED)
                                                "
                                                class="status light-red"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="
                                                        incompleteSectionIcon
                                                    "
                                                ></div>
                                            </div>
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.MARKED_FOR_REWORK ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.MARKED_FOR_REWORK)
                                                "
                                                class="status light-accent"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="reworkSectionIcon"
                                                ></div>
                                            </div>
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.INCOMPLETE ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.INCOMPLETE)
                                                "
                                                class="status light-blue"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="notEvaluatedIcon"
                                                ></div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div
                                        class="stack-mid-layer sub-section"
                                        *ngIf="
                                            subSection['attributes'] &&
                                            !showSubSections.get(
                                                subSection['attributeName']
                                            )
                                        "
                                    >
                                        <div class="checkbox">
                                            <mat-checkbox
                                                *ngIf="
                                                    !subSection?.optionalSection
                                                "
                                                name="checkbox"
                                                [checked]="
                                                    isChecked.has(
                                                        selectedTab
                                                    ) &&
                                                    isChecked.get(
                                                        selectedTab
                                                    ) &&
                                                    isChecked
                                                        .get(selectedTab)
                                                        .get(subSection.id)
                                                        ? isChecked
                                                              .get(selectedTab)
                                                              .get(
                                                                  subSection.id
                                                              )['checked']
                                                        : false
                                                "
                                                (change)="
                                                    selected(
                                                        subSection,
                                                        $event,
                                                        section['id']
                                                    )
                                                "
                                                [disabled]="
                                                    this.assessmentCacheService
                                                        .assessmentView
                                                        ? true
                                                        : this
                                                              .disableActionButtons
                                                "
                                                [class.disabled]="
                                                    this.assessmentCacheService
                                                        .assessmentView
                                                        ? true
                                                        : this
                                                              .disableActionButtons
                                                "
                                            ></mat-checkbox>
                                        </div>
                                        <div class="sub-section-text">
                                            <div class="sub-section-numbers">
                                                {{ subSection["icon"] }}
                                            </div>
                                            <div class="sub-section-heading">
                                                <span
                                                    >{{
                                                        subSection[
                                                            "attributeName"
                                                        ]
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                        <ng-container
                                            *ngIf="!subSection?.optionalSection"
                                        >
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.APPROVED ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.APPROVED)
                                                "
                                                class="status light-green"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="readySectionIcon"
                                                ></div>
                                            </div>
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.REJECTED ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.REJECTED)
                                                "
                                                class="status light-red"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="
                                                        incompleteSectionIcon
                                                    "
                                                ></div>
                                            </div>
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.MARKED_FOR_REWORK ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.MARKED_FOR_REWORK)
                                                "
                                                class="status light-accent"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="reworkSectionIcon"
                                                ></div>
                                            </div>
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.INCOMPLETE ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.INCOMPLETE)
                                                "
                                                class="status light-blue"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="notEvaluatedIcon"
                                                ></div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="sub-section">
                                        <div class="checkbox">
                                            <mat-checkbox
                                                *ngIf="
                                                    !subSection?.optionalSection
                                                "
                                                name="checkbox"
                                                [checked]="
                                                    isChecked.has(
                                                        selectedTab
                                                    ) &&
                                                    isChecked.get(
                                                        selectedTab
                                                    ) &&
                                                    isChecked
                                                        .get(selectedTab)
                                                        .get(subSection.id)
                                                        ? isChecked
                                                              .get(selectedTab)
                                                              .get(
                                                                  subSection.id
                                                              )['checked']
                                                        : false
                                                "
                                                (change)="
                                                    selected(
                                                        subSection,
                                                        $event,
                                                        section['id']
                                                    )
                                                "
                                                [disabled]="
                                                    this.assessmentCacheService
                                                        .assessmentView
                                                        ? true
                                                        : this
                                                              .disableActionButtons ||
                                                          (isJuniperAudit &&
                                                              subSection[
                                                                  'disabled'
                                                              ])
                                                "
                                                [class.disabled]="
                                                    this.assessmentCacheService
                                                        .assessmentView
                                                        ? true
                                                        : this
                                                              .disableActionButtons ||
                                                          (isJuniperAudit &&
                                                              subSection[
                                                                  'disabled'
                                                              ])
                                                "
                                            ></mat-checkbox>
                                        </div>
                                        <div
                                            [matTooltip]="
                                                subSection['leafNode']
                                                    ? 'Navigate To ' +
                                                      subSection[
                                                          'attributeName'
                                                      ]
                                                    : ''
                                            "
                                            (click)="
                                                subSection['leafNode']
                                                    ? navigateToSubSection(
                                                          subSection
                                                      )
                                                    : toggleSubSections(
                                                          subSection[
                                                              'attributeName'
                                                          ]
                                                      )
                                            "
                                            class="sub-section-text"
                                        >
                                            <div class="sub-section-numbers">
                                                {{ subSection["icon"] }}
                                            </div>
                                            <div class="sub-section-heading">
                                                <span
                                                    >{{
                                                        subSection[
                                                            "attributeName"
                                                        ]
                                                    }}
                                                </span>
                                            </div>
                                            <div class="link-to-sub-section">
                                                <span class="navigation-text">{{
                                                    subSection["leafNode"]
                                                        ? "Click to view control point"
                                                        : "Click to view"
                                                }}</span>
                                            </div>
                                        </div>
                                        <ng-container
                                            *ngIf="!subSection?.optionalSection"
                                        >
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.APPROVED ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.APPROVED)
                                                "
                                                class="status light-green"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="readySectionIcon"
                                                ></div>
                                            </div>
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.EVALUATED ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.EVALUATED)
                                                "
                                                class="status light-green"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="readySectionIcon"
                                                ></div>
                                            </div>
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.REJECTED ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.REJECTED)
                                                "
                                                class="status light-red"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="
                                                        incompleteSectionIcon
                                                    "
                                                ></div>
                                            </div>
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.MARKED_FOR_REWORK ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.MARKED_FOR_REWORK)
                                                "
                                                class="status light-accent"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="reworkSectionIcon"
                                                ></div>
                                            </div>
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.INCOMPLETE ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.INCOMPLETE)
                                                "
                                                class="status light-blue"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="notEvaluatedIcon"
                                                ></div>
                                            </div>
                                            <div
                                                *ngIf="
                                                    subSection['status'] ===
                                                        Status.NOT_EVALUATED ||
                                                    (subSection['attributes']
                                                        | getAssessmentStatus
                                                            : Status.NOT_EVALUATED)
                                                "
                                                class="status light-blue"
                                            >
                                                <div
                                                    class="sub-section-icon"
                                                    appIcon
                                                    [data]="notEvaluatedIcon"
                                                ></div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div
                                        *ngIf="
                                            subSectionsGroupForAssessor.has(
                                                subSection['attributeName']
                                            ) &&
                                            showSubSections.get(
                                                subSection['attributeName']
                                            )
                                        "
                                    >
                                        <div class="border"></div>
                                        <div
                                            *ngFor="
                                                let subSection of subSectionsGroupForAssessor.get(
                                                    subSection['attributeName']
                                                )
                                            "
                                        >
                                            <div class="sub-section">
                                                <div class="checkbox">
                                                    <mat-checkbox
                                                        *ngIf="
                                                            !subSection?.optionalSection
                                                        "
                                                        name="checkbox"
                                                        [checked]="
                                                            isChecked.has(
                                                                selectedTab
                                                            ) &&
                                                            isChecked.get(
                                                                selectedTab
                                                            ) &&
                                                            isChecked
                                                                .get(
                                                                    selectedTab
                                                                )
                                                                .get(
                                                                    subSection.id
                                                                )
                                                                ? isChecked
                                                                      .get(
                                                                          selectedTab
                                                                      )
                                                                      .get(
                                                                          subSection.id
                                                                      )[
                                                                      'checked'
                                                                  ]
                                                                : false
                                                        "
                                                        (change)="
                                                            selected(
                                                                subSection,
                                                                $event,
                                                                section['id']
                                                            )
                                                        "
                                                        [disabled]="
                                                            this
                                                                .assessmentCacheService
                                                                .assessmentView
                                                                ? true
                                                                : this
                                                                      .disableActionButtons ||
                                                                  (isJuniperAudit &&
                                                                      subSection[
                                                                          'disabled'
                                                                      ])
                                                        "
                                                        [class.disabled]="
                                                            this
                                                                .assessmentCacheService
                                                                .assessmentView
                                                                ? true
                                                                : this
                                                                      .disableActionButtons ||
                                                                  (isJuniperAudit &&
                                                                      subSection[
                                                                          'disabled'
                                                                      ])
                                                        "
                                                    ></mat-checkbox>
                                                </div>
                                                <div
                                                    [matTooltip]="
                                                        subSection['leafNode']
                                                            ? 'Navigate To ' +
                                                              subSection[
                                                                  'attributeName'
                                                              ]
                                                            : ''
                                                    "
                                                    (click)="
                                                        subSection['leafNode']
                                                            ? navigateToSubSection(
                                                                  subSection
                                                              )
                                                            : toggleSubSections(
                                                                  subSection[
                                                                      'attributeName'
                                                                  ]
                                                              )
                                                    "
                                                    class="sub-section-text"
                                                >
                                                    <div
                                                        class="sub-section-numbers"
                                                    >
                                                        {{ subSection["icon"] }}
                                                    </div>
                                                    <div
                                                        class="sub-section-heading"
                                                    >
                                                        <span
                                                            >{{
                                                                subSection[
                                                                    "attributeName"
                                                                ]
                                                            }}
                                                        </span>
                                                    </div>
                                                    <div
                                                        class="link-to-sub-section"
                                                    >
                                                        <span
                                                            class="navigation-text"
                                                            >{{
                                                                subSection[
                                                                    "leafNode"
                                                                ]
                                                                    ? "Click to view control point"
                                                                    : "Click to view"
                                                            }}</span
                                                        >
                                                    </div>
                                                </div>

                                                <ng-container
                                                    *ngIf="
                                                        !subSection?.optionalSection
                                                    "
                                                >
                                                    <div
                                                        *ngIf="
                                                            subSection[
                                                                'status'
                                                            ] ===
                                                                Status.APPROVED ||
                                                            (subSection[
                                                                'attributes'
                                                            ]
                                                                | getAssessmentStatus
                                                                    : Status.APPROVED)
                                                        "
                                                        class="status light-green"
                                                    >
                                                        <div
                                                            class="sub-section-icon"
                                                            appIcon
                                                            [data]="
                                                                readySectionIcon
                                                            "
                                                        ></div>
                                                    </div>
                                                    <div
                                                        *ngIf="
                                                            subSection[
                                                                'status'
                                                            ] ===
                                                                Status.REJECTED ||
                                                            (subSection[
                                                                'attributes'
                                                            ]
                                                                | getAssessmentStatus
                                                                    : Status.REJECTED)
                                                        "
                                                        class="status light-red"
                                                    >
                                                        <div
                                                            class="sub-section-icon"
                                                            appIcon
                                                            [data]="
                                                                incompleteSectionIcon
                                                            "
                                                        ></div>
                                                    </div>
                                                    <div
                                                        *ngIf="
                                                            subSection[
                                                                'status'
                                                            ] ===
                                                                Status.MARKED_FOR_REWORK ||
                                                            (subSection[
                                                                'attributes'
                                                            ]
                                                                | getAssessmentStatus
                                                                    : Status.MARKED_FOR_REWORK)
                                                        "
                                                        class="status light-accent"
                                                    >
                                                        <div
                                                            class="sub-section-icon"
                                                            appIcon
                                                            [data]="
                                                                reworkSectionIcon
                                                            "
                                                        ></div>
                                                    </div>
                                                    <div
                                                        *ngIf="
                                                            subSection[
                                                                'status'
                                                            ] ===
                                                                Status.INCOMPLETE ||
                                                            (subSection[
                                                                'attributes'
                                                            ]
                                                                | getAssessmentStatus
                                                                    : Status.INCOMPLETE)
                                                        "
                                                        class="status light-blue"
                                                    >
                                                        <div
                                                            class="sub-section-icon"
                                                            appIcon
                                                            [data]="
                                                                notEvaluatedIcon
                                                            "
                                                        ></div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                *ngIf="section['leafNode']"
                                class="sub-section-container"
                            >
                                <div class="sub-section">
                                    <div class="checkbox">
                                        <mat-checkbox
                                            *ngIf="!section?.optionalSection"
                                            name="checkbox"
                                            [checked]="
                                                isChecked.has(selectedTab) &&
                                                isChecked.get(selectedTab) &&
                                                isChecked
                                                    .get(selectedTab)
                                                    .get(section.id)
                                                    ? isChecked
                                                          .get(selectedTab)
                                                          .get(section.id)[
                                                          'checked'
                                                      ]
                                                    : false
                                            "
                                            (change)="
                                                selected(
                                                    section,
                                                    $event,
                                                    section['id']
                                                )
                                            "
                                            [disabled]="
                                                this.assessmentCacheService
                                                    .assessmentView
                                                    ? true
                                                    : this.disableActionButtons
                                            "
                                            [class.disabled]="
                                                this.assessmentCacheService
                                                    .assessmentView
                                                    ? true
                                                    : this.disableActionButtons
                                            "
                                        ></mat-checkbox>
                                    </div>
                                    <div
                                        [matTooltip]="
                                            section['leafNode']
                                                ? 'Navigate To ' +
                                                  section['attributeName']
                                                : ''
                                        "
                                        (click)="
                                            section['leafNode']
                                                ? navigateToSubSection(section)
                                                : toggleSubSections(
                                                      section['attributeName']
                                                  )
                                        "
                                        class="sub-section-text"
                                    >
                                        <div class="sub-section-numbers">
                                            {{ section["icon"] }}
                                        </div>
                                        <div class="sub-section-heading">
                                            <span
                                                >{{ section["attributeName"] }}
                                            </span>
                                        </div>
                                        <div class="link-to-sub-section">
                                            <span class="navigation-text">{{
                                                section["leafNode"]
                                                    ? "Click to view control point"
                                                    : "Click to view"
                                            }}</span>
                                        </div>
                                    </div>

                                    <ng-container
                                        *ngIf="!section?.optionalSection"
                                    >
                                        <div
                                            *ngIf="
                                                section['status'] ===
                                                    Status.APPROVED ||
                                                (section['attributes']
                                                    | getAssessmentStatus
                                                        : Status.APPROVED) ||
                                                (isGoogleMsp &&
                                                    section['status'] ===
                                                        Status.EVALUATED)
                                            "
                                            class="status light-green"
                                        >
                                            <div
                                                class="sub-section-icon"
                                                appIcon
                                                [data]="readySectionIcon"
                                            ></div>
                                        </div>
                                        <div
                                            *ngIf="
                                                section['status'] ===
                                                    Status.REJECTED ||
                                                (section['attributes']
                                                    | getAssessmentStatus
                                                        : Status.REJECTED)
                                            "
                                            class="status light-red"
                                        >
                                            <div
                                                class="sub-section-icon"
                                                appIcon
                                                [data]="incompleteSectionIcon"
                                            ></div>
                                        </div>
                                        <div
                                            *ngIf="
                                                section['status'] ===
                                                    Status.MARKED_FOR_REWORK ||
                                                (section['attributes']
                                                    | getAssessmentStatus
                                                        : Status.MARKED_FOR_REWORK)
                                            "
                                            class="status light-accent"
                                        >
                                            <div
                                                class="sub-section-icon"
                                                appIcon
                                                [data]="reworkSectionIcon"
                                            ></div>
                                        </div>
                                        <div
                                            *ngIf="
                                                section['status'] ===
                                                    Status.INCOMPLETE ||
                                                (section['attributes']
                                                    | getAssessmentStatus
                                                        : Status.INCOMPLETE) ||
                                                (isGoogleMsp &&
                                                    section['status'] ===
                                                        Status.NOT_EVALUATED)
                                            "
                                            class="status light-blue"
                                        >
                                            <div
                                                class="sub-section-icon"
                                                appIcon
                                                [data]="notEvaluatedIcon"
                                            ></div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab
                    [label]="Tab.ASSESSMENT_REPORT"
                    class="download-report-tab"
                >
                    <ng-template mat-tab-label>
                        <label
                            [ngStyle]="
                                !assessmentCacheService.assessmentView &&
                                this.data.response['assesseeSubmitted'] &&
                                this.data.response['assesseOverview'] &&
                                this.data.response['assessorSubmitted']
                                    ? ''
                                    : { color: 'var(--grayTextColor)' }
                            "
                            class="labelHeading clickable"
                            >{{ Tab.ASSESSMENT_REPORT }}</label
                        >
                    </ng-template>
                    <div class="download-report-section">
                        <div class="report-download-text">
                            <span class="report-box-heading"
                                >Assessment Report</span
                            >
                            <div class="report-actions-container">
                                <div
                                    class="allow-assesse"
                                    *ngIf="
                                        data.response['assesseOverview'] &&
                                        userDataCacheService.isAssessorView
                                    "
                                >
                                    <p>Allow assesee to download report</p>
                                    <app-form-generator
                                        [formGeneratorInput]="
                                            enableReportGenInput
                                        "
                                        (formGroupRef)="
                                            enableReportFormRef = $event
                                        "
                                    >
                                    </app-form-generator>
                                </div>
                                <span class="download-report-text"
                                    >Download Report</span
                                >
                                <div class="button">
                                    <app-multi-button-generator
                                        [buttonGenInputs]="buttonInputs"
                                    ></app-multi-button-generator>
                                </div>
                                <ng-container
                                    *ngIf="
                                        data.response['assesseOverview'] &&
                                        userDataCacheService.isAssessorView
                                    "
                                >
                                    <div class="save-button">
                                        <app-button-generator
                                            [buttonGenInput]="
                                                saveButtonGenInput
                                            "
                                        ></app-button-generator>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <div class="overlay" *ngIf="loader">
        <div class="overlay-content">
            <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
        </div>
    </div>
</div>
<div class="buttons">
    <ng-container
        *ngIf="
            !endAssessmentState.includes(this.data.response['status']) &&
            selectedTab !== Tab.ASSESSMENT_REPORT
        "
    >
        <app-button-generator
            *ngIf="
                !(isCSPScoring || isGoogleMsp) &&
                selectedTab === Tab.ASSESSOR_OVERVIEW
            "
            [buttonGenInput]="rejectButtonGenInput"
        ></app-button-generator>

        <!-- Bulk action section started -->
        <div
            class="action"
            [matMenuTriggerFor]="
                !enableBulkActionButton
                    ? nothing
                    : isScoringAudit && userDataCacheService.isAssessorView
                    ? BulkActionsAssessorCsp
                    : isGoogleMsp && userDataCacheService.isAssessorView
                    ? BulkActionsAssessorGoogleMsp
                    : bulkAction
            "
            (click)="$event.stopPropagation()"
        >
            <app-button-generator
                *ngIf="!assessmentCacheService.assessmentView"
                [buttonGenInput]="bulkActionButtonGenInput"
                [disabled]="!enableBulkActionButton"
            ></app-button-generator>
        </div>
        <mat-menu
            class="assessment-sub-mat-menu"
            #BulkActionsAssessorGoogleMsp="matMenu"
        >
            <button
                class="met-button"
                mat-menu-item
                (click)="bulkUpdateStatus(frameworkStatus.APPROVED)"
            >
                Submit All
            </button>
        </mat-menu>
        <mat-menu
            class="assessment-sub-mat-menu"
            #BulkActionsAssessorCsp="matMenu"
        >
            <button
                mat-menu-item="amphibians"
                (click)="bulkUpdateStatus(frameworkStatus.MARKED_FOR_REWORK)"
            >
                Open Action Item
            </button>
            <button
                class="met-button"
                mat-menu-item
                (click)="bulkUpdateStatus(frameworkStatus.APPROVED)"
            >
                Submit All
            </button>
        </mat-menu>
        <mat-menu #bulkAction="matMenu" class="assessment-mat-menu-panel">
            <div class="assessment-more-info-menu">
                <div class="heading">Bulk Action</div>
                <button
                    class="hide-submenu-trigger"
                    mat-menu-item
                    [matMenuTriggerFor]="
                        selectedTab === Tab.ASSESSE_OVERVIEW
                            ? assesseeStatus
                            : assessorStatus
                    "
                >
                    Update Status
                </button>
                <button
                    *ngIf="selectedTab === Tab.ASSESSE_OVERVIEW"
                    mat-menu-item
                    (click)="bulkUploadEvidence()"
                >
                    Upload Evidence
                </button>
            </div>
        </mat-menu>

        <mat-menu class="assessment-sub-mat-menu" #assesseeStatus="matMenu">
            <button
                mat-menu-item="amphibians"
                (click)="bulkUpdateStatus(frameworkStatus.MARKED_FOR_REVIEW)"
            >
                Mark For Review
            </button>
            <button
                mat-menu-item
                (click)="bulkUpdateStatus(frameworkStatus.READY_FOR_ASSESSMENT)"
            >
                Complete
            </button>
        </mat-menu>
        <mat-menu class="assessment-sub-mat-menu" #assessorStatus="matMenu">
            <button
                mat-menu-item
                (click)="bulkUpdateStatus(frameworkStatus.REJECTED)"
            >
                Not Met
            </button>
            <button
                mat-menu-item="amphibians"
                (click)="bulkUpdateStatus(frameworkStatus.MARKED_FOR_REWORK)"
            >
                Open Action Item
            </button>
            <button
                class="met-button"
                mat-menu-item
                [matMenuTriggerFor]="
                    assessmentCacheService.assessmentAuditType ===
                    AssessmentAuditTypes.HP
                        ? metMenu
                        : nothing
                "
                (click)="
                    assessmentCacheService.assessmentAuditType ===
                    AssessmentAuditTypes.HP
                        ? null
                        : bulkUpdateStatus(frameworkStatus.APPROVED)
                "
            >
                Met
            </button>
        </mat-menu>
        <mat-menu #metMenu="matMenu">
            <div class="metMenu">
                <app-multi-button-generator
                    [buttonGenInputs]="metButtonInputs"
                ></app-multi-button-generator>
            </div>
        </mat-menu>
        <mat-menu #nothing="matMenu"></mat-menu>
        <!-- Bulk action section end -->

        <app-button-generator
            *ngIf="
                !userDataCacheService.isAssessorView &&
                selectedTab === Tab.ASSESSE_OVERVIEW
            "
            [buttonGenInput]="submitButtonGenInput"
        ></app-button-generator>

        <app-button-generator
            *ngIf="selectedTab === Tab.ASSESSOR_OVERVIEW"
            [buttonGenInput]="approveButtonGenInput"
        ></app-button-generator>
    </ng-container>
</div>
