import { Component, OnInit } from '@angular/core';
import { RowEvent } from 'ag-grid-community';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';

@Component({
    selector: 'app-compliance-severity-info-modal',
    templateUrl: './compliance-severity-info-modal.component.html',
    styleUrls: ['./compliance-severity-info-modal.component.sass'],
})
export class ComplianceSeverityInfoModalComponent implements OnInit {
    severityCount: Object[];
    objectKeys = Object.keys;
    iconData: any;
    tableGenInput: ITableGeneratorInput = null;

    noMinHeight: boolean = false;

    constructor(private modalData: ModalInjectedData) {}

    ngOnInit(): void {
        if (this.modalData['data']) {
            this.severityCount = this.objectKeys(
                this.modalData['data']['severityCount']
            ).map((each) => {
                return {
                    complianceName: each,
                    ...this.modalData['data']['severityCount'][each],
                };
            });

            this.noMinHeight = this.severityCount.length <= 10;

            this.iconData = this.modalData['data']['iconData'];

            this.tableGenInput = {
                buttons: null,
                listExtraction: {
                    type: 'DIRECT',
                },
                selection: 'single',
                widgetIconData: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-list',
                },
                noDataMessage: 'No Data found',
                tableAutoHeight: this.noMinHeight,
                tableHeight: 440,
                columns: [
                    {
                        columnKey: 'complianceName',
                        columnName: 'Compliance Name',
                        flex: 1,
                        filter: false,
                    },
                    {
                        columnKey: '',
                        columnName: 'Ok',
                        filter: false,
                        maxWidth: 80,
                        cellRenderer: (rowData: RowEvent) => {
                            if (rowData.data['_3'] || rowData.data['_6']) {
                                return `<span>  ${
                                    rowData.data['_3']
                                        ? rowData.data['_3']
                                        : rowData.data['_6']
                                        ? rowData.data['_6']
                                        : '-'
                                } </span>`;
                            } else {
                                return `-`;
                            }
                        },
                    },
                    {
                        columnKey: '',
                        columnName: 'Warning',
                        filter: false,
                        maxWidth: 120,
                        cellRenderer: (rowData: RowEvent) => {
                            if (rowData.data['_2'] || rowData.data['_5']) {
                                return `<span> ${
                                    rowData.data['_2']
                                        ? rowData.data['_2']
                                        : rowData.data['_5']
                                        ? rowData.data['_5']
                                        : '-'
                                } </span>`;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        columnKey: '',
                        columnName: 'Critical',
                        filter: false,
                        maxWidth: 100,
                        cellRenderer: (rowData: RowEvent) => {
                            if (rowData.data['_1'] || rowData.data['_4']) {
                                return `<span> ${
                                    rowData.data['_1']
                                        ? rowData.data['_1']
                                        : rowData.data['_4']
                                        ? rowData.data['_4']
                                        : '-'
                                } </span>`;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        columnKey: '',
                        columnName: 'Whitelisted',
                        filter: false,
                        maxWidth: 142,
                        cellRenderer: (rowData: RowEvent) => {
                            if (rowData.data['whiteListed']) {
                                return `${
                                    rowData.data['whiteListed']
                                        ? rowData.data['whiteListed']
                                        : '-'
                                }`;
                            } else {
                                return '-';
                            }
                        },
                    },
                ],
            };
        }
    }
}
