<h2>Enter View Details</h2>

<div class="details-container">
    <app-form-generator
        *ngIf="formGenInput"
        [formGeneratorInput]="formGenInput"
        (formGroupRef)="formGroup = $event"
    ></app-form-generator>
    <div>
        <app-multi-button-generator
            [buttonGenInputs]="buttonGenInputs"
            [options]="buttonOptions"
        ></app-multi-button-generator>
    </div>
</div>
