import { IconType } from 'src/app/shared/enums/IconType';
import { IIcon } from '../interfaces/icon-data/IIcon';

export enum IconName {
    REFRESH = 'REFRESH',
    SPINNER = 'SPINNER',
    WARNING = 'WARNING'
}

export class Icon {
    static icons = new Map<IconName, IIcon>()
        .set(IconName.REFRESH, {
            type: IconType.SVG,
            class: 'reset'
        })
        .set(IconName.SPINNER, {
            type: IconType.SPINNERLOADER
        })
        .set(IconName.WARNING, {
            type: IconType.FONTAWSOME,
            class: 'fas fa-exclamation-triangle',
            extraClass: 'yellow-severity-color'
        });
}
