import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import {
    CheckboxField,
    TextFieldAppearance,
} from 'src/app/shared/enums/AppearanceType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';

@Component({
    selector: 'app-field-setting-time',
    templateUrl: './field-setting-time.component.html',
    styleUrls: ['./field-setting-time.component.sass'],
})
export class FieldSettingTimeComponent {
    public formGeneratorInput: IFormGeneratorInput = null;
    public formGroup: FormGroup = null;
    constructor(
        public dialogRef: MatDialogRef<FieldSettingTimeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ISignUpFieldSettingTimeType
    ) {
        this.createFormGeneratorInput();
    }

    private createFormGeneratorInput = () => {
        this.formGeneratorInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: {
                buttonName: 'Done',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                function: () => {
                    this.dialogRef.close(this.formGroup.value);
                },
            },
            fields: [
                {
                    disabled: !!this.data.isDefaultField,
                    name: 'name',
                    fieldType: FilterType.TEXT,
                    label: 'Name',
                    required: true,
                    placeholder: 'Enter here',
                    value: this.data.name || '',
                    appearance: TextFieldAppearance.TYPE_3,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Name is required',
                        },
                        {
                            validator: CustomValidators.keyAlreadyExists(
                                this.data['alreadyTakenFieldNames']
                            ),
                            errorMessage:
                                'Name is already taken by another field',
                        },
                    ],
                },
                {
                    name: 'label',
                    fieldType: FilterType.TEXT,
                    label: 'Label',
                    required: true,
                    placeholder: 'Enter here',
                    value: this.data.label || '',
                    appearance: TextFieldAppearance.TYPE_3,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Label is required',
                        },
                    ],
                },
                {
                    disabled: !!this.data.isDefaultField,
                    name: 'required',
                    value: this.data.required || false,
                    fieldType: FilterType.CHECKBOX,
                    label: 'Make field required ?',
                    placeholder: 'Make field required ?',
                    required: false,
                    appearance: CheckboxField.TYPE_3,
                },
            ],
        };
    };
}

export interface ISignUpFieldSettingTimeType {
    name: string;
    label: string;
    required: boolean;
    isDefaultField: boolean;
    alreadyTakenFieldNames: string[];
}
