import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class PasswordValidator extends CustomValidator {
    key = CustomValidatorKey.PASSWORD_VALIDATOR;
    validatorFunction(control: AbstractControl): ValidationErrors {
        const PASSWORD_REGEXP =
            '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';
        return Validators.pattern(PASSWORD_REGEXP)(control);
    }
}
