import { Component, OnInit } from '@angular/core';
import { GridOptions, RowEvent, ValueGetterParams } from 'ag-grid-community';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ActionState } from 'src/app/shared/enums/ActionState';
import { ActionVisibility } from 'src/app/shared/enums/ActionVisibility';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import { IBackendFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IBackendFormGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { CustomWhitelistingModalComponent } from '../../../custom-whitelisting-modal/custom-whitelisting-modal.component';
import { ChecksV2MoreInfoModalComponent } from '../../../modal-templates/checks-v2-more-info-modal/checks-v2-more-info-modal.component';
import { ComplianceInfoModalComponent } from '../../../modal-templates/checks-v2/compliance-info-modal/compliance-info-modal.component';
import { ComplianceSeverityInfoModalComponent } from '../../../modal-templates/checks-v2/compliance-severity-info-modal/compliance-severity-info-modal.component';
import { FormGeneratorModalComponent } from '../../../modal-templates/form-generator-modal/form-generator-modal.component';
import { RemediateDocumentationModalComponent } from '../../../modal-templates/remediate-documentation-modal/remediate-documentation-modal.component';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IColumnData } from './../../../../interfaces/table-generator/IColumnData';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';
import { AgGridCollapsibleColumnHeaderComponent } from './../../../ag-grid-collapsible-column-header/ag-grid-collapsible-column-header.component';
import { CustomizationModalComponent } from './../../../modal-templates/checks-v2/customization-modal/customization-modal.component';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-checks-v2',
    templateUrl: './checks-v2.component.html',
    styleUrls: ['./checks-v2.component.sass']
})
export class ChecksV2Component implements OnInit {
    agGrid: GridOptions = null;
    widgetRef: Widget;
    tableInput: ITableGeneratorInput = null;
    severityCount: any = {};

    criticalSeverityIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-times-circle',
        extraClass: 'red-severity-color'
    };

    warningSeverityIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-exclamation-triangle',
        extraClass: 'yellow-severity-color'
    };

    okSeverityIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-check-circle',
        extraClass: 'green-severity-color'
    };

    whiteLabelIcon: IIcon = {
        type: IconType.SVG,
        class: 'paper_board'
    };

    complianceInfoIcon: IIcon = {
        type: IconType.SVG,
        class: 'info',
        extraClass: 'svg-blue-info-fill'
    };

    apiResponse: any = null;
    compliancesColumns: IColumnData[] = null;
    selectAll = false;
    severityTypeCheck = '';
    applicableCompliances = [];
    applicableActions = [];

    controlButtons: IButtonGeneratorInput[];
    controlButtonOption: IMultiButtonOption = {
        disable: true
    };

    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService
    ) {
        this.widgetRef = widgetData.widgetRef;

        // Sending custom input to support custom compliances

        this.widgetRef.customInputCallback = () => {
            return {
                widgetId: this.widgetRef.widgetData.widgetId
            };
        };

        // Sending custom input to support report

        if (this.widgetRef.widgetData.widgetInfo.reports) {
            const reports = Object.keys(
                this.widgetRef.widgetData.widgetInfo.reports
            );

            reports.map((each) => {
                if (
                    this.widgetRef.widgetData.widgetInfo.reports[each]
                        .customInputCallback
                ) {
                    this.widgetRef.widgetData.widgetInfo.reports[
                        each
                    ].customInputCallback = () => {
                        return {
                            widgetId: this.widgetRef.widgetData.widgetId
                        };
                    };
                }
            });
        }
    }

    ngOnInit(): void {
        this.setUpBasics();
        this.checkApplicableActions();
        this.getCustomCompliance();
        this.applyTagOperation();
        this.applySettings();
    }

    checkApplicableActions() {
        const apis =
            this.widgetRef.widgetData.widgetInfo['securityCheckResponse'];

        if (apis && apis['remediation']) {
            this.applicableActions.push(Actions.FIX);
        }

        if (apis && apis['whitelisting']) {
            this.applicableActions.push(Actions.WHITELIST);
        }

        if (apis && apis['customization']) {
            this.applicableActions.push(Actions.CUSTOMIZATION);
        }
    }

    // Checking if the widget has custom compliance by calling the compliance listing api.

    getCustomCompliance() {
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['securityCheckResponse'][
                'listCustomCompliance'
            ]
        );
        apiArgs.input = {};
        apiArgs.function = (response) => {
            this.applicableCompliances = response;
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while getting compliances.'
            );
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    applyTagOperation() {
        const operationActions: IAction[] = [];
        operationActions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: null,
            function: () => {
                this.openManualRemediateDocumentationModal();
            },
            icon: {
                type: IconType.SVG,
                class: 'hand_tap',
                text: `Manual Remediation Documentation`,
                extraClass: 'inline-fix-box-1'
            }
        });

        operationActions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: null,
            function: () => {
                this.openComplianceInfoModal();
            },
            icon: {
                type: IconType.SVG,
                class: 'info',
                text: 'Custom Compliance Info',
                extraClass: 'inline-fix-box-1'
            }
        });
        this.widgetRef.operationalActions.value.set(
            'Documentation',
            operationActions
        );

        this.widgetRef.operationalActions.next(
            this.widgetRef.operationalActions.value
        );
    }

    applySettings() {
        const settingActions: IAction[] = [];

        const remediateAction = {
            state: ActionState.DISABLED,
            visibility: ActionVisibility.VISIBLE,
            message: null,
            actionId: Helper.generateUniqueKey(6),
            tooltip: {
                [ActionState.DISABLED]:
                    Messages.WIDGET_ACTION_NO_RESOURCE_SELECTED,
                [ActionState.ENABLED]: Messages.WIDGET_ACTION_BULK_REMEDIATE
            },
            function: (actionId) => {
                if (this.prepareDataForActions()) {
                    this.remediationModalHandling(
                        this.prepareDataForActions(),
                        null,
                        null,
                        actionId
                    );
                }
            },
            icon: {
                type: IconType.SVG,
                class: 'tools',
                text: BulkActions.BULK_REMEDIATE,
                extraClass: 'inline-fix-box-1'
            }
        };

        const whitelistAction = {
            state: ActionState.DISABLED,
            visibility: ActionVisibility.VISIBLE,
            tooltip: {
                [ActionState.DISABLED]:
                    Messages.WIDGET_ACTION_NO_RESOURCE_SELECTED
            },
            message: null,
            function: () => {
                if (this.checkIfCustomCompliance) {
                    if (this.prepareDataForActions()) {
                        this.openWhitelistingModal(
                            this.prepareDataForActions()
                        );
                    }
                }
            },
            icon: {
                type: IconType.SVG,
                class: 'paper_board',
                text: BulkActions.BULK_WHITELIST,
                extraClass: 'inline-fix-box-1'
            }
        };

        const customizeAction = {
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: null,
            function: () => {
                if (this.checkIfCustomCompliance) {
                    this.openCustomizeModal();
                }
            },
            icon: {
                type: IconType.SVG,
                class: 'customize',
                text: 'Customize',
                extraClass: 'inline-fix-box-1'
            }
        };

        if (this.applicableActions.includes(Actions.FIX)) {
            settingActions.push(remediateAction);
        }

        if (this.applicableActions.includes(Actions.WHITELIST)) {
            settingActions.push(whitelistAction);
        }

        if (this.applicableActions.includes(Actions.CUSTOMIZATION)) {
            settingActions.push(customizeAction);
        }

        if (settingActions.length) {
            this.widgetRef.widgetActions.next(settingActions);
        }

        this.widgetRef.showNotificationBadge.next(false);
    }

    openManualRemediateDocumentationModal() {
        const modalData: IModalData = {
            modalName: 'Manual Remediation  Documentation',
            modalIcon: null,
            modalType: ModalType.SIDE,
            sourceId: this.widgetRef.uniqueIdentity,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: RemediateDocumentationModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef
                            }
                        }
                    },
                    stepName: 'Manual Remediation  Documentation'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    setUpBasics() {
        this.tableInput = {
            afterPartialResponse: (response: any) => {
                if (
                    response &&
                    response['dataList'] &&
                    response['dataList'].length
                ) {
                    this.setUpTable(response);
                    this.setUpControlButtons();
                } else if (response && response['dataMap'] && response['dataMap']['severityCount']) {
                    this.calculateSeverityCount(response);
                }
            },
            onStreamClosed: (rowData) => {
                if (rowData && rowData.length) {
                    this.enableControlButtons();
                    const maxSeverity = this.maxSeverityCount(
                        this.severityCount['Severity']
                    );

                    if (maxSeverity) {
                        this.widgetRef.headerIcon.next({
                            type: IconType.IMAGE,
                            class: `assets/${maxSeverity}_check.png`
                        });
                    }
                }
            },
            showViewIcon: true,
            afterResponse: (response) => {
                this.setUpTable(response);
                this.setUpControlButtons();
                this.enableControlButtons();
            },
            selection: 'multiple',
            buttons: null,
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'dataList'
            },
            columns: [],
            extraComponents: {
                agColumnHeader: AgGridCollapsibleColumnHeaderComponent,
                buttonGen: MultiButtonGeneratorComponent
            },
            refreshWidgetCallback: this.widgetRefreshCallback.bind(this),
            refreshColumnAfterResponse: true,
            handleAttentionRequired: true
        };
    }

    maxSeverityCount(severityCount) {
        if (severityCount?.['_1']) {
            return 'red';
        }
        if (severityCount?.['_2']) {
            return 'yellow';
        } else return 'green';
    }

    setUpControlButtons() {
        const buttonData = {};
        this.controlButtons = [];
        let index = 0;

        if (this.severityCount && this.severityCount['Severity']) {
            const criticalButton: IButtonGeneratorInput = {
                buttonName: `Critical : ${
                    this.severityCount['Severity']['_1'] ?? 0
                }`,
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.WARN,
                hoverText: 'Critical',
                hoverEffect: 'shadow',
                function: (buttoRef: IButtonGeneratorInput) => {
                    if (
                        Helper.changeButtonType(
                            buttonData,
                            this.controlButtons,
                            'Critical',
                            this.severityFilter.bind(this, '_1')
                        )
                    ) {
                        this.severityFilter('_1');
                    }
                }
            };
            const warningButton: IButtonGeneratorInput = {
                buttonName: `Warning : ${
                    this.severityCount['Severity']['_2'] ?? 0
                }`,
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.PRIMARY,
                hoverText: 'Warning',
                hoverEffect: 'shadow',
                function: (buttoRef: IButtonGeneratorInput) => {
                    if (
                        Helper.changeButtonType(
                            buttonData,
                            this.controlButtons,
                            'Warning',
                            this.severityFilter.bind(this, '_2')
                        )
                    ) {
                        this.severityFilter('_2');
                    }
                }
            };
            const okButton: IButtonGeneratorInput = {
                buttonName: `Ok : ${this.severityCount['Severity']['_3'] ?? 0}`,
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.SUCCESS,
                hoverText: 'Ok',
                hoverEffect: 'shadow',
                function: (buttoRef: IButtonGeneratorInput) => {
                    if (
                        Helper.changeButtonType(
                            buttonData,
                            this.controlButtons,
                            'Ok',
                            this.severityFilter.bind(this, '_3')
                        )
                    ) {
                        this.severityFilter('_3');
                    }
                }
            };

            if (this.severityCount['Severity']['_3']) {
                this.controlButtons.push(okButton);
                buttonData['Ok'] = index++;
            }
            if (this.severityCount['Severity']['_2']) {
                this.controlButtons.push(warningButton);
                buttonData['Warning'] = index++;
            }
            if (this.severityCount['Severity']['_1']) {
                this.controlButtons.push(criticalButton);
                buttonData['Critical'] = index++;
            }
        }
        const complianceButton: IButtonGeneratorInput = {
            buttonName: 'Compliance',
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.INFO,
            hoverText: 'Compliance',
            hoverEffect: 'filled',
            function: (buttoRef: IButtonGeneratorInput) => {
                this.openComplianceSeverityInfoModal();
            }
        };

        if (
            this.apiResponse &&
            this.apiResponse['dataList'] &&
            this.apiResponse['dataList'].length
        ) {
            this.controlButtons.push(complianceButton);
        }
    }

    enableControlButtons() {
        this.controlButtonOption = {
            disable: false
        };
    }

    widgetRefreshCallback() {
        this.severityTypeCheck = '';
        this.apiResponse = null;
        this.controlButtons = [];
        this.severityCount = {};
        this.compliancesColumns = null;
        this.tableInput.columns = [];
        this.applicableCompliances = [];
        this.widgetRef.showNotificationBadge.next(false);
        this.getCustomCompliance();
    }

    setUpTable(response) {
        if (!this.apiResponse) {
            this.apiResponse = response;
            this.addTableColumns(response);
        } else {
            if (this.tableInput.columns.length === 0) {
                this.addTableColumns(response);
            }

            this.apiResponse['dataList'].push(...response.dataList);
        }

        this.widgetRef.apiResponse = this.apiResponse;

        this.addCompliancesColumns(response);
        this.calculateSeverityCount(response);
    }

    calculateSeverityCount(response) {
        if (
            response &&
            response['dataMap'] &&
            response['dataMap']['severityCount']
        ) {
            const compliances = Object.keys(
                response['dataMap']['severityCount']
            );

            compliances.map((each) => {
                if (each in this.severityCount) {
                    Object.keys(
                        response['dataMap']['severityCount'][each]
                    ).forEach((item) => {
                        if (item in this.severityCount[each]) {
                            this.severityCount[each][item] =
                                Number(this.severityCount[each][item]) +
                                Number(
                                    response['dataMap']['severityCount'][each][
                                        item
                                    ]['count']
                                );
                        } else {
                            this.severityCount[each][item] = {};
                            this.severityCount[each][item] = Number(
                                response['dataMap']['severityCount'][each][
                                    item
                                ]['count']
                            );
                        }
                    });
                } else {
                    this.severityCount[each] = {};
                    Object.keys(
                        response['dataMap']['severityCount'][each]
                    ).forEach((item) => {
                        this.severityCount[each][item] = Number(
                            response['dataMap']['severityCount'][each][item][
                                'count'
                            ]
                        );
                    });
                }
            });

            if (
                response &&
                response['dataMap'] &&
                response['dataMap']['whiteListCount']
            ) {
                const compliances = Object.keys(
                    response['dataMap']['whiteListCount']
                );
                compliances.map((each) => {
                    if (each in this.severityCount) {
                        if (!this.severityCount[each]['whiteListed']) {
                            this.severityCount[each]['whiteListed'] = 0;
                        }

                        this.severityCount[each]['whiteListed'] =
                            Number(this.severityCount[each]['whiteListed']) +
                            Number(response['dataMap']['whiteListCount'][each]);
                    } else {
                        this.severityCount[each]['whiteListed'] =
                            response['dataMap']['whiteListCount'][each];
                    }
                });
            }
        }
    }

    checkIfCustomCompliance() {
        if (this.applicableCompliances.length) {
            return true;
        } else {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Custom Compliance does not exist'
            );
            return false;
        }
    }

    addTableColumns(response) {
        const columns: IColumnData[] = [];
        let hasMoreInfo = false;

        response['dataMap']['keysToShow'].forEach((colObj) => {
            if (colObj['id'] !== 'compliances') {
                columns.push({
                    columnName: colObj['label'],
                    columnKey: colObj['id']
                });
            }
        });

        columns.push({
            columnKey: 'action',
            columnName: 'Action',
            pinned: 'right',
            filter: false,
            buttonGen: true,
            minWidth: 131,
            headerClass: 'grid-cell-data-centred',
            componentFramework: MultiButtonGeneratorComponent,
            valueFormatter: (rowData: RowEvent) => {
                // FIX BUTTON
                const buttons: IButtonGeneratorInput[] = [];
                const fixButton: IButtonGeneratorInput = {
                    buttonName: 'Fix   ',
                    buttonType: ButtonType.TEXT,
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: ''
                    },
                    buttonColorType: ButtonColorType.INFO,
                    showLoader: true,
                    disable: rowData.data.isFixable === false,
                    hoverText: rowData.data.isFixableReason
                        ? rowData.data.isFixableReason
                        : null,
                    function: (buttonRef: IButtonGeneratorInput) => {
                        this.remediationModalHandling(
                            [rowData.data],
                            null,
                            buttonRef
                        );
                    }
                };

                const whitelistButton: IButtonGeneratorInput = {
                    buttonName: 'Whitelist',
                    buttonType: ButtonType.TEXT,
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: ''
                    },
                    buttonColorType: ButtonColorType.WARN,
                    showLoader: true,
                    function: () => {
                        if (this.checkIfCustomCompliance) {
                            this.openWhitelistingModal([rowData.data]);
                        }
                    }
                };

                // MoreInfo Button
                const moreInfo: IButtonGeneratorInput = {
                    buttonName: 'More Info',
                    buttonType: ButtonType.TEXT,
                    buttonColorType: ButtonColorType.INFO,
                    showLoader: true,
                    function: (buttonRef: IButtonGeneratorInput) => {
                        this.openMoreInfoModal([rowData.data]);
                    }
                };

                if (this.applicableActions.includes(Actions.FIX)) {
                    buttons.push(fixButton);
                }

                if (this.applicableActions.includes(Actions.WHITELIST)) {
                    buttons.push(whitelistButton);
                }

                if (rowData && rowData['data'] && rowData['data']['moreInfo']) {
                    if (this.agGrid && this.agGrid.columnApi && !hasMoreInfo) {
                        this.agGrid.columnApi.setColumnWidth('action', 230);
                        hasMoreInfo = true;
                    }
                    buttons.push(moreInfo);
                }

                rowData['buttonGenInputs'] = buttons;
                const options: IMultiButtonOption = {
                    layout: {
                        justifyContent: 'space-evenly'
                    }
                };
                rowData['options'] = options;
                return rowData;
            }
        });
        this.tableInput.columns = [...columns];
    }

    remediationModalHandling(rowData, action, buttonRef?, actionId?) {
        if (buttonRef) {
            buttonRef.loader = true;
        } else if (actionId) {
            this.widgetRef.operationLoaders.set(actionId, true);
        }

        // Handling Fix on based of severity
        if (this.isOkSeverity(rowData)) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Select at least one resource with critical severity'
            );
            if (buttonRef) {
                buttonRef.loader = false;
            } else if (actionId) {
                this.widgetRef.operationLoaders.set(actionId, false);
            }
            return;
        }

        Helper.getRemediationDoc(
            this.widgetRef.widgetData.widgetInfo['securityCheckResponse'][
                rowData.length > 1 &&
                this.widgetRef.widgetData.widgetInfo['securityCheckResponse'][
                    'bulkRemediationDocument'
                ]
                    ? 'bulkRemediationDocument'
                    : 'remediationDocument'
            ],
            rowData,
            this.widgetRef.httpService,
            this.widgetRef.ngZone,
            this.widgetRef.notificationsService,
            this.generateRemediationForm.bind(this),
            this.widgetRef.operationLoaders,
            this.widgetRef.changeDetectorRef,
            buttonRef,
            actionId
        );
    }

    openRemediateModal(resources) {
        const hitApi: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['securityCheckResponse'][
                'remediationDocument'
            ]
        );
        hitApi.function = (response) => {
            this.generateRemediationForm(response, resources);
        };
        hitApi.config.defaultHeaders = {
            'content-type': 'application/json'
        };
        hitApi.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error getting convention list.'
            );
        };
        new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    openCustomizeModal() {
        const modalData: IModalData = {
            modalName: 'Customize',
            modalIcon: null,
            modalType: ModalType.MINI_MODAL,
            sourceId: this.widgetRef.uniqueIdentity,
            extraClass: 'security-customization-modal',
            panelClass: 'modal-with-custom-dropdown-panel',
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: CustomizationModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef
                            }
                        }
                    },
                    stepName: 'Customize'
                }
            ],
            modalAutoHeight: true
        };
        this.modalService.openModal(modalData);
    }

    generateRemediationForm(
        response: IBackendFormGeneratorInput,
        rowData: any[]
    ) {
        let initialIp: string = null;
        for (let index = 0; index < rowData.length; index++) {
            if (rowData[index]['remediateUnique']) {
                if (!initialIp) {
                    initialIp = rowData[index]['remediateUnique'];
                }
                if (initialIp !== rowData[index]['remediateUnique']) {
                    if (this.apiResponse.dataMap.remediateUniqueErrorMessage) {
                        this.widgetRef.notificationsService.showSnackBar(
                            this.apiResponse.dataMap
                                .remediateUniqueErrorMessage,
                            true
                        );
                        return;
                    }
                    return;
                }
            } else {
                break;
            }
        }

        const message =
            "This action will remediate only the resource(s) with <span class = 'red-severity-color'><b>critical</b></span> severity.";

        if (response.message) {
            response.message = message + '<br><br>' + response.message;
        } else {
            response.message = message;
        }
        let isTable = false;
        let fieldsCount: number = 0;
        if (response && response.fields && response.fields.length) {
            response.fields.forEach((field) => {
                if (field.fieldType === FilterType.TABLE) {
                    isTable = true;
                }
            });
            fieldsCount = response.fields.length;
        }
        const data = {
            resourceData: rowData
        };
        const modalId = Symbol();
        const formGenInput: IFormGeneratorInput =
            Helper.formGeneratorBtoFParser(
                response,
                rowData,
                this.widgetRef.notificationsService,
                this.widgetRef.httpService,
                this.widgetRef.changeDetectorRef,
                data,
                (response) => {
                    if (
                        response &&
                        response.dataMap &&
                        response.dataMap.jobIdStatus &&
                        response.dataMap.jobIdStatus.length
                    ) {
                        response.dataMap.jobIdStatus.every((each) => {
                            if (each.status === 'FAILURE') {
                                this.widgetRef.notificationsService.showSnackBar(
                                    Messages.SECURITY_REMEDIATE_SUCCESS
                                );

                                return false;
                            }

                            return true;
                        });
                    }

                    this.widgetRef.modalService.closeModal(null, modalId);
                    this.widgetRef.refreshWidget(true);
                },
                (formGroup) => {
                    const formValue = Helper.cloneDeep(formGroup.value);
                    const complianceName = formValue['complianceName'];

                    delete formValue['complianceName'];
                    return {
                        remediateObject: formValue,
                        complianceName: complianceName
                    };
                }
            );

        if (formGenInput && formGenInput.backButton) {
            formGenInput.backButton.function = () => {
                this.modalService.closeModal(null, modalId);
            };
        }
        formGenInput.extraClass = 'remediate-modal';
        const modalData: IModalData = {
            modalName: formGenInput.formName,
            modalIcon: null,
            sourceId: this.widgetRef.uniqueIdentity,
            modalId: modalId,
            modalType: ModalType.MIDDLE,
            modalHeightVh: isTable ? 65 : 50,
            modalAutoHeight: fieldsCount <= 5 && !isTable ? true : false,
            modalWidthVw: isTable ? 50 : 45,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: FormGeneratorModalComponent,
                        payload: {
                            data: formGenInput
                        }
                    },
                    stepName: ''
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    openWhitelistingModal(rowData) {
        const alreadyWhitelisted = [];
        if (rowData && rowData.length === 1) {
            rowData.map((each) => {
                this.applicableCompliances.forEach((eachCompliance) => {
                    const COMPLIANCE_KEY =
                        CompliancesKey.compliances in each
                            ? CompliancesKey.compliances
                            : CompliancesKey.Compliances;
                    if (
                        each &&
                        eachCompliance &&
                        each[COMPLIANCE_KEY] &&
                        each[COMPLIANCE_KEY][eachCompliance.value]
                    ) {
                        if (
                            each[COMPLIANCE_KEY][eachCompliance.value]
                                .insightWhiteListed ||
                            each[COMPLIANCE_KEY][eachCompliance.value]
                                .globalWhiteListed
                        ) {
                            alreadyWhitelisted.push({
                                id: eachCompliance.id,
                                insightWhiteListed: each[COMPLIANCE_KEY][
                                    eachCompliance.value
                                ].insightWhiteListed
                                    ? each[COMPLIANCE_KEY][eachCompliance.value]
                                          .insightWhiteListed
                                    : false,
                                globalWhiteListed: each[COMPLIANCE_KEY][
                                    eachCompliance.value
                                ].globalWhiteListed
                                    ? each[COMPLIANCE_KEY][eachCompliance.value]
                                          .globalWhiteListed
                                    : false
                            });
                        }
                    }
                });
            });
        }

        const modalData: IModalData = {
            modalName: 'Whitelisting',
            modalIcon: null,
            modalType: ModalType.MINI_MODAL,
            sourceId: this.widgetRef.uniqueIdentity,
            modalAutoHeight: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: CustomWhitelistingModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                rowData,
                                function: this.hitWhiteListApi.bind(this),
                                alreadyWhitelisted
                            }
                        }
                    },
                    stepName: 'Whitelisting'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    hitWhiteListApi(compliances, selectedRowData, modalId) {
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['securityCheckResponse'][
                'whitelisting'
            ]
        );
        apiArgs.function = () => {
            this.widgetRef.notificationsService.showSnackBar(
                Messages.WHITELIST_SUCCESS
            );
            this.modalService.closeModal(null, modalId);
            this.widgetRef.refreshWidget(true);
        };

        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                Messages.WHITELIST_ERROR
            );
            this.modalService.closeModal(null, modalId);
        };
        apiArgs.input = {
            widgetId: this.widgetRef.widgetData.widgetId,
            complianceIdList: compliances['customCompliance'],
            identifier: selectedRowData.map(
                (each) => each[this.apiResponse['dataMap']['identifier']]
            )
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    addCompliancesColumns(response) {
        const compliancesColumns: IColumnData[] = [];
        if (response['dataList'].length) {
            if (!this.compliancesColumns) {
                const compliances = Object.keys(
                    response['dataList'][0][
                        CompliancesKey.compliances in response['dataList'][0]
                            ? CompliancesKey.compliances
                            : CompliancesKey.Compliances
                    ]
                );
                if (compliances.length) {
                    const collapsibleColumns = compliances;
                    collapsibleColumns.splice(
                        compliances.indexOf('Severity'),
                        1
                    );
                    const firstCompliance = compliances[0];
                    collapsibleColumns.splice(
                        compliances.indexOf(firstCompliance),
                        1
                    );

                    compliancesColumns.push({
                        columnName: firstCompliance,
                        columnKey: firstCompliance,
                        columnVisibility: true,
                        cellRenderer: this.columnRenderer.bind(
                            this,
                            firstCompliance
                        ),
                        columnValueGetter: this.columnValueGetter.bind(
                            this,
                            firstCompliance
                        ),
                        headerComponentFramework: 'agColumnHeader',
                        headerComponentParams: {
                            collapseIconShow:
                                collapsibleColumns.length >= 1 ? true : false,
                            collapsibleColumns
                        },
                        cellStyle: (params) => {
                            if (
                                params.data[
                                    CompliancesKey.compliances in params.data
                                        ? CompliancesKey.compliances
                                        : CompliancesKey.Compliances
                                ][firstCompliance].insightWhiteListed ||
                                params.data[
                                    CompliancesKey.compliances in params.data
                                        ? CompliancesKey.compliances
                                        : CompliancesKey.Compliances
                                ][firstCompliance].globalWhiteListed
                            ) {
                                return {
                                    'background-color':
                                        params.data[
                                            CompliancesKey.compliances in
                                            params.data
                                                ? CompliancesKey.compliances
                                                : CompliancesKey.Compliances
                                        ][firstCompliance].colorMapping
                                };
                            }
                        },
                        headerClass: 'collapse-header-border',
                        cellClass: 'collapse-cell-border whitelisted'
                    });

                    Object.keys(
                        response['dataList'][0][
                            CompliancesKey.compliances in
                            response['dataList'][0]
                                ? CompliancesKey.compliances
                                : CompliancesKey.Compliances
                        ]
                    ).map((each) => {
                        if (each === 'Severity') {
                            compliancesColumns.push({
                                columnName: 'Severity',
                                columnKey: 'Severity',
                                columnVisibility: true,
                                pinned: 'left',
                                lockPinned: true,
                                headerClass: 'collapse-header-border',
                                cellClass: 'collapse-cell-border',
                                cellRenderer: this.columnRenderer.bind(
                                    this,
                                    each
                                ),
                                columnValueGetter: this.columnValueGetter.bind(
                                    this,
                                    each
                                )
                            });
                        } else {
                            if (each !== firstCompliance) {
                                compliancesColumns.push({
                                    columnName: each,
                                    columnKey: each,
                                    columnVisibility: false,
                                    cellRenderer: this.columnRenderer.bind(
                                        this,
                                        each
                                    ),
                                    columnValueGetter:
                                        this.columnValueGetter.bind(this, each),
                                    cellStyle: (params) => {
                                        if (
                                            params.data[
                                                CompliancesKey.compliances in
                                                params.data
                                                    ? CompliancesKey.compliances
                                                    : CompliancesKey.Compliances
                                            ][each].insightWhiteListed ||
                                            params.data[
                                                CompliancesKey.compliances in
                                                params.data
                                                    ? CompliancesKey.compliances
                                                    : CompliancesKey.Compliances
                                            ][each].globalWhiteListed
                                        ) {
                                            return {
                                                'background-color':
                                                    params.data[
                                                        CompliancesKey.compliances in
                                                        params.data
                                                            ? CompliancesKey.compliances
                                                            : CompliancesKey.Compliances
                                                    ][each].colorMapping
                                            };
                                        }
                                    },
                                    headerClass: 'collapse-header-border',
                                    cellClass:
                                        'collapse-cell-border whitelisted'
                                });
                            }
                        }
                    });

                    compliancesColumns.sort((x, y) => {
                        return x.columnName === 'Severity'
                            ? -1
                            : y.columnName === 'Severity'
                            ? 1
                            : 0;
                    });
                    this.compliancesColumns = compliancesColumns;

                    this.tableInput.columns.unshift(...compliancesColumns);
                    this.tableInput.columns = [...this.tableInput.columns];
                }
            }
        }
    }

    columnRenderer(complianceName, rowData: RowEvent) {
        if (
            rowData['data'] &&
            rowData['data'][
                CompliancesKey.compliances in rowData['data']
                    ? CompliancesKey.compliances
                    : CompliancesKey.Compliances
            ] &&
            rowData['data'][
                CompliancesKey.compliances in rowData['data']
                    ? CompliancesKey.compliances
                    : CompliancesKey.Compliances
            ][complianceName]
        ) {
            const severity =
                rowData['data'][
                    CompliancesKey.compliances in rowData['data']
                        ? CompliancesKey.compliances
                        : CompliancesKey.Compliances
                ][complianceName]['level'];

            if (severity === '_1' || severity === '_4') {
                return `<span class="red-severity-color" style="font-weight: bold; font-size: var(--fs200);">CRITICAL</span>`;
            } else if (severity === '_2' || severity === '_5') {
                return `<span class="yellow-severity-color" style="font-weight: bold; font-size: var(--fs200);">WARNING</span>`;
            } else {
                return `<span class="green-severity-color" style="font-weight: bold; font-size: var(--fs200);">OK</span>`;
            }
        }
    }

    columnValueGetter(complianceName, params: ValueGetterParams) {
        if (
            params['data'] &&
            params['data'][
                CompliancesKey.compliances in params['data']
                    ? CompliancesKey.compliances
                    : CompliancesKey.Compliances
            ] &&
            params['data'][
                CompliancesKey.compliances in params['data']
                    ? CompliancesKey.compliances
                    : CompliancesKey.Compliances
            ][complianceName]
        ) {
            const severity =
                params['data'][
                    CompliancesKey.compliances in params['data']
                        ? CompliancesKey.compliances
                        : CompliancesKey.Compliances
                ][complianceName]['level'];

            if (severity === '_1' || severity === '_4') {
                return `CRITICAL`;
            } else if (severity === '_2' || severity === '_5') {
                return `WARNING`;
            } else {
                return `OK`;
            }
        }
    }

    severityFilter(severityType) {
        if (this.severityTypeCheck === severityType) {
            this.severityTypeCheck = '';
            this.agGrid.api.setRowData(this.apiResponse['dataList']);
            this.agGrid.api.refreshCells();
            return;
        }

        const filteredSearch = this.apiResponse['dataList'].filter((item) => {
            if (
                item[
                    CompliancesKey.compliances in item
                        ? CompliancesKey.compliances
                        : CompliancesKey.Compliances
                ]['Severity']['level'] === severityType
            ) {
                return item;
            }
        });

        this.agGrid.api.setRowData(filteredSearch);
        this.agGrid.api.refreshCells();
        this.severityTypeCheck = severityType;
        this.widgetRef.showNotificationBadge.next(false);
    }

    openComplianceInfoModal() {
        const modalData: IModalData = {
            modalName: 'Custom Compliance Info',
            modalIcon: null,
            modalType: ModalType.SIDE,
            sourceId: this.widgetRef.uniqueIdentity,
            modalWidthVw: 60,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ComplianceInfoModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef
                            }
                        }
                    },
                    stepName: 'Custom Compliance Info'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    openComplianceSeverityInfoModal() {
        if (this.widgetRef.loadingPartialData.value) {
            return;
        }

        const { Severity, ...restData } = this.severityCount;
        const modalInputData = {
            iconData: {
                ok: this.okSeverityIcon,
                warning: this.warningSeverityIcon,
                critical: this.criticalSeverityIcon,
                whitelisted: this.whiteLabelIcon
            },
            severityCount: restData
        };

        const modalData: IModalData = {
            modalName: 'Compliance Severity Information',
            modalIcon: null,
            modalType: ModalType.MINI_MODAL,
            sourceId: this.widgetRef.uniqueIdentity,
            modalAutoHeight: true,
            modalWidthVw: 55,
            noStepPadding: true,
            extraClass: 'compliance-severity-info-modal',
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ComplianceSeverityInfoModalComponent,
                        payload: {
                            data: modalInputData
                        }
                    },
                    stepName: 'Compliance Severity Information'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    onSelectionChanged() {
        if (this.agGrid) {
            if (
                this.agGrid.api.getSelectedNodes().length >
                GlobalConfiguration.CHECKS_SELECTION_LIMIT_V2
            ) {
                // Select All case
                this.selectAll = !this.selectAll;
                if (this.selectAll) {
                    this.agGrid.api.deselectAll();
                    this.agGrid.api.forEachNode((node) =>
                        node.rowIndex >=
                        GlobalConfiguration.CHECKS_SELECTION_LIMIT_V2
                            ? 0
                            : node.setSelected(true)
                    );
                } else {
                    this.agGrid.api.deselectAll();
                }
                this.agGrid.api.redrawRows();
            } else if (
                this.agGrid.api.getSelectedNodes().length ===
                GlobalConfiguration.CHECKS_SELECTION_LIMIT_V2
            ) {
                this.widgetRef.notificationsService.showSnackBar(
                    `Maximum ${GlobalConfiguration.CHECKS_SELECTION_LIMIT_V2} rows allowed`
                );
                this.agGrid.api.redrawRows();
            } else {
                this.selectAll = false;
            }

            if (this.agGrid.api.getSelectedNodes().length) {
                this.widgetRef.widgetActions.next(
                    this.widgetRef.widgetActions.value.map((each) => {
                        if (
                            each.icon.text === BulkActions.BULK_REMEDIATE ||
                            each.icon.text === BulkActions.BULK_WHITELIST
                        ) {
                            each.state = ActionState.ENABLED;
                        }
                        return each;
                    })
                );
                this.widgetRef.showNotificationBadge.next(true);
            } else {
                this.widgetRef.widgetActions.next(
                    this.widgetRef.widgetActions.value.map((each) => {
                        if (
                            each.icon.text === BulkActions.BULK_REMEDIATE ||
                            each.icon.text === BulkActions.BULK_WHITELIST
                        ) {
                            each.state = ActionState.DISABLED;
                        }
                        return each;
                    })
                );
                this.widgetRef.showNotificationBadge.next(false);
            }
        }
    }

    prepareDataForActions() {
        if (this.agGrid.api.getSelectedNodes().length === 0) {
            this.widgetRef.notificationsService.showSnackBar(
                'Select atleast one resource to perform any action.',
                true
            );
            return false;
        }
        if (
            this.agGrid.api.getSelectedNodes().length >
            GlobalConfiguration.CHECKS_SELECTION_LIMIT_V2
        ) {
            this.widgetRef.notificationsService.showSnackBar(
                `You Can Select Maximum ${GlobalConfiguration.CHECKS_SELECTION_LIMIT_V2} Resources`,
                true
            );
            return false;
        }
        const selectedRows = [];

        this.agGrid.api.getSelectedNodes().forEach((item) => {
            selectedRows.push(item.data);
        });

        return selectedRows;
    }

    openMoreInfoModal(data) {
        const modalConfig = data[0]['moreInfo']['modalConfig'];
        const modalData: IModalData = {
            modalName: modalConfig.modalName,
            modalIcon: modalConfig.modalIcon,
            noHeader: true,
            modalType: modalConfig.modalType,
            sourceId: this.widgetRef.uniqueIdentity,
            noStepPadding: true,
            modalSteps: [
                {
                    stepName: 'More Info',
                    stepData: {
                        componentToLoad: ChecksV2MoreInfoModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                itemData: data
                            }
                        }
                    }
                }
            ],
            modalWidthVw: modalConfig.modalWidthVw
        };
        this.modalService.openModal(modalData);
    }

    /**
     * @param rowData Takes in the number of accounts selected
     * @returns True if all the selected accounts have 'OK' severity else returns False
     */
    isOkSeverity(rowData): boolean {
        let isSeverityOk: boolean = true;
        for (let i = 0; i < rowData.length; i++) {
            const dataSeverity =
                rowData[i][
                    rowData[i][CompliancesKey.compliances]
                        ? CompliancesKey.compliances
                        : CompliancesKey.Compliances
                ]['Severity']['level'];
            if (dataSeverity !== '_3' && dataSeverity !== '_6'
                && dataSeverity !== '_2' && dataSeverity !== '_5') {
                isSeverityOk = false;
                break;
            }
        }
        return isSeverityOk;
    }
}

export enum BulkActions {
    BULK_REMEDIATE = 'Bulk Remediate',
    BULK_WHITELIST = 'Bulk Whitelist'
}

enum Actions {
    FIX = 'Fix',
    WHITELIST = 'Whitelist',
    CUSTOMIZATION = 'Customization',
    MORE_INFO = 'MoreInfo'
}
enum CompliancesKey {
    Compliances = 'Compliances',
    compliances = 'compliances'
}
