<div class="bulkEditContainer">
    <app-only-table-generator
        *ngIf="tableGenInputs"
        [tableData]="tableData"
        [tableInput]="tableGenInputs"
        (gridRef)="gridRef = $event"
    ></app-only-table-generator>
    <div class="formFieldContainer">
        <div *ngIf="thresholdFormGenInputs" class="form box">
            <div *ngIf="thresholdFormGenInputs" class="section-heading">
                <div class="title">Threshold Details:</div>
                <div class="actions">
                    <div
                        *ngIf="
                            thresholdFormGenInputs.state === FormState.IDLE;
                            then edit;
                            else idle
                        "
                    ></div>
                    <ng-template #edit>
                        <i
                            (click)="changeState(FormState.EDIT)"
                            class="fas fa-edit"
                        >
                        </i>
                    </ng-template>
                    <ng-template #idle>
                        <i
                            (click)="changeState(FormState.IDLE)"
                            class="fas fa-times"
                        ></i>
                    </ng-template>
                </div>
            </div>
            <app-form-generator
                *ngIf="thresholdFormGenInputs"
                [formGeneratorInput]="thresholdFormGenInputs"
                (formGroupRef)="thresholdFormGroup = $event"
            >
            </app-form-generator>
        </div>
        <div *ngIf="thresholdFormGenInputs" class="form box">
            <div *ngIf="thresholdFormGenInputs" class="section-heading">
                <div class="title">Escalation metrics:</div>
            </div>
            <app-form-generator
                *ngIf="metricsFormGenInputs"
                [formGeneratorInput]="metricsFormGenInputs"
                (formGroupRef)="metricsFormGroup = $event"
                (valuesChange)="hitAlertEmailsApis()"
            >
            </app-form-generator>
            <app-form-generator
                *ngIf="emailFormGenInputs"
                [formGeneratorInput]="emailFormGenInputs"
                (formGroupRef)="emailFormGroup = $event"
            >
            </app-form-generator>
        </div>

        <div class="overlay" *ngIf="!thresholdFormGenInputs">
            <div class="overlay-content">
                <div
                    appIcon
                    [data]="spinnerLoader"
                    class="overlay-loader"
                ></div>
            </div>
        </div>
    </div>
</div>

<div class="btn">
    <app-button-generator
        *ngIf="saveButton"
        [buttonGenInput]="saveButton"
    ></app-button-generator>
</div>
