<div class="overlay" *ngIf="loader">
    <div class="overlay-content">
        <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
    </div>
</div>

<div class="form-container tw-overflow-y-auto">
    <app-form-generator
        *ngIf="tagConfigFirstFormGenInput"
        [formGeneratorInput]="tagConfigFirstFormGenInput"
        (formGroupRef)="tagConfigFormGroup=$event"
    ></app-form-generator>
</div>

<app-button-generator
    class="tw-ml-auto tw-mr-5 tw-mb-2"
    [buttonGenInput]="buttonInput"
>
</app-button-generator>
