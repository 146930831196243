import {
    Component,
    Injector,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { SubWidgetInjectedData } from 'src/app/shared/classes/SubWidgetInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalAction } from 'src/app/shared/enums/ModalAction';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { WhitelabelingColorPalette } from 'src/app/shared/enums/WhitelabelingColorPalette';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { DefaultValues } from './../../../../classes/DefaultValues';
import { Helper } from './../../../../classes/Helper';
import { HitApi } from './../../../../classes/HitApi';
import { IframeOpenerModalComponent } from './../../../modal-templates/iframe-opener-modal/iframe-opener-modal.component';
import { WhitelabelingDomainComponent } from './whitelabeling-domain/whitelabeling-domain.component';
import { WhitelabelingEmailComponent } from './whitelabeling-email/whitelabeling-email.component';
import { WhitelabelingProprietaryComponent } from './whitelabeling-proprietary/whitelabeling-proprietary.component';
import { WhitelabelingSocialComponent } from './whitelabeling-social/whitelabeling-social.component';
import { WhitelabelingThemeComponent } from './whitelabeling-theme/whitelabeling-theme.component';
import { WhitelebalingPrivacyPolicyComponent } from './whitelebaling-privacy-policy/whitelebaling-privacy-policy.component';
import { WhitelebelingTermsOfServicesComponent } from './whitelebeling-terms-of-services/whitelebeling-terms-of-services.component';

@Component({
    selector: 'app-whitelabeling',
    templateUrl: './whitelabeling.component.html',
    styleUrls: ['./whitelabeling.component.sass']
})
export class WhitelabelingComponent implements OnInit, OnDestroy {
    @ViewChild('deleteModal') deleteModal;
    @ViewChild('publishModal') publishModal;
    widgetRef: Widget;
    isLoaded = false;
    whitelabelData: any;
    whiteLabelDraft: any;

    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    actionButtons: IButtonGeneratorInput[];

    resetButton: IButtonGeneratorInput = {
        buttonName: 'Reset',
        buttonColorType: ButtonColorType.SECONDARY,
        buttonType: ButtonType.STROKED,
        hoverText: 'Reset',
        preventHoverEffect: true,
        buttonIcon: {
            type: IconType.SVG,
            class: 'circle_reset',
            extraClass: 'reset-button-icon'
        },
        customClass: 'reset-button',
        function: null
    };

    subWidgetCollapseMap: Map<string, boolean>;
    subWidgetList: {
        id: string;
        name: string;
        component: Function;
        subWidgetInjector?: Injector;
        isHideResetButton?: boolean;
    }[];

    // TRUE when whitelabeled
    isDomainRegistered: boolean = false;

    // TRUE when saved once
    isDomainSaved: boolean = false;

    resetObservable = new Subject<string>();

    // variable to store the subscription in order to unsubscribe on destroy
    // below variable stores widgetRef.widgetContentVisible subscription
    contentVisibleSubscription: Subscription;

    proprietary: Map<string, boolean> = new Map<string, boolean>();

    formGroupMap: Map<string, FormGroup> = new Map();

    constructor(
        widgetData: WidgetInjectedData,
        private injector: Injector,
        public globalDataService: GlobalDataService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
        this.widgetRef.refreshWidgetCallback = () => {
            this.isLoaded = false;
            this.whitelabelData = null;
        };
    }

    initActionButtons() {
        this.actionButtons = [
            {
                buttonName: 'Delete',
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.WARN,
                showLoader: true,
                disable:
                    GlobalConfiguration.PREVIEW_MODE || !this.isDomainRegistered
                        ? true
                        : false,
                hoverText: GlobalConfiguration.PREVIEW_MODE
                    ? 'Cannot perform this action in preview mode'
                    : null,
                function: (buttonRef) => {
                    this.deleteConfiguration();
                }
            },
            {
                buttonName: 'Publish',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                showLoader: true,
                disable:
                    GlobalConfiguration.PREVIEW_MODE || !this.isDomainSaved
                        ? true
                        : false,
                hoverText: GlobalConfiguration.PREVIEW_MODE
                    ? 'Cannot perform this action in preview mode'
                    : null,
                function: (buttonRef) => {
                    if (this.formGroupMap && this.formGroupMap.has('domain')) {
                        const domainFormGroup = this.formGroupMap.get('domain');
                        if (domainFormGroup) {
                            Helper.markAllFieldAsTouched(domainFormGroup);
                            if (domainFormGroup.invalid) {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'Please provide the mandatory documents to publish',
                                    true
                                );
                                return;
                            }
                        }
                    }
                    let isProprietaryConfiured = true;
                    this.proprietary.forEach((value) => {
                        if (!value) {
                            isProprietaryConfiured = false;
                        }
                    });
                    if (!isProprietaryConfiured) {
                        this.publishConfigurationModal(buttonRef);
                    } else {
                        this.publishConfiguration(buttonRef);
                    }
                }
            }
        ];
    }

    initSubWidgetList() {
        const domainSubWidgetId = 'domain';
        const proprietarySubWidgetId = 'proprietiary';
        const themeSubWidgetId = 'theme';
        const socialSubWidgetId = 'social';
        const emailSubWidgetId = 'email';
        const privacySubWidgetId = 'privacy';
        const termsSubWidgetId = 'terms';
        this.subWidgetList = [
            {
                id: domainSubWidgetId,
                name: 'Configure Domain',
                component: WhitelabelingDomainComponent,
                ...this.getResetInjectorForId(domainSubWidgetId)
            },
            {
                id: proprietarySubWidgetId,
                name: 'Configure Icons and Logos',
                component: WhitelabelingProprietaryComponent,
                ...this.getResetInjectorForId(proprietarySubWidgetId)
            },
            {
                id: themeSubWidgetId,
                name: 'Configure Theme',
                component: WhitelabelingThemeComponent,
                ...this.getResetInjectorForId(themeSubWidgetId)
            },
            {
                id: socialSubWidgetId,
                name: 'Configure Social Channels',
                component: WhitelabelingSocialComponent,
                ...this.getResetInjectorForId(socialSubWidgetId)
            },
            {
                id: emailSubWidgetId,
                name: 'Configure Email Content',
                component: WhitelabelingEmailComponent,
                ...this.getResetInjectorForId(emailSubWidgetId)
            },
            {
                id: privacySubWidgetId,
                name: 'Privacy Policy',
                isHideResetButton: true,
                component: WhitelebalingPrivacyPolicyComponent,
                ...this.getResetInjectorForId(privacySubWidgetId)
            },
            {
                id: termsSubWidgetId,
                name: 'Terms of Services',
                isHideResetButton: true,
                component: WhitelebelingTermsOfServicesComponent,
                ...this.getResetInjectorForId(termsSubWidgetId)
            }
        ];
        this.subWidgetCollapseMap = new Map<string, boolean>();
        this.subWidgetCollapseMap.set(
            domainSubWidgetId,
            !this.widgetRef.widgetContentVisible.value
        );
        this.subWidgetCollapseMap.set(
            proprietarySubWidgetId,
            !this.widgetRef.widgetContentVisible.value
        );
        this.subWidgetCollapseMap.set(
            themeSubWidgetId,
            !this.widgetRef.widgetContentVisible.value
        );
        this.subWidgetCollapseMap.set(
            socialSubWidgetId,
            !this.widgetRef.widgetContentVisible.value
        );
        this.subWidgetCollapseMap.set(
            emailSubWidgetId,
            !this.widgetRef.widgetContentVisible.value
        );
        this.subWidgetCollapseMap.set(
            privacySubWidgetId,
            !this.widgetRef.widgetContentVisible.value
        );
        this.subWidgetCollapseMap.set(
            termsSubWidgetId,
            !this.widgetRef.widgetContentVisible.value
        );
    }

    initCollapseActions() {
        this.contentVisibleSubscription =
            this.widgetRef.widgetContentVisible.subscribe((visiblity) => {
                this.subWidgetCollapseMap.forEach((value, key) => {
                    this.subWidgetCollapseMap.set(key, !visiblity);
                });
            });
    }

    getResetInjectorForId(widgetId): {
        subWidgetInjector: Injector;
    } {
        const subWidgetInjector = Injector.create({
            providers: [
                {
                    provide: SubWidgetInjectedData,
                    useValue: {
                        resetObserver: this.resetObservable,
                        subWidgetId: widgetId,
                        parentWidgetRef: this.widgetRef,
                        data: {
                            isDomainRegistered: this.isDomainRegistered,
                            isDomainSaved: this.isDomainSaved,
                            whiteLabelingData: this.whitelabelData,
                            whiteLabelingDraft: this.whiteLabelDraft,
                            saveAsDraftApiInfo:
                                this.widgetRef.widgetData.widgetInfo[
                                    'additionalApisForWidget'
                                ] &&
                                this.widgetRef.widgetData.widgetInfo[
                                    'additionalApisForWidget'
                                ]['saveAsDraft']
                                    ? this.widgetRef.widgetData.widgetInfo[
                                          'additionalApisForWidget'
                                      ]['saveAsDraft']
                                    : null,
                            previewWhitelabelModalData:
                                this.previewWhitelabelModalData.bind(this),
                            reInitializeWhiteLabelData:
                                this.initializeWhiteLabelData.bind(this),
                            onFormGroupCreated:
                                this.onFormGroupCreated.bind(this)
                        }
                    }
                }
            ],
            parent: this.injector
        });
        return {
            subWidgetInjector
        };
    }

    onFormGroupCreated(name: string, formGroup: FormGroup) {
        if (this.formGroupMap) {
            this.formGroupMap.set(name, formGroup);
        }
    }

    resetSubWidgetForm(widgetId) {
        this.resetObservable.next(widgetId);
    }

    onToggleCollapse(widgetId) {
        if (this.subWidgetCollapseMap.has(widgetId)) {
            this.subWidgetCollapseMap.set(
                widgetId,
                !this.subWidgetCollapseMap.get(widgetId)
            );
        }
    }

    bindData(response) {
        this.widgetRef.endLoader();
        this.isLoaded = true;
        this.whitelabelData = response;
        this.initializeWhiteLabelData(true);
        this.initCollapseActions();
    }

    /**
     * Initializes sub widgets, button inputs, image state, draft and injectors.
     *
     * This function is called from:
     * 1. bindData from this component.
     * 2. Via 5 sub component/widgets whenever save as draft is successfully called.
     *
     * If called with parameter as true, it initialize subwidgets, injector and draft also
     * which is only required when called via bindData.
     *
     * Else they are not initialized.
     *
     * @param initSubWidgetList when true initializes draft, subwidgets and it's injector
     */
    initializeWhiteLabelData(initSubWidgetList?: boolean) {
        this.isDomainRegistered = this.whitelabelData['whitelabel'];
        this.isDomainSaved = this.whitelabelData['domainName'] ? true : false;
        this.initActionButtons();
        if (initSubWidgetList) {
            this.prepareDraft();
            this.initSubWidgetList();
        }
        this.getImagesState();
        this.widgetRef.changeDetectorRef.detectChanges();
    }

    getImagesState() {
        if (this.whitelabelData) {
            const imageMap = {
                miniLogo: 'Favicon',
                lightLogo: 'Light Theme Logo',
                darkLogo: 'Dark Theme Logo',
                loginImage: 'Login Image'
            };

            Object.keys(imageMap).forEach((key) => {
                if (
                    this.whitelabelData.orgProprietary &&
                    this.whitelabelData.orgProprietary[key]
                ) {
                    Helper.checkImageSrcExistance(
                        this.whitelabelData.orgProprietary[key],
                        () => {
                            this.proprietary.set(imageMap[key], true);
                        },
                        () => this.proprietary.set(imageMap[key], false)
                    );
                } else {
                    this.proprietary.set(imageMap[key], false);
                }
            });
        }
    }

    prepareDraft() {
        this.whiteLabelDraft = {
            whitelabelPublished: this.isDomainRegistered,
            editWhiteLabelConfig: this.isDomainRegistered,
            domainName: null,
            certificateBody: null,
            privateKey: null,
            certificateChain: null,
            orgProprietary: {
                email: null
            },
            themeConfig: {
                //Console theme
                '--primaryColor': Helper.convertToHexIfHsl(
                    Helper.getCssVarValue(WhitelabelingColorPalette.PRIMARY)
                ),
                '--accentColor': Helper.convertToHexIfHsl(
                    Helper.getCssVarValue(WhitelabelingColorPalette.ACCENT)
                ),
                '--defaultTextColor': Helper.convertToHexIfHsl(
                    Helper.getCssVarValue(
                        WhitelabelingColorPalette.DEFAULT_TEXT_COLOR
                    )
                ),
                '--primaryButtonColor': Helper.convertToHexIfHsl(
                    Helper.getCssVarValue(
                        WhitelabelingColorPalette.PRIMARY_BUTTON_COLOR
                    )
                ),
                '--secondaryButtonColor': Helper.convertToHexIfHsl(
                    Helper.getCssVarValue(
                        WhitelabelingColorPalette.SECONDARY_BUTTON_COLOR
                    )
                ),
                '--backgroundColor': Helper.convertToHexIfHsl(
                    Helper.getCssVarValue(
                        WhitelabelingColorPalette.BACKGROUND_COLOR
                    )
                ),
                // Widget and Aggregate theme
                '--widgetColor': Helper.convertToHexIfHsl(
                    Helper.getCssVarValue(
                        WhitelabelingColorPalette.WIDGET_COLOR
                    )
                ),
                '--widgetTextColor': Helper.convertToHexIfHsl(
                    Helper.getCssVarValue(
                        WhitelabelingColorPalette.WIDGET_TEXT_COLOR
                    )
                ),
                '--aggregatesDefaultColor': Helper.convertToHexIfHsl(
                    Helper.getCssVarValue(
                        WhitelabelingColorPalette.AGGREGATE_DEFAULT_COLOR
                    )
                ),
                '--aggregatesDefaultTextColor': Helper.convertToHexIfHsl(
                    Helper.getCssVarValue(
                        WhitelabelingColorPalette.AGGREGATE_DEFAULT_TEXT_COLOR
                    )
                ),
                '--menuPrimaryColor': Helper.convertToHexIfHsl(
                    Helper.getCssVarValue(
                        WhitelabelingColorPalette.MENU_PRIMARY_COLOR
                    )
                ),
                // Side menu themes
                '--menuAccentColor': Helper.convertToHexIfHsl(
                    Helper.getCssVarValue(
                        WhitelabelingColorPalette.MENU_ACCENT_COLOR
                    )
                ),
                '--menuTextColor': Helper.convertToHexIfHsl(
                    Helper.getCssVarValue(
                        WhitelabelingColorPalette.MENU_TEXT_COLOR
                    )
                ),
                '--menuHoverColor': Helper.convertToHexIfHsl(
                    Helper.getCssVarValue(
                        WhitelabelingColorPalette.MENU_HOVER_COLOR
                    )
                ),
                //Header and Footer theme
                '--headerColor': Helper.convertToHexIfHsl(
                    Helper.getCssVarValue(
                        WhitelabelingColorPalette.HEADER_COLOR
                    )
                ),
                '--footerColor': Helper.convertToHexIfHsl(
                    Helper.getCssVarValue(
                        WhitelabelingColorPalette.FOOTER_COLOR
                    )
                ),
                '--footerTextColor': Helper.convertToHexIfHsl(
                    Helper.getCssVarValue(
                        WhitelabelingColorPalette.FOOTER_TEXT_COLOR
                    )
                ),

                //Action color
                '--negativeColor': Helper.convertToHexIfHsl(
                    Helper.getCssVarValue(
                        WhitelabelingColorPalette.NEGATIVE_COLOR
                    )
                ),
                '--positiveColor': Helper.convertToHexIfHsl(
                    Helper.getCssVarValue(
                        WhitelabelingColorPalette.POSITEVE_COLOR
                    )
                ),
                '--neutralColor': Helper.convertToHexIfHsl(
                    Helper.getCssVarValue(
                        WhitelabelingColorPalette.NEUTRAL_COLOR
                    )
                ),
            },
            socialMediaUrl: {
                organizationalUrl: null,
                knowledgeBaseUrl: null,
                facebookUrl: null,
                linkedinUrl: null,
                twitterUrl: null,
            },
            emailConfig: {
                user: {
                    subject: this.isDomainSaved
                        ? DefaultValues.EMAIL_DATA.NEW_USER_EMAIL.SUBJECT
                        : null,
                    content: this.isDomainSaved
                        ? DefaultValues.EMAIL_DATA.NEW_USER_EMAIL.CONTENT
                        : null,
                },
                customer: {
                    subject: this.isDomainSaved
                        ? DefaultValues.EMAIL_DATA.NEW_CUSTOMER_EMAIL.SUBJECT
                        : null,
                    content: this.isDomainSaved
                        ? DefaultValues.EMAIL_DATA.NEW_CUSTOMER_EMAIL.CONTENT
                        : null,
                },
                report: {
                    subject: this.isDomainSaved
                        ? DefaultValues.EMAIL_DATA.NEW_REPORT_EMAIL.SUBJECT
                        : null,
                    content: this.isDomainSaved
                        ? DefaultValues.EMAIL_DATA.NEW_REPORT_EMAIL.CONTENT
                        : null,
                },
                alert: {
                    subject: this.isDomainSaved
                        ? DefaultValues.EMAIL_DATA.NEW_ALERT_EMAIL.SUBJECT
                        : null,
                    content: this.isDomainSaved
                        ? DefaultValues.EMAIL_DATA.NEW_ALERT_EMAIL.CONTENT
                        : null,
                },
                budget: {
                    subject: this.isDomainSaved
                        ? DefaultValues.EMAIL_DATA.NEW_BUDGET_EMAIL.SUBJECT
                        : null,
                    content: this.isDomainSaved
                        ? DefaultValues.EMAIL_DATA.NEW_BUDGET_EMAIL.CONTENT
                        : null,
                },
                automation: {
                    subject: this.isDomainSaved
                        ? DefaultValues.EMAIL_DATA.NEW_AUTOMATION_EMAIL.SUBJECT
                        : null,
                    content: this.isDomainSaved
                        ? DefaultValues.EMAIL_DATA.NEW_AUTOMATION_EMAIL.CONTENT
                        : null,
                },
                resetPassword: {
                    subject: this.isDomainSaved
                        ? DefaultValues.EMAIL_DATA.RESET_PASSWORD_EMAIL.SUBJECT
                        : null,
                    content: this.isDomainSaved
                        ? DefaultValues.EMAIL_DATA.RESET_PASSWORD_EMAIL.CONTENT
                        : null,
                },
                forgetPassword: {
                    subject: this.isDomainSaved
                        ? DefaultValues.EMAIL_DATA.FORGET_PASSWORD_EMAIL.SUBJECT
                        : null,
                    content: this.isDomainSaved
                        ? DefaultValues.EMAIL_DATA.FORGET_PASSWORD_EMAIL.CONTENT
                        : null,
                },
            },
            privacyPolicyConfig: {
                privacyPolicyKey: null,
            },
            termsOfServiceConfig: {
                termsOfServiceKey: null,
                hidden: false,
            },
        };
    }

    previewWhitelabelModalData(data, pseudoConsoleConfig?: object) {
        const modalData: IModalData = {
            modalName: 'Preview',
            modalHeightVh: 100,
            modalWidthVw: 100,
            noHeader: true,
            noStepPadding: true,
            noBottomPadding: true,
            modalIcon: {
                class: 'fas fa-file-invoice',
                type: IconType.FONTAWSOME
            } as IIcon,
            modalId: Symbol(),
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalPurpose: ModalAction.CREATE,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: IframeOpenerModalComponent,
                        payload: {
                            data: {
                                pseudoConsoleConfig,
                                whiteLabelData: data,
                                url:
                                    window.location.origin +
                                    this.globalDataService
                                        .previewModeQueryParam,
                                closeButtonColor:
                                    data['themeConfig']['--accentColor']
                            }
                        }
                    },
                    stepName: 'Preview'
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    openCloudFrontDnsInfo() {
        if (
            this.whitelabelData &&
            this.whitelabelData.domainConfig &&
            this.whitelabelData.domainConfig.cloudFrontDns
        ) {
            this.widgetRef.modalService.openInfoModal({
                infoHeading: 'Cloudfront DNS',
                content: [
                    {
                        type: 'UNORDERED_LIST',
                        listStyleType: 'disc',
                        data: [
                            `Login to your account where you have registered the domain <b>${this.whitelabelData.domainName}</b>.`,
                            'Check the configuration settings.',
                            'Select the domain name you wish to create an A record for.',
                            `Now you can add the desired A record provided by us i.e <b>${this.whitelabelData.domainConfig.cloudFrontDns}</b>.`,
                            'Provided domain will be active within 30 minutes for end customers.'
                        ]
                    },
                    {
                        type: 'PARAGRAPH',
                        data: [
                            '<p class="color-warn">(* These are the general steps and may vary depending on the domain provider you are using. Please read the manual documentation carefully of your domain provider.)</p>'
                        ]
                    }
                ]
            });
        }
    }

    deleteConfiguration() {
        const modalData: IConfirmationModal = {
            modalName: 'Delete Configuration',
            modalIcon: null,
            confirmationMessage:
                'Do you want to delete the entire WhiteLabeling Configuration? Please click Confirm to proceed.',
            contextIcon: null,
            buttonText: 'Confirm',
            buttonColorType: ButtonColorType.PRIMARY,
            hideCancelButton: true,
            bodyTemplate: this.deleteModal,
            loaderOnExec: true,
            modalHeightVh: 35,
            modalWidthVw: 35,
            extraClass: 'delete-modal',
            function: (modalId, formGroup, buttonRef) => {
                const deleteApi = this.widgetRef.widgetData.widgetInfo.delete;
                if (deleteApi) {
                    const apiConfig = Helper.generateHitApiConfig(deleteApi);
                    apiConfig.input = {};
                    apiConfig.function = (response) => {
                        buttonRef.loader = false;
                        this.widgetRef.refreshWidget();
                        this.widgetRef.notificationsService.showSnackBar(
                            'WhiteLabeling configuration deleted sucessfully'
                        );
                        this.widgetRef.modalService.closeModal(null, modalId);
                    };
                    apiConfig.errorFunction = (error) => {
                        buttonRef.loader = false;
                        this.widgetRef.changeDetectorRef.detectChanges();
                        Helper.showErrorMessage(
                            this.widgetRef.notificationsService,
                            error,
                            'Error in deleting WhiteLabeling configuration'
                        );
                        this.widgetRef.modalService.closeModal(null, modalId);
                    };
                    new HitApi(
                        apiConfig,
                        this.widgetRef.httpService,
                        this.widgetRef.ngZone
                    ).hitApi();
                } else {
                    buttonRef.loader = false;
                }
            }
        };

        this.widgetRef.modalService.openConfirmationModal(modalData);
    }

    publishConfigurationModal(buttonRef) {
        const modalData: IConfirmationModal = {
            modalName: 'Publish',
            modalIcon: null,
            confirmationMessage: null,
            contextIcon: null,
            buttonText: 'Confirm',
            buttonColorType: ButtonColorType.PRIMARY,
            hideCancelButton: true,
            bodyTemplate: this.publishModal,
            loaderOnExec: true,
            modalHeightVh: 40,
            function: (modalId) => {
                this.publishConfiguration(buttonRef, modalId);
            }
        };

        this.widgetRef.modalService.openConfirmationModal(modalData);
    }

    publishConfiguration(buttonRef, modalId?) {
        const publishApi =
            this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'] &&
            this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                'publish'
            ]
                ? this.widgetRef.widgetData.widgetInfo[
                      'additionalApisForWidget'
                  ]['publish']
                : null;
        if (publishApi) {
            buttonRef.loader = true;
            const apiConfig = Helper.generateHitApiConfig(publishApi);
            apiConfig.input = this.whiteLabelDraft;
            apiConfig.function = (response) => {
                buttonRef.loader = false;
                this.widgetRef.refreshWidget();
                this.widgetRef.notificationsService.showSnackBar(
                    'WhiteLabeling configuration published sucessfully'
                );
                if (modalId) {
                    this.widgetRef.modalService.closeModal(null, modalId);
                }
            };

            apiConfig.errorFunction = (error) => {
                buttonRef.loader = false;
                this.widgetRef.changeDetectorRef.detectChanges();
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    'Error in publishing whiteLabeling configuration'
                );
                if (modalId) {
                    this.widgetRef.modalService.closeModal(null, modalId);
                }
            };

            new HitApi(
                apiConfig,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        } else {
            buttonRef.loader = false;
        }
    }

    ngOnDestroy(): void {
        if (this.contentVisibleSubscription) {
            this.contentVisibleSubscription.unsubscribe();
        }
    }
}
