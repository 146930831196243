import { Component, OnInit } from '@angular/core';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { CaCreateTicketModalComponent } from 'src/app/shared/components/modal-templates/integration/ca-sdm-modal/ca-sdm-create-ticket-modal/ca-create-ticket-modal/ca-create-ticket-modal.component';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ButtonColorType } from './../../../../../enums/ButtonColorType';
import { IButtonGeneratorInput } from './../../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-ca-ticket-listing',
    templateUrl: './ca-ticket-listing.component.html',
    styleUrls: ['./ca-ticket-listing.component.sass']
})
export class CaTicketListingComponent implements OnInit {
    tableGenInput: ITableGeneratorInput = null;
    widgetRef: Widget;
    caStatusResponse = null;
    widgetIcon: IIcon = {
        class: 'assets/integrations/ca-logo.png',
        type: IconType.IMAGE
    };
    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        const apiArgs: IHitApi = {
            url: ApiUrls.INTEGRATION_STATUS + 'CASDM',
            intactUrl: `${ApiUrls.INTEGRATION_STATUS}{integration-name}`,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (response) => {
                this.caStatusResponse = response;
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    'Error hitting ca-sdm status api.'
                );
            }
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
        this.setUpBasics();
    }

    setUpBasics() {
        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);

        this.tableGenInput = {
            buttons: [
                {
                    buttonName: 'Create Ticket',
                    function: (buttonRef: IButtonGeneratorInput) => {
                        const modalArgs: IModalData = {
                            modalName: 'Create Ticket',
                            sourceId:
                                this.widgetRef.widgetData
                                    .widgetUniqueIdentifier,
                            modalType: ModalType.MIDDLE,
                            modalIcon: {
                                type: IconType.FONTAWSOME,
                                class: 'fas fa-ticket-alt'
                            },
                            modalSteps: [
                                {
                                    stepName: 'Credentials',
                                    stepData: {
                                        componentToLoad:
                                            CaCreateTicketModalComponent,
                                        payload: {
                                            data: {
                                                response: this.caStatusResponse,
                                                widgetRef: this.widgetRef
                                            }
                                        }
                                    }
                                }
                            ],
                            modalWidthVw: 60,
                            modalHeightVh: 80
                        };
                        this.widgetRef.modalService.openModal(modalArgs);
                    },

                    showLoader: false,
                    buttonType: ButtonType.RAISED,
                    buttonColorType: ButtonColorType.PRIMARY
                }
            ],
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'ticketList'
            },
            noDataMessage: 'No Tickets Available',
            widgetIconData: this.widgetIcon,
            columns: [
                {
                    columnKey: 'ref_num',
                    columnName: 'Ticket Number'
                },
                {
                    columnKey: 'z_email',
                    columnName: 'Email'
                },
                {
                    columnKey: 'description',
                    columnName: 'Description'
                },
                {
                    columnKey: 'priority',
                    columnName: 'Priority'
                },
                {
                    columnKey: 'open_date',
                    columnName: 'Open Data'
                },
                {
                    columnKey: 'group',
                    columnName: 'Group'
                },
                {
                    columnKey: 'category',
                    columnName: 'Category'
                }
            ]
        };
    }
}
