import {
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { FilterStoreKey } from '../../enums/FilterStoreKey';
import { IconType } from '../../enums/IconType';
import { IIcon } from '../../interfaces/icon-data/IIcon';
import { IModalData } from '../../interfaces/modal/IModalData';
import { ConfigCacheService } from '../../services/cache/config-cache/config-cache.service';
import { FilterCacheService } from '../../services/cache/filters-cache/filter-cache.service';
import { CompareViewService } from '../../services/compare-view/compare-view.service';
import { FiltersService } from '../../services/filters/filters.service';
import { GlobalDataService } from '../../services/global-data/global-data.service';
import { ModalService } from '../../services/modal/modal-service/modal.service';
import { UserDataCacheService } from '../../services/user-data-cache/user-data-cache.service';
import { Helper } from './../../classes/Helper';

@Component({
    selector: 'app-link-widget',
    templateUrl: './link-widget.component.html',
    styleUrls: ['./link-widget.component.sass']
})
export class LinkWidgetComponent implements OnInit, OnDestroy {
    @Input() modalData: IModalData;
    FilterStoreKey = FilterStoreKey;
    compare: boolean;
    showSecondInsight: boolean;
    firstViewData: IModalData;
    secondViewData: IModalData;
    filtersCollapsed = true;
    secondViewFiltersCollapsed = true;
    filtersData = {};
    moduleList = [];
    pageList = [];
    insightList = [];
    module = '';
    page = '';
    insight = '';
    refreshSecondInsight = new EventEmitter();
    insightObservables = [];
    moduleName = '';
    pageName = '';
    actionName = '';
    errorMessage = '';
    insightGenData = {};
    objectKeys = Object.keys;
    selectedFiltersData = {};
    insightGenDataChanged = new EventEmitter();
    crossIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-times'
    };
    filterIcon: IIcon = {
        type: IconType.SVG,
        class: 'funnel'
    };
    filterMenuRef: MatSidenav;
    constructor(
        private compareViewService: CompareViewService,
        public filtersService: FiltersService,
        private globalDataService: GlobalDataService,
        private ngZone: NgZone,
        private modalService: ModalService,
        private filterCacheService: FilterCacheService,
        private userCacheService: UserDataCacheService,
        private configCache: ConfigCacheService
    ) {}

    ngOnInit() {
        this.compareViewService.linkWidgetMode = true;
        this.compareViewService.currentLevel = {};

        setTimeout(() => {
            const filtersMap = new Map();
            const filterObj = {};
            const filtersData = this.filterCacheService.getFiltersInfo(
                this.userCacheService.emailId,
                this.configCache.viewId,
                FilterStoreKey.WEBSITE_FILTERS
            );
            this.modalData.widgetData.widgetInfo.filters.forEach((filterId) => {
                if (filtersData && filtersData[filterId]) {
                    filterObj[filterId] = Helper.cloneDeep(
                        filtersData[filterId]
                    );
                }
            });
            if (Object.keys(filterObj) && Object.keys(filterObj).length) {
                const filterData = {
                    [FilterStoreKey.FIRST_VIEW_FILTERS]: filtersData
                };
                this.filterCacheService.setFiltersInfo(
                    this.userCacheService.emailId,
                    this.configCache.viewId,
                    filterData
                );
            }

            this.firstViewData = Helper.cloneDeep(this.modalData);
            this.secondViewData = Helper.cloneDeep(this.modalData);
        }, 10);
    }

    toggleCompare() {
        setTimeout(() => {
            const filtersData = this.filterCacheService.getFiltersInfo(
                this.userCacheService.emailId,
                this.configCache.viewId,
                FilterStoreKey.FIRST_VIEW_FILTERS
            );
            if (
                filtersData &&
                Object.keys(filtersData) &&
                Object.keys(filtersData).length
            ) {
                const filterData = {
                    [FilterStoreKey.SECOND_VIEW_FILTERS]:
                        Helper.cloneDeep(filtersData)
                };
                this.filterCacheService.setFiltersInfo(
                    this.userCacheService.emailId,
                    this.configCache.viewId,
                    Helper.cloneDeep(filterData)
                );
            }
            this.showSecondInsight = !this.showSecondInsight;
            this.compareViewService.isCompareViewEnabled.next(
                this.showSecondInsight
            );
        }, 10);
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.globalDataService.windowResizeEvent.next();
            }, 30);
        });
    }

    closeModal() {
        this.modalService.closeModal(null, this.modalData.modalId);
    }

    ngOnDestroy(): void {
        this.filtersService.dataStoreKey = '';
        this.compareViewService.isCompareViewEnabled.next(false);
        this.compareViewService.compareViewMode.next(false);
        this.compareViewService.linkWidgetMode = false;
        this.filtersService.widgetLinkingData.next(null);
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.globalDataService.windowResizeEvent.next();
            }, 50);
        });
    }
}
