import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { skip, take } from 'rxjs/operators';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { TestApiUrls } from 'src/app/core/classes/TestApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { WidgetTypeMap } from 'src/app/shared/classes/WidgetTypeMap';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { WidgetAttribute } from 'src/app/shared/enums/WidgetAttribute';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IWidgetData } from 'src/app/shared/interfaces/widget/IWidgetData';
import { IWidgetInfo } from 'src/app/shared/interfaces/widget/IWidgetInfo';
import { IWidgetUrlInfo } from 'src/app/shared/interfaces/widget/IWidgetUrlInfo';
import { SubSink } from 'subsink';
import { ApiResponseCacheService } from '../../cache/api-response-cache/api-response-cache.service';
import { WidgetCacheService } from '../../cache/widget-cache/widget-cache.service';
import { GlobalDataService } from '../../global-data/global-data.service';
import { IotService } from '../../iot/iot.service';
import { HttpService } from '../http-main/http.service';
import { NotificationsService } from '../../notifications/notifications.service';

@Injectable({
    providedIn: 'root'
})
export class WidgetHttpService extends HttpService {
    bindData: any = null;
    constructor(
        http: HttpClient,
        public widgetCacheService: WidgetCacheService,
        public globalDataService: GlobalDataService,
        iotService: IotService,
        apiResponseCacheService: ApiResponseCacheService,
        ngZone: NgZone,
        public notificationsService: NotificationsService,
    ) {
        super(http, iotService, apiResponseCacheService, ngZone);
    }

    getWidgetsFromUrl(url: string): BehaviorSubject<IWidgetUrlInfo> {
        const widgetObs = new BehaviorSubject<IWidgetUrlInfo>(null);
        const key = Helper.convertUrlToKey(url);
        this.widgetCacheService.fetch(key, (value) => {
            widgetObs.next(value);
        });

        return widgetObs;
    }

    getWidgetInfo(
        widgetId: string,
        testing = false,
        callBack?
    ): BehaviorSubject<IWidgetInfo> {
        if (callBack) {
            this.bindData = callBack;
        }
        const widgetInfoObs = new BehaviorSubject<IWidgetInfo>(null);
        this.widgetCacheService.fetch(widgetId, (value) => {
            if (value) {
                if (this.bindData) {
                    this.bindData(value);
                }
                widgetInfoObs.next(value);
            } else {
                // Hit api to get widget info

                const apiData: IHitApi = {
                    url: testing
                        ? `${TestApiUrls.WIDGET_INFO_API_PATH}${widgetId}`
                        : `${ApiUrls.WIDGET_INFO_API_PATH}${widgetId}`,
                    intactUrl: testing
                        ? `${TestApiUrls.WIDGET_INFO_API_PATH}{widgetId}`
                        : `${ApiUrls.WIDGET_INFO_API_PATH}{widgetId}`,
                    uniqueIdentity: widgetId,

                    function: (response: IWidgetInfo) => {
                        if (!testing) {
                            this.widgetCacheService.store(widgetId, response);
                        }
                        if (this.bindData) {
                            this.bindData(response);
                        }
                        widgetInfoObs.next(response);
                    },

                    errorFunction: (error) => widgetInfoObs.next(error),

                    input: {},

                    requestType: RequestType.GET,

                    config: {
                        authorization: AuthorizationType.BEARER_TOKEN,

                        ignoreBaseUrl: testing ? true : false
                    }
                };

                new HitApi(apiData, this, this.ngZone).hitApi();
            }
        });

        return widgetInfoObs;
    }

    getAllWidgetsFromLeafNode(leafNodeUrl: string): Subject<any[]> {
        const widgetList: Subject<any[]> = new Subject();
        const config: IHitApi = {
            url: leafNodeUrl,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            function: (response) => {
                if ('totalPages' in response && response['totalPages'] > 1) {
                    const widgetIds: any[] = response['widgets'];
                    const totalPages = +response['totalPages'];
                    let pageNum = +response['page'] + 1;
                    const parallelApis: IHitApi[] = [];
                    while (pageNum < totalPages) {
                        const apiConfig: IHitApi = {
                            url: Helper.generateNextPageUrl(
                                leafNodeUrl,
                                pageNum
                            ),
                            function: null,
                            input: {},
                            requestType: RequestType.GET,
                            uniqueIdentity: `${pageNum}`,
                            config: {
                                authorization: AuthorizationType.BEARER_TOKEN,
                                sendOnlyResponse: true
                            }
                        };
                        parallelApis.push(apiConfig);
                        ++pageNum;
                    }
                    this.hitParallelApis(parallelApis).subscribe(
                        (response: Map<Symbol | string, any>) => {
                            for (const [key, value] of response.entries()) {
                                if (value['widgets']) {
                                    widgetIds.push(...value['widgets']);
                                }
                            }
                            widgetList.next(widgetIds);
                        }
                    );
                } else {
                    widgetList.next(response['widgets']);
                }
            },
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            }
        };

        new HitApi(config, this, this.ngZone).hitApi();

        return widgetList;
    }

    set widgetsListForUrl(info) {
        const url = info.url;
        const data = info.data;
        const key = Helper.convertUrlToKey(url);
        this.widgetCacheService.store(key, data);
    }

    loadWidgetsData(
        widgetHttpService: WidgetHttpService,
        widgetSubs: SubSink,
        widgetId,
        widgetData: Map<string, IWidgetData>,
        callback?,
        testing = false
    ) {
        const widgetInfoObs = widgetHttpService.getWidgetInfo(
            widgetId,
            testing
        );
        if (!widgetInfoObs.value) {
            widgetSubs.add(
                widgetInfoObs
                    .pipe(skip(1), take(1))
                    .subscribe((widgetInfoRes) => {
                        let widgetInfo;
                        if (!widgetInfoRes['error']) {
                            widgetInfo = widgetInfoRes;
                            if (widgetInfo && testing) {
                                widgetInfo = widgetInfoRes[0];
                            }
                        } else {
                            Helper.showErrorMessage(
                                this.notificationsService,
                                null,
                                'Unable to fetch the widget information.'
                            );
                        }
                        this.getWidgetAttributesData(
                            widgetHttpService,
                            widgetSubs,
                            widgetData,
                            widgetId,
                            widgetInfo,
                            callback ? callback : null,
                            testing
                        );
                    })
            );
        } else {
            this.getWidgetAttributesData(
                widgetHttpService,
                widgetSubs,
                widgetData,
                widgetId,
                widgetInfoObs.value,
                callback ? callback : null,
                testing
            );
        }
    }

    getWidgetAttributesData(
        widgetHttpService: WidgetHttpService,
        widgetSubs: SubSink,
        widgetCollectionData: Map<string, IWidgetData>,
        widgetId: string,
        widgetInfo: IWidgetInfo,
        callback,
        testing = false
    ) {
        if (!widgetInfo) {
            callback();
            return;
        }
        const widgetData: IWidgetData = {
            widgetInfo,
            automation: null,
            description: null,
            moreInfo: null,
            linking: null,
            componentToLoad: WidgetTypeMap.getComponent(widgetInfo.portlet),
            widgetUniqueIdentifier: Symbol(),
            widgetId
        };

        const DESCRIPTION_JSON_KEY = `${widgetId}|${WidgetAttribute.DESCRIPTION}`;
        const AUTOMATION_JSON_KEY = `${widgetId}|${WidgetAttribute.AUTOMATION}`;
        const LINKING_JSON_KEY = `${widgetId}|${WidgetAttribute.LINKING_JSON}`;
        const MORE_INFO_KEY = `${widgetId}|${WidgetAttribute.MORE_INFO}`;

        const attributesApis: IHitApi[] = [];

        attributesApis.push({
            uniqueIdentity: DESCRIPTION_JSON_KEY,
            url: ApiUrls.WIDGET_DOCUMENTATION_PUBLISHED_DESCRIPTION.replace(
                '{widgetId}',
                widgetId
            ),
            intactUrl: ApiUrls.WIDGET_DOCUMENTATION_PUBLISHED_DESCRIPTION,
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
                sendOnlyResponse: true
            },
            function: null,
            requestType: RequestType.GET,
            input: {}
        });

        if (widgetInfo.automation) {
            attributesApis.push({
                uniqueIdentity: AUTOMATION_JSON_KEY,
                url: `${
                    widgetInfo.automation.host ? widgetInfo.automation.host : ''
                }${widgetInfo.automation.apiRouteSuffix}`,
                config: {
                    authorization: widgetInfo.automation.authorization,
                    ignoreBaseUrl: widgetInfo.automation.host ? true : false,
                    sendOnlyResponse: true
                },
                function: null,
                requestType: widgetInfo.automation.requestType,
                input: {}
            });
        }

        if (widgetInfo.linking) {
            attributesApis.push({
                uniqueIdentity: LINKING_JSON_KEY,
                url: `${ApiUrls.LINKING_INFO_API_PATH}${widgetInfo.linking}`,
                intactUrl: `${ApiUrls.LINKING_INFO_API_PATH}{linkingId}`,
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN,
                    sendOnlyResponse: true
                },
                function: null,
                requestType: RequestType.GET,
                input: {}
            });
        }
        if (widgetInfo.moreInfo) {
            const moreInfoArgs = Helper.generateHitApiConfig(
                widgetInfo.moreInfo
            );
            moreInfoArgs.uniqueIdentity = MORE_INFO_KEY;
            // moreInfoArgs.config = {
            //     ...moreInfoArgs.config,
            //     sendOnlyResponse: true,
            // };
            moreInfoArgs.config['sendOnlyResponse'] = true;
            moreInfoArgs.function = null;
            // moreInfoArgs.input = {};
            attributesApis.push(moreInfoArgs);
        }
        if (attributesApis.length) {
            widgetSubs.add(
                widgetHttpService
                    .hitParallelApis(attributesApis)
                    .subscribe((response: Map<Symbol | string, any>) => {
                        widgetData.description =
                            response.get(DESCRIPTION_JSON_KEY);
                        widgetData.automation =
                            response.get(AUTOMATION_JSON_KEY);
                        widgetData.moreInfo = response.get(MORE_INFO_KEY);
                        widgetData.linking = response.get(LINKING_JSON_KEY);
                        widgetCollectionData.set(widgetId, widgetData);
                        if (callback) {
                            callback();
                        }
                    })
            );
        } else {
            widgetCollectionData.set(widgetId, widgetData);
            if (callback) {
                callback();
            }
        }
    }
}
