<ng-container *ngIf="widgetRef">
    <div
        *ngIf="widgetRef.loadingData | async"
        appIcon
        [data]="spinnerLoader"
        class="overlay-loader"
    ></div>

    <div class="toggle-view" *ngIf="!(widgetRef.loadingData | async)">
        <app-single-select-dropdown
            [filterInfo]="viewFilterInfo"
            [selectedValue]="selectedView"
            [clearable]="false"
            (change)="onViewChanged($event)"
            [appendTo]="'.toggle-view'"
        ></app-single-select-dropdown>
    </div>

    <div
        class="container"
        [ngClass]="
            widgetRef.errorMessage.value || widgetRef.extraMessage.value
                ? 'hide'
                : 'show'
        "
    >
        <app-table-generator
            *ngIf="selectedView === ViewType.TABLE"
            [tableInput]="tableInput"
            [widgetRef]="widgetRef"
            [overviewTemplateStart]="overviewTemplateStart"
            [useMaterialIcons]="true"
            (gridRef)="agGrid = $event"
        >
        </app-table-generator>

        <app-kanban-generator
            *ngIf="selectedView === ViewType.KANBAN"
            [widgetRef]="widgetRef"
            [kanbanInput]="kanbanInput"
        >
        </app-kanban-generator>

        <ng-template #overviewTemplateStart>
            <div class="control-panel">
                <app-button-generator [buttonGenInput]="buttonInputs[0]">
                </app-button-generator>

                <div
                    *ngIf="
                        widgetRef.widgetData.widgetInfo.reports &&
                        !(widgetRef.widgetData.widgetInfo.reports | emptyObject)
                    "
                    class="action"
                    #menuTrigger="matMenuTrigger"
                    [matMenuTriggerFor]="moreInfoMenu"
                    (click)="$event.stopPropagation()"
                    [matTooltip]="'Download Report'"
                >
                    <app-button-generator
                        [buttonGenInput]="buttonInputs[1]"
                        (buttonRef)="menuTrigger.openMenu()"
                    >
                    </app-button-generator>
                    <mat-menu #moreInfoMenu="matMenu">
                        <div class="more-info-menu">
                            <ng-container
                                *ngFor="
                                    let report of widgetRef.widgetData
                                        .widgetInfo.reports | keyvalue
                                "
                            >
                                <div
                                    class="info-item"
                                    [class.rotatingLoader]="
                                        widgetRef.reportLoaders.has(
                                            report.key
                                        ) &&
                                        widgetRef.reportLoaders.get(report.key)
                                    "
                                    (click)="
                                        $event.stopPropagation();
                                        widgetRef.downloadReport(
                                            report.key,
                                            report.value
                                        )
                                    "
                                >
                                    <span> {{ report.key }}</span>
                                    <div
                                        appIcon
                                        [data]="lineLoaders"
                                        class="loader"
                                    ></div>
                                </div>
                            </ng-container>
                        </div>
                    </mat-menu>
                </div>

                <div class="refresh">
                    <div
                        class="icon"
                        appIcon
                        [data]="refreshIcon"
                        [hideText]="true"
                        [matTooltip]="'Refresh'"
                        (click)="
                            $event.stopPropagation(); widgetRef.refreshWidget()
                        "
                        [class.disabled]="
                            !widgetRef.widgetConfigState.actions.refresh.state
                        "
                        [class.loader]="widgetRef.loadingData | async"
                    ></div>
                </div>
            </div>
        </ng-template>
    </div>

    <div
        class="error-message"
        *ngIf="
            (widgetRef.errorMessage | async) &&
            (widgetRef.errorMessage | async).length
        "
    >
        <i class="fas fa-exclamation-circle"></i>
        <span>{{ widgetRef.errorMessage.value }}</span>
    </div>

    <div
        class="extra-message"
        [style.top]="(widgetRef.loadingData | async) ? '-40px' : 'unset'"
        *ngIf="
            (widgetRef.extraMessage | async) &&
            (widgetRef.extraMessage | async).length
        "
    >
        {{ widgetRef.extraMessage.value }}
    </div>
</ng-container>
