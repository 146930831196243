
        <style>
            .budget-data-container {
                padding: 24px;
                padding-bottom: 48px;
            }
            .heading {
                background-color: var(--primaryColor);
                color: var(--secondaryTextColor);
                font-weight: 600;
                padding: 8px 16px;
                border-radius: 20px;
                justify-content: center;
                display: flex;
                align-items: center;
                margin-bottom: 26px;
                white-space: pre;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .data {
                display: flex;
                flex-direction: column;
            }
            .item {
                display: flex;
                padding: 6px 12px;
                margin-bottom: 8px;
            }
            .item-heading {
                flex: 3;
                color: var(--defaultTextColor);
                opacity: 67%;
                font-size: var(--fs400);
                display: flex;
                align-items: center;
            }
            .item-value {
                flex: 1;
                justify-content: flex-end;
                display: flex;
                font-size: var(--fs600);
                color: var(--defaultTextColor);
            }
        </style>

        <div class="tw-flex tw-flex-col budget-data-container">
            <div class="heading" [matTooltip]="cardData.budgetName">
                {{ cardData.budgetName }}
            </div>
            <div class="data">
                <div class="item">
                    <div class="item-heading">Total Budget</div>
                    <div class="item-value">
                        {{ cardData.totalBudget }}
                    </div>
                </div>
                <div class="item">
                    <div class="item-heading">
                        Consumed Budget<i
                            [matTooltip]="
                                'Discrepancy can be observed between the cost reflected in Cost Monitoring and Budgets due to custom exchange rate configuration'
                            "
                            style="margin-left: 8px;"
                            class="fas fa-info-circle"
                        ></i>
                    </div>
                    <div class="item-value">
                        {{ cardData.currentBudget }}
                    </div>
                </div>
                <div class="item">
                    <div class="item-heading">Deviation</div>
                    <div class="item-value">
                        {{ getDeviation() }}
                    </div>
                </div>
                <div class="item">
                    <div class="item-heading">Percentage Consumed</div>
                    <div class="item-value">
                        {{
                            (
                                (cardData.currentBudget /
                                    cardData.totalBudget) *
                                100
                            ).toFixed(1)
                        }}
                    </div>
                </div>
            </div>
        </div>
    