import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgxSliderModule } from '@m0t0r/ngx-slider';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgxGaugeModule } from 'ngx-gauge';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgxSummernoteModule } from 'ngx-summernote';
import { ContentFieldComponent } from '../../components/form-fields/content-field/content-field.component';
import { DateRangeFieldComponent } from '../../components/form-fields/date-range-field/date-range-field.component';
import { DoubleRangeFieldComponent } from '../../components/form-fields/double-range-field/double-range-field.component';
import { ImageUploadFieldComponent } from '../../components/form-fields/image-upload-field/image-upload-field.component';
import { ListObjectDropdownFieldComponent } from '../../components/form-fields/list-object-dropdown-field/list-object-dropdown-field.component';
import { MultiDropdownFieldComponent } from '../../components/form-fields/multi-dropdown-field/multi-dropdown-field.component';
import { MultiGroupDropdownFieldComponent } from '../../components/form-fields/multi-group-dropdown-field/multi-group-dropdown-field.component';
import { NumberFieldComponent } from '../../components/form-fields/number-field/number-field.component';
import { SingleDropdownFieldComponent } from '../../components/form-fields/single-dropdown-field/single-dropdown-field.component';
import { SingleGroupDropdownFieldComponent } from '../../components/form-fields/single-group-dropdown-field/single-group-dropdown-field.component';
import { TextAreaComponent } from '../../components/form-fields/text-area/text-area.component';
import { TextFieldComponent } from '../../components/form-fields/text-field/text-field.component';
import { TimeFieldComponent } from '../../components/form-fields/time-field/time-field.component';
import { FormGeneratorComponent } from '../../components/form-generator/form-generator.component';
import { CommonPipesModule } from '../common-pipes/common-pipes.module';
import { HelperComponentModule } from '../helper-component/helper-component.module';
import { MaterialModule } from '../material/material-module.module';
import { CheckboxFieldComponent } from './../../components/form-fields/checkbox-field/checkbox-field.component';
import { ColorFieldComponent } from './../../components/form-fields/color-field/color-field.component';
import { DateFieldComponent } from './../../components/form-fields/date-field/date-field.component';
import { DateTimeFieldComponent } from './../../components/form-fields/date-time-field/date-time-field.component';
import { EmailFieldComponent } from './../../components/form-fields/email-field/email-field.component';
import { FileFieldComponent } from './../../components/form-fields/file-field/file-field.component';
import { MatchipFieldComponent } from './../../components/form-fields/matchip-field/matchip-field.component';
import { PasswordFieldComponent } from './../../components/form-fields/password-field/password-field.component';
import { RadioFieldComponent } from './../../components/form-fields/radio-field/radio-field.component';
import { ToggleFieldComponent } from './../../components/form-fields/toggle-field/toggle-field.component';
import { UrlFieldComponent } from './../../components/form-fields/url-field/url-field.component';
import { WysiwygEditorFieldComponent } from './../../components/form-fields/wysiwyg-editor-field/wysiwyg-editor-field.component';
import { MultiDropdownWithInputComponent } from '../../components/form-fields/multi-dropdown-with-input/multi-dropdown-with-input.component';
const components = [
    FormGeneratorComponent,
    TextFieldComponent,
    SingleDropdownFieldComponent,
    SingleGroupDropdownFieldComponent,
    MultiGroupDropdownFieldComponent,
    MultiDropdownFieldComponent,
    ListObjectDropdownFieldComponent,
    TextAreaComponent,
    NumberFieldComponent,
    CheckboxFieldComponent,
    WysiwygEditorFieldComponent,
    ContentFieldComponent,
    DateFieldComponent,
    TimeFieldComponent,
    DateTimeFieldComponent,
    PasswordFieldComponent,
    EmailFieldComponent,
    UrlFieldComponent,
    ColorFieldComponent,
    FileFieldComponent,
    RadioFieldComponent,
    ToggleFieldComponent,
    MatchipFieldComponent,
    ImageUploadFieldComponent,
    DoubleRangeFieldComponent,
    DateRangeFieldComponent,
    MultiDropdownWithInputComponent
];
const modules = [
    CommonModule,
    MaterialModule,
    HelperComponentModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgxSliderModule,
    NgxGaugeModule,
    MaterialFileInputModule,
    NgxSummernoteModule,
    NgxMatTimepickerModule,
    BrowserModule,
    CommonPipesModule,
    EditorModule
];
@NgModule({
    declarations: [...components],
    imports: [...modules],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [...components]
})
export class FormModule {}
