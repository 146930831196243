import { Injectable } from '@angular/core';
import { CacheMain } from 'src/app/shared/classes/CacheMain';

@Injectable({
    providedIn: 'root'
})
export class CartCacheService extends CacheMain {
    static readonly CART_WIDGETS = 'CART_WIDGETS';
    static readonly CART_SELECTED_WIDGETS = 'CART_SELECTED_WIDGETS';

    constructor() {
        const CART_CACHE_PREFIX = 'CART';
        super(CART_CACHE_PREFIX, true);
    }

    get cartWidgets() {
        return this.fetch(CartCacheService.CART_WIDGETS);
    }

    set cartWidgets(cartData) {
        this.store(CartCacheService.CART_WIDGETS, cartData);
    }

    get cartSelectedWidgets() {
        return this.fetch(CartCacheService.CART_SELECTED_WIDGETS);
    }

    set cartSelectedWidgets(cartData) {
        this.store(CartCacheService.CART_SELECTED_WIDGETS, cartData);
    }
}
