<ng-container>
    <div
        class="potential-count-container"
        *ngIf="dataMap && cardsData && cardsData.length && potentialSavings"
    >
        <div class="general">
            <span class="header-web-view tw-font-semibold"
                >Potential Savings: </span
            >&nbsp;
            <span class="value tw-font-bold">{{ potentialSavings }}</span>
        </div>
    </div>
    <div class="tw-flex tw-py-1 tw-px-0 wrapper">
        <div class="overview">
            <div class="consumption-heading"></div>
        </div>
        <ng-container *ngIf="this.cardsData && this.cardsData.length">
            <app-button-generator
                *ngIf="recommendationCount"
                [buttonGenInput]="recommendationCount"
            ></app-button-generator>
        </ng-container>
        <div
            class="tw-ml-auto tw-mt-2 tw-mr-5 table-search"
            *ngIf="
                cardsData &&
                cardsData.length &&
                widgetRef.visibleSections.value.has(ViewType.CARDS) &&
                !widgetRef.lightState
            "
        >
            <app-table-search
                [data]="cardsData"
                (changed)="filteredCardsData = $event"
            ></app-table-search>
        </div>
    </div>
    <div
        class="tw-items-center tw-py-2 tw-px-0 controls"
        *ngIf="
            !(widgetRef.loadingData | async) &&
            widgetRef.visibleSections.value.has(ViewType.CARDS) &&
            cardsData &&
            cardsData.length
        "
    >
        <div
            class="widget-data-container"
            [id]="widgetRef.widgetTableId"
            *ngIf="
                !(widgetRef.loadingData | async) &&
                widgetRef.visibleSections.value.has(ViewType.CARDS) &&
                cardsData &&
                cardsData.length
            "
        >
            <div class="tw-max-h-96 tw-overflow-auto cards-container">
                <div
                    *ngFor="let card of filteredCardsData; let i = index"
                    class="tw-overflow-x-auto tw-m-5 tw-rounded-md tw-flex tw-mb-2 tw-items-center tw-justify-between tw-w-full tw-cursor-pointer card"
                    (click)="isV2 ? showRiModalV2(card) : showRiModal(card)"
                    [class.getFullAccessState]="
                        widgetRef.lightState && i >= widgetRef.liteViewLimit
                    "
                >
                    <div
                        class="tw-flex-1 tw-py-2 tw-px-3 item"
                        *ngFor="
                            let key of dataMap['displayEntry']
                                ? dataMap['displayEntry']
                                : objectKeys(card['displayEntry'])
                        "
                        [class.saving]="key.includes('Savings')"
                    >
                        <span class="tw-text-fs-400 tw-font-semibold name"
                            >{{ key }}:
                            <span class="tw-text-fs-200 tw-font-light value">{{
                                card["displayEntry"][key]
                            }}</span>
                        </span>
                    </div>
                    <app-button-generator
                        *ngIf="isV2"
                        [buttonGenInput]="cardButtons[i]"
                        (buttonRef)="openPurchaseModalViaCard($event, card)"
                    >
                    </app-button-generator>
                </div>
            </div>
        </div>
    </div>

    <div
        class="tw--mt-10 tw-overflow-hidden table-generate"
        *ngIf="
            !(widgetRef.loadingData | async) &&
            widgetRef.visibleSections.value.has(ViewType.TABLE) &&
            tableInput &&
            cardsData &&
            cardsData.length
        "
    >
        <app-only-table-generator
            [tableInput]="tableInput"
            [tableData]="tableData"
            [widgetRef]="widgetRef"
            [showSearchBox]="showSearchBox"
        ></app-only-table-generator>
    </div>
</ng-container>
