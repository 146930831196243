<section
    class="tw-rounded tw-overflow-hidden tw-my-2 tw-border-t-0"
    *ngIf="personalInfoFormGenInput && !(widgetRef.loadingData | async)"
>
    <div class="tw-flex tw-py-3 tw-px-4 section-heading">
        <div class="tw-flex tw-items-center">Personal Information</div>
        <div class="tw-ml-auto">
            <div
                *ngIf="
                    personalInfoFormGenInput.state === FormState.IDLE;
                    then edit;
                    else idle
                "
            ></div>
            <ng-template #edit>
                <i
                    class="fas fa-edit"
                    (click)="changePersonalInfoState(FormState.EDIT)"
                >
                </i>
            </ng-template>
            <ng-template #idle>
                <i
                    class="fas fa-times"
                    (click)="changePersonalInfoState(FormState.IDLE)"
                ></i>
            </ng-template>
        </div>
    </div>
    <div class="tw-w-full">
        <app-form-generator
            *ngIf="personalInfoFormGenInput"
            [formGeneratorInput]="personalInfoFormGenInput"
            (resetFormFunction)="resetPersonalInfo = $event"
        ></app-form-generator>
    </div>
</section>

<section
    class="tw-rounded tw-overflow-hidden tw-my-2 tw-border-t-0"
    *ngIf="changeCredentialFormGenInput && !(widgetRef.loadingData | async)"
>
    <div class="tw-flex tw-py-3 tw-px-4 section-heading">
        <div class="tw-flex tw-items-center">Credentials</div>
        <div class="tw-ml-auto">
            <div
                *ngIf="
                    credentialsFormGenInput.state === FormState.IDLE;
                    then edit;
                    else idle
                "
            ></div>
            <ng-template #edit>
                <i
                    class="fas fa-edit"
                    (click)="changeCredentialsState(FormState.EDIT)"
                >
                </i>
            </ng-template>
            <ng-template #idle>
                <i
                    class="fas fa-times"
                    (click)="changeCredentialsState(FormState.IDLE)"
                ></i>
            </ng-template>
        </div>
    </div>
    <div *ngIf="credentialsFormGenInput" class="tw-w-full">
        <div
            *ngIf="
                credentialsFormGenInput.state === FormState.IDLE;
                then idle;
                else edit
            "
        ></div>
        <ng-template #idle>
            <app-form-generator
                *ngIf="credentialsFormGenInput"
                [formGeneratorInput]="credentialsFormGenInput"
            ></app-form-generator>
        </ng-template>
        <ng-template #edit>
            <div class="tw-flex tw-flex-col">
                <app-form-generator
                    *ngIf="changeCredentialFormGenInput"
                    class="tw-flex-1 edit-form"
                    [formGeneratorInput]="changeCredentialFormGenInput"
                    (formGroupRef)="changeCredentialFormGroup = $event"
                ></app-form-generator>
                <app-button-generator
                    class="tw-ml-auto tw-mb-3 tw-mr-5"
                    [buttonGenInput]="credsUpdateButton"
                    (buttonRef)="updatePassword($event)"
                ></app-button-generator>
            </div>
        </ng-template>
    </div>
</section>
