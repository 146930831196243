<div class="widget-container" [class.custom-widget-holder]="hasStaticStyling">
    <app-widget-generator
        *ngIf="showInsight && widgetData.has(widgetId)"
        [data]="widgetData.get(widgetId)"
    ></app-widget-generator>
    <div class="overlay" *ngIf="!showInsight">
        <div class="overlay-content">
            <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
        </div>
    </div>
    <div
        class="error-message"
        *ngIf="widgetData.has(widgetId) && !widgetData.get(widgetId)"
    >
        <i class="fas fa-exclamation-circle"></i>
        Invalid Widget Id.
    </div>
</div>

<div class="action">
    <app-button-generator [buttonGenInput]="buttonInput">
    </app-button-generator>
</div>
