<div class="container" *ngIf="widgetRef && !(widgetRef.loadingData | async)">
    <div class="search" *ngIf="data && data.length">
        <app-table-search
            [searchFunction]="handleTableSearch.bind(this)"
        ></app-table-search>
    </div>
    <div class="kanban-panel" *ngIf="kanbanData.size">
        <ng-container *ngFor="let board of kanbanData | keyvalue">
            <div class="board">
                <div
                    class="board-title"
                    *ngIf="board.key && board.value && board.value.length"
                >
                    <div class="title">
                        {{ board.key }}
                    </div>
                    <div class="title" style="min-width: 90px">
                        ( {{ board.value.length }} )
                    </div>
                </div>
                <cdk-virtual-scroll-viewport
                    itemSize="50"
                    class="cards-container"
                >
                    <ng-container *cdkVirtualFor="let card of board.value">
                        <div class="card">
                            <div class="title-container">
                                <div
                                    class="card-name"
                                    *ngIf="kanbanInput.cardTitle"
                                    [matTooltip]="
                                        kanbanInput.cardTitle.infoKey
                                            | objectDataExtractor: card
                                    "
                                >
                                    {{
                                        kanbanInput.cardTitle.infoKey
                                            | objectDataExtractor: card
                                    }}
                                </div>
                                <div
                                    class="card-badge"
                                    *ngIf="kanbanInput.cardBadge"
                                    [ngStyle]="
                                        kanbanInput.cardBadge.infoRenderer
                                            ? kanbanInput.cardBadge.infoRenderer(
                                                  card
                                              )
                                            : null
                                    "
                                    [matTooltip]="
                                        kanbanInput.cardBadge.infoKey
                                            | objectDataExtractor: card
                                    "
                                >
                                    {{
                                        kanbanInput.cardBadge.infoKey
                                            | objectDataExtractor: card
                                    }}
                                </div>
                            </div>
                            <div
                                class="info-container"
                                [ngStyle]="
                                    kanbanInfoTemplate &&
                                    kanbanInfoTemplateStyles
                                        ? kanbanInfoTemplateStyles
                                        : null
                                "
                            >
                                <ng-container *ngIf="kanbanInfoTemplate">
                                    <ng-container
                                        *ngTemplateOutlet="
                                            kanbanInfoTemplate;
                                            context: { card: card }
                                        "
                                    ></ng-container>
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        !kanbanInfoTemplate &&
                                        kanbanInput.infoData &&
                                        kanbanInput.infoData.length
                                    "
                                >
                                    <ng-container
                                        *ngFor="
                                            let info of kanbanInput.infoData
                                        "
                                    >
                                        <div class="info-item">
                                            <div class="info-heading">
                                                {{ info.infoTitle }}
                                            </div>
                                            <div
                                                class="info-value"
                                                [matTooltip]="
                                                    info.infoRenderer
                                                        ? (info.infoRenderer
                                                          | cardDataRenderer
                                                              : card)
                                                        : (info.infoKey
                                                          | objectDataExtractor
                                                              : card)
                                                "
                                            >
                                                <ng-container
                                                    *ngIf="info.infoRenderer"
                                                >
                                                    {{
                                                        info.infoRenderer
                                                            | cardDataRenderer
                                                                : card
                                                    }}
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="!info.infoRenderer"
                                                >
                                                    {{
                                                        info.infoKey
                                                            | objectDataExtractor
                                                                : card
                                                    }}
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="action-container">
                                <ng-container
                                    *ngFor="
                                        let cardAction of kanbanInput.cardActions
                                            | cardButtonsDereferencer
                                    "
                                >
                                    <div class="card-actions">
                                        <app-button-generator
                                            [buttonGenInput]="cardAction"
                                            (buttonRef)="
                                                cardAction.function(
                                                    cardAction,
                                                    card
                                                )
                                            "
                                        >
                                        </app-button-generator>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </cdk-virtual-scroll-viewport>
            </div>
        </ng-container>
    </div>
    <div class="no-data" *ngIf="emptyFilteredData">
        {{ messages.NO_DATA_AVAILABLE }}
    </div>
</div>
