import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { PortletType } from 'src/app/shared/enums/PortletType';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { MoreInfoModalComponent } from '../../more-info-modal/more-info-modal/more-info-modal.component';
import { Helper } from './../../../../classes/Helper';
import { HitApi } from './../../../../classes/HitApi';
import { IconType } from './../../../../enums/IconType';
import { IFormField } from './../../../../interfaces/form-generator/IFormField';

@Component({
    selector: 'app-create-widget-step-two',
    templateUrl: './create-widget-step-two.component.html',
    styleUrls: ['./create-widget-step-two.component.sass']
})
export class CreateWidgetStepTwoComponent implements OnInit {
    widgetRef: Widget = null;
    widgetData = null;
    edit: boolean = false;

    formGenInput: IFormGeneratorInput = null;
    formGroup: FormGroup = null;

    draftLoader = false;
    publishLoader = false;
    updateLoader = false;

    constructor(
        private modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService
    ) {}

    ngOnInit(): void {
        this.widgetRef = this.modalInputData.data.widgetRef;
        if (
            this.modalInputData &&
            this.modalInputData.data &&
            this.modalInputData.data.edit
        ) {
            this.edit = true;
            this.widgetData = this.modalInputData.data.widgetData;
        }
        const step2Data = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(2);
        this.formGenInput = {
            formName: 'More Info',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'More Information',
                    placeholder: 'More Information',
                    fieldType: FilterType.WYSIWYG_EDITOR,
                    name: 'moreInformation',
                    required: true,
                    value: step2Data
                        ? step2Data.moreInformation
                        : this.widgetData
                        ? 'Loading.....'
                        : '',
                    prefixIcon: {
                        hoverText: 'Click for preview',
                        iconData: {
                            type: IconType.MATICON,
                            class: 'preview'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            if (
                                formGroup.get(field.name).invalid ||
                                formGroup.get(field.name).value ===
                                    'Loading.....'
                            ) {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'Provide more info first to see preview',
                                    true
                                );
                                return;
                            }
                            this.showMoreInfo(formGroup.get(field.name).value);
                        }
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'More Information is required'
                        }
                    ]
                }
            ]
        };
        if (this.edit) {
            this.getMoreInfo(step2Data.size ? false : true);
        }
    }

    showMoreInfo(data) {
        const moreInfoIcon: IIcon = {
            type: IconType.MATICON,
            class: 'info'
        };

        const modalData: IModalData = {
            modalName: 'More  Information',
            modalIcon: moreInfoIcon,
            sourceId: Symbol(),
            modalType: ModalType.SIDE,
            modalHeightVh: 80,
            modalWidthVw: 60,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: MoreInfoModalComponent,
                        payload: {
                            data: {
                                moreInfo: data,
                                isHtml: true
                            }
                        }
                    },
                    stepName: 'MORE INFO'
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    getMoreInfo(check) {
        if (check) {
            const args = Helper.generateHitApiConfig(this.widgetData.moreInfo);
            args.function = (response) => {
                this.formGroup
                    .get('moreInformation')
                    .setValue(response['moreInfo']);
            };
            args.errorFunction = (error) => {
                this.formGroup.get('moreInformation').setValue('');
            };
            new HitApi(
                args,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }

    back() {
        this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .set(2, this.formGroup.value);
        this.multiStepFormService.previousStep(this.modalInputData.modalId);
    }

    publish() {
        if (this.draftLoader || this.publishLoader) {
            return;
        }
        if (this.formGroup.invalid) {
            Helper.markAllFieldAsTouched(this.formGroup);
            return;
        }
        this.publishLoader = true;
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.create
        );
        apiArgs.input = this.generateInput(false);
        apiArgs.function = (response) => {
            this.publishLoader = false;
            this.widgetRef.refreshWidget();
            this.widgetRef.notificationsService.showSnackBar(
                'Widget created successfully'
            );
            this.widgetRef.modalService.closeModal(
                null,
                this.modalInputData.modalId
            );
        };
        apiArgs.errorFunction = (error) => {
            this.publishLoader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error creating widget'
            );
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    saveAsDraft() {
        if (this.draftLoader || this.publishLoader) {
            return;
        }
        if (this.formGroup.invalid) {
            Helper.markAllFieldAsTouched(this.formGroup);
            return;
        }
        this.draftLoader = true;
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.create
        );
        apiArgs.input = this.generateInput(true);
        apiArgs.function = (response) => {
            this.draftLoader = false;
            this.widgetRef.refreshWidget();
            this.widgetRef.notificationsService.showSnackBar(
                'Widget created successfully'
            );
            this.widgetRef.modalService.closeModal(
                null,
                this.modalInputData.modalId
            );
        };
        apiArgs.errorFunction = (error) => {
            this.draftLoader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error creating widget'
            );
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    update() {
        if (this.updateLoader) {
            return;
        }
        if (this.formGroup.invalid) {
            Helper.markAllFieldAsTouched(this.formGroup);
            return;
        }
        this.updateLoader = true;
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.update
        );

        apiArgs.input = this.generateInput(
            this.widgetData.extraInfo.activityType === 'WIDGET_DRAFT'
        );
        apiArgs.intactUrl = apiArgs.url;
        apiArgs.url = apiArgs.url.replace(
            '{widgetId}',
            this.widgetData['widgetId']
        );
        apiArgs.function = (response) => {
            this.updateLoader = false;
            this.widgetRef.refreshWidget();
            this.widgetRef.notificationsService.showSnackBar(
                'Widget updated successfully'
            );
            this.widgetRef.modalService.closeModal(
                null,
                this.modalInputData.modalId
            );
        };
        apiArgs.errorFunction = (error) => {
            this.updateLoader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error updating widget'
            );
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    generateInput(draft?) {
        const step1Data = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(1);
        const input = {
            widgetName: step1Data.widgetName,
            descriptionModel: {
                baseDescription: [
                    {
                        type: 'HTML',
                        heading: 'Description',
                        showHeading: false,
                        value: step1Data.description
                    }
                ]
            },
            widgetPortletType: PortletType.CUSTOMER_SERVICE,
            extraInfo: {
                price: step1Data.price,
                email: step1Data.email,
                activityType: draft ? 'WIDGET_DRAFT' : 'WIDGET_PUBLISHED',
                frequency: {
                    type: step1Data.frequency,
                    days:
                        step1Data.frequency === 'CUSTOM' ? step1Data.days : null
                },
                moreInfo: {
                    moreInformation: this.formGroup.value.moreInformation
                }
            }
        };
        return input;
    }
}
