<div class="acl-container" [ngClass]="showAcl ? 'show' : 'hide'">
    <div class="filter-head">
        <div class="filter-name {{ filterInfo.lightMode ? 'light' : false }}">
            ACL
        </div>
        <div class="filter-controls" *ngIf="filterData.associatedWidgets">
            <div
                appIcon
                *ngIf="filterData?.associatedWidgets.length"
                [matMenuTriggerFor]="associatedWidgetsMenu"
                [matTooltip]="'Widgets associated with filter'"
                [data]="infoIcon"
                class="action"
                [class.disabled]="disabled"
            ></div>
            <mat-menu #associatedWidgetsMenu="matMenu">
                <div class="more-info-menu">
                    <div class="heading">
                        Filter is applicable on following insights
                    </div>
                    <div
                        class="info-item not-clickable"
                        *ngFor="
                            let widget of filterData.associatedWidgets;
                            let i = index
                        "
                    >
                        {{ i + 1 }}.&nbsp;{{ widget.widgetTitle }}
                    </div>
                </div>
            </mat-menu>
        </div>
    </div>

    <app-multi-select-dropdown
        *ngIf="filterInfo"
        [isCaching]="isCaching"
        [filterInfo]="filterInfo"
        [clearable]="false"
        [selectedValues]="selectedValue ? selectedValue.acl : ''"
        [refreshListing]="refreshListing"
        (change)="aclValueChanged($event)"
        (afterResponse)="handleResponse($event)"
    ></app-multi-select-dropdown>
</div>
<section>
    <div class="filter-head">
        <div class="filter-name {{ filterInfo.lightMode ? 'light' : false }}">
            View
        </div>
        <div class="filter-controls" *ngIf="filterData.associatedWidgets">
            <div
                appIcon
                *ngIf="filterData?.associatedWidgets.length"
                [matMenuTriggerFor]="associatedWidgetsMenu"
                [matTooltip]="'Widgets associated with filter'"
                [data]="infoIcon"
                class="action"
                [class.disabled]="disabled"
            ></div>
            <mat-menu #associatedWidgetsMenu="matMenu">
                <div class="more-info-menu">
                    <div class="heading">
                        Filter is applicable on following insights
                    </div>
                    <div
                        class="info-item not-clickable"
                        *ngFor="
                            let widget of filterData.associatedWidgets;
                            let i = index
                        "
                    >
                        {{ i + 1 }}.&nbsp;{{ widget.widgetTitle }}
                    </div>
                </div>
            </mat-menu>
        </div>
    </div>
    <app-single-select-dropdown
        [isCaching]="false"
        [filterInfo]="
            [] | createFilterInfo: 'Views':null:null:lightMode:viewApiInfo
        "
        [selectedValue]="selectedValue ? selectedValue.view : ''"
        [refreshListing]="refreshViewListing"
        [clearable]="true"
        [apiInputCallback]="viewInputCallback.bind(this)"
        (change)="viewChanged($event)"
    ></app-single-select-dropdown>
</section>

<ng-containr *ngIf="refreshListArray.length">
    <section *ngFor="let layer of layersList; index as i">
        <div class="filter-head">
            <div
                class="filter-name {{ filterInfo.lightMode ? 'light' : false }}"
            >
                Layer {{ i + 1 }}
            </div>
            <div class="filter-controls" *ngIf="filterData.associatedWidgets">
                <div
                    appIcon
                    *ngIf="filterData?.associatedWidgets.length"
                    [matMenuTriggerFor]="associatedWidgetsMenu"
                    [matTooltip]="'Widgets associated with filter'"
                    [data]="infoIcon"
                    class="action"
                    [class.disabled]="disabled"
                ></div>
                <mat-menu #associatedWidgetsMenu="matMenu">
                    <div class="more-info-menu">
                        <div class="heading">
                            Filter is applicable on following insights
                        </div>
                        <div
                            class="info-item not-clickable"
                            *ngFor="
                                let widget of filterData.associatedWidgets;
                                let i = index
                            "
                        >
                            {{ i + 1 }}.&nbsp;{{ widget.widgetTitle }}
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>
        <app-single-select-dropdown
            [isCaching]="false"
            [filterInfo]="
                []
                    | createFilterInfo
                        : 'layer' + ' ' + (i + 1)
                        : null
                        : null
                        : lightMode
                        : layerApiInfo
            "
            [selectedValue]="selectedValue[layer]"
            [refreshListing]="refreshListArray[i]"
            [clearable]="true"
            [apiInputCallback]="layerInputCallback.bind(this, i)"
            (change)="layerChanged($event, i)"
        ></app-single-select-dropdown>
    </section>
</ng-containr>
