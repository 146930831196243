import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { WhiteLabelingService } from 'src/app/shared/services/white-labeling/white-labeling.service';
import { FieldSettingGenericComponent } from './components/field-setting-generic/field-setting-generic.component';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { Helper } from 'src/app/shared/classes/Helper';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import {
    CustomSignUpFieldType,
    CustomSignUpFormResponseType,
    ISignUpFieldTypeResponse,
} from 'src/app/shared/interfaces/ICustomSignTypes';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { FieldSettingDropdownComponent } from './components/field-setting-dropdown/field-setting-dropdown.component';
import { FieldSettingDateComponent } from './components/field-setting-date/field-setting-date.component';
import { FieldSettingTimeComponent } from './components/field-setting-time/field-setting-time.component';

@Component({
    selector: 'app-signup-config-step-two',
    templateUrl: './signup-config-step-two.component.html',
    styleUrls: ['./signup-config-step-two.component.sass'],
})
export class SignupConfigStepTwoComponent implements OnInit {
    public readonly plusIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'plus_icon',
        extraClass: 'action-icon',
    };
    public readonly plusIconBlue: IIcon = {
        type: IconType.SVG,
        class: 'plus_filled_blue',
        extraClass: 'action-icon',
    };
    public readonly minusIcon: IIcon = {
        type: IconType.SVG,
        class: 'minus_round_filled_red',
        extraClass: 'action-icon',
    };
    public readonly bottomEllipse: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'bottom_ellipse',
        extraClass: 'svg-accent-fill',
    };
    public readonly topEllipse: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'top_ellipse',
        extraClass: 'svg-accent-fill',
    };
    public readonly logo: IIcon = {
        type: IconType.IMAGE,
        class: this.whitelabelingService.whiteLableData.darkLogo,
        alt: this.whitelabelingService.whiteLableData.companyName,
        extraClass: 'logo-image',
    };
    public readonly deleteIcon: IIcon = {
        type: IconType.SVG,
        class: 'trash',
        extraClass: 'action-icon',
    };
    public readonly pencilIcon: IIcon = {
        type: IconType.SVG,
        class: 'pencil_icon',
        extraClass: 'action-icon',
    };
    public readonly dragIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'dragIcon',
        extraClass: 'action-icon',
    };
    public readonly trashIcon: IIcon = {
        type: IconType.SVG,
        class: 'trash_icon_filled',
        extraClass: 'action-icon',
    };
    public readonly fieldTypeList = FieldTypeList;

    private isEdit = false;
    public uploadedFile: null | File | Blob = null;
    public uploadedFileUrlPreSaved: string | null = null;
    get uploadedFileUrl() {
        if (this.uploadedFileUrlPreSaved) {
            return this.domSanitizer.bypassSecurityTrustUrl(
                this.uploadedFileUrlPreSaved
            );
        } else if (this.uploadedFile) {
            return this.domSanitizer.bypassSecurityTrustUrl(
                URL.createObjectURL(this.uploadedFile)
            );
        } else {
            return null;
        }
    }

    public defaultFieldsStatus = Helper.cloneDeep(
        defaultFieldsSourceTemplate
    ) as typeof defaultFieldsSourceTemplate;
    public isEditStepMode = false;
    public selectedStepIndex: number = 0;
    public isShowAddDefaultFieldMenu = false;
    public handleAddDefaultFields: IButtonGeneratorInput = {
        buttonName: 'Add default fields',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.STROKED,
        function: () => {
            this.defaultFieldsStatus = Helper.cloneDeep(
                defaultFieldsSourceTemplate
            );
            const currentStepFieldNamesList =
                this.currentSelectedStepFieldList.map((field) => field.name);
            this.defaultFieldsStatus.map((field) => {
                field.isAddedInCurrentStep = currentStepFieldNamesList.includes(
                    field.name
                );
                return field;
            });
            this.isShowAddDefaultFieldMenu = true;
        },
        buttonIconPrefix: {
            type: IconType.SVG_ASSETS,
            class: 'plus_icon',
            extraClass: 'action-icon',
        },
    };
    public handleCancelDefaultFieldsAction: IButtonGeneratorInput = {
        buttonName: 'Cancel',
        buttonColorType: ButtonColorType.INFO,
        buttonType: ButtonType.STROKED,
        function: () => {
            this.isShowAddDefaultFieldMenu = false;
            this.defaultFieldsStatus = Helper.cloneDeep(
                defaultFieldsSourceTemplate
            );
        },
    };
    public RemoveImageButton: IButtonGeneratorInput = {
        buttonName: 'Delete',
        buttonColorType: ButtonColorType.WARN,
        buttonType: ButtonType.STROKED,
        function: () => {
            this.uploadedFile = null;
            this.uploadedFileUrlPreSaved = null;
        },
        buttonIconPrefix: {
            type: IconType.FONTAWSOME,
            class: 'fas fa-trash-alt',
            extraClass: 'action-icon',
        },
    };

    public backButton: IButtonGeneratorInput = {
        buttonName: 'Back',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.STROKED,
        function: () => {
            this.multiStepFormService.stepData
                .get(this.modalInjectedData.modalId)
                .set(2, {
                    formSections: this.formSectionsInput,
                    file: this.uploadedFile,
                });
            this.multiStepFormService.previousStep(
                this.modalInjectedData.modalId
            );
        },
    };

    public SaveAsDraftButton: IButtonGeneratorInput = {
        buttonName: 'Save as Draft',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.STROKED,
        showLoader: true,
        function: (buttonRef: IButtonGeneratorInput) => {
            if (this.runValidationBeforeAction()) {
                buttonRef.loader = true;
                this.multiStepFormService.stepData
                    .get(this.modalInjectedData.modalId)
                    .set(2, {
                        formSections: this.formSectionsInput,
                        file: this.uploadedFile,
                    });
                this.modalInjectedData.data['handleSubmit'](
                    buttonRef,
                    this.modalInjectedData.data?.['formData'],
                    this.modalInjectedData.data?.['action']
                );
            }
        },
    };

    public NextButton: IButtonGeneratorInput = {
        buttonName: 'Next',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        function: () => {
            if (this.runValidationBeforeAction()) {
                this.multiStepFormService.stepData
                    .get(this.modalInjectedData.modalId)
                    .set(2, {
                        formSections: this.formSectionsInput,
                        file: this.uploadedFile,
                    });

                this.multiStepFormService.nextStep(
                    this.modalInjectedData.modalId
                );
            }
        },
    };

    private runValidationBeforeAction() {
        if (
            (this.isEdit &&
                !this.uploadedFileUrlPreSaved &&
                !this.uploadedFile) ||
            (!this.isEdit && !this.uploadedFile)
        ) {
            this.modalService.openInfoModal({
                infoHeading: 'Alert',
                content: [
                    {
                        data: ['Please select an image to continue'],
                        type: 'PARAGRAPH',
                        listStyleType: 'none',
                    },
                ],
            });
            return false;
        } else if (
            this.uploadedFile &&
            this.uploadedFile.size / 1024 / 1024 > 2
        ) {
            this.modalService.openInfoModal({
                infoHeading: 'Alert',
                content: [
                    {
                        data: [
                            'Warning: The selected media is of large size, this might take time to load {Suggested size: 2MB}',
                        ],
                        type: 'PARAGRAPH',
                        listStyleType: 'none',
                    },
                ],
            });
            return false;
        }
        return true;
    }

    public selectedStep: number | null = null;
    public formSectionsInput: ISignupFormStepConfigType[] = [];

    constructor(
        public whitelabelingService: WhiteLabelingService,
        private domSanitizer: DomSanitizer,
        private dialog: MatDialog,
        private modalInjectedData: ModalInjectedData,
        private modalService: ModalService,
        private httpClient: HttpClient,
        private multiStepFormService: MultiStepFormService
    ) {
        this.modalService.modalData.modalHeightVh = 80;
        this.modalService.modalData.modalWidthVw = 95;
        this.modalService.modalData.modalAutoHeight = true;
        this.modalService.modalData.modalAutoWidth = true;
        this.isEdit = this.modalInjectedData.data['isEdit'] || false;
        const formData = this.modalInjectedData.data.formData;
        this.initializeForm(formData);
    }

    private initializeForm(formData?: CustomSignUpFormResponseType) {
        const savedFormSectionsData = formData?.formSections;
        const defaultFieldsName = defaultFieldsSourceTemplate.map(
            (defaultEl) => defaultEl.name
        );
        savedFormSectionsData?.map((step) => {
            step.fields = step?.fields?.map((field) => {
                const fieldClone: ISignUpFieldConfigType = {
                    label: field?.label,
                    placeHolder: field?.placeHolder,
                    name: field?.name,
                    fieldType: field?.fieldType,
                    required: field?.required,
                    listData: field?.listData || [],
                    maxValue: field?.maxValue || 0,
                };
                if (defaultFieldsName?.includes(fieldClone.name)) {
                    fieldClone['isDefaultField'] = true;
                }
                return fieldClone;
            });
        });

        const formDataSavedInService = this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            ?.get(2);
        this.formSectionsInput = formDataSavedInService?.formSections ||
            savedFormSectionsData || [
                {
                    formName: 'Step 1',
                    fields: this.defaultFieldsStatus.map((field) => ({
                        fieldType: field.fieldType,
                        label: field.label,
                        name: field.name,
                        placeHolder: field.placeHolder,
                        required: field.required,
                        isDefaultField: field.isDefaultField,
                        maxValue: field?.maxValue || 0,
                    })),
                },
            ];
        if (formDataSavedInService?.file) {
            this.uploadedFile = formDataSavedInService.file;
        } else if (formData?.imageS3Url) {
            if (this.isEdit) {
                this.uploadedFileUrlPreSaved = formData.imageS3Url;
            } else {
                this.httpClient
                    .get(formData.imageS3Url, {
                        responseType: 'blob',
                    })
                    .subscribe({
                        next: (imageFile) => {
                            this.uploadedFile = imageFile as Blob;
                        },
                    });
            }
        }
    }

    ngOnInit(): void {}

    get whiteLableData() {
        return this.whitelabelingService.whiteLableData;
    }

    get currentSelectedStepFieldList() {
        return this.formSectionsInput[this.selectedStepIndex].fields;
    }

    get defaultAddedFieldInCurrentStep() {
        return this.currentSelectedStepFieldList.filter((el) => {
            if (
                defaultFieldsSourceTemplate
                    .map((defaultEl) => defaultEl.name)
                    .includes(el.name)
            ) {
                return true;
            }
            return false;
        });
    }

    public addNewField(el: (typeof FieldTypeList)[number]) {
        this.handleClickFeildEdit({
            field: {
                fieldType: el.value,
                label: '',
                name: '',
                placeHolder: '',
                required: false,
            },
            isCreateNewField: true,
        });
    }

    public handleOndrop(ev: CdkDragDrop<ISignUpFieldConfigType>) {
        moveItemInArray(
            this.currentSelectedStepFieldList,
            ev.previousIndex,
            ev.currentIndex
        );
    }

    public stepAddNew(index: number): void {
        this.formSectionsInput.splice(index + 1, 0, {
            formName: `Step`,
            fields: [],
        });
    }

    public editStepPencil(index: number): void {
        this.isEditStepMode = true;
        this.selectedStepIndex = index;
    }

    public clickStepDelete(index: number): void {
        this.formSectionsInput.splice(index, 1);
    }

    public addDefaultFieldIconHandle(fieldIndex: number) {
        this.defaultFieldsStatus[fieldIndex].touched = true;
        this.defaultFieldsStatus[fieldIndex].isAddedInCurrentStep = true;
    }

    public removeDefaultFieldIconHandle(fieldIndex: number) {
        this.defaultFieldsStatus[fieldIndex].touched = true;
        this.defaultFieldsStatus[fieldIndex].isAddedInCurrentStep = false;
    }

    public handleClickFeildEdit({
        field,
        isCreateNewField,
        fieldIndex,
    }: {
        field?: ISignUpFieldConfigType;
        isCreateNewField?: boolean;
        fieldIndex?: number;
    }) {
        const alreadyTakenFieldNames: string[] = [];
        this.formSectionsInput.forEach((section, stepIndex) => {
            section?.fields?.forEach((field, index) => {
                if (
                    isCreateNewField ||
                    !(
                        index === fieldIndex &&
                        stepIndex === this.selectedStepIndex
                    )
                ) {
                    alreadyTakenFieldNames.push(field.name);
                }
            });
        });

        let component;
        switch (field.fieldType) {
            case FilterType.TIME:
                component = FieldSettingTimeComponent;
                break;
            case FilterType.DATE:
                component = FieldSettingDateComponent;
                break;
            case FilterType.DROPDOWN_MULTIPLE:
            case FilterType.DROPDOWN_SINGLE:
                component = FieldSettingDropdownComponent;
                break;
            default:
                component = FieldSettingGenericComponent;
        }
        const dialogRef = this.dialog.open(component, {
            data: {
                name: field.name || '',
                label: field.label || '',
                maxValue: field?.['maxValue'] || 0,
                required: !!field.required,
                isDefaultField: !!field.isDefaultField,
                placeHolder: field?.placeHolder || '',
                listData: field?.['listData'],
                alreadyTakenFieldNames,
            },
        });

        dialogRef.afterClosed().subscribe((result?) => {
            if (result) {
                if (!isCreateNewField) {
                    field.name = result.name;
                    field.label = result.label;
                    field.required = result.required;
                    field['listData'] = result?.['listData'];
                    field['maxValue'] = result?.['maxValue'];
                    field['placeHolder'] = result.placeHolder || '';
                } else {
                    this.currentSelectedStepFieldList.push({
                        label: result.label,
                        name: result.name,
                        required: result.required,
                        fieldType: field.fieldType,
                        placeHolder: field.placeHolder || '',
                        ['listData']: result?.['listData'],
                        maxValue: result?.['maxValue'] || 0,
                    });
                }
            }
        });
    }

    public handleClickFeildDelete(fieldIndex: number) {
        this.formSectionsInput[this.selectedStepIndex].fields.splice(
            fieldIndex,
            1
        );
    }

    public handleSubmitDefaultFieldsAction: IButtonGeneratorInput = {
        buttonName: 'Done',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.STROKED,
        function: () => {
            const touchedDefaultFields = this.defaultFieldsStatus.filter(
                (el) => el.touched
            );
            const fieldNamesToRemove = touchedDefaultFields.map(
                (field) => field.name
            );
            this.formSectionsInput = this.formSectionsInput.map((section) => {
                section.fields = section.fields.filter(
                    (field) => !fieldNamesToRemove.includes(field.name)
                );
                return section;
            });
            const fieldsToAdd = touchedDefaultFields.filter(
                (el) => el.isAddedInCurrentStep
            );
            fieldsToAdd.forEach((field) => {
                this.currentSelectedStepFieldList.push({
                    name: field.name,
                    fieldType: field.fieldType,
                    label: field.label,
                    placeHolder: field.placeHolder,
                    required: field.required,
                    isDefaultField: field.isDefaultField,
                });
            });
            this.defaultFieldsStatus = Helper.cloneDeep(
                defaultFieldsSourceTemplate
            );
            this.isShowAddDefaultFieldMenu = false;
        },
    };

    public onFileSelected(file: File) {
        this.uploadedFile = file;
    }
}

const FieldTypeList: {
    label: string;
    value: CustomSignUpFieldType;
}[] = [
    {
        label: 'Text',
        value: FilterType.TEXT,
    },
    {
        label: 'Number',
        value: FilterType.NUMBER,
    },
    {
        label: 'Alphabetic',
        value: 'ALPHABETIC',
    },
    {
        label: 'Alpha-numeric',
        value: 'ALPHA_NUMERIC',
    },
    {
        label: 'Email',
        value: FilterType.EMAIL,
    },
    {
        label: 'Password',
        value: FilterType.PASSWORD,
    },
    {
        label: 'Date',
        value: FilterType.DATE,
    },
    {
        label: 'Time',
        value: FilterType.TIME,
    },
    {
        label: 'Single-select Dropdown',
        value: FilterType.DROPDOWN_SINGLE,
    },
    {
        label: 'Multi-select Dropdown',
        value: FilterType.DROPDOWN_MULTIPLE,
    },
];

interface ISignupFormStepConfigType {
    formName: string;
    fields: ISignUpFieldConfigType[];
}

interface ISignUpFieldConfigType extends ISignUpFieldTypeResponse {
    isDefaultField?: boolean;
}

const defaultFieldsSourceTemplate: {
    label: string;
    placeHolder: string;
    name: string;
    fieldType: CustomSignUpFieldType;
    required: boolean;
    touched: boolean;
    isAddedInCurrentStep: boolean;
    isDefaultField: boolean;
    maxValue?: number;
}[] = [
    {
        label: 'Domain ID',
        placeHolder: 'Domain ID',
        name: 'domainId',
        fieldType: FilterType.TEXT,
        required: true,
        touched: false,
        isAddedInCurrentStep: false,
        isDefaultField: true,
        maxValue: 0,
    },
    {
        label: 'Company Name',
        placeHolder: 'Enter Company Name',
        name: 'companyName',
        fieldType: FilterType.TEXT,
        required: true,
        touched: false,
        isAddedInCurrentStep: false,
        isDefaultField: true,
        maxValue: 0,
    },
    {
        label: 'Email ID',
        placeHolder: 'Enter Email ID',
        name: 'email',
        fieldType: FilterType.EMAIL,
        required: true,
        touched: false,
        isAddedInCurrentStep: false,
        isDefaultField: true,
        maxValue: 0,
    },
    {
        label: 'Password',
        placeHolder: 'Enter Password',
        name: 'password',
        fieldType: FilterType.PASSWORD,
        required: true,
        touched: false,
        isAddedInCurrentStep: false,
        isDefaultField: true,
        maxValue: 0,
    },
    {
        label: 'First name',
        placeHolder: 'Enter first name',
        name: 'firstName',
        fieldType: FilterType.TEXT,
        required: true,
        touched: false,
        isAddedInCurrentStep: false,
        isDefaultField: true,
        maxValue: 0,
    },
    {
        label: 'Last name',
        placeHolder: 'Enter last name',
        name: 'lastName',
        fieldType: FilterType.TEXT,
        required: true,
        touched: false,
        isAddedInCurrentStep: false,
        isDefaultField: true,
        maxValue: 0,
    },
];
