<form class="form" *ngIf="!reportLoader">
    <p class="extra-message" *ngIf="isShowMessage">To include widgets within a report rule, please ensure the 'Report Management' widget is added and utilized in the designated view containing all necessary widgets.</p>
    <div class="form-data"  [style.cursor] = "isReadAccess? 'not-allowed' : '' " (keydown)="handleKeyDown($event)" [attr.tabindex]="isReadAccess?'-1' : '0'" >
        <app-form-generator
            [formGeneratorInput]="reportNameFormGenInput"
            (formGroupRef)="reportNameFormGroup = $event"
        ></app-form-generator>
        <app-widget-selector
            [supportedFormats]="[SupportedFormat.REPORT]"
            [selectedMap]="widgetsSelectedMap"
            [selectedIds]="widgetsSelected"
            [limit]="GlobalConfiguration.REPORT_LIMIT"
            [apiUrl]="ApiUrls.GOVERNANCE_SIDE_MENU_DATA_API_PATH"
            [viewId]="configCacheService.viewId"
            (widgetsSelectedIds)="
                widgetsSelected = $event; noWidgetsSelected = false
            "
            (widgetsSelectedMap)="widgetsSelectedMap = $event"
            [getAddedByUser] = "getAddedByUser"
            [disable]="isReadAccess? true : false"
        ></app-widget-selector>
        <div class="no-widgets" *ngIf="noWidgetsSelected">
            <i class="fas fa-exclamation-triangle color-warn"></i>
            Select atleast one widget
        </div>
    </div>
    <div class="form-controls">
        <app-button-generator
            *ngIf="buttonGenInput"
            [buttonGenInput]="buttonGenInput"
        ></app-button-generator>
    </div>
</form>
<div class="overlay" *ngIf="reportLoader">
    <div class="overlay-content">
      <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
    </div>
  </div>
