import { Component, OnInit } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'app-aggrid-tooltip',
    templateUrl: './aggrid-tooltip.component.html',
    styleUrls: ['./aggrid-tooltip.component.sass']
})
export class AggridTooltipComponent implements OnInit, ITooltipAngularComp {
    params: any;
    data: any;
    value = null;
    constructor() {}

    ngOnInit() {}

    agInit(params): void {
        this.params = params ? params : null;
        this.value = this.params ? this.params.value : null;
        this.data = this.params
            ? params.api.getDisplayedRowAtIndex(params.rowIndex).data
            : null;
    }
}
