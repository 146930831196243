import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SubSink } from 'subsink';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import {
    DropdownSingleFieldAppearance,
    RadioField,
    TextFieldAppearance,
} from 'src/app/shared/enums/AppearanceType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import {
    CustomSignUpActionEnum,
    CustomSignUpFormResponseType,
    CustomSignUpFormStepOneType,
} from 'src/app/shared/interfaces/ICustomSignTypes';
import { IWidgetInfo } from 'src/app/shared/interfaces/widget/IWidgetInfo';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';

@Component({
    selector: 'app-signup-config-step-one',
    templateUrl: './signup-config-step-one.component.html',
    styleUrls: ['./signup-config-step-one.component.sass'],
})
export class SignupConfigStepOneComponent implements AfterViewInit, OnDestroy {
    private subSink = new SubSink();
    private widgetInfo: IWidgetInfo;
    public formGroup: FormGroup = null;
    public formGeneratorInput: IFormGeneratorInput = null;

    constructor(
        private modalInjectedData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService
    ) {
        this.handleOnChangeSignUpActionControl =
            this.handleOnChangeSignUpActionControl.bind(this);
        this.widgetInfo = this.modalInjectedData.data.widgetInfo;
        const formData = this.modalInjectedData.data.formData;
        this.initializeForm(formData);
    }

    private initializeForm(formData?: CustomSignUpFormResponseType) {
        const initialFormData: CustomSignUpFormStepOneType | undefined =
            this.multiStepFormService.stepData
                .get(this.modalInjectedData.modalId)
                .get(1) ||
            (formData && {
                name: formData.name,
                signUpAction: formData.signUpAction,
                url: formData.url,
                auditId: formData.auditId,
                defaultViewId: formData.viewId,
            });

        this.formGeneratorInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: {
                buttonName: 'Next',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                function: () => {
                    this.multiStepFormService.stepData
                        .get(this.modalInjectedData.modalId)
                        .set(1, this.formGroup.value);
                    this.multiStepFormService.nextStep(
                        this.modalInjectedData.modalId
                    );
                },
            },
            fields: [
                {
                    name: 'name',
                    value: initialFormData?.name || '',
                    fieldType: FilterType.TEXT,
                    label: 'Sign up form name',
                    required: true,
                    placeholder: 'Enter here',
                    appearance: TextFieldAppearance.TYPE_3,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Sign up form name is required',
                        },
                        {
                            validator: CustomValidators.keyAlreadyExists(
                                this.modalInjectedData.data.alreadyUsedNameList
                            ),
                            errorMessage: 'Given sign up form name is already taken',
                        },
                    ],
                },
                {
                    name: 'url',
                    value: initialFormData?.url || '',
                    fieldType: FilterType.TEXT,
                    label: 'Page URL',
                    required: true,
                    placeholder: 'https://www.loremipsum',
                    appearance: TextFieldAppearance.TYPE_3,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Page URL is required',
                        },
                        {
                            validator: CustomValidators.url,
                            errorMessage: 'Please enter a valid URL',
                        },
                        {
                            validator: CustomValidators.keyAlreadyExists(
                                this.modalInjectedData.data.alreadyUsedUrlList
                            ),
                            errorMessage: 'Given signup url is already taken',
                        },
                    ],
                },
                {
                    name: 'signUpAction',
                    value: initialFormData?.signUpAction,
                    fieldType: FilterType.RADIO,
                    label: 'Select Preference',
                    placeholder: 'Select Preference',
                    listData: [
                        {
                            id: CustomSignUpActionEnum.CREATE_CUSTOMER,
                            label: 'Manage View',
                        },
                        {
                            id: CustomSignUpActionEnum.ISSUE_ASSESSMENT,
                            label: 'Issue Audit',
                        },
                        {
                            id: CustomSignUpActionEnum.SEND_EMAIL,
                            label: 'Send email',
                        },
                    ],
                    appearance: RadioField.TYPE_3,
                    fieldStyle: {
                        width: '100%',
                        display: 'flex',
                        alignItems: 'flex-start',
                        marginTop: '1.8rem',
                    },
                },
                {
                    apiInfo:
                        this.widgetInfo.additionalApisForWidget[
                            'listViewsForSignUp'
                        ],
                    name: 'defaultViewId',
                    value: initialFormData?.defaultViewId || '',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'View',
                    placeholder: 'Manage the view for tenents',
                    required: true,
                    showKey: 'name',
                    getKey: 'id',
                    appearance: DropdownSingleFieldAppearance.TYPE_3,
                    showLabel: true,
                    fieldStyle: {
                        display: 'none',
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'View is required',
                        },
                    ],
                    disabled: false,
                },
                {
                    apiInfo:
                        this.widgetInfo.additionalApisForWidget[
                            'listAuditsForSignUp'
                        ],
                    showKey: 'assessmentName',
                    getKey: 'assessmentId',
                    name: 'auditId',
                    value: initialFormData?.auditId || '',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'Audit',
                    placeholder: 'Select audit to issue',
                    required: true,
                    appearance: DropdownSingleFieldAppearance.TYPE_3,
                    showLabel: true,
                    fieldStyle: {
                        display: 'none',
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'View is required',
                        },
                    ],
                    disabled: false,
                },
            ],
        };
    }

    ngAfterViewInit(): void {
        if (this.formGroup) {
            const signUpActionControl = this.formGroup.get('signUpAction');
            this.handleOnChangeSignUpActionControl(signUpActionControl.value);
            this.subSink.sink = signUpActionControl.valueChanges.subscribe(
                this.handleOnChangeSignUpActionControl
            );
        }
    }

    private handleOnChangeSignUpActionControl(value: string | unknown) {
        if (value === CustomSignUpActionEnum.ISSUE_ASSESSMENT) {
            this.formGeneratorInput.fields[3].fieldStyle = {
                display: 'none',
            };
            this.formGeneratorInput.fields[3].required = false;
            this.formGeneratorInput.fields[3].disabled = true;
            this.formGroup.get('defaultViewId').disable();
            this.formGeneratorInput.fields[4].fieldStyle = {
                width: '100%',
            };
            this.formGeneratorInput.fields[4].required = true;
            this.formGeneratorInput.fields[4].disabled = false;
            this.formGroup.get('auditId').enable();
        } else if (value === CustomSignUpActionEnum.CREATE_CUSTOMER) {
            this.formGeneratorInput.fields[3].fieldStyle = {
                width: '100%',
            };
            this.formGeneratorInput.fields[3].required = true;
            this.formGeneratorInput.fields[3].disabled = false;
            this.formGroup.get('defaultViewId').enable();
            this.formGeneratorInput.fields[4].fieldStyle = {
                display: 'none',
            };
            this.formGeneratorInput.fields[4].required = false;
            this.formGeneratorInput.fields[4].disabled = true;
            this.formGroup.get('auditId').disable();
        } else {
            this.formGeneratorInput.fields[3].fieldStyle = {
                display: 'none',
            };
            this.formGeneratorInput.fields[3].required = false;
            this.formGeneratorInput.fields[3].disabled = true;
            this.formGroup.get('defaultViewId').disable();
            this.formGeneratorInput.fields[4].fieldStyle = {
                display: 'none',
            };
            this.formGeneratorInput.fields[4].required = false;
            this.formGeneratorInput.fields[4].disabled = true;
            this.formGroup.get('auditId').disable();
        }
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
