import { AfterViewInit, Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import * as $ from 'jquery';
import { Helper } from 'src/app/shared/classes/Helper';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import { IRegionalApiResponse } from 'src/app/shared/interfaces/api/portlets/IRegionalApiResponse';
import { IGraphData } from 'src/app/shared/interfaces/graph/IGraphData';
import { IColumnData } from 'src/app/shared/interfaces/table-generator/IColumnData';
import { Linking } from '../../../../classes/Linking';
import { GraphType } from './../../../../enums/GraphType';
import { IconType } from './../../../../enums/IconType';
import { IIcon } from './../../../../interfaces/icon-data/IIcon';
import { ITableGeneratorInput } from './../../../../interfaces/table-generator/ITableGeneratorInput';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';

@Component({
    selector: 'app-regional',
    templateUrl: './regional.component.html',
    styleUrls: ['./regional.component.sass']
})
export class RegionalComponent implements OnInit, AfterViewInit {
    // Regional 1.0

    agGrid: GridOptions;
    ViewType = ViewType;
    widgetRef: Widget;
    rowData;
    filteredSearchData;
    colDefs;
    tableKeys;
    currencySymbol;
    mapData;

    agGridIcons = Helper.getAgGridIcons();
    defaultColDef = {
        editable: false,
        sortable: true,
        filter: true,
        resizable: true
    };
    gridApi;
    gridColumnApi;
    getRowStyle;
    arrowRightIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-chevron-right small-icon'
    };
    tableInputData: ITableGeneratorInput = {
        listExtraction: {
            type: 'DIRECT'
        },
        columns: [],
        tableHeight: 300
    };
    drillDownColor;
    graphData: IGraphData;

    constructor(
        widgetData: WidgetInjectedData,
        public modalService: ModalService
    ) {
        this.widgetRef = widgetData.widgetRef;
        this.drillDownColor = Helper.convertToHexIfHsl(
            Helper.getCssVarValue('drillDownColor')
        );
    }

    ngOnInit(): void {
        this.setUpBasics();
        if (this.widgetRef.lightState) {
            this.setUpRowClassRules();
        }
    }

    getFullAccess() {
        Helper.getFullAccess(
            this.modalService,
            this.widgetRef.widgetData.widgetInfo.portlet.type
        );
    }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(true);

        // Setting Visible Sections
        this.setVisibleSections();
        // Setting Aggrid Row styling
        this.getRowStyle = this.rowStyling.bind(this);
    }

    ngAfterViewInit() {
        this.widgetRef.isBindDataAssigned = false;
        this.widgetRef.setBindData(this.bindData.bind(this));
        this.widgetRef.setRedrawSection(this.redrawSection.bind(this));
    }
    setUpRowClassRules() {
        this.defaultColDef.sortable = false;
        this.defaultColDef.filter = false;
        Helper.setRowClassRules(this.widgetRef);
    }

    setVisibleSections() {
        const visibleSections: Set<ViewType> = new Set();
        if (
            this.widgetRef.widgetData.widgetInfo.defaultPref &&
            this.widgetRef.widgetData.widgetInfo.defaultPref !== ViewType.BOTH
        ) {
            visibleSections.add(
                this.widgetRef.widgetData.widgetInfo.defaultPref
            );
        } else {
            visibleSections.add(ViewType.TABLE);
            visibleSections.add(ViewType.GRAPH);
        }
        visibleSections.add(ViewType.LEGEND);
        this.widgetRef.visibleSections.next(visibleSections);
    }

    bindData(data: IRegionalApiResponse): void {
        if (!data) {
            return;
        }

        this.widgetRef.apiResponse = data;
        // if (data['insightState'] && !(this.insightClass.checkIfDemoOrPreview())) {
        //   this.insightClass.insightConfigState = InsightConfig.insightConfigStates[State[data['insightState']]];
        // }
        // this.limit = data['basicLimit'] ? data['basicLimit'] : this.insightClass.globalDataService.basicLimit;

        const widgetActions: IAction[] = [
            this.widgetRef.widgetConfigState.actions.regionalGraph,
            this.widgetRef.widgetConfigState.actions.table
        ];
        this.widgetRef.widgetActions.next(widgetActions);
        // this.insightClass.autoRenew = data['autoRenew'];

        if (data.dataMap && Object.keys(data.dataMap).length === 0) {
            this.widgetRef.extraMessage.next(Messages.NO_DATA_AVAILABLE);
            this.widgetRef.visibleSections.next(new Set());
            this.widgetRef.widgetActions.next([]);
            this.widgetRef.loadingData.next(false);
            return;
        }

        if (!this.widgetRef.visibleSections.value.size) {
            this.setVisibleSections();
        }
        this.drawTable(data.dataMap);

        this.mapData = JSON.parse(JSON.stringify(data.dataMap['output']));
        this.prepareMap();
        this.widgetRef.endLoader();
    }

    redrawSection(data: IRegionalApiResponse) {
        this.mapData = JSON.parse(JSON.stringify(data.dataMap['output']));
        this.prepareMap();
        if (
            this.widgetRef.visibleSections.value.has(ViewType.GRAPH) &&
            !this.widgetRef.visibleSections.value.has(ViewType.TABLE)
        ) {
            const widgetActions: IAction[] = [
                this.widgetRef.widgetConfigState.actions.regionalGraph,
                this.widgetRef.widgetConfigState.actions.table,
                this.widgetRef.widgetConfigState.actions.legend
            ];
            this.widgetRef.widgetActions.next(widgetActions);
        } else {
            const widgetActions: IAction[] = [
                this.widgetRef.widgetConfigState.actions.regionalGraph,
                this.widgetRef.widgetConfigState.actions.table
            ];
            this.widgetRef.widgetActions.next(widgetActions);
        }
    }

    prepareMap() {
        this.graphData = {
            graphType: GraphType.MAP,
            liteState: this.widgetRef.lightState,
            visibleSections: this.widgetRef.visibleSections,
            chartData: this.mapData,
            currencySymbol: this.currencySymbol
        };
    }

    drawTable(data) {
        this.colDefs = [];
        this.tableKeys = data['tableKeys']
            ? data['tableKeys']
            : Object.keys(data['table']);
        this.tableKeys.forEach((row, index) => {
            const obj = {
                headerName: row,
                field: row
            };
            if (index === 0) {
                obj['pinned'] = 'left';
                obj['lockPinned'] = true;
                obj['lockPosition'] = true;
                if ($(window).width() < 840) {
                    obj['width'] = 100;
                }
            }

            if (row.toLowerCase() === 'total') {
                obj['cellStyle'] = {
                    'font-weight': 'bold'
                };
            }

            if (
                data['tableCurrencySymbolToShow'] &&
                data['tableCurrencySymbolToShow'].includes(row)
            ) {
                obj['columnValueGetter'] = this.customValueGetter.bind(this);
                obj['cellRenderer'] = this.columnRenderer.bind(this);
            } else {
                obj['cellRenderer'] =
                    this.columnRendererWithoutCurrency.bind(this);
            }
            this.colDefs.push(obj);
        });

        this.currencySymbol = data['currencySymbol'];
        this.rowData = data['table'];
        this.filteredSearchData = data['table'];
        this.prepareTableData(data);
    }

    prepareTableData(data) {
        this.tableInputData.columns = [];

        this.colDefs.forEach((col) => {
            const columnObj: IColumnData = {
                columnName: col.headerName,
                columnKey: col.field,
                pinned: col.pinned
            };

            if (columnObj.pinned === 'left') {
                columnObj.maxWidth = 190;
            }

            if (
                data['tableCurrencySymbolToShow'] &&
                data['tableCurrencySymbolToShow'].includes(col.headerName)
            ) {
                columnObj['columnValueGetter'] =
                    this.customValueGetter.bind(this);
                columnObj['cellRenderer'] = this.columnRenderer.bind(this);
            } else {
                columnObj['cellRenderer'] =
                    this.columnRendererWithoutCurrency.bind(this);
            }
            this.tableInputData.columns.push(columnObj);
        });
        this.tableInputData.columns[this.tableInputData.columns.length - 1][
            'pinned'
        ] = 'right';
        this.tableInputData.columns[this.tableInputData.columns.length - 1][
            'cellStyle'
        ] = {
            'font-weight': 'bold'
        };
        this.tableInputData.columns[this.tableInputData.columns.length - 1][
            'maxWidth'
        ] = 100;
    }

    customValueGetter(row) {
        return Helper.parseCommaSeperatedStringToNumber(
            row.data[row.colDef.field]
        );
    }

    columnRenderer(item) {
        const itemValue = item.data[item.colDef.field];
        if (itemValue === null || itemValue === undefined) {
            return '-';
        } else {
            return this.currencySymbol
                ? this.currencySymbol + ' ' + itemValue
                : itemValue;
        }
    }

    columnRendererWithoutCurrency(item) {
        if (item.value === null || item.value === undefined) {
            return '-';
        } else {
            return item.value;
        }
    }

    onQuickFilterChanged(id: string) {
        this.agGrid.api.setQuickFilter(document.getElementById(id)['value']);
    }

    rowStyling(data) {
        if (
            this.widgetRef.widgetLinkingData &&
            this.widgetRef.widgetData &&
            this.widgetRef.widgetData.linking
        ) {
            if (
                this.widgetRef.widgetData.linking['drillDownType'] === 'column'
            ) {
                if (
                    data['data'][this.tableKeys[0]] &&
                    data['data'][this.tableKeys[0]].toLowerCase() === 'total'
                ) {
                    return { fontWeight: 'bold' };
                } else {
                    return {
                        cursor: 'pointer',
                        color: this.drillDownColor + ' !important'
                    };
                }
            } else if (
                this.widgetRef.widgetData.linking['drillDownType'] === 'row'
            ) {
                if (
                    this.widgetRef.widgetData.linking &&
                    this.widgetRef.widgetData.linking['legendRow'] &&
                    this.widgetRef.widgetData.linking['legendRow'][
                        data['data'][this.tableKeys[0]]
                    ]
                ) {
                    return {
                        cursor: 'pointer',
                        color: this.drillDownColor + ' !important'
                    };
                } else if (
                    data['data'][this.tableKeys[0]] &&
                    data['data'][this.tableKeys[0]].toLowerCase() === 'total'
                ) {
                    return { fontWeight: 'bold' };
                }
            }
        } else if (
            data['data'][this.tableKeys[0]] &&
            data['data'][this.tableKeys[0]].toLowerCase() === 'total'
        ) {
            return { fontWeight: 'bold' };
        }
        return '';
    }

    async tableRowClicked(slice) {
        if (
            this.widgetRef.widgetLinkingData &&
            this.widgetRef.widgetData.linking &&
            (this.widgetRef.widgetData.linking['legendRow'] ||
                this.widgetRef.widgetData.linking['legendColumn'])
        ) {
            //this.prevDefaultPref = this.defaultPref;
            const tempObj = Linking.prepareNextWidgetData(
                this.widgetRef,
                slice,
                this.tableKeys
            );
            if (tempObj) {
                tempObj['viewId'] = this.widgetRef.widgetLinkingData.viewId;
                tempObj[`widgetConfig`] = this.widgetRef.widgetConfigDetails;
                const currentLevel =
                    this.widgetRef.compareViewService.currentLevel[
                        this.widgetRef.widgetLinkingData.viewId
                    ] + 1;
                this.widgetRef.compareViewService.currentLevel[
                    this.widgetRef.widgetLinkingData.viewId
                ] = currentLevel;
                this.widgetRef.compareViewService.nextWidgetView.next(tempObj);
            }
        }
    }

    goBackDrillDown(home?, index?, isLast?) {
        if (isLast) {
            return;
        }
        if (home) {
            this.widgetRef.compareViewService.currentLevel[
                this.widgetRef.widgetLinkingData.viewId
            ] = 1;
        } else {
            const currentLevel =
                this.widgetRef.compareViewService.currentLevel[
                    this.widgetRef.widgetLinkingData.viewId
                ] - index;
            this.widgetRef.compareViewService.currentLevel[
                this.widgetRef.widgetLinkingData.viewId
            ] = currentLevel;
        }

        const tempObj = {};
        tempObj[this.widgetRef.widgetLinkingData.viewId] = true;
        this.widgetRef.compareViewService.previousWidgetView.next(tempObj);
    }
}
