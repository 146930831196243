import {
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output
} from '@angular/core';
import { IconType } from '../../enums/IconType';
import { GlobalDataService } from '../../services/global-data/global-data.service';
import { ApiUrls } from './../../../core/classes/ApiUrls';
import { HitApi } from './../../classes/HitApi';
import { AuthorizationType } from './../../enums/AuthorizationType';
import { IntegrationName } from './../../enums/IntegrationName';
import { RequestType } from './../../enums/RequestType';
import { IHitApi } from './../../interfaces/hit-api/IHitApi';
import { IIcon } from './../../interfaces/icon-data/IIcon';
import { HttpService } from './../../services/http/http-main/http.service';

@Component({
    selector: 'app-common-integrations-index',
    templateUrl: './common-integrations-index.component.html',
    styleUrls: ['./common-integrations-index.component.sass']
})
export class CommonIntegrationsIndexComponent implements OnInit {
    @Input() integrations: IntegrationName[];
    @Input() initialSelection: IntegrationName[];
    @Input() isEmailDisabled?: boolean = false;
    @Input() showSearchBox: boolean = true;
    @Output() selected = new EventEmitter<
        Map<
            IntegrationName,
            {
                iconData: IIcon;
                name: string;
                integrationData;
                integrated: boolean;
            }
        >
    >();
    @Output() integrationInfoFunc = new EventEmitter();
    selectedIntegrations: Set<IntegrationName> = new Set();
    IGNORED_STATUS = [IntegrationName.DOWNLOAD_REPORT, IntegrationName.EMAIL];
    INTEGRATION_STATUS_V2 = [IntegrationName.FRESHSERVICE];

    IntegrationName = IntegrationName;

    integrationsInfo = new Map<
        IntegrationName,
        {
            iconData: IIcon;
            name: string;
            integrationData;
            integrated: boolean;
            checked: boolean;
        }
    >();

    filteredIntegrations: IntegrationName[] = null;
    constructor(
        private httpService: HttpService,
        private ngZone: NgZone,
        public globalDataService: GlobalDataService
    ) {
        // Download Report
        this.integrationsInfo.set(IntegrationName.DOWNLOAD_REPORT, {
            iconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-download'
            },
            name: 'Download Report',
            integrationData: {},
            integrated: true,
            checked: true
        });

        // Email
        this.integrationsInfo.set(IntegrationName.EMAIL, {
            iconData: {
                type: IconType.FONTAWSOME,
                class: 'far fa-envelope'
            },
            name: 'Email',
            integrationData: {},
            integrated: true,
            checked: true
        });

        // Slack
        this.integrationsInfo.set(IntegrationName.SLACK, {
            iconData: {
                type: IconType.FONTAWSOME,
                class: 'fab fa-slack'
            },
            name: 'Slack',
            integrationData: undefined,
            integrated: false,
            checked: false
        });

        // O365
        this.integrationsInfo.set(IntegrationName.O365, {
            iconData: {
                type: IconType.FONTAWSOME,
                class: 'fab fa-microsoft'
            },
            name: 'O365',
            integrationData: undefined,
            integrated: false,
            checked: false
        });

        // Gmail
        this.integrationsInfo.set(IntegrationName.GMAIL, {
            iconData: {
                type: IconType.FONTAWSOME,
                class: 'fab fa-google'
            },
            name: 'Gmail',
            integrationData: undefined,
            integrated: false,
            checked: false
        });

        // Freshdesk
        this.integrationsInfo.set(IntegrationName.FRESHDESK, {
            iconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            name: 'Freshdesk',
            integrationData: undefined,
            integrated: false,
            checked: false
        });

        // Freshservice
        this.integrationsInfo.set(IntegrationName.FRESHSERVICE, {
            iconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            name: 'Freshservice',
            integrationData: undefined,
            integrated: false,
            checked: false
        });

        // Servicenow
        this.integrationsInfo.set(IntegrationName.SERVICENOW, {
            iconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            name: 'ServiceNow',
            integrationData: undefined,
            integrated: false,
            checked: false
        });

        // Zendesk
        this.integrationsInfo.set(IntegrationName.ZENDESK, {
            iconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            name: 'Zendesk',
            integrationData: undefined,
            integrated: false,
            checked: false
        });

        // SNS
        this.integrationsInfo.set(IntegrationName.SNS, {
            iconData: {
                type: IconType.FONTAWSOME,
                class: 'far fa-envelope'
            },
            name: 'SNS',
            integrationData: undefined,
            integrated: false,
            checked: false
        });

        //JIRADESK
        this.integrationsInfo.set(IntegrationName.JIRADESK, {
            iconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            name: 'Jiradesk',
            integrationData: undefined,
            integrated: false,
            checked: false
        });

        //AUTOTASK
        this.integrationsInfo.set(IntegrationName.AUTOTASK, {
            iconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            name: 'Autotask',
            integrationData: undefined,
            integrated: false,
            checked: false
        });

        this.integrationsInfo.set(IntegrationName.AUTOTASK_CHILD, {
            iconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            name: 'Autotask (Child Tenant Integration)',
            integrationData: undefined,
            integrated: false,
            checked: false
        });

        this.integrationsInfo.set(IntegrationName.AUTOTASK_PARENT, {
            iconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            name: 'Autotask (Parent Tenant Integration)',
            integrationData: undefined,
            integrated: false,
            checked: false
        });

        //OTRS
        this.integrationsInfo.set(IntegrationName.OTRS, {
            iconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            name: 'OTRS',
            integrationData: undefined,
            integrated: false,
            checked: false
        });

        this.integrationsInfo.set(IntegrationName.OTRS_USER, {
            iconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            name: 'OTRS (User Tenant Integration)',
            integrationData: undefined,
            integrated: false,
            checked: false
        });

        this.integrationsInfo.set(IntegrationName.OTRS_PARENT, {
            iconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            name: 'OTRS (Parent Tenant Integration)',
            integrationData: undefined,
            integrated: false,
            checked: false
        });

        //CASDM
        this.integrationsInfo.set(IntegrationName.CASDM, {
            iconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            name: 'CASDM',
            integrationData: undefined,
            integrated: false,
            checked: false
        });

        this.integrationsInfo.set(IntegrationName.CASDM_CHILD, {
            iconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            name: 'CASDM (Child Tenant Integration)',
            integrationData: undefined,
            integrated: false,
            checked: false
        });

        this.integrationsInfo.set(IntegrationName.CASDM_PARENT, {
            iconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            name: 'CASDM (Parent Tenant Integration)',
            integrationData: undefined,
            integrated: false,
            checked: false
        });

        this.integrationsInfo.set(IntegrationName.SALESFORCE, {
            iconData: {
                type: null,
                class: ''
            },
            name: 'Salesforce',
            integrationData: undefined,
            integrated: false,
            checked: false
        });
    }

    ngOnInit(): void {
        this.filteredIntegrations = this.integrations;
        if (this.initialSelection && this.initialSelection.length) {
            this.selectedIntegrations = new Set(this.initialSelection);
        }
        this.setIntegrationStatus();
        this.integrationInfoFunc.emit(this.getIntegrationData.bind(this));
    }
    setIntegrationStatus() {
        this.integrations.forEach((integration) => {
            if (!this.IGNORED_STATUS.includes(integration)) {
                let url = `${ApiUrls.INTEGRATION_STATUS + integration}`;
                if (this.INTEGRATION_STATUS_V2.includes(integration)) {
                    url = ApiUrls.INTEGRATION_STATUS_V2.replace(
                        '{integration-type}',
                        IntegrationName.FRESHSERVICE
                    );
                }

                if (integration === IntegrationName.SALESFORCE) {
                    url = ApiUrls.SALESFORCE_INTEGRATION_STATUS_CHECK;
                }

                const apiArgs: IHitApi = {
                    url: url,
                    intactUrl: url,
                    requestType: RequestType.GET,
                    input: {},
                    function: (response) => {
                        const integrated = this.checkIntegrationStatus(
                            response,
                            integration
                        );
                        this.integrationsInfo.get(integration).integrationData =
                            response;
                        this.integrationsInfo.get(integration).integrated =
                            integrated;
                        this.integrationsInfo.get(integration).checked =
                            integrated ? true : false;

                        this.checkInitialEmit(integration);
                    },
                    errorFunction: (error) => {
                        this.integrationsInfo.get(integration).integrationData =
                            null;
                        this.integrationsInfo.get(integration).checked = false;
                        this.checkInitialEmit(integration);
                    },
                    uniqueIdentity: Symbol(),
                    config: {
                        authorization: AuthorizationType.BEARER_TOKEN
                    }
                };
                new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
            }
        });
    }

    checkInitialEmit(integrationName: IntegrationName) {
        if (this.initialSelection && this.initialSelection.length) {
            if (
                this.initialSelection.includes(integrationName) &&
                !this.integrationsInfo.get(integrationName).integrated
            ) {
                this.initialSelection = this.initialSelection.filter(
                    (integration) => integration !== integrationName
                );
                this.selectedIntegrations = new Set(this.initialSelection);
            }

            let emit = true;
            this.initialSelection.every((integrationName) => {
                if (!this.integrationsInfo.get(integrationName).checked) {
                    emit = false;
                }
                return this.integrationsInfo.get(integrationName).checked;
            });

            if (emit) {
                this.emitSelectedValues();
            }
        }
    }

    checkIntegrationStatus(response, integration: IntegrationName): boolean {
        if (integration === IntegrationName.FRESHDESK) {
            if ('self' in response && response['self']['integration']) {
                return true;
            }
            if ('parent' in response && response['parent']['integration']) {
                return true;
            }
        } else if (integration === IntegrationName.FRESHSERVICE) {
            if (
                response['childIntegrationConfigured'] ||
                response['parentIntegrationConfigured']
            ) {
                return true;
            }
        } else if (integration === IntegrationName.ZENDESK) {
            if ('self' in response && response['self']['integration']) {
                return true;
            }
            if ('parent' in response && response['parent']['integration']) {
                return true;
            }
        } else if (integration === IntegrationName.JIRADESK) {
            if ('self' in response && response['self']['integration']) {
                return true;
            }
            if ('parent' in response && response['parent']['integration']) {
                return true;
            }
        } else if (integration === IntegrationName.AUTOTASK) {
            if (
                'parentIntegration' in response &&
                response['parentIntegration'] &&
                'childIntegration' in response &&
                !response['childIntegration'] &&
                'ownerIntegration' in response &&
                !response['ownerIntegration']
            ) {
                return true;
            } else if (
                'ownerIntegration' in response &&
                response['ownerIntegration']
            ) {
                this.integrations.splice(
                    this.integrations.indexOf(IntegrationName.AUTOTASK),
                    1
                );
                this.integrations.push(IntegrationName.AUTOTASK_CHILD);
                this.integrations.push(IntegrationName.AUTOTASK_PARENT);
                this.integrationsInfo.get(
                    IntegrationName.AUTOTASK_CHILD
                ).integrationData = response;

                this.integrationsInfo.get(
                    IntegrationName.AUTOTASK_PARENT
                ).integrationData = response;
                if (this.initialSelection.includes(IntegrationName.AUTOTASK)) {
                    this.initialSelection = this.initialSelection.filter(
                        (integration) =>
                            integration !== IntegrationName.AUTOTASK
                    );
                    this.initialSelection.push(IntegrationName.AUTOTASK_PARENT);
                    this.selectedIntegrations = new Set(this.initialSelection);
                }

                if (
                    'childIntegration' in response &&
                    response['childIntegration'] &&
                    'ownerIntegration' in response &&
                    response['ownerIntegration']
                ) {
                    // this.integrationsInfo.get(
                    //     IntegrationName.AUTOTASK_CHILD
                    // ).integrationData = response;
                    this.integrationsInfo.get(
                        IntegrationName.AUTOTASK_CHILD
                    ).integrated = true;
                    if (
                        this.initialSelection.includes(
                            IntegrationName.AUTOTASK_CHILD
                        )
                    ) {
                        this.integrationsInfo.get(
                            IntegrationName.AUTOTASK_CHILD
                        ).checked = true;
                    }
                }

                if (
                    'parentIntegration' in response &&
                    response['parentIntegration'] &&
                    'ownerIntegration' in response &&
                    response['ownerIntegration']
                ) {
                    // this.integrationsInfo.get(
                    //     IntegrationName.AUTOTASK_PARENT
                    // ).integrationData = response;
                    this.integrationsInfo.get(
                        IntegrationName.AUTOTASK_PARENT
                    ).integrated = true;
                    if (
                        this.initialSelection.includes(
                            IntegrationName.AUTOTASK_PARENT
                        )
                    ) {
                        this.integrationsInfo.get(
                            IntegrationName.AUTOTASK_PARENT
                        ).checked = true;
                    }
                }

                // if (
                //     'parentIntegration' in response &&
                //     !response['parentIntegration'] &&
                //     'childIntegration' in response &&
                //     !response['childIntegration']
                // ) {
                //     this.integrationsInfo.get(
                //         IntegrationName.AUTOTASK_PARENT
                //     ).checked = false;
                //     this.integrationsInfo.get(
                //         IntegrationName.AUTOTASK_CHILD
                //     ).checked = false;
                // }

                return false;
            }
        } else if (integration === IntegrationName.OTRS) {
            if (
                'parentIntegration' in response &&
                response['parentIntegration'] &&
                'userIntegration' in response &&
                !response['userIntegration'] &&
                'ownerIntegration' in response &&
                !response['ownerIntegration']
            ) {
                return true;
            } else if (
                'ownerIntegration' in response &&
                response['ownerIntegration']
            ) {
                this.integrations.splice(
                    this.integrations.indexOf(IntegrationName.OTRS),
                    1
                );
                this.integrations.push(IntegrationName.OTRS_USER);
                this.integrations.push(IntegrationName.OTRS_PARENT);
                this.integrationsInfo.get(
                    IntegrationName.OTRS_USER
                ).integrationData = response;

                this.integrationsInfo.get(
                    IntegrationName.OTRS_PARENT
                ).integrationData = response;

                if (this.initialSelection.includes(IntegrationName.OTRS)) {
                    this.initialSelection = this.initialSelection.filter(
                        (integration) => integration !== IntegrationName.OTRS
                    );
                    this.initialSelection.push(IntegrationName.OTRS_PARENT);
                    this.selectedIntegrations = new Set(this.initialSelection);
                }

                if (
                    'userIntegration' in response &&
                    response['userIntegration'] &&
                    'ownerIntegration' in response &&
                    response['ownerIntegration']
                ) {
                    this.integrationsInfo.get(
                        IntegrationName.OTRS_USER
                    ).integrated = true;
                    if (
                        this.initialSelection.includes(
                            IntegrationName.OTRS_USER
                        )
                    ) {
                        this.integrationsInfo.get(
                            IntegrationName.OTRS_USER
                        ).checked = true;
                    }
                }

                if (
                    'parentIntegration' in response &&
                    response['parentIntegration'] &&
                    'ownerIntegration' in response &&
                    response['ownerIntegration']
                ) {
                    this.integrationsInfo.get(
                        IntegrationName.OTRS_PARENT
                    ).integrated = true;
                    if (
                        this.initialSelection.includes(
                            IntegrationName.OTRS_PARENT
                        )
                    ) {
                        this.integrationsInfo.get(
                            IntegrationName.OTRS_PARENT
                        ).checked = true;
                    }
                }
                return false;
            }
        } else if (integration === IntegrationName.CASDM) {
            if (
                'parentIntegration' in response &&
                response['parentIntegration'] &&
                'childIntegration' in response &&
                !response['childIntegration'] &&
                'ownerIntegration' in response &&
                !response['ownerIntegration']
            ) {
                return true;
            } else if (
                'ownerIntegration' in response &&
                response['ownerIntegration']
            ) {
                this.integrations.splice(
                    this.integrations.indexOf(IntegrationName.CASDM),
                    1
                );
                this.integrations.push(IntegrationName.CASDM_CHILD);
                this.integrations.push(IntegrationName.CASDM_PARENT);
                this.integrationsInfo.get(
                    IntegrationName.CASDM_CHILD
                ).integrationData = response;

                this.integrationsInfo.get(
                    IntegrationName.CASDM_PARENT
                ).integrationData = response;

                if (this.initialSelection.includes(IntegrationName.CASDM)) {
                    this.initialSelection = this.initialSelection.filter(
                        (integration) => integration !== IntegrationName.CASDM
                    );
                    this.initialSelection.push(IntegrationName.CASDM_PARENT);
                    this.selectedIntegrations = new Set(this.initialSelection);
                }

                if (
                    'childIntegration' in response &&
                    response['childIntegration'] &&
                    'ownerIntegration' in response &&
                    response['ownerIntegration']
                ) {
                    this.integrationsInfo.get(
                        IntegrationName.CASDM_CHILD
                    ).integrated = true;
                    if (
                        this.initialSelection.includes(
                            IntegrationName.CASDM_CHILD
                        )
                    ) {
                        this.integrationsInfo.get(
                            IntegrationName.CASDM_CHILD
                        ).checked = true;
                    }
                }

                if (
                    'parentIntegration' in response &&
                    response['parentIntegration'] &&
                    'ownerIntegration' in response &&
                    response['ownerIntegration']
                ) {
                    this.integrationsInfo.get(
                        IntegrationName.CASDM_PARENT
                    ).integrated = true;
                    if (
                        this.initialSelection.includes(
                            IntegrationName.CASDM_PARENT
                        )
                    ) {
                        this.integrationsInfo.get(
                            IntegrationName.CASDM_PARENT
                        ).checked = true;
                    }
                }
                return false;
            }
        }

        const integrated =
            'integration' in response
                ? response['integration']
                : 'integrated' in response
                ? response['integrated']
                : false;
        return integrated;
    }

    emitSelectedValues() {
        const data = new Map<
            IntegrationName,
            {
                iconData: IIcon;
                name: string;
                integrationData;
                integrated: boolean;
            }
        >();
        this.selectedIntegrations.forEach((integration) => {
            data.set(integration, this.integrationsInfo.get(integration));
        });
        this.selected.emit(data);
    }

    getIntegrationData(integration: IntegrationName) {
        return this.integrationsInfo.get(integration);
    }
}
