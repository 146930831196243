import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class ControlValueInValidator extends CustomValidator {
    key = CustomValidatorKey.CONTROL_VALUE_IN;

    constructor(private values: string[]) {
        super();
    }
    validatorFunction(control: AbstractControl): ValidationErrors {
        if (this.values.includes(control.value)) {
            return null;
        }
        const obj = {};
        obj[this.key] = true;
        return obj;
    }
}
