<div
    *ngIf="
        filterInfo &&
        filterInfo.type === FilterType.DROPDOWN_SINGLE &&
        selectedValue
    "
    class="cell-container"
>
    <app-single-select-dropdown
        class="ag-grid-dropdown"
        [filterInfo]="filterInfo"
        [selectedValue]="selectedValue"
        (change)="dropDownValueChange($event)"
        [appendTo]="appendToClass"
        (dropDownRef)="dropDownRefFn($event)"
    >
    </app-single-select-dropdown>
</div>

<div
    *ngIf="
        filterInfo &&
        filterInfo.type === FilterType.DROPDOWN_MULTIPLE &&
        selectedValue
    "
    class="cell-container"
>
    <app-multi-select-dropdown
        class="ag-grid-dropdown"
        [filterInfo]="filterInfo"
        [selectedValues]="selectedValue"
        (change)="dropDownValueChange($event)"
        [appendTo]="appendToClass"
        [dummy]="isFieldDummy"
    ></app-multi-select-dropdown>
</div>
