import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { IntegrationName } from 'src/app/shared/enums/IntegrationName';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { UpdateAction } from 'src/app/shared/enums/UpdateAction';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IUpdateAction } from 'src/app/shared/interfaces/update-action/IUpdateAction';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { WidgetCreationService } from 'src/app/shared/services/widget-creation/widget-creation.service';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';
import { WidgetCacheService } from './../../../../services/cache/widget-cache/widget-cache.service';

@Component({
    selector: 'app-service-catalog-configuration',
    templateUrl: './service-catalog-configuration.component.html',
    styleUrls: ['./service-catalog-configuration.component.sass']
})
export class ServiceCatalogConfigurationComponent implements OnInit {
    widgetRef: Widget;
    integrationTypes = [
        {
            title: IntegrationName.SERVICENOW,
            icon: 'assets/integrations/servicenow-plugin.png'
        }
    ];
    integrationType = '';
    columnList = [];
    integratedItsmTools = [];
    stepData = {};
    serviceNowFormGenInput: IFormGeneratorInput = null;
    serviceNowFormGroup: FormGroup;
    otrsFormGenInput: IFormGeneratorInput = null;
    otrsFormGroup: FormGroup;
    autoTaskFormGenInput: IFormGeneratorInput = null;
    autoTaskFormGroup: FormGroup;
    casdmFormGenInput: IFormGeneratorInput = null;
    casdmFormGroup: FormGroup;
    zohoFormGenInput: IFormGeneratorInput = null;
    zohoFormGroup: FormGroup;
    freshServiceFormGenInput: IFormGeneratorInput = null;
    freshServiceConfigInput: IFormGeneratorInput = null;
    freshServiceConfigFormGroup: FormGroup;
    zendeskFormGenInput: IFormGeneratorInput;
    zendeskFormGroup: FormGroup;
    freshServiceFields = [];
    freshServiceFormGroup: FormGroup;
    freshServiceConfigurationList = [];
    salesforceFormGenInput: IFormGeneratorInput;
    salesforceFormGroup: FormGroup;
    salesforceConfigurationList = [];
    IntegrationName = IntegrationName;
    modalLoader = false;
    response;
    extraMessage = '';
    buttonInput: IButtonGeneratorInput;
    salesforceConfigResponse: any;
    zendeskWidgetId: string;
    zendeskFormId: string;
    updateControlInput: IUpdateAction;
    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };

    constructor(
        private modalInputData: ModalInjectedData,
        private httpService: HttpService,
        private ngZone: NgZone,
        private notificationService: NotificationsService,
        private modalService: ModalService,
        private listHttpService: ListHttpService,
        private widgetCacheService: WidgetCacheService,
        private widgetCreationService: WidgetCreationService
    ) {
        this.integrationTypes = [
            {
                title: IntegrationName.SERVICENOW,
                icon: 'assets/integrations/servicenow-plugin.png'
            },
            {
                title: IntegrationName.AUTOTASK,
                icon: 'assets/integrations/autotask.png'
            },
            {
                title: IntegrationName.OTRS,
                icon: 'assets/integrations/otrs.png'
            },
            {
                title: IntegrationName.CASDM,
                icon: 'assets/integrations/ca-logo.png'
            },
            {
                title: IntegrationName.ZOHO,
                icon: 'assets/integrations/zoho.png'
            },
            {
                title: IntegrationName.FRESHSERVICE,
                icon: 'assets/integrations/freshservice-plugin.png'
            },
            {
                title: IntegrationName.ZENDESK,
                icon: 'assets/integrations/zendesk-plugin.png'
            },
            {
                title: IntegrationName.SALESFORCE,
                icon: 'assets/integrations/salesforce.png'
            }
        ];
    }

    ngOnInit() {
        if (this.modalInputData && this.modalInputData.data) {
            this.stepData = this.modalInputData.data;
            this.widgetRef = this.stepData['widgetRef'];
        }

        this.setIntegrationData();
        this.stepData['columns'].forEach((element) => {
            this.columnList.push({
                id: element,
                label: element
            });
        });

        this.setUpBasics();
    }
    setUpBasics() {
        this.salesforceConfigurationValueChanged();
        this.serviceNowFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Short Description',
                    placeholder: 'Short Description Column',
                    name: 'shortDescription',
                    value: this.stepData['configuredColumnData']
                        ? this.stepData['configuredColumnData'][
                              'shortDescription'
                          ]
                        : '',
                    getKey: 'id',
                    listData: this.columnList,
                    apiInfo: null,
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This field is mandatory'
                        }
                    ]
                }
            ]
        };
        this.otrsFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Title Column',
                    placeholder: 'Tilte Column',
                    name: 'titleColumn',
                    value: this.stepData['configuredColumnData']
                        ? this.stepData['configuredColumnData']['titleColumn']
                        : '',
                    getKey: 'id',
                    listData: this.columnList,
                    apiInfo: null,
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This field is mandatory'
                        }
                    ]
                },
                {
                    label: 'Subject Column',
                    placeholder: 'Subject Column',
                    name: 'subjectColumn',
                    value: this.stepData['configuredColumnData']
                        ? this.stepData['configuredColumnData']['subjectColumn']
                        : '',
                    getKey: 'id',
                    listData: this.columnList,
                    apiInfo: null,
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This field is mandatory'
                        }
                    ]
                }
            ]
        };
        this.autoTaskFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Title Column',
                    placeholder: 'Title Column',
                    name: 'titleColumn',
                    value: this.stepData['configuredColumnData']
                        ? this.stepData['configuredColumnData']['titleColumn']
                        : '',
                    getKey: 'id',
                    listData: this.columnList,
                    apiInfo: null,
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This field is mandatory'
                        }
                    ]
                }
            ]
        };
        this.casdmFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Description Column',
                    placeholder: 'Description Column',
                    name: 'descriptionColumn',
                    value: this.stepData['configuredColumnData']
                        ? this.stepData['configuredColumnData'][
                              'descriptionColumn'
                          ]
                        : '',
                    getKey: 'id',
                    listData: this.columnList,
                    apiInfo: null,
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This field is mandatory'
                        }
                    ]
                }
            ]
        };
        this.zohoFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Name Column',
                    placeholder: 'Name Column',
                    name: 'nameColumn',
                    value: this.stepData['configuredColumnData']
                        ? this.stepData['configuredColumnData']['nameColumn']
                        : '',
                    getKey: 'id',
                    listData: this.columnList,
                    apiInfo: null,
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This field is mandatory'
                        }
                    ]
                },
                {
                    label: 'Subject Column',
                    placeholder: 'Subject Column',
                    name: 'subjectColumn',
                    value: this.stepData['configuredColumnData']
                        ? this.stepData['configuredColumnData']['subjectColumn']
                        : '',
                    getKey: 'id',
                    listData: this.columnList,
                    apiInfo: null,
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This field is mandatory'
                        }
                    ]
                },
                {
                    label: 'Cloud Column',
                    placeholder: 'Cloud Column',
                    name: 'cloudColumn',
                    value: this.stepData['configuredColumnData']
                        ? this.stepData['configuredColumnData']['cloudColumn']
                        : '',
                    getKey: 'id',
                    listData: this.columnList,
                    apiInfo: null,
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This field is mandatory'
                        }
                    ]
                },
                {
                    label: 'Category Column',
                    placeholder: 'Category Column',
                    name: 'categoryColumn',
                    value: this.stepData['configuredColumnData']
                        ? this.stepData['configuredColumnData'][
                              'categoryColumn'
                          ]
                        : '',
                    getKey: 'id',
                    listData: this.columnList,
                    apiInfo: null,
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This field is mandatory'
                        }
                    ]
                },
                {
                    label: 'Cloud Service Column',
                    placeholder: 'Cloud Service Column',
                    name: 'cloudServiceColumn',
                    value: this.stepData['configuredColumnData']
                        ? this.stepData['configuredColumnData'][
                              'cloudServiceColumn'
                          ]
                        : '',
                    getKey: 'id',
                    listData: this.columnList,
                    apiInfo: null,
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This field is mandatory'
                        }
                    ]
                }
            ]
        };

        if (this.response && !this.freshServiceConfigurationList.length) {
            this.response.forEach((element) => {
                if (element.key === IntegrationName.FRESHSERVICE) {
                    element.value.childConfigurationListing.forEach(
                        (element) => {
                            this.freshServiceConfigurationList.push({
                                id: element.listingWidgetId,
                                label: element.configurationName
                            });
                        }
                    );
                    element.value.parentConfigurationListing.forEach(
                        (element) => {
                            this.freshServiceConfigurationList.push({
                                id: element.listingWidgetId,
                                label: element.configurationName
                            });
                        }
                    );
                }
            });
        }
        this.freshServiceConfigInput = {
            formName: 'Freshservice',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'widgetId',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'Configuration',
                    placeholder: 'Select Configuration',
                    required: true,
                    value:
                        this.integrationType === IntegrationName.FRESHSERVICE &&
                        this.stepData['configuredColumnData']
                            ? this.stepData['configuredColumnData'][
                                  'configuration_widgetId'
                              ]
                            : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Configuration is required'
                        }
                    ],
                    listData: this.freshServiceConfigurationList
                }
            ]
        };
        let salesforceConfigData: any;
        this.salesforceFormGenInput = {
            formName: 'Salesforce',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Select Configuration',
                    placeholder: 'Select Option',
                    name: 'id',
                    required: true,
                    value:
                        salesforceConfigData && salesforceConfigData.id
                            ? salesforceConfigData.id
                            : '',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    listData: this.salesforceConfigurationList,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This field is mandatory'
                        }
                    ],
                    onChange: (event) => {
                        this.salesforceFormGenInput.fields[0].value = event.id;
                        this.salesforceFormGenInput.fields.length = 1;
                        this.salesforceNewForm(event);
                    },
                    apiInfo: this.listHttpService.listSalesforceForm()
                }
            ]
        };

        this.buttonInput = {
            buttonName: 'Save',
            function: (buttonRef: IButtonGeneratorInput) => {
                this.saveButtonClicked(buttonRef);
            },
            showLoader: true,
            buttonType: ButtonType.RAISED,
            buttonColorType: ButtonColorType.PRIMARY
        };
        this.createZendeskForm();
    }
    salesforceConfigurationValueChanged() {
        if (this.response && !this.salesforceConfigurationList.length) {
            this.response.forEach((element) => {
                if (element.key === IntegrationName.SALESFORCE) {
                    this.salesforceConfigResponse =
                        element.value.configResponses;
                    element.value.configResponses.forEach((element) => {
                        this.salesforceConfigurationList.push({
                            id: element.id,
                            label: element.configurationName
                        });
                    });
                }
            });
        }
    }
    createZendeskForm() {
        this.zendeskFormGenInput = {
            formName: 'Zendesk',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'form',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'Select Form',
                    placeholder: 'Select Form',
                    required: true,
                    value: this.stepData['configuredColumnData']
                        ? !isNaN(
                              parseInt(
                                  this.stepData['configuredColumnData']['form']
                              )
                          )
                            ? parseInt(
                                  this.stepData['configuredColumnData']['form']
                              )
                            : null
                        : null,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Form is required'
                        }
                    ],
                    apiInfo: this.listHttpService.listZendeskForm(
                        this.zendeskWidgetId
                    ),
                    onChange: (event) => {
                        this.zendeskFormSelected(event);
                    }
                }
            ]
        };
        if (
            this.stepData['configuredColumnData'] &&
            this.stepData['configuredColumnData']['form']
        ) {
            const zendeskFormValue =
                this.stepData['configuredColumnData']['form'];
            this.zendeskFormSelected(zendeskFormValue);
        }
    }
    salesforceNewForm(event) {
        const form = this.salesforceConfigResponse.find(
            (each) => each.id === event.id
        ).step2;
        const defaultValues = null;
        const dynamicField = [];
        form.fields.forEach((field) => {
            if (
                field.fieldType === FilterType.TEXT ||
                field.fieldType === FilterType.SHORT_TEXT
            ) {
                const newField = {
                    label: field.label,
                    placeholder: field.label + ' Column',
                    name: field.name,
                    value: this.stepData['configuredColumnData']
                        ? this.stepData['configuredColumnData'][field.name]
                        : null,
                    getKey: 'id',
                    listData: this.columnList,
                    apiInfo: null,
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: field.required,
                    validations: field.required
                        ? [
                              {
                                  validator: CustomValidators.required,
                                  errorMessage: `${field.label} is required.`
                              }
                          ]
                        : null
                };
                dynamicField.push(newField);
            }
        });
        const updatedNewField = dynamicField;
        if (updatedNewField && updatedNewField.length) {
            this.updateControlInput = {
                action: UpdateAction.UPDATE_FORM_FIELD,
                fields: updatedNewField
            };
            this.updateControl.next(this.updateControlInput);
        }
    }

    zendeskFormSelected(form) {
        this.zendeskFormGenInput.fields[0].value = parseInt(
            form && form.id ? form.id : form
        );
        this.zendeskFormGenInput.fields = [this.zendeskFormGenInput.fields[0]];
        if (form) {
            this.zendeskFormId = form && form.id ? form.id : form;
            this.extraMessage = '';
            this.modalLoader = true;
            const apiConf: IHitApi = {
                url: ApiUrls.ZENDESK_GET_FORM_API,
                input: {},
                function: (response) => {
                    this.modalLoader = false;
                    if (response && response.form && !response.form.length) {
                        this.extraMessage =
                            'Selected configuration does not contain configurable fields. Click save to select this configuration for Creating Incident';
                    }
                    const dynamicField = [];
                    response.form.forEach((field) => {
                        if (
                            field.fieldType === FilterType.TEXT ||
                            field.fieldType === FilterType.TEXTAREA
                        ) {
                            const newField = {
                                label: field.label,
                                placeholder: field.label + ' Column',
                                name: field.name,
                                value: this.stepData['configuredColumnData']
                                    ? this.stepData['configuredColumnData'][
                                          field.name
                                      ]
                                    : '',
                                getKey: 'id',
                                listData: this.columnList,
                                apiInfo: null,
                                fieldType: FilterType.DROPDOWN_SINGLE,
                                required: field.required,
                                validations: field.required
                                    ? [
                                          {
                                              validator:
                                                  CustomValidators.required,
                                              errorMessage: `${field.label} is required.`
                                          }
                                      ]
                                    : null
                            };
                            dynamicField.push(newField);
                        }
                    });
                    if (dynamicField && dynamicField.length) {
                        this.updateControlInput = {
                            action: UpdateAction.UPDATE_FORM_FIELD,
                            fields: dynamicField
                        };
                        this.updateControl.next(this.updateControlInput);
                    }
                },
                requestType: RequestType.POST,
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                },
                errorFunction: (error) => {
                    this.modalLoader = false;
                }
            };
            apiConf.intactUrl = apiConf.url;
            apiConf.url = apiConf.url.replace(
                '{form-id}',
                form && form.id ? form.id : form
            );
            apiConf.url = apiConf.url.replace(
                '{widget-id}',
                this.zendeskWidgetId
            );
            new HitApi(apiConf, this.httpService, this.ngZone).hitApi();
        } else {
            this.zendeskFormGenInput.fields = [
                this.zendeskFormGenInput.fields[0]
            ];
        }
    }
    setFreshServiceFormGenInput(freshServiceFields) {
        this.extraMessage = '';
        const fields = [];
        freshServiceFields.forEach((field: IFormField) => {
            if (
                field.fieldType === FilterType.TEXT ||
                field.fieldType === FilterType.TEXTAREA
            ) {
                fields.push({
                    label: field.label,
                    placeholder: field.label + ' Column',
                    name: field.name,
                    value: this.stepData['configuredColumnData']
                        ? this.stepData['configuredColumnData'][field.name]
                        : '',
                    getKey: 'id',
                    listData: this.columnList,
                    apiInfo: null,
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: false
                });
            }
        });

        if (!fields.length) {
            this.extraMessage =
                'Selected configuration does not contain configurable fields. Click save to select this configuration for Creating Incident';
        }

        this.freshServiceFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: fields
        };
    }

    setIntegrationData() {
        if (
            this.stepData['integratedItsms'] &&
            this.stepData['integratedItsms'].length
        ) {
            this.integratedItsmTools = this.stepData['integratedItsms'];
            if (
                this.stepData['configuredColumnData'] &&
                this.integratedItsmTools.includes(
                    this.stepData['configuredColumnData']['itsmToolName']
                )
            ) {
                this.integrationType =
                    this.stepData['configuredColumnData']['itsmToolName'];
            } else {
                this.integrationType = this.integratedItsmTools[0];
            }
        }

        if (this.stepData['response']) {
            this.response = this.stepData['response'];
            this.getWidgetId();
        }
    }
    getWidgetId() {
        this.response.forEach((widgetData) => {
            if (widgetData.key === IntegrationName.ZENDESK) {
                this.zendeskWidgetId =
                    widgetData.value &&
                    widgetData.value.self &&
                    widgetData.value.self.widgetId
                        ? widgetData.value.self.widgetId
                        : widgetData.value &&
                          widgetData.value.parent &&
                          widgetData.value.parent.widgetId
                        ? widgetData.value.parent.widgetId
                        : null;
            }
        });
    }
    async saveButtonClicked(buttonRef: IButtonGeneratorInput) {
        const input = {};

        if (buttonRef.loader) {
            return;
        }
        if (this.integratedItsmTools.length) {
            if (this.integrationType === IntegrationName.SERVICENOW) {
                Helper.markAllFieldAsTouched(this.serviceNowFormGroup);
                if (this.serviceNowFormGroup.invalid) {
                    this.serviceNowFormGroup.updateValueAndValidity();
                    return;
                } else {
                    input['configuredColumn'] = this.serviceNowFormGroup.value;
                    input['configuredColumn']['itsmToolName'] =
                        this.integrationType;
                }
            }
            if (this.integrationType === IntegrationName.AUTOTASK) {
                Helper.markAllFieldAsTouched(this.autoTaskFormGroup);
                if (this.autoTaskFormGroup.invalid) {
                    this.autoTaskFormGroup.updateValueAndValidity();
                    return;
                } else {
                    input['configuredColumn'] = this.autoTaskFormGroup.value;
                    input['configuredColumn']['itsmToolName'] =
                        this.integrationType;
                }
            }
            if (this.integrationType === IntegrationName.OTRS) {
                Helper.markAllFieldAsTouched(this.otrsFormGroup);
                if (this.otrsFormGroup.invalid) {
                    this.otrsFormGroup.updateValueAndValidity();
                    return;
                } else {
                    input['configuredColumn'] = this.otrsFormGroup.value;
                    input['configuredColumn']['itsmToolName'] =
                        this.integrationType;
                }
            }
            if (this.integrationType === IntegrationName.CASDM) {
                Helper.markAllFieldAsTouched(this.casdmFormGroup);
                if (this.casdmFormGroup.invalid) {
                    this.casdmFormGroup.updateValueAndValidity();
                    return;
                } else {
                    input['configuredColumn'] = this.casdmFormGroup.value;
                    input['configuredColumn']['itsmToolName'] =
                        this.integrationType;
                }
            }
            if (this.integrationType === IntegrationName.ZOHO) {
                Helper.markAllFieldAsTouched(this.zohoFormGroup);
                if (this.zohoFormGroup.invalid) {
                    this.zohoFormGroup.updateValueAndValidity();
                    return;
                } else {
                    input['configuredColumn'] = this.zohoFormGroup.value;
                    input['configuredColumn']['itsmToolName'] =
                        this.integrationType;
                }
            }
            if (this.integrationType === IntegrationName.FRESHSERVICE) {
                Helper.markAllFieldAsTouched(this.freshServiceConfigFormGroup);
                if (this.freshServiceFormGroup) {
                    Helper.markAllFieldAsTouched(this.freshServiceFormGroup);
                }
                if (this.freshServiceConfigFormGroup.invalid) {
                    this.freshServiceConfigFormGroup.updateValueAndValidity();
                    return;
                } else if (this.freshServiceFormGroup.invalid) {
                    this.freshServiceFormGroup.updateValueAndValidity();
                    return;
                } else {
                    input['configuredColumn'] =
                        this.freshServiceFormGroup.value;
                    input['configuredColumn']['itsmToolName'] =
                        this.integrationType;
                    input['configuredColumn']['configuration_widgetId'] =
                        this.freshServiceConfigFormGroup.get('widgetId').value;
                    this.integrationType;
                }
            }
            if (this.integrationType === IntegrationName.ZENDESK) {
                Helper.markAllFieldAsTouched(this.zendeskFormGroup);
                if (this.zendeskFormGroup.invalid) {
                    this.zendeskFormGroup.updateValueAndValidity();
                    return;
                } else {
                    input['configuredColumn'] = this.zendeskFormGroup.value;
                    input['configuredColumn']['itsmToolName'] =
                        this.integrationType;
                    input['configuredColumn']['itsmFormId'] =
                        this.zendeskFormId;
                    input['configuredColumn']['configuration_widgetId'] =
                        this.zendeskWidgetId;
                }
            }
            if (this.integrationType === IntegrationName.SALESFORCE) {
                Helper.markAllFieldAsTouched(this.salesforceFormGroup);
                if (this.salesforceFormGroup.invalid) {
                    this.salesforceFormGroup.updateValueAndValidity();
                    return;
                } else {
                    const finalResponse = {};
                    Object.keys(this.salesforceFormGroup.value).forEach(
                        (each) => {
                            const test =
                                this.widgetCreationService.resolveSalesforceInputString(
                                    this.salesforceFormGroup.value[each],
                                    'input'
                                );
                            const test2 =
                                this.widgetCreationService.resolveSalesforceInputString(
                                    this.salesforceFormGroup.value[each],
                                    'selection'
                                );

                            finalResponse[each] = test + test2;
                        }
                    );
                    input['configuredColumn'] = finalResponse;
                    input['configuredColumn']['itsmToolName'] =
                        this.integrationType;
                }
            }
        } else {
            return;
        }
        buttonRef.loader = true;

        const apiConf: IHitApi = {
            url: `srm-integrations/service-catalog/save-column-catalog`,
            input: input,
            function: (response) => {
                buttonRef.loader = false;
                this.stepData['updateConfig']();
                this.notificationService.showSnackBar(
                    'Columns Successfully Configured'
                );

                this.modalService.closeModal(null, this.modalInputData.modalId);
            },
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            errorFunction: (error) => {
                buttonRef.loader = false;
                Helper.showErrorMessage(
                    this.notificationService,
                    error,
                    'Error while saving configuration'
                );
                this.modalService.closeModal(null, this.modalInputData.modalId);
            }
        };
        new HitApi(apiConf, this.httpService, this.ngZone).hitApi();
    }

    setCurrentTab(key) {
        if (this.integratedItsmTools.includes(key)) {
            this.integrationType = key;
        }
    }

    freshServiceConfigValueChanged(value) {
        if (!value['widgetId']) {
            return;
        }
        this.freshServiceFormGenInput = null;
        this.modalLoader = true;
        let url = ApiUrls.FRESHSERVICE_CONFIGURED_FIELDS.replace(
            '{widgetId}',
            value['widgetId']
        );
        url = url.replace('{budgeting}', 'false');
        url = url.replace('{alerting}', 'false');
        const apiConf: IHitApi = {
            url: url,
            input: {},
            intactUrl: ApiUrls.FRESHSERVICE_CONFIGURED_FIELDS,
            function: (response) => {
                this.modalLoader = false;
                this.setFreshServiceFormGenInput(response);
            },
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            errorFunction: (error) => {
                this.modalLoader = false;
            }
        };

        new HitApi(apiConf, this.httpService, this.ngZone).hitApi();
    }
}
