<div class="query-builder-step-container" *ngIf="!isLoading">
    <div class="tabs-container">
        <div
            class="query-tabs"
            #tabList="cdkDropList"
            cdkDropList
            cdkDropListSortingDisabled
            [cdkDropListData]="queryClauseList"
            [cdkDropListConnectedTo]="[dropForm]"
        >
            <ng-container
                *ngFor="let queryClause of queryClauseList; let i = index"
            >
                <div
                    class="tab"
                    cdkDrag
                    [matTooltip]="
                        queryClause.id === Clause.FROM
                            ? !selectedClause.includes(Clause.SELECT)
                                ? 'Add \'Select Clause\' to enable this tab'
                                : ''
                            : queryClause.id !== Clause.SELECT &&
                              (!selectedClause.includes(Clause.SELECT) ||
                                  !selectedClause.includes(Clause.FROM))
                            ? 'Add \'Select Clause\' and \'From Clause\' to enable this tab'
                            : inCompleteClause
                            ? 'Complete the clause to continue'
                            : ''
                    "
                    [cdkDragDisabled]="
                        (queryClause.id !== Clause.SELECT &&
                            !selectedClause.includes(Clause.SELECT)) ||
                        (queryClause.id !== Clause.FROM &&
                            queryClause.id !== Clause.SELECT &&
                            !selectedClause.includes(Clause.FROM)) ||
                        inCompleteClause
                    "
                    [class.active]="queryClause.id === currentTab"
                    [class.disabled]="
                        (queryClause.id !== Clause.SELECT &&
                            !selectedClause.includes(Clause.SELECT)) ||
                        (queryClause.id !== Clause.FROM &&
                            queryClause.id !== Clause.SELECT &&
                            !selectedClause.includes(Clause.FROM)) ||
                        inCompleteClause
                    "
                    (click)="updateCurrentTab(queryClause.id, i)"
                >
                    <span appIcon [data]="dragIcon"> </span>
                    <span class="name"> {{ queryClause.label }}</span>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="query-editor-container tw-justify-between">
        <div class="editor-table-cont tw-w-full">
            <div
                class="query-editor tw-w-full tw-flex-f2 tw-relative"
                [class.success]="tableQueryData"
                [class.error]="queryValidationError"
            >
                <div
                    class="tw-w-2/4 tw-h-full"
                    cdkDropList
                    #dropForm="cdkDropList"
                    [cdkDropListData]="tempQueryClauseList"
                    [cdkDropListConnectedTo]="[tabList]"
                    (cdkDropListDropped)="tabDropped($event)"
                >
                    <div class="tw-w-full tw-h-full" cdkDrag>
                        <div
                            class="tw-w-full tw-h-full"
                            *ngIf="currentTab || query"
                        >
                            <ngx-monaco-editor
                                #queryEditor
                                class="tw-h-full"
                                [options]="editorOptions"
                                [(ngModel)]="query"
                            ></ngx-monaco-editor>
                        </div>
                    </div>
                </div>
                <div
                    class="other-container"
                    *ngIf="
                        !queryValidationError &&
                        !tableQueryData &&
                        currentTab &&
                        currentTab !== Clause.FROM
                    "
                >
                    <div class="selection-list tw-relative">
                        <!-- Options for SELECT Clause -->
                        <ng-container *ngIf="currentTab === Clause.SELECT">
                            <div class="header">
                                <div class="search">
                                    <label appIcon [data]="searchIcon"></label>
                                    <input
                                        class="tw-pt-1"
                                        (keyup)="search($event.target.value)"
                                        placeholder="Search"
                                    />
                                </div>
                                <div class="icon-container">
                                    <a
                                        class="tw-w-9 tw-h-9 tw-flex"
                                        href="assets/aws-columns-description-pdf/aws-columns-description.pdf"
                                        target="_blank"
                                    >
                                        <span
                                            [matTooltip]="
                                                'Click to download columns information'
                                            "
                                            class="icon"
                                            appIcon
                                            [data]="pdfIcon"
                                        >
                                        </span>
                                    </a>
                                    <span
                                        class="tw-pl-1 tw-pr-2 tw-pb-1 tw-text-2xl tw-cursor-pointer"
                                        appIcon
                                        [data]="crossIcon"
                                        (click)="currentTab = null"
                                    >
                                    </span>
                                </div>
                            </div>
                            <div class="column-list">
                                <ng-container
                                    *ngFor="
                                        let row of filteredColumns;
                                        let rowIndex = index
                                    "
                                >
                                    <div
                                        class="row"
                                        (click)="updateQueryColumns(row.id)"
                                    >
                                        <span
                                            class="column-name"
                                            [class.selected]="
                                                selectedColumns.includes(row.id)
                                            "
                                        >
                                            {{ row.label }}
                                        </span>
                                        <span
                                            class="column-type"
                                            [ngClass]="
                                                row.type === ColumnType.TEXT
                                                    ? 'text'
                                                    : 'number'
                                            "
                                        >
                                            {{ row.type }}
                                        </span>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>

                        <!-- Options for WHERE Clause -->
                        <ng-container *ngIf="currentTab === Clause.WHERE">
                            <div
                                class="where-clause-container tw-flex tw-flex-col tw-p-3"
                            >
                                <ng-container
                                    *ngFor="
                                        let row of tempWhereClauseList;
                                        let rowIndex = index
                                    "
                                >
                                    <div class="tw-flex tw-flex-col tw-pb-3">
                                        <div
                                            class="tw-flex tw-w-full tw-items-center"
                                        >
                                            <div
                                                class="dropdowns tw-flex tw-pb-3 tw-pl-1 tw-pr-1"
                                            >
                                                <div
                                                    class="tw-flex-1 tw-mr-1 tw-flex"
                                                >
                                                    <app-single-select-dropdown
                                                        class="tw-w-full"
                                                        [filterInfo]="{
                                                            placeholder:
                                                                'Column',
                                                            listData: columnList
                                                        }"
                                                        [selectedValue]="
                                                            row.columnName
                                                                ? row.columnName
                                                                : undefined
                                                        "
                                                        (change)="
                                                            whereClauseColumnNameChanged(
                                                                row,
                                                                rowIndex,
                                                                $event
                                                            )
                                                        "
                                                    ></app-single-select-dropdown>
                                                </div>
                                                <div
                                                    class="tw-flex-1 tw-mr-1 tw-flex"
                                                >
                                                    <app-single-select-dropdown
                                                        class="tw-w-full"
                                                        [filterInfo]="{
                                                            placeholder:
                                                                'Operator',
                                                            listData:
                                                                operatorsList
                                                        }"
                                                        [selectedValue]="
                                                            row.operator
                                                                ? row.operator
                                                                : undefined
                                                        "
                                                        (change)="
                                                            row.operator =
                                                                $event
                                                        "
                                                    ></app-single-select-dropdown>
                                                </div>
                                                <div
                                                    class="tw-flex-1 tw-mr-1 tw-flex"
                                                >
                                                    <app-single-select-dropdown
                                                        *ngIf="
                                                            row.columnName &&
                                                            allColumnsIdLabelMap.get(
                                                                row.columnName
                                                            ).type ===
                                                                ColumnType.TEXT
                                                        "
                                                        class="tw-w-full"
                                                        [filterInfo]="
                                                            columnDataListFilterInfo[
                                                                rowIndex
                                                            ]
                                                        "
                                                        [selectedValue]="
                                                            row.value
                                                                ? row.value
                                                                : undefined
                                                        "
                                                        (change)="
                                                            row.value = $event
                                                        "
                                                        (afterResponse)="
                                                            afterResponse(
                                                                $event,
                                                                rowIndex
                                                            )
                                                        "
                                                    ></app-single-select-dropdown>
                                                    <input
                                                        *ngIf="
                                                            row.columnName &&
                                                            allColumnsIdLabelMap.get(
                                                                row.columnName
                                                            ).type ===
                                                                ColumnType.NUMBER
                                                        "
                                                        class="tw-outline-none tw-w-full tw-bg-transparent tw-pl-2 tw-pr-2"
                                                        type="number"
                                                        autocomplete="off"
                                                        name="column"
                                                        [value]="row.value"
                                                        (change)="
                                                            row.value = $event
                                                                .target.value
                                                                ? +$event.target
                                                                      .value
                                                                : $event.target
                                                                      .value
                                                        "
                                                        [placeholder]="
                                                            'Enter Value'
                                                        "
                                                    />
                                                </div>
                                            </div>
                                            <div class="delete-button">
                                                <app-button-generator
                                                    [buttonGenInput]="
                                                        deleteButtonInput
                                                    "
                                                    (buttonRef)="
                                                        deleteWhereClauseEntry(
                                                            rowIndex
                                                        )
                                                    "
                                                >
                                                </app-button-generator>
                                            </div>
                                        </div>
                                        <div
                                            class="where-clause-btn-container tw-flex tw-flex-1 tw-pl-1"
                                        >
                                            <app-button-generator
                                                [buttonGenInput]="
                                                    andOrButtonGenInputs[
                                                        rowIndex
                                                    ][0]
                                                "
                                                [disabled]="
                                                    !row.columnName ||
                                                    !row.operator ||
                                                    row.value === ''
                                                "
                                                (buttonRef)="
                                                    updateWhereClauseNextRelation(
                                                        rowIndex,
                                                        andOrButtonGenInputs[
                                                            rowIndex
                                                        ][0]
                                                    )
                                                "
                                            >
                                            </app-button-generator>
                                            <app-button-generator
                                                [buttonGenInput]="
                                                    andOrButtonGenInputs[
                                                        rowIndex
                                                    ][1]
                                                "
                                                [disabled]="
                                                    !row.columnName ||
                                                    !row.operator ||
                                                    row.value === ''
                                                "
                                                (buttonRef)="
                                                    updateWhereClauseNextRelation(
                                                        rowIndex,
                                                        andOrButtonGenInputs[
                                                            rowIndex
                                                        ][1]
                                                    )
                                                "
                                            >
                                            </app-button-generator>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>

                        <!-- Options for GROUP BY Clause -->
                        <ng-container *ngIf="currentTab === Clause.GROUP_BY">
                            <div class="group-by-columns">
                                <mat-selection-list #columns>
                                    <ng-container
                                        *ngFor="let field of columnList"
                                    >
                                        <ng-container
                                            *ngIf="
                                                allColumnsIdLabelMap.get(
                                                    field.id
                                                ).type === ColumnType.TEXT
                                            "
                                        >
                                            <mat-list-option
                                                checkboxPosition="before"
                                                [value]="field.id"
                                                [disabled]="
                                                    selectedColumns.includes(
                                                        field.id
                                                    )
                                                "
                                                [selected]="
                                                    selectedGroupByColumns.includes(
                                                        field.id
                                                    )
                                                "
                                                (click)="
                                                    onGroupByColumnsChanged(
                                                        columns
                                                    )
                                                "
                                            >
                                                {{ field.label }}
                                            </mat-list-option>
                                        </ng-container>
                                    </ng-container>
                                </mat-selection-list>
                            </div>
                        </ng-container>

                        <!-- Options for ORDER BY Clause -->
                        <ng-container *ngIf="currentTab === Clause.ORDER_BY">
                            <div class="order-by-columns tw-relative">
                                <app-only-table-generator
                                    [tableData]="orderByList"
                                    [tableInput]="tableGenInput"
                                    [defaultColDef]="defaultColDef"
                                    [hideControlBar]="true"
                                    [autoFitColumns]="false"
                                    [useMaterialIcons]="true"
                                >
                                </app-only-table-generator>
                            </div>
                        </ng-container>

                        <div
                            class="tw-mt-auto tw-mr-5 tw-mb-3"
                            *ngIf="currentTab !== Clause.SELECT"
                        >
                            <app-multi-button-generator
                                [buttonGenInputs]="addQueryButtonGenInputs"
                            ></app-multi-button-generator>
                        </div>
                    </div>
                </div>
                <span
                    class="tw-h-8 tw-w-8 tw-p-2 tw-absolute tw-top-0 tw-right-0"
                    appIcon
                    [data]="rightTickIcon"
                    *ngIf="tableQueryData"
                >
                </span>
                <span
                    *ngIf="tableQueryData"
                    class="success tw-text-xs tw-p-2 tw-absolute tw-bottom-0 tw-right-0"
                >
                    Verified
                </span>
                <span
                    class="tw-h-8 tw-w-8 tw-p-2 tw-absolute tw-top-0 tw-right-0"
                    appIcon
                    [data]="errorIcon"
                    *ngIf="queryValidationError"
                >
                </span>
            </div>
            <div
                class="query-table-container tw-flex-f2 tw-pt-1"
                *ngIf="runQuery"
            >
                <div
                    class="query-table tw-relative"
                    *ngIf="checkQueryTableGenInput"
                >
                    <app-only-table-generator
                        [showSearchBox]="false"
                        [apiInfo]="checkQueryApiInfo"
                        [tableInput]="checkQueryTableGenInput"
                        [hideControlBar]="true"
                    ></app-only-table-generator>
                </div>
                <div
                    class="tw-flex tw-text-sm tw-items-center tw-pt-2"
                    *ngIf="tableQueryData"
                >
                    <span class="tw-flex-1 tw-flex tw-items-center">
                        <span
                            class="tw-h-8 tw-w-8 tw-p-2"
                            appIcon
                            [data]="infoIcon"
                        >
                        </span>
                        <span class="info tw-text-xs tw-pr-5">
                            The query is "Run" on last 7 days of your AWS
                            expense. The above table shows only top 10 rows from
                            the response. Click "Download" to view complete
                            data.
                        </span>
                    </span>
                    <div
                        [class.rotatingLoader]="downloadLoader"
                        class="tw-flex tw-justify-end"
                    >
                        <span
                            class="tw-flex tw-justify-end tw-items-center tw-cursor-pointer"
                            (click)="downloadReport()"
                        >
                            <span class="download tw-self-center"
                                >Download</span
                            >
                            <span
                                class="tw-w-8 tw-h-8 tw-p-2"
                                appIcon
                                [data]="downloadIcon"
                            ></span>
                            <mat-spinner [diameter]="16" *ngIf="downloadLoader">
                            </mat-spinner>
                        </span>
                    </div>
                </div>
            </div>

            <!-- Query Validation Error Handling -->
            <div
                class="tw-pt-3"
                *ngIf="queryValidationError || inCompleteClause"
            >
                <ng-template *ngTemplateOutlet="errorTemplate"></ng-template>
            </div>

            <!-- Select Clause Info -->
            <div
                class="tw-pt-3"
                *ngIf="
                    !queryValidationError &&
                    !inCompleteClause &&
                    !runQuery &&
                    currentTab === Clause.SELECT
                "
            >
                <ng-template *ngTemplateOutlet="infoTemplate"></ng-template>
            </div>
        </div>

        <div class="btn-gen-container tw-h-12 tw-flex tw-items-end">
            <app-multi-button-generator
                [buttonGenInputs]="buttonGenInputs"
            ></app-multi-button-generator>
        </div>
    </div>
</div>
<div class="overlay" *ngIf="isLoading">
    <div class="overlay-content">
        <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
    </div>
</div>

<ng-template #errorTemplate>
    <div
        class="error-template-content tw-w-full tw-h-9 tw-flex tw-items-center"
    >
        <span class="tw-h-8 tw-w-8 tw-p-2" appIcon [data]="errorIcon"> </span>
        <span class="tw-text-base error-message">{{
            queryValidationError
                ? queryValidationError
                : inCompleteClause
                ? "Complete the clause to continue"
                : ""
        }}</span>
    </div>
</ng-template>

<ng-template #infoTemplate>
    <div class="info-template-content tw-w-full tw-h-9 tw-flex tw-items-center">
        <span class="tw-h-8 tw-w-8 tw-p-2" appIcon [data]="infoIcon"> </span>
        <span class="tw-text-base info-message"
            >Either 'Cost' or 'Usage' column should be added in the 'Select
            Clause'</span
        >
    </div>
</ng-template>
