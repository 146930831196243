import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild
} from '@angular/core';
import { CellClassParams, GridOptions } from 'ag-grid-community';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { Helper } from './../../../classes/Helper';
import { IconType } from './../../../enums/IconType';
import { IIcon } from './../../../interfaces/icon-data/IIcon';
import { IColumnData } from './../../../interfaces/table-generator/IColumnData';
import { ITableGeneratorInput } from './../../../interfaces/table-generator/ITableGeneratorInput';

@Component({
    selector: 'app-savings-plan-recommendation-modal',
    templateUrl: './savings-plan-recommendation-modal.component.html',
    styleUrls: ['./savings-plan-recommendation-modal.component.sass']
})
export class SavingsPlanRecommendationModalComponent
    implements OnInit, AfterViewInit
{
    @ViewChild('recommendationTable') recommendationTable: ElementRef<any>;
    selectedItem;
    dataMap: any;
    consumptionBreakupArray = [];
    consumptionInformation = [];
    keys;
    tabs;
    selectedTab;
    objectKeys = Object.keys;
    descriptionTableData: object[];
    descriptionTableGenInput: ITableGeneratorInput;
    recommendationTableData: object[];
    recommendationTableGenInput: ITableGeneratorInput;
    tabData: Map<string, object[]> = new Map<string, object[]>();
    gridRef: GridOptions;
    widgetRef: Widget;

    arrowUpIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-up'
    };

    arrowDownIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-down'
    };
    defaultColDef: any = {
        sortable: false,
        filter: false,
        resizable: false
    };

    constructor(
        public modalData: ModalInjectedData,
        public modalService: ModalService
    ) {
        this.selectedItem = modalData.data.item;
        this.dataMap = modalData.data.dataMap;
        this.widgetRef = modalData.data.widgetRef;
    }

    ngOnInit(): void {
        this.keys = this.objectKeys(this.selectedItem);
        this.tabs =
            this.selectedItem && this.selectedItem['amortizedCost']
                ? Object.keys(this.selectedItem['amortizedCost'])
                : [];

        if (Object.keys(this.selectedItem).includes('savingsTable')) {
            const tableData = [];
            this.selectedTab = 'savingsTable';

            const savingsData = this.selectedItem['savingsTable'];
            Object.keys(savingsData).forEach((selectedKey) => {
                const data = savingsData[selectedKey];
                data['cost'] = selectedKey;
                tableData.push(data);
            });
            this.tabData.set(this.selectedTab, tableData);
        } else {
            this.selectedTab = this.tabs[0];
        }
        this.initDescriptionTableGen();
        this.initRecommendationTable();
    }

    ngAfterViewInit(): void {
        this.setColors();
    }

    setColors() {
        const styleList = [
            `--positiveColorBackground:${Helper.addOpacityToColor(
                Helper.getCssVarValue('positiveColor'),
                15
            )}`
        ];
        this.recommendationTable.nativeElement.style = styleList.join('; ');
    }

    changeTab(tab) {
        this.selectedTab = tab;
        this.gridRef.api.setRowData(this.tabData.get(tab));
    }

    displayCondition(key1) {
        for (const element in this.selectedItem['savingsTable'][
            'Savings (%)'
        ]) {
            if (element.includes(key1)) {
                return true;
            }
        }
    }

    calColspan(key) {
        let count = 0;
        Object.keys(this.selectedItem['savingsTable']['Savings (%)']).forEach(
            (element) => {
                if (element.includes(key)) {
                    count++;
                }
            }
        );

        return count;
    }

    isObject(val) {
        return typeof val === 'object';
    }

    initDescriptionTableGen() {
        const columns: IColumnData[] = [
            {
                columnKey: 'key',
                columnName: 'key',
                pinned: 'left',
                cellClass: 'pinned-key'
            },
            {
                columnKey: 'value',
                columnName: 'value',
                cellClass: 'value-column'
            }
        ];
        const data = this.selectedItem['generalInformation'];
        const tableData: object[] = [];
        Object.keys(this.selectedItem['generalInformation']).forEach(
            (colKey) => {
                tableData.push({ key: colKey, value: data[colKey] });
            }
        );
        this.descriptionTableData = tableData;
        this.descriptionTableGenInput = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            tableAutoHeight: true,
            columns,
            headerHeight: 0
        };
    }

    initRecommendationTable() {
        // Preparing the columns 👇
        const columns: IColumnData[] = [
            { columnKey: null, columnName: '' },
            { columnKey: null, columnName: '1 year' },
            { columnKey: null, columnName: '3 year' }
        ];

        columns.forEach((data: IColumnData) => {
            const colName = data.columnName;
            if (colName.includes('1')) {
                data.children = [
                    {
                        columnKey: `No-Upfront 1yr`,
                        columnName: 'No-Upfront',
                        minWidth: 200
                    },
                    {
                        columnKey: `Partial-Upfront 1yr`,
                        columnName: 'Partial-Upfront',
                        minWidth: 200
                    },
                    {
                        columnKey: `All-Upfront 1yr`,
                        columnName: 'All-Upfront',
                        minWidth: 200,
                        cellClass: (cellClassParams: CellClassParams) => {
                            if (
                                cellClassParams.data['cost'] ===
                                    'Savings ($)' ||
                                cellClassParams.data['cost'] === 'Savings (%)'
                            ) {
                                return 'positive';
                            }
                        }
                    }
                ];
            } else if (colName.includes('3')) {
                data.children = [
                    {
                        columnKey: `No-Upfront 3yr`,
                        columnName: 'No-Upfront',
                        minWidth: 200
                    },
                    {
                        columnKey: `Partial-Upfront 3yr`,
                        columnName: 'Partial-Upfront',
                        minWidth: 200
                    },
                    {
                        columnKey: `All-Upfront 3yr`,
                        columnName: 'All-Upfront',
                        minWidth: 200,
                        cellClass: (cellClassParams: CellClassParams) => {
                            if (
                                cellClassParams.data['cost'] ===
                                    'Savings ($)' ||
                                cellClassParams.data['cost'] === 'Savings (%)'
                            ) {
                                return 'positive';
                            }
                        }
                    }
                ];
            } else {
                data.children = [
                    {
                        columnKey: `cost`,
                        columnName: 'Cost Type',
                        pinned: 'left',
                        minWidth: 330,
                        cellClass: 'pinned-key'
                    }
                ];
            }
        });

        this.recommendationTableGenInput = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            tableAutoHeight: true,
            columns
        };

        const amortizedCost = this.selectedItem['amortizedCost'];
        this.tabs.forEach((tab) => {
            const tableData = [];
            const selectedTabData = amortizedCost[tab];
            Object.keys(selectedTabData).forEach((selectedKey) => {
                const data = selectedTabData[selectedKey];
                data['cost'] = selectedKey;
                tableData.push(data);
            });
            this.tabData.set(tab, tableData);
        });
        this.recommendationTableData = this.tabData.get(this.selectedTab);
    }
}
