import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { Clouds } from 'src/app/shared/enums/Clouds';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { OnboardingAccountType } from 'src/app/shared/enums/OnboardingAccountType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { IDropdownData } from './../../../../interfaces/dropdown-data/IDropdownData';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';

@Component({
    selector: 'app-csp-onboarding-modal',
    templateUrl: './csp-onboarding-modal.component.html',
    styleUrls: ['./csp-onboarding-modal.component.sass']
})
export class CspOnboardingModalComponent implements OnInit {
    formGenInput: IFormGeneratorInput = null;
    billingAnniversaryList: IDropdownData[] = [];
    buttonInputs: IButtonGeneratorInput[];
    cspFormGroup: FormGroup;
    resetSubscription: Subscription;
    isEdit: boolean = false;
    constructor(
        private notificationService: NotificationsService,
        private httpService: HttpService,
        private ngZone: NgZone,
        private modalInput: ModalInjectedData,
        private filtersService: FiltersService,
        private modalService: ModalService
    ) {}

    ngOnInit(): void {
        this.populateBillingAnniversaryList();
        if (!this.modalInput.data.formData) {
            this.formGenInput = this.prepareOnboardingForm();
        } else {
            this.isEdit = true;
            this.formGenInput = this.prepareEditForm();
        }
        this.buttonInputs = [
            {
                buttonName: 'Onboarding Steps',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.LINK,
                customClass: 'onboard-steps',
                link: 'assets/onboarding-pdf/azure-csp-poc-onboarding.pdf',
                function: () => {}
            },
            {
                buttonName: 'Save',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED,
                showLoader: true,
                customClass: 'save',
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.onSave(buttonRef);
                }
            }
        ];
        this.resetSubscription = this.modalService.resetModal.subscribe(() => {
            this.cspFormGroup.reset();
        });
    }
    prepareOnboardingForm() {
        const formGenInput: IFormGeneratorInput = {
            formName: 'CSP Onboarding',
            state: FormState.CREATE,
            submitButton: null,
            extraClass: 'form-height',
            fields: [
                {
                    label: 'Application ID',
                    name: 'applicationId',
                    placeholder: 'Application ID',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Application Id is required'
                        }
                    ]
                },
                {
                    label: 'Key',
                    name: 'key',
                    placeholder: 'Key',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Key is required'
                        }
                    ]
                },

                {
                    label: 'Partner ID',
                    name: 'partnerId',
                    placeholder: 'Partner ID',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Partner ID is required'
                        }
                    ]
                },
                {
                    label: 'Domain Name',
                    name: 'domainName',
                    placeholder: 'Domain Name example, onmicrosoft.com',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Domain Name is required'
                        },
                        {
                            validator: CustomValidators.domain,
                            errorMessage: 'Domain Name is wrong'
                        }
                    ]
                },
                {
                    label: 'Account Name',
                    name: 'accountName',
                    placeholder: 'Account Name',
                    fieldType: FilterType.TEXT,
                    required: false,
                    showLabel: true,
                    appearance: 'legacy'
                },

                {
                    label: 'Billing Anniversary Day',
                    name: 'billingAnniversaryDay',
                    placeholder: 'Select Billing Anniversary Day',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    listData: this.billingAnniversaryList,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Billing Anniversary Day is required'
                        }
                    ]
                }
            ]
        };
        return formGenInput;
    }

    prepareEditForm() {
        const formGenInput: IFormGeneratorInput = {
            formName: 'CSP Onboarding',
            state: FormState.EDIT,
            submitButton: {
                buttonName: 'Save',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                function: (
                    buttonRef: IButtonGeneratorInput,
                    formGroup: FormGroup
                ) => {
                    const formInputJson = {
                        accountNumber:
                            this.modalInput.data.formData.accountNumber,
                        accountName: formGroup.get('accountName').value,
                        azureCspAccountsAttributes: {
                            appId: formGroup.get('applicationId').value,
                            domainName: formGroup.get('domainName').value,
                            key: formGroup.get('key').value,
                            partnerId:
                                this.modalInput.data.formData.accountNumber
                        },
                        billingAniversaryDay: formGroup.get(
                            'billingAnniversaryDay'
                        )
                            ? formGroup.get('billingAnniversaryDay').value
                            : ''
                    };
                    if (formGroup.valid) {
                        buttonRef.loader = true;
                        const apiArgs: IHitApi = {
                            url: `${ApiUrls.CLOUD_ACCOUNTS_ENDPOINT}/${Clouds.AZURE_CSP}/${this.modalInput.data.formData.accountNumber}`,
                            intactUrl: `${ApiUrls.CLOUD_ACCOUNTS_ENDPOINT}/${Clouds.AZURE_CSP}/{accountNumber}`,
                            input: formInputJson,
                            requestType: RequestType.PUT,
                            uniqueIdentity: Symbol(),
                            config: {
                                authorization: AuthorizationType.BEARER_TOKEN
                            },
                            function: (response) => {
                                this.notificationService.showSnackBar(
                                    Messages.ACCOUNT_UPDATE_LIST
                                );
                                buttonRef.loader = false;
                                this.filtersService.refreshWidget.next(
                                    new Set([
                                        this.modalService.modalData.sourceId
                                    ])
                                );
                                this.modalService.closeModal(
                                    null,
                                    this.modalInput.modalId
                                );
                            },
                            errorFunction: (error) => {
                                this.notificationService.showSnackBar(
                                    error.error.message,
                                    true
                                );
                                buttonRef.loader = false;
                            }
                        };
                        new HitApi(
                            apiArgs,
                            this.httpService,
                            this.ngZone
                        ).hitApi();
                    }
                },
                showLoader: true
            },
            fields: [
                {
                    label: 'Application ID',
                    name: 'applicationId',
                    placeholder: 'Application ID',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Application Id is required'
                        }
                    ],
                    value: this.modalInput.data.formData
                        .azureCspAccountsAttributes.appId
                        ? this.modalInput.data.formData
                              .azureCspAccountsAttributes.appId
                        : ''
                },
                {
                    label: 'Key',
                    name: 'key',
                    showLabel: true,
                    appearance: 'legacy',
                    placeholder: this.modalInput.data.formData
                        ? this.modalInput.data.formData
                              .azureCspAccountsAttributes.key
                            ? '******'
                            : 'Enter Key'
                        : 'Enter Key',
                    fieldType: FilterType.TEXT,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Key is required'
                        }
                    ]
                },
                {
                    label: 'Partner ID',
                    name: 'partnerId',
                    placeholder: 'Partner ID',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Partner ID is required'
                        }
                    ],
                    value: this.modalInput.data.formData
                        .azureCspAccountsAttributes.partnerId
                        ? this.modalInput.data.formData
                              .azureCspAccountsAttributes.partnerId
                        : '',
                    disabled: true
                },
                {
                    label: 'Domain Name',
                    name: 'domainName',
                    placeholder: 'Domain Name example, onmicrosoft.com',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Domain Name is required'
                        },
                        {
                            validator: CustomValidators.domain,
                            errorMessage: 'Domain Name is wrong'
                        }
                    ],
                    value: this.modalInput.data.formData
                        .azureCspAccountsAttributes.domainName
                        ? this.modalInput.data.formData
                              .azureCspAccountsAttributes.domainName
                        : ''
                },
                {
                    label: 'Account Name',
                    name: 'accountName',
                    placeholder: 'Account Name',
                    fieldType: FilterType.TEXT,
                    required: false,
                    showLabel: true,
                    appearance: 'legacy',
                    value: this.modalInput.data.formData.accountName
                        ? this.modalInput.data.formData.accountName
                        : ''
                },
                {
                    label: 'Billing Anniversary Day',
                    name: 'billingAnniversaryDay',
                    placeholder: 'Select Billing Anniversary Day',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    listData: this.billingAnniversaryList,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Billing Anniversary Day is required'
                        }
                    ],
                    value: this.modalInput.data.formData
                        .azureCspAccountsAttributes.billingAniversaryDay
                        ? this.modalInput.data.formData
                              .azureCspAccountsAttributes.billingAniversaryDay +
                          ''
                        : null
                }
            ],
            extraClass: 'form-height'
        };
        if (
            this.modalInput.data.formData.type ===
                OnboardingAccountType.CONSOLIDATED ||
            this.modalInput.data.formData.type ===
                OnboardingAccountType.LINKED ||
            this.modalInput.data.formData.type ===
                OnboardingAccountType.LINKED_1 ||
            this.modalInput.data.formData.type ===
                OnboardingAccountType.LINKED_2
        ) {
            Helper.removeFormField(formGenInput, 'partnerId');
            Helper.removeFormField(formGenInput, 'billingAnniversaryDay');
        }
        return formGenInput;
    }
    populateBillingAnniversaryList() {
        for (let i = 1; i <= 31; i++) {
            this.billingAnniversaryList.push({
                id: i + '',
                label: i + ''
            });
        }
    }

    onSave(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }
        Helper.markAllFieldAsTouched(this.cspFormGroup);
        if (this.cspFormGroup.invalid) {
            this.cspFormGroup.updateValueAndValidity();
            return;
        }
        const formInputJson = {
            accountNumber: this.cspFormGroup.get('partnerId').value,
            accountName: this.cspFormGroup.get('accountName').value,
            azureCspAccountsAttributes: {
                appId: this.cspFormGroup.get('applicationId').value,
                domainName: this.cspFormGroup.get('domainName').value,
                key: this.cspFormGroup.get('key').value,
                partnerId: this.cspFormGroup.get('partnerId').value,
                billingAniversaryDay: this.cspFormGroup.get(
                    'billingAnniversaryDay'
                ).value
            }
        };
        if (this.cspFormGroup.valid) {
            buttonRef.loader = true;
            const apiArgs: IHitApi = Helper.generateHitApiConfig(
                this.modalInput.data.create
            );
            apiArgs.input = formInputJson;
            apiArgs.function = (response) => {
                this.notificationService.showSnackBar(
                    Messages.ACCOUNT_ONBOARDING_SUCCESS
                );
                buttonRef.loader = false;
                this.filtersService.refreshWidget.next(
                    new Set([this.modalService.modalData.sourceId])
                );
                this.modalService.closeModal(null, this.modalInput.modalId);
            };
            apiArgs.errorFunction = (error) => {
                this.notificationService.showSnackBar(
                    error.error.message,
                    true
                );
                buttonRef.loader = false;
            };
            new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
        }
    }

    ngOnDestroy() {
        this.resetSubscription.unsubscribe();
    }
}
