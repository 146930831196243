<div class="variable-mapping-container">
    <div class="info" *ngIf="warning">
        <span appIcon [data]="infoHeading" class="info-icon clickable"></span
        ><span>{{ warning }}</span>
    </div>
    <div class="label">{{ "Variable Mapping" }}</div>
    <div class="variable-container">
        <div class="header">
            <div class="flex-1">
                <span>TF File Variables</span>
            </div>
            <div class="flex-1">
                <span>Variable Name</span>
            </div>
            <div class="flex-1">
                <span>Value Type</span>
            </div>
            <div class="flex-1 d-flex js-center">
                <span>Set Value</span>
            </div>
        </div>
        <ng-container *ngFor="let variables of variableFields">
            <div
                [ngClass]="
                    variables.defaultValue
                        ? 'disabled pointer-null body'
                        : 'body'
                "
            >
                <div class="tf-file-variable flex-1">
                    <span>{{ variables["varId"] }}</span>
                </div>
                <div class="variable-name flex-1">
                    <span>{{ variables["label"] }}</span>
                </div>
                <div class="value-type flex-1">
                    <app-form-generator
                        [formGeneratorInput]="
                            valueTypeFormGenInputs.get(variables.name)
                        "
                        [intitalFormGroup]="
                            valueTypeFormGroup.has(variables.name)
                                ? valueTypeFormGroup.get(variables.name)
                                : null
                        "
                        (formGroupRef)="
                            valueTypeFormGroup.set(variables.name, $event)
                        "
                        (valuesChange)="
                            valueTypeChanged($event, variables.name)
                        "
                    ></app-form-generator>
                </div>
                <div class="value-type set-value flex-1 d-flex js-center">
                    <app-form-generator
                        [formGeneratorInput]="
                            variableFormGenInputs.get(variables.name)
                        "
                        [intitalFormGroup]="
                            variableFormGroup.has(variables.name)
                                ? variableFormGroup.get(variables.name)
                                : null
                        "
                        (formGroupRef)="
                            variableFormGroup.set(variables.name, $event)
                        "
                        (resetFormFunction)="resetForm = $event"
                    ></app-form-generator>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<div class="controls">
    <div class="back-button">
        <app-button-generator
            [buttonGenInput]="backButtonGenInput"
        ></app-button-generator>
    </div>
    <div class="action-button">
        <app-multi-button-generator [buttonGenInputs]="actionButtonGenInput">
        </app-multi-button-generator>
    </div>
</div>
