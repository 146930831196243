import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';

@Component({
    selector: 'app-create-custom-compliance-step-one',
    templateUrl: './create-custom-compliance-step-one.component.html',
    styleUrls: ['./create-custom-compliance-step-one.component.sass']
})
export class CreateCustomComplianceStepOneComponent implements OnInit {
    customComplianceFormGenInput: IFormGeneratorInput = null;
    customComplianceFormGroup: FormGroup;
    stepData = null;
    edit: boolean = false;
    widgetRef: Widget;

    buttonGenInput: IButtonGeneratorInput = {
        buttonName: 'Next',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.RAISED,
        function: () => {
            this.next();
        },
    };
    constructor(
        private modalInjectedData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService
    ) {
        this.widgetRef = modalInjectedData.data['widgetRef'];
        if (
            multiStepFormService.stepData.get(modalInjectedData.modalId).has(1)
        ) {
            this.stepData = multiStepFormService.stepData
                .get(modalInjectedData.modalId)
                .get(1);
        } else if (modalInjectedData.data['stepData']) {
            this.stepData = modalInjectedData.data['stepData'];
        }
        this.edit = modalInjectedData.data['edit'];
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        this.customComplianceFormGenInput = {
            formName: 'Compliance Form',
            state: FormState.CREATE,
            formId: Symbol(),
            submitButton: null,
            fields: [
                {
                    label: 'Custom Compliance Name',
                    name: 'complianceName',
                    fieldType: FilterType.TEXT,
                    placeholder: 'Enter Custom Compliance Name',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Compliance name is required'
                        }
                    ],
                    value: this.stepData
                        ? this.stepData['complianceName']
                        : null,
                    disabled: this.edit ? true : false
                },
                {
                    label: 'Custom Compliance Description',
                    name: 'complianceDescription',
                    fieldType: FilterType.TEXT,
                    placeholder: 'Enter Custom Compliance Description',
                    required: false,
                    value: this.stepData
                        ? this.stepData['complianceDescription']
                        : null
                },
                {
                    label: 'Base Compliance',
                    name: 'baseCompliance',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select base compliance',
                    required: true,
                    apiInfo:
                        this.widgetRef.widgetData.widgetInfo[
                            'customCompliance'
                        ]['listBaseCompliance'],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Base compliance is required'
                        }
                    ],
                    value: this.stepData ? this.stepData['baseCompliance'] : []
                }
            ]
        };
    }

    next() {
        if (this.customComplianceFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.customComplianceFormGroup);
            this.customComplianceFormGroup.updateValueAndValidity();
            return;
        }
        this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .set(1, this.customComplianceFormGroup.getRawValue());
        this.multiStepFormService.nextStep(this.modalInjectedData.modalId);
    }
}
