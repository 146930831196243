<div
    [class]="'graph-container tw-flex ' + graphData.extraClass"
    [class.getFullAccessState]="graphData.liteState"
>
    <div
        [style.height.px]="graphData.height"
        class="chartdivLine chartdiv-line tw-flex-f4"
        [id]="graphId"
        [ngClass]="graphData.graphExtraClass ? graphData.graphExtraClass : ''"
        [ngStyle]="graphData.graphStyle ? graphData.graphStyle : null"
    ></div>
    <ng-container *ngIf="!graphData.disableLegend">
        <div class="legendwrapper tw-h-96 tw-overflow-scroll tw-flex-1">
            <div
                [id]="graphLegendId"
                class="legendDivLine"
                [ngClass]="
                    graphData.legendExtraClass ? graphData.legendExtraClass : ''
                "
            ></div>
        </div>
    </ng-container>
</div>
