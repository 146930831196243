import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GridOptions, RowEvent } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { IApiResponse } from 'src/app/shared/interfaces/api/portlets/ApiResponse';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ButtonColorType } from './../../../../enums/ButtonColorType';

@Component({
    selector: 'app-cpu-utilization-edit-modal',
    templateUrl: './cpu-utilization-edit-modal.component.html',
    styleUrls: ['./cpu-utilization-edit-modal.component.sass']
})
export class CpuUtilizationEditModalComponent implements OnInit {
    tableData;
    tableGenInputs: ITableGeneratorInput;
    thresholdFormGenInputs: IFormGeneratorInput = null;
    thresholdFormGroup: FormGroup;
    metricsFormGenInputs: IFormGeneratorInput = null;
    metricsFormGroup: FormGroup;
    emailFormGenInputs: IFormGeneratorInput = null;
    emailFormGroup: FormGroup;
    widgetRef: Widget;
    keysToShow: object = {};
    FormState = FormState;
    integrationName: string = '';
    gridRef: GridOptions;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    utilization = [
        'diskUtilization',
        'cpuUtilization',
        'memoryUtilization',
        'networkOutboundUtilization',
        'networkInboundUtilization',
        'utilization'
    ];
    highWarning: boolean = false;
    allowedDecimal: boolean = false;

    saveButton: IButtonGeneratorInput = {
        buttonName: 'Save',
        buttonType: ButtonType.RAISED,
        buttonColorType: ButtonColorType.PRIMARY,
        showLoader: true,
        function: (buttonRef) => {
            this.save(buttonRef);
        }
    };

    constructor(private modalData: ModalInjectedData) {
        this.tableData = modalData.data['selectedData'];
        this.widgetRef = modalData.data['widgetRef'];
        this.keysToShow = modalData.data['keysToShow'];
        this.highWarning = modalData.data['highWarning'];
        this.allowedDecimal = modalData.data['allowedDecimal'];
    }

    ngOnInit(): void {
        this.setUpBasics();
    }
    setUpBasics() {
        this.tableGenInputs = {
            listExtraction: {
                type: 'DIRECT'
            },
            selection: 'single',
            columns: [],
            afterResponse: (response: IApiResponse) => {
                this.tableGenInputs.columns = [];
                for (const key in this.keysToShow) {
                    if (this.utilization.includes(key)) {
                        this.tableGenInputs.columns.push({
                            columnKey: this.keysToShow[key],
                            columnName: this.keysToShow[key],
                            cellStyle: Helper.cellStyling,
                            cellRenderer: (rowEvent: RowEvent) => {
                                if (rowEvent.data[this.keysToShow[key]]) {
                                    return Helper.extractDataFromObject(
                                        'value',
                                        rowEvent.data[this.keysToShow[key]]
                                    );
                                }
                            }
                        });
                    } else {
                        this.tableGenInputs.columns.push({
                            columnKey: this.keysToShow[key],
                            columnName: this.keysToShow[key]
                        });
                    }
                }
                this.tableGenInputs.columns.push({
                    columnName: 'Action',
                    columnKey: 'action',
                    cellRenderer: (rowEvent: RowEvent) => {
                        const buttons: IButtonData[] = [
                            {
                                buttonName: 'Delete',
                                buttonIcon: {
                                    type: IconType.FONTAWSOME,
                                    class: 'fas fa-trash'
                                },
                                buttonColor: 'warning',
                                showLoader: false,
                                function: () => {
                                    const index = rowEvent.rowIndex;
                                    this.tableData.splice(index, 1);
                                    this.gridRef.api.setRowData(this.tableData);
                                    if (!this.tableData.lenth) {
                                        this.widgetRef.modalService.closeModal(
                                            null,
                                            this.modalData.modalId
                                        );
                                    }
                                }
                            }
                        ];
                        return Helper.generateColumnButtons(
                            buttons,
                            Helper.getCssVarValue.bind(this.widgetRef),
                            this.widgetRef.modalService,
                            null,
                            'text'
                        );
                    }
                });
            }
        };
        this.hitThresholdApi();
        this.generateEscalationMetricsForm();
        this.generateAlertEmails();
    }

    hitThresholdApi() {
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['nagiosUtilization'][
                'listThresholdDetails'
            ]
        );
        apiArgs.input = this.prepareThresholdInputs();
        apiArgs.function = (response) => {
            this.generateThresholdForm(response);
        };
        apiArgs.errorFunction = () => {
            this.generateThresholdForm();
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    generateThresholdForm(data?) {
        this.thresholdFormGenInputs = {
            formName: '',
            state: FormState.IDLE,
            submitButton: null,
            fields: [
                {
                    label: 'Critical',
                    name: 'critical',
                    placeholder: 'Critical',
                    fieldType: FilterType.NUMBER,
                    value:
                        data && data.criticalThreshold
                            ? data.criticalThreshold
                            : '',
                    required: false
                },
                {
                    label: 'Warning',
                    name: 'warning',
                    placeholder: 'warning',
                    fieldType: FilterType.NUMBER,
                    value:
                        data && data.warningThreshold
                            ? data.warningThreshold
                            : '',
                    required: false
                }
            ]
        };
    }
    prepareThresholdInputs() {
        const instanceList = [];
        this.tableData.forEach((server) => {
            const instanceListObj = {
                ipAddress: server['IP Address'],
                displayName: server['Display Name']
            };
            instanceList.push(instanceListObj);
            this.integrationName = server['Integration Name'];
        });
        const input = {
            integrationName: this.integrationName,
            instanceList: instanceList,
            isParent: 'false'
        };
        return input;
    }
    save(buttonRef) {
        if (buttonRef.loader) {
            return;
        }
        const warningValue = this.thresholdFormGroup.getRawValue()['warning'];
        const criticalValue = this.thresholdFormGroup.getRawValue()['critical'];
        if (this.allowedDecimal && warningValue && criticalValue) {
            if (
                Number.isInteger(+warningValue) ||
                Number.isInteger(+criticalValue)
            ) {
                this.widgetRef.notificationsService.showSnackBar(
                    'Kindly re-enter the threshold values. The current values are invalid.',
                    true
                );
                return;
            }
        }
        if (!this.allowedDecimal && warningValue && criticalValue) {
            if (
                !Number.isInteger(+warningValue) ||
                !Number.isInteger(+criticalValue)
            ) {
                this.widgetRef.notificationsService.showSnackBar(
                    'Kindly re-enter the threshold values. The current values are invalid.',
                    true
                );
                return;
            }
        }
        if (this.highWarning) {
            if (
                warningValue < criticalValue ||
                (warningValue && !criticalValue) ||
                (!warningValue && criticalValue)
            ) {
                this.widgetRef.notificationsService.showSnackBar(
                    'Kindly re-enter the threshold values. The current values are invalid.',
                    true
                );
                return;
            }
        }
        if (!this.highWarning) {
            if (
                +warningValue > +criticalValue ||
                (warningValue && !criticalValue) ||
                (!warningValue && criticalValue)
            ) {
                this.widgetRef.notificationsService.showSnackBar(
                    'Kindly re-enter the threshold values. The current values are invalid.',
                    true
                );
                return;
            }
        }
        buttonRef.loader = true;

        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.update
        );
        apiArgs.input = this.prepreInputs();
        apiArgs.function = () => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'Process initiated. Please refresh after 1-2 minutes as it takes time to reflect the data on Nagios.',
                true
            );
            this.widgetRef.modalService.closeModal(
                null,
                this.modalData.modalId
            );
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
            this.widgetRef.modalService.closeModal(
                null,
                this.modalData.modalId
            );
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    prepreInputs() {
        const instanceList = [];
        this.tableData.forEach((server) => {
            this.integrationName = server['Integration Name'];
            const instanceListObj = {
                ipAddress: server['IP Address'],
                displayName: server['Display Name']
            };
            instanceList.push(instanceListObj);
        });
        const inputs = {
            warningThreshold: this.thresholdFormGroup.getRawValue()['warning'],
            criticalThreshold:
                this.thresholdFormGroup.getRawValue()['critical'],
            integrationName: this.integrationName,
            instanceList: instanceList,
            contacts: this.metricsFormGroup.get('contacts').value,
            contactGroups: this.metricsFormGroup.get('contactGroup').value,
            isBulk: true
        };
        return inputs;
    }
    changeState(state) {
        this.thresholdFormGenInputs.state = state;
        this.thresholdFormGenInputs = Helper.dereference(
            this.thresholdFormGenInputs
        );
    }
    generateEscalationMetricsForm() {
        this.metricsFormGenInputs = {
            formName: '',
            state: FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    label: 'Contacts Group',
                    name: 'contactGroup',
                    placeholder: 'Contacts Group',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    apiInfo:
                        this.widgetRef.widgetData.widgetInfo[
                            'nagiosIntegration'
                        ]['listContactGroups'],
                    apiInput: { integrationsList: [this.integrationName] },
                    required: false
                },
                {
                    label: 'Contacts',
                    name: 'contacts',
                    placeholder: 'Contacts',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    apiInfo:
                        this.widgetRef.widgetData.widgetInfo[
                            'nagiosIntegration'
                        ]['listContacts'],
                    apiInput: { integrationsList: [this.integrationName] },
                    required: false
                }
            ]
        };
    }
    generateAlertEmails() {
        this.emailFormGenInputs = {
            formName: '',
            state: FormState.IDLE,
            submitButton: null,
            fields: [
                {
                    label: 'Critical (L1 Email)',
                    name: 'emailCritical',
                    placeholder: 'Critical (L1 Email)',
                    fieldType: FilterType.MATCHIPLIST,
                    required: false
                },
                {
                    label: 'Warning (L2 Email)',
                    name: 'emailWarning',
                    placeholder: 'Warning (L1 Email)',
                    fieldType: FilterType.MATCHIPLIST,
                    required: false
                }
            ]
        };
    }
    hitAlertEmailsApis() {
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['nagiosUtilization'][
                'listAlertEmails'
            ]
        );
        apiArgs.input = this.prepareAlertEmailsInputs();
        apiArgs.function = (response) => {
            this.emailFormGroup
                .get('emailCritical')
                .setValue(response['criticalEmail']);
            this.emailFormGroup
                .get('emailWarning')
                .setValue(response['warningEmail']);
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    prepareAlertEmailsInputs() {
        const input = {
            integrationName: this.integrationName,
            contacts: this.metricsFormGroup.get('contacts').value,
            contactGroups: this.metricsFormGroup.get('contactGroup').value,
            isParent: 'false'
        };
        return input;
    }
}
