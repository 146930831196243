import { ModalAction } from '../enums/ModalAction';
import { Widget } from './Widget';

export class ModalInjectedData {
    data: IData | any;
    modalId?: Symbol;
}

interface IData {
    widgetRef?: Widget;
    purpose?: ModalAction;
    itemData?: any;
}
