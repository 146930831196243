import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import { AgGridColumn } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import * as $ from 'jquery';
import { Helper } from 'src/app/shared/classes/Helper';
import { Linking } from 'src/app/shared/classes/Linking';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { AggridTooltipComponent } from '../../../aggrid-tooltip/aggrid-tooltip.component';

@Component({
    selector: 'app-utilisation',
    templateUrl: './utilisation.component.html',
    styleUrls: ['./utilisation.component.sass'],
})
export class UtilisationComponent implements OnInit, AfterViewInit {
    @ViewChild('agGrid', { static: false }) agGrid: GridOptions;
    readonly numberDataList: string[] = [
        'DiskUtilization',
        'CPUUtilization',
        'ReadIOPS',
        'WriteIOPS',
    ];
    widgetRef: Widget;
    ViewType = ViewType;
    gridApi;
    gridColumnApi;
    rowData = [];
    filteredSearchData;
    columnDefs = [];
    rowSelection;
    agGridIcons = Helper.getAgGridIcons();
    disableSearchInput: boolean;

    rowClassRules;
    frameworkComponents;
    defaultColDef = {
        editable: false,
        sortable: true,
        filter: true,
        resizable: true,
    };
    isDrillable: boolean;
    tableKeys = [];
    tableHeading = {
        key: '',
        value: '',
    };
    constructor(
        widgetData: WidgetInjectedData,
        private cdr: ChangeDetectorRef
    ) {
        this.widgetRef = widgetData.widgetRef;
    }
    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        // Show View Icon
        if (this.widgetRef.lightState) {
            this.defaultColDef.sortable = false;
            this.defaultColDef.filter = false;
            Helper.setRowClassRules(this.widgetRef);
        }

        this.widgetRef.showViewIcon.next(true);

        // Update Header Icon
        this.widgetRef.headerIcon.next({
            class: 'fas fa-chart-bar',
            type: IconType.FONTAWSOME,
        });

        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.agGrid.api.sizeColumnsToFit();
    }

    onQuickFilterChanged(id: string) {
        this.agGrid.api.setQuickFilter(document.getElementById(id)[`value`]);
    }

    async tableRowClicked(slice) {
        if (
            this.widgetRef.widgetLinkingData &&
            this.widgetRef.widgetData.linking &&
            (this.widgetRef.widgetData.linking.legendRow ||
                this.widgetRef.widgetData.linking.legendColumn)
        ) {
            const tempObj = Linking.prepareNextWidgetDataUtilization(
                this.widgetRef,
                slice,
                this.tableKeys
            );
            tempObj['viewId'] = this.widgetRef.widgetLinkingData.viewId;
            const currentLevel =
                this.widgetRef.compareViewService.currentLevel[
                    this.widgetRef.widgetLinkingData.viewId
                ] + 1;
            this.widgetRef.compareViewService.currentLevel[
                this.widgetRef.widgetLinkingData.viewId
            ] = currentLevel;

            if (
                this.widgetRef.apiResponse['dataMap']['drilldown'] &&
                this.widgetRef.apiResponse['dataMap']['drilldown'][
                    'restrictedFilters'
                ]
            ) {
                tempObj['restrictedFilters'] =
                    this.widgetRef.apiResponse['dataMap']['drilldown'][
                        'restrictedFilters'
                    ];
            }
            this.widgetRef.compareViewService.nextWidgetView.next(tempObj);
        }
    }

    goBackDrillDown(home?) {
        if (home) {
            this.widgetRef.compareViewService.currentLevel[
                this.widgetRef.widgetLinkingData.viewId
            ] = 1;
        } else {
            const currentLevel =
                this.widgetRef.compareViewService.currentLevel[
                    this.widgetRef.widgetLinkingData.viewId
                ] - 1;
            this.widgetRef.compareViewService.currentLevel[
                this.widgetRef.widgetLinkingData.viewId
            ] = currentLevel;
        }
        const tempObj = {};
        tempObj[this.widgetRef.widgetLinkingData.viewId] = true;
        this.widgetRef.compareViewService.previousWidgetView.next(tempObj);
    }

    bindData(response) {
        if (
            this.widgetRef &&
            this.widgetRef.widgetLinkingData &&
            this.widgetRef.widgetLinkingData.input
        ) {
            const inputKey = Object.keys(
                this.widgetRef?.widgetLinkingData?.input
            );
            const utilisationValue = Object.values(
                this.widgetRef?.widgetLinkingData?.input
            );
            const formattedText = inputKey[0]
                ?.toString()
                ?.replace(/([A-Z])/g, ' $1')
                .trim();
            const utilisationKey =
                formattedText?.charAt(0).toUpperCase() +
                formattedText?.slice(1);
            this.tableHeading.key = utilisationKey.toString();
            this.tableHeading.value = utilisationValue[0]?.toString();
        }

        if (
            (response.dataMap && !Object.keys(response.dataMap).length) ||
            (response.dataList && !response.dataList.length)
        ) {
            this.widgetRef.extraMessage.next(Messages.NO_DATA_AVAILABLE);
            this.widgetRef.visibleSections.next(new Set());
            this.widgetRef.endLoader();
            return;
        }

        if (response.dataMap.drilldown) {
            this.isDrillable = true;
        } else {
            this.isDrillable = false;
        }
        this.frameworkComponents = { customTooltip: AggridTooltipComponent };

        this.rowData = [];
        this.filteredSearchData = [];
        this.columnDefs = [];
        this.rowData = response.dataList?.length
            ? response.dataList.map((each) => {
                  const res = each;
                  this.numberDataList.forEach((list) => {
                      if (res[list]) {
                          res[list] = +res[list];
                      }
                  });
                  return res;
              })
            : [];
        const columns = response.dataMap.columns;
        const hover = response.dataMap.hover;
        const colorMap = response.dataMap.colorMap;
        this.widgetRef.apiResponse = response;

        if (response.dataMap['tableKeys']) {
            this.tableKeys = response.dataMap['tableKeys'];
        } else if (
            Object.keys(response.dataMap.columns) &&
            Object.keys(response.dataMap.columns).length
        ) {
            this.tableKeys = Object.keys(response.dataMap.columns);
        }

        Object.keys(columns).forEach((column) => {
            const obj = {
                headerName: columns[column],
                field: column,
            } as AgGridColumn;
            if (hover && column in hover) {
                obj.tooltipValueGetter = (item) => {
                    let hoverText = '';
                    if (
                        item &&
                        item.value &&
                        (item.value.trim().length === 0 ||
                            item.value.trim() === '-')
                    ) {
                        return;
                    }
                    const hoverObj = hover[column];
                    Object.keys(hoverObj).forEach((hoverItem, index) => {
                        if (index > 0) {
                            hoverText += '\n';
                        }
                        hoverText += item.data[hoverItem]
                            ? ' ' +
                              hoverObj[hoverItem] +
                              ': ' +
                              item.data[hoverItem]
                            : '-';
                    });
                    hoverText += '\n';
                    return hoverText;
                };
                obj.tooltipComponent = 'customTooltip';
            }
            if (colorMap && column in colorMap) {
                obj.cellRenderer = (item) => {
                    const data = item.data;

                    const value = column in data ? data[column] : '-';
                    if (value === '-') {
                        return '-';
                    }
                    const color = data[colorMap[column]];
                    const div = document.createElement('div');
                    $(div).css({
                        color: value === '-' ? 'black' : color,
                        'text-align': 'left',
                        background:
                            value === '-'
                                ? 'white'
                                : color === 'red'
                                ? 'hsla(var(--negativeColorHslValues),0.2)'
                                : 'hsla(var(--positiveColorHslValues),0.5)',
                        padding: '4px 8px',
                        width: '100%',
                        'font-weight': 'bold',
                    });
                    $(div).append(value);
                    $(div).parent('.ag-cell').css('padding', '0');
                    return div;
                };
            }

            if (
                colorMap &&
                !(column in colorMap) &&
                this.isDrillable &&
                this.widgetRef.widgetLinkingData
            ) {
                obj.cellRenderer = (item) => {
                    const data = item.data;
                    const value = column in data ? data[column] : '-';
                    return `<span style = "color: var(--drillDownColor); cursor: pointer; font-weight: 600;">${value}</span>`;
                };
            }
            this.columnDefs.push(obj);
        });
        this.widgetRef.endLoader();
    }
}
