import { Injectable, NgZone } from '@angular/core';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { IDropdownData } from 'src/app/shared/interfaces/dropdown-data/IDropdownData';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { Helper } from '../../classes/Helper';
import { Messages } from '../../classes/Messages';
import { Widget } from '../../classes/Widget';
import { ReportType } from '../../components/modal-templates/assessee-view/assessment-overview/assessment-overview.component';
import { AuthorizationType } from '../../enums/AuthorizationType';
import { ContentType } from '../../enums/ContentType';
import { FilterType } from '../../enums/FilterType';
import { FormState } from '../../enums/FormState';
import { RequestType } from '../../enums/RequestType';
import { SupportedFormat } from '../../enums/SupportedFormat';
import { IApiInfo } from '../../interfaces/api/IApiInfo';
import { IButtonGeneratorInput } from '../../interfaces/button-generator/IButtonGeneratorInput';
import { HttpService } from '../http/http-main/http.service';
import { WhiteLabelingService } from './../white-labeling/white-labeling.service';

@Injectable({
    providedIn: 'root'
})
export class WidgetCreationService {
    widgetRef: Widget;

    constructor(
        private whiteLabelingService: WhiteLabelingService,
        private httpService: HttpService,
        public ngZone: NgZone
    ) {}

    saveAsDraft(
        draftData: WidgetCreationData,
        callback?,
        errorCallback?,
        widgetId = null
    ) {
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            widgetId
                ? this.widgetRef.widgetData.widgetInfo.update
                : this.widgetRef.widgetData.widgetInfo.create
        );
        apiArgs.intactUrl = apiArgs.url;
        apiArgs.url = widgetId
            ? apiArgs.url.replace('{widgetId}', widgetId)
            : apiArgs.url;
        apiArgs.url = widgetId
            ? apiArgs.url.replace('{widget_id}', widgetId)
            : apiArgs.url;
        apiArgs.input = draftData;
        apiArgs.function = (response) => {
            if (callback) {
                this.widgetRef.notificationsService.showSnackBar(
                    Messages.WIDGET_CREATION_SAVE_AS_DRAFT
                );
                this.widgetRef.refreshWidget();
                callback(response);
            }
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while saving'
            );
            if (errorCallback) {
                errorCallback();
            }
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    convertSalesforceField(
        fields: ISalesforceBackendFormField[]
    ): IFormGeneratorInput {
        const formFields: IFormField[] = [];
        fields.forEach((field: ISalesforceBackendFormField) => {
            formFields.push({
                label: field.name,
                placeholder: '',
                name: field['varId'],
                fieldType: field.fieldType as FilterType,
                required: field.required,
                value: field.defaultValue,
                listData: field.listData,
                varId: field['varId']
            });
        });
        return {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: formFields
        };
    }
    salesforceBToFParser(
        formInput: ISalesforceBackendFormGenInput,
        salesforceRequestData?: any,
        alertKey?: string
    ): IFormGeneratorInput {
        let defaultValues = null;
        if (salesforceRequestData) {
            salesforceRequestData.map((each) => {
                if (!defaultValues) {
                    defaultValues = {};
                }
                defaultValues[each.id] = each.inputString
                    ? each.inputString
                    : each.selection
                    ? each.selection
                    : null;
            });
        }

        const formGenInput: IFormGeneratorInput = {
            formName: formInput.fromName,
            fields: [],
            state: FormState.CREATE,
            submitButton: null
        };

        const fields: IFormField[] = [];
        const subjectKey =
            alertKey === 'HEALTH_ALERT'
                ? 'alertSubject'
                : alertKey === 'BUDGET_ALERT'
                ? 'budgetSubject'
                : null;
        const descriptionKey =
            alertKey === 'HEALTH_ALERT'
                ? 'alertContent'
                : alertKey === 'BUDGET_ALERT'
                ? 'budgetContent'
                : null;

        const subject = this.whiteLabelingService.whiteLableData[subjectKey];
        const description =
            this.whiteLabelingService.whiteLableData[descriptionKey];

        formInput.fields.map((each) => {
            const fieldType = each.fieldType as FilterType;
            const eachField: IFormField = {
                label: each.label,
                placeholder: each.placeHolder,
                fieldType:
                    fieldType === FilterType.CURRENCY
                        ? FilterType.NUMBER
                        : fieldType,
                name: each.id,
                required: each.required,
                value:
                    each.name === 'Subject' && subject
                        ? subject
                        : each.name === 'Description' && description
                        ? description
                        : defaultValues && defaultValues[each.id]
                        ? defaultValues[each.id]
                        : '',
                varId: each.varId
            };

            if (each.listData) {
                eachField.listData = each.listData;
            }

            if (each.fieldType === FilterType.DATE_TIME) {
                eachField.value = {
                    dateValue:
                        defaultValues && defaultValues[each.id]
                            ? defaultValues[each.id]
                            : '',
                    timeValue:
                        defaultValues && defaultValues[each.id]
                            ? defaultValues[each.id]
                            : ''
                };
            }

            fields.push(eachField);
        });

        formGenInput.fields = fields;

        return formGenInput;
    }

    resolveSalesforceInputString(data, type: 'input' | 'selection') {
        if (!data) {
            return type === 'input' ? '' : [];
        }

        if (
            type === 'input' &&
            !Array.isArray(data) &&
            typeof data !== 'object'
        ) {
            return data.toString();
        }

        if (type === 'selection' && Array.isArray(data)) {
            return data;
        }

        if (type === 'selection') {
            return [];
        }

        if (
            type === 'input' &&
            typeof data === 'object' &&
            (data.dateValue || data.timeValue)
        ) {
            const date = Helper.formatDate(data.dateValue, 'yyyy-MM-DD');
            const time = Helper.formatDate(
                data.timeValue,
                'hh:mm:ss',
                'hh:mm A'
            );

            return Helper.formatDate(
                date + ' ' + time,
                'utc',
                'yyyy-MM-DD hh:mm:ss'
            );
        }

        return type === 'input' ? '' : [];
    }
    downloadAssessmentReport(
        buttonRef: IButtonGeneratorInput,
        assessmentId: string,
        assessmentName: string,
        report: string,
        onSuccess: () => void,
        onError: (error) => void
    ) {
        let apiInfo: IApiInfo;
        if (buttonRef.loader) {
            return;
        }
        buttonRef.loader = true;
        if (report === ReportType.DETAILED_REPORT) {
            apiInfo = {
                apiRouteSuffix: ApiUrls.ASSESSMENT_REPORT_DOWNLOAD.replace(
                    '{assessment-id}',
                    assessmentId
                ),
                authorization: AuthorizationType.BEARER_TOKEN,
                requestType: RequestType.POST,
                host: null
            };
        } else if (report === ReportType.SUMMARY_REPORT) {
            apiInfo = {
                apiRouteSuffix:
                    ApiUrls.ASSESSMENT_SUMMARY_REPORT_DOWNLOAD.replace(
                        '{assessment-id}',
                        assessmentId
                    ),
                authorization: AuthorizationType.BEARER_TOKEN,
                requestType: RequestType.POST,
                host: null
            };
        }
        const apiArgs: IHitApi = Helper.generateHitApiConfig(apiInfo);
        apiArgs.config.defaultHeaders = {
            Accept: ContentType.PDF
        };
        const reportType = SupportedFormat.PDF;
        apiArgs.function = (response) => {
            if (
                response &&
                response['dataMap'] &&
                response['dataMap']['preSignedUrl']
            ) {
                Helper.downloadReport(
                    response['dataMap']['preSignedUrl'],
                    reportType,
                    response['dataMap']['fileName'],
                    this.httpService,
                    this.ngZone
                );
            }
            if (onSuccess) {
                onSuccess();
            }
        };
        apiArgs.endFunction = () => {
            if (buttonRef) {
                buttonRef.loader = false;
            }
        };
        apiArgs.errorFunction = (error) => {
            if (onError) {
                onError(error);
            }
        };
        if (!apiArgs.input) {
            apiArgs.input = {};
        }
        new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
    }
}
export interface WidgetCreationData {
    widgetName: string;
    description: {
        type: string;
        heading: string;
        showHeading: boolean;
        value: string;
    }[];
    extraInfo: {
        email?: string;
        serviceNowIntegrationId?: string;
        activityType: WidgetCreationActivityType;
        configuredByEmail?: boolean;
        configuredByServiceNow?: boolean;
        categoryId?: string;
        itemId?: string;
        catalogId?: string;
    };
    form?: any;
}

export enum WidgetCreationActivityType {
    WIDGET_DRAFT = 'WIDGET_DRAFT',
    WIDGET_PUBLISHED = 'WIDGET_PUBLISHED'
}

export interface ISalesforceBackendFormGenInput {
    fromName: string;
    fields?: ISalesforceBackendFormField[];
}

export interface ISalesforceBackendFormField {
    configure?: boolean;
    defaultVariableValue?: boolean;
    disabled?: boolean;
    emailBody?: boolean;
    fieldType?: FilterType | string;
    hideFloatLabel?: boolean;
    id?: string;
    label?: string;
    maxValue?: number;
    minValue?: number;
    name?: string;
    placeHolder?: string;
    required?: boolean;
    sectionName?: string;
    selectionLimit?: number;
    subject?: boolean;
    varId?: string;
    listData?: IDropdownData[];
    defaultValue: string;
}
