<div class="container">
    <div class="field-types">
        <app-request-mediums
            [selectedMedium]="selectedRequestMedium"
            (selected)="requestMediumOutput($event)"
        ></app-request-mediums>
    </div>

    <div class="configure-area">
        <ng-container *ngIf="requestMediums.EMAIL === selectedRequestMedium">
            <div class="text">
                <span class="text-style"
                    >Enter Email Address at which you want the request to be
                    send.</span
                >

                <app-form-generator
                    *ngIf="emailFormsMap.has(emailFormsTypes.EMAIL_ADDRESS)"
                    [formGeneratorInput]="
                        emailFormsMap.get(emailFormsTypes.EMAIL_ADDRESS)
                            .formGenInput
                    "
                    (formGroupRef)="
                        emailFormsMap.get(
                            emailFormsTypes.EMAIL_ADDRESS
                        ).formGroupRef = $event
                    "
                >
                </app-form-generator>
            </div>

            <div class="field-mapping">
                <span class="text-style-xl bold"> Field Mapping </span>

                <div class="row-heading">
                    <div class="col-1">
                        <span class="text-style bold"> Field Name </span>
                    </div>
                    <div class="col-2">
                        <span class="text-style bold"> Mapping Type </span>
                    </div>
                    <div class="col-3">
                        <span class="text-style bold"> Select Value </span>
                    </div>
                </div>
                <hr />

                <div class="row">
                    <div class="col-1">
                        <span class="text-style"> Subject </span>
                    </div>
                    <div class="col-2 flex-radio">
                        <app-form-generator
                            *ngIf="
                                emailFormsMap.has(
                                    emailFormsTypes.SUBJECT_FIELD_MAPPING
                                )
                            "
                            [formGeneratorInput]="
                                emailFormsMap.get(
                                    emailFormsTypes.SUBJECT_FIELD_MAPPING
                                ).formGenInput
                            "
                            (formGroupRef)="
                                emailFormsMap.get(
                                    emailFormsTypes.SUBJECT_FIELD_MAPPING
                                ).formGroupRef = $event
                            "
                            (valuesChange)="fieldMappingEmailChange($event)"
                        >
                        </app-form-generator>
                    </div>
                    <div class="col-3 email-field-mapping">
                        <app-form-generator
                            *ngIf="
                                emailFormsMap.has(emailFormsTypes.SUBJECT_VALUE)
                            "
                            [formGeneratorInput]="
                                emailFormsMap.get(emailFormsTypes.SUBJECT_VALUE)
                                    .formGenInput
                            "
                            (formGroupRef)="
                                emailFormsMap.get(
                                    emailFormsTypes.SUBJECT_VALUE
                                ).formGroupRef = $event
                            "
                        >
                        </app-form-generator>
                    </div>
                </div>

                <div class="row">
                    <div class="col-1">
                        <span class="text-style"> Body </span>
                    </div>
                    <div class="col-2 flex-radio">
                        <app-form-generator
                            *ngIf="
                                emailFormsMap.has(
                                    emailFormsTypes.BODY_FIELD_MAPPING
                                )
                            "
                            [formGeneratorInput]="
                                emailFormsMap.get(
                                    emailFormsTypes.BODY_FIELD_MAPPING
                                ).formGenInput
                            "
                            (formGroupRef)="
                                emailFormsMap.get(
                                    emailFormsTypes.BODY_FIELD_MAPPING
                                ).formGroupRef = $event
                            "
                        >
                        </app-form-generator>
                    </div>
                    <div class="col-3 email-field-mapping">
                        <app-form-generator
                            *ngIf="
                                emailFormsMap.has(emailFormsTypes.BODY_VALUE)
                            "
                            [formGeneratorInput]="
                                emailFormsMap.get(emailFormsTypes.BODY_VALUE)
                                    .formGenInput
                            "
                            (formGroupRef)="
                                emailFormsMap.get(
                                    emailFormsTypes.BODY_VALUE
                                ).formGroupRef = $event
                            "
                            (valuesChange)="bodyFieldValueChanges($event)"
                        >
                        </app-form-generator>
                    </div>
                </div>
            </div>

            <div
                class="configure-area email-body"
                *ngIf="
                    emailBodyTemplateValues && emailBodyTemplateValues.length
                "
            >
                <ng-container *ngFor="let data of emailBodyTemplateValues">
                    <span class="text-style"> {{ data }} : </span>
                    <br />
                    <br />
                    <hr />
                    <br />
                </ng-container>
            </div>
        </ng-container>

        <ng-container
            *ngIf="requestMediums.SERVICE_NOW === selectedRequestMedium"
        >
            <div class="text">
                <span class="text-style"> Select Service Catalog Item </span>
            </div>

            <div class="field-mapping">
                <div class="row row-unset">
                    <div class="col-1 flex-ratio-1 form-width padding-unset">
                        <app-form-generator
                            *ngIf="
                                serviceNowFormsMap.has(
                                    serviceNowFormTypes.CATALOG
                                )
                            "
                            [formGeneratorInput]="
                                serviceNowFormsMap.get(
                                    serviceNowFormTypes.CATALOG
                                ).formGenInput
                            "
                            (formGroupRef)="
                                serviceNowFormsMap.get(
                                    serviceNowFormTypes.CATALOG
                                ).formGroupRef = $event
                            "
                            (valuesChange)="catalogValueChange($event)"
                        >
                        </app-form-generator>
                    </div>
                </div>

                <div class="row row-unset">
                    <div class="col-2 flex-ratio-1 form-width">
                        <app-form-generator
                            *ngIf="
                                serviceNowFormsMap.has(
                                    serviceNowFormTypes.CATEGORY
                                ) &&
                                serviceNowFormsMap.has(
                                    serviceNowFormTypes.CATALOG
                                ) &&
                                serviceNowFormsMap.get(
                                    serviceNowFormTypes.CATALOG
                                ).formGroupRef &&
                                serviceNowFormsMap.get(
                                    serviceNowFormTypes.CATALOG
                                ).formGroupRef.value['catalogId']
                            "
                            [formGeneratorInput]="
                                serviceNowFormsMap.get(
                                    serviceNowFormTypes.CATEGORY
                                ).formGenInput
                            "
                            (formGroupRef)="
                                serviceNowFormsMap.get(
                                    serviceNowFormTypes.CATEGORY
                                ).formGroupRef = $event
                            "
                            (valuesChange)="categoryValueChange($event)"
                        >
                        </app-form-generator>
                    </div>
                </div>

                <div class="row row-unset">
                    <div class="col-3 flex-ratio-1 form-width">
                        <app-form-generator
                            *ngIf="
                                serviceNowFormsMap.has(
                                    serviceNowFormTypes.ITEMS
                                ) &&
                                serviceNowFormsMap.has(
                                    serviceNowFormTypes.CATEGORY
                                ) &&
                                serviceNowFormsMap.get(
                                    serviceNowFormTypes.CATEGORY
                                ).formGroupRef &&
                                serviceNowFormsMap.get(
                                    serviceNowFormTypes.CATALOG
                                ).formGroupRef.value['catalogId'] &&
                                serviceNowFormsMap.get(
                                    serviceNowFormTypes.CATEGORY
                                ).formGroupRef.value['categoryId']
                            "
                            [formGeneratorInput]="
                                serviceNowFormsMap.get(
                                    serviceNowFormTypes.ITEMS
                                ).formGenInput
                            "
                            (formGroupRef)="
                                serviceNowFormsMap.get(
                                    serviceNowFormTypes.ITEMS
                                ).formGroupRef = $event
                            "
                            (valuesChange)="serviceNowItemsValueChange($event)"
                        >
                        </app-form-generator>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="serviceNowItemSelected">
                <ng-container
                    *ngIf="serviceNowFields && serviceNowFields.length"
                >
                    <div class="field-mapping">
                        <span class="text-style-xl bold"> Field Mapping </span>

                        <div class="row-heading">
                            <div class="col-1">
                                <span class="text-style bold">
                                    Field Name
                                </span>
                            </div>
                            <div class="col-2 flex-center">
                                <span class="text-style bold">
                                    Mapping Type
                                </span>
                            </div>
                            <div class="col-3">
                                <span class="text-style bold">
                                    Select Value
                                </span>
                            </div>
                        </div>
                        <hr />

                        <div
                            class="row"
                            *ngFor="let field of serviceNowFields; index as i"
                        >
                            <div class="col-1">
                                <span
                                    class="text-style"
                                    [innerHTML]="field?.label"
                                >
                                </span>

                                <span
                                    *ngIf="field?.mandatory"
                                    class="text-style mat-form-field-required-marker"
                                >
                                    *
                                </span>
                            </div>

                            <div class="col-2 flex-radio flex-center">
                                <app-form-generator
                                    *ngIf="field?.mappingForm"
                                    [formGeneratorInput]="field.mappingForm"
                                    (formGroupRef)="
                                        field.mappingFormGroupRef = $event
                                    "
                                    (valuesChange)="
                                        serviceNowMappingValueChange($event, i)
                                    "
                                >
                                </app-form-generator>
                            </div>

                            <div class="col-3">
                                <app-form-generator
                                    *ngIf="
                                        field?.showDefaultForm &&
                                        field?.mappingValueDefaultForm
                                    "
                                    [formGeneratorInput]="
                                        field.mappingValueDefaultForm
                                    "
                                    (formGroupRef)="
                                        field.mappingValueDefaultFormGroupRef =
                                            $event
                                    "
                                >
                                </app-form-generator>

                                <app-form-generator
                                    *ngIf="
                                        !field?.showDefaultForm &&
                                        field?.mappingValueRequestForm
                                    "
                                    [formGeneratorInput]="
                                        field.mappingValueRequestForm
                                    "
                                    (formGroupRef)="
                                        field.mappingValueRequestFormGroupRef =
                                            $event
                                    "
                                    (valuesChange)="
                                        serviceNowRequestFormValueChange(
                                            $event,
                                            field
                                        )
                                    "
                                >
                                </app-form-generator>

                                <span
                                    class="value-mapping-text text-style bold clickable"
                                    (click)="
                                        openValueMappingModal(
                                            field,
                                            field
                                                .mappingValueRequestFormGroupRef
                                                .value
                                        )
                                    "
                                    *ngIf="field.showValueMapping"
                                >
                                    Value Mapping</span
                                >
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container
                    *ngIf="
                        serviceNowFields &&
                        serviceNowFields.length === 0 &&
                        !loaderGroup.serviceNowFields
                    "
                >
                    <div class="not-found">
                        <span class="text-style bold"> No fields found </span>
                    </div>
                </ng-container>
                <ng-container
                    *ngIf="
                        serviceNowFields &&
                        serviceNowFields.length === 0 &&
                        loaderGroup.serviceNowFields
                    "
                >
                    <ng-container *ngTemplateOutlet="loader"></ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>

<div class="footer-button-container">
    <div class="clear-fix"></div>
    <div class="buttons">
        <app-multi-button-generator
            [buttonGenInputs]="buttonInputs"
            [options]="buttonOptions"
        >
        </app-multi-button-generator>
    </div>
</div>

<ng-template #loader>
    <div class="overlay-content">
        <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
    </div>
</ng-template>
