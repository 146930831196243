<div class="header-container">
    <div
        class="label"
        [class.with-menu]="params.enableMenu"
        (click)="onSortRequested($event)"
    >
        <span ref="eText" [attr.aria-colindex]="columnIndex + 1">{{
            params.displayName
        }}</span>

        <div
            *ngIf="params.enableSorting && sortRequested"
            [data]="sortIcon"
            appIcon
            class="sort-icon"
        ></div>
    </div>

    <div *ngIf="params.enableMenu" #menuButton (click)="onMenuClicked($event)">
        <div appIcon [data]="filterMenu" class="filter-icon"></div>
    </div>

    <div *ngIf="showCollapseIcon" class="collapse-icon-container">
        <div
            (click)="expandColumn()"
            [data]="isExpanded ? collapseIcon : expandIcon"
            appIcon
            class="icon"
        ></div>
    </div>
</div>
