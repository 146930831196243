import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ConfirmationModalComponent } from '../../../modal-templates/confirmation-modal/confirmation-modal.component';

@Component({
    selector: 'app-deprovisioning',
    templateUrl: './deprovisioning.component.html',
    styleUrls: ['./deprovisioning.component.sass']
})
export class DeprovisioningComponent implements OnInit {
    widgetRef: Widget;
    tableInput: ITableGeneratorInput;
    agGrid: GridOptions;
    input = {};

    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
        this.tableInput = {
            afterResponse: (response) => {
                this.addColumns(response);
            },
            selection: 'multiple',
            buttons: [
                {
                    buttonName: 'Delete',
                    buttonType: ButtonType.RAISED,
                    buttonColorType: ButtonColorType.WARN,
                    function: this.terminateInstance.bind(this)
                }
            ],
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'dataList'
            },
            columns: []
        };
    }

    ngOnInit(): void {}

    addColumns(response) {
        Object.keys(response['dataMap']['tableKeys']).forEach((key) => {
            this.tableInput.columns.push({
                columnName: response['dataMap']['tableKeys'][key],
                columnKey: key
            });
        });
    }

    terminateInstance() {
        if (!this.agGrid || this.agGrid.api.getSelectedNodes().length === 0) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                '',
                'Select Atleast One Resource.'
            );
            return;
        }
        if (this.agGrid.api.getSelectedNodes().length > 5) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                '',
                'You Can Select Maximum 5 Resources.'
            );
            return;
        }

        this.input['manageServices'] = {};
        const instanceIdToDelete = [];
        this.agGrid.api.getSelectedNodes().forEach((item) => {
            if (
                item.data &&
                item.data[
                    this.widgetRef.widgetData.widgetInfo.delete[
                        'columnsToChoose'
                    ][0]['id']
                ]
            ) {
                instanceIdToDelete.push(
                    item.data[
                        this.widgetRef.widgetData.widgetInfo.delete[
                            'columnsToChoose'
                        ][0]['id']
                    ]
                );
            }

            if (
                !(
                    item.data[
                        this.widgetRef.widgetData.widgetInfo.delete[
                            'prepareKeysArray'
                        ][0]
                    ] +
                        '|' +
                        item.data[
                            this.widgetRef.widgetData.widgetInfo.delete[
                                'prepareKeysArray'
                            ][1]
                        ] in
                    this.input['manageServices']
                )
            ) {
                this.input['manageServices'][
                    item.data[
                        this.widgetRef.widgetData.widgetInfo.delete[
                            'prepareKeysArray'
                        ][0]
                    ] +
                        '|' +
                        item.data[
                            this.widgetRef.widgetData.widgetInfo.delete[
                                'prepareKeysArray'
                            ][1]
                        ]
                ] = [
                    item.data[
                        this.widgetRef.widgetData.widgetInfo.delete[
                            'columnsToChoose'
                        ][0]['id']
                    ]
                ];
            } else {
                this.input['manageServices'][
                    item.data[
                        this.widgetRef.widgetData.widgetInfo.delete[
                            'prepareKeysArray'
                        ][0]
                    ] +
                        '|' +
                        item.data[
                            this.widgetRef.widgetData.widgetInfo.delete[
                                'prepareKeysArray'
                            ][1]
                        ]
                ].push(
                    item.data[
                        this.widgetRef.widgetData.widgetInfo.delete[
                            'columnsToChoose'
                        ][0]['id']
                    ]
                );
            }
        });

        const confirmationModalData: IConfirmationModal = {
            function: (modalId: Symbol) => {
                const apiArgs: IHitApi = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo.delete
                );
                apiArgs.input = this.input;
                (apiArgs.function = (response) => {
                    this.widgetRef.notificationsService.showSnackBar(
                        'Resource(s) deleted successfully'
                    );
                    this.widgetRef.refreshWidget();
                    this.widgetRef.modalService.closeModal(null, modalId);
                }),
                    (apiArgs.errorFunction = (error) => {
                        this.widgetRef.modalService.closeModal(null, modalId);
                        Helper.showErrorMessage(
                            this.widgetRef.notificationsService,
                            error,
                            'Error Deleting Resources.'
                        );
                    }),
                    new HitApi(
                        apiArgs,
                        this.widgetRef.httpService,
                        this.widgetRef.ngZone
                    ).hitApi();
            },
            modalName: 'Delete Instance',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            contextIcon: {
                extraClass: 'color-accent',
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclamation-triangle'
            },
            confirmationMessage:
                'Are you sure you want to terminate these instances : ' +
                instanceIdToDelete.toString(),
            buttonText: 'Terminate',
            buttonColorType: ButtonColorType.SECONDARY,
            loaderOnExec: true
        };

        const modalData: IModalData = {
            modalName: this.widgetRef.widgetData.widgetInfo.text,
            modalIcon: confirmationModalData.modalIcon,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 45,
            modalWidthVw: 50,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ConfirmationModalComponent,
                        payload: {
                            data: {
                                function: confirmationModalData.function,
                                params: confirmationModalData
                            }
                        }
                    },
                    stepName: 'Confirmation'
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }
}
