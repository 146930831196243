import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as $ from 'jquery';
import { FileInput } from 'ngx-material-file-input';
import { BehaviorSubject } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { WhitelabelingColorPalette } from 'src/app/shared/enums/WhitelabelingColorPalette';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IInfoModal } from 'src/app/shared/interfaces/modal/IInfoModal';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { WhiteLabelingService } from 'src/app/shared/services/white-labeling/white-labeling.service';
import { DefaultValues } from './../../../../classes/DefaultValues';

@Component({
    selector: 'app-domain-configuration',
    templateUrl: './domain-configuration.component.html',
    styleUrls: ['./domain-configuration.component.sass']
})
export class DomainConfigurationComponent implements OnInit, AfterViewInit {
    widgetRef: Widget;
    readonly MORE_INFO_HOVER_TEXT = `Click here for more info`;
    configExist = false;
    domainConfigForm: IFormGeneratorInput = null;
    domainConfigFormGroup: FormGroup = null;
    domainConfigFormResetFunc = null;
    orgProprietaryForm: IFormGeneratorInput = null;
    orgProprietaryFormResetFunc = null;
    orgProprietaryFormGroup: FormGroup = null;
    socialChannelForm: IFormGeneratorInput = null;
    socialChannelFormResetFunc = null;
    socialChannelFormGroup: FormGroup = null;
    colorThemeForm: IFormGeneratorInput = null;
    colorThemeFormResetFunc = null;
    colorThemeFormGroup: FormGroup = null;
    emailContentForms: {
        sectionHeading: string;
        formGeneratorInput: IFormGeneratorInput;
        formGroup: FormGroup;
        resetFunc;
        infoFunc;
    }[] = [];

    saveButtonLoader = false;
    deleteButtonLoader = false;
    edit = false;
    FormState = FormState;
    FORMS_AVAILABLE = FORMS_AVAILABLE;
    widgetIcon = {
        class: 'fas fa-globe',
        type: IconType.FONTAWSOME
    };

    infoData: BehaviorSubject<IInfoModal[]> = new BehaviorSubject<IInfoModal[]>(
        []
    );

    constructor(
        widgetData: WidgetInjectedData,
        private whitelabelingService: WhiteLabelingService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(false);

        // Update Header Icon
        this.widgetRef.headerIcon.next(this.widgetIcon);
    }

    bindData(response) {
        this.resetAllForms();
        if (
            !response ||
            ('whitelabel' in response && !response.whitelabel) ||
            !response.hasOwnProperty('whitelabel')
        ) {
            // Domain configuration not set
            this.configExist = false;
            this.edit = false;
        } else {
            // Domain configuration set

            this.infoData.next([
                {
                    infoHeading: '<strong>Cloudfront DNS => </strong>',
                    content: [
                        {
                            type: 'PARAGRAPH',
                            data: [
                                `<a href="https://${response['domainConfig']['cloudFrontDns']}" target="_blank" rel=”noopener noreferrer”>${response['domainConfig']['cloudFrontDns']}</a>`
                            ]
                        }
                    ]
                }
            ]);
            this.configExist = true;
            this.edit = true;
        }
        this.setupDomainConfigForm(response);
        this.widgetRef.endLoader();
    }

    resetAllForms() {
        this.domainConfigForm = null;
        this.domainConfigFormGroup = null;
        this.domainConfigFormResetFunc = null;
        this.orgProprietaryForm = null;
        this.orgProprietaryFormResetFunc = null;
        this.orgProprietaryFormGroup = null;
        this.socialChannelForm = null;
        this.socialChannelFormResetFunc = null;
        this.socialChannelFormGroup = null;
        this.colorThemeForm = null;
        this.colorThemeFormResetFunc = null;
        this.colorThemeFormGroup = null;
        this.emailContentForms = [];
    }

    setupDomainConfigForm(response) {
        // Domain Configuration Form
        this.domainConfigForm = {
            formName: 'Domain Configuration',
            submitButton: {
                buttonName: 'Next',
                function: (buttonRef: IButtonData, formGroup: FormGroup) => {
                    this.domainConfigFormGroup = formGroup;
                    this.domainConfigForm.state = FormState.IDLE;
                    this.changeReference(
                        this.domainConfigForm,
                        FORMS_AVAILABLE.DOMAIN_CONFIG_FORM
                    );
                    this.setupOrgProprietary(response);
                },
                showLoader: false,
                buttonType: 'PRIMARY'
            },
            fields: [
                {
                    label: 'Domain Name',
                    fieldType: FilterType.TEXT,
                    name: 'domainName',
                    placeholder: 'Domain Name',
                    required: true,
                    disabled: this.configExist ? true : false,
                    value: this.configExist
                        ? response['domainConfig']['customDns']
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Domain name is required'
                        },
                        {
                            validator: CustomValidators.domain,
                            errorMessage: 'Invalid domain name'
                        }
                    ],
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Your Domain Name',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Enter the full name of your registered website. This is the address on which WhiteLabel will be set up and your customers will visit this domain to access Centilytics`,
                                            `<b>Recommended Format:</b> (yourdomain).(com)(* This field take inputs only as lower case letters, numbers, and following characters: ' _ - . ')`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                },
                {
                    label: 'Certificate Body',
                    fieldType: FilterType.TEXTAREA,
                    name: 'certificateBody',
                    placeholder: 'Certificate Body',
                    required: true,
                    value: this.configExist
                        ? response['domainConfig']
                            ? response['domainConfig']['certificateBody']
                            : ''
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Certificate body is required'
                        }
                    ],
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Certificate File',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Submit the SSL certificate of your registered domain.`,
                                            `This will be used to validate the domain you have submitted for WhiteLabel.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                },
                {
                    label: 'Private Key',
                    fieldType: FilterType.TEXTAREA,
                    name: 'privateKey',
                    placeholder: 'Private Key',
                    required: true,
                    value: this.configExist
                        ? response['domainConfig']
                            ? response['domainConfig']['privateKey']
                            : ''
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Private key is required'
                        }
                    ],
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Private Key',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Submit the private key of your registered domain.`,
                                            `This will be used to validate SSL certificate on the domain that you have submitted for WhiteLabel.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                },
                {
                    label: 'Certificate Chain',
                    fieldType: FilterType.TEXTAREA,
                    name: 'certificateChain',
                    placeholder: 'Certificate Chain',
                    required: true,
                    value: this.configExist
                        ? response['domainConfig']
                            ? response['domainConfig']['certificateChain']
                            : ''
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Certificate chain is required'
                        }
                    ],
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Certificate Chain',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Submit the certificate chain I.e. from root certificate to the end-user certificate, for your registered domain.`,
                                            `The certificates in the chain should be issued by trusted certificate authorities(CAs).`,
                                            `These certificates will be used for validating end-to-end trusted connection onyour registered domain.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                }
            ],
            state: this.configExist ? FormState.IDLE : FormState.CREATE
        };
        if (this.configExist) {
            this.setupOrgProprietary(response);
        }
    }

    setupOrgProprietary(response) {
        // Org Proprietary Configuration Form
        this.orgProprietaryForm = {
            formName: 'Configure Organization’s Proprietary',
            submitButton: {
                buttonName: 'Next',
                function: (buttonRef: IButtonData, formGroup: FormGroup) => {
                    this.orgProprietaryFormGroup = formGroup;
                    this.orgProprietaryForm.state = FormState.IDLE;
                    this.changeReference(
                        this.orgProprietaryForm,
                        FORMS_AVAILABLE.ORG_PROPRIETARY_FORM
                    );
                    this.setupSocialChannels(response);
                },
                showLoader: false,
                buttonType: 'PRIMARY'
            },
            fields: [
                {
                    label: 'Upload Logo',
                    fieldType: FilterType.FILE,
                    accept: 'image/png',
                    name: 'logo',
                    placeholder: 'Upload Logo',
                    required: true,
                    value: this.configExist
                        ? response['orgProprietary']['logo']
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Logo is required'
                        }
                    ],
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Logo',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Upload your company’s trademark logo. This logo will appear in the header, login screen and as element wherever required.`,
                                            `<b>Edit:</b> If you want to change the submission, you can select ‘Edit Insight’ option on top right.`,
                                            `<b>Recommended Dimension:</b> 350px X 100px.`,
                                            `<b>Recommended Size:</b> 100KB.`,
                                            `<b>Recommended Format:</b> PNG.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                },
                {
                    label: 'Upload Favicon',
                    fieldType: FilterType.FILE,
                    accept: 'image/png',
                    name: 'miniLogo',
                    placeholder: 'Upload Favicon',
                    required: true,
                    value: this.configExist
                        ? response['orgProprietary']['miniLogo']
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Favicon is required'
                        }
                    ],
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Logo',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Upload an image as an icon for your whitelabel domain. This image will be displayed as the browser tab icon.`,
                                            `<b>Edit:</b> If you want to change the submission, you can select ‘Edit Insight’ option on top right.`,
                                            `<b>Recommended Dimension:</b> 11px x 20px.`,
                                            `<b>Recommended Size:</b> 100KB.`,
                                            `<b>Recommended Format:</b> PNG.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                },
                {
                    label: 'Upload Login Image',
                    fieldType: FilterType.FILE,
                    accept: 'image/png',
                    name: 'loginImage',
                    placeholder: 'Upload Login Image',
                    required: true,
                    value: this.configExist
                        ? response['orgProprietary']['loginImage']
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Login image is required'
                        }
                    ],
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Logo',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Upload your company’s image. This image will appear on the Login Page of the Console.`,
                                            `<b>Edit:</b> If you want to change the submission, select the ‘Edit Insight’ option on the top right.`,
                                            `<b>Recommended Dimension:</b> 510px X 550px.`,
                                            `<b>Recommended Size:</b> 300KB.`,
                                            `<b>Recommended Format:</b> PNG.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                },
                {
                    label: 'Email Authentication',
                    fieldType: FilterType.EMAIL,
                    name: 'email',
                    placeholder: 'Email Authentication',
                    required: true,
                    value: this.configExist
                        ? response['orgProprietary']['email']
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Domain name is required'
                        },
                        {
                            validator: CustomValidators.email,
                            errorMessage: 'Invalid email'
                        }
                    ],
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Email Authentication',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Enter the email ID that you want to authenticate to communicate with your users in order send reports, alerts and much more. This should be a valid email ID.`,
                                            `Register the submitted email ID with Centilytics by clicking ‘Register’ and click on ‘verify’ to ensure Centilytics is ready to send emails.`,
                                            `If you do not register and verify the email ID, then Centilytics will send emailsthrough default Centilytics email ID.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                }
            ],
            state: this.configExist ? FormState.IDLE : FormState.CREATE
        };
        if (this.configExist) {
            this.setupSocialChannels(response);
        }
    }

    setupSocialChannels(response) {
        this.socialChannelForm = {
            formName: 'Configure Social Channels',
            submitButton: {
                buttonName: 'Next',
                function: (buttonRef: IButtonData, formGroup: FormGroup) => {
                    this.socialChannelFormGroup = formGroup;
                    this.socialChannelForm.state = FormState.IDLE;
                    this.changeReference(
                        this.socialChannelForm,
                        FORMS_AVAILABLE.SOCIAL_CHANNEL_FORM
                    );
                    this.setupColorTheme(response);
                },
                showLoader: false,
                buttonType: 'PRIMARY'
            },
            fields: [
                {
                    label: 'Organization Url',
                    fieldType: FilterType.URL,
                    name: 'organizationalUrl',
                    placeholder: 'Organization Url',
                    value: this.configExist
                        ? response['socialMediaUrl']['organizationalUrl']
                        : '',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Organization url is required'
                        },
                        {
                            validator: CustomValidators.url,
                            errorMessage: 'Invalid url'
                        }
                    ],
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Organization Url',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Enter the website address of your homepage. This address will be added as hyperlinks where redirection is required.`,
                                            `This is an optional field. If not provided, then the hyperlink will be disabled, and the redirections icons will also not appear.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                },
                {
                    label: 'Knowledge Base Url',
                    fieldType: FilterType.URL,
                    name: 'knowledgeBaseUrl',
                    placeholder: 'Knowledge Base Url',
                    value: this.configExist
                        ? response['socialMediaUrl']['knowledgeBaseUrl']
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.url,
                            errorMessage: 'Invalid url'
                        }
                    ],
                    suffixIcon: {
                        hoverText: `Please input a valid URL which will be used to redirect the user.`,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        }
                    }
                },
                {
                    label: 'Facebook Url',
                    fieldType: FilterType.URL,
                    name: 'facebookUrl',
                    placeholder: 'Facebook Url',
                    value: this.configExist
                        ? response['socialMediaUrl']['facebookUrl']
                        : '',
                    required: false,
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Facebook Url',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Enter the address of your company’s official Facebook page. This address will be added as hyperlinks where redirection is required.`,
                                            `This is an optional field. If not provided, then the hyperlink will be disabled, and the redirections icons will also not appear.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                },
                {
                    label: 'Linkedin Url',
                    fieldType: FilterType.URL,
                    name: 'linkedinUrl',
                    placeholder: 'Linkedin Url',
                    value: this.configExist
                        ? response['socialMediaUrl']['linkedinUrl']
                        : '',
                    required: false,
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Linkedin Url',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Enter the address of your company’s official LinkedIn page. This address will be added as hyperlinks where redirection is required.`,
                                            `This is an optional field. If not provided, then the hyperlink will be disabled, and the redirections icons will also not appear.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                },
                {
                    label: 'Twitter Url',
                    fieldType: FilterType.URL,
                    name: 'twitterUrl',
                    placeholder: 'Twitter Url',
                    value: this.configExist
                        ? response['socialMediaUrl']['twitterUrl']
                        : '',
                    required: false,
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Twitter Url',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Enter the address of your company’s official twitter page. This address will beadded as hyperlinks where redirection is required.`,
                                            `This is an optional field. If not provided, then the hyperlink will be disabled, and the redirections icons will also not appear.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                }
            ],
            state: this.configExist ? FormState.IDLE : FormState.CREATE
        };
        if (this.configExist) {
            this.setupColorTheme(response);
        }
    }

    setupColorTheme(response) {
        const defaultTheme = {};
        Object.keys(this.whitelabelingService.centilyticsData.theme).forEach(
            (key) => {
                defaultTheme[key] = Helper.getHex(
                    this.whitelabelingService.centilyticsData.theme[key]
                );
            }
        );

        this.colorThemeForm = {
            formName: 'Configure Theme',
            submitButton: {
                buttonName: 'Next',
                function: (buttonRef: IButtonData, formGroup: FormGroup) => {
                    this.colorThemeFormGroup = formGroup;
                    this.colorThemeForm.state = FormState.IDLE;
                    this.changeReference(
                        this.colorThemeForm,
                        FORMS_AVAILABLE.COLOR_THEME_FORM
                    );
                    this.setupMailContent(response);
                },
                showLoader: false,
                buttonType: 'PRIMARY'
            },
            fields: [
                {
                    label: 'Primary Color',
                    fieldType: FilterType.COLOR,
                    name: WhitelabelingColorPalette.PRIMARY,
                    placeholder: 'Primary Color',
                    required: true,
                    value: this.configExist
                        ? response['themeConfig']
                            ? response['themeConfig'][
                                  WhitelabelingColorPalette.PRIMARY
                              ]
                            : defaultTheme[WhitelabelingColorPalette.PRIMARY]
                        : defaultTheme[WhitelabelingColorPalette.PRIMARY],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Primary color is required'
                        }
                    ],
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Primary Color',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Select the color that you want to use for side menu bar, aggregated tabs and modal headers.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                },
                {
                    label: 'Accent Color',
                    fieldType: FilterType.COLOR,
                    name: WhitelabelingColorPalette.ACCENT,
                    placeholder: 'Accent Color',
                    required: true,
                    value: this.configExist
                        ? response['themeConfig']
                            ? response['themeConfig'][
                                  WhitelabelingColorPalette.ACCENT
                              ]
                            : defaultTheme[WhitelabelingColorPalette.ACCENT]
                        : defaultTheme[WhitelabelingColorPalette.ACCENT],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Accent color is required'
                        }
                    ],
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Accent Color',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `This color will be reflected as a highlighter when you hover on menu panel, And color for buttons and checkboxes.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                },
                {
                    label: 'Primary Text Color',
                    fieldType: FilterType.COLOR,
                    name: WhitelabelingColorPalette.PRIMARY_TEXT_COLOR,
                    placeholder: 'Primary Text Color',
                    required: true,
                    value: this.configExist
                        ? response['themeConfig']
                            ? response['themeConfig'][
                                  WhitelabelingColorPalette.PRIMARY_TEXT_COLOR
                              ]
                            : defaultTheme[
                                  WhitelabelingColorPalette.PRIMARY_TEXT_COLOR
                              ]
                        : defaultTheme[
                              WhitelabelingColorPalette.PRIMARY_TEXT_COLOR
                          ],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Primary text color is required'
                        }
                    ],
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Primary Text Color',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Select the color of the text that will be displayed on the side menu bar, aggregated tabs and modal headers.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                },
                {
                    label: 'Widget Background Color',
                    fieldType: FilterType.COLOR,
                    name: WhitelabelingColorPalette.WIDGET_BACKGROUND,
                    placeholder: 'Widget Background Color',
                    required: true,
                    value: this.configExist
                        ? response['themeConfig']
                            ? response['themeConfig'][
                                  WhitelabelingColorPalette.WIDGET_BACKGROUND
                              ]
                            : defaultTheme[
                                  WhitelabelingColorPalette.WIDGET_BACKGROUND
                              ]
                        : defaultTheme[
                              WhitelabelingColorPalette.WIDGET_BACKGROUND
                          ],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Widget background color is required'
                        }
                    ],
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Widget Background Color',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Select the color for the header of the insights displayed across all modules.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                },
                {
                    label: 'Widget Text Color',
                    fieldType: FilterType.COLOR,
                    name: WhitelabelingColorPalette.WIDGET_TEXT,
                    placeholder: 'Widget Text Color',
                    required: true,
                    value: this.configExist
                        ? response['themeConfig']
                            ? response['themeConfig'][
                                  WhitelabelingColorPalette.WIDGET_TEXT
                              ]
                            : defaultTheme[
                                  WhitelabelingColorPalette.WIDGET_TEXT
                              ]
                        : defaultTheme[WhitelabelingColorPalette.WIDGET_TEXT],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Widget text color is required'
                        }
                    ],
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Widget Text Color',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Select the color of the text displayed on the insight header across all modules.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                },
                {
                    label: 'Main Background Color',
                    fieldType: FilterType.COLOR,
                    name: WhitelabelingColorPalette.MAIN_BACKGROUND,
                    placeholder: 'Main Background Color',
                    required: true,
                    value: this.configExist
                        ? response['themeConfig']
                            ? response['themeConfig'][
                                  WhitelabelingColorPalette.MAIN_BACKGROUND
                              ]
                            : defaultTheme[
                                  WhitelabelingColorPalette.MAIN_BACKGROUND
                              ]
                        : defaultTheme[
                              WhitelabelingColorPalette.MAIN_BACKGROUND
                          ],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Main background color is required'
                        }
                    ],
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Main Background Color',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Select the primary color which will be reflected as background in the console.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                },
                {
                    label: 'Nav Footer Background Color',
                    fieldType: FilterType.COLOR,
                    name: WhitelabelingColorPalette.NAV_FOOTER_COLOR,
                    placeholder: 'Nav Footer Background Color',
                    required: true,
                    value: this.configExist
                        ? response['themeConfig']
                            ? response['themeConfig']['--navFooterColor']
                            : defaultTheme[
                                  WhitelabelingColorPalette.NAV_FOOTER_COLOR
                              ]
                        : defaultTheme[
                              WhitelabelingColorPalette.NAV_FOOTER_COLOR
                          ],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage:
                                'Nav footer background color is required'
                        }
                    ],
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Nav Footer Background Color',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Select the color that you want to use for navigation bar and footer.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                },
                {
                    label: 'Nav Footer Text Color',
                    fieldType: FilterType.COLOR,
                    name: WhitelabelingColorPalette.NAV_FOOTER_TEXT,
                    placeholder: 'Nav Footer Text Color',
                    required: true,
                    value: this.configExist
                        ? response['themeConfig']
                            ? response['themeConfig']['--navFooterText']
                            : defaultTheme[
                                  WhitelabelingColorPalette.NAV_FOOTER_TEXT
                              ]
                        : defaultTheme[
                              WhitelabelingColorPalette.NAV_FOOTER_TEXT
                          ],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Nav footer text color is required'
                        }
                    ],
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Nav Footer Text Color',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Select the color of the text displayed on navigation bar and footer.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                }
            ],
            state: this.configExist ? FormState.IDLE : FormState.CREATE
        };
        if (this.configExist) {
            this.setupMailContent(response);
        }
    }

    setupMailContent(response) {
        // New Users Email Content
        this.emailContentForms.push({
            formGroup: null,
            sectionHeading: 'Welcome mail for new users',
            infoFunc: () => {
                this.widgetRef.modalService.openInfoModal({
                    infoHeading: 'New User',
                    content: [
                        {
                            type: 'UNORDERED_LIST',
                            data: [
                                `Create a template of email that is sent to the user while creation.`,
                                `This will be the prefilled email content. You can edit this anytime in the editor.`,
                                `We use macros for the dynamic fields used in email. Available macros are:<br />
                                - {{user}} <br />
                                - {{email}} <br />
                                - {{password}} <br />
                                - {{domainName}} <br />
                                - {{companyName}}
                                `
                            ]
                        }
                    ]
                });
            },
            formGeneratorInput: {
                formName: 'New users email',
                submitButton: null,
                fields: [
                    {
                        label: 'Subject',
                        name: 'userSubject',
                        fieldType: FilterType.TEXT,
                        placeholder: 'Subject',
                        required: true,
                        value: this.configExist
                            ? response['emailConfig']['userSubject']
                            : DefaultValues.EMAIL_DATA.NEW_USER_EMAIL.SUBJECT,
                        validations: [
                            {
                                errorMessage: 'Subject is required',
                                validator: CustomValidators.required
                            }
                        ]
                    },
                    {
                        label: 'Content',
                        name: 'userContent',
                        fieldType: FilterType.WYSIWYG_EDITOR,
                        placeholder: 'Content',
                        required: true,
                        value: this.configExist
                            ? response['emailConfig']['userContent']
                            : DefaultValues.EMAIL_DATA.NEW_USER_EMAIL.CONTENT,
                        validations: [
                            {
                                errorMessage: 'Content is required',
                                validator: CustomValidators.required
                            }
                        ]
                    }
                ],
                state: FormState.IDLE
            },
            resetFunc: null
        });

        // New Customers Email Content
        this.emailContentForms.push({
            formGroup: null,
            sectionHeading: 'Welcome mail for new customers',
            infoFunc: () => {
                this.widgetRef.modalService.openInfoModal({
                    infoHeading: 'New Customer',
                    content: [
                        {
                            type: 'UNORDERED_LIST',
                            data: [
                                `Create a template of email that is sent to the customer while creation.`,
                                `This will be the prefilled email content. You can edit this anytime in the editor.`,
                                `We use macros for the dynamic fields used in email. Available macros are:<br />
                                - {{user}} <br />
                                - {{email}} <br />
                                - {{password}} <br />
                                - {{domainName}} <br />
                                - {{companyName}}
                                `
                            ]
                        }
                    ]
                });
            },
            formGeneratorInput: {
                formName: 'New customers email',
                submitButton: null,
                fields: [
                    {
                        label: 'Subject',
                        name: 'customerSubject',
                        fieldType: FilterType.TEXT,
                        placeholder: 'Subject',
                        required: true,
                        value: this.configExist
                            ? response['emailConfig']['customerSubject']
                            : DefaultValues.EMAIL_DATA.NEW_CUSTOMER_EMAIL
                                  .SUBJECT,
                        validations: [
                            {
                                errorMessage: 'Subject is required',
                                validator: CustomValidators.required
                            }
                        ]
                    },
                    {
                        label: 'Content',
                        name: 'customerContent',
                        fieldType: FilterType.WYSIWYG_EDITOR,
                        placeholder: 'Content',
                        required: true,
                        value: this.configExist
                            ? response['emailConfig']['customerContent']
                            : DefaultValues.EMAIL_DATA.NEW_CUSTOMER_EMAIL
                                  .CONTENT,
                        validations: [
                            {
                                errorMessage: 'Content is required',
                                validator: CustomValidators.required
                            }
                        ]
                    }
                ],
                state: FormState.IDLE
            },
            resetFunc: null
        });

        // Reports Email Content
        this.emailContentForms.push({
            formGroup: null,
            sectionHeading: 'Mail received for reports',
            infoFunc: () => {
                this.widgetRef.modalService.openInfoModal({
                    infoHeading: 'Report',
                    content: [
                        {
                            type: 'UNORDERED_LIST',
                            data: [
                                `Create a template of email that is sent to the contacts while scheduling a report.`,
                                `This will be the prefilled email content. You can edit this anytime in the editor.`,
                                `We use macros for the dynamic fields used in email. Available macros are:<br />
                                - {{reportName}} <br />
                                - {{companyName}} <br />
                                - {{user}}
                                `
                            ]
                        }
                    ]
                });
            },
            formGeneratorInput: {
                formName: 'Reports email',
                submitButton: null,
                fields: [
                    {
                        label: 'Subject',
                        name: 'reportSubject',
                        fieldType: FilterType.TEXT,
                        placeholder: 'Subject',
                        value: this.configExist
                            ? response['emailConfig']['reportSubject']
                            : DefaultValues.EMAIL_DATA.NEW_REPORT_EMAIL.SUBJECT,
                        required: true,
                        validations: [
                            {
                                errorMessage: 'Subject is required',
                                validator: CustomValidators.required
                            }
                        ]
                    },
                    {
                        label: 'Content',
                        name: 'reportContent',
                        fieldType: FilterType.WYSIWYG_EDITOR,
                        placeholder: 'Content',
                        required: true,
                        value: this.configExist
                            ? response['emailConfig']['reportContent']
                            : DefaultValues.EMAIL_DATA.NEW_REPORT_EMAIL.CONTENT,
                        validations: [
                            {
                                errorMessage: 'Content is required',
                                validator: CustomValidators.required
                            }
                        ]
                    }
                ],
                state: FormState.IDLE
            },
            resetFunc: null
        });

        // Alerts Email Content
        this.emailContentForms.push({
            formGroup: null,
            sectionHeading: 'Mail received for alerts',
            infoFunc: () => {
                this.widgetRef.modalService.openInfoModal({
                    infoHeading: 'Alert',
                    content: [
                        {
                            type: 'UNORDERED_LIST',
                            data: [
                                `Create a template of email that is sent to the contacts while scheduling an alert.`,
                                `This will be the prefilled email content. You can edit this anytime in the editor.`,
                                `We use macros for the dynamic fields used in email. Available macros are:<br />
                                - {{table}} (not applicable for subject field.) <br />
                                - {{companyName}} <br />
                                - {{user}}
                                `
                            ]
                        }
                    ]
                });
            },
            formGeneratorInput: {
                formName: 'Alerts email',
                submitButton: null,
                fields: [
                    {
                        label: 'Subject',
                        name: 'alertSubject',
                        fieldType: FilterType.TEXT,
                        placeholder: 'Subject',
                        value: this.configExist
                            ? response['emailConfig']['alertSubject']
                            : DefaultValues.EMAIL_DATA.NEW_ALERT_EMAIL.SUBJECT,
                        required: true,
                        validations: [
                            {
                                errorMessage: 'Subject is required',
                                validator: CustomValidators.required
                            }
                        ]
                    },
                    {
                        label: 'Content',
                        name: 'alertContent',
                        fieldType: FilterType.WYSIWYG_EDITOR,
                        placeholder: 'Content',
                        value: this.configExist
                            ? response['emailConfig']['alertContent']
                            : DefaultValues.EMAIL_DATA.NEW_ALERT_EMAIL.CONTENT,
                        required: true,
                        validations: [
                            {
                                errorMessage: 'Content is required',
                                validator: CustomValidators.required
                            }
                        ]
                    }
                ],
                state: FormState.IDLE
            },
            resetFunc: null
        });

        // Budget Email Content
        this.emailContentForms.push({
            formGroup: null,
            sectionHeading: 'Mail received for budget',
            infoFunc: () => {
                this.widgetRef.modalService.openInfoModal({
                    infoHeading: 'Budget',
                    content: [
                        {
                            type: 'UNORDERED_LIST',
                            data: [
                                `Create a template of email that is sent to the contacts while scheduling a budget alert.`,
                                `This will be the prefilled email content. You can edit this anytime in the editor.`,
                                `We use macros for the dynamic fields used in email. Available macros are:<br />
                                - {{monitoringType}} (eg. SUM, PEAK, MIN, MAX)}}<br>
                                - {{thresholdValue}} (The value that must be exceeded against which the budget alert will be created.)<br>
                                - {{budgetName}}<br>
                                - {{totalBudget}}<br>
                                - {{companyName}}<br>
                                - {{user}}<br>
                                `
                            ]
                        }
                    ]
                });
            },
            formGeneratorInput: {
                formName: 'Budget email',
                submitButton: null,
                fields: [
                    {
                        label: 'Subject',
                        name: 'budgetSubject',
                        fieldType: FilterType.TEXT,
                        placeholder: 'Subject',
                        value: this.configExist
                            ? response['emailConfig']['budgetSubject']
                            : DefaultValues.EMAIL_DATA.NEW_BUDGET_EMAIL.SUBJECT,
                        required: true,
                        validations: [
                            {
                                errorMessage: 'Subject is required',
                                validator: CustomValidators.required
                            }
                        ]
                    },
                    {
                        label: 'Content',
                        name: 'budgetContent',
                        fieldType: FilterType.WYSIWYG_EDITOR,
                        placeholder: 'Content',
                        value: this.configExist
                            ? response['emailConfig']['budgetContent']
                            : DefaultValues.EMAIL_DATA.NEW_BUDGET_EMAIL.CONTENT,
                        required: true,
                        validations: [
                            {
                                errorMessage: 'Content is required',
                                validator: CustomValidators.required
                            }
                        ]
                    }
                ],
                state: FormState.IDLE
            },
            resetFunc: null
        });

        // Automation Email Content
        this.emailContentForms.push({
            formGroup: null,
            sectionHeading: 'Mail received for automation',
            infoFunc: () => {
                this.widgetRef.modalService.openInfoModal({
                    infoHeading: 'Automation',
                    content: [
                        {
                            type: 'UNORDERED_LIST',
                            data: [
                                `Create a template of email that is sent to the contacts before executing an automation rule.`,
                                `This will be the pre-filled email content. You can edit this anytime in the editor.`,
                                `We use macros for the dynamic fields used in email. Available macros are:<br />
                                - {{companyName}}<br>
                                `
                            ]
                        }
                    ]
                });
            },
            formGeneratorInput: {
                formName: 'Automation Email',
                submitButton: null,
                fields: [
                    {
                        label: 'Subject',
                        name: 'automationSubject',
                        fieldType: FilterType.TEXT,
                        placeholder: 'Subject',
                        value: this.configExist
                            ? response['emailConfig']['automationSubject']
                            : DefaultValues.EMAIL_DATA.NEW_AUTOMATION_EMAIL
                                  .SUBJECT,
                        required: true,
                        validations: [
                            {
                                errorMessage: 'Subject is required',
                                validator: CustomValidators.required
                            }
                        ]
                    },
                    {
                        label: 'Content',
                        name: 'automationContent',
                        fieldType: FilterType.WYSIWYG_EDITOR,
                        placeholder: 'Content',
                        value: this.configExist
                            ? response['emailConfig']['automationContent']
                            : DefaultValues.EMAIL_DATA.NEW_AUTOMATION_EMAIL
                                  .CONTENT,
                        required: true,
                        validations: [
                            {
                                errorMessage: 'Content is required',
                                validator: CustomValidators.required
                            }
                        ]
                    }
                ],
                state: FormState.IDLE
            },
            resetFunc: null
        });

        // Reset Password Email Content
        this.emailContentForms.push({
            formGroup: null,
            sectionHeading: 'Mail received to reset password',
            infoFunc: () => {
                this.widgetRef.modalService.openInfoModal({
                    infoHeading: 'Forget Password',
                    content: [
                        {
                            type: 'UNORDERED_LIST',
                            data: [
                                `Create a template of email that is sent to the user while 'forgot password?'.`,
                                `This will be the prefilled email content. You can edit this anytime in the editor.`,
                                `We use macros for the dynamic fields used in email. Available macros are:<br />
                                - {{date}} <br />
                                - {{time}} <br />
                                - {{companyName}} <br />
                                - {{user}}
                                `
                            ]
                        }
                    ]
                });
            },
            formGeneratorInput: {
                formName: 'Reset password email',
                submitButton: null,
                fields: [
                    {
                        label: 'Subject',
                        name: 'forgetPasswordSubject',
                        fieldType: FilterType.TEXT,
                        placeholder: 'Subject',
                        value: this.configExist
                            ? response['emailConfig']['forgetPasswordSubject']
                            : DefaultValues.EMAIL_DATA.RESET_PASSWORD_EMAIL
                                  .SUBJECT,
                        required: true,
                        validations: [
                            {
                                errorMessage: 'Subject is required',
                                validator: CustomValidators.required
                            }
                        ]
                    },
                    {
                        label: 'Content',
                        name: 'forgetPasswordContent',
                        fieldType: FilterType.WYSIWYG_EDITOR,
                        placeholder: 'Content',
                        required: true,
                        value: this.configExist
                            ? response['emailConfig']['forgetPasswordContent']
                            : DefaultValues.EMAIL_DATA.RESET_PASSWORD_EMAIL
                                  .CONTENT,
                        validations: [
                            {
                                errorMessage: 'Content is required',
                                validator: CustomValidators.required
                            }
                        ]
                    }
                ],
                state: FormState.IDLE
            },
            resetFunc: null
        });
    }

    toggleSection(event, toggleSiblings?) {
        event.stopPropagation();
        $(event.target).siblings('.section-content').toggle();
        if (toggleSiblings) {
            if (
                $(event.target).siblings('.section-content').css('display') !==
                'none'
            ) {
                $(event.target)
                    .parent('section')
                    .siblings('section')
                    .children('.section-content')
                    .hide();
            }
        }
    }

    saveWhitelabelingData() {
        if (
            this.domainConfigFormGroup.invalid ||
            this.orgProprietaryFormGroup.invalid ||
            this.socialChannelFormGroup.invalid ||
            this.colorThemeFormGroup.invalid
        ) {
            this.widgetRef.notificationsService.showSnackBar(
                Messages.FILL_ALL_FIELDS,
                true
            );
            return;
        }

        let invalid = false;
        this.emailContentForms.every((formGroup) => {
            if (formGroup.formGroup.invalid) {
                Helper.markAllFieldAsTouched(formGroup.formGroup);
                invalid = true;
                return false;
            }
            return true;
        });
        if (invalid) {
            this.widgetRef.notificationsService.showSnackBar(
                Messages.FILL_ALL_FIELDS,
                true
            );
            return;
        }

        this.saveButtonLoader = true;
        // Generting input
        const input = {
            ...this.domainConfigFormGroup.getRawValue()
        };
        input['orgProprietary'] = { ...this.orgProprietaryFormGroup.value };

        // Changing images to base 64
        const orgProps = Object.keys(input['orgProprietary']);
        const orgPropsFiles = orgProps.filter(
            (key) => input['orgProprietary'][key] instanceof FileInput
        );
        if (orgPropsFiles.length) {
            orgPropsFiles.forEach((key, index) => {
                if (input['orgProprietary'][key] instanceof FileInput) {
                    Helper.convertImageToBase64(
                        input['orgProprietary'][key]
                    ).then((response) => {
                        input['orgProprietary'][key] = response;
                        if (index === orgPropsFiles.length - 1) {
                            // All images converted
                            input['themeConfig'] = {
                                ...this.colorThemeFormGroup.value
                            };
                            input['socialMediaUrl'] = {
                                ...this.socialChannelFormGroup.value
                            };
                            input['emailConfig'] = {};
                            this.emailContentForms.forEach(
                                (emailContentForm) => {
                                    input['emailConfig'] = {
                                        ...input['emailConfig'],
                                        ...emailContentForm.formGroup.value
                                    };
                                }
                            );
                            const args = Helper.generateHitApiConfig(
                                this.edit
                                    ? this.widgetRef.widgetData.widgetInfo
                                          .update
                                    : this.widgetRef.widgetData.widgetInfo
                                          .create
                            );
                            args.input = input;
                            args.function = (response) => {
                                this.saveButtonLoader = false;
                                this.widgetRef.notificationsService.showSnackBar(
                                    `Domain configuration saved successfully`
                                );
                                this.widgetRef.refreshWidget();
                            };
                            args.errorFunction = (error) => {
                                Helper.showErrorMessage(
                                    this.widgetRef.notificationsService,
                                    error,
                                    'Something went wrong'
                                );
                                this.saveButtonLoader = false;
                                this.widgetRef.changeDetectorRef.detectChanges();
                            };
                            new HitApi(
                                args,
                                this.widgetRef.widgetHttpService,
                                this.widgetRef.ngZone
                            ).hitApi();
                        }
                    });
                }
            });
        } else {
            input['themeConfig'] = {
                ...this.colorThemeFormGroup.value
            };
            input['socialMediaUrl'] = {
                ...this.socialChannelFormGroup.value
            };
            input['emailConfig'] = {};
            this.emailContentForms.forEach((emailContentForm) => {
                input['emailConfig'] = {
                    ...input['emailConfig'],
                    ...emailContentForm.formGroup.value
                };
            });
            const args = Helper.generateHitApiConfig(
                this.edit
                    ? this.widgetRef.widgetData.widgetInfo.update
                    : this.widgetRef.widgetData.widgetInfo.create
            );
            args.input = input;
            args.function = (response) => {
                this.saveButtonLoader = false;
                this.widgetRef.notificationsService.showSnackBar(
                    `Domain configuration saved successfully`
                );
                this.widgetRef.refreshWidget();
            };
            args.errorFunction = (error) => {
                this.widgetRef.notificationsService.showSnackBar(
                    error.error.message,
                    true
                );
                this.saveButtonLoader = false;
                this.widgetRef.changeDetectorRef.detectChanges();
            };
            new HitApi(
                args,
                this.widgetRef.widgetHttpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }

    deleteWhitelabelingIntegration() {
        if (this.configExist) {
            this.widgetRef.modalService.openConfirmationModal({
                buttonText: 'Remove',
                buttonColorType: ButtonColorType.WARN,
                loaderOnExec: true,
                confirmationMessage:
                    'Are you sure you want to delete domain configuration ?',
                contextIcon: {
                    extraClass: 'color-accent',
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-exclamation-triangle'
                },
                modalIcon: this.widgetIcon,
                modalName: 'Remove domain configuration',
                function: (modalId: Symbol) => {
                    const args = Helper.generateHitApiConfig(
                        this.widgetRef.widgetData.widgetInfo.delete
                    );
                    args.input = {};
                    args.function = (response) => {
                        this.deleteButtonLoader = false;
                        this.widgetRef.notificationsService.showSnackBar(
                            `Domain configuration deleted successfully`
                        );
                        this.widgetRef.modalService.closeModal(null, modalId);
                        this.widgetRef.refreshWidget();
                    };
                    args.errorFunction = (error) => {
                        this.widgetRef.notificationsService.showSnackBar(
                            error.error.message,
                            true
                        );
                        this.widgetRef.modalService.closeModal(null, modalId);
                        this.deleteButtonLoader = false;
                    };
                    new HitApi(
                        args,
                        this.widgetRef.widgetHttpService,
                        this.widgetRef.ngZone
                    ).hitApi();
                }
            });
        }
    }

    editFormGroup(
        formGenInput: IFormGeneratorInput,
        formGroup: FormGroup,
        type: FORMS_AVAILABLE
    ) {
        if (formGenInput.submitButton) {
            formGenInput.state = FormState.EDIT;
            formGenInput.submitButton.buttonName = 'Update';
            formGenInput.submitButton.function = (
                buttonRef: IButtonData,
                formGroupRes: FormGroup
            ) => {
                formGroup = formGroupRes;
                formGenInput.state = FormState.IDLE;
                this.changeReference(formGenInput, type);
            };
            this.changeReference(formGenInput, type);
        }
    }

    stopEditingFormGroup(
        formGenInput: IFormGeneratorInput,
        formGroup: FormGroup,
        type: FORMS_AVAILABLE
    ) {
        formGenInput.state = FormState.IDLE;
        this.changeReference(formGenInput, type);
    }

    editEmailContent(index: number) {
        this.emailContentForms[index].formGeneratorInput.submitButton = {
            buttonName: 'Update',
            buttonType: 'PRIMARY',
            function: (buttonRef: IButtonData, formGroupRes: FormGroup) => {
                this.emailContentForms[index].formGroup = formGroupRes;
                this.emailContentForms[index].formGeneratorInput.state =
                    FormState.IDLE;
                this.emailContentForms[index].formGeneratorInput =
                    Helper.dereference(
                        this.emailContentForms[index].formGeneratorInput
                    );
            }
        };
        this.emailContentForms[index].formGeneratorInput.state = FormState.EDIT;
        this.emailContentForms[index].formGeneratorInput = Helper.dereference(
            this.emailContentForms[index].formGeneratorInput
        );
    }

    stopEditingEmailContent(index: number) {}

    changeReference(formGenInput: IFormGeneratorInput, type: FORMS_AVAILABLE) {
        if (type === FORMS_AVAILABLE.DOMAIN_CONFIG_FORM) {
            this.domainConfigForm = Helper.dereference(formGenInput);
        } else if (type === FORMS_AVAILABLE.ORG_PROPRIETARY_FORM) {
            this.orgProprietaryForm = Helper.dereference(formGenInput);
        } else if (type === FORMS_AVAILABLE.SOCIAL_CHANNEL_FORM) {
            this.socialChannelForm = Helper.dereference(formGenInput);
        } else if (type === FORMS_AVAILABLE.COLOR_THEME_FORM) {
            this.colorThemeForm = Helper.dereference(formGenInput);
        }
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
        document.querySelector('section .section-heading');
    }
}

enum FORMS_AVAILABLE {
    DOMAIN_CONFIG_FORM,
    ORG_PROPRIETARY_FORM,
    SOCIAL_CHANNEL_FORM,
    COLOR_THEME_FORM
}
