import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.sass']
})
export class ConfirmationModalComponent implements OnInit {
    formGroup: FormGroup;
    functionToExecute = null;
    cancelFunctionToExecute = null;
    modalParams = null;
    confirmationMessage;
    contextIcon;
    buttonText;
    loaderOnExec;
    reason: boolean = false;
    isLoading = false;
    reasonInput: string;
    buttonType: 'ACCENT' | 'PRIMARY' | 'WARN' | 'SUCCESS';
    buttonInputs: IButtonGeneratorInput[];
    buttonColorType: ButtonColorType;
    buttonClass: string;
    bodyTemplate: TemplateRef<any>;
    fontSize: number | string;
    cancelButtonType: string;
    cancelButtonAttribute: string;
    hideCancelButton: boolean;
    buttonPosition: 'center' | 'right';
    extraClass: string;
    hideButtons: boolean;
    documentStyles;
    noHeaderLine: boolean;
    customButtonGeneratorInput: {
        buttonGenInput: IButtonGeneratorInput[];
        options?: IMultiButtonOption;
    };
    constructor(
        public modalInputData: ModalInjectedData,
        public modalService: ModalService,
        private formBuilder: FormBuilder,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.documentStyles = getComputedStyle(this.document.documentElement);
    }

    ngOnInit(): void {
        this.buttonClass = this.modalInputData.data.params.buttonClass;
        this.reason = this.modalInputData['data']['params']['reason'];
        this.functionToExecute = this.modalInputData['data']['function'];
        this.cancelFunctionToExecute =
            this.modalInputData['data']['cancelFunction'];
        this.modalParams = this.modalInputData['data']['params'];
        this.confirmationMessage = this.modalParams['confirmationMessage'];
        this.bodyTemplate = this.modalParams['bodyTemplate']
            ? this.modalParams['bodyTemplate']
            : null;
        this.fontSize = this.modalParams['fontSize']
            ? this.modalParams['fontSize']
            : 0.875;
        this.fontSize = this.getFontSize(this.fontSize);
        this.hideCancelButton = this.modalParams['hideCancelButton']
            ? true
            : false;
        this.contextIcon = this.modalParams['contextIcon'];
        this.buttonText = this.modalParams['buttonText'];
        this.buttonColorType = this.modalParams['buttonColorType'];
        this.cancelButtonType = this.modalParams['cancelButtonType'];
        this.cancelButtonAttribute = this.modalParams['cancelButtonAttribute'];
        this.loaderOnExec = this.modalParams['loaderOnExec'];
        this.extraClass = this.modalParams['extraClass'];
        this.customButtonGeneratorInput =
            this.modalParams['customButtonGeneratorInput'];
        this.noHeaderLine = this.modalParams['noHeaderLine'];
        this.formGroup = new FormGroup({
            reason: new FormControl()
        });
        this.buttonInputs = [
            {
                buttonName: this.modalParams?.['cancelButtonText'] || 'Cancel',
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.PRIMARY,
                function: (buttonRef: IButtonGeneratorInput) => {
                    buttonRef.loader = this.loaderOnExec;
                    this.cancel();
                },
                hide: this.hideCancelButton,
                showLoader: this.loaderOnExec
            },
            {
                buttonName: this.buttonText,
                buttonType: ButtonType.RAISED,
                buttonColorType: this.buttonColorType
                    ? this.buttonColorType
                    : ButtonColorType.WARN,
                function: (buttonRef: IButtonGeneratorInput) => {
                    if (this.loaderOnExec) {
                        buttonRef.loader = true;
                    }
                    this.functionToExecute(
                        this.modalInputData.modalId,
                        this.formGroup,
                        buttonRef
                    );
                },

                showLoader: this.loaderOnExec
            }
        ];
        this.hideButtons = this.modalParams['hideButtons'];
        this.buttonPosition = this.modalParams['buttonPosition'];
    }

    cancel() {
        if (this.cancelFunctionToExecute) {
            this.cancelFunctionToExecute();
        }
        this.modalService.closeModal(null, this.modalInputData.modalId);
    }
    getFontSize(value) {
        if (typeof value === 'string') {
            this.fontSize = this.documentStyles.getPropertyValue(`--${value}`);

            return this.fontSize;
        } else {
            return value + 'rem';
        }
    }

    executeFunction() {
        if (this.reason && this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
        } else {
            this.isLoading = true;
            this.functionToExecute(this.modalInputData.modalId, this.formGroup);
        }
    }

    stopLoader() {
        this.isLoading = false;
    }
}
