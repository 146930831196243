import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IIntegrationApiResponse } from 'src/app/shared/interfaces/api/portlets/IIntegrationApiResponse';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IIntegrationInput } from 'src/app/shared/interfaces/integration/IIntegrationInput';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { FormGeneratorModalComponent } from '../../../modal-templates/form-generator-modal/form-generator-modal.component';
import { IframeOpenerModalComponent } from '../../../modal-templates/iframe-opener-modal/iframe-opener-modal.component';

@Component({
    selector: 'app-freshservice-integration',
    templateUrl: './freshservice-integration.component.html',
    styleUrls: ['./freshservice-integration.component.sass']
})
export class FreshserviceIntegrationComponent implements OnInit, AfterViewInit {
    integrationInput: IIntegrationInput = null;
    widgetRef: Widget;
    isParentIntegrated = false;
    isSelfIntegrated = false;
    isConfigured: Boolean = false;
    formGenInput: IFormGeneratorInput;

    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        this.integrationInput = {
            integrationName: this.widgetRef.widgetData.widgetInfo.text,
            integrationCategory: 'ITSM Integration',
            imageUrl: 'assets/integrations/freshservice_2.png',
            buttons: [],
            imagePadding: 4
        };
    }

    generateButtons(response: IIntegrationApiResponse) {
        this.formGenInput = {
            formName: 'Freshservice',
            state: this.isSelfIntegrated ? FormState.EDIT : FormState.CREATE,
            submitButton: {
                buttonName: this.isSelfIntegrated ? 'Update' : 'Integrate',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: (
                    buttonRef: IButtonData,
                    formGroup: FormGroup,
                    modalId: Symbol
                ) => {
                    if (formGroup.valid) {
                        buttonRef.loader = true;
                        if (this.isSelfIntegrated) {
                            // Update
                            const args = Helper.generateHitApiConfig(
                                this.widgetRef.widgetData.widgetInfo.update
                            );
                            args.input = {
                                ...formGroup.value,
                                integrationType: 'FRESHSERVICE'
                            };
                            args.function = (response) => {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'Freshservice configuration updated successfully'
                                );
                                buttonRef.loader = false;
                                this.widgetRef.refreshWidget();
                                this.widgetRef.modalService.closeModal(
                                    null,
                                    modalId
                                );
                            };
                            args.errorFunction = (error) => {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'Error updating freshservice configuration'
                                );
                                buttonRef.loader = false;
                            };
                            new HitApi(
                                args,
                                this.widgetRef.httpService,
                                this.widgetRef.ngZone
                            ).hitApi();
                        } else {
                            // Create
                            const args = Helper.generateHitApiConfig(
                                this.widgetRef.widgetData.widgetInfo.create
                            );
                            args.input = {
                                ...formGroup.value,
                                integrationType: 'FRESHSERVICE'
                            };
                            args.function = (response) => {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'Freshservice integrated successfully'
                                );
                                buttonRef.loader = false;
                                this.widgetRef.refreshWidget();
                                this.widgetRef.modalService.closeModal(
                                    null,
                                    modalId
                                );
                            };
                            args.errorFunction = (error) => {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'Error integrating freshservice'
                                );
                                buttonRef.loader = false;
                            };
                            new HitApi(
                                args,
                                this.widgetRef.httpService,
                                this.widgetRef.ngZone
                            ).hitApi();
                        }
                    }
                }
            },
            fields: [
                {
                    name: 'email',
                    placeholder: 'Email',
                    label: 'Email',
                    fieldType: FilterType.EMAIL,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email is required'
                        },
                        {
                            validator: CustomValidators.email,
                            errorMessage: 'Invalid email'
                        }
                    ],
                    value: this.isSelfIntegrated
                        ? response['self']['email']
                        : ''
                },
                {
                    name: 'supportedEmails',
                    placeholder: 'Support Email',
                    label: 'Support Email',
                    fieldType: FilterType.MATCHIPLIST,
                    showLabel: true,
                    appearance: 'legacy',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Support email is required'
                        },
                        {
                            validator: CustomValidators.matChipAllowEmail,
                            errorMessage: 'Invalid email'
                        }
                    ],
                    value: this.isSelfIntegrated
                        ? response['self']['supportedEmails']
                        : ''
                },
                {
                    name: 'apiKey',
                    placeholder: 'API Key',
                    label: 'API Key',
                    fieldType: FilterType.TEXT,
                    showLabel: true,
                    appearance: 'legacy',
                    required: true,
                    suffixIcon: {
                        hoverText: `Where to get api key`,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Your API Key',
                                content: [
                                    {
                                        type: 'ORDERED_LIST',
                                        data: [
                                            `Log in to your Support Portal.`,
                                            `Click on your profile picture on the top right corner of your portal.`,
                                            `Go to Profile settings Page.`,
                                            `Your API key will be available below the change password section to your right.`
                                        ]
                                    }
                                ]
                            });
                        }
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'API key is required'
                        }
                    ],
                    value: this.isSelfIntegrated
                        ? response['self']['apiKey']
                        : ''
                },
                {
                    name: 'domainName',
                    placeholder: 'Domain Name',
                    label: 'Domain Name',
                    fieldType: FilterType.TEXT,
                    showLabel: true,
                    appearance: 'legacy',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Domain name is required'
                        }
                    ],
                    value: this.isSelfIntegrated
                        ? response['self']['domainName']
                        : ''
                },
                {
                    name: 'widgetCode',
                    placeholder: 'Widget Code',
                    label: 'Widget Code',
                    fieldType: FilterType.TEXTAREA,
                    showLabel: true,
                    extraClass: 'tw-items-center',
                    appearance: 'legacy',
                    required: true,
                    suffixIcon: {
                        hoverText: `Where to get widget code`,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Your Widget Code',
                                content: [
                                    {
                                        type: 'ORDERED_LIST',
                                        data: [
                                            `Log into your Freshservice account as an administrator.`,
                                            `Go to Admin Settings and click on Support Channels (under General Settings) and click on Feedback Widget.`,
                                            `You will see two tabs: One for the popup widget and another one for the embeddable widget.`,
                                            `Copy the embedded one and paste it in the text field.`
                                        ]
                                    }
                                ]
                            });
                        }
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Widget code is required'
                        }
                    ],
                    value: this.isSelfIntegrated
                        ? response['self']['widgetCode']
                        : '',
                    fieldStyle: {
                        height: '40%'
                    },
                    textAreaStyles: {
                        'min-height': '80px'
                    }
                }
            ],
            extraClass: 'integration-new-styling add-scroll'
        };
        if (this.isSelfIntegrated || this.isParentIntegrated) {
            if (this.isSelfIntegrated) {
                // Adding play button
                this.integrationInput.buttons.push({
                    buttonName: 'Test',
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    hoverText: 'Test Freshservice',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-play'
                    },
                    function: () => {
                        const modalArgs: IModalData = {
                            modalName: 'Create Ticket',
                            sourceId:
                                this.widgetRef.widgetData
                                    .widgetUniqueIdentifier,
                            modalType: ModalType.MIDDLE,
                            modalIcon: {
                                type: IconType.FONTAWSOME,
                                class: 'fas fa-play'
                            },
                            modalSteps: [
                                {
                                    stepName: 'Create Ticket',
                                    stepData: {
                                        componentToLoad:
                                            IframeOpenerModalComponent,
                                        payload: {
                                            data: this.isSelfIntegrated
                                                ? response.self.widgetCode
                                                : this.isParentIntegrated
                                                ? response.parent.widgetCode
                                                : null
                                        }
                                    }
                                }
                            ],
                            modalWidthVw: 60,
                            modalHeightVh: 60,
                            noBottomPadding: true
                        };
                        this.widgetRef.modalService.openModal(modalArgs);
                    }
                });

                // Adding edit button
                this.integrationInput.buttons.push({
                    buttonName: 'Edit',
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    hoverText: 'Edit Freshservice',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-wrench'
                    },
                    function: () => {
                        const modalArgs: IModalData = {
                            modalName: 'Edit Freshservice',
                            sourceId:
                                this.widgetRef.widgetData
                                    .widgetUniqueIdentifier,
                            modalType: ModalType.MIDDLE,
                            modalIcon: {
                                type: IconType.FONTAWSOME,
                                class: 'fas fa-wrench'
                            },
                            modalSteps: [
                                {
                                    stepName: 'Edit Freshservice',
                                    stepData: {
                                        componentToLoad:
                                            FormGeneratorModalComponent,
                                        payload: {
                                            data: this.formGenInput
                                        }
                                    }
                                }
                            ],
                            modalWidthVw: 60,
                            modalHeightVh: 70,
                            noBottomPadding: true
                        };
                        this.widgetRef.modalService.openModal(modalArgs);
                    }
                });

                // Adding delete button
                this.integrationInput.buttons.push({
                    buttonName: 'Delete',
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.WARN,
                    hoverText: 'Remove Freshservice',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-trash'
                    },
                    function: (modalId: Symbol) => {
                        // Delete integration
                        const args = Helper.generateHitApiConfig(
                            this.widgetRef.widgetData.widgetInfo.delete
                        );
                        args.input = {};
                        args.function = (response) => {
                            this.widgetRef.notificationsService.showSnackBar(
                                `Freshservice integration removed successfully`
                            );
                            this.widgetRef.modalService.closeModal(
                                null,
                                modalId
                            );
                            this.widgetRef.refreshWidget();
                        };
                        args.errorFunction = (error) => {
                            this.widgetRef.notificationsService.showSnackBar(
                                error.error.message,
                                true
                            );
                            this.widgetRef.modalService.closeModal(
                                null,
                                modalId
                            );
                        };
                        new HitApi(
                            args,
                            this.widgetRef.httpService,
                            this.widgetRef.ngZone
                        ).hitApi();
                    },
                    executeFunctionAfterConfirmation: {
                        modalName: 'Remove Freshservice Integration',
                        modalIcon: {
                            type: IconType.FONTAWSOME,
                            class: 'far fa-trash-alt'
                        },
                        contextIcon: {
                            extraClass: 'color-accent',
                            type: IconType.FONTAWSOME,
                            class: 'fas fa-exclamation-triangle'
                        },
                        confirmationMessage: `Are you sure you want to remove freshservice ?`,
                        buttonText: 'Remove',
                        buttonColorType: ButtonColorType.WARN,
                        loaderOnExec: true
                    }
                });
            }

            if (this.isParentIntegrated && !this.isSelfIntegrated) {
                // Adding create button
                this.integrationInput.buttons.push({
                    buttonName: 'Integrate',
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    hoverText: 'Integrate Freshservice',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-plus'
                    },
                    function: () => {
                        const modalArgs: IModalData = {
                            modalName: 'Integrate Freshservice',
                            sourceId:
                                this.widgetRef.widgetData
                                    .widgetUniqueIdentifier,
                            modalType: ModalType.MIDDLE,
                            modalIcon: {
                                type: IconType.FONTAWSOME,
                                class: 'fas fa-ticket-alt'
                            },
                            extraStepContainerClass: 'remove-form-space',
                            modalSteps: [
                                {
                                    stepName: 'Integrate Freshservice',
                                    stepData: {
                                        componentToLoad:
                                            FormGeneratorModalComponent,
                                        payload: {
                                            data: this.formGenInput
                                        }
                                    }
                                }
                            ],
                            modalWidthVw: 60,
                            modalHeightVh: 70
                        };
                        this.widgetRef.modalService.openModal(modalArgs);
                    }
                });
            }
        }
    }
    onIntegrate(formGenInput) {
        const modalArgs: IModalData = {
            modalName: 'Integrate Freshservice',
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            extraStepContainerClass: 'remove-form-space remove-bottom-padding',
            modalSteps: [
                {
                    stepName: 'Integrate Freshservice',
                    stepData: {
                        componentToLoad: FormGeneratorModalComponent,
                        payload: {
                            data: formGenInput
                        }
                    }
                }
            ],
            modalWidthVw: 60,
            modalHeightPx: 510,
            noBottomPadding: true
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }

    bindData(response: IIntegrationApiResponse): void {
        this.isConfigured = false;
        if ('integration' in response && !response.integration) {
            this.isParentIntegrated = false;
            this.isSelfIntegrated = false;
        }
        if (response.parent && response.parent.integration) {
            this.isParentIntegrated = true;
            this.isSelfIntegrated = false;
        }
        if (response.self && response.self.integration) {
            this.isSelfIntegrated = true;
            this.isConfigured = true;
        }
        this.integrationInput.buttons = [];

        this.generateButtons(response);
        this.widgetRef.endLoader();
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
