import { Pipe, PipeTransform } from '@angular/core';
import { IIcon } from '../../interfaces/icon-data/IIcon';

@Pipe({
    name: 'iconDataGenerator'
})
export class IconDataGeneratorPipe implements PipeTransform {
    transform(iconType, iconClass, iconText?, iconExtraClass?): IIcon {
        return {
            type: iconType,
            class: iconClass,
            text: iconText,
            extraClass: iconExtraClass
        };
    }
}
