import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFilterData } from 'src/app/shared/interfaces/filter/IFilterData';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { MulticurrencyFilterSelectorService } from 'src/app/shared/services/multicurrency-filter-selector/multicurrency-filter-selector.service';

@Component({
    selector: 'app-multi-currency-filter',
    templateUrl: './multi-currency-filter.component.html',
    styleUrls: ['./multi-currency-filter.component.sass'],
})
export class MultiCurrencyFilterComponent implements OnInit {
    @Input() filterInfo: IFilterInfo;
    @Input() filterData: IFilterData;
    @Input() disabled;
    @Input() disableMessage;
    @Input() filterStoreKey;
    @Input() enableTemp: boolean;
    @Input() selectedValue;

    @Input() dummy: boolean;
    @Input() index: number;
    @Input() removeButtonGenInput: IButtonGeneratorInput;
    refreshListing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false,
    );
    exchangeRate;
    customExchangeRate = false;
    defaultExchangeRate;
    resetFilters$ = null;
    infoIcon: IIcon = {
        type: IconType.MATICON,
        class: 'info',
    };
    listCurrencyFilterInfo: IFilterInfo;
    constructor(
        private filtersService: FiltersService,
        private multiCurrencyFilterService: MulticurrencyFilterSelectorService,
    ) { }

    ngOnInit(): void {
        this.listCurrencyFilterInfo = Helper.cloneDeep(this.filterInfo);
        this.listCurrencyFilterInfo.selector = 'listCurrencies';
        if (this.selectedValue) {
            this.defaultExchangeRate = this.selectedValue.exchangeRate;
            this.exchangeRate = this.selectedValue.exchangeRate;
        }
        this.resetFilters$ = this.filtersService.resetFilters.subscribe((res) => {
            if (!res) {
                this.customExchangeRate = false;
            }
        });
    }

    refreshFilter() {
        this.refreshListing.next(true);
    }

    /**
     * Handles the change of a single dropdown value.
     * @param $event - The selected value.
     */
    singleDropdownValueChange($event) {
        if (!$event) {
            this.selectedValue = $event = this.listCurrencyFilterInfo?.selectedValue;
        }
        let storedValues = [];
        this.customExchangeRate = false;
        this.selectedValue = $event;
        this.defaultExchangeRate = this.selectedValue?.exchangeRate;
        this.exchangeRate = this.selectedValue?.exchangeRate;

        const value = {
            ...$event,
            defaultCurrencyValue: this.customExchangeRate,
        };

        // Check and set initial filter values if not already set
        if (
            this.filtersService?.filtersDataTemp?.get(this.filterStoreKey) &&
            this.filtersService?.filtersDataTemp
                ?.get(this.filterStoreKey)
                .get(this.multiCurrencyFilterService.multiCurrencyFilterId) &&
            this.multiCurrencyFilterService?.getFilterValues(
                this.multiCurrencyFilterService.multiCurrencyFilterId,
            ).length === 0
        ) {
            this.multiCurrencyFilterService.setFilterValues(
                this.multiCurrencyFilterService.multiCurrencyFilterId,
                [value],
            );
        }

        // Retrieve stored values from the multiCurrencyFilterService
        storedValues =
            this.multiCurrencyFilterService?.getFilterValues(
                this.multiCurrencyFilterService.multiCurrencyFilterId,
            ) || [];
        storedValues[this.index] = value;

        // Set the updated values back to the multiCurrencyFilterService
        this.multiCurrencyFilterService.setFilterValues(
            this.multiCurrencyFilterService.multiCurrencyFilterId,
            storedValues,
        );

        const currencyFiltersValue =
            this.multiCurrencyFilterService.getFilterValues(
                this.multiCurrencyFilterService.multiCurrencyFilterId,
            );
        this.multiCurrencyFilterService.checkDuplicateFilter();

        // Update filtersDataTemp or filtersData based on the enableTemp flag
        const filtersDataTemp = this.filtersService.filtersDataTemp;
        const filtersData = this.filtersService.filtersData;

        if (this.enableTemp) {
            filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.multiCurrencyFilterService.multiCurrencyFilterId, {
                    value: currencyFiltersValue,
                    filterInfo: this.listCurrencyFilterInfo,
                });
        } else {
            filtersData.get(this.filterStoreKey).set(this.multiCurrencyFilterService.multiCurrencyFilterId, {
                value,
                filterInfo: this.listCurrencyFilterInfo,
            });
        }

        // Add the filterData.filterId to filterChanged if not already present
        if (
            !this.filtersService.filterChanged.includes(this.multiCurrencyFilterService.multiCurrencyFilterId)
        ) {
            this.filtersService.filterChanged.push(this.multiCurrencyFilterService.multiCurrencyFilterId);
        }
    }

    /**
     * Handles changes in input properties.
     * @param changes - Object containing changes to input properties.
     */
    ngOnChanges(change) {
        if (change.selectedValue) {
            this.selectedValue = change.selectedValue.currentValue
                ? change.selectedValue.currentValue
                : change.filterInfo.currentValue.selectedValue;
            this.exchangeRate = this.selectedValue.exchangeRate;
        }
        if (change.filterInfo) {
            this.listCurrencyFilterInfo = change.filterInfo.currentValue;
        }
    }

    /**
     * Handles the change of the default exchange rate.
     * @param $event - The event containing the new value of the checkbox.
     */
    exchangeRateChanged($event) {
        if (!$event || $event < 0) {
            return;
        }
        this.exchangeRate = $event;
        const value = this.multiCurrencyFilterService.getFilterValues(
            this.multiCurrencyFilterService.multiCurrencyFilterId,
        )[this.index];
        value.exchangeRate = this.exchangeRate;
        value.defaultCurrencyValue = this.customExchangeRate;

        const storedValues = this.multiCurrencyFilterService.getFilterValues(
            this.multiCurrencyFilterService.multiCurrencyFilterId,
        );
        storedValues[this.index] = value; // Assuming (this.index)  + 1 is unique for each instance
        this.multiCurrencyFilterService.setFilterValues(
            this.multiCurrencyFilterService.multiCurrencyFilterId,
            storedValues,
        );
        if (this.enableTemp) {
            const value = this.multiCurrencyFilterService.getFilterValues(
                this.multiCurrencyFilterService.multiCurrencyFilterId,
            );
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.multiCurrencyFilterService.multiCurrencyFilterId, {
                    value,
                    filterInfo: this.listCurrencyFilterInfo,
                });
        } else {
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.multiCurrencyFilterService.multiCurrencyFilterId, {
                    value,
                    filterInfo: this.listCurrencyFilterInfo,
                });
        }
        if (
            !this.filtersService.filterChanged.includes(this.multiCurrencyFilterService.multiCurrencyFilterId)
        ) {
            this.filtersService.filterChanged.push(this.multiCurrencyFilterService.multiCurrencyFilterId);
        }
    }

    defaultExchangeRateChanged($event) {
        if (!$event.checked && this.exchangeRate) {
            this.exchangeRate = this.defaultExchangeRate;
        }
    }

    ngOnDestroy() {
        if (this.resetFilters$) {
            this.resetFilters$.unsubscribe();
        }
    }
}
