<div class="form-container">
    <app-form-generator
        *ngIf="levelOneFormGenInput"
        [formGeneratorInput]="levelOneFormGenInput"
        (formGroupRef)="levelOneFormGroup = $event"
        (valuesChange)="levelOneValueChanged($event)"
    ></app-form-generator>
    <app-form-generator
        class="second-field"
        *ngIf="levelTwoFormGenInput"
        [formGeneratorInput]="levelTwoFormGenInput"
        (formGroupRef)="levelTwoFormGroup = $event"
        (valuesChange)="levelTwoValueChanged($event)"
    ></app-form-generator>
    <app-form-generator
        class="third-field"
        *ngIf="levelThreeFormGenInput"
        [formGeneratorInput]="levelThreeFormGenInput"
        (formGroupRef)="levelThreeFormGroup = $event"
        (valuesChange)="levelThreeValueChanged($event)"
    ></app-form-generator>
</div>
