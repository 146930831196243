<section class="container">
    <app-form-generator
        *ngIf="!!formGeneratorInput && isShowForm1"
        [formGeneratorInput]="formGeneratorInput"
        (formGroupRef)="formGroup = $event"
    ></app-form-generator>

    <div
        *ngIf="isShowListInputMethodSelection"
        class="api-input-type-selection-container"
    >
        <mat-radio-group
            aria-label="Select an option"
            [(ngModel)]="ApiInputSelectionValue"
        >
            <mat-radio-button
                name="{{ ApiRadioButtonOptions.FETCH_WITH_APIS }}"
                value="{{ ApiRadioButtonOptions.FETCH_WITH_APIS }}"
            >
                {{ ApiRadioButtonOptions.FETCH_WITH_APIS }}
            </mat-radio-button>
            <mat-radio-button
                name="{{ ApiRadioButtonOptions.INPUT_MANUALLY }}"
                value="{{ ApiRadioButtonOptions.INPUT_MANUALLY }}"
            >
                {{ ApiRadioButtonOptions.INPUT_MANUALLY }}
            </mat-radio-button>
        </mat-radio-group>
        <app-button-generator
            [buttonGenInput]="NextButton"
        ></app-button-generator>
    </div>

    <div *ngIf="isShowApiInputSelection" class="input-api-link-container">
        <app-form-generator
            [formGeneratorInput]="formGeneratorInputApiLink"
        ></app-form-generator>
    </div>

    <div *ngIf="isShowListItemsSelection" class="input-list-container">
        <div class="list-container">
            <ng-container
                *ngFor="let item of formData.listData; index as fieldIndex"
            >
                <mat-form-field appearance="fill">
                    <input matInput [(ngModel)]="item.id" />

                    <button
                        matSuffix
                        *ngIf="formData.listData?.length > 1"
                        mat-icon-button
                        (click)="handleRemoveItem(fieldIndex)"
                    >
                        <span
                            class="action-icon"
                            appIcon
                            [data]="deleteIcon"
                        ></span>
                    </button>
                </mat-form-field>
            </ng-container>
        </div>
        <app-button-generator
            [buttonGenInput]="handleAddNewItem"
        ></app-button-generator>
        <app-button-generator [buttonGenInput]="DoneButton">
        </app-button-generator>
    </div>
</section>
