<div class="tw-flex tw-flex-col tw-relative tw-overflow-hidden">
    <div
        class="tw-h-14 tw-pl-3 tw-flex tw-items-center tw-text-fs-400 modal-heading"
    >
        <i class="tw-mr-2 fas fa-info"></i>General Information
        <div class="tw-absolute tw-right-2 tw-cursor-pointer close">
            <i
                (click)="modalService.closeModal(null, modalData.modalId)"
                class="tw-mr-2 fas fa-times"
            ></i>
        </div>
    </div>
    <div class="tw-flex tw-flex-col">
        <table class="tw-m-1 tw-p-1">
            <ng-container
                *ngFor="let k of generalInformation | keyvalue: returnZero"
            >
                <ng-container *ngIf="!isArray(k.value)">
                    <tr>
                        <th class="tw-font-bold tw-text-fs-200 rules">
                            {{ k.key }}
                        </th>
                        <td
                            class="tw-p-2 tw-font-semibold tw-text-fs-200 tagValue"
                        >
                            {{ k.value }}
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
        </table>
    </div>

    <div
        class="tw-h-14 tw-pl-3 tw-flex tw-items-center tw-text-fs-400 modal-heading"
    >
        <i class="tw-mr-2 fas fa-info"></i>Tagging Info
    </div>
    <div class="tw-flex tw-flex-col tw-overflow-x-auto">
        <table class="tw-m-1 tw-p-1">
            <tr>
                <th class="tw-font-bold tw-text-fs-200 rules">Key</th>
                <th
                    class="tw-p-2 tw-font-semibold tw-text-fs-200 tagValue"
                    *ngFor="let key of tags"
                >
                    {{ key.key }}
                </th>
            </tr>
            <tr>
                <th class="tw-font-bold tw-text-fs-200 rules">Value</th>
                <th
                    class="tw-p-2 tw-font-semibold tw-text-fs-200 tagValue"
                    *ngFor="let key of tags"
                >
                    {{ key.value }}
                </th>
            </tr>
        </table>
    </div>

    <div
        class="tw-h-14 tw-pl-3 tw-flex tw-items-center tw-text-fs-400 modal-heading"
    >
        <i class="tw-mr-2 fas fa-info"></i>Usage Pattern Overview
    </div>
    <div class="tw-flex tw-flex-col tw-overflow-x-auto">
        <table class="tw-m-1 tw-p-1">
            <tr>
                <th></th>
                <th
                    colspan="2"
                    class="tw-text-fs-200 dayTitle"
                    *ngFor="let day of weekdays"
                >
                    {{ day }}
                </th>
            </tr>
            <tr>
                <th></th>
                <ng-container *ngFor="let day of weekdays">
                    <td
                        class="tw-break-words tw-font-semibold tw-text-fs-100 tw-pl-1 time"
                        colspan="1"
                    >
                        Instance Stop Time (in hours)
                    </td>
                    <td
                        class="tw-break-words tw-font-semibold tw-text-fs-100 tw-pl-1 time"
                        colspan="1"
                    >
                        Instance Start Time (in hours)
                    </td>
                </ng-container>
            </tr>
            <tr *ngFor="let rule of rules">
                <th class="tw-font-bold tw-text-fs-200 rules">{{ rule }}</th>
                <ng-container *ngFor="let day of weekdays">
                    <td
                        class="tw-break-words tw-font-semibold tw-text-fs-200 tw-pl-1 time"
                        colspan="1"
                    >
                        {{
                            tableData[day][rule]
                                ? tableData[day][rule][
                                      "Instance Stop Time (in hours)"
                                  ]
                                : "-"
                        }}
                    </td>
                    <td
                        class="tw-break-words tw-font-semibold tw-text-fs-200 tw-pl-1 time"
                        colspan="1"
                    >
                        {{
                            tableData[day][rule]
                                ? tableData[day][rule][
                                      "Instance Start Time (in hours)"
                                  ]
                                : "-"
                        }}
                    </td>
                </ng-container>
            </tr>
        </table>
    </div>

    <div
        class="tw-h-14 tw-pl-3 tw-flex tw-items-center tw-text-fs-400 modal-heading"
    >
        <i class="tw-mr-2 fas fa-info"></i>Instance Usage Visibility
    </div>
    <div class="tw-flex tw-flex-col">
        <app-graph-generator
            *ngIf="stackBarGraph"
            [graphData]="stackBarGraph"
        ></app-graph-generator>
        <div
            *ngIf="widgetRef.lightState"
            class="lightOrFull clickable"
            (click)="getFullAccess()"
        >
            <p>Get Full Access!</p>
            <p class="description">Graph Details in full version</p>
        </div>
        <div class="tw-flex tw-justify-center">
            <div
                class="tw-m-2 tw-items-center tw-font-semibold tw-text-fs-200 tw-flex"
                *ngFor="let legend of legendData"
            >
                <span
                    class="tw-rounded-full tw-h-7 tw-w-7 tw-mr-1"
                    [style.background]="legend.fill"
                ></span>
                <span>{{ legend.name }}</span>
            </div>
        </div>
    </div>
</div>
