import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatMenuTrigger } from '@angular/material/menu';
import moment from 'moment';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { SingleDropdownFieldComponent } from 'src/app/shared/components/form-fields/single-dropdown-field/single-dropdown-field.component';
import { GoogleMSPAuditSection0RequestWidgetIds } from 'src/app/shared/enums/AssessmentRequestWidgetForm';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { IconType } from 'src/app/shared/enums/IconType';
import { CustomRequestWidgetFormValueKeys } from 'src/app/shared/interfaces/assessment/ICustomeRequestWidgetTypes';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IDropdownData } from 'src/app/shared/interfaces/dropdown-data/IDropdownData';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { AuditEvidenceSaveConfirmationService } from 'src/app/shared/services/assessment/audit-evidence-save-confirmation.service';
import { WidgetCacheService } from 'src/app/shared/services/cache/widget-cache/widget-cache.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';

@Component({
    selector: 'app-assessment-google-msp-forms',
    templateUrl: './assessment-google-msp-forms.component.html',
    styleUrls: ['./assessment-google-msp-forms.component.sass'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: SingleDropdownFieldComponent,
            multi: true,
        },
    ],
})
export class AssessmentGoogleMspFormsComponent implements OnInit {
    public isShowLoader = false;
    public readonly spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER,
    };
    public readonly FilterType = FilterType;
    public readonly CustomRequestWidgetFormValueKeys =
        CustomRequestWidgetFormValueKeys;
    @Input()
    private isEdit: string;
    @Input()
    private modalId: symbol;
    @Input()
    private widgetRef: Widget;
    @Input()
    private formPreSavedDataInEdit: any;
    @ViewChild(MatMenuTrigger)
    private bulkActionTrigger: MatMenuTrigger;
    @ViewChild('inputRef')
    private inputFielfRef: ElementRef<HTMLInputElement>;

    private get formInfoByWidgetInfo() {
        return this?.widgetRef?.widgetData?.widgetInfo?.form;
    }

    /**
     * For Form type 2
     */
    public tabularFormToIterate = [];

    /**
     * form 0 is initial loading
     * form 1 is for collecting customer names
     * form 2 are other remaining tabular forms
     */
    public formToShow: 0 | 1 | 2 = 0;
    public formOneFieldContent: string[] = [''];
    public formOneEditFieldIndex: number | null = 0;
    private formOneFieldValueAccumulator: string | null = null;

    public plusIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-plus',
    };
    public submitButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Submit',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        function: (buttonRef: IButtonData) => {
            if (this.formToShow === 1) {
                this.submitForFormOne(buttonRef);
            } else {
                this.submitForFormTwoType(buttonRef);
            }
        },
        showLoader: true,
    };
    public bulkActionButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Bulk Action',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        function: () => {
            this.bulkActionTrigger?.openMenu();
        },
    };

    public get isBulkActionButtonEnabled() {
        if (!this.tabularFormToIterate?.length) {
            return false;
        }
        for (let i = 0; i < this.tabularFormToIterate.length; i++) {
            const rowItem = this.tabularFormToIterate[i];
            if (rowItem?.fields?.length) {
                for (let j = 0; j < rowItem?.fields?.length; j++) {
                    const field = rowItem?.fields[j];
                    if (field['isSelectedForBulkAction']) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    public buttonGenInputsAddNewField: IButtonGeneratorInput = {
        buttonName: 'Add Customer',
        buttonColorType: ButtonColorType.INFO,
        buttonType: ButtonType.STROKED,
        function: (buttonRef: IButtonData) => {
            const formValue =
                this.formOneFieldContent[this.formOneEditFieldIndex];
            if (this.formOneEditFieldIndex === 0 && !formValue?.trim()) {
                return;
            }
            this.formOneFieldContent.unshift('');
            this.formOneEditFieldIndex = 0;
            setTimeout(() => {
                this.inputFielfRef?.nativeElement?.focus();
            }, 0);
        },
        showLoader: true,
        customClass: 'plus-button',
        buttonIcon: this.plusIcon,
    };

    constructor(
        private auditEvidenceSaveConfirmationService: AuditEvidenceSaveConfirmationService,
        private modalService: ModalService,
        private widgetCacheService: WidgetCacheService
    ) {}

    ngOnInit(): void {
        if (
            this.widgetRef.widgetData.widgetId ===
                GoogleMSPAuditSection0RequestWidgetIds.GoogleMspSection0 ||
            this.widgetRef.widgetData.widgetId ===
                GoogleMSPAuditSection0RequestWidgetIds.GoogleMsspSection0
        ) {
            if (this.isEdit) {
                this.formOneFieldContent =
                    this.formPreSavedDataInEdit?.[0]?.[
                        'Form Details'
                    ]?.[0]?.selection;
                this.formOneEditFieldIndex = null;
            }
            this.formToShow = 1;
        } else {
            this.formToShow = 2;

            const url = window.location.pathname?.split('/')?.[1];
            const key = Helper.convertUrlToKey(url);
            this.widgetCacheService.fetch(
                `${key}assessment_overview`,
                (value) => {
                    if (value?.response) {
                        const frameworkId =
                            value?.response?.['assesseOverview']?.[
                                'overview'
                            ]?.['sections']?.[0]?.['attributes']?.[0]?.[
                                'framework'
                            ]?.['id'] ||
                            value?.response?.['assessorOverview']?.[
                                'overview'
                            ]?.['sections']?.[0]?.['attributes']?.[0]?.[
                                'framework'
                            ]?.['id'];

                        const getCustomerNameApiArgs =
                            Helper.generateHitApiConfig(
                                this.widgetRef.widgetData.widgetInfo?.[
                                    'additionalApisForWidget'
                                ]?.['googleMspCustomerListing']
                            );
                        getCustomerNameApiArgs.intactUrl =
                            getCustomerNameApiArgs.url;
                        getCustomerNameApiArgs.url =
                            getCustomerNameApiArgs.url.replace(
                                '{frameworkId}',
                                frameworkId
                            );
                        getCustomerNameApiArgs.input = {};
                        getCustomerNameApiArgs.function = (response) => {
                            this.isShowLoader = false;
                            const RowfieldsInfo =
                                this.widgetRef.widgetData.widgetInfo.form
                                    .fields;

                            const customerList =
                                response?.[0]?.['Form Details']?.[0].selection;

                            const preSavedDataForEdit =
                                this.formPreSavedDataInEdit?.[0]?.[
                                    'Form Details'
                                ];

                            this.tabularFormToIterate = customerList.map(
                                (customerName) => {
                                    let presavedRowData;
                                    if (this.isEdit) {
                                        presavedRowData =
                                            preSavedDataForEdit?.find(
                                                (el) =>
                                                    el?.rowFields?.[0]
                                                        ?.inputString ===
                                                    customerName
                                            );
                                    }
                                    let clonedRowfieldsInfo =
                                        Helper.cloneDeep(RowfieldsInfo);
                                    clonedRowfieldsInfo =
                                        clonedRowfieldsInfo?.map(
                                            (field, index) => {
                                                if (index === 0) {
                                                    field[
                                                        CustomRequestWidgetFormValueKeys.INPUTSTRING
                                                    ] = customerName;
                                                }
                                                if (index !== 0) {
                                                    if (
                                                        field.fieldType ===
                                                        FilterType.DROPDOWN_SINGLE
                                                    ) {
                                                        field[
                                                            'isSelectedForBulkAction'
                                                        ] = false;
                                                    }

                                                    if (
                                                        this.isEdit &&
                                                        presavedRowData
                                                            ?.rowFields?.length
                                                    ) {
                                                        if (
                                                            field.fieldType ===
                                                            FilterType.DATE_TIME
                                                        ) {
                                                            const newMoment =
                                                                moment(
                                                                    Number(
                                                                        presavedRowData
                                                                            ?.rowFields?.[
                                                                            index
                                                                        ]?.[
                                                                            CustomRequestWidgetFormValueKeys
                                                                                .DATE
                                                                        ]
                                                                    )
                                                                );
                                                            field[
                                                                CustomRequestWidgetFormValueKeys.DATE
                                                            ] =
                                                                newMoment.toDate();
                                                        } else {
                                                            field[
                                                                CustomRequestWidgetFormValueKeys.INPUTSTRING
                                                            ] =
                                                                presavedRowData?.rowFields?.[
                                                                    index
                                                                ]?.[
                                                                    CustomRequestWidgetFormValueKeys.INPUTSTRING
                                                                ];
                                                        }
                                                    }
                                                }

                                                return field;
                                            }
                                        );

                                    return {
                                        id:
                                            presavedRowData?.id ||
                                            Helper.generateUniqueKey(36),
                                        value: customerName,
                                        fields: clonedRowfieldsInfo,
                                    };
                                }
                            );
                        };
                        getCustomerNameApiArgs.errorFunction = (error) => {
                            this.isShowLoader = false;
                        };
                        this.isShowLoader = true;
                        new HitApi(
                            getCustomerNameApiArgs,
                            this.widgetRef.httpService,
                            this.widgetRef.ngZone
                        ).hitApi();
                    }
                }
            );
        }
    }

    public getBulkActionOptions() {
        if (this.formToShow !== 2) {
            return null;
        }
        const fields = this.tabularFormToIterate?.[0]?.fields;
        for (let i = 0; i < fields?.length; i++) {
            const field = fields[i];
            if (field.fieldType === FilterType.DROPDOWN_SINGLE) {
                return field.listData;
            }
        }
        return null;
    }

    public getBulkCheckedStatus(fieldIndex: number): boolean {
        if (!this.tabularFormToIterate?.length) {
            return false;
        }
        for (let i = 0; i < this.tabularFormToIterate.length; i++) {
            const rowItem = this.tabularFormToIterate[i];
            if (!rowItem?.fields?.[fieldIndex]?.isSelectedForBulkAction) {
                return false;
            }
        }
        return true;
    }

    public setBulkCheckedStatus(
        changeEvent: MatCheckboxChange,
        fieldIndex: number
    ) {
        for (let i = 0; i < this.tabularFormToIterate.length; i++) {
            const rowItem = this.tabularFormToIterate[i];
            rowItem.fields[fieldIndex].isSelectedForBulkAction =
                changeEvent.checked;
        }
    }

    public handleOnBulkAction(optionSelected: IDropdownData) {
        let tabularFormToIterateClone = Helper.cloneDeep(
            this.tabularFormToIterate
        );

        tabularFormToIterateClone = tabularFormToIterateClone?.map((row) => {
            row.fields = row.fields?.map((field) => {
                if (
                    field?.fieldType === FilterType.DROPDOWN_SINGLE &&
                    field?.isSelectedForBulkAction
                ) {
                    field[CustomRequestWidgetFormValueKeys.INPUTSTRING] =
                        optionSelected.id;
                    field.isSelectedForBulkAction = false;
                }
                return field;
            });

            return row;
        });

        this.tabularFormToIterate = tabularFormToIterateClone;
    }

    public handleFormOneEditIcon(index: number) {
        this.formOneFieldValueAccumulator =
            this.formOneFieldContent[index]?.trim() || null;

        this.formOneEditFieldIndex = index;
        setTimeout(() => {
            this.inputFielfRef?.nativeElement?.focus();
        }, 0);
    }

    public handleFormOneDeleteIcon(index: number) {
        this.formOneFieldContent.splice(index, 1);
    }

    public customTrackBy(index: number, _name: string): any {
        return index;
    }

    public handleOnBlurFormOneInput() {
        if (
            !this.formOneFieldContent[this.formOneEditFieldIndex]?.trim() &&
            this.formOneFieldValueAccumulator
        ) {
            this.formOneFieldContent[this.formOneEditFieldIndex] =
                this.formOneFieldValueAccumulator;
        }

        if (
            this.formOneEditFieldIndex === 0 &&
            this.formOneFieldContent.length > 1 &&
            !this.formOneFieldContent[0]?.trim()
        ) {
            this.formOneFieldContent.shift();
        }
        if (
            this.formOneFieldContent.length > 1 ||
            this.formOneFieldContent[0]?.trim()
        ) {
            this.formOneEditFieldIndex = null;
        }

        this.formOneFieldValueAccumulator = null;
    }

    private submitForFormOne(buttonRef: IButtonData) {
        const customerNamesToSubmit = [];
        this.formOneFieldContent.forEach((name) => {
            if (name?.trim()) {
                customerNamesToSubmit.push(name?.trim());
            }
        });

        if (customerNamesToSubmit?.length === 0) {
            this.widgetRef.notificationsService.showSnackBar(
                'Please add atleast one customer name to continue',
                true
            );
            return;
        }

        const apiArgs = Helper.generateHitApiConfig(
            this.isEdit
                ? this.widgetRef.widgetData.widgetInfo.update
                : this.widgetRef.widgetData.widgetInfo.action
        );

        const fieldId = this.formInfoByWidgetInfo?.fields?.[0]?.id;

        apiArgs.input = [{ id: fieldId, selection: customerNamesToSubmit }];
        if (this.isEdit) {
            apiArgs.url = apiArgs.url.replace(
                '{request-id}',
                this.formPreSavedDataInEdit?.[0]?.['Form ID']
            );
            apiArgs.url = apiArgs.url.replace(
                '%7Brequest-id%7D',
                this.formPreSavedDataInEdit?.[0]?.['Form ID']
            );
        }
        apiArgs.function = () => {
            this.widgetRef.notificationsService.showSnackBar(
                Messages.REQUEST_FORM_SUBMITTED
            );
            buttonRef.disable = false;
            buttonRef.loader = false;
            // to enable nav button to next control point
            this.auditEvidenceSaveConfirmationService.isInvalidateSectionDataObservable.next(
                true
            );
            this.modalService.closeModal(null, this.modalId);
            this.widgetRef.changeDetectorRef.detectChanges();
            this.widgetRef.refreshWidget();
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.disable = false;
            buttonRef.loader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while submitting request form'
            );
            this.widgetRef.refreshWidget();
        };

        buttonRef.loader = true;
        buttonRef.disable = true;

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    private submitForFormTwoType(buttonRef: IButtonData) {
        let isContentMissingInForm = false;

        this.tabularFormToIterate.forEach((row) => {
            row?.fields?.forEach((field, index) => {
                if (index === 0) {
                    return;
                }
                if (field.fieldType === FilterType.DATE_TIME) {
                    if (!field[CustomRequestWidgetFormValueKeys.DATE]) {
                        isContentMissingInForm = true;
                    }
                } else {
                    if (!field[CustomRequestWidgetFormValueKeys.INPUTSTRING]) {
                        isContentMissingInForm = true;
                    }
                }
            });
        });

        if (isContentMissingInForm) {
            this.widgetRef.notificationsService.showSnackBar(
                'Please fill all the required fields to continue',
                true
            );
            return;
        }

        const apiArgs = Helper.generateHitApiConfig(
            this.isEdit
                ? this.widgetRef.widgetData.widgetInfo.update
                : this.widgetRef.widgetData.widgetInfo.action
        );

        const payload = this.tabularFormToIterate?.map((row) => {
            const rowFields = row.fields.map((field) => {
                const fieldContent = {
                    id: field.id,
                };

                if (field.fieldType === FilterType.DATE_TIME) {
                    const dateObject =
                        field[CustomRequestWidgetFormValueKeys.DATE];

                    fieldContent[CustomRequestWidgetFormValueKeys.DATE] =
                        moment(dateObject).valueOf();
                } else {
                    fieldContent[CustomRequestWidgetFormValueKeys.INPUTSTRING] =
                        field[CustomRequestWidgetFormValueKeys.INPUTSTRING];
                }

                return fieldContent;
            });

            return {
                id: row?.id,
                rowFields,
            };
        });

        if (this.isEdit) {
            apiArgs.url = apiArgs.url.replace(
                '{request-id}',
                this.formPreSavedDataInEdit?.[0]?.['Form ID']
            );
            apiArgs.url = apiArgs.url.replace(
                '%7Brequest-id%7D',
                this.formPreSavedDataInEdit?.[0]?.['Form ID']
            );
        }

        apiArgs.input = payload;
        apiArgs.function = () => {
            this.modalService.closeModal(null, this.modalId);
            this.widgetRef.notificationsService.showSnackBar(
                Messages.REQUEST_FORM_SUBMITTED
            );
            buttonRef.disable = false;
            buttonRef.loader = false;
            this.widgetRef.refreshWidget();
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.disable = false;
            buttonRef.loader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while submitting request form'
            );
            this.widgetRef.refreshWidget();
        };

        buttonRef.loader = true;
        buttonRef.disable = true;

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
