import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ChangeDetectorRef
} from '@angular/core';
import { MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { Query, DataManager } from '@syncfusion/ej2-data';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';

@Component({
    selector: 'app-multi-group-dropdown',
    templateUrl: './multi-group-dropdown.component.html',
    styleUrls: ['./multi-group-dropdown.component.sass']
})
export class MultiGroupDropdownComponent implements OnInit {
    @Input('data') data;
    @Input('name') name;
    @Input('width') width;
    @Input('value') value;
    @Input('checkFields') checkFields;
    @Input('checkWaterMark') checkWaterMark;
    @Input('enableGroupCheckBox') enableGroupCheckBox;
    @Input('mode') mode;
    @Input('showNotification') showNotification;
    @Input('filterBarPlaceholder') filterBarPlaceholder;
    @Input('showSelectAll') showSelectAll;
    @Input('limit') limit;
    @Input('showDropdownIcon') showDropdownIcon;
    @Input('enabled') enabled;
    @Output('change') change = new EventEmitter();
    @Input('emptyValues') emptyValues;
    @ViewChild('checkbox', { static: false })
    mulObj: MultiSelectComponent;
    query: Query = new Query().take(25);
    height = '300px';

    constructor(
        private notificationsService: NotificationsService,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        if (!this.limit) {
            this.limit = Infinity;
        }
        if (this.emptyValues) {
            this.emptyValues.subscribe((element) => {
                if (element instanceof Object) {
                    if (element['name'] !== this.name) {
                        this.mulObj.value = null;
                    }
                } else {
                    this.mulObj.value = null;
                }
            });
        }
        if (this.showNotification) {
            if (this.data.length > 500) {
                const message =
                    'Data is too large; May take longer than usual to populate data in dropdowns';
                this.notificationsService.showSnackBar(message);
            }
        }
    }

    dropdownSelectedValueChamged($event) {
        if (this.name) {
            const obj = {};
            obj[this.name] = $event.value;
            this.change.emit(obj);
        } else {
            this.change.emit($event.value);
        }
    }

    onfiltering($event) {
        let query: Query = new Query();
        // frame the query based on search string with filter type.
        query =
            $event.text !== ''
                ? query.where(
                      this.checkFields['groupBy'],
                      'contains',
                      $event.text,
                      true
                  )
                : query;
        // pass the filter data source, filter query to updateData method.
        $event.updateData(this.data, query);
    }

    // onOpen(args) {
    //   let start = 7;
    //   let end = 17;
    //   const listElement: HTMLElement = (this.mulObj as any).list;
    //   const query: Query = new Query();
    //   listElement.addEventListener('scroll', () => {
    //     if (
    //       listElement.scrollTop + listElement.offsetHeight >=
    //       listElement.scrollHeight
    //     ) {
    //       const filterQuery = query.clone();
    //       new DataManager(this.data)
    //         .executeQuery(filterQuery.range(start, end))
    //         .then((event: any) => {
    //           start = end;
    //           end += 10;
    //           this.mulObj.addItem(event.result as { [key: string]: Object }[]);
    //         })
    //         .catch((e: Object) => {});
    //     }
    //   });
    // }
}
