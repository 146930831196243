<div #rightsizingModal class="tw-relative tw-h-full tw-w-full container">
    <div
        class="tw-cursor-pointer close-button"
        (click)="modalService.closeModal(null, modalData.modalId)"
        appIcon
        [data]="crossIcon"
    ></div>

    <app-vertical-scrolling-tab-group>
        <ng-template appVerticalScrollingTab [tabLabel]="tabs[0]">
            <!-- Expenses Section -->
            <ng-container
                *ngIf="data[costType.ADDITIONAL_COST] !== '-'; else savings"
            >
                <div class="tw-flex tw-justify-center tw-mt-6 tw-mb-3 expenses">
                    <div class="tw-rounded tw-p-3 additional-expense">
                        <span> Additional Expenses: &nbsp;&nbsp;</span>
                        <span class="tw-font-bold">
                            {{ currency + recommendationExpense.startCost }} -
                            {{ currency + recommendationExpense.endCost }}
                        </span>
                    </div>
                </div>
            </ng-container>
            <ng-template #savings>
                <div class="tw-flex tw-justify-center tw-mt-6 tw-mb-3 expenses">
                    <div class="tw-rounded tw-p-3 savings">
                        <span>Saving to be made: &nbsp;&nbsp;</span>
                        <span class="tw-font-bold">
                            {{ currency + recommendationExpense.startCost }} -
                            {{ currency + recommendationExpense.endCost }}
                        </span>
                    </div>
                </div>
            </ng-template>
            <!-- Expenses Section Ends -->

            <div class="tw-flex tw-justify-evenly">
                <app-single-select-dropdown
                    *ngIf="processorDropdownData"
                    class="rightsize-dropdown processor"
                    [filterInfo]="processorDropdownData"
                    [dropdownLabel]="'Processor'"
                    [selectedValue]="'both'"
                    (change)="
                        recommendationsfilterChanged(
                            processorDropdownData.selector,
                            $event
                        )
                    "
                ></app-single-select-dropdown>
                <app-grouping-dropdown
                    *ngIf="vmSeriesDropdownData"
                    class="rightsize-dropdown virtual-machine"
                    [filterInfo]="vmSeriesDropdownData"
                    [dropdownLabel]="'Virtual Machine Series'"
                    (change)="
                        recommendationsfilterChanged(
                            vmSeriesDropdownData.selector,
                            $event
                        )
                    "
                ></app-grouping-dropdown>
            </div>
            <div
                appResizeObserver
                (resize)="resize.emit($event)"
                class="table-gen"
            >
                <app-only-table-generator
                    *ngIf="recommendationsTableGen && recommendationsTableData"
                    [hideControlBar]="true"
                    [tableInput]="recommendationsTableGen"
                    [tableData]="recommendationsTableData"
                    [defaultColDef]="colDef"
                    (gridRef)="recommendationsGrid = $event"
                >
                </app-only-table-generator>
            </div>
            <div class="tw-text-right tw-px-4 table-state">
                <span
                    (click)="
                        recommendationsTableExpanded =
                            !recommendationsTableExpanded;
                        recommendationsGrid.api.onFilterChanged()
                    "
                    class="tw-cursor-pointer"
                >
                    {{
                        recommendationsTableExpanded
                            ? "Collapse Table "
                            : "Expand Table"
                    }}
                    <span
                        appIcon
                        [data]="
                            recommendationsTableExpanded
                                ? arrowUpIcon
                                : arrowDownIcon
                        "
                    ></span>
                </span>
            </div>
            <div
                class="tw-flex tw-justify-center tw-items-center tw-h-14 tw-font-bold tw-text-fs-800"
            >
                Amortized Price
            </div>
            <div class="table-gen amortized-table">
                <app-only-table-generator
                    *ngIf="amortizedTableGen && amortizedTableData"
                    [hideControlBar]="true"
                    [tableInput]="amortizedTableGen"
                    [tableData]="amortizedTableData"
                    [defaultColDef]="colDef"
                    (gridRef)="amortizedGrid = $event"
                >
                </app-only-table-generator>
            </div>
        </ng-template>

        <ng-template appVerticalScrollingTab [tabLabel]="tabs[1]">
            <mat-accordion displayMode="flat" multi>
                <mat-expansion-panel
                    class="tw-py-2"
                    #consumptionInfoPanel
                    hideToggle="true"
                    [expanded]="true"
                >
                    <mat-expansion-panel-header class="expansion-height">
                        <mat-panel-title>
                            {{ tabs[1] }}
                        </mat-panel-title>
                        <mat-panel-description>
                            <span
                                *ngIf="!consumptionInfoPanel.expanded"
                                appIcon
                                [data]="arrowDownIcon"
                            ></span>
                            <span
                                *ngIf="consumptionInfoPanel.expanded"
                                appIcon
                                [data]="arrowUpIcon"
                            ></span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="tw-px-10">
                        <div class="tw-flex tw-justify-around">
                            <ng-container
                                *ngFor="
                                    let data of graphDropdownData;
                                    let i = index
                                "
                            >
                                <app-single-select-dropdown
                                    class="rightsize-dropdown rightsize-graph-dropdown"
                                    [filterInfo]="data"
                                    [dropdownLabel]="data.selector"
                                    [selectedValue]="data.listData[0].id"
                                    (change)="
                                        filterChanged(data.selector, $event)
                                    "
                                    [class.middle-dropdown]="i === 1"
                                ></app-single-select-dropdown>
                            </ng-container>
                        </div>
                        <div class="overlay" *ngIf="loader">
                            <div class="overlay-content">
                                <div
                                    appIcon
                                    [data]="spinnerLoader"
                                    class="overlay-loader"
                                ></div>
                            </div>
                        </div>
                        <div class="overlay" *ngIf="errorOccured">
                            <div
                                class="tw-flex tw-justify-center tw-items-center tw-text-fs-600 overlay-content"
                            >
                                No data available
                            </div>
                        </div>
                        <ng-container
                            *ngIf="!loader && !errorOccured && lineChart"
                        >
                            <app-graph-generator
                                class="tw-w-full"
                                [graphData]="lineChart"
                            ></app-graph-generator>

                            <!-- Custom legends -->
                            <ng-container
                                *ngIf="
                                    selectedGraphType === 'utilization' &&
                                    legendData
                                "
                            >
                                <div class="tw-flex tw-justify-evenly">
                                    <div
                                        class="tw-flex tw-flex-col tw-w-10 tw-flex-1"
                                    >
                                        <div
                                            class="tw-text-center tw-font-semibold"
                                        >
                                            Current Resources
                                        </div>
                                        <div
                                            class="tw-flex tw-flex-wrap tw-justify-center tw-whitespace-nowrap legend-container"
                                        >
                                            <ng-container
                                                *ngFor="
                                                    let data of legendData.current;
                                                    let i = index
                                                "
                                            >
                                                <div
                                                    class="tw-flex tw-flex-1 tw-items-center tw-justify-start tw-cursor-pointer"
                                                    (click)="
                                                        toggleCustomLegend(
                                                            'current',
                                                            i
                                                        )
                                                    "
                                                >
                                                    <div
                                                        [style.background]="
                                                            data.legendColor
                                                        "
                                                        class="tw-h-3 tw-w-3 tw-rounded-full"
                                                    ></div>
                                                    <div class="tw-px-1">
                                                        Current
                                                        {{
                                                            graphLegends[
                                                                data.label
                                                            ]
                                                        }}
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div
                                        class="tw-flex tw-flex-col tw-w-10 tw-flex-1"
                                    >
                                        <div
                                            class="tw-text-center tw-font-semibold"
                                        >
                                            Recommended Resources
                                        </div>
                                        <div
                                            class="tw-whitespace-nowrap tw-justify-center tw-flex tw-flex-wrap legend-container"
                                        >
                                            <ng-container
                                                *ngFor="
                                                    let data of legendData.recommended;
                                                    let i = index
                                                "
                                            >
                                                <div
                                                    class="tw-flex tw-flex-1 tw-items-center tw-justify-start tw-cursor-pointer"
                                                    (click)="
                                                        toggleCustomLegend(
                                                            'recommended',
                                                            i
                                                        )
                                                    "
                                                >
                                                    <div
                                                        [style.background]="
                                                            data.legendColor
                                                        "
                                                        class="tw-h-3 tw-w-3 tw-rounded-full"
                                                    ></div>
                                                    <div class="tw-px-1">
                                                        Recommended
                                                        {{
                                                            graphLegends[
                                                                data.label
                                                            ]
                                                        }}
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-template>

        <ng-template appVerticalScrollingTab [tabLabel]="tabs[2]">
            <mat-accordion displayMode="flat" multi>
                <mat-expansion-panel
                    #consumptionInfoPanel
                    hideToggle="true"
                    [expanded]="true"
                    class="tw-py-2"
                >
                    <mat-expansion-panel-header class="expansion-height">
                        <mat-panel-title>
                            {{ tabs[2] }}
                        </mat-panel-title>
                        <mat-panel-description>
                            <span
                                *ngIf="!consumptionInfoPanel.expanded"
                                appIcon
                                [data]="arrowDownIcon"
                            ></span>
                            <span
                                *ngIf="consumptionInfoPanel.expanded"
                                appIcon
                                [data]="arrowUpIcon"
                            ></span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="table-gen">
                        <app-only-table-generator
                            *ngIf="descriptionTableGen && descriptionTableData"
                            [hideControlBar]="true"
                            [tableInput]="descriptionTableGen"
                            [tableData]="descriptionTableData"
                        >
                        </app-only-table-generator>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-template>
    </app-vertical-scrolling-tab-group>
</div>
