<form class="form">
    <div class="form-data">
        <app-form-generator
            *ngIf="accountIdsFormGenInputs | async"
            [formGeneratorInput]="accountIdsFormGenInputs | async"
            (formGroupRef)="accountIdsFormGroup = $event"
            (valuesChange)="depFields($event)"
        ></app-form-generator>
        <ng-container *ngIf="accountIds && regions">
            <ng-container *ngTemplateOutlet="first"> </ng-container>
        </ng-container>
        <ng-template #first>
            <app-form-generator
                class="tw-py-3"
                *ngIf="imageFormGenInputs"
                [formGeneratorInput]="imageFormGenInputs"
                (formGroupRef)="imageFormGroup = $event"
                (updateControl)="updateControl2 = $event"
                (valuesChange)="imagePublisherChanged($event)"
            ></app-form-generator>
            <app-form-generator
                *ngIf="
                    imageOfferFormGenInputs &&
                    imageFormGroup.get('imagePublisher').value
                "
                [formGeneratorInput]="imageOfferFormGenInputs"
                (formGroupRef)="imageOfferFormGroup = $event"
                (updateControl)="updateImageControl = $event"
                (valuesChange)="imageOfferChanged($event)"
            ></app-form-generator>
            <app-form-generator
                *ngIf="
                    skuFormGenInputs &&
                    imageFormGroup.get('imagePublisher').value &&
                    imageOfferFormGroup.get('imageOffer').value
                "
                [formGeneratorInput]="skuFormGenInputs"
                (formGroupRef)="skuFormGroup = $event"
                (updateControl)="updateSkuControl = $event"
            ></app-form-generator>
            <app-form-generator
                class="tw-py-3"
                *ngIf="networkInterfaceFormGenInputs"
                [formGeneratorInput]="networkInterfaceFormGenInputs"
                (formGroupRef)="networkInterfaceFormGroup = $event"
                (updateControl)="updateControl = $event"
                (valuesChange)="subnet($event)"
            ></app-form-generator>
            <app-form-generator
                *ngIf="
                    subnetFormGenInputs &&
                    (networkInterfaceFormGroup.get('virtualNetwork').value ||
                        networkInterfaceFormGroup.get('virtualNetworkType')
                            .value === 'createNew')
                "
                [formGeneratorInput]="subnetFormGenInputs"
                (formGroupRef)="subnetFormGroup = $event"
                (updateControl)="updateSubnetControl = $event"
            ></app-form-generator>
        </ng-template>

        <app-form-generator
            class="tw-py-3"
            *ngIf="authTypeFormGenInputs"
            [formGeneratorInput]="authTypeFormGenInputs"
            (formGroupRef)="authTypeFormGroup = $event"
        ></app-form-generator>
        <section
            class="tw-py-1 tw-px-3 tw-rounded tw-mt-0 tw-mx-3 tw-mb-4 keyVal-fg"
        >
            <div class="tw-font-semibold tw-mb-3 heading">Tags</div>
            <div class="tw-max-h-96 tw-overflow-y-auto tw-py-3 tw-px-0">
                <ng-container
                    *ngFor="
                        let keyValuePair of keyValuePairFormGenInputs;
                        let i = index;
                        let last = last
                    "
                >
                    <div class="tw-flex tw-items-start">
                        <app-form-generator
                            class="tw-flex tw-flex-col tw-flex-1"
                            [formGeneratorInput]="keyValuePair"
                            [intitalFormGroup]="
                                keyValuePairFormGroup.has(keyValuePair.formId)
                                    ? keyValuePairFormGroup.get(
                                          keyValuePair.formId
                                      )
                                    : null
                            "
                            (formGroupRef)="
                                keyValuePairFormGroup.set(
                                    keyValuePair.formId,
                                    $event
                                )
                            "
                        ></app-form-generator>
                        <mat-icon
                            class="tw-ml-3 tw-mt-3 tw-cursor-pointer tw-text-fs-600 tw-flex tw-justify-center tw-items-center tw-rounded-full success"
                            *ngIf="last"
                            (click)="
                                lastKeyValueFormUpdate(i, keyValuePair.formId)
                            "
                        >
                            add
                        </mat-icon>
                        <mat-icon
                            class="tw-ml-3 tw-mt-3 tw-cursor-pointer tw-text-fs-600 tw-flex tw-justify-center tw-items-center tw-rounded-full error"
                            *ngIf="!last"
                            (click)="
                                deleteKeyValuePairFormGroup(
                                    i,
                                    keyValuePair.formId
                                )
                            "
                        >
                            delete
                        </mat-icon>
                    </div>
                </ng-container>
            </div>
        </section>
    </div>
    <div class="form-controls">
        <app-button-generator
            *ngIf="resetButton"
            [buttonGenInput]="resetButton"
        ></app-button-generator>
        <app-button-generator
            *ngIf="submitButton"
            [buttonGenInput]="submitButton"
        ></app-button-generator>
    </div>
</form>
