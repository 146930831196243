import { Pipe, PipeTransform } from '@angular/core';
import { WhiteLabelingService } from '../services/white-labeling/white-labeling.service';

@Pipe({
    name: 'aggregateCardColor'
})
export class AggregateCardColorPipe implements PipeTransform {
    constructor(private whiteLabelingService: WhiteLabelingService) {}

    transform(value: Number): String {
        if (this.whiteLabelingService.whiteLableData.isCentilytics) {
            switch (value) {
                case 0:
                    return 'first-card';
                case 1:
                    return 'second-card';
                case 2:
                    return 'third-card';
                case 3:
                    return 'forth-card';
                default:
                    '';
                    break;
            }
        } else {
            return '';
        }
    }
}
