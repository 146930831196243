<div class="more-info-container" *ngIf="data && !isHtml">
    <div class="description" *ngIf="data?.insightDetails">
        <div
            class="modal-content content"
            *ngFor="let insightDetail of data['insightDetails']"
        >
            <p class="heading">{{ insightDetail["heading"] }}</p>
            <ng-container *ngIf="insightDetail['description'].length > 1">
                <ul>
                    <li *ngFor="let item of insightDetail['description']">
                        {{ item }}
                    </li>
                </ul>
            </ng-container>

            <ng-container *ngIf="insightDetail['description'].length === 1">
                <p
                    class="insight-details"
                    *ngFor="let item of insightDetail['description']"
                >
                    {{ item }}
                </p>
            </ng-container>
        </div>
    </div>

    <div class="description">
        <div class="insight-content content">
            <ng-container *ngIf="data?.severityColumns">
                <p class="heading">Insight Description</p>

                <table *ngIf="objectKeys(data['severityColumns'])">
                    <div class="heading-grey-bold margin">
                        There can be
                        {{ objectKeys(data["severityColumns"]).length }}
                        possible scenario(s):
                    </div>
                    <tr>
                        <th>Severity</th>
                        <th>Description</th>
                    </tr>

                    <tr *ngFor="let key of objectKeys(data['severityColumns'])">
                        <td class="heading-grey-bold">
                            <span *ngIf="key === 'OK'">
                                <img
                                    class="severity-img"
                                    src="assets/green_check.png"
                                />
                            </span>

                            <span *ngIf="key === 'Warning'">
                                <img
                                    class="severity-img"
                                    src="assets/yellow_check.png"
                                />
                            </span>

                            <span *ngIf="key === 'Critical'">
                                <img
                                    class="severity-img"
                                    src="assets/red_check.png"
                                />
                            </span>
                            {{ key }}
                        </td>
                        <td>{{ data["severityColumns"][key] }}</td>
                    </tr>
                </table>
            </ng-container>

            <ng-container *ngIf="data?.tableDescription">
                <div class="heading-grey-bold margin">
                    Description of further columns are as follows:
                </div>

                <div class="column-image">
                    <img src="{{ imageUrl }}" />
                </div>
                <table>
                    <tr>
                        <th>Column Name</th>
                        <th>Description</th>
                    </tr>

                    <tr *ngFor="let key of data['tableDescription']">
                        <td
                            class="link heading-grey-bold"
                            [class.selected]="imageUrl === key['url']"
                            (click)="imageUrl = key.url"
                        >
                            {{ key["columnName"] }}
                        </td>
                        <td>{{ key["description"] }}</td>
                    </tr>
                </table>

                <p
                    class="heading-grey-bold margin"
                    *ngIf="data?.subsequentColumns"
                >
                    {{ data["subsequentColumns"] }}
                </p>
            </ng-container>
            <ng-container *ngIf="filtersDescInsightLevel">
                <p class="heading">Filters Applicable</p>
                <table>
                    <tr>
                        <th>Filter Name</th>
                        <th>Description</th>
                    </tr>

                    <tr *ngFor="let key of objectKeys(filtersDescInsightLevel)">
                        <td class="heading-grey-bold">
                            {{ [key] }}
                        </td>

                        <td>
                            {{ filtersDescInsightLevel[key] }}
                        </td>
                    </tr>
                </table>
            </ng-container>

            <ng-container *ngIf="data?.compliance">
                <p class="heading">Compliances Covered</p>

                <table>
                    <tr>
                        <th style="flex: 0.6">Compliance Name</th>
                        <th>Reference Number</th>
                        <th>Link</th>
                    </tr>
                    <tr *ngFor="let complianceData of data['compliance']">
                        <td style="flex: 0.6">
                            {{ complianceData?.complianceName }}
                        </td>
                        <td>{{ complianceData?.referenceId }}</td>
                        <td>
                            <a
                                class="table-link"
                                target="_blank"
                                href="{{ complianceData?.link }}"
                                >{{ complianceData?.link }}</a
                            >
                        </td>
                    </tr>
                </table>
            </ng-container>

            <ng-container *ngIf="data?.readMore">
                <p class="heading">Read More</p>
                <span class="show-data-as-columns">
                    <a
                        class="link-href"
                        target="_blank"
                        *ngFor="let link of data['readMore']; let i = index"
                        href="{{ link }}"
                        >[{{ i + 1 }}] {{ link }}</a
                    >
                </span>
            </ng-container>

            <ng-container *ngIf="data?.videoLink">
                <p class="heading">
                    Still facing some problems? Here's something for you
                </p>
                <a
                    class="link-href"
                    target="_blank"
                    href="{{ data['videoLink'] }}"
                    >{{ data["videoLink"] }}</a
                >
            </ng-container>
        </div>
    </div>

    <div
        class="more-info-html-container"
        *ngIf="data && isHtml"
        [innerHTML]="data | safeRender: SanitizeTypes.HTML"
    ></div>
</div>
