import { FilterType } from '../enums/FilterType';
import { AuditEmailConfigRuleItemType } from './email-configuration/emailConfigurationTypes';

export enum CustomSignUpActionEnum {
    CREATE_CUSTOMER = 'CREATE_CUSTOMER',
    ISSUE_ASSESSMENT = 'ISSUE_ASSESSMENT',
    SEND_EMAIL = 'SEND_EMAIL',
}

export enum CustomSignUpStatusEnum {
    InActive = 'InActive',
    Active = 'Active',
}

export type CustomSignUpFieldType =
    | FilterType.TEXT
    | FilterType.NUMBER
    | 'ALPHABETIC'
    | 'ALPHA_NUMERIC'
    | FilterType.EMAIL
    | FilterType.PASSWORD
    | FilterType.DATE
    | FilterType.TIME
    | FilterType.DROPDOWN_SINGLE
    | FilterType.DROPDOWN_MULTIPLE;

export interface CustomSignUpFormStepOneType {
    name: string;
    url: string;
    signUpAction: CustomSignUpActionEnum;
    defaultViewId?: string;
    auditId?: string;
}

export interface CustomSignUpFormStepTwoType {
    isActive: boolean;
    imageS3Url?: string;
    formSections: ISignupFormStepResponseType[];
    file?: File;
}

export interface CustomSignUpFormRequestPayloadType
    extends CustomSignUpFormStepOneType,
        CustomSignUpFormStepTwoType {
    emailRules: Omit<AuditEmailConfigRuleItemType, 'triggerPoints'>[];
}

export interface CustomSignUpFormResponseType {
    id: string;
    name: string;
    url: string;
    signUpAction: CustomSignUpActionEnum;
    formSections: ISignupFormStepResponseType[];
    imageS3Url: string;
    emailRules: AuditEmailConfigRuleItemType[];
    status: CustomSignUpStatusEnum;
    createdByTenantId: string;
    createdByEmail: string;
    lastModifiedBy: string;
    createdOn: string;
    updatedOn: string;
    viewId?: string;
    auditId?: string;
}

export interface ISignupFormStepResponseType {
    formName: string;
    fields: ISignUpFieldTypeResponse[];
}

export interface ISignUpFieldTypeResponse {
    id?: string;
    label: string;
    placeHolder: string;
    minValue?: number;
    maxValue?: number;
    required: boolean;
    selectionLimit?: number;
    fieldType: CustomSignUpFieldType;
    sectionName?: string;
    name: string;
    configure?: boolean;
    defaultVariableValue?: boolean;
    disabled?: boolean;
    hideFloatLabel?: boolean;
    emailBody?: boolean;
    subject?: boolean;
    listData?: {
        id: string;
        label: string;
    }[];
}

export interface CustomSignUpPageSubmitPayloadType {
    domainId: string;
    signUpId: string;
    companyName: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    viewId: string;
    auditId: string;
    formFields: {
        [key: string]: string | string[] | number;
    };
}

export const CustomeSignUpSubmitDefaultKeys = [
    'domainId',
    'signUpId',
    'companyName',
    'firstName',
    'lastName',
    'email',
    'password',
    'confirmPassword',
];
