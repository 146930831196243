<app-single-select-dropdown
    *ngIf="filterInfo"
    [isCaching]="isCaching"
    [filterInfo]="filterInfo"
    [clearable]="false"
    [selectedValue]="selectedValue.customer"
    [refreshListing]="refreshListing"
    (change)="customerChanged($event)"
></app-single-select-dropdown>

<section>
    <div class="title">Users</div>
    <app-multi-select-dropdown
        [isCaching]="false"
        [filterInfo]="
            [] | createFilterInfo: 'Users':null:null:lightMode:userApiInfo
        "
        [selectedValues]="selectedValue.users"
        [refreshListing]="refreshUserListing"
        [apiInputCallback]="usersInputCallback.bind(this)"
        (change)="userChanged($event)"
    ></app-multi-select-dropdown>
</section>
