<app-vertical-scrolling-tab-group [modalId]="modalInjectedData.modalId">
    <ng-container *ngFor="let tab of tabs; index as i">
        <ng-template appVerticalScrollingTab [tabLabel]="tab">
            <mat-accordion>
                <mat-expansion-panel class="tw-py-2">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ tab }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-only-table-generator
                        [tableData]="data[tabs[i]]"
                        [tableInput]="tableInput"
                        [hideControlBar]="true"
                    >
                    </app-only-table-generator>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-template>
    </ng-container>
</app-vertical-scrolling-tab-group>
