<div class="wrapper tw-overflow-auto" id="wrappers">
    <ng-container
        *ngFor="let keyValueInput of keyValueFormGenInputArray; index as i"
    >
        <div
            class="main-container tw-flex tw-justify-center tw-items-center tw-overflow-hidden"
        >
            <div class="app-form-container tw-relative">
                <app-form-generator
                    *ngIf="keyValueInput"
                    [formGeneratorInput]="keyValueInput"
                    (formGroupRef)="formGroupRef[i] = $event"
                    (valuesChange)="changed($event, i)"
                ></app-form-generator>

                <div
                    class="tw-py-1 tw-absolute tw-bottom-0 tw-left-0 tw-max-w-full tw-overflow-hidden tw-whitespace-nowrap tw-overflow-ellipsis"
                    *ngIf="
                        handleCaseInsensitiveAsWarning &&
                        warningMessage &&
                        keyValueInput[WARNING_MESSAGE_KEY]
                    "
                >
                    <span class="tw-text-negative tw-text-fs-100">{{
                        warningMessage
                    }}</span>
                </div>
            </div>
            <div
                class="icons tw-flex tw-mt-2 tw-relative tw-items-center tw-self-center"
            >
                <app-button-generator
                    *ngIf="i === formGroupRef.length - 1"
                    [buttonGenInput]="buttonInputs[0]"
                    (buttonRef)="createNewKeyValueField('', '', true, i)"
                >
                </app-button-generator>

                <app-button-generator
                    *ngIf="keyValueFormGenInputArray.length > 1"
                    [buttonGenInput]="buttonInputs[1]"
                    (buttonRef)="deleteKeyValueField(i)"
                >
                </app-button-generator>
            </div>
        </div>
    </ng-container>
</div>
