import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';
import { IFilterInfo } from '../../interfaces/filter/IFilterInfo';

@Component({
    selector: 'app-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.sass']
})
export class ToggleComponent implements OnInit, OnChanges {
    @Input() filterInfo: IFilterInfo;
    @Input() selectedValue;
    // tslint:disable-next-line: no-output-native
    @Output() change = new EventEmitter();
    constructor() {}

    ngOnInit(): void {
        this.selectedValue = this.selectedValue + '';
    }

    valueChanged($event) {
        this.change.emit($event.value);
    }
    ngOnChanges(changes) {
        if (changes.selectedValue) {
            this.selectedValue = changes.selectedValue.currentValue + '';
        }
    }
}
