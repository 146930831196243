<div class="date-picker-container">
    <mat-form-field (click)="picker.open()">
        <input
            readonly
            matInput
            [matDatepicker]="picker"
            (dateChange)="dateUpdated($event)"
            [min]="minDate"
            [max]="maxDate"
            [(ngModel)]="date"
        />
        <mat-label>{{ data?.placeholderText }}</mat-label>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</div>
