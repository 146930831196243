import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { HitApi } from './../../../classes/HitApi';
import { ModalInjectedData } from './../../../classes/ModalInjectedData';
import { ButtonColorType } from './../../../enums/ButtonColorType';
import { ButtonType } from './../../../enums/ButtonType';
import { FileType } from './../../../enums/FileType';
import { IButtonGeneratorInput } from './../../../interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from './../../../interfaces/form-generator/IFormGeneratorInput';

@Component({
    selector: 'app-upload-json',
    templateUrl: './upload-json.component.html',
    styleUrls: ['./upload-json.component.sass']
})
export class UploadJsonComponent implements OnInit {
    uploadFileFormGenInput: IFormGeneratorInput;
    uploadFileFormGroup: FormGroup;
    widgetRef: Widget;
    onlyTemplate: boolean = false;
    validJson: boolean = false;
    submitButton: IButtonGeneratorInput = {
        buttonName: 'Submit',
        buttonType: ButtonType.RAISED,
        buttonColorType: ButtonColorType.PRIMARY,
        showLoader: true,
        function: (buttonRef: IButtonGeneratorInput) => {
            this.uploadFile(buttonRef);
        }
    };
    constructor(private modalData: ModalInjectedData) {
        this.widgetRef = this.modalData.data['widgetRef'];
        if (modalData.data['rowData']) {
            this.onlyTemplate = true;
        }
    }

    ngOnInit(): void {
        this.uploadFileFormGenInput = {
            formName: 'Upload File',
            state: FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    label: 'File Name',
                    placeholder: 'File Name',
                    name: 'fileName',
                    fieldType: FilterType.TEXT,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'File name is required'
                        }
                    ]
                },
                {
                    label: 'File Description',
                    placeholder: 'File Description',
                    name: 'fileDescription',
                    fieldType: FilterType.TEXTAREA,
                    required: false
                },
                {
                    label: 'Upload File',
                    placeholder: 'Upload File',
                    name: 'uploadFile',
                    fieldType: FilterType.FILE,
                    required: true,
                    accept: FileType.JSON,
                    suffixIcon: {
                        hoverText: 'Upload JSON File Only',
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help_outline'
                        }
                    },
                    prefixIcon: {
                        hoverText: 'Upload JSON File',
                        iconData: {
                            type: IconType.MATICON,
                            class: 'file_upload'
                        }
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This field is required'
                        }
                    ]
                }
            ]
        };
        if (this.onlyTemplate) {
            this.uploadFileFormGenInput.fields = [];
            this.uploadFileFormGenInput.fields.push({
                label: 'Template',
                placeholder: 'Template',
                name: 'template',
                fieldType: FilterType.TEXTAREA,
                required: false,
                disabled: true,
                value: JSON.stringify(
                    this.modalData.data['rowData']['jsonTemplate'],
                    null,
                    3
                )
            });
        }
    }
    valueChange(data) {
        if (data.uploadFile) {
            const file = this.uploadFileFormGroup.get('uploadFile').value;
            Helper.jsonFileReader(file.files[0], this.validateJson.bind(this));
        }
    }
    uploadFile(buttonRef) {
        if (buttonRef.loader) {
            return;
        }
        Helper.markAllFieldAsTouched(this.uploadFileFormGroup);
        if (this.uploadFileFormGroup.invalid) {
            this.uploadFileFormGroup.updateValueAndValidity();
            return;
        }
        const file = this.uploadFileFormGroup.get('uploadFile').value;
        if (!this.validJson) {
            this.widgetRef.notificationsService.showSnackBar(
                'Invalid JSON.',
                true
            );
            return;
        }
        if (!this.getFileExtension(file.files[0].name)) {
            this.widgetRef.notificationsService.showSnackBar(
                'Invalid file format.',
                true
            );
            return;
        }
        buttonRef.loader = true;
        const preSigneApiConfig: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['provisioning']['preSignedUrl']
        );
        preSigneApiConfig.input = {
            fileName: this.uploadFileFormGroup.get('fileName').value
        };
        preSigneApiConfig.function = (response) => {
            this.hitPreSignedUrl(
                response['dataMap']['preSignedURL'],
                buttonRef
            );
            this.uploadJsonTemplate(buttonRef);
        };
        preSigneApiConfig.errorFunction = (error) => {
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
            buttonRef.loader = false;
        };
        new HitApi(
            preSigneApiConfig,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    validateJson(json) {
        if (json instanceof Array) {
            this.validJson = false;
        } else {
            this.validJson = true;
        }
    }
    getFileExtension(file) {
        const extension = file.split('.');
        if (
            extension.length &&
            extension[extension.length - 1].toLowerCase() === 'json'
        ) {
            return true;
        }
        return false;
    }
    uploadJsonTemplate(buttonRef) {
        const input = {
            fileDescription:
                this.uploadFileFormGroup.get('fileDescription').value,
            fileName: this.uploadFileFormGroup.get('fileName').value
        };
        const uploadJson: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['provisioning']['uploadJson']
        );
        uploadJson.input = input;
        uploadJson.function = () => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'File successfully uploaded'
            );
            this.widgetRef.refreshWidget();
            this.widgetRef.modalService.closeModal(
                null,
                this.modalData.modalId
            );
        };
        uploadJson.errorFunction = (error) => {
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
            buttonRef.loader = false;
        };
        new HitApi(
            uploadJson,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    hitPreSignedUrl(url, buttonRef) {
        const uploadJson: IHitApi = {
            url: url,
            input: this.uploadFileFormGroup.get('uploadFile').value.files[0],
            requestType: RequestType.PUT,
            uniqueIdentity: Symbol(),
            function: () => {},
            errorFunction: (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error
                );
                buttonRef.loader = false;
            },
            config: {
                authorization: AuthorizationType.NOT_AUTHORIZED,
                ignoreBaseUrl: true
            }
        };

        new HitApi(
            uploadJson,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
