import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class UrlValidator extends CustomValidator {
    key = CustomValidatorKey.URL_VALIDATOR;
    validatorFunction(control: AbstractControl): ValidationErrors {
        const URL_REGEXP =
            /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;
        return Validators.pattern(URL_REGEXP)(control);
    }
}
