import { Helper } from '../../Helper';

export class Node<T> {
    nodeId: string;
    constructor(
        public data: any,
        public children: Node<T>[],
        public parentNodeId: string
    ) {
        this.nodeId = Helper.generateUniqueKey(16);
    }
}
