import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'redirectionUrlGenerator'
})
export class RedirectionUrlGenerator implements PipeTransform {
    transform(item, sideNavigationData): string {
        let tempUrl = '';
        let url = window.location.origin;
        sideNavigationData.forEach((value, index) => {
            if (value.currentLevelName) {
                tempUrl = tempUrl + '/' + value.currentLevelName;
            }
        });
        tempUrl = tempUrl + '/' + item.name;
        tempUrl = tempUrl.split(' ').join('_').toLowerCase();
        url = url + tempUrl;
        return url;
    }
}
