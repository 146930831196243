import { Component } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ICellRendererParams } from 'ag-grid-community';
import { FilterType } from '../../enums/FilterType';
import { IFilterInfo } from './../../interfaces/filter/IFilterInfo';

@Component({
    selector: 'app-ag-grid-cell-column',
    templateUrl: './ag-grid-cell-column.component.html',
    styleUrls: ['./ag-grid-cell-column.component.sass']
})
export class AgGridCellColumnComponent {
    filterInfo: IFilterInfo;
    selectedValue = '';
    params: any;
    appendToClass: string;

    FilterType = FilterType;
    isFieldDummy = true;

    constructor() {}

    agInit(params: ICellRendererParams | any): void {
        this.params = params;
        this.appendToClass = `.${params.appendToClass}`;

        this.selectedValue = this.params.getSelectedData(params);
        this.filterInfo = this.params.filterInfo ?? {};
        this.filterInfo.listData = this.params.getListData(params);
        if (this.params.setEditableFn) {
            this.params.setEditableFn(params, () => this.enableField());
        }
    }

    dropDownValueChange(event) {
        if (this.params.callBackFn) {
            this.params.callBackFn(event, this.params);
        }
    }

    enableField() {
        this.isFieldDummy = false;
    }

    dropDownRefFn($event: MatSidenav) {
        if ($event && this.params.dropDownRefFn) {
            this.params.dropDownRefFn($event);
        }
    }
}
