import { Component, OnInit } from '@angular/core';
import { GridOptions, ValueFormatterParams } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ConfirmationModalComponent } from 'src/app/shared/components/modal-templates/confirmation-modal/confirmation-modal.component';
import { MyPriceConfigurationModalComponent } from 'src/app/shared/components/modal-templates/my-price-configuration/my-price-config-modal/my-price-configuration-modal/my-price-configuration-modal.component';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ButtonColorType } from './../../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../../enums/ButtonType';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from './../../../../../interfaces/button-generator/IButtonGeneratorInput';
import { MultiButtonGeneratorComponent } from './../../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-my-price-listing',
    templateUrl: './my-price-listing.component.html',
    styleUrls: ['./my-price-listing.component.sass']
})
export class MyPriceListingComponent implements OnInit {
    widgetRef: Widget;
    tableInput: ITableGeneratorInput;
    selectedValue = [];
    gridRef: GridOptions;
    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
        this.tableInput = {
            afterResponse: null,
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-chalkboard-teacher'
            },
            selection: 'multiple',
            buttons: [
                {
                    buttonName: 'Add ',
                    buttonType: ButtonType.RAISED,
                    buttonColorType: ButtonColorType.PRIMARY,
                    function: () => {
                        this.addPrice();
                    },
                    loader: false
                },
                {
                    buttonName: 'Edit ',
                    buttonColorType: ButtonColorType.PRIMARY,
                    buttonType: ButtonType.RAISED,
                    function: () => {
                        this.bulkEdit();
                    },
                    loader: false
                },
                {
                    buttonName: 'Delete ',
                    buttonColorType: ButtonColorType.WARN,
                    buttonType: ButtonType.RAISED,
                    function: () => {
                        this.bulkDelete();
                    },
                    loader: false
                }
            ],
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'myPriceConfigData'
            },
            columns: [
                {
                    columnName: 'Aws Account',
                    columnKey: 'awsAccount',
                    pinned: 'left'
                },
                {
                    columnName: 'Payer Account',
                    columnKey: 'payerAccount'
                },
                {
                    columnName: 'Charge Type Included',
                    columnKey: 'chargeTypeIncluded'
                },
                {
                    columnName: 'Action',
                    columnKey: 'action',
                    pinned: 'right',
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: ValueFormatterParams) => {
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'Edit',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.INFO,
                                showLoader: false,
                                function: () => {
                                    const customerData = rowData['data'];
                                    const Icon: IIcon = {
                                        type: IconType.FONTAWSOME,
                                        class: 'fas fa-file-invoice'
                                    };

                                    const modalData: IModalData = {
                                        modalName: 'My Price Configuration',
                                        modalIcon: Icon,
                                        sourceId: this.widgetRef.uniqueIdentity,
                                        modalType: ModalType.MIDDLE,
                                        modalWidthVw: 50,
                                        modalSteps: [
                                            {
                                                stepData: {
                                                    componentToLoad:
                                                        MyPriceConfigurationModalComponent,
                                                    payload: {
                                                        data: {
                                                            edit: true,
                                                            customerData: [
                                                                customerData
                                                            ],
                                                            widgetRef:
                                                                this.widgetRef
                                                        }
                                                    }
                                                },
                                                stepName: ''
                                            }
                                        ]
                                    };
                                    this.widgetRef.modalService.openModal(
                                        modalData
                                    );
                                }
                            },
                            {
                                buttonName: 'Delete',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.WARN,
                                showLoader: false,
                                function: () => {
                                    const data = rowData['data'];
                                    const confirmationModalData: IConfirmationModal =
                                        {
                                            function: (modalId: Symbol) => {
                                                const apiArgs =
                                                    Helper.generateHitApiConfig(
                                                        this.widgetRef
                                                            .widgetData
                                                            .widgetInfo.delete
                                                    );
                                                apiArgs.input = {
                                                    listAwsUsageAccountIds: [
                                                        data['awsAccount']
                                                    ]
                                                };
                                                apiArgs.function = () => {
                                                    this.widgetRef.notificationsService.showSnackBar(
                                                        'Configuration deleted successfully.'
                                                    );
                                                    this.widgetRef.refreshWidget();
                                                    this.widgetRef.modalService.closeModal(
                                                        null,
                                                        modalId
                                                    );
                                                };
                                                apiArgs.errorFunction = (
                                                    error
                                                ) => {
                                                    Helper.showErrorMessage(
                                                        this.widgetRef
                                                            .notificationsService,
                                                        error
                                                    );
                                                    this.widgetRef.refreshWidget();

                                                    this.widgetRef.modalService.closeModal(
                                                        null,
                                                        modalId
                                                    );
                                                };
                                                new HitApi(
                                                    apiArgs,
                                                    this.widgetRef.httpService,
                                                    this.widgetRef.ngZone
                                                ).hitApi();
                                            },

                                            modalName: 'Delete Cofiguration',
                                            modalIcon: {
                                                type: IconType.FONTAWSOME,
                                                class: 'fas fa-trash'
                                            },
                                            contextIcon: {
                                                extraClass: 'color-accent',
                                                type: IconType.FONTAWSOME,
                                                class: 'fas fa-exclamation-triangle'
                                            },
                                            confirmationMessage: `Are you sure you want to delete this configuration ?`,
                                            buttonText: 'Delete',
                                            buttonColorType:
                                                ButtonColorType.WARN,
                                            loaderOnExec: true
                                        };
                                    const modalData: IModalData = {
                                        modalName:
                                            confirmationModalData.modalName,
                                        modalIcon:
                                            confirmationModalData.modalIcon,
                                        sourceId: Symbol(),
                                        modalType: ModalType.MIDDLE,
                                        modalHeightVh: 30,
                                        modalWidthVw: 50,
                                        modalSteps: [
                                            {
                                                stepData: {
                                                    componentToLoad:
                                                        ConfirmationModalComponent,
                                                    payload: {
                                                        data: {
                                                            function:
                                                                confirmationModalData.function,
                                                            params: confirmationModalData
                                                        }
                                                    }
                                                },
                                                stepName: 'Confirmation'
                                            }
                                        ]
                                    };
                                    this.widgetRef.modalService.openModal(
                                        modalData
                                    );
                                }
                            }
                        ];

                        const buttonOption: IMultiButtonOption = {
                            layout: {
                                justifyContent: 'space-evenly'
                            }
                        };
                        rowData['options'] = buttonOption;
                        rowData['buttonGenInputs'] = buttons;
                        return rowData;
                    }
                }
            ],
            columnResizeCount: 6
        };
    }

    ngOnInit(): void {}
    selectedRow() {
        this.selectedValue = [];

        const selectedNodes = this.gridRef.api.getSelectedNodes();

        if (selectedNodes.length) {
            selectedNodes.forEach((selectedRow) => {
                this.selectedValue.push(selectedRow.data);
            });
        } else {
            this.selectedValue = [];
        }
    }
    bulkEdit() {
        if (!this.selectedValue.length) {
            this.widgetRef.notificationsService.showSnackBar(
                'Select atleast one item.',
                true
            );
            return;
        }
        const Icon: IIcon = {
            type: IconType.FONTAWSOME,
            class: 'fas fa-file-invoice'
        };
        const modalData: IModalData = {
            modalName: 'My Price Configuration',
            modalIcon: Icon,
            sourceId: this.widgetRef.uniqueIdentity,
            modalType: ModalType.MIDDLE,
            modalWidthVw: 70,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: MyPriceConfigurationModalComponent,
                        payload: {
                            data: {
                                edit: true,
                                customerData: this.selectedValue,
                                widgetRef: this.widgetRef
                            }
                        }
                    },
                    stepName: ''
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }
    bulkDelete() {
        if (!this.selectedValue.length) {
            this.widgetRef.notificationsService.showSnackBar(
                'Select atleast one item.',
                true
            );
            return;
        }
        const confirmationModalData: IConfirmationModal = {
            function: (modalId: Symbol) => {
                const apiArgs = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo.delete
                );
                apiArgs.input = this.prepareBulkDeleteInput();
                apiArgs.function = () => {
                    this.widgetRef.notificationsService.showSnackBar(
                        'Configuration deleted successfully.'
                    );
                    this.widgetRef.refreshWidget();
                    this.widgetRef.modalService.closeModal(null, modalId);
                };
                apiArgs.errorFunction = (error) => {
                    Helper.showErrorMessage(
                        this.widgetRef.notificationsService,
                        error
                    );
                    this.widgetRef.refreshWidget();

                    this.widgetRef.modalService.closeModal(null, modalId);
                };

                new HitApi(
                    apiArgs,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            },

            modalName: 'Delete Cofiguration',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            contextIcon: {
                extraClass: 'color-accent',
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclamation-triangle'
            },
            confirmationMessage: `Are you sure you want to delete this configuration ?`,
            buttonText: 'Delete',
            buttonColorType: ButtonColorType.WARN,
            loaderOnExec: true
        };
        const modalData: IModalData = {
            modalName: confirmationModalData.modalName,
            modalIcon: confirmationModalData.modalIcon,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 30,
            modalWidthVw: 50,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ConfirmationModalComponent,
                        payload: {
                            data: {
                                function: confirmationModalData.function,
                                params: confirmationModalData
                            }
                        }
                    },
                    stepName: 'Confirmation'
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }
    prepareBulkDeleteInput() {
        const input = [];

        this.selectedValue.forEach((data) => {
            input.push(data.awsAccount);
        });
        return { listAwsUsageAccountIds: input };
    }
    addPrice() {
        const Icon: IIcon = {
            type: IconType.FONTAWSOME,
            class: 'fas fa-file-invoice'
        };
        const modalData: IModalData = {
            modalName: 'My Price Configuration',
            modalIcon: Icon,
            sourceId: this.widgetRef.uniqueIdentity,
            modalType: ModalType.MIDDLE,
            modalWidthVw: 70,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: MyPriceConfigurationModalComponent,
                        payload: {
                            data: {
                                customerData: null,
                                widgetRef: this.widgetRef
                            }
                        }
                    },
                    stepName: ''
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }
}
