import { TitleCasePipe } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    GridOptions,
    RowEvent,
    RowNode,
    ValueFormatterParams,
    ValueGetterParams
} from 'ag-grid-community';
import { debounceTime } from 'rxjs/operators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { GraphType } from 'src/app/shared/enums/GraphType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IGraphData } from 'src/app/shared/interfaces/graph/IGraphData';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { Messages } from './../../../../classes/Messages';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';
import { FilterStoreKey } from './../../../../enums/FilterStoreKey';
import { FormState } from './../../../../enums/FormState';
import { IconType } from './../../../../enums/IconType';
import { IApiInfo } from './../../../../interfaces/api/IApiInfo';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from './../../../../interfaces/hit-api/IHitApi';
import { IModalData } from './../../../../interfaces/modal/IModalData';
import { IColumnData } from './../../../../interfaces/table-generator/IColumnData';
import { ITableGeneratorInput } from './../../../../interfaces/table-generator/ITableGeneratorInput';
import { RegexExtractPipe } from './../../../../pipes/regex-extract.pipe';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';
import { FormGeneratorModalComponent } from './../../form-generator-modal/form-generator-modal.component';

enum TimePeriod {
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
    YEARLY = 'yearly'
}
enum RightsizingGraphType {
    UTILIZATION = 'utilization',
    COST = 'cost'
}
enum CostType {
    ADDITIONAL_COST = 'Additional Cost',
    COST_SAVINGS = 'Cost Savings'
}
enum DataKeys {
    RECOMMENDATION_ORDER = 'recommendationOrder'
}
enum TableKeys {
    VIRTUAL_MACHINE_TYPE = 'Virtual Machine Type',
    ACTION = 'Action',
    ON_DEMAND = 'On Demand',
    SAVINGS = 'Savings',
    EXPENSES = 'Expenses'
}
@Component({
    selector: 'app-rightsizing-more-info-modal-v3',
    templateUrl: './rightsizing-more-info-modal-v3.component.html',
    styleUrls: ['./rightsizing-more-info-modal-v3.component.sass'],
    providers: [TitleCasePipe]
})
export class RightsizingMoreInfoModalV3Component
    implements OnInit, AfterViewInit
{
    readonly AMORTIZED_PRICE = 'Amortized Price';
    readonly monthlyPrice;
    readonly graphLegends = {
        cpu: 'CPU',
        iops: 'IOPS',
        network: 'Network',
        memory: 'Memory'
    };
    readonly firstLegendId = Helper.generateUniqueKey(8);
    readonly secondLegendId = Helper.generateUniqueKey(8);

    data;
    widgetRef: Widget;
    descriptionKeys: any;
    loader: boolean;
    errorOccured;
    chart: any;
    legends: { id: string; label: string }[];
    rightsizingGraphType = RightsizingGraphType;
    selectedGraphType = RightsizingGraphType.COST;
    selectedTimePeriod = TimePeriod.DAILY;
    recommendationsTableExpanded = true;
    recommendationsGrid: GridOptions;
    amortizedGrid: GridOptions;
    tabs: string[] = ['Recommendations', 'Graphical Analysis', 'Description'];
    recommendationColumnId: string[] = [];
    costType = CostType;
    filteredLegend = {};

    processorDropdownData: IFilterInfo = {
        selector: 'Processor',
        placeholder: 'Processor',
        searchable: false,
        listData: [
            {
                id: 'both',
                label: 'Both'
            },
            {
                id: 'amd',
                label: 'AMD'
            },
            {
                id: 'intel',
                label: 'Intel'
            }
        ]
    };

    graphDropdownData: IFilterInfo[] = [
        {
            selector: 'Graph Type',
            listData: [
                {
                    id: RightsizingGraphType.COST,
                    label: 'Cost'
                },
                {
                    id: RightsizingGraphType.UTILIZATION,
                    label: 'Utilization'
                }
            ]
        },
        {
            selector: 'Time Period',
            listData: [
                {
                    id: TimePeriod.DAILY,
                    label: 'Daily'
                },
                {
                    id: TimePeriod.WEEKLY,
                    label: 'Weekly'
                },
                {
                    id: TimePeriod.MONTHLY,
                    label: 'Monthly'
                },
                {
                    id: TimePeriod.YEARLY,
                    label: 'Yearly'
                }
            ]
        }
    ];

    vmSeriesDropdownData: IFilterInfo;
    recommendationsTableGen: ITableGeneratorInput;
    recommendationsTableData: any[] = [];
    amortizedTableData: any[] = [];
    amortizedTableGen: ITableGeneratorInput;
    descriptionTableData: any[] = [];
    descriptionTableGen: ITableGeneratorInput;
    recommendationExpense: { startCost: string; endCost: string } = {
        startCost: null,
        endCost: null
    };
    colDef = {
        sortable: false,
        filter: false,
        resizable: false
    };
    processorIndex: number = null;
    selectedProcessor: string;
    selectedVMSeries: string[] = [];
    currency: string;

    crossIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fa fa-times'
    };
    arrowUpIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-up'
    };
    arrowDownIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-down'
    };
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    selectedRecommendedInstance;
    graphData: Map<string, object> = new Map<string, object>();
    @ViewChild('rightsizingModal') rightsizingModal: ElementRef;
    resize = new EventEmitter<any>();
    rowOrder: string[] = null;
    lineChart: IGraphData;
    legendKeys = [];
    legendData: {
        current: { label: string; legendColor: string; enable: boolean }[];
        recommended: {
            label: string;
            legendColor: string;
            enable: boolean;
        }[];
    };

    constructor(
        public modalData: ModalInjectedData,
        public modalService: ModalService,
        private filtersService: FiltersService,
        public titlePipe: TitleCasePipe
    ) {
        this.widgetRef = modalData.data.widgetRef;
        this.data = modalData.data.itemData;
        this.descriptionKeys = modalData.data.response.dataMap.descriptionKeys;
        this.currency = modalData.data.response.dataMap.currency ?? '';
        this.rowOrder =
            modalData.data.response.dataMap.configurationOrder ?? [];
        this.monthlyPrice = `Monthly Price (${this.currency})`;
    }

    ngOnInit(): void {
        this.vmSeriesDropdownData = {
            selector: 'Virtual Machine Series',
            placeholder: 'Virtual Machine',
            listData: this.data.dropdownData
        };
        const graphListData = [];
        this.data[DataKeys.RECOMMENDATION_ORDER].forEach((data: string) => {
            if (!data.includes('Current')) {
                graphListData.push({ id: data, label: data });
            }
        });

        const dropDownItem: IFilterInfo = {
            selector: 'Recommended VM(s)',
            listData: graphListData
        };
        this.graphDropdownData.splice(1, 0, dropDownItem);
        this.selectedRecommendedInstance = graphListData[0]['id'];

        this.setExpense();
        this.generateRecommendationsTable();
        this.generateAmortizedTable();
        this.recommendationsTableGen.gridOptions.alignedGrids.push(
            this.amortizedTableGen.gridOptions
        );
        this.amortizedTableGen.gridOptions.alignedGrids.push(
            this.recommendationsTableGen.gridOptions
        );
        this.getGraphData();
        this.generateDescriptionTable();
    }

    ngAfterViewInit(): void {
        this.setColors();
        this.resize
            .pipe(debounceTime(150))
            .subscribe(this.redrawGrid.bind(this));
    }

    setColors() {
        const styleList = [
            `--negativeColorBackground:
                ${Helper.addOpacityToColor(
                    Helper.getCssVarValue('negativeColor'),
                    10
                )}`,
            `--positiveColorBackground:${Helper.addOpacityToColor(
                Helper.getCssVarValue('positiveColor'),
                10
            )}`,
            `--lightbackgroundColor:${Helper.addOpacityToColor(
                Helper.getCssVarValue('backgroundColor'),
                30
            )}`
        ];
        this.rightsizingModal.nativeElement.style = styleList.join('; ');
    }

    setExpense() {
        const monthlyPriceData =
            this.data[this.AMORTIZED_PRICE][this.monthlyPrice];
        const monthlyPriceKeys = Object.keys(
            this.data[this.AMORTIZED_PRICE][this.monthlyPrice]
        );

        let firstTimeEntered: boolean = true;

        monthlyPriceKeys.forEach((key, index) => {
            if (!key.includes('Current')) {
                if (firstTimeEntered === true) {
                    firstTimeEntered = false;
                    this.recommendationExpense.startCost = monthlyPriceData[key]
                        .split('||')[1]
                        .split('>')[1]
                        .split(' ')[2];
                }
                if (index === monthlyPriceKeys.length - 1) {
                    this.recommendationExpense.endCost = monthlyPriceData[key]
                        .split('||')[1]
                        .split('>')[1]
                        .split(' ')[2];
                }
            }
        });
    }

    generateRecommendationsTable() {
        this.recommendationsTableData = [];
        this.recommendationColumnId = [];
        const columns: IColumnData[] = [
            {
                columnKey: null,
                columnName: 'Specifications',
                headerTooltip: 'Specifications',
                children: [
                    {
                        columnKey: 'specifications',
                        columnName: 'Specifications',
                        minWidth: 250,
                        flex: 1,
                        pinned: 'left',
                        cellClass: 'first-pinned-col'
                    }
                ]
            },
            {
                columnKey: null,
                columnName: 'Current Resources',
                headerTooltip: 'Current resources',
                children: [
                    {
                        columnKey: 'current',
                        columnName: 'Current Resources',
                        minWidth: 150,
                        width: 150,
                        flex: 1,
                        pinned: 'left',
                        cellClass: 'second-pinned-col'
                    }
                ]
            },
            {
                columnKey: null,
                columnName: 'Recommended Resources',
                headerTooltip: 'Recommended resources',
                children: []
            }
        ];

        this.data[DataKeys.RECOMMENDATION_ORDER].forEach((key) => {
            if (!key.includes('Current')) {
                this.recommendationColumnId.push(key);
                columns[columns.length - 1]['children'].push({
                    columnKey: key,
                    columnName: key,
                    minWidth: 300,
                    maxWidth: 300,
                    buttonGen: true,
                    colId: key,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: ValueFormatterParams) => {
                        const valueButton: IButtonGeneratorInput = {
                            buttonName: rowData['value'],
                            buttonType: ButtonType.TEXT,
                            buttonColorType: ButtonColorType.DEFAULT,
                            function: null,
                            clickableEffect: false
                        };

                        if (rowData['value'] === 'Rightsize') {
                            valueButton.clickableEffect = true;
                            valueButton.buttonColorType = ButtonColorType.INFO;
                            valueButton.showLoader = true;
                            valueButton.function = (
                                buttonRef: IButtonGeneratorInput
                            ) => {
                                if (buttonRef.loader) {
                                    return;
                                }
                                buttonRef.loader = true;
                                const apiArgs: IHitApi =
                                    Helper.generateHitApiConfig(
                                        this.widgetRef.widgetData.widgetInfo[
                                            'formGenerator'
                                        ]['thresholdFormGenerator']
                                    );
                                apiArgs.input = {
                                    region: this.data['Region'],
                                    os: this.data['Operating System'],
                                    subscriptionId:
                                        this.data['Subscription ID'],
                                    filters: this.filtersService.prepareInput(
                                        this.widgetRef.widgetData.widgetInfo
                                            .filters,
                                        FilterStoreKey.WEBSITE_FILTERS
                                    ),
                                    rowValue: [this.data]
                                };
                                apiArgs.function = (response) => {
                                    this.rightsizeModal(
                                        response,
                                        rowData.colDef
                                    );
                                };
                                apiArgs.errorFunction = (error) => {
                                    Helper.showErrorMessage(
                                        this.widgetRef.notificationsService,
                                        error,
                                        Messages.DEFAULT_ERROR
                                    );
                                };
                                apiArgs.endFunction = () => {
                                    if (buttonRef) {
                                        buttonRef.loader = false;
                                    }
                                    this.widgetRef.changeDetectorRef.detectChanges();
                                };

                                new HitApi(
                                    apiArgs,
                                    this.widgetRef.widgetHttpService,
                                    this.widgetRef.widgetHttpService.ngZone
                                ).hitApi();
                            };
                        }

                        rowData['buttonGenInputs'] = [valueButton];
                        return rowData;
                    }
                });
            }
        });

        this.rowOrder.map((key, index) => {
            if (this.data.Configuration[key]) {
                const data = { specifications: key };
                Object.keys(this.data.Configuration[key]).forEach(
                    (nestedKey) => {
                        if (nestedKey.includes('Current')) {
                            data['current'] =
                                this.data.Configuration[key][nestedKey];
                        } else {
                            data[nestedKey] =
                                this.data.Configuration[key][nestedKey];
                        }
                    }
                );
                this.recommendationsTableData.push(data);
            }
        });

        const VMTypeRow = { specifications: TableKeys.VIRTUAL_MACHINE_TYPE };
        const lastRow = { specifications: TableKeys.ACTION };
        this.data[DataKeys.RECOMMENDATION_ORDER].forEach((key) => {
            if (!key.includes('Current')) {
                lastRow[key] = 'Rightsize';
                VMTypeRow[key] = key;
            } else {
                VMTypeRow['current'] = key.split(' ')[0];
            }
        });

        this.recommendationsTableData.unshift(VMTypeRow);
        this.recommendationsTableData.push(lastRow);

        this.recommendationsTableGen = {
            widgetIconData: null,
            listExtraction: { type: 'DIRECT' },
            columns,
            headerHeight: 0,
            groupHeaderHeight: 50,
            tableAutoHeight: true,
            rowHeight: 50,
            isExternalFilterPresent: this.isExternalFilterAvailable.bind(this),
            doesExternalFilterPass: this.externalFilterFunction.bind(this),
            gridOptions: {
                alignedGrids: []
            }
        };
    }

    rightsizeModal(response, coldef) {
        const modalId: Symbol = Symbol();
        let currentCost;
        let costAfterRightsizing;
        const regexExp = /([0-9]*([\.]*[0-9]*))/g;
        const monthlyPrice = this.data[this.AMORTIZED_PRICE][this.monthlyPrice];
        Object.keys(monthlyPrice).forEach((keys) => {
            if (keys.includes('Current')) {
                currentCost = new RegexExtractPipe().transform(
                    monthlyPrice[keys],
                    regexExp
                );
                currentCost = parseFloat(
                    currentCost.filter((each) => !!each)[0]
                );
            }
            if (keys === coldef.colId) {
                costAfterRightsizing = new RegexExtractPipe().transform(
                    monthlyPrice[keys],
                    regexExp
                );
                costAfterRightsizing = parseFloat(
                    costAfterRightsizing.filter((each) => !!each)[0]
                );
            }
        });

        const savings = Helper.roundOff(currentCost - costAfterRightsizing, 3);

        const formGen: IFormGeneratorInput = {
            formName: 'Rightsize',
            submitButton: {
                buttonName: 'Confirm',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    buttonRef.loader = true;
                    const apiInfo: IApiInfo =
                        response[response.steps[1]].submitButton.apiInfo;
                    const apiArgs: IHitApi =
                        Helper.generateHitApiConfig(apiInfo);

                    apiArgs.input = {
                        rightsize: 'centilyticsRecommendation',
                        recommendations: coldef.colId,
                        listResourceUri: [this.data['Resource ID']]
                    };

                    apiArgs.function = (response) => {
                        this.modalService.closeModal(null, modalId);
                    };

                    apiArgs.errorFunction = (error) => {
                        Helper.showErrorMessage(
                            this.widgetRef.notificationsService,
                            error,
                            Messages.DEFAULT_ERROR
                        );
                    };
                    apiArgs.endFunction = () => {
                        buttonRef.loader = false;
                    };

                    new HitApi(
                        apiArgs,
                        this.widgetRef.widgetHttpService,
                        this.widgetRef.widgetHttpService.ngZone
                    ).hitApi();
                }
            },
            extraClass: 'rightsize-modal',
            formMessage:
                'Your resource will be rightsized with a minimal downtime.',
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Current VM Type',
                    name: null,
                    placeholder: null,
                    fieldType: FilterType.CONTENT,
                    showLabel: true,
                    value: this.data['VM Type']
                },
                {
                    label: 'Recommended VM Type',
                    name: null,
                    placeholder: null,
                    fieldType: FilterType.CONTENT,
                    showLabel: true,
                    value: coldef.colId
                },
                {
                    label: 'Current Cost',
                    name: null,
                    placeholder: null,
                    fieldType: FilterType.CONTENT,
                    showLabel: true,
                    value: `${this.currency} ${currentCost}`
                },
                {
                    label: 'Cost after rightsizing',
                    name: null,
                    placeholder: null,
                    fieldType: FilterType.CONTENT,
                    showLabel: true,
                    value: `${this.currency} ${costAfterRightsizing}`
                },
                {
                    label: savings < 0 ? 'Additional Cost' : 'Savings',
                    fieldType: FilterType.CONTENT,
                    name: null,
                    placeholder: null,
                    showLabel: true,
                    value: `${this.currency}  ${Math.abs(+savings)}`,
                    extraClass: savings < 0 ? 'additionalCost' : 'savings'
                }
            ]
        };

        const modalData: IModalData = {
            modalName: 'Rightsize',
            modalIcon: null,
            sourceId: Symbol(),
            modalId: modalId,
            modalType: ModalType.MIDDLE,
            modalHeightVh: 74,
            modalWidthVw: 50,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: FormGeneratorModalComponent,
                        payload: {
                            data: formGen
                        }
                    },
                    stepName: 'Confirmation'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    generateAmortizedTable() {
        const columns: IColumnData[] = [
            {
                columnKey: 'amortizedPrice',
                columnName: '',
                pinned: 'left',
                cellClass: 'first-pinned-col',
                minWidth: 250,
                flex: 1
            },
            {
                columnKey: 'currentOnDemand',
                columnName: 'Current On Demand',
                pinned: 'left',
                minWidth: 150,
                width: 150,
                flex: 1,
                cellClass: 'second-pinned-col',
                headerTooltip: 'Current On Demand',
                cellRenderer: (rowData: RowEvent) => {
                    const data = Helper.stringToHTML(rowData['value']).body;
                    return data;
                }
            }
        ];
        const amortizedTableColumnId = [];
        const amortizedTableKey =
            this.data[CostType.ADDITIONAL_COST] !== '-'
                ? TableKeys.EXPENSES
                : TableKeys.SAVINGS;

        Object.keys(this.data[this.AMORTIZED_PRICE]).forEach((key) => {
            const tempData = { amortizedPrice: key };
            Object.keys(this.data[this.AMORTIZED_PRICE][key]).forEach(
                (nestedKey) => {
                    if (nestedKey.includes('Current')) {
                        tempData['currentOnDemand'] =
                            this.data[this.AMORTIZED_PRICE][key][nestedKey];
                    } else {
                        if (!amortizedTableColumnId.includes(nestedKey)) {
                            const data: IColumnData[] = [
                                {
                                    columnKey: nestedKey,
                                    columnName: TableKeys.ON_DEMAND,
                                    minWidth: 150,
                                    maxWidth: 150,
                                    colSpan: () => 2,
                                    headerTooltip: TableKeys.ON_DEMAND,
                                    cellRenderer: (rowData: RowEvent) => {
                                        const div =
                                            document.createElement('div');
                                        div.classList.add(
                                            'tw-flex',
                                            'tw-w-full',
                                            'savings-demand'
                                        );

                                        const onDemandSpan =
                                            document.createElement('span');
                                        onDemandSpan.innerHTML =
                                            rowData['value'].split('||')[0];
                                        const savingsSpan = Helper.stringToHTML(
                                            rowData['value'].split('||')[1]
                                        ).body;

                                        div.appendChild(onDemandSpan);
                                        if (
                                            savingsSpan.innerHTML !==
                                            'undefined'
                                        ) {
                                            div.appendChild(savingsSpan);
                                        }
                                        return div;
                                    }
                                },
                                {
                                    columnKey: `${amortizedTableKey}${nestedKey}`,
                                    columnName: amortizedTableKey,
                                    minWidth: 150,
                                    maxWidth: 150,
                                    headerTooltip: amortizedTableKey
                                }
                            ];

                            columns.push(...data);
                            amortizedTableColumnId.push(nestedKey);
                        }

                        tempData[nestedKey] =
                            this.data[this.AMORTIZED_PRICE][key][nestedKey];
                    }
                }
            );
            this.amortizedTableData.push(tempData);
        });

        this.amortizedTableGen = {
            widgetIconData: null,
            listExtraction: { type: 'DIRECT' },
            columns,
            tableAutoHeight: true,
            rowHeight: 50,
            gridOptions: {
                alignedGrids: []
            }
        };
    }

    getGraphData() {
        this.errorOccured = false;
        const recommendedInstance = this.selectedRecommendedInstance;
        const graphResponse = this.graphData.get(recommendedInstance);

        if (graphResponse) {
            this.prepareGraphInput(graphResponse);
            return;
        }

        this.loader = true;
        const apiInfo =
            this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'] &&
            this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                'rightsizingGraph'
            ]
                ? this.widgetRef.widgetData.widgetInfo[
                      'additionalApisForWidget'
                  ]['rightsizingGraph']
                : null;

        if (!apiInfo) {
            return;
        }
        const apiArgs: IHitApi = Helper.generateHitApiConfig(apiInfo);

        apiArgs.input = {
            recommendedInstanceName: recommendedInstance,
            rowValue: [this.data],
            filters: this.filtersService.prepareFiltersInputs(
                this.widgetRef.widgetData.widgetInfo.filters,
                FilterStoreKey.WEBSITE_FILTERS
            )
        };

        apiArgs.function = (response) => {
            this.graphData.set(recommendedInstance, response);
            this.prepareGraphInput(response);
            this.loader = false;
            this.errorOccured = false;
        };
        apiArgs.errorFunction = (error) => {
            this.errorOccured = true;
            this.loader = false;
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
        };

        new HitApi(
            apiArgs,
            this.widgetRef.widgetHttpService,
            this.widgetRef.widgetHttpService.ngZone
        ).hitApi();
    }

    prepareGraphInput(response: any) {
        const chartData = this.prepareGraphData(
            response.graphData[this.selectedGraphType]
        );
        this.legendKeys = chartData.keys;

        const filteredSeries =
            this.selectedGraphType === RightsizingGraphType.UTILIZATION
                ? this.legendKeys.filter((data) => {
                      return this.filteredLegend[data] ?? true;
                  })
                : this.legendKeys;

        const data: IGraphData = {
            graphType: GraphType.LINE,
            labelText: {
                categoryX: 'category',
                xAxesTitle: 'Date',
                yAxesTitle:
                    this.selectedGraphType === RightsizingGraphType.COST
                        ? `Cost(${this.currency})`
                        : 'Utilization(%)'
            },
            chartData: chartData.graphData,
            seriesList: filteredSeries,
            toggleLegend: false,
            disableLegend: this.selectedGraphType !== RightsizingGraphType.COST,
            categoryAxisRotationAngle: 90,
            extraClass:
                this.selectedGraphType === RightsizingGraphType.COST
                    ? 'tw-flex-col'
                    : '',
            graphExtraClass: 'tw-w-full',
            graphStyle: { 'min-height': '350px' },
            seriesName:
                this.selectedGraphType === RightsizingGraphType.COST
                    ? (legendKeys) => {
                          return this.titlePipe.transform(
                              legendKeys.replace('_', ' ')
                          );
                      }
                    : null,
            tooltiptext: (chart, legendKeys) => {
                return `${this.titlePipe.transform(
                    legendKeys.replace('_', ' ')
                )}: ${
                    this.selectedGraphType === RightsizingGraphType.COST
                        ? `${this.currency}{valueY}`
                        : '{valueY}%'
                } `;
            }
        };
        this.lineChart = data;

        this.widgetRef.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                if (
                    this.selectedGraphType === RightsizingGraphType.UTILIZATION
                ) {
                    this.prepareCustomLegends();
                }
            }, 300);
        });
    }

    prepareCustomLegends() {
        this.legendData = null;
        const legendObject: {
            current: { label: string; legendColor: string; enable: boolean }[];
            recommended: {
                label: string;
                legendColor: string;
                enable: boolean;
            }[];
        } = {
            current: [],
            recommended: []
        };
        this.legendKeys.forEach((data: string) => {
            const element = document.querySelector(`[aria-label="${data}"]`);
            const color = element ? element.getAttribute('stroke') : '#808080';
            const legendType = data.includes('current')
                ? 'current'
                : 'recommended';

            const legendName = (
                this.graphLegends[data.split('_')[1]] as string
            ).toLowerCase();

            if (legendType === 'current') {
                const legendData = {
                    label: legendName,
                    legendColor: color,
                    enable: this.filteredLegend[`current_${legendName}`] ?? true
                };
                legendObject.current.push(legendData);
            } else {
                const legendData = {
                    label: legendName,
                    legendColor: color,
                    enable:
                        this.filteredLegend[`recommended_${legendName}`] ?? true
                };
                legendObject.recommended.push(legendData);
            }
        });
        this.legendData = legendObject;
    }

    toggleCustomLegend(type: 'current' | 'recommended', index) {
        this.legendData[type][index]['enable'] =
            !this.legendData[type][index]['enable'];

        this.legendData.recommended.forEach((data) => {
            this.filteredLegend[`recommended_${data.label}`] = data.enable;
        });
        this.legendData.current.forEach((data) => {
            this.filteredLegend[`current_${data.label}`] = data.enable;
        });

        this.prepareGraphInput(
            this.graphData.get(this.selectedRecommendedInstance)
        );
    }

    prepareGraphData(instanceType): { graphData; keys } {
        const current = 'Current';
        const recommended = 'Recommended';
        const data = [];
        this.legends = [];
        let keys = [];
        const dateValuePair = new Map<string, number>();
        if (this.selectedGraphType === RightsizingGraphType.COST) {
            keys = ['current_cost', 'recommended_cost'];
            const graphData = [];
            [current, recommended].forEach((graphKey) => {
                const graphTypeData =
                    instanceType[this.selectedTimePeriod][graphKey];
                Object.keys(graphTypeData).forEach((timeKey) => {
                    if (!graphData.includes(timeKey)) {
                        graphData.push(timeKey);
                        data.push({
                            category: timeKey,
                            [`current_${RightsizingGraphType.COST}`]:
                                instanceType[this.selectedTimePeriod][
                                    'Current'
                                ][timeKey],
                            [`recommended_${RightsizingGraphType.COST}`]:
                                instanceType[this.selectedTimePeriod][
                                    'Recommended'
                                ][timeKey]
                        });
                    }
                });
            });
        } else {
            const memoryType = Object.keys(instanceType);
            memoryType.forEach((memoryKey) => {
                const graphData = [];
                keys.push(
                    ...[`current_${memoryKey}`, `recommended_${memoryKey}`]
                );
                [current, recommended].forEach((graphKey) => {
                    const graphTypeData =
                        instanceType[memoryKey][this.selectedTimePeriod][
                            graphKey
                        ];
                    Object.keys(graphTypeData).forEach((timeKey, index) => {
                        if (!graphData.includes(timeKey)) {
                            graphData.push(timeKey);
                            if (dateValuePair.has(timeKey)) {
                                const i = dateValuePair.get(timeKey);
                                data[i][`current_${memoryKey}`] =
                                    instanceType[memoryKey][
                                        this.selectedTimePeriod
                                    ]['Current'][timeKey];
                                data[i][`recommended_${memoryKey}`] =
                                    instanceType[memoryKey][
                                        this.selectedTimePeriod
                                    ]['Recommended'][timeKey];
                            } else {
                                if (
                                    Array.from(dateValuePair.values()).includes(
                                        dateValuePair.get(timeKey)
                                    )
                                ) {
                                    dateValuePair.set(timeKey, data.length);
                                } else {
                                    dateValuePair.set(timeKey, index);
                                }
                                const tempData = {
                                    category: timeKey,
                                    [`current_${memoryKey}`]:
                                        instanceType[memoryKey][
                                            this.selectedTimePeriod
                                        ]['Current'][timeKey],
                                    [`recommended_${memoryKey}`]:
                                        instanceType[memoryKey][
                                            this.selectedTimePeriod
                                        ]['Recommended'][timeKey]
                                };

                                Object.keys(tempData).forEach((key) => {
                                    if (!tempData[key]) delete tempData[key];
                                });

                                data.push(tempData);
                            }
                        }
                    });
                });
            });
        }
        return {
            graphData: data,
            keys: keys
        };
    }

    generateDescriptionTable() {
        const columns: IColumnData[] = [
            {
                columnKey: 'key',
                columnName: 'key',
                pinned: 'left',
                cellClass: 'pinned-description',
                maxWidth: 400
            },
            {
                columnKey: 'value',
                columnName: 'value',
                cellClass: (params) => {
                    if (
                        params.data.key === 'Resource ID' ||
                        params.data.key === 'Resource Tags'
                    ) {
                        return 'generalDescriptionTable';
                    }
                },
                cellStyle: (param) => {
                    return {
                        justifyContent: 'center'
                    };
                },
                columnValueGetter: (params: ValueGetterParams) => {
                    let value: string = '';
                    const data = params.data['value'];
                    if (data instanceof Object) {
                        Object.keys(data).map((key, index, arr) => {
                            value += `${key}:[${data[key]}]${
                                index !== arr.length - 1 ? ` || ` : ``
                            }`;
                        });
                        return value;
                    } else {
                        return data;
                    }
                }
            }
        ];
        this.descriptionKeys.forEach((data) => {
            this.descriptionTableData.push({
                key: data.label,
                value: this.data[data.id]
            });
        });
        this.descriptionTableGen = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            columns,
            tableAutoHeight: true,
            headerHeight: 0
        };
    }

    filterChanged(dropDown, event) {
        this.filteredLegend = {};
        if (dropDown === 'Graph Type') {
            this.selectedGraphType = event;
            this.prepareGraphInput(
                this.graphData.get(this.selectedRecommendedInstance)
            );
        } else if (dropDown === 'Time Period') {
            this.selectedTimePeriod = event;
            this.prepareGraphInput(
                this.graphData.get(this.selectedRecommendedInstance)
            );
        } else if (dropDown === 'Recommended VM(s)') {
            this.selectedRecommendedInstance = event;
            this.getGraphData();
        }
    }

    recommendationsfilterChanged(data, event: any) {
        if (!event) {
            return;
        }

        const processor = ['intel', 'amd'];
        const rowData = this.recommendationsGrid.rowData;
        const amortizedKey =
            this.data[CostType.ADDITIONAL_COST] !== '-'
                ? TableKeys.EXPENSES
                : TableKeys.SAVINGS;

        if (data === 'Virtual Machine Series') {
            this.selectedVMSeries = event;
        }

        if (data === 'Processor') {
            this.selectedProcessor = event.toLowerCase();
            this.processorIndex = rowData.findIndex((data) => {
                return processor.includes(data.current.toLowerCase());
            });
        }

        this.recommendationColumnId.forEach((key) => {
            const showColumn =
                (this.selectedProcessor && this.selectedProcessor !== 'both'
                    ? rowData[this.processorIndex][key].toLowerCase() ===
                      this.selectedProcessor
                    : true) &&
                (this.selectedVMSeries.length
                    ? this.selectedVMSeries.includes(key)
                    : true);

            this.recommendationsGrid.columnApi.setColumnVisible(
                key,
                showColumn
            );

            this.amortizedGrid.columnApi.setColumnVisible(
                `${amortizedKey}${key}`,
                showColumn
            );
        });

        this.redrawGrid();
    }

    redrawGrid() {
        if (!this.recommendationsGrid || !this.amortizedGrid) {
            return;
        }
        /*
         * We are using sizeColumnsToFit method to autosize columns
         * because columns are present after applying filters but
         * are not visible due to the width. So we're using this method
         * to fit colums size after applying filter
         * We're using redrawRows and refresh header to maintain UI
         * after resizing table
         * https://www.ag-grid.com/angular-data-grid/column-sizing/
         */
        this.recommendationsGrid.api.sizeColumnsToFit();
        this.recommendationsGrid.api.redrawRows();
        this.amortizedGrid.api.sizeColumnsToFit();
        this.amortizedGrid.api.redrawRows();
        this.widgetRef.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.recommendationsGrid.api.refreshHeader();
                this.amortizedGrid.api.refreshHeader();
            }, 100);
        });
    }

    isExternalFilterAvailable() {
        return !this.recommendationsTableExpanded;
    }

    externalFilterFunction(node: RowNode) {
        if (node.rowIndex <= 4 || node.data.specifications === 'Action') {
            return true;
        }
        return false;
    }
}
