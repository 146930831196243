<app-table-generator
    *ngIf="tableGenInput"
    [tableInput]="tableGenInput"
    [widgetRef]="widgetRef"
    [overviewTemplateEnd]="overviewTemplateEnd"
    (gridRef)="agGrid = $event"
></app-table-generator>

<ng-template #overviewTemplateEnd>
    <div class="tw-flex tw-items-center overview" *ngIf="statusMap">
        <div
            class="
                tw-flex tw-items-center tw-cursor-pointer tw-ml-3
                circle-item
            "
            *ngFor="let status of statusMap | keyvalue"
            [matTooltip]="status.value.name"
            (click)="filterStatus(status.key)"
        >
            <span
                class="tw-h-4 tw-w-4 tw-rounded-full tw-mr-2 circle"
                [style.backgroundColor]="status.value.color"
            ></span>
            <span class="count">{{ status.value.count }}</span>
        </div>
    </div>
</ng-template>
