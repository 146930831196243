import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { TerraformPreviewResourcesModalComponent } from '../terraform-preview-resources-modal/terraform-preview-resources-modal.component';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';

@Component({
    selector: 'app-terraform-request-widget-form-modal',
    templateUrl: './terraform-request-widget-form-modal.component.html',
    styleUrls: ['./terraform-request-widget-form-modal.component.sass']
})
export class TerraformRequestWidgetFormModalComponent
    implements OnInit, OnDestroy
{
    formGenInputs: IFormGeneratorInput;
    formGroup: FormGroup;
    widgetRef: Widget;
    stepTwoData: any;
    providerFileDetails: any;
    callBackFn: any;
    isRequestWidget: boolean = false;
    cloud: string;
    requestDetails: any;
    showRequestButton: boolean = false;
    previewButtonGenInput: IButtonGeneratorInput;
    requestButtonGenInput: IButtonGeneratorInput;
    backButtonGenInput: IButtonGeneratorInput;
    loader: boolean = false;
    errorMessage: string;
    infoIcon: IIcon = {
        type: IconType.MATICON,
        class: 'info'
    };
    currentStepData: any;
    fieldsWithDefaultValue: IFormField[] = [];
    resetModal: Subscription;
    initialFormValues: any;
    counter: number = 1; // This variable used here to remember the initial form value.
    constructor(
        private modalData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService
    ) {
        this.providerFileDetails = this.multiStepFormService.stepData
            .get(modalData.modalId)
            .get(1)['data'];
        if (
            this.multiStepFormService.stepData.get(this.modalData.modalId) &&
            this.multiStepFormService.stepData
                .get(this.modalData.modalId)
                .has(2)
        ) {
            this.currentStepData = this.multiStepFormService.stepData
                .get(this.modalData.modalId)
                .get(2);
        }
        this.isRequestWidget = this.modalData.data['isRequestWidget'];
        this.requestDetails = this.modalData.data['requestDetaiils'];
        if (this.modalData.data['stepTwoData']) {
            this.stepTwoData = this.modalData.data['stepTwoData'];
        }
        if (this.stepTwoData) {
            this.cloud = this.stepTwoData['cloud'];
        } else {
            this.cloud = this.modalData.data['cloud'];
        }

        if (
            this.modalData &&
            this.modalData.data &&
            this.modalData.data['callBackFn']
        ) {
            this.callBackFn = this.modalData.data['callBackFn'];
        }
        this.widgetRef = this.modalData.data['widgetRef'];
    }

    ngOnInit(): void {
        let form: IFormGeneratorInput;
        if (this.currentStepData) {
            this.formGroup = this.currentStepData['formGroup'];
            this.handleDefaultValue(
                this.currentStepData['formGenInputs'] &&
                    this.currentStepData['formGenInputs']['fields']
                    ? this.currentStepData['formGenInputs']['fields']
                    : []
            );
            form = this.currentStepData['formGenInputs'];
            this.fieldsWithDefaultValue =
                this.currentStepData['fieldsWithDefaultValue'];
            this.counter = this.currentStepData['counter'];
            this.initialFormValues = this.currentStepData['initialFormValues'];
        } else {
            this.handleDefaultValue(this.modalData.data['form']['fields']);
            form = this.modalData.data['form'];
        }
        this.generateForm(form);
        this.generateButtons();
        this.resetModal = this.widgetRef.modalService.resetModal.subscribe(
            (data) => {
                if (this.formGroup) {
                    this.formGroup.reset(this.initialFormValues);
                }
            }
        );
    }
    generateForm(form) {
        if (!form) {
            return;
        }
        const formFields = form.fields.filter((field) => {
            if (field['defaultValue']) {
                this.fieldsWithDefaultValue.push(field);
            } else {
                return field;
            }
        });
        if (formFields && formFields.length) {
            this.formGenInputs = {
                formName: '',
                submitButton: null,
                state: FormState.EDIT,
                fields: formFields
            };
        }
    }
    handleDefaultValue(fields) {
        fields.forEach((field: IFormField) => {
            field.hideFloatLabel = true;
            field.placeholder = field.placeholder
                ? field.placeholder
                : field['placeHolder'];
            if (
                field['defaultValue'] &&
                (field.fieldType === FilterType.TEXT ||
                    field.fieldType === FilterType.NUMBER)
            ) {
                field.value =
                    this.formGroup &&
                    this.formGroup.get(field.name) &&
                    this.formGroup.get(field.name).value
                        ? this.formGroup.get(field.name).value
                        : field['defaultValue'];
            } else {
                field.value =
                    this.formGroup &&
                    this.formGroup.get(field.name) &&
                    this.formGroup.get(field.name).value
                        ? this.formGroup.get(field.name).value
                        : field.value;
            }
        });
    }
    goToPreviousStep() {
        if (this.loader) {
            return;
        }
        const stepData = {
            formGenInputs: this.formGenInputs,
            formGroup: this.formGroup,
            fieldsWithDefaultValue: this.fieldsWithDefaultValue,
            counter: this.counter,
            initialFormValues: this.initialFormValues
        };
        this.multiStepFormService.stepData
            .get(this.modalData.modalId)
            .set(2, stepData);
        this.multiStepFormService.previousStep(this.modalData.modalId);
    }
    generateButtons() {
        this.previewButtonGenInput = {
            buttonName: this.isRequestWidget ? 'Test Script' : 'Test',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: (buttonRef) => {
                this.runTestBook(buttonRef);
            },
            showLoader: true
        };
        this.requestButtonGenInput = {
            buttonName: 'Request',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: (buttonRef) => {
                this.request(buttonRef);
            },
            showLoader: true
        };
        this.backButtonGenInput = {
            buttonName: 'Back',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: (buttonRef) => {
                this.goToPreviousStep();
            },
            showLoader: false
        };
    }
    generateFiles() {
        const files = {};
        const filesKey = Object.keys(this.requestDetails.filesDetails);
        filesKey.forEach((file) => {
            files[file] = this.requestDetails.filesDetails[file].map(
                (eachFile) => {
                    return eachFile.key;
                }
            );
        });
        return files;
    }
    runTestBook(buttonRef) {
        if (buttonRef.loader) {
            return;
        }
        if (this.formGroup) {
            Helper.markAllFieldAsTouched(this.formGroup);
            if (this.formGroup.invalid) {
                this.formGroup.updateValueAndValidity();
                return;
            }
        }
        this.errorMessage = '';
        const input = this.prepareInput();
        buttonRef.loader = true;
        this.loader = true;
        const apiInfo = this.isRequestWidget
            ? this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                  'widgetPreview'
              ]
            : this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                  'testRunbook'
              ];
        const apiConfig = Helper.generateHitApiConfig(apiInfo);
        apiConfig.input = input;
        apiConfig.function = (response) => {
            buttonRef.loader = false;
            this.loader = false;
            if (this.checkIfResourceCanBeCreated(response)) {
                if (this.isRequestWidget) {
                    this.showRequestButton = true;
                    this.openPreviewModal(response);
                } else {
                    this.widgetRef.notificationsService.showSnackBar(
                        'Script Tested Successfully.'
                    );
                    this.widgetRef.modalService.closeModal(
                        null,
                        this.modalData.modalId
                    );
                }
                if (this.callBackFn) {
                    this.callBackFn(true);
                }
            }
        };
        apiConfig.errorFunction = (error) => {
            buttonRef.loader = false;
            this.errorMessage = error.error.message;
            this.loader = false;
        };
        new HitApi(
            apiConfig,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    checkIfResourceCanBeCreated(resources): boolean {
        let status: boolean = true;
        resources.forEach((resource) => {
            if (
                resource['resource'] &&
                resource['resource'][0] &&
                resource['resource'][0].includes('error=')
            ) {
                status = false;
                this.errorMessage = resource['resource'][0]
                    .split('error=')[1]
                    .split('}')[0];
            }
        });
        return status;
    }
    request(buttonRef) {
        if (buttonRef.loader) {
            return;
        }
        if (this.formGroup) {
            Helper.markAllFieldAsTouched(this.formGroup);
            if (this.formGroup.invalid) {
                this.formGroup.updateValueAndValidity();
                return;
            }
        }
        const input = this.prepareInput();
        buttonRef.loader = true;
        this.errorMessage = '';
        this.loader = true;
        const apiInfo = this.widgetRef.widgetData.widgetInfo.create;
        const apiConfig = Helper.generateHitApiConfig(apiInfo);
        apiConfig.input = input;
        apiConfig.function = (response) => {
            buttonRef.loader = false;
            this.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                `Request Submitted, refresh the ${this.providerFileDetails.requestInfo.requestName} after 1-2 minutes to get latest status.`,
                false,
                '',
                { duration: 6000 }
            );
            this.widgetRef.refreshWidget();
            this.widgetRef.modalService.closeModal(
                null,
                this.modalData.modalId
            );
        };
        apiConfig.errorFunction = (error) => {
            buttonRef.loader = false;
            this.errorMessage = error.error.message;
            this.loader = false;
        };
        new HitApi(
            apiConfig,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    prepareInput() {
        let files = {};
        if (this.isRequestWidget) {
            files = this.generateFiles();
        }
        const input = this.providerFileDetails;
        input['cloud'] = this.cloud;
        input['mainTfs'] = this.stepTwoData
            ? this.stepTwoData['mainTfs']
            : files['mainTfs'];
        input['variableFiles'] = this.stepTwoData
            ? this.stepTwoData['variableFiles']
            : files['variableFiles'];
        input['tfVarsFiles'] = this.stepTwoData
            ? this.stepTwoData['tfVarsFiles']
            : files['tfVarsFiles'];
        input['requestForm'] = this.generateRequestFormData();
        return input;
    }
    generateRequestFormData() {
        const requestForm = [];
        if (this.formGenInputs) {
            this.formGenInputs.fields.forEach((field) => {
                const obj = {};
                obj['varId'] = field['varId'];
                obj['mappingType'] = field['mappingType'];
                obj['value'] = this.formGroup.getRawValue()[field.name];
                obj['defaultValue'] = field['defaultValue']
                    ? field['defaultValue']
                    : field['mapObject'];
                obj['defaultVariableValue'] = field['defaultValue']
                    ? true
                    : false;
                if (field['mapObject']) {
                    obj['fieldType'] = 'MAP';
                }
                if (field['variableFieldType'].includes('LIST')) {
                    obj['fieldType'] = 'LIST';
                }
                requestForm.push(obj);
            });
        }
        this.fieldsWithDefaultValue.forEach((field) => {
            const obj = {};
            obj['varId'] = field['varId'];
            obj['mappingType'] = field['mappingType'];
            obj['defaultValue'] = field['defaultValue']
                ? field['defaultValue']
                : field['mapObject'];
            obj['defaultVariableValue'] = field['defaultValue'] ? true : false;
            if (field['mapObject']) {
                obj['fieldType'] = 'MAP';
            }
            if (field['variableFieldType'].includes('LIST')) {
                obj['fieldType'] = 'LIST';
            }
            requestForm.push(obj);
        });
        return requestForm;
    }
    openPreviewModal(response) {
        const modalData: IModalData = {
            modalName: 'Preview',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },

            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalWidthVw: 85,
            modalHeightVh: 95,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad:
                            TerraformPreviewResourcesModalComponent,
                        payload: {
                            data: {
                                modalPreviewData: response,
                                widgetRef: this.widgetRef,
                                isPreview: true
                            }
                        }
                    },
                    stepName: ''
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }
    setFormGroup(formGroup: FormGroup) {
        if (this.counter === 1) {
            this.initialFormValues = Helper.dereference(
                formGroup.getRawValue()
            );
            this.counter++;
        }
        this.formGroup = formGroup;
    }
    ngOnDestroy(): void {
        this.resetModal.unsubscribe();
    }
}
