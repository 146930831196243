<form class="main-container">
    <div class="form-data">
        <div class="first-container">
            <span class="details">Account Details</span>
            <hr />
            <div class="form">
                <app-form-generator
                    [formGeneratorInput]="accountListFormGenInput"
                    (formGroupRef)="accountListFormGroup = $event"
                    (resetFormFunction)="resetForm = $event"
                    class="update-form"
                ></app-form-generator>
            </div>
        </div>

        <ng-container *ngIf="!edit">
            <div class="second-container">
                <span class="details">Email Details</span>
                <hr />
                <div class="second-form-container">
                    <app-form-generator
                        class="checkbox-container"
                        *ngIf="checkBoxFormGenInput"
                        [formGeneratorInput]="checkBoxFormGenInput"
                        (formGroupRef)="checkBoxFormGroup = $event"
                    ></app-form-generator>
                    <app-form-generator
                        *ngIf="
                            checkBoxFormGroup &&
                            checkBoxFormGroup.value &&
                            checkBoxFormGroup.value.send
                        "
                        [formGeneratorInput]="emailContentFormGenInputs"
                        (formGroupRef)="emailContentFormGroup = $event"
                    ></app-form-generator>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="tw-mt-auto tw-mb-3">
        <app-multi-button-generator
            [buttonGenInputs]="buttonGenInputs"
            [options]="buttonOptions"
        ></app-multi-button-generator>
    </div>
</form>
