import * as am4core from '@amcharts/amcharts4/core';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { GraphType } from 'src/app/shared/enums/GraphType';
import { IconType } from 'src/app/shared/enums/IconType';
import { SanitizeTypes } from 'src/app/shared/enums/SanitizeTypes';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import { ITimeSeriesApiResponse } from 'src/app/shared/interfaces/api/portlets/ITimeSeriesApiResponse';
import { IGraphData } from 'src/app/shared/interfaces/graph/IGraphData';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IColumnData } from 'src/app/shared/interfaces/table-generator/IColumnData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { Linking } from '../../../../classes/Linking';
import { ILineSeriesData } from '../../../../interfaces/graph/IGraphData';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';

@Component({
    selector: 'app-timeseries',
    templateUrl: './timeseries.component.html',
    styleUrls: ['./timeseries.component.sass']
})
export class TimeseriesComponent implements OnInit, AfterViewInit {
    // TimeSeries: 2.0

    @ViewChild('agGrid', { static: false }) agGrid: GridOptions;
    graphChartData: IGraphData;
    ViewType = ViewType;
    SanitizeTypes = SanitizeTypes;
    widgetRef: Widget;
    currencySymbol: string;
    keys: any;
    filteredSearchData: any[];
    tableKeys = [];
    showGraphOverlay: boolean;
    graphDisclaimerText: string;
    aggregateType: AggregateType;
    AggregateType = AggregateType;
    limit;
    rowData: any[];
    colDefs: any[];
    defaultColDef = {
        editable: false,
        sortable: true,
        filter: true,
        resizable: true
    };
    getRowStyle;
    drilldownWidgetRefList = [];
    drillDownTitleList = [];
    drillDownFilterObject = {};
    filtersStorageObject = {};
    gridApi;
    gridColumnApi;
    agGridIcons = Helper.getAgGridIcons();
    arrowRightIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-chevron-right small-icon'
    };
    tempDrilldownFilterObject = {};
    tempInputData = {};

    tableInputData: ITableGeneratorInput = {
        listExtraction: {
            type: 'DIRECT'
        },
        columns: [],
        tableHeight: 300
    };
    drillDownColor;
    constructor(
        widgetData: WidgetInjectedData,
        private globalDataService: GlobalDataService,
        public modalService: ModalService
    ) {
        this.widgetRef = widgetData.widgetRef;
        this.widgetRef.viewRestriction = true;
        this.initializeGraphColors();
    }

    ngOnInit(): void {
        this.setUpBasics();
        if (this.widgetRef.lightState) {
            this.setUpRowClassRules();
        }
    }
    initializeGraphColors() {
        this.drillDownColor = Helper.convertToHexIfHsl(
            Helper.getCssVarValue('drillDownColor')
        );
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridColumnApi.autoSizeColumns(this.tableKeys);
    }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(true);

        // Setting Visible Sections
        this.setVisibleSections();
        // Setting Aggrid Row styling
        this.getRowStyle = this.rowStyling.bind(this);
    }

    prepareTableData(data) {
        this.tableInputData.columns = [];

        this.colDefs.forEach((col) => {
            const columnObj: IColumnData = {
                columnName: col.headerName,
                columnKey: col.field,
                pinned: col.pinned
            };
            if (
                data['tableCurrencySymbolToShow'] &&
                data['tableCurrencySymbolToShow'].includes(col.headerName)
            ) {
                columnObj['columnValueGetter'] =
                    this.customValueGetter.bind(this);
                columnObj['cellRenderer'] = this.columnRenderer.bind(this);
            } else {
                columnObj['cellRenderer'] =
                    this.columnRendererWithoutCurrency.bind(this);
            }
            this.tableInputData.columns.push(columnObj);
        });
        this.tableInputData.columns[this.tableInputData.columns.length - 1][
            'pinned'
        ] = 'right';
        this.tableInputData.columns[this.tableInputData.columns.length - 1][
            'cellStyle'
        ] = {
            'font-weight': 'bold'
        };
    }
    getFullAccess() {
        Helper.getFullAccess(
            this.modalService,
            this.widgetRef.widgetData.widgetInfo.portlet.type
        );
    }

    ngAfterViewInit(): void {
        this.widgetRef.isBindDataAssigned = false;
        this.widgetRef.setBindData(this.bindData.bind(this));
        this.widgetRef.setRedrawSection(this.bindData.bind(this));
    }
    setUpRowClassRules() {
        this.defaultColDef.sortable = false;
        this.defaultColDef.filter = false;
        Helper.setRowClassRules(this.widgetRef);
    }

    setVisibleSections() {
        const visibleSections: Set<ViewType> = new Set();
        if (
            this.widgetRef.widgetData.widgetInfo.defaultPref &&
            this.widgetRef.widgetData.widgetInfo.defaultPref !== ViewType.BOTH
        ) {
            visibleSections.add(
                this.widgetRef.widgetData.widgetInfo.defaultPref
            );
        } else if (
            this.widgetRef.widgetData.widgetInfo.defaultPref &&
            this.widgetRef.widgetData.widgetInfo.defaultPref === ViewType.BOTH
        ) {
            visibleSections.add(ViewType.TABLE);
            visibleSections.add(ViewType.GRAPH);
        } else {
            visibleSections.add(ViewType.GRAPH);
        }
        visibleSections.add(ViewType.LEGEND);
        this.widgetRef.visibleSections.next(visibleSections);
    }

    bindData(
        data: ITimeSeriesApiResponse,
        visibleSectionEvaluated = false
    ): void {
        if (!data) {
            return;
        }
        this.widgetRef.apiResponse = data;
        this.aggregateType = data.dataMap['aggregateType'];
        this.rowData = [];
        this.filteredSearchData = [];
        this.colDefs = [];

        if (data.dataMap && !Object.keys(data.dataMap).length) {
            this.widgetRef.extraMessage.next(Messages.NO_DATA_AVAILABLE);
            this.widgetRef.visibleSections.next(new Set());
            this.widgetRef.widgetActions.next([]);
            this.widgetRef.loadingData.next(false);
            return;
        }

        if (!this.widgetRef.visibleSections.value.size) {
            this.setVisibleSections();
        }

        let widgetActions: IAction[];
        if (
            this.widgetRef.visibleSections.value.has(ViewType.GRAPH) ||
            this.widgetRef.visibleSections.value.has(ViewType.STACKED_BAR_GRAPH)
        ) {
            widgetActions = [
                this.widgetRef.widgetConfigState.actions.lineGraph,
                this.widgetRef.widgetConfigState.actions.table,
                this.widgetRef.widgetConfigState.actions.stackedBarGraph,
                this.widgetRef.widgetConfigState.actions.legend
            ];
        } else {
            widgetActions = [
                this.widgetRef.widgetConfigState.actions.lineGraph,
                this.widgetRef.widgetConfigState.actions.table,
                this.widgetRef.widgetConfigState.actions.stackedBarGraph
            ];
        }

        if (data.dataMap['tableKeys']) {
            this.tableKeys = data.dataMap['tableKeys'];
        } else if (data.dataMap['table'] && data.dataMap['table'].length) {
            this.tableKeys = Object.keys(data.dataMap['table'][0]);
        }

        if (
            data.dataMap[data.dataMap['graphKeyToBeUsed']].length > 72 ||
            data.dataMap['keys'].length > 70
        ) {
            widgetActions = [
                this.widgetRef.widgetConfigState.actions.lineGraph,
                this.widgetRef.widgetConfigState.actions.table,
                this.widgetRef.widgetConfigState.actions.stackedBarGraph
            ];
            this.showGraphOverlay = true;
            this.graphDisclaimerText = '';
            if (data.dataMap[data.dataMap['graphKeyToBeUsed']].length > 72) {
                this.graphDisclaimerText =
                    'The time series graph displays a maximum data of 72 data lines';
            } else if (data.dataMap['keys'].length > 70) {
                this.graphDisclaimerText =
                    'To maintain visual clarity, legends for time series graph are limited to 70.';
            }
            // let visibleSections: Set<ViewType> = new Set();
            // visibleSections.add(ViewType.GRAPH);
            // this.widgetRef.visibleSections.next(visibleSections);
        } else {
            this.showGraphOverlay = false;
        }
        // else {
        //     if (
        //         this.widgetRef.widgetData.widgetInfo.defaultPref &&
        //         this.widgetRef.widgetData.widgetInfo.defaultPref ===
        //             ViewType.TABLE
        //     ) {
        //         // let visibleSections: Set<ViewType> = new Set();
        //         // visibleSections.add(ViewType.TABLE);
        //         // this.widgetRef.visibleSections.next(visibleSections);
        //     }
        //     this.showGraphOverlay = false;
        // }
        this.widgetRef.widgetActions.next(widgetActions);

        this.prepareLineGraph(data.dataMap);
        this.drawTable(data.dataMap);
        this.widgetRef.endLoader();
    }

    prepareLineGraph(chartData) {
        const response = Helper.cloneDeep(chartData);
        const filteredChartData: ILineSeriesData[] = (
            response[response['graphKeyToBeUsed']] as any[]
        ).map((data) => {
            data.category = data['Date'];
            delete data.Date;
            return data;
        });

        const data: IGraphData = {
            graphType: this.widgetRef.visibleSections.value.has(
                ViewType.STACKED_BAR_GRAPH
            )
                ? GraphType.STACK_BAR
                : GraphType.LINE,
            liteState: this.widgetRef.lightState,
            visibleSections: this.widgetRef.visibleSections,
            labelText: {
                categoryX: 'category',
                xAxesTitle: 'Date',
                yAxesTitle: response['graphKeyToBeUsed']
            },
            categoryAxisRotationAngle: 90,
            chartData: this.showGraphOverlay
                ? filteredChartData.slice(0, 5)
                : filteredChartData,
            seriesList: this.showGraphOverlay
                ? response['keys'].slice(0, 5)
                : this.widgetRef.visibleSections.value.has(
                      ViewType.STACKED_BAR_GRAPH
                  )
                ? response['keys'].filter(
                      (item: string) => item.toLowerCase() !== 'total'
                  )
                : response['keys'],
            tooltiptext: (chart, legendKeys) => {
                return `${legendKeys}: ${this.currencySymbol}{valueY}`;
            },
            customizeSeries: (seriesValue, series) => {
                if (
                    this.widgetRef.widgetLinkingData &&
                    this.widgetRef.widgetData.linking
                ) {
                    if (
                        this.widgetRef.widgetData.linking['drillDownType'] ===
                        'column'
                    ) {
                        if (seriesValue.toLowerCase() === 'total') {
                            series.legendSettings.labelText = '[bold]{name}[/]';
                        } else {
                            series.legendSettings.labelText = `[${this.drillDownColor}]{name}[/]`;
                        }
                    } else if (
                        this.widgetRef.widgetData.linking['drillDownType'] ===
                        'row'
                    ) {
                        if (
                            this.widgetRef.widgetData.linking &&
                            this.widgetRef.widgetData.linking['legendRow'] &&
                            this.widgetRef.widgetData.linking['legendRow'][
                                seriesValue
                            ]
                        ) {
                            series.legendSettings.labelText = `[${this.drillDownColor}]{name}[/]`;
                        }
                    }
                } else if (seriesValue.toLowerCase() === 'total') {
                    series.legendSettings.labelText = '[bold]{name}[/]';
                }
            },
            onClicklegend: (event) => {
                // Custom logic
                let clickedLabelText = event.target.currentText;
                clickedLabelText = clickedLabelText.replace('[bold]', '');
                clickedLabelText = clickedLabelText.replace(
                    `[${this.drillDownColor}]`,
                    ''
                );
                clickedLabelText = clickedLabelText.replace('[/]', '');
                this.tableRowClicked(clickedLabelText);
            },
            onHoverlegend: (event) => {
                // Custom logic
                let hoveredLabelText = event.target.currentText;
                hoveredLabelText = hoveredLabelText.replace('[bold]', '');
                hoveredLabelText = hoveredLabelText.replace(
                    `[${this.drillDownColor}]`,
                    ''
                );
                hoveredLabelText = hoveredLabelText.replace('[/]', '');
                if (
                    this.widgetRef.widgetLinkingData &&
                    this.widgetRef.widgetData.linking
                ) {
                    if (
                        this.widgetRef.widgetData.linking['drillDownType'] ===
                        'column'
                    ) {
                        if (
                            hoveredLabelText &&
                            hoveredLabelText.toLowerCase() !== 'total'
                        ) {
                            event.target.cursorOverStyle =
                                am4core.MouseCursorStyle.pointer;
                        }
                    } else if (
                        this.widgetRef.widgetData.linking['drillDownType'] ===
                        'row'
                    ) {
                        if (
                            this.widgetRef.widgetData.linking &&
                            this.widgetRef.widgetData.linking['legendRow'] &&
                            this.widgetRef.widgetData.linking['legendRow'][
                                hoveredLabelText
                            ]
                        ) {
                            event.target.cursorOverStyle =
                                am4core.MouseCursorStyle.pointer;
                        }
                    }
                }
            }
        };
        this.graphChartData = data;
    }

    drawTable(data) {
        this.colDefs = [];
        this.tableKeys.forEach((row, index) => {
            const obj = {
                headerName: row,
                field: row
            };
            if (index === 0) {
                obj['pinned'] = 'left';
                obj['lockPinned'] = true;
                obj['lockPosition'] = true;
            }

            if (row.toLowerCase() === 'total') {
                obj['cellStyle'] = {
                    'font-weight': 'bold'
                };
            }

            if (
                data['tableCurrencySymbolToShow'] &&
                data['tableCurrencySymbolToShow'].includes(row)
            ) {
                obj['columnValueGetter'] = this.customValueGetter.bind(this);
                obj['cellRenderer'] = this.columnRenderer.bind(this);
            } else {
                obj['cellRenderer'] =
                    this.columnRendererWithoutCurrency.bind(this);
            }
            this.colDefs.push(obj);
        });

        this.currencySymbol = data['currencySymbol'];
        this.rowData = data['table'];
        this.filteredSearchData = this.rowData;
        this.prepareTableData(data);
    }

    customValueGetter(row) {
        return Helper.parseCommaSeperatedStringToNumber(
            row.data[row.colDef.field]
        );
    }

    columnRenderer(item) {
        const itemValue = item.data[item.colDef.field];
        if (itemValue === null || itemValue === undefined) {
            return '-';
        } else {
            return this.currencySymbol
                ? this.currencySymbol + ' ' + itemValue
                : itemValue;
        }
    }

    columnRendererWithoutCurrency(item) {
        if (item.value === null || item.value === undefined) {
            return '-';
        } else {
            return item.value;
        }
    }

    switchToTableView() {
        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);
    }

    onQuickFilterChanged(id: string) {
        this.agGrid.api.setQuickFilter(document.getElementById(id)['value']);
    }

    rowStyling(data) {
        if (
            this.widgetRef.widgetLinkingData &&
            this.widgetRef.widgetData &&
            this.widgetRef.widgetData.linking
        ) {
            if (
                this.widgetRef.widgetData.linking['drillDownType'] === 'column'
            ) {
                if (
                    data['data'][this.tableKeys[0]] &&
                    data['data'][this.tableKeys[0]].toLowerCase() === 'total'
                ) {
                    return { fontWeight: 'bold' };
                } else {
                    return {
                        cursor: 'pointer',
                        color: this.drillDownColor + ' !important'
                    };
                }
            } else if (
                this.widgetRef.widgetData.linking['drillDownType'] === 'row'
            ) {
                if (
                    this.widgetRef.widgetData.linking &&
                    this.widgetRef.widgetData.linking['legendRow'] &&
                    this.widgetRef.widgetData.linking['legendRow'][
                        data['data'][this.tableKeys[0]]
                    ]
                ) {
                    return {
                        cursor: 'pointer',
                        color: this.drillDownColor + ' !important'
                    };
                } else if (
                    data['data'][this.tableKeys[0]] &&
                    data['data'][this.tableKeys[0]].toLowerCase() === 'total'
                ) {
                    return { fontWeight: 'bold' };
                }
            }
        } else if (
            data['data'][this.tableKeys[0]] &&
            data['data'][this.tableKeys[0]].toLowerCase() === 'total'
        ) {
            return { fontWeight: 'bold' };
        }
        return '';
    }

    async tableRowClicked(slice) {
        if (
            this.widgetRef.widgetLinkingData &&
            this.widgetRef.widgetData.linking &&
            (this.widgetRef.widgetData.linking['legendRow'] ||
                this.widgetRef.widgetData.linking['legendColumn'])
        ) {
            //this.prevDefaultPref = this.defaultPref;
            const tempObj = Linking.prepareNextWidgetData(
                this.widgetRef,
                slice,
                this.tableKeys
            );
            if (tempObj) {
                tempObj['viewId'] = this.widgetRef.widgetLinkingData.viewId;
                tempObj[`widgetConfig`] = this.widgetRef.widgetConfigDetails;
                const currentLevel =
                    this.widgetRef.compareViewService.currentLevel[
                        this.widgetRef.widgetLinkingData.viewId
                    ] + 1;
                this.widgetRef.compareViewService.currentLevel[
                    this.widgetRef.widgetLinkingData.viewId
                ] = currentLevel;
                this.widgetRef.compareViewService.nextWidgetView.next(tempObj);
            }
        }
    }

    goBackDrillDown(home?, index?, isLast?) {
        if (isLast) {
            return;
        }
        if (home) {
            this.widgetRef.compareViewService.currentLevel[
                this.widgetRef.widgetLinkingData.viewId
            ] = 1;
        } else {
            const currentLevel =
                this.widgetRef.compareViewService.currentLevel[
                    this.widgetRef.widgetLinkingData.viewId
                ] - index;
            this.widgetRef.compareViewService.currentLevel[
                this.widgetRef.widgetLinkingData.viewId
            ] = currentLevel;
        }

        const tempObj = {};
        tempObj[this.widgetRef.widgetLinkingData.viewId] = true;
        this.widgetRef.compareViewService.previousWidgetView.next(tempObj);
    }
}
enum AggregateType {
    MONTHS = 'months',
    YEARS = 'years'
}
