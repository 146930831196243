import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { UpdateAction } from 'src/app/shared/enums/UpdateAction';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IUpdateAction } from 'src/app/shared/interfaces/update-action/IUpdateAction';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import {
    WidgetCreationActivityType,
    WidgetCreationService
} from 'src/app/shared/services/widget-creation/widget-creation.service';
import {
    FieldConfig,
    terraFormConfiguration
} from '../../../form-builder/form-json';
import { VariableFieldType } from './../../../../enums/VariableFieldType';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { NotificationsService } from './../../../../services/notifications/notifications.service';

@Component({
    selector: 'app-terraform-request-form-modal',
    templateUrl: './terraform-request-form-modal.component.html',
    styleUrls: ['./terraform-request-form-modal.component.sass']
})
export class TerraformRequestFormModalComponent implements OnInit, OnDestroy {
    LoaderType = LoaderType;
    configurationForms: any;
    widgetRef: Widget;
    finalForm = [];
    widgetId: any;
    configureFormInput: IFormGeneratorInput = null;
    editIcon: IIcon = {
        type: IconType.SVG,
        class: 'edit_filled',
        extraClass: 'svg-white-fill'
    };
    configureFormGroupRef: FormGroup = null;
    formName: string;
    editFormName: boolean = false;
    titleFormInput: IFormGeneratorInput = null;
    titleFormGroupRef: FormGroup = null;
    pencilIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-pencil-alt',
        extraClass: 'pencil-icon'
    };
    formField: any;
    activeConfigureFormName: string;
    activeConfigureFormIndex: any;
    plusIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-plus'
    };
    minusIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-minus'
    };
    mapResourceNameFormGenInputs: IFormGeneratorInput[];
    mapResourceNameFormGroup: Map<Symbol, FormGroup> = new Map();
    mapKeyValFormGenInput: IFormGeneratorInput[] = [];
    mapFormGroup: Map<Symbol, FormGroup> = new Map();
    objectFormGenInputs: IFormGeneratorInput;
    objectKeyValFormGenInput: IFormGeneratorInput[];
    objectFormGroup: FormGroup;
    subFormDatas: SubForm;
    resourceMap = [];
    fieldNameTypeMap: Map<string, []> = new Map();
    variableType: string;
    variableFieldType = VariableFieldType;
    showField: boolean = false;
    requiredFieldFormGenInputs: IFormGeneratorInput;
    requiredFieldFormGroup: FormGroup;
    variablesValue: Map<string, any> = new Map();
    variablesFieldValue: Map<string, any> = new Map();
    variablesField = [];
    stepData: any;
    errorMessage: Map<Symbol, boolean> = new Map<Symbol, boolean>();
    previousStepData: any;
    stepOneData: any;
    deleteUploadedFilesCallBackFn: any;
    deleteUploadedFile: boolean = true;
    editLoader: Map<Symbol, boolean> = new Map<Symbol, boolean>();
    editResponse: any;
    actionButtonGenInput: IButtonGeneratorInput[];
    saveButtonGenInput: IButtonGeneratorInput;
    cancelButtonGenInput: IButtonGeneratorInput;
    backButtonGenInput: IButtonGeneratorInput;
    activeConfigureField: Map<Symbol, boolean> = new Map<Symbol, boolean>();
    resetModal: Subscription;
    constructor(
        public modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        private widgetCreationService: WidgetCreationService,
        private modalService: ModalService,
        private notificationService: NotificationsService
    ) {}

    ngOnInit(): void {
        this.configurationForms = Helper.cloneDeep(terraFormConfiguration);
        this.widgetRef = this.modalInputData.data['widgetRef'];
        this.widgetId = this.modalInputData.data['widgetId'];
        this.editResponse = this.modalInputData.data['response'];
        if (this.editResponse && this.editResponse['form']) {
            this.formName = this.editResponse['form']['formName'];
        }
        this.initTitleForm();
        this.stepOneData = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(1);
        this.previousStepData = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(2);

        this.stepData = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(3);

        if (
            this.previousStepData &&
            this.previousStepData['deleteFilesCallBackFn']
        ) {
            this.deleteUploadedFilesCallBackFn =
                this.previousStepData['deleteFilesCallBackFn'];
        }
        if (
            this.stepData &&
            this.stepData['currentStepData'] &&
            (this.stepData['currentStepData']['finalForm'] ||
                this.stepData['currentStepData']['variablesFieldValue'] ||
                this.stepData['currentStepData']['variablesField'])
        ) {
            this.finalForm = this.stepData['currentStepData']['finalForm'];
            this.variablesFieldValue =
                this.stepData['currentStepData']['variablesFieldValue'];
            this.variablesValue =
                this.stepData['currentStepData']['variablesValue'];
            this.variablesField =
                this.stepData['currentStepData']['variablesField'];
        } else {
            this.formField = Helper.cloneDeep(this.stepData);
            this.parseAllVariable();
        }
        this.requiredFieldFormGenInputs = {
            formId: Symbol(),
            formName: '',
            submitButton: null,
            state: FormState.EDIT,
            fields: [
                {
                    label: 'Make field required ?',
                    placeholder: 'Place Holder Value',
                    name: FieldConfig.REQUIRED_VALUE,
                    fieldType: FilterType.CHECKBOX,
                    required: false,
                    value: true,
                    extraClass: 'required-checkbox accent-checkbox'
                }
            ]
        };
        this.resetModal = this.modalService.resetModal.subscribe((data) => {
            if (
                this.multiStepFormService.stepData.has(
                    this.modalInputData.modalId
                ) &&
                this.multiStepFormService.stepData
                    .get(this.modalInputData.modalId)
                    .has(3) &&
                this.multiStepFormService.stepData
                    .get(this.modalInputData.modalId)
                    .get(3)
            ) {
                this.stepData = this.multiStepFormService.stepData
                    .get(this.modalInputData.modalId)
                    .get(3);
            }
            this.finalForm = [];
            this.variablesField = [];
            this.variablesFieldValue.clear();
            this.variablesValue.clear();
            this.formField = Helper.cloneDeep(this.stepData);
            this.parseAllVariable();
            if (this.editResponse && this.editResponse['form']) {
                this.formName = this.editResponse['form']['formName'];
            }
            if (this.titleFormGroupRef) {
                this.titleFormGroupRef.reset();
            }
        });
        this.generateButtons();
    }
    generateButtons() {
        this.actionButtonGenInput = [
            {
                buttonName: 'Map Variables',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                function: (buttonRef) => {
                    this.goToNextStep();
                },
                showLoader: false
            }
        ];
        if (this.finalForm && this.finalForm.length) {
            this.actionButtonGenInput.unshift({
                buttonName: 'Save as Draft',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                function: (buttonRef) => {
                    this.saveAsDraft(buttonRef);
                },
                showLoader: true
            });
        }
        this.backButtonGenInput = {
            buttonName: 'Back',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: (buttonRef) => {
                this.back();
            },
            showLoader: false
        };
        this.cancelButtonGenInput = {
            buttonName: 'Cancel',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.STROKED,
            function: null,
            showLoader: false
        };
        this.saveButtonGenInput = {
            buttonName: 'Save',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: null,
            showLoader: false
        };
    }
    parseAllVariable() {
        this.formField.forEach((field) => {
            const form = this.handleResponse(field);
            if (!field['defaultValue']) {
                field['fieldType'] = FilterType.TEXT;
                field['variableValue'] = field['value'];
                field['label'] = field['name'];
                field['disabled'] = true;
                field['placeholder'] = field['description']
                    ? field['description']
                    : field['placeHolder']
                    ? field['placeHolder']
                    : '';
                field['value'] = field['description']
                    ? field['description']
                    : field['placeHolder']
                    ? field['placeHolder']
                    : 'Place Holder';
                field['variableFieldType'] = this.getFieldType(field);
                field['configure'] = field['configure']
                    ? field['configure']
                    : false;

                this.finalForm.push(form);
            } else {
                const variableType = this.getFieldType(field);
                if (variableType === this.variableFieldType.NUMBER) {
                    field['fieldType'] = FilterType.NUMBER;
                } else if (variableType === this.variableFieldType.BOOLEAN) {
                    field['fieldType'] = FilterType.DROPDOWN_SINGLE;
                    field['listData'] = [
                        { id: true, label: 'TRUE' },
                        { id: false, label: 'FALSE' }
                    ];
                } else if (
                    variableType === this.variableFieldType.LIST_NUMBER ||
                    variableType === this.variableFieldType.LIST_STRING
                ) {
                    const listData = Helper.mapToDropdownData(
                        field['defaultValue']
                    );
                    field['listData'] = listData;
                    field['fieldType'] = FilterType.DROPDOWN_MULTIPLE;
                } else if (
                    variableType === this.variableFieldType.LIST_OBJECT
                ) {
                    field['objectListData'] = field['defaultValue'];
                    field['fieldType'] = FilterType.DROPDOWN_LIST_OBJECT;
                } else if (variableType === this.variableFieldType.MAP) {
                    const dropdownOptions = Object.keys(field['defaultValue']);
                    const listData = Helper.mapToDropdownData(dropdownOptions);
                    field['listData'] = listData;
                    field['fieldType'] = FilterType.DROPDOWN_MULTIPLE;
                } else {
                    field['fieldType'] = FilterType.TEXT;
                }
                field['label'] = field['name'];
                field['disabled'] = true;
                field['placeholder'] = field['description']
                    ? field['description']
                    : field['placeHolder']
                    ? field['placeHolder']
                    : '';
                field['variableFieldType'] = this.getFieldType(field);
                field['configure'] = true;
                this.variablesFieldValue.set(
                    field['name'],
                    field['defaultValue']
                );
            }
            field.hideFloatLabel = true;
            this.getFieldsValue(form.fields[0]);
            this.variablesField.push(form.fields[0]);
        });
    }
    addForm(formId) {
        if (this.checkFormValidation()) {
            return;
        }
        if (this.variablesValue.has(this.activeConfigureFormName)) {
            const existingValue = this.checkIfValuePresent(formId);
            if (
                !existingValue ||
                this.variableType === this.variableFieldType.LIST_OBJECT
            ) {
                if (this.variableType === this.variableFieldType.MAP) {
                    const obj = {};
                    const subFormValues = {};
                    const resourceName =
                        this.mapResourceNameFormGroup.get(formId).value[
                            'value'
                        ];

                    this.mapKeyValFormGenInput.forEach((form) => {
                        const subFormValue = this.mapFormGroup
                            .get(form.formId)
                            .getRawValue();
                        subFormValues[subFormValue['key']] =
                            subFormValue['value'];
                    });
                    obj[resourceName] = subFormValues;
                    this.variablesValue.get(this.activeConfigureFormName)[
                        resourceName
                    ] = subFormValues;
                } else if (
                    this.variableType === this.variableFieldType.LIST_OBJECT
                ) {
                    const subFormValues = {};
                    this.mapKeyValFormGenInput.forEach((form) => {
                        const subFormValue = this.mapFormGroup
                            .get(form.formId)
                            .getRawValue();
                        subFormValues[subFormValue['key']] =
                            subFormValue['value'];
                    });

                    this.variablesValue
                        .get(this.activeConfigureFormName)
                        .push(subFormValues);
                } else {
                    const listValue =
                        this.mapResourceNameFormGroup.get(formId).value[
                            'value'
                        ];
                    this.variablesValue
                        .get(this.activeConfigureFormName)
                        .push(listValue);
                }
            } else {
                this.notificationService.showSnackBar(
                    'Value Already Exist.',
                    true
                );
                return;
            }
        } else {
            if (this.variableType === this.variableFieldType.MAP) {
                const obj = {};
                const subFormValues = {};
                const resourceName =
                    this.mapResourceNameFormGroup.get(formId).value['value'];

                this.mapKeyValFormGenInput.forEach((form) => {
                    const subFormValue = this.mapFormGroup
                        .get(form.formId)
                        .getRawValue();
                    subFormValues[subFormValue['key']] = subFormValue['value'];
                });
                obj[resourceName] = subFormValues;
                this.variablesValue.set(this.activeConfigureFormName, obj);
            } else if (
                this.variableType === this.variableFieldType.LIST_OBJECT
            ) {
                const subFormValues = {};
                this.mapKeyValFormGenInput.forEach((form) => {
                    const subFormValue = this.mapFormGroup
                        .get(form.formId)
                        .getRawValue();
                    subFormValues[subFormValue['key']] = subFormValue['value'];
                });
                this.variablesValue.set(this.activeConfigureFormName, [
                    subFormValues
                ]);
            } else {
                const listValue =
                    this.mapResourceNameFormGroup.get(formId).value['value'];
                this.variablesValue.set(this.activeConfigureFormName, [
                    listValue
                ]);
            }
        }

        this.mapKeyValFormGenInput = [];
        this.initSubForm();
        const fieldType =
            this.variableType === VariableFieldType.LIST_NUMBER
                ? FilterType.NUMBER
                : FilterType.TEXT;
        this.subFormDatas = {
            id: Symbol(),
            variableName: this.activeConfigureFormName,
            variableType: this.variableType,
            resourceNameForm:
                this.variableType === VariableFieldType.LIST_OBJECT
                    ? null
                    : this.variableType === VariableFieldType.MAP
                    ? this.generateResourceName()
                    : this.generateResourceName(
                          fieldType,
                          'Enter Value',
                          'Value'
                      ),
            resourceKeyForms: this.mapKeyValFormGenInput
        };
        this.resourceMap.push(this.subFormDatas);
        this.disableVariableValue();
        this.mapResourceNameFormGroup.clear();
        this.mapFormGroup.clear();
    }
    disableVariableValue() {
        this.resourceMap.forEach((data, index) => {
            if (index !== this.resourceMap.length - 1) {
                if (data['resourceNameForm']) {
                    if (data['resourceNameForm']['state'] === FormState.EDIT) {
                        data['resourceNameForm']['state'] = FormState.IDLE;
                    }
                    Helper.dereference(data['resourceNameForm']);
                }
                if (data['resourceKeyForms']) {
                    data['resourceKeyForms'].forEach((form) => {
                        if (form['state'] === FormState.EDIT) {
                            form['state'] = FormState.IDLE;
                        }
                        return Helper.dereference(form);
                    });
                }
            }
        });
    }
    checkFormValidation(): boolean {
        let invalid: boolean = false;

        this.mapResourceNameFormGroup.forEach((fg, key, index) => {
            Helper.markAllFieldAsTouched(fg);
            if (fg.invalid) {
                fg.updateValueAndValidity();
                invalid = true;
                return;
            }
        });
        this.mapFormGroup.forEach((fg, index) => {
            Helper.markAllFieldAsTouched(fg);
            if (fg.invalid) {
                fg.updateValueAndValidity();
                invalid = true;
                return;
            }
        });
        return invalid;
    }
    checkIfValuePresent(formId): boolean {
        let valueAvailable = false;
        if (this.variableType !== this.variableFieldType.LIST_OBJECT) {
            const newValue =
                this.mapResourceNameFormGroup.get(formId).value['value'];
            const existingValue = this.variablesValue.get(
                this.activeConfigureFormName
            );
            if (this.variableType === this.variableFieldType.MAP) {
                const keys = Object.keys(existingValue);
                if (keys.includes(newValue)) {
                    valueAvailable = true;
                }
            } else if (existingValue && existingValue.includes(newValue)) {
                valueAvailable = true;
            }
        }

        return valueAvailable;
    }
    generateResourceName(
        formType?,
        placeholder?,
        label?,
        value?
    ): IFormGeneratorInput {
        return {
            formId: Symbol(),
            formName: '',
            submitButton: null,
            state: FormState.EDIT,
            fields: [
                {
                    name: 'value',
                    label: label ? label : 'Resource Name',
                    placeholder: placeholder ? placeholder : 'Resource Name',
                    fieldType: formType ? formType : FilterType.TEXT,
                    value: value ? value : null,
                    hideFloatLabel: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This field is required.'
                        }
                    ]
                }
            ]
        };
    }
    generateForm(element, fieldType, value?): IFormField[] {
        return [
            {
                name: 'key',
                label: element,
                placeholder: element,
                fieldType: FilterType.TEXT,
                value: element,
                disabled: true,
                hideFloatLabel: true
            },
            {
                name: 'value',
                label: element + 'Value',
                placeholder: element + 'Value',
                fieldType: this.getSubFieldType(fieldType),
                value: value ? value : '',
                hideFloatLabel: true,
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'This field is required.'
                    }
                ]
            }
        ];
    }
    getSubFieldType(type) {
        const fieldType = this.getType(type);
        switch (fieldType) {
            case VariableFieldType.STRING:
                return FilterType.TEXT;
            case VariableFieldType.NUMBER:
                return FilterType.NUMBER;
            case VariableFieldType.BOOLEAN:
                return FilterType.DROPDOWN_SINGLE;
            default:
                return FilterType.TEXT;
        }
    }
    getFieldType(field) {
        let variableType = field['variableType'];
        variableType = variableType.split(' ').join('').split('=')[1];
        return this.getType(variableType);
    }
    getType(type) {
        const variableType = type;
        switch (true) {
            case variableType.includes('list(string)'):
                return VariableFieldType.LIST_STRING;
            case variableType.includes('list(number)'):
                return VariableFieldType.LIST_NUMBER;
            case variableType.includes('list(object'):
                return VariableFieldType.LIST_OBJECT;
            case variableType.includes('list'):
                return VariableFieldType.LIST_STRING;
            case variableType.includes('string'):
                return VariableFieldType.STRING;
            case variableType.includes('map'):
                return VariableFieldType.MAP;
            case variableType.includes('number'):
                return VariableFieldType.NUMBER;
            case variableType.includes('boolean'):
                return VariableFieldType.BOOLEAN;
            case variableType.includes('object'):
                return VariableFieldType.OBJECT;
            default:
                return VariableFieldType.STRING;
        }
    }
    getFieldNameTypeMap(variableType) {
        const variableFieldsType = variableType.split(' ').join('');
        const from = variableFieldsType.indexOf('({') + 2;
        const to = variableFieldsType.indexOf('})');
        let subStr = variableFieldsType.substring(from, to);
        subStr = subStr.split(',');
        subStr.forEach((data) => {
            const x = data.split('=');
            this.fieldNameTypeMap.set(x[0], x[1]);
        });
    }
    initSubForm(obj?) {
        this.mapKeyValFormGenInput = [];
        const keys = Array.from(this.fieldNameTypeMap.keys());

        keys.forEach((subFieldName) => {
            const form: IFormGeneratorInput = {
                formId: Symbol(),
                formName: '',
                submitButton: null,
                state: FormState.EDIT,
                fields: this.generateForm(
                    subFieldName,
                    this.fieldNameTypeMap.get(subFieldName),
                    obj && obj[subFieldName] ? obj[subFieldName] : null
                )
            };
            this.mapKeyValFormGenInput.push(form);
        });
    }
    initTitleForm() {
        this.titleFormInput = {
            formName: 'titleForm',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Request Form Name',
                    placeholder: 'Request Form Name',
                    name: 'formName',
                    fieldType: FilterType.TEXT,
                    required: true,
                    value: this.formName ? this.formName : 'Request Form Name',
                    hideFloatLabel: true,
                    onFocusout: () => {
                        this.editFormName = false;
                        this.formName =
                            this.titleFormGroupRef &&
                            this.titleFormGroupRef.value
                                ? this.titleFormGroupRef.value.formName
                                : 'Request Form Name';
                    }
                }
            ]
        };
    }
    handleResponse(response): IFormGeneratorInput {
        return {
            formName: '',
            submitButton: null,
            state: FormState.CREATE,
            formId: Symbol(),
            fields: [response]
        };
    }
    saveAsDraft(buttonRef) {
        if (!this.checkIfFieldConfigure()) {
            return;
        }
        buttonRef.loader = true;
        const inputs = this.prepareDraftInput();
        this.widgetCreationService.saveAsDraft(
            inputs,
            (res) => {
                this.deleteUploadedFile = false;
                buttonRef.loader = false;
                this.modalService.closeModal(null, this.modalInputData.modalId);
            },
            (error) => {
                this.deleteUploadedFile = true;
                buttonRef.loader = false;
            },
            this.widgetId
        );
    }
    prepareDraftInput() {
        const input = { ...this.stepOneData };
        input['newKeys'] = this.previousStepData['newKeys'];
        input['extraInfo']['terraformCloud'] = this.previousStepData.cloud;
        input['extraInfo']['activityType'] =
            WidgetCreationActivityType.WIDGET_DRAFT;
        input['mainTfs'] = this.previousStepData['mainTfs'];
        input['variableFiles'] = this.previousStepData['variableFiles'];
        input['tfVarsFiles'] = this.previousStepData['tfVarsFiles']
            ? this.previousStepData['tfVarsFiles']
            : [];
        input['secretFiles'] = this.previousStepData['secretFiles']
            ? this.previousStepData['secretFiles']
            : [];
        input['configureLocation'] = this.previousStepData['configureLocation'];
        input['form'] = {
            formName: 'Request Form',
            submitButton: null,
            state: FormState.EDIT,
            fields: this.getFormFields()
        };
        return input;
    }
    back() {
        this.multiStepFormService.previousStep(this.modalInputData.modalId);
    }
    generateConfigurationForm(form: IFormGeneratorInput, index) {
        this.editLoader.set(form.formId, true);
        this.mapResourceNameFormGroup.clear();
        this.mapFormGroup.clear();
        this.fieldNameTypeMap.clear();
        this.resourceMap = [];
        this.mapKeyValFormGenInput = [];
        const finalFormField = form.fields[0];
        this.variableType = finalFormField['variableFieldType'];
        this.getFieldsValue(finalFormField);
        const configureForm = Helper.cloneDeep(
            this.configurationForms[form.fields[0].fieldType]
        );
        this.activeConfigureFormName = form.fields[0].name;
        this.activeConfigureFormIndex = index;

        if (
            finalFormField['variableFieldType'] === VariableFieldType.MAP ||
            finalFormField['variableFieldType'] ===
                VariableFieldType.LIST_OBJECT
        ) {
            this.getFieldNameTypeMap(finalFormField['variableType']);
            this.editVariableType(index);
            this.initSubForm();
            this.subFormDatas = {
                resourceNameForm:
                    finalFormField['variableFieldType'] ===
                    VariableFieldType.LIST_OBJECT
                        ? null
                        : this.generateResourceName(),
                resourceKeyForms: this.mapKeyValFormGenInput
            };
            this.resourceMap.push(this.subFormDatas);
        } else {
            this.editVariableType(index);
            const fieldType =
                this.variableType === VariableFieldType.LIST_NUMBER
                    ? FilterType.NUMBER
                    : FilterType.TEXT;
            this.subFormDatas = {
                resourceNameForm: this.generateResourceName(
                    fieldType,
                    'Enter Value',
                    'Value'
                ),
                resourceKeyForms: []
            };
            this.resourceMap.push(this.subFormDatas);
        }
        this.disableVariableValue();
        configureForm.fields = configureForm.fields.map(
            (each: IFormField, index) => {
                if (each.name === FieldConfig.LABEL_VALUE) {
                    each.value = finalFormField.label;
                }
                if (each.name === FieldConfig.PLACE_HOLDER_VALUE) {
                    each.value = finalFormField.placeholder;
                    each.disabled = finalFormField.placeholder ? true : false;
                }
                if (each.name === FieldConfig.SHOW_CASE_FIELD) {
                    each.listData = Helper.mapToDropdownData(
                        finalFormField['formFieldType']
                    );
                    each.value = finalFormField['selectedFieldType'];
                }
                if (
                    each.name === FieldConfig.STRING_FIELD_VALUE ||
                    each.name === FieldConfig.NUMBER_FIELD_VALUE ||
                    each.name === FieldConfig.BOOLEAN_FIELD_VALUE
                ) {
                    each.value = finalFormField['variableValue'];
                }
                each.hideFloatLabel = true;

                return each;
            }
        );
        this.configureFormInput = configureForm;
        this.activeConfigureField.set(form.formId, true);
        this.editLoader.set(form.formId, false);
        this.widgetRef.changeDetectorRef.detectChanges();
    }
    cancelConfigureForm(index) {
        this.configureFormInput = null;
        this.activeConfigureField.clear();
        this.mapResourceNameFormGroup.clear();
        this.mapFormGroup.clear();
        if (this.variablesFieldValue && this.variablesFieldValue.size) {
            let variableValue = this.variablesFieldValue.get(
                this.activeConfigureFormName
            );
            variableValue = Helper.cloneDeep(variableValue);
            this.variablesValue.set(
                this.activeConfigureFormName,
                variableValue
            );
        }
    }

    processConfigurationForm(index, formId) {
        if (this.configureFormGroupRef.invalid) {
            this.configureFormGroupRef.markAllAsTouched();
            return;
        }
        const configureValue = this.configureFormGroupRef.getRawValue();
        if (this.variableType === this.variableFieldType.MAP) {
            const variableValue = this.variablesValue.get(
                this.activeConfigureFormName
            );
            if (!variableValue) {
                this.validateForm();
                return;
            }
            const keys = Object.keys(variableValue);
            if (keys && !keys.length) {
                this.validateForm();
                return;
            }
        } else if (
            configureValue['showcaseField'] !==
                this.variableFieldType.BOOLEAN &&
            (this.variableType === this.variableFieldType.LIST_OBJECT ||
                this.variableType === this.variableFieldType.LIST_STRING ||
                this.variableType === this.variableFieldType.LIST_NUMBER ||
                this.variableType === this.variableFieldType.LIST ||
                configureValue['showcaseField'] ===
                    FilterType.DROPDOWN_SINGLE ||
                configureValue['showcaseField'] ===
                    FilterType.DROPDOWN_MULTIPLE)
        ) {
            const variableValue = this.variablesValue.get(
                this.activeConfigureFormName
            );
            if (!variableValue || (variableValue && !variableValue.length)) {
                this.validateForm();
                return;
            }
        }

        const valuesKeys = Object.keys(configureValue).filter((each) => {
            if (each.includes(FieldConfig.DROPDOWN_VALUE)) {
                return each;
            }
        });

        let listData = [];
        if (valuesKeys && valuesKeys.length) {
            listData = valuesKeys.map((each) => {
                return {
                    id: this.configureFormGroupRef.value[each],
                    label: this.configureFormGroupRef.value[each]
                };
            });
        }
        const fields: IFormField[] = [...this.finalForm[index].fields];
        fields[0].listData = listData ? listData : [];
        fields[0].label = configureValue[FieldConfig.LABEL_VALUE];
        fields[0].placeholder = configureValue[FieldConfig.PLACE_HOLDER_VALUE];
        fields[0]['variableValue'] =
            this.variableType === this.variableFieldType.STRING
                ? configureValue[FieldConfig.STRING_FIELD_VALUE]
                : this.variableType === this.variableFieldType.NUMBER
                ? configureValue[FieldConfig.NUMBER_FIELD_VALUE]
                : this.variableType === this.variableFieldType.BOOLEAN
                ? configureValue[FieldConfig.BOOLEAN_FIELD_VALUE]
                : '';
        fields[0].required =
            this.requiredFieldFormGroup.value[FieldConfig.REQUIRED_VALUE];
        fields[0]['configure'] = true;
        fields[0]['selectedFieldType'] =
            configureValue[FieldConfig.SHOW_CASE_FIELD];
        if (fields[0]['variableValue']) {
            fields[0].value = fields[0]['variableValue'];
        }
        if (
            this.variablesValue.has(this.activeConfigureFormName) &&
            this.variablesValue.get(this.activeConfigureFormName)
        ) {
            let variableValue = this.variablesValue.get(
                this.activeConfigureFormName
            );
            variableValue = Helper.cloneDeep(variableValue);
            if (this.variableType === this.variableFieldType.MAP) {
                this.variablesFieldValue.set(this.activeConfigureFormName, {
                    ...variableValue
                });
            } else {
                this.variablesFieldValue.set(this.activeConfigureFormName, [
                    ...variableValue
                ]);
            }
        }
        this.finalForm[index].fields = [];
        this.finalForm[index].fields = [...fields];
        const varIndex = this.variablesField.findIndex(
            (variable) => variable.name === fields[0].name
        );
        if (varIndex !== -1) {
            this.variablesField.splice(varIndex, 1, fields[0]);
        }

        const refreshControl: IUpdateAction = {
            action: UpdateAction.REFRESH,
            controls: [fields[0].name]
        };
        this.finalForm[index].updateControlSubject.next(refreshControl);
        this.errorMessage.set(formId, false);
        this.configureFormInput = null;
        this.activeConfigureField.clear();
        this.mapResourceNameFormGroup.clear();
        this.mapFormGroup.clear();
        this.widgetRef.changeDetectorRef.detectChanges();
    }
    getFieldsValue(field) {
        if (
            this.variableType === this.variableFieldType.MAP ||
            field['variableFieldType'] === this.variableFieldType.MAP
        ) {
            let mapObject = field['mapObject'];
            if (!this.variablesValue.has(field.name)) {
                mapObject = Helper.cloneDeep(mapObject);
                this.variablesValue.set(field.name, mapObject);
            }
            if (!this.variablesFieldValue.has(field.name)) {
                mapObject = Helper.cloneDeep(mapObject);
                this.variablesFieldValue.set(field.name, mapObject);
            }
        } else if (
            this.variableType === this.variableFieldType.LIST_OBJECT ||
            field['variableFieldType'] === this.variableFieldType.LIST_OBJECT
        ) {
            if (!this.variablesValue.has(field.name)) {
                const listData = Helper.cloneDeep(field.objectListData);
                this.variablesValue.set(field.name, listData);
            }
            if (!this.variablesFieldValue.has(field.name)) {
                const listData = Helper.cloneDeep(field.objectListData);
                this.variablesFieldValue.set(field.name, listData);
            }
        } else if (field && field.listData && field.listData.length) {
            if (!this.variablesValue.has(field.name)) {
                let listData = field.listData.map((item) => {
                    return item.id;
                });
                listData = Helper.cloneDeep(listData);
                this.variablesValue.set(field.name, listData);
            }
            if (!this.variablesFieldValue.has(field.name)) {
                let listData = field.listData.map((item) => {
                    return item.id;
                });
                listData = Helper.cloneDeep(listData);
                this.variablesFieldValue.set(field.name, listData);
            }
        }
    }
    validateForm() {
        this.notificationService.showSnackBar('Please add variable Value.');
        this.checkFormValidation();
    }
    valueChange(change) {
        if (
            change &&
            change['showcaseField'] &&
            (change['showcaseField'] === FilterType.DROPDOWN_SINGLE ||
                change['showcaseField'] === FilterType.DROPDOWN_MULTIPLE) &&
            this.variableFieldType &&
            this.variableType !== this.variableFieldType.MAP &&
            this.variableType !== this.variableFieldType.LIST_OBJECT
        ) {
            this.showField = true;
        } else {
            this.showField = false;
        }
    }
    editVariableType(index) {
        if (this.variablesFieldValue.has(this.activeConfigureFormName)) {
            if (this.variableType === this.variableFieldType.MAP) {
                const resourceNames = Object.keys(
                    this.variablesFieldValue.get(this.activeConfigureFormName)
                );
                resourceNames.forEach((resourceNameValue) => {
                    this.mapKeyValFormGenInput = [];
                    const subFields = this.variablesFieldValue.get(
                        this.activeConfigureFormName
                    );
                    this.initSubForm(subFields[resourceNameValue]);
                    this.subFormDatas = {
                        resourceNameForm: this.generateResourceName(
                            FilterType.TEXT,
                            '',
                            null,
                            resourceNameValue
                        ),
                        resourceKeyForms: this.mapKeyValFormGenInput
                    };
                    this.resourceMap.push(this.subFormDatas);
                });
            } else if (
                this.variableType === this.variableFieldType.LIST_OBJECT
            ) {
                const subFields = this.variablesFieldValue.get(
                    this.activeConfigureFormName
                );
                subFields.forEach((data) => {
                    this.initSubForm(data);
                    this.subFormDatas = {
                        resourceNameForm: null,
                        resourceKeyForms: this.mapKeyValFormGenInput
                    };

                    this.resourceMap.push(this.subFormDatas);
                });
            } else {
                const listData = this.variablesFieldValue.get(
                    this.activeConfigureFormName
                );
                const fieldType =
                    this.variableType === VariableFieldType.LIST_NUMBER
                        ? FilterType.NUMBER
                        : FilterType.TEXT;
                listData.forEach((value) => {
                    this.subFormDatas = {
                        resourceNameForm: this.generateResourceName(
                            fieldType,
                            'Enter Value',
                            'Value',
                            value
                        ),
                        resourceKeyForms: null
                    };
                    this.resourceMap.push(this.subFormDatas);
                });
            }
        }
    }
    goToNextStep() {
        if (this.checkIfFieldConfigure()) {
            this.generateRequestFormFields();
            this.deleteUploadedFile = false;
        }
    }
    checkIfFieldConfigure(): boolean {
        let configured: boolean = true;
        this.finalForm.forEach((form: IFormGeneratorInput) => {
            if (!form.fields[0]['configure']) {
                configured = false;
                this.errorMessage.set(form.formId, true);
            } else {
                this.errorMessage.set(form.formId, false);
            }
        });
        return configured;
    }
    generateRequestFormFields() {
        const formFields = this.getFormFields();
        const stepData = this.prepareCurrentStepData();
        this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .set(3, { currentStepData: stepData, formFields: formFields });

        this.multiStepFormService.nextStep(this.modalInputData.modalId);
    }
    getFormFields() {
        const formFields = [];

        this.variablesField.forEach((field) => {
            if (field['configure']) {
                const formField = {};
                formField['name'] = field.name;
                formField['placeHolder'] = field.placeholder;
                formField['fieldType'] = field.selectedFieldType
                    ? field.selectedFieldType
                    : field.fieldType;
                formField['varId'] = field.varId;
                formField['defaultValue'] = field.defaultValue;
                formField['defaultVariableValue'] = field.defaultValue
                    ? true
                    : false;
                formField['required'] = field.required;
                formField['value'] = field.variableValue;
                formField['label'] = field.label;
                formField['variableFieldType'] = field.variableFieldType;
                formField['formFieldType'] = field.formFieldType;
                formField['variableType'] = field.variableType;
                formField['selectedFieldType'] = field.selectedFieldType;
                formField['configure'] = field['configure'];
                formField['mappingType'] = field['mappingType'];
                if (
                    field.variableFieldType ===
                        this.variableFieldType.LIST_NUMBER ||
                    field.variableFieldType ===
                        this.variableFieldType.LIST_STRING
                ) {
                    const dropdownOptions = this.variablesFieldValue.get(
                        field.name
                    );
                    const listData = Helper.mapToDropdownData(dropdownOptions);
                    formField['listData'] =
                        listData && listData.length ? listData : field.listData;
                } else if (
                    field.variableFieldType ===
                    this.variableFieldType.LIST_OBJECT
                ) {
                    formField['fieldType'] = FilterType.DROPDOWN_LIST_OBJECT;
                    formField['objectListData'] = this.variablesFieldValue.has(
                        field.name
                    )
                        ? this.variablesFieldValue.get(field.name)
                        : field['objectListData'];
                } else if (
                    field.variableFieldType === this.variableFieldType.MAP
                ) {
                    const fieldMapObject = this.variablesFieldValue.has(
                        field.name
                    )
                        ? this.variablesFieldValue.get(field.name)
                        : field['mapObject'];
                    const dropdownOptions = Object.keys(fieldMapObject);
                    const listData = Helper.mapToDropdownData(dropdownOptions);
                    formField['mapObject'] = fieldMapObject;
                    formField['listData'] = listData;
                } else if (
                    field.VariableFieldType === this.variableFieldType.BOOLEAN
                ) {
                    formField['fieldType'] = FilterType.DROPDOWN_SINGLE;
                    formField['listData'] = [
                        { id: true, label: 'TRUE' },
                        { id: false, label: 'FALSE' }
                    ];
                } else if (
                    (field.selectedFieldType === FilterType.DROPDOWN_SINGLE ||
                        field.selectedFieldType ===
                            FilterType.DROPDOWN_MULTIPLE) &&
                    (field.variableFieldType ===
                        this.variableFieldType.NUMBER ||
                        field.variableFieldType ===
                            this.variableFieldType.STRING)
                ) {
                    const dropdownOptions = this.variablesFieldValue.get(
                        field.name
                    );
                    const listData = Helper.mapToDropdownData(dropdownOptions);
                    formField['listData'] =
                        listData && listData.length ? listData : field.listData;
                }
                formFields.push(formField);
            }
        });

        return formFields;
    }
    prepareCurrentStepData() {
        const stepData = {};
        stepData['variablesValue'] = new Map(this.variablesValue);
        stepData['variablesFieldValue'] = new Map(this.variablesFieldValue);
        stepData['finalForm'] = [...this.finalForm];
        stepData['variablesField'] = [...this.variablesField];
        return stepData;
    }
    deleteValue(id, index) {
        this.resourceMap.splice(index, 1);
        this.mapResourceNameFormGroup.delete(id);
        if (this.variableType === this.variableFieldType.MAP) {
            const keys = Object.keys(
                this.variablesValue.get(this.activeConfigureFormName)
            );
            const mapObject = this.variablesValue.get(
                this.activeConfigureFormName
            );
            delete mapObject[keys[index]];
        } else {
            this.variablesValue
                .get(this.activeConfigureFormName)
                .splice(index, 1);
        }
    }
    ngOnDestroy() {
        if (this.deleteUploadedFile) {
            this.deleteUploadedFilesCallBackFn(true);
        }
        this.resetModal.unsubscribe();
    }
}
export interface SubForm {
    id?: Symbol;
    variableName?: string;
    variableType?: string;
    resourceNameForm: any;
    resourceKeyForms: IFormGeneratorInput[];
}
export enum LoaderType {
    SAVE_AS_DRAFT = 'SAVE_AS_DRAFT',
    SAVE = 'SAVE'
}
