import { OverlayRef } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    HostBinding,
    Inject,
    Injector,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { BehaviorSubject } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { ActionState } from 'src/app/shared/enums/ActionState';
import { getLogoPathOfCloudLabel } from 'src/app/shared/enums/CloudLabel';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { environment } from 'src/environments/environment';
import { CarouselInjectedData } from '../../classes/CarouselInjectedData';
import { HitApi } from '../../classes/HitApi';
import { Messages } from '../../classes/Messages';
import { Widget } from '../../classes/Widget';
import { WidgetConfig } from '../../classes/WidgetConfig';
import { WidgetInjectedData } from '../../classes/WidgetInjectedData';
import { WidgetTypeMap } from '../../classes/WidgetTypeMap';
import { AuthorizationType } from '../../enums/AuthorizationType';
import { ButtonColorType } from '../../enums/ButtonColorType';
import { ButtonType } from '../../enums/ButtonType';
import { FilterStoreKey } from '../../enums/FilterStoreKey';
import { IconType } from '../../enums/IconType';
import { ModalType } from '../../enums/ModalType';
import { PortletType } from '../../enums/PortletType';
import { RequestType } from '../../enums/RequestType';
import { State } from '../../enums/State';
import { IButtonGeneratorInput } from '../../interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from '../../interfaces/hit-api/IHitApi';
import { IIcon } from '../../interfaces/icon-data/IIcon';
import { IConfirmationModal } from '../../interfaces/modal/IConfirmationModal';
import { IModalData } from '../../interfaces/modal/IModalData';
import { ILinkingData } from '../../interfaces/widget/ILinkingData';
import { IWidgetConfig } from '../../interfaces/widget/IWidgetConfig';
import { IWidgetData } from '../../interfaces/widget/IWidgetData';
import { DocumentTypes } from '../../interfaces/widget/IWidgetDescription';
import { CartCacheService } from '../../services/cache/cart-cache/cart-cache.service';
import { CompareViewService } from '../../services/compare-view/compare-view.service';
import { FiltersService } from '../../services/filters/filters.service';
import { GlobalDataService } from '../../services/global-data/global-data.service';
import { HttpService } from '../../services/http/http-main/http.service';
import { WidgetHttpService } from '../../services/http/widget-http/widget-http.service';
import { ModalService } from '../../services/modal/modal-service/modal.service';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { UserDataCacheService } from '../../services/user-data-cache/user-data-cache.service';
import { AttentionRequiredAccordionComponent } from '../attention-required-accordion/attention-required-accordion.component';
import { Helper } from './../../classes/Helper';
import { MoreInfoModalComponent } from './../modal-templates/more-info-modal/more-info-modal/more-info-modal.component';

@Component({
    selector: 'app-widget-generator',
    templateUrl: './widget-generator.component.html',
    styleUrls: ['./widget-generator.component.sass']
})
export class WidgetGeneratorComponent
    extends Widget
    implements OnInit, OnDestroy
{
    @HostBinding('class.customStyled') customStyled: boolean = false;
    @HostBinding('class.integrationContainer')
    integrationContainer: boolean = false;

    @HostBinding('class.unset-full-height')
    unsetFullHeight: boolean = false;

    @ViewChild(MatMenuTrigger, { static: false })
    public menuTrigger: MatMenuTrigger;
    @ViewChild('confirmationModalTemplate')
    confirmationModalTemplate: TemplateRef<any>;
    @Input() data: IWidgetData;
    @Input() state: State;
    @Input() dataStoreKey: FilterStoreKey;
    @Input() linkingData: ILinkingData;
    @Input() widgetConfig: IWidgetConfig;
    @Input() widgetIndex?: Number;
    @Input() widgetTabsRef: TemplateRef<any>; // for grouped only

    widgetInjector: Injector;
    actionState = ActionState;

    menuToggle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    Messages = Messages;
    PortletType = PortletType;
    updatePosition = null;

    lockIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'lock'
    };

    crossIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-times'
    };
    attentionRequiredIcon: IIcon = {
        type: IconType.SVG,
        class: 'attention_required',
        extraClass: 'svg-warning-fill'
    };

    lineLoaders: IIcon = {
        type: IconType.LINELOADER,
        class: 'loading-bar'
    };

    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };

    toggleOnIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-toggle-on',
        extraClass: 'switch'
    };

    toggleOffIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-toggle-off',
        extraClass: 'switch'
    };

    headingCurveIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'heading_curve'
    };
    detailDescriptionLineLoader: boolean = false;

    constructor(
        httpService: HttpService,
        globalDataService: GlobalDataService,
        notificationsService: NotificationsService,
        changeDetectorRef: ChangeDetectorRef,
        ngZone: NgZone,
        private injector: Injector,
        @Inject(DOCUMENT) document: Document,
        modalService: ModalService,
        filterService: FiltersService,
        widgetHttpService: WidgetHttpService,
        compareViewService: CompareViewService,
        private configCache: ConfigCacheService,
        public userDataCache: UserDataCacheService,
        cartCache: CartCacheService,
        private carouselInjectedData: CarouselInjectedData
    ) {
        super(
            httpService,
            globalDataService,
            changeDetectorRef,
            notificationsService,
            ngZone,
            document,
            filterService,
            widgetHttpService,
            compareViewService,
            modalService,
            cartCache
        );

        if (this.carouselInjectedData.data) {
            this.data = this.carouselInjectedData.data;
        }
    }

    ngOnInit() {
        if (
            this.data.widgetInfo.portlet.type === PortletType.MOCK_WIDGET &&
            environment.mock
        ) {
            this.setUpMockEnv();
        }

        this.settingUpBasics();
        this.customStyled = this.CUSTOM_STYLED_PORTLETS.includes(
            this.widgetData.widgetInfo.portlet.type
        );
        this.integrationContainer = this.INTEGRATION_PORTLETS.includes(
            this.widgetData.widgetInfo.portlet.type
        );
        this.settingChildInjectableData();
        this.subSink.add(
            this.filterService.refreshWidget.subscribe((response) => {
                if (this.widgetData.apiResponse) {
                    this.widgetData.apiResponse = null;
                }
                if (this.apiResponse) {
                    this.apiResponse = null;
                }
                if (response.has(this.uniqueIdentity)) {
                    this.refreshWidget(true);
                } else if (response.has(this.widgetData.widgetId)) {
                    this.refreshWidget(true);
                }
            })
        );
        if (this.globalDataService.widgetsModifier) {
            this.subSink.add(
                this.globalDataService.widgetsModifier.subscribe((response) => {
                    if (response.has('*')) {
                        this.widgetContentVisible.next(
                            response.get('*').contentViewState
                        );
                    }
                    if (response.has(this.widgetData.widgetUniqueIdentifier)) {
                        this.widgetContentVisible.next(
                            response.get(this.widgetData.widgetUniqueIdentifier)
                                .contentViewState
                        );
                    }
                    this.unsetFullHeight = !this.widgetContentVisible.value
                })
            );
        }
        this.updateSelectedWidget();
        this.globalDataService.updateSelectedWidgets.subscribe((response) => {
            this.updateSelectedWidget();
        });

        this.menuToggle.subscribe((toggle) => {
            if (toggle) {
                this.ngZone.runOutsideAngular(() => {
                    this.updatePosition = setInterval(() => {
                        const overlayRef: OverlayRef = (this.menuTrigger as any)
                            ._overlayRef;

                        const overlayConfig = overlayRef.getConfig();

                        (
                            overlayConfig.positionStrategy as any
                        )._isInitialRender = true;
                        overlayConfig.positionStrategy.apply();
                    }, 100);
                });
            } else {
                if (this.updatePosition) {
                    clearInterval(this.updatePosition);
                    this.updatePosition = null;
                }
            }
        });

        if (this.widgetData.widgetInfo.widgetType === State.LITE) {
            this.lightState = true;
        }
        this.getWidgetDetailedDescription();
    }

    settingUpBasics(): void {
        this.widgetData = this.data;
        this.widgetDescription = this.data.description;
        this.widgetLinkingData = this.linkingData;
        if (this.widgetLinkingData) {
            this.linkingMode = true;
        }
        const generalConfig = this.configCache.getGeneralConfig(
            this.userDataCache.emailId,
            this.configCache.viewId
        );
        if (generalConfig) {
            const insightsState = generalConfig?.collapseInsightByDefault
                ? generalConfig.collapseInsightByDefault
                : false;
            this.widgetContentVisible.next(!insightsState);
        }
        this.uniqueIdentity = this.widgetData.widgetUniqueIdentifier;
        this.widgetText.next(this.widgetData.widgetInfo.text);
        this.filterStoreKey = this.dataStoreKey;
        this.widgetConfigDetails = this.widgetConfig;
        if (!this.widgetData.componentToLoad) {
            // No widget mapped with the portlet
            this.errorMessage.next(Messages.NO_WIDGET_MAPPED_TO_PORTLET);
        }

        if (this.widgetData.widgetInfo.cloudIcon) {
            this.widgetData.widgetInfo.cloudIconPath = getLogoPathOfCloudLabel(
                this.widgetData.widgetInfo.cloudIcon
            );
        }

        // Setting widget configuration
        this.setInsightState();
    }

    settingChildInjectableData() {
        this.widgetInjector = Injector.create({
            providers: [
                {
                    provide: WidgetInjectedData,
                    deps: [],
                    useValue: {
                        widgetRef: this
                    }
                }
            ],
            parent: this.injector
        });
    }

    private setInsightState() {
        if (this.state) {
            this.widgetConfigState = Helper.cloneDeep(
                WidgetConfig.widgetConfigStates[this.state]
            );
            this.widgetState = this.state;
        } else if (!this.isWidgetPremium()) {
            this.widgetConfigState = Helper.cloneDeep(
                WidgetConfig.widgetConfigStates[State.BASIC]
            );
            this.widgetState = State.BASIC;
        } else if (this.widgetTokenPrice === 0) {
            this.widgetConfigState = Helper.cloneDeep(
                WidgetConfig.widgetConfigStates[State.FREE]
            );
            this.widgetState = State.FREE;
        } else {
            this.widgetConfigState = Helper.cloneDeep(
                WidgetConfig.widgetConfigStates[State.PREMIUM]
            );
            this.widgetState = State.PREMIUM;
        }
    }

    isWidgetPremium() {
        // TODO: Check if widget is premium
        return true;
    }

    toggleCollapseWidget($event, widget: HTMLDivElement): void {
        this.widgetContentVisible.next(!this.widgetContentVisible.value);
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.globalDataService.windowResizeEvent.next();
            }, 0);
        });
        this.unsetFullHeight = !this.widgetContentVisible.value;
    }

    openView() {
        const modalData: IModalData = {
            modalName: '',
            modalIcon: this.toggleOnIcon,
            sourceId: this.widgetData.widgetUniqueIdentifier,
            widgetData: Helper.cloneDeep(this.widgetData),
            modalType: ModalType.COMPARE,
            modalSteps: [],
            widgetConfig: {
                visibleSections: new Set(this.visibleSections.value)
            }
        };
        modalData.widgetData.apiResponse = Helper.cloneDeep(this.apiResponse);

        modalData.widgetData['componentToLoad'] = WidgetTypeMap.getComponent(
            modalData.widgetData.widgetInfo.portlet
        );
        this.modalService.openModal(modalData);
    }

    getFullAccess() {
        Helper.getFullAccess(
            this.modalService,
            this.widgetData.widgetInfo.portlet.type
        );
    }

    moreInfo() {
        const data = this.data.moreInfo
            ? this.data.moreInfo
            : this.data.description;
        const moreInfoIcon: IIcon = {
            type: IconType.MATICON,
            class: 'info'
        };

        if (this.data.moreInfo) {
            data['isHtml'] = true;
        } else {
            data['isHtml'] = false;
        }
        const modalData: IModalData = {
            modalName: 'More  Information',
            modalIcon: moreInfoIcon,
            sourceId: this.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.SIDE,
            modalHeightVh: 80,
            modalWidthVw: 60,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: MoreInfoModalComponent,
                        payload: {
                            data
                        }
                    },
                    stepName: 'MORE INFO'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    showWidgetDetailedInfo() {}

    showAttentionRequired(event) {
        event.stopPropagation();

        if (!this.loadingData.value) {
            const modalData: IModalData = {
                modalName: 'Credentials Error',
                sourceId: this.widgetData.widgetUniqueIdentifier,
                modalType: ModalType.MIDDLE,
                modalIcon: this.attentionRequiredIcon,
                modalWidthVw: 60,
                maxHeightVh: 90,
                modalSteps: [
                    {
                        stepData: {
                            componentToLoad:
                                AttentionRequiredAccordionComponent,
                            payload: {
                                data: this.attentionRequiredList
                            }
                        },
                        stepName: ''
                    }
                ]
            };

            this.modalService.openModal(modalData);
        }
    }

    ngOnDestroy(): void {
        if (this.listApiArgs) {
            this.httpService.activeStreams.delete(
                this.listApiArgs.uniqueIdentity
            );
        }
        if (this.streamApiParams) {
            if (this.streamApiParams.socket) {
                this.streamApiParams.socket.close();
            }
            if (this.streamApiParams.client) {
                this.streamApiParams.client.close();
            }
        }
    }

    bindData(data): void {}

    bindPartialData(data): void {}
    getWidgetDetailedDescription() {
        if (this.detailDescriptionLineLoader) {
            return;
        }

        this.detailDescriptionLineLoader = true;
        this.hitWidgetMoreInfoApi(
            this.widgetData.widgetId,
            true,
            (data) => {
                if (data && data.length) {
                    this.widgetMoreInfo = data.filter(
                        (each) => each.type !== DocumentTypes.DESCRIPTION
                    );
                }

                this.detailDescriptionLineLoader = false;
                this.changeDetectorRef.detectChanges();
            },
            true,
            true
        );
    }

    /**
     * Function Traverse whole widgetInfo and adds mockApi=true to all the objects of type IApiInfo
     */
    setUpMockEnv() {
        const getInternalApiKeys = (obj: any) => {
            const objectKeys = Object.keys(obj);
            objectKeys.forEach((key: string) => {
                if (
                    typeof obj[key] === 'object' &&
                    !Array.isArray(obj[key]) &&
                    this.isApiInfo(obj[key])
                ) {
                    obj[key]['mockApi'] = true;
                } else {
                    if (typeof obj[key] === 'object') {
                        getInternalApiKeys(obj[key]);
                    }
                }
            });
        };
        getInternalApiKeys(this.data.widgetInfo);
    }

    /**
     * Function checks if a given object is ApiInfo or not
     * @param obj An Object of type any
     * @returns true if the object if of type IApiInfo else returns false
     */
    isApiInfo(obj: any) {
        if ('apiRouteSuffix' in obj && 'requestType' in obj && 'host' in obj) {
            return true;
        }
        return false;
    }

    refreshCache(buttonRef: IButtonGeneratorInput) {
        const apiArgs: IHitApi = {
            url: `${this.httpService.DEFAULT_BASE_URL.replace('/v2', '')}${
                ApiUrls.CACHE_SYSTEM_REMOVE_DATA
            }`,
            requestType: RequestType.DELETE,
            input: {},
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
                ignoreBaseUrl: true
            },
            function: (res) => {
                this.globalDataService.refreshWidgetsForCache.forEach(
                    (widget) => {
                        widget.refreshWidget();
                    }
                );
            },
            errorFunction: (err) => {
                Helper.showErrorMessage(
                    this.notificationsService,
                    err,
                    'Something went wrong!'
                );
            }
        };
        new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
    }

    openRefreshCacheModal() {
        let modalId;
        const buttonGenInput: IButtonGeneratorInput[] = [
            {
                buttonName: 'Cancel',
                buttonColorType: ButtonColorType.PRIMARY,
                function: () => {
                    this.modalService.closeModal(null, modalId);
                },
                buttonType: ButtonType.STROKED
            },
            {
                buttonName: 'Proceed',
                buttonColorType: ButtonColorType.PRIMARY,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.refreshCache(buttonRef);
                    this.modalService.closeModal(null, modalId);
                },
                buttonType: ButtonType.FLAT
            }
        ];
        const modalData: IConfirmationModal = {
            modalName: '',
            confirmationMessage: '',
            modalWidthVw: 20,
            bodyTemplate: this.confirmationModalTemplate,
            buttonText: '',
            buttonColorType: ButtonColorType.PRIMARY,
            loaderOnExec: true,
            noHeader: true,
            modalAutoHeight: true,
            noHeaderLine: true,
            customButtonGeneratorInput: {
                buttonGenInput: buttonGenInput,
                options: {
                    layout: {
                        justifyContent: 'space-around'
                    }
                }
            }
        };
        modalId = this.modalService.openConfirmationModal(modalData);
    }
}
