import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IApiInfo } from 'src/app/shared/interfaces/api/IApiInfo';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-customer-user-listing-filter',
    templateUrl: './customer-user-listing-filter.component.html',
    styleUrls: ['./customer-user-listing-filter.component.sass']
})
export class CustomerUserListingFilterComponent implements OnInit, OnDestroy {
    @Input() selectedValue: CustomerUserData;
    @Input() lightMode: boolean;
    @Input() filterInfo: IFilterInfo;
    @Input() isCaching: boolean;
    @Input() refreshListing: BehaviorSubject<boolean>;
    @Output() valueChange = new EventEmitter();

    subSink: SubSink = new SubSink();
    refreshUserListing: BehaviorSubject<boolean> = new BehaviorSubject(false);

    userApiInfo: IApiInfo = {
        host: '',
        apiRouteSuffix: '/identity-access-management/users/filter/user-name',
        authorization: AuthorizationType.BEARER_TOKEN,
        requestType: RequestType.POST,
        customInput: null
    };

    constructor() {}

    ngOnInit(): void {
        if (this.refreshListing) {
            this.subSink.add(
                this.refreshListing.subscribe((val) => {
                    if (val) {
                        this.refreshUserListing.next(true);
                    }
                })
            );
        }
    }

    ngOnDestroy() {
        this.subSink.unsubscribe();
    }

    customerChanged($event) {
        if (this.selectedValue.customer !== $event) {
            this.selectedValue.customer = $event;
            this.selectedValue.users = [];
            this.refreshUserListing.next(true);
            this.emitChange();
        }
    }

    userChanged($event) {
        this.selectedValue.users = $event;
        this.emitChange();
    }

    emitChange() {
        this.valueChange.emit(this.selectedValue);
    }

    usersInputCallback() {
        return {
            id: this.selectedValue.customer
        };
    }
}

interface CustomerUserData {
    customer: string;
    users: string[];
}
