import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IApiResponse } from 'src/app/shared/interfaces/api/portlets/ApiResponse';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ConfirmationModalComponent } from '../../../modal-templates/confirmation-modal/confirmation-modal.component';
import { UploadJsonComponent } from '../../../modal-templates/upload-json/upload-json.component';
import { ButtonType } from './../../../../enums/ButtonType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-arm-json-templates-listing',
    templateUrl: './arm-json-templates-listing.component.html',
    styleUrls: ['./arm-json-templates-listing.component.sass']
})
export class ArmJsonTemplatesListingComponent implements OnInit {
    widgetRef: Widget;
    tableInput: ITableGeneratorInput;
    tableGenInputs: ITableGeneratorInput;
    keysToShow: object = {};
    selectedRow: any = [];
    customInputs: any;
    rowData;
    gridRef: GridOptions;
    tableData;
    constructor(private widgetData: WidgetInjectedData) {}

    ngOnInit(): void {
        this.widgetRef = this.widgetData.widgetRef;
        this.generateTable();
    }
    generateTable() {
        this.tableGenInputs = {
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'dataList'
            },
            selection: 'multiple',
            buttons: [
                {
                    buttonName: 'Upload JSON File',
                    buttonType: ButtonType.RAISED,
                    buttonColorType: ButtonColorType.PRIMARY,
                    function: (event) => {
                        this.uploadJsonFile();
                    }
                },
                {
                    buttonName: 'Delete File',
                    buttonType: ButtonType.RAISED,
                    buttonColorType: ButtonColorType.WARN,
                    function: (buttonRef) => {
                        this.deleteAllSelectedTemplates();
                    }
                }
            ],
            columns: [],
            afterResponse: (response: IApiResponse) => {
                this.rowData = response.dataList;
                this.keysToShow = response.dataMap['tableKeys'];
                this.selectedRow = [];
                this.tableGenInputs.columns = [];
                for (const key in response.dataMap['tableKeys']) {
                    this.tableGenInputs.columns.push({
                        columnKey: key,
                        columnName: response.dataMap['tableKeys'][key]
                    });
                }
                this.tableGenInputs.columns.push({
                    columnName: 'Template JSON',
                    columnKey: '',
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData) => {
                        rowData['buttonGenInputs'] = [];

                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: null,
                                buttonIcon: {
                                    type: IconType.FONTAWSOME,
                                    class: 'fas fa-code'
                                },
                                buttonType: ButtonType.ICON,
                                buttonColorType: ButtonColorType.PRIMARY,
                                showLoader: false,
                                function: () => {
                                    const moreInfoIcon: IIcon = {
                                        type: IconType.FONTAWSOME,
                                        class: 'fas fa-code'
                                    };
                                    const modalData: IModalData = {
                                        modalName: 'Template',
                                        modalIcon: moreInfoIcon,
                                        sourceId: this.widgetRef.uniqueIdentity,
                                        modalType: ModalType.MIDDLE,
                                        modalHeightVh: 70,
                                        modalWidthVw: 50,
                                        modalSteps: [
                                            {
                                                stepData: {
                                                    componentToLoad:
                                                        UploadJsonComponent,
                                                    payload: {
                                                        data: {
                                                            widgetRef:
                                                                this.widgetRef,
                                                            rowData:
                                                                rowData.data
                                                        }
                                                    }
                                                },
                                                stepName: 'TEMPLATE'
                                            }
                                        ]
                                    };
                                    this.widgetRef.modalService.openModal(
                                        modalData
                                    );
                                }
                            }
                        ];

                        rowData['buttonGenInputs'] = buttons;
                        return rowData;
                    }
                });

                if (this.gridRef) {
                    this.gridRef.api.setRowData(this.rowData);
                }
            }
        };
    }
    uploadJsonFile() {
        const moreInfoIcon: IIcon = {
            type: IconType.FONTAWSOME,
            class: 'fas fa-chalkboard-teacher'
        };
        const modalData: IModalData = {
            modalName: 'Upload File',
            modalIcon: moreInfoIcon,
            sourceId: this.widgetRef.uniqueIdentity,
            modalType: ModalType.MIDDLE,
            modalHeightVh: 90,
            modalWidthVw: 70,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: UploadJsonComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef
                            }
                        }
                    },
                    stepName: 'UPLOAD JSON TEMPLATES'
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }
    prepareDeleteTemplateInputs() {
        let selectedRowData = [];
        const selectedNodes = this.gridRef.api.getSelectedNodes();
        if (!selectedNodes.length) {
            return false;
        }
        if (selectedNodes.length) {
            selectedNodes.forEach((selectedRow) => {
                selectedRowData.push(selectedRow.data['fileName']);
            });
        } else {
            selectedRowData = [];
        }
        return { fileNames: selectedRowData };
    }
    deleteAllSelectedTemplates() {
        if (!this.prepareDeleteTemplateInputs()) {
            this.widgetRef.notificationsService.showSnackBar(
                'Please select atleast one template to delete.',
                true
            );
            return false;
        }
        const confirmationModalData: IConfirmationModal = {
            function: (
                modalId,
                formGroupRef,
                buttonRef: IButtonGeneratorInput
            ) => {
                const apiConf: IHitApi = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo.delete
                );
                apiConf.input = this.prepareDeleteTemplateInputs();
                apiConf.function = () => {
                    buttonRef.loader = false;
                    this.widgetRef.notificationsService.showSnackBar(
                        'Templates Deleted Successfully.'
                    );
                    this.widgetRef.refreshWidget();
                    this.widgetRef.modalService.closeModal(null, modalId);
                };
                apiConf.errorFunction = (error) => {
                    buttonRef.loader = false;
                    Helper.showErrorMessage(
                        this.widgetRef.notificationsService,
                        error
                    );
                    this.widgetRef.modalService.closeModal(null, modalId);
                };
                new HitApi(
                    apiConf,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            },

            modalName: 'Delete Templates',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            contextIcon: {
                extraClass: 'color-accent',
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclamation-triangle'
            },
            confirmationMessage: `Are you sure you want to delete the templates?`,
            buttonText: 'Delete',
            buttonColorType: ButtonColorType.WARN,
            loaderOnExec: true
        };
        const modalData: IModalData = {
            modalName: confirmationModalData.modalName,
            modalIcon: confirmationModalData.modalIcon,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 35,
            modalWidthVw: 50,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ConfirmationModalComponent,
                        payload: {
                            data: {
                                function: confirmationModalData.function,
                                params: confirmationModalData
                            }
                        }
                    },
                    stepName: 'Confirmation'
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }
}
