import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';

@Component({
    selector: 'app-salesforce-integrations-step-three',
    templateUrl: './salesforce-integrations-step-three.component.html',
    styleUrls: ['./salesforce-integrations-step-three.component.sass']
})
export class SalesforceIntegrationsStepThreeComponent implements OnInit {
    readonly formFieldHeading: string = 'Select Users';
    buttonInputs: IButtonGeneratorInput[];
    totalUsers: UserData[] = [];
    formFieldsLabel: string[];
    users: UserData[];
    actionIcons: IButtonGeneratorInput = {
        buttonName: null,
        buttonColorType: ButtonColorType.WARN,
        buttonType: ButtonType.ICON,
        buttonIcon: {
            type: IconType.SVG,
            class: 'trash_v2'
        },
        function: null,
        preventHoverEffect: true
    };
    stepData: StepData;
    configuredUsers: object[] = [];
    deleteIcon: IIcon = {
        type: IconType.SVG,
        class: 'trash_v2',
        extraClass: 'svg-delete-icon-fill'
    };
    isChecked: Map<string, boolean> = new Map();
    widgetRef: Widget;
    resetModal: Subscription;
    step3Data: UserData[];
    isEdit: boolean = false;
    constructor(
        private modalInjectedData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        private modalService: ModalService
    ) {
        this.widgetRef = modalInjectedData.data.widgetRef;
        this.isEdit = modalInjectedData.data.isEdit;
        if (this.isEdit) {
            this.step3Data =
                modalInjectedData.data['response']?.['step3']?.['userMapping'];
        }
    }

    ngOnInit(): void {
        this.stepData = this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .get(3);
        this.totalUsers = this.stepData['userData'];
        this.users = Helper.cloneDeep(this.totalUsers);
        if (this.stepData['isChecked']) {
            this.isChecked = this.stepData['isChecked'];
        }
        if (this.stepData['configuredUsers']) {
            this.configuredUsers = this.stepData['configuredUsers'];
        }
        if (this.isEdit) {
            this.getConfiguredUser();
        }

        this.buttonInputs = [
            {
                buttonName: 'Back',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.STROKED,
                function: () => {
                    this.setUpCurrentPageData();
                    this.multiStepFormService.previousStep(
                        this.modalInjectedData.modalId
                    );
                },
                hostClass: 'tw-mr-auto'
            },
            {
                buttonName: 'Next',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.getCustomersData(buttonRef);
                }
            }
        ];
        this.resetModal = this.modalService.resetModal.subscribe((data) => {
            this.isChecked.clear();
            this.configuredUsers = [];
            if (this.isEdit) {
                this.getConfiguredUser();
            }
        });
    }
    getConfiguredUser() {
        this.configuredUsers = this.totalUsers.filter((user) => {
            const index = this.step3Data.findIndex(
                (configuredUser) => configuredUser.id === user['id']
            );
            if (index !== -1) {
                this.isChecked.set(user['id'], true);
                return user;
            }
        });
    }
    setUpCurrentPageData() {
        this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .set(3, {
                userData: this.totalUsers,
                isChecked: this.isChecked,
                configuredUsers: this.configuredUsers
            });
    }
    removeUser(userData, isChecked) {
        if (isChecked) {
            this.configuredUsers.push(userData);
            this.isChecked.set(userData.id, true);
        } else {
            const index = this.configuredUsers.findIndex((configuredUser) => {
                return configuredUser['id'] === userData.id;
            });
            if (index !== -1) {
                this.configuredUsers.splice(index, 1);
                this.isChecked.set(userData.id, false);
            }
        }
    }
    getCustomersData(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }
        buttonRef.loader = true;
        const hitApi: IHitApi = {
            url: ApiUrls.CUSTOMERS_LISTING_URL,
            requestType: RequestType.POST,
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },

            function: (response) => {
                buttonRef.loader = false;
                response = this.parseCustomersData(response);
                this.goToNextStep(response);
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error
                );
                buttonRef.loader = false;
            },
            input: {},

            uniqueIdentity: Symbol()
        };

        new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    parseCustomersData(customersData) {
        const customersStepData = [];
        customersData.forEach((customerData) => {
            const data = {
                customerName: customerData.domainId,
                id: customerData.tenantId,
                label: customerData.domainId
            };
            customersStepData.push(data);
        });
        return customersStepData;
    }
    goToNextStep(fourthStepData) {
        this.setUpCurrentPageData();
        if (
            !this.multiStepFormService.stepData
                .get(this.modalInjectedData.modalId)
                .has(4)
        ) {
            this.multiStepFormService.stepData
                .get(this.modalInjectedData.modalId)
                .set(4, { customerData: fourthStepData });
        }

        this.multiStepFormService.nextStep(this.modalInjectedData.modalId);
    }
    ngOnDestroy() {
        this.resetModal.unsubscribe();
    }
}
export interface StepData {
    userData: UserData[];
    configuredUsers: UserData[];
    isChecked: Map<string, boolean>;
}
export interface UserData {
    username: string;
    id: string;
    email: string;
}
