import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from './../../classes/CustomValidators';
import { Helper } from './../../classes/Helper';
import { FilterType } from './../../enums/FilterType';
import { FormState } from './../../enums/FormState';
import { IDropdownData } from './../../interfaces/dropdown-data/IDropdownData';
import { IFormField } from './../../interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from './../../interfaces/form-generator/IFormGeneratorInput';

interface IDependentField {
    label: string;
    placeholder?: string;
    fieldType: FilterType;
    name: string;
    required?: boolean;
    listData: IDropdownData[];
    nestedFields?: INestedField[];
    customField?: boolean;
}

interface INestedField {
    label: string;
    placeholder?: string;
    name: string;
    required?: boolean; // Default is true
    listData: IDropdownData[];
    nestedFields?: INestedField[];
}

@Component({
    selector: 'app-dependent-dropdowns',
    templateUrl: './dependent-dropdowns.component.html',
    styleUrls: ['./dependent-dropdowns.component.sass']
})
export class DependentDropdownsComponent implements OnInit {
    @Input() selectedValue;
    @Input() selectedCustomFieldValue;
    @Input() fieldData: IDependentField;
    @Input() validate: boolean;
    levelSelectionObj = {};
    formGenInputMap = new Map<number, IFormGeneratorInput>();
    levelOneFormGenInput: IFormGeneratorInput = null;
    levelOneFormGroup: FormGroup;
    levelTwoFormGenInput: IFormGeneratorInput = null;
    levelTwoFormGroup: FormGroup;
    levelThreeFormGenInput: IFormGeneratorInput = null;
    levelThreeFormGroup: FormGroup;
    @Output() change = new EventEmitter();
    constructor() {}

    ngOnChanges(change) {
        if (change.validate && change.validate.currentValue) {
            if (this.levelOneFormGroup) {
                Helper.markAllFieldAsTouched(this.levelOneFormGroup);
            }
            if (this.levelTwoFormGroup) {
                Helper.markAllFieldAsTouched(this.levelTwoFormGroup);
            }
            if (this.levelThreeFormGroup) {
                Helper.markAllFieldAsTouched(this.levelThreeFormGroup);
            }
        }
    }

    ngOnInit(): void {
        const field: IFormField = {
            label: this.fieldData.label,
            placeholder: this.fieldData.placeholder
                ? this.fieldData.placeholder
                : this.fieldData.label,
            name: this.fieldData.name,
            value: '',
            fieldType: FilterType.DROPDOWN_SINGLE,
            listData: this.fieldData.listData,
            required: this.fieldData.required ? true : false,
            getKey: null
        };
        if (this.fieldData.required) {
            field.validations = [
                {
                    validator: CustomValidators.required,
                    errorMessage: this.fieldData.label + ' is required'
                }
            ];
        } else {
            this.fieldData.required = false;
        }

        if (
            this.selectedValue &&
            this.selectedValue[field.name] &&
            !field.customField
        ) {
            field.value = this.selectedValue[field.name];
        }

        if (
            this.selectedCustomFieldValue &&
            this.selectedCustomFieldValue[field.name] &&
            field.customField
        ) {
            field.value = this.selectedCustomFieldValue[field.name];
        }

        this.levelOneFormGenInput = {
            formName: '',
            state: FormState.EDIT,
            submitButton: null,
            fields: [field]
        };
    }

    levelOneValueChanged(value) {
        if (
            value[this.fieldData.name] &&
            Object.keys(value[this.fieldData.name]).length
        ) {
            this.levelSelectionObj[this.fieldData.name] = (
                value[this.fieldData.name] as IDropdownData
            ).id;

            this.change.emit({
                custom: this.fieldData.customField,
                value: this.levelSelectionObj
            });
            const nestedListData = (value[this.fieldData.name] as IDropdownData)
                .nestedListData;
            if (!nestedListData.length) {
                return;
            }
            const field: IFormField = {
                label: this.fieldData.nestedFields[0].label,
                placeholder: this.fieldData.nestedFields[0].placeholder
                    ? this.fieldData.nestedFields[0].placeholder
                    : this.fieldData.nestedFields[0].label,
                name: this.fieldData.nestedFields[0].name,
                value: '',
                fieldType: FilterType.DROPDOWN_SINGLE,
                listData: nestedListData,
                required: false,
                getKey: null
            };
            this.levelTwoFormGenInput = {
                formName: '',
                state: FormState.EDIT,
                submitButton: null,
                fields: [field]
            };
        }
    }

    levelTwoValueChanged(value) {
        if (
            value[this.fieldData.nestedFields[0].name] &&
            Object.keys(value[this.fieldData.nestedFields[0].name]).length
        ) {
            this.levelSelectionObj[this.fieldData.nestedFields[0].name] = (
                value[this.fieldData.nestedFields[0].name] as IDropdownData
            ).id;

            this.change.emit({
                custom: this.fieldData.customField,
                value: this.levelSelectionObj
            });
            const nestedListData = (
                value[this.fieldData.nestedFields[0].name] as IDropdownData
            ).nestedListData;
            if (!nestedListData.length) {
                return;
            }
            const field: IFormField = {
                label: this.fieldData.nestedFields[1].label,
                placeholder: this.fieldData.nestedFields[1].placeholder
                    ? this.fieldData.nestedFields[1].placeholder
                    : this.fieldData.nestedFields[1].label,
                name: this.fieldData.nestedFields[1].name,
                value: '',
                fieldType: FilterType.DROPDOWN_SINGLE,
                listData: nestedListData,
                required: false,
                getKey: null
            };
            this.levelThreeFormGenInput = {
                formName: '',
                state: FormState.EDIT,
                submitButton: null,
                fields: [field]
            };
        }
    }

    levelThreeValueChanged(value) {
        if (
            value[this.fieldData.nestedFields[1].name] &&
            Object.keys(value[this.fieldData.nestedFields[1].name]).length
        ) {
            this.levelSelectionObj[this.fieldData.nestedFields[1].name] = (
                value[this.fieldData.nestedFields[1].name] as IDropdownData
            ).id;
            this.change.emit({
                custom: this.fieldData.customField,
                value: this.levelSelectionObj
            });
        }
    }
}
