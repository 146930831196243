import { WidgetHttpService } from './../../../services/http/widget-http/widget-http.service';
import {
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { WhiteLabelingService } from 'src/app/shared/services/white-labeling/white-labeling.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import {
    TextFieldAppearance,
    TimeField,
} from 'src/app/shared/enums/AppearanceType';
import {
    CustomeSignUpSubmitDefaultKeys,
    CustomSignUpFormResponseType,
    CustomSignUpPageSubmitPayloadType,
} from 'src/app/shared/interfaces/ICustomSignTypes';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';

@Component({
    selector: 'app-custom-signup-form',
    templateUrl: './custom-signup-form.component.html',
    styleUrls: ['./custom-signup-form.component.sass'],
})
export class CustomSignupFormComponent implements OnInit {
    @Input('CustomSignUpFormResponseData')
    public CustomSignUpFormResponseData: CustomSignUpFormResponseType;
    @ViewChild(MatStepper)
    public matStepper: MatStepper;
    constructor(
        public whiteLabelService: WhiteLabelingService,
        private notificationService: NotificationsService,
        private widgetHttpService: WidgetHttpService,
        private cdr: ChangeDetectorRef
    ) {}
    public readonly logo: IIcon = {
        type: IconType.IMAGE,
        class: this.whiteLabelService.whiteLableData.darkLogo,
        alt: this.whiteLabelService.whiteLableData.companyName,
    };
    public loginImage: IIcon = null;
    public signupFormGenInput: IFormGeneratorInput = null;
    public signupFormGroup: FormGroup = null;
    public backButtonGenInput: IButtonGeneratorInput;
    public index = 0;
    public totalSteps = 0;
    private preparepayload = [];
    public currentStep = 1;
    public readonly bottomEllipse: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'bottom_ellipse',
        extraClass: 'svg-accent-fill',
    };
    public readonly topEllipse: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'top_ellipse',
        extraClass: 'svg-accent-fill',
    };

    ngOnInit(): void {
        this.logInView();
        this.loginImage = {
            type: IconType.IMAGE,
            class: this.CustomSignUpFormResponseData.imageS3Url,
            alt: 'Signup-image',
        };
        this.totalSteps = this.CustomSignUpFormResponseData.formSections.length;
    }

    logInView() {
        this.signupFormGenInput = {
            formName:
                this.CustomSignUpFormResponseData.formSections[this.index]
                    .formName,
            state: FormState.CREATE,
            submitButton: null,
            fields: this.CustomSignUpFormResponseData.formSections[
                this.index
            ].fields.map((val) => {
                const field: IFormField = {
                    label: val.label,
                    placeholder: val.placeHolder,
                    name: val.name,
                    fieldType: FilterType.TEXT,
                    value:
                        this.preparepayload?.[this.index]?.[val.name] ||
                        undefined,
                    validations: [],
                };

                if (val.maxValue) {
                    field.validations.push({
                        validator: CustomValidators.maxLength(val.maxValue),
                        errorMessage: `Length can be maximum ${val.maxValue}`,
                    });
                }

                if (
                    val.fieldType === FilterType.DROPDOWN_SINGLE ||
                    val.fieldType === FilterType.DROPDOWN_MULTIPLE
                ) {
                    field.listData = val.listData;
                    field.fieldType = val.fieldType as unknown as FilterType;
                } else {
                    field.fieldType = val.fieldType as unknown as FilterType;
                }
                if (val.fieldType === FilterType.DATE) {
                    field.appearance = TextFieldAppearance.TYPE_1;
                } else if (val.fieldType === FilterType.TIME) {
                    field.appearance = TimeField.TYPE_2;
                } else if (val.fieldType === FilterType.NUMBER) {
                    field.validations.push({
                        validator: CustomValidators.numberValidator,
                        errorMessage: 'Numbers are allowed',
                    });
                } else if (val.fieldType === 'ALPHABETIC') {
                    field.fieldType = FilterType.TEXT;
                    field.validations.push({
                        validator:
                            CustomValidators.regexValidator('^[A-Za-z0-9]*$'),
                        errorMessage: 'Only alphabets are allowed',
                    });
                } else if (val.fieldType === 'ALPHA_NUMERIC') {
                    field.fieldType = FilterType.TEXT;
                    field.validations.push({
                        validator: CustomValidators.noSpecialCharacters,
                        errorMessage: 'Only AlphaNumerics are allowed',
                    });
                } else if (val.fieldType === FilterType.EMAIL) {
                    field.validations.push({
                        validator: CustomValidators.email,
                        errorMessage: 'Email is invalid',
                    });
                } else if (val.fieldType === FilterType.PASSWORD) {
                    field.validations.push({
                        validator: CustomValidators.password,
                        errorMessage: 'Password is invalid',
                    });
                }

                if (val.required) {
                    field.required = true;
                    if (val.fieldType !== FilterType.NUMBER) {
                        field.validations.push({
                            validator: CustomValidators.required,
                            errorMessage: 'Field is required',
                        });
                    }
                } else {
                    field.required = false;
                }

                return field;
            }),
        };

        this.backButtonGenInput = {
            buttonName: 'Back',
            buttonColorType: ButtonColorType.SECONDARY,
            customClass: 'back-button',
            buttonType: ButtonType.STROKED,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.currentStep--;
                this.matStepper?.previous();
                if (this.preparepayload.hasOwnProperty(this.index)) {
                } else {
                    this.preparepayload.push(this.signupFormGroup.value);
                }
                this.index--;
                this.logInView();
            },
            showLoader: true,
        };
    }

    public nextButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Next',
        buttonColorType: ButtonColorType.PRIMARY,
        customClass: 'register-button',
        buttonType: ButtonType.RAISED,
        showLoader: true,
        function: (buttonRef: IButtonGeneratorInput) => {
            Helper.markAllFieldAsTouched(this.signupFormGroup);
            if (this.signupFormGroup.invalid) {
                this.signupFormGroup.updateValueAndValidity();
                return;
            } else {
                this.currentStep++;
                this.index++;
                if (this.preparepayload.hasOwnProperty(this.index)) {
                    //Do Nothing
                } else {
                    this.preparepayload.push(this.signupFormGroup.value);
                }
                this.logInView();
            }
        },
    };

    public submitButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Submit',
        buttonColorType: ButtonColorType.PRIMARY,
        customClass: 'register-button',
        buttonType: ButtonType.RAISED,
        showLoader: true,
        function: (buttonRef: IButtonGeneratorInput) => {
            Helper.markAllFieldAsTouched(this.signupFormGroup);
            if (this.signupFormGroup.invalid) {
                this.signupFormGroup.updateValueAndValidity();
                return;
            } else {
                this.preparepayload.push(this.signupFormGroup.value);
                const finalpayload: CustomSignUpPageSubmitPayloadType = {
                    domainId: '',
                    signUpId: '',
                    companyName: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                    formFields: {},
                    viewId: '',
                    auditId: '',
                };

                this.preparepayload.forEach((step) => {
                    Object.keys(step).forEach((key) => {
                        if (CustomeSignUpSubmitDefaultKeys.includes(key)) {
                            finalpayload[key] = step[key];
                        } else {
                            finalpayload.formFields[key] = step[key];
                        }
                    });
                });
                finalpayload.confirmPassword = finalpayload.password;
                finalpayload.signUpId = this.CustomSignUpFormResponseData?.id;
                finalpayload.viewId =
                    this.CustomSignUpFormResponseData?.viewId || '';
                finalpayload.auditId =
                    this.CustomSignUpFormResponseData?.auditId || '';

                buttonRef.loader = true;
                this.cdr.detectChanges();
                const apiArgs: IHitApi = {
                    url: ApiUrls.CUSTOM_SIGNUP_FORM_SUBMIT_API,
                    uniqueIdentity: Symbol(),
                    requestType: RequestType.POST,
                    input: finalpayload,
                    config: {
                        authorization: AuthorizationType.NOT_AUTHORIZED,
                    },
                    function: (response) => {
                        this.preparepayload = [];
                        this.index = 0;
                        this.currentStep = 1;
                        this.signupFormGroup.reset();
                        this.notificationService.showSnackBar(
                            'Thankyou for registering with us. Please check your mailbox for credentials.',
                            false,
                            '',
                            {
                                duration: 5000,
                                panelClass: '',
                                horizontalPosition: 'center',
                                verticalPosition: 'bottom',
                            }
                        );
                        buttonRef.loader = false;
                        this.cdr.detectChanges();
                    },
                    errorFunction: (error) => {
                        Helper.showErrorMessage(
                            this.notificationService,
                            error
                        );
                        buttonRef.loader = false;
                        this.cdr.detectChanges();
                    },
                };
                new HitApi(
                    apiArgs,
                    this.widgetHttpService,
                    this.widgetHttpService.ngZone
                ).hitApi();
            }
        },
    };
}
