import { Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { IconType } from 'src/app/shared/enums/IconType';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';

@Component({
    selector: 'app-remediate-documentation-modal',
    templateUrl: './remediate-documentation-modal.component.html',
    styleUrls: ['./remediate-documentation-modal.component.sass'],
})
export class RemediateDocumentationModalComponent implements OnInit {
    widgetRef: Widget;
    content: any;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER,
    };

    constructor(private modalData: ModalInjectedData) {
        this.widgetRef = this.modalData.data['widgetRef'];
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        const args: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['securityCheckResponse'][
                'manualRemediation'
            ]
        );
        args.input = {};
        args.function = (response) => {
            this.content = response['manualRemediationSteps'];
        };
        args.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error performing action'
            );
        };

        new HitApi(
            args,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
