import {
    Component,
    AfterViewInit,
    ViewChild,
    ElementRef,
    OnDestroy
} from '@angular/core';
import { Subscription } from 'rxjs';
import { IconType } from 'src/app/shared/enums/IconType';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ApiResponseCacheService } from 'src/app/shared/services/cache/api-response-cache/api-response-cache.service';
import { CartCacheService } from 'src/app/shared/services/cache/cart-cache/cart-cache.service';
import { ViewHttpService } from 'src/app/shared/services/http/view-http/view-http.service';
import { SessionService } from 'src/app/shared/services/session/session.service';
import { WhiteLabelingService } from 'src/app/shared/services/white-labeling/white-labeling.service';
import { CredentialsCacheService } from './../../../shared/services/cache/credentials-cache/credentials-cache.service';
import { GlobalConfiguration } from '../../classes/GlobalConfiguration';
import { PseudoConsolePreviewSourceTypes } from 'src/app/shared/enums/PreviewModeSourceType';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements AfterViewInit, OnDestroy {
    @ViewChild('privacypolicyspan')
    private privacyPolicySpan: ElementRef<HTMLSpanElement>;

    private subWindowResize: Subscription;

    twitterIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fab fa-twitter'
    };

    facebookIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fab fa-facebook-f'
    };

    linkedInIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fab fa-linkedin-in'
    };

    constructor(
        public whiteLabelService: WhiteLabelingService,
        public credentialsCacheService: CredentialsCacheService,
        private viewHttpService: ViewHttpService,
        private sessionService: SessionService,
        private apiResponseCacheService: ApiResponseCacheService,
        private cartCacheService: CartCacheService,
        private userDataCacheService: UserDataCacheService,
        private globalDataService: GlobalDataService
    ) {}

    ngAfterViewInit(): void {
        this.updatePreviewOverlayPosition(true);
        if (this.isPreviewPrivacyPolicy) {
            this.subWindowResize =
                this.globalDataService.windowResizeEvent.subscribe(() => {
                    this.updatePreviewOverlayPosition();
                });
        }
    }

    ngOnDestroy(): void {
        if (this.subWindowResize?.unsubscribe) {
            this.subWindowResize.unsubscribe();
        }
    }

    backToMaster() {
        this.apiResponseCacheService.deleteCollection();
        this.sessionService.login(
            this.credentialsCacheService.masterLoginDiveOut(),
            this.viewHttpService,
            true
        );
        this.cartCacheService.deleteCollection();
    }

    get isPreviewPrivacyPolicy(): boolean {
        return (
            GlobalConfiguration?.PREVIEW_MODE &&
            GlobalConfiguration?.pseudoConsoleConfig?.previewSource ===
                PseudoConsolePreviewSourceTypes.PREVIEW_SOURCE_PRIVACY_POLICY
        );
    }

    get privacyPolicyUrl() {
        let link = this.userDataCacheService.privacyPolicyFile;
        if (!link?.startsWith('http')) {
            link = 'https://' + link;
        }
        return link;
    }

    private updatePreviewOverlayPosition(firstLoad?: boolean) {
        if (this.isPreviewPrivacyPolicy) {
            const privacyPolicySpanRect =
                this.privacyPolicySpan?.nativeElement?.getBoundingClientRect();
            if (privacyPolicySpanRect) {
                const callback = () => {
                    const el = document.getElementById(
                        'preview-blur-background-privacy-policy'
                    );
                    const Q3 = el?.children?.item(1) as HTMLDivElement;
                    const Q4 = el?.children?.item(2) as HTMLDivElement;
                    const tooltip = el?.children?.item(3) as HTMLDivElement;
                    if (Q3 && Q4 && tooltip) {
                        Q3.style.width = `calc(${privacyPolicySpanRect.left}px - 0.5rem)`;
                        Q4.style.width = `calc(100vw - ${privacyPolicySpanRect.right}px - 0.5rem)`;
                        tooltip.style.right = `calc(100vw - ${
                            privacyPolicySpanRect.right +
                            privacyPolicySpanRect.width
                        }px)`;
                    }
                };
                if (firstLoad) {
                    setTimeout(callback, 0);
                } else {
                    callback();
                }
            }
        }
    }
}
