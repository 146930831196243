import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class AlphaNumWithUnderscoreValidator extends CustomValidator {
    key = CustomValidatorKey.ALPHA_NUM_WITH_UNDERSCORE_VALIDATOR;
    validatorFunction(control: AbstractControl): ValidationErrors {
        const ALPHA_NUM_WITH_UNDERSCORE_VALIDATOR = '^[A-Za-z0-9_]*$';
        return Validators.pattern(ALPHA_NUM_WITH_UNDERSCORE_VALIDATOR)(control);
    }
}
