import { Component, OnInit } from '@angular/core';
import { GridOptions, RowEvent } from 'ag-grid-community';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ActionState } from 'src/app/shared/enums/ActionState';
import { ActionVisibility } from 'src/app/shared/enums/ActionVisibility';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { ApiResponseType } from 'src/app/shared/interfaces/api/portlets/ApiResponse';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { FormGeneratorModalComponent } from '../../../modal-templates/form-generator-modal/form-generator-modal.component';
import { IColumnData } from './../../../../interfaces/table-generator/IColumnData';
import { RightsizingV2MoreInfoModalComponent } from './../../../modal-templates/rightsizing-v2/rightsizing-v2-more-info-modal/rightsizing-v2-more-info-modal.component';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-rightsizing-v2',
    templateUrl: './rightsizing-v2.component.html',
    styleUrls: ['./rightsizing-v2.component.sass']
})
export class RightsizingV2Component implements OnInit {
    ViewType = ViewType;
    GradeOptions = GradeOptions;

    widgetRef: Widget;
    tableGenInput: ITableGeneratorInput = null;
    agGrid: GridOptions = null;
    selectAll = false;

    apiResponse = null;
    dataList = [];

    filteredCardsData = [];
    keysToShow = [];
    cardButtonLoader = new Map<number, boolean>();

    gradeCount: {
        upgrade: number;
        downgrade: number;
        total: number;
    } = null;

    downgradeIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-arrow-down'
    };

    upgradeIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-arrow-up'
    };

    severityButtons: IButtonGeneratorInput[];
    readonly SUBSCRIPTION_ID_KEY = 'subscriptionId';
    readonly BEST_SAVINGS_KEY = 'bestSaving';
    readonly ACTION_KEY = 'action';

    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        // Setting WidgetActions
        this.widgetRef.widgetActions.next([
            this.widgetRef.widgetConfigState.actions.card
        ]);

        this.tableGenInput = {
            showViewIcon: true,
            afterResponse: (response) => {
                this.apiResponse = response;
                if (response && response['dataList']) {
                    this.dataList = response['dataList'];
                    if (this.dataList.length) {
                        // Setting FixActions
                        this.widgetRef.fixActions.next([
                            {
                                state: ActionState.ENABLED,
                                message: 'Rightsizing',
                                visibility: ActionVisibility.VISIBLE,
                                actionId: 'bulk-rightsizing',
                                icon: {
                                    type: IconType.FONTAWSOME,
                                    class: 'fas fa-exchange-alt',
                                    text: 'RightSize'
                                },
                                function: (actionId) => {
                                    this.bulkRightsizing(actionId);
                                }
                            }
                        ]);
                    }
                    this.filteredCardsData = response['dataList'];
                    this.keysToShow =
                        response['dataMap'] && response['dataMap']['keysToShow']
                            ? response['dataMap']['keysToShow']
                            : [];
                }
                this.severityButtons = null;
                this.setGradeCount(response);
                this.setTableColumns(response);
                if (
                    response &&
                    response['dataList'] &&
                    response['dataList'].length
                ) {
                    this.initButtonGen();
                }

                // Setting VisibleSection
                this.widgetRef.loadingData.subscribe((data) => {
                    if (!data) {
                        this.widgetRef.visibleSections.next(
                            new Set([ViewType.CARDS])
                        );
                    }
                });
            },
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'dataList'
            },
            buttons: null,
            selection: 'multiple',
            columns: []
        };

        this.setUpRowClassRules();
    }

    initButtonGen() {
        const buttonData = {
            Upgrade: 0,
            Downgrade: 1,
            totalRecommendations: 2
        };

        this.severityButtons = [
            {
                buttonName: `Upgrade : ${this.gradeCount.upgrade}`,
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.PRIMARY,
                hoverText: `Upgrade : ${this.gradeCount.upgrade}`,
                hoverEffect: 'shadow',
                function: (buttoRef: IButtonGeneratorInput) => {
                    if (
                        Helper.changeButtonType(
                            buttonData,
                            this.severityButtons,
                            'Upgrade',
                            this.onApplyGradeFilter.bind(this)
                        )
                    ) {
                        this.onApplyGradeFilter(GradeOptions.Upgrade);
                    }
                }
            },
            {
                buttonName: `Downgrade : ${this.gradeCount.downgrade}`,
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.SUCCESS,
                hoverText: `Downgrade : ${this.gradeCount.downgrade}`,
                hoverEffect: 'shadow',
                function: (buttoRef: IButtonGeneratorInput) => {
                    if (
                        Helper.changeButtonType(
                            buttonData,
                            this.severityButtons,
                            'Downgrade',
                            this.onApplyGradeFilter.bind(this)
                        )
                    ) {
                        this.onApplyGradeFilter(GradeOptions.Downgrade);
                    }
                }
            },
            {
                buttonName: `Total Recommendations : ${this.gradeCount.total}`,
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.SECONDARY,
                hoverText: `Total Recommendations : ${this.gradeCount.total}`,
                hoverEffect: 'shadow',
                function: (buttoRef: IButtonGeneratorInput) => {
                    if (
                        Helper.changeButtonType(
                            buttonData,
                            this.severityButtons,
                            'totalRecommendations',
                            this.onApplyGradeFilter.bind(this)
                        )
                    ) {
                        this.onApplyGradeFilter();
                    }
                }
            }
        ];
    }

    setGradeCount(response: ApiResponseType) {
        if (response && response['dataList'] && response['dataList'].length) {
            this.gradeCount = {
                upgrade: 0,
                downgrade: 0,
                total: 0
            };
            response['dataList'].forEach((dataItem) => {
                if (dataItem[this.ACTION_KEY]) {
                    if (dataItem[this.ACTION_KEY] === GradeOptions.Upgrade) {
                        this.gradeCount.upgrade++;
                    }
                    if (dataItem[this.ACTION_KEY] === GradeOptions.Downgrade) {
                        this.gradeCount.downgrade++;
                    }
                }
            });
            this.gradeCount.total =
                this.gradeCount.upgrade + this.gradeCount.downgrade;
        }
    }

    setTableColumns(response: ApiResponseType) {
        const columns: IColumnData[] = [];
        if (
            response &&
            response['dataMap'] &&
            response['dataMap']['keysToShow']
        ) {
            response['dataMap']['keysToShow'].forEach((showKey) => {
                const colObj: IColumnData = {
                    columnKey: showKey.id,
                    columnName: showKey.label
                };

                if (showKey.id === this.BEST_SAVINGS_KEY) {
                    colObj.cellRenderer =
                        this.bestSavingsColumnRenderer.bind(this);
                }
                columns.push(colObj);
            });
            columns.push({
                columnKey: '',
                columnName: 'Action',
                headerClass: 'header-text-center',
                buttonGen: true,
                componentFramework: MultiButtonGeneratorComponent,
                valueFormatter: this.actionColumnRenderer.bind(this),
                editable: false,
                filter: false,
                pinned: 'right',
                minWidth: 180
            });

            columns.unshift({
                columnKey: '',
                columnName: '',
                pinned: 'left',
                filter: false,
                minWidth: 45,
                maxWidth: 45,
                editable: false,
                sortable: false,
                lockPinned: true,
                lockPosition: true,
                cellRenderer: this.subscriptionColumnRenderer.bind(this)
            });
        }
        this.tableGenInput.columns = columns;
    }

    subscriptionColumnRenderer(rowItem: RowEvent) {
        if (rowItem.data[this.ACTION_KEY]) {
            let iconStyle: {
                iconClass: string;
                colorClass: string;
            } = {
                iconClass: 'fas fa-minus',
                colorClass: 'cl-silver'
            };
            if (rowItem.data[this.ACTION_KEY] === GradeOptions.Upgrade) {
                iconStyle = {
                    iconClass: 'fas fa-arrow-up',
                    colorClass: 'cl-red'
                };
            }
            if (rowItem.data[this.ACTION_KEY] === GradeOptions.Downgrade) {
                iconStyle = {
                    iconClass: 'fas fa-arrow-down',
                    colorClass: 'cl-green'
                };
            }
            return `
                <div class="svg-icon-holder ${iconStyle.colorClass}"><i class="${iconStyle.iconClass}"></i></div>
            `;
        }
    }

    bestSavingsColumnRenderer(rowItem: RowEvent) {
        if (rowItem.data[this.ACTION_KEY]) {
            let colorClass = 'cl-silver';
            if (rowItem.data[this.ACTION_KEY] === GradeOptions.Upgrade) {
                colorClass = 'cl-red';
            }
            if (rowItem.data[this.ACTION_KEY] === GradeOptions.Downgrade) {
                colorClass = 'cl-green';
            }
            return `<span class="${colorClass}"><strong>${
                rowItem.data[this.BEST_SAVINGS_KEY]
            }</strong></span>`;
        }
        return rowItem.data[this.BEST_SAVINGS_KEY];
    }

    actionColumnRenderer(rowData: RowEvent) {
        const buttons: IButtonGeneratorInput[] = [
            {
                buttonName: 'More Info',
                buttonType: ButtonType.TEXT,
                buttonColorType: ButtonColorType.INFO,
                function: () => {
                    this.onMoreInfoClicked(rowData.data);
                }
            }
        ];

        if (
            this.widgetRef.widgetData.widgetInfo['formGenerator'] &&
            this.widgetRef.widgetData.widgetInfo['formGenerator'][
                'thresholdFormGenerator'
            ]
        ) {
            buttons.push({
                buttonName: 'Rightsize',
                buttonType: ButtonType.TEXT,
                buttonColorType: ButtonColorType.SUCCESS,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.openRightsizeModal([rowData.data], null, buttonRef);
                }
            });
        }
        rowData['buttonGenInputs'] = buttons;
        return rowData;
    }

    setUpRowClassRules() {
        this.widgetRef.tableRowClassRules = {
            agRowMod: (params) => {
                if (
                    this.agGrid &&
                    this.agGrid.api.getSelectedNodes().length >=
                        GlobalConfiguration.RIGHTSIZE_SELECTION_LIMIT_V2
                ) {
                    return params.node.selected === false;
                }
                return false;
            }
        };
    }

    onApplyGradeFilter(grade?: GradeOptions) {
        let filteredDataList = this.dataList;
        if (grade) {
            filteredDataList = this.dataList.filter((data) => {
                return data[this.ACTION_KEY] && data[this.ACTION_KEY] === grade
                    ? true
                    : false;
            });
        }
        if (this.agGrid) {
            this.agGrid.api.setRowData(filteredDataList);
        }

        this.filteredCardsData = filteredDataList;
    }

    onSelectionChanged() {
        if (!this.agGrid) {
            return;
        }
        if (
            this.agGrid.api.getSelectedNodes().length >
            GlobalConfiguration.RIGHTSIZE_SELECTION_LIMIT_V2
        ) {
            this.selectAll = !this.selectAll;
            if (this.selectAll) {
                this.agGrid.api.forEachNode((node) => {
                    if (
                        node.rowIndex >=
                        GlobalConfiguration.RIGHTSIZE_SELECTION_LIMIT_V2
                    ) {
                        node.selectThisNode(false);
                    }
                });
            } else {
                this.agGrid.api.deselectAll();
            }
        } else if (
            this.agGrid.api.getSelectedNodes().length ===
            GlobalConfiguration.RIGHTSIZE_SELECTION_LIMIT_V2
        ) {
            this.widgetRef.notificationsService.showSnackBar(
                `Maximum ${GlobalConfiguration.RIGHTSIZE_SELECTION_LIMIT_V2} selection allowed`
            );
            this.selectAll = true;
        } else {
            this.selectAll = false;
        }
        this.agGrid.api.redrawRows();
    }

    onMoreInfoClicked(data: any) {
        let modalLayout = {};
        if (
            this.apiResponse &&
            this.apiResponse['dataMap'] &&
            this.apiResponse['dataMap']['modalLayout']
        ) {
            modalLayout = this.apiResponse['dataMap']['modalLayout'];
        }
        const moreInfoModalData: IModalData = {
            noHeader: true,
            modalName: 'Rightsizing MoreInfo',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-toggle-on',
                extraClass: 'switch'
            },
            modalType: ModalType.SIDE,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalSteps: [
                {
                    stepName: 'More Info',
                    stepData: {
                        componentToLoad: RightsizingV2MoreInfoModalComponent,
                        payload: {
                            data: {
                                itemData: data,
                                modalLayout: modalLayout
                            }
                        }
                    }
                }
            ]
        };
        this.widgetRef.modalService.openModal(moreInfoModalData);
    }

    openRightsizeModal(
        data: any[],
        actionId?,
        buttonRef?: IButtonGeneratorInput,
        viaCardId?
    ) {
        if (
            this.widgetRef.widgetData.widgetInfo['formGenerator'] &&
            this.widgetRef.widgetData.widgetInfo['formGenerator'][
                'thresholdFormGenerator'
            ]
        ) {
            const databaseType =
                data && data.length && data[0] && data[0]['databaseType']
                    ? data[0]['databaseType']
                    : null;
            if (actionId) {
                this.widgetRef.operationLoaders.set(actionId, true);
            } else if (buttonRef) {
                buttonRef.loader = true;
            } else if (viaCardId) {
                this.cardButtonLoader.set(viaCardId, true);
                this.widgetRef.changeDetectorRef.detectChanges();
            }
            const apiArgs = Helper.generateHitApiConfig(
                this.widgetRef.widgetData.widgetInfo['formGenerator'][
                    'thresholdFormGenerator'
                ]
            );
            apiArgs.input = {};
            if (databaseType) {
                apiArgs.input = { databaseType };
            }
            apiArgs.function = (response) => {
                this.openFormGeneratorModal(data, response, databaseType);
            };
            apiArgs.errorFunction = (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error
                );
            };
            apiArgs.endFunction = () => {
                if (actionId) {
                    this.widgetRef.operationLoaders.set(actionId, false);
                } else if (buttonRef) {
                    buttonRef.loader = false;
                    this.widgetRef.changeDetectorRef.detectChanges();
                } else if (viaCardId) {
                    this.cardButtonLoader.set(viaCardId, false);
                    this.widgetRef.changeDetectorRef.detectChanges();
                }
            };
            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }

    openFormGeneratorModal(data: any[], response, databaseType?: String) {
        if (response) {
            const modalId = Symbol();
            const inputObj = {
                listResourceUri: data.map((item) => item['resourceId'])
            };
            if (databaseType) {
                inputObj['databaseType'] = databaseType;
            }
            const rightSizeFormGenInput = Helper.formGeneratorBtoFParser(
                response,
                data,
                this.widgetRef.notificationsService,
                this.widgetRef.httpService,
                this.widgetRef.changeDetectorRef,
                {
                    listResourceUri: data.map((item) => item['resourceId'])
                },
                () => {
                    this.widgetRef.refreshWidget();
                    this.widgetRef.modalService.closeModal(null, modalId);
                }
            );
            if (data.length > 1) {
                const radioField = rightSizeFormGenInput.fields.find(
                    (field) => field.name === 'rightsize'
                );
                if (radioField && radioField.listData) {
                    const disabledElement = radioField.listData.find(
                        (data) => data.id === 'centilyticsRecommendation'
                    );
                    if (disabledElement) {
                        disabledElement.disabled = true;
                    }
                }
            }
            const modalData: IModalData = {
                modalName: rightSizeFormGenInput.formName,
                modalIcon: null,
                sourceId: this.widgetRef.uniqueIdentity,
                modalId: modalId,
                modalType: ModalType.MIDDLE,
                modalHeightVh: 50,
                modalWidthVw: 40,
                modalSteps: [
                    {
                        stepData: {
                            componentToLoad: FormGeneratorModalComponent,
                            payload: {
                                data: rightSizeFormGenInput
                            }
                        },
                        stepName: ''
                    }
                ]
            };
            this.widgetRef.modalService.openModal(modalData);
        }
    }

    bulkRightsizing(actionId) {
        if (this.widgetRef.visibleSections.value.has(ViewType.TABLE)) {
            if (this.agGrid && this.agGrid.api.getSelectedRows().length) {
                if (
                    this.agGrid.api.getSelectedRows().length <=
                    GlobalConfiguration.RIGHTSIZE_SELECTION_LIMIT_V2
                ) {
                    this.openRightsizeModal(
                        this.agGrid.api.getSelectedRows(),
                        actionId
                    );
                } else {
                    this.widgetRef.notificationsService.showSnackBar(
                        `Maximum of ${GlobalConfiguration.RIGHTSIZE_SELECTION_LIMIT_V2} items can be selected`
                    );
                }
            } else {
                this.widgetRef.notificationsService.showSnackBar(
                    `No item selected`,
                    true
                );
            }
        } else {
            const selectedCards = this.dataList.filter(
                (dataItem) => dataItem && dataItem.selected
            );
            if (
                selectedCards &&
                selectedCards.length &&
                selectedCards.length > 0 &&
                selectedCards.length <=
                    GlobalConfiguration.RIGHTSIZE_SELECTION_LIMIT_V2
            ) {
                this.openRightsizeModal(selectedCards, actionId);
            } else {
                if (
                    selectedCards &&
                    selectedCards.length &&
                    selectedCards.length >
                        GlobalConfiguration.RIGHTSIZE_SELECTION_LIMIT_V2
                ) {
                    this.widgetRef.notificationsService.showSnackBar(
                        `Maximum of ${GlobalConfiguration.RIGHTSIZE_SELECTION_LIMIT_V2} items can be selected`
                    );
                } else {
                    this.widgetRef.notificationsService.showSnackBar(
                        `No item selected`,
                        true
                    );
                }
            }
        }
    }

    onCardsSelectionChange(event, cardItem) {
        cardItem['selected'] = event.checked;
        const selectedCards = this.dataList.filter(
            (dataItem) => dataItem && dataItem.selected
        );
        if (selectedCards && selectedCards.length) {
            if (
                selectedCards.length >=
                GlobalConfiguration.RIGHTSIZE_SELECTION_LIMIT_V2
            ) {
                this.widgetRef.notificationsService.showSnackBar(
                    `Maximum of ${GlobalConfiguration.RIGHTSIZE_SELECTION_LIMIT_V2} items can be selected`
                );
                this.dataList.forEach((dataItem) => {
                    if (!dataItem.selected) {
                        dataItem['disabled'] = true;
                    }
                });
            } else {
                this.dataList.forEach((dataItem) => {
                    if (dataItem.disabled) {
                        dataItem['disabled'] = false;
                    }
                });
            }
        }
    }
}

enum GradeOptions {
    Upgrade = 'Upgrade',
    Downgrade = 'Downgrade'
}
