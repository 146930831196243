import { Component, OnInit } from '@angular/core';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';

@Component({
    selector: 'app-adoption-metrics',
    templateUrl: './adoption-metrics.component.html',
    styleUrls: ['./adoption-metrics.component.sass']
})
export class AdoptionMetricsComponent implements OnInit {
    widgetRef: Widget = null;
    tableInput: ITableGeneratorInput = null;

    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.tableInput = {
            afterResponse: null,
            widgetIconData: {
                type: IconType.SVG,
                class: 'metrics'
            },
            listExtraction: {
                type: 'DIRECT'
            },
            columns: [
                {
                    columnName: 'User Name',
                    columnKey: 'userName'
                },
                {
                    columnName: 'Customer Name',
                    columnKey: 'customerName'
                },
                {
                    columnName: 'Email ID',
                    columnKey: 'emailId'
                },
                {
                    columnName: 'Action Date & Time',
                    columnKey: 'date'
                },
                {
                    columnName: 'Action Type',
                    columnKey: 'actionType'
                },
                {
                    columnName: 'WidgetName',
                    columnKey: 'widgetName'
                }
            ],

            selection: 'single'
        };
    }
}
