<app-date-range-filter
    [selectedValue]="{
        startDateTime:
            selectedValue && selectedValue.startDate
                ? selectedValue.startDate
                : null,
        endDateTime:
            selectedValue && selectedValue.endDate
                ? selectedValue.endDate
                : null
    }"
    (dateOnChange)="dateChanged($event)"
    [minDateValue]="minDateValue"
    [maxDateValue]="maxDateValue"
    [lightMode]="lightMode ? lightMode : false"
    [filterInfo]="filterInfo"
>
</app-date-range-filter>
<section class="time-range-container">
    <section class="start-time-container time-container">
        <span class="title">Start Time</span>
        <ngx-mat-timepicker-field
            #startTimePicker
            [class.light-mode]="lightMode"
            [controlOnly]="true"
            (timeChanged)="startTimeChanged($event)"
        ></ngx-mat-timepicker-field>
    </section>
    <section class="end-time-container time-container">
        <span class="title">End Time</span>
        <ngx-mat-timepicker-field
            #endTimePicker
            [class.light-mode]="lightMode"
            [controlOnly]="true"
            (timeChanged)="endTimeChanged($event)"
        ></ngx-mat-timepicker-field>
    </section>
</section>
