import { Component, Inject, NgZone } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import {
    CheckboxField,
    TextFieldAppearance,
} from 'src/app/shared/enums/AppearanceType';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';

@Component({
    selector: 'app-field-setting-dropdown',
    templateUrl: './field-setting-dropdown.component.html',
    styleUrls: ['./field-setting-dropdown.component.sass'],
})
export class FieldSettingDropdownComponent {
    public readonly ApiRadioButtonOptions = ApiRadioButtonOptions;
    public readonly deleteIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-trash',
        extraClass: 'action-icon',
    };
    public formGroup: FormGroup = null;
    public formData: ISignUpFieldSettingDropDownType;
    public formGeneratorInput: IFormGeneratorInput = null;
    public ApiInputSelectionValue = '';
    public isShowForm1 = true;
    public isShowListInputMethodSelection = false;
    public isShowApiInputSelection = false;
    public isShowListItemsSelection = false;
    public formGeneratorInputApiLink: IFormGeneratorInput = {
        formName: '',
        state: FormState.CREATE,
        submitButton: {
            buttonName: 'Input menu item',
            buttonType: ButtonType.FLAT,
            buttonColorType: ButtonColorType.PRIMARY,
            function: (buttonRef: IButtonGeneratorInput, fg: FormGroup) => {
                const apiConfig: IHitApi = {
                    uniqueIdentity: Symbol(),
                    config: {
                        authorization: AuthorizationType.NOT_AUTHORIZED,
                    },
                    requestType: RequestType.GET,
                    input: {},
                    url: fg.value.link,
                    function: (response) => {
                        if (Array.isArray(response)) {
                            this.formData.listData = response.map((item) => ({
                                id: item?.id || '',
                                label: item?.label || '',
                            }));
                        } else {
                            this.formData.listData = [];
                        }
                        this.isShowApiInputSelection = false;
                        this.isShowListItemsSelection = true;
                    },
                    errorFunction: (error) => {
                        Helper.showErrorMessage(
                            this.notificationService,
                            error
                        );
                    },
                };
                new HitApi(apiConfig, this.httpService, this.ngZone).hitApi();
            },
        },
        fields: [
            {
                name: 'link',
                fieldType: FilterType.TEXT,
                label: '',
                required: true,
                placeholder: 'Input url https://',
                value: '',
                appearance: TextFieldAppearance.TYPE_1,
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'Link is required',
                    },
                    {
                        validator: CustomValidators.url,
                        errorMessage: 'Link should be valid',
                    },
                ],
                infoMessage: '* only for ISSI Sign up pages',
            },
        ],
    };

    constructor(
        public dialogRef: MatDialogRef<FieldSettingDropdownComponent>,
        private notificationService: NotificationsService,
        private httpService: HttpService,
        private ngZone: NgZone,
        @Inject(MAT_DIALOG_DATA)
        public data: ISignUpFieldSettingDropDownType
    ) {
        this.formData = this.data;
        this.createFormGeneratorInput();
    }

    private createFormGeneratorInput = () => {
        this.formGeneratorInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: {
                buttonName: 'Input menu item',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                function: () => {
                    this.openApiInputSelectionForm();
                },
            },
            fields: [
                {
                    disabled: !!this.data.isDefaultField,
                    name: 'name',
                    fieldType: FilterType.TEXT,
                    label: 'Name',
                    required: true,
                    placeholder: 'Enter here',
                    value: this.data.name || '',
                    appearance: TextFieldAppearance.TYPE_3,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Name is required',
                        },
                        {
                            validator: CustomValidators.keyAlreadyExists(
                                this.data['alreadyTakenFieldNames']
                            ),
                            errorMessage:
                                'Name is already taken by another field',
                        },
                    ],
                },
                {
                    name: 'label',
                    fieldType: FilterType.TEXT,
                    label: 'Label',
                    required: true,
                    placeholder: 'Enter here',
                    value: this.data.label || '',
                    appearance: TextFieldAppearance.TYPE_3,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Label is required',
                        },
                    ],
                },
                {
                    name: 'placeHolder',
                    fieldType: FilterType.TEXT,
                    label: 'Placeholder',
                    placeholder: 'Enter here',
                    value: this.data.placeHolder || '',
                    required: false,
                    appearance: TextFieldAppearance.TYPE_3,
                },
                {
                    disabled: !!this.data.isDefaultField,
                    name: 'required',
                    value: this.data.required || false,
                    fieldType: FilterType.CHECKBOX,
                    label: 'Make field required ?',
                    placeholder: 'Make field required ?',
                    required: false,
                    appearance: CheckboxField.TYPE_3,
                },
            ],
        };
    };

    private openApiInputSelectionForm() {
        const value = this.formGroup.value;
        this.formData = {
            ...this.formData,
            listData: this.formData.listData || [],
            name: value?.name,
            label: value?.label,
            required: value?.required,
        };
        this.isShowForm1 = false;
        this.isShowListInputMethodSelection = true;
    }

    public NextButton: IButtonGeneratorInput = {
        buttonName: 'Next',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        function: () => {
            if (
                this.ApiInputSelectionValue ===
                ApiRadioButtonOptions.FETCH_WITH_APIS
            ) {
                this.isShowListInputMethodSelection = false;
                this.isShowApiInputSelection = true;
            } else if (
                this.ApiInputSelectionValue ===
                ApiRadioButtonOptions.INPUT_MANUALLY
            ) {
                this.isShowListInputMethodSelection = false;
                this.isShowListItemsSelection = true;
                if (this.formData.listData?.length < 1) {
                    this.formData.listData.push({
                        id: '',
                        label: '',
                    });
                }
            }
        },
    };

    public handleAddNewItem: IButtonGeneratorInput = {
        buttonName: 'Add menu item',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.STROKED,
        function: () => {
            this.formData.listData.push({
                id: '',
                label: '',
            });
        },
        buttonIconPrefix: {
            type: IconType.SVG_ASSETS,
            class: 'plus_icon',
            extraClass: 'action-icon',
        },
    };

    public handleRemoveItem(index: number) {
        this.formData.listData.splice(index, 1);
    }

    public DoneButton: IButtonGeneratorInput = {
        buttonName: 'Done',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        function: () => {
            const value = this.formData;
            value.listData.map((item) => {
                item.label = item.id;
            });
            this.dialogRef.close(value);
        },
    };
}

export interface ISignUpFieldSettingDropDownType {
    name: string;
    label: string;
    required: boolean;
    isDefaultField: boolean;
    listData: {
        id: string;
        label: string;
    }[];
    placeHolder?: string;
    alreadyTakenFieldNames: string[];
}

enum ApiRadioButtonOptions {
    FETCH_WITH_APIS = 'Fetch with APIs',
    INPUT_MANUALLY = 'Input manually',
}
