import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IChecksApiResponse } from 'src/app/shared/interfaces/api/portlets/IChecksApiResponse';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-checks',
    templateUrl: './checks.component.html',
    styleUrls: ['./checks.component.sass']
})
export class ChecksComponent implements OnInit, AfterViewInit {
    // CHECKS: 1.0

    @ViewChild('agGrid', { static: false }) agGrid: GridOptions;
    ViewType = ViewType;
    widgetRef: Widget;
    gridApi;
    gridColumnApi;
    rowData;
    filteredSearchData;
    colDefs;
    agGridIcons = Helper.getAgGridIcons();

    severityCount = {
        red: 0,
        yellow: 0,
        green: 0
    };
    defaultColDef = {
        editable: false,
        sortable: true,
        filter: true,
        resizable: true
    };

    severityButtons: IButtonGeneratorInput[];
    accountRegionCost = null;

    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();

        if (this.widgetRef.lightState) {
            this.setUpRowClassRules();
        }
    }

    setUpRowClassRules() {
        this.defaultColDef.sortable = false;
        this.defaultColDef.filter = false;
        Helper.setRowClassRules(this.widgetRef);
    }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(true);

        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    bindData(data: IChecksApiResponse): void {
        if (!data) {
            return;
        }
        this.accountRegionCost = null;
        this.widgetRef.apiResponse = data;
        this.rowData = [];
        this.filteredSearchData = [];
        this.colDefs = [];
        this.widgetRef.attentionRequired(data);
        if (
            (data.dataMap && !Object.keys(data.dataMap).length) ||
            (data.dataList && !data.dataList.length)
        ) {
            this.widgetRef.extraMessage.next(Messages.NO_DATA_AVAILABLE);
            this.widgetRef.visibleSections.next(new Set());
            this.widgetRef.endLoader();
            return;
        }

        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);

        if (data.dataMap && data.dataMap['accountRegionCost']) {
            this.accountRegionCost = data.dataMap['accountRegionCost'];
        }

        this.rowData = data.dataList;
        this.filteredSearchData = this.rowData;

        this.tableAndSeverityCountBinder(data);
        this.initButtonGenInput();
        this.widgetRef.endLoader();
        this.colDefs.forEach((obj) => {
            obj['width'] = Helper.getColumnMinWidth(obj.headerName);
        });
    }

    initButtonGenInput() {
        const buttonData = {};
        let index = 0;

        const criticalButton: IButtonGeneratorInput = {
            buttonName: `Critical : ${this.severityCount.red}`,
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.WARN,
            hoverText: 'Critical',
            hoverEffect: 'shadow',
            function: (buttoRef: IButtonGeneratorInput) => {
                if (
                    Helper.changeButtonType(
                        buttonData,
                        this.severityButtons,
                        'Critical',
                        this.severityFilter.bind(this)
                    )
                ) {
                    this.severityFilter('Critical');
                }
            }
        };

        const warningButton: IButtonGeneratorInput = {
            buttonName: `Warning : ${this.severityCount.yellow}`,
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.PRIMARY,
            hoverText: 'Warning',
            hoverEffect: 'shadow',
            function: (buttoRef: IButtonGeneratorInput) => {
                if (
                    Helper.changeButtonType(
                        buttonData,
                        this.severityButtons,
                        'Warning',
                        this.severityFilter.bind(this)
                    )
                ) {
                    this.severityFilter('Warning');
                }
            }
        };

        const okButton: IButtonGeneratorInput = {
            buttonName: `OK : ${this.severityCount.green}`,
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.SUCCESS,
            hoverText: 'OK',
            hoverEffect: 'shadow',
            function: (buttoRef: IButtonGeneratorInput) => {
                if (
                    Helper.changeButtonType(
                        buttonData,
                        this.severityButtons,
                        'OK',
                        this.severityFilter.bind(this)
                    )
                ) {
                    this.severityFilter('OK');
                }
            }
        };

        this.severityButtons = [];

        if (this.severityCount.red) {
            this.severityButtons.push(criticalButton);
            buttonData['Critical'] = index++;
        }
        if (this.severityCount.yellow) {
            this.severityButtons.push(warningButton);
            buttonData['Warning'] = index++;
        }
        if (this.severityCount.green) {
            this.severityButtons.push(okButton);
            buttonData['OK'] = index++;
        }
    }

    tableAndSeverityCountBinder(response: IChecksApiResponse) {
        const keys = Object.keys(response.dataList[0]).filter((key) => {
            if (response.dataList[0][key] instanceof Array) {
                return false;
            }
            return true;
        });

        keys.forEach((key) => {
            const obj = {
                headerName: key,
                field: key
            };
            if (key === 'Severity') {
                obj['cellRenderer'] = this.columnRenderer;
                obj['pinned'] = 'left';
                obj['lockPinned'] = true;
            }
            this.colDefs.push(obj);
        });

        this.severityCount.red =
            'critical' in response.dataMap['severityCount']
                ? response.dataMap['severityCount']['critical']
                : null;
        this.severityCount.yellow =
            'warning' in response.dataMap['severityCount']
                ? response.dataMap['severityCount']['warning']
                : null;
        this.severityCount.green =
            'ok' in response.dataMap['severityCount']
                ? response.dataMap['severityCount']['ok']
                : null;

        const icon: IIcon = {
            class: `assets/${this.maxSeverityCount()}_check.png`,
            type: IconType.IMAGE
        };

        this.widgetRef.headerIcon.next(icon);
    }

    maxSeverityCount() {
        if (this.severityCount.red) {
            return 'red';
        }
        if (this.severityCount.yellow) {
            return 'yellow';
        } else return 'green';
    }

    columnRenderer(item) {
        if (item.value === 'critical') {
            return `<img src='assets/red_check.png' alt='red' class='severity-icon' style='max-width: 16px !important' >`;
        } else if (item.value === 'warning') {
            return `<img src='assets/yellow_check.png' alt='yellow' class='severity-icon' style='max-width: 16px !important' >`;
        } else {
            return `<img src='assets/green_check.png' alt='green' class='severity-icon' style='max-width: 16px !important' >`;
        }
    }

    severityFilter(severityType?: string) {
        let filteredSearch = this.rowData;

        if (severityType) {
            filteredSearch = filteredSearch.filter((item) => {
                if (
                    item['Severity'].toLowerCase() ===
                    severityType.toLowerCase()
                ) {
                    return item;
                }
            });
        }
        this.filteredSearchData = filteredSearch;
    }

    onQuickFilterChanged(id: string) {
        this.agGrid.api.setQuickFilter(document.getElementById(id)['value']);
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
