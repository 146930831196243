import { Component, OnInit } from '@angular/core';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IIntegrationApiResponse } from 'src/app/shared/interfaces/api/portlets/IIntegrationApiResponse';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { WidgetCreationService } from 'src/app/shared/services/widget-creation/widget-creation.service';
import { SalesforceIntegrationCaseFormComponent } from '../../../modal-templates/integration/salesforce-modal/salesforce-integration-case-form/salesforce-integration-case-form.component';

@Component({
    selector: 'app-salesforce-listing',
    templateUrl: './salesforce-listing.component.html',
    styleUrls: ['./salesforce-listing.component.sass']
})
export class SalesforceListingComponent implements OnInit {
    widgetRef: Widget;
    tableGenInput: ITableGeneratorInput = null;
    constructor(
        widgetData: WidgetInjectedData,
        private widgetCreationService: WidgetCreationService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }
    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
    ngOnInit(): void {
        this.setUpBasics();
    }
    setUpBasics() {
        this.tableGenInput = {
            listExtraction: {
                type: 'DIRECT'
            },
            noDataMessage: 'No listing Available',
            widgetIconData: null,
            buttons: [
                {
                    buttonName: 'Create Case',
                    buttonColorType: ButtonColorType.PRIMARY,
                    buttonType: ButtonType.FLAT,
                    showLoader: true,
                    function: (buttonRef: IButtonGeneratorInput) => {
                        if (buttonRef.loader) {
                            return;
                        }
                        buttonRef.loader = true;

                        const apiArgs: IHitApi = {
                            url: ApiUrls.SALESFORCE_INTEGRATION_GET_CONFIG_WIDGET.replace(
                                '{id}',
                                this.widgetRef.widgetData.widgetId
                            ),
                            intactUrl:
                                ApiUrls.SALESFORCE_INTEGRATION_GET_CONFIG_WIDGET,
                            requestType: RequestType.GET,
                            input: {},
                            function: (response) => {
                                buttonRef.loader = false;
                                this.widgetRef.changeDetectorRef.detectChanges();

                                this.openCaseHandlingModal(response);
                            },
                            errorFunction: (error) => {
                                Helper.showErrorMessage(
                                    this.widgetRef.notificationsService,
                                    error,
                                    'Error while fetching form data'
                                );
                            },
                            endFunction: () => {
                                buttonRef.loader = false;
                                this.widgetRef.changeDetectorRef.detectChanges();
                            },
                            uniqueIdentity: Symbol(),
                            config: {
                                authorization: AuthorizationType.BEARER_TOKEN
                            }
                        };
                        new HitApi(
                            apiArgs,
                            this.widgetRef.httpService,
                            this.widgetRef.ngZone
                        ).hitApi();
                    }
                }
            ],
            columns: [
                {
                    columnKey: 'caseNumber',
                    columnName: 'Case Number'
                },
                {
                    columnKey: 'subject',
                    columnName: 'Subject'
                },
                {
                    columnKey: 'description',
                    columnName: 'Description'
                },
                {
                    columnKey: 'priority',
                    columnName: 'Priority'
                },
                {
                    columnKey: 'status',
                    columnName: 'Status'
                },
                {
                    columnKey: 'customer',
                    columnName: 'Customer'
                }
            ]
        };
    }
    openCaseHandlingModal(response) {
        const modalArgs: IModalData = {
            modalName: 'New Case',
            modalIcon: undefined,
            modalType: ModalType.MIDDLE,
            extraStepContainerClass: 'remove-form-space',
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalSteps: [
                {
                    stepName: 'New Case',
                    stepData: {
                        componentToLoad: SalesforceIntegrationCaseFormComponent,
                        payload: {
                            data: {
                                formFields:
                                    response && response.step2
                                        ? this.widgetCreationService.salesforceBToFParser(
                                              response.step2
                                          ).fields
                                        : [],
                                widgetRef: this.widgetRef,
                                configId: this.widgetRef.widgetData.widgetId,
                                isCreate: true
                            }
                        }
                    }
                }
            ],
            modalHeightVh: 50,
            modalWidthVw: 50
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
    bindData(response: IIntegrationApiResponse): void {
        this.widgetRef.endLoader();
    }
}
