<h2>Select Landing Page</h2>

<div class="details-container">
    <app-form-generator
        *ngIf="landingPageFormGenInput"
        [formGeneratorInput]="landingPageFormGenInput"
        (formGroupRef)="landingPageFormGroup = $event"
    ></app-form-generator>
    <div>
        <app-multi-button-generator
            [buttonGenInputs]="buttonGenInputs"
            [options]="buttonOptions"
        ></app-multi-button-generator>
    </div>
</div>
