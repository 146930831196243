import { Component, OnInit } from '@angular/core';
import { GridOptions, RowEvent } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IApiResponse } from 'src/app/shared/interfaces/api/portlets/ApiResponse';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { CpuUtilizationEditModalComponent } from '../../../modal-templates/cpu-utilization-modals/cpu-utilization-edit-modal/cpu-utilization-edit-modal.component';
import { CpuUtilizationModalComponent } from '../../../modal-templates/cpu-utilization-modals/cpu-utilization-modal/cpu-utilization-modal.component';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-cpu-utilization',
    templateUrl: './cpu-utilization.component.html',
    styleUrls: ['./cpu-utilization.component.sass']
})
export class CpuUtilizationComponent implements OnInit {
    tableGenInputs: ITableGeneratorInput;
    widgetRef: Widget;
    keysToShow: object = {};
    modalKeysToShow: object = {};
    selectedRow: any = [];
    customInputs: any;
    rowData;
    gridRef: GridOptions;
    tableData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    buttonIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-pen'
    };
    highWarning: boolean = false;
    allowedDecimal: boolean = false;
    constructor(private widgetData: WidgetInjectedData) {
        this.widgetRef = this.widgetData.widgetRef;
    }
    utilization = [
        'diskUtilization',
        'cpuUtilization',
        'memoryUtilization',
        'networkUtilization',
        'utilization'
    ];

    editButton: IButtonGeneratorInput = {
        buttonName: 'Edit',
        buttonType: ButtonType.RAISED,
        buttonColorType: ButtonColorType.SECONDARY,
        function: () => {
            this.bulkEdit();
        }
    };

    ngOnInit(): void {
        this.tableGenInputs = {
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'dataList'
            },
            selection: 'multiple',
            columns: [],
            removeHeaderCheckBox: true,
            afterResponse: (response: IApiResponse) => {
                this.rowData = response.dataList;
                this.modalKeysToShow = response.dataMap['modalKeysToShow'];
                this.keysToShow = response.dataMap['keysToShow'];
                this.highWarning = response.dataMap['highWarning'];
                this.allowedDecimal = response.dataMap['allowedDecimal'];
                this.selectedRow = [];
                this.tableGenInputs.columns = [];
                for (const key in response.dataMap['keysToShow']) {
                    if (this.utilization.includes(key)) {
                        this.tableGenInputs.columns.push({
                            columnKey: response.dataMap['keysToShow'][key],
                            columnName: response.dataMap['keysToShow'][key],

                            cellStyle: Helper.cellStyling,
                            cellRenderer: (rowEvent: RowEvent) => {
                                if (
                                    rowEvent.data[
                                        response.dataMap['keysToShow'][key]
                                    ]
                                ) {
                                    return Helper.extractDataFromObject(
                                        'value',
                                        rowEvent.data[
                                            response.dataMap['keysToShow'][key]
                                        ]
                                    );
                                }
                            }
                        });
                    } else {
                        this.tableGenInputs.columns.push({
                            columnKey: response.dataMap['keysToShow'][key],
                            columnName: response.dataMap['keysToShow'][key]
                        });
                    }
                }
                this.tableGenInputs.columns.push({
                    columnName: 'Action',
                    columnKey: 'action',
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: RowEvent) => {
                        if (!rowData['buttonGenInputs']) {
                            rowData['buttonGenInputs'] = [];
                        }
                        const fixButton: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'View & Edit',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.INFO,
                                function: () => {
                                    const moreInfoIcon: IIcon = {
                                        type: IconType.FONTAWSOME,
                                        class: 'fas fa-user-plus'
                                    };

                                    const modalData: IModalData = {
                                        noHeader: true,
                                        modalName: '',
                                        modalIcon: moreInfoIcon,
                                        sourceId: this.widgetRef.uniqueIdentity,
                                        modalType: ModalType.SIDE,
                                        modalHeightVh: 90,
                                        modalWidthVw: 50,
                                        modalSteps: [
                                            {
                                                stepName: '',
                                                stepData: {
                                                    componentToLoad:
                                                        CpuUtilizationModalComponent,
                                                    payload: {
                                                        data: {
                                                            rowData:
                                                                rowData.data,
                                                            keysToShow:
                                                                this
                                                                    .modalKeysToShow,
                                                            widgetRef:
                                                                this.widgetRef,
                                                            highWarning:
                                                                this
                                                                    .highWarning,
                                                            allowedDecimal:
                                                                this
                                                                    .allowedDecimal
                                                        }
                                                    }
                                                }
                                            }
                                        ]
                                    };
                                    this.widgetRef.modalService.openModal(
                                        modalData
                                    );
                                }
                            }
                        ];
                        rowData['buttonGenInputs'] = fixButton;
                        return rowData;
                    }
                });
                if (this.gridRef) {
                    this.gridRef.api.setRowData(this.rowData);
                }
            }
        };
        this.setUpRowClassRules();
    }
    setUpRowClassRules() {
        this.widgetRef.tableRowClassRules = {
            agRowDisabled: (params) => {
                if (params['data']['isParent'] === 'true') {
                    return true;
                }
                return false;
            }
        };
    }
    bulkEdit() {
        if (!this.selectedRow.length) {
            this.widgetRef.notificationsService.showSnackBar(
                'Please select atleast one server to edit',
                true
            );
            return;
        }
        const sameIntegrationName = this.integrationName();
        if (!sameIntegrationName) {
            this.widgetRef.notificationsService.showSnackBar(
                'Please select the servers of same integration name for bulk edit',
                true
            );
            return;
        }
        const diffServer = this.monitoredBy();
        if (diffServer) {
            this.widgetRef.notificationsService.showSnackBar(
                'Please select all the servers monitored on Nagios for Bulk Edit.',
                true
            );
            return;
        }

        const modalData: IModalData = {
            modalName: 'Server Utilization',
            modalIcon: null,
            sourceId: this.widgetRef.uniqueIdentity,
            modalType: ModalType.MIDDLE,
            modalHeightVh: 95,
            modalWidthVw: 75,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: CpuUtilizationEditModalComponent,
                        payload: {
                            data: {
                                selectedData: this.selectedRow,
                                widgetRef: this.widgetRef,
                                keysToShow: this.keysToShow,
                                highWarning: this.highWarning,
                                allowedDecimal: this.allowedDecimal
                            }
                        }
                    },
                    stepName: ''
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
        this.gridRef.api.setRowData(this.rowData);
        this.selectedRow = [];
    }
    integrationName() {
        if (this.selectedRow.length >= 2) {
            return this.selectedRow.reduce((a, b) => {
                return a['Integration Name'] === b['Integration Name']
                    ? a
                    : false;
            });
        } else {
            return true;
        }
    }
    monitoredBy(): boolean {
        let monitoredBy: boolean = false;
        this.selectedRow.forEach((data) => {
            if (data['Monitored By'] === 'Cloud') {
                monitoredBy = true;
            }
        });
        return monitoredBy;
    }
    getSelectedRow() {
        this.selectedRow = [];
        const selectedNodes = this.gridRef.api.getSelectedNodes();
        if (selectedNodes.length) {
            selectedNodes.forEach((selectedRow) => {
                if (selectedRow.data['isParent'] === 'true') {
                    selectedRow.setSelected(false);
                } else {
                    this.selectedRow.push(selectedRow.data);
                }
            });
        } else {
            this.selectedRow = [];
        }
    }
}
