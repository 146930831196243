<div class="myPrice-container tw-px-5 tw-py-0">
    <app-modal-info-template [infoData]="infoData"></app-modal-info-template>
    <ng-container *ngIf="!edit">
        <app-form-generator
            [formGeneratorInput]="awsAccountFormGenInputs"
            (valuesChange)="valueChanged($event)"
        >
        </app-form-generator>
    </ng-container>
    <ng-container *ngIf="accountDetails && accountDetails.length">
        <div class="header tw-flex tw-h-10 tw-font-semibold">
            <div class="tw-flex-1 tw-items-center tw-flex">
                <span>Account</span>
            </div>
            <div class="tw-flex-f2 tw-items-center tw-flex">
                <div class="tw-flex-1">
                    <span>Customer Name</span>
                </div>
                <div class="tw-flex-1">
                    <span>Charge Type Included In Billing</span>
                </div>
            </div>
        </div>
        <div
            class="body-container tw-overflow-auto"
            [ngStyle]="edit ? { height: '270px' } : { height: '170px' }"
        >
            <div class="body tw-flex" *ngFor="let key of accountDetails">
                <div class="account-id tw-flex-1 tw-items-center tw-flex">
                    <span>{{ key.awsAccount ? key.awsAccount : key.id }}</span>
                </div>
                <div class="account-detail tw-flex-f2 tw-flex tw-items-center">
                    <div class="customer-name tw-flex-1">
                        <span>{{ key.customerName }}</span>
                    </div>
                    <div class="charge-type tw-flex-1">
                        <app-multi-select-dropdown
                            [filterInfo]="filterInfo"
                            [selectedValues]="
                                key.chargeTypeIncluded
                                    ? key.chargeTypeIncluded
                                    : []
                            "
                            (change)="
                                chargeType.set(
                                    key.awsAccount ? key.awsAccount : key.id,
                                    $event
                                )
                            "
                            [isCaching]="false"
                        ></app-multi-select-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<div class="btn tw-flex tw-justify-end tw-h-11 tw-py-1 tw-px-5">
    <app-button-generator [buttonGenInput]="buttonInput">
    </app-button-generator>
</div>
