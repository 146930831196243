import { Pipe, PipeTransform } from '@angular/core';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';

@Pipe({
    name: 'widgetsPageSeparation'
})
export class WidgetsPageSeparationPipe implements PipeTransform {
    transform(totalWidgets): number[] {
        const indexes = [];
        let initialIndex = totalWidgets - 1;
        if (totalWidgets > GlobalConfiguration.WIDGETS_PAGE_LIMIT) {
            initialIndex = GlobalConfiguration.WIDGETS_PAGE_LIMIT - 1;
            indexes.push(initialIndex);
        } else if (totalWidgets <= GlobalConfiguration.WIDGETS_PAGE_LIMIT) {
            indexes.push(totalWidgets - 1);
            return indexes;
        }
        let flag = true;
        let i = 0;
        while (flag) {
            const nextIndex =
                indexes[i] + GlobalConfiguration.WIDGETS_PAGE_LIMIT;
            if (nextIndex >= totalWidgets) {
                indexes.push(totalWidgets - 1);
                flag = false;
            } else if (nextIndex < totalWidgets) {
                indexes.push(nextIndex);
                ++i;
            }
        }
        return indexes;
    }
}
