<div
    class="
        config-container
        tw-flex tw-flex-col tw-my-3 tw-mx-0 tw-overflow-auto
    "
    *ngIf="!(widgetRef.loadingData | async)"
>
    <div class="attributes-dropdown tw-relative">
        <app-form-generator
            *ngIf="attrFormGenInput"
            [formGeneratorInput]="attrFormGenInput"
            (formGroupRef)="attrFormGroup = $event"
            (valuesChange)="attributeChanged()"
        ></app-form-generator>
        <div
            [ngClass]="
                saveButtonGenInput.loader || resetSaveButtonGenInput.loader
                    ? 'overlay disabled'
                    : ''
            "
        ></div>
    </div>
    <ng-container
        class="
            filters-container
            tw-flex tw--mt-4 tw-overflow-auto tw-my-2 tw-mx-1 tw-p-1
        "
        *ngIf="filters && filters.length; else noFilters"
        [style.display]="
            filtersLoader && !filterConfigDataFetched ? 'none' : 'flex'
        "
    >
        <app-filters-handler
            [style.display]="
                filtersLoader && !filterConfigDataFetched ? 'none' : 'block'
            "
            [filterIds]="filters"
            [filterStoreKey]="FilterStoreKey.CONFIGURATION"
            [lightMode]="true"
        ></app-filters-handler>
    </ng-container>
    <ng-template #noFilters>
        <div
            class="
                no-filters
                tw-text-center tw-pb-4 tw-font-medium tw-text-fs-400
            "
            *ngIf="
                filters &&
                !filters.length &&
                !filtersLoader &&
                !errorLoadingFiltersData
            "
        >
            No Applicable Filters
        </div>
    </ng-template>
    <div *ngIf="errorLoadingFiltersData">
        <i class="fas fa-exclamation-triangle color-warn"></i>
        {{ errorLoadingFiltersData }}
    </div>
    <div
        class="tw-flex tw-justify-center tw-items-center tw-py-3 tw-px-0"
        *ngIf="filtersLoader"
    >
        <mat-spinner [diameter]="24"></mat-spinner>
    </div>
</div>

<div class="tw-flex tw-justify-end tw-mt-3">
    <app-button-generator
        [disabled]="saveButtonGenInput.loader"
        [buttonGenInput]="resetSaveButtonGenInput"
        (buttonRef)="resetConfig($event)"
    ></app-button-generator>
    <app-button-generator
        [disabled]="
            !attributeSelected ||
            filtersLoader ||
            resetSaveButtonGenInput.loader
        "
        [buttonGenInput]="saveButtonGenInput"
        (buttonRef)="saveConfig($event)"
    ></app-button-generator>
</div>
