import { Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { IconType } from 'src/app/shared/enums/IconType';
import { IBackendFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IBackendFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ModalInjectedData } from '../../../../classes/ModalInjectedData';
import { IBackendFormField } from './../../../../interfaces/form-generator/IBackendFormField';

@Component({
    selector: 'app-compliance-info-modal',
    templateUrl: './compliance-info-modal.component.html',
    styleUrls: ['./compliance-info-modal.component.sass'],
})
export class ComplianceInfoModalComponent implements OnInit {
    getKeys = Object.keys;
    widgetRef: Widget;
    complianceInfo: any;
    nameLabelPair: object;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER,
    };

    constructor(private modalData: ModalInjectedData) {
        this.widgetRef = this.modalData.data['widgetRef'];
    }

    ngOnInit(): void {
        this.getComplianceInfo();
        this.getCustomComplianceLogicKeys();
    }

    getComplianceInfo() {
        const args: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['securityCheckResponse'][
                'complianceInfo'
            ]
        );
        args.input = {
            widgetId: this.widgetRef.widgetData.widgetId,
        };
        args.function = (response) => {
            this.complianceInfo = response;
            this.parseResponse();
        };
        args.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error performing action'
            );
            this.complianceInfo = [];
        };

        new HitApi(
            args,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    parseResponse() {
        this.complianceInfo.forEach((each) => {
            const keys = Helper.setDynamicCloudFilterKeys(this.widgetRef);

            each['globalWhitelisting']['accountIds'] =
                each['globalWhitelisting'][keys.accountIds];

            each['globalWhitelisting']['regions'] =
                each['globalWhitelisting'][keys.regions];

            each['globalWhitelisting']['resourceTags'] =
                each['globalWhitelisting'][keys.resourceTags];
        });
    }

    /**
     * This function is added to get the keys and values of custom compliance logic
     */
    getCustomComplianceLogicKeys() {
        const hitApi: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['securityCheckResponse'][
                'customizeForm'
            ]
        );
        hitApi.function = (response: IBackendFormGeneratorInput) => {
            this.nameLabelPair = {};
            response.fields.forEach((fields: IBackendFormField) => {
                this.nameLabelPair[fields.name] = fields.label;
            });
        };
        hitApi.config.defaultHeaders = {
            'content-type': 'application/json',
        };
        hitApi.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error getting compliance info.'
            );
        };
        new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
