<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_5">
    <div class="numberRangeSingle">
        <ngx-slider
            (userChangeEnd)="onValueChange($event)"
            [(value)]="field.value.lowValue"
            [(highValue)]="field.value.highValue"
            [options]="filterInfo.rangeOptions"
            (change)="
                field.onChange ? field.onChange($event, formGroup) : null"
        ></ngx-slider>
    </div>
</ng-container>
