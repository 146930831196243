<section class="container-section">
    <div class="overlay" style="z-index: 99999" *ngIf="isShowLoader">
        <div class="overlay-content">
            <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
        </div>
    </div>
    <div *ngIf="formToShow === 1" class="form-area-one">
        <span class="form-label"> Customer name field </span>
        <app-button-generator
            [buttonGenInput]="buttonGenInputsAddNewField"
        ></app-button-generator>

        <div class="form-one-scroll-area">
            <ng-container
                *ngFor="
                    let customerName of formOneFieldContent;
                    let i = index;
                    trackBy: customTrackBy
                "
            >
                <div
                    *ngIf="i !== formOneEditFieldIndex"
                    class="field-one-value-container"
                >
                    <span class="label">{{ customerName }}</span>

                    <div
                        class="edit-icon icon"
                        mat-ripple
                        (click)="handleFormOneEditIcon(i)"
                    >
                        <ng-template
                            [ngTemplateOutlet]="editIconSvg"
                        ></ng-template>
                    </div>
                    <div
                        class="delete-icon icon"
                        mat-ripple
                        (click)="handleFormOneDeleteIcon(i)"
                    >
                        <ng-template
                            [ngTemplateOutlet]="deleteIconSvg"
                        ></ng-template>
                    </div>
                </div>
                <form
                    *ngIf="formOneEditFieldIndex === i"
                    class="field-one-input-container"
                >
                    <input
                        #inputRef
                        autofocus
                        placeholder="Enter Customer Name"
                        [(ngModel)]="formOneFieldContent[i]"
                        [ngModelOptions]="{ standalone: true }"
                        (blur)="handleOnBlurFormOneInput()"
                    />
                </form>
            </ng-container>
        </div>
    </div>
    <div class="table-div">
        <table *ngIf="formToShow === 2" class="form-table">
            <tr>
                <ng-container
                    *ngFor="
                        let fieldItem of tabularFormToIterate[0]?.fields;
                        let fieldIndex = index
                    "
                >
                    <th>
                        <span class="flex-row">
                            <mat-checkbox
                                *ngIf="
                                    fieldItem.fieldType ===
                                    FilterType.DROPDOWN_SINGLE
                                "
                                [checked]="getBulkCheckedStatus(fieldIndex)"
                                (change)="
                                    setBulkCheckedStatus($event, fieldIndex)
                                "
                                name="checkbox"
                            >
                            </mat-checkbox>
                            <span>
                                {{ fieldItem?.label }}
                                <sup *ngIf="fieldIndex !== 0" class="asterisk">
                                    *
                                </sup>
                            </span>
                        </span>
                    </th>
                </ng-container>
            </tr>
            <tr
                *ngFor="
                    let fieldsGroup of tabularFormToIterate;
                    let fieldGroupIndex = index
                "
            >
                <ng-container
                    *ngFor="
                        let fieldItem of fieldsGroup?.fields;
                        let fieldItemIndex = index
                    "
                >
                    <td *ngIf="fieldItemIndex === 0" class="td-label">
                        {{ fieldsGroup?.value }}
                    </td>

                    <td *ngIf="fieldItemIndex !== 0">
                        <span class="flex-row">
                            <mat-checkbox
                                class="margin-top-field"
                                *ngIf="
                                    fieldItem.fieldType ===
                                    FilterType.DROPDOWN_SINGLE
                                "
                                name="checkbox"
                                [(ngModel)]="fieldItem.isSelectedForBulkAction"
                            >
                            </mat-checkbox>

                            <span class="flex-row">
                                <ng-container
                                    *ngIf="
                                        fieldItem.fieldType ===
                                        FilterType.DATE_TIME
                                    "
                                >
                                    <span class="flex-row margin-top-field">
                                        <input
                                            class=""
                                            style="min-width: 7rem"
                                            matInput
                                            (click)="picker.open()"
                                            [matDatepicker]="picker"
                                            [placeholder]="
                                                fieldItem?.placeHolder
                                            "
                                            [(ngModel)]="fieldItem.date"
                                        />

                                        <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker"
                                        ></mat-datepicker-toggle>

                                        <mat-datepicker
                                            #picker
                                            class="margin-top-field"
                                        ></mat-datepicker>
                                    </span>
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        fieldItem.fieldType ===
                                        FilterType.DROPDOWN_SINGLE
                                    "
                                >
                                    <ng-select
                                        class="margin-top-field"
                                        style="
                                            z-index: 10;
                                            min-width: 8rem;
                                            width: 100%;
                                        "
                                        [bindValue]="'id'"
                                        [bindLabel]="'label'"
                                        [items]="fieldItem.listData"
                                        [closeOnSelect]="true"
                                        [placeholder]="fieldItem.placeHolder"
                                        [appendTo]="'body'"
                                        dropdownPosition="auto"
                                        [multiple]="false"
                                        [virtualScroll]="true"
                                        [clearable]="false"
                                        [(ngModel)]="
                                            fieldItem[
                                                CustomRequestWidgetFormValueKeys
                                                    .INPUTSTRING
                                            ]
                                        "
                                    >
                                        <ng-template
                                            ng-option-tmp
                                            let-item="item"
                                        >
                                            <div title="{{ item['label'] }}">
                                                <span class="common-label">{{
                                                    item.label
                                                }}</span>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        fieldItem.fieldType ===
                                            FilterType.SHORT_TEXT ||
                                        fieldItem.fieldType ===
                                            FilterType.LONG_TEXT ||
                                        fieldItem.fieldType ===
                                            FilterType.TEXTAREA
                                    "
                                >
                                    <mat-form-field>
                                        <input
                                            matInput
                                            [placeholder]="
                                                fieldItem.placeHolder
                                            "
                                            [(ngModel)]="
                                                fieldItem[
                                                    CustomRequestWidgetFormValueKeys
                                                        .INPUTSTRING
                                                ]
                                            "
                                        />
                                    </mat-form-field>
                                </ng-container>
                            </span>
                        </span>
                    </td>
                </ng-container>
            </tr>
        </table>
    </div>
    <div *ngIf="formToShow !== 0" class="button-group-container">
        <ng-container *ngIf="getBulkActionOptions()?.length">
            <app-button-generator
                [matMenuTriggerFor]="bulkActionMatMenuRef"
                [buttonGenInput]="bulkActionButtonGenInput"
                [disabled]="!isBulkActionButtonEnabled"
            ></app-button-generator>

            <mat-menu #bulkActionMatMenuRef="matMenu">
                <ng-container *ngFor="let option of getBulkActionOptions()">
                    <button mat-menu-item (click)="handleOnBulkAction(option)">
                        {{ option?.label }}
                    </button>
                </ng-container>
            </mat-menu>
        </ng-container>
        <app-button-generator
            [buttonGenInput]="submitButtonGenInput"
        ></app-button-generator>
    </div>
</section>
<ng-template #editIconSvg>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_313_148)">
            <path
                d="M4 19.9998H8L18.5 9.49981C18.7626 9.23717 18.971 8.92537 19.1131 8.58221C19.2553 8.23905 19.3284 7.87125 19.3284 7.49981C19.3284 7.12838 19.2553 6.76058 19.1131 6.41742C18.971 6.07426 18.7626 5.76246 18.5 5.49981C18.2374 5.23717 17.9256 5.02883 17.5824 4.88669C17.2392 4.74455 16.8714 4.67139 16.5 4.67139C16.1286 4.67139 15.7608 4.74455 15.4176 4.88669C15.0744 5.02883 14.7626 5.23717 14.5 5.49981L4 15.9998V19.9998Z"
                stroke="#262626"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M13.5 6.5L17.5 10.5"
                stroke="#262626"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_313_148">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</ng-template>
<ng-template #deleteIconSvg>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_313_141)">
            <path
                d="M4 7H20"
                stroke="#262626"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M10 11V17"
                stroke="#262626"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M14 11V17"
                stroke="#262626"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7"
                stroke="#262626"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                stroke="#262626"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_313_141">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</ng-template>
