<div *ngIf="!isEdit" class="select-option">
    <span class="form-head tw-flex tw-items-center tw-font-semibold"
        >Whom do you want to involve in the assessment?</span
    >
    <div class="tw-flex tw-items-center tw-mt-4">
        <mat-radio-group
            aria-label="Select Assessment Type"
            class="tw-flex"
            name="assessmentType"
            (change)="checkAssessmentType($event.value)"
        >
            <mat-radio-button
                class="tw-mr-12"
                [checked]="assessmentType == eAssessmentType.ASSESSOR"
                value="Independent"
            >
                <span class="common-label">Assessor</span>
                <i
                    class="fa fa-info-circle tw-ml-1 tw-text-fs-150"
                    [matTooltip]="
                        'Choosing this option will not create a customer in your tenant; we are only taking input for additional information.'
                    "
                ></i>
            </mat-radio-button>
            <mat-radio-button
                class="tw-mr-12"
                [checked]="
                    assessmentType == eAssessmentType.BOTHASSESSORANDASSESSE
                "
                value="Dependent"
            >
                <span class="common-label">Both Assessor & Assessee</span>
                <i
                    class="fa fa-info-circle tw-ml-1 tw-text-fs-150"
                    [matTooltip]="
                        'If you are choosing this, a new customer will be created in your tenant.'
                    "
                ></i>
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <div
        class="only-assessor"
        *ngIf="assessmentType == eAssessmentType.ASSESSOR"
    >
        <div class="independent-container">
            <hr class="tw-my-5" />
            <span class="tw-text-accent tw-text-fs-600">Partner Information</span>
            <div class="choose-assessor-type tw-flex tw-mt-5">
                <span
                    class="tw-w- tw-text-fs-400 tw-font-semibold choose-assesse-label tw-flex tw-justify-center"
                    >Assessee Type <span class="tw-text-red-600">*</span></span
                >
                <mat-radio-group
                    aria-label="Select Dependent Type"
                    class="tw-flex"
                    name="assessorType"
                    (change)="assessorType = $event.value"
                >
                    <mat-radio-button
                        class="tw-mr-12"
                        [checked]="assessorType == selectedAssessorType.NEW"
                        value="New"
                    >
                        <span class="common-label">New</span>
                    </mat-radio-button>
                    <mat-radio-button
                        class="tw-mr-12"
                        [checked]="assessorType == selectedAssessorType.EXISTING"
                        value="Existing"
                    >
                        <span class="common-label">Existing</span>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="assessorType == selectedAssessorType.NEW" 
                class="assessor-form">
                <div class="assessor-first-form-container tw-mt-3">
                    <span class="details">Assessment Information</span>
                    <app-form-generator
                        *ngIf="onlyAssessorFormGenInput"
                        [formGeneratorInput]="onlyAssessorFormGenInput"
                        (formGroupRef)="onlyAssessorFormGroupRef = $event"
                    >
                    </app-form-generator>
                </div>
                <div class="customer-tag tw-flex tw-justify-center tw-my-2">
                    <div class="container tw-w-4/5 tw-flex tw-justify-between">
                        <span class="tw-text-fs-400 tw-font-semibold"
                            >Assessment Tag</span
                        >
                        <app-tag-generator
                            [extraClass]="'tagMenuResize'"
                            [selectedTags]="
                                selectedTagsData && selectedTagsData.length
                                    ? selectedTagsData
                                    : []
                            "
                            [availableTags]="
                                availableTagsData && availableTagsData.length
                                    ? availableTagsData
                                    : []
                            "
                            (tagsDataEmitter)="updateTags($event)"
                            [showSaveButton]="true"
                        ></app-tag-generator>
                    </div>
                </div>
                <div class="assesor-second-form-container">
                    <app-form-generator
                        *ngIf="assessorInfoFormGenInput"
                        [formGeneratorInput]="assessorInfoFormGenInput"
                        (formGroupRef)="assessorInfoFormRef = $event"
                    >
                    </app-form-generator>
                </div>
                <div class="button">
                    <app-button-generator
                        class="tw-ml-auto tw-mb-4 tw-mr-5"
                        [buttonGenInput]="issueButtonGenInput"
                    ></app-button-generator>
                </div>
            </div>
            <div *ngIf="assessorType == selectedAssessorType.EXISTING" 
                class="assessor-form">
                <div class="assessor-first-form-container tw-mt-3">
                    <span class="details">Assessment Information</span>
                    <app-form-generator
                        *ngIf="assessorExistingDetailsFormGenInput"
                        [formGeneratorInput]="assessorExistingDetailsFormGenInput"
                        (formGroupRef)="onlyAssessorFormGroupRef = $event"
                    >
                    </app-form-generator>
                </div>
                <div class="customer-tag tw-flex tw-justify-center tw-my-2">
                    <div class="container tw-w-4/5 tw-flex tw-justify-between">
                        <span class="tw-text-fs-400 tw-font-semibold"
                            >Assessment Tag</span
                        >
                        <app-tag-generator
                            [extraClass]="'tagMenuResize'"
                            [selectedTags]="
                                selectedTagsData && selectedTagsData.length
                                    ? selectedTagsData
                                    : []
                            "
                            [availableTags]="
                                availableTagsData && availableTagsData.length
                                    ? availableTagsData
                                    : []
                            "
                            (tagsDataEmitter)="updateTags($event)"
                            [showSaveButton]="true"
                        ></app-tag-generator>
                    </div>
                </div>
                <div class="assesor-second-form-container">
                    <app-form-generator
                        *ngIf="assessorInfoFormGenInput"
                        [formGeneratorInput]="assessorInfoFormGenInput"
                        (formGroupRef)="assessorInfoFormRef = $event"
                    >
                    </app-form-generator>
                </div>
                <div class="button">
                    <app-button-generator
                        class="tw-ml-auto tw-mb-4 tw-mr-5"
                        [buttonGenInput]="issueButtonGenInput"
                    ></app-button-generator>
                </div>
            </div>
        </div>
    </div>
    <div
        class="both-assessor-and-assesse"
        *ngIf="assessmentType == eAssessmentType.BOTHASSESSORANDASSESSE"
    >
        <hr class="tw-my-5" />
        <span class="tw-text-accent tw-text-fs-600">Partner Information</span>
        <div class="choose-assesse-type tw-flex tw-mt-5">
            <span
                class="tw-w- tw-text-fs-400 tw-font-semibold choose-assesse-label tw-flex tw-justify-center"
                >Assessee Type <span class="tw-text-red-600">*</span></span
            >
            <mat-radio-group
                aria-label="Select Dependent Type"
                class="tw-flex"
                name="dependentType"
                (change)="dependentType = $event.value"
            >
                <mat-radio-button
                    class="tw-mr-12"
                    [checked]="dependentType == eDependentType.NEW"
                    value="New"
                >
                    <span class="common-label">New</span>
                </mat-radio-button>
                <mat-radio-button
                    class="tw-mr-12"
                    [checked]="dependentType == eDependentType.EXISTING"
                    value="Existing"
                >
                    <span class="common-label">Existing</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div
            class="existing tw-mt-3"
            *ngIf="dependentType == eDependentType.EXISTING"
        >
            <div class="select-customer-form">
                <app-form-generator
                    *ngIf="existingFormGenInput"
                    [formGeneratorInput]="existingFormGenInput"
                    (formGroupRef)="existingFormRef = $event"
                >
                </app-form-generator>
            </div>
            <div class="customer-tag tw-flex tw-justify-center tw-my-2">
                <div class="container tw-w-4/5 tw-flex tw-justify-between">
                    <span class="tw-text-fs-400 tw-font-semibold"
                        >Assessment Tag</span
                    >
                    <app-tag-generator
                        [extraClass]="'tagMenuResize'"
                        [selectedTags]="
                            selectedTagsData && selectedTagsData.length
                                ? selectedTagsData
                                : []
                        "
                        [availableTags]="
                            availableTagsData && availableTagsData.length
                                ? availableTagsData
                                : []
                        "
                        (tagsDataEmitter)="updateTags($event)"
                        [showSaveButton]="true"
                    ></app-tag-generator>
                </div>
            </div>
            <div class="third-form-container">
                <app-form-generator
                    *ngIf="assessorInfoFormGenInput"
                    [formGeneratorInput]="assessorInfoFormGenInput"
                    (formGroupRef)="assessorInfoFormRef = $event"
                >
                </app-form-generator>
            </div>
            <div class="button">
                <app-button-generator
                    class="tw-ml-auto tw-mb-4 tw-mr-5"
                    [buttonGenInput]="nextButtonGenInput"
                ></app-button-generator>
            </div>
        </div>
        <div class="new" *ngIf="dependentType == eDependentType.NEW">
            <div *ngIf="!isEdit" class="main-container tw-mt-3">
                <div class="first-form-container">
                    <span class="details">Assessee Information</span>
                    <app-form-generator
                        *ngIf="assesseeInfoFormGenInput"
                        [formGeneratorInput]="assesseeInfoFormGenInput"
                        (formGroupRef)="assesseeInfoFormRef = $event"
                    >
                    </app-form-generator>
                </div>
                <div class="customer-tag tw-flex tw-justify-center tw-my-2">
                    <div class="container tw-w-4/5 tw-flex tw-justify-between">
                        <span class="tw-text-fs-400 tw-font-semibold"
                            >Assessment Tag</span
                        >
                        <app-tag-generator
                            [extraClass]="'tagMenuResize'"
                            [selectedTags]="
                                selectedTagsData && selectedTagsData.length
                                    ? selectedTagsData
                                    : []
                            "
                            [availableTags]="
                                availableTagsData && availableTagsData.length
                                    ? availableTagsData
                                    : []
                            "
                            (tagsDataEmitter)="updateTags($event)"
                            [showSaveButton]="true"
                        ></app-tag-generator>
                    </div>
                </div>
                <div class="second-form-container">
                    <span class="details">Root User</span>
                    <app-form-generator
                        *ngIf="rootUserInfoFormGenInput"
                        [formGeneratorInput]="rootUserInfoFormGenInput"
                        (formGroupRef)="rootUserInfoFormRef = $event"
                    >
                    </app-form-generator>
                </div>
                <div class="third-form-container">
                    <span class="details">Assessment Information</span>
                    <app-form-generator
                        *ngIf="assessorInfoFormGenInput"
                        [formGeneratorInput]="assessorInfoFormGenInput"
                        (formGroupRef)="assessorInfoFormRef = $event"
                    >
                    </app-form-generator>
                </div>
                <div class="button">
                    <app-button-generator
                        class="tw-ml-auto tw-mb-4 tw-mr-5"
                        [buttonGenInput]="nextButtonGenInput"
                    ></app-button-generator>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isEdit" class="main-container edit-form">
    <div class="customer-tag tw-flex tw-justify-center tw-my-2">
        <div class="container tw-w-full tw-flex tw-justify-between">
            <span class="tw-text-fs-400 tw-font-semibold">Assessment Tag</span>
            <app-tag-generator
                [extraClass]="'tagMenuResize'"
                [selectedTags]="
                    selectedTagsData && selectedTagsData.length
                        ? selectedTagsData
                        : []
                "
                [availableTags]="
                    availableTagsData && availableTagsData.length
                        ? availableTagsData
                        : []
                "
                (tagsDataEmitter)="updateTags($event)"
                [showSaveButton]="true"
            ></app-tag-generator>
        </div>
    </div>
    <app-form-generator
        *ngIf="assessorInfoFormGenInput"
        [formGeneratorInput]="assessorInfoFormGenInput"
        (formGroupRef)="assessorInfoFormRef = $event"
    >
    </app-form-generator>
    <div class="button">
        <app-button-generator
            class="tw-ml-auto tw-mb-4 tw-mr-5"
            [buttonGenInput]="nextButtonGenInput"
        ></app-button-generator>
    </div>
</div>
