import { Component, OnInit } from '@angular/core';
import { GridOptions, RowEvent, ValueGetterParams } from 'ag-grid-community';
import * as $ from 'jquery';
import { BehaviorSubject } from 'rxjs';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IColumnData } from 'src/app/shared/interfaces/table-generator/IColumnData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { AddResourceTagsComponent } from '../../../modal-templates/allocation-checks-modal/add-resource-tags/add-resource-tags.component';
import { ConventionsModalComponent } from '../../../modal-templates/allocation-checks-modal/conventions-modal/conventions-modal.component';
import { DeleteResourceTagsComponent } from '../../../modal-templates/allocation-checks-modal/delete-resource-tags/delete-resource-tags.component';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-allocation-checks-v3',
    templateUrl: './allocation-checks-v3.component.html',
    styleUrls: ['./allocation-checks-v3.component.sass']
})
export class AllocationChecksV3Component implements OnInit {
    tableInput: ITableGeneratorInput;
    widgetRef: Widget;
    agGrid: GridOptions = null;
    cellValueChanged = null;
    selectionChanged = null;
    RED_SEV = `<span>
    <i class="fas fa-times-circle red-severity-color"></i>
                </span>`;
    YELLOW_SEV = `<span>
        <i class="fas fa-exclamation-triangle yellow-severity-color"></i>
                </span>`;
    GREEN_SEV = `<span>
        <i class="fas fa-check-circle green-severity-color"></i>
                </span>`;

    selectAll = false;
    showConvention = false;
    conventionLength: Number;
    showButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    allocationChecksApiResponse = null;
    severityButtons: IButtonGeneratorInput[];
    conventionButton: IButtonGeneratorInput;
    buttonInputs: IButtonGeneratorInput[];

    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService
    ) {
        this.widgetRef = widgetData.widgetRef;
        this.setUpBasics();
    }

    ngOnInit(): void {}

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(true);

        this.tableInput = {
            afterResponse: (response) => {
                this.addTableColumns(response);
                this.setWidgetIconAndConvention(response);
                this.allocationChecksApiResponse = response;
                this.initButtonGenInput();
                this.showButton.next(true);
            },
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-check-circle',
                extraClass: 'green-severity-color'
            },
            selection: 'multiple',
            buttons: null,
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'dataList'
            },
            showViewIcon: true,
            columns: []
        };

        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);
        this.setUpRowClassRules();
        this.buttonInputs = [
            {
                buttonName: 'Add/Update Tags',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                function: () => {
                    this.addResourceTag();
                }
            },
            {
                buttonName: 'Delete Tags',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.WARN,
                function: () => {
                    this.deleteResourceTags();
                }
            }
        ];
    }

    initButtonGenInput() {
        const buttonData = {};
        let index = 0;

        const criticalButton: IButtonGeneratorInput = {
            buttonName: `Critical : ${this.widgetRef.apiResponse['dataMap']['totalSeverity']['_1']}`,
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.WARN,
            hoverText: 'Critical',
            hoverEffect: 'shadow',
            function: (buttoRef: IButtonGeneratorInput) => {
                if (
                    Helper.changeButtonType(
                        buttonData,
                        this.severityButtons,
                        'Critical',
                        this.severityFilter.bind(this)
                    )
                ) {
                    this.severityFilter('_1');
                }
            }
        };

        const warningButton: IButtonGeneratorInput = {
            buttonName: `Warning : ${this.widgetRef.apiResponse['dataMap']['totalSeverity']['_2']}`,
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.PRIMARY,
            hoverText: 'Warning',
            hoverEffect: 'shadow',
            function: (buttoRef: IButtonGeneratorInput) => {
                if (
                    Helper.changeButtonType(
                        buttonData,
                        this.severityButtons,
                        'Warning',
                        this.severityFilter.bind(this)
                    )
                ) {
                    this.severityFilter('_2');
                }
            }
        };

        const okButton: IButtonGeneratorInput = {
            buttonName: `OK : ${this.widgetRef.apiResponse['dataMap']['totalSeverity']['_3']}`,
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.SUCCESS,
            hoverText: 'OK',
            hoverEffect: 'shadow',
            function: (buttoRef: IButtonGeneratorInput) => {
                if (
                    Helper.changeButtonType(
                        buttonData,
                        this.severityButtons,
                        'OK',
                        this.severityFilter.bind(this)
                    )
                ) {
                    this.severityFilter('_3');
                }
            }
        };

        this.severityButtons = [];

        if (this.widgetRef.apiResponse['dataMap']['totalSeverity']['_1']) {
            this.severityButtons.push(criticalButton);
            buttonData['Critical'] = index++;
        }
        if (this.widgetRef.apiResponse['dataMap']['totalSeverity']['_2']) {
            this.severityButtons.push(warningButton);
            buttonData['Warning'] = index++;
        }
        if (this.widgetRef.apiResponse['dataMap']['totalSeverity']['_3']) {
            this.severityButtons.push(okButton);
            buttonData['OK'] = index++;
        }

        if (this.showConvention) {
            this.conventionButton = {
                buttonName: `Conventions : ${this.conventionLength}`,
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.SECONDARY,
                hoverText: 'Conventions',
                function: this.showConventions.bind(this)
            };
        }
    }

    setUpRowClassRules() {
        this.widgetRef.tableRowClassRules = {
            agRowMod: (params) => {
                if (
                    this.agGrid &&
                    this.agGrid.api.getSelectedNodes().length >=
                        GlobalConfiguration.RESOURCE_SELECTION_LIMIT_V2
                ) {
                    return params.node.selected === false;
                }
                return false;
            }
        };
    }

    onSelectionChanged() {
        if (
            this.agGrid.api.getSelectedNodes().length >
            GlobalConfiguration.RESOURCE_SELECTION_LIMIT_V2
        ) {
            // Select All case
            this.selectAll = !this.selectAll;
            if (this.selectAll) {
                this.agGrid.api.deselectAll();
                this.agGrid.api.forEachNode((node) =>
                    node.rowIndex >=
                    GlobalConfiguration.RESOURCE_SELECTION_LIMIT_V2
                        ? 0
                        : node.setSelected(true)
                );
            } else {
                this.agGrid.api.deselectAll();
            }
            this.agGrid.api.redrawRows();
        } else if (
            this.agGrid.api.getSelectedNodes().length ===
            GlobalConfiguration.RESOURCE_SELECTION_LIMIT_V2
        ) {
            this.widgetRef.notificationsService.showSnackBar(
                `Maximum ${GlobalConfiguration.RESOURCE_SELECTION_LIMIT_V2} tags allowed`
            );
            this.agGrid.api.redrawRows();
        } else {
            this.selectAll = false;
        }
    }

    onCellValueChanged(data) {
        if (data.newValue.length === 0) {
            this.widgetRef.notificationsService.showSnackBar(
                `Value field can't be empty.`,
                true
            );
        }
        if (data.newValue.length > 0) {
            const headerName = data.colDef.headerName;
            // Traversing Applied Tags
            for (
                let i = 0, appliedTags = data.data.appliedTags;
                i < appliedTags.length;
                ++i
            ) {
                if (appliedTags[i].split('|')[0].trim() === headerName) {
                    appliedTags[i] = headerName + ' | ' + data.newValue;
                }
            }
            // Traversing Extra Tags
            for (
                let i = 0, extraTags = data.data.extraTags;
                i < extraTags.length;
                ++i
            ) {
                if (extraTags[i].split('|')[0].trim() === headerName) {
                    extraTags[i] = headerName + ' | ' + data.newValue;
                }
            }
            // Traversing Missing Tags
            for (
                let i = 0, missingTags = data.data.missingTags;
                i < missingTags.length;
                ++i
            ) {
                if (missingTags[i].split('|')[0].trim() === headerName) {
                    missingTags[i] = headerName + ' | ' + data.newValue;
                }
            }
            this.agGrid.api.redrawRows();
            const inputData = {};
            let accountsData = {};
            const columnChange =
                this.allocationChecksApiResponse['dataMap']['columnsToChoose'];
            accountsData = this.mapToRequiredInputs(columnChange, data.data);
            inputData['resourceTaggingList'] = [accountsData];
            inputData['tagsToApply'] = {};
            const fieldName = data['column']['userProvidedColDef']['field'];
            inputData['tagsToApply'][fieldName] = data.data[fieldName];

            const apiArgs = Helper.generateHitApiConfig(
                this.widgetRef.widgetData.widgetInfo.create
            );

            apiArgs.input = inputData;

            apiArgs.function = (response) => {
                this.widgetRef.notificationsService.showSnackBar(
                    data.column.colId +
                        ' Resource Tag Updated Successfully. Kindly refresh the insight to reflect changes.'
                );
            };
            apiArgs.errorFunction = () => {
                this.widgetRef.notificationsService.showSnackBar(
                    'Error while editing resource tag. Refresh the insight to see accurate data',
                    true
                );
            };
            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }

    addTableColumns(response) {
        const columns: IColumnData[] = [];
        columns.push({
            columnName: 'Severity',
            columnKey: 'severityModel',
            pinned: 'left',
            lockPinned: true,
            cellRenderer: this.columnRenderer.bind(this)
        });
        response['dataMap']['keysToShow'].forEach((colObj) => {
            columns.push({
                columnName: colObj['label'],
                columnKey: colObj['id']
            });
        });

        response['resourceTags'].forEach((tag) => {
            columns.push({
                columnName: tag,
                columnKey: tag,
                cellRenderer: this.tagsRenderer.bind(this),
                editable: true,
                columnValueGetter: (rowData: ValueGetterParams) => {
                    return String(this.tagsRenderer(rowData, true)).trim();
                }
            });
        });
        this.tableInput.columns = columns;
    }

    showConventions() {
        if (
            (this.widgetRef.apiResponse['dataMap']['Conventions'] &&
                Object.keys(
                    this.widgetRef.apiResponse['dataMap']['Conventions']
                ).length > 0) ||
            (this.widgetRef.apiResponse['dataMap']['conventions'] &&
                Object.keys(
                    this.widgetRef.apiResponse['dataMap']['conventions']
                ).length > 0)
        ) {
            const conventions = this.widgetRef.apiResponse['dataMap'][
                'Conventions'
            ]
                ? this.widgetRef.apiResponse['dataMap']['Conventions']
                : this.widgetRef.apiResponse['dataMap']['conventions'];
            const modalData: IModalData = {
                modalName: 'Conventions',
                modalIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-copyright'
                },
                modalType: ModalType.MINI_MODAL,
                sourceId: this.widgetRef.uniqueIdentity,
                modalSteps: [
                    {
                        stepData: {
                            componentToLoad: ConventionsModalComponent,
                            payload: {
                                data: conventions
                            }
                        },
                        stepName: 'Conventions'
                    }
                ]
            };
            this.modalService.openModal(modalData);
        } else {
            this.widgetRef.notificationsService.showSnackBar(
                Messages.NO_CONVENTIONS_FOUND,
                true
            );
        }
    }

    setWidgetIconAndConvention(response) {
        if (!response || !response['dataList'].length) {
            return;
        }

        //Setting Widget Header Icon
        const overallSev = response.dataMap['overallSeverity'];
        if (overallSev === '_1') {
            this.widgetRef.headerIcon.next({
                type: IconType.FONTAWSOME,
                class: 'fas fa-times-circle',
                extraClass: 'red-severity-color'
            });
        } else if (overallSev === '_2') {
            this.widgetRef.headerIcon.next({
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclamation-triangle',
                extraClass: 'yellow-severity-color'
            });
        } else {
            this.widgetRef.headerIcon.next({
                class: 'assets/green_check.png',
                type: IconType.IMAGE
            });
        }

        //Checking for Convention
        this.conventionLength =
            response.dataMap['Conventions'] &&
            Object.keys(response.dataMap['Conventions']).length > 0
                ? Object.keys(response.dataMap['Conventions']).length
                : response.dataMap['conventions'] &&
                  Object.keys(response.dataMap['conventions']).length > 0
                ? Object.keys(response.dataMap['conventions']).length
                : null;
        if (this.conventionLength) {
            this.showConvention = true;
            this.widgetRef.changeDetectorRef.detectChanges();
        }
    }

    severityFilter(severityType?: string) {
        if (severityType) {
            const filteredRows = this.widgetRef.apiResponse['dataList'].filter(
                (rowData) => rowData['severity'] === severityType
            );
            this.agGrid.api.setRowData(filteredRows);
            this.agGrid.api.refreshCells();
        } else {
            this.agGrid.api.setRowData(this.widgetRef.apiResponse['dataList']);
            this.agGrid.api.refreshCells();
        }
    }

    columnRenderer(rowData: RowEvent) {
        const sev = rowData.data['severity'];
        if (sev === '_1') {
            return this.RED_SEV;
        } else if (sev === '_2') {
            return this.YELLOW_SEV;
        } else {
            return this.GREEN_SEV;
        }
    }

    tagsRenderer(
        rowData: RowEvent | ValueGetterParams,
        isColValGetter?: boolean
    ) {
        const headerName = rowData['colDef']['headerName'];
        // Traversing Applied Tags
        for (
            let i = 0, appliedTags = rowData.data.appliedTags;
            i < appliedTags.length;
            ++i
        ) {
            if (appliedTags[i].split('|')[0].trim() === headerName) {
                const value = appliedTags[i].split('|')[1];
                if (isColValGetter) {
                    return value ? value : '';
                }
                const div = document.createElement('div');
                $(div).css({
                    color: Helper.getCssVarValue('secondaryTextColor'),
                    'text-align': 'center',
                    background: Helper.getCssVarValue('okSeverityColor'),
                    padding: '4px 8px'
                });

                $(div).append(value);
                return div;
            }
        }
        // Traversing Extra Tags
        for (
            let i = 0, extraTags = rowData.data.extraTags;
            i < extraTags.length;
            ++i
        ) {
            if (extraTags[i].split('|')[0].trim() === headerName) {
                const value = extraTags[i].split('|')[1];
                if (isColValGetter) {
                    return value ? value : '';
                }
                const div = document.createElement('div');
                $(div).css({
                    color: Helper.getCssVarValue('secondaryTextColor'),
                    'text-align': 'center',
                    background: Helper.getCssVarValue('graySeverityColor')
                });

                $(div).append(value);
                return div;
            }
        }
        // Traversing Missing Tags
        for (
            let i = 0, missingTags = rowData.data.missingTags;
            i < missingTags.length;
            ++i
        ) {
            if (missingTags[i].split('|')[0].trim() === headerName) {
                const value = missingTags[i].split('|')[1];
                if (isColValGetter) {
                    return value ? value : '';
                }
                const div = document.createElement('div');
                $(div).css({
                    color: Helper.getCssVarValue('secondaryTextColor'),
                    'text-align': 'center',
                    background: Helper.getCssVarValue('criticalSeverityColor')
                });

                $(div).append(value);
                return div;
            }
        }
    }

    addResourceTag() {
        if (this.agGrid.api.getSelectedNodes().length === 0) {
            this.widgetRef.notificationsService.showSnackBar(
                'Select Atleast One Resource To Add/Update Resource Tag',
                true
            );
            return;
        }
        if (
            this.agGrid.api.getSelectedNodes().length >
            GlobalConfiguration.RESOURCE_SELECTION_LIMIT_V2
        ) {
            this.widgetRef.notificationsService.showSnackBar(
                `You Can Select Maximum ${GlobalConfiguration.RESOURCE_SELECTION_LIMIT_V2} Resources To Add/Updated Resource Tag`,
                true
            );
            return;
        }
        const inputDataForCreatingResourceTag = {};
        inputDataForCreatingResourceTag['resourceTaggingList'] = [];

        const columnChange =
            this.allocationChecksApiResponse['dataMap']['columnsToChoose'];

        this.agGrid.api.getSelectedNodes().forEach((item) => {
            inputDataForCreatingResourceTag['resourceTaggingList'].push(
                this.mapToRequiredInputs(columnChange, item.data)
            );
        });
        const modalData: IModalData = {
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-tag'
            },
            modalName: 'Add/Update Resource Tags',
            modalType: ModalType.MINI_MODAL,
            sourceId: this.widgetRef.uniqueIdentity,
            modalSteps: [
                {
                    stepName: 'Add/Update Resource Tags',
                    stepData: {
                        componentToLoad: AddResourceTagsComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                inputDataForCreatingResourceTag,
                                function:
                                    this.addResourceTagAndRefresh.bind(this)
                            }
                        }
                    }
                }
            ]
        };

        this.modalService.openModal(modalData);
    }

    addResourceTagAndRefresh(
        inputDataForCreatingResourceTag,
        addResourceTagJsonObjectData,
        buttonRef: IButtonGeneratorInput,
        modalId: Symbol
    ) {
        inputDataForCreatingResourceTag['tagsToApply'] =
            addResourceTagJsonObjectData;

        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.create
        );

        apiArgs.input = inputDataForCreatingResourceTag;
        apiArgs.function = (response) => {
            if (
                response['generalException'].length > 0 ||
                response['insufficientPermission'].length > 0 ||
                response['invalidCredentials'].length > 0 ||
                response['noCredentialsDB'].length > 0
            ) {
                this.widgetRef.notificationsService.showSnackBar(
                    'Resource Tag(s) have been added/updated for account(s) with sufficient permission(s).'
                );
            } else {
                this.widgetRef.notificationsService.showSnackBar(
                    'Resource Tag(s) Added/Updated Successfully'
                );
            }
            this.widgetRef.refreshWidget();
            buttonRef.loader = false;
            this.modalService.closeModal(null, modalId);
        };
        apiArgs.errorFunction = () => {
            this.widgetRef.notificationsService.showSnackBar(
                'Error While Adding/Updating Resource Tag(s)',
                true
            );
            buttonRef.loader = false;
            this.modalService.closeModal(null, modalId);
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    deleteResourceTags() {
        if (this.agGrid.api.getSelectedNodes().length === 0) {
            this.widgetRef.notificationsService.showSnackBar(
                'Select Atleast One Resource To Delete Resource Tag',
                true
            );
            return;
        }
        if (
            this.agGrid.api.getSelectedNodes().length >
            GlobalConfiguration.RESOURCE_SELECTION_LIMIT_V2
        ) {
            this.widgetRef.notificationsService.showSnackBar(
                `You Can Select Maximum ${GlobalConfiguration.RESOURCE_SELECTION_LIMIT_V2} Resources To Delete Resource Tag`,
                true
            );
            return;
        }

        const inputDataFoDeletingResourceTag = {};
        inputDataFoDeletingResourceTag['resourceTaggingList'] = [];

        const columnChange =
            this.allocationChecksApiResponse['dataMap']['columnsToChoose'];

        this.agGrid.api.getSelectedNodes().forEach((item) => {
            inputDataFoDeletingResourceTag['resourceTaggingList'].push(
                this.mapToRequiredInputs(columnChange, item.data)
            );
        });
        const modalData: IModalData = {
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-tag'
            },
            modalName: 'Delete Resource Tags',
            modalType: ModalType.MINI_MODAL,
            sourceId: this.widgetRef.uniqueIdentity,
            modalSteps: [
                {
                    stepName: 'Delete Resource Tags',
                    stepData: {
                        componentToLoad: DeleteResourceTagsComponent,
                        payload: {
                            data: {
                                inputDataFoDeletingResourceTag,
                                resourceTagKeys:
                                    this.widgetRef.apiResponse['resourceTags'],
                                function:
                                    this.deleteResourceTagsAndRefresh.bind(this)
                            }
                        }
                    }
                }
            ],
            modalHeightVh: 30
        };

        this.modalService.openModal(modalData);
    }

    deleteResourceTagsAndRefresh(
        inputDataFoDeletingResourceTag,
        resourceTags,
        buttonRef: IButtonGeneratorInput,
        modalId: Symbol
    ) {
        const resourceTagsToDelete = resourceTags.map((resourceKey) =>
            resourceKey['id'] ? resourceKey['id'] : resourceKey
        );
        inputDataFoDeletingResourceTag['tagsToDelete'] = resourceTagsToDelete;

        const deleteApiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.delete
        );
        deleteApiArgs.input = inputDataFoDeletingResourceTag;
        deleteApiArgs.function = (response) => {
            if (
                response['generalException'].length > 0 ||
                response['insufficientPermission'].length > 0 ||
                response['invalidCredentials'].length > 0 ||
                response['noCredentialsDB'].length > 0
            ) {
                this.widgetRef.notificationsService.showSnackBar(
                    'Resource Tag(s) have been deleted for account(s) with sufficient permission(s).'
                );
            } else {
                this.widgetRef.notificationsService.showSnackBar(
                    'Resource Tag(s) Deleted Successfully'
                );
            }
            buttonRef.loader = false;
            this.modalService.closeModal(null, modalId);
            this.widgetRef.refreshWidget();
        };
        deleteApiArgs.errorFunction = () => {
            this.widgetRef.notificationsService.showSnackBar(
                'Error While Deleting Resource Tag(s)',
                true
            );
            buttonRef.loader = false;
            this.modalService.closeModal(null, modalId);
        };

        new HitApi(
            deleteApiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    mapToRequiredInputs(changeMap, data) {
        const obj = {};
        const keysToShow =
            this.allocationChecksApiResponse['dataMap']['keysToShow'];
        Object.keys(changeMap).forEach((item) => {
            const field = keysToShow.find(
                (field) => field.label === changeMap[item]
            );
            obj[item] = data[field['id']];
        });
        return obj;
    }
}
