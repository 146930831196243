import { Component, OnInit, TemplateRef } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { Widget } from 'src/app/shared/classes/Widget';
import { IconType } from 'src/app/shared/enums/IconType';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { ModalInjectedData } from './../../../classes/ModalInjectedData';

@Component({
    selector: 'app-common-more-info-modal',
    templateUrl: './common-more-info-modal.component.html',
    styleUrls: ['./common-more-info-modal.component.sass']
})
export class CommonMoreInfoModalComponent implements OnInit {
    widgetRef: Widget;
    moreInfoData;
    getKeys = Object.keys;
    getValues = Object.values;
    showData: { [key: string]: Boolean }[] = [];
    crossIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fa fa-times'
    };
    totalTabs: number;
    tabDescription: string;
    warningMessageTemplate: TemplateRef<any>;
    ignoreCamelCaseToNormalPipe: boolean;
    noAccordion: boolean;
    constructor(
        public modalInjectedData: ModalInjectedData,
        public modalService: ModalService
    ) {
        this.moreInfoData = modalInjectedData['data']['stepData'];
        this.noAccordion = modalInjectedData['data']['noAccordion'];
        this.tabDescription = this.getKeys(this.moreInfoData)[0];
        this.ignoreCamelCaseToNormalPipe =
            modalInjectedData['data']['ignoreCamelCaseToNormalPipe'];
        this.warningMessageTemplate =
            modalInjectedData['data']['warningMessageTemplate'];
    }

    ngOnInit(): void {
        this.getHeadingValues();
        this.totalTabs = this.showData.length;
    }
    getHeadingValues() {
        for (const heading of this.getKeys(this.moreInfoData)) {
            this.showData.push({ [heading]: true });
        }
    }
    toShowFieldOrNot(heading) {
        for (const index in this.getKeys(this.showData)) {
            if (this.getKeys(this.showData[index])[0] === heading) {
                this.showData[index][heading] = !this.showData[index][heading];
            }
        }
    }
    changeTabStyle(tab) {
        this.tabDescription = tab;
        Helper.doScroll(tab, 'start');
    }

    isArray(data) {
        if (data instanceof Array) {
            return true;
        } else {
            return false;
        }
    }
    validateTrim(value?: any) {
        if (typeof value === 'string') {
            return value.trim();
        } else {
            return value;
        }
    }
}
