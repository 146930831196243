import { Component, OnInit } from '@angular/core';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import {
    DocumentTypes,
    IDetailedDescription
} from 'src/app/shared/interfaces/widget/IWidgetDescription';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { WidgetDocumentationMarkdownGuideModalComponent } from './../widget-documentation-markdown-guide-modal/widget-documentation-markdown-guide-modal.component';
import { WidgetDocumentationMoreInfoModalComponent } from './../widget-documentation-more-info-modal/widget-documentation-more-info-modal.component';

@Component({
    selector: 'app-create-widget-documentation-step-two-modal',
    templateUrl: './create-widget-documentation-step-two-modal.component.html',
    styleUrls: ['./create-widget-documentation-step-two-modal.component.sass']
})
export class CreateWidgetDocumentationStepTwoModalComponent implements OnInit {
    filteredDocumentTypes: CmsContent[];
    widgetRef: Widget;
    infoIcon: IIcon = {
        type: IconType.MATICON,
        class: 'info_outlined'
    };
    crossIcon: IIcon = {
        type: IconType.MATICON,
        class: 'cancel_outlined'
    };
    s3FolderName: string;
    loaderGroup = {
        saveAsDraft: false,
        save: false,
        preview: false
    };

    contentLimit = {
        [DocumentTypes.IMAGES]: 20,
        [DocumentTypes.VIDEOS]: 1
    };

    contentSizeLimit = {
        [DocumentTypes.IMAGES]: 10, // In MB
        [DocumentTypes.VIDEOS]: 100 // In MB
    };

    contentLimitMessage = {
        [DocumentTypes.IMAGES]: `Supports only ${
            this.contentLimit[DocumentTypes.IMAGES]
        }  images of maximum size ${
            this.contentSizeLimit[DocumentTypes.IMAGES]
        } MB each`,
        [DocumentTypes.VIDEOS]: `Supports only ${
            this.contentLimit[DocumentTypes.VIDEOS]
        } video of maximum size ${
            this.contentSizeLimit[DocumentTypes.VIDEOS]
        } MB`
    };

    documentTypesEnums = DocumentTypes;

    documentTypes: CmsContent[] = [];

    fileUploadIcon: IIcon = {
        type: IconType.MATICON,
        class: 'file_upload'
    };

    selectedDocument: CmsContent = null;
    contentFormGenInput: IFormGeneratorInput = null;
    widgetIds: string[];
    templateData: IDetailedDescription[];
    isEdit: boolean;
    hideBackButton: boolean = false;
    buttonInputs: IButtonGeneratorInput[];
    topHeaderButtons: IButtonGeneratorInput[];

    constructor(
        private modalInputData: ModalInjectedData,
        private modalService: ModalService,
        private multiStepFormService: MultiStepFormService,
        private listHttpService: ListHttpService
    ) {
        this.widgetRef = modalInputData.data['widgetRef'];
        this.templateData = modalInputData.data['templateData'];
        this.isEdit = modalInputData.data['isEdit'];
        this.hideBackButton = modalInputData.data['hideBackButton'];
    }

    ngOnInit(): void {
        this.initDocumentTypes();
        this.widgetIds = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(1);

        // reset subscription
        this.modalService.resetModal.subscribe(() => {
            if (this.isEdit) {
                this.mapTemplateDataToDocumentData();
            } else {
                this.initDocumentTypes();
                if (this.selectedDocument) {
                    this.selectedDocument.content = '';
                    if (this.selectedDocument.listData) {
                        this.selectedDocument.listData = [];
                    }
                }
            }
        });

        if (this.isEdit) {
            this.mapTemplateDataToDocumentData();
        }
        this.buttonInputs = [
            {
                buttonName: 'Back',
                buttonColorType: ButtonColorType.PRIMARY,
                function: this.back.bind(this),
                buttonType: ButtonType.FLAT,
                hide: this.hideBackButton,
                customClass: 'back-button'
            },
            {
                buttonName: 'Publish',
                buttonColorType: ButtonColorType.PRIMARY,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.uploadDocuments(false, buttonRef);
                },
                buttonType: ButtonType.FLAT,
                showLoader: true
            },
            {
                buttonName: 'Save as Draft',
                buttonColorType: ButtonColorType.PRIMARY,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.uploadDocuments(true, buttonRef);
                },
                showLoader: true,
                buttonType: ButtonType.FLAT
            }
        ];
        this.topHeaderButtons = [
            {
                buttonName: 'Preview',
                buttonColorType: ButtonColorType.SUCCESS,
                buttonType: ButtonType.TEXT,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.openPreviewModal(buttonRef);
                }
            },
            {
                buttonType: ButtonType.TEXT,
                buttonColorType: ButtonColorType.INFO,
                buttonName: 'Markdown Guide',
                function: this.openMarkdownModal.bind(this)
            }
        ];

        this.documentSelectionChange(this.documentTypes[0]);
    }

    initDocumentTypes() {
        const selectedDocument = this.selectedDocument;
        this.selectedDocument = null;
        this.documentTypes = [
            {
                type: DocumentTypes.DESCRIPTION,
                content: ``
            },
            {
                type: DocumentTypes.DETAILED_DESCRIPTION,
                content: ``
            },
            {
                type: DocumentTypes.IMAGES,
                content: ``,
                listData: [],
                contentAccept: 'image/png, image/gif, image/jpeg'
            },
            {
                type: DocumentTypes.VIDEOS,
                content: ``,
                listData: [],
                contentAccept: 'video/*'
            },
            {
                type: DocumentTypes.REFERENCE,
                content: ``
            },
            {
                type: DocumentTypes.USE_CASES,
                content: ``
            },
            {
                type: DocumentTypes.FAQ,
                content: ``
            }
        ];

        this.filteredDocumentTypes = this.documentTypes;

        this.documentSelectionChange(selectedDocument);
    }

    mapTemplateDataToDocumentData() {
        const selectedDocument = this.selectedDocument;

        this.selectedDocument = null;

        this.templateData.map((each) => {
            this.documentTypes.forEach((eachDocument) => {
                if (each.type === eachDocument.type) {
                    if (
                        each.type === DocumentTypes.IMAGES ||
                        each.type === DocumentTypes.VIDEOS
                    ) {
                        eachDocument.listData = [];
                        this.s3FolderName = this.widgetIds[0];
                        each.links.map((eachMedia) => {
                            const getName = (name) => {
                                if (name) {
                                    const arr = name.split('/');
                                    if (arr.length) {
                                        return arr[arr.length - 1];
                                    } else {
                                        return '';
                                    }
                                } else {
                                    return '';
                                }
                            };

                            eachDocument.listData.push({
                                selectionStatus: true,
                                uploadStatus: true,
                                data: { name: getName(eachMedia.name) },
                                previewData: eachMedia.link,
                                keys: eachMedia.name,
                                path: `${
                                    each.type === DocumentTypes.IMAGES
                                        ? 'images'
                                        : 'videos'
                                }/${this.widgetIds[0]}`
                            });
                        });
                    } else {
                        eachDocument.content = each.content;
                    }
                }
            });
        });

        this.documentSelectionChange(selectedDocument);
    }

    documentSelectionChange(data) {
        if (!data) {
            return;
        }

        if (this.selectedDocument && this.selectedDocument.type === data.type) {
            return;
        }

        this.selectedDocument = data;
        this.contentFormGenInput = null;

        if (
            this.selectedDocument.type !== DocumentTypes.IMAGES &&
            this.selectedDocument.type !== DocumentTypes.VIDEOS
        ) {
            setTimeout(() => {
                this.contentFormGenInput = {
                    formName: 'Content Form',
                    submitButton: null,
                    state: FormState.CREATE,
                    fields: [
                        {
                            placeholder: '',
                            fieldType: FilterType.TEXTAREA,
                            name: 'content',
                            label: '',
                            value: this.selectedDocument.content,
                            required: false
                        }
                    ]
                };
            }, 0);
        } else {
            if (
                this.selectedDocument.listData &&
                this.selectedDocument.listData.length === 0
            ) {
                this.generateImageVideoListData();
            }
        }
    }

    generateImageVideoListData(returnData = false) {
        const fileUploadData = {
            id: Helper.generateUniqueKey(6),
            selectionStatus: false,
            uploadStatus: false,
            data: null
        };

        if (returnData) {
            return fileUploadData;
        }
        this.selectedDocument.listData.push(fileUploadData);
        this.updateDocumentData();
    }

    updateDocumentData() {
        this.documentTypes.every((each) => {
            if (each.type === this.selectedDocument.type) {
                each = this.selectedDocument;
                return false;
            }
            return true;
        });
    }

    addImageOrVideo() {
        if (
            this.selectedDocument.type === DocumentTypes.VIDEOS &&
            this.selectedDocument.listData.length ===
                this.contentLimit[DocumentTypes.VIDEOS]
        ) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                `Maximum ${
                    this.contentLimit[DocumentTypes.VIDEOS]
                } is allowed to select.`
            );

            return;
        }

        if (
            this.selectedDocument.type === DocumentTypes.IMAGES &&
            this.selectedDocument.listData.length ===
                this.contentLimit[DocumentTypes.IMAGES]
        ) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                `Maximum ${
                    this.contentLimit[DocumentTypes.IMAGES]
                } is allowed to select.`
            );

            return;
        }

        if (
            !this.selectedDocument.listData[
                this.selectedDocument.listData.length - 1
            ].selectionStatus
        ) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Please upload file in the existing slot'
            );
            return;
        }

        this.generateImageVideoListData();
    }

    fileUploadChange(file, index) {
        if (file && file[0]) {
            const fileSize = (file[0].size / 1024 / 1024).toFixed(4);

            if (fileSize > this.contentSizeLimit[this.selectedDocument.type]) {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    null,
                    `Max file upload size is ${
                        this.contentSizeLimit[this.selectedDocument.type]
                    } MB.`
                );
                return;
            }

            this.selectedDocument.listData[index].selectionStatus = false;
            this.selectedDocument.listData[index].uploadStatus = false;
            const reader = new FileReader();
            reader.onload = (e) => {
                this.selectedDocument.listData[index] = {
                    ...this.selectedDocument.listData[index],
                    previewData: e.target.result
                };
                this.selectedDocument.listData[index].selectionStatus = true;
                this.selectedDocument.listData[index].data = file[0];
                this.updateDocumentData();
                this.startUploading(this.selectedDocument, index);
            };
            reader.readAsDataURL(file[0]);
        }
    }

    startUploading(selectedDocument, index) {
        const key =
            selectedDocument.type === DocumentTypes.IMAGES
                ? 'imageName'
                : 'videoName';

        const data = {
            widgetIds: this.widgetIds,
            presignedUrlRequest: {
                [key]: selectedDocument.listData[index].data.name
            },
            s3FolderName: this.s3FolderName
        };

        const hitApi: IHitApi = Helper.generateHitApiConfig(
            this.listHttpService.getPresignedUrlForWidgetDocumentation
        );

        hitApi.input = data;

        hitApi.function = (response) => {
            this.s3FolderName = response['s3FolderName'];
            const preSignedUrl =
                response[
                    selectedDocument.type === DocumentTypes.IMAGES
                        ? 'imagePresignedUrl'
                        : 'videoPresignedUrl'
                ];

            if (preSignedUrl) {
                const file = selectedDocument.listData[index].data;

                const hitApi: IHitApi = {
                    url: preSignedUrl,
                    requestType: RequestType.PUT,
                    config: {
                        ignoreBaseUrl: true,
                        authorization: null
                    },

                    function: () => {
                        this.documentTypes.every((each) => {
                            if (each.type === selectedDocument.type) {
                                selectedDocument.listData[index].uploadStatus =
                                    true;
                                selectedDocument.listData[index].keys =
                                    selectedDocument.type ===
                                    DocumentTypes.IMAGES
                                        ? response['imageKeys'][0]
                                        : response['videoKey'];

                                selectedDocument.listData[index].path =
                                    selectedDocument.type ===
                                    DocumentTypes.IMAGES
                                        ? response['imagePath'][0]
                                        : response['videoPath'];

                                each = selectedDocument;
                                return false;
                            }
                            return true;
                        });
                        this.save(true, () => {
                            this.documentTypes.every((each) => {
                                if (each.type === selectedDocument.type) {
                                    selectedDocument.listData[
                                        index
                                    ].uploadStatus = true;
                                    each = selectedDocument;
                                    return false;
                                }
                                return true;
                            });
                        });
                    },
                    errorFunction: (error) => {
                        Helper.showErrorMessage(
                            this.widgetRef.notificationsService,
                            error,
                            'Error uploading file.'
                        );
                    },
                    input: file,
                    uniqueIdentity: Symbol(),
                    returnApiCallSubscription: (subscription) => {
                        this.documentTypes.every((each) => {
                            if (each.type === selectedDocument.type) {
                                selectedDocument.listData[index].subscription =
                                    subscription;

                                return false;
                            }
                            return true;
                        });
                    }
                };

                new HitApi(
                    hitApi,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            }
        };
        hitApi.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error uploading file.'
            );
        };
        const http = new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        );
        http.hitApi();
    }

    deleteImageOrVideo(selectedDocument, index) {
        const deleteFunction = () => {
            this.documentTypes.every((each) => {
                if (each.type === selectedDocument.type) {
                    if (selectedDocument.listData[index].subscription) {
                        selectedDocument.listData[
                            index
                        ].subscription.unsubscribe();
                    }

                    selectedDocument.listData.splice(index, 1);

                    if (selectedDocument.listData.length === 0) {
                        selectedDocument.listData.push(
                            this.generateImageVideoListData(true)
                        );
                    }
                    each = selectedDocument;
                    return false;
                }
                return true;
            });
        };

        if (!this.selectedDocument.listData[index].uploadStatus) {
            deleteFunction();

            return;
        }

        this.selectedDocument.listData[index].uploadStatus = false;

        const json =
            selectedDocument.type === DocumentTypes.IMAGES
                ? {
                      imageRemoveFlag: true,
                      removeKey: this.selectedDocument.listData[index].keys
                  }
                : {
                      videoRemoveFlag: true,
                      removeKey: this.selectedDocument.listData[index].keys
                  };

        const hitApi: IHitApi = Helper.generateHitApiConfig(
            this.listHttpService.deleteMediaFileForWidgetDocumentation
        );

        hitApi.intactUrl = hitApi.url;
        hitApi.url = hitApi.url.replace('{widgetId}', this.widgetIds[0]);

        hitApi.function = () => {
            deleteFunction();
        };
        hitApi.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error deleting file.'
            );
        };
        hitApi.input = json;
        new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    save(iDraft = false, callback) {
        const data = {
            widgetIds: this.widgetIds,
            s3FolderName: this.s3FolderName,
            imageVideoPath: this.prepareImageVideoData()
        };

        const editUrl = this.widgetRef.widgetData.widgetInfo['create'];
        editUrl.apiRouteSuffix = ApiUrls.WIDGET_DOCUMENTATION_EDIT.replace(
            '{widgetId}',
            this.widgetIds[0]
        );
        editUrl.intactUrl = ApiUrls.WIDGET_DOCUMENTATION_EDIT;
        editUrl.requestType = RequestType.PATCH;

        const hitApi: IHitApi = Helper.generateHitApiConfig(
            iDraft
                ? this.listHttpService.saveAsDraftWidgetDocumentation
                : this.isEdit
                ? editUrl
                : this.listHttpService.createWidgetDocumentation
        );

        hitApi.function = () => {
            if (callback) {
                callback();
            }
        };
        hitApi.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error deleting file.'
            );
        };
        hitApi.input = data;
        new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    closeModal() {
        this.widgetRef.refreshWidget();
        this.widgetRef.modalService.closeModal(
            null,
            this.modalInputData.modalId
        );

        this.widgetRef.notificationsService.showSnackBar('Saved successfully.');
    }

    prepareImageVideoData() {
        const imageVideoPath = {
            s3FolderName: this.s3FolderName
        };

        this.documentTypes.map((each) => {
            if (each.listData && each.listData.length) {
                each.listData.map((eachObj) => {
                    if (each.type === DocumentTypes.IMAGES) {
                        if (!imageVideoPath['imageKeys']) {
                            imageVideoPath['imageKeys'] = [];
                            imageVideoPath['imagePath'] = [];
                        }
                        if (eachObj.keys && eachObj.path) {
                            imageVideoPath['imageKeys'].push(eachObj.keys);
                            imageVideoPath['imagePath'].push(eachObj.path);
                        }
                    } else {
                        if (eachObj.keys && eachObj.path) {
                            imageVideoPath['videoKey'] = eachObj.keys;
                            imageVideoPath['videoPath'] = eachObj.path;
                        }
                    }
                });
            }
        });

        return imageVideoPath;
    }

    back() {
        this.multiStepFormService.previousStep(this.modalInputData.modalId);
    }

    openMarkdownModal() {
        const modalData: IModalData = {
            modalName: 'Markdown Guide',
            modalIcon: null,
            modalType: ModalType.MIDDLE,
            sourceId: this.widgetRef.uniqueIdentity,
            modalWidthVw: 95,
            modalHeightVh: 95,
            hideSteps: true,
            noStepPadding: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad:
                            WidgetDocumentationMarkdownGuideModalComponent,
                        payload: {
                            data: {}
                        }
                    },
                    stepName: ''
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    uploadDocuments(isDraft = false, buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }

        if (!this.checkValueAndValidity()) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Please add content to proceed.'
            );
            return;
        }

        const getPresignedURLData = {
            widgetIds: this.widgetIds,
            s3FolderName: this.s3FolderName,
            presignedUrlRequest: {
                description: false,
                detailDescription: false,
                useCase: false,
                faqs: false,
                referenceLinks: false
            }
        };
        let uploadStatus = true;
        this.documentTypes.map((each) => {
            if (each.content) {
                switch (each.type) {
                    case DocumentTypes.DESCRIPTION:
                        getPresignedURLData.presignedUrlRequest.description =
                            true;

                        break;

                    case DocumentTypes.DETAILED_DESCRIPTION:
                        getPresignedURLData.presignedUrlRequest.detailDescription =
                            true;

                        break;

                    case DocumentTypes.USE_CASES:
                        getPresignedURLData.presignedUrlRequest.useCase = true;

                        break;

                    case DocumentTypes.FAQ:
                        getPresignedURLData.presignedUrlRequest.faqs = true;

                        break;

                    case DocumentTypes.REFERENCE:
                        getPresignedURLData.presignedUrlRequest.referenceLinks =
                            true;
                }
            } else {
                if (
                    each.type === DocumentTypes.IMAGES ||
                    each.type === DocumentTypes.VIDEOS
                ) {
                    if (each.listData.length) {
                        each.listData.map((eachItem) => {
                            if (
                                eachItem.uploadStatus === false &&
                                eachItem.selectionStatus === true
                            ) {
                                uploadStatus = false;
                            }
                        });
                    }
                }
            }
        });

        if (!uploadStatus) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Please wait until your media files are uploading.'
            );
            return;
        }
        buttonRef.loader = true;

        const hitApi: IHitApi = Helper.generateHitApiConfig(
            this.listHttpService.getPresignedUrlForWidgetDocumentation
        );

        hitApi.input = getPresignedURLData;

        hitApi.function = (response) => {
            if (response) {
                this.s3FolderName = response.s3FolderName;
                let apiCount = 0;
                let responseCount = 0;
                this.documentTypes.map((each) => {
                    if (each.content) {
                        apiCount = apiCount + 1;

                        const textFileAsBlob = new Blob([each.content], {
                            type: 'text/plain'
                        });
                        apiCount;
                        let file = null;
                        let url = null;

                        switch (each.type) {
                            case DocumentTypes.DESCRIPTION:
                                url = response['descriptionPresignedUrl'];
                                file = new File(
                                    [textFileAsBlob],
                                    'description.txt'
                                );
                                break;

                            case DocumentTypes.DETAILED_DESCRIPTION:
                                url = response['detailDescriptionPresignedUrl'];
                                file = new File(
                                    [textFileAsBlob],
                                    'detailDescription.txt'
                                );
                                break;

                            case DocumentTypes.REFERENCE:
                                url = response['referenceLinksPresignedUrl'];
                                file = new File(
                                    [textFileAsBlob],
                                    'referenceLinks.txt'
                                );
                                break;

                            case DocumentTypes.FAQ:
                                url = response['faqsPresignedUrl'];
                                file = new File([textFileAsBlob], 'faqs.txt');
                                break;

                            case DocumentTypes.USE_CASES:
                                url = response['useCasePresignedUrl'];
                                file = new File(
                                    [textFileAsBlob],
                                    'useCase.txt'
                                );
                                break;
                        }

                        const eachApiArgs: IHitApi = {
                            url: url,
                            requestType: RequestType.PUT,
                            config: {
                                ignoreBaseUrl: true,
                                authorization: null
                            },
                            function: () => {
                                responseCount = responseCount + 1;

                                if (apiCount === responseCount) {
                                    this.save(true, () => {
                                        if (!isDraft) {
                                            this.save(false, () => {
                                                this.closeModal();
                                            });
                                        } else {
                                            this.closeModal();
                                        }
                                    });
                                }
                            },
                            input: file,
                            uniqueIdentity: Symbol()
                        };

                        new HitApi(
                            eachApiArgs,
                            this.widgetRef.httpService,
                            this.widgetRef.ngZone
                        ).hitApi();
                    } else if (
                        each.type === DocumentTypes.IMAGES ||
                        each.type === DocumentTypes.VIDEOS
                    ) {
                        if (each.listData.length) {
                            apiCount = apiCount + 1;
                            responseCount = responseCount + 1;
                        }

                        if (apiCount === responseCount) {
                            this.save(true, isDraft);
                        }
                    }
                });
            }
        };
        hitApi.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error uploading file.'
            );
        };

        new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    checkValueAndValidity() {
        let isValid = false;
        this.documentTypes.map((each) => {
            if (
                each.type === DocumentTypes.IMAGES ||
                each.type === DocumentTypes.VIDEOS
            ) {
                if (each.listData.length) {
                    isValid = true;
                }
            } else {
                if (each.content) {
                    if (each.content.trim()) {
                        isValid = true;
                    }
                }
            }
        });

        return isValid;
    }

    openPreviewModal(buttonRef: IButtonGeneratorInput) {
        if (!this.checkValueAndValidity()) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Please add content to proceed.'
            );
            return;
        }

        let uploadStatus = true;
        this.documentTypes.map((each) => {
            if (
                each.type === DocumentTypes.IMAGES ||
                each.type === DocumentTypes.VIDEOS
            ) {
                if (each.listData.length) {
                    each.listData.map((eachItem) => {
                        if (
                            eachItem.uploadStatus === false &&
                            eachItem.selectionStatus === true
                        ) {
                            uploadStatus = false;
                        }
                    });
                }
            }
        });

        if (!uploadStatus) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Please wait until your media files are uploading.'
            );
            return;
        }

        if (buttonRef.loader) {
            return;
        }

        buttonRef.loader = true;
        this.widgetRef.hitWidgetMoreInfoApi(this.widgetIds[0], true, (res) => {
            buttonRef.loader = false;
            const videoImageResponse = [];
            if (res && res.length) {
                res.forEach((each: IDetailedDescription) => {
                    if (
                        each.type === DocumentTypes.IMAGES ||
                        each.type === DocumentTypes.VIDEOS
                    ) {
                        videoImageResponse.push(each);
                    }
                });
            }

            const contentData = Helper.cloneDeep(this.documentTypes);

            const previewData = contentData.filter((eachContent) => {
                if (eachContent.content) {
                    eachContent.content = Helper.convertMarkdownToHtml(
                        eachContent.content
                    );
                    return eachContent;
                }
            });

            res = [...videoImageResponse, ...previewData];

            const modalData: IModalData = {
                modalName: 'Markdown Guide',
                modalIcon: null,
                modalType: ModalType.SIDE,
                sourceId: this.widgetRef.uniqueIdentity,
                modalWidthVw: 75,
                modalHeightVh: 80,
                hideSteps: true,
                noHeader: true,
                noStepPadding: true,
                modalSteps: [
                    {
                        stepData: {
                            componentToLoad:
                                WidgetDocumentationMoreInfoModalComponent,
                            payload: {
                                data: {
                                    previewData: res,
                                    widgetRef: this.widgetRef,
                                    selectedDocument: this.selectedDocument.type
                                }
                            }
                        },
                        stepName: ''
                    }
                ]
            };
            this.widgetRef.modalService.openModal(modalData);
        });

        return;
    }
}

export interface CmsContent {
    type: DocumentTypes;
    content: string;
    listData?: CmsContentListData[];
    contentAccept?: string;
}
interface CmsContentListData {
    selectionStatus: boolean;
    uploadStatus: boolean;
    keys?: any;
    path?: any;
    id?: any;
    previewData?: any;
    data?: any;
}
