import Kelly from '@amcharts/amcharts4/themes/kelly';
import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IGraphData } from 'src/app/shared/interfaces/graph/IGraphData';
import { ILineSeriesData } from '../../interfaces/graph/IGraphData';
import { GlobalDataService } from './../../services/global-data/global-data.service';

@Component({
    selector: 'app-xy-chart',
    templateUrl: './xy-chart.component.html',
    styleUrls: ['./xy-chart.component.sass']
})
export class XyChartComponent implements OnInit, OnDestroy, OnChanges {
    private seriesList: any[];
    private chart: any;
    private legendContainer: any;
    private am4charts: any;
    private am4core: any;
    private isXAxesCategory: boolean;
    graphId = Helper.generateUniqueKey(8);
    graphLegendId = Helper.generateUniqueKey(8);
    sassValue = getComputedStyle(document.body);
    @Input('graphData') graphData: IGraphData;

    constructor(public globalDataService: GlobalDataService) {}

    ngOnInit(): void {
        this.isXAxesCategory =
            this.graphData.categoryAxis === undefined ||
            this.graphData.categoryAxis === 'x'
                ? true
                : false;
        Helper.loadAm4chartsLibraries().then((resolve) => {
            this.am4charts = resolve['am4charts'];
            this.am4core = resolve['am4core'];
            this.generateGraph();
        });

        this.globalDataService.windowResizeEvent.subscribe(() => {
            this.resizeChart();
        });
    }

    resizeChart() {
        if (this.chart && this.chart.svgContainer) {
            this.chart.svgContainer.measure();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.graphData &&
            changes.graphData.currentValue !== changes.graphData.previousValue
        ) {
            this.generateGraph();
        }
    }

    generateGraph() {
        if (!this.am4core || !this.am4charts) {
            return;
        }

        this.seriesList = [];
        this.disposeChart();

        if (!document.getElementById(this.graphId)) {
            setTimeout(() => {
                this.generateGraph();
            }, 10);
            return;
        }
        if (!this.graphData.disableLegend) {
            if (!document.getElementById(this.graphLegendId)) {
                setTimeout(() => {
                    this.generateGraph();
                }, 10);
                return;
            }
        }

        // Chart data
        const chartData: ILineSeriesData[] = this.graphData
            .chartData as ILineSeriesData[];

        this.am4core.useTheme(Kelly);
        // Create chart instance
        this.chart = this.am4core.create(this.graphId, this.am4charts.XYChart);

        this.chart.data = chartData;
        this.chart.hideCredits = true;
        this.chart.paddingRight = 40;
        this.chart.marginTop = 7;

        this.chart.svgContainer.autoResize = true;

        // Create category axes
        let categoryAxis;
        if (this.isXAxesCategory) {
            categoryAxis = this.chart.xAxes.push(
                new this.am4charts.CategoryAxis()
            );
        } else {
            categoryAxis = this.chart.yAxes.push(
                new this.am4charts.CategoryAxis()
            );
        }
        categoryAxis.dataFields.category = this.isXAxesCategory
            ? this.graphData.labelText.categoryX
            : this.graphData.labelText.categoryY;
        categoryAxis.title.text = this.isXAxesCategory
            ? this.graphData.labelText.xAxesTitle
            : this.graphData.labelText.yAxesTitle;
        categoryAxis.title.fontSize = 13;
        categoryAxis.title.fontWeight = 'bold';

        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.grid.template.location = 0.5;

        // Setting up label rotation
        categoryAxis.renderer.labels.template.rotation =
            this.graphData.categoryAxisRotationAngle ?? 0;
        categoryAxis.renderer.labels.template.horizontalCenter = 'left';
        categoryAxis.renderer.labels.template.verticalCenter = 'middle';
        categoryAxis.renderer.labels.template.fontSize = 10;

        // Create value axes
        let valueAxis;
        if (this.isXAxesCategory) {
            valueAxis = this.chart.yAxes.push(new this.am4charts.ValueAxis());
        } else {
            valueAxis = this.chart.xAxes.push(new this.am4charts.ValueAxis());
        }
        valueAxis.title.text = this.isXAxesCategory
            ? this.graphData.labelText.yAxesTitle
            : this.graphData.labelText.xAxesTitle;
        valueAxis.title.fontSize = this.sassValue.getPropertyValue('--fs200');
        valueAxis.title.fontWeight = 'bold';
        valueAxis.renderer.labels.template.fontSize = 13;

        this.graphData.seriesList.forEach((seriesValue, index) => {
            if (!seriesValue) {
                return;
            }
            const series = this.chart.series.push(
                new this.am4charts.LineSeries()
            );

            if (this.isXAxesCategory) {
                series.dataFields.valueY = seriesValue;
                series.dataFields.categoryX =
                    this.graphData.labelText.categoryX;
            } else {
                series.dataFields.valueX = seriesValue;
                series.dataFields.categoryY =
                    this.graphData.labelText.categoryY;
            }
            series.name = this.graphData.seriesName
                ? this.graphData.seriesName(seriesValue)
                : seriesValue;
            series.tooltip.getFillFromObject = false;
            series.tooltip.getStrokeFromObject = true;
            series.tooltip.background.fill = this.am4core.color(
                Helper.convertToHexIfHsl(
                    Helper.getCssVarValue('--commonBgColor')
                )
            );
            series.tooltip.autoTextColor = false;
            series.tooltip.label.fill = this.am4core.color(
                Helper.convertToHexIfHsl(
                    Helper.getCssVarValue('--graphTooltipLabelColor')
                )
            );
            series.tooltip.label.fontSize = Helper.getCssVarValue('fs200');

            series.strokeWidth = 1.5;

            const bullet = series.bullets.push(
                new this.am4charts.CircleBullet()
            );
            bullet.circle.stroke = this.am4core.color(
                Helper.convertToHexIfHsl(
                    Helper.getCssVarValue('--defaultTextColor')
                )
            );
            bullet.circle.strokeWidth = 1;
            bullet.circle.radius = 3;
            series.bullets.getIndex(0).tooltipText = this.graphData.tooltiptext
                ? this.graphData.tooltiptext(series, seriesValue)
                : this.isXAxesCategory
                ? '{name}:[b]{valueY}'
                : '{name}:[b]{valueX}';

            series.legendSettings.itemValueText = this.isXAxesCategory
                ? '{valueY}'
                : '{valueX}';

            series.dataFields.valueY = seriesValue;
            if (this.graphData.customizeSeries) {
                this.graphData.customizeSeries(seriesValue, series, bullet);
            }
            this.seriesList.push(series);
        });

        // Provide adapters for tooltipText so we can modify them on the fly
        this.chart.series.each((series) => {
            series.bullets
                .getIndex(0)
                .adapter.add('tooltipText', (tooltipText, target) => {
                    return tooltipText;
                });
        });

        if (!this.graphData.disableLegend) {
            this.chart.legend = new this.am4charts.Legend();
            this.legendContainer = this.am4core.create(
                this.graphLegendId,
                this.am4core.Container
            );
            this.legendContainer.width = this.am4core.percent(100);
            this.legendContainer.height = this.am4core.percent(100);
            this.chart.legend.parent = this.legendContainer;
            this.chart.legend.labels.template.maxWidth = 150;
            this.chart.legend.labels.template.truncate = false;
            this.chart.legend.labels.template.wrap = true;
            const reference = this;
            function resizeLegend(event) {
                setTimeout(() => {
                    if (
                        document.getElementById(reference.graphLegendId) &&
                        document.getElementById(reference.graphLegendId)
                            .style &&
                        reference.chart.legend.contentHeight
                    ) {
                        document.getElementById(
                            reference.graphLegendId
                        ).style.height =
                            reference.chart.legend.contentHeight + 40 + 'px';
                    }

                    if (
                        reference.graphData.toggleLegend === undefined ||
                        !!reference.graphData.toggleLegend
                    ) {
                        // toggle legends after graph is ready
                        reference.toggleLegends();
                    }
                }, 10);
            }

            this.chart.events.on('datavalidated', resizeLegend);
            this.chart.events.on('maxsizechanged', resizeLegend);

            this.chart.legend.useDefaultMarker = true;
            const markerTemplate = this.chart.legend.markers.template;
            markerTemplate.width = 15;
            markerTemplate.height = 15;
            this.chart.legend.fontSize = Helper.getCssVarValue('--fs200');
            const marker =
                this.chart.legend.markers.template.children.getIndex(0);
            marker.cornerRadius(12, 12, 12, 12);
            marker.radius = 6;
            marker.stroke = this.am4core.color(
                Helper.convertToHexIfHsl(
                    Helper.getCssVarValue('--backgroundColor')
                )
            );

            // 1. Disable default toggle behavior for the whole container
            this.chart.legend.itemContainers.template.clickable = false;

            // 2. Custom logic on label
            this.chart.legend.labels.template.interactionsEnabled = true;
            this.chart.legend.itemContainers.template.cursorOverStyle =
                this.am4core.MouseCursorStyle.pointer;

            //legends linking
            if (this.graphData.onClicklegend) {
                this.chart.legend.labels.template.events.on(
                    'hit',
                    this.graphData.onClicklegend
                );
            }

            // legends hover

            this.chart.legend.labels.template.events.on('over', (event) => {
                if (this.graphData.clickableLegendOnHover) {
                    event.target.cursorOverStyle =
                        this.am4core.MouseCursorStyle.pointer;
                }
                if (this.graphData.onHoverlegend) {
                    this.graphData.onHoverlegend(event);
                }
            });

            // 3. Toggle series on marker
            this.chart.legend.markers.template.interactionsEnabled = true;
        }

        if (!this.graphData.disableScrollBarX) {
            this.chart.scrollbarX = new this.am4core.Scrollbar();
            this.chart.scrollbarX.minHeight = 4;
            this.chart.scrollbarX.margin(20, 20, 20, 20);
            this.chart.scrollbarX.startGrip.scale = 0.6;
            this.chart.scrollbarX.endGrip.scale = 0.6;
        }
        if (!this.graphData.disableScrollBarY) {
            this.chart.scrollbarY = new this.am4core.Scrollbar();
            this.chart.scrollbarY.minWidth = 4;
            this.chart.scrollbarY.margin(20, 20, 20, 20);
            this.chart.scrollbarY.startGrip.scale = 0.6;
            this.chart.scrollbarY.endGrip.scale = 0.6;
        }

        if (this.graphData.showChartCursor) {
            this.chart.cursor = new this.am4charts.XYCursor();
        }
        this.am4core.unuseTheme(Kelly);
    }

    toggleLegends() {
        if (this.chart) {
            this.seriesList.forEach((graph) => {
                if (
                    this.graphData.visibleSections &&
                    this.graphData.visibleSections.value.has(ViewType.LEGEND)
                ) {
                    graph.show();
                } else {
                    graph.hide();
                }
            });
        }
    }

    disposeChart() {
        if (this.chart) {
            this.chart.dispose();
            this.chart = null;
        }
        if (this.legendContainer) {
            this.legendContainer.dispose();
            this.legendContainer = null;
        }
    }

    ngOnDestroy(): void {
        this.disposeChart();
    }
}
