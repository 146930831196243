import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class UnequalityValidator extends CustomValidator {
    key = CustomValidatorKey.UNEQUALITY_VALIDATOR;

    constructor(private controlName: string) {
        super();
    }

    validatorFunction(control: AbstractControl): ValidationErrors {
        const value = control.value;
        if (this.formGenRef && this.formGenRef.formGroup) {
            const controlValue = this.formGenRef.formGroup.get(
                this.controlName
            ).value;
            if (controlValue !== value) {
                return null;
            }
        }
        const obj = {};
        obj[this.key] = true;
        return obj;
    }
}
