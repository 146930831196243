import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';
import { EmailValidator } from './EmailValidator';

export class MatChipAllowEmailValidator extends CustomValidator {
    key = CustomValidatorKey.MAT_CHIP_ALLOW_EMAIL;
    validatorFunction(control: AbstractControl): ValidationErrors {
        const values: string[] = control.value;
        let error = false;
        const validValues: string[] = [];
        if (values) {
            values.forEach((value) => {
                const fc = new FormControl();
                fc.setValue(value);
                const validationRes = new EmailValidator().validatorFunction(
                    fc
                );
                if (validationRes && validationRes[CustomValidatorKey.EMAIL]) {
                    error = true;
                } else {
                    validValues.push(value);
                }
            });
            if (error) {
                control.setValue(validValues);
            }
        }
        const res = {};
        res[CustomValidatorKey.MAT_CHIP_ALLOW_EMAIL] = error ? true : null;
        return error ? res : null;
    }
}
