import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { FilterCacheService } from 'src/app/shared/services/cache/filters-cache/filter-cache.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from './../../../../interfaces/hit-api/IHitApi';

@Component({
    selector: 'app-create-custom-compliance-step-three',
    templateUrl: './create-custom-compliance-step-three.component.html',
    styleUrls: ['./create-custom-compliance-step-three.component.sass']
})
export class CreateCustomComplianceStepThreeComponent implements OnInit {
    filtersFormGenInput: IFormGeneratorInput = null;
    filtersFormGroup: FormGroup;
    colorFormGenInput: IFormGeneratorInput = null;
    colorFormGroup: FormGroup;
    stepData = null;
    edit: boolean = false;
    fieldValueMap: Map<string, any> = new Map();
    widgetRef: Widget;
    loader: boolean = false;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    filterIds = [];

    buttonGenInputs: IButtonGeneratorInput[] = [
        {
            buttonName: 'Back',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.RAISED,
            function: () => {
                this.goToPreviousStep();
            }
        },
        {
            buttonName: this.edit ? 'Update' : 'Save',
            buttonColorType: ButtonColorType.PRIMARY,
            showLoader: true,
            buttonType: ButtonType.RAISED,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.save(buttonRef);
            }
        }
    ];

    constructor(
        private modalInjectedData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        private modalService: ModalService,
        private filtersCacheService: FilterCacheService,
        private userCacheService: UserDataCacheService,
        private configCacheService: ConfigCacheService
    ) {
        if (
            multiStepFormService.stepData.get(modalInjectedData.modalId).has(3)
        ) {
            this.stepData = multiStepFormService.stepData
                .get(modalInjectedData.modalId)
                .get(3);
        } else if (modalInjectedData.data['stepData']) {
            this.stepData = modalInjectedData.data['stepData'];
        }
        this.edit = modalInjectedData.data['edit'];
        this.widgetRef = this.modalInjectedData.data['widgetRef'];
        this.filterIds =
            this.widgetRef.widgetData.widgetInfo.schedulingAutomation[
                'widgetFilters'
            ][0]['filters'];
    }

    ngOnInit(): void {
        if (this.edit) {
            this.buttonGenInputs[1].buttonName = 'Update';
        }
        const keys = Helper.setDynamicCloudFilterKeys(this.widgetRef);

        if (this.stepData && this.stepData['globalWhitelisting']) {
            this.fieldValueMap.set(
                keys.accountIds,
                this.stepData['globalWhitelisting'][keys.accountIds]
            );
            this.fieldValueMap.set(
                keys.regions,
                this.stepData['globalWhitelisting'][keys.regions]
            );
            this.fieldValueMap.set(
                keys.resourceTags,
                this.stepData['globalWhitelisting'][keys.resourceTags]
            );
        } else {
            this.fieldValueMap.set(keys.accountIds, []);
            this.fieldValueMap.set(keys.regions, []);
            this.fieldValueMap.set(keys.resourceTags, []);
        }

        this.generateFiltersFormInput();
        this.generateColorForm();
    }

    generateFiltersFormInput() {
        Helper.generateFormInput(
            this.filterIds,
            this.widgetRef.httpService,
            this.filtersCacheService,
            { showKey: 'label', responseValueKey: 'dataList' },
            true,
            this.fieldValueMap,
            null,
            null,
            null,
            this.userCacheService,
            this.configCacheService
        ).subscribe((res) => {
            if (res) {
                this.filtersFormGenInput = res;
            }
        });
    }

    generateColorForm() {
        this.colorFormGenInput = {
            formName: 'Color Scheme',
            state: FormState.CREATE,
            formId: Symbol(),
            submitButton: null,
            fields: [
                {
                    label: 'Whitelist',
                    name: 'colorMapping',
                    fieldType: FilterType.COLOR,
                    placeholder: 'Whitelisting Color',
                    value:
                        this.stepData && this.stepData['colorMapping']
                            ? this.stepData['colorMapping']
                            : '#bcbbb5'
                }
            ]
        };
    }

    goToPreviousStep() {
        const inputData = {
            globalWhitelisting: this.filtersFormGroup.value,
            colorMapping: this.colorFormGroup.value['colorMapping']
        };
        this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .set(3, inputData);
        this.multiStepFormService.previousStep(this.modalInjectedData.modalId);
    }

    save(buttonRef: IButtonGeneratorInput) {
        if (this.colorFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.colorFormGroup);
            this.colorFormGroup.updateValueAndValidity;
            return;
        }
        if (this.filtersFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.filtersFormGroup);
            this.filtersFormGroup.updateValueAndValidity;
            return;
        }
        let inputData = {
            globalWhitelisting: this.filtersFormGroup.value,
            colorMapping: this.colorFormGroup.value['colorMapping']
        };
        const stepOneData = this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .get(1);
        const stepTwoData = this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .get(2);

        inputData = { ...inputData, ...stepOneData, ...stepTwoData };
        buttonRef.loader = true;
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.edit
                ? this.widgetRef.widgetData.widgetInfo.update
                : this.widgetRef.widgetData.widgetInfo.create
        );
        apiArgs.input = inputData;
        apiArgs.function = (response) => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                `Custom compliance ${
                    this.edit ? 'updated' : 'created'
                } successfully`
            );
            this.modalService.closeModal(null, this.modalInjectedData.modalId);
            this.widgetRef.refreshWidget();
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                `Error ${this.edit ? 'updating' : 'creating'} custom compliance`
            );
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
