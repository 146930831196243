import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-nagios-group-edit-modal',
    templateUrl: './nagios-group-edit-modal.component.html',
    styleUrls: ['./nagios-group-edit-modal.component.sass']
})
export class NagiosGroupEditModalComponent implements OnInit, OnDestroy {
    widgetRef: Widget;
    modalData: any;
    integrationName: any;
    names: string;
    closeCallbackSubject: Subject<Symbol>;
    contactsToSave: any[] = [];
    contactsToSaveSelectAll: boolean = false;
    filteredContactsToSave: any[] = [];
    selectedContactsToSave: any[] = [];
    rightLoader: boolean = true;
    contactNames: any[] = [];
    contactNamesSelectAll: boolean = false;
    filteredContactNames: any[] = [];
    leftLoader: boolean = true;
    buttonGenInputs: IButtonGeneratorInput[] = [
        {
            buttonName: 'Back',
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.PRIMARY,
            function: () => {
                this.widgetRef.modalService.closeModal(
                    null,
                    this.modalInputData.modalId
                );
            }
        },
        {
            buttonName: 'Update',
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.PRIMARY,
            showLoader: true,
            /**
             * Function prepares the payload and hits the final Api for updating data to BE
             * @param buttonRef Instance of button -> IButtonGeneratorInput
             */
            function: (buttonRef: IButtonGeneratorInput) => {
                this.hitUpdateApi(buttonRef);
            }
        }
    ];
    buttonOptions: IMultiButtonOption = {
        extraClass: 'action-buttons',
        layout: {
            justifyContent: 'space-between'
        }
    };
    trashIcon: IIcon = {
        type: IconType.SVG,
        class: 'trash',
        extraClass: 'svg-warning-fill tw-w-4 tw-h-4'
    };
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    resetModalSubs = new SubSink();
    callbackFn: () => void;
    constructor(private modalInputData: ModalInjectedData) {
        this.widgetRef = this.modalInputData.data.widgetRef;
        this.modalData = this.modalInputData.data['modalData'];
        this.closeCallbackSubject =
            this.modalInputData.data['closeCallbackSubject'];
        this.callbackFn = this.modalInputData.data['callbackFn'];
    }

    ngOnInit(): void {
        this.resetModalSubs.add(
            this.closeCallbackSubject.subscribe((data) => {
                this.hitUpdateApi(
                    data['buttonRef'],
                    data['modalId'],
                    data['confirmationModalId']
                );
            })
        );
        this.resetModalSubs.add(
            this.widgetRef.modalService.resetModal.subscribe(() => {
                this.contactsToSave = [];
                this.filteredContactsToSave = [];
                this.contactNames = [];
                this.leftLoader = true;
                this.rightLoader = true;
                this.filteredContactNames = [];
                this.selectedContactsToSave = [];
                this.getContactToAssociate();
                this.getContactForAlerting();
            })
        );
        this.names = this.modalData['members'];
        this.integrationName = this.modalData['integrationName'];
        this.getContactToAssociate();
        this.getContactForAlerting();
    }

    /**
     * Function hits api for getting list of contacts for alerting and stores them in 'contactNames' variable
     */
    getContactForAlerting() {
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['nagiosIntegration'][
                'listContactForAlerting'
            ]
        );
        apiArgs.input = this.generateInputs();
        apiArgs.function = (response) => {
            this.contactNames = response['contactsList'];
            this.filteredContactNames = this.contactNames;
            this.leftLoader = false;
        };

        apiArgs.errorFunction = (error) => {
            this.leftLoader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'An error occured'
            );
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    /**
     * Function hits api for getting list of contacts to Associate and stores them in 'contactsToSave' variable
     */
    getContactToAssociate() {
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['nagiosIntegration'][
                'listContactToAssociate'
            ]
        );
        apiArgs.input = {
            integrationName: this.modalData['integrationName'],
            members: this.names
        };

        apiArgs.function = (response) => {
            this.rightLoader = false;
            this.contactsToSave = response['contactsView'];
            this.filteredContactsToSave = this.contactsToSave;
        };

        apiArgs.errorFunction = (error) => {
            this.rightLoader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'An error occured'
            );
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    /**
     * @returns Api Input for getting contacts for alerting
     */
    generateInputs() {
        const inputs = {
            groupName: this.modalData['groupName'],
            members: this.names,
            integrationName: this.modalData['integrationName']
        };

        return { ...inputs };
    }

    /**
     * Function Runs when any contact is selected from left side and add it to the right side
     * @param contact An object or an Array of object depending upon if bulk selection is performed
     * @param index index of the contact selected
     * @param bulk a boolean value which is true when bulk selection is performed
     */

    selectContact(contact, index, bulk?: boolean) {
        this.callbackFn();
        if (bulk) {
            contact.forEach((contact) => {
                this.contactNames.splice(0, 0, contact);
            });
            this.contactsToSave = [];
        } else {
            this.contactsToSave.splice(index, 1);
            this.contactNames.splice(0, 0, contact);
        }

        this.filteredContactsToSave = [...this.contactsToSave];
        this.filteredContactNames = [...this.contactNames];
    }

    /**
     * Function runs when delete is performed on the right side.. in case of single delete and bulk delete.
     * It deletes the element from right side and puts it back on the left
     * @param contact An object or an Array of object depending upon if bulk selection is performed
     * @param index index of the contact selected
     * @param bulk a boolean value which is true when bulk deletion is performed
     * @returns void
     */
    deleteContacts(contact, index, bulk?: boolean) {
        this.callbackFn();
        if (bulk) {
            contact.forEach((c) => {
                if (this.contactNames && this.contactNames.length) {
                    this.contactNames.splice(this.contactNames.indexOf(c), 1);
                } else {
                    this.contactNames = [];
                    this.contactNames.push(c);
                }
                if (this.contactsToSave && this.contactsToSave.length) {
                    this.contactsToSave.splice(0, 0, c);
                } else {
                    this.contactsToSave = [];
                    this.contactsToSave.push(c);
                }
            });
            this.filteredContactsToSave = [...this.contactsToSave];
            this.filteredContactNames = [...this.contactNames];
            return;
        }
        this.contactNames.splice(index, 1);
        this.filteredContactNames = [...this.contactNames];
        this.contactsToSave.splice(0, 0, contact);
        this.filteredContactsToSave = [...this.contactsToSave];
    }
    /**
     * Function Runs when a contact on right side is checked or unchecked to perform bulk deletion
     * @param event mat-checkbox event which tells if the checkbox is checked or unchecked
     * @param contact An object or an Array of object depending upon if bulk selection is performed
     * @param index Index of the object in case of single selection
     * @param bulk a boolean which states if bulk action is performed or not
     * @returns void
     */
    selectContactsToSaveFn(event, contact, index, bulk?: boolean) {
        if (bulk && this.contactNamesSelectAll) {
            this.selectedContactsToSave = [...contact];
            return;
        }
        if (bulk && !this.contactNamesSelectAll) {
            this.selectedContactsToSave = [];
            return;
        }

        if (event.checked && !this.selectedContactsToSave.includes(contact)) {
            this.selectedContactsToSave.push(contact);
        }
        if (!event.checked && this.selectedContactsToSave.includes(contact)) {
            this.selectedContactsToSave.slice(
                this.selectedContactsToSave.indexOf(contact),
                1
            );
        }
    }
    hitUpdateApi(
        buttonRef: IButtonGeneratorInput,
        modalId?,
        confirmationModalId?
    ) {
        if (buttonRef.loader) {
            return;
        }
        buttonRef.loader = true;
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['nagiosIntegration'][
                'associateContactsToTheGroup'
            ]
        );
        const members: string[] = [];
        this.contactNames.forEach((contact) => {
            if (contact['name']) {
                members.push(contact['name']);
            } else if (contact['contactName']) {
                members.push(contact['contactName']);
            }
        });
        apiArgs.input = {
            groupName: this.modalData['groupName'],
            members: [...members],
            integrationName: this.integrationName
        };
        apiArgs.function = (result) => {
            buttonRef.loader = false;
            if (modalId) {
                this.widgetRef.modalService.closeModal(null, modalId);
            }
            if (confirmationModalId) {
                this.widgetRef.modalService.closeModal(
                    null,
                    confirmationModalId
                );
            }
            this.widgetRef.modalService.closeModal(
                null,
                this.modalInputData.modalId
            );
            this.widgetRef.notificationsService.showSnackBar(
                'Contacts are successfully associated to the Contact Group.'
            );
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while associating contacts to the contact group.'
            );
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    ngOnDestroy(): void {
        this.resetModalSubs.unsubscribe();
    }
}
