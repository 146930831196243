import { Component, OnInit } from '@angular/core';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IIntegrationInput } from 'src/app/shared/interfaces/integration/IIntegrationInput';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { AwsOnboardingModalComponent } from '../../../modal-templates/account-onboarding-modals/aws-onboarding-modal/aws-onboarding-modal.component';

@Component({
    selector: 'app-aws-integration',
    templateUrl: './aws-integration.component.html',
    styleUrls: ['./aws-integration.component.sass']
})
export class AwsIntegrationComponent implements OnInit {
    integrationInput: IIntegrationInput = null;
    widgetRef: Widget;
    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        this.integrationInput = {
            integrationName: this.widgetRef.widgetData.widgetInfo.text,
            integrationCategory: 'Cloud Integration',
            imageUrl: 'assets/aws-logo.png',
            buttons: [
                {
                    buttonName: 'Add',
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    hoverText: 'Add AWS account',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-plus'
                    },
                    function: (buttonRef) => {
                        //Open Modal
                        this.onIntegrate(buttonRef);
                    }
                }
            ],
            imagePadding: 10
        };
    }
    onIntegrate(buttonRef: any) {
        const modalArgs: IModalData = {
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-chalkboard-teacher'
            },
            modalName: 'On Board AWS',
            modalType: ModalType.MIDDLE,
            sourceId: this.widgetRef.uniqueIdentity,
            extraStepContainerClass: 'remove-form-space',
            modalSteps: [
                {
                    stepName: 'Step 1',
                    resetModal: true,
                    stepData: {
                        componentToLoad: AwsOnboardingModalComponent,
                        payload: {
                            data: {
                                widgetInfo: this.widgetRef.widgetData.widgetInfo
                            }
                        }
                    }
                }
            ],
            modalWidthVw: 60,
            modalHeightVh: 90,
            noBottomPadding: true
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
}
