<!--Field type one start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_1">
    <div class="form-row {{ field.extraClass }}">
        <mat-form-field
            [appearance]="'outline'"
            [floatLabel]="'auto'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
        >
            <mat-label>
                {{ field.label }}
            </mat-label>
            <ngx-mat-file-input
                #ngxMatFileInput
                [formControl]="control"
                [errorStateMatcher]="matcher"
                [placeholder]="field.placeholder"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                [accept]="field.accept"
                [multiple]="field.multipleUpload ? field.multipleUpload : false"
            ></ngx-mat-file-input>
            <img
                *ngIf="
                    field.accept.includes('image') &&
                    formGroup.get(field.name).value
                "
                #imageElement
                [src]="
                    formGroup.get(field.name).value
                        | imageUrlFromFile : imageElement
                "
                [alt]="formGroup.get(field.name).value._fileNames"
                style="height: 40px; max-width: 200px; margin-top: 8px"
            />
            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
                >{{ field.prefixIcon.iconData.class }}</mat-icon
            >
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>
            <app-button-generator
                matSuffix
                *ngIf="
                    !field.suffixIcon &&
                    field.suffixButton &&
                    field.suffixButton.show
                "
                [buttonGenInput]="{
                    buttonName: field.suffixButton.text,
                    buttonColorType: ButtonColorType.PRIMARY,
                    buttonType: ButtonType.FLAT
                }"
                [ngClass]="field.suffixButton.noFill ? '' : 'filled'"
                (buttonRef)="
                    ngxMatFileInput.open();
                    field.suffixButton.onClick
                        ? field.suffixButton.onClick()
                        : null
                "
            >
            </app-button-generator>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type one end -->

<!--Field type two start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_2">
    <div class="form-row {{ field.extraClass }}">
        <mat-form-field
            [appearance]="'outline'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [floatLabel]="'never'"
        >
            <ngx-mat-file-input
                #ngxMatFileInput
                [formControl]="control"
                [errorStateMatcher]="matcher"
                [placeholder]="field.placeholder"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                [accept]="field.accept"
                [multiple]="field.multipleUpload ? field.multipleUpload : false"
            ></ngx-mat-file-input>
            <img
                *ngIf="
                    field.accept.includes('image') &&
                    formGroup.get(field.name).value
                "
                #imageElement
                [src]="
                    formGroup.get(field.name).value
                        | imageUrlFromFile : imageElement
                "
                [alt]="formGroup.get(field.name).value._fileNames"
                style="height: 40px; max-width: 200px; margin-top: 8px"
            />
            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
                >{{ field.prefixIcon.iconData.class }}</mat-icon
            >
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>
            <app-button-generator
                matSuffix
                *ngIf="
                    !field.suffixIcon &&
                    field.suffixButton &&
                    field.suffixButton.show
                "
                [buttonGenInput]="{
                    buttonName: field.suffixButton.text,
                    buttonColorType: ButtonColorType.PRIMARY,
                    buttonType: ButtonType.FLAT
                }"
                [ngClass]="field.suffixButton.noFill ? '' : 'filled'"
                (buttonRef)="
                    ngxMatFileInput.open();
                    field.suffixButton.onClick
                        ? field.suffixButton.onClick()
                        : null
                "
            >
            </app-button-generator>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type two end -->

<!--Field type three start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_3">
    <div class="form-row {{ field.extraClass }}">
        <label class="form-label">
            {{ field.label }}
            <span
                *ngIf="field.required !== undefined ? field.required : true"
                class="mat-form-field-required-marker"
            >
                &nbsp;*
            </span>
        </label>
        <mat-form-field
            class="form-input"
            [appearance]="'legacy'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [floatLabel]="'never'"
            class="field-type-three"
        >
            <ngx-mat-file-input
                #ngxMatFileInput
                [formControl]="control"
                [errorStateMatcher]="matcher"
                [placeholder]="field.placeholder"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                [accept]="field.accept"
                [multiple]="field.multipleUpload ? field.multipleUpload : false"
            ></ngx-mat-file-input>
            <img
                *ngIf="
                    field.accept.includes('image') &&
                    formGroup.get(field.name).value
                "
                #imageElement
                [src]="
                    formGroup.get(field.name).value
                        | imageUrlFromFile : imageElement
                "
                [alt]="formGroup.get(field.name).value._fileNames"
                style="height: 40px; max-width: 200px; margin-top: 8px"
            />
            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
                >{{ field.prefixIcon.iconData.class }}</mat-icon
            >
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>
            <div class="button-container">
                <app-button-generator
                    class="tw-relative tw-bottom-1"
                    matSuffix
                    *ngIf="
                        !field.suffixIcon &&
                        field.suffixButton &&
                        field.suffixButton.show
                    "
                    [buttonGenInput]="{
                        buttonName: field.suffixButton.text,
                        buttonColorType: ButtonColorType.PRIMARY,
                        buttonType: ButtonType.FLAT
                    }"
                    [ngClass]="field.suffixButton.noFill ? '' : 'filled'"
                    (buttonRef)="
                        ngxMatFileInput.open();
                        field.suffixButton.onClick
                            ? field.suffixButton.onClick()
                            : null
                    "
                >
                </app-button-generator>
            </div>
            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type three end -->

<!--Field type four start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_4">
    <div class="form-row {{ field.extraClass }}">
        <mat-form-field
            [appearance]="'legacy'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [floatLabel]="'never'"
            class="field-type-four tw-w-full"
        >
            <ngx-mat-file-input
                #ngxMatFileInput
                [formControl]="control"
                [errorStateMatcher]="matcher"
                [placeholder]="field.placeholder"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                [accept]="field.accept"
                [multiple]="field.multipleUpload ? field.multipleUpload : false"
            ></ngx-mat-file-input>
            <img
                *ngIf="
                    field.accept.includes('image') &&
                    formGroup.get(field.name).value
                "
                #imageElement
                [src]="
                    formGroup.get(field.name).value
                        | imageUrlFromFile : imageElement
                "
                [alt]="formGroup.get(field.name).value._fileNames"
                style="height: 40px; max-width: 200px; margin-top: 8px"
            />
            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
                >{{ field.prefixIcon.iconData.class }}</mat-icon
            >
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>
            <div class="button-container">
                <app-button-generator
                    matSuffix
                    *ngIf="
                        !field.suffixIcon &&
                        field.suffixButton &&
                        field.suffixButton.show
                    "
                    [buttonGenInput]="{
                        buttonName: field.suffixButton.text,
                        buttonColorType: ButtonColorType.PRIMARY,
                        buttonType: ButtonType.FLAT
                    }"
                    [ngClass]="field.suffixButton.noFill ? '' : 'filled'"
                    (buttonRef)="
                        ngxMatFileInput.open();
                        field.suffixButton.onClick
                            ? field.suffixButton.onClick()
                            : null
                    "
                >
                </app-button-generator>
            </div>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type four end -->

<!-- Error Templates -->
<ng-template #groupError>
    <ng-container
        *ngIf="
            formGroup.get(field.groupByKey).get(field.name).touched &&
            formGroup.get(field.groupByKey).get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation
                        : formGroup.get(field.groupByKey).get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
<ng-template #error>
    <ng-container
        *ngIf="
            formGroup.get(field.name).touched &&
            formGroup.get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation : formGroup.get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
