<app-table-generator
    [ngClass]="widgetRef.lightState ? 'allocation-container' : ''"
    [tableInput]="tableInput"
    [widgetRef]="widgetRef"
    (gridRef)="agGrid = $event"
    (cellValueChanged)="onCellValueChanged($event)"
    (selectionChanged)="onSelectionChanged()"
    [overviewTemplateStart]="overviewTemplateStart"
    [showSearchBox]="!widgetRef.lightState"
></app-table-generator>

<ng-template #overviewTemplateStart>
    <app-multi-button-generator
        *ngIf="
            allocationChecksApiResponse &&
            allocationChecksApiResponse['dataMap'] &&
            allocationChecksApiResponse['dataMap']['severityCount'] &&
            severityButtons
        "
        [buttonGenInputs]="severityButtons"
    ></app-multi-button-generator>
    <app-button-generator
        *ngIf="conventions && conventions.length > 0 && conventionButton"
        [buttonGenInput]="conventionButton"
    ></app-button-generator>
</ng-template>
