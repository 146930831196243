<div class="container">
	<div class="flex-container">
		<ng-container>
			<div class="search-section">
				<h2 style="margin-bottom: 0">Widget List</h2>
				<div>
					<app-table-search
						[searchFunction]="onQuickFilterChanged.bind(this)"
					></app-table-search>
				</div>
			</div>
			<div class="filter-section">
				<div class="filter-row">
					<div class="filter-label">
						<h3>Filter & Search By:</h3>
					</div>
					<div class="filter-groups">
						<div class="filter-group-1">
							<div class="filter-dropdown">
								<app-multi-select-dropdown
									*ngIf="cloudFilterInfo"
									[filterInfo]="cloudFilterInfo"
									[selectedValues]="cloudSelected"
									(change)="
										updateFilterInfoListData($event, CLOUD_KEY)
									"
								></app-multi-select-dropdown>
							</div>
							<div class="filter-dropdown">
								<app-multi-select-dropdown
									*ngIf="productFilterInfo"
									[filterInfo]="productFilterInfo"
									[selectedValues]="productSelected"
									(change)="
										updateFilterInfoListData($event, PRODUCT_KEY)
									"
								></app-multi-select-dropdown>
							</div>
						</div>
						<div class="filter-group-2">
							<div class="filter-dropdown">
								<app-multi-select-dropdown
									*ngIf="subProductFilterInfo"
									[filterInfo]="subProductFilterInfo"
									[selectedValues]="subProductSelected"
									(change)="
										updateFilterInfoListData($event, SUB_PRODUCT_KEY)
									"
								></app-multi-select-dropdown>
							</div>
							<div class="filter-dropdown">
								<app-multi-select-dropdown
									*ngIf="serviceFilterInfo"
									[filterInfo]="serviceFilterInfo"
									[selectedValues]="serviceSelected"
									(change)="
										updateFilterInfoListData($event, SERVICE_KEY)
									"
								></app-multi-select-dropdown>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="table-section">
				<ng-container>
					<app-only-table-generator
						*ngIf="tableGenInput"
						[tableInput]="tableGenInput"
						[defaultColDef]="defaultColDef"
						[autoFitColumns]="false"
						[hideControlBar]="true"
						(gridRef)="gridRefEvent($event); gridSetInitialSelection()"
						(selectionChange)="selectionChanged($event)"
						[tableData]="widgets"
						[useMaterialIcons]="true"
					></app-only-table-generator>
				</ng-container>
			</div>
			<div class="add-group-widgets">
				<app-button-generator
					*ngIf="gridRef && gridRef.rowData"
					[disabled]="
						gridRef &&
						gridRef.api &&
						gridRef.api.getSelectedRows().length < 2
					"
					[buttonGenInput]="insertButtonGenInput"
                    (buttonRef)="insertWidgetsToModule($event)"
				></app-button-generator>
			</div>
		</ng-container>
	</div>
</div>
