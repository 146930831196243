<div
    class="tw-flex tw-justify-center tw-items-center tw-mt-2 controls"
    *ngIf="
        !(widgetRef.loadingData | async) &&
        rowData &&
        rowData.length &&
        widgetRef.visibleSections.value.has(ViewType.TABLE)
    "
></div>
<div
    class="widget-data-container"
    [id]="widgetRef.widgetTableId"
    *ngIf="
        !(widgetRef.loadingData | async) &&
        rowData &&
        rowData.length &&
        widgetRef.visibleSections.value.has(ViewType.TABLE)
    "
>
    <div
        [ngClass]="
            widgetRef.widgetLinkingData
                ? 'table-container-compare-view'
                : 'table-container'
        "
    >
        <app-only-table-generator
            [tableInput]="tableInputData"
            [tableData]="filteredSearchData"
            (gridRef)="onGridReady($event)"
        ></app-only-table-generator>
    </div>
</div>
