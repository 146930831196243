import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IOverviewApiResponse } from 'src/app/shared/interfaces/api/portlets/IOverviewApiResponse';

@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.sass']
})
export class OverviewComponent implements OnInit, AfterViewInit {
    widgetRef: Widget;
    severityCounts = null;
    ViewType = ViewType;
    warningSeverityColor;
    okSeverityColor;
    criticalSeverityColor;
    backgroundColor;

    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
        this.graphColorInitialization();
    }

    ngOnInit(): void {
        this.setUpBasics();
    }
    graphColorInitialization() {
        this.okSeverityColor = Helper.getCssVarValue('okSeverityColor');

        this.warningSeverityColor = Helper.getCssVarValue(
            'warningSeverityColor'
        );

        this.criticalSeverityColor = Helper.getCssVarValue(
            'criticalSeverityColor'
        );

        this.backgroundColor = Helper.getCssVarValue('backgroundColor');
    }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(true);
        const visibleSections = new Set<ViewType>();
        visibleSections.add(ViewType.GRAPH);
        this.widgetRef.visibleSections.next(visibleSections);
    }

    bindData(data: IOverviewApiResponse) {
        if (!data) {
            return;
        }

        this.widgetRef.apiResponse = data;
        this.severityCounts = data.dataList;

        let count = 0;
        this.severityCounts.forEach((item) => {
            count = count + item.count;
        });

        if (!count || (data.dataList && data.dataList.length === 0)) {
            this.widgetRef.extraMessage.next(Messages.NO_DATA_AVAILABLE);
            this.widgetRef.visibleSections.next(new Set());
            this.widgetRef.endLoader();
            return;
        }

        this.widgetRef.endLoader();
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
