<div
    class="tw-flex-col"
    *ngIf="
        !(widgetRef.loadingData | async) &&
        severityCounts &&
        (widgetRef.visibleSections | async) &&
        (widgetRef.visibleSections | async).has(ViewType.GRAPH)
    "
>
    <div
        class="gauge-div tw-grid tw-grid-cols-2 tw-items-center tw-w-full"
        *ngFor="let severity of severityCounts"
    >
        <p class="severityName tw-text-center">
            {{
                severity.color === "red"
                    ? "Critical"
                    : severity.color === "yellow"
                    ? "Warning"
                    : severity.color === "green"
                    ? "OK"
                    : "Unknown"
            }}
            Checks:
            <span class="severityName tw-text-center">
                {{ severity.count }}
            </span>
        </p>
        <div
            class="tw-h-full tw-w-full tw-grid tw-items-center tw-justify-center"
        >
            <ngx-gauge
                class="tw-relative"
                [type]="'full'"
                [value]="severity.percentage"
                [size]="95"
                [append]="'%'"
                [foregroundColor]="
                    severity.color === 'red'
                        ? criticalSeverityColor
                        : severity.color === 'yellow'
                        ? warningSeverityColor
                        : severity.color === 'green'
                        ? okSeverityColor
                        : backgroundColor
                "
                [min]="0"
                [max]="100"
                [thick]="5"
            >
            </ngx-gauge>
        </div>
    </div>
</div>
