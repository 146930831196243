import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { ApiUrls } from './../../../../../../core/classes/ApiUrls';
import { Helper } from './../../../../../classes/Helper';
import { HitApi } from './../../../../../classes/HitApi';
import { AuthorizationType } from './../../../../../enums/AuthorizationType';
import { RequestType } from './../../../../../enums/RequestType';
import { IButtonGeneratorInput } from './../../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from './../../../../../interfaces/hit-api/IHitApi';

@Component({
    selector: 'app-freshservice-create-ticket',
    templateUrl: './freshservice-create-ticket.component.html',
    styleUrls: ['./freshservice-create-ticket.component.sass']
})
export class FreshserviceCreateTicketComponent implements OnInit {
    formGenInput: IFormGeneratorInput = null;
    formGroup: FormGroup;
    edit: boolean = false;
    stepData: any;
    FilterType = FilterType;
    fieldValueObj = {};
    customFieldValueObj = {};
    otherFieldListObj = {};
    originalFormData = [];
    formData = [];
    widgetRef: Widget;
    modalLoader = false;
    checkValidation = false;
    isServiceCatalog: boolean;
    tableRowData = [];
    configuredColumnData;
    selectedConfig = '';
    isPartner: boolean;
    buttonInput: IButtonGeneratorInput;

    constructor(
        private modalInputData: ModalInjectedData,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.widgetRef = modalInputData.data['widgetRef'];
        this.isServiceCatalog = modalInputData.data['isServiceCatalog'];
        if (this.isServiceCatalog) {
            this.selectedConfig =
                modalInputData.data['configuredColumnData'][
                    'configuration_widgetId'
                ];
            this.isPartner = modalInputData.data['isPartner'];
        }
        this.getFields();
    }

    ngOnInit(): void {
        this.buttonInput = {
            buttonName: 'Create',
            buttonType: ButtonType.RAISED,
            buttonColorType: ButtonColorType.PRIMARY,
            showLoader: true,
            customClass: 'button',
            function: (buttonRef: IButtonGeneratorInput) => {
                this.save(buttonRef);
            }
        };
    }

    getFields() {
        this.modalLoader = true;
        let url = ApiUrls.FRESHSERVICE_CONFIGURED_FIELDS.replace(
            '{widgetId}',
            this.isServiceCatalog
                ? this.selectedConfig
                : this.widgetRef.widgetData.widgetId
        );
        url = url.replace('{budgeting}', 'false');
        url = url.replace('{alerting}', 'false');
        const apiConf: IHitApi = {
            url: url,
            input: {},
            intactUrl: ApiUrls.FRESHSERVICE_CONFIGURED_FIELDS,
            function: (response) => {
                this.modalLoader = false;
                this.initForm(response);
            },
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            errorFunction: (error) => {
                this.modalLoader = false;
            }
        };

        new HitApi(
            apiConf,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    initForm(response) {
        this.originalFormData = response;
        if (this.isServiceCatalog) {
            response.forEach((field) => {
                const value =
                    this.modalInputData.data['tableRowData'][
                        this.modalInputData.data['configuredColumnData'][
                            field.name
                        ]
                    ];
                if (field.customField) {
                    this.customFieldValueObj[field.name] = value;
                } else {
                    this.fieldValueObj[field.name] = value;
                }

                if (this.isPartner) {
                    if (
                        this.modalInputData.data['configuredColumnData'][
                            field.name
                        ]
                    ) {
                        field.disabled = true;
                    }
                    this.formData.push(field);
                } else {
                    if (
                        !this.modalInputData.data['configuredColumnData'][
                            field.name
                        ]
                    ) {
                        this.formData.push(field);
                    }
                }
            });
        } else {
            this.formData = response;
        }
    }

    fieldValueChanged(response) {
        if (response.value && response.custom) {
            this.customFieldValueObj = {
                ...this.customFieldValueObj,
                ...response.value
            };
        }

        if (response.otherFieldList) {
            this.otherFieldListObj = {
                ...this.otherFieldListObj,
                ...response.otherFieldList
            };
            this.changeDetectorRef.detectChanges();
        }

        if (response.value && !response.custom) {
            this.fieldValueObj = {
                ...this.fieldValueObj,
                ...response.value
            };
        }
    }

    save(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }
        this.checkValidation = true;
        const input = {};
        const customFields = {};
        if (
            !Object.keys(this.fieldValueObj).length &&
            !Object.keys(this.customFieldValueObj).length
        ) {
            return;
        }
        const valid = this.originalFormData.every((field) => {
            if (field.required) {
                if (
                    (!field.customField &&
                        !(field.name in this.fieldValueObj)) ||
                    this.fieldValueObj[field.name] === null ||
                    Array.isArray(
                        this.fieldValueObj[field.name] &&
                            !this.fieldValueObj[field.name].length
                    )
                ) {
                    return false;
                } else if (
                    (field.customField &&
                        !(field.name in this.customFieldValueObj)) ||
                    this.customFieldValueObj[field.name] === null ||
                    Array.isArray(
                        this.customFieldValueObj[field.name] &&
                            !this.customFieldValueObj[field.name].length
                    )
                ) {
                    return false;
                } else {
                    if (
                        field.customField &&
                        this.customFieldValueObj[field.name] !== null
                    ) {
                        customFields[field.name] =
                            this.customFieldValueObj[field.name];
                    } else {
                        if (this.fieldValueObj[field.name] !== null) {
                            input[field.name] = this.fieldValueObj[field.name];
                        }
                    }

                    if (field['nestedFields']) {
                        field['nestedFields'].forEach((nestedField) => {
                            if (this.fieldValueObj[nestedField.name]) {
                                if (
                                    field.customField &&
                                    this.customFieldValueObj[field.name] !==
                                        null
                                ) {
                                    customFields[nestedField.name] =
                                        this.customFieldValueObj[
                                            nestedField.name
                                        ];
                                } else {
                                    if (
                                        this.fieldValueObj[field.name] !== null
                                    ) {
                                        input[nestedField.name] =
                                            this.fieldValueObj[
                                                nestedField.name
                                            ];
                                    }
                                }
                            }
                        });
                    }

                    return true;
                }
            } else {
                if (
                    field.customField &&
                    this.customFieldValueObj[field.name] !== null
                ) {
                    customFields[field.name] =
                        this.customFieldValueObj[field.name];
                } else {
                    if (this.fieldValueObj[field.name] !== null) {
                        input[field.name] = this.fieldValueObj[field.name];
                    }
                }
                if (field['nestedFields']) {
                    field['nestedFields'].forEach((nestedField) => {
                        if (this.fieldValueObj[nestedField.name]) {
                            if (
                                field.customField &&
                                this.customFieldValueObj[field.name] !== null
                            ) {
                                customFields[nestedField.name] =
                                    this.customFieldValueObj[nestedField.name];
                            } else {
                                if (this.fieldValueObj[field.name] !== null) {
                                    input[nestedField.name] =
                                        this.fieldValueObj[nestedField.name];
                                }
                            }
                        }
                    });
                }
                return true;
            }
        });

        if (!valid) {
            return;
        }

        input['custom_fields'] = customFields;
        buttonRef.loader = true;
        const apiArgs: IHitApi = {
            url: ApiUrls.FRESHSERVICE_CREATE_TICKET.replace(
                '{widgetId}',
                this.isServiceCatalog
                    ? this.selectedConfig
                    : this.widgetRef.widgetData.widgetId
            ),
            intactUrl: ApiUrls.FRESHSERVICE_CREATE_TICKET,
            input: input,
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (response) => {
                buttonRef.loader = false;
                this.widgetRef.modalService.closeModal(
                    null,
                    this.modalInputData.modalId
                );
                this.widgetRef.notificationsService.showSnackBar(
                    'Ticket Created Successfully'
                );
                if (!this.isServiceCatalog) {
                    this.widgetRef.refreshWidget();
                }
            },
            errorFunction: (error) => {
                buttonRef.loader = false;
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    'Error Creating Ticket'
                );
            }
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
