<div class="container">
    <!--Field type one start -->
    <div
        *ngIf="field.appearance === FieldAppearance.TYPE_1"
        class="form-row {{ field.extraClass }}"
    >
        <mat-form-field
            #textFieldRef
            [appearance]="'outline'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [hideRequiredMarker]="true"
        >
            <mat-label
                >{{ field.label }}
                <span
                    *ngIf="field.required !== undefined ? field.required : true"
                    class="color-warn"
                    >&nbsp;*</span
                >
            </mat-label>
            <div
                class="infoMessageBox"
                matTooltipPosition="before"
                matTooltipClass="tooltip"
                [matTooltip]="field.infoMessage ? field.infoMessage : ''"
                #tooltip="matTooltip"
            ></div>
            <input
                matInput
                type="text"
                [ngClass]="field.extraClass ? field.extraClass : ''"
                [placeholder]="field.placeholder"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                [class.field-required]="
                    field.required !== undefined ? field.required : false
                "
                [name]="field.name"
                [formControl]="control"
                [errorStateMatcher]="matcher"
                (change)="
                    field.onChange ? field.onChange($event, formGroup) : null
                "
                (focus)="
                    tooltip.show();
                    field.prefixIcon ? textFieldRef.updateOutlineGap() : '';
                    field.onFocus ? field.onFocus($event, formGroup, field.name) : null
                "
                (focusout)="
                    tooltip.hide();
                    field.onFocusout
                        ? field.onFocusout($event, formGroup, field.name)
                        : null
                "
            />
            <span *ngIf="field.prefixText" matPrefix>
                {{ field.prefixText }}</span
            >
            <span *ngIf="field.suffixText" matSuffix>
                {{ field.suffixText }}</span
            >
            <mat-icon
                *ngIf="field.prefixIcon && !field.showLabel"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                >{{ field.prefixIcon.iconData.class }}</mat-icon
            >
            <mat-icon
                *ngIf="!field.showSuffixIconInLabel && field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
                >{{ field.suffixIcon.iconData.class }}</mat-icon
            >

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
        <div *ngIf="field.suffixTemplate && field.suffixTemplate.templateRef">
            <ng-container
                *ngTemplateOutlet="
                    field.suffixTemplate.templateRef;
                    context: field.suffixTemplate.templateData
                        ? field.suffixTemplate.templateData
                        : null
                "
            >
            </ng-container>
        </div>
    </div>
    <!-- field type one end -->

    <!--Field type two start -->
    <div
        *ngIf="field.appearance === FieldAppearance.TYPE_2"
        class="form-row {{ field.extraClass }}"
    >
        <mat-form-field
            [appearance]="'outline'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
        >
            <input
                matInput
                type="text"
                [ngClass]="field.extraClass ? field.extraClass : ''"
                [placeholder]="field.placeholder"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                [name]="field.name"
                [formControl]="control"
                [errorStateMatcher]="matcher"
                (change)="
                    field.onChange ? field.onChange($event, formGroup) : null
                "
                (focusout)="
                    field.onFocusout
                        ? field.onFocusout($event, formGroup, field.name)
                        : null
                "
            />
            <span *ngIf="field.prefixText" matPrefix>
                {{ field.prefixText }}</span
            >
            <span *ngIf="field.suffixText" matSuffix>
                {{ field.suffixText }}</span
            >
            <mat-icon
                *ngIf="field.prefixIcon && !field.showLabel"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                >{{ field.prefixIcon.iconData.class }}</mat-icon
            >
            <mat-icon
                *ngIf="!field.showSuffixIconInLabel && field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
                >{{ field.suffixIcon.iconData.class }}</mat-icon
            >

            <!-- Handling errors -->

            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
        <div *ngIf="field.suffixTemplate && field.suffixTemplate.templateRef">
            <ng-container
                *ngTemplateOutlet="
                    field.suffixTemplate.templateRef;
                    context: field.suffixTemplate.templateData
                        ? field.suffixTemplate.templateData
                        : null
                "
            >
            </ng-container>
        </div>
    </div>
    <!-- field type two end -->

    <!-- Field type three start -->
    <div
        *ngIf="field.appearance === FieldAppearance.TYPE_3"
        class="form-row {{ field.extraClass }}"
    >
        <span class="form-label">
            {{ field.label }}
            <span *ngIf="field.required" class="mat-form-field-required-marker">
                *
            </span>

            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
            >
                {{ field.prefixIcon.iconData.class }}
            </mat-icon>
            <mat-icon
                *ngIf="field.showSuffixIconInLabel && field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
                class="clickable"
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>
        </span>

        <mat-form-field
            #textFieldRef
            [class.form-input]="true"
            [appearance]="'legacy'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [hideRequiredMarker]="true"
            [floatLabel]="'never'"
        >
            <input
                matInput
                type="text"
                [ngClass]="field.extraClass ? field.extraClass : ''"
                [placeholder]="field.placeholder"
                [name]="field.name"
                [formControl]="control"
                [errorStateMatcher]="matcher"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                (focus)="
                    field.prefixIcon ? textFieldRef.updateOutlineGap() : ''
                "
                (change)="
                    field.onChange ? field.onChange($event, formGroup) : null
                "
                (focusout)="
                    field.onFocusout
                        ? field.onFocusout($event, formGroup, field.name)
                        : null
                "
            />
            <span *ngIf="field.prefixText" matPrefix>
                {{ field.prefixText }}</span
            >
            <span *ngIf="field.suffixText" matSuffix>
                {{ field.suffixText }}</span
            >
            <mat-icon
                *ngIf="!field.showSuffixIconInLabel && field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
                >{{ field.suffixIcon.iconData.class }}</mat-icon
            >

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
        <div *ngIf="field.suffixTemplate && field.suffixTemplate.templateRef">
            <ng-container
                *ngTemplateOutlet="
                    field.suffixTemplate.templateRef;
                    context: field.suffixTemplate.templateData
                        ? field.suffixTemplate.templateData
                        : null
                "
            >
            </ng-container>
        </div>
    </div>
    <!-- Field type three end -->

    <!-- Field type Four start -->
    <div
        *ngIf="field.appearance === FieldAppearance.TYPE_4"
        class="form-row {{ field.extraClass }}"
    >
        <mat-form-field
            #textFieldRef
            [class]="'full-width-input type-4'"
            [appearance]="'legacy'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [hideRequiredMarker]="field.required === false"
            [floatLabel]="'never'"
        >
            <input
                matInput
                type="text"
                [ngClass]="field.extraClass ? field.extraClass : ''"
                [placeholder]="field.placeholder"
                [name]="field.name"
                [formControl]="control"
                [errorStateMatcher]="matcher"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                (change)="
                    field.onChange ? field.onChange($event, formGroup) : null
                "
                (focusout)="
                    field.onFocusout
                        ? field.onFocusout($event, formGroup, field.name)
                        : null
                "
            />
            <span *ngIf="field.prefixText" matPrefix>
                {{ field.prefixText }}</span
            >
            <span *ngIf="field.suffixText" matSuffix>
                {{ field.suffixText }}</span
            >
            <mat-icon
                *ngIf="!field.showSuffixIconInLabel && field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
                >{{ field.suffixIcon.iconData.class }}</mat-icon
            >

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
        <div *ngIf="field.suffixTemplate && field.suffixTemplate.templateRef">
            <ng-container
                *ngTemplateOutlet="
                    field.suffixTemplate.templateRef;
                    context: field.suffixTemplate.templateData
                        ? field.suffixTemplate.templateData
                        : null
                "
            >
            </ng-container>
        </div>
    </div>
    <!-- Field type four end -->

    <!-- Field type seven start -->
    <div
        *ngIf="field.appearance === FieldAppearance.TYPE_7"
        class="form-row type-7 {{ field.extraClass }}"
    >
        <span class="form-label type-7">
            {{ field.label }}
            <span *ngIf="field.required" class="mat-form-field-required-marker">
                *
            </span>

            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
            >
                {{ field.prefixIcon.iconData.class }}
            </mat-icon>
            <mat-icon
                *ngIf="field.showSuffixIconInLabel && field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
                class="clickable"
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>
        </span>

        <mat-form-field
            #textFieldRef
            [appearance]="'outline'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [hideRequiredMarker]="true"
            [style.width.%]="100"
            [style.margin-top.px]="4"
        >
            <input
                matInput
                type="text"
                [ngClass]="field.extraClass ? field.extraClass : ''"
                [placeholder]="field.placeholder"
                [name]="field.name"
                [formControl]="control"
                [errorStateMatcher]="matcher"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                (focus)="
                    field.prefixIcon ? textFieldRef.updateOutlineGap() : ''
                "
                (change)="
                    field.onChange ? field.onChange($event, formGroup) : null
                "
                (focusout)="
                    field.onFocusout
                        ? field.onFocusout($event, formGroup, field.name)
                        : null
                "
            />
            <span *ngIf="field.prefixText" matPrefix>
                {{ field.prefixText }}</span
            >
            <span *ngIf="field.suffixText" matSuffix>
                {{ field.suffixText }}</span
            >
            <mat-icon
                *ngIf="!field.showSuffixIconInLabel && field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
                >{{ field.suffixIcon.iconData.class }}</mat-icon
            >

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
        <div *ngIf="field.suffixTemplate && field.suffixTemplate.templateRef">
            <ng-container
                *ngTemplateOutlet="
                    field.suffixTemplate.templateRef;
                    context: field.suffixTemplate.templateData
                        ? field.suffixTemplate.templateData
                        : null
                "
            >
            </ng-container>
        </div>
    </div>
    <!-- Field type seven ends -->
</div>
<ng-template #groupError>
    <ng-container
        *ngIf="
            formGroup.get(field.groupByKey).get(field.name).touched &&
            formGroup.get(field.groupByKey).get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation
                        : formGroup.get(field.groupByKey).get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
<ng-template #error>
    <ng-container
        *ngIf="
            formGroup.get(field.name).touched &&
            formGroup.get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation : formGroup.get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
