<div class="testing-widget-container">
    <div class="close-testing-modal" (click)="closeTesting()">
        <i class="fas fa-times-circle"></i>
    </div>

    <div class="widget-container">
        <app-widget-generator
            *ngIf="showInsight && widgetData.has(widgetId)"
            [data]="widgetData.get(widgetId)"
        ></app-widget-generator>
        <div class="overlay" *ngIf="!showInsight">
            <div class="overlay-content">
                <div
                    appIcon
                    [data]="spinnerLoader"
                    class="overlay-loader"
                ></div>
            </div>
        </div>
        <div
            class="error-message"
            *ngIf="widgetData.has(widgetId) && !widgetData.get(widgetId)"
        >
            <i class="fas fa-exclamation-circle"></i>
            Widget Id entered was invalid or server not started
        </div>
    </div>
</div>
