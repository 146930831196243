import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
export class CacheMain {
    private ignoredKeys = [];
    private _enableCaching;
    private _prefix: string;

    constructor(prefix: string, enableCaching: boolean = false) {
        this._prefix = prefix;
        this._enableCaching = enableCaching;
    }

    keyGenerator(key) {
        if (
            GlobalConfiguration.PREVIEW_MODE &&
            !GlobalConfiguration?.pseudoConsoleConfig?.allowAccessToStorageKeys
        ) {
            return 'CONSOLE_PREVIEW||' + this._prefix + '||' + key;
        } else {
            return this._prefix + '||' + key;
        }
    }

    store(key: string, value: any): void {
        if (!this._enableCaching) {
            return null;
        }
        if (!this._prefix) {
            return null;
        }
        // const sizeInKb = Helper.getSizeInKb(value);
        // if (sizeInKb > 100) {
        //     return null;
        // }

        localStorage.setItem(this.keyGenerator(key), JSON.stringify(value));
    }

    fetch(key: string): any {
        if (!this._prefix) {
            return null;
        }

        const cachedObj = localStorage.getItem(this.keyGenerator(key));
        if (cachedObj) {
            return JSON.parse(cachedObj);
        }
        return null;
    }

    deleteKey(key: string): void {
        if (!this._prefix) {
            return null;
        }
        localStorage.removeItem(this.keyGenerator(key));
    }

    deleteCollection(): void {
        if (!this._prefix) {
            return null;
        }
        const keysToDelete = Object.keys(localStorage).filter((key) =>
            key.includes(this.keyGenerator(''))
        );
        keysToDelete.forEach((key) => {
            localStorage.removeItem(key);
        });
    }
}
