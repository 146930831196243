import {
    ChangeDetectorRef,
    Component,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { GridOptions, RowEvent, RowNode } from 'ag-grid-community';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ActionState } from 'src/app/shared/enums/ActionState';
import { ActionVisibility } from 'src/app/shared/enums/ActionVisibility';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { WidgetCreationService } from 'src/app/shared/services/widget-creation/widget-creation.service';
import { ConfirmationModalComponent } from '../../../modal-templates/confirmation-modal/confirmation-modal.component';
import { TerraformMapVariablesModalComponent } from '../../../modal-templates/terraform/terraform-map-variables-modal/terraform-map-variables-modal.component';
import { TerraformRequestFormModalComponent } from '../../../modal-templates/terraform/terraform-request-form-modal/terraform-request-form-modal.component';
import { UploadTfFilesModalComponent } from '../../../modal-templates/terraform/upload-tf-files-modal/upload-tf-files-modal.component';
import { RequestWidgetTemplateModalComponent } from '../../../modal-templates/widget-creation-modal/request-widget-template-modal/request-widget-template-modal.component';
import { MultiButtonGeneratorComponent } from '../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-terraform-create-widget',
    templateUrl: './terraform-create-widget.component.html',
    styleUrls: ['./terraform-create-widget.component.sass']
})
export class TerraformCreateWidgetComponent implements OnInit {
    tableInput: ITableGeneratorInput;
    widgetRef: Widget;
    agGrid: GridOptions;
    selectedWidgets = [];
    objectKeys = Object.keys;
    selectionDone = false;
    editIcon: IIcon = {
        type: IconType.SVG,
        class: 'info_icon'
    };
    @ViewChild('infoIcon') infoIconTemplateRef: TemplateRef<any>;
    @ViewChild('bulkDeleteList') bulkDeleteTemplateRef: TemplateRef<any>;
    @ViewChild('deleteFailedResponseView')
    deleteFailedResponseViewRef: TemplateRef<any>;

    deleteErrorResponse = [];
    apiResponse: any;
    counter: number = 1;
    ragCellClassRules: any;
    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService,
        private filterService: FiltersService,
        private widgetCreationService: WidgetCreationService,
        private cd: ChangeDetectorRef
    ) {
        this.widgetRef = widgetData.widgetRef;
        this.widgetCreationService.widgetRef = this.widgetRef;
    }

    ngOnInit(): void {
        this.widgetRef.showViewIcon.next(true);
        this.setUpBasics();
        this.applyOperationalActions();
        this.widgetRef.refreshWidgetCallback =
            this.refreshWidgetCallback.bind(this);
    }

    refreshWidgetCallback() {
        this.widgetRef.showNotificationBadge.next(false);
        this.tableInput.columns = this.tableInput.columns.filter(
            (each) => each.columnKey !== 'tags'
        );
    }

    setUpBasics() {
        this.tableInput = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            buttons: [
                {
                    buttonName: 'Create Widget',
                    buttonColorType: ButtonColorType.PRIMARY,
                    buttonType: ButtonType.FLAT,
                    function: (buttonRef) => {
                        this.openCreateWidget();
                    }
                }
            ],
            tableHeight: 300,
            selection: 'multiple',
            selectionLimit: GlobalConfiguration.CUSTOM_WIDGET_LIMIT,
            afterResponse: (res: any) => {
                this.apiResponse = res;
            },
            isRowSelectable: this.isRowSelectable.bind(this),
            columns: [
                {
                    columnKey: 'widgetName',
                    columnName: 'Widget Name',
                    flex: 1
                },
                {
                    columnKey: 'status',
                    columnName: 'Status',
                    flex: 1,
                    cellRenderer: (rowData: RowEvent) => {
                        return Helper.generateColumnButtons(
                            [
                                {
                                    buttonName: rowData.data['status'],
                                    buttonIcon: {
                                        type: IconType.FONTAWSOME,
                                        class: ''
                                    },
                                    buttonColor:
                                        rowData.data['status'] === 'Draft'
                                            ? 'blueInfoColor'
                                            : 'success',
                                    showLoader: true,
                                    function: () => {}
                                }
                            ],
                            Helper.getCssVarValue.bind(this.widgetRef),
                            this.widgetRef.modalService,
                            null,
                            'text',
                            { justifyContent: 'flex-start' }
                        );
                    }
                },
                {
                    columnKey: 'createdOn',
                    columnName: 'Create Date & Time',
                    flex: 1
                },
                {
                    columnKey: 'lastModifiedOn',
                    columnName: 'Last Modified Date & Time',
                    flex: 1
                },
                {
                    columnKey: 'acl',
                    columnName: 'ACL',
                    flex: 1
                },
                {
                    columnName: 'Action',
                    columnKey: 'action',
                    pinned: 'right',
                    minWidth: 200,
                    buttonGen: true,
                    cellClass: 'action-button',
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData) => {
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'Edit',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.INFO,
                                disable: !rowData.data['modifiable'],
                                hoverText: !rowData.data['modifiable']
                                    ? 'Cannot edit widget as it is attached to customer or user.'
                                    : '',
                                function: (buttonRef) => {
                                    buttonRef.loader = true;
                                    this.getRequestWidgetData(
                                        rowData.data['id'],
                                        true,
                                        buttonRef
                                    );
                                },
                                showLoader: true
                            },
                            {
                                buttonName: 'Preview',
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    buttonRef.loader = true;
                                    this.getRequestWidgetData(
                                        rowData.data['id'],
                                        false,
                                        buttonRef,
                                        true
                                    );
                                },
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.SUCCESS,
                                showLoader: true
                            },
                            {
                                buttonName: 'Delete',
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    this.openDeleteModal([rowData.data]);
                                },
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.WARN,
                                showLoader: true
                            }
                        ];
                        rowData['buttonGenInputs'] = buttons;
                        return rowData;
                    }
                }
            ],
            noDataMessage: Messages.NO_CUSTOM_WIDGET,
            showNotificationBadgeOnSelection:
                this.widgetRef.showNotificationBadge
        };
    }

    getRequestWidgetData(
        widgetId,
        isEdit,
        buttonRef: IButtonGeneratorInput,
        isPreview = false
    ) {
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                'myWidgets'
            ]
        );
        apiArgs.intactUrl = apiArgs.url;
        apiArgs.url = apiArgs.url.replace('{widget_id}', widgetId);
        apiArgs.input = {};
        apiArgs.function = (response) => {
            response['widgetName'] = response.widgetName
                ? response.widgetName
                : '';
            if (response && response['description']) {
                const apiArgs: IHitApi = {
                    uniqueIdentity: Symbol(),
                    url: `${ApiUrls.DESCRIPTION_INFO_API_PATH}${response['description']}`,
                    config: {
                        authorization: AuthorizationType.BEARER_TOKEN
                    },
                    intactUrl: `${ApiUrls.DESCRIPTION_INFO_API_PATH}{id}`,
                    function: (descriptionResponse) => {
                        buttonRef.loader = false;
                        response['description'] = descriptionResponse;
                        this.openCreateWidget(
                            response,
                            isEdit,
                            isPreview,
                            widgetId
                        );
                        this.cd.detectChanges();
                    },
                    errorFunction: (error) => {
                        buttonRef.loader = false;
                        Helper.showErrorMessage(
                            this.widgetRef.notificationsService,
                            error,
                            'Error while getting description'
                        );
                        this.cd.detectChanges();
                    },
                    requestType: RequestType.GET,
                    input: {}
                };
                new HitApi(
                    apiArgs,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            } else {
                buttonRef.loader = false;
                this.openCreateWidget(response, isEdit, isPreview, widgetId);
                this.cd.detectChanges();
            }
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while getting data'
            );
            buttonRef.loader = false;
            this.cd.detectChanges();
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    applyOperationalActions() {
        this.widgetRef.operationalActions.next(
            new Map().set('Bulk Action', [
                {
                    icon: {
                        type: IconType.SVG,
                        class: 'trash',
                        text: 'Bulk Delete',
                        extraClass: 'inline-fix-box-1'
                    },
                    function: () => {
                        this.openDeleteModal(
                            this.agGrid.api
                                .getSelectedNodes()
                                .map((each) => each.data)
                        );
                    },
                    message: null,
                    state: ActionState.ENABLED,
                    visibility: ActionVisibility.VISIBLE
                }
            ])
        );
    }

    openDeleteModal(data) {
        this.selectedWidgets = data;

        if (this.selectedWidgets.length === 0) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Select atleast one widget'
            );
            return;
        }

        const confirmationModalData: IConfirmationModal = {
            function: (modalId: Symbol) => {
                const apiArgs: IHitApi = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo.delete
                );

                const deleteIds = data.map((each) => each.id);

                apiArgs.input = deleteIds;

                (apiArgs.function = (response) => {
                    this.widgetRef.modalService.closeModal(null, modalId);

                    if (response && response.error) {
                        this.deleteErrorResponse = response.attachedToWidget;
                        this.openDeleteFailedModal(
                            this.objectKeys(response.attachedToWidget[0])[0]
                        );
                        return;
                    }

                    this.widgetRef.notificationsService.showSnackBar(
                        'Deleted successfully'
                    );

                    this.widgetRef.refreshWidget();
                }),
                    (apiArgs.errorFunction = (error) => {
                        this.widgetRef.modalService.closeModal(null, modalId);
                        Helper.showErrorMessage(
                            this.widgetRef.notificationsService,
                            error,
                            'Error Deleting.'
                        );
                    }),
                    new HitApi(
                        apiArgs,
                        this.widgetRef.httpService,
                        this.widgetRef.ngZone
                    ).hitApi();
            },
            modalName: 'Delete Widget',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            contextIcon: {
                extraClass: 'color-accent',
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclamation-triangle'
            },
            confirmationMessage: `Are you sure you want to delete this widget ?`,
            buttonText: 'Confirm',
            buttonColorType: ButtonColorType.WARN,
            loaderOnExec: true,
            bodyTemplate: this.bulkDeleteTemplateRef,
            fontSize: 1.12
        };

        const modalData: IModalData = {
            modalName: 'Delete Widget',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            modalType: ModalType.MINI_MODAL,
            sourceId: this.widgetRef.uniqueIdentity,
            modalHeightVh: 40,
            modalWidthVw: 45,
            modalAutoHeight: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ConfirmationModalComponent,
                        payload: {
                            data: {
                                function: confirmationModalData.function,
                                params: confirmationModalData
                            }
                        }
                    },
                    stepName: ''
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    openDeleteFailedModal(widgetName) {
        const confirmationModalData: IConfirmationModal = {
            function: null,
            modalName: 'Delete Widget',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            contextIcon: {
                extraClass: 'color-accent',
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclamation-triangle'
            },
            confirmationMessage: `${widgetName} cannot be Deleted because it is attached to following customers and users.`,
            buttonText: 'Confirm',
            buttonColorType: ButtonColorType.WARN,
            loaderOnExec: true,
            bodyTemplate: this.deleteFailedResponseViewRef,
            fontSize: 1.125,
            hideButtons: true
        };

        const modalData: IModalData = {
            modalName: 'Delete Widget',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            modalType: ModalType.MINI_MODAL,
            sourceId: this.widgetRef.uniqueIdentity,
            modalHeightVh: 55,
            modalWidthVw: 55,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ConfirmationModalComponent,
                        payload: {
                            data: {
                                function: confirmationModalData.function,
                                params: confirmationModalData
                            }
                        }
                    },
                    stepName: 'Delete'
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    openCreateWidget(
        templateData?,
        isEdit = false,
        isPreview = false,
        widgetId = null
    ) {
        const edit = isEdit;
        if (!templateData) {
            templateData = {
                widgetName: '',
                description: ''
            };
            isEdit = true;
        }

        const modalData: IModalData = {
            modalName: '',
            modalIcon: null,
            modalType: ModalType.MIDDLE,
            sourceId: this.widgetRef.uniqueIdentity,
            modalWidthVw: 85,
            modalHeightVh: 95,
            hideSteps: true,
            disableClose: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: RequestWidgetTemplateModalComponent,
                        payload: {
                            data: {
                                templateData,
                                widgetRef: this.widgetRef,
                                isEdit,
                                isPreview,
                                widgetId,
                                alreadyExistWidgetNames: this.apiResponse.map(
                                    (each) =>
                                        each.widgetName.toLocaleLowerCase()
                                ),
                                hideRequestButton: false,
                                hideDescriptionHeader: isPreview,
                                isTerraform: true,
                                requestButtonMessage:
                                    'Configure the request form to enable this button'
                            }
                        }
                    },
                    stepName: 'Create',
                    headingText: 'Request Widget Template',
                    resetModal: isPreview ? false : true,
                    headerIconTemplate: !isPreview
                        ? this.infoIconTemplateRef
                        : null
                },
                {
                    stepData: {
                        componentToLoad: UploadTfFilesModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                response: templateData,
                                isEdit: edit,
                                widgetId: widgetId
                            }
                        }
                    },
                    stepName: 'Create',
                    headingText: 'Upload TF Files',
                    resetModal: true,
                    headerIconTemplate: this.infoIconTemplateRef
                },
                {
                    stepData: {
                        componentToLoad: TerraformRequestFormModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                response: templateData,
                                isEdit: edit,
                                widgetId: widgetId
                            }
                        }
                    },
                    stepName: 'Create',
                    headingText: 'Configure Request Form',
                    resetModal: true,
                    headerIconTemplate: this.infoIconTemplateRef
                },
                {
                    stepData: {
                        componentToLoad: TerraformMapVariablesModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                isEdit: edit,
                                widgetId: widgetId
                            }
                        }
                    },
                    headingText: 'Map Variables',
                    stepName: 'Create',
                    resetModal: true,
                    headerIconTemplate: this.infoIconTemplateRef
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }
    isRowSelectable(rowNode: RowNode) {
        return rowNode.data['modifiable'];
    }
}
