<div class="form-container tw-flex-1 tw-flex tw-py-2 tw-px-5">
    <div class="first-container tw-flex-1 tw-flex tw-flex-col tw-mt-3">
        <span class="label tw-text-fs-400"> Select Customer </span>
        <span class="info tw-text-fs-200 tw-mt-5 tw-py-4 tw-px-2">
            Select customer to grant access using same credentials.
        </span>
    </div>
    <div
        class="second-container tw-flex-f3 tw-flex tw-flex-col tw-ml-11 tw-py-0 tw-px-6"
        *ngIf="filterInfo"
    >
        <app-multi-select-dropdown
            [filterInfo]="filterInfo"
            [selectedValues]="selectedCustomers"
            [isCaching]="false"
            (change)="selectedCustomers = $event"
        ></app-multi-select-dropdown>
    </div>
</div>
<div class="btn tw-mx-5 tw-mb-5">
    <app-multi-button-generator [buttonGenInputs]="buttonInputs">
    </app-multi-button-generator>
</div>
