import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { SubWidgetInjectedData } from 'src/app/shared/classes/SubWidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { CustomValidators } from './../../../../../classes/CustomValidators';
import { Helper } from './../../../../../classes/Helper';
import { HitApi } from './../../../../../classes/HitApi';
import { FilterType } from './../../../../../enums/FilterType';
import { FormState } from './../../../../../enums/FormState';
import { IconType } from './../../../../../enums/IconType';
import { IFormField } from './../../../../../interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from './../../../../../interfaces/form-generator/IFormGeneratorInput';

interface ISocialChannel {
    label: string;
    url?: URL;
}
enum SocialChannelUrl {
    ORGANISATION_URL = 'Organisation Url',
    KNOWLEDGE_BASE_URL = 'Knowledge Base Url',
    FACEBOOK_URL = 'Facebook Url',
    LINKEDIN_URL = 'Linkedin Url',
    TWITTER_URL = 'Twitter Url'
}
@Component({
    selector: 'app-whitelabeling-social',
    templateUrl: './whitelabeling-social.component.html',
    styleUrls: ['./whitelabeling-social.component.sass']
})
export class WhitelabelingSocialComponent implements OnInit, OnDestroy {
    resetObservable: Subject<string>;
    resetSubscription: Subscription;
    subWidgetId: string;
    parentWidgetRef;
    whiteLabelingData;
    socialChannels: ISocialChannel[] = [];
    formGenInput: IFormGeneratorInput;
    tempSocialChannel;
    reInitializeWhiteLabelData: Function;

    constructor(
        private subWidgetData: SubWidgetInjectedData,
        public globalDataService: GlobalDataService
    ) {
        this.whiteLabelingData = subWidgetData.data.whiteLabelingData;
        this.subWidgetId = subWidgetData.subWidgetId;
        this.resetObservable = subWidgetData.resetObserver;
        this.parentWidgetRef = subWidgetData.parentWidgetRef;
        this.reInitializeWhiteLabelData =
            subWidgetData.data.reInitializeWhiteLabelData;
    }

    ngOnInit(): void {
        this.initResetObservable();
        this.initFormGenInput();
    }

    initResetObservable() {
        if (this.resetObservable) {
            this.resetSubscription = this.resetObservable.subscribe(
                (targetId) => {
                    if (targetId === this.subWidgetId) {
                        this.initFormGenInput();
                    }
                }
            );
        }
    }

    initFormGenInput() {
        this.formGenInput = null;
        this.subWidgetData.parentWidgetRef.changeDetectorRef.detectChanges();

        let socialMediaUrls;
        const data = this.subWidgetData.data.whiteLabelingDraft.socialMediaUrl;

        if (this.whiteLabelingData && this.whiteLabelingData.socialMediaUrl) {
            socialMediaUrls = Helper.cloneDeep(
                this.whiteLabelingData.socialMediaUrl
            );
            Object.keys(socialMediaUrls).forEach((key) => {
                data[key] = socialMediaUrls[key];
            });

            socialMediaUrls = data;
            this.tempSocialChannel = socialMediaUrls;
        } else {
            socialMediaUrls =
                this.subWidgetData.data.whiteLabelingDraft.socialMediaUrl;
            this.tempSocialChannel = socialMediaUrls;
        }

        this.formGenInput = {
            formName: 'Configure Social Channels',
            state: FormState.CREATE,
            submitButton: {
                buttonName: 'Save As Draft',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                disable: GlobalConfiguration.PREVIEW_MODE,
                hoverText: GlobalConfiguration.PREVIEW_MODE
                    ? 'Cannot perform this action in preview mode'
                    : null,
                function: (buttonRef) => {
                    this.saveConfiguration(buttonRef);
                }
            },
            fields: [
                {
                    fieldType: FilterType.TEXT,
                    label: 'Website URL',
                    name: 'organizationalUrl',
                    placeholder: '',
                    required: false,
                    showLabel: true,
                    showSuffixIconInLabel: true,
                    appearance: 'legacy',
                    value: socialMediaUrls
                        ? socialMediaUrls['organizationalUrl']
                        : null,
                    validations: [
                        {
                            validator: CustomValidators.url,
                            errorMessage: 'Enter correct URL'
                        }
                    ],
                    suffixIcon: this.getSuffixIconButton(
                        SocialChannelUrl.ORGANISATION_URL
                    )
                },
                {
                    fieldType: FilterType.TEXT,
                    label: 'Knowledge Base URL',
                    name: 'knowledgeBaseUrl',
                    placeholder: '',
                    required: false,
                    showLabel: true,
                    showSuffixIconInLabel: true,
                    appearance: 'legacy',
                    value: socialMediaUrls
                        ? socialMediaUrls['knowledgeBaseUrl']
                        : null,
                    validations: [
                        {
                            validator: CustomValidators.url,
                            errorMessage: 'Enter correct URL'
                        }
                    ],
                    suffixIcon: this.getSuffixIconButton(
                        SocialChannelUrl.KNOWLEDGE_BASE_URL
                    )
                },
                {
                    fieldType: FilterType.TEXT,
                    label: 'Facebook URL',
                    name: 'facebookUrl',
                    placeholder: '',
                    required: false,
                    showLabel: true,
                    showSuffixIconInLabel: true,
                    appearance: 'legacy',
                    value: socialMediaUrls
                        ? socialMediaUrls['facebookUrl']
                        : null,
                    validations: [
                        {
                            validator: CustomValidators.url,
                            errorMessage: 'Enter correct URL'
                        }
                    ],
                    suffixIcon: this.getSuffixIconButton(
                        SocialChannelUrl.FACEBOOK_URL
                    )
                },
                {
                    fieldType: FilterType.TEXT,
                    label: 'LinkedIn URL',
                    name: 'linkedinUrl',
                    placeholder: '',
                    required: false,
                    showLabel: true,
                    showSuffixIconInLabel: true,
                    appearance: 'legacy',
                    value: socialMediaUrls
                        ? socialMediaUrls['linkedinUrl']
                        : null,
                    validations: [
                        {
                            validator: CustomValidators.url,
                            errorMessage: 'Enter correct URL'
                        }
                    ],
                    suffixIcon: this.getSuffixIconButton(
                        SocialChannelUrl.LINKEDIN_URL
                    )
                },
                {
                    fieldType: FilterType.TEXT,
                    label: 'Twitter URL',
                    name: 'twitterUrl',
                    placeholder: '',
                    required: false,
                    showLabel: true,
                    showSuffixIconInLabel: true,
                    appearance: 'legacy',
                    value: socialMediaUrls
                        ? socialMediaUrls['twitterUrl']
                        : null,
                    validations: [
                        {
                            validator: CustomValidators.url,
                            errorMessage: 'Enter correct URL'
                        }
                    ],
                    suffixIcon: this.getSuffixIconButton(
                        SocialChannelUrl.TWITTER_URL
                    )
                }
            ]
        };
    }

    getSuffixIconButton(fieldName) {
        const moreInfoMessage = {
            [SocialChannelUrl.ORGANISATION_URL]: {
                heading: 'Website URL',
                data: [
                    `Enter the website address of your homepage. This address will be added as hyperlinks where redirection is required.`,
                    `This is an optional field. If not provided, then the hyperlink will be disabled, and the redirections icons will also not appear.`
                ]
            },
            [SocialChannelUrl.KNOWLEDGE_BASE_URL]: {
                heading: 'Knowledge Base URL',
                data: [
                    `Enter the knowledge base address of your organization. This address will be added as hyperlinks where redirection is required.`,
                    `This is an optional field. If not provided, then the hyperlink will be disabled and the redirection icons will also not appear.`
                ]
            },
            [SocialChannelUrl.FACEBOOK_URL]: {
                heading: 'Facebook URL',
                data: [
                    `Enter the address of your company’s official Facebook page. This address will be added as hyperlinks where redirection is required.`,
                    `This is an optional field. If not provided, then the hyperlink will be disabled, and the redirections icons will also not appear.`
                ]
            },
            [SocialChannelUrl.LINKEDIN_URL]: {
                heading: 'Linkedin URL',
                data: [
                    `Enter the address of your company’s official LinkedIn page. This address will be added as hyperlinks where redirection is required.`,
                    `This is an optional field. If not provided, then the hyperlink will be disabled, and the redirections icons will also not appear.`
                ]
            },
            [SocialChannelUrl.TWITTER_URL]: {
                heading: 'Twitter URL',
                data: [
                    `Enter the address of your company’s official twitter page. This address will beadded as hyperlinks where redirection is required.`,
                    `This is an optional field. If not provided, then the hyperlink will be disabled, and the redirections icons will also not appear.`
                ]
            }
        };
        return {
            hoverText: 'More Info',
            iconData: {
                type: IconType.MATICON,
                class: 'info'
            },
            function: (field: IFormField, formGroup: FormGroup) => {
                this.parentWidgetRef.modalService.openInfoModal({
                    infoHeading: moreInfoMessage[fieldName].heading,
                    content: [
                        {
                            type: 'UNORDERED_LIST',
                            data: moreInfoMessage[fieldName].data,
                            listStyleType: 'disc'
                        }
                    ]
                });
            }
        };
    }

    setSocialChannels(event) {
        this.tempSocialChannel = event.value;
    }

    saveConfiguration(buttonRef) {
        if (this.subWidgetData.data.saveAsDraftApiInfo) {
            buttonRef.loader = true;
            const socialDraft = Helper.cloneDeep(
                this.subWidgetData.data.whiteLabelingDraft
            );
            socialDraft['socialMediaUrl'] = Helper.cloneDeep(
                this.tempSocialChannel
            );

            const apiConfig = Helper.generateHitApiConfig(
                this.subWidgetData.data.saveAsDraftApiInfo
            );

            apiConfig.input = socialDraft;

            apiConfig.function = (response) => {
                buttonRef.loader = false;
                this.subWidgetData.data.whiteLabelingData.socialMediaUrl =
                    Helper.cloneDeep(this.tempSocialChannel);
                this.subWidgetData.parentWidgetRef.notificationsService.showSnackBar(
                    'Configuration saved successfully. Click on publish to deploy these changes.',
                    false,
                    '',
                    { duration: 3500 }
                );
                if (this.reInitializeWhiteLabelData) {
                    this.reInitializeWhiteLabelData();
                }
                this.initFormGenInput();
                this.subWidgetData.parentWidgetRef.changeDetectorRef.detectChanges();
            };
            apiConfig.errorFunction = (error) => {
                buttonRef.loader = false;
                this.subWidgetData.parentWidgetRef.changeDetectorRef.detectChanges();
                Helper.showErrorMessage(
                    this.subWidgetData.parentWidgetRef.notificationsService,
                    error,
                    'Error in saving Social Configuration'
                );
            };

            new HitApi(
                apiConfig,
                this.parentWidgetRef.httpService,
                this.parentWidgetRef.ngZone
            ).hitApi();
        } else {
            buttonRef.loader = false;
        }
    }

    ngOnDestroy(): void {
        if (this.resetSubscription) {
            this.resetSubscription.unsubscribe();
        }
    }
}
