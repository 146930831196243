import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { ModalInjectedData } from './../../../../../../classes/ModalInjectedData';
import { IButtonGeneratorInput } from './../../../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from './../../../../../../interfaces/form-generator/IFormGeneratorInput';

@Component({
    selector: 'app-ca-integration-step-one',
    templateUrl: './ca-integration-step-one.component.html',
    styleUrls: ['./ca-integration-step-one.component.sass']
})
export class CaIntegrationStepOneComponent implements OnInit {
    stepOneFormGenInputs: IFormGeneratorInput;
    stepOneFormGroup: FormGroup;
    widgetRef: Widget;
    stepData;
    buttonInput: IButtonGeneratorInput;
    resetSubscription: Subscription;

    constructor(
        private modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        private modalService: ModalService
    ) {}
    ngOnInit(): void {
        if (
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(1)
        ) {
            this.stepData = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1);
        }
        if (this.modalInputData.data.edit) {
            this.stepData = this.modalInputData.data.response.credentials;
        }
        this.setUpBasics();
        this.buttonInput = {
            buttonName: 'Next',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.RAISED,
            function: () => {
                this.save();
            }
        };
        this.resetSubscription = this.modalService.resetModal.subscribe(() => {
            this.stepOneFormGroup.reset();
        });
    }
    setUpBasics() {
        this.stepOneFormGenInputs = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'WebService URL',
                    placeholder: 'WebService URL',
                    name: 'webserviceUrl',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'WebService URL is required'
                        }
                    ],
                    value: this.stepData?.webserviceUrl
                        ? this.stepData.webserviceUrl
                        : ''
                },
                {
                    label: 'User Name',
                    placeholder: 'User Name',
                    name: 'userName',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    value:
                        this.stepData && this.stepData.userName
                            ? this.stepData.userName
                            : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'User name is required'
                        }
                    ]
                },
                {
                    label: 'Password',
                    placeholder: 'Password',
                    name: 'password',
                    fieldType: FilterType.PASSWORD,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    value:
                        this.stepData && this.stepData.password
                            ? this.stepData.password
                            : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Password is required'
                        }
                    ]
                },
                {
                    label: 'Desk Name',
                    placeholder: 'Desk Name',
                    name: 'deskName',
                    fieldType: FilterType.TEXT,
                    showLabel: true,
                    appearance: 'legacy',
                    required: true,
                    value:
                        this.stepData && this.stepData.deskName
                            ? this.stepData.deskName
                            : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Desk name is required'
                        }
                    ]
                }
            ]
        };
    }
    save() {
        Helper.markAllFieldAsTouched(this.stepOneFormGroup);
        if (this.stepOneFormGroup.invalid) {
            this.stepOneFormGroup.updateValueAndValidity();
            return;
        } else {
            this.multiStepFormService.nextStep(this.modalInputData.modalId);
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .set(1, this.stepOneFormGroup.value);
        }
    }
    ngOnDestroy() {
        this.resetSubscription.unsubscribe();
    }
}
