import { Component, OnInit } from '@angular/core';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';

@Component({
  selector: 'app-associated-assessments',
  templateUrl: './associated-assessments.component.html',
  styleUrls: ['./associated-assessments.component.sass']
})
export class AssociatedAssessmentsComponent implements OnInit {
    data: any;
     constructor(private modalInjectedData: ModalInjectedData) {
        this.data = modalInjectedData.data;
    }

    tableGenInput: ITableGeneratorInput;
    associatedAssessmentData: any;
    ngOnInit(): void {
        if (this.data.itemData['associations']) { 
            this.tableGenInput = {
                listExtraction: {
                    type: 'DIRECT',
                },
                columns: [
                    {
                        columnKey: 'assessmentName',
                        columnName: 'Assessment Name',
                        filter: false,
                        cellStyle: (param) => {
                            return {
                                justifyContent: 'flex-start'
                            };
                        }
                    }
                ],
            };
            this.associatedAssessmentData = this.data.itemData['associations']
        } else {
            this.tableGenInput = {
                listExtraction: {
                    type: 'DIRECT',
                },
                columns: [
                    {
                        columnKey: 'assessmentName',
                        columnName: 'Assessment Name',
                        filter: false,
                        cellStyle: (param) => {
                            return {
                                justifyContent: 'flex-start'
                            };
                        }
                    }
                ],
            };
            this.associatedAssessmentData = this.data.itemData['assosiatedAssessments']
        }
    }

}
