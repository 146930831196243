<div class="modal-container">
    <div class="modal-heading">
        <div class="icon" appIcon [data]="descriptionIcon"></div>
        Description
        <div class="close">
            <div
                (click)="modalService.closeModal(null, modalData.modalId)"
                class="icon"
                appIcon
                [data]="closeIcon"
            ></div>
        </div>
    </div>

    <div class="modal-description">
        <table>
            <tr *ngFor="let k of objectValues(keysToShow)">
                <th class="last-th">{{ k }}</th>
                <ng-container *ngIf="isObject(rowData[k])">
                    <td>
                        <ul>
                            <li>
                                {{
                                    Helper.extractDataFromObject(
                                        "value",
                                        rowData[k]
                                    )
                                }}
                            </li>
                        </ul>
                    </td>
                </ng-container>

                <ng-container *ngIf="!isObject(rowData[k])">
                    <td>{{ rowData[k] }}</td>
                </ng-container>
            </tr>
        </table>
    </div>
    <div class="sections" *ngIf="containsModalData">
        <ng-container *ngFor="let heading of getKeys(extraInfoData)">
            <div
                class="section"
                *ngIf="
                    extraInfoData[heading]['data'] &&
                    extraInfoData[heading]['data'].length > 0
                "
            >
                <div class="modal-heading">
                    <div class="icon" appIcon [data]="descriptionIcon"></div>
                    {{ heading }}
                </div>
                <div class="section-description">
                    <table>
                        <div class="xd-container xd-container-1">
                            <thead>
                                <tr>
                                    <th
                                        *ngFor="
                                            let thead of extraInfoData[heading][
                                                'columns'
                                            ]
                                        "
                                    >
                                        {{ thead }}
                                    </th>
                                </tr>
                            </thead>
                        </div>
                        <div class="xd-container xd-container-2">
                            <tbody>
                                <tr
                                    *ngFor="
                                        let trowObj of extraInfoData[heading][
                                            'data'
                                        ];
                                        let i = index
                                    "
                                >
                                    <td
                                        *ngFor="
                                            let tdColumn of extraInfoData[
                                                heading
                                            ]['columns']
                                        "
                                    >
                                        {{
                                            transformValue(
                                                extraInfoData[heading]["data"][
                                                    i
                                                ][tdColumn]
                                            )
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </div>
                    </table>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="modal-heading">
        <div class="icon" appIcon [data]="escalationIcon"></div>
        Escalation metrics
    </div>
    <div *ngIf="thresholdFormGenInputs" class="section-heading">
        <div class="title">Threshold Details:</div>
        <div class="actions">
            <div
                *ngIf="
                    thresholdFormGenInputs.state === FormState.IDLE;
                    then edit;
                    else idle
                "
            ></div>
            <ng-template #edit>
                <i (click)="changeState(FormState.EDIT)" class="fas fa-edit">
                </i>
            </ng-template>
            <ng-template #idle>
                <i
                    (click)="changeState(FormState.IDLE)"
                    class="fas fa-times"
                ></i>
            </ng-template>
        </div>
    </div>

    <app-form-generator
        *ngIf="thresholdFormGenInputs"
        [formGeneratorInput]="thresholdFormGenInputs"
        (formGroupRef)="thresholdFormGroup = $event"
    >
    </app-form-generator>
    <span class="form-header">{{ "Escalation metrics:" }}</span>

    <app-form-generator
        *ngIf="metricsFormGenInputs"
        [formGeneratorInput]="metricsFormGenInputs"
        (formGroupRef)="metricsFormGroup = $event"
        (valuesChange)="hitAlertEmailsApis()"
    >
    </app-form-generator>
    <app-form-generator
        *ngIf="emailFormGenInputs"
        [formGeneratorInput]="emailFormGenInputs"
        (formGroupRef)="emailFormGroup = $event"
    >
    </app-form-generator>
    <div
        class="overlay"
        *ngIf="!thresholdFormGenInputs || !metricsFormGenInputs"
    >
        <div class="overlay-content">
            <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
        </div>
    </div>
    <div class="btn">
        <app-button-generator
            *ngIf="saveButton"
            [buttonGenInput]="saveButton"
        ></app-button-generator>
    </div>
</div>
