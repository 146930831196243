<app-form-generator
    *ngIf="rootUserInfoFormGenInput"
    class="tw-flex-1"
    [formGeneratorInput]="rootUserInfoFormGenInput"
    (formGroupRef)="rootUserInfoFormGroup = $event"
></app-form-generator>
<div class="tw-mt-auto tw-mr-5 tw-ml-5 tw-mb-3">
    <app-multi-button-generator
        [buttonGenInputs]="buttonGenInputs"
        [options]="buttonOptions"
    ></app-multi-button-generator>
</div>
