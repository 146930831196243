import {
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    ViewChild
} from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { IGraphData } from '../../../interfaces/graph/IGraphData';
import { GraphType } from './../../../enums/GraphType';

@Component({
    selector: 'app-azure-ri-recommendation-modal-v2',
    templateUrl: './azure-ri-recommendation-modal-v2.component.html',
    styleUrls: ['./azure-ri-recommendation-modal-v2.component.sass']
})
export class AzureRiRecommendationModalV2Component implements OnInit {
    @ViewChild('overviewContainer') overviewElement: ElementRef;
    @ViewChild('recommendationsContainer') recommendationsElement: ElementRef;
    @ViewChild('versusContainer') versusElement: ElementRef;
    @ViewChild('descriptionContainer') descriptionElement: ElementRef;

    objectKeys = Object.keys;
    data: any;
    keys: any;
    tabs: any;
    selectedTab: string;
    parentActiveTab = 'overview';
    activeCheckboxes = [];
    applicableFilters = [];
    activeOfferingsTerms = [];
    selectedPaymentType: string;
    inputValue = {};
    totalObj = {};
    unitsLeft = 0;
    inputError = {};
    totalCountOfSelected = 0;
    comparisonCosts: {};
    crossIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fa fa-times'
    };

    arrowUpIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-up'
    };

    arrowDownIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-down'
    };

    scrollSubject: EventEmitter<any> = new EventEmitter<any>();
    overviewElementOffset: Number = null;
    recommendationsOffset: Number = null;
    versusElementOffset: Number = null;
    descriptionOffset: Number = null;
    sassValue = getComputedStyle(document.body);
    graphData: IGraphData;
    isV2: boolean = false; //true if version=2 is coming in dataMap
    overviewSavingsRadioButtonVale: string = 'Best Savings';
    verseusSavingsRadioButtonVale: string = 'Best Savings';

    constructor(
        public modalData: ModalInjectedData,
        private notificationsService: NotificationsService,
        public modalService: ModalService,
        public globalDataService: GlobalDataService
    ) {}

    ngOnInit() {
        this.data = this.modalData.data.item;
        if (
            this.modalData.data &&
            this.modalData.data.dataMap &&
            this.modalData.data.dataMap.version === 2.0
        ) {
            this.isV2 = true;
        }
        this.keys = this.objectKeys(this.data);
        this.tabs = this.objectKeys(this.data['amortizedCost']);

        this.initializeScrollEvent();

        if (Object.keys(this.data).includes('savingTable')) {
            this.selectedTab = 'savingTable';
        }
        for (const item of Object.keys(
            this.data.savingTable['Total Cost (' + this.data.currency + ')']
        )) {
            this.applicableFilters.push(item);

            for (const costType of Object.keys(
                this.data.savingTable[
                    'Total Cost (' + this.data.currency + ')'
                ][item]
            )) {
                if (!this.applicableFilters.includes(costType)) {
                    this.applicableFilters.push(costType);
                }
                if (this.isV2) {
                    for (const item2 of Object.keys(
                        this.data.savingTable[
                            'Total Cost (' + this.data.currency + ')'
                        ][item][costType]
                    )) {
                        if (!this.applicableFilters.includes(item2)) {
                            this.applicableFilters.push(item2);
                        }
                    }
                }
            }
        }
        if (this.applicableFilters.includes('Monthly')) {
            this.selectedPaymentType = 'Monthly';
        } else {
            this.selectedPaymentType = 'Upfront';
        }
        this.unitsLeft = this.data['typeDistribution']
            ? this.data['typeDistribution']['normalizationCount']
            : 0;

        const applicableFilters = this.applicableFilters;
        if ((applicableFilters.includes('1yr') && applicableFilters.includes('3yr')) ||
            applicableFilters.includes('1yr')) {
            if (applicableFilters.includes('Monthly') && applicableFilters.includes('Upfront')
                || applicableFilters.includes('Monthly')) {
                this.activeCheckboxes = ['Monthly', '1yr'];
                this.activeOfferingsTerms = ['Monthly', '1yr'];
            } else if (applicableFilters.includes('Upfront')) {
                this.activeCheckboxes = ['Upfront', '1yr'];
                this.activeOfferingsTerms = ['Upfront', '1yr'];
            }
        } else if (applicableFilters.includes('3yr')) {
            if (applicableFilters.includes('Monthly') && applicableFilters.includes('Upfront')
                || applicableFilters.includes('Monthly')) {
                this.activeCheckboxes = ['Monthly', '3yr'];
                this.activeOfferingsTerms = ['Monthly', '3yr'];
            } else if (applicableFilters.includes('Upfront')) {
                this.activeCheckboxes = ['Upfront', '3yr'];
                this.activeOfferingsTerms = ['Upfront', '3yr'];
            }
        }
        this.calculateCosts();
        this.prepareChartData();
    }

    initializeScrollEvent() {
        this.scrollSubject.pipe(debounceTime(150)).subscribe((event) => {
            const scrollPosition = event.target.scrollTop;
            const bottomPosition = event.target.scrollHeight;
            const offsetHeight = event.target.offsetHeight;
            if (
                scrollPosition >= this.overviewElementOffset &&
                scrollPosition < this.recommendationsOffset
            ) {
                this.parentActiveTab = 'overview';
            } else if (
                scrollPosition >= this.recommendationsOffset &&
                scrollPosition < this.versusElementOffset
            ) {
                this.parentActiveTab = 'recommendations';
            } else if (
                scrollPosition >= this.versusElementOffset &&
                scrollPosition < this.descriptionOffset
            ) {
                this.parentActiveTab = 'versus';
            }

            if (scrollPosition >= bottomPosition - offsetHeight) {
                this.parentActiveTab = 'description';
            }
        });
    }

    ngAfterViewInit() {
        this.overviewElementOffset =
            this.overviewElement.nativeElement.offsetTop;
        this.recommendationsOffset =
            this.recommendationsElement.nativeElement.offsetTop;
        this.versusElementOffset = this.versusElement.nativeElement.offsetTop;
        this.descriptionOffset =
            this.descriptionElement.nativeElement.offsetTop;
        this.parentActiveTab = 'overview';
    }

    prepareChartData() {
        const graphData = [];

        const combinationList = [
            {
                id: '1',
                year: '1yr',
                type: 'Monthly',
                costType: 'On Demand Cost'
            },
            {
                id: '2',
                year: '3yr',
                type: 'Monthly',
                costType: 'On Demand Cost'
            },
            {
                id: '3',
                year: '1yr',
                type: 'Monthly',
                costType: 'Total RI Cost'
            },
            {
                id: '4',
                year: '3yr',
                type: 'Monthly',
                costType: 'Total RI Cost'
            },
            {
                id: '5',
                year: '1yr',
                type: 'Upfront',
                costType: 'On Demand Cost'
            },
            {
                id: '6',
                year: '3yr',
                type: 'Upfront',
                costType: 'On Demand Cost'
            }
        ];

        const monthCombinationList = [
            {
                id: '7',
                month: '36',
                year: '1yr',
                type: 'Upfront',
                costType: 'Total RI Cost'
            },
            {
                id: '8',
                month: '36',
                year: '3yr',
                type: 'Upfront',
                costType: 'Total RI Cost'
            }
        ];

        Object.keys(this.comparisonCosts).map((month, index) => {
            const eachObj = {};

            if (index <= 11 && !this.activeOfferingsTerms.includes('3yr')) {
                eachObj['category'] = `Month ${index + 1}`;

                combinationList.forEach((combination) => {
                    const each = this.extractValue(
                        month,
                        combination.year,
                        combination.type,
                        combination.costType
                    );
                    if (each) {
                        eachObj[combination.id] = each;
                    }
                });

                monthCombinationList.forEach((combination) => {
                    const each = this.extractValue(
                        combination.month,
                        combination.year,
                        combination.type,
                        combination.costType
                    );
                    if (each) {
                        eachObj[combination.id] = each;
                    }
                });
            } else if (
                index <= 36 &&
                this.activeOfferingsTerms.includes('3yr')
            ) {
                eachObj['category'] = `Month ${index + 1}`;

                combinationList.forEach((combination) => {
                    const each = this.extractValue(
                        month,
                        combination.year,
                        combination.type,
                        combination.costType
                    );
                    if (
                        each &&
                        ((combination.year.includes('1') && index < 12) ||
                            combination.year.includes('3'))
                    ) {
                        eachObj[combination.id] = each;
                    }
                });

                monthCombinationList.forEach((combination) => {
                    const each = this.extractValue(
                        combination.month,
                        combination.year,
                        combination.type,
                        combination.costType
                    );
                    if (
                        each &&
                        ((combination.year.includes('1') && index < 12) ||
                            combination.year.includes('3'))
                    ) {
                        eachObj[combination.id] = each;
                    }
                });
            }
            graphData.push(eachObj);
        });

        const seriesList: string[] = [];

        if (this.selectedPaymentType === 'Monthly') {
            if (this.activeOfferingsTerms.includes('1yr')) {
                seriesList.push('1', '3');
            }
            if (this.activeOfferingsTerms.includes('3yr')) {
                seriesList.push('2', '4');
            }
        }

        if (this.selectedPaymentType === 'Upfront') {
            if (this.activeOfferingsTerms.includes('1yr')) {
                seriesList.push('5', '7');
            }

            if (this.activeOfferingsTerms.includes('3yr')) {
                seriesList.push('6', '8');
            }
        }

        const data: IGraphData = {
            graphType: GraphType.LINE,
            disableScrollBarX: false,
            disableScrollBarY: false,
            labelText: {
                categoryX: 'category',
                xAxesTitle: '',
                yAxesTitle: `Cost(${this.data.currency})`
            },
            chartData: graphData,
            seriesList: seriesList,
            toggleLegend: false,
            seriesName: this.getSeriesName.bind(this),
            extraClass: 'tw-flex-col',
            categoryAxisRotationAngle: 270,
            graphExtraClass: 'tw-w-full',
            showChartCursor: true,
            graphStyle: { 'min-height': '350px' }
        };

        this.graphData = data;
    }

    getSeriesName(name) {
        if (this.selectedPaymentType === 'Monthly') {
            if (this.activeOfferingsTerms.includes('1yr')) {
                if (name === '1') {
                    return '1-year On Demand Cost (' + this.data.currency + ')';
                } else if (name === '3') {
                    return '1-year Total RI Cost (' + this.data.currency + ')';
                }
            }
            if (this.activeOfferingsTerms.includes('3yr')) {
                if (name === '2') {
                    return (
                        '3-years On Demand Cost (' + this.data.currency + ')'
                    );
                } else if (name === '4') {
                    return '3-years Total RI Cost (' + this.data.currency + ')';
                }
            }
        }

        if (this.selectedPaymentType === 'Upfront') {
            if (this.activeOfferingsTerms.includes('1yr')) {
                if (name === '5') {
                    return '1-year On Demand Cost (' + this.data.currency + ')';
                } else if (name === '7') {
                    return '1-year Total RI Cost (' + this.data.currency + ')';
                }
            }

            if (this.activeOfferingsTerms.includes('3yr')) {
                if (name === '6') {
                    return (
                        '3-years On Demand Cost (' + this.data.currency + ')'
                    );
                } else if (name === '8') {
                    return '3-years Total RI Cost (' + this.data.currency + ')';
                }
            }
        }
    }

    extractValue(month, terms, type, costType) {
        if (costType === 'Total RI Cost') {
            month = terms === '3yr' ? 36 : 12;
        }

        if (
            this.comparisonCosts[month] &&
            this.comparisonCosts[month][`${costType} (${this.data.currency})`]
        ) {
            if (
                !this.isV2 &&
                this.comparisonCosts[month][
                    `${costType} (${this.data.currency})`
                ][type] &&
                this.comparisonCosts[month][
                    `${costType} (${this.data.currency})`
                ][type][terms]
            ) {
                return this.comparisonCosts[month][
                    `${costType} (${this.data.currency})`
                ][type][terms];
            }

            if (
                this.isV2 &&
                this.verseusSavingsRadioButtonVale &&
                this.comparisonCosts[month][
                    `${costType} (${this.data.currency})`
                ][this.verseusSavingsRadioButtonVale] &&
                this.comparisonCosts[month][
                    `${costType} (${this.data.currency})`
                ][this.verseusSavingsRadioButtonVale][type] &&
                this.comparisonCosts[month][
                    `${costType} (${this.data.currency})`
                ][this.verseusSavingsRadioButtonVale][type][terms]
            ) {
                return this.comparisonCosts[month][
                    `${costType} (${this.data.currency})`
                ][this.verseusSavingsRadioButtonVale][type][terms];
            }
        } else {
            return null;
        }
    }

    displayCondition(key1) {
        const savingsPercent = this.data['savingTable']['Savings (%)'];
        for (const element in this.isV2 && this.overviewSavingsRadioButtonVale
            ? savingsPercent[this.overviewSavingsRadioButtonVale]['Monthly']
            : savingsPercent['Monthly']) {
            if (element.includes(key1)) {
                return true;
            }
        }
    }

    changeParentTab(tabName, template?: HTMLElement) {
        this.parentActiveTab = tabName;
        if (template) {
            template.scrollIntoView({ behavior: 'smooth' });
        }
    }

    updateTotal(item, event, action?, inputElement?) {
        this.totalCountOfSelected = 0;
        const previousInputValue = this.inputValue[item]
            ? this.inputValue[item]
            : '0';
        const previousNormalizedValue = this.totalObj[item]
            ? this.totalObj[item]
            : '0';
        const previousUnitsLeft = this.unitsLeft;
        if (event < 0) {
            this.notificationsService.showSnackBar(
                'Negative values are not accepted',
                true
            );
            this.inputValue[item] = 0;
            return;
        }
        if (action === 'plus') {
            this.totalObj[item] = item.split('|')[1] * (event + 1);
            let total1 = 0;
            Object.values(this.totalObj).forEach((value) => {
                total1 = +total1 + +value;
            });
            this.unitsLeft =
                this.data['typeDistribution']['normalizationCount'] - total1;

            if (+total1 > this.data['typeDistribution']['normalizationCount']) {
                this.notificationsService.showSnackBar(
                    'Select an instance matching amount of units left',
                    true
                );
                this.totalObj[item] = previousNormalizedValue
                    ? previousNormalizedValue
                    : 0;
                this.inputValue[item] = previousInputValue
                    ? previousInputValue
                    : 0;
                this.inputError[item] = true;
                let totalUpdate = 0;
                Object.values(this.totalObj).forEach((value) => {
                    totalUpdate = +totalUpdate + +value;
                });
                this.unitsLeft =
                    this.data['typeDistribution']['normalizationCount'] -
                    totalUpdate;
            } else {
                this.inputValue[item] = event + 1;
                this.totalObj[item] =
                    item.split('|')[1] * this.inputValue[item];
                this.inputError[item] = false;
                let totalUpdate = 0;
                Object.values(this.totalObj).forEach((value) => {
                    totalUpdate = +totalUpdate + +value;
                });
                this.unitsLeft =
                    this.data['typeDistribution']['normalizationCount'] -
                    totalUpdate;
            }

            Object.values(this.totalObj).forEach((value) => {
                this.totalCountOfSelected = +this.totalCountOfSelected + +value;
            });
        } else {
            this.inputValue[item] = +event;
            this.totalObj[item] = item.split('|')[1] * event;
            let total = 0;
            Object.values(this.totalObj).forEach((value) => {
                total = +total + +value;
            });
            this.unitsLeft =
                this.data['typeDistribution']['normalizationCount'] - total;

            if (+total > this.data['typeDistribution']['normalizationCount']) {
                this.notificationsService.showSnackBar(
                    'Select an instance matching amount of units left',
                    true
                );
                this.totalObj[item] = 0;
                this.inputValue[item] = 0;
                inputElement.value = '';
                this.unitsLeft = this.unitsLeft + previousNormalizedValue;
                this.inputError[item] = true;
                let totalUpdate = 0;
                Object.values(this.totalObj).forEach((value) => {
                    totalUpdate = +totalUpdate + +value;
                });
                this.unitsLeft =
                    this.data['typeDistribution']['normalizationCount'] -
                    totalUpdate;
            } else {
                this.inputError[item] = false;
            }
            Object.values(this.totalObj).forEach((value) => {
                this.totalCountOfSelected = +this.totalCountOfSelected + +value;
            });
        }
    }

    checkNormalization(data) {
        if ('typeDistribution' in data) {
            if (data['typeDistribution']['instanceType'].length > 0) {
                return true;
            }
        }
        return false;
    }

    isObject(val) {
        return typeof val === 'object';
    }

    toggleCheckBoxes(isChecked, checkboxName) {
        isChecked
            ? this.activeCheckboxes.push(checkboxName)
            : this.activeCheckboxes.splice(
                  this.activeCheckboxes.indexOf(checkboxName),
                  1
              );
    }

    toggleOfferings(isChecked, checkboxName) {
        isChecked
            ? this.activeOfferingsTerms.push(checkboxName)
            : this.activeOfferingsTerms.splice(
                  this.activeOfferingsTerms.indexOf(checkboxName),
                  1
              );
    }

    // Calculate 1 and 3 year ON Demand Cost and Total Cost

    calculateCosts() {
        const allCosts = {};
        const applicableMonth = this.applicableFilters.includes('3yr')
            ? 36
            : 12;

        for (let i = 1; i <= applicableMonth; i++) {
            allCosts[i] = {};
            for (const cost of [
                'On Demand Cost (' + this.data.currency + ')',
                'Total RI Cost (' + this.data.currency + ')'
            ]) {
                allCosts[i][cost] = {};
                for (const month of Object.keys(
                    this.data['amortizedCost']['Monthly Cost'][cost]
                )) {
                    allCosts[i][cost][month] = {};
                    for (const term of Object.keys(
                        this.data['amortizedCost']['Monthly Cost'][cost][month]
                    )) {
                        if (!this.isV2) {
                            if (!(term.includes('1') && i > 12)) {
                                let finalCost =
                                    this.data['amortizedCost']['Monthly Cost'][
                                        cost
                                    ][month][term] * i;

                                finalCost =
                                    Math.round(finalCost) === finalCost
                                        ? finalCost
                                        : Number(finalCost.toFixed(2));

                                allCosts[i][cost][month][term] = finalCost;
                            }
                        }
                        if (this.isV2) {
                            allCosts[i][cost][month][term] = {};
                            for (const term2 of Object.keys(
                                this.data['amortizedCost']['Monthly Cost'][
                                    cost
                                ][month][term]
                            )) {
                                if (!(term2.includes('1') && i > 12)) {
                                    let finalCost =
                                        this.data['amortizedCost'][
                                            'Monthly Cost'
                                        ][cost][month][term][term2] * i;

                                    finalCost =
                                        Math.round(finalCost) === finalCost
                                            ? finalCost
                                            : Number(finalCost.toFixed(2));

                                    allCosts[i][cost][month][term][term2] =
                                        finalCost;
                                }
                            }
                        }
                    }
                }
            }
        }
        this.comparisonCosts = allCosts;
    }

    commutativeDisplayCondition(month) {
        if (Number(month) <= 12) {
            return true;
        }
    }
}
