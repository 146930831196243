import { Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';
import { ListHttpService } from './../../../../services/http/list-http/list-http.service';
import { NotificationsService } from './../../../../services/notifications/notifications.service';

@Component({
    selector: 'app-customer-info-modal',
    templateUrl: './customer-info-modal.component.html',
    styleUrls: ['./customer-info-modal.component.sass']
})
export class CustomerInfoModalComponent implements OnInit {
    widgetRef: Widget;

    modalData;
    selectedTagsData = [];
    availableTagsData = [];
    allRowsData = [];
    stepData = null;
    tagsAssigned = [];
    tagsToBeDeleted = [];
    customerDetails = {};
    userDetails = {};
    Object = Object;

    constructor(
        private modalInjectedData: ModalInjectedData,
        private notificationService: NotificationsService,
        private listHttpService: ListHttpService
    ) {
        this.widgetRef = modalInjectedData.data.widgetRef;

        this.modalData = modalInjectedData.data;
        this.stepData = modalInjectedData.data.stepData;
        this.selectedTagsData = modalInjectedData.data.stepData['tags'];
        this.allRowsData = modalInjectedData.data.allRowsData;
        this.allRowsData.forEach((tags) => {
            this.availableTagsData.push(tags);
        });
        this.availableTagsData = Helper.removeDuplicateObjetsFromArray(
            this.availableTagsData
        );
    }

    ngOnInit(): void {
        this.setUpBasics();
    }
    hitAddTagApi($event) {
        let tags = [...this.tagsAssigned];
        const finalTagsData = [];
        tags = Helper.removeDuplicateObjetsFromArray(tags);
        if (this.tagsToBeDeleted.length) {
            this.tagsToBeDeleted.forEach((tagsToBeDeleted) => {
                const tagsIndex = tags.findIndex(
                    (tag) => tag.name === this.tagsToBeDeleted
                );
                if (tagsIndex > -1) {
                    tags = Helper.removeIndexfromArray(tagsIndex, tags);
                }
            });
        }

        finalTagsData.push({
            tags: tags,
            tenantId: this.stepData['tenantId']
        });
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                'addTag'
            ]
        );
        apiArgs.input = finalTagsData;
        apiArgs.function = (response) => {
            this.selectedTagsData = tags;

            this.widgetRef.notificationsService.showSnackBar(
                'Tags updated successfully.'
            );
            this.widgetRef.refreshWidget();
            if ($event['callback']) {
                $event['callback']('SUCCESS');
            }
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
            if ($event['callback']) {
                $event['callback']('ERROR');
            }
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    updateTags($event) {
        this.tagsAssigned = $event['tagsData'];
        if ($event['tagstoBeDeleted']) {
            this.tagsToBeDeleted.push($event['tagsToBeDeleted']);
        }
        if ($event['deleteTags']) {
            this.hitAddTagApi($event);
        }
    }

    setUpBasics() {
        this.customerDetails = {
            'Domain Id': this.stepData['domainId'],
            'Company Name': this.stepData['companyName'],
            Region: this.stepData['regionToShow']
                ? this.stepData['regionToShow']
                : this.stepData['region'],
            'Price Type': this.stepData['priceType'],
            'Retention Period': this.stepData['dataRetentionMonths'],
            'Customer Tag': ''
        };
        this.userDetails = {
            'First Name': this.stepData['rootUserFirstName'],
            'Last Name': this.stepData['rootUserLastName'],
            'Email Id': this.stepData['rootUserEmail'],
            'Contact Number': this.stepData['contact']
        };
    }
}
