export enum CustomValidatorKey {
    // Existing
    REQUIRED = 'required',
    REQUIRED_TRUE = 'required',
    MAX_LENGTH = 'maxlength',
    MIN_LENGTH = 'minlength',
    MAX_VALUE = 'max',
    MIN_VALUE = 'min',
    EMAIL = 'pattern',
    URL_VALIDATOR = 'pattern',
    DOMAIN_VALIDATOR = 'pattern',
    PASSWORD_VALIDATOR = 'pattern',
    NO_SPECIAL_CHARACTERS = 'pattern',
    USERNAME_VALIDATOR = 'pattern',
    ALPHA_NUM_WITH_UNDERSCORE_VALIDATOR = 'pattern',
    CIDR_VALIDATOR = 'pattern',
    PHONE_NUMBER_VALIDATOR = 'pattern',
    REGEX_VALIDATOR = 'pattern',
    NUMBER_VALIDATOR = 'pattern',

    // Custom
    GCP_CAPITAL_LETTERS_VALIDATOR = 'gcpCapitalLetterValidator',
    GCP_KEY = 'pattern',
    GCP_NUMBER_VALIDATOR = 'gcpNumberValidator',
    GCP_SPECIAL_CHARACTER = 'gcpSpecialCharacterValidator',
    GCP_VALUE_VALIDATOR = 'gcpValueValidator',
    AZURE_KEY = 'caseInsensitiveValidator',
    NO_WHITE_SPACE = 'noWhiteSpace',
    EMAIL_LIST = 'emailList',
    MAT_CHIP_REQUIRED = 'matChipRequired',
    MAT_CHIP_ALLOW_EMAIL = 'matChipAllowEmail',
    EQUALITY_VALIDATOR = 'equalityValidator',
    EQUALITY_PASSWORD_VALIDATOR = 'equalityPasswordValidator',
    UNEQUALITY_VALIDATOR = 'unequalityValidator',
    JSON_VALIDATOR = 'jsonValidator',
    HIDDEN_DEPENDENT_VALIDATOR = 'hiddenDependentValidator',
    XML_VALIDATOR = 'xmlValidator',
    DEPENDENT_FIELD_VALIDATOR = 'dependentFieldValidator',
    CONTROL_VALUE_IN = 'controlValueIn',
    CONTROL_VALUE_NOT_IN = 'controlValueNotIn',
    OTHER_JSON_CONTROL_KEY_VALUE_VALIDATOR = 'otherJsonControlKeyValueValidator',
    MULTI_CIDR = 'multiCIDR',
    GREATER_THAN_CONTROLS = 'greaterThanControls',
    LESSER_THAN_CONTROLS = 'lesserThanControls',
    IP_RANGE = 'ipRange',
    REMOVE_SPACE_FROM_STRING = 'removeSpaceFromSring'
}
