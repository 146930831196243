<ng-container>
    <div class="tw-w-full tw-flex wrapper">
        <div class="tw-w-full overview">
            <div
                class="tw-flex tw-relative consumption-heading"
                *ngIf="accounts && accounts.length"
            >
                <app-multi-button-generator
                    *ngIf="severityButtons"
                    [buttonGenInputs]="severityButtons"
                ></app-multi-button-generator>
            </div>
        </div>

        <div
            class="tw-mr-6 tw-mt-2 table-search"
            *ngIf="
                accounts &&
                accounts.length &&
                widgetRef.visibleSections.value.has(ViewType.CARDS) &&
                !widgetRef.lightState
            "
        >
            <app-table-search
                [data]="accounts"
                (changed)="filteredCardsData = $event"
            ></app-table-search>
        </div>
    </div>
    <div
        class="tw-items-center controls"
        *ngIf="
            !(widgetRef.loadingData | async) &&
            widgetRef.visibleSections.value.has(ViewType.CARDS) &&
            accounts &&
            accounts.length
        "
    >
        <div
            class="widget-data-container"
            [id]="widgetRef.widgetTableId"
            *ngIf="
                !(widgetRef.loadingData | async) &&
                widgetRef.visibleSections.value.has(ViewType.CARDS) &&
                accounts &&
                accounts.length > 0
            "
        >
            <div
                class="tw-flex tw-flex-wrap tw-justify-around tw-h-96 tw-overflow-auto tw-w-full cards-container"
            >
                <ng-container
                    *ngIf="filteredCardsData && filteredCardsData.length"
                >
                    <div
                        *ngFor="let card of filteredCardsData; let i = index"
                        class="tw-text-fs-200 tw-my-5 tw-mx-2 tw-flex tw-flex-col tw-cursor-pointer tw-relative card"
                        (click)="showRiModal(card)"
                        [class.getFullAccessState]="
                            widgetRef.lightState && i >= widgetRef.liteViewLimit
                        "
                    >
                        <div
                            class="tw-flex-1 tw-w-full tw-text-fs-1000 tw-max-h-12 head"
                            [class.redGradient]="
                                colorGradient(
                                    card['Display Entry'][
                                        'Consumption (In Percentage)'
                                    ]
                                ) === 'red'
                            "
                            [class.greenGradient]="
                                colorGradient(
                                    card['Display Entry'][
                                        'Consumption (In Percentage)'
                                    ]
                                ) === 'green'
                            "
                            [class.yellowGradient]="
                                colorGradient(
                                    card['Display Entry'][
                                        'Consumption (In Percentage)'
                                    ]
                                ) === 'yellow'
                            "
                            [class.silverGradient]="
                                colorGradient(
                                    card['Display Entry'][
                                        'Consumption (In Percentage)'
                                    ]
                                ) === 'silver'
                            "
                        >
                            <div
                                class="tw-flex tw-my-0 tw-mx-auto tw-text-fs-800 tw-justify-center title"
                            >
                                <div
                                    class="tw-flex tw-h-20 tw-w-20 tw-relative tw-top-2 tw-rounded-full tw-justify-center tw-items-center hover:tw-shadow-lg card-circle"
                                >
                                    <span
                                        class="tw-absolute tw-text-fs-200 tw-font-bold tw-flex tw-max-w-full tw-py-0 tw-px-1"
                                        [class.unknown]="
                                            card['Display Entry'][
                                                'Consumption (In Percentage)'
                                            ] === 'Unknown' ||
                                            card['Display Entry'][
                                                'Consumption (In Percentage)'
                                            ] === 'Insufficient Permission'
                                        "
                                    >
                                        <span
                                            class="tw-flex-f4 tw-overflow-ellipsis"
                                            *ngIf="
                                                card['Display Entry'][
                                                    'Consumption (In Percentage)'
                                                ] === 'Unknown' ||
                                                card['Display Entry'][
                                                    'Consumption (In Percentage)'
                                                ] === 'Insufficient Permission'
                                            "
                                            [matTooltip]="
                                                card['Display Entry'][
                                                    'Consumption (In Percentage)'
                                                ]
                                            "
                                        >
                                            ?
                                        </span>
                                        <span
                                            class="tw-flex-1"
                                            *ngIf="
                                                card['Display Entry'][
                                                    'Consumption (In Percentage)'
                                                ] !== 'Unknown' &&
                                                card['Display Entry'][
                                                    'Consumption (In Percentage)'
                                                ] !== 'Insufficient Permission'
                                            "
                                            >{{
                                                card["Display Entry"][
                                                    "Consumption (In Percentage)"
                                                ]
                                            }}%</span
                                        >
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            class="tw-flex-f8 tw-py-10 tw-px-3 tw-text-fs-200 tw-relative"
                        >
                            <div
                                class="display-entry-keys"
                                *ngFor="
                                    let item of card['Display Entry'] | keyvalue
                                "
                            >
                                <span>
                                    <div>
                                        <strong> {{ item.key }}</strong>
                                    </div>
                                    <span> {{ item.value }} </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container
                    *ngIf="
                        filteredCardsData &&
                        !filteredCardsData.length &&
                        accounts &&
                        accounts.length &&
                        !(widgetRef.loadingData | async)
                    "
                >
                    <div
                        class="tw-h-10 tw-text-center tw-fs-800 tw-w-full tw-flex tw-justify-center tw-items-center tw-m-auto card-container"
                    >
                        {{ Messages.NO_DATA_AVAILABLE }}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div
        *ngIf="
            widgetRef.visibleSections.value.has(ViewType.TABLE) &&
            tableInput &&
            accounts &&
            accounts.length &&
            filteredCardsData &&
            filteredCardsData.length &&
            !(widgetRef.loadingData | async)
        "
        class="tw--mt-9 tw-overflow-hidden"
    >
        <div>
            <app-only-table-generator
                [tableInput]="tableInput"
                [tableData]="tableData"
                (gridRef)="gridRef = $event"
                [widgetRef]="widgetRef"
                [showSearchBox]="showSearchBox"
            >
            </app-only-table-generator>
        </div>
    </div>
    <div
        *ngIf="
            filteredCardsData &&
            !filteredCardsData.length &&
            accounts &&
            accounts.length &&
            widgetRef.visibleSections.value.has(ViewType.TABLE) &&
            !(widgetRef.loadingData | async)
        "
        class="tw-text-center tw-text-fs-800 tw-w-full tw-flex tw-justify-center tw-items-center tw-m-auto table-container"
    >
        {{ Messages.NO_DATA_AVAILABLE }}
    </div>
</ng-container>
