<div class="container">
    <ng-container *ngIf="selectedIndex !== undefined">
        <app-vertical-scrolling-tab-group
            [activeIndex]="selectedIndex"
            [modalId]="modalInputData.modalId"
        >
            <ng-container *ngFor="let data of previewData">
                <ng-container
                    *ngIf="
                        data.type !== documentTypes.DESCRIPTION &&
                        data.type !== documentTypes.VIDEOS &&
                        data.type !== documentTypes.IMAGES
                    "
                >
                    <ng-template
                        appVerticalScrollingTab
                        [tabLabel]="data.type"
                        showTabLabelTooltip="true"
                    >
                        <mat-accordion displayMode="flat" multi>
                            <mat-expansion-panel
                                hideToggle="true"
                                [expanded]="true"
                            >
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{ data.type }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div class="content-container">
                                    <div [innerHTML]="data.content"></div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </ng-template>
                </ng-container>

                <ng-container
                    *ngIf="
                        data.type === documentTypes.VIDEOS ||
                        data.type === documentTypes.IMAGES
                    "
                >
                    <ng-template
                        appVerticalScrollingTab
                        [tabLabel]="data.type"
                        showTabLabelTooltip="true"
                    >
                        <mat-accordion displayMode="flat" multi>
                            <mat-expansion-panel
                                hideToggle="true"
                                [expanded]="true"
                            >
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{ data.type }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div class="media-container">
                                    <ng-container
                                        *ngIf="
                                            data.type === documentTypes.IMAGES
                                        "
                                    >
                                        <div
                                            class="slider"
                                            *ngIf="data.links.length"
                                        >
                                            <div
                                                class="arrow clickable"
                                                (click)="prevImage()"
                                                [class.disabled]="
                                                    displayableImages.length < 3
                                                "
                                            >
                                                <div
                                                    appIcon
                                                    [data]="leftArrowIcon"
                                                ></div>
                                            </div>
                                            <div
                                                class="each-slide"
                                                [class.active]="
                                                    displayableImages.length -
                                                        2 ===
                                                        i ||
                                                    displayableImages.length < 3
                                                "
                                                *ngFor="
                                                    let images of displayableImages;
                                                    index as i
                                                "
                                            >
                                                <img
                                                    [src]="images.link"
                                                    [class.padding-fix]="
                                                        displayableImages.length <
                                                        3
                                                    "
                                                    [style.width]="
                                                        displayableImages.length ===
                                                        1
                                                            ? 'auto'
                                                            : '100%'
                                                    "
                                                />

                                                <p
                                                    class="image-name"
                                                    *ngIf="
                                                        displayableImages.length -
                                                            2 ===
                                                            i ||
                                                        displayableImages.length <
                                                            3
                                                    "
                                                >
                                                    {{
                                                        images.name | titlecase
                                                    }}
                                                </p>
                                            </div>
                                            <div
                                                class="arrow clickable"
                                                (click)="nextImage()"
                                                [class.disabled]="
                                                    displayableImages.length < 3
                                                "
                                            >
                                                <div
                                                    appIcon
                                                    [data]="rightArrowIcon"
                                                ></div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="
                                            data.type === documentTypes.VIDEOS
                                        "
                                    >
                                        <div class="video">
                                            <video
                                                #videoJsTemplateRef
                                                class="video-js"
                                                controls
                                                muted
                                                playsinline
                                                preload="none"
                                                width="640"
                                                height="264"
                                            ></video>
                                        </div>
                                    </ng-container>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </ng-template>
                </ng-container>
            </ng-container>
        </app-vertical-scrolling-tab-group>
    </ng-container>
</div>
