<app-vertical-scrolling-tab-group [modalId]="modalData.modalId">
    <ng-template appVerticalScrollingTab [tabLabel]="'Recommendation'">
        <mat-accordion displayMode="flat" multi>
            <mat-expansion-panel
                class="tw-py-2"
                #recommendation
                hideToggle="true"
                [expanded]="true"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ "Recommendation" }}
                    </mat-panel-title>
                    <mat-panel-description>
                        <span
                            *ngIf="!recommendation.expanded"
                            appIcon
                            [data]="arrowDownIcon"
                        ></span>
                        <span
                            *ngIf="recommendation.expanded"
                            appIcon
                            [data]="arrowUpIcon"
                        ></span>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="tabs">
                    <div class="tab-header">
                        <div
                            class="tab-header-item"
                            *ngFor="let tab of tabs"
                            [class.active]="selectedTab === tab"
                            (click)="changeTab(tab)"
                        >
                            {{ tab }}
                        </div>
                    </div>
                    <div class="table-container">
                        <div
                            class="tw-p-8 tw-font-bold tw-text-center tw-text-fs-600"
                        >
                            Configuration
                        </div>
                        <app-only-table-generator
                            *ngIf="configurationTableData"
                            [tableData]="configurationTableData"
                            [tableInput]="configurationTableGen"
                            (gridRef)="configurationGrid = $event"
                            [hideControlBar]="true"
                        ></app-only-table-generator>
                    </div>
                    <div class="table-container">
                        <div
                            class="tw-p-6 tw-font-bold tw-text-center tw-text-fs-600"
                        >
                            Amortized
                        </div>
                        <app-only-table-generator
                            *ngIf="amortizedTableData"
                            [tableData]="amortizedTableData"
                            [tableInput]="amortizedTableGen"
                            (gridRef)="amortizedGrid = $event"
                            [hideControlBar]="true"
                        ></app-only-table-generator>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-template>

    <ng-template appVerticalScrollingTab [tabLabel]="'Description'">
        <mat-accordion displayMode="flat" multi>
            <mat-expansion-panel
                class="tw-py-2"
                #description
                hideToggle="true"
                [expanded]="true"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ "Description" }}
                    </mat-panel-title>
                    <mat-panel-description>
                        <span
                            *ngIf="!description.expanded"
                            appIcon
                            [data]="arrowDownIcon"
                        ></span>
                        <span
                            *ngIf="description.expanded"
                            appIcon
                            [data]="arrowUpIcon"
                        ></span>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="table-container">
                    <app-only-table-generator
                        *ngIf="descriptionTableData"
                        [tableData]="descriptionTableData"
                        [tableInput]="descriptionTableGen"
                        [hideControlBar]="true"
                    ></app-only-table-generator>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-template>
</app-vertical-scrolling-tab-group>
