import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Widget } from 'src/app/shared/classes/Widget';
import { CustomValidators } from './../../../../classes/CustomValidators';
import { Helper } from './../../../../classes/Helper';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';
import { FilterType } from './../../../../enums/FilterType';
import { FormState } from './../../../../enums/FormState';
import { IconType } from './../../../../enums/IconType';
import { ICustomCostMonitoringWidgetCreationInput } from './../../../../interfaces/api/input-data/ICustomCostMonitoringWidgetCreationInput';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { IIcon } from './../../../../interfaces/icon-data/IIcon';
import { CustomCostMonitoringWidgetService } from './../../../../services/custom-cost-monitoring-widget/custom-cost-monitoring-widget.service';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';
import { MultiStepFormService } from './../../../../services/modal/multi-step-form/multi-step-form.service';

@Component({
    selector: 'app-widget-details',
    templateUrl: './widget-details.component.html',
    styleUrls: ['./widget-details.component.sass']
})
export class WidgetDetailsComponent implements OnInit {
    templateData: any;
    titleFormInput: IFormGeneratorInput;
    descriptionFormInput: IFormGeneratorInput;
    titleFormGroupRef: FormGroup;
    descriptionFormGroupRef: FormGroup;
    widgetRef: Widget;
    pencilIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-pencil-alt',
        extraClass: 'pencil-icon'
    };
    buttonGenInputs: IButtonGeneratorInput[] = [
        {
            buttonName: 'Save as Draft',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            showLoader: true,
            function: (buttonRef) => {
                this.saveAsDraft(buttonRef);
            }
        },
        {
            buttonName: 'Create Query',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.openCreateQueryForm();
            }
        }
    ];
    inputData: ICustomCostMonitoringWidgetCreationInput = {
        widgetName: '',
        widgetDescription: '',
        query: null,
        queryString: '',
        columnsToRename: null,
        reporting: null,
        filters: null,
        graph: null,
        draft: false,
        hideGraph: false
    };
    isEditingName: boolean;
    isViewMode: boolean;
    isEdit: boolean;
    widgetId: string;
    existingWidgetNames: string[] = [];
    loader = false;

    constructor(
        private modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        private modalService: ModalService,
        private customCostMonitoringWidgetService: CustomCostMonitoringWidgetService,
        private changeDetectionRef: ChangeDetectorRef
    ) {
        if (multiStepFormService.stepData.get(modalInputData.modalId).has(1)) {
            this.inputData = multiStepFormService.stepData
                .get(modalInputData.modalId)
                .get(1);
        } else {
            this.inputData = modalInputData.data['widgetData']
                ? modalInputData.data['widgetData']
                : this.inputData;
        }
        this.widgetRef = modalInputData.data['widgetRef'];
        this.isEdit = modalInputData.data['isEdit'];
        this.isViewMode = modalInputData.data['isViewMode'];
        this.existingWidgetNames = modalInputData.data['widgetNames'];
        this.widgetId = modalInputData.data['widgetId'];

        this.buttonGenInputs[0].disable = this.isViewMode;
    }

    ngOnInit(): void {
        this.initForms();
    }

    initTitleForm() {
        this.titleFormInput = null;
        const titleFormInput = {
            formName: 'titleForm',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Widget Name',
                    placeholder: 'Widget Name',
                    name: 'widgetName',
                    fieldType: FilterType.TEXT,
                    required: true,
                    value: this.inputData.widgetName,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Widget Name is required'
                        },
                        {
                            validator: CustomValidators.keyAlreadyExists(
                                this.existingWidgetNames
                            ),
                            errorMessage: 'Enter unique widget name'
                        }
                    ]
                }
            ]
        };

        this.widgetRef.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.titleFormInput = titleFormInput;
                this.changeDetectionRef.detectChanges();
            }, 0);
        });
    }

    initForms() {
        this.initTitleForm();
        this.descriptionFormInput = {
            formName: 'descriptionForm',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Widget Description',
                    placeholder: 'Widget description goes here...',
                    name: 'widgetDescription',
                    fieldType: FilterType.WYSIWYG_EDITOR,
                    required: false,
                    value: this.inputData.widgetDescription
                }
            ]
        };
    }

    saveAsDraft(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader || this.isViewMode) {
            return;
        }

        if (!this.checkDataValidity(true)) {
            return;
        }

        this.customCostMonitoringWidgetService.saveAsDraft(
            buttonRef,
            this.widgetRef,
            this.prepareData(this.isEdit ? this.inputData.draft : true),
            () => {
                buttonRef.loader = false;
                this.modalService.closeModal(null, this.modalInputData.modalId);
            },
            () => {
                buttonRef.loader = false;
            },
            this.widgetId
        );
    }

    checkDataValidity(draft = false) {
        let isValid = true;

        if (this.titleFormGroupRef && this.titleFormGroupRef.invalid) {
            this.titleFormGroupRef.markAllAsTouched();
            isValid = false;
        }

        if (!this.titleFormGroupRef) {
            if (this.inputData && this.inputData.widgetName) {
                isValid = true;
            } else {
                this.widgetRef.notificationsService.showSnackBar(
                    'Enter Widget Name to proceed'
                );
                isValid = false;
            }
        }

        return isValid;
    }

    prepareData(isDraft = false, nextStep = false) {
        const inputData = Helper.cloneDeep(this.inputData);
        inputData.widgetName =
            this.titleFormGroupRef && this.titleFormGroupRef.value['widgetName']
                ? this.titleFormGroupRef.value['widgetName']
                : this.inputData.widgetName;
        inputData.widgetDescription = this.descriptionFormGroupRef.value[
            'widgetDescription'
        ]
            ? this.descriptionFormGroupRef.value['widgetDescription']
            : null;
        inputData.draft = nextStep ? this.inputData.draft : isDraft;
        return inputData;
    }

    openCreateQueryForm() {
        if (!this.checkDataValidity()) {
            return;
        }

        this.inputData = Helper.cloneDeep(this.prepareData(false, true));
        this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .set(1, this.inputData);
        this.multiStepFormService.nextStep(this.modalInputData.modalId);
    }
}
