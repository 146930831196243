<div class="tw-flex tw-justify-center">
    <div class="tw-relative tw-top-7">Select Email Template &nbsp;&nbsp;</div>
    <mat-form-field class="tw-w-1/3" appearance="fill">
        <mat-select
            (selectionChange)="setValues($event.value)"
            [(value)]="defaultDropdownMailTemplate"
        >
            <ng-container *ngFor="let item of mapMailTemplate | keyvalue">
                <mat-option [value]="item.key">
                    <ng-container>
                        {{ dropdownList[item.key] }}
                    </ng-container>
                </mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
</div>

<app-form-generator
    *ngIf="formGenInput && formGenInput.fields"
    (formGroupRef)="formGroupRef = $event; setEmail($event)"
    [formGeneratorInput]="formGenInput"
></app-form-generator>
