import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { IGraphData } from 'src/app/shared/interfaces/graph/IGraphData';
import { IPieData } from '../../interfaces/graph/IGraphData';
import { Helper } from './../../classes/Helper';
import { GlobalDataService } from './../../services/global-data/global-data.service';

@Component({
    selector: 'app-pie-graph',
    templateUrl: './pie-graph.component.html',
    styleUrls: ['./pie-graph.component.sass']
})
export class PieGraphComponent implements OnInit, OnDestroy, OnChanges {
    am4charts: any;
    am4core: any;
    chart: any;
    pieSeries: any;
    chartData: IPieData[];
    graphId = Helper.generateUniqueKey(8);
    @Input() graphData: IGraphData;

    constructor(public globalDataService: GlobalDataService) {}

    ngOnInit(): void {
        this.chartData = this.graphData.chartData as IPieData[];
        Helper.loadAm4chartsLibraries().then((resolve) => {
            this.am4charts = resolve['am4charts'];
            this.am4core = resolve['am4core'];
            this.drawChart();
        });

        this.globalDataService.windowResizeEvent.subscribe(() => {
            this.resizeChart();
        });
    }
    ngOnChanges(simpleChanges: SimpleChanges): void {
        if (
            simpleChanges.graphData &&
            simpleChanges.graphData.currentValue &&
            this.am4charts
        ) {
            this.graphData = simpleChanges.graphData.currentValue;
            this.chartData = this.graphData.chartData as IPieData[];
            this.drawChart();
        }
    }

    resizeChart() {
        if (this.chart && this.chart.svgContainer) {
            this.chart.svgContainer.measure();
        }
    }

    drawChart() {
        this.disposeChart();
        if (!document.getElementById(this.graphId)) {
            setTimeout(() => {
                this.drawChart();
            }, 10);
            return;
        }

        this.chart = this.am4core.create(
            this.graphId,
            this.am4charts.PieChart3D
        );
        this.chart.radius = this.am4core.percent(100);
        this.chart.depth = 20;
        this.chart.pulOutRadius = 0;
        this.chart.angle = 43;
        this.chart.innerRadius = 27;
        this.chart.outlineAlpha = 1;
        this.chart.data = this.chartData;

        this.chart.svgContainer.autoResize = false;

        // Add and configure Series
        this.pieSeries = this.chart.series.push(
            new this.am4charts.PieSeries3D()
        );
        this.pieSeries.dataFields.value = Label.VALUE;
        this.pieSeries.dataFields.category = Label.CATEGORY;
        this.pieSeries.tooltip.getFillFromObject = false;
        this.pieSeries.tooltip.background.fill = this.am4core.color(
            Helper.convertToHexIfHsl(Helper.getCssVarValue('commonBgColor'))
        );
        this.pieSeries.tooltip.autoTextColor = false;
        this.pieSeries.tooltip.label.fill = this.am4core.color(
            Helper.convertToHexIfHsl(
                Helper.getCssVarValue('graphTooltipLabelColor')
            )
        );
        this.pieSeries.tooltip.label.fontSize = 11;
        this.pieSeries.slices.template.stroke = this.am4core.color(
            Helper.convertToHexIfHsl(Helper.getCssVarValue('commonBgColor'))
        );
        this.pieSeries.slices.template.strokeWidth = 0.5;
        this.pieSeries.slices.template.strokeOpacity = 1;
        this.pieSeries.labels.template.disabled = true;
        this.pieSeries.ticks.template.disabled = true;
        this.pieSeries.showOnInit = false;

        this.pieSeries.slices.template.events.on(
            'over',
            (event) => {
                this.graphData.onHoverTemplate
                    ? this.graphData.onHoverTemplate(event)
                    : null;
            },
            this
        );

        this.pieSeries.slices.template.events.on(
            'hit',
            (event) => {
                this.graphData.onClickTemplate
                    ? this.graphData.onClickTemplate(event)
                    : null;
            },
            this
        );
    }

    disposeChart() {
        this.chart ? this.chart.dispose() : null;
    }

    ngOnDestroy(): void {
        this.disposeChart();
    }
}

enum Label {
    CATEGORY = 'category',
    VALUE = 'value'
}
