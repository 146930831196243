<div #dynamicLabel
    [ngClass]="widgetRef.widgetIndex | aggregateCardColor"
    class="tw-flex tw-rounded-md tw-max-h-44 tw-relative cards-container"
    appResizeObserver
    (resize)="handleResize($event)"
>
    <div class="tw-absolute">
        <div appIcon [data]="headingCurveIcon"></div>

        <div *ngIf="widgetRef.widgetData.widgetInfo.cloudIconPath">
            <img
                class="tw-w-6 tw-top-0 tw-absolute tw-m-2 tw-h-6"
                [src]="widgetRef.widgetData.widgetInfo.cloudIconPath"
            />
        </div>
    </div>

    <div
        class="tw-flex-1 tw-absolute tw-w-8 tw-h-8 tw-rounded-full tw-text-center tw-flex tw-justify-center tw-content-center tw-left-2 tw-bottom-2 cart"
        *ngIf="!widgetRef.errorMessage.value"
    >
        <div
            appIcon
            [data]="
                (widgetRef.widgetInCart | async) ? cartIconFilled : cartIcon
            "
            class="tw-m-auto tw-cursor-pointer tw-h-4 icon"
            [matTooltip]="
                (widgetRef.widgetInCart | async)
                    ? 'Remove from cart'
                    : 'Add to cart'
            "
            (click)="$event.stopPropagation(); widgetRef.addWidgetToCart()"
        ></div>
    </div>
    <div
        class="tw-w-full tw-h-40 tw-flex tw-flex-col tw-items-center aggregate"
    >
        <div
        [matTooltip]="widgetRef.widgetData.widgetInfo.text"
            class="tw-flex-1 tw-font-normal tw-font-family tw-text-fs-400 tw-italic tw-pt-4 tw-text-center tw-w-10/12 tw-pl-4 expense-title"
            [ngClass]="applyCustomClass ? 'dynamic-title': ''"
        >
            {{ widgetRef.widgetData.widgetInfo.text }}
        </div>
        <div
            class="tw-py-2.5 tw-px-0 tw-text-fs-100 tw-my-2 tw-mx-0 tw-h-16 tw-w-full tw-flex tw-justify-center tw-items-center tw--mt-1.5 tw-break-all error-message"
            *ngIf="
                (widgetRef.errorMessage | async) &&
                (widgetRef.errorMessage | async).length
            "
        >
            <i class="tw-mr-1 fas fa-exclamation-circle"></i>
            {{ widgetRef.errorMessage.value }}
        </div>
        <div class="cost" *ngIf="!widgetRef.errorMessage.value">
            <span
                [ngClass]="
                    (cardData.currency + cardData.value).length > 17
                        ? 'tw-fs-400'
                        : 'tw-fs-800'
                "
                >{{ cardData.currency }}{{ cardData.value }}
            </span>
        </div>
        <div
            class="tw-mb-2 tw-text-center tw-relative extra-info"
            *ngIf="extraInfo"
        >
            <div
                class="tw-font-normal tw-text-fs-200 tw-overflow-ellipsis tw-overflow-hidden tw-cursor-pointer extraInfo"
                [matTooltip]="extraInfo"
                [cdkCopyToClipboard]="extraInfo" 
            >
                {{ extraInfo }}
            </div>
        </div>

        <div class="date-container">
            <span
                class="tw-font-family tw-text-fs-150 date"
                *ngIf="
                    cardData.startDate &&
                    cardData.endDate &&
                    !widgetRef.errorMessage.value
                "
                >{{ cardData.startDate + " - " }}{{ cardData.endDate }}</span
            >
        </div>

        <!-- <div class="bg-icon">
        <i class="fas fa-dollar"></i>
      </div> -->
        <!-- <div class="score-container"><span class="score" *ngIf="masterLogin">{{score}}</span></div> -->
    </div>

    <div
        class="tw-text-fs-400 tw-flex-1 tw-absolute tw-right-2 tw-bottom-2 tw-w-8 tw-h-8 tw-flex tw-justify-center tw-items-center tw-rounded-full date-refresh"
    >
        <div
            class="tw-cursor-pointer tw-h-4 icon"
            appIcon
            [data]="refreshIcon"
            [hideText]="true"
            (click)="$event.stopPropagation(); widgetRef.refreshWidget()"
            [class.disabled]="
                !widgetRef.widgetConfigState.actions.refresh.state
            "
            [class.loader]="widgetRef.loadingData | async"
        ></div>
    </div>
</div>
