import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
    ChangeDetectorRef,
    Component,
    Input,
    NgZone,
    OnInit
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Helper } from 'src/app/shared/classes/Helper';
import { CartComponent } from 'src/app/shared/components/modal-templates/cart/cart.component';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IWidgetModifier } from 'src/app/shared/interfaces/widget/IWidgetModifier';
import { ApiResponseCacheService } from 'src/app/shared/services/cache/api-response-cache/api-response-cache.service';
import { CartCacheService } from 'src/app/shared/services/cache/cart-cache/cart-cache.service';
import { CredentialsCacheService } from 'src/app/shared/services/cache/credentials-cache/credentials-cache.service';
import { SideMenuCacheService } from 'src/app/shared/services/cache/side-menu-cache/side-menu-cache.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { ViewHttpService } from 'src/app/shared/services/http/view-http/view-http.service';
import { WidgetHttpService } from 'src/app/shared/services/http/widget-http/widget-http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { SessionService } from 'src/app/shared/services/session/session.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { WhiteLabelingService } from 'src/app/shared/services/white-labeling/white-labeling.service';
import { SideMenuType } from './../../../shared/enums/SideMenuType';
import { AssessmentCacheService, AssessmentKey } from './../../../shared/services/cache/assessment-cache/assessment-cache.service';
import { ConfigCacheService } from './../../../shared/services/cache/config-cache/config-cache.service';
import { GlobalConfiguration } from './../../classes/GlobalConfiguration';
import { AuditEvidenceSaveConfirmationService } from 'src/app/shared/services/assessment/audit-evidence-save-confirmation.service';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
    @Input() sidenav: MatSidenav;
    @Input() filterMenu: MatSidenav;

    collapseContent = false;
    isViewOpen = false;
    logo: IIcon = {
        class: this.whiteLabelService.whiteLableData.darkLogo,
        type: IconType.IMAGE,
        alt: this.whiteLabelService.whiteLableData.companyName
    };
    views = null;
    Helper = Helper;
    centilyticsLogo: IIcon;
    poweredCentilyticsLogo: IIcon;
    selectedRole: string = null;
    SideMenuType = SideMenuType;
    sideMenuType: SideMenuType = SideMenuType.DETAILED;
    cartCount = 0;
    menuStateList: boolean = false;
    menuStateUser: boolean = false;
    widgetCatalogToggle: boolean = false;
    widgetCatalog = null;
    filteredViews = null;

    toggleIcon: IIcon = {
        type: IconType.SVG,
        class: 'menu_icon'
    };

    crossIcon: IIcon = {
        type: IconType.SVG,
        class: 'collapse_menu'
    };

    expandIcon: IIcon = {
        type: IconType.SVG,
        class: 'collapse'
    };

    filterIcon: IIcon = {
        type: IconType.SVG,
        class: 'funnel'
    };

    filterFilledIcon: IIcon = {
        type: IconType.SVG,
        class: 'funnel_filled',
        extraClass: 'svg-accent-fill'
    };

    viewIcon: IIcon = {
        type: IconType.SVG,
        class: 'views'
    };

    viewFilledIcon: IIcon = {
        type: IconType.SVG,
        class: 'views',
        extraClass: 'svg-accent-fill'
    };
    knowledgeBaseIcon: IIcon = {
        type: IconType.SVG,
        class: 'knowledge_base_url'
    };

    userIcon: IIcon = {
        type: IconType.SVG,
        class: 'user'
    };
    userFilledIcon: IIcon = {
        type: IconType.SVG,
        class: 'user_filled',
        extraClass: 'svg-accent-fill'
    };

    cartIcon: IIcon = {
        type: IconType.SVG,
        class: 'cart_icon'
    };

    roleIcon: IIcon = {
        type: IconType.IMAGE,
        class: 'assets/new-icons/person.svg',
        extraClass: 'role-img'
    };

    widgetCatalogFilledIcon: IIcon = {
        type: IconType.SVG,
        class: 'widgetCatalog',
        extraClass: 'svg-default-v2-fill'
    };

    widgetCatalogIcon: IIcon = {
        type: IconType.SVG,
        class: 'widgetCatalog_filled',
        extraClass: 'svg-accent-fill'
    };
    GlobalConfiguration = GlobalConfiguration;

    constructor(
        public ngZone: NgZone,
        public sessionService: SessionService,
        public whiteLabelService: WhiteLabelingService,
        public globalDataService: GlobalDataService,
        private listHttpService: ListHttpService,
        private viewHttpService: ViewHttpService,
        private credentialsCacheService: CredentialsCacheService,
        private modalService: ModalService,
        private configCacheService: ConfigCacheService,
        private httpService: HttpService,
        private notificationsService: NotificationsService,
        private widgetHttpService: WidgetHttpService,
        private changeDetectorRef: ChangeDetectorRef,
        public filterService: FiltersService,
        public userDataCacheService: UserDataCacheService,
        public assessmentCacheService: AssessmentCacheService,
        private sideMenuCacheService: SideMenuCacheService,
        private router: Router,
        private apiResponseCacheService: ApiResponseCacheService,
        private auditEvidenceSaveConfirmationService: AuditEvidenceSaveConfirmationService,
        private cartCacheService: CartCacheService
    ) {}

    ngOnInit(): void {
        this.globalDataService.isFrameworkPage.subscribe((data) => {
            if (!data && this.filterMenu && this.filterMenu.opened) {
                this.filterMenu.toggle();
                this.changeDetectorRef.detectChanges();
            }
        });
        // if (
        //     this.globalDataService.roles &&
        //     this.globalDataService.roles.length
        // ) {
        //     this.selectedRole = this.globalDataService.roles[0]?.roleName;
        // }
        this.setSideMenuType();
        this.collapseIconState();
        this.globalDataService.pageSwitch.subscribe((data) => {
            if (data.switched) {
                this.collapseIconState();
            }
        });
        this.centilyticsLogo = {
            class: this.whiteLabelService.whiteLableData.darkLogo,
            type: IconType.IMAGE,
            alt: this.whiteLabelService.whiteLableData.companyName
        };
        this.poweredCentilyticsLogo = {
            type: IconType.IMAGE,
            class: 'assets/PoweredLogo.svg',
            extraClass: 'secondLogo'
        };

        if (!this.userDataCacheService.isAssesseeView) {
            this.updateViews();
            this.updateWidgets();
        }


        this.globalDataService.updateViews.subscribe((update) => {
            if (update) {
                this.updateViews();
            }
        });

        this.globalDataService.updateCartCount.subscribe((response) => {
            if (this.cartCacheService.cartWidgets) {
                const cartWidgets = JSON.parse(this.cartCacheService.cartWidgets);
                this.cartCount = Object.keys(cartWidgets).length;
            } else {
                this.cartCount = 0;
            }
            this.changeDetectorRef.detectChanges();
        });

        this.globalDataService.toggleCart.subscribe((response) => {
            if (response) {
                this.cartIcon = {
                    type: IconType.SVG,
                    class: 'cart_icon_filled',
                    extraClass: 'svg-accent-fill'
                };
            } else {
                this.cartIcon = {
                    type: IconType.SVG,
                    class: 'cart_icon'
                };
            }
            this.changeDetectorRef.detectChanges();
        });

        this.globalDataService.toggleCatalog.subscribe((response) => {
            if (typeof response === 'boolean' && response) {
                this.widgetCatalogToggle = true;
            } else {
                this.widgetCatalogToggle = false;
            }
        });
        this.sidenav.openedChange.subscribe(() => {
            this.globalDataService.sideNavToggle.next(true);
        });
    }

    openShoppingCart() {
        if (this.cartCount) {
            const modalData: IModalData = {
                modalName: 'Shopping Cart',
                modalIcon: this.cartIcon,
                sourceId: Symbol(),
                modalType: ModalType.MIDDLE,
                noHeader: true,
                modalHeightVh: 90,
                modalWidthVw: 35,
                modalPosition: {
                    top: '4%',
                    right: '2%'
                },
                modalSteps: [
                    {
                        stepData: {
                            componentToLoad: CartComponent
                        },
                        stepName: 'Shopping Cart'
                    }
                ]
            };
            this.modalService.openModal(modalData);
        } else {
            this.notificationsService.showSnackBar(
                'Add atleast 1 widget to cart',
                true
            );
        }
    }

    setSideMenuType() {
        const generalConfig = this.configCacheService.getGeneralConfig(
            this.userDataCacheService.emailId,
            this.configCacheService.viewId
        );
        if (generalConfig) {
            if (generalConfig.sideMenuType) {
                this.sideMenuType = generalConfig.sideMenuType;
            }
        }
    }
    collapseIconState() {
        const generalConfig = this.configCacheService.getGeneralConfig(
            this.userDataCacheService.emailId,
            this.configCacheService.viewId
        );
        if (generalConfig) {
            this.collapseContent = generalConfig?.collapseInsightByDefault
                ? generalConfig.collapseInsightByDefault
                : false;
            if (this.collapseContent) {
                this.expandIcon = {
                    type: IconType.SVG,
                    class: 'expand'
                };
            } else {
                this.expandIcon = {
                    type: IconType.SVG,
                    class: 'collapse'
                };
            }
        }
    }

    contentVisibilityToggle() {
        this.collapseContent = !this.collapseContent;
        if (this.collapseContent) {
            this.expandIcon = {
                type: IconType.SVG,
                class: 'expand'
            };
        } else {
            this.expandIcon = {
                type: IconType.SVG,
                class: 'collapse'
            };
            this.ngZone.runOutsideAngular(() => {
                setTimeout(() => {
                    this.globalDataService.windowResizeEvent.next();
                }, 0);
            });
        }
        const data: Map<string, IWidgetModifier> = new Map();
        const widgetsModifier: IWidgetModifier = {
            contentViewState: !this.collapseContent
        };
        data.set('*', widgetsModifier);
        this.globalDataService.widgetsModifier.next(data);
    }

    updateViews() {
        this.listHttpService.listViewsHeader(
            (response) => {
                if (this.globalDataService.selectedView) {
                    moveItemInArray(
                        response,
                        response.findIndex(
                            (viewDetails) =>
                                viewDetails.id ===
                                this.globalDataService.selectedView
                        ),
                        0
                    );
                }
                this.views = response;
                this.filteredViews = response;
            },
            (error) => {
                this.views = null;
            }
        );
    }

    updateWidgets() {
        this.listHttpService.listWidgetsHeader(
            (response) => {
                this.widgetCatalog = response;
            },
            (error) => {
                this.widgetCatalog = null;
            }
        );
    }

    changeView(view) {
        if (this.globalDataService.selectedView === view.id) {
            return;
        }
        this.globalDataService.changeView(
            this.userDataCacheService.emailId,
            view.id,
            this.credentialsCacheService,
            this.viewHttpService,
            this.sessionService,
            this.cartCacheService
        );
    }

    public shouldPauseNavigation(navArgsToCache: any) {
        if (
            this.auditEvidenceSaveConfirmationService.getIsUnsavedDataPresent()
        ) {
            this.auditEvidenceSaveConfirmationService.setIsNavigationTriggeredWithoutSave(
                navArgsToCache
            );
            return true;
        } else {
            return false;
        }
    }

    goToMainView(
        shouldPauseNavigation?: (...argsToCache: any) => boolean | void,
        isActive?: boolean) {
        if (!isActive &&
            typeof shouldPauseNavigation === 'function' &&
            this.shouldPauseNavigation({
                continueFunction: this.goToMainView.bind(this),
                navFunctionArgsToCache: []
            })
        ) {
            return;
        }
        if (
            (this.userDataCacheService.isAssesseeView ||
                this.userDataCacheService.isAssessorView)
            && !this.globalDataService.createAssessmentOverview
        ) {
            this.apiResponseCacheService.deleteCollection();
            const viewId = this.assessmentCacheService.defaultViewId;
            const loginResponse =
                this.credentialsCacheService.masterLoginDiveOut();
            loginResponse['tokens']['bearerToken'] =
                this.credentialsCacheService.getViewToken(
                    this.userDataCacheService.emailId,
                    viewId
                );
            loginResponse['defaultViewId'] = viewId;
            this.sessionService.login(
                loginResponse,
                this.viewHttpService,
                true,
                null,
                () => {
                    this.assessmentCacheService.assessmentView = false;
                    this.userDataCacheService.isAssesseeView = false;
                    this.userDataCacheService.isAssessorView = false;
                    this.globalDataService.returnToConsole = true;
                }
            );
            this.changeDetectorRef.detectChanges();
            return;
        }
        const url = this.assessmentCacheService.mainViewPath;
        this.globalDataService.routingData.next(null);
        this.router.navigateByUrl(url);
        this.changeDetectorRef.detectChanges();
        this.globalDataService.freshLogin = false;
        const viewId = this.assessmentCacheService.defaultViewId;
        if (this.userDataCacheService.isAssessorView) {
            const token = this.credentialsCacheService.getViewToken(
                this.userDataCacheService.emailId,
                viewId
            );
            this.viewHttpService.bearerToken = token;
        }

        const sideMenuData =
            this.sideMenuCacheService.getFirstLevelData(viewId);
        this.sessionService.setSideMenuFirstLevelDataData(
            viewId,
            sideMenuData,
            false,
            false
        );
        this.assessmentCacheService.deleteKey(AssessmentKey.CREATE_ASSESSMENT_LIST_DATA);
        this.globalDataService.createAssessmentOverview = false;
        this.assessmentCacheService.assessmentView = false;
        this.userDataCacheService.isAssesseeView = false;
        this.userDataCacheService.isAssessorView = false;
        this.globalDataService.returnToConsole = true;
        this.globalDataService.fullPageLoader.next(false);
    }
    changeRole(role) {
        if (this.selectedRole === role.roleName) {
            return;
        }
        this.selectedRole = role.roleName;
        this.globalDataService.fullPageLoader.next(true);
        this.globalDataService.roleBasedLogin(
            role,
            this.httpService.samlAuthorizationToken,
            this.sessionService,
            this.credentialsCacheService,
            this.notificationsService,
            this.widgetHttpService,
            this.viewHttpService
        );
    }

    toggleWidgetCatalog() {
        this.widgetCatalogToggle = !this.widgetCatalogToggle;
        if (this.widgetCatalogToggle) {
            this.globalDataService.loadWidgetsFromWidgetId.next(
                this.widgetCatalog.widgetCatalogResponse
            );
            this.globalDataService.breadcrumbData.next(['Widget Catalog']);
        } else {
            this.globalDataService.handleLeafNode.next(true);
        }
    }
}
