import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { FilterStoreKey } from 'src/app/shared/enums/FilterStoreKey';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';

@Component({
    selector: 'app-assessee-home',
    templateUrl: './assessee-home.component.html',
    styleUrls: ['./assessee-home.component.sass']
})
export class AssesseeHomeComponent implements OnInit {
    FilterStoreKey = FilterStoreKey;
    constructor(
        public globalDataService: GlobalDataService,
        public filterService: FiltersService
    ) {}

    ngOnInit(): void {}

    navigationChanged() {
        this.globalDataService.isHandset$
            .pipe(take(1))
            .subscribe((isHandset) => {
                if (!isHandset) {
                    this.globalDataService.windowResizeEvent.next();
                }
            });
    }
}
