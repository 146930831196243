<section class="signup-config-step-two-container">
    <section class="form-elements-list-container">
        <div class="title">Form Elements</div>
        <div class="form-elements-list">
            <ng-container *ngFor="let el of fieldTypeList">
                <div
                    matRipple
                    class="label-draggable-item"
                    (click)="addNewField(el)"
                >
                    {{ el.label }}
                    <span appIcon [data]="plusIcon"></span>
                </div>
            </ng-container>
        </div>
    </section>
    <section class="page-section">
        <div class="header">
            <div class="top-ellipse" appIcon [data]="topEllipse"></div>
        </div>
        <div class="logo-container">
            <div class="logo">
                <a
                    appIcon
                    [data]="logo"
                    [href]="
                        whiteLableData.organizationalUrl
                            ? whiteLableData.organizationalUrl.includes('http')
                                ? whiteLableData.organizationalUrl
                                : '//' + whiteLableData.organizationalUrl
                            : ''
                    "
                    target="_blank"
                    rel="noopener noreferrer"
                >
                </a>
            </div>
        </div>
        <section class="content-section">
            <div class="upload-image-container">
                <div
                    *ngIf="!uploadedFile && !uploadedFileUrlPreSaved"
                    class="file-picker-container"
                    matRipple
                    [style.backgroundImage]="
                        'url(/assets/signup/signup-config-rectangle.svg)'
                    "
                    appDragDropFileSelect
                    [mimeTypes]="['image/png', 'image/jpeg']"
                    (onChange)="onFileSelected($event)"
                >
                    <img src="/assets/signup/folder-blue.svg" />
                    <span>OR</span>
                    <span class="browse-files-button">Browse Files</span>
                </div>
                <div
                    *ngIf="uploadedFile || uploadedFileUrlPreSaved"
                    class="file-preview"
                >
                    <img class="image" [src]="uploadedFileUrl" />
                    <app-button-generator
                        [buttonGenInput]="RemoveImageButton"
                    ></app-button-generator>
                </div>
            </div>
            <section class="creator-component">
                <div class="title">Sign Up</div>
                <div class="step-info-wrapper">
                    <div class="step-title">
                        <span>
                            Step {{ selectedStepIndex! + 1 }} of
                            {{ formSectionsInput.length }}
                        </span>
                        <button
                            mat-icon-button
                            (click)="isEditStepMode = !isEditStepMode"
                        >
                            <i
                                class="fas fa-chevron-up"
                                *ngIf="!isEditStepMode"
                            ></i>
                            <i
                                class="fas fa-chevron-down"
                                *ngIf="isEditStepMode"
                            ></i>
                        </button>
                    </div>
                    <ng-container *ngIf="!isEditStepMode">
                        <div
                            *ngFor="let el of formSectionsInput; index as i"
                            class="step-item-wrapper"
                        >
                            <div class="virtual-input">
                                {{ i + 1 }}.
                                <input
                                    placeholder="Add here..."
                                    [(ngModel)]="el.formName"
                                />
                                <button
                                    mat-icon-button
                                    (click)="editStepPencil(i)"
                                >
                                    <span
                                        class="action-icon"
                                        appIcon
                                        [data]="pencilIcon"
                                    ></span>
                                </button>
                            </div>
                            <button
                                mat-icon-button
                                (click)="clickStepDelete(i)"
                                [ngStyle]="{
                                    visibility:
                                        formSectionsInput.length > 1
                                            ? 'visible'
                                            : 'hidden'
                                }"
                            >
                                <span
                                    class="action-icon"
                                    appIcon
                                    [data]="deleteIcon"
                                ></span>
                            </button>
                            <button mat-icon-button (click)="stepAddNew(i)">
                                <span
                                    class="action-icon"
                                    appIcon
                                    [data]="plusIcon"
                                ></span>
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isEditStepMode">
                        <div class="step-selected-item">
                            <span>
                                {{
                                    formSectionsInput[selectedStepIndex]
                                        .formName ||
                                        "Section " +
                                            (selectedStepIndex + 1) +
                                            " name here"
                                }}
                            </span>
                            <button
                                mat-icon-button
                                (click)="isEditStepMode = !isEditStepMode"
                            >
                                <span
                                    class="action-icon"
                                    appIcon
                                    [data]="pencilIcon"
                                ></span>
                            </button>
                        </div>
                    </ng-container>
                </div>
                <ng-container *ngIf="!isShowAddDefaultFieldMenu">
                    <div
                        class="field-list-area"
                        cdkDropList
                        #fieldTargetList="cdkDropList"
                        [cdkDropListData]="currentSelectedStepFieldList"
                        [cdkDropListConnectedTo]="'fieldSourceList'"
                        (cdkDropListDropped)="handleOndrop($event)"
                    >
                        <div class="fields-list">
                            <mat-form-field
                                cdkDrag
                                cdkDragBoundary=".feild-list-area"
                                cdkDragLockAxis="y"
                                [appearance]="'outline'"
                                *ngFor="
                                    let field of currentSelectedStepFieldList;
                                    index as fieldIndex
                                "
                            >
                                <div *cdkDragPreview></div>

                                <span
                                    matPrefix
                                    cdkDragHandle
                                    class="field-drag-icon"
                                    appIcon
                                    [data]="dragIcon"
                                ></span>

                                <input
                                    matInput
                                    placeholder="{{ field?.label || '' }}"
                                    readonly
                                    [type]="'text'"
                                />
                                <div matSuffix style="display: flex">
                                    <button
                                        mat-icon-button
                                        (click)="
                                            handleClickFeildEdit({
                                                field: field,
                                                fieldIndex: fieldIndex
                                            })
                                        "
                                    >
                                        <span
                                            class="action-icon"
                                            appIcon
                                            [data]="pencilIcon"
                                        ></span>
                                    </button>
                                    <button
                                        *ngIf="!field.isDefaultField"
                                        mat-icon-button
                                        (click)="
                                            handleClickFeildDelete(fieldIndex)
                                        "
                                    >
                                        <span
                                            class="action-icon"
                                            appIcon
                                            [data]="trashIcon"
                                        ></span>
                                    </button>
                                </div>
                            </mat-form-field>
                        </div>
                        <div class="default-field-area">
                            <ng-container
                                *ngIf="
                                    defaultAddedFieldInCurrentStep.length !==
                                    defaultFieldsStatus.length
                                "
                            >
                                <app-button-generator
                                    [buttonGenInput]="handleAddDefaultFields"
                                ></app-button-generator>
                                <span>OR</span>
                            </ng-container>
                            <span
                                >Drag and drop from the form elements to get
                                started!</span
                            >
                        </div>
                    </div>
                </ng-container>
                <ng-container
                    *ngIf="isShowAddDefaultFieldMenu"
                    [ngTemplateOutlet]="AddDefaultFieldsMenu"
                >
                </ng-container>
            </section>
        </section>
        <div class="footer">
            <div class="bottom-ellipse" appIcon [data]="bottomEllipse"></div>
        </div>
    </section>
</section>
<section class="modal-action-buttons-container">
    <app-button-generator [buttonGenInput]="backButton"></app-button-generator>
    <app-button-generator
        class="save-as-draft-button"
        [buttonGenInput]="SaveAsDraftButton"
    ></app-button-generator>
    <app-button-generator [buttonGenInput]="NextButton"></app-button-generator>
</section>

<ng-template #AddDefaultFieldsMenu>
    <div class="default-field-menu-container">
        <mat-form-field
            [appearance]="'outline'"
            *ngFor="let field of defaultFieldsStatus; index as fieldIndex"
        >
            <input
                matInput
                placeholder="{{ field?.label || '' }}"
                readonly
                [type]="'text'"
            />
            <button
                *ngIf="!field.isAddedInCurrentStep"
                matSuffix
                mat-icon-button
                (click)="addDefaultFieldIconHandle(fieldIndex)"
            >
                <span class="action-icon" appIcon [data]="plusIconBlue"></span>
            </button>
            <button
                *ngIf="field.isAddedInCurrentStep"
                matSuffix
                mat-icon-button
                (click)="removeDefaultFieldIconHandle(fieldIndex)"
            >
                <span class="action-icon" appIcon [data]="minusIcon"></span>
            </button>
        </mat-form-field>
        <div class="action-buttons-container">
            <app-button-generator
                [buttonGenInput]="handleCancelDefaultFieldsAction"
            ></app-button-generator
            ><app-button-generator
                [buttonGenInput]="handleSubmitDefaultFieldsAction"
            ></app-button-generator>
        </div>
    </div>
</ng-template>
