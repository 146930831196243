import { Component, OnInit } from '@angular/core';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import {
    CloudLabel,
    getLogoPathOfCloudLabel
} from 'src/app/shared/enums/CloudLabel';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { PortletType } from 'src/app/shared/enums/PortletType';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { WidgetInjectedData } from '../../../../classes/WidgetInjectedData';
import { IIntegrationInput } from '../../../../interfaces/integration/IIntegrationInput';
import { ModalService } from '../../../../services/modal/modal-service/modal.service';
import { AzureSponsorshipOnboardingModalComponent } from './../../../modal-templates/account-onboarding-modals/azure-sponsorship-onboarding-modal/azure-sponsorship-onboarding-modal.component';

@Component({
    selector: 'app-azure-sponsorship-integration',
    templateUrl: './azure-sponsorship-integration.component.html',
    styleUrls: ['./azure-sponsorship-integration.component.sass']
})
export class AzureSponsorshipIntegrationComponent implements OnInit {
    integrationInput: IIntegrationInput = null;
    widgetRef: Widget;
    isPayAsYouGO: boolean = false;
    isSponsorship: boolean = false;

    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        if (
            this.widgetRef.widgetData.widgetInfo.portlet.type ===
            PortletType.SPONSORSHIP_INTEGRATION
        ) {
            this.isSponsorship = true;
        } else if (
            this.widgetRef.widgetData.widgetInfo.portlet.type ===
            PortletType.PAY_AS_YOU_GO_INTEGRATION
        ) {
            this.isPayAsYouGO = true;
        }
        this.setUpBasics();
    }
    setUpBasics() {
        this.integrationInput = {
            integrationName: this.widgetRef.widgetData.widgetInfo.text,
            integrationCategory: 'Cloud Integration',
            imageUrl: this.isPayAsYouGO
                ? getLogoPathOfCloudLabel(CloudLabel.AZURE_PG)
                : getLogoPathOfCloudLabel(CloudLabel.AZURE_SP),
            buttons: [
                {
                    buttonName: 'Add',
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-plus'
                    },
                    function: () => {
                        const modalArgs: IModalData = {
                            modalName: this.isPayAsYouGO
                                ? 'On Board Azure Pay As You Go Account'
                                : 'On Board Azure Sponsorship Account',
                            modalType: ModalType.MIDDLE,
                            modalIcon: {
                                type: IconType.FONTAWSOME,
                                class: ''
                            },

                            sourceId: this.widgetRef.uniqueIdentity,
                            modalSteps: [
                                {
                                    resetModal: true,
                                    stepName: 'Step 1',
                                    stepData: {
                                        componentToLoad:
                                            AzureSponsorshipOnboardingModalComponent,

                                        payload: {
                                            data: {
                                                widgetRef: this.widgetRef,
                                                stepData: null,
                                                edit: false,
                                                isPayAsYouGO: this.isPayAsYouGO,
                                                isSponsorship:
                                                    this.isSponsorship
                                            }
                                        }
                                    }
                                }
                            ],
                            modalWidthVw: 75,
                            modalHeightVh: 95
                        };
                        this.modalService.openModal(modalArgs);
                    }
                }
            ]
        };
    }
}
