<div class="info-content">
    <ng-container *ngFor="let con of infoData.content">
        <ng-container *ngIf="con.type === 'PARAGRAPH'">
            <p
                *ngFor="let value of con.data"
                [innerHTML]="value | safeRender: SanitizeTypes.HTML"
            ></p>
        </ng-container>
        <ng-container *ngIf="con.type === 'ORDERED_LIST'">
            <ol>
                <li
                    *ngFor="let value of con.data"
                    [innerHTML]="value | safeRender: SanitizeTypes.HTML"
                ></li>
            </ol>
        </ng-container>
        <ng-container *ngIf="con.type === 'UNORDERED_LIST'">
            <ul
                [class.has-list-style]="con.listStyleType"
                [style.list-style-type]="
                    con.listStyleType && con.listStyleType !== 'none'
                        ? con.listStyleType
                        : null
                "
            >
                <li
                    *ngFor="let value of con.data"
                    [innerHTML]="value | safeRender: SanitizeTypes.HTML"
                ></li>
            </ul>
        </ng-container>
    </ng-container>
</div>
