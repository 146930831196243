<form class="form">
    <div class="form-data">
        <app-form-generator
            [formGeneratorInput]="alertNameFormGenInput"
            (formGroupRef)="alertNameFormGroup = $event"
        ></app-form-generator>
        <app-widget-selector
            [supportedFormats]="[SupportedFormat.ALERT]"
            [selectedMap]="widgetsSelectedMap"
            [selectedIds]="widgetsSelected"
            [limit]="GlobalConfiguration.ALERT_LIMIT"
            [apiUrl]="ApiUrls.GOVERNANCE_SIDE_MENU_DATA_API_PATH"
            [viewId]="configCacheService.viewId"
            (widgetsSelectedIds)="
                widgetsSelected = $event; noWidgetsSelected = false
            "
            (widgetsSelectedMap)="widgetsSelectedMap = $event"
        ></app-widget-selector>
        <div
            class="tw-font-semibold tw-text-fs-400 tw-py-3 tw-px-5"
            *ngIf="noWidgetsSelected"
        >
            <i
                class="
                    tw-mr-2 tw-text-fs-400
                    fas
                    fa-exclamation-triangle
                    color-warn
                "
            ></i>
            Select atleast one widget
        </div>
    </div>
    <div class="form-controls">
        <app-button-generator
            *ngIf="nextButton"
            [buttonGenInput]="nextButton"
        ></app-button-generator>
    </div>
</form>
