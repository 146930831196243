<div class="container">
    <div class="form-container">
        <div class="form-field">
            <div class="form-field-types">
                <div
                    class="form-field-heading tw-pt-1 tw-pb-4 tw-font-semibold"
                >
                    {{ formFieldHeading }}
                </div>
                <app-table-search
                    class="tw-flex"
                    [data]="totalFields.fields"
                    (changed)="sideFields.fields = $event"
                ></app-table-search>
                <ng-container
                    *ngFor="let field of sideFields.fields; index as i"
                >
                    <div class="tw-flex tw-align-center index-item">
                        <mat-checkbox
                            (change)="plotField(field, $event.checked)"
                            [checked]="isChecked.get(field.name)"
                            [class.disabled]="isRequired.get(field.name)"
                            [ngClass]="
                                isRequired.get(field.name) ? 'accent-color' : ''
                            "
                            [disabled]="isRequired.get(field.name)"
                            color="accent"
                            >{{ field.label }}</mat-checkbox
                        >
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="form-area-container">
            <div class="form-area">
                <div class="configure-box-backdrop" *ngIf="configureFormInput">
                    <div class="configure-box">
                        <div class="configure-form">
                            <app-form-generator
                                *ngIf="configureFormInput"
                                [formGeneratorInput]="configureFormInput"
                                (formGroupRef)="
                                    tempFormGroupMap.set(
                                        configureFormInput.formId,
                                        $event
                                    )
                                "
                            >
                            </app-form-generator>
                            <div class="configure-buttons tw-justify-items-end">
                                <app-multi-button-generator
                                    [buttonGenInputs]="configureButtonsInput"
                                ></app-multi-button-generator>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="tw-h-full tw-overflow-y-auto tw-px-4 tw-py-3"
                    [class.tw-blur-sm]="
                        configureFormInput && configureButtonsInput
                    "
                >
                    <div class="form-name">
                        <span>Create Case</span>
                    </div>
                    <div class="form-drop-area">
                        <div
                            class="form-drop-area-item tw-flex tw-justify-between tw-pt-2"
                            *ngFor="let configField of configureFields"
                        >
                            <div>
                                {{ configField.label }}
                            </div>
                            <div class="action-buttons">
                                <div
                                    class="edit-icon"
                                    appIcon
                                    [data]="editIcon"
                                    (click)="
                                        generateConfigurationForm(configField)
                                    "
                                ></div>
                                <div
                                    class="delete-icon"
                                    appIcon
                                    [data]="deleteIcon"
                                    (click)="plotField(configField, false)"
                                    [class.disabled]="
                                        isRequired.get(configField.name)
                                    "
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-button-container">
        <div class="clear-fix"></div>
        <div class="buttons">
            <app-multi-button-generator [buttonGenInputs]="buttonInputs">
            </app-multi-button-generator>
        </div>
    </div>
</div>
