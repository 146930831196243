<div class="date-picker-container">
    <ngb-datepicker
        style="display: block; flex: 1; background: white"
        #dp
        (select)="onDateSelection($event)"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [displayMonths]="1"
        [dayTemplate]="t"
        [startDate]="fromDate"
        outsideDays="hidden"
    >
    </ngb-datepicker>

    <ng-template #t let-date="date" let-focused="focused">
        <span
            class="custom-day"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
        >
            {{ date.day }}
        </span>
    </ng-template>
</div>
