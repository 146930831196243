<form class="form">
    <div class="form-data" [style.cursor] = "isReadAccess? 'not-allowed' : '' " (keydown)="handleKeyDown($event)" [attr.tabindex]="isReadAccess?'-1' : '0'">
        <app-filters-handler
            [widgetIds]="widgetsSelected"
            [filterStoreKey]="FilterStoreKey.REPORT_FILTERS"
            (formats)="supportedFormats = $event"
            [setToDefault]="
                this.modalInputData &&
                this.modalInputData.data &&
                this.modalInputData.data['edit']
                    ? false
                    : true
            "
            (filtersLoaded)="disableNextButton = !$event"
            [isFieldDisable]="isReadAccess? true: false"
        ></app-filters-handler>
    </div>
    <div class="tw-justify-between form-controls">
        <app-button-generator
            *ngIf="backButton"
            [buttonGenInput]="backButton"
        ></app-button-generator
        ><app-button-generator
            *ngIf="nextButton"
            [buttonGenInput]="nextButton"
        ></app-button-generator>
    </div>
</form>