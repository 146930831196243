<input
    #numberInput
    [placeholder]="
        params.field && params.field.placeholder
            ? params.field.placeholder
            : null
    "
    (keydown)="onKeyDown($event)"
    [(ngModel)]="value"
    style="width: 100%"
/>
