<!--Field type one start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_1">
    <div
        *ngIf="field.appearance === FieldAppearance.TYPE_1"
        class="form-row {{ field.extraClass }}"
    >
        <mat-form-field
            #textAreaFieldRef
            [appearance]="'outline'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [hideRequiredMarker]="true"
        >
            <mat-label>
                {{ field.label }}
                <span
                    *ngIf="field.required !== undefined ? field.required : true"
                    class="color-warn"
                >
                    &nbsp;*
                </span>
            </mat-label>
            <textarea
                matInput
                [placeholder]="field.placeholder"
                [name]="field.name"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                (focus)="
                    field.prefixIcon ? textAreaFieldRef.updateOutlineGap() : '';
                    field.onFocus ? field.onFocus($event, formGroup, field.name) : null
                "
                [formControl]="control"
                [errorStateMatcher]="matcher"
            ></textarea>
            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.prefixIcon.iconData.class }}
            </mat-icon>
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type one end -->

<!-- Field type two start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_2">
    <div class="form-row {{ field.extraClass }}">
        <mat-form-field
            #textAreaFieldRef
            [appearance]="'outline'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [hideRequiredMarker]="true"
            [floatLabel]="'never'"
        >
            <textarea
                matInput
                [placeholder]="field.placeholder"
                [name]="field.name"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                (focus)="
                    field.prefixIcon ? textAreaFieldRef.updateOutlineGap() : ''
                "
                [formControl]="control"
                [errorStateMatcher]="matcher"
            ></textarea>
            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.prefixIcon.iconData.class }}
            </mat-icon>
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type two end -->

<!-- Field type three start -->

<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_3">
    <div class="form-row show-label {{ field.extraClass }}">
        <span class="label">
            {{ field.label }}
            <span
                *ngIf="field.required !== undefined ? field.required : true"
                class="color-warn"
            >
                &nbsp;*
            </span>
        </span>

        <mat-form-field
            #textAreaFieldRef
            [appearance]="'legacy'"
            class="form-field-textarea"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [hideRequiredMarker]="true"
            [floatLabel]="'never'"
        >
            <textarea
                matInput
                [placeholder]="field.placeholder"
                [name]="field.name"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                (focus)="
                    field.prefixIcon ? textAreaFieldRef.updateOutlineGap() : ''
                "
                [ngStyle]="field['textAreaStyles']"
                [formControl]="control"
                [errorStateMatcher]="matcher"
            ></textarea>
            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.prefixIcon.iconData.class }}
            </mat-icon>
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type three end -->

<!-- Field type four start -->

<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_4">
    <div class="form-row {{ field.extraClass }}">
        <mat-form-field
            #textAreaFieldRef
            [appearance]="'legacy'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [hideRequiredMarker]="true"
            [floatLabel]="'never'"
        >
            <textarea
                matInput
                [placeholder]="field.placeholder"
                [name]="field.name"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                (focus)="
                    field.prefixIcon ? textAreaFieldRef.updateOutlineGap() : ''
                "
                [formControl]="control"
                [errorStateMatcher]="matcher"
            ></textarea>
            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.prefixIcon.iconData.class }}
            </mat-icon>
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type four end -->

<!--Field type seven start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_7">
    <div class="form-row type-7 {{ field.extraClass }}">
        <span class="label">
            {{ field.label }}
            <span
                *ngIf="field.required !== undefined ? field.required : true"
                class="color-warn"
            >
                &nbsp;*
            </span>
        </span>
        <mat-form-field
            #textAreaFieldRef
            [appearance]="'outline'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [hideRequiredMarker]="true"
        >
            <textarea
                matInput
                [placeholder]="field.placeholder"
                [name]="field.name"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                (focus)="
                    field.prefixIcon ? textAreaFieldRef.updateOutlineGap() : '';
                "
                [formControl]="control"
                [errorStateMatcher]="matcher"
            ></textarea>
            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.prefixIcon.iconData.class }}
            </mat-icon>
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type seven end -->

<!-- Error Templates -->
<ng-template #groupError>
    <ng-container
        *ngIf="
            formGroup.get(field.groupByKey).get(field.name).touched &&
            formGroup.get(field.groupByKey).get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation
                        : formGroup.get(field.groupByKey).get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
<ng-template #error>
    <ng-container
        *ngIf="
            formGroup.get(field.name).touched &&
            formGroup.get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation : formGroup.get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
