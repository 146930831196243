import { ActionState } from '../enums/ActionState';
import { ActionVisibility } from '../enums/ActionVisibility';
import { DataType } from '../enums/DataType';
import { State } from '../enums/State';
import { IWidgetConfigState } from '../interfaces/widget/IWidgetConfigState';
import { ActionType } from './ActionType';

export function construct(target: any) {
    target.construct();
}

@construct
export class WidgetConfig {
    static actionsPremium: ActionType = new ActionType();
    static actionsFree: ActionType = new ActionType();
    static actionsBasic: ActionType = new ActionType();
    static actionsPreview: ActionType = new ActionType();
    static actionsDemo: ActionType = new ActionType();

    static widgetConfigStates = {};
    static FREE: IWidgetConfigState = {
        blur: false,
        enableLimit: false,
        data: DataType.REAL,
        actions: WidgetConfig.actionsFree
    };

    static PREMIUM: IWidgetConfigState = {
        blur: false,
        enableLimit: false,
        data: DataType.REAL,
        actions: WidgetConfig.actionsPremium
    };

    static BASIC: IWidgetConfigState = {
        blur: true,
        enableLimit: true,
        data: DataType.REAL,
        actions: WidgetConfig.actionsBasic
    };

    static DEMO: IWidgetConfigState = {
        blur: false,
        enableLimit: false,
        data: DataType.DEMO,
        actions: WidgetConfig.actionsDemo
    };

    static PREVIEW: IWidgetConfigState = {
        blur: false,
        enableLimit: false,
        data: DataType.DEMO,
        actions: WidgetConfig.actionsPreview
    };

    static construct() {
        this.actionsFree.downgrade.visibility = ActionVisibility.HIDDEN;
        this.actionsFree.upgrade.visibility = ActionVisibility.HIDDEN;
        this.actionsPremium.upgrade.visibility = ActionVisibility.HIDDEN;
        this.actionsBasic.downgrade.visibility = ActionVisibility.HIDDEN;
        this.actionsBasic.download.state = ActionState.DISABLED;
        this.actionsBasic.fix.state = ActionState.DISABLED;
        this.actionsBasic.compareView.state = ActionState.DISABLED;
        this.actionsBasic.legend.state = ActionState.DISABLED;
        this.actionsDemo.downgrade.visibility = ActionVisibility.HIDDEN;
        this.actionsDemo.upgrade.visibility = ActionVisibility.HIDDEN;
        this.actionsPreview.fix.state = ActionState.DISABLED;
        this.actionsPreview.moreInfo.state = ActionState.DISABLED;
        this.actionsPreview.download.state = ActionState.DISABLED;
        this.actionsPreview.downgrade.state = ActionState.DISABLED;
        this.actionsPreview.upgrade.state = ActionState.DISABLED;
        this.actionsPreview.compareView.state = ActionState.DISABLED;
        this.actionsPreview.addToCart.state = ActionState.DISABLED;
        this.actionsPreview.attention.state = ActionState.DISABLED;
        this.actionsPreview.tag.state = ActionState.DISABLED;
        this.actionsPreview.refresh.state = ActionState.DISABLED;
        this.widgetConfigStates[State.FREE] = this.FREE;
        this.widgetConfigStates[State.PREMIUM] = this.PREMIUM;
        this.widgetConfigStates[State.BASIC] = this.BASIC;
        this.widgetConfigStates[State.PREVIEW] = this.PREVIEW;
        this.widgetConfigStates[State.DEMO] = this.DEMO;
    }
}
