<ng-template #controls>
    <div class="controls" *ngIf="!(widgetRef.loadingData | async)">
        <div class="overview">
            <app-multi-button-generator
                [buttonGenInputs]="controlButtons"
                [options]="controlButtonOption"
            ></app-multi-button-generator>
        </div>
    </div>
</ng-template>
<app-table-generator
    *ngIf="tableInput"
    [tableInput]="tableInput"
    [widgetRef]="widgetRef"
    (gridRef)="agGrid = $event"
    (selectionChanged)="onSelectionChanged()"
    [overviewTemplateStart]="controls"
></app-table-generator>
