import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { GridOptions, RowNode } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { Widget } from 'src/app/shared/classes/Widget';
import { IconType } from 'src/app/shared/enums/IconType';
import { IApiInfo } from 'src/app/shared/interfaces/api/IApiInfo';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IColumnData } from 'src/app/shared/interfaces/table-generator/IColumnData';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { HitApi } from '../../classes/HitApi';
import { Messages } from '../../classes/Messages';
import { RequestType } from '../../enums/RequestType';
import { ApiResponseType } from '../../interfaces/api/portlets/ApiResponse';
import { ITableGeneratorInput } from '../../interfaces/table-generator/ITableGeneratorInput';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { Helper } from './../../classes/Helper';

@Component({
    selector: 'app-only-table-generator',
    templateUrl: './only-table-generator.component.html',
    styleUrls: ['./only-table-generator.component.sass']
})
export class OnlyTableGeneratorComponent implements OnInit, OnChanges {
    @Input() widgetRef: Widget;
    @Input() tableInput: ITableGeneratorInput;
    @Input() apiInfo: IApiInfo;
    @Input() overviewTemplateStart: TemplateRef<any>;
    @Input() overviewTemplateEnd: TemplateRef<any>;
    @Input() overviewTemplateBottom: TemplateRef<any>;
    @Input() tableData: any;
    @Input() showSearchBox: boolean = true;
    @Input() autoFitColumns: boolean = true;
    @Input() hideControlBar: boolean = false;
    @Input() defaultColDef: any = {
        sortable: true,
        filter: true,
        resizable: true,
        width: 150
    };
    /**
     * @deprecated below input variable will be removed. It is of no use now.
     */
    @Input() useMaterialIcons: boolean = false;
    @Input() getRowStyle: any;
    @Output() gridRef = new EventEmitter<GridOptions>();
    @Output() selectionChange = new EventEmitter<any>();
    @Output() rowClicked = new EventEmitter<any>();
    @Output() cellValueChanged = new EventEmitter<GridOptions>();
    @Output() scroll = new EventEmitter<GridOptions>();
    @ViewChild('agGrid', { static: false }) agGrid: GridOptions;
    tableRowClassRules;
    tableId: string;
    private gridApi;
    private gridColumnApi;
    selectionChangeActive = true;
    selectAll = false;
    agGridIcons = Helper.getAgGridIcons();

    rowData;
    colDefs;
    Helper = Helper;
    frameworkComponents: any;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    errorMessage: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    extraMessage: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    loader: boolean = false;
    tableAutoHeight: boolean = false;
    stopEditingWhenGridLosesFocus: boolean;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private httpService: HttpService,
        private ngZone: NgZone,
        private notificationService: NotificationsService
    ) {
        this.tableId = Helper.generateUniqueKey(16);
    }

    ngOnInit(): void {
        this.setupBasics();
    }

    ngOnChanges(changes) {
        if (this.agGrid) {
            this.agGrid.api.redrawRows();
        }
    }
    rowClassRulesForLightState() {
        this.defaultColDef.sortable = false;
        this.defaultColDef.filter = false;
        Helper.setRowClassRules(
            this.widgetRef,
            this.tableInput.rowsToShow ? this.tableInput.rowsToShow : null
        );
    }
    selectionChanged($event) {
        if (!this.selectionChangeActive) {
            return;
        }

        if (this.tableInput.selectionLimit !== undefined) {
            if (
                $event.api.getSelectedNodes().length >
                this.tableInput.selectionLimit
            ) {
                this.selectionChangeActive = false;
                this.selectAll = !this.selectAll;
                // Select All Case
                if (this.agGrid && this.agGrid.api && this.selectAll) {
                    this.agGrid.api.deselectAll();
                    const nodes: RowNode[] = (this.agGrid.api as any)[
                        'rowModel'
                    ]['rowsToDisplay'].slice(0, this.tableInput.selectionLimit);
                    let index = 0;
                    for (const node of nodes) {
                        if (index === nodes.length - 1) {
                            node.setSelected(true);
                        } else {
                            node.setSelected(true, false, true);
                        }
                        ++index;
                    }
                    this.selectionChangeActive = true;
                    this.selectionChange.emit($event);
                    this.agGrid.api.redrawRows();
                } else {
                    this.selectionChangeActive = true;
                    this.agGrid.api.deselectAll();
                    this.selectionChange.emit($event);
                    this.agGrid.api.redrawRows();
                }
            } else if (
                $event.api.getSelectedNodes().length ===
                this.tableInput.selectionLimit
            ) {
                this.notificationService.showSnackBar(
                    `${
                        this.tableInput.selectionLimitBreachMessage
                            ? this.tableInput.selectionLimitBreachMessage
                            : `Maximum of ${this.tableInput.selectionLimit} row can be selected`
                    }`
                );
                this.selectionChangeActive = true;
                this.selectionChange.emit($event);
                this.agGrid.api.redrawRows();
            } else {
                this.selectionChangeActive = true;
                this.selectionChange.emit($event);
                this.agGrid.api.redrawRows();
            }
        } else {
            this.selectionChangeActive = true;
            this.selectionChange.emit($event);
        }

        if (this.tableInput.showNotificationBadgeOnSelection) {
            this.agGrid.api.getSelectedNodes().length
                ? this.tableInput.showNotificationBadgeOnSelection.next(true)
                : this.tableInput.showNotificationBadgeOnSelection.next(false);
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.handleResize();
        if (this.rowData.length < 7) {
            this.agGrid.api.setDomLayout('autoHeight');
            this.tableAutoHeight = true;
        }
        this.gridRef.emit(this.agGrid);
    }

    setupBasics() {
        this.dataGenerator();
        if (this.widgetRef && this.widgetRef.lightState) {
            this.rowClassRulesForLightState();
        }
        this.frameworkComponents = {
            ...this.frameworkComponents,
            ...this.tableInput.extraComponents
        };
        this.stopEditingWhenGridLosesFocus =
            this.tableInput.stopEditingWhenGridLosesFocus ?? true;
    }

    handleResize() {
        if (this.agGrid && this.tableId) {
            Helper.handleTableResize(this.agGrid, this.tableId);
        }
    }

    dataGenerator() {
        // Resetting Error and Extra Message
        this.errorMessage.next(null);
        this.extraMessage.next(null);
        if (this.apiInfo) {
            this.loader = true;
            // Creating api arguments
            const args: IHitApi = Helper.generateHitApiConfig(this.apiInfo);
            if (args.requestType === RequestType.GET && !args.input) {
                args.input = {};
            }
            if (this.tableInput.apiInput) {
                args.input = this.tableInput.apiInput;
            }
            args.function = (response) => {
                this.bindData(response);
            };
            // args.errorMessage = this.errorMessage;
            args.updateUiFunction =
                this.changeDetectorRef.detectChanges.bind(this);
            args.extraMessage = this.extraMessage;
            args.errorFunction = (error) => {
                // this.bindData(null);
                this.loader = false;
                this.errorMessage.next(error.error.message);
            };
            new HitApi(args, this.httpService, this.ngZone).hitApi();
        } else if (this.tableData) {
            this.bindData(this.tableData);
        }
    }

    bindData(data: ApiResponseType): void {
        if (data) {
            this.rowData = [];
            this.colDefs = [];

            if (this.tableInput.afterResponse) {
                this.tableInput.afterResponse(data);
            }

            if (this.tableInput.listExtraction.type === 'DIRECT') {
                this.rowData = data;
            } else {
                this.rowData = Helper.extractDataFromObject(
                    this.tableInput.listExtraction.nestedKey,
                    data
                );
            }

            if (!this.rowData || !this.rowData.length) {
                this.extraMessage.next(
                    this.tableInput.noDataMessage
                        ? this.tableInput.noDataMessage
                        : Messages.NO_DATA_AVAILABLE
                );
                this.loader = false;
                return;
            }

            /**
             * This is a reusable function for generating colDef for aggrid, with a level parameter in order to watch for any children available to a certain level
             * @param column IColumnData, having properties of a column to be mapped
             * @param level number, to watch for children to a certain level in tree, currently only 1st level is taken
             * @returns return a prepared column compatible with aggrid
             */
            const prepareColumns = (column: IColumnData, level: number = 0) => {
                const colDefObj = {
                    headerName: column.columnName,
                    field: column.columnKey
                };

                if (
                    column.columnName === 'Action' ||
                    column.columnName === 'More Info'
                ) {
                    colDefObj['filter'] = false;
                    colDefObj['lockPinned'] = true;
                }

                if (this.tableInput.agGridCellOptions) {
                    if (this.tableInput.agGridCellOptions.cellFontSize) {
                        colDefObj['cellStyle'] = {
                            fontSize:
                                this.tableInput.agGridCellOptions.cellFontSize
                        };
                    }
                }

                if (!column.buttonGen && column.cellRenderer) {
                    colDefObj['cellRenderer'] = column.cellRenderer;
                }

                if (column.cellEditor && column.componentFramework) {
                    colDefObj['cellEditor'] = 'cellEditor';

                    this.frameworkComponents = {
                        cellEditor: column.componentFramework
                    };
                }

                if (column.cellEditorParams) {
                    colDefObj['cellEditorParams'] = column.cellEditorParams;
                }
                if (column.cellRendererFramework) {
                    colDefObj['cellRendererFramework'] =
                        column.cellRendererFramework;
                }
                if (column.cellRendererParams) {
                    colDefObj['cellRendererParams'] = column.cellRendererParams;
                }
                if (column.pinned) {
                    colDefObj['pinned'] = column.pinned;
                }

                if (column.filter !== undefined) {
                    colDefObj['filter'] = column.filter;
                }

                if (column.lockPinned) {
                    colDefObj['lockPinned'] = column.lockPinned;
                }

                if (column.lockPosition) {
                    colDefObj['lockPosition'] = column.lockPosition;
                }

                if (column.editable) {
                    colDefObj['editable'] = column.editable;
                }

                if (column.headerClass) {
                    colDefObj['headerClass'] = column.headerClass;
                }

                if (column.maxWidth !== undefined) {
                    colDefObj['maxWidth'] = column.maxWidth;
                }

                if (column.cellStyle) {
                    if (typeof column.cellStyle === 'function') {
                        colDefObj['cellStyle'] = column.cellStyle;
                    } else {
                        colDefObj['cellStyle'] = {
                            ...colDefObj['cellStyle'],
                            ...column.cellStyle
                        };
                    }
                }

                if (column.cellClass !== undefined) {
                    colDefObj['cellClass'] = column.cellClass;
                }

                if (column.headerComponentFramework !== undefined) {
                    colDefObj['headerComponentFramework'] =
                        column.headerComponentFramework;
                }

                if (column.columnValueSetter) {
                    colDefObj['valueSetter'] = column.columnValueSetter;
                }

                if (column.columnValueGetter) {
                    colDefObj['valueGetter'] = column.columnValueGetter;
                }

                if (column.minWidth !== undefined) {
                    colDefObj['minWidth'] = column.minWidth;
                }

                if (column.checkboxSelection) {
                    colDefObj['checkboxSelection'] = column.checkboxSelection;
                }

                if (column.headerCheckboxSelection) {
                    colDefObj['headerCheckboxSelection'] =
                        column.headerCheckboxSelection;
                }

                if (column.tooltipField) {
                    colDefObj['tooltipField'] = column.tooltipField;
                }

                if (column.flex) {
                    colDefObj['flex'] = column.flex;
                }

                if (column.tooltipValueGetter) {
                    colDefObj['tooltipValueGetter'] = column.tooltipValueGetter;
                }

                if (column.hideFilterMenu) {
                    colDefObj['suppressFilter'] = column.hideFilterMenu;
                }

                if (column.width) {
                    colDefObj['width'] = column.width;
                }

                if (column.resizable !== undefined) {
                    colDefObj['resizable'] = column.resizable;
                }

                if (column.colId) {
                    colDefObj['colId'] = column.colId;
                }
                if (column.headerTooltip) {
                    colDefObj['headerTooltip'] = column.headerTooltip;
                }

                if (column.colSpan) {
                    colDefObj['colSpan'] = column.colSpan;
                }

                if (column.rowSpan) {
                    colDefObj['rowSpan'] = column.rowSpan;
                }

                if (column.headerComponentParams) {
                    colDefObj['headerComponentParams'] =
                        column.headerComponentParams;
                }

                if (column.children && column.children.length) {
                    if (level < 1) {
                        colDefObj['children'] = column.children.map(
                            (childColumn) =>
                                prepareColumns(childColumn, level + 1)
                        );
                    }
                }

                if (column.valueFormatter) {
                    colDefObj['valueFormatter'] = column.valueFormatter;
                }

                if (column.buttonGen && column.componentFramework) {
                    colDefObj['cellRenderer'] = column.buttonGenFramework
                        ? column.buttonGenFramework
                        : 'buttonGen';
                    this.frameworkComponents = {
                        ...this.frameworkComponents,
                        [colDefObj['cellRenderer']]: column.componentFramework
                    };
                }

                return colDefObj;
            };

            this.tableInput.columns.forEach((column) => {
                this.colDefs.push(prepareColumns(column));
            });

            if (this.tableInput.selection === 'multiple') {
                this.colDefs.unshift({
                    headerName: '',
                    field: '',
                    headerCheckboxSelection: true,
                    checkboxSelection: (row) => {
                        if (this.tableInput.checkBoxSelection) {
                            return this.tableInput.checkBoxSelection(row);
                        } else {
                            return true;
                        }
                    },
                    pinned: 'left',
                    lockPinned: true,
                    lockPosition: true,
                    minWidth: 45,
                    maxWidth: 45,
                    filter: false
                });
            }
            if (
                this.tableInput.selection === 'multiple' &&
                this.tableInput.removeHeaderCheckBox
            ) {
                this.colDefs[0]['headerCheckboxSelection'] = false;
            }
            this.loader = false;
            this.setUpRowClassRules();
            this.errorMessage.next(null);
            this.extraMessage.next(null);
        }
    }

    setUpRowClassRules() {
        if (this.tableInput.selectionLimit) {
            this.tableRowClassRules = {
                agRowDisabled: (params) => {
                    if (
                        this.agGrid &&
                        this.agGrid.api.getSelectedNodes().length >=
                            this.tableInput.selectionLimit
                    ) {
                        return params.node.selected === false;
                    }
                    return false;
                }
            };
        }
    }

    onQuickFilterChanged(id: string) {
        this.agGrid.api.setQuickFilter(document.getElementById(id)['value']);
    }

    handleRowClick(data) {
        this.rowClicked.emit(data);
    }
}
