import { Injectable, NgZone } from '@angular/core';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { HitApi } from '../../classes/HitApi';
import { AuthorizationType } from '../../enums/AuthorizationType';
import { RequestType } from '../../enums/RequestType';
import { IHitApi } from '../../interfaces/hit-api/IHitApi';
import { GlobalDataService } from '../global-data/global-data.service';
import { HttpService } from '../http/http-main/http.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    constructor(
        private httpService: HttpService,
        private ngZone: NgZone,
        private globalDataService: GlobalDataService
    ) {}

    login(credentials, assessseeLogin?) {
        const hitApi: IHitApi = {
            input: credentials.input,
            url: assessseeLogin
                ? ApiUrls.ASSESSMENT_LOGIN_API_PATH
                : ApiUrls.LOGIN_API_PATH,
            function: credentials.function,
            requestType: RequestType.POST,
            endFunction: credentials.endFunction,
            errorFunction: credentials.errorFunction,
            errorMessage: credentials.errorMessage,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.NOT_AUTHORIZED,
                masterRegion: this.globalDataService?.domainIdRegion,
                iotRegion: false,
                skipBypassRegion: !this.globalDataService?.domainIdRegion
                    ? true
                    : false
            },
            intactUrl: assessseeLogin
                ? ApiUrls.ASSESSMENT_LOGIN_API_PATH
                : ApiUrls.LOGIN_API_PATH
        };
        const http = new HitApi(hitApi, this.httpService, this.ngZone);
        http.hitApi();
    }
    forgetPassword(credentials) {
        const hitApi: IHitApi = {
            input: credentials.input,
            url: ApiUrls.FORGET_PASSWORD_API_PATH,
            function: credentials.function,
            requestType: RequestType.POST,
            endFunction: credentials.endFunction,
            errorMessage: credentials.errorMessage,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.NOT_AUTHORIZED,
                skipBypassRegion: true
            },
            intactUrl: ApiUrls.FORGET_PASSWORD_API_PATH
        };
        const http = new HitApi(hitApi, this.httpService, this.ngZone);
        http.hitApi();
    }
    changePassword(credentials) {
        const hitApi: IHitApi = {
            input: credentials.input,
            url: ApiUrls.CHANGE_PASSWORD_API_PATH,
            function: credentials.function,
            requestType: RequestType.POST,
            endFunction: credentials.endFunction,
            errorMessage: credentials.errorMessage,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
                skipBypassRegion: true
            },
            intactUrl: ApiUrls.CHANGE_PASSWORD_API_PATH
        };
        const http = new HitApi(hitApi, this.httpService, this.ngZone);
        http.hitApi();
    }
}
