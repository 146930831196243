import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class RemoveSpaceFromString extends CustomValidator {
    key = CustomValidatorKey.REMOVE_SPACE_FROM_STRING;
    validatorFunction(control: AbstractControl): ValidationErrors {
        if (!control.value) {
            return;
        }
        const FieldValue: string = control.value;
        const fieldValueWithoutSpace: string = FieldValue.replace(/\s/g, '');
        if (
            control.value &&
            FieldValue.length !== fieldValueWithoutSpace.length
        ) {
            return { removeSpaceFromSring: true };
        }
        return null;
    }
}
