import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GridOptions, RowEvent } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ActionState } from 'src/app/shared/enums/ActionState';
import { ActionVisibility } from 'src/app/shared/enums/ActionVisibility';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import { IDropdownData } from 'src/app/shared/interfaces/dropdown-data/IDropdownData';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { CreateCustomComplianceStepOneComponent } from '../../../modal-templates/custom-compliance-overview/create-custom-compliance-step-one/create-custom-compliance-step-one.component';
import { CreateCustomComplianceStepThreeComponent } from '../../../modal-templates/custom-compliance-overview/create-custom-compliance-step-three/create-custom-compliance-step-three.component';
import { CustomComplianceWidgetListComponent } from '../../../modal-templates/custom-compliance-overview/custom-compliance-widget-list/custom-compliance-widget-list.component';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-custom-compliance-listing',
    templateUrl: './custom-compliance-listing.component.html',
    styleUrls: ['./custom-compliance-listing.component.sass']
})
export class CustomComplianceListingComponent implements OnInit {
    widgetRef: Widget;
    agGrid: GridOptions = null;
    tableGenInput: ITableGeneratorInput = null;
    serviceFilterData: IDropdownData[] = [];
    widgetListData = [];
    selectAll: boolean;
    selectedCompliance = [];
    @ViewChild('deleteTemplate') deleteTemplate: TemplateRef<any>;
    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setServiceFilterData();
        this.setWidgetListData();
        this.setUpBasics();
    }

    setUpBasics() {
        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);
        this.widgetRef.refreshWidgetCallback = () => {
            this.widgetRef.showNotificationBadge.next(false);
        };
        this.tableGenInput = {
            afterResponse: (response: any) => {
                if (this.widgetRef.lightState && response) {
                    if (response.length) {
                        this.tableGenInput.buttons.forEach(
                            (button) => (button.disable = true)
                        );
                    } else {
                        this.tableGenInput.buttons.forEach(
                            (button) => (button.disable = false)
                        );
                    }
                }
            },
            buttons: [
                {
                    buttonName: 'Add New',
                    buttonColorType: ButtonColorType.PRIMARY,
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-plus'
                    },
                    function: (buttonRef: IButtonGeneratorInput) => {
                        this.openAddNewModal(this.widgetListData);
                    },
                    showLoader: false,
                    buttonType: ButtonType.FLAT
                }
            ],
            rowSelection: this.widgetRef.lightState ? 'single' : 'multiple',
            listExtraction: {
                type: 'DIRECT'
            },
            selection: 'multiple',
            widgetIconData: this.widgetRef.widgetData.widgetInfo.cloudIconPath
                ? null
                : {
                      type: IconType.FONTAWSOME,
                      class: 'fas fa-user-plus'
                  },
            noDataMessage: 'No Compliances Available',
            columns: [
                {
                    columnKey: 'complianceName',
                    columnName: 'Custom Compliance Name',
                    minWidth: 80
                },
                {
                    columnKey: 'baseCompliance',
                    columnName: 'Base Compliance Name',
                    minWidth: 80
                },
                {
                    columnKey: 'widgetSelected',
                    columnName: 'Widgets Selected',
                    minWidth: 80,
                    cellRenderer: (rowData: RowEvent) => {
                        return rowData.data['widgetSelected']['count'];
                    }
                },
                {
                    columnKey: 'actions',
                    columnName: 'Actions',
                    minWidth: 230,
                    pinned: 'right',
                    filter: false,
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    headerClass: 'grid-cell-data-centred',
                    valueFormatter: (rowData: RowEvent) => {
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'More Info',
                                buttonColorType: ButtonColorType.INFO,
                                buttonIcon: {
                                    type: IconType.FONTAWSOME,
                                    class: 'none'
                                },
                                buttonType: ButtonType.TEXT,
                                showLoader: true,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    this.getMoreInfo(buttonRef, false, rowData);
                                }
                            },
                            {
                                buttonName: 'Edit',
                                buttonColorType: ButtonColorType.SUCCESS,
                                buttonIcon: {
                                    type: IconType.FONTAWSOME,
                                    class: 'none'
                                },
                                buttonType: ButtonType.TEXT,
                                showLoader: true,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    this.getMoreInfo(buttonRef, true, rowData);
                                }
                            },
                            {
                                buttonName: 'Delete',
                                buttonColorType: ButtonColorType.WARN,
                                buttonIcon: {
                                    type: IconType.FONTAWSOME,
                                    class: 'none'
                                },
                                buttonType: ButtonType.TEXT,
                                showLoader: false,
                                function: () => {
                                    this.selectedCompliance = [rowData.data];

                                    this.deleteCompliance({
                                        complianceList: [
                                            rowData.data['complianceId']
                                        ]
                                    });
                                }
                            }
                        ];
                        rowData['buttonGenInputs'] = buttons;
                        const options: IMultiButtonOption = {
                            layout: {
                                justifyContent: 'space-between'
                            }
                        };
                        rowData['options'] = options;
                        return rowData;
                    }
                }
            ]
        };
    }

    setServiceFilterData() {
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['customCompliance'][
                'listService'
            ]
        );
        apiArgs.input = {};
        apiArgs.function = (response) => {
            this.serviceFilterData = response;
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    setWidgetListData() {
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['customCompliance'][
                'listWidget'
            ]
        );
        apiArgs.input = {};
        apiArgs.function = (response) => {
            this.widgetListData = response;
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    openAddNewModal(widgetList, rowData?) {
        const modalArgs: IModalData = {
            modalName: 'Custom Compliance',
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-wrench'
            },
            modalSteps: [
                {
                    stepName: 'Custom Compliance',
                    stepData: {
                        componentToLoad: CreateCustomComplianceStepOneComponent,
                        payload: {
                            data: {
                                stepData: rowData ? rowData : null,
                                edit: rowData ? true : false,
                                widgetList: widgetList,
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                },
                {
                    stepName: 'Widget',
                    stepData: {
                        componentToLoad: CustomComplianceWidgetListComponent,
                        payload: {
                            data: {
                                widgetList: null,
                                serviceFilterData: this.serviceFilterData,
                                multipleSelection: true,
                                widgetRef: this.widgetRef,
                                stepData: rowData ? rowData : null,
                                edit: rowData ? true : false
                            }
                        }
                    }
                },
                {
                    stepName: 'Whitelisting',
                    stepData: {
                        componentToLoad:
                            CreateCustomComplianceStepThreeComponent,
                        payload: {
                            data: {
                                stepData: rowData ? rowData : null,
                                edit: rowData ? true : false,
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                }
            ],
            modalWidthVw: 60,
            modalHeightVh: 75
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }

    openMoreInfoModal(widgetList) {
        const modalArgs: IModalData = {
            modalName: 'Widget List',
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-wrench'
            },
            modalSteps: [
                {
                    stepName: 'Widget List',
                    stepData: {
                        componentToLoad: CustomComplianceWidgetListComponent,
                        payload: {
                            data: {
                                widgetList: widgetList,
                                serviceFilterData: this.serviceFilterData,
                                multipleSelection: false,
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                }
            ],
            modalWidthVw: 50,
            modalHeightVh: 60
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }

    deleteCompliance(data) {
        this.modalService.openConfirmationModal({
            modalName: 'Delete Compliance',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            contextIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclaimation-triangle'
            },
            confirmationMessage: `Are you sure you want to delete selected compliance ? `,
            buttonColorType: ButtonColorType.WARN,
            buttonText: 'Delete',
            loaderOnExec: true,
            modalHeightVh: 40,
            bodyTemplate: this.deleteTemplate,
            function: (modalId: Symbol) => {
                const apiArgs: IHitApi = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo.delete
                );
                apiArgs.input = data;
                (apiArgs.function = (response) => {
                    this.widgetRef.notificationsService.showSnackBar(
                        'Compliance deleted successfully'
                    );
                    this.widgetRef.refreshWidget();
                    this.widgetRef.showNotificationBadge.next(false);
                    this.widgetRef.modalService.closeModal(null, modalId);
                }),
                    (apiArgs.errorFunction = (error) => {
                        this.widgetRef.notificationsService.showSnackBar(
                            error && error.error && error.error.message
                                ? error.error.message
                                : 'Error deleting compliance',
                            true
                        );
                        this.widgetRef.refreshWidget();
                        this.widgetRef.showNotificationBadge.next(false);
                        this.widgetRef.modalService.closeModal(null, modalId);
                    }),
                    new HitApi(
                        apiArgs,
                        this.widgetRef.httpService,
                        this.widgetRef.ngZone
                    ).hitApi();
            }
        });
    }

    onGridReady($event) {
        this.agGrid = $event;
        this.handleBulkDelete();
    }

    handleBulkDelete() {
        const actions: IAction[] = [];
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: 'Bulk Delete',
            actionId: 'Bulk Delete',
            function: () => {
                if (!this.agGrid.api.getSelectedRows().length) {
                    this.widgetRef.notificationsService.showSnackBar(
                        'Select atleast 1 compliance',
                        true
                    );
                    return;
                }
                const inputData = this.agGrid.api
                    .getSelectedRows()
                    .map((row) => row['complianceId']);
                this.selectedCompliance = this.agGrid.api.getSelectedRows();
                this.deleteCompliance.call(this, { complianceList: inputData });
            },
            icon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash-alt',
                text: 'Bulk Delete'
            }
        });

        this.widgetRef.operationalActions.value.set('Operations', actions);

        this.widgetRef.operationalActions.next(
            this.widgetRef.operationalActions.value
        );
    }

    handleSelectionChange() {
        if (this.agGrid && this.widgetRef.lightState) {
            const limit = 1;
            if (this.agGrid.api.getSelectedNodes().length > limit) {
                // Select All case
                this.selectAll = !this.selectAll;
                if (this.selectAll) {
                    this.agGrid.api.deselectAll();
                    this.agGrid.api.forEachNode((node) =>
                        node.rowIndex >= limit ? 0 : node.setSelected(true)
                    );
                } else {
                    this.agGrid.api.deselectAll();
                }
                this.agGrid.api.redrawRows();
            } else if (this.agGrid.api.getSelectedNodes().length === limit) {
                this.widgetRef.notificationsService.showSnackBar(
                    `Maximum ${limit} row allowed`
                );
                this.agGrid.api.redrawRows();
            } else {
                this.selectAll = false;
            }
        }
        if (this.agGrid && this.agGrid.api.getSelectedRows().length > 0) {
            this.widgetRef.showNotificationBadge.next(true);
        } else {
            this.widgetRef.showNotificationBadge.next(false);
        }
    }

    getMoreInfo(
        buttonRef: IButtonGeneratorInput,
        isEdit: boolean,
        rowData: any
    ) {
        buttonRef.loader = true;
        const apiConfig =
            this.widgetRef.widgetData.widgetInfo['customCompliance'][
                'moreInfo'
            ];

        const apiArgs: IHitApi = Helper.generateHitApiConfig(apiConfig);

        apiArgs.intactUrl = apiArgs.url;
        apiArgs.url = apiArgs.url.replace(
            '{compliance-id}',
            rowData.data['complianceId']
        );

        apiArgs.input = {};
        apiArgs.function = (response) => {
            const widgetList = response[0]['widgetSelected']['widgetList'];
            if (isEdit) {
                rowData.data = { ...rowData.data, ...response[0] };
                this.openAddNewModal(widgetList, rowData.data);
            } else {
                this.openMoreInfoModal(widgetList);
            }
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while getting data.'
            );
        };
        apiArgs.endFunction = () => {
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
