import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'camelCaseToNormalName'
})
export class CamelCaseToNormalNamePipe implements PipeTransform {
    transform(value: string): string {
        value = value
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase());
        return value;
    }
}
