import { Injectable } from '@angular/core';
import { CacheMain } from 'src/app/shared/classes/CacheMain';
import { FilterStoreKey } from 'src/app/shared/enums/FilterStoreKey';

@Injectable({
    providedIn: 'root'
})
export class FilterCacheService extends CacheMain {
    static readonly SORTED_FILTERS_LIST = 'SORTED_FILTERS_LIST';
    static readonly DEFAULT_FILTERS = 'DEFAULT_FILTERS';
    static readonly PAGE_FILTERS = 'PAGE_FILTERS';
    static readonly FILTERS_PAGE_INFO = 'FILTERS_PAGE_INFO';

    constructor() {
        const FILTERS_CACHE_PREFIX = 'FILTERS_CACHE';
        super(FILTERS_CACHE_PREFIX, true);
    }

    set sortedFilters(filtersList: string[]) {
        this.store(FilterCacheService.SORTED_FILTERS_LIST, filtersList);
    }

    get sortedFilters() {
        const sortedFilters = this.fetch(
            FilterCacheService.SORTED_FILTERS_LIST
        );
        if (sortedFilters) {
            return sortedFilters;
        }
        return [];
    }

    storeFilterData(emailId, viewId, filterKey, filterValue) {
        let pageFilters = this.fetch(FilterCacheService.PAGE_FILTERS);
        if (!pageFilters) {
            pageFilters = {};
        }
        pageFilters[`${emailId}${viewId}${filterKey}`] = filterValue;
        this.store(FilterCacheService.PAGE_FILTERS, pageFilters);
    }

    getFilterData(emailId, viewId, filterKey) {
        const pageFilters = this.fetch(FilterCacheService.PAGE_FILTERS);
        if (!pageFilters || !pageFilters[`${emailId}${viewId}${filterKey}`]) {
            return null;
        }

        return pageFilters[`${emailId}${viewId}${filterKey}`];
    }

    setDefaultFilters(emailId: string, viewId: string, filtersValue: any) {
        let defaultFilters = this.fetch(FilterCacheService.DEFAULT_FILTERS);
        if (!defaultFilters) {
            defaultFilters = {};
        }
        defaultFilters[`${emailId}${viewId}`] = filtersValue;
        this.store(FilterCacheService.DEFAULT_FILTERS, defaultFilters);
    }

    getDefaultFilters(emailId: string, viewId: string) {
        const defaultFilters = this.fetch(FilterCacheService.DEFAULT_FILTERS);
        if (!defaultFilters || !defaultFilters[`${emailId}${viewId}`]) {
            return null;
        }

        return defaultFilters[`${emailId}${viewId}`];
    }

    setFiltersInfo(emailId: string, viewId: string, filtersValue: any) {
        let defaultFilters = this.fetch(FilterCacheService.FILTERS_PAGE_INFO);
        if (!defaultFilters) {
            defaultFilters = {};
        }
        defaultFilters[`${emailId}${viewId}`] = {
            ...defaultFilters[`${emailId}${viewId}`],
            ...filtersValue
        };
        this.store(FilterCacheService.FILTERS_PAGE_INFO, defaultFilters);
    }

    getFiltersInfo(
        emailId: string,
        viewId: string,
        filterStorKey: FilterStoreKey
    ) {
        const defaultFilters = this.fetch(FilterCacheService.FILTERS_PAGE_INFO);
        if (!defaultFilters || !defaultFilters[`${emailId}${viewId}`]) {
            return null;
        }

        return defaultFilters[`${emailId}${viewId}`][filterStorKey];
    }
}
