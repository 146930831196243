import { Component, OnInit } from '@angular/core';
import { ValueFormatterParams } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { GcpOnboardingModalComponent } from '../../../modal-templates/account-onboarding-modals/gcp-onboarding-modal/gcp-onboarding-modal.component';
import { IMultiButtonOption } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-gcp-listing',
    templateUrl: './gcp-listing.component.html',
    styleUrls: ['./gcp-listing.component.sass']
})
export class GcpListingComponent implements OnInit {
    tableGenInput: ITableGeneratorInput = null;
    widgetRef: Widget;
    constructor(
        widgetData: WidgetInjectedData,
        private notificationService: NotificationsService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }
    setUpBasics() {
        this.tableGenInput = {
            buttons: [
                {
                    buttonName: 'Add GCP Account',
                    buttonType: ButtonType.FLAT,
                    buttonColorType: ButtonColorType.PRIMARY,
                    function: (buttonRef: IButtonGeneratorInput) => {
                        this.openOnboardingModal();
                    }
                }
            ],
            listExtraction: {
                type: 'DIRECT'
            },
            noDataMessage: 'No Accounts Available',
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-user-plus'
            },
            columns: [
                {
                    columnKey: 'billingAccountId',
                    columnName: 'Billing Account ID'
                },
                {
                    columnKey: 'projectId',
                    columnName: 'Project ID'
                },
                {
                    columnKey: 'accountName',
                    columnName: 'Service Account'
                },
                {
                    columnKey: 'action',
                    columnName: 'Action',
                    pinned: 'right',
                    buttonGen: true,
                    width: 80,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: ValueFormatterParams) => {
                        const buttons: IButtonGeneratorInput[] = [
                            // EDIT BUTTON
                            {
                                buttonName: 'Edit',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.INFO,
                                function: () => {
                                    this.openOnboardingModal(rowData.data);
                                }
                            },
                            // DELETE BUTTON
                            {
                                buttonName: 'Delete',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.WARN,
                                showLoader: true,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    const modalData: IConfirmationModal = {
                                        modalName: 'Delete GCP Account',
                                        modalIcon: {
                                            type: IconType.FONTAWSOME,
                                            class: 'fas fa-trash'
                                        },
                                        contextIcon: {
                                            extraClass: 'color-accent',
                                            type: IconType.FONTAWSOME,
                                            class: 'fas fa-exclamation-triangle'
                                        },
                                        confirmationMessage: `Are you sure you want to delete the ${rowData.data.projectId} ${rowData.data.accountName}`,
                                        buttonText: 'Delete',
                                        buttonColorType:
                                            ButtonColorType.SECONDARY,
                                        loaderOnExec: true,
                                        function: (modalId: Symbol) => {
                                            const apiArgs =
                                                Helper.generateHitApiConfig(
                                                    this.widgetRef.widgetData
                                                        .widgetInfo['delete']
                                                );
                                            apiArgs.input = {};
                                            apiArgs.intactUrl = apiArgs.url;
                                            apiArgs.url = apiArgs.url.replace(
                                                '{id}',
                                                rowData.data.projectId
                                            );
                                            apiArgs.function = () => {
                                                this.widgetRef.notificationsService.showSnackBar(
                                                    `${rowData.data.projectId} Deleted Successfully`
                                                );
                                                this.widgetRef.refreshWidget();
                                                this.widgetRef.modalService.closeModal(
                                                    null,
                                                    modalId
                                                );
                                            };
                                            apiArgs.errorFunction = (error) => {
                                                this.widgetRef.modalService.closeModal(
                                                    null,
                                                    modalId
                                                );
                                                Helper.showErrorMessage(
                                                    this.notificationService,
                                                    error,
                                                    'Error perfoming action'
                                                );
                                            };

                                            new HitApi(
                                                apiArgs,
                                                this.widgetRef.httpService,
                                                this.widgetRef.ngZone
                                            ).hitApi();
                                        }
                                    };
                                    this.widgetRef.modalService.openConfirmationModal(
                                        modalData
                                    );
                                }
                            }
                        ];
                        const buttonOption: IMultiButtonOption = {
                            layout: {
                                justifyContent: 'space-evenly'
                            }
                        };
                        rowData['options'] = buttonOption;
                        rowData['buttonGenInputs'] = buttons;
                        return rowData;
                    }
                }
            ],
            afterResponse: null,
            selection: 'single'
        };
    }

    openOnboardingModal(rowData?: any) {
        const modalArgs: IModalData = {
            modalName: 'On Board GCP',
            sourceId: this.widgetRef.uniqueIdentity,
            modalType: ModalType.MIDDLE,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-chalkboard-teacher'
            },
            modalSteps: [
                {
                    stepName: 'GCP ONBOARDING',
                    stepData: {
                        componentToLoad: GcpOnboardingModalComponent,
                        payload: {
                            data: {
                                widgetInfo:
                                    this.widgetRef.widgetData.widgetInfo,
                                inputData: rowData
                            }
                        }
                    }
                }
            ],
            modalWidthVw: 60
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
}
