import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IIntegrationApiResponse } from 'src/app/shared/interfaces/api/portlets/IIntegrationApiResponse';
import { IIntegrationInput } from 'src/app/shared/interfaces/integration/IIntegrationInput';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { FormGeneratorModalComponent } from '../../../modal-templates/form-generator-modal/form-generator-modal.component';
import { SnsModalComponent } from '../../../modal-templates/sns-modal/sns-modal/sns-modal.component';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';
import { ModalAction } from './../../../../enums/ModalAction';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from './../../../../interfaces/hit-api/IHitApi';

@Component({
    selector: 'app-sns-integration',
    templateUrl: './sns-integration.component.html',
    styleUrls: ['./sns-integration.component.sass']
})
export class SnsIntegrationComponent implements OnInit {
    integrationInput: IIntegrationInput = null;
    widgetRef: Widget;
    isIntegrated = false;
    isParentIntegrated = false;
    isSelfIntegrated = false;
    isConfigured: Boolean = false;
    constructor(
        widgetData: WidgetInjectedData,
        private listHttpService: ListHttpService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }
    setUpBasics() {
        this.integrationInput = {
            integrationName: this.widgetRef.widgetData.widgetInfo.text,
            integrationCategory: 'Mail Integration',
            imageUrl: 'assets/integrations/sns.png',
            buttons: [],
            imagePadding: 8
        };
    }
    generateButtons(response) {
        const playFormGenInputs: IFormGeneratorInput = {
            formName: '',
            submitButton: {
                buttonName: 'Send',
                buttonType: ButtonType.RAISED,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: (
                    buttonRef: IButtonGeneratorInput,
                    formGroup: FormGroup,
                    modalId: Symbol
                ) => {
                    if (formGroup.valid) {
                        buttonRef.loader = true;
                        const apiConf: IHitApi = {
                            url: ApiUrls.SNS_SEND_MESSAGE,
                            input: formGroup.value,
                            requestType: RequestType.POST,
                            uniqueIdentity: Symbol(),
                            config: {
                                authorization: AuthorizationType.BEARER_TOKEN
                            },
                            function: (response) => {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'Message sent successfully'
                                );
                                buttonRef.loader = false;
                                this.widgetRef.modalService.closeModal(
                                    null,
                                    modalId
                                );
                            },
                            errorFunction: (error) => {
                                Helper.showErrorMessage(
                                    this.widgetRef.notificationsService,
                                    error,
                                    'Error while sending message'
                                );
                                buttonRef.loader = false;
                            }
                        };
                        new HitApi(
                            apiConf,
                            this.widgetRef.httpService,
                            this.widgetRef.ngZone
                        ).hitApi();
                    }
                }
            },
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Topics',
                    placeholder: 'Topics',
                    name: 'notify',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    apiInfo: this.listHttpService.snsListingApiInfo,
                    responseValueKey: 'newAccounts',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Topic is required'
                        }
                    ]
                },
                {
                    label: 'Message',
                    placeholder: 'Message',
                    name: 'message',
                    fieldType: FilterType.TEXTAREA
                }
            ]
        };
        if (this.isIntegrated) {
            // Adding play button
            this.integrationInput.buttons.push({
                buttonName: 'Test',
                buttonType: ButtonType.CIRCLE,
                buttonColorType: ButtonColorType.SUCCESS,
                buttonIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-play'
                },
                hoverText: 'Test SNS',
                function: () => {
                    const modalArgs: IModalData = {
                        modalName: 'Test SNS',
                        sourceId:
                            this.widgetRef.widgetData.widgetUniqueIdentifier,
                        modalType: ModalType.MIDDLE,
                        modalIcon: {
                            type: IconType.FONTAWSOME,
                            class: 'fas fa-play'
                        },
                        modalSteps: [
                            {
                                stepName: 'Integrate SNS',
                                stepData: {
                                    componentToLoad:
                                        FormGeneratorModalComponent,
                                    payload: {
                                        data: playFormGenInputs
                                    }
                                }
                            }
                        ],
                        modalWidthVw: 60,
                        modalHeightVh: 60
                    };
                    this.widgetRef.modalService.openModal(modalArgs);
                }
            });
            // Adding edit button
            this.integrationInput.buttons.push({
                buttonName: 'Edit',
                buttonIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-wrench'
                },

                buttonType: ButtonType.CIRCLE,
                buttonColorType: ButtonColorType.SUCCESS,
                hoverText: 'Edit SNS',
                function: () => {
                    const modalArgs: IModalData = {
                        modalName: 'Edit SNS',
                        sourceId:
                            this.widgetRef.widgetData.widgetUniqueIdentifier,
                        modalType: ModalType.MIDDLE,
                        modalIcon: {
                            type: IconType.FONTAWSOME,
                            class: 'fas fa-wrench'
                        },
                        modalSteps: [
                            {
                                stepName: 'Edit SNS',
                                stepData: {
                                    componentToLoad: SnsModalComponent,
                                    payload: {
                                        data: {
                                            widgetRef: this.widgetRef,
                                            purpose: ModalAction.UPDATE
                                        }
                                    }
                                }
                            }
                        ],
                        modalWidthVw: 70,
                        modalHeightVh: 90,
                        noBottomPadding: true
                    };
                    this.widgetRef.modalService.openModal(modalArgs);
                }
            });

            // Adding delete button
            this.integrationInput.buttons.push({
                buttonName: 'Delete',
                buttonIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-trash'
                },
                buttonType: ButtonType.CIRCLE,
                buttonColorType: ButtonColorType.WARN,

                hoverText: 'Remove SNS',
                function: (modalId: Symbol) => {
                    // Delete integration
                    const args = Helper.generateHitApiConfig(
                        this.widgetRef.widgetData.widgetInfo.delete
                    );
                    args.input = {};
                    args.function = (response) => {
                        this.widgetRef.notificationsService.showSnackBar(
                            `SNS integration removed successfully`
                        );
                        this.widgetRef.modalService.closeModal(null, modalId);
                        this.widgetRef.refreshWidget();
                    };
                    args.errorFunction = (error) => {
                        Helper.showErrorMessage(
                            this.widgetRef.notificationsService,
                            error,
                            'Error While Removing SNS'
                        );
                        this.widgetRef.modalService.closeModal(null, modalId);
                    };
                    new HitApi(
                        args,
                        this.widgetRef.httpService,
                        this.widgetRef.ngZone
                    ).hitApi();
                },
                executeFunctionAfterConfirmation: {
                    modalName: 'Remove SNS Integration',
                    modalIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'far fa-trash-alt'
                    },
                    contextIcon: {
                        extraClass: 'color-accent',
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-exclamation-triangle'
                    },
                    confirmationMessage: `Are you sure you want to remove SNS ?`,
                    buttonText: 'Remove',
                    buttonColorType: ButtonColorType.WARN,
                    loaderOnExec: true
                }
            });
        }
    }
    bindData(response: IIntegrationApiResponse): void {
        this.isConfigured = false;
        this.isIntegrated = response.integration;
        if (this.isIntegrated) {
            this.isConfigured = true;
        }
        this.integrationInput.buttons = [];
        this.generateButtons(response);
        this.widgetRef.endLoader();
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }

    onIntegrate(buttonRef: any) {
        const modalArgs: IModalData = {
            modalName: 'Integrate SNS',
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-envelope'
            },
            extraStepContainerClass: 'remove-form-space',
            modalSteps: [
                {
                    stepName: 'Integrate SNS',
                    resetModal: true,
                    stepData: {
                        componentToLoad: SnsModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                purpose: ModalAction.CREATE
                            }
                        }
                    }
                }
            ],
            modalWidthVw: 70,
            modalHeightVh: 90,
            noBottomPadding: true
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
}
