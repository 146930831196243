import { Component, OnInit } from '@angular/core';
import { GridOptions, RowEvent } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { ApiResponseType } from 'src/app/shared/interfaces/api/portlets/ApiResponse';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { IframeOpenerModalComponent } from '../../../modal-templates/iframe-opener-modal/iframe-opener-modal.component';

@Component({
    selector: 'app-zoho-listing',
    templateUrl: './zoho-listing.component.html',
    styleUrls: ['./zoho-listing.component.sass']
})
export class ZohoListingComponent implements OnInit {
    tableGenInput: ITableGeneratorInput = null;
    tableData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    widgetRef: Widget;
    widgetIcon: IIcon = {
        class: 'assets/integrations/freshdesk-mini-logo.svg',
        type: IconType.IMAGE
    };
    agGrid: GridOptions = null;
    appliedFilter = null;
    currentPage = 1;
    showBackButton = false;
    showNextButton = true;
    widgetCode = '';

    statusMap;

    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
        this.initializeStatusColors();
    }

    initializeStatusColors() {
        this.statusMap = {
            Open: {
                name: 'Open',
                color: Helper.convertToHexIfHsl(
                    Helper.getCssVarValue('negativeTicketColor')
                ),
                count: 0
            },
            Closed: {
                name: 'Closed',
                color: Helper.convertToHexIfHsl(
                    Helper.getCssVarValue('neutralTicketColor')
                ),
                count: 0
            },
            'Under Analysis': {
                name: 'Under Analysis',
                color: Helper.convertToHexIfHsl(
                    Helper.getCssVarValue('accentLightTicketColor')
                ),
                count: 0
            },
            'Developer Ticket': {
                name: 'Developer Ticket',
                color: Helper.convertToHexIfHsl(
                    Helper.getCssVarValue('accentTicketColor')
                ),
                count: 0
            },
            Escalated: {
                name: 'Escalated',
                color: Helper.convertToHexIfHsl(
                    Helper.getCssVarValue('postiveTicketColor')
                ),
                count: 0
            },
            'On Hold': {
                name: 'On Hold',
                color: Helper.convertToHexIfHsl(
                    Helper.getCssVarValue('violetTicketColor')
                ),
                count: 0
            },
            'In Progress': {
                name: 'In Progress',
                color: Helper.convertToHexIfHsl(
                    Helper.getCssVarValue('blueTicketColor')
                ),
                count: 0
            },
            Other: {
                name: 'Other',
                color: Helper.convertToHexIfHsl(
                    Helper.getCssVarValue('generalTicketColor')
                ),
                count: 0
            }
        };
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);

        this.tableGenInput = {
            buttons: [
                {
                    buttonName: 'Create Case',
                    buttonType: ButtonType.FLAT,
                    buttonColorType: ButtonColorType.PRIMARY,
                    function: (buttonRef: IButtonGeneratorInput) => {
                        this.openTestingModal(this.widgetCode);
                    },
                    showLoader: false
                }
            ],
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'dataList'
            },
            noDataMessage: 'No Tickets Available',
            widgetIconData: this.widgetIcon,
            columns: [
                {
                    columnKey: 'status',
                    columnName: '',
                    pinned: 'left',
                    cellRenderer: (rowData: RowEvent) => {
                        const status = rowData.data['status'];
                        const circleDiv = `
                            <div
                                style = "
                                    height: 14px;
                                    width: 14px;
                                    background: ${
                                        this.statusMap[status]
                                            ? this.statusMap[status].color
                                            : 'black'
                                    };
                                    border-radius: 100%;
                                "
                            >
                            </div>
                        `;
                        return circleDiv;
                    }
                },
                {
                    columnKey: 'requester',
                    columnName: 'Requester'
                },
                {
                    columnKey: 'subject',
                    columnName: 'Subject'
                },
                {
                    columnKey: 'closedTime',
                    columnName: 'Closed Time'
                },
                {
                    columnKey: 'creationTime',
                    columnName: 'Creation Time'
                },
                {
                    columnKey: 'priority',
                    columnName: 'Priority'
                },
                {
                    columnKey: 'status',
                    columnName: 'Status'
                },
                {
                    columnKey: 'severity',
                    columnName: 'Severity'
                }
            ],
            afterResponse: (response) => {
                this.overviewRender(response);
                this.widgetCode = response['widgetCode'];
            }
        };
    }

    overviewRender(response) {
        Object.keys(this.statusMap).forEach((status) => {
            this.statusMap[status]['count'] = 0;
        });
        response['dataList'].forEach((row) => {
            const status = row['status'];
            if (this.statusMap.hasOwnProperty(status)) {
                this.statusMap[status].count++;
            } else {
                this.statusMap['Other'].count++;
            }
        });
    }

    filterStatus(statusKey) {
        if (this.appliedFilter !== statusKey) {
            this.appliedFilter = statusKey;
            const filteredRows = this.widgetRef.apiResponse['dataList'].filter(
                (rowData) => rowData['status'] === this.appliedFilter
            );
            this.agGrid.api.setRowData(filteredRows);
            this.agGrid.api.refreshCells();
        } else {
            this.appliedFilter = null;
            this.agGrid.api.setRowData(this.widgetRef.apiResponse['dataList']);
            this.agGrid.api.refreshCells();
        }
    }

    openTestingModal(widgetCode) {
        const modalArgs: IModalData = {
            modalName: 'Create Ticket',
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            modalSteps: [
                {
                    stepName: 'Create Ticket',
                    stepData: {
                        componentToLoad: IframeOpenerModalComponent,
                        payload: {
                            data: {
                                widgetCode,
                                isHtml: true
                            }
                        }
                    }
                }
            ],
            modalWidthVw: 60,
            modalHeightVh: 60,
            closeCallback: this.handleOnClose.bind(this)
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }

    handleOnClose() {
        this.widgetRef.refreshWidget();
    }

    bindData(apiResponse: ApiResponseType) {
        this.tableData.next(apiResponse);
        this.overviewRender(apiResponse);
        if (apiResponse['dataList']) {
            if (!apiResponse['dataList'].length) {
                this.showBackButton = true;
                this.showNextButton = false;
            } else if (
                apiResponse['dataList'].length &&
                this.currentPage === 1
            ) {
                this.showBackButton = false;
                this.showNextButton = true;
            } else {
                this.showBackButton = true;
                this.showNextButton = true;
            }
        }
    }

    changePage(pageNumber, pageFlow) {
        const customInput = {
            pageNumber: pageNumber
        };
        if (pageFlow === 'back') {
            this.currentPage--;
        }
        if (pageFlow === 'next') {
            this.currentPage++;
        }
        this.widgetRef.isBindDataAssigned = false;
        this.widgetRef.setBindData(
            this.bindData.bind(this),
            true,
            customInput,
            this.setPageNumber.bind(this)
        );
    }

    setPageNumber() {
        return { pageNumber: this.currentPage };
    }
}
