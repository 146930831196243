<div
    *ngIf="!loader"
    class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-between tw-items-center"
>
    <ag-grid-angular
        #agGrid
        *ngIf="isTable"
        class="ag-theme-balham"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [animateRows]="true"
        [rowHeight]="37"
        [gridAutoHeight]="rowData.length > 3 ? 'false' : 'true'"
        (gridReady)="onGridReady($event)"
        [rowSelection]="'single'"
        [suppressFieldDotNotation]="true"
        [icons]="agGridIcons"
        [defaultColDef]="defaultColDef"
    >
    </ag-grid-angular>
    <div class="tw-w-full tw-flex tw-flex-col input-field" *ngIf="!isTable">
        <ng-container *ngFor="let item of fields">
            <div class="field" *ngIf="item['isVisible']">
                <div class="field-head">
                    {{ item.label
                    }}<span *ngIf="item?.compulsory" class="compulsory">*</span>
                </div>
                <div class="field-content" *ngIf="item">
                    <!-- Number field start -->
                    <ng-container *ngIf="item['filterType'] === 'number'">
                        <input
                            #numberField
                            type="number"
                            placeholder="{{ item['placeholder'] }}"
                            [(ngModel)]="inputData[item['selector']]"
                            (change)="
                                numberFieldChanged(
                                    item['selector'],
                                    $event.target.value,
                                    numberField,
                                    item['min']
                                )
                            "
                            min="item['min']"
                        />
                    </ng-container>
                    <!-- Number field end -->

                    <div
                        class="error"
                        *ngIf="!inputData[item['selector']] && nextBtnClicked"
                    >
                        <div class="error-message-custom">
                            <i class="fas fa-exclamation-triangle"></i>
                            <span>This field is mandatory.</span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="tw-w-full tw-flex tw-justify-end">
        <app-button-generator
            *ngIf="nextButton"
            [buttonGenInput]="nextButton"
        ></app-button-generator>
    </div>
</div>
<div class="overlay" *ngIf="loader">
    <div class="overlay-loader">
        <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </div>
</div>
