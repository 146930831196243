import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions, ValueGetterParams } from 'ag-grid-community';
import * as $ from 'jquery';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IAllocationChecksApiResponse } from 'src/app/shared/interfaces/api/portlets/IAllocationChecksApiResponse';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { AddResourceTagsComponent } from '../../../modal-templates/allocation-checks-modal/add-resource-tags/add-resource-tags.component';
import { ConventionsModalComponent } from '../../../modal-templates/allocation-checks-modal/conventions-modal/conventions-modal.component';
import { DeleteResourceTagsComponent } from '../../../modal-templates/allocation-checks-modal/delete-resource-tags/delete-resource-tags.component';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-allocation-checks',
    templateUrl: './allocation-checks.component.html',
    styleUrls: ['./allocation-checks.component.sass']
})
export class AllocationChecksComponent implements OnInit, AfterViewInit {
    // ALLOCATIONCHECKS: 1.0

    @ViewChild('agGrid', { static: false }) agGrid: GridOptions;
    ViewType = ViewType;
    widgetRef: Widget;
    gridApi;
    gridColumnApi;
    rowData;
    filteredSearchData;
    agGridIcons = Helper.getAgGridIcons();
    buttonInputs: IButtonGeneratorInput[];

    colDefs;
    showConvention = false;
    conventionLength: Number;
    severityButtons: IButtonGeneratorInput[];
    severityCount = {
        red: 0,
        yellow: 0,
        green: 0
    };
    conventionButton: IButtonGeneratorInput;
    defaultColDef = {
        editable: false,
        sortable: true,
        filter: true,
        resizable: true
    };
    selectAll = false;

    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(true);

        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);
        this.setUpRowClassRules();
        this.buttonInputs = [
            {
                buttonName: 'Add/Update Tags',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                function: () => {
                    this.addResourceTag();
                }
            },
            {
                buttonName: 'Delete Tags',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.WARN,
                function: () => {
                    this.deleteResourceTags();
                }
            }
        ];
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    setUpRowClassRules() {
        this.widgetRef.tableRowClassRules = {
            agRowMod: (params) => {
                if (
                    this.agGrid &&
                    this.agGrid.api.getSelectedNodes().length >=
                        GlobalConfiguration.RESOURCE_SELECTION_LIMIT
                ) {
                    return params.node.selected === false;
                }
                return false;
            }
        };
    }

    onSelectionChanged() {
        if (
            this.agGrid.api.getSelectedNodes().length >
            GlobalConfiguration.RESOURCE_SELECTION_LIMIT
        ) {
            // Select All case
            this.selectAll = !this.selectAll;
            if (this.selectAll) {
                this.agGrid.api.deselectAll();
                this.agGrid.api.forEachNode((node) =>
                    node.rowIndex >=
                    GlobalConfiguration.RESOURCE_SELECTION_LIMIT
                        ? 0
                        : node.setSelected(true)
                );
            } else {
                this.agGrid.api.deselectAll();
            }
            this.agGrid.api.redrawRows();
        } else if (
            this.agGrid.api.getSelectedNodes().length ===
            GlobalConfiguration.RESOURCE_SELECTION_LIMIT
        ) {
            this.widgetRef.notificationsService.showSnackBar(
                `Maximum ${GlobalConfiguration.RESOURCE_SELECTION_LIMIT} tags allowed`
            );
            this.agGrid.api.redrawRows();
        } else {
            this.selectAll = false;
        }
    }

    bindData(data: IAllocationChecksApiResponse): void {
        this.showConvention = false;
        if (!data) {
            return;
        }
        this.widgetRef.apiResponse = data;
        this.rowData = [];
        this.filteredSearchData = [];
        this.colDefs = [];

        if (
            (data.dataMap && !Object.keys(data.dataMap).length) ||
            (data.dataList && !data.dataList.length)
        ) {
            this.widgetRef.headerIcon.next({
                class: 'assets/green_check.png',
                type: IconType.IMAGE
            });
            this.widgetRef.extraMessage.next(Messages.NO_DATA_AVAILABLE);
            this.widgetRef.visibleSections.next(new Set());
            this.widgetRef.attentionRequired(data);
            this.widgetRef.endLoader();
            return;
        }

        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);

        this.rowData = data.dataList;
        this.filteredSearchData = this.rowData;
        this.conventionLength =
            data.dataMap['Conventions'] &&
            Object.keys(data.dataMap['Conventions']).length > 0
                ? Object.keys(data.dataMap['Conventions']).length
                : data.dataMap['conventions'] &&
                  Object.keys(data.dataMap['conventions']).length > 0
                ? Object.keys(data.dataMap['conventions']).length
                : null;

        if (this.conventionLength) {
            this.showConvention = true;
        }

        this.tableAndSeverityCountBinder(data);
        this.widgetRef.attentionRequired(data);
        this.initButtonGenInput();
        this.conventionButton = {
            buttonName: `Conventions : ${this.conventionLength}`,
            buttonType: ButtonType.FLAT,
            buttonColorType: ButtonColorType.SECONDARY,
            hoverText: 'Conventions',
            function: this.showConventions.bind(this)
        };
        this.widgetRef.endLoader();
    }

    initButtonGenInput() {
        const buttonData = {};
        let index = 0;

        const criticalButton: IButtonGeneratorInput = {
            buttonName: `Critical : ${this.severityCount.red}`,
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.WARN,
            hoverText: 'Critical',
            hoverEffect: 'shadow',
            function: (buttoRef: IButtonGeneratorInput) => {
                if (
                    Helper.changeButtonType(
                        buttonData,
                        this.severityButtons,
                        'Critical',
                        this.severityFilter.bind(this)
                    )
                ) {
                    this.severityFilter('Critical');
                }
            }
        };

        const warningButton: IButtonGeneratorInput = {
            buttonName: `Warning : ${this.severityCount.yellow}`,
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.PRIMARY,
            hoverText: 'Warning',
            hoverEffect: 'shadow',
            function: (buttoRef: IButtonGeneratorInput) => {
                if (
                    Helper.changeButtonType(
                        buttonData,
                        this.severityButtons,
                        'Warning',
                        this.severityFilter.bind(this)
                    )
                ) {
                    this.severityFilter('Warning');
                }
            }
        };

        const okButton: IButtonGeneratorInput = {
            buttonName: `OK : ${this.severityCount.green}`,
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.SUCCESS,
            hoverText: 'OK',
            hoverEffect: 'shadow',
            function: (buttoRef: IButtonGeneratorInput) => {
                if (
                    Helper.changeButtonType(
                        buttonData,
                        this.severityButtons,
                        'OK',
                        this.severityFilter.bind(this)
                    )
                ) {
                    this.severityFilter('OK');
                }
            }
        };

        this.severityButtons = [];

        if (this.severityCount.red) {
            this.severityButtons.push(criticalButton);
            buttonData['Critical'] = index++;
        }
        if (this.severityCount.yellow) {
            this.severityButtons.push(warningButton);
            buttonData['Warning'] = index++;
        }
        if (this.severityCount.green) {
            this.severityButtons.push(okButton);
            buttonData['OK'] = index++;
        }
    }

    tableAndSeverityCountBinder(response: IAllocationChecksApiResponse) {
        const keys = Object.keys(response.dataList[0]).filter((key) => {
            if (response.dataList[0][key] instanceof Array) {
                return false;
            }
            return true;
        });

        if (this.widgetRef.widgetData.automation) {
            this.colDefs.unshift({
                headerName: '',
                field: 'Automation',
                headerCheckboxSelection: true,
                checkboxSelection: true,
                editable: false,
                filter: false,
                pinned: 'left',
                lockPinned: true,
                maxWidth: 40
            });
        }

        keys.forEach((key) => {
            const obj = {
                headerName: key,
                field: key,
                editable: false
            };
            if (key === 'Severity') {
                obj['pinned'] = 'left';
                obj['lockPinned'] = true;
                obj['cellRenderer'] = this.columnRenderer;
            }
            this.colDefs.push(obj);
        });

        response['resourceTags'].forEach((resourceTagsKeys) => {
            const obj = {
                headerName: resourceTagsKeys,
                field: resourceTagsKeys,
                cellRenderer: this.tagsRenderer.bind(this),
                tooltipValueGetter: () => {
                    return 'Double tap to edit';
                },
                valueGetter: (rowData: ValueGetterParams): string => {
                    return String(this.tagsRenderer(rowData, true)).trim();
                }
            };
            this.colDefs.push(obj);
        });

        this.severityCount.red =
            'critical' in response.dataMap['severityCount']
                ? response.dataMap['severityCount']['critical']
                : null;
        this.severityCount.yellow =
            'warning' in response.dataMap['severityCount']
                ? response.dataMap['severityCount']['warning']
                : null;
        this.severityCount.green =
            'ok' in response.dataMap['severityCount']
                ? response.dataMap['severityCount']['ok']
                : null;
        const icon: IIcon = {
            class: `assets/${this.maxSeverityCount()}_check.png`,
            type: IconType.IMAGE
        };
        this.widgetRef.headerIcon.next(icon);
    }

    maxSeverityCount() {
        if (this.severityCount.red) {
            return 'red';
        }
        if (this.severityCount.yellow) {
            return 'yellow';
        } else return 'green';
    }

    tagsRenderer(item, isColValGetter?: boolean) {
        const deleteTagButton = document.createElement('button');
        deleteTagButton.innerHTML = `<i class="fas fa-times"></i>`;
        $(deleteTagButton).hover(function () {
            $(this).css({
                background: '0',
                color: Helper.getCssVarValue('accentColor')
            });
        });
        $(deleteTagButton).mouseleave(function () {
            $(this).css({
                background: '0',
                color: Helper.getCssVarValue('secondaryTextColor')
            });
        });
        $(deleteTagButton).css({
            border: '0',
            background: '0',
            color: Helper.getCssVarValue('secondaryTextColor'),
            padding: '5px',
            cursor: 'pointer',
            margin: '0 3px'
        });
        $(deleteTagButton).click((event) => {
            this.deleteTag(event, item);
        });
        const headerName = item.colDef.headerName;
        // Traversing Applied Tags
        for (
            let i = 0, appliedTags = item.data.appliedTags;
            i < appliedTags.length;
            ++i
        ) {
            if (appliedTags[i].split('|')[0].trim() === headerName) {
                const value = appliedTags[i].split('|')[1];
                if (isColValGetter) {
                    return value ? value : '';
                }
                const div = document.createElement('div');
                $(div).css({
                    color: Helper.getCssVarValue('secondaryTextColor'),
                    'text-align': 'center',
                    background: Helper.getCssVarValue('okSeverityColor'),
                    padding: '4px 8px'
                });
                // if (this.considerTokenization && this.insightState === this.insightStausObj.basic && item.rowIndex >= this.limit) {
                //   $(div).css({
                //     background: 'white',
                //     color: 'black'
                //   });
                //   $(div).addClass('agRowMod');
                // }
                $(div).append(value);
                // $(div).append(deleteTagButton);
                return div;
            }
        }
        // Traversing Extra Tags
        for (
            let i = 0, extraTags = item.data.extraTags;
            i < extraTags.length;
            ++i
        ) {
            if (extraTags[i].split('|')[0].trim() === headerName) {
                const value = extraTags[i].split('|')[1];
                if (isColValGetter) {
                    return value ? value : '';
                }
                const div = document.createElement('div');
                $(div).css({
                    color: Helper.getCssVarValue('secondaryTextColor'),
                    'text-align': 'center',
                    background: Helper.getCssVarValue('graySeverityColor')
                });
                // if (this.considerTokenization && this.insightState === this.insightStausObj.basic && item.rowIndex >= this.limit) {
                //   $(div).css({
                //     background: 'white',
                //     color: 'black'
                //   });
                //   $(div).addClass('agRowMod');
                // }
                $(div).append(value);
                // $(div).append(deleteTagButton);
                return div;
            }
        }
        // Traversing Missing Tags
        for (
            let i = 0, missingTags = item.data.missingTags;
            i < missingTags.length;
            ++i
        ) {
            if (missingTags[i].split('|')[0].trim() === headerName) {
                const value = missingTags[i].split('|')[1];
                if (isColValGetter) {
                    return value ? value : '';
                }
                const div = document.createElement('div');
                $(div).css({
                    color: Helper.getCssVarValue('secondaryTextColor'),
                    'text-align': 'center',
                    background: Helper.getCssVarValue('criticalSeverityColor')
                });
                // if (this.considerTokenization && this.insightState === this.insightStausObj.basic && item.rowIndex >= this.limit) {
                //   $(div).css({
                //     background: 'white',
                //     color: 'black'
                //   });
                //   $(div).addClass('agRowMod');
                // }
                $(div).append(value);
                // $(div).append(deleteTagButton);
                return div;
            }
        }
    }

    deleteTag(event, item) {
        event.stopPropagation();
        const inputData = {};
        inputData['resourceTaggingList'] = [
            this.mapToRequiredInputs(
                this.widgetRef.widgetData.automation.allocationMap.delete
                    .columnsToChoose,
                item.data
            )
        ];
        inputData['tagsToDelete'] = [item.colDef.field];
        const deleteApiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.delete
        );
        deleteApiArgs.input = inputData;
        deleteApiArgs.function = (response) => {
            // Tag deleted successfully
            const tagName = item.colDef.headerName;
            // Traversing Applied Tags
            item.data.appliedTags = item.data.appliedTags.filter(
                (tag) => !(tag.indexOf(tagName) >= 0)
            );
            // Traversing Extra Tags
            item.data.extraTags = item.data.appliedTags.filter(
                (tag) => !(tag.indexOf(tagName) >= 0)
            );
            // Traversing Missing Tags
            item.data.missingTags = item.data.appliedTags.filter(
                (tag) => !(tag.indexOf(tagName) >= 0)
            );
            this.agGrid.api.redrawRows();
        };
        deleteApiArgs.errorFunction = () => {
            this.widgetRef.notificationsService.showSnackBar(
                'Error while deleting tag. Try again later',
                true
            );
        };
        new HitApi(
            deleteApiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    columnRenderer(item) {
        if (item.value === 'critical') {
            return `<img src='assets/red_check.png' alt='red' class='severity-icon' style='max-width: 16px !important' >`;
        } else if (item.value === 'warning') {
            return `<img src='assets/yellow_check.png' alt='yellow' class='severity-icon' style='max-width: 16px !important' >`;
        } else {
            return `<img src='assets/green_check.png' alt='green' class='severity-icon' style='max-width: 16px !important' >`;
        }
    }

    severityFilter(severityType?: string) {
        let filteredSearch = this.rowData;

        if (severityType) {
            filteredSearch = filteredSearch.filter((item) => {
                if (
                    item['Severity'].toLowerCase() ===
                    severityType.toLowerCase()
                ) {
                    return item;
                }
            });
        }
        this.filteredSearchData = filteredSearch;
    }

    showConventions() {
        if (
            (this.widgetRef.apiResponse['dataMap']['Conventions'] &&
                Object.keys(
                    this.widgetRef.apiResponse['dataMap']['Conventions']
                ).length > 0) ||
            (this.widgetRef.apiResponse['dataMap']['conventions'] &&
                Object.keys(
                    this.widgetRef.apiResponse['dataMap']['conventions']
                ).length > 0)
        ) {
            const conventions = this.widgetRef.apiResponse['dataMap'][
                'Conventions'
            ]
                ? this.widgetRef.apiResponse['dataMap']['Conventions']
                : this.widgetRef.apiResponse['dataMap']['conventions'];
            const modalData: IModalData = {
                modalName: 'Conventions',
                modalIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-copyright'
                },
                modalType: ModalType.MINI_MODAL,
                sourceId: this.widgetRef.uniqueIdentity,
                modalSteps: [
                    {
                        stepData: {
                            componentToLoad: ConventionsModalComponent,
                            payload: {
                                data: conventions
                            }
                        },
                        stepName: 'Conventions'
                    }
                ]
            };
            this.modalService.openModal(modalData);
        } else {
            this.widgetRef.notificationsService.showSnackBar(
                Messages.NO_CONVENTIONS_FOUND,
                true
            );
        }
    }

    addResourceTag() {
        if (this.agGrid.api.getSelectedNodes().length === 0) {
            this.widgetRef.notificationsService.showSnackBar(
                'Select Atleast One Resource To Add/Update Resource Tag',
                true
            );
            return;
        }
        if (
            this.agGrid.api.getSelectedNodes().length >
            GlobalConfiguration.RESOURCE_SELECTION_LIMIT
        ) {
            this.widgetRef.notificationsService.showSnackBar(
                `You Can Select Maximum ${GlobalConfiguration.RESOURCE_SELECTION_LIMIT} Resources To Add/Updated Resource Tag`
            );
            return;
        }

        const inputDataForCreatingResourceTag = {};
        inputDataForCreatingResourceTag['resourceTaggingList'] = [];
        const columnChange =
            this.widgetRef.widgetData.automation.allocationMap.create
                .columnsToChoose;
        this.agGrid.api.getSelectedNodes().forEach((item) => {
            inputDataForCreatingResourceTag['resourceTaggingList'].push(
                this.mapToRequiredInputs(columnChange, item.data)
            );
        });

        const modalData: IModalData = {
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-tag'
            },
            modalName: 'Add/Update Resource Tags',
            modalType: ModalType.MINI_MODAL,
            sourceId: this.widgetRef.uniqueIdentity,
            modalSteps: [
                {
                    stepName: 'Add/Update Resource Tags',
                    stepData: {
                        componentToLoad: AddResourceTagsComponent,
                        payload: {
                            data: {
                                inputDataForCreatingResourceTag,
                                function:
                                    this.addResourceTagAndRefresh.bind(this)
                            }
                        }
                    }
                }
            ]
        };

        this.modalService.openModal(modalData);
    }

    mapToRequiredInputs(changeMap, data) {
        const obj = {};
        Object.keys(changeMap).forEach((item) => {
            obj[item] = data[changeMap[item]];
        });
        return obj;
    }

    addResourceTagAndRefresh(
        inputDataForCreatingResourceTag,
        addResourceTagJsonObjectData,
        buttonRef: IButtonGeneratorInput,
        modalId: Symbol
    ) {
        inputDataForCreatingResourceTag['tagsToApply'] =
            addResourceTagJsonObjectData;
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.create
        );
        apiArgs.input = inputDataForCreatingResourceTag;
        apiArgs.function = (response) => {
            if (
                response['generalException'].length > 0 ||
                response['insufficientPermission'].length > 0 ||
                response['invalidCredentials'].length > 0 ||
                response['noCredentialsDB'].length > 0
            ) {
                this.widgetRef.notificationsService.showSnackBar(
                    'Resource Tag(s) have been added/updated for account(s) with sufficient permission(s).'
                );
            } else {
                this.widgetRef.notificationsService.showSnackBar(
                    'Resource Tag(s) Added/Updated Successfully'
                );
            }
            this.widgetRef.refreshWidget();
            buttonRef.loader = false;
            this.modalService.closeModal(null, modalId);
        };
        apiArgs.errorFunction = () => {
            this.widgetRef.notificationsService.showSnackBar(
                'Error While Adding/Updating Resource Tag(s)',
                true
            );
            buttonRef.loader = false;
            this.modalService.closeModal(null, modalId);
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    editRows(data) {
        if (data.newValue.length === 0) {
            this.widgetRef.notificationsService.showSnackBar(
                `Value field can't be empty.`,
                true
            );
        }
        if (data.newValue.length > 0) {
            const headerName = data.colDef.headerName;
            // Traversing Applied Tags
            for (
                let i = 0, appliedTags = data.data.appliedTags;
                i < appliedTags.length;
                ++i
            ) {
                if (appliedTags[i].split('|')[0].trim() === headerName) {
                    appliedTags[i] = headerName + ' | ' + data.newValue;
                }
            }
            // Traversing Extra Tags
            for (
                let i = 0, extraTags = data.data.extraTags;
                i < extraTags.length;
                ++i
            ) {
                if (extraTags[i].split('|')[0].trim() === headerName) {
                    extraTags[i] = headerName + ' | ' + data.newValue;
                }
            }
            // Traversing Missing Tags
            for (
                let i = 0, missingTags = data.data.missingTags;
                i < missingTags.length;
                ++i
            ) {
                if (missingTags[i].split('|')[0].trim() === headerName) {
                    missingTags[i] = headerName + ' | ' + data.newValue;
                }
            }
            this.agGrid.api.redrawRows();
            const inputData = {};
            const accountsData = {};
            const columnChange =
                this.widgetRef.widgetData.automation.allocationMap.create
                    .columnsToChoose;
            Object.keys(columnChange).forEach((item) => {
                accountsData[item] = data.data[columnChange[item]];
            });

            inputData['resourceTaggingList'] = [accountsData];
            inputData['tagsToApply'] = {};
            inputData['tagsToApply'][data.column.colId] =
                data.data[data.column.colId];

            const apiArgs = Helper.generateHitApiConfig(
                this.widgetRef.widgetData.widgetInfo.update
            );
            apiArgs.function = (response) => {
                this.widgetRef.notificationsService.showSnackBar(
                    data.column.colId +
                        ' Resource Tag Updated Successfully. Kindly refresh the insight to reflect changes.'
                );
            };
            apiArgs.errorFunction = () => {
                this.widgetRef.notificationsService.showSnackBar(
                    'Error while editing resource tag. Refresh the insight to see accurate data',
                    true
                );
            };
            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }

    deleteResourceTags() {
        if (this.agGrid.api.getSelectedNodes().length === 0) {
            this.widgetRef.notificationsService.showSnackBar(
                'Select Atleast One Resource To Delete Resource Tag'
            );
            return;
        }
        if (
            this.agGrid.api.getSelectedNodes().length >
            GlobalConfiguration.RESOURCE_SELECTION_LIMIT
        ) {
            this.widgetRef.notificationsService.showSnackBar(
                `You Can Select Maximum ${GlobalConfiguration.RESOURCE_SELECTION_LIMIT} Resources To Delete Resource Tag`
            );
            return;
        }

        const inputDataFoDeletingResourceTag = {};
        inputDataFoDeletingResourceTag['resourceTaggingList'] = [];

        const columnChange =
            this.widgetRef.widgetData.automation.allocationMap.delete
                .columnsToChoose;
        this.agGrid.api.getSelectedNodes().forEach((item) => {
            inputDataFoDeletingResourceTag['resourceTaggingList'].push(
                this.mapToRequiredInputs(columnChange, item.data)
            );
        });
        const modalData: IModalData = {
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-tag'
            },
            modalName: 'Delete Resource Tags',
            modalType: ModalType.MINI_MODAL,
            sourceId: this.widgetRef.uniqueIdentity,
            modalSteps: [
                {
                    stepName: 'Delete Resource Tags',
                    stepData: {
                        componentToLoad: DeleteResourceTagsComponent,
                        payload: {
                            data: {
                                inputDataFoDeletingResourceTag,
                                resourceTagKeys:
                                    this.widgetRef.apiResponse['resourceTags'],
                                function:
                                    this.deleteResourceTagsAndRefresh.bind(this)
                            }
                        }
                    }
                }
            ],
            modalHeightVh: 30
        };

        this.modalService.openModal(modalData);
    }

    deleteResourceTagsAndRefresh(
        inputDataFoDeletingResourceTag,
        resourceTags,
        buttonRef: IButtonGeneratorInput,
        modalId: Symbol
    ) {
        const resourceTagsToDelete = resourceTags.map((resourceKey) =>
            resourceKey['id'] ? resourceKey['id'] : resourceKey
        );
        inputDataFoDeletingResourceTag['tagsToDelete'] = resourceTagsToDelete;
        const deleteApiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.delete
        );
        deleteApiArgs.input = inputDataFoDeletingResourceTag;
        deleteApiArgs.function = (response) => {
            if (
                response['generalException'].length > 0 ||
                response['insufficientPermission'].length > 0 ||
                response['invalidCredentials'].length > 0 ||
                response['noCredentialsDB'].length > 0
            ) {
                this.widgetRef.notificationsService.showSnackBar(
                    'Resource Tag(s) have been deleted for account(s) with sufficient permission(s).'
                );
            } else {
                this.widgetRef.notificationsService.showSnackBar(
                    'Resource Tag(s) Deleted Successfully'
                );
            }
            buttonRef.loader = false;

            this.modalService.closeModal(null, modalId);
            this.widgetRef.refreshWidget();
        };
        deleteApiArgs.errorFunction = () => {
            this.widgetRef.notificationsService.showSnackBar(
                'Error While Deleting Resource Tag(s)',
                true
            );
            buttonRef.loader = false;

            this.modalService.closeModal(null, modalId);
        };

        new HitApi(
            deleteApiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    onQuickFilterChanged(id: string) {
        this.agGrid.api.setQuickFilter(document.getElementById(id)['value']);
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
