import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { Helper } from './../../../../classes/Helper';
import { HitApi } from './../../../../classes/HitApi';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from './../../../../interfaces/hit-api/IHitApi';
import { DescriptionPreviewComponent } from './../../widget-modals/description-preview/description-preview.component';

@Component({
    selector: 'app-create-widget-step-one',
    templateUrl: './create-widget-step-one.component.html',
    styleUrls: ['./create-widget-step-one.component.sass']
})
export class CreateWidgetStepOneComponent implements OnInit {
    widgetRef: Widget = null;
    widgetData = null;
    edit: boolean = false;

    formGenInput: IFormGeneratorInput = null;
    formGroup: FormGroup = null;
    customFrequencyformGenInput: IFormGeneratorInput = null;
    customFrequencyformGroup: FormGroup = null;

    constructor(
        private modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService
    ) {}

    ngOnInit(): void {
        this.widgetRef = this.modalInputData.data.widgetRef;
        if (
            this.modalInputData &&
            this.modalInputData.data &&
            this.modalInputData.data.edit
        ) {
            this.edit = true;
            this.widgetData = this.modalInputData.data.widgetData;
        }

        const step1Data = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(1);

        this.formGenInput = {
            formName: 'Widget',
            state: FormState.CREATE,
            formId: Symbol(),
            submitButton: null,
            fields: [
                {
                    fieldType: FilterType.TEXT,
                    label: 'Widget Name',
                    name: 'widgetName',
                    placeholder: 'Widget Name',
                    required: true,
                    disabled: this.edit,
                    value: this.edit
                        ? this.widgetData['text']
                        : step1Data
                        ? step1Data.widgetName
                        : '',
                    prefixIcon: {
                        hoverText: null,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'short_text'
                        }
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Widget name is required'
                        }
                    ]
                },
                {
                    fieldType: FilterType.NUMBER,
                    label: 'Price',
                    name: 'price',
                    placeholder: 'Price',
                    value: this.edit
                        ? this.widgetData['extraInfo']['price']
                        : step1Data
                        ? step1Data.price
                        : '',
                    prefixIcon: {
                        hoverText: null,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'attach_money'
                        }
                    },
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Price is required'
                        }
                    ]
                },
                {
                    fieldType: FilterType.WYSIWYG_EDITOR,
                    label: 'Description',
                    name: 'description',
                    placeholder: 'Description',
                    value: step1Data
                        ? step1Data.description
                        : this.edit
                        ? 'Loading.....'
                        : // : DefaultValues.WIDGET_DESCRIPTION,
                          '',
                    required: true,
                    prefixIcon: {
                        hoverText: 'Click for preview',
                        iconData: {
                            type: IconType.MATICON,
                            class: 'preview'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            if (
                                formGroup.get(field.name).invalid ||
                                formGroup.get(field.name).value ===
                                    'Loading.....'
                            ) {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'Provide description first to see preview',
                                    true
                                );
                                return;
                            }
                            this.widgetRef.modalService.openModal({
                                modalIcon: {
                                    type: IconType.MATICON,
                                    class: 'preview'
                                },
                                modalName: 'Description Preview',
                                sourceId: Symbol(),
                                modalType: ModalType.MIDDLE,
                                modalSteps: [
                                    {
                                        stepName: 'Preview',
                                        stepData: {
                                            componentToLoad:
                                                DescriptionPreviewComponent,
                                            payload: {
                                                data: {
                                                    baseDescription: [
                                                        {
                                                            type: 'HTML',
                                                            heading:
                                                                'Description',
                                                            showHeading: false,
                                                            value: formGroup.get(
                                                                'description'
                                                            ).value
                                                        }
                                                    ]
                                                }
                                            }
                                        }
                                    }
                                ],
                                modalWidthVw: 50,
                                noHeader: true,
                                modalAutoHeight: true
                            });
                        }
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Description is required'
                        }
                    ]
                },
                {
                    fieldType: FilterType.EMAIL,
                    label: 'Email',
                    name: 'email',
                    placeholder: 'Email',
                    required: true,
                    value: this.edit
                        ? this.widgetData['extraInfo']['email']
                        : step1Data
                        ? step1Data.email
                        : '',
                    prefixIcon: {
                        hoverText: null,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'email'
                        }
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email is required'
                        },
                        {
                            validator: CustomValidators.email,
                            errorMessage: 'Invalid email'
                        }
                    ]
                },
                {
                    fieldType: FilterType.RADIO,
                    label: 'Frequency',
                    name: 'frequency',
                    placeholder: 'Frequency',
                    required: true,
                    value: this.edit
                        ? this.widgetData['extraInfo']['frequency']['type']
                        : step1Data
                        ? step1Data.frequency
                        : 'NO_FREQUENCY',
                    listData: [
                        {
                            id: 'NO_FREQUENCY',
                            label: 'No Frequency'
                        },
                        {
                            id: 'MONTHLY',
                            label: 'Monthly'
                        },
                        {
                            id: 'QUARTERLY',
                            label: 'Quarterly'
                        },
                        {
                            id: 'ANNUALLY',
                            label: 'Annually'
                        },
                        {
                            id: 'CUSTOM',
                            label: 'Custom Date'
                        }
                    ],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Frequency is required'
                        }
                    ]
                }
            ]
        };

        this.customFrequencyformGenInput = {
            formName: 'Custom Frequency',
            state: FormState.CREATE,
            formId: Symbol(),
            submitButton: null,
            fields: [
                {
                    label: 'Days',
                    fieldType: FilterType.NUMBER,
                    name: 'days',
                    placeholder: 'Days',
                    required: true,
                    value: this.edit
                        ? this.widgetData['extraInfo']['frequency']['days']
                        : step1Data
                        ? step1Data.days
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Days are required'
                        },
                        {
                            validator: CustomValidators.minValue(1),
                            errorMessage: 'Value of days must be greater than 1'
                        }
                    ]
                }
            ]
        };
        if (this.edit) {
            this.handleDescription(step1Data && step1Data.size ? false : true);
        }
    }

    handleDescription(check) {
        if (check) {
            const apiArgs: IHitApi = {
                uniqueIdentity: Symbol(),
                url: `${ApiUrls.DESCRIPTION_INFO_API_PATH}${this.widgetData['description']}`,
                intactUrl: `${ApiUrls.DESCRIPTION_INFO_API_PATH}{description}`,
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                },
                function: (response) => {
                    this.formGroup
                        .get('description')
                        .setValue(response['baseDescription'][0]['value']);
                },
                errorFunction: (error) => {
                    this.formGroup.get('description').setValue('');
                },
                requestType: RequestType.GET,
                input: {}
            };
            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }

    goNext() {
        if (this.formGroup && this.formGroup.invalid) {
            Helper.markAllFieldAsTouched(this.formGroup);
            return;
        }
        if (
            this.formGroup.get('frequency').value === 'CUSTOM' &&
            this.customFrequencyformGroup &&
            this.customFrequencyformGroup.invalid
        ) {
            Helper.markAllFieldAsTouched(this.customFrequencyformGroup);
            return;
        }
        let data = {
            ...this.formGroup.getRawValue()
        };
        if (this.customFrequencyformGroup) {
            data = {
                ...data,
                ...this.customFrequencyformGroup.value
            };
        }
        this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .set(1, data);
        this.multiStepFormService.nextStep(this.modalInputData.modalId);
    }
}
