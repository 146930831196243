import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { HitApi } from './../../../../classes/HitApi';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from './../../../../interfaces/hit-api/IHitApi';

@Component({
    selector: 'app-service-now-value-map-modal',
    templateUrl: './service-now-value-map-modal.component.html',
    styleUrls: ['./service-now-value-map-modal.component.sass']
})
export class ServiceNowValueMapModalComponent implements OnInit {
    templateData: any;
    widgetRef: Widget;
    isEdit: boolean = true;
    isPreview: any;
    widgetId: any;
    serviceNowField: any;
    valueMappingFormGenInput: IFormGeneratorInput = null;
    valueMappingFormGroupRef: FormGroup;
    callback: any;
    selectedField: any;
    buttonInput: IButtonGeneratorInput;
    constructor(private modalInputData: ModalInjectedData) {
        this.templateData = modalInputData.data['templateData'];
        this.widgetRef = modalInputData.data['widgetRef'];
        this.isEdit = modalInputData.data['isEdit'];
        this.isPreview = modalInputData.data['isPreview'];
        this.widgetId = modalInputData.data['widgetId'];
        this.serviceNowField = modalInputData.data['serviceNowField'];
        this.selectedField = modalInputData.data['selectedField'];
        this.callback = modalInputData.data['callback'];
        this.generateButton();
    }
    generateButton() {
        this.buttonInput = {
            buttonName: 'Save',
            buttonType: ButtonType.FLAT,
            buttonColorType: ButtonColorType.PRIMARY,
            function: this.save.bind(this)
        };
    }

    ngOnInit(): void {
        const generateValueMappingForm = (listData) => {
            this.valueMappingFormGenInput = {
                formName: 'Value Mapping Form',
                submitButton: null,
                state: FormState.CREATE,
                fields: this.selectedField.values.map((each, index) => {
                    return {
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        name: each.label,
                        placeholder: 'Select Value Mapping',
                        label: each.label,
                        required: true,
                        appearance: 'legacy',
                        listData,
                        value: each.id ? each.id : '',
                        showLabel: true,
                        validations: [
                            {
                                errorMessage: 'This field is required',
                                validator: CustomValidators.required
                            }
                        ]
                    };
                })
            };
        };

        if (
            this.templateData &&
            this.templateData.listData &&
            this.templateData.listData.length
        ) {
            generateValueMappingForm(this.templateData.listData);
        } else {
            const serviceNowApiInfo: IHitApi = Helper.generateHitApiConfig(
                this.templateData.serviceNowValueApi
            );

            serviceNowApiInfo.config.defaultHeaders = {
                'Content-type': 'application/json'
            };
            serviceNowApiInfo.input = null;
            serviceNowApiInfo.function = (response) => {
                generateValueMappingForm(response);
            };

            serviceNowApiInfo.errorFunction = (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    'Error while fetching service now fields.'
                );
            };

            new HitApi(
                serviceNowApiInfo,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }

    save() {
        if (this.valueMappingFormGroupRef.invalid) {
            this.valueMappingFormGroupRef.markAllAsTouched();
            return;
        }

        const values = Object.keys(this.valueMappingFormGroupRef.value);
        let mapping = [];

        if (values && values.length) {
            mapping = values.map((each) => {
                return {
                    id: this.valueMappingFormGroupRef.value[each],
                    label: each
                };
            });
        }

        this.callback(mapping, this.modalInputData.modalId);
    }
}
