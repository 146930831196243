<div class="overlay" *ngIf="!filtersFormGenInput">
    <div class="overlay-content">
        <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
    </div>
</div>

<div class="container" *ngIf="filtersFormGenInput">
    <div class="section">
        <div class="heading">
            <span>Global Whitelisting</span>
        </div>
        <div class="form">
            <app-form-generator
                [formGeneratorInput]="filtersFormGenInput"
                (formGroupRef)="filtersFormGroup = $event"
            >
            </app-form-generator>
        </div>
    </div>
    <div class="section">
        <div class="heading">
            <span>Color Scheme</span>
        </div>
        <div class="form">
            <app-form-generator
                [formGeneratorInput]="colorFormGenInput"
                (formGroupRef)="colorFormGroup = $event"
            >
            </app-form-generator>
        </div>
    </div>

    <div class="actions">
        <app-multi-button-generator
            [buttonGenInputs]="buttonGenInputs"
        ></app-multi-button-generator>
    </div>
</div>
