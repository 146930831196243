<form class="mt-2.5 form">
    <div class="form-data">
        <app-form-generator
            *ngIf="resourceFormGenInputs | async"
            [formGeneratorInput]="resourceFormGenInputs | async"
            (formGroupRef)="resourceFormGroup = $event"
        ></app-form-generator>
        <ng-container
            *ngIf="
                resourceFormGroup &&
                resourceFormGroup.get('jsonFile') &&
                resourceFormGroup.get('jsonFile').value === 'json'
            "
        >
            <ng-container *ngTemplateOutlet="templte"></ng-container>
        </ng-container>
        <ng-template #templte>
            <app-form-generator
                *ngIf="templateFormGenInputs"
                [formGeneratorInput]="templateFormGenInputs"
                (formGroupRef)="templateFormGroup = $event"
                (valuesChange)="depField($event)"
            ></app-form-generator>
        </ng-template>
        <app-form-generator
            *ngIf="templateBodyFormGenInputs"
            [formGeneratorInput]="templateBodyFormGenInputs"
            (formGroupRef)="templateBodyFormGroup = $event"
            (updateControl)="updateControl = $event"
        ></app-form-generator>
    </div>

    <div class="form-controls">
        <app-button-generator
            *ngIf="resetButton"
            [buttonGenInput]="resetButton"
        ></app-button-generator>
        <app-button-generator
            *ngIf="submitButton"
            [buttonGenInput]="submitButton"
        ></app-button-generator>
    </div>
</form>
