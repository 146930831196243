<div>
    <legend class="legend-container" *ngIf="dropdownLabel">
        {{ dropdownLabel }}
    </legend>
    <ng-select
        style="z-index: 10"
        [ngClass]="[
            filterInfo.lightMode ? 'light' : 'custom',
            dropdownLabel ? 'outline' : ''
        ]"
        [groupBy]="filterInfo.groupBy"
        [selectableGroup]="true"
        [selectableGroupAsModel]="false"
        [multiple]="true"
        [items]="filteredList"
        bindLabel="label"
        [bindValue]="filterInfo.getFullObj ? null : 'id'"
        [virtualScroll]="true"
        [multiple]="true"
        [closeOnSelect]="false"
        [loading]="showLoading"
        [clearable]="showLoading ? false : true"
        [placeholder]="
            filterInfo.placeholder &&
            (!selectedValues || (selectedValues && !selectedValues.length))
                ? filterInfo.placeholder
                : ''
        "
        (change)="selectionChange($event)"
        appendTo="body"
        (scroll)="onScroll($event)"
        [loadingText]="loadingMessage ? loadingMessage : 'Loading...'"
        dropdownPosition="auto"
        [clearable]="true"
        (scrollToEnd)="onScrollToEnd()"
        [ngModel]="selectedItems"
        (clear)="emitChange('clear')"
        (close)="emitChange('close'); isOpen = false"
        (open)="onOpenDropdown($event)"
        [disabled]="disabled"
        [class.disabled]="disabled"
    >
        <ng-template
            ng-multi-label-tmp
            let-items="selectedValues"
            let-clear="clear"
        >
            <div
                class="ng-value"
                *ngIf="selectedValues.length > 0"
                [style.display]="isOpen ? 'none' : 'block'"
            >
                <span class="ng-value-label"
                    >{{
                        showLoading
                            ? loadingMessage
                                ? loadingMessage
                                : "Loading..."
                            : selectedValues.length + " selected"
                    }}
                </span>
            </div>
        </ng-template>
        <ng-container
            *ngIf="showSelectAll && !showLoading && filteredList.length > 0"
        >
            <ng-template ng-header-tmp>
                <div
                    *ngIf="selectedValues.length === 0"
                    (click)="onSelectAll('selectAll')"
                >
                    <i class="far fa-square clickable"></i>
                    <span class="common-label">
                        <ng-container *ngIf="filterInfo.selectionLimit">
                            Select top
                            {{ filterInfo.selectionLimit }} items</ng-container
                        >
                        <ng-container *ngIf="!filterInfo.selectionLimit">
                            Select All</ng-container
                        >
                    </span>
                </div>
                <div
                    class="selected-item"
                    *ngIf="
                        selectedValues.length < filteredList.length &&
                        selectedValues.length !== 0
                    "
                    (click)="onSelectAll('unSelectAll')"
                >
                    <i class="fas fa-minus-square clickable"></i>
                    <span class="common-label">
                        <ng-container *ngIf="filterInfo.selectionLimit">
                            Select top
                            {{ filterInfo.selectionLimit }} items</ng-container
                        >
                        <ng-container *ngIf="!filterInfo.selectionLimit">
                            Select All</ng-container
                        >
                    </span>
                </div>
                <div
                    class="selected-item"
                    *ngIf="
                        filteredList.length !== 0 &&
                        selectedValues.length === filteredList.length
                    "
                    (click)="onSelectAll('unSelectAll')"
                >
                    <i class="fas fa-check-square clickable"></i
                    ><span class="common-label">
                        <ng-container *ngIf="filterInfo.selectionLimit">
                            Select top
                            {{ filterInfo.selectionLimit }} items</ng-container
                        >
                        <ng-container *ngIf="!filterInfo.selectionLimit">
                            Select All</ng-container
                        >
                    </span>
                </div>
            </ng-template>
        </ng-container>
        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$">
            <div *ngIf="!item$.selected">
                <i class="checkbox-dropdown far fa-square"></i>
                <span class="common-label">{{ item[filterInfo.groupBy] }}</span>
            </div>
            <div class="selected-item" *ngIf="item$.selected">
                <i class="checkbox-dropdown fas fa-check-square"></i
                ><span class="common-label">{{
                    item[filterInfo.groupBy]
                }}</span>
            </div>
        </ng-template>
        <ng-template
            ng-option-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
        >
            <div *ngIf="!item$.selected">
                <i class="checkbox-dropdown far fa-square"></i>
                <span class="common-label">{{ item.label }}</span>
            </div>
            <div class="selected-item" *ngIf="item$.selected">
                <i class="checkbox-dropdown fas fa-check-square"></i
                ><span class="common-label">{{ item.label }}</span>
            </div>
        </ng-template>
    </ng-select>

    <div class="dropdown-background-layer" *ngIf="isOpen"></div>
</div>
