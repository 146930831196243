<div class="wrapper">
    <div class="tw-w-3/5 tw-mx-auto">
        <app-form-generator
            *ngIf="formGenFirstInput"
            [formGeneratorInput]="formGenFirstInput"
            (formGroupRef)="formGroupFirstRef = $event"
            (valuesChange)="hitExportNameApi($event)"
            (updateControl)="updateControl = $event"
            (resetFormFunction)="resetFirstForm = $event"
        ></app-form-generator>
    </div>

    <div class="billing-section">
        <span class="details"
            ><span> Billing Details</span>

            <span
                class="info-icon"
                matTooltip="Billing ingestion will begin after adding these, although the associated widgets are still being developed. Therefore, data wont be visible right now."
                matTooltipPosition="right"
                appIcon
                [data]="infoIcon"
            ></span>
        </span>
        <hr />

        <mat-tab-group
            mat-stretch-tabs
            mat-align-tabs="center"
            (selectedTabChange)="changeDetailsTab($event)"
            [(selectedIndex)]="selectedIndexForDetailsTab"
            [disableRipple]="true"
        >
            <mat-tab [label]="DetailsTab.ONE_CLICK">
                <div class="tw-w-3/5 tw-mx-auto">
                    <app-form-generator
                        *ngIf="singleFormFieldInput"
                        [formGeneratorInput]="singleFormFieldInput"
                        (formGroupRef)="singleFormGroup = $event"
                        (resetFormFunction)="resetSingleFormInput = $event"
                        (updateControl)="singleFormUpdateControl = $event"
                    >
                    </app-form-generator>
                </div>
            </mat-tab>
            <mat-tab [label]="DetailsTab.MANUALLY">
                <div class="tw-w-3/5 tw-mx-auto manually-tab">
                    <app-form-generator
                        *ngIf="formGenSecondInput"
                        [formGeneratorInput]="formGenSecondInput"
                        (formGroupRef)="formGroupSecondRef = $event"
                        (resetFormFunction)="resetSecondForm = $event"
                    >
                    </app-form-generator>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
<div class="btn">
    <app-multi-button-generator
        [buttonGenInputs]="buttonGenInput"
        [options]="buttonOption"
    >
    </app-multi-button-generator>
</div>
