<app-table-generator
    *ngIf="tableGenInput"
    [tableInput]="tableGenInput"
    [widgetRef]="widgetRef"
    [overviewTemplateEnd]="overviewTemplateEnd"
    (gridRef)="agGrid = $event"
    [tableData]="tableData"
></app-table-generator>

<ng-template #overviewTemplateEnd>
    <div class="tw-flex tw-items-center overview" *ngIf="statusMap">
        <div
            class="
                tw-flex tw-items-center tw-cursor-pointer tw-ml-3
                circle-item
            "
            *ngFor="let status of statusMap | keyvalue"
            [matTooltip]="status.value.name"
            (click)="filterStatus(status.key)"
        >
            <span
                class="tw-rounded-full tw-mr-2 tw-w-4 tw-h-4 circle"
                [style.backgroundColor]="status.value.color"
            ></span>
            <span class="count">{{ status.value.count }}</span>
        </div>
    </div>
</ng-template>
<div
    *ngIf="totalPages > 1"
    class="
        tw-flex
        tw-justify-end
        tw-text-fs-200
        tw-items-center
        tw-font-semibold
        tw-h-10
        pagination
    "
>
    <div
        [style.visibility]="showBackButton ? 'visible' : 'hidden'"
        class="
            tw-font-bold
            tw-h-6
            tw-w-6
            tw-flex
            tw-items-center
            tw-justify-center
            tw-rounded-full
            clickable
            back-page
        "
        (click)="changePage(currentPage - 1, 'back')"
        matTooltip="Previous Page"
    >
        <i class="tw-text-fs-200 fas fa-chevron-left"></i>
    </div>

    <div
        [style.visibility]="showNextButton ? 'visible' : 'hidden'"
        class="
            tw-font-bold
            tw-h-6
            tw-w-6
            tw-flex
            tw-items-center
            tw-justify-center
            tw-rounded-full
            clickable
            back-page
        "
        (click)="changePage(currentPage + 1, 'next')"
        matTooltip="Next Page"
    >
        <i class="tw-text-fs-200 fas fa-chevron-right"></i>
    </div>
</div>
