<div class="form-area">
    <div
        [ngClass]="
            editFormName ? 'form-name edit-able' : 'form-name non-edit-able'
        "
    >
        <span *ngIf="!editFormName">
            {{ formName ? formName : "Request Form Name" }}
        </span>

        <app-form-generator
            *ngIf="editFormName"
            [formGeneratorInput]="titleFormInput"
            (formGroupRef)="titleFormGroupRef = $event"
        >
        </app-form-generator>

        <span
            appIcon
            [data]="pencilIcon"
            (click)="editFormName = true; initTitleForm()"
        ></span>
    </div>

    <div *ngIf="finalForm && finalForm.length" class="form-drop-area">
        <div class="each-field" *ngFor="let form of finalForm; index as i">
            <div class="forms d-flex fd-col">
                <app-form-generator
                    [formGeneratorInput]="form"
                    (updateControl)="form.updateControlSubject = $event"
                >
                </app-form-generator>
                <mat-error *ngIf="errorMessage.get(form.formId)">
                    {{ "*This field cannot be blank." }}
                </mat-error>
            </div>
            <div
                class="configure-box"
                *ngIf="
                    configureFormInput && activeConfigureField.get(form.formId)
                "
            >
                <div class="variable-name">
                    {{ activeConfigureFormName }}
                </div>
                <div class="form-heading">Variable Mapping</div>
                <app-form-generator
                    [formGeneratorInput]="configureFormInput"
                    (formGroupRef)="configureFormGroupRef = $event"
                    (valuesChange)="valueChange($event)"
                >
                </app-form-generator>
                <div
                    *ngIf="
                        variableFieldType &&
                        variableType !== variableFieldType.MAP &&
                        variableType !== variableFieldType.LIST_OBJECT &&
                        showField
                    "
                >
                    <ng-container *ngTemplateOutlet="multiValue"></ng-container>
                </div>
                <div
                    *ngIf="
                        variableFieldType &&
                        (variableType === variableFieldType.MAP ||
                            variableType === variableFieldType.LIST_OBJECT) &&
                        !showField
                    "
                >
                    <ng-container
                        *ngTemplateOutlet="objectFields"
                    ></ng-container>
                </div>
                <ng-container *ngTemplateOutlet="requiredField"></ng-container>
                <div class="configure-field-buttons">
                    <app-button-generator
                        [buttonGenInput]="saveButtonGenInput"
                        (buttonRef)="
                            processConfigurationForm(
                                activeConfigureFormIndex,
                                form.formId
                            )
                        "
                    ></app-button-generator>
                    <app-button-generator
                        [buttonGenInput]="cancelButtonGenInput"
                        (buttonRef)="
                            cancelConfigureForm(activeConfigureFormIndex)
                        "
                    ></app-button-generator>
                </div>
            </div>

            <div class="actions">
                <div
                    class="edit-icon"
                    appIcon
                    [data]="editIcon"
                    (click)="generateConfigurationForm(form, i)"
                ></div>
            </div>
        </div>
    </div>
    <div class="no-var-text" *ngIf="!finalForm || !finalForm.length">
        <span>No Variable Available To Map</span>
    </div>
</div>

<ng-template #objectFields>
    <ng-container
        *ngFor="
            let keyValuePair of resourceMap;
            let last = last;
            let index = index
        "
    >
        <div class="resource-name">
            <app-form-generator
                *ngIf="keyValuePair['resourceNameForm']"
                [class.pointer-null]="!last"
                [formGeneratorInput]="keyValuePair['resourceNameForm']"
                [intitalFormGroup]="
                    mapResourceNameFormGroup.has(
                        keyValuePair['resourceNameForm'].formId
                    )
                        ? mapResourceNameFormGroup.get(
                              keyValuePair['resourceNameForm'].formId
                          )
                        : null
                "
                (formGroupRef)="
                    mapResourceNameFormGroup.set(
                        keyValuePair['resourceNameForm'].formId,
                        $event
                    )
                "
            ></app-form-generator>
            <div
                class="plus-button clickable"
                *ngIf="last"
                (click)="
                    addForm(
                        keyValuePair['resourceNameForm']
                            ? keyValuePair['resourceNameForm'].formId
                            : null
                    )
                "
            >
                <span appIcon [data]="plusIcon"></span>
            </div>

            <div
                class="plus-button clickable"
                *ngIf="!last"
                (click)="
                    deleteValue(
                        keyValuePair['resourceNameForm']
                            ? keyValuePair['resourceNameForm'].formId
                            : null,
                        index
                    )
                "
            >
                <span appIcon [data]="minusIcon"></span>
            </div>
        </div>
        <div class="section-content">
            <ng-container
                *ngFor="
                    let subFormData of keyValuePair['resourceKeyForms'];
                    let last = last
                "
            >
                <div class="item">
                    <app-form-generator
                        [formGeneratorInput]="subFormData"
                        [intitalFormGroup]="
                            mapFormGroup.has(subFormData.formId)
                                ? mapFormGroup.get(subFormData.formId)
                                : null
                        "
                        (formGroupRef)="
                            mapFormGroup.set(subFormData.formId, $event)
                        "
                    ></app-form-generator>
                </div>
            </ng-container>
        </div>
    </ng-container>
</ng-template>
<ng-template #multiValue>
    <ng-container
        *ngFor="
            let keyValuePair of resourceMap;
            let last = last;
            let index = index
        "
    >
        <div class="resource-name">
            <app-form-generator
                [formGeneratorInput]="keyValuePair['resourceNameForm']"
                [intitalFormGroup]="
                    mapResourceNameFormGroup.has(
                        keyValuePair['resourceNameForm'].formId
                    )
                        ? mapResourceNameFormGroup.get(
                              keyValuePair['resourceNameForm'].formId
                          )
                        : null
                "
                (formGroupRef)="
                    mapResourceNameFormGroup.set(
                        keyValuePair['resourceNameForm'].formId,
                        $event
                    )
                "
                [class.pointer-null]="!last"
            ></app-form-generator>
            <div
                class="plus-button clickable"
                *ngIf="last"
                (click)="
                    addForm(
                        keyValuePair['resourceNameForm']
                            ? keyValuePair['resourceNameForm'].formId
                            : null
                    )
                "
            >
                <span appIcon [data]="plusIcon"></span>
            </div>

            <div
                class="plus-button clickable"
                *ngIf="!last"
                (click)="
                    deleteValue(
                        keyValuePair['resourceNameForm']
                            ? keyValuePair['resourceNameForm'].formId
                            : null,
                        index
                    )
                "
            >
                <span appIcon [data]="minusIcon"></span>
            </div>
        </div>
    </ng-container>
</ng-template>
<ng-template #requiredField>
    <div>
        <app-form-generator
            [formGeneratorInput]="requiredFieldFormGenInputs"
            (formGroupRef)="requiredFieldFormGroup = $event"
        ></app-form-generator>
    </div>
</ng-template>

<div class="action-button">
    <div class="back-button">
        <app-button-generator
            [buttonGenInput]="backButtonGenInput"
        ></app-button-generator>
    </div>
    <div class="buttons">
        <app-multi-button-generator [buttonGenInputs]="actionButtonGenInput">
        </app-multi-button-generator>
    </div>
</div>
