<app-table-generator
    *ngIf="tableGenInput"
    [tableInput]="tableGenInput"
    [widgetRef]="widgetRef"
    [overviewTemplateEnd]="overviewTemplateEnd"
    (gridRef)="agGrid = $event"
></app-table-generator>

<ng-template #overviewTemplateEnd>
    <div
        class="overview"
        *ngIf="statusMap"
        style="display: flex; align-items: center"
    >
        <div
            class="circle-item"
            *ngFor="let status of statusMap | keyvalue"
            style="
                display: flex;
                align-items: center;
                margin-left: 12px;
                cursor: pointer;
            "
            [matTooltip]="status.value.name"
            (click)="filterStatus(status.key)"
        >
            <span
                class="circle"
                [style.backgroundColor]="status.value.color"
                style="
                    width: 14px;
                    height: 14px;
                    border-radius: 100%;
                    margin-right: 8px;
                "
            ></span>
            <span class="count">{{ status.value.count }}</span>
        </div>
    </div>
</ng-template>
