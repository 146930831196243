import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'extractValueFromTag'
})
export class ExtractValueFromTagPipe implements PipeTransform {
    transform(value: string): string {
        if (value.includes('<')) {
            const div = document.createElement('div');
            div.innerHTML = value;
            return div.innerText;
        }
        return value;
    }
}
