<div class="widget-details-container tw-flex tw-flex-col">
    <div class="widget-name tw-flex">
        <ng-container *ngIf="!isEditingName || isEdit">
            <span
                class="tw-text-base tw-self-center tw-pl-4 tw-pr-5 tw-text-default"
                [ngClass]="isEdit ? 'tw-pt-3 tw-pb-3' : ''"
            >
                {{
                    inputData["widgetName"]
                        ? inputData["widgetName"]
                        : "Widget Name"
                }}
            </span>
        </ng-container>
        <ng-container *ngIf="isEditingName && titleFormInput">
            <app-form-generator
                class="tw-pt-2 tw-pb-2"
                [formGeneratorInput]="titleFormInput"
                (formGroupRef)="titleFormGroupRef = $event"
            >
            </app-form-generator>
        </ng-container>
        <span
            class="tw-cursor-pointer tw-text-lg tw-p-4 tw-text-accent"
            appIcon
            [data]="pencilIcon"
            (click)="isEditingName = true; this.initTitleForm()"
            *ngIf="!isEdit && !isEditingName"
        >
        </span>
    </div>
    <div class="tw-pt-2">
        <app-form-generator
            *ngIf="descriptionFormInput"
            [formGeneratorInput]="descriptionFormInput"
            (formGroupRef)="descriptionFormGroupRef = $event"
        >
        </app-form-generator>
    </div>
</div>
<div class="tw-h-12 tw-flex tw-items-end tw-justify-end">
    <app-multi-button-generator
        class="tw-w-auto"
        [buttonGenInputs]="buttonGenInputs"
    ></app-multi-button-generator>
</div>
