import {
    AfterContentInit,
    Component,
    ContentChildren,
    EventEmitter,
    OnInit,
    Output,
    QueryList
} from '@angular/core';
import { HorizontalScrollingTabDirective } from './../../directives/horizontal-scrolling-tab.directive';

@Component({
    selector: 'app-horizontal-scrolling-tab-group',
    templateUrl: './horizontal-scrolling-tab-group.component.html',
    styleUrls: ['./horizontal-scrolling-tab-group.component.sass'],
})
export class HorizontalScrollingTabGroupComponent
    implements OnInit, AfterContentInit
{
    @Output() onTabChange = new EventEmitter<string>();
    @ContentChildren(HorizontalScrollingTabDirective)
    tabContentList: QueryList<HorizontalScrollingTabDirective>;
    constructor() {}
    ngOnInit() {}
    ngAfterContentInit(): void {}

    getActiveTab(val: string) {
        this.onTabChange.emit(val);
    }
}
