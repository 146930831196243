import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { IWidgetViewInfo } from '../../interfaces/widget/IWidgetViewInfo';

@Injectable({
    providedIn: 'root'
})
export class CompareViewService {
    linkWidgetMode = false;
    intermediateLatest;
    isCompareViewEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);
    compareViewMode = new Subject<boolean>();
    hideDateonView = new Subject();
    updateViewDate = new Subject();
    nextWidgetView = new Subject();
    previousWidgetView = new Subject();
    currentLevel;
    viewInsightsManifest;
    linkingArray: Map<string, Array<IWidgetViewInfo>> = new Map();

    constructor() {}
}
