import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/shared/classes/ErrorStateMatcher';
import { Helper } from 'src/app/shared/classes/Helper';
import { DropdownListObjectFieldAppearance } from 'src/app/shared/enums/AppearanceType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';

@Component({
    selector: 'app-list-object-dropdown-field',
    templateUrl: './list-object-dropdown-field.component.html',
    styleUrls: ['./list-object-dropdown-field.component.sass'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ListObjectDropdownFieldComponent,
            multi: true
        }
    ]
})
export class ListObjectDropdownFieldComponent
    implements OnInit, ControlValueAccessor
{
    @Input() field: IFormField;
    @Input() formGeneratorInput: IFormGeneratorInput;
    @Input() formGroup: FormGroup;
    @Input() loading: Map<string, boolean>;
    @Input() compareWith: any;
    @Output() valueChange = new EventEmitter<any>();
    FormState = FormState;
    showOverlay = false;
    AppearanceType = DropdownListObjectFieldAppearance;
    Object = Object;
    onChange: any = () => {};
    onTouch: any = () => {};
    control: FormControl;
    matcher = new MyErrorStateMatcher();
    constructor() {}
    ngOnInit(): void {
        const existingAppearance = Object.values(this.AppearanceType);
        if (
            !existingAppearance.includes(
                this.field.appearance as DropdownListObjectFieldAppearance
            )
        ) {
            this.field.appearance = Helper.getFieldsAppearance(
                this.field,
                this.field.isFilter ? true : false
            );
        }
        this.control = this.findControl();
    }
    findControl(): FormControl {
        if (this.field.groupByKey) {
            const formControl = this.formGroup
                .get(this.field.groupByKey)
                .get(this.field.name);
            return formControl as FormControl;
        } else {
            return this.formGroup.get(this.field.name) as FormControl;
        }
    }
    writeValue(obj: any): void {}
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }
    setDisabledState?(isDisabled: boolean): void {}
    onValueChange(event) {
        this.valueChange.emit(event);
    }
}
