import { BehaviorSubject } from 'rxjs';
import { AuthorizationType } from '../enums/AuthorizationType';
import { RequestType } from '../enums/RequestType';
import { TraversalPurpose } from '../enums/TraversalPurpose';
import { IHitApi } from '../interfaces/hit-api/IHitApi';
import { HitApi } from './HitApi';

export class IconSideNavigation {
    constructor() {}

    static lastLevelHandle(
        event,
        item,
        sideNavigationData,
        firstLevelData,
        activeAttributes,
        selectedAttribues,
        selectedPathIds,
        leafNodesData,
        traversalPurpose,
        globalDataService
    ) {
        event.preventDefault();
        const leafNodeData: Map<number, any> = this.generateLeafNodeData(
            item,
            sideNavigationData,
            firstLevelData
        );

        if (this.differentLeafTraversed(leafNodeData)) {
            this.handleAttributeSelection(
                item['level'],
                item['id'],
                activeAttributes,
                true
            );
            this.updateSelectedAttributes(leafNodeData, selectedAttribues);
            this.handleSelectedPath(leafNodeData, selectedPathIds);
            leafNodesData.emit(leafNodeData);
            if (traversalPurpose === TraversalPurpose.SIDE_MENU) {
                globalDataService.pageSwitch.next({
                    leafNodeData,
                    switched: true
                });
            }
        }
    }

    static goNextLevel(
        item,
        currentLevel: BehaviorSubject<number>,
        sideNavigationData,
        activeAttributes,
        subMenuLoader,
        sideMenuCache,
        httpService,
        ngZone
    ) {
        this.handleAttributeSelection(
            item['level'],
            item['id'],
            activeAttributes,
            true
        );

        if (
            sideNavigationData.get(item['level'] + 1) &&
            sideNavigationData.get(item['level'] + 1).currentLevelName !==
                item.name
        ) {
            // First time initiating
            subMenuLoader.next(true);
            this.hitNextUrl(
                item['nextUrl'],
                sideMenuCache,
                httpService,
                ngZone,
                (response) => {
                    if (response) {
                        response.forEach(
                            (menuItem) => (menuItem['parentId'] = item['id'])
                        );
                        sideNavigationData.get(
                            item['level'] + 1
                        ).currentLevelName = item['name'];
                        sideNavigationData.get(
                            item['level'] + 1
                        ).currentLevelData = response;
                        sideNavigationData.get(
                            item['level'] + 1
                        ).currentLevelIconData = item;
                        subMenuLoader.next(false);
                        currentLevel.next(item['level'] + 1);
                    } else {
                        subMenuLoader.next(false);
                    }
                }
            );
        } else if (
            sideNavigationData.has(item['level']) &&
            sideNavigationData.get(item['level']).currentLevelData
        ) {
            // Drilling
            subMenuLoader.next(true);
            this.hitNextUrl(
                item['nextUrl'],
                sideMenuCache,
                httpService,
                ngZone,
                (response) => {
                    if (response) {
                        response.forEach(
                            (menuItem) => (menuItem['parentId'] = item['id'])
                        );
                        sideNavigationData.set(item['level'] + 1, {
                            previousLevelData: JSON.parse(
                                JSON.stringify(
                                    sideNavigationData.get(item['level'])
                                        .currentLevelData
                                )
                            ),
                            level: item['level'] + 1,
                            currentLevelData: response,
                            currentLevelName: item['name'],
                            currentLevelIconData: item
                        });
                        subMenuLoader.next(false);
                        currentLevel.next(item['level'] + 1);
                    } else {
                        subMenuLoader.next(false);
                    }
                }
            );
        } else {
            // Do Nothing
        }
    }

    static handleAttributeSelection(
        level: number,
        attributeId: string,
        activeAttributes,
        clearAdvanceLevels?
    ) {
        if (!activeAttributes.has(level)) {
            activeAttributes.set(level, []);
        }

        if (clearAdvanceLevels) {
            activeAttributes.set(level, []);

            let lev = level + 1;
            while (activeAttributes.has(lev)) {
                activeAttributes.delete(lev++);
            }
        }

        activeAttributes.get(level).push(attributeId);
    }

    static hitNextUrl(
        url: string,
        sideMenuCache,
        httpService,
        ngZone,
        callback
    ) {
        if (sideMenuCache.fetch(url)) {
            callback(sideMenuCache.fetch(url));
        } else {
            if (url.charAt(0) !== '/') {
                url = `/${url}`;
            }
            const args: IHitApi = {
                url,
                input: {},
                requestType: RequestType.GET,
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                },
                function: (response) => {
                    sideMenuCache.store(url, response);
                    callback(response);
                },
                errorFunction: (error) => {
                    callback(null);
                },
                uniqueIdentity: Symbol()
            };
            new HitApi(args, httpService, ngZone).hitApi();
        }
    }

    static goBackToLevel(
        level: number,
        currentLevel: BehaviorSubject<number>,
        sideNavigationData,
        activeAttributes: Map<number, string[]>
    ) {
        currentLevel.next(level);
        this.clearUnusedLevels(
            currentLevel,
            sideNavigationData,
            activeAttributes
        );
    }

    static clearUnusedLevels(
        currentLevel: BehaviorSubject<number>,
        sideNavigationData,
        activeAttributes: Map<number, string[]>
    ) {
        let level = currentLevel.getValue() + 1;
        while (sideNavigationData.has(level)) {
            sideNavigationData.delete(level++);
        }
        activeAttributes.clear();
    }

    static updateSelectedAttributes(
        leafNodesData: Map<number, any>,
        selectedAttribues: [string]
    ) {
        selectedAttribues.splice(0, selectedAttribues.length);
        for (const item of leafNodesData.values()) {
            selectedAttribues.push(item['id']);
        }
    }

    static generateLeafNodeData(
        item,
        sideNavigationData,
        firstLevelData
    ): Map<number, any> {
        const leafNodeData: Map<number, any> = new Map();
        let level = item['level'];
        let parentId = item['parentId'];
        while (level >= 1) {
            if (level === item['level']) {
                leafNodeData.set(level--, item);
            } else {
                leafNodeData.set(
                    level,
                    sideNavigationData
                        .get(level)
                        .currentLevelData.find(
                            (prevVal) => prevVal['id'] === parentId
                        )
                );
                parentId = leafNodeData.get(level)['parentId'];
                level -= 1;
            }
        }
        return leafNodeData;
    }

    static differentLeafTraversed(leafNodeData: Map<number, any>): boolean {
        let path = '';
        let level = 1;
        while (leafNodeData.has(level)) {
            path += `>>${leafNodeData.get(level++)['id']}`;
        }

        // if (selectedPath && selectedPath === path) {
        //     return false;
        // }

        // selectedPath = path;
        return true;
    }

    static handleSelectedPath(leafNodeData: Map<number, any>, selectedPathIds) {
        selectedPathIds.splice(0, selectedPathIds.length);
        for (const [key, value] of leafNodeData.entries()) {
            selectedPathIds.push(value['id']);
        }
    }
}
