export enum CloudLabel {
    AWS = 'AWS',
    AZURE_CSP = 'Azure CSP',
    AZURE_PLAN = 'Azure Plan',
    AZURE_EA = 'Azure EA',
    AZURE_SP = 'Azure Sponsorship',
    AZURE_PG = 'Pay As You Go',
    AZURE_MCA = 'Azure Mca',
    GCP = 'GCP'
}

export function getLogoPathOfCloudLabel(label: CloudLabel) {
    if (label === CloudLabel.AWS) {
        return 'assets/cloud-icons/aws-dark-logo.png';
    } else if (label === CloudLabel.AZURE_CSP) {
        return 'assets/cloud-icons/azure-csp-dark-logo.png';
    } else if (label === CloudLabel.AZURE_EA) {
        return 'assets/cloud-icons/azure-ea-dark-logo.png';
    } else if (label === CloudLabel.AZURE_PLAN) {
        return 'assets/cloud-icons/azure-plan-dark-logo.png';
    } else if (label === CloudLabel.AZURE_MCA) {
        return 'assets/cloud-icons/azure-mca-dark-logo.png';
    } else if (label === CloudLabel.GCP) {
        return 'assets/cloud-icons/google-cloud-logo.png';
    } else if (label === CloudLabel.AZURE_SP) {
        return 'assets/integrations/azure-sponsorship.png';
    } else if (label === CloudLabel.AZURE_PG) {
        return 'assets/integrations/azure-payg.png';
    }
}
