import { Component, OnInit } from '@angular/core';
import { RowEvent } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { SsoIntegrationStepOneComponent } from '../sso-integration-step-one/sso-integration-step-one.component';
import { SsoIntegrationStepTwoComponent } from '../sso-integration-step-two/sso-integration-step-two.component';
import { ButtonColorType } from './../../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../../enums/ButtonType';
import { MultiButtonGeneratorComponent } from './../../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-sso-integrations-table',
    templateUrl: './sso-integrations-table.component.html',
    styleUrls: ['./sso-integrations-table.component.sass']
})
export class SsoIntegrationsTableComponent implements OnInit {
    widgetRef: Widget;
    tableGenInput: ITableGeneratorInput = null;
    tableData = [];
    constructor(private modalInputData: ModalInjectedData) {
        this.tableData = modalInputData.data['response'];
        this.widgetRef = modalInputData.data['widgetRef'];
    }

    ngOnInit(): void {
        this.setUpBasics();
    }
    setUpBasics() {
        this.tableGenInput = {
            listExtraction: {
                type: 'DIRECT'
            },
            noDataMessage: 'No Integrations Available',
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-user-plus'
            },
            columns: [
                {
                    columnKey: 'configurationName',
                    columnName: 'Integrations'
                },
                {
                    columnKey: 'action',
                    columnName: 'Action',
                    pinned: 'right',
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: RowEvent) => {
                        rowData['buttonGenInputs'] = [
                            {
                                buttonName: 'Edit',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.INFO,
                                showLoader: false,
                                function: () => {
                                    const modalArgs: IModalData = {
                                        modalName: 'Edit SAML',
                                        sourceId:
                                            this.widgetRef.widgetData
                                                .widgetUniqueIdentifier,
                                        modalType: ModalType.MIDDLE,
                                        modalIcon: {
                                            type: IconType.FONTAWSOME,
                                            class: 'fas fa-wrench'
                                        },
                                        extraStepContainerClass:
                                            'remove-form-space',
                                        modalSteps: [
                                            {
                                                stepName: 'Integrate SAML',
                                                stepData: {
                                                    componentToLoad:
                                                        SsoIntegrationStepOneComponent,
                                                    payload: {
                                                        data: {
                                                            edit: true,
                                                            response:
                                                                rowData.data,
                                                            widgetRef:
                                                                this.widgetRef
                                                        }
                                                    }
                                                },
                                                resetModal: true
                                            },
                                            {
                                                stepName: 'Map Roles to Views',
                                                stepData: {
                                                    componentToLoad:
                                                        SsoIntegrationStepTwoComponent,
                                                    payload: {
                                                        data: {
                                                            edit: true,
                                                            response:
                                                                rowData.data,
                                                            widgetRef:
                                                                this.widgetRef
                                                        }
                                                    }
                                                },
                                                resetModal: true
                                            }
                                        ],
                                        modalWidthVw: 70,
                                        modalHeightVh: 90
                                    };
                                    this.widgetRef.modalService.openModal(
                                        modalArgs
                                    );
                                    this.widgetRef.modalService.closeModal(
                                        null,
                                        this.modalInputData.modalId
                                    );
                                }
                            },
                            // DELETE BUTTON
                            {
                                buttonName: 'Delete',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.WARN,
                                showLoader: true,
                                function: (modalId: Symbol) => {
                                    // Delete integration
                                    const args = Helper.generateHitApiConfig(
                                        this.widgetRef.widgetData.widgetInfo
                                            .delete
                                    );
                                    args.intactUrl = args.url;
                                    args.url = args.url.replace(
                                        '{saml-integration-id}',
                                        rowData.data['id']
                                    );
                                    args.input = {};
                                    args.function = (response) => {
                                        this.widgetRef.notificationsService.showSnackBar(
                                            `SAML integration removed successfully`
                                        );
                                        this.widgetRef.modalService.closeModal(
                                            null,
                                            modalId
                                        );
                                        this.widgetRef.refreshWidget();
                                    };
                                    args.errorFunction = (error) => {
                                        Helper.showErrorMessage(
                                            this.widgetRef.notificationsService,
                                            error,
                                            'Error removing SAML'
                                        );
                                        this.widgetRef.modalService.closeModal(
                                            null,
                                            modalId
                                        );
                                    };
                                    new HitApi(
                                        args,
                                        this.widgetRef.httpService,
                                        this.widgetRef.ngZone
                                    ).hitApi();
                                    this.widgetRef.modalService.closeModal(
                                        null,
                                        this.modalInputData.modalId
                                    );
                                },
                                executeFunctionAfterConfirmation: {
                                    modalName: 'Remove SAML Integration',
                                    modalIcon: {
                                        type: IconType.FONTAWSOME,
                                        class: 'far fa-trash-alt'
                                    },
                                    contextIcon: {
                                        extraClass: 'color-accent',
                                        type: IconType.FONTAWSOME,
                                        class: 'fas fa-exclamation-triangle'
                                    },
                                    confirmationMessage: `Are you sure you want to remove SAML ?`,
                                    buttonText: 'Remove',
                                    buttonColorType: ButtonColorType.WARN,
                                    loaderOnExec: true
                                }
                            }
                        ];
                        return rowData;
                    }
                }
            ],
            afterResponse: null,
            selection: 'single'
        };
    }
}
