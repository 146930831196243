import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GridOptions, RowEvent } from 'ag-grid-community';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { ConfirmationModalComponent } from '../../../confirmation-modal/confirmation-modal.component';
import { FormGeneratorModalComponent } from '../../../form-generator-modal/form-generator-modal.component';
import { ButtonType } from './../../../../../enums/ButtonType';
import { IButtonGeneratorInput } from './../../../../../interfaces/button-generator/IButtonGeneratorInput';
import { MultiButtonGeneratorComponent } from './../../../../multi-button-generator/multi-button-generator.component';
@Component({
    selector: 'app-nagios-integration-step-one',
    templateUrl: './nagios-integration-step-one.component.html',
    styleUrls: ['./nagios-integration-step-one.component.sass']
})
export class NagiosIntegrationStepOneComponent implements OnInit {
    nagiosFormgenInput: IFormGeneratorInput = null;
    nagiosFormGoup: FormGroup;
    tableInput: ITableGeneratorInput;
    tableData: any = [];
    widgetRef: Widget;
    nagiosIntegration = [];
    gridRef: GridOptions;
    selectedRowData = [];
    edit: boolean = false;
    loader: boolean = false;
    integration: boolean = false;
    freshIntegrations: any = [];
    parentData: any = [];
    selfData: any = [];
    nagiosUrl = new Map<string, string>();

    addNew: IButtonGeneratorInput = {
        buttonName: 'Add New Integration',
        buttonType: ButtonType.STROKED,
        buttonColorType: ButtonColorType.PRIMARY,
        hoverText: 'Add New Integration',
        buttonIcon: null,
        function: (buttonRef) => {
            this.addIntegration(buttonRef);
        },
        showLoader: true
    };

    deleteButton: IButtonGeneratorInput = {
        buttonName: 'Delete All',
        buttonColorType: ButtonColorType.WARN,
        buttonType: ButtonType.FLAT,
        function: this.deleteAllSelectedRow.bind(this)
    };
    nextButton: IButtonGeneratorInput = {
        buttonName: 'Save & Next',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        function: this.next.bind(this)
    };

    constructor(
        private httpService: HttpService,
        private ngZone: NgZone,
        private modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService
    ) {
        this.widgetRef = this.modalInputData.data['widgetRef'];
        if (
            this.modalInputData.data['response'] &&
            this.modalInputData.data['response']['parent'] &&
            this.modalInputData.data['response']['parent'].length
        ) {
            this.parentData = this.modalInputData.data['response']['parent'];
            this.parentData.forEach((data) => {
                data['isParent'] = true;
            });

            this.tableData = [...this.tableData, ...this.parentData];
        }
        if (
            this.modalInputData.data['response'] &&
            this.modalInputData.data['response']['self'] &&
            this.modalInputData.data['response']['self'].length
        ) {
            this.tableData = [
                ...this.tableData,
                ...this.modalInputData.data['response']['self']
            ];
            this.selfData = this.modalInputData.data['response']['self'];
        }

        if (this.modalInputData.data && this.modalInputData.data['edit']) {
            this.edit = true;
        }
        if (
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(1)
        ) {
            const stepData = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1);
            this.selfData = stepData['selfData'];
            this.parentData = stepData['parentData'];
            this.tableData = this.selfData;
            this.tableData = [...this.parentData, ...this.selfData];
            this.tableGenerator();
        }
        if (this.selfData.length) {
            this.selfData.forEach((data) => {
                this.nagiosUrl.set(data.integrationName, data.nagiosUrl);
            });
        }
    }

    ngOnInit(): void {
        this.nagiosFormgenInput = {
            state: FormState.CREATE,
            formName: 'New Nagios Integration',
            submitButton: null,
            fields: [
                {
                    name: 'integrationName',
                    fieldType: FilterType.TEXT,
                    label: 'Nagios Integration Name',
                    placeholder: 'Enter Nagios Integration Name',
                    required: true,
                    appearance: 'legacy',
                    showLabel: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Integration name is required'
                        }
                    ]
                },
                {
                    name: 'nagiosUrl',
                    fieldType: FilterType.TEXT,
                    label: 'Nagios API Url',
                    placeholder: 'Enter Nagios API Url',
                    required: true,
                    appearance: 'legacy',
                    showLabel: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'API url is required'
                        }
                    ]
                },
                {
                    name: 'apiKey',
                    fieldType: FilterType.TEXT,
                    label: 'Nagios API Key',
                    placeholder: 'Enter Nagios API Key',
                    required: true,
                    appearance: 'legacy',
                    showLabel: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'API key is required'
                        }
                    ]
                }
            ]
        };
        if (this.edit) {
            this.tableGenerator();
            this.nagiosIntegration = this.tableData;
        }
        this.setUpRowClassRules();
    }
    setUpRowClassRules() {
        this.widgetRef.tableRowClassRules = {
            agRowDisabled: (params) => {
                if (params.data.isParent) {
                    return true;
                } else {
                    return false;
                }
            }
        };
    }
    addIntegration(buttonRef?) {
        Helper.markAllFieldAsTouched(this.nagiosFormGoup);
        if (this.nagiosFormGoup.invalid) {
            this.nagiosFormGoup.updateValueAndValidity();
            return;
        } else {
            if (this.tableData && this.tableData.length) {
                this.checkIfNameExists(
                    this.nagiosFormGoup.value['integrationName'],
                    this.nagiosFormGoup.value['nagiosUrl'],
                    buttonRef
                );
            } else {
                this.nagiosIntegration.push(this.nagiosFormGoup.value);
                this.freshIntegrations.push(
                    this.nagiosFormGoup.get('integrationName').value
                );
                this.selfData.push(this.nagiosFormGoup.value);
                this.nagiosUrl.set(
                    this.nagiosFormGoup.get('integrationName').value,
                    this.nagiosFormGoup.get('nagiosUrl').value
                );
                this.nagiosFormGoup.reset();
                if (this.nagiosIntegration.length) {
                    this.integration = true;
                }
                this.save(buttonRef);
            }
        }
    }

    tableGenerator() {
        this.tableInput = {
            afterResponse: null,
            widgetIconData: null,
            tableAutoHeight: true,
            selection: 'multiple',
            listExtraction: {
                type: 'DIRECT'
            },
            columns: [
                {
                    columnName: 'Integration Name',
                    columnKey: 'integrationName'
                },
                {
                    columnName: 'API Key',
                    columnKey: 'apiKey',
                    editable: true,
                    cellRenderer: () => {
                        return '**** **** ***';
                    }
                },
                {
                    columnName: 'API Url',
                    columnKey: 'nagiosUrl',
                    editable: true
                },
                {
                    columnName: 'Action',
                    columnKey: 'Delete',
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: RowEvent) => {
                        rowData['buttonGenInputs'] = [
                            {
                                buttonName: 'Edit',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.INFO,
                                function: () => {
                                    const formFields: IFormField[] = [];
                                    const columnIds = Object.keys(rowData.data);
                                    columnIds.forEach((id) => {
                                        const colDef = rowData.columnApi
                                            .getColumn(id)
                                            .getColDef();
                                        formFields.push({
                                            name: colDef.field,
                                            label: colDef.headerName,
                                            placeholder: colDef.headerName,
                                            fieldType: FilterType.TEXT,
                                            disabled: !colDef.editable,
                                            showLabel: true,
                                            appearance: 'legacy',
                                            value: rowData.data[id],
                                            required: true,
                                            validations: [
                                                {
                                                    validator:
                                                        CustomValidators.required,
                                                    errorMessage: `${colDef.headerName} is required`
                                                }
                                            ]
                                        });
                                    });

                                    const editFormGenInput: IFormGeneratorInput =
                                        {
                                            formName: 'nagios-integration-edit',
                                            state: FormState.CREATE,
                                            submitButton: {
                                                buttonName: 'Update',
                                                buttonColorType:
                                                    ButtonColorType.PRIMARY,
                                                buttonType: ButtonType.STROKED,
                                                showLoader: true,
                                                function: (
                                                    buttonRef: IButtonGeneratorInput,
                                                    formGroup: FormGroup
                                                ) => {
                                                    const formGroupValueKeys =
                                                        Object.keys(
                                                            formGroup.value
                                                        );
                                                    const rowDataKeys =
                                                        Object.keys(
                                                            rowData.data
                                                        );
                                                    rowDataKeys.forEach(
                                                        (key) => {
                                                            if (
                                                                formGroupValueKeys.includes(
                                                                    key
                                                                )
                                                            ) {
                                                                rowData.node.setDataValue(
                                                                    key,
                                                                    formGroup
                                                                        .value[
                                                                        key
                                                                    ]
                                                                );
                                                            }
                                                        }
                                                    );
                                                    this.save(buttonRef);
                                                    this.widgetRef.modalService.closeModal(
                                                        null,
                                                        modalData.modalId
                                                    );
                                                }
                                            },
                                            fields: formFields,
                                            extraClass: 'add-scroll'
                                        };

                                    const modalData: IModalData = {
                                        modalName: 'Nagios Integration',
                                        modalIcon: null,
                                        modalType: ModalType.MIDDLE,
                                        sourceId: null,
                                        modalId: Symbol(),
                                        extraStepContainerClass:
                                            'remove-form-space',
                                        modalHeightVh: 50,
                                        modalWidthVw: 50,
                                        modalSteps: [
                                            {
                                                stepName: 'Nagios Integration',
                                                stepData: {
                                                    componentToLoad:
                                                        FormGeneratorModalComponent,
                                                    payload: {
                                                        data: editFormGenInput
                                                    }
                                                }
                                            }
                                        ]
                                    };
                                    this.widgetRef.modalService.openModal(
                                        modalData
                                    );
                                }
                            },
                            {
                                buttonName: 'Delete',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.WARN,
                                function: (buttonRef) => {
                                    const selectedRowData = [];
                                    selectedRowData.push(rowData.data);
                                    const confirmationModalData: IConfirmationModal =
                                        {
                                            function: (modalId) => {
                                                const apiConf: IHitApi =
                                                    Helper.generateHitApiConfig(
                                                        this.widgetRef
                                                            .widgetData
                                                            .widgetInfo.delete
                                                    );
                                                apiConf.input =
                                                    this.prepareDeleteInput(
                                                        rowData.data
                                                    );
                                                apiConf.function = () => {
                                                    this.widgetRef.notificationsService.showSnackBar(
                                                        'Integration Deleted Successfully.'
                                                    );

                                                    this.nagiosIntegration =
                                                        this.nagiosIntegration.filter(
                                                            (integration) => {
                                                                const index =
                                                                    selectedRowData.findIndex(
                                                                        (
                                                                            selectedRow
                                                                        ) =>
                                                                            integration.integrationName ===
                                                                                selectedRow.integrationName &&
                                                                            !integration.isParent
                                                                    );

                                                                if (
                                                                    index === -1
                                                                ) {
                                                                    return integration;
                                                                }
                                                            }
                                                        );
                                                    this.selfData =
                                                        this.selfData.filter(
                                                            (integration) => {
                                                                const index =
                                                                    selectedRowData.findIndex(
                                                                        (
                                                                            selectedRow
                                                                        ) =>
                                                                            integration.integrationName ===
                                                                            selectedRow.integrationName
                                                                    );
                                                                if (
                                                                    index === -1
                                                                ) {
                                                                    return integration;
                                                                }
                                                            }
                                                        );

                                                    this.widgetRef.refreshWidget();

                                                    this.widgetRef.modalService.closeModal(
                                                        null,
                                                        modalId
                                                    );
                                                    this.gridRef.api.setRowData(
                                                        this.nagiosIntegration
                                                    );
                                                };
                                                apiConf.errorFunction = (
                                                    error
                                                ) => {
                                                    Helper.showErrorMessage(
                                                        this.widgetRef
                                                            .notificationsService,
                                                        error,
                                                        'Error while deleting integration'
                                                    );
                                                    this.widgetRef.modalService.closeModal(
                                                        null,
                                                        modalId
                                                    );
                                                    this.widgetRef.refreshWidget();
                                                };
                                                new HitApi(
                                                    apiConf,
                                                    this.httpService,
                                                    this.ngZone
                                                ).hitApi();
                                            },

                                            modalName: 'Delete Integration',
                                            modalIcon: {
                                                type: IconType.FONTAWSOME,
                                                class: 'fas fa-trash'
                                            },
                                            contextIcon: {
                                                extraClass: 'color-accent',
                                                type: IconType.FONTAWSOME,
                                                class: 'fas fa-exclamation-triangle'
                                            },
                                            confirmationMessage: `Are you sure you want to delete the integration?`,
                                            buttonText: 'Delete',
                                            buttonColorType:
                                                ButtonColorType.WARN,
                                            loaderOnExec: true
                                        };
                                    const modalData: IModalData = {
                                        modalName:
                                            confirmationModalData.modalName,
                                        modalIcon:
                                            confirmationModalData.modalIcon,
                                        sourceId: Symbol(),
                                        modalType: ModalType.MIDDLE,
                                        modalHeightVh: 40,
                                        modalWidthVw: 50,
                                        modalSteps: [
                                            {
                                                stepData: {
                                                    componentToLoad:
                                                        ConfirmationModalComponent,
                                                    payload: {
                                                        data: {
                                                            function:
                                                                confirmationModalData.function,
                                                            params: confirmationModalData
                                                        }
                                                    }
                                                },
                                                stepName: 'Confirmation'
                                            }
                                        ]
                                    };
                                    this.widgetRef.modalService.openModal(
                                        modalData
                                    );
                                }
                            }
                        ];
                        return rowData;
                    }
                }
            ],
            stopEditingWhenGridLosesFocus: false
        };
        this.tableInput = Helper.cloneDeep(this.tableInput);
        this.widgetRef.changeDetectorRef.detectChanges();
    }

    checkIfNameExists(
        name: string,
        url: string,
        buttonRef?: IButtonGeneratorInput
    ): boolean {
        let isNameExist: boolean = false;
        const integrationNameIndex = this.selfData.findIndex(
            (integration) => integration.integrationName === name
        );
        const urlIndex = this.selfData.findIndex(
            (integration) => integration.nagiosUrl === url
        );
        if (integrationNameIndex > -1) {
            this.widgetRef.notificationsService.showSnackBar(
                'The name already exist. Please enter a new name.',
                true
            );
            isNameExist = true;
            return isNameExist;
        }
        if (urlIndex > -1) {
            this.widgetRef.notificationsService.showSnackBar(
                'The URL is not valid. Please enter a new one.',
                true
            );
            isNameExist = true;
            return isNameExist;
        } else {
            this.nagiosIntegration.push(this.nagiosFormGoup.value);
            this.freshIntegrations.push(
                this.nagiosFormGoup.get('integrationName').value
            );
            this.selfData.push(this.nagiosFormGoup.value);
            this.nagiosUrl.set(
                this.nagiosFormGoup.get('integrationName').value,
                this.nagiosFormGoup.get('nagiosUrl').value
            );
            this.selectedRowData = [];
            this.nagiosFormGoup.reset();
            if (this.nagiosIntegration.length) {
                this.integration = true;
            }
            this.save(buttonRef);
            isNameExist = false;
            return isNameExist;
        }
    }
    getSelectedRow() {
        this.selectedRowData = [];
        const selectedNodes = this.gridRef.api.getSelectedNodes();

        if (selectedNodes.length) {
            selectedNodes.forEach((selectedRow) => {
                this.selectedRowData.push(selectedRow.data);
            });
        } else {
            this.selectedRowData = [];
        }
    }
    deleteAllSelectedRow() {
        const confirmationModalData: IConfirmationModal = {
            function: (modalId) => {
                const apiConf: IHitApi = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo.delete
                );
                apiConf.input = this.prepareDeleteAllInputs();
                apiConf.function = () => {
                    this.widgetRef.notificationsService.showSnackBar(
                        'Integrations Deleted Successfully.'
                    );

                    this.nagiosIntegration = this.nagiosIntegration.filter(
                        (integration) => {
                            const index = this.selectedRowData.findIndex(
                                (selectedRow) =>
                                    integration.integrationName ===
                                        selectedRow.integrationName &&
                                    !integration.isParent
                            );
                            if (index === -1) {
                                return integration;
                            }
                        }
                    );
                    this.selfData = this.selfData.filter((integration) => {
                        const index = this.selectedRowData.findIndex(
                            (selectedRow) =>
                                integration.integrationName ===
                                selectedRow.integrationName
                        );
                        if (index === -1) {
                            return integration;
                        }
                    });
                    this.widgetRef.refreshWidget();

                    this.widgetRef.modalService.closeModal(null, modalId);
                    this.gridRef.api.setRowData(this.nagiosIntegration);
                    this.selectedRowData = [];
                };
                apiConf.errorFunction = (error) => {
                    Helper.showErrorMessage(
                        this.widgetRef.notificationsService,
                        error,
                        'Error while deleting integration'
                    );
                    this.widgetRef.modalService.closeModal(null, modalId);
                };
                new HitApi(apiConf, this.httpService, this.ngZone).hitApi();
            },

            modalName: 'Delete Integration',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            contextIcon: {
                extraClass: 'color-accent',
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclamation-triangle'
            },
            confirmationMessage: `Are you sure you want to delete the integrations?`,
            buttonText: 'Delete',
            buttonColorType: ButtonColorType.WARN,
            loaderOnExec: true
        };
        const modalData: IModalData = {
            modalName: confirmationModalData.modalName,
            modalIcon: confirmationModalData.modalIcon,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 40,
            modalWidthVw: 50,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ConfirmationModalComponent,
                        payload: {
                            data: {
                                function: confirmationModalData.function,
                                params: confirmationModalData
                            }
                        }
                    },
                    stepName: 'Confirmation'
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }
    prepareDeleteAllInputs() {
        const integration = [];
        this.selectedRowData.forEach((data) => {
            if (
                !this.freshIntegrations.includes(data['integrationName']) &&
                !data.isParent
            ) {
                integration.push(data['integrationName']);
            }
        });
        return { integrationName: integration };
    }
    prepareDeleteInput(data) {
        const integration = [];
        if (
            !this.freshIntegrations.includes(data['integrationName']) &&
            !data.isParent
        ) {
            integration.push(data['integrationName']);
        }
        return { integrationName: integration };
    }
    save(buttonRef?: IButtonGeneratorInput) {
        this.nagiosFormGoup.get('integrationName').markAsUntouched();
        if (!this.selfData.length) {
            this.widgetRef.notificationsService.showSnackBar(
                'Please create an integration first.',
                true
            );
            return;
        }
        if (this.loader) {
            return;
        }
        if (buttonRef.loader) {
            return;
        }
        if (this.edit && this.gridRef && this.gridRef.api) {
            this.gridRef.api.stopEditing();
        }

        this.loader = true;
        buttonRef.loader = true;
        const apiConf: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.create
        );
        apiConf.input = this.prepareInputs();
        apiConf.function = () => {
            this.tableInput = null;
            this.loader = false;
            buttonRef.loader = false;
            this.widgetRef.refreshWidget();
            this.tableData = this.prepareInputs()['integrationLicences'];
            this.tableGenerator();
            this.selfData = this.tableData;
            this.widgetRef.notificationsService.showSnackBar(
                'Integration is successfully done.'
            );
            if (this.gridRef) {
                this.integrationDone();
            }

            this.freshIntegrations = [];
        };
        apiConf.errorFunction = (error) => {
            this.loader = false;
            buttonRef.loader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while integrating'
            );
            this.widgetRef.modalService.closeModal(
                null,
                this.modalInputData.modalId
            );
            this.widgetRef.refreshWidget();
        };
        new HitApi(apiConf, this.httpService, this.ngZone).hitApi();
    }
    integrationDone() {
        this.nagiosIntegration = this.nagiosIntegration.filter((data) => {
            data.apiKey = '';
            return data;
        });
        this.gridRef.api.setRowData(this.nagiosIntegration);
    }
    next() {
        if (!this.selfData.length) {
            this.widgetRef.notificationsService.showSnackBar(
                'Please create an integration first.',
                true
            );
            return;
        }

        const stepData = {
            selfData: this.selfData,
            parentData: this.parentData
        };

        this.multiStepFormService.nextStep(this.modalInputData.modalId);
        this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .set(1, stepData);

        this.widgetRef.notificationsService.showSnackBar(
            'Integrations are successfully loaded.'
        );
    }
    prepareInputs() {
        this.selfData.forEach((integration) => {
            if (!integration.nagiosUrl) {
                integration.nagiosUrl = this.nagiosUrl.get(
                    integration.integrationName
                );
            }
        });
        const response = {
            integrationLicences: this.selfData
        };
        return response;
    }
}
