import { Component, OnDestroy, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IPieApiResponse } from 'src/app/shared/interfaces/api/portlets/IPieApiResponse';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IColumnData } from 'src/app/shared/interfaces/table-generator/IColumnData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { FilterCacheService } from 'src/app/shared/services/cache/filters-cache/filter-cache.service';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';


@Component({
    selector: 'app-ri-coverage',
    templateUrl: './ri-coverage.component.html',
    styleUrls: ['./ri-coverage.component.sass'],
})
export class RiCoverageComponent implements OnInit, OnDestroy {
    agGrid: GridOptions;
    ViewType = ViewType;
    widgetRef: Widget;
    rowData: any;
    colDefs: any;
    gridApi: any;
    potentialSavingsValue: any = this.loadingData();
    tableKeys: string[];
    defaultColDef = {
        editable: false,
        sortable: true,
        filter: true,
        resizable: true
    };
    getRowStyle: any;
    agGridIcons = Helper.getAgGridIcons();
    loadingRIData = new BehaviorSubject<string>(null);
    dataUpdated = new BehaviorSubject<boolean>(null);
    drillDownFilterObject = {};
    tempInputData = {};
    filterInfo: IFilterInfo = {} as IFilterInfo;
    arrowRightIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-chevron-right small-icon'
    };
    tableInputData: ITableGeneratorInput = {
        listExtraction: {
            type: 'DIRECT'
        },
        columns: [],
        tableHeight: 300
    };
    drillDownColor: any;
    filteredSearchData: any;
    serviceResponse: any;
    roundOff: number;
    preparedTableData: any = [];
    constructor(
        widgetData: WidgetInjectedData,
        public modalService: ModalService,
        private filterCacheService: FilterCacheService,
        private configCache: ConfigCacheService,
        private userDataCache: UserDataCacheService,
        private httpService: HttpService
    ) {
        this.widgetRef = widgetData.widgetRef;
        this.drillDownColor = Helper.convertToHexIfHsl(
            Helper.getCssVarValue('drillDownColor')
        );
    }

    ngOnInit(): void {
        this.setUpBasics();
        this.loadingRIData = new BehaviorSubject<string>(null);
        this.dataUpdated = new BehaviorSubject<boolean>(null);
        if (this.widgetRef.lightState) {
            this.setUpRowClassRules();
        }
    }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(true);
        this.widgetRef.showViewIcon.next(true);
        this.getRowStyle = this.rowStyling.bind(this);
        this.widgetRef.setBindData(this.bindData.bind(this));
    }

    getFullAccess() {
        Helper.getFullAccess(
            this.modalService,
            this.widgetRef.widgetData.widgetInfo.portlet.type
        );
    }

    setUpRowClassRules() {
        this.defaultColDef.sortable = false;
        this.defaultColDef.filter = false;
        Helper.setRowClassRules(this.widgetRef);
    }
    bindData(data: IPieApiResponse): void {
        if (!data) {
            return;
        }
        // Resetting the this.serviceResponse map on every widget refresh
        // otherwise it will hold the previous value.
        this.serviceResponse = new Map<string, Object>();
        this.preparedTableData = [];
        this.loadingRIData = new BehaviorSubject<string>(null);
        this.dataUpdated = new BehaviorSubject<boolean>(null);
        // Hitting the paralle apis for all the services that comes in datamap response.
        this.getParallelResponse(data);
        this.widgetRef.apiResponse = data;
        this.roundOff = this.widgetRef.listApiArgs.input['roundOff'];
        this.rowData = [];
        this.colDefs = [];
        if (
            (data.dataMap && !Object.keys(data.dataMap).length) ||
            (data.dataMap.table && data.dataMap.table.length === 0)
        ) {
            this.widgetRef.extraMessage.next(Messages.NO_DATA_AVAILABLE);
            this.widgetRef.visibleSections.next(new Set());
            this.widgetRef.widgetActions.next([]);
            this.widgetRef.loadingData.next(false);
            return;
        }
        this.widgetRef.visibleSections.next(new Set([ViewType.TABLE]));
        this.drawTable();
        this.widgetRef.endLoader();
    }

    drawTable() {
        this.colDefs = [];
        this.rowData = [];
        this.tableKeys = [];
        this.tableKeys.push(
            ...(this.widgetRef.apiResponse as IPieApiResponse).dataMap.tableKeys
        );
        this.tableKeys.forEach((row) => {
            let obj = {};
            obj = {
                headerName: row,
                field: row
            };

            this.colDefs.push(obj);
        });
        this.rowData = (
            this.widgetRef.apiResponse as IPieApiResponse
        ).dataMap.table;
        this.filteredSearchData = this.rowData;
        this.prepareTableData();
    }

    prepareTableData() {
        this.tableInputData.columns = [];
        this.colDefs.forEach((col) => {
            let columnObj: IColumnData = {
                columnName: col.headerName,
                columnKey: col.field,
                sortable: true
            };
            if (col.field === 'Potential RI Savings (Count)') {
                columnObj = {
                    // Assign the cell renderer function
                    cellRenderer: (params) => {
                        if (!params.data['Potential RI Savings (Count)']) {
                            return this.loadingData(); // Update the cell value here
                        } else {
                            return params.data['Potential RI Savings (Count)'];
                        }
                    },
                    ...columnObj
                };
            }
            this.tableInputData.columns.push(columnObj);
        });
        this.tableInputData.rowSelection = 'single';
        this.tableInputData.columns[0]['pinned'] = 'left';

        // Call the function here to set data to this.preparedTableData
        this.getRIPotentialSavings(this.rowData);

        this.dataUpdated.subscribe((data: boolean) => {
            if (data) {
                // Update the rowData variable with the new data
                this.rowData = [...new Set(this.preparedTableData)];
                
                // Set the updated rowData
                this.agGrid.api.setRowData(this.rowData);
                this.agGrid.api.redrawRows();
            }
        });
    }


    rowStyling(data) {
        if (
            this.widgetRef.widgetLinkingData &&
            this.widgetRef.widgetData &&
            this.widgetRef.widgetData.linking
        ) {
            
            if (this.widgetRef.widgetData.linking.drillDownType === 'column') {
                if (
                    data[`data`][this.tableKeys[0]] &&
                    data[`data`][this.tableKeys[0]].toLowerCase() === 'total'
                ) {
                    return { fontWeight: 'bold' };
                } else {
                    return {
                        cursor: 'pointer',
                        color: this.drillDownColor + ' !important'
                    };
                }
            } else if (
                this.widgetRef.widgetData.linking.drillDownType === 'row'
            ) {
                if (
                    this.widgetRef.widgetData.linking &&
                    this.widgetRef.widgetData.linking.legendRow &&
                    this.widgetRef.widgetData.linking.legendRow[
                        data[`data`][this.tableKeys[0]]
                    ]
                ) {
                    return {
                        cursor: 'pointer',
                        color: this.drillDownColor + ' !important'
                    };
                } else if (
                    data[`data`][this.tableKeys[0]] &&
                    data[`data`][this.tableKeys[0]].toLowerCase() === 'total'
                ) {
                    return { fontWeight: 'bold' };
                }
            }
        } else if (
            data[`data`][this.tableKeys[0]] &&
            data[`data`][this.tableKeys[0]].toLowerCase() === 'total'
        ) {
            return { fontWeight: 'bold' };
        }
        return '';
    }

    async tableRowClicked(selectedData, isTableRowClicked) {
        if (
            this.widgetRef.widgetLinkingData &&
            this.widgetRef.widgetData.linking &&
            (this.widgetRef.widgetData.linking.legendRow ||
                this.widgetRef.widgetData.linking.legendColumn)
        ) {
            let slice = '';
            if (isTableRowClicked) {
                slice = selectedData[this.tableKeys[0]];
            } else {
                slice = selectedData;
            }

            // this.prevDefaultPref = this.defaultPref;
            const tempObj = this.prepareNextWidgetData(
                slice,
                isTableRowClicked,
                selectedData
            );
            if (tempObj) {
                tempObj[`viewId`] = this.widgetRef.widgetLinkingData.viewId;
                tempObj[`widgetConfig`] = this.widgetRef.widgetConfigDetails;
                const currentLevel =
                    this.widgetRef.compareViewService.currentLevel[
                        this.widgetRef.widgetLinkingData.viewId
                    ] + 1;
                this.widgetRef.compareViewService.currentLevel[
                    this.widgetRef.widgetLinkingData.viewId
                ] = currentLevel;
                this.widgetRef.compareViewService.nextWidgetView.next(tempObj);
            }
        }
    }

    prepareNextWidgetData(slice, isTableRowClicked, selectedData) {
        let drilldownFilterObject;
        let widgetId;
        const drillDownFiltersData = {};
        const breadCrumbs = this.widgetRef.widgetLinkingData.breadCrumbs
            ? this.widgetRef.widgetLinkingData.breadCrumbs.slice()
            : [];
        const breadCrumbsData = this.widgetRef.widgetLinkingData.breadCrumbsData
            ? this.widgetRef.widgetLinkingData.breadCrumbsData.slice()
            : [];
        if (this.widgetRef.widgetData.apiResponse) {
            this.widgetRef.widgetData.apiResponse = null;
        }
        if (
            this.widgetRef.compareViewService.linkingArray.get(
                this.widgetRef.widgetLinkingData.viewId
            )[
                this.widgetRef.compareViewService.currentLevel[
                    this.widgetRef.widgetLinkingData.viewId
                ] - 1
            ]
        ) {
            const filterInput = this.filterCacheService.getFiltersInfo(
                this.userDataCache.emailId,
                this.configCache.viewId,
                this.widgetRef.filterStoreKey
            );
            this.widgetRef.compareViewService.linkingArray.get(
                this.widgetRef.widgetLinkingData.viewId
            )[
                this.widgetRef.compareViewService.currentLevel[
                    this.widgetRef.widgetLinkingData.viewId
                ] - 1
            ][`filters`] = Helper.cloneDeep(filterInput);
        }
        if (this.widgetRef.widgetData.linking.drillDownType === 'column') {
            if (!this.widgetRef.widgetData.linking.legendColumn[`filters`]) {
                return;
            }
            if (this.widgetRef.widgetData.linking.legendColumn[`widgetId`]) {
                drilldownFilterObject =
                    this.widgetRef.widgetData.linking.legendColumn[`filters`];
                widgetId =
                    this.widgetRef.widgetData.linking.legendColumn[`widgetId`];
            } else {
                return;
            }
        } else if (this.widgetRef.widgetData.linking.drillDownType === 'row') {
            if (this.widgetRef.widgetData.linking.legendRow[slice]) {
                if (
                    this.widgetRef.widgetData.linking.legendRow[slice][
                        `widgetId`
                    ]
                ) {
                    drilldownFilterObject = this.widgetRef.widgetData.linking
                        .legendRow[slice][`filters`]
                        ? this.widgetRef.widgetData.linking.legendRow[slice][
                              `filters`
                          ]
                        : {};
                    widgetId =
                        this.widgetRef.widgetData.linking.legendRow[slice][
                            `widgetId`
                        ];
                } else {
                    return;
                }
            } else {
                return;
            }
        } else {
            return;
        }

        const tempInputData = {};
        if (
            drilldownFilterObject &&
            Object.keys(drilldownFilterObject).length
        ) {
            Object.keys(drilldownFilterObject).forEach((key, index) => {
                const object = breadCrumbsData.find((row) => key in row);
                if (object) {
                    tempInputData[drilldownFilterObject[key]] = [object[key]];
                } else {
                    if (!isTableRowClicked) {
                        if (
                            key ===
                            (this.widgetRef.apiResponse as IPieApiResponse)
                                .dataMap.tableKeys[0]
                        ) {
                            tempInputData[drilldownFilterObject[key]] = [slice];
                            const tempObject = {};
                            tempObject[key] = slice;
                            breadCrumbsData.push(tempObject);
                        } else {
                            tempInputData[drilldownFilterObject[key]] = null;
                        }
                    } else {
                        const headerName = this.tableKeys.find(
                            (tableKey) => tableKey === key
                        );
                        if (headerName) {
                            tempInputData[drilldownFilterObject[key]] = [
                                selectedData[headerName],
                            ];
                            if (headerName === this.tableKeys[0]) {
                                const tempObject = {};
                                tempObject[key] = selectedData[headerName];
                                breadCrumbsData.push(tempObject);
                            }
                        } else {
                            tempInputData[drilldownFilterObject[key]] = null;
                        }
                    }
                }
                drillDownFiltersData[drilldownFilterObject[key]] = [
                    tempInputData[drilldownFilterObject[key]][0]
                ];
            });
        } else {
            const tempObject = {};
            const slicedKey = (this.widgetRef.apiResponse as IPieApiResponse)
                .dataMap.tableKeys[0];
            tempObject[slicedKey] = slice;
            breadCrumbsData.push(tempObject);
        }

        if (!isTableRowClicked) {
            breadCrumbs.push(slice);
        } else {
            if (this.widgetRef.widgetData.linking.titleKeys) {
                let title = '';
                this.widgetRef.widgetData.linking.titleKeys.forEach(
                    (element, index) => {
                        if (selectedData[element]) {
                            if (!index) {
                                title += selectedData[element];
                            } else {
                                title += '|' + selectedData[element];
                            }
                        }
                    }
                );

                breadCrumbs.push(title);
            } else {
                breadCrumbs.push(slice);
            }
        }

        return {
            widgetId,
            input: tempInputData,
            breadCrumbs,
            breadCrumbsData,
            filtersData: drillDownFiltersData
        };
    }

    goBackDrillDown(home?, index?, isLast?) {
        if (isLast) {
            return;
        }
        if (home) {
            this.widgetRef.compareViewService.currentLevel[
                this.widgetRef.widgetLinkingData.viewId
            ] = 1;
        } else {
            const currentLevel =
                this.widgetRef.compareViewService.currentLevel[
                    this.widgetRef.widgetLinkingData.viewId
                ] - index;
            this.widgetRef.compareViewService.currentLevel[
                this.widgetRef.widgetLinkingData.viewId
            ] = currentLevel;
        }

        const tempObj = {};
        tempObj[this.widgetRef.widgetLinkingData.viewId] = true;
        this.widgetRef.compareViewService.previousWidgetView.next(tempObj);
    }

    /**
    * Retrieves and calculates the potential RI savings for each row of data based on grouping conditions.
    * @param rowDataOverall An array of objects representing the row data.
    * @returns The potential savings value for each row.
    */
    getRIPotentialSavings(tableData: any) {
        const columnKey = 'Potential RI Savings (Count)';
        const riCovWidgetResponse = (this.widgetRef.apiResponse as IPieApiResponse).dataMap.table;
        tableData.forEach((rowData) => {
            const serviceName = rowData['Azure Services'];
            // Fetching Group By Filters.
            const groupBySubscriptionIdEnable = this.widgetRef.listApiArgs.input.
                groupingForSubscriptionIdAzurePlan;
            const groupByAzureRegionEnable = this.widgetRef.listApiArgs.input.
                groupingForRegionAzurePlan;

            // First Check If Group By Is not Selected.
            if (!groupBySubscriptionIdEnable && !groupByAzureRegionEnable) {
                if (rowData[columnKey] === undefined) {
                    // ServiceResponseMap it will keep all the data coresponding to the services.
                    let serviceResponseMap = new Map<string, string>();

                    // Subscribing the LoadingRIData Subject so that we know when the data
                    // retrieved from the response and Service Response map is prepared.
                    this.loadingRIData.subscribe((data) => {
                        if (data === 'notEnabled') {
                            if (
                                this.serviceResponse.size > 0 &&
                                this.serviceResponse.has(serviceName)
                            ) {
                                const serviceResponse =
                                    this.serviceResponse.get(serviceName);
                                if (
                                    serviceResponse &&
                                    serviceResponse.dataList.length > 0
                                ) {
                                    riCovWidgetResponse.forEach((riCovKey) => {
                                        let potentialSavingNew: number = 0;
                                        let instanceCount: number = 0;
                                        for (let i = 0; i < serviceResponse.dataList.length; i++) {
                                            if (riCovKey['Azure Services'] === serviceName) {
                                                potentialSavingNew += Number(
                                                    serviceResponse.dataList[i]['displayEntry']
                                                    ['Best Savings'].split('/')[0].split(' ')[1]
                                                );
                                                instanceCount += serviceResponse.dataList[i]['generalInformation']
                                                ['Instance Count to be Reserved'];
                                                // Preparing Plan Id response map which store all the response
                                                // corresponding to the Plan Id's
                                                serviceResponseMap.set(riCovKey['Azure Services'],
                                                    Helper.roundOff(potentialSavingNew, this.roundOff) +
                                                    '(' + instanceCount + ')'
                                                );
                                            }
                                        }
                                    });
                                    const service = rowData['Azure Services'];
                                    if (serviceResponseMap.has(service)) {
                                        this.potentialSavingsValue = serviceResponseMap.get(service);
                                    } else {
                                        this.potentialSavingsValue = '-';
                                    }
                                    rowData[columnKey] = this.potentialSavingsValue;
                                    this.preparedTableData.push(rowData);
                                } else {
                                    this.potentialSavingsValue = '-';
                                    rowData[columnKey] = this.potentialSavingsValue;
                                    this.preparedTableData.push(rowData);
                                }
                            } else {
                                this.potentialSavingsValue = 'N.A.';
                                rowData[columnKey] = this.potentialSavingsValue;
                                this.preparedTableData.push(rowData);
                            }
                        }
                        if (this.preparedTableData && this.preparedTableData.length > 0 &&
                            this.preparedTableData.length === riCovWidgetResponse.length) {
                            this.dataUpdated.next(true)
                        }
                    });
                }
            } else {
                // First Check If Plan ID Group By Is Selected.
                if (groupBySubscriptionIdEnable && !groupByAzureRegionEnable) {
                    if (rowData[columnKey] === undefined) {
                        let planIdResponseMap = new Map<string, string>();
                        this.loadingRIData.subscribe((planIdData) => {
                            if (planIdData === 'planId') {
                                if (
                                    this.serviceResponse.size > 0 &&
                                    this.serviceResponse.has(serviceName)
                                ) {
                                    const serviceResponse =
                                        this.serviceResponse.get(serviceName);
                                    if (
                                        serviceResponse &&
                                        serviceResponse.dataList.length > 0
                                    ) {
                                        riCovWidgetResponse.forEach((riCovKey) => {
                                            let potentialSavingNew: number = 0;
                                            let instanceCount: number = 0;
                                            for (let i = 0; i < serviceResponse.dataList.length; i++) {
                                                if (riCovKey['Azure Plan Id'] ===
                                                    serviceResponse.dataList[i]['displayEntry']
                                                    ['Subscription ID']
                                                ) {
                                                    potentialSavingNew += Number(
                                                        serviceResponse.dataList[i]['displayEntry']
                                                        ['Best Savings'].split('/')[0].split(' ')[1]
                                                    );
                                                    instanceCount += serviceResponse.dataList[i]['generalInformation']
                                                    ['Instance Count to be Reserved'];
                                                    // Preparing Plan Id response map which store all the response
                                                    // corresponding to the Plan Id's
                                                    planIdResponseMap.set(riCovKey['Azure Plan Id'],
                                                        Helper.roundOff(potentialSavingNew, this.roundOff) +
                                                        '(' + instanceCount + ')'
                                                    );
                                                }
                                            }
                                        });
                                        const planId = rowData['Azure Plan Id'];
                                        if (planIdResponseMap.has(planId)) {
                                            this.potentialSavingsValue = planIdResponseMap.get(planId);
                                        } else {
                                            this.potentialSavingsValue = '-';
                                        }
                                        rowData[columnKey] = this.potentialSavingsValue;
                                        this.preparedTableData.push(rowData);
                                    } else {
                                        this.potentialSavingsValue = '-';
                                        rowData[columnKey] = this.potentialSavingsValue;
                                        this.preparedTableData.push(rowData);
                                    }
                                } else {
                                    this.potentialSavingsValue = 'N.A.';
                                    rowData[columnKey] = this.potentialSavingsValue;
                                    this.preparedTableData.push(rowData);
                                }
                            }
                            if (this.preparedTableData && this.preparedTableData.length > 0 &&
                                this.preparedTableData.length === riCovWidgetResponse.length) {
                                this.dataUpdated.next(true);
                            }
                        });
                    }
                } else if (groupByAzureRegionEnable && !groupBySubscriptionIdEnable) {
                    // Second It Checks If Region Group By Is Selected.
                    if (rowData[columnKey] === undefined) {
                        let regionalResponeMap = new Map<string, string>();
                        this.loadingRIData.subscribe((regionData) => {
                            if (regionData === 'region') {
                                if (
                                    this.serviceResponse.size > 0 &&
                                    this.serviceResponse.has(serviceName)
                                ) {
                                    const serviceResponse =
                                        this.serviceResponse.get(serviceName);
                                    if (serviceResponse && serviceResponse.dataList.length > 0) {
                                        riCovWidgetResponse.forEach((riCovKey) => {
                                            let potentialSavingNew: number = 0;
                                            let instanceCount: number = 0;
                                            for (let i = 0; i < serviceResponse.dataList.length; i++) {
                                                if (
                                                    riCovKey['Region'] ===
                                                    serviceResponse.dataList[i]['displayEntry']['Region']
                                                ) {
                                                    potentialSavingNew += Number(serviceResponse.dataList[i]
                                                    ['displayEntry']['Best Savings']
                                                        .split('/')[0]
                                                        .split(' ')[1]
                                                    );
                                                    instanceCount +=
                                                        serviceResponse.dataList[i]
                                                        ['generalInformation']['Instance Count to be Reserved'];
                                                    // Preparing Region Wise response map which store all the response
                                                    // corresponding to the Regions.
                                                    regionalResponeMap.set(riCovKey['Region'],
                                                        Helper.roundOff(potentialSavingNew, this.roundOff) +
                                                        '(' + instanceCount + ')'
                                                    );
                                                }
                                            }
                                        });
                                        if (regionalResponeMap.size > 0) {
                                            if (regionalResponeMap.has(rowData['Region'])) {
                                                this.potentialSavingsValue = regionalResponeMap.get(rowData['Region']);
                                            } else {
                                                this.potentialSavingsValue = '-';
                                            }
                                        } else {
                                            this.potentialSavingsValue = '-';
                                        }
                                        rowData[columnKey] = this.potentialSavingsValue;
                                        this.preparedTableData.push(rowData);
                                    } else {
                                        this.potentialSavingsValue = '-';
                                        rowData[columnKey] = this.potentialSavingsValue;
                                        this.preparedTableData.push(rowData);
                                    }
                                } else {
                                    this.potentialSavingsValue = 'N.A.';
                                    rowData[columnKey] = this.potentialSavingsValue;
                                    this.preparedTableData.push(rowData);
                                }
                            }
                            if (this.preparedTableData && this.preparedTableData.length > 0 &&
                                this.preparedTableData.length === riCovWidgetResponse.length) {
                                this.dataUpdated.next(true)
                            }
                        });
                    }
                } else if (groupBySubscriptionIdEnable && groupByAzureRegionEnable) {
                    // If Both Plan ID and Region Group By are selected.
                    if (rowData[columnKey] === undefined) {
                        let planIdRegionalResponseMap = new Map<string, string>();
                        this.loadingRIData.subscribe((bothData: string) => {
                            if (bothData === 'bothEnabled') {
                                if (
                                    this.serviceResponse.size > 0 &&
                                    this.serviceResponse.has(serviceName)
                                ) {
                                    const serviceResponse =
                                        this.serviceResponse.get(serviceName);
                                    if (
                                        serviceResponse &&
                                        serviceResponse.dataList.length > 0
                                    ) {
                                        riCovWidgetResponse.forEach((riCovKey) => {
                                            let potentialSavingNew: number = 0;
                                            let instanceCount: number = 0;
                                            for (let i = 0; i < serviceResponse.dataList.length; i++) {
                                                if (riCovKey['Azure Plan Id'] ===
                                                    serviceResponse.dataList[i]
                                                    ['displayEntry']['Subscription ID'] &&
                                                    riCovKey['Region'] ===
                                                    serviceResponse.dataList[i]
                                                    ['displayEntry']['Region']
                                                ) {
                                                    potentialSavingNew += Number(
                                                        serviceResponse.dataList[i][
                                                            'displayEntry'
                                                        ]['Best Savings']
                                                            .split('/')[0]
                                                            .split(' ')[1]
                                                    );
                                                    instanceCount +=
                                                        serviceResponse.dataList[i][
                                                        'generalInformation'
                                                        ][
                                                        'Instance Count to be Reserved'
                                                        ];
                                                    // Preparing PlanId with Region Wise response map which store all the response
                                                    // corresponding to the Plan Id's corresponding with there Region.
                                                    // Plan Id | Region this will gives us unique key for the map.
                                                    planIdRegionalResponseMap.set(
                                                        riCovKey['Azure Plan Id'] +
                                                        '|' +
                                                        riCovKey['Region'],
                                                        Helper.roundOff(
                                                            potentialSavingNew,
                                                            this.roundOff
                                                        ) +
                                                        '(' +
                                                        instanceCount +
                                                        ')'
                                                    );
                                                }
                                            }
                                        });
                                        if (planIdRegionalResponseMap.size > 0) {
                                            const key = rowData['Azure Plan Id'] + '|' + rowData['Region'];

                                            if (planIdRegionalResponseMap.has(key)) {
                                                this.potentialSavingsValue = planIdRegionalResponseMap.get(key);
                                            } else {
                                                this.potentialSavingsValue = '-';
                                            }
                                        } else {
                                            this.potentialSavingsValue = '-';
                                        }
                                        rowData[columnKey] = this.potentialSavingsValue;
                                        this.preparedTableData.push(rowData);

                                    } else {
                                        // This will run if we don't have any data List or No Response corresponding
                                        // to the service API.
                                        this.potentialSavingsValue = '-';
                                        rowData[columnKey] = this.potentialSavingsValue;
                                        this.preparedTableData.push(rowData);
                                    }
                                } else {
                                    // This will run if we don't have any api corresponding to the service name.
                                    this.potentialSavingsValue = 'N.A.';
                                    rowData[columnKey] = this.potentialSavingsValue;
                                    this.preparedTableData.push(rowData);
                                }
                            }
                            if (this.preparedTableData && this.preparedTableData.length > 0 &&
                                this.preparedTableData.length === riCovWidgetResponse.length) {
                                this.dataUpdated.next(true)
                            }
                        });
                    }
                }
            }
        });
    }

    // Display loading message
    loadingData() {
        const div = document.createElement('div');
        div.innerHTML = 'Loading';
        div.classList.add('loading');
        return div;
    }
    /**
     * Checks wheather the received api info is valid or not.
     * @param apiInfo api information fetched from the datamap.
     * @returns boolean value indicating whether the api info is valid or not.
     */
    isApiInfo(apiInfo: any) {
        if (
            'apiRouteSuffix' in apiInfo &&
            'requestType' in apiInfo &&
            'host' in apiInfo &&
            'authorization' in apiInfo
        ) {
            return true;
        }
        return false;
    }

    /**
    * Retrieves parallel responses from service APIs and handles the data loading process.
    * @param data - The input data containing service APIs and configuration.
    */
    getParallelResponse(data: any) {
        const {
            groupingForSubscriptionIdAzurePlan,
            groupingForRegionAzurePlan,
        } = this.widgetRef.listApiArgs.input;

        this.loadingRIData.next(null);

        const serviceApis = (data as IPieApiResponse).dataMap.serviceApis;
        const parallelApis: IHitApi[] = [];

        for (const [serviceName, apiInfo] of Object.entries(serviceApis)) {
            let apiArgs;

            if (apiInfo && this.isApiInfo(apiInfo)) {
                // Generate API configuration for hitting the service API
                apiArgs = Helper.generateHitApiConfig(apiInfo);
                apiArgs.input = { ...this.widgetRef.listApiArgs.input };
                
                // Define the function to be executed on successful API response
                apiArgs.function = (apiResponse) => {
                    // Store the API response for the corresponding service name
                    this.serviceResponse.set(serviceName, apiResponse);

                    // Emit the appropriate loadingRIData value based on the grouping configurations
                    if (
                        groupingForSubscriptionIdAzurePlan &&
                        !groupingForRegionAzurePlan
                    ) {
                        this.loadingRIData.next('planId');
                    } else if (
                        !groupingForSubscriptionIdAzurePlan &&
                        groupingForRegionAzurePlan
                    ) {
                        this.loadingRIData.next('region');
                    } else if (
                        groupingForSubscriptionIdAzurePlan &&
                        groupingForRegionAzurePlan
                    ) {
                        this.loadingRIData.next('bothEnabled');
                    } else {
                        this.loadingRIData.next('notEnabled');
                    }
                };
                apiArgs.errorFunction = (errrorMessage) => {
                    this.potentialSavingsValue = '-';
                    this.serviceResponse.set(
                        serviceName,
                        this.potentialSavingsValue
                    );
                    this.loadingRIData.next(null);
                };
            }

            parallelApis.push(apiArgs);
        }
        this.httpService.hitParallelApis(parallelApis);
    }

    onGridReady($event) {
        this.agGrid = $event;
        this.gridApi = this.agGrid.api;
    }

    ngOnDestroy(): void {
        this.loadingRIData.unsubscribe();
        this.dataUpdated.unsubscribe();
    }
}
