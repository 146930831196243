import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GridOptions } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { UpdateAction } from 'src/app/shared/enums/UpdateAction';
import { IUpdateAction } from 'src/app/shared/interfaces/update-action/IUpdateAction';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { WidgetInjectedData } from './../../../../../classes/WidgetInjectedData';
import { ButtonType } from './../../../../../enums/ButtonType';
import { IButtonGeneratorInput } from './../../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from './../../../../../interfaces/form-generator/IFormGeneratorInput';
import { ITableGeneratorInput } from './../../../../../interfaces/table-generator/ITableGeneratorInput';

@Component({
    selector: 'app-partner-visibility',
    templateUrl: './partner-visibility.component.html',
    styleUrls: ['./partner-visibility.component.sass']
})
export class PartnerVisibilityComponent implements OnInit {
    tableInput: ITableGeneratorInput;
    widgetRef: Widget;
    gridRef: GridOptions;
    hideCustomer: boolean = false;
    payerAccountId: string = '';
    generalData;
    payerAccFormGenInputs: IFormGeneratorInput;
    payerAccFormGroup: FormGroup;
    updateControlInput: IUpdateAction;
    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    tableData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    buttonInput: IButtonGeneratorInput;
    constructor(
        widgetData: WidgetInjectedData,
        private listHttpService: ListHttpService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }
    setUpBasics() {
        this.tableInput = {
            afterResponse: (data) => {
                this.setGeneralDetails(data);
            },
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-chalkboard-teacher'
            },
            selection: 'single',

            listExtraction: {
                type: 'NESTED',
                nestedKey: 'dataList'
            },
            columns: [
                {
                    columnName: 'Usage Account',
                    columnKey: 'Usage Account'
                },
                {
                    columnName: 'Type Of Charges',
                    columnKey: 'Type Of Charges'
                },
                {
                    columnName: 'Cost',
                    columnKey: 'Cost'
                }
            ]
        };
        this.payerAccFormGenInputs = {
            formName: '',
            state: FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    name: 'payerAccountId',
                    label: 'Payer Account Number',
                    placeholder: 'Select Payer Account Number',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    apiInfo: {
                        ...this.listHttpService.payerAccountIds,
                        host: this.widgetRef.widgetData.widgetInfo.list.host
                    },
                    responseValueKey: 'dataList',
                    value: null
                }
            ]
        };
        this.buttonInput = {
            buttonName: 'Submit',
            function: this.submit.bind(this),
            buttonType: ButtonType.RAISED,
            buttonColorType: ButtonColorType.SECONDARY,
            customClass: 'button-con'
        };
    }
    setGeneralDetails(data) {
        this.generalData = data.dataMap;
    }
    setTableColumn(data) {
        if (data.dataMap['tableKeys'].length) {
            this.tableInput.columns = this.generateColumn(
                data.dataMap['tableKeys']
            );
        }
        if (this.payerAccountId) {
            this.updateControlInput = {
                action: UpdateAction.UPDATE_VALUE,
                controls: ['payerAccountId'],
                value: this.payerAccountId
            };
            this.updateControl.next(this.updateControlInput);
        }

        this.tableData.next(data);
        this.generalData = data.dataMap;
    }
    accountIdSelected(event) {
        if (event.payerAccountId) {
            this.payerAccountId = event.payerAccountId;
        }
    }
    submit() {
        this.generalData = null;
        const inputs = {
            payerAccountID: this.payerAccountId,
            hideCustomer: this.hideCustomer
        };
        this.widgetRef.isBindDataAssigned = false;
        this.widgetRef.setBindData(
            this.setTableColumn.bind(this),
            true,
            inputs,
            this.generateInputs.bind(this)
        );
    }
    generateInputs() {
        const inputs = {
            payerAccountID: this.payerAccountId,
            hideCustomer: this.hideCustomer
        };
        return inputs;
    }
    generateColumn(showColumnKey) {
        const columns = [];
        showColumnKey.forEach((data) => {
            const obj = { columnKey: data, columnName: data };
            columns.push(obj);
        });
        return columns;
    }
}
