<app-form-generator
    [ngClass]="onlyTemplate ? 'onlyTemplate' : null"
    *ngIf="uploadFileFormGenInput"
    [formGeneratorInput]="uploadFileFormGenInput"
    (formGroupRef)="uploadFileFormGroup = $event"
    (valuesChange)="valueChange($event)"
></app-form-generator>
<div class="tw-flex tw-justify-end">
    <app-button-generator
        *ngIf="!onlyTemplate && submitButton"
        [buttonGenInput]="submitButton"
    ></app-button-generator>
</div>
