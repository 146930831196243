<app-table-generator
    [tableInput]="tableInput"
    [widgetRef]="widgetRef"
    (gridRef)="agGrid = $event; onGridReady()"
></app-table-generator>
<mat-menu #downloadMenu="matMenu">
    <ul class="list-action list">
        <li *ngFor="let button of tableDropdownButtons" class="list-item">
            <app-button-generator
                [buttonGenInput]="button"
            ></app-button-generator>
        </li>
    </ul>
</mat-menu>
<mat-menu #buttonMenu="matMenu">
    <ul class="list">
        <li *ngFor="let button of actionButtonsDropdown" class="list-item">
            <app-button-generator
                [buttonGenInput]="button"
            ></app-button-generator>
        </li>
    </ul>
</mat-menu>
