import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalAction } from 'src/app/shared/enums/ModalAction';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { UpdateAction } from 'src/app/shared/enums/UpdateAction';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IInfoModal } from 'src/app/shared/interfaces/modal/IInfoModal';
import { IUpdateAction } from 'src/app/shared/interfaces/update-action/IUpdateAction';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';
@Component({
    selector: 'app-sns-modal',
    templateUrl: './sns-modal.component.html',
    styleUrls: ['./sns-modal.component.sass']
})
export class SnsModalComponent implements OnInit {
    widgetRef: Widget;
    Tab = Tab;
    selectedTab: any = Tab.EXISTING;
    selectedValue: any[] = [];
    selectedValue1: any[] = [];
    infoData: IInfoModal[] = [
        {
            infoHeading: '',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [
                        ' Select your Account ID since we already have the access and secret key of the corresponding account. If red colour pops up, it means we are not able to publish the message to the attached subscriptions. Please download the PDF; it contains the IAM policies to provide the permissions.'
                    ]
                }
            ]
        }
    ];
    newAccInfoData: IInfoModal[] = [
        {
            infoHeading: '',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [
                        'Select your Account ID and provide us the access and secret key of the corresponding account. Download the PDF which contains the IAM policies; Providing us enough permission to publish message to attached subscriptions. '
                    ]
                }
            ]
        }
    ];
    accountIdFormGenInput: IFormGeneratorInput = null;
    accountIdFormGroup: FormGroup;
    newAccountIdFormGenInput: IFormGeneratorInput = null;
    newAccountIdFormGroup: FormGroup;
    keysFormGenInput: IFormGeneratorInput = null;
    keysFormGroup: Map<string, FormGroup> = new Map();
    accountIds: Map<string, string> = new Map();
    accountIds1: Map<string, string> = new Map();
    verifyButton: boolean = false;
    saveButton: boolean = false;
    spinner: boolean = false;
    accounts: string[] = [];
    data;
    buttonInput: IButtonGeneratorInput[];
    updateControlInput: IUpdateAction;
    updateControlInput2: IUpdateAction;
    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    deleteIcon: IIcon = {
        type: IconType.SVG,
        class: 'trash_v2',
        extraClass: 'svg-delete-icon-fill'
    };
    resetSubcription: Subscription;
    constructor(
        private listHttpService: ListHttpService,
        private httpService: HttpService,
        private ngZone: NgZone,
        public changeDetectorRef: ChangeDetectorRef,
        private modalData: ModalInjectedData,
        private modalService: ModalService
    ) {
        this.widgetRef = this.modalData.data.widgetRef;
    }

    ngOnInit(): void {
        if (this.modalData.data['purpose'] === ModalAction.UPDATE) {
            const apiConf: IHitApi = {
                url: ApiUrls.SNS_INFO,
                input: '',
                requestType: RequestType.GET,
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                },
                function: (response) => {
                    this.data = response;
                    this.updateControlInput = {
                        action: UpdateAction.UPDATE_VALUE,
                        controls: ['awsAccounts'],
                        value: this.data.accounts
                    };
                    this.widgetRef.changeDetectorRef.detectChanges();
                    this.updateControl.next(this.updateControlInput);
                },
                errorFunction: (error) => {}
            };
            new HitApi(apiConf, this.httpService, this.ngZone).hitApi();
        }
        this.setUpBasic();
        this.resetSubcription = this.modalService.resetModal.subscribe(() => {
            if (this.selectedTab === this.Tab.EXISTING) {
                this.accountIdFormGroup.reset();
                this.selectedValue1 = [];
            }
            if (this.selectedTab === this.Tab.ADD_NEW) {
                this.newAccountIdFormGroup.reset();
                this.selectedValue = [];
            }
        });
    }
    setUpBasic() {
        this.accountIdFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Select Account ID',
                    placeholder: 'Select Account ID',
                    name: 'awsAccounts',
                    value: this.data ? this.data.accounts : '',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    apiInfo: this.listHttpService.snsExistingAccountApiInfo,
                    showKey: 'label',
                    getKey: 'id',
                    responseValueKey: 'existingAccounts',
                    showLabel: true,
                    appearance: 'legacy',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Account ID is required'
                        }
                    ]
                }
            ]
        };

        this.newAccountIdFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Select Account ID',
                    placeholder: 'Select Account ID',
                    name: 'access',
                    value: this.data?.accounts ? this.data.accounts : '',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    apiInfo: this.listHttpService.snsNewAccountApiInfo,
                    showKey: 'label',
                    getKey: 'id',
                    responseValueKey: 'newAccounts',
                    showLabel: true,
                    appearance: 'legacy',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Account ID is required'
                        }
                    ]
                }
            ]
        };

        this.generateButtons();
    }
    generateButtons() {
        this.buttonInput = [
            {
                buttonName: 'Onboarding Steps',
                buttonType: ButtonType.LINK,
                buttonColorType: ButtonColorType.SECONDARY,
                function: null,
                link: 'https://configurations.centilytics.com/1/pdf/sns-policy.pdf'
            },
            {
                buttonName: 'Verify',
                buttonType: ButtonType.RAISED,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.verifyKeys(buttonRef);
                }
            },
            {
                buttonName: 'Onboard',
                buttonType: ButtonType.RAISED,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.save(buttonRef);
                }
            }
        ];
    }
    change(event) {
        if (event && event.awsAccounts && !event.awsAccounts.length) {
            this.selectedValue1 = [];
            this.accounts = [];
            this.accountIds1.clear();
            this.saveButton = false;
        }
        if (event && event.access && !event.access.length) {
            this.selectedValue = [];
            this.accounts = [];
            this.accountIds.clear();
            this.verifyButton = false;
        }

        if (
            event &&
            event.access &&
            event.access.length &&
            this.selectedTab === Tab.ADD_NEW
        ) {
            this.selectedValue = event.access;
            this.keysFormGenInput = this.createKeys();
            this.selectedValue.forEach((ele) => {
                if (!this.accountIds.has(ele)) {
                    this.accountIds.set(ele, null);
                }
            });
            this.verifyButton = true;
        }
        if (
            event &&
            event.awsAccounts &&
            event.awsAccounts.length &&
            this.selectedTab === Tab.EXISTING
        ) {
            const selectedValue = event.awsAccounts;
            const obj = {};
            selectedValue.forEach((ele) => {
                if (!this.accountIds1.has(ele)) {
                    this.accountIds1.set(ele, null);
                    this.accountIds1.forEach((str, id) => {
                        obj['id'] = id;
                        obj['str'] = str;
                        this.selectedValue1.push(obj);
                    });
                }
            });
            this.spinner = true;
            const apiConf: IHitApi = {
                url: ApiUrls.SNS_EXISTING_ACCOUNT_POLICY,
                input: event,
                function: (response) => {
                    selectedValue.forEach((ele) => {
                        if (response.policyValidate[ele]) {
                            if (!this.accountIds1.get(ele)) {
                                this.spinner = false;
                                this.saveButton = true;
                                this.accountIds1.set(ele, 'Access Grant');
                                this.accounts.push(ele);
                            }
                        } else {
                            if (!this.accountIds1.get(ele)) {
                                this.accountIds1.set(ele, 'Access Denied');
                            }
                        }
                    });
                    this.spinner = false;
                },
                requestType: RequestType.POST,
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                },
                errorFunction: (error) => {
                    this.spinner = false;
                }
            };

            new HitApi(apiConf, this.httpService, this.ngZone).hitApi();
        }
    }
    // For delete the particular entry for the box
    onDelete(key) {
        this.accountIds.delete(key);
        const index = this.selectedValue.indexOf(key);
        this.selectedValue.splice(index, 1);
        this.updateControlInput2 = {
            action: UpdateAction.UPDATE_VALUE,
            controls: ['access'],
            value: this.selectedValue
        };
        this.updateControl.next(this.updateControlInput2);
    }
    createKeys() {
        return {
            formName: '',
            state: FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    label: 'Access Key',
                    placeholder: 'Access Key',
                    name: 'Access key',
                    fieldType: FilterType.TEXT,
                    required: true,
                    appearance: 'legacy',
                    showLabel: false,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Access key is required'
                        }
                    ]
                },
                {
                    label: 'Secret Key',
                    placeholder: 'Secret Key',
                    name: 'Secret Key',
                    fieldType: FilterType.TEXT,
                    required: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Access key is required'
                        }
                    ]
                }
            ]
        };
    }
    changeTab(tab) {
        this.selectedTab = tab ? tab : Tab.EXISTING;
        if (this.selectedTab === this.Tab.ADD_NEW) {
            this.saveButton = false;
        }
        if (this.selectedTab === this.Tab.EXISTING) {
            this.saveButton = false;
            if (this.selectedValue1 && this.selectedValue1.length) {
                this.saveButton = true;
            }
        }
    }
    verifyKeys(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }
        buttonRef.loader = true;
        const input = this.prepareInput();
        const apiConf: IHitApi = {
            url: ApiUrls.SNS_NEW_ACCOUNT_POLICY,
            input: input,
            function: (response) => {
                buttonRef.loader = false;
                this.selectedValue.forEach((ele) => {
                    if (response.policyValidate[ele]) {
                        if (!this.accountIds.get(ele)) {
                            this.accountIds.set(ele, 'green');
                            this.accounts.push(ele);
                            this.saveButton = true;
                        }
                    } else {
                        if (!this.accountIds.get(ele)) {
                            this.accountIds.set(ele, 'red');
                        }
                    }
                });
            },
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            errorFunction: (error) => {
                buttonRef.loader = false;
            }
        };

        new HitApi(apiConf, this.httpService, this.ngZone).hitApi();
    }
    prepareInput() {
        const access = {};
        this.selectedValue.forEach((accEle) => {
            this.keysFormGroup.forEach((ele, str) => {
                if (str === accEle) {
                    access[accEle] = ele.value;
                }
            });
        });
        return { access: access };
    }
    save(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }
        if (this.selectedTab === Tab.EXISTING) {
            Helper.markAllFieldAsTouched(this.accountIdFormGroup);
            if (this.accountIdFormGroup.invalid) {
                this.accountIdFormGroup.updateValueAndValidity();
                return;
            }
        } else {
            Helper.markAllFieldAsTouched(this.newAccountIdFormGroup);
            if (this.newAccountIdFormGroup.invalid) {
                this.newAccountIdFormGroup.updateValueAndValidity();
                return;
            }
        }
        buttonRef.loader = true;

        const msg =
            this.modalData.data.purpose === ModalAction.CREATE
                ? 'SNS integrated successfully'
                : 'SNS updated successfully';
        const inputs = { awsAccounts: this.accounts };
        const apiConf: IHitApi = {
            url: ApiUrls.SNS_EDIT,
            input: inputs,
            function: (response) => {
                buttonRef.loader = false;
                this.widgetRef.modalService.closeModal(
                    null,
                    this.modalData.modalId
                );
                this.widgetRef.notificationsService.showSnackBar(msg);
                this.widgetRef.refreshWidget();
            },
            requestType: RequestType.PATCH,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            errorFunction: (error) => {
                buttonRef.loader = false;
            }
        };

        new HitApi(apiConf, this.httpService, this.ngZone).hitApi();
    }

    ngOnDestroy() {
        this.resetSubcription.unsubscribe();
    }
}
enum Tab {
    EXISTING = 'Existing',
    ADD_NEW = 'Add New'
}
