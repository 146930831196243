import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { State } from 'src/app/shared/enums/State';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { AutoRecommendationModalComponent } from '../../../modal-templates/auto-recommendation-modal/auto-recommendation-modal.component';

@Component({
    selector: 'app-auto-recommendation',
    templateUrl: './auto-recommendation.component.html',
    styleUrls: ['./auto-recommendation.component.sass']
})
export class AutoRecommendationComponent implements OnInit, AfterViewInit {
    ViewType = ViewType;
    widgetRef: Widget;
    dataMap;
    cardsData: any[] = [];
    filteredCardsData: any[];
    accounts;
    State = State;
    objectKeys = Object.keys;
    recommendationCount;

    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(true);

        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set([ViewType.CARDS]);
        this.widgetRef.visibleSections.next(visibleSections);
    }

    bindData(data) {
        if (data === null) {
            return;
        }
        this.widgetRef.apiResponse = data;
        this.dataMap = data.dataMap;
        // for view modal
        this.accounts =
            data.dataList && data.dataList.length ? data.dataList : [];
        if (this.accounts && this.accounts.length === 0 && !data.message) {
            this.widgetRef.extraMessage.next(Messages.NO_DATA_AVAILABLE);
            this.widgetRef.visibleSections.next(new Set());
            this.widgetRef.endLoader();
            return;
        }
        if ('message' in data && data.message) {
            this.widgetRef.extraMessage.next(data.message);
            this.widgetRef.endLoader();
            return;
        }
        this.cardsData = this.accounts;
        this.filteredCardsData = this.accounts;
        this.recommendationCount = this.accounts.length;
        this.widgetRef.endLoader();
    }

    showrecommendationModal(card) {
        const toggleOnIcon: IIcon = {
            type: IconType.FONTAWSOME,
            class: 'fas fa-toggle-on',
            extraClass: 'switch'
        };
        const modalData: IModalData = {
            noHeader: true,
            modalName: 'Utilization',
            modalIcon: toggleOnIcon,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.SIDE,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: AutoRecommendationModalComponent,
                        payload: {
                            data: {
                                card,
                                dataMap: this.dataMap,
                                widget: this.widgetRef
                            }
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
