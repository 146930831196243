import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import * as moment from 'moment';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { PortletType } from 'src/app/shared/enums/PortletType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { SupportedFormat } from 'src/app/shared/enums/SupportedFormat';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { WidgetCreationService } from 'src/app/shared/services/widget-creation/widget-creation.service';
import { Helper } from './../../../../classes/Helper';
import { HitApi } from './../../../../classes/HitApi';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { IntegrationName } from './../../../../enums/IntegrationName';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IDropdownData } from './../../../../interfaces/dropdown-data/IDropdownData';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
@Component({
    selector: 'app-budgeting-modal-step3',
    templateUrl: './budgeting-modal-step3.component.html',
    styleUrls: ['./budgeting-modal-step3.component.sass']
})
export class BudgetingModalStep3Component implements OnInit {
    readonly MORE_INFO_HOVER_TEXT = 'Click here for more info';
    widgetRef: Widget = null;
    IntegrationName = IntegrationName;
    selectedIntegration: Map<IntegrationName, IntegrationData> = null;
    Tab = Tab;
    selectedTab: Tab = Tab.SUM;
    initialTabIndex: number = 0;
    SupportedFormat = SupportedFormat;
    supportedFormat: SupportedFormat = null;
    initialSelection: IntegrationName[] = [];
    integrations: IntegrationName[] = [
        IntegrationName.EMAIL,
        IntegrationName.O365,
        IntegrationName.GMAIL,
        IntegrationName.SLACK,
        IntegrationName.SNS,
        IntegrationName.FRESHDESK,
        IntegrationName.FRESHSERVICE,
        IntegrationName.SALESFORCE,
        IntegrationName.ZENDESK,
        IntegrationName.JIRADESK,
        IntegrationName.AUTOTASK,
        IntegrationName.OTRS,
        IntegrationName.CASDM
    ];
    edit = false;
    budgetData = null;
    budgetFilter = null;
    sendingMechanism = null;
    integrationInfoFunc = null;

    totalBudgetFormGenInput: IFormGeneratorInput = null;
    totalBudgetFormGroup: FormGroup = null;
    frequencyFormGenInput: IFormGeneratorInput = null;
    frequencyFormGroup: FormGroup = null;
    frequencyOnlyCustomFormGenInput: IFormGeneratorInput = null;
    frequencyOnlyCustomFormGroup: FormGroup = null;

    dateFormGenInput: IFormGeneratorInput = null;
    dateFormGroup: FormGroup = null;

    emailFormGenInputs: IFormGeneratorInput[] = null;
    emailFormGroups: Map<Symbol, FormGroup> = new Map();

    slackFormGenInputs: IFormGeneratorInput[] = null;
    slackFormGroups: Map<Symbol, FormGroup> = new Map();

    freshdeskFormGenInputs: IFormGeneratorInput[] = null;
    freshdeskFormGroups: Map<Symbol, FormGroup> = new Map();

    freshserviceFormGenInputs: IFormGeneratorInput[] = null;
    freshserviceFormGroups: Map<Symbol, FormGroup> = new Map();
    FilterType = FilterType;
    checkValidation: boolean;
    freshserviceFormData = [];
    fieldValueObj = {};
    customFieldValueObj = {};
    otherFieldListObj = {};
    freshserviceLoader = false;
    freshServiceConfiguredFieldsData: Map<number, object> = new Map();
    freshServiceConfigurationList = [];

    zendeskFormGenInputs: IFormGeneratorInput[] = null;
    zendeskFormGroups: Map<Symbol, FormGroup> = new Map();

    jiradeskFormGenInputs: IFormGeneratorInput[] = null;
    jiradeskFormGroups: Map<Symbol, FormGroup> = new Map();

    autotaskFormGenInputs: IFormGeneratorInput[] = null;
    autotaskFormGroups: Map<Symbol, FormGroup> = new Map();

    autotaskChildFormGenInputs: IFormGeneratorInput[] = null;
    autotaskChildFormGroups: Map<Symbol, FormGroup> = new Map();

    autotaskParentFormGenInputs: IFormGeneratorInput[] = null;
    autotaskParentFormGroups: Map<Symbol, FormGroup> = new Map();

    otrsFormGenInputs: IFormGeneratorInput[] = null;
    otrsFormGroups: Map<Symbol, FormGroup> = new Map();

    otrsUserFormGenInputs: IFormGeneratorInput[] = null;
    otrsUserFormGroups: Map<Symbol, FormGroup> = new Map();

    otrsParentFormGenInputs: IFormGeneratorInput[] = null;
    otrsParentFormGroups: Map<Symbol, FormGroup> = new Map();

    casdmFormGenInputs: IFormGeneratorInput[] = null;
    casdmFormGroups: Map<Symbol, FormGroup> = new Map();

    casdmChildFormGenInputs: IFormGeneratorInput[] = null;
    casdmChildFormGroups: Map<Symbol, FormGroup> = new Map();

    casdmParentFormGenInputs: IFormGeneratorInput[] = null;
    casdmParentFormGroups: Map<Symbol, FormGroup> = new Map();

    snsFormGenInputs: IFormGeneratorInput[] = null;
    snsFormGroups: Map<Symbol, FormGroup> = new Map();

    salesforceFormGenInputs: IFormGeneratorInput[] = [];
    salesforceFormGroups: Map<Symbol, FormGroup> = new Map();
    salesforceConfigFormGenInput: IFormGeneratorInput = null;
    salesforceConfigFormGroupRef: FormGroup = null;
    salesforceFormResponses: any[] = [];

    defaultEmails;

    backButton: IButtonGeneratorInput = {
        buttonName: 'Back',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        function: () => {
            this.multiStepFormService.previousStep(this.modalInputData.modalId);
        }
    };
    saveButton: IButtonGeneratorInput = {
        buttonName: 'Save',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        showLoader: true,
        function: (buttonRef) => {
            this.saveBudget(buttonRef);
        }
    };
    constructor(
        public modalInputData: ModalInjectedData,
        public multiStepFormService: MultiStepFormService,
        private modalService: ModalService,
        private notificationsService: NotificationsService,
        private listHttpService: ListHttpService,
        private configCache: ConfigCacheService,
        private userDataCache: UserDataCacheService,
        private widgetCreationService: WidgetCreationService
    ) {}

    ngOnInit(): void {
        this.widgetRef = this.modalInputData.data.widgetRef;

        const generalConfig = this.configCache.getGeneralConfig(
            this.userDataCache.emailId,
            this.configCache.viewId
        );
        if (generalConfig) {
            this.defaultEmails = generalConfig.primaryEmail?.emails
                ? generalConfig.primaryEmail.emails
                : [];
        }
        if (
            this.widgetRef.widgetData.widgetInfo.portlet.type ===
            PortletType.BUDGET_PIE
        ) {
            this.supportedFormat = SupportedFormat.BUDGET_PIE;
        } else if (
            this.widgetRef.widgetData.widgetInfo.portlet.type ===
            PortletType.BUDGET_REGION
        ) {
            this.supportedFormat = SupportedFormat.BUDGET_REGIONAL;
        } else if (
            this.widgetRef.widgetData.widgetInfo.portlet.type ===
            PortletType.BUDGET_TIMESERIES
        ) {
            this.supportedFormat = SupportedFormat.BUDGET_TIMESERIES;
        }
        if (
            this.modalInputData &&
            this.modalInputData.data &&
            this.modalInputData.data['stepData']
        ) {
            this.edit = true;
            this.budgetData = this.modalInputData.data['stepData'];
            this.budgetFilter = this.budgetData['budgetFilter'];
            this.sendingMechanism = this.budgetFilter['budgetSendingMechanism'];
        }

        this.totalBudgetFormGenInput = {
            formName: 'Total Budget',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    name: 'totalBudget',
                    label: 'Total Budget',
                    placeholder: 'Total Budget',
                    fieldType: FilterType.NUMBER,
                    value: this.edit ? this.budgetData['totalBudget'] : null,
                    suffixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            const infoData: string[] = [
                                `<b>Total Budget:</b> Set your budget.`,
                                `<b>Sum:</b> User will be notified if the total cost spent within the selected month exceeds the set threshold percentage.`
                            ];
                            if (
                                this.supportedFormat ===
                                SupportedFormat.BUDGET_TIMESERIES
                            ) {
                                infoData.push(
                                    `<b>Maximum:</b> The user will be notified if the cost spent is higher than the set threshold percentage in a selected timeline.`,
                                    `<b>Minimum:</b> The user will be notified if the cost spent is lower than the set threshold percentage in a selected timeline.`,
                                    `<b>Peak:</b> User will be notified if the difference between two consecutive days is higher than the set threshold percentage.`
                                );
                            }
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Total Budget',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: infoData
                                    }
                                ]
                            });
                        }
                    },
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Total budget is required'
                        }
                    ]
                }
            ]
        };

        this.frequencyFormGenInput = {
            formName: 'Frequency',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    name: 'frequency',
                    label: 'Frequency',
                    placeholder: 'Frequency',
                    fieldType: FilterType.RADIO,
                    prefixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Frequency',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Set budget limits as per your preferred timeline and get alerts if the limit breaches You can also set the alerts based on escalation matrix i.e. alerts will be sent to specified users if the set limits breach. (Escalation Matrix: This is a set of defined limits based on which specific users will be notified after every limit breaches.)`,
                                            `Timelines that you can select: Daily, Monthly, Quarterly and Custom Timeline`,
                                            `For example: Your monthly budget limit is set as $1000 with threshold percentage equivalent to 30%. If the total budget consumption breaches the set threshold percentage then the user will get an alert.`,
                                            `This applies for daily and quarterly time line as well.
                                            For example: You have selected a custom timeline and set the limit as $1000 with threshold percentage equivalent to 30%. If the total budget consumption breaches the set threshold percentage then the user will be notified.`
                                        ]
                                    }
                                ]
                            });
                        }
                    },
                    required: true,
                    value: this.edit
                        ? this.budgetFilter['budgetType'].toLowerCase()
                        : 'monthly',
                    listData: [
                        {
                            id: 'monthly',
                            label: 'Monthly'
                        },
                        {
                            id: 'daily',
                            label: 'Daily'
                        },
                        {
                            id: 'quarterly',
                            label: 'Quarterly'
                        },
                        {
                            id: 'custom',
                            label: 'Custom'
                        }
                    ],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Frequency is required'
                        }
                    ]
                }
            ]
        };

        this.frequencyOnlyCustomFormGenInput = {
            formName: 'Frequency',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    name: 'frequency',
                    label: 'Frequency',
                    placeholder: 'Frequency',
                    fieldType: FilterType.RADIO,
                    prefixIcon: {
                        hoverText: this.MORE_INFO_HOVER_TEXT,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Frequency',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `Set budget limits as per your preferred timeline and get alerts if the limit breaches You can also set the alerts based on escalation matrix i.e. alerts will be sent to specified users if the set limits breach. (Escalation Matrix: This is a set of defined limits based on which specific users will be notified after every limit breaches.)`,
                                            `Timelines that you can select: Daily, Monthly, Quarterly and Custom Timeline`,
                                            `For example: Your monthly budget limit is set as $1000 with threshold percentage equivalent to 30%. If the total budget consumption breaches the set threshold percentage then the user will get an alert.`,
                                            `This applies for daily and quarterly time line as well.
                                            For example: You have selected a custom timeline and set the limit as $1000 with threshold percentage equivalent to 30%. If the total budget consumption breaches the set threshold percentage then the user will be notified.`
                                        ]
                                    }
                                ]
                            });
                        }
                    },
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Frequency is required'
                        }
                    ],
                    value: 'custom',
                    listData: [
                        {
                            id: 'custom',
                            label: 'Custom'
                        }
                    ]
                }
            ]
        };

        this.dateFormGenInput = {
            formName: 'Date',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'startDate',
                    label: 'Start Date',
                    placeholder: 'Start Date',
                    fieldType: FilterType.DATE,
                    required: true,
                    value:
                        this.edit &&
                        this.budgetFilter &&
                        this.budgetFilter['startDate']
                            ? moment(this.budgetFilter['startDate']).format(
                                  'YYYY-MM-DD'
                              )
                            : moment().toDate(),
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Start date is required'
                        }
                    ]
                },
                {
                    name: 'endDate',
                    label: 'End Date',
                    placeholder: 'End Date',
                    fieldType: FilterType.DATE,
                    required: true,
                    value:
                        this.edit &&
                        this.budgetFilter &&
                        this.budgetFilter['endDate']
                            ? moment(this.budgetFilter['endDate']).format(
                                  'YYYY-MM-DD'
                              )
                            : moment().add(1, 'day').toDate(),
                    minDateFromControl: 'startDate',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'End date is required'
                        }
                    ]
                }
            ]
        };

        if (!this.edit) {
            this.initialSelection = [IntegrationName.EMAIL];
        } else {
            this.selectedTab = this.budgetFilter['monitoringType'];
            this.initialTabIndex = this.getTabIndex(this.selectedTab);

            const integrations: IntegrationName[] = [];
            let emailGen = false;
            let emailGenData = false;

            if (this.sendingMechanism['sendViaEmail']) {
                integrations.push(IntegrationName.EMAIL);

                emailGen = true;
                emailGenData = this.sendingMechanism['sendViaEmail'];
            }
            if (this.sendingMechanism['sendViaGmail']) {
                integrations.push(IntegrationName.GMAIL);

                emailGen = true;
                emailGenData = this.sendingMechanism['sendViaGmail'];
            }
            if (this.sendingMechanism['sendViaO365']) {
                integrations.push(IntegrationName.O365);

                emailGen = true;
                emailGenData = this.sendingMechanism['sendViaO365'];
            }

            if (emailGen) {
                this.generateEmailFormGenInputs(emailGenData);
            }

            if (this.sendingMechanism['sendViaSlack']) {
                integrations.push(IntegrationName.SLACK);

                this.generateSlackFormGenInputs(
                    this.sendingMechanism['sendViaSlack']
                );
            }
            if (this.sendingMechanism['sendViaFreshDesk']) {
                integrations.push(IntegrationName.FRESHDESK);
            }
            if (this.sendingMechanism['sendViaNewFreshService']) {
                integrations.push(IntegrationName.FRESHSERVICE);
            }
            if (this.sendingMechanism['sendViaZendesk']) {
                integrations.push(IntegrationName.ZENDESK);
            }
            if (this.sendingMechanism['sendViaJiradesk']) {
                integrations.push(IntegrationName.JIRADESK);
            }
            if (this.sendingMechanism['sendViaSns']) {
                integrations.push(IntegrationName.SNS);
            }

            if (this.sendingMechanism['sendViaAutotask']) {
                this.sendingMechanism['sendViaAutotask'].forEach(
                    (autotaskData) => {
                        if (
                            autotaskData['autotaskRequest']['childIntegration']
                        ) {
                            integrations.push(IntegrationName.AUTOTASK_CHILD);
                        }
                        if (
                            autotaskData['autotaskRequest'][
                                'parentIntegration'
                            ] ||
                            autotaskData['autotaskRequest']['ownerIntegration']
                        ) {
                            integrations.push(IntegrationName.AUTOTASK);
                        }
                    }
                );
            }

            if (this.sendingMechanism['sendViaOtrs']) {
                this.sendingMechanism['sendViaOtrs'].forEach((otrsData) => {
                    if (otrsData['otrsRequest']['userIntegration']) {
                        integrations.push(IntegrationName.OTRS_USER);
                    }
                    if (
                        otrsData['otrsRequest']['parentIntegration'] ||
                        otrsData['otrsRequest']['ownerIntegration']
                    ) {
                        integrations.push(IntegrationName.OTRS);
                    }
                });
            }

            if (this.sendingMechanism['sendViaCasdm']) {
                this.sendingMechanism['sendViaCasdm'].forEach((casdmData) => {
                    if (casdmData['casdmRequest']['childIntegration']) {
                        integrations.push(IntegrationName.CASDM_CHILD);
                    }
                    if (
                        casdmData['casdmRequest']['parentIntegration'] ||
                        casdmData['casdmRequest']['ownerIntegration']
                    ) {
                        integrations.push(IntegrationName.CASDM);
                    }
                });
            }

            if (this.sendingMechanism['sendViaSalesforce']) {
                integrations.push(IntegrationName.SALESFORCE);
            }

            this.initialSelection = integrations;
        }
    }

    integrationsChanged(integrations: Map<IntegrationName, IntegrationData>) {
        this.selectedIntegration = integrations;
        if (
            (integrations.has(IntegrationName.EMAIL) ||
                integrations.has(IntegrationName.GMAIL) ||
                integrations.has(IntegrationName.O365)) &&
            (!this.emailFormGenInputs || !this.emailFormGenInputs.length)
        ) {
            this.emailFormGenInputs = [this.getEmailFormGenInput(null, [])];
        }

        if (
            integrations.has(IntegrationName.SLACK) &&
            (!this.slackFormGenInputs || !this.slackFormGenInputs.length)
        ) {
            this.slackFormGenInputs = [this.getSlackFormGenInput(null, [], [])];
        }

        if (
            this.edit &&
            integrations.has(IntegrationName.SNS) &&
            (!this.snsFormGenInputs || !this.snsFormGenInputs.length) &&
            this.sendingMechanism['sendViaSns']
        ) {
            this.generateSnsFormGenInputs(this.sendingMechanism['sendViaSns']);
        } else if (
            integrations.has(IntegrationName.SNS) &&
            (!this.snsFormGenInputs || !this.snsFormGenInputs.length) &&
            this.integrationInfoFunc(IntegrationName.SNS)
        ) {
            this.snsFormGenInputs = [this.getSnsFormGenInput(null, [])];
        }

        if (
            this.edit &&
            integrations.has(IntegrationName.FRESHDESK) &&
            (!this.freshdeskFormGenInputs ||
                !this.freshdeskFormGenInputs.length) &&
            this.sendingMechanism['sendViaFreshdesk']
        ) {
            this.generateFreshdeskFormGenInputs(
                this.sendingMechanism['sendViaFreshdesk']
            );
        } else if (
            integrations.has(IntegrationName.FRESHDESK) &&
            (!this.freshdeskFormGenInputs ||
                !this.freshdeskFormGenInputs.length) &&
            this.integrationInfoFunc(IntegrationName.FRESHDESK)
        ) {
            this.freshdeskFormGenInputs = [
                this.getFreshdeskFormGenInput(null, [])
            ];
        }

        if (
            this.edit &&
            integrations.has(IntegrationName.FRESHSERVICE) &&
            (!this.freshserviceFormGenInputs ||
                !this.freshserviceFormGenInputs.length) &&
            this.sendingMechanism['sendViaNewFreshService']
        ) {
            this.generateFreshserviceFormGenInputs(
                this.sendingMechanism['sendViaNewFreshService']
            );
        } else if (
            integrations.has(IntegrationName.FRESHSERVICE) &&
            (!this.freshserviceFormGenInputs ||
                !this.freshserviceFormGenInputs.length) &&
            this.integrationInfoFunc(IntegrationName.FRESHSERVICE)
        ) {
            this.freshserviceFormGenInputs = [
                this.getFreshserviceFormGenInput(null, '')
            ];
        }

        if (
            this.edit &&
            integrations.has(IntegrationName.ZENDESK) &&
            (!this.zendeskFormGenInputs || !this.zendeskFormGenInputs.length) &&
            this.sendingMechanism['sendViaZendesk']
        ) {
            this.generateZendeskFormGenInputs(
                this.sendingMechanism['sendViaZendesk']
            );
        } else if (
            integrations.has(IntegrationName.ZENDESK) &&
            (!this.zendeskFormGenInputs || !this.zendeskFormGenInputs.length) &&
            this.integrationInfoFunc(IntegrationName.ZENDESK)
        ) {
            this.zendeskFormGenInputs = [this.getZendeskFormGenInput(null, [])];
        }

        if (
            this.edit &&
            integrations.has(IntegrationName.JIRADESK) &&
            (!this.jiradeskFormGenInputs ||
                !this.jiradeskFormGenInputs.length) &&
            this.sendingMechanism['sendViaJiradesk']
        ) {
            this.generateJiradeskFormGenInputs(
                this.sendingMechanism['sendViaJiradesk']
            );
        } else if (
            integrations.has(IntegrationName.JIRADESK) &&
            (!this.jiradeskFormGenInputs ||
                !this.jiradeskFormGenInputs.length) &&
            this.integrationInfoFunc(IntegrationName.JIRADESK)
        ) {
            this.jiradeskFormGenInputs = [
                this.getJiradeskFormGenInput(null, [])
            ];
        }

        const autotaskData = this.handlePopulateFormOnChange(
            integrations,
            'sendViaAutotask',
            IntegrationName.AUTOTASK,
            IntegrationName.AUTOTASK_CHILD,
            IntegrationName.AUTOTASK_PARENT,
            this.autotaskFormGenInputs,
            this.autotaskChildFormGenInputs,
            this.autotaskParentFormGenInputs,
            this.generateAutotaskFormGenInputs.bind(this),
            this.getAutotaskFormGenInput.bind(this),
            'autotaskRequest',
            'childIntegration'
        );

        if (autotaskData['formGenInput']) {
            this.autotaskFormGenInputs = autotaskData['formGenInput'];
        }
        if (autotaskData['childFormGenInput']) {
            this.autotaskChildFormGenInputs = autotaskData['childFormGenInput'];
        }
        if (autotaskData['parentFormGenInput']) {
            this.autotaskParentFormGenInputs =
                autotaskData['parentFormGenInput'];
        }

        const otrsData = this.handlePopulateFormOnChange(
            integrations,
            'sendViaOtrs',
            IntegrationName.OTRS,
            IntegrationName.OTRS_USER,
            IntegrationName.OTRS_PARENT,
            this.otrsFormGenInputs,
            this.otrsUserFormGenInputs,
            this.otrsParentFormGenInputs,
            this.generateOtrsFormGenInputs.bind(this),
            this.getOtrsFormGenInput.bind(this),
            'otrsRequest',
            'userIntegration'
        );
        if (otrsData['formGenInput']) {
            this.otrsFormGenInputs = otrsData['formGenInput'];
        }
        if (otrsData['childFormGenInput']) {
            this.otrsUserFormGenInputs = otrsData['childFormGenInput'];
        }
        if (otrsData['parentFormGenInput']) {
            this.otrsParentFormGenInputs = otrsData['parentFormGenInput'];
        }

        const casdmData = this.handlePopulateFormOnChange(
            integrations,
            'sendViaCasdm',
            IntegrationName.CASDM,
            IntegrationName.CASDM_CHILD,
            IntegrationName.CASDM_PARENT,
            this.casdmFormGenInputs,
            this.casdmChildFormGenInputs,
            this.casdmParentFormGenInputs,
            this.generateCasdmFormGenInputs.bind(this),
            this.getCasdmFormGenInput.bind(this),
            'casdmRequest',
            'childIntegration'
        );
        if (casdmData['formGenInput']) {
            this.casdmFormGenInputs = casdmData['formGenInput'];
        }
        if (casdmData['childFormGenInput']) {
            this.casdmChildFormGenInputs = casdmData['childFormGenInput'];
        }
        if (casdmData['parentFormGenInput']) {
            this.casdmParentFormGenInputs = casdmData['parentFormGenInput'];
        }

        if (
            this.edit &&
            integrations.has(IntegrationName.SALESFORCE) &&
            (!this.salesforceFormGenInputs ||
                !this.salesforceFormGenInputs.length)
        ) {
            this.generateSalesforceFormGenInputs(
                this.sendingMechanism['sendViaSalesforce']
            );
        } else if (integrations.has(IntegrationName.SALESFORCE)) {
            if (
                !this.salesforceFormGenInputs ||
                !this.salesforceFormGenInputs.length
            ) {
                this.generateSalesforceFormGenInputs(
                    integrations.get(IntegrationName.SALESFORCE)
                );
            }
        }
    }

    salesforceConfigurationValueChanged(value, index) {
        this.salesforceConfigFormGenInput = null;

        if (!value['widgetId']) {
            return;
        }

        const configResponse = this.integrationInfoFunc(
            IntegrationName.SALESFORCE
        ).integrationData.configResponses;

        const form = configResponse.find(
            (each) => each.id === value['widgetId']
        );

        if (form && form.step2) {
            this.widgetRef.ngZone.runOutsideAngular(() => {
                setTimeout(() => {
                    this.salesforceConfigFormGenInput =
                        this.widgetCreationService.salesforceBToFParser(
                            form.step2,
                            null,
                            'BUDGET_ALERT'
                        );
                }, 10);
            });
        }
    }

    generateSalesforceFormGenInputs(salesforceIntegrationData) {
        const generateForm = (percentage, widgetId) => {
            const form = {
                formId: Symbol(),
                formName: 'Salesforce',
                submitButton: null,
                state: widgetId ? FormState.IDLE : FormState.CREATE,
                fields: [
                    {
                        name: 'percentage',
                        fieldType: FilterType.NUMBER,
                        label: 'Percentage',
                        placeholder: 'Percentage',
                        required: true,
                        value: percentage,
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Percentage is required'
                            }
                        ]
                    },
                    {
                        label: 'Select Configuration',
                        name: 'widgetId',
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        placeholder: 'Select Configuration',
                        required: true,
                        value: widgetId,
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Configuration is required'
                            }
                        ],
                        listData:
                            this.integrationInfoFunc &&
                            this.integrationInfoFunc(
                                IntegrationName.SALESFORCE
                            ) &&
                            this.integrationInfoFunc(IntegrationName.SALESFORCE)
                                .integrationData &&
                            this.integrationInfoFunc(IntegrationName.SALESFORCE)
                                .integrationData.configResponses &&
                            this.integrationInfoFunc(IntegrationName.SALESFORCE)
                                .integrationData.configResponses.length
                                ? this.integrationInfoFunc(
                                      IntegrationName.SALESFORCE
                                  ).integrationData.configResponses.map(
                                      (each) => {
                                          return {
                                              id: each.id,
                                              label: each.configurationName
                                          };
                                      }
                                  )
                                : []
                    }
                ]
            };
            return form;
        };
        this.salesforceConfigFormGenInput = null;

        if (this.edit && salesforceIntegrationData) {
            salesforceIntegrationData.map((each) => {
                this.salesforceFormGenInputs.push(
                    generateForm(
                        each.percentage,
                        each.salesforceConfiguration &&
                            each.salesforceConfiguration.id
                            ? each.salesforceConfiguration.id
                            : null
                    )
                );

                const requestObj = {};

                each.salesforceConfiguration.request.map((eachField) => {
                    requestObj[eachField.id] = eachField.inputString
                        ? eachField.inputString
                        : eachField.selection
                        ? eachField.selection
                        : null;
                });

                this.salesforceFormResponses.push({
                    percentage: each.percentage,
                    widgetId:
                        each.salesforceConfiguration &&
                        each.salesforceConfiguration.id
                            ? each.salesforceConfiguration.id
                            : null,
                    ...requestObj
                });
            });

            this.salesforceFormGenInputs.push(generateForm(null, null));
        } else {
            this.salesforceFormGenInputs.push(generateForm(null, null));
        }
    }

    handlePopulateFormOnChange(
        integrations: Map<IntegrationName, IntegrationData>,
        sendViaText: string,
        integrationName: IntegrationName,
        childIntegrationName: IntegrationName,
        parentIntegrationName: IntegrationName,
        formGenInput: IFormGeneratorInput[],
        childFormGenInput: IFormGeneratorInput[],
        parentFormGenInput: IFormGeneratorInput[],
        generateFormGenInputs: Function,
        getFormGenInput: Function,
        responseKey: string,
        childKey: string
    ) {
        if (
            this.edit &&
            integrations.has(integrationName) &&
            (!formGenInput || !formGenInput.length) &&
            this.sendingMechanism[sendViaText]
        ) {
            if (this.integrationInfoFunc(integrationName).integrated) {
                generateFormGenInputs(
                    this.sendingMechanism[sendViaText],
                    integrationName
                );
            }
        } else if (
            integrations.has(integrationName) &&
            (!formGenInput || !formGenInput.length) &&
            this.integrationInfoFunc(integrationName)
        ) {
            formGenInput = [getFormGenInput(null, [], integrationName)];
        }

        if (
            this.edit &&
            integrations.has(childIntegrationName) &&
            (!childFormGenInput || !childFormGenInput.length) &&
            this.sendingMechanism[sendViaText] &&
            this.integrationInfoFunc(childIntegrationName).checked
        ) {
            const integrationData = this.sendingMechanism[sendViaText].filter(
                (dataObj) => dataObj[responseKey][childKey] === true
            );
            generateFormGenInputs(integrationData, childIntegrationName);
        } else if (
            integrations.has(childIntegrationName) &&
            (!childFormGenInput || !childFormGenInput.length) &&
            this.integrationInfoFunc(childIntegrationName)
        ) {
            childFormGenInput = [
                getFormGenInput(null, [], childIntegrationName)
            ];
        }

        if (
            this.edit &&
            integrations.has(parentIntegrationName) &&
            (!parentFormGenInput || !parentFormGenInput.length) &&
            this.sendingMechanism[sendViaText] &&
            this.integrationInfoFunc(parentIntegrationName).checked
        ) {
            const integrationData = this.sendingMechanism[sendViaText].filter(
                (dataObj) => dataObj[responseKey]['parentIntegration'] === true
            );

            generateFormGenInputs(integrationData, parentIntegrationName);
        } else if (
            integrations.has(parentIntegrationName) &&
            (!parentFormGenInput || !parentFormGenInput.length) &&
            this.integrationInfoFunc(parentIntegrationName)
        ) {
            parentFormGenInput = [
                getFormGenInput(null, [], parentIntegrationName)
            ];
        }

        return {
            formGenInput: formGenInput,
            childFormGenInput: childFormGenInput,
            parentFormGenInput: parentFormGenInput
        };
    }
    // Email Functions start

    getEmailFormGenInput(
        percentage: number,
        emails: string[]
    ): IFormGeneratorInput {
        return {
            formId: Symbol(),
            formName: 'Email',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'percentage',
                    fieldType: FilterType.NUMBER,
                    label: 'Percentage',
                    placeholder: 'Percentage',
                    required: true,
                    value: percentage,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Percentage is required'
                        }
                    ]
                },
                {
                    name: 'emailTo',
                    fieldType: FilterType.MATCHIPLIST,
                    label: 'Email',
                    placeholder: 'example@domain.com',
                    required: true,
                    type: 'email',
                    value:
                        emails && emails.length
                            ? emails
                            : this.defaultEmails
                            ? this.defaultEmails
                            : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Senders are required'
                        },
                        {
                            validator: CustomValidators.matChipAllowEmail,
                            errorMessage: 'Enter valid email'
                        }
                    ]
                }
            ]
        };
    }

    lastEmailFormUpdate(index: number, formId: Symbol) {
        if (this.emailFormGroups.has(formId)) {
            const fg = this.emailFormGroups.get(formId);
            if (fg.valid) {
                if (this.emailFormGenInputs && this.emailFormGenInputs.length) {
                    if (
                        this.getEmailPercents().includes(
                            fg.get('percentage').value
                        )
                    ) {
                        this.notificationsService.showSnackBar(
                            'Configuration for this percentage is already present',
                            true
                        );
                    } else {
                        this.emailFormGenInputs.push(
                            this.getEmailFormGenInput(null, [])
                        );
                        this.idleEmailForms();
                    }
                }
            }
        }
    }

    idleEmailForms() {
        this.emailFormGenInputs = this.emailFormGenInputs.map((fg, index) => {
            if (index !== this.emailFormGenInputs.length - 1) {
                fg.state = FormState.IDLE;
            }
            return Helper.dereference(fg);
        });
        this.widgetRef.changeDetectorRef.detectChanges();
    }

    getEmailPercents(): number[] {
        const percents = [];
        this.emailFormGenInputs.forEach((fg, index) => {
            if (index !== this.emailFormGenInputs.length - 1) {
                if (this.emailFormGroups.has(fg.formId)) {
                    percents.push(
                        this.emailFormGroups.get(fg.formId).get('percentage')
                            .value
                    );
                }
            }
        });
        return percents;
    }

    deleteEmailFormGroup(index: number, formId: Symbol) {
        this.emailFormGroups.delete(formId);
        this.emailFormGenInputs = Helper.removeIndexfromArray(
            index,
            this.emailFormGenInputs
        );
    }

    generateEmailFormGenInputs(emailData) {
        const fgInputs: IFormGeneratorInput[] = [];
        emailData.forEach((data) => {
            const fInput = this.getEmailFormGenInput(
                data['percentage'],
                data['emailTo']
            );
            fInput.state = FormState.IDLE;
            fgInputs.push(fInput);
        });
        fgInputs.push(this.getEmailFormGenInput(null, []));
        this.emailFormGenInputs = fgInputs;
    }

    // Email Functions end

    // Slack Functions start
    getSlackFormGenInput(
        percentage: number,
        users: string[],
        channels: string[]
    ): IFormGeneratorInput {
        return {
            formId: Symbol(),
            formName: 'Slack',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'percentage',
                    fieldType: FilterType.NUMBER,
                    label: 'Percentage',
                    placeholder: 'Percentage',
                    required: true,
                    value: percentage,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Percentage is required'
                        }
                    ]
                },
                {
                    label: 'Users',
                    name: 'userIds',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    placeholder: 'Select Users',
                    required: true,
                    value: users ? users : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Select atleast one user'
                        }
                    ],
                    apiInfo: {
                        requestType: RequestType.GET,
                        customInput: null,
                        host: null,
                        apiRouteSuffix: ApiUrls.SLACK_LIST_USERS,
                        authorization: AuthorizationType.BEARER_TOKEN
                    },
                    showKey: 'label',
                    getKey: 'id',
                    responseValueKey: 'usersInfo'
                },
                {
                    label: 'Channels',
                    name: 'channelIds',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    placeholder: 'Select Channels',
                    validations: null,
                    value: channels ? channels : [],
                    apiInfo: {
                        requestType: RequestType.GET,
                        customInput: null,
                        host: null,
                        apiRouteSuffix: ApiUrls.SLACK_LIST_CHANNELS,
                        authorization: AuthorizationType.BEARER_TOKEN
                    },
                    showKey: 'label',
                    getKey: 'id',
                    responseValueKey: 'channalsInfo'
                }
            ]
        };
    }

    lastSlackFormUpdate(index: number, formId: Symbol) {
        if (this.slackFormGroups.has(formId)) {
            const fg = this.slackFormGroups.get(formId);
            if (fg.valid) {
                if (this.slackFormGenInputs && this.slackFormGenInputs.length) {
                    if (
                        this.getSlackPercents().includes(
                            fg.get('percentage').value
                        )
                    ) {
                        this.notificationsService.showSnackBar(
                            'Configuration for this percentage is already present',
                            true
                        );
                    } else {
                        this.slackFormGenInputs.push(
                            this.getSlackFormGenInput(null, [], [])
                        );
                        this.idleSlackForms();
                    }
                }
            }
        }
    }

    idleSlackForms() {
        this.slackFormGenInputs = this.slackFormGenInputs.map((fg, index) => {
            if (index !== this.slackFormGenInputs.length - 1) {
                fg.state = FormState.IDLE;
            }
            return Helper.dereference(fg);
        });
        this.widgetRef.changeDetectorRef.detectChanges();
    }

    getSlackPercents(): number[] {
        const percents = [];
        this.slackFormGenInputs.forEach((fg, index) => {
            if (index !== this.slackFormGenInputs.length - 1) {
                if (this.slackFormGroups.has(fg.formId)) {
                    percents.push(
                        this.slackFormGroups.get(fg.formId).get('percentage')
                            .value
                    );
                }
            }
        });
        return percents;
    }

    deleteSlackFormGroup(index: number, formId: Symbol) {
        this.slackFormGroups.delete(formId);
        this.slackFormGenInputs = Helper.removeIndexfromArray(
            index,
            this.slackFormGenInputs
        );
    }

    generateSlackFormGenInputs(slackData) {
        const fgInputs: IFormGeneratorInput[] = [];
        slackData.forEach((data) => {
            const fInput = this.getSlackFormGenInput(
                data['percentage'],
                data['userIds'],
                data['channelIds']
            );
            fInput.state = FormState.IDLE;
            fgInputs.push(fInput);
        });
        fgInputs.push(this.getSlackFormGenInput(null, [], []));
        this.slackFormGenInputs = fgInputs;
    }
    // Slack Functions end

    // Freshdesk Functions start
    getFreshdeskFormGenInput(
        percentage: number,
        ids: string[]
    ): IFormGeneratorInput {
        const listData: IDropdownData[] = [];
        const self = this.selectedIntegration.get(IntegrationName.FRESHDESK)
            .integrationData['self'];
        const parent = this.selectedIntegration.get(IntegrationName.FRESHDESK)
            .integrationData['parent'];
        if (self) {
            listData.push({
                id: self.id,
                label: self.supportedEmails[0]
            });
        }
        if (parent) {
            listData.push({
                id: parent.id,
                label: parent.supportedEmails[0]
            });
        }
        return {
            formId: Symbol(),
            formName: 'Freshdesk',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'percentage',
                    fieldType: FilterType.NUMBER,
                    label: 'Percentage',
                    placeholder: 'Percentage',
                    required: true,
                    value: percentage,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Percentage is required'
                        }
                    ]
                },
                {
                    label: 'Supported Email',
                    name: 'integrationIds',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    placeholder: 'Supported Email',
                    required: true,
                    value: ids ? ids : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Support email is required'
                        }
                    ],
                    listData
                }
            ]
        };
    }
    lastFreshdeskFormUpdate(index: number, formId: Symbol) {
        if (this.freshdeskFormGroups.has(formId)) {
            const fg = this.freshdeskFormGroups.get(formId);
            if (fg.valid) {
                if (
                    this.freshdeskFormGenInputs &&
                    this.freshdeskFormGenInputs.length
                ) {
                    if (
                        this.getFreshdeskPercents().includes(
                            fg.get('percentage').value
                        )
                    ) {
                        this.notificationsService.showSnackBar(
                            'Configuration for this percentage is already present',
                            true
                        );
                    } else {
                        this.freshdeskFormGenInputs.push(
                            this.getFreshdeskFormGenInput(null, [])
                        );
                        this.idleFreshdeskForms();
                    }
                }
            }
        }
    }
    idleFreshdeskForms() {
        this.freshdeskFormGenInputs = this.freshdeskFormGenInputs.map(
            (fg, index) => {
                if (index !== this.freshdeskFormGenInputs.length - 1) {
                    fg.state = FormState.IDLE;
                }
                return Helper.dereference(fg);
            }
        );
        this.widgetRef.changeDetectorRef.detectChanges();
    }
    getFreshdeskPercents(): number[] {
        const percents = [];
        this.freshdeskFormGenInputs.forEach((fg, index) => {
            if (index !== this.freshdeskFormGenInputs.length - 1) {
                if (this.freshdeskFormGroups.has(fg.formId)) {
                    percents.push(
                        this.freshdeskFormGroups
                            .get(fg.formId)
                            .get('percentage').value
                    );
                }
            }
        });
        return percents;
    }
    deleteFreshdeskFormGroup(index: number, formId: Symbol) {
        this.freshdeskFormGroups.delete(formId);
        this.freshdeskFormGenInputs = Helper.removeIndexfromArray(
            index,
            this.freshdeskFormGenInputs
        );
    }
    generateFreshdeskFormGenInputs(freshdeskData) {
        const fgInputs: IFormGeneratorInput[] = [];
        freshdeskData.forEach((data) => {
            const fInput = this.getFreshdeskFormGenInput(
                data['percentage'],
                data['integrationIds']
            );
            fInput.state = FormState.IDLE;
            fgInputs.push(fInput);
        });
        fgInputs.push(this.getFreshdeskFormGenInput(null, []));
        this.freshdeskFormGenInputs = fgInputs;
    }
    // Freshdesk Functions end

    // Freshservice Functions start
    getFreshserviceFormGenInput(
        percentage: number,
        id: string
    ): IFormGeneratorInput {
        if (!this.freshServiceConfigurationList.length) {
            this.selectedIntegration
                .get(IntegrationName.FRESHSERVICE)
                .integrationData['childConfigurationListing'].forEach(
                    (element) => {
                        this.freshServiceConfigurationList.push({
                            id: element.listingWidgetId,
                            label: element.configurationName
                        });
                    }
                );
            this.selectedIntegration
                .get(IntegrationName.FRESHSERVICE)
                .integrationData['parentConfigurationListing'].forEach(
                    (element) => {
                        this.freshServiceConfigurationList.push({
                            id: element.listingWidgetId,
                            label: element.configurationName
                        });
                    }
                );
        }
        return {
            formId: Symbol(),
            formName: 'Freshservice',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'percentage',
                    fieldType: FilterType.NUMBER,
                    label: 'Percentage',
                    placeholder: 'Percentage',
                    required: true,
                    value: percentage,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Percentage is required'
                        }
                    ]
                },
                {
                    label: 'Configuration',
                    name: 'widgetId',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Configuration',
                    required: true,
                    value: id && id['widgetId'] ? id['widgetId'] : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Configuration is required'
                        }
                    ],
                    listData: this.freshServiceConfigurationList
                }
            ]
        };
    }

    freshserviceConfigValueChanged(value, index) {
        if (
            !value['widgetId'] ||
            index !== this.freshserviceFormGenInputs.length - 1
        ) {
            return;
        }

        this.freshserviceFormData = [];
        this.freshserviceLoader = true;
        let url = ApiUrls.FRESHSERVICE_CONFIGURED_FIELDS.replace(
            '{widgetId}',
            value['widgetId']
        );
        url = url.replace('{budgeting}', 'true');
        url = url.replace('{alerting}', 'false');
        const apiConf: IHitApi = {
            url: url,
            input: {},
            intactUrl: ApiUrls.FRESHSERVICE_CONFIGURED_FIELDS,
            function: (response) => {
                this.freshserviceLoader = false;
                this.freshserviceFormData = response;
            },
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            errorFunction: (error) => {
                this.freshserviceLoader = false;
            }
        };

        new HitApi(
            apiConf,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    freshserviceFieldValueChanged(response) {
        if (response.value && response.custom) {
            this.customFieldValueObj = {
                ...this.customFieldValueObj,
                ...response.value
            };
        }

        if (response.value && !response.custom) {
            this.fieldValueObj = {
                ...this.fieldValueObj,
                ...response.value
            };
        }

        if (response.otherFieldList) {
            this.otherFieldListObj = {
                ...this.otherFieldListObj,
                ...response.otherFieldList
            };
        }
    }

    lastFreshserviceFormUpdate(index: number, formId: Symbol) {
        if (this.freshserviceFormGroups.has(formId)) {
            const fg = this.freshserviceFormGroups.get(formId);
            if (fg.valid) {
                if (
                    this.freshserviceFormGenInputs &&
                    this.freshserviceFormGenInputs.length
                ) {
                    if (
                        this.getFreshservicePercents().includes(
                            fg.get('percentage').value
                        )
                    ) {
                        this.notificationsService.showSnackBar(
                            'Configuration for this percentage is already present',
                            true
                        );
                    } else {
                        this.checkValidation = true;
                        const input = {};
                        const customFields = {};
                        if (
                            !Object.keys(this.fieldValueObj).length &&
                            !Object.keys(this.customFieldValueObj).length
                        ) {
                            return;
                        }
                        const valid = this.freshserviceFormData.every(
                            (field) => {
                                if (field.required) {
                                    if (
                                        (!field.customField &&
                                            !(
                                                field.name in this.fieldValueObj
                                            )) ||
                                        this.fieldValueObj[field.name] ===
                                            null ||
                                        Array.isArray(
                                            this.fieldValueObj[field.name] &&
                                                !this.fieldValueObj[field.name]
                                                    .length
                                        )
                                    ) {
                                        return false;
                                    } else if (
                                        (field.customField &&
                                            !(
                                                field.name in
                                                this.customFieldValueObj
                                            )) ||
                                        this.customFieldValueObj[field.name] ===
                                            null ||
                                        Array.isArray(
                                            this.customFieldValueObj[
                                                field.name
                                            ] &&
                                                !this.customFieldValueObj[
                                                    field.name
                                                ].length
                                        )
                                    ) {
                                        return false;
                                    } else {
                                        if (
                                            field.customField &&
                                            this.customFieldValueObj[
                                                field.name
                                            ] !== null
                                        ) {
                                            customFields[field.name] =
                                                this.customFieldValueObj[
                                                    field.name
                                                ];
                                        } else {
                                            if (
                                                this.fieldValueObj[
                                                    field.name
                                                ] !== null
                                            ) {
                                                input[field.name] =
                                                    this.fieldValueObj[
                                                        field.name
                                                    ];
                                            }
                                        }

                                        if (field['nestedFields']) {
                                            field['nestedFields'].forEach(
                                                (nestedField) => {
                                                    if (
                                                        this.fieldValueObj[
                                                            nestedField.name
                                                        ]
                                                    ) {
                                                        if (
                                                            field.customField &&
                                                            this
                                                                .customFieldValueObj[
                                                                field.name
                                                            ] !== null
                                                        ) {
                                                            customFields[
                                                                nestedField.name
                                                            ] =
                                                                this.customFieldValueObj[
                                                                    nestedField.name
                                                                ];
                                                        } else {
                                                            if (
                                                                this
                                                                    .fieldValueObj[
                                                                    field.name
                                                                ] !== null
                                                            ) {
                                                                input[
                                                                    nestedField.name
                                                                ] =
                                                                    this.fieldValueObj[
                                                                        nestedField.name
                                                                    ];
                                                            }
                                                        }
                                                    }
                                                }
                                            );
                                        }

                                        return true;
                                    }
                                } else {
                                    if (
                                        field.customField &&
                                        this.customFieldValueObj[field.name] !==
                                            null
                                    ) {
                                        customFields[field.name] =
                                            this.customFieldValueObj[
                                                field.name
                                            ];
                                    } else {
                                        if (
                                            this.fieldValueObj[field.name] !==
                                            null
                                        ) {
                                            input[field.name] =
                                                this.fieldValueObj[field.name];
                                        }
                                    }
                                    return true;
                                }
                            }
                        );

                        if (!valid) {
                            this.notificationsService.showSnackBar(
                                'Fill all the mandatory fields',
                                true
                            );
                            return;
                        }

                        input['custom_fields'] = customFields;
                        input['widgetId'] = fg.get('widgetId').value;
                        this.freshServiceConfiguredFieldsData.set(
                            fg.get('percentage').value,
                            input
                        );

                        this.freshserviceFormGenInputs.push(
                            this.getFreshserviceFormGenInput(null, '')
                        );

                        // empty second form dependent on selected configuration
                        this.idleFreshserviceForms();
                        this.checkValidation = false;
                        this.freshserviceFormData = [];
                        this.fieldValueObj = {};
                        this.customFieldValueObj = {};
                        this.otherFieldListObj = {};
                    }
                }
            }
        }
    }
    idleFreshserviceForms() {
        this.freshserviceFormGenInputs = this.freshserviceFormGenInputs.map(
            (fg, index) => {
                if (index !== this.freshserviceFormGenInputs.length - 1) {
                    fg.state = FormState.IDLE;
                }
                return Helper.dereference(fg);
            }
        );
        this.widgetRef.changeDetectorRef.detectChanges();
    }
    getFreshservicePercents(): number[] {
        const percents = [];
        this.freshserviceFormGenInputs.forEach((fg, index) => {
            if (index !== this.freshserviceFormGenInputs.length - 1) {
                if (this.freshserviceFormGroups.has(fg.formId)) {
                    percents.push(
                        this.freshserviceFormGroups
                            .get(fg.formId)
                            .get('percentage').value
                    );
                }
            }
        });
        return percents;
    }
    deleteFreshserviceFormGroup(index: number, formId: Symbol) {
        if (this.freshserviceFormGroups.get(formId).get('percentage').value) {
            this.freshServiceConfiguredFieldsData.delete(
                this.freshserviceFormGroups.get(formId).get('percentage').value
            );
        }
        this.freshserviceFormGroups.delete(formId);
        this.freshserviceFormGenInputs = Helper.removeIndexfromArray(
            index,
            this.freshserviceFormGenInputs
        );
    }
    generateFreshserviceFormGenInputs(freshserviceData) {
        const fgInputs: IFormGeneratorInput[] = [];
        freshserviceData.forEach((data) => {
            const fInput = this.getFreshserviceFormGenInput(
                data['percentage'],
                data['freshServiceConfiguration']
            );
            fInput.state = FormState.IDLE;
            fgInputs.push(fInput);

            this.freshServiceConfiguredFieldsData.set(
                data['percentage'],
                data['freshServiceConfiguration']
            );
        });
        fgInputs.push(this.getFreshserviceFormGenInput(null, ''));
        this.freshserviceFormGenInputs = fgInputs;
    }
    // Freshservice Functions end

    // Zendesk Functions start
    getZendeskFormGenInput(
        percentage: number,
        ids: string[]
    ): IFormGeneratorInput {
        const self = this.selectedIntegration.get(IntegrationName.ZENDESK)
            .integrationData['self'];
        const parent = this.selectedIntegration.get(IntegrationName.ZENDESK)
            .integrationData['parent'];
        const listData: IDropdownData[] = [];
        if (self) {
            listData.push({
                id: self.id,
                label: self.supportedEmails[0]
            });
        }
        if (parent) {
            listData.push({
                id: parent.id,
                label: parent.supportedEmails[0]
            });
        }
        return {
            formId: Symbol(),
            formName: 'Zendesk',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'percentage',
                    fieldType: FilterType.NUMBER,
                    label: 'Percentage',
                    placeholder: 'Percentage',
                    required: true,
                    value: percentage,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Percentage is required'
                        }
                    ]
                },
                {
                    label: 'Supported Email',
                    name: 'integrationIds',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    placeholder: 'Supported Email',
                    required: true,
                    value: ids ? ids : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Support email is required'
                        }
                    ],
                    listData
                }
            ]
        };
    }
    lastZendeskFormUpdate(index: number, formId: Symbol) {
        if (this.zendeskFormGroups.has(formId)) {
            const fg = this.zendeskFormGroups.get(formId);
            if (fg.valid) {
                if (
                    this.zendeskFormGenInputs &&
                    this.zendeskFormGenInputs.length
                ) {
                    if (
                        this.getZendeskPercents().includes(
                            fg.get('percentage').value
                        )
                    ) {
                        this.notificationsService.showSnackBar(
                            'Configuration for this percentage is already present',
                            true
                        );
                    } else {
                        this.zendeskFormGenInputs.push(
                            this.getZendeskFormGenInput(null, [])
                        );
                        this.idleZendeskForms();
                    }
                }
            }
        }
    }
    idleZendeskForms() {
        this.zendeskFormGenInputs = this.zendeskFormGenInputs.map(
            (fg, index) => {
                if (index !== this.zendeskFormGenInputs.length - 1) {
                    fg.state = FormState.IDLE;
                }
                return Helper.dereference(fg);
            }
        );
        this.widgetRef.changeDetectorRef.detectChanges();
    }
    getZendeskPercents(): number[] {
        const percents = [];
        this.zendeskFormGenInputs.forEach((fg, index) => {
            if (index !== this.zendeskFormGenInputs.length - 1) {
                if (this.zendeskFormGroups.has(fg.formId)) {
                    percents.push(
                        this.zendeskFormGroups.get(fg.formId).get('percentage')
                            .value
                    );
                }
            }
        });
        return percents;
    }
    deleteZendeskFormGroup(index: number, formId: Symbol) {
        this.zendeskFormGroups.delete(formId);
        this.zendeskFormGenInputs = Helper.removeIndexfromArray(
            index,
            this.zendeskFormGenInputs
        );
    }
    generateZendeskFormGenInputs(zendeskData) {
        const fgInputs: IFormGeneratorInput[] = [];
        zendeskData.forEach((data) => {
            const fInput = this.getZendeskFormGenInput(
                data['percentage'],
                data['integrationIds']
            );
            fInput.state = FormState.IDLE;
            fgInputs.push(fInput);
        });
        fgInputs.push(this.getZendeskFormGenInput(null, []));
        this.zendeskFormGenInputs = fgInputs;
    }
    // Zendesk Functions end

    // Jiradesk Functions start
    getJiradeskFormGenInput(
        percentage: number,
        ids: string[]
    ): IFormGeneratorInput {
        const self = this.selectedIntegration.get(IntegrationName.JIRADESK)
            .integrationData['self'];
        const parent = this.selectedIntegration.get(IntegrationName.JIRADESK)
            .integrationData['parent'];
        const listData: IDropdownData[] = [];
        if (self) {
            listData.push({
                id: self.id,
                label: self.supportedEmails[0]
            });
        }
        if (parent) {
            listData.push({
                id: parent.id,
                label: parent.supportedEmails[0]
            });
        }
        return {
            formId: Symbol(),
            formName: 'Jiradesk',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'percentage',
                    fieldType: FilterType.NUMBER,
                    label: 'Percentage',
                    placeholder: 'Percentage',
                    required: true,
                    value: percentage,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Percentage is required'
                        }
                    ]
                },
                {
                    label: 'Supported Email',
                    name: 'integrationIds',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    placeholder: 'Supported Email',
                    required: true,
                    value: ids ? ids : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Support email is required'
                        }
                    ],
                    listData
                }
            ]
        };
    }
    lastJiradeskFormUpdate(index: number, formId: Symbol) {
        if (this.jiradeskFormGroups.has(formId)) {
            const fg = this.jiradeskFormGroups.get(formId);
            if (fg.valid) {
                if (
                    this.jiradeskFormGenInputs &&
                    this.jiradeskFormGenInputs.length
                ) {
                    if (
                        this.getJiradeskPercents().includes(
                            fg.get('percentage').value
                        )
                    ) {
                        this.notificationsService.showSnackBar(
                            'Configuration for this percentage is already present',
                            true
                        );
                    } else {
                        this.jiradeskFormGenInputs.push(
                            this.getJiradeskFormGenInput(null, [])
                        );
                        this.idleJiradeskForms();
                    }
                }
            }
        }
    }
    idleJiradeskForms() {
        this.jiradeskFormGenInputs = this.jiradeskFormGenInputs.map(
            (fg, index) => {
                if (index !== this.jiradeskFormGenInputs.length - 1) {
                    fg.state = FormState.IDLE;
                }
                return Helper.dereference(fg);
            }
        );
        this.widgetRef.changeDetectorRef.detectChanges();
    }
    getJiradeskPercents(): number[] {
        const percents = [];
        this.jiradeskFormGenInputs.forEach((fg, index) => {
            if (index !== this.jiradeskFormGenInputs.length - 1) {
                if (this.jiradeskFormGroups.has(fg.formId)) {
                    percents.push(
                        this.jiradeskFormGroups.get(fg.formId).get('percentage')
                            .value
                    );
                }
            }
        });
        return percents;
    }
    deleteJiradeskFormGroup(index: number, formId: Symbol) {
        this.jiradeskFormGroups.delete(formId);
        this.jiradeskFormGenInputs = Helper.removeIndexfromArray(
            index,
            this.jiradeskFormGenInputs
        );
    }
    generateJiradeskFormGenInputs(jiradeskData) {
        const fgInputs: IFormGeneratorInput[] = [];
        jiradeskData.forEach((data) => {
            const fInput = this.getJiradeskFormGenInput(
                data['percentage'],
                data['integrationIds']
            );
            fInput.state = FormState.IDLE;
            fgInputs.push(fInput);
        });
        fgInputs.push(this.getJiradeskFormGenInput(null, []));
        this.jiradeskFormGenInputs = fgInputs;
    }
    // Jiradesk Functions end

    // Sns Functions Start
    getSnsFormGenInput(percentage: number, ids: string[]): IFormGeneratorInput {
        return {
            formId: Symbol(),
            formName: 'Jiradesk',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'percentage',
                    fieldType: FilterType.NUMBER,
                    label: 'Percentage',
                    placeholder: 'Percentage',
                    required: true,
                    value: percentage,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Percentage is required'
                        }
                    ]
                },
                {
                    label: 'Topics',
                    placeholder: 'Topics',
                    name: 'notify',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    apiInfo: this.listHttpService.snsListingApiInfo,
                    responseValueKey: 'newAccounts',
                    required: true,
                    value: ids ? (ids['notify'] ? ids['notify'] : null) : null,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Topic is required'
                        }
                    ]
                }
            ]
        };
    }
    lastSnsFormUpdate(index: number, formId: Symbol) {
        if (this.snsFormGroups.has(formId)) {
            const fg = this.snsFormGroups.get(formId);
            if (fg.valid) {
                if (this.snsFormGenInputs && this.snsFormGenInputs.length) {
                    if (
                        this.getSnsPercents().includes(
                            fg.get('percentage').value
                        )
                    ) {
                        this.notificationsService.showSnackBar(
                            'Configuration for this percentage is already present',
                            true
                        );
                    } else {
                        this.snsFormGenInputs.push(
                            this.getSnsFormGenInput(null, [])
                        );
                        this.idleSnsForms();
                    }
                }
            }
        }
    }
    idleSnsForms() {
        this.snsFormGenInputs = this.snsFormGenInputs.map((fg, index) => {
            if (index !== this.snsFormGenInputs.length - 1) {
                fg.state = FormState.IDLE;
            }
            return Helper.dereference(fg);
        });
        this.widgetRef.changeDetectorRef.detectChanges();
    }
    getSnsPercents(): number[] {
        const percents = [];
        this.snsFormGenInputs.forEach((fg, index) => {
            if (index !== this.snsFormGenInputs.length - 1) {
                if (this.snsFormGroups.has(fg.formId)) {
                    percents.push(
                        this.snsFormGroups.get(fg.formId).get('percentage')
                            .value
                    );
                }
            }
        });
        return percents;
    }
    deleteSnsFormGroup(index: number, formId: Symbol) {
        this.snsFormGroups.delete(formId);
        this.snsFormGenInputs = Helper.removeIndexfromArray(
            index,
            this.snsFormGenInputs
        );
    }
    generateSnsFormGenInputs(snsData) {
        const fgInputs: IFormGeneratorInput[] = [];
        snsData.forEach((data) => {
            const fInput = this.getSnsFormGenInput(
                data['percentage'],
                data['snsRequest']
            );
            fInput.state = FormState.IDLE;
            fgInputs.push(fInput);
        });
        fgInputs.push(this.getSnsFormGenInput(null, []));
        this.snsFormGenInputs = fgInputs;
    }
    // Sns Functions End

    //Autotask Functions start
    getAutotaskFormGenInput(
        percentage: number,
        ids: {},
        integrationName: IntegrationName
    ): IFormGeneratorInput {
        const childIntegration =
            this.selectedIntegration.get(integrationName).integrationData[
                'ownerConfiguredFeilds'
            ];
        const parentIntegration =
            this.selectedIntegration.get(integrationName).integrationData[
                'selfConfiguredFeilds'
            ];
        let priorityListData: IDropdownData[] = [];
        let queueListData: IDropdownData[] = [];
        const sourceListData: IDropdownData[] = [];
        let statusListData: IDropdownData[] = [];
        if (
            (integrationName === IntegrationName.AUTOTASK ||
                integrationName === IntegrationName.AUTOTASK_PARENT) &&
            parentIntegration
        ) {
            priorityListData = parentIntegration.priority;
            queueListData = parentIntegration.queueId;
            sourceListData.push(parentIntegration.source);
            statusListData = parentIntegration.status;
        }
        if (
            integrationName === IntegrationName.AUTOTASK_CHILD &&
            childIntegration
        ) {
            priorityListData = childIntegration.priority;
            queueListData = childIntegration.queueId;
            sourceListData.push(childIntegration.source);
            statusListData = childIntegration.status;
        }

        return {
            formId: Symbol(),
            formName: 'Autotask',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'percentage',
                    fieldType: FilterType.NUMBER,
                    label: 'Percentage',
                    placeholder: 'Percentage',
                    required: true,
                    value: percentage,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Percentage is required'
                        }
                    ]
                },
                {
                    label: 'Priority',
                    name: 'priorityValue',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Priority',
                    required: true,
                    value: ids
                        ? ids['priorityValue']
                            ? ids['priorityValue']
                            : []
                        : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Priority is required'
                        }
                    ],
                    listData: priorityListData
                },
                {
                    label: 'Queue',
                    name: 'queueIdValue',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Queue',
                    required: true,
                    value: ids
                        ? ids['queueIdValue']
                            ? ids['queueIdValue']
                            : []
                        : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Queue is required'
                        }
                    ],
                    listData: queueListData
                },
                {
                    label: 'Source',
                    name: 'sourceValue',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Source',
                    required: true,
                    value: ids
                        ? ids['sourceValue']
                            ? ids['sourceValue']
                            : []
                        : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Source is required'
                        }
                    ],
                    listData: sourceListData
                },
                {
                    label: 'Status',
                    name: 'statusValue',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Status',
                    required: true,
                    value: ids
                        ? ids['statusValue']
                            ? ids['statusValue']
                            : []
                        : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Status is required'
                        }
                    ],
                    listData: statusListData
                },
                {
                    label: 'Client Id',
                    name: 'clientId',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Client Id',
                    required: true,
                    value: ids
                        ? ids['clientId']
                            ? {
                                  id: ids['clientId'],
                                  label: ids['clientId']
                              }
                            : integrationName ===
                                  IntegrationName.AUTOTASK_CHILD &&
                              this.integrationInfoFunc(integrationName)
                                  .integrated
                            ? {
                                  id: this.selectedIntegration.get(
                                      integrationName
                                  ).integrationData['childInfo']['clientId'],
                                  label: this.selectedIntegration.get(
                                      integrationName
                                  ).integrationData['childInfo']['clientId']
                              }
                            : null
                        : null,
                    apiInfo:
                        integrationName === IntegrationName.AUTOTASK_CHILD
                            ? null
                            : this.listHttpService.autoTaskClientIds,
                    responseValueKey: 'clientList',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Client id is required'
                        }
                    ],
                    disabled:
                        integrationName === IntegrationName.AUTOTASK_CHILD
                            ? true
                            : false
                }
            ]
        };
    }

    lastAutotaskFormUpdate(
        index: number,
        formId: Symbol,
        integrationName: IntegrationName
    ) {
        let formGroup: Map<Symbol, FormGroup> = null;
        let formGenInput: IFormGeneratorInput[] = null;
        if (integrationName === IntegrationName.AUTOTASK) {
            formGroup = this.autotaskFormGroups;
            formGenInput = this.autotaskFormGenInputs;
        }
        if (integrationName === IntegrationName.AUTOTASK_CHILD) {
            formGroup = this.autotaskChildFormGroups;
            formGenInput = this.autotaskChildFormGenInputs;
        }
        if (integrationName === IntegrationName.AUTOTASK_PARENT) {
            formGroup = this.autotaskParentFormGroups;
            formGenInput = this.autotaskParentFormGenInputs;
        }
        if (formGroup.has(formId)) {
            const fg = formGroup.get(formId);
            if (fg.valid) {
                if (formGenInput && formGenInput.length) {
                    if (
                        this.getAutotaskPercents(formGenInput).includes(
                            fg.get('percentage').value
                        )
                    ) {
                        this.notificationsService.showSnackBar(
                            'Configuration for this percentage is already present',
                            true
                        );
                    } else {
                        formGenInput.push(
                            this.getAutotaskFormGenInput(
                                null,
                                [],
                                integrationName
                            )
                        );
                        this.idleAutotaskForms(formGenInput);
                    }
                }
            }
        }
    }
    idleAutotaskForms(formGenInput) {
        formGenInput = formGenInput.map((fg, index) => {
            if (index !== formGenInput.length - 1) {
                fg.state = FormState.IDLE;
            }
            return Helper.dereference(fg);
        });
        this.widgetRef.changeDetectorRef.detectChanges();
    }
    getAutotaskPercents(formGenInput): number[] {
        const percents = [];
        formGenInput.forEach((fg, index) => {
            if (index !== formGenInput.length - 1) {
                if (this.autotaskFormGroups.has(fg.formId)) {
                    percents.push(
                        this.autotaskFormGroups.get(fg.formId).get('percentage')
                            .value
                    );
                }
            }
        });
        return percents;
    }

    getSalesforcePercents(formGenInput): number[] {
        const percents = [];
        formGenInput.forEach((fg, index) => {
            if (index !== formGenInput.length - 1) {
                if (this.salesforceFormGroups.has(fg.formId)) {
                    percents.push(
                        this.salesforceFormGroups
                            .get(fg.formId)
                            .get('percentage').value
                    );
                }
            }
        });
        return percents;
    }

    deleteAutotaskFormGroup(
        index: number,
        formId: Symbol,
        integrationName: IntegrationName
    ) {
        if (integrationName === IntegrationName.AUTOTASK) {
            this.autotaskFormGroups.delete(formId);
            this.autotaskFormGenInputs = Helper.removeIndexfromArray(
                index,
                this.autotaskFormGenInputs
            );
        } else if (integrationName === IntegrationName.AUTOTASK_CHILD) {
            this.autotaskChildFormGroups.delete(formId);
            this.autotaskChildFormGenInputs = Helper.removeIndexfromArray(
                index,
                this.autotaskChildFormGenInputs
            );
        } else if (integrationName === IntegrationName.AUTOTASK_PARENT) {
            this.autotaskParentFormGroups.delete(formId);
            this.autotaskParentFormGenInputs = Helper.removeIndexfromArray(
                index,
                this.autotaskParentFormGenInputs
            );
        }
    }
    generateAutotaskFormGenInputs(
        autotaskData,
        integrationName: IntegrationName
    ) {
        const fgInputs: IFormGeneratorInput[] = [];
        autotaskData.forEach((data) => {
            const fInput = this.getAutotaskFormGenInput(
                data['percentage'],
                data['autotaskRequest'],
                integrationName
            );
            fInput.state = FormState.IDLE;
            fgInputs.push(fInput);
        });
        fgInputs.push(this.getAutotaskFormGenInput(null, [], integrationName));
        if (integrationName === IntegrationName.AUTOTASK) {
            this.autotaskFormGenInputs = fgInputs;
        } else if (integrationName === IntegrationName.AUTOTASK_CHILD) {
            this.autotaskChildFormGenInputs = fgInputs;
        } else if (integrationName === IntegrationName.AUTOTASK_PARENT) {
            this.autotaskParentFormGenInputs = fgInputs;
        }
    }
    //Autotask Functions End

    //Otrs Function Start
    getOtrsFormGenInput(
        percentage: number,
        ids: {},
        integrationName: IntegrationName
    ): IFormGeneratorInput {
        const userIntegration =
            this.selectedIntegration.get(integrationName).integrationData[
                'ownerConfiguredFeilds'
            ];
        const parentIntegration =
            this.selectedIntegration.get(integrationName).integrationData[
                'configuredFeilds'
            ];
        const priorityListData: IDropdownData[] =
            this.selectedIntegration.get(integrationName).integrationData
                .priorities;
        let queueListData: IDropdownData[] = [];
        let customFieldData: [] = [];
        if (
            (integrationName === IntegrationName.OTRS ||
                integrationName === IntegrationName.OTRS_PARENT) &&
            parentIntegration
        ) {
            queueListData = Helper.mapToDropdownData(parentIntegration.queueId);
            customFieldData = parentIntegration['customFeilds'];
        }
        if (integrationName === IntegrationName.OTRS_USER && userIntegration) {
            queueListData = Helper.mapToDropdownData(userIntegration.queueId);
            customFieldData = userIntegration['customFeilds'];
        }

        const formGenInput: IFormGeneratorInput = {
            formId: Symbol(),
            formName: 'OTRS',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'percentage',
                    fieldType: FilterType.NUMBER,
                    label: 'Percentage',
                    placeholder: 'Percentage',
                    required: true,
                    value: percentage,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Percentage is required'
                        }
                    ]
                },
                {
                    label: 'Priority',
                    name: 'priority',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Priority',
                    required: true,
                    value: ids ? (ids['priority'] ? ids['priority'] : []) : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Priority is required'
                        }
                    ],
                    listData: priorityListData
                },
                {
                    label: 'Queue',
                    name: 'queueValue',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Queue',
                    required: true,
                    value: ids
                        ? ids['queueValue']
                            ? ids['queueValue']
                            : []
                        : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Queue is required'
                        }
                    ],
                    listData: queueListData
                },
                {
                    label: 'Customer Username',
                    name: 'customerUserName',
                    fieldType: FilterType.TEXT,
                    placeholder: 'Select Client Id',
                    required: true,
                    value: ids
                        ? ids['customerUserName']
                            ? ids['customerUserName']
                            : integrationName === IntegrationName.OTRS_USER
                            ? this.selectedIntegration.get(integrationName)
                                  .integrationData['userCred'][
                                  'customerUserName'
                              ]
                            : null
                        : null,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Customer username is required'
                        }
                    ],
                    disabled:
                        integrationName === IntegrationName.OTRS_USER
                            ? true
                            : false
                }
            ]
        };

        Object.keys(customFieldData).forEach((field) => {
            formGenInput.fields.push(
                this.dynamicFormField(field, customFieldData, ids)
            );
        });

        return formGenInput;
    }

    lastOtrsFormUpdate(
        index: number,
        formId: Symbol,
        integrationName: IntegrationName
    ) {
        let formGroup: Map<Symbol, FormGroup> = null;
        let formGenInput: IFormGeneratorInput[] = null;
        if (integrationName === IntegrationName.OTRS) {
            formGroup = this.otrsFormGroups;
            formGenInput = this.otrsFormGenInputs;
        }
        if (integrationName === IntegrationName.OTRS_USER) {
            formGroup = this.otrsUserFormGroups;
            formGenInput = this.otrsUserFormGenInputs;
        }
        if (integrationName === IntegrationName.OTRS_PARENT) {
            formGroup = this.otrsParentFormGroups;
            formGenInput = this.otrsParentFormGenInputs;
        }
        if (formGroup.has(formId)) {
            const fg = formGroup.get(formId);
            if (fg.valid) {
                if (formGenInput && formGenInput.length) {
                    if (
                        this.getOtrsPercents(formGenInput, formGroup).includes(
                            fg.get('percentage').value
                        )
                    ) {
                        this.notificationsService.showSnackBar(
                            'Configuration for this percentage is already present',
                            true
                        );
                    } else {
                        formGenInput.push(
                            this.getOtrsFormGenInput(null, [], integrationName)
                        );
                        this.idleOtrsForms(formGenInput);
                    }
                }
            }
        }
    }
    idleOtrsForms(formGenInput) {
        formGenInput = formGenInput.map((fg, index) => {
            if (index !== formGenInput.length - 1) {
                fg.state = FormState.IDLE;
            }
            return Helper.dereference(fg);
        });
        this.widgetRef.changeDetectorRef.detectChanges();
    }
    getOtrsPercents(formGenInput, formGroup): number[] {
        const percents = [];
        formGenInput.forEach((fg, index) => {
            if (index !== formGenInput.length - 1) {
                if (formGroup.has(fg.formId)) {
                    percents.push(
                        formGroup.get(fg.formId).get('percentage').value
                    );
                }
            }
        });
        return percents;
    }
    deleteOtrsFormGroup(
        index: number,
        formId: Symbol,
        integrationName: IntegrationName
    ) {
        if (integrationName === IntegrationName.OTRS) {
            this.otrsFormGroups.delete(formId);
            this.otrsFormGenInputs = Helper.removeIndexfromArray(
                index,
                this.otrsFormGenInputs
            );
        } else if (integrationName === IntegrationName.OTRS_USER) {
            this.otrsUserFormGroups.delete(formId);
            this.otrsUserFormGenInputs = Helper.removeIndexfromArray(
                index,
                this.otrsUserFormGenInputs
            );
        } else if (integrationName === IntegrationName.OTRS_PARENT) {
            this.otrsParentFormGroups.delete(formId);
            this.otrsParentFormGenInputs = Helper.removeIndexfromArray(
                index,
                this.otrsParentFormGenInputs
            );
        }
    }
    generateOtrsFormGenInputs(otrsData, integrationName: IntegrationName) {
        const fgInputs: IFormGeneratorInput[] = [];
        otrsData.forEach((data) => {
            const fInput = this.getOtrsFormGenInput(
                data['percentage'],
                data['otrsRequest'],
                integrationName
            );
            fInput.state = FormState.IDLE;
            fgInputs.push(fInput);
        });
        fgInputs.push(this.getOtrsFormGenInput(null, [], integrationName));
        if (integrationName === IntegrationName.OTRS) {
            this.otrsFormGenInputs = fgInputs;
        } else if (integrationName === IntegrationName.OTRS_USER) {
            this.otrsUserFormGenInputs = fgInputs;
        } else if (integrationName === IntegrationName.OTRS_PARENT) {
            this.otrsParentFormGenInputs = fgInputs;
        }
    }

    dynamicFormField(fieldName, data, ids) {
        const formField: IFormField = {
            name: fieldName,
            placeholder: 'Select ' + fieldName,
            label: fieldName,
            value: ids
                ? ids['customFeildValue']
                    ? ids['customFeildValue'][fieldName]
                    : null
                : null,
            listData: Helper.mapToDropdownData(data[fieldName]),
            fieldType: FilterType.DROPDOWN_SINGLE,
            required: true,
            validations: [
                {
                    validator: CustomValidators.required,
                    errorMessage: fieldName + ' is required'
                }
            ]
        };

        return formField;
    }

    //Otrs Function End

    //Casdm Function Start

    getCasdmFormGenInput(
        percentage: number,
        ids: {},
        integrationName: IntegrationName
    ): IFormGeneratorInput {
        const userIntegration =
            this.selectedIntegration.get(integrationName).integrationData[
                'ownerConfigueredFields'
            ];
        const parentIntegration =
            this.selectedIntegration.get(integrationName).integrationData;
        let groupListData: IDropdownData[] = [];
        let categoryListData: IDropdownData[] = [];
        const priorityListData: IDropdownData[] = [
            { id: '1', label: '1' },
            { id: '2', label: '2' },
            { id: '3', label: '3' },
            { id: '4', label: '4' }
        ];
        let incidentTypeValueListData: IDropdownData[] = [];
        if (
            integrationName === IntegrationName.CASDM ||
            integrationName === IntegrationName.CASDM_PARENT
        ) {
            incidentTypeValueListData = this.handleType(
                parentIntegration['incidentType']
            );
            groupListData = parentIntegration['group'];
            categoryListData = parentIntegration['category'];
        }
        if (integrationName === IntegrationName.CASDM_CHILD) {
            incidentTypeValueListData = this.handleType(
                userIntegration['incidentType']
            );
            groupListData = userIntegration['group'];
            categoryListData = userIntegration['category'];
        }

        return {
            formId: Symbol(),
            formName: 'CA-SDM',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'percentage',
                    fieldType: FilterType.NUMBER,
                    label: 'Percentage',
                    placeholder: 'Percentage',
                    required: true,
                    value: percentage,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Percentage is required'
                        }
                    ]
                },
                {
                    label: 'Customer Handler',
                    name: 'customerHandler',
                    fieldType: FilterType.TEXT,
                    placeholder: 'Enter Customer Handler',
                    required: true,
                    value: ids
                        ? ids['customerHandler']
                            ? ids['customerHandler']
                            : integrationName === IntegrationName.CASDM_CHILD
                            ? this.selectedIntegration.get(integrationName)
                                  .integrationData['childCredentials'][
                                  'customerHandler'
                              ]
                            : integrationName === IntegrationName.CASDM_PARENT
                            ? this.selectedIntegration.get(integrationName)
                                  .integrationData['credentials'][
                                  'customerHandler'
                              ]
                            : null
                        : null,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Customer Handler is required'
                        }
                    ]
                },
                {
                    label: 'Email',
                    name: 'email',
                    fieldType: FilterType.TEXT,
                    placeholder: 'Enter Email',
                    required: true,
                    value: ids
                        ? ids['email']
                            ? ids['email']
                            : integrationName === IntegrationName.CASDM_CHILD
                            ? this.selectedIntegration.get(integrationName)
                                  .integrationData['email']
                            : null
                        : null,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email is required'
                        }
                    ],
                    disabled:
                        integrationName === IntegrationName.CASDM_CHILD
                            ? true
                            : false
                },

                {
                    label: 'Priority',
                    name: 'priority',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Priority',
                    required: true,
                    value: ids ? (ids['priority'] ? ids['priority'] : []) : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Priority is required'
                        }
                    ],
                    listData: priorityListData
                },
                {
                    label: 'Group',
                    name: 'groupValue',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Group',
                    required: true,
                    value: ids
                        ? ids['groupValue']
                            ? ids['groupValue']
                            : []
                        : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Group is required'
                        }
                    ],
                    listData: groupListData
                },
                {
                    label: 'Category',
                    name: 'categoryValue',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Category',
                    required: true,
                    value: ids
                        ? ids['categoryValue']
                            ? ids['categoryValue']
                            : []
                        : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Category is required'
                        }
                    ],
                    listData: categoryListData
                },
                {
                    label: 'Type',
                    name: 'incidentTypeValue',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Type',
                    required: true,
                    value: ids
                        ? ids['incidentTypeValue']
                            ? ids['incidentTypeValue']
                            : []
                        : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Incident Type is required'
                        }
                    ],
                    listData: incidentTypeValueListData
                }
            ]
        };
    }
    lastCasdmFormUpdate(
        index: number,
        formId: Symbol,
        integrationName: IntegrationName
    ) {
        let formGroup: Map<Symbol, FormGroup> = null;
        let formGenInput: IFormGeneratorInput[] = null;
        if (integrationName === IntegrationName.CASDM) {
            formGroup = this.casdmFormGroups;
            formGenInput = this.casdmFormGenInputs;
        }
        if (integrationName === IntegrationName.CASDM_CHILD) {
            formGroup = this.casdmChildFormGroups;
            formGenInput = this.casdmChildFormGenInputs;
        }
        if (integrationName === IntegrationName.CASDM_PARENT) {
            formGroup = this.casdmParentFormGroups;
            formGenInput = this.casdmParentFormGenInputs;
        }
        if (formGroup.has(formId)) {
            const fg = formGroup.get(formId);
            if (fg.valid) {
                if (formGenInput && formGenInput.length) {
                    if (
                        this.getCasdmPercents(formGenInput, formGroup).includes(
                            fg.get('percentage').value
                        )
                    ) {
                        this.notificationsService.showSnackBar(
                            'Configuration for this percentage is already present',
                            true
                        );
                    } else {
                        formGenInput.push(
                            this.getCasdmFormGenInput(null, [], integrationName)
                        );
                        this.idleCasdmForms(formGenInput);
                    }
                }
            }
        }
    }
    idleCasdmForms(formGenInput) {
        formGenInput = formGenInput.map((fg, index) => {
            if (index !== formGenInput.length - 1) {
                fg.state = FormState.IDLE;
            }
            return Helper.dereference(fg);
        });
        this.widgetRef.changeDetectorRef.detectChanges();
    }
    getCasdmPercents(formGenInput, formGroup): number[] {
        const percents = [];
        formGenInput.forEach((fg, index) => {
            if (index !== formGenInput.length - 1) {
                if (formGroup.has(fg.formId)) {
                    percents.push(
                        formGroup.get(fg.formId).get('percentage').value
                    );
                }
            }
        });
        return percents;
    }
    deleteCasdmFormGroup(
        index: number,
        formId: Symbol,
        integrationName: IntegrationName
    ) {
        if (integrationName === IntegrationName.CASDM) {
            this.casdmFormGroups.delete(formId);
            this.casdmFormGenInputs = Helper.removeIndexfromArray(
                index,
                this.casdmFormGenInputs
            );
        } else if (integrationName === IntegrationName.CASDM_CHILD) {
            this.casdmChildFormGroups.delete(formId);
            this.casdmChildFormGenInputs = Helper.removeIndexfromArray(
                index,
                this.casdmChildFormGenInputs
            );
        } else if (integrationName === IntegrationName.CASDM_PARENT) {
            this.casdmParentFormGroups.delete(formId);
            this.casdmParentFormGenInputs = Helper.removeIndexfromArray(
                index,
                this.casdmParentFormGenInputs
            );
        }
    }
    generateCasdmFormGenInputs(casdmData, integrationName: IntegrationName) {
        const fgInputs: IFormGeneratorInput[] = [];
        casdmData.forEach((data) => {
            const fInput = this.getCasdmFormGenInput(
                data['percentage'],
                data['casdmRequest'],
                integrationName
            );
            fInput.state = FormState.IDLE;
            fgInputs.push(fInput);
        });
        fgInputs.push(this.getCasdmFormGenInput(null, [], integrationName));
        if (integrationName === IntegrationName.CASDM) {
            this.casdmFormGenInputs = fgInputs;
        } else if (integrationName === IntegrationName.CASDM_CHILD) {
            this.casdmChildFormGenInputs = fgInputs;
        } else if (integrationName === IntegrationName.CASDM_PARENT) {
            this.casdmParentFormGenInputs = fgInputs;
        }
    }

    handleType(data) {
        const listData = [];
        data.forEach((ele) => {
            if (ele === 'incident') {
                listData.push({ id: 'I', label: 'Incident' });
            } else if (ele === 'task') {
                listData.push({ id: 'T', label: 'Task' });
            }
        });
        return listData;
    }

    //Casdm Function End

    dateVerification() {
        Helper.syncStartAndEndDate(this.dateFormGroup, 'startDate', 'endDate');
    }

    tabChanged(tabChangedEvent: MatTabChangeEvent) {
        const tab = tabChangedEvent.tab.textLabel as Tab;
        this.selectedTab = tab;
    }

    saveBudget(buttonRef) {
        if (buttonRef.loader) {
            return;
        }
        if (this.totalBudgetFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.totalBudgetFormGroup);
            this.widgetRef.changeDetectorRef.detectChanges();
            return;
        }
        if (this.frequencyFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.frequencyFormGroup);
            this.widgetRef.changeDetectorRef.detectChanges();
            return;
        }

        if (this.selectedTab === Tab.SUM || this.selectedTab === Tab.PEAK) {
            if (
                this.frequencyFormGroup.get('frequency').value === 'custom' &&
                this.dateFormGroup.invalid
            ) {
                Helper.markAllFieldAsTouched(this.dateFormGroup);
                this.widgetRef.changeDetectorRef.detectChanges();
                return;
            }
        } else {
            if (
                this.frequencyOnlyCustomFormGroup.get('frequency').value ===
                    'custom' &&
                this.dateFormGroup.invalid
            ) {
                Helper.markAllFieldAsTouched(this.dateFormGroup);
                this.widgetRef.changeDetectorRef.detectChanges();
                return;
            }
        }

        if (
            this.selectedIntegration.has(IntegrationName.EMAIL) ||
            this.selectedIntegration.has(IntegrationName.GMAIL) ||
            this.selectedIntegration.has(IntegrationName.O365)
        ) {
            if (this.emailFormGroups.size === 1) {
                this.notificationsService.showSnackBar(
                    `Budget for mail not configured properly`,
                    true
                );
                return;
            }

            // Verifying email forms
            let valid = true;
            const lastEmailFgInput =
                this.emailFormGenInputs[this.emailFormGenInputs.length - 1];
            this.emailFormGroups.forEach((value, key) => {
                if (valid && value.invalid && lastEmailFgInput.formId !== key) {
                    valid = false;
                    Helper.markAllFieldAsTouched(value);
                    this.widgetRef.changeDetectorRef.detectChanges();
                }
            });
            if (!valid) {
                return;
            }
        }

        if (this.selectedIntegration.has(IntegrationName.SLACK)) {
            if (this.slackFormGroups.size === 1) {
                this.notificationsService.showSnackBar(
                    `Budget for slack not configured properly`,
                    true
                );
                return;
            }

            // Verifying slack forms
            let valid = true;
            const lastSlackFgInput =
                this.slackFormGenInputs[this.slackFormGenInputs.length - 1];
            this.slackFormGroups.forEach((value, key) => {
                if (valid && value.invalid && lastSlackFgInput.formId !== key) {
                    valid = false;
                    Helper.markAllFieldAsTouched(value);
                    this.widgetRef.changeDetectorRef.detectChanges();
                }
            });
            if (!valid) {
                return;
            }
        }

        if (this.selectedIntegration.has(IntegrationName.FRESHDESK)) {
            if (this.freshdeskFormGroups.size === 1) {
                this.notificationsService.showSnackBar(
                    `Budget for freshdesk not configured properly`,
                    true
                );
                return;
            }

            // Verifying freshdesk forms
            let valid = true;
            const lastFreshdeskFgInput =
                this.freshdeskFormGenInputs[
                    this.freshdeskFormGenInputs.length - 1
                ];
            this.freshdeskFormGroups.forEach((value, key) => {
                if (
                    valid &&
                    value.invalid &&
                    lastFreshdeskFgInput.formId !== key
                ) {
                    valid = false;
                    Helper.markAllFieldAsTouched(value);
                    this.widgetRef.changeDetectorRef.detectChanges();
                }
            });
            if (!valid) {
                return;
            }
        }

        if (this.selectedIntegration.has(IntegrationName.FRESHSERVICE)) {
            if (this.freshserviceFormGroups.size === 1) {
                this.notificationsService.showSnackBar(
                    `Budget for freshservice not configured properly`,
                    true
                );
                return;
            }

            // Verifying freshservice forms
            let valid = true;
            const lastFreshserviceFgInput =
                this.freshserviceFormGenInputs[
                    this.freshserviceFormGenInputs.length - 1
                ];
            this.freshserviceFormGroups.forEach((value, key) => {
                if (
                    valid &&
                    value.invalid &&
                    lastFreshserviceFgInput.formId !== key
                ) {
                    valid = false;
                    Helper.markAllFieldAsTouched(value);
                    this.widgetRef.changeDetectorRef.detectChanges();
                }
            });
            if (!valid) {
                return;
            }
        }

        if (this.selectedIntegration.has(IntegrationName.ZENDESK)) {
            if (this.zendeskFormGroups.size === 1) {
                this.notificationsService.showSnackBar(
                    `Budget for zendesk not configured properly`,
                    true
                );
                return;
            }

            // Verifying zendesk forms
            let valid = true;
            const lastZendeskFgInput =
                this.zendeskFormGenInputs[this.zendeskFormGenInputs.length - 1];
            this.zendeskFormGroups.forEach((value, key) => {
                if (
                    valid &&
                    value.invalid &&
                    lastZendeskFgInput.formId !== key
                ) {
                    valid = false;
                    Helper.markAllFieldAsTouched(value);
                    this.widgetRef.changeDetectorRef.detectChanges();
                }
            });
            if (!valid) {
                return;
            }
        }

        if (this.selectedIntegration.has(IntegrationName.JIRADESK)) {
            if (this.jiradeskFormGroups.size === 1) {
                this.notificationsService.showSnackBar(
                    `Budget for jiradesk not configured properly`,
                    true
                );
                return;
            }

            // Verifying Jiradesk forms
            let valid = true;
            const lastjiradeskFgInput =
                this.jiradeskFormGenInputs[
                    this.jiradeskFormGenInputs.length - 1
                ];
            this.jiradeskFormGroups.forEach((value, key) => {
                if (
                    valid &&
                    value.invalid &&
                    lastjiradeskFgInput.formId !== key
                ) {
                    valid = false;
                    Helper.markAllFieldAsTouched(value);
                    this.widgetRef.changeDetectorRef.detectChanges();
                }
            });

            if (!valid) {
                return;
            }
        }

        if (this.selectedIntegration.has(IntegrationName.SNS)) {
            if (this.snsFormGroups.size === 1) {
                this.notificationsService.showSnackBar(
                    `Budget for sns not configured properly`,
                    true
                );
                return;
            }

            // Verifying sns forms
            let valid = true;
            const lastsnsFgInput =
                this.snsFormGenInputs[this.snsFormGenInputs.length - 1];
            this.snsFormGroups.forEach((value, key) => {
                if (valid && value.invalid && lastsnsFgInput.formId !== key) {
                    valid = false;
                    Helper.markAllFieldAsTouched(value);
                    this.widgetRef.changeDetectorRef.detectChanges();
                }
            });

            if (!valid) {
                return;
            }
        }

        if (this.selectedIntegration.has(IntegrationName.AUTOTASK)) {
            if (this.autotaskFormGroups.size === 1) {
                this.notificationsService.showSnackBar(
                    `Budget for autotask not configured properly`,
                    true
                );
                return;
            }

            // Verifying Autotask forms
            let valid = true;
            const lastautotaskFgInput =
                this.autotaskFormGenInputs[
                    this.autotaskFormGenInputs.length - 1
                ];
            this.autotaskFormGroups.forEach((value, key) => {
                if (
                    valid &&
                    value.invalid &&
                    lastautotaskFgInput.formId !== key
                ) {
                    valid = false;
                    Helper.markAllFieldAsTouched(value);
                    this.widgetRef.changeDetectorRef.detectChanges();
                }
            });
            if (!valid) {
                return;
            }
        }

        if (this.selectedIntegration.has(IntegrationName.AUTOTASK_CHILD)) {
            if (this.autotaskChildFormGroups.size === 1) {
                this.notificationsService.showSnackBar(
                    `Budget for autotask (Child tenant integration) not configured properly`,
                    true
                );
                return;
            }

            // Verifying AutotaskChild forms
            let valid = true;
            const lastautotaskChildFgInput =
                this.autotaskChildFormGenInputs[
                    this.autotaskChildFormGenInputs.length - 1
                ];
            this.autotaskChildFormGroups.forEach((value, key) => {
                if (
                    valid &&
                    value.invalid &&
                    lastautotaskChildFgInput.formId !== key
                ) {
                    valid = false;
                    Helper.markAllFieldAsTouched(value);
                    this.widgetRef.changeDetectorRef.detectChanges();
                }
            });
            if (!valid) {
                return;
            }
        }

        if (this.selectedIntegration.has(IntegrationName.AUTOTASK_PARENT)) {
            if (this.autotaskParentFormGroups.size === 1) {
                this.notificationsService.showSnackBar(
                    `Budget for autotask (Parent tenant integration) not configured properly`,
                    true
                );
                return;
            }

            // Verifying AutotaskParent forms
            let valid = true;
            const lastautotaskParentFgInput =
                this.autotaskParentFormGenInputs[
                    this.autotaskParentFormGenInputs.length - 1
                ];
            this.autotaskParentFormGroups.forEach((value, key) => {
                if (
                    valid &&
                    value.invalid &&
                    lastautotaskParentFgInput.formId !== key
                ) {
                    valid = false;
                    Helper.markAllFieldAsTouched(value);
                    this.widgetRef.changeDetectorRef.detectChanges();
                }
            });
            if (!valid) {
                return;
            }
        }

        if (this.selectedIntegration.has(IntegrationName.OTRS)) {
            if (this.otrsFormGroups.size === 1) {
                this.notificationsService.showSnackBar(
                    `Budget for OTRS not configured properly`,
                    true
                );
                return;
            }

            // Verifying OTRS forms
            let valid = true;
            const lastotrsFgInput =
                this.otrsFormGenInputs[this.otrsFormGenInputs.length - 1];
            this.otrsFormGroups.forEach((value, key) => {
                if (valid && value.invalid && lastotrsFgInput.formId !== key) {
                    valid = false;
                    Helper.markAllFieldAsTouched(value);
                    this.widgetRef.changeDetectorRef.detectChanges();
                }
            });
            if (!valid) {
                return;
            }
        }

        if (this.selectedIntegration.has(IntegrationName.OTRS_USER)) {
            if (this.otrsUserFormGroups.size === 1) {
                this.notificationsService.showSnackBar(
                    `Budget for OTRS (User tenant integration) not configured properly`,
                    true
                );
                return;
            }

            // Verifying OtrsUser forms
            let valid = true;
            const lastotrsUserFgInput =
                this.otrsUserFormGenInputs[
                    this.otrsUserFormGenInputs.length - 1
                ];
            this.otrsUserFormGroups.forEach((value, key) => {
                if (
                    valid &&
                    value.invalid &&
                    lastotrsUserFgInput.formId !== key
                ) {
                    valid = false;
                    Helper.markAllFieldAsTouched(value);
                    this.widgetRef.changeDetectorRef.detectChanges();
                }
            });
            if (!valid) {
                return;
            }
        }

        if (this.selectedIntegration.has(IntegrationName.OTRS_PARENT)) {
            if (this.otrsParentFormGroups.size === 1) {
                this.notificationsService.showSnackBar(
                    `Budget for OTRS (Parent tenant integration) not configured properly`,
                    true
                );
                return;
            }

            // Verifying otrsParent forms
            let valid = true;
            const lastotrsParentFgInput =
                this.otrsParentFormGenInputs[
                    this.otrsParentFormGenInputs.length - 1
                ];
            this.otrsParentFormGroups.forEach((value, key) => {
                if (
                    valid &&
                    value.invalid &&
                    lastotrsParentFgInput.formId !== key
                ) {
                    valid = false;
                    Helper.markAllFieldAsTouched(value);
                    this.widgetRef.changeDetectorRef.detectChanges();
                }
            });
            if (!valid) {
                return;
            }
        }

        if (this.selectedIntegration.has(IntegrationName.CASDM)) {
            if (this.casdmFormGroups.size === 1) {
                this.notificationsService.showSnackBar(
                    `Budget for ca-sdm not configured properly`,
                    true
                );
                return;
            }

            // Verifying casdm forms
            let valid = true;
            const lastcasdmFgInput =
                this.casdmFormGenInputs[this.casdmFormGenInputs.length - 1];
            this.casdmFormGroups.forEach((value, key) => {
                if (valid && value.invalid && lastcasdmFgInput.formId !== key) {
                    valid = false;
                    Helper.markAllFieldAsTouched(value);
                    this.widgetRef.changeDetectorRef.detectChanges();
                }
            });
            if (!valid) {
                return;
            }
        }

        if (this.selectedIntegration.has(IntegrationName.CASDM_CHILD)) {
            if (this.casdmChildFormGroups.size === 1) {
                this.notificationsService.showSnackBar(
                    `Budget for ca-sdm (Child tenant integration) not configured properly`,
                    true
                );
                return;
            }

            // Verifying casdmChild forms
            let valid = true;
            const lastcasdmChildFgInput =
                this.casdmChildFormGenInputs[
                    this.casdmChildFormGenInputs.length - 1
                ];
            this.casdmChildFormGroups.forEach((value, key) => {
                if (
                    valid &&
                    value.invalid &&
                    lastcasdmChildFgInput.formId !== key
                ) {
                    valid = false;
                    Helper.markAllFieldAsTouched(value);
                    this.widgetRef.changeDetectorRef.detectChanges();
                }
            });
            if (!valid) {
                return;
            }
        }

        if (this.selectedIntegration.has(IntegrationName.CASDM_PARENT)) {
            if (this.casdmParentFormGroups.size === 1) {
                this.notificationsService.showSnackBar(
                    `Budget for ca-sdm (Parent tenant integration) not configured properly`,
                    true
                );
                return;
            }

            // Verifying casdmParent forms
            let valid = true;
            const lastcasdmParentFgInput =
                this.casdmParentFormGenInputs[
                    this.casdmParentFormGenInputs.length - 1
                ];
            this.casdmParentFormGroups.forEach((value, key) => {
                if (
                    valid &&
                    value.invalid &&
                    lastcasdmParentFgInput.formId !== key
                ) {
                    valid = false;
                    Helper.markAllFieldAsTouched(value);
                    this.widgetRef.changeDetectorRef.detectChanges();
                }
            });
            if (!valid) {
                return;
            }
        }

        if (this.selectedIntegration.has(IntegrationName.SALESFORCE)) {
            if (this.salesforceFormGroups.size === 1) {
                this.notificationsService.showSnackBar(
                    `Budget for salesforce not configured properly`,
                    true
                );
                return;
            }
        }

        buttonRef.loader = true;

        const budgetSendingMechanism = {};
        if (this.selectedIntegration.has(IntegrationName.EMAIL)) {
            budgetSendingMechanism['sendViaEmail'] = [];
            const lastEmailFgInput =
                this.emailFormGenInputs[this.emailFormGenInputs.length - 1];
            this.emailFormGroups.forEach((value, key) => {
                if (lastEmailFgInput.formId !== key) {
                    budgetSendingMechanism['sendViaEmail'].push(value.value);
                }
            });
        }

        if (this.selectedIntegration.has(IntegrationName.GMAIL)) {
            budgetSendingMechanism['sendViaGmail'] = [];
            const lastEmailFgInput =
                this.emailFormGenInputs[this.emailFormGenInputs.length - 1];
            this.emailFormGroups.forEach((value, key) => {
                if (lastEmailFgInput.formId !== key) {
                    const val = value.value;
                    val['integrationId'] = this.selectedIntegration.get(
                        IntegrationName.GMAIL
                    ).integrationData.id;
                    budgetSendingMechanism['sendViaGmail'].push(val);
                }
            });
        }

        if (this.selectedIntegration.has(IntegrationName.O365)) {
            budgetSendingMechanism['sendViaO365'] = [];
            const lastEmailFgInput =
                this.emailFormGenInputs[this.emailFormGenInputs.length - 1];
            this.emailFormGroups.forEach((value, key) => {
                if (lastEmailFgInput.formId !== key) {
                    const val = value.value;
                    val['integrationId'] = this.selectedIntegration.get(
                        IntegrationName.O365
                    ).integrationData.id;
                    budgetSendingMechanism['sendViaO365'].push(val);
                }
            });
        }

        if (this.selectedIntegration.has(IntegrationName.SLACK)) {
            budgetSendingMechanism['sendViaSlack'] = [];
            const lastSlackFgInput =
                this.slackFormGenInputs[this.slackFormGenInputs.length - 1];
            this.slackFormGroups.forEach((value, key) => {
                if (lastSlackFgInput.formId !== key) {
                    const val = value.value;
                    val['integrationId'] = this.selectedIntegration.get(
                        IntegrationName.SLACK
                    ).integrationData.id;
                    budgetSendingMechanism['sendViaSlack'].push(val);
                }
            });
        }

        if (this.selectedIntegration.has(IntegrationName.FRESHDESK)) {
            budgetSendingMechanism['sendViaFreshDesk'] = [];
            const lastFreshdeskFgInput =
                this.freshdeskFormGenInputs[
                    this.freshdeskFormGenInputs.length - 1
                ];
            this.freshdeskFormGroups.forEach((value, key) => {
                if (lastFreshdeskFgInput.formId !== key) {
                    budgetSendingMechanism['sendViaFreshDesk'].push(
                        value.value
                    );
                }
            });
        }

        if (this.selectedIntegration.has(IntegrationName.FRESHSERVICE)) {
            budgetSendingMechanism['sendViaNewFreshService'] = [];
            const lastFreshserviceFgInput =
                this.freshserviceFormGenInputs[
                    this.freshserviceFormGenInputs.length - 1
                ];
            this.freshserviceFormGroups.forEach((value, key) => {
                if (lastFreshserviceFgInput.formId !== key) {
                    const finalObj = {
                        ...value.value,
                        freshServiceConfiguration: {
                            ...this.freshServiceConfiguredFieldsData.get(
                                value.value['percentage']
                            )
                        }
                    };
                    budgetSendingMechanism['sendViaNewFreshService'].push(
                        finalObj
                    );
                }
            });
        }

        if (this.selectedIntegration.has(IntegrationName.ZENDESK)) {
            budgetSendingMechanism['sendViaZendesk'] = [];
            const lastZendeskFgInput =
                this.zendeskFormGenInputs[this.zendeskFormGenInputs.length - 1];
            this.zendeskFormGroups.forEach((value, key) => {
                if (lastZendeskFgInput.formId !== key) {
                    budgetSendingMechanism['sendViaZendesk'].push(value.value);
                }
            });
        }

        if (this.selectedIntegration.has(IntegrationName.JIRADESK)) {
            budgetSendingMechanism['sendViaJiradesk'] = [];
            const lastJiradeskFgInput =
                this.jiradeskFormGenInputs[
                    this.jiradeskFormGenInputs.length - 1
                ];
            this.jiradeskFormGroups.forEach((value, key) => {
                if (lastJiradeskFgInput.formId !== key) {
                    budgetSendingMechanism['sendViaJiradesk'].push(value.value);
                }
            });
        }

        if (this.selectedIntegration.has(IntegrationName.SNS)) {
            budgetSendingMechanism['sendViaSns'] = [];
            const lastSnsFgInput =
                this.snsFormGenInputs[this.snsFormGenInputs.length - 1];
            this.snsFormGroups.forEach((value, key) => {
                if (lastSnsFgInput.formId !== key) {
                    budgetSendingMechanism['sendViaSns'].push({
                        percentage: value.value['percentage'],
                        snsRequest: {
                            notify: value.value['notify']
                        }
                    });
                }
            });
        }

        if (this.selectedIntegration.has(IntegrationName.AUTOTASK)) {
            if (!budgetSendingMechanism['sendViaAutotask']) {
                budgetSendingMechanism['sendViaAutotask'] = [];
            }

            const lastAutotaskFgInput =
                this.autotaskFormGenInputs[
                    this.autotaskFormGenInputs.length - 1
                ];
            this.autotaskFormGroups.forEach((value, key) => {
                if (lastAutotaskFgInput.formId !== key) {
                    budgetSendingMechanism['sendViaAutotask'].push({
                        percentage: value.value['percentage'],
                        autotaskRequest: {
                            queueIdValue: value.value['queueIdValue'],
                            sourceValue: value.value['sourceValue'],
                            priorityValue: value.value['priorityValue'],
                            statusValue: value.value['statusValue'],
                            parentIntegration: true,
                            clientId: value.value['clientId']
                        }
                    });
                }
            });
        }

        if (this.selectedIntegration.has(IntegrationName.AUTOTASK_CHILD)) {
            if (!budgetSendingMechanism['sendViaAutotask']) {
                budgetSendingMechanism['sendViaAutotask'] = [];
            }
            const lastAutotaskChildFgInput =
                this.autotaskChildFormGenInputs[
                    this.autotaskChildFormGenInputs.length - 1
                ];

            this.autotaskChildFormGroups.forEach((value, key) => {
                if (lastAutotaskChildFgInput.formId !== key) {
                    budgetSendingMechanism['sendViaAutotask'].push({
                        percentage: value.value['percentage'],
                        autotaskRequest: {
                            queueIdValue: value.value['queueIdValue'],
                            sourceValue: value.value['sourceValue'],
                            priorityValue: value.value['priorityValue'],
                            statusValue: value.value['statusValue'],
                            childIntegration: true,
                            clientId: value.getRawValue()['clientId']['id']
                        }
                    });
                }
            });
        }

        if (this.selectedIntegration.has(IntegrationName.AUTOTASK_PARENT)) {
            if (!budgetSendingMechanism['sendViaAutotask']) {
                budgetSendingMechanism['sendViaAutotask'] = [];
            }
            const lastAutotaskParentFgInput =
                this.autotaskParentFormGenInputs[
                    this.autotaskParentFormGenInputs.length - 1
                ];
            this.autotaskParentFormGroups.forEach((value, key) => {
                if (lastAutotaskParentFgInput.formId !== key) {
                    budgetSendingMechanism['sendViaAutotask'].push({
                        percentage: value.value['percentage'],
                        autotaskRequest: {
                            queueIdValue: value.value['queueIdValue'],
                            sourceValue: value.value['sourceValue'],
                            priorityValue: value.value['priorityValue'],
                            statusValue: value.value['statusValue'],
                            parentIntegration: true,
                            clientId: value.value['clientId']
                        }
                    });
                }
            });
        }

        if (this.selectedIntegration.has(IntegrationName.OTRS)) {
            if (!budgetSendingMechanism['sendViaOtrs']) {
                budgetSendingMechanism['sendViaOtrs'] = [];
            }

            const lastOtrsFgInput =
                this.otrsFormGenInputs[this.otrsFormGenInputs.length - 1];
            this.otrsFormGroups.forEach((value, key) => {
                if (lastOtrsFgInput.formId !== key) {
                    const input = {
                        percentage: value.value['percentage'],
                        otrsRequest: {
                            customerUserName: value.value['customerUserName'],
                            queueValue: value.value['queueValue'],
                            priority: value.value['priority'],
                            customFeildValue: {},
                            parentIntegration: true
                        }
                    };
                    Object.keys(
                        this.selectedIntegration.get(IntegrationName.OTRS)
                            .integrationData['configuredFeilds']['customFeilds']
                    ).forEach((fieldName) => {
                        input.otrsRequest.customFeildValue[fieldName] =
                            value.value[fieldName];
                    });

                    budgetSendingMechanism['sendViaOtrs'].push(input);
                }
            });
        }

        if (this.selectedIntegration.has(IntegrationName.OTRS_USER)) {
            if (!budgetSendingMechanism['sendViaOtrs']) {
                budgetSendingMechanism['sendViaOtrs'] = [];
            }
            const lastOtrsUserFgInput =
                this.otrsUserFormGenInputs[
                    this.otrsUserFormGenInputs.length - 1
                ];

            this.otrsUserFormGroups.forEach((value, key) => {
                if (lastOtrsUserFgInput.formId !== key) {
                    const input = {
                        percentage: value.value['percentage'],
                        otrsRequest: {
                            customerUserName:
                                value.getRawValue()['customerUserName'],
                            queueValue: value.value['queueValue'],
                            priority: value.value['priority'],
                            customFeildValue: {},
                            userIntegration: true
                        }
                    };
                    Object.keys(
                        this.selectedIntegration.get(IntegrationName.OTRS_USER)
                            .integrationData['ownerConfiguredFeilds'][
                            'customFeilds'
                        ]
                    ).forEach((fieldName) => {
                        input.otrsRequest.customFeildValue[fieldName] =
                            value.value[fieldName];
                    });

                    budgetSendingMechanism['sendViaOtrs'].push(input);
                }
            });
        }

        if (this.selectedIntegration.has(IntegrationName.OTRS_PARENT)) {
            if (!budgetSendingMechanism['sendViaOtrs']) {
                budgetSendingMechanism['sendViaOtrs'] = [];
            }
            const lastOtrsParentFgInput =
                this.otrsParentFormGenInputs[
                    this.otrsParentFormGenInputs.length - 1
                ];
            this.otrsParentFormGroups.forEach((value, key) => {
                if (lastOtrsParentFgInput.formId !== key) {
                    const input = {
                        percentage: value.value['percentage'],
                        otrsRequest: {
                            customerUserName: value.value['customerUserName'],
                            queueValue: value.value['queueValue'],
                            priority: value.value['priority'],
                            customFeildValue: {},
                            parentIntegration: true
                        }
                    };
                    Object.keys(
                        this.selectedIntegration.get(
                            IntegrationName.OTRS_PARENT
                        ).integrationData['configuredFeilds']['customFeilds']
                    ).forEach((fieldName) => {
                        input.otrsRequest.customFeildValue[fieldName] =
                            value.value[fieldName];
                    });

                    budgetSendingMechanism['sendViaOtrs'].push(input);
                }
            });
        }

        if (this.selectedIntegration.has(IntegrationName.CASDM)) {
            if (!budgetSendingMechanism['sendViaCasdm']) {
                budgetSendingMechanism['sendViaCasdm'] = [];
            }

            const lastCasdmFgInput =
                this.casdmFormGenInputs[this.casdmFormGenInputs.length - 1];
            this.casdmFormGroups.forEach((value, key) => {
                if (lastCasdmFgInput.formId !== key) {
                    budgetSendingMechanism['sendViaCasdm'].push({
                        percentage: value.value['percentage'],
                        casdmRequest: {
                            groupValue: value.value['groupValue'],
                            categoryValue: value.value['categoryValue'],
                            priority: value.value['priority'],
                            incidentTypeValue: value.value['incidentTypeValue'],
                            customerHandler: value.value['customerHandler'],
                            email: value.value['email'],
                            parentIntegration: true
                        }
                    });
                }
            });
        }

        if (this.selectedIntegration.has(IntegrationName.CASDM_CHILD)) {
            if (!budgetSendingMechanism['sendViaCasdm']) {
                budgetSendingMechanism['sendViaCasdm'] = [];
            }
            const lastCasdmChildFgInput =
                this.casdmChildFormGenInputs[
                    this.casdmChildFormGenInputs.length - 1
                ];

            this.casdmChildFormGroups.forEach((value, key) => {
                if (lastCasdmChildFgInput.formId !== key) {
                    budgetSendingMechanism['sendViaCasdm'].push({
                        percentage: value.value['percentage'],
                        casdmRequest: {
                            groupValue: value.value['groupValue'],
                            categoryValue: value.value['categoryValue'],
                            priority: value.value['priority'],
                            incidentTypeValue: value.value['incidentTypeValue'],
                            customerHandler: value.value['customerHandler'],
                            email: value.getRawValue()['email'],
                            childIntegration: true
                        }
                    });
                }
            });
        }

        if (this.selectedIntegration.has(IntegrationName.CASDM_PARENT)) {
            if (!budgetSendingMechanism['sendViaCasdm']) {
                budgetSendingMechanism['sendViaCasdm'] = [];
            }
            const lastCasdmParentFgInput =
                this.casdmParentFormGenInputs[
                    this.casdmParentFormGenInputs.length - 1
                ];
            this.casdmParentFormGroups.forEach((value, key) => {
                if (lastCasdmParentFgInput.formId !== key) {
                    budgetSendingMechanism['sendViaCasdm'].push({
                        percentage: value.value['percentage'],
                        casdmRequest: {
                            groupValue: value.value['groupValue'],
                            categoryValue: value.value['categoryValue'],
                            priority: value.value['priority'],
                            incidentTypeValue: value.value['incidentTypeValue'],
                            customerHandler: value.value['customerHandler'],
                            email: value.value['email'],
                            parentIntegration: true
                        }
                    });
                }
            });
        }

        if (this.selectedIntegration.has(IntegrationName.SALESFORCE)) {
            const createSalesforcePayload = (widgetId, formResponseIndex) => {
                const response = this.integrationInfoFunc(
                    IntegrationName.SALESFORCE
                ).integrationData.configResponses;

                const form = response.find(
                    (each) => each.id === widgetId
                ).step2;

                const finalResponse = [];

                form.fields.map((each) => {
                    const eachField = {
                        id: each.varId,
                        inputString:
                            this.salesforceFormResponses[formResponseIndex][
                                each.id
                            ] &&
                            !Array.isArray(
                                this.salesforceFormResponses[formResponseIndex][
                                    each.id
                                ]
                            )
                                ? this.salesforceFormResponses[
                                      formResponseIndex
                                  ][each.id].toString()
                                : null,
                        selection:
                            this.salesforceFormResponses[formResponseIndex][
                                each.id
                            ] &&
                            Array.isArray(
                                this.salesforceFormResponses[formResponseIndex][
                                    each.id
                                ]
                            )
                                ? this.salesforceFormResponses[
                                      formResponseIndex
                                  ][each.id]
                                : [],
                        defaultValue: each.defaultValue
                            ? each.defaultValue
                            : '',
                        fieldType: each.fieldType,
                        varId: each.varId
                    };
                    finalResponse.push(eachField);
                });

                Object.keys(
                    this.salesforceFormResponses[formResponseIndex]
                ).forEach((each) => {});

                return finalResponse;
            };

            if (!budgetSendingMechanism['sendViaSalesforce']) {
                budgetSendingMechanism['sendViaSalesforce'] = [];
            }

            const lastSalesForceFgInput =
                this.salesforceFormGenInputs[
                    this.salesforceFormGenInputs.length - 1
                ];
            this.salesforceFormGroups.forEach((value, key) => {
                let i = 0;

                if (lastSalesForceFgInput.formId !== key) {
                    budgetSendingMechanism['sendViaSalesforce'].push({
                        percentage: value.value['percentage'],
                        salesforceConfiguration: {
                            id: value.value['widgetId'],
                            request: createSalesforcePayload(
                                value.value['widgetId'],
                                i
                            )
                        }
                    });
                    i += 1;
                }
            });
        }

        const budgetFilter = {
            monitoringType: this.selectedTab,
            budgetType:
                this.selectedTab === Tab.SUM || this.selectedTab === Tab.PEAK
                    ? this.frequencyFormGroup
                          .get('frequency')
                          .value.toUpperCase()
                    : this.frequencyOnlyCustomFormGroup
                          .get('frequency')
                          .value.toUpperCase(),
            budgetSendingMechanism
        };

        if (this.dateFormGroup) {
            budgetFilter['startDate'] = Helper.formatDate(
                this.dateFormGroup.get('startDate').value
            );
            budgetFilter['endDate'] = Helper.formatDate(
                this.dateFormGroup.get('endDate').value
            );
        }

        const step1Data = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(1);
        const step2Data = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(2);
        const budgetInput = {
            budgetName: step1Data['budgetName'],
            widgetIds: step1Data['widgetsSelected'],
            widgetFilters: Helper.mapToObj(step2Data['widgetFilters']),
            totalBudget: this.totalBudgetFormGroup.get('totalBudget').value,
            budgetFilter
        };

        const createArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.create
        );
        createArgs.input = budgetInput;
        createArgs.function = (response) => {
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
            this.notificationsService.showSnackBar(
                `Budget created successfully`
            );
            this.widgetRef.refreshWidget();
            this.modalService.closeModal(null, this.modalInputData.modalId);
        };
        createArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
            this.notificationsService.showSnackBar(
                error && error.error && error.error.message
                    ? error.error.message
                    : `Error creating budget`,
                true
            );
        };

        const updateArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.update
        );

        if (this.budgetData) {
            updateArgs.url = updateArgs.url.replace(
                '{budget-id}',
                this.budgetData['id']
            );
        }
        updateArgs.input = budgetInput;
        updateArgs.function = (response) => {
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
            this.notificationsService.showSnackBar(
                `Budget updated successfully`
            );
            this.widgetRef.refreshWidget();
            this.modalService.closeModal(null, this.modalInputData.modalId);
        };
        updateArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
            this.notificationsService.showSnackBar(
                error && error.error && error.error.message
                    ? error.error.message
                    : `Error updating budget`,
                true
            );
        };

        if (!this.edit) {
            new HitApi(
                createArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        } else {
            new HitApi(
                updateArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }

    getTabIndex(tab: Tab) {
        return tab === Tab.SUM
            ? 0
            : tab === Tab.MAX
            ? 1
            : tab === Tab.MIN
            ? 2
            : 3;
    }

    ngAfterViewInit() {
        this.widgetRef.changeDetectorRef.detectChanges();
    }

    // SALESFORCE INTEGRATION

    deleteSalesFormGroup(index: number, formId: Symbol) {
        this.salesforceFormGroups.delete(formId);
        this.salesforceFormGenInputs = Helper.removeIndexfromArray(
            index,
            this.salesforceFormGenInputs
        );
        this.salesforceFormResponses.splice(index, 1);
    }

    lastSalesforceFormUpdate(index: number, formId: Symbol) {
        const formGroup: Map<Symbol, FormGroup> = this.salesforceFormGroups;
        const formGenInput: IFormGeneratorInput[] =
            this.salesforceFormGenInputs;

        if (formGroup.has(formId)) {
            const fg = formGroup.get(formId);
            if (fg.valid) {
                if (formGenInput && formGenInput.length) {
                    if (
                        this.getSalesforcePercents(formGenInput).includes(
                            fg.get('percentage').value
                        )
                    ) {
                        this.notificationsService.showSnackBar(
                            'Configuration for this percentage is already present',
                            true
                        );
                    } else {
                        if (this.salesforceConfigFormGroupRef.invalid) {
                            this.salesforceConfigFormGroupRef.markAllAsTouched();
                            return;
                        }

                        const finalFormValue = {
                            ...fg.value,
                            ...this.salesforceConfigFormGroupRef.value
                        };

                        this.salesforceFormResponses.push(finalFormValue);
                        fg.disable();

                        this.generateSalesforceFormGenInputs(null);
                    }
                }
            } else {
                fg.markAllAsTouched();
                return;
            }
        }
    }
}

interface IntegrationData {
    iconData: IIcon;
    name: string;
    integrationData;
    integrated: boolean;
}

enum Tab {
    SUM = 'SUM',
    MAX = 'MAX',
    MIN = 'MIN',
    PEAK = 'PEAK'
}
