import { Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalAction } from 'src/app/shared/enums/ModalAction';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { ICardsGeneratorInput } from 'src/app/shared/interfaces/card-generator/ICardsGeneratorInput';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { IIcon } from '../../../../interfaces/icon-data/IIcon';
import { AddTagConfigFirstStepComponent } from '../../../modal-templates/tag-config-modal/add-tag-config-first-step/add-tag-config-first-step.component';

@Component({
    selector: 'app-tag-configuration-listing',
    templateUrl: './tag-configuration-listing.component.html',
    styleUrls: ['./tag-configuration-listing.component.sass']
})
export class TagConfigurationListingComponent implements OnInit {
    widgetRef: Widget;
    cardInput: ICardsGeneratorInput;

    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService,
        private notificationService: NotificationsService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.cardInput = {
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-list-ol'
            },
            cardTitle: {
                infoTitle: 'Tag Name',
                infoKey: 'ruleSetName'
            },
            infoData: [
                {
                    infoTitle: 'Created By',
                    infoKey: 'email'
                }
            ],
            buttons: [
                {
                    buttonName: 'New Tag',
                    function: (buttonData: IButtonData) => {
                        this.create();
                    },
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-plus'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.PRIMARY,
                    hoverText: 'Create New Tag'
                }
            ],
            cardButtons: [
                {
                    buttonName: 'Edit Tag',
                    function: (buttonData: IButtonData, cardData) => {
                        this.update(cardData);
                    },
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-edit'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SECONDARY,
                    hoverText: 'Edit Tag',
                    showLoader: false
                },
                {
                    buttonName: '',
                    function: (buttonData: IButtonData, cardData) => {
                        this.delete(cardData);
                    },
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-trash'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.WARN,
                    hoverText: 'Delete Tag',
                    showLoader: false
                }
            ],

            listExtraction: {
                type: 'NESTED',
                nestedKey: 'dataList'
            }
        };
    }

    create() {
        const modalInfo = {
            label: 'Create Tag',
            widgetRef: this.widgetRef,
            payload: {
                widgetRef: this.widgetRef,
                purpose: ModalAction.CREATE
            }
        };
        this.setModalDataAndOpen(modalInfo);
    }

    update(item) {
        const modalInfo = {
            label: 'Edit Tag',
            payload: {
                widgetRef: this.widgetRef,
                purpose: ModalAction.UPDATE,
                itemData: item
            }
        };
        this.setModalDataAndOpen(modalInfo, false);
    }

    setModalDataAndOpen(modalInfo, reset = true) {
        const modalData: IModalData = {
            modalName: modalInfo.label,
            modalWidthVw: 80,
            modalIcon: {
                class: 'fas fa-file-invoice',
                type: IconType.FONTAWSOME
            } as IIcon,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalPurpose: modalInfo.payload.purpose,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: AddTagConfigFirstStepComponent,
                        payload: {
                            data: modalInfo.payload
                        }
                    },
                    stepName: ''
                }
            ]
        };
        this.modalService.openModal(modalData, reset);
    }

    delete(item) {
        this.modalService.openConfirmationModal({
            modalName: 'Delete Tag',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            contextIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclaimation-triangle'
            },
            confirmationMessage: 'Are you sure you want to delete tag ?',
            buttonColorType: ButtonColorType.WARN,
            buttonText: 'Delete',
            loaderOnExec: true,
            function: (modalId: Symbol) => {
                this.deleteApi.call(this, item, modalId);
            }
        });
    }

    deleteApi(item, modalId: Symbol) {
        const deleteArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.delete
        );

        deleteArgs.input = {
            ruleSetName: item.ruleSetName,
            email: item.email
        };
        deleteArgs.function = (response) => {
            this.widgetRef.refreshWidget();
            this.notificationService.showSnackBar('Tag deleted successfully');
            this.modalService.closeModal(null, modalId);
        };
        deleteArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while deleting tag'
            );
            this.modalService.closeModal(null, modalId);
        };

        new HitApi(
            deleteArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
