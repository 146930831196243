<div class="vs-tab-container">
    <div class="vs-tab-header-container">
        <div class="vs-tab-header-list">
            <ng-container
                *ngFor="
                    let tabHeader of tabHeaderList;
                    index as i;
                    last as isLast
                "
            >
                <div
                    class="vs-tab-header clickable"
                    [class.active]="activeIndex === i"
                    [style.width]="eachTabWidth + '%'"
                    (click)="changeVerticalTab(i, true)"
                >
                    <span
                        [matTooltip]="
                            tabHeader.showTooltip ? tabHeader.tooltipText : null
                        "
                    >
                        {{ tabHeader.label }}
                    </span>
                </div>
                <span *ngIf="!isLast" class="separate"></span>
            </ng-container>
        </div>
        <ng-container *ngIf="modalId">
            <div
                class="tw-flex tw-justify-center tw-items-center tw-absolute tw-h-11 tw-w-11 tw-right-2 tw-top-2 tw-rounded-xl tw-text-fs-800 tw-z-10 tw-cursor-pointer close-button"
                (click)="modalService.closeModal(null, modalId)"
                appIcon
                [data]="crossIcon"
            ></div>
        </ng-container>
    </div>

    <ng-container *ngIf="tabContentList && tabContentList.length">
        <div
            class="vs-tab-content-wrapper"
            (scroll)="scrollSubject.emit($event)"
        >
            <ng-container *ngFor="let tabContent of tabContentList">
                <div #verticalTabContent class="vs-tab-content">
                    <ng-container
                        [ngTemplateOutlet]="tabContent.templateRef"
                    ></ng-container>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
