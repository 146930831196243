import { Component, OnInit } from '@angular/core';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IDropdownData } from 'src/app/shared/interfaces/dropdown-data/IDropdownData';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { ButtonType } from './../../../../../enums/ButtonType';
import { IButtonGeneratorInput } from './../../../../../interfaces/button-generator/IButtonGeneratorInput';
import { FreshserviceAddFieldComponent } from './../freshservice-add-field/freshservice-add-field.component';

@Component({
    selector: 'app-freshservice-integration-step-two',
    templateUrl: './freshservice-integration-step-two.component.html',
    styleUrls: ['./freshservice-integration-step-two.component.sass']
})
export class FreshserviceIntegrationStepTwoComponent implements OnInit {
    mandatoryFields: IDropdownData[] = [];
    optionalFields: IDropdownData[] = [];
    customFields: IDropdownData[] = [];
    otherFields: IDropdownData[] = [];
    edit: boolean;
    buttonsInput: IButtonGeneratorInput[];

    constructor(
        public modalInputData: ModalInjectedData,
        public multiStepFormService: MultiStepFormService,
        private modalService: ModalService
    ) {
        if (
            modalInputData &&
            modalInputData.data &&
            this.modalInputData.data.response
        ) {
            this.edit = true;
        }
        this.buttonsInput = [
            {
                buttonName: 'Back',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED,
                function: () => {
                    this.back();
                }
            },
            {
                buttonName: 'Next',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED,
                function: () => {
                    this.next();
                }
            }
        ];
    }

    ngOnInit(): void {
        if (
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(2)
        ) {
            this.mandatoryFields = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(2).mandatoryFields;
            this.otherFields = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(2).otherFields;
            this.optionalFields = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(2).optionalFields;
            this.customFields = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(2).customFields;
        } else {
            const firstStep = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1).fields;
            this.mandatoryFields = firstStep['fields']['mandatoryFields'];
            this.optionalFields = firstStep['fields']['optionalFields'];
            this.customFields = firstStep['fields']['customFields'];

            if (this.edit) {
                this.optionalFields.forEach((element) => {
                    if (
                        this.modalInputData.data.response['selectedFields'][
                            'optionalFields'
                        ].includes(element.id)
                    ) {
                        this.otherFields.push(element);
                    }
                });
                this.customFields.forEach((element) => {
                    if (
                        this.modalInputData.data.response['selectedFields'][
                            'customFields'
                        ].includes(element.id)
                    ) {
                        this.otherFields.push(element);
                    }
                });
            }
        }
    }

    remove(field): void {
        const index = this.otherFields.indexOf(field);

        if (index >= 0) {
            this.otherFields.splice(index, 1);
        }
    }

    openFieldSelectionModal() {
        const modalArgs: IModalData = {
            modalName: 'Fresh Service Field Selection',
            modalIcon: null,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalSteps: [
                {
                    stepName: '',
                    stepData: {
                        componentToLoad: FreshserviceAddFieldComponent,
                        payload: {
                            data: {
                                fieldList: [
                                    ...this.optionalFields,
                                    ...this.customFields
                                ],
                                selectedFields: this.otherFields,
                                updateFieldMethod:
                                    this.updateOtherFields.bind(this)
                            }
                        }
                    }
                }
            ],
            modalWidthVw: 30,
            modalHeightVh: 70
        };
        this.modalService.openModal(modalArgs);
    }

    updateOtherFields(fields) {
        this.otherFields = fields;
    }

    back() {
        this.multiStepFormService.previousStep(this.modalInputData.modalId);
    }

    next() {
        this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .set(2, {
                mandatoryFields: this.mandatoryFields,
                otherFields: this.otherFields,
                optionalFields: this.optionalFields,
                customFields: this.customFields
            });
        this.multiStepFormService.nextStep(this.modalInputData.modalId);
    }
}
