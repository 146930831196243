import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IGraphData } from 'src/app/shared/interfaces/graph/IGraphData';
import { IRadarChartData } from '../../interfaces/graph/IGraphData';
import { Helper } from './../../classes/Helper';
@Component({
    selector: 'app-radar-chart',
    templateUrl: './radar-chart.component.html',
    styleUrls: ['./radar-chart.component.sass']
})
export class RadarChartComponent implements OnInit, OnDestroy {
    am4core: any;
    am4charts: any;
    am4themesAnimated: any;
    chart: any;
    chartData: IRadarChartData[];
    graphId: string = Helper.generateUniqueKey(8);
    legendId: string = Helper.generateUniqueKey(8);
    @Input() graphData: IGraphData;

    constructor() {}

    ngOnInit(): void {
        this.chartData = this.graphData.chartData as IRadarChartData[];
        Helper.loadAm4chartsLibraries().then((resolve) => {
            this.am4charts = resolve['am4charts'];
            this.am4core = resolve['am4core'];
            this.am4themesAnimated = resolve['am4themesAnimated'];
            this.drawRadarMap();
        });
    }

    drawRadarMap() {
        this.disposeChart();

        if (!document.getElementById(this.graphId)) {
            setTimeout(() => {
                this.drawRadarMap();
            }, 10);
            return;
        }
        // Themes begin
        this.am4core.useTheme(this.am4themesAnimated);
        // Themes end
        this.chart = this.am4core.create(
            this.graphId,
            this.am4charts.RadarChart
        );
        this.chart.hiddenState.properties.opacity = 5; // this creates initial fade-in
        this.chart.data = this.graphData.chartData;
        this.chart.colors.step = 3;
        this.chart.startAngle = -90;
        this.chart.endAngle = 180;
        this.chart.innerRadius = this.am4core.percent(20);
        this.chart.numberFormatter.numberFormat = "#.#'%'";

        const categoryAxis = this.chart.yAxes.push(
            new this.am4charts.CategoryAxis()
        );
        categoryAxis.dataFields.category = 'category';
        categoryAxis.renderer.labels.template.location = 0.5;
        categoryAxis.renderer.labels.template.horizontalCenter = 'right';
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.tooltipLocation = 0.5;
        categoryAxis.renderer.grid.template.strokeOpacity = 0;
        categoryAxis.renderer.axisFills.template.disabled = true;
        categoryAxis.interactionsEnabled = false;
        categoryAxis.renderer.minGridDistance = 10;
        categoryAxis.renderer.labels.template.fontWeight = 400;
        categoryAxis.renderer.labels.template.fontSize = 13;
        const valueAxis = this.chart.xAxes.push(new this.am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.labels.template.horizontalCenter = 'left';
        valueAxis.min = 0;
        valueAxis.max = 100;
        valueAxis.strictMinMax = true;
        valueAxis.renderer.maxLabelPosition = 1;
        valueAxis.renderer.minGridDistance = 10;
        valueAxis.renderer.grid.template.strokeOpacity = 0;
        valueAxis.renderer.axisFills.template.disabled = true;
        valueAxis.interactionsEnabled = false;
        valueAxis.renderer.labels.template.fontSize = 10;

        const generateSeries = (data: {
            seriesName: string;
            valueX: string;
            color: string;
        }) => {
            const series = this.chart.series.push(
                new this.am4charts.RadarColumnSeries()
            );
            series.columns.template.tooltipText = '{name}: {valueX.value}';
            series.name = data.seriesName;
            series.dataFields.categoryY = 'category';
            series.dataFields.valueX = data.valueX;
            series.stacked = true;
            series.columns.template.fill = this.am4core.color(data.color);
            series.tooltip.getFillFromObject = false;
            series.tooltip.getStrokeFromObject = true;
            series.tooltip.background.fill = this.am4core.color(
                Helper.convertToHexIfHsl(Helper.getCssVarValue('commonBgColor'))
            );
            series.tooltip.autoTextColor = false;
            series.tooltip.label.fill = this.am4core.color(
                Helper.convertToHexIfHsl(
                    Helper.getCssVarValue('graphTooltipLabelColor')
                )
            );
            series.tooltip.label.fontSize = Helper.getCssVarValue('fs200');
            series.strokeWidth = 0;
        };

        this.graphData.seriesList.forEach((data) => {
            generateSeries(data);
        });
        this.chart.seriesContainer.zIndex = -1;
    }

    disposeChart() {
        if (this.chart) {
            this.chart.dispose();
            this.chart = null;
        }
    }

    ngOnDestroy(): void {
        this.disposeChart();
    }
}
