<div class="container">
    <ng-container *ngIf="content; else loader">
        <div class="step" *ngFor="let step of content; index as i">
            <div class="step-number">{{ i + 1 }}.</div>
            <div class="each-step">
                <p [innerHTML]="step['id']"></p>

                <ul
                    *ngIf="
                        step &&
                        step['internalList'] &&
                        step['internalList'].length
                    "
                >
                    <ng-container *ngFor="let data of step['internalList']">
                        <li *ngIf="data['id']">
                            {{ data["id"] }}
                        </li>

                        <ul
                            *ngIf="
                                data &&
                                data['internalList'] &&
                                data['internalList'].length
                            "
                        >
                            <li
                                *ngFor="
                                    let internalList of data['internalList']
                                "
                            >
                                {{ internalList["id"] }}
                            </li>
                        </ul>
                    </ng-container>
                </ul>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #loader>
    <div class="overlay-content">
        <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
    </div>
</ng-template>
