import { ButtonColorType } from '../../enums/ButtonColorType';
import { ButtonType } from '../../enums/ButtonType';
import { IIcon } from '../icon-data/IIcon';
import { IConfirmationModal } from '../modal/IConfirmationModal';
import { IApiInfo } from './../api/IApiInfo';

export interface IButtonGeneratorInput {
    buttonName: string; //name is mandatory and will have to give name to every type of buttons.
    buttonColorType: ButtonColorType;
    buttonType: ButtonType;
    hide?: boolean;
    hoverText?: string;
    buttonIcon?: IIcon;
    buttonIconPrefix?: IIcon;
    function: any;
    loader?: boolean;
    showLoader?: boolean;
    disable?: boolean;
    customClass?: string;
    hostClass?: string; //This class directly applies to app-button-gen in multi-button-gen comp
    link?: string;
    linkTargetValue?: HtmlTargetAttributeValue;
    fileType?: string[];
    executeFunctionAfterConfirmation?: IConfirmationModal;
    preventHoverEffect?: boolean; // Only handled for stroked
    preventBorder?: boolean;
    iconButtonType?: 'filled' | 'default';
    fileName?: string; // Only works in button which has type Link
    clickableEffect?: boolean;
    apiInfo?: IApiInfo;
    apiSuccessMessage?: string;
    disableRipple?: boolean;
    hoverEffect?: 'filled' | 'shadow'; //Only handled for stroked button
    tooltipPosition?: TooltipPosition;
    blurEffect?: boolean; // Handled only for buttonType = TEXT
    matMenu?: any; // Material menu refrance to show onclick
}
export interface IMultiButtonOption {
    extraClass?: string;
    disable?: boolean;
    layout?: {
        justifyContent?:
            | 'center'
            | 'space-between'
            | 'space-around'
            | 'space-evenly'
            | 'flex-start'
            | 'flex-end';
        alignItems?:
            | 'stretch'
            | 'center'
            | 'flex-start'
            | 'flex-end'
            | 'baseline';
    };
}

export enum HtmlTargetAttributeValue {
    BLANK = '_blank',
    SELF = '_self',
    PARENT = '_parent',
    TOP = '_top',
    FRAMENAME = 'framename'
}

export enum TooltipPosition {
    LEFT = 'left',
    RIGHT = 'right',
    ABOVE = 'above',
    BELOW = 'below',
    BEFORE = 'before',
    AFTER = 'after'
}
