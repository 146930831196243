import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

/**
 * Validator for validating value greater than other form controls value
 */

export class GreaterThanValidator extends CustomValidator {
    key = CustomValidatorKey.GREATER_THAN_CONTROLS;

    constructor(private controlNames: string[]) {
        super();
    }

    validatorFunction(control: AbstractControl): ValidationErrors {
        const value = control.value;
        let isGreaterThanControls = false;
        if (this.formGenRef && this.formGenRef.formGroup) {
            isGreaterThanControls = this.controlNames.every((controlName) => {
                if (this.formGenRef.formGroup.get(controlName)) {
                    const controlValue =
                        this.formGenRef.formGroup.get(controlName).value;
                    if (controlValue) {
                        if (
                            typeof controlValue === typeof value &&
                            typeof value === 'number'
                        ) {
                            return value > controlValue;
                        }
                        return false;
                    }
                    return true;
                }
                return false;
            });
            if (isGreaterThanControls) {
                return null;
            }
        }
        const obj = {};
        obj[this.key] = true;
        return obj;
    }
}
