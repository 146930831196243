import {
    ComponentFactoryResolver,
    Directive,
    ElementRef,
    Input,
    OnInit,
    SimpleChanges,
    ViewContainerRef
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { IconLibrary } from 'src/app/shared/classes/IconLibrary';
import { Icon, IconName } from '../../classes/Icon';
import { IconType } from '../../enums/IconType';
import { IIcon } from '../../interfaces/icon-data/IIcon';

@Directive({
    selector: '[appIcon]'
})
export class IconDirective implements OnInit {
    @Input() data: IIcon;
    @Input() hideText: boolean;
    @Input() iconName: IconName;

    constructor(
        private elmRef: ElementRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef
    ) {}
    ngOnInit() {
        this.configIcon();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.data =
            changes && changes.data && changes.data.currentValue
                ? changes.data.currentValue
                : null;
        this.configIcon();
    }

    configIcon() {
        if (this.iconName) {
            this.data = Icon.icons.has(this.iconName)
                ? Icon.icons.get(this.iconName)
                : null;
        }

        if (this.data) {
            if (this.data.type && this.data.type === IconType.MATICON) {
                this.elmRef.nativeElement.innerHTML = '';
                const factory =
                    this.componentFactoryResolver.resolveComponentFactory(
                        MatIcon
                    );
                const icon = this.viewContainerRef.createComponent(factory);
                this.elmRef.nativeElement.appendChild(
                    icon.location.nativeElement
                );
                this.elmRef.nativeElement.children[0].innerHTML =
                    this.data.class;
            } else if (
                this.data.type &&
                this.data.type === IconType.FONTAWSOME
            ) {
                if (
                    this.hideText ? '' : this.data.text && this.data.extraClass
                ) {
                    this.elmRef.nativeElement.innerHTML = `<i class='${
                        this.data.class
                    } ${this.data.extraClass}'></i> ${
                        this.hideText ? '' : this.data.text
                    }`;
                } else if (this.hideText ? '' : this.data.text) {
                    this.elmRef.nativeElement.innerHTML = `<i class='${
                        this.data.class
                    }'></i> ${this.hideText ? '' : this.data.text}`;
                } else if (this.data.extraClass) {
                    this.elmRef.nativeElement.innerHTML = `<i class='${this.data.class} ${this.data.extraClass}'></i>`;
                } else {
                    this.elmRef.nativeElement.innerHTML = `<i class='${this.data.class}'></i>`;
                }
            } else if (this.data.type && this.data.type === IconType.SVG) {
                let svgIconCode = IconLibrary.icon[`${this.data.class}`];
                if (!svgIconCode) {
                    svgIconCode = IconLibrary.consoleAssets.not_found;
                }
                this.elmRef.nativeElement.innerHTML = `<div class="${
                    this.data.extraClass ? this.data.extraClass : ''
                } svg-default-fill" style="width:100%;height:100%;">${svgIconCode}</div>
                ${
                    !this.hideText && this.data.text
                        ? `<span class="icon-text"> ${this.data.text} </span>`
                        : ''
                }`;
            } else if (
                this.data.type &&
                this.data.type === IconType.SVG_ASSETS
            ) {
                const svgIconCode =
                    IconLibrary.consoleAssets[`${this.data.class}`];
                this.elmRef.nativeElement.innerHTML = `<div ${
                    this.data.extraClass
                        ? `class="${this.data.extraClass}"`
                        : ''
                } style="width:100%;height:100%;">${svgIconCode}</div>`;
            } else if (this.data.type && this.data.type === IconType.IMAGE) {
                if (
                    this.hideText ? '' : this.data.text && this.data.extraClass
                ) {
                    this.elmRef.nativeElement.innerHTML = `<img style="max-height: 100%; max-width: 100%" class='${
                        this.data.extraClass
                    }' src='${this.data.class}' alt='${this.data.alt}'> ${
                        this.hideText ? '' : this.data.text
                    }`;
                } else if (this.data.extraClass) {
                    this.elmRef.nativeElement.innerHTML = `<img style="max-height: 100%; max-width: 100%" class='${this.data.extraClass}' src='${this.data.class}' alt='${this.data.alt}'>`;
                } else if (this.hideText ? '' : this.data.text) {
                    this.elmRef.nativeElement.innerHTML = `<img style="max-height: 100%; max-width: 100%" src='${
                        this.data.class
                    }' alt='${this.data.alt}'> ${
                        this.hideText ? '' : this.data.text
                    }`;
                } else {
                    this.elmRef.nativeElement.innerHTML = `<img style="max-height: 100%; max-width: 100%" src='${this.data.class}' alt='${this.data.alt}'>`;
                }
            } else if (
                this.data.type &&
                this.data.type === IconType.LINELOADER
            ) {
                this.elmRef.nativeElement.innerHTML = `
          <div class='${this.data.class}'></div>
          <div class='${this.data.class}'></div>
          <div class='${this.data.class}'></div>
          <div class='${this.data.class}'></div>
        `;
            } else if (
                this.data.type &&
                this.data.type === IconType.SPINNERLOADER
            ) {
                this.elmRef.nativeElement.innerHTML = `
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        `;
            } else if (this.data.type && this.data.type === IconType.NUMBER) {
                if (this.data.extraClass) {
                    this.elmRef.nativeElement.innerHTML = `<span class='${this.data.class} ${this.data.extraClass}'>${this.data.extraData}</span>`;
                } else {
                    this.elmRef.nativeElement.innerHTML = `<span class='${this.data.class}'>${this.data.extraData}</span>`;
                }
            }
        }
    }
}
