<div class="container">
    <div class="form-container">
        <div class="tw-flex tw-justify-center">
            <div class="tw-w-3/4">
                <app-form-generator
                    *ngIf="uploadFormGenInput"
                    [formGeneratorInput]="uploadFormGenInput"
                    (formGroupRef)="uploadFormGroup = $event"
                    (valuesChange)="updateEmailField($event)"
                    (resetFormFunction)="resetForm = $event"
                ></app-form-generator>
            </div>
        </div>
        <div class="tw-flex tw-justify-center">
            <div class="tw-w-3/4">
                <app-form-generator
                    *ngIf="emailFormGenInput"
                    [formGeneratorInput]="emailFormGenInput"
                    (formGroupRef)="emailFormGroup = $event"
                    (resetFormFunction)="resetForm = $event"
                ></app-form-generator>
            </div>
        </div>
        <div class="tw-flex tw-justify-center">
            <div class="tw-w-3/4">
                <app-form-generator
                    *ngIf="formGenInput"
                    [formGeneratorInput]="formGenInput"
                    (formGroupRef)="formGroup = $event"
                    (resetFormFunction)="resetForm = $event"
                ></app-form-generator>
            </div>
        </div>
    </div>
    <div class="buttons">
        <app-multi-button-generator [buttonGenInputs]="buttonInputs">
        </app-multi-button-generator>
    </div>
</div>
