<div class="form-container">
    <app-form-generator
        *ngIf="formGenInput"
        [formGeneratorInput]="formGenInput"
        (formGroupRef)="formGroupRef = $event"
        (valuesChange)="onValuesChange($event)"
    ></app-form-generator>
</div>
<div
    class="extra-message tw-text-center tw-relative"
    *ngIf="
        emailStatus === VerificationStatus.FAILED ||
        emailStatus === VerificationStatus.PENDING
    "
    [ngClass]="
        emailStatus === VerificationStatus.FAILED
            ? 'invalid-email'
            : emailStatus === VerificationStatus.PENDING
            ? 'pending-approval'
            : ''
    "
>
    <span class="tw-pl-1">
        {{
            emailStatus === VerificationStatus.FAILED
                ? "Please enter a valid email address."
                : "Please visit your email to verify."
        }}
    </span>
</div>
<div class="control-buttons tw-py-3">
    <app-button-generator
        [buttonGenInput]="previewButtonGenInput"
    ></app-button-generator>
    <app-button-generator
        [buttonGenInput]="saveButtonGenInput"
    ></app-button-generator>
</div>

<ng-template #emailStatusRef>
    <ng-container *ngIf="emailStatus === VerificationStatus.SUCCESS">
        <div
            class="tw-flex tw-justify-start tw-items-center tw-text-positive"
            appIcon
            [data]="successStatusIcon"
        ></div>
    </ng-container>
    <ng-container *ngIf="emailStatus === VerificationStatus.FAILED">
        <div
            class="tw-flex tw-justify-start tw-items-center tw-text-negative"
            appIcon
            [data]="failedStatusIcon"
        ></div>
    </ng-container>
    <ng-container *ngIf="emailStatus === VerificationStatus.PENDING">
        <div
            class="pending-container tw-flex tw-justify-between tw-items-center tw-relative"
        >
            <div
                class="tw-flex tw-justify-start tw-items-center tw-text-neutral"
                appIcon
                [data]="pendingStatusIcon"
            ></div>
            <app-button-generator
                class="tw-absolute tw-left-full"
                [class.spinning]="isEmailStatusUpdating"
                [buttonGenInput]="updateEmailStatusBtn"
                [matTooltip]="'Click to refesh status'"
            ></app-button-generator>
        </div>
    </ng-container>
</ng-template>
