<div class="container">
    <div class="form-container">
        <div class="form-field-wrapper">
            <div class="form-field">
                <div *ngIf="totalUsers" class="form-field-types">
                    <div
                        class="form-field-heading tw-pt-1 tw-pb-4 tw-font-semibold"
                    >
                        {{ formFieldHeading }}
                    </div>
                    <app-table-search
                        class="tw-flex"
                        [data]="users"
                        (changed)="totalUsers = $event"
                    ></app-table-search>
                    <ng-container *ngFor="let data of totalUsers; index as i">
                        <div class="tw-flex tw-align-center index-item">
                            <mat-checkbox
                                (change)="removeUser(data, $event.checked)"
                                [checked]="isChecked.get(data.id)"
                                color="accent"
                                >{{ data.username }}</mat-checkbox
                            >
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form-area">
            <div class="form-name">
                <span>Selected Users</span>
            </div>
            <div class="form-drop-area">
                <div>
                    <div
                        *ngFor="let userData of configuredUsers; index as i"
                        class="form-drop-area-item tw-flex tw-justify-between tw-pt-2"
                    >
                        <div class="form-drop-area-heading tw-font-medium">
                            {{ userData.username }}
                        </div>
                        <div class="action-buttons">
                            <div
                                class="delete-icon"
                                appIcon
                                [data]="deleteIcon"
                                (click)="removeUser(userData, false)"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-info">
        <div class="info-icon">
            <i class="custom-fa-icon fa fa-info-circle"></i>
        </div>
        <div class="info-message">
            <p>
                Credentials will remain hidden for the selected users. They can
                only view and create cases via Salesforce Desk Widget.
            </p>
        </div>
    </div>
    <div class="buttons">
        <app-multi-button-generator [buttonGenInputs]="buttonInputs">
        </app-multi-button-generator>
    </div>
</div>
