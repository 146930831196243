import { CustomValidators } from '../../classes/CustomValidators';
import { FilterType } from '../../enums/FilterType';
import { FormState } from '../../enums/FormState';
import { IFormGeneratorInput } from './../../interfaces/form-generator/IFormGeneratorInput';

export enum FieldConfig {
    LABEL_VALUE = 'labelValue',
    PLACE_HOLDER_VALUE = 'placeHolderValue',
    REQUIRED_VALUE = 'requiredValue',
    SHOW_CASE_FIELD = 'showcaseField',
    DROPDOWN_VALUE = 'dropdownValue',
    NUMBER_MIN_VALUE = 'numberMinValue',
    NUMBER_MAX_VALUE = 'numberMaxValue',
    STRING_FIELD_VALUE = 'textValue',
    NUMBER_FIELD_VALUE = 'numberValue',
    BOOLEAN_FIELD_VALUE = 'booleanValue',
    FIELD_VALUE = 'fieldValue'
}

export enum FormType {
    SHORT_TEXT = 'Short Text',
    LONG_TEXT = 'Long Text',
    NUMBERS = 'Numbers',
    DATE_TIME = 'Date and Time',
    SINGLE_SELECT = 'Single Select Drop Down',
    MULTI_SELECT = 'Multi Select Drop Down',
    TEXT = 'TEXT',
    TEXTAREA = 'TEXTAREA',
    DATE = 'DATE',
    BOOLEAN = 'BOOLEAN'
}

export const formBuilder: IFormGeneratorInput[] = [
    {
        formName: FormType.SHORT_TEXT,
        submitButton: null,
        state: FormState.IDLE,
        fields: [
            {
                label: 'Short Text',
                placeholder: 'Place Holder Value',
                name: 'shortText',
                fieldType: FilterType.SHORT_TEXT,
                required: false,
                value: 'Place Holder Value',
                extraData: FilterType.SHORT_TEXT
            }
        ]
    },
    {
        formName: FormType.LONG_TEXT,
        submitButton: null,
        state: FormState.IDLE,
        fields: [
            {
                label: 'Long Text',
                placeholder: 'Place Holder Value',
                name: 'longText',
                fieldType: FilterType.TEXTAREA,
                required: false,
                extraData: FilterType.TEXTAREA
            }
        ]
    },
    {
        formName: FormType.NUMBERS,
        submitButton: null,
        state: FormState.IDLE,
        fields: [
            {
                label: 'Numbers',
                placeholder: 'Place Holder Value',
                name: 'numbers',
                fieldType: FilterType.TEXT,
                required: false,
                value: 'Place Holder Value',
                extraData: FilterType.NUMBER
            }
        ]
    },
    {
        formName: FormType.DATE_TIME,
        submitButton: null,
        state: FormState.IDLE,
        fields: [
            {
                label: 'Date and Time',
                placeholder: 'Place Holder Value',
                name: 'dateTime',
                fieldType: FilterType.TEXT,
                required: false,
                extraData: FilterType.DATE_TIME
            }
        ]
    },
    {
        formName: FormType.SINGLE_SELECT,
        submitButton: null,
        state: FormState.CREATE,
        fields: [
            {
                label: 'Single select',
                placeholder: 'Place Holder Value',
                name: 'singleSelect',
                fieldType: FilterType.DROPDOWN_SINGLE,
                required: false
            }
        ]
    },
    {
        formName: FormType.MULTI_SELECT,
        submitButton: null,
        state: FormState.CREATE,
        fields: [
            {
                label: 'Multi select',
                placeholder: 'Place Holder Value',
                name: 'multiSelect',
                fieldType: FilterType.DROPDOWN_MULTIPLE,
                required: false
            }
        ]
    }
];

export const formConfiguration = {
    [FormType.SHORT_TEXT]: {
        formName: 'Short Text Configure',
        submitButton: null,
        state: FormState.CREATE,
        fields: [
            {
                label: 'Field Label',
                placeholder: 'Type in Field Label',
                name: FieldConfig.LABEL_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                value: 'Short Text',
                hideFloatLabel: true
            },
            {
                label: 'Place Holder Value',
                placeholder: 'Type in Place Holder Value',
                name: FieldConfig.PLACE_HOLDER_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                value: 'Place Holder Value',
                hideFloatLabel: true
            },
            {
                label: 'Field Type',
                placeholder: 'Short Text',
                name: FieldConfig.SHOW_CASE_FIELD,
                fieldType: FilterType.TEXT,
                required: false,
                value: 'Short Text',
                disabled: true,
                hideFloatLabel: true
            },
            {
                label: 'Make field required ?',
                placeholder: 'Place Holder Value',
                name: FieldConfig.REQUIRED_VALUE,
                fieldType: FilterType.CHECKBOX,
                required: false,
                value: true,
                extraClass: 'required-checkbox accent-checkbox'
            }
        ]
    },
    [FormType.LONG_TEXT]: {
        formName: 'Long Text Configure',
        submitButton: null,
        state: FormState.CREATE,
        fields: [
            {
                label: 'Field Label',
                placeholder: 'Type in Field Label',
                name: FieldConfig.LABEL_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                value: 'Long Text',
                hideFloatLabel: true
            },

            {
                label: 'Place Holder Value',
                placeholder: 'Type in Place Holder Value',
                name: FieldConfig.PLACE_HOLDER_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                value: 'Place Holder Value',
                hideFloatLabel: true
            },
            {
                label: 'Field Type',
                placeholder: 'Long Text',
                name: FieldConfig.SHOW_CASE_FIELD,
                fieldType: FilterType.TEXT,
                required: false,
                value: 'Long Text',
                disabled: true,
                hideFloatLabel: true
            },
            {
                label: 'Make field required ?',
                placeholder: 'Place Holder Value',
                name: FieldConfig.REQUIRED_VALUE,
                fieldType: FilterType.CHECKBOX,
                required: false,
                value: true,
                extraClass: 'required-checkbox accent-checkbox'
            }
        ]
    },
    [FormType.NUMBERS]: {
        formName: 'Numbers Configure',
        submitButton: null,
        state: FormState.CREATE,
        fields: [
            {
                label: 'Field Label',
                placeholder: 'Type in Field Label',
                name: FieldConfig.LABEL_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                value: 'Numbers',
                hideFloatLabel: true
            },

            {
                label: 'Place Holder Value',
                placeholder: 'Type in Place Holder Value',
                name: FieldConfig.PLACE_HOLDER_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                value: 'Place Holder Value',
                hideFloatLabel: true
            },

            {
                label: 'Field Type',
                placeholder: 'Numbers',
                name: FieldConfig.SHOW_CASE_FIELD,
                fieldType: FilterType.TEXT,
                required: false,
                value: 'Numbers',
                disabled: true,
                hideFloatLabel: true
            },
            {
                label: 'Make field required ?',
                placeholder: 'Place Holder Value',
                name: FieldConfig.REQUIRED_VALUE,
                fieldType: FilterType.CHECKBOX,
                required: false,
                value: true,
                extraClass: 'required-checkbox accent-checkbox'
            }
        ]
    },
    [FormType.DATE_TIME]: {
        formName: 'Date and Time Configure',
        submitButton: null,
        state: FormState.CREATE,
        fields: [
            {
                label: 'Field Label',
                placeholder: 'Type in Field Label',
                name: FieldConfig.LABEL_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                value: 'Numbers',
                hideFloatLabel: true
            },

            {
                label: 'Place Holder Value',
                placeholder: 'Type in Place Holder Value',
                name: FieldConfig.PLACE_HOLDER_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                value: 'Place Holder Value',
                hideFloatLabel: true
            },
            {
                label: 'Field Type',
                placeholder: 'Date and Time',
                name: FieldConfig.SHOW_CASE_FIELD,
                fieldType: FilterType.TEXT,
                required: false,
                value: 'Date and Time',
                disabled: true,
                hideFloatLabel: true
            },
            {
                label: 'Make field required ?',
                placeholder: 'Place Holder Value',
                name: FieldConfig.REQUIRED_VALUE,
                fieldType: FilterType.CHECKBOX,
                required: false,
                value: true,
                extraClass: 'required-checkbox accent-checkbox'
            }
        ]
    },
    [FormType.SINGLE_SELECT]: {
        formName: 'Short Text Configure',
        submitButton: null,
        state: FormState.CREATE,
        fields: [
            {
                label: 'Field Label',
                placeholder: 'Type in Field Label',
                name: FieldConfig.LABEL_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                value: 'Single Select Drop Down',
                hideFloatLabel: true
            },

            {
                label: 'Place Holder Value',
                placeholder: 'Type in Place Holder Value',
                name: FieldConfig.PLACE_HOLDER_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                value: 'Place Holder Value',
                hideFloatLabel: true
            },

            {
                label: 'Value',
                placeholder: 'Value',
                name: FieldConfig.DROPDOWN_VALUE,
                fieldType: FilterType.TEXT,
                required: true,
                value: 'Value 1',
                suffixTemplate: null,
                hideFloatLabel: true
            },

            {
                label: 'Field Type',
                placeholder: 'Single Select Drop Down',
                name: FieldConfig.SHOW_CASE_FIELD,
                fieldType: FilterType.TEXT,
                required: false,
                value: 'Single Select Drop Down',
                disabled: true,
                hideFloatLabel: true
            },
            {
                label: 'Make field required ?',
                placeholder: 'Place Holder Value',
                name: FieldConfig.REQUIRED_VALUE,
                fieldType: FilterType.CHECKBOX,
                required: false,
                value: true,
                extraClass: 'required-checkbox accent-checkbox'
            }
        ]
    },
    [FormType.MULTI_SELECT]: {
        formName: 'Short Text Configure',
        submitButton: null,
        state: FormState.CREATE,
        fields: [
            {
                label: 'Field Label',
                placeholder: 'Type in Field Label',
                name: FieldConfig.LABEL_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                value: 'Multi Select Drop Down',
                hideFloatLabel: true
            },

            {
                label: 'Place Holder Value',
                placeholder: 'Type in Place Holder Value',
                name: FieldConfig.PLACE_HOLDER_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                value: 'Place Holder Value',
                hideFloatLabel: true
            },
            {
                label: 'Value',
                placeholder: 'Value',
                name: FieldConfig.DROPDOWN_VALUE,
                fieldType: FilterType.TEXT,
                required: true,
                value: 'Value 1',
                suffixTemplate: null,
                hideFloatLabel: true
            },
            {
                label: 'Field Type',
                placeholder: 'Multi Select Drop Down',
                name: FieldConfig.SHOW_CASE_FIELD,
                fieldType: FilterType.TEXT,
                required: false,
                value: 'Multi Select Drop Down',
                disabled: true,
                hideFloatLabel: true
            },
            {
                label: 'Make field required ?',
                placeholder: 'Place Holder Value',
                name: FieldConfig.REQUIRED_VALUE,
                fieldType: FilterType.CHECKBOX,
                required: false,
                value: true,
                extraClass: 'required-checkbox accent-checkbox'
            }
        ]
    }
};
export const terraFormConfiguration = {
    [FormType.TEXT]: {
        formName: 'Configure Variable',
        submitButton: null,
        state: FormState.CREATE,
        fields: [
            {
                label: 'Variable Name',
                placeholder: 'Type In Variable Name',
                name: FieldConfig.LABEL_VALUE,
                fieldType: FilterType.TEXT,
                required: true,
                value: '',
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'This field is required'
                    }
                ],
                hideFloatLabel: true
            },
            {
                label: 'Description',
                placeholder: 'Type in Description',
                name: FieldConfig.PLACE_HOLDER_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                value: '',
                hideFloatLabel: true
            },
            {
                label: 'Form Field Type',
                placeholder: 'Form Field Type',
                name: FieldConfig.SHOW_CASE_FIELD,
                fieldType: FilterType.DROPDOWN_SINGLE,
                required: true,
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'This field is required'
                    }
                ],
                hideFloatLabel: true
            },
            {
                label: 'Value',
                placeholder: 'Add Value',
                name: FieldConfig.STRING_FIELD_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                hiddenDependency: [
                    {
                        controlName: 'showcaseField',
                        validations: [
                            {
                                validator:
                                    CustomValidators.controlValueMatch(
                                        'SHORT_TEXT'
                                    )
                            }
                        ]
                    }
                ],
                hideFloatLabel: true
            },
            {
                label: 'Value',
                placeholder: 'Add Value',
                name: FieldConfig.NUMBER_FIELD_VALUE,
                fieldType: FilterType.NUMBER,
                required: false,
                hiddenDependency: [
                    {
                        controlName: 'showcaseField',
                        validations: [
                            {
                                validator: CustomValidators.required
                            },
                            {
                                validator: CustomValidators.controlValueMatch(
                                    FormType.NUMBERS
                                )
                            }
                        ]
                    }
                ],
                hideFloatLabel: true
            },
            {
                label: 'Value',
                placeholder: 'Select Value',
                name: FieldConfig.BOOLEAN_FIELD_VALUE,
                fieldType: FilterType.DROPDOWN_SINGLE,
                listData: [
                    { id: 'true', label: 'TRUE' },
                    { id: 'false', label: 'FALSE' }
                ],
                required: false,
                hiddenDependency: [
                    {
                        controlName: 'showcaseField',
                        validations: [
                            {
                                validator: CustomValidators.required
                            },
                            {
                                validator: CustomValidators.controlValueMatch(
                                    FormType.BOOLEAN
                                )
                            }
                        ]
                    }
                ],
                hideFloatLabel: true
            }
        ]
    }
};
export const salesforceFormConfiguration = {
    [FilterType.SHORT_TEXT]: {
        formName: 'Short Text Configure',
        submitButton: null,
        state: FormState.CREATE,
        formId: Symbol(),
        fields: [
            {
                label: 'Input Field Name',
                placeholder: 'Type Input Field Name',
                name: FieldConfig.LABEL_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                showLabel: true,
                appearance: 'legacy',
                value: 'Short Text',
                hideFloatLabel: true
            },
            {
                label: 'Place Holder Value',
                placeholder: 'Type in Place Holder Value',
                name: FieldConfig.PLACE_HOLDER_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                hideFloatLabel: true,
                appearance: 'legacy',
                showLabel: true
            },
            {
                label: 'Field Type',
                placeholder: 'Short Text',
                name: FieldConfig.SHOW_CASE_FIELD,
                fieldType: FilterType.CONTENT,
                required: false,
                value: 'Short Text',
                hideFloatLabel: true,
                appearance: 'legacy',
                showLabel: true,
                extraClass: 'content-input form-content-label-accent'
            },
            {
                label: 'Default Value',
                placeholder: 'Type in default value',
                name: FieldConfig.FIELD_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                value: '',
                hideFloatLabel: true,
                showLabel: true,
                appearance: 'legacy'
            },
            {
                label: 'Make field required ?',
                placeholder: 'Place Holder Value',
                name: FieldConfig.REQUIRED_VALUE,
                fieldType: FilterType.CHECKBOX,
                required: false,
                value: true,
                extraClass: 'required-checkbox accent-checkbox',
                showLabel: true,
                appearance: 'legacy'
            }
        ]
    },
    [FilterType.LONG_TEXT]: {
        formName: 'Short Text Configure',
        submitButton: null,
        state: FormState.CREATE,
        formId: Symbol(),
        fields: [
            {
                label: 'Input Field Name',
                placeholder: 'Type Input Field Name',
                name: FieldConfig.LABEL_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                showLabel: true,
                appearance: 'legacy',
                value: 'Long Text',
                hideFloatLabel: true
            },
            {
                label: 'Place Holder Value',
                placeholder: 'Type in Place Holder Value',
                name: FieldConfig.PLACE_HOLDER_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                hideFloatLabel: true,
                appearance: 'legacy',
                showLabel: true
            },
            {
                label: 'Field Type',
                placeholder: 'Long Text',
                name: FieldConfig.SHOW_CASE_FIELD,
                fieldType: FilterType.CONTENT,
                required: false,
                value: 'Long Text',
                hideFloatLabel: true,
                appearance: 'legacy',
                showLabel: true,
                extraClass: 'content-input form-content-label-accent'
            },
            {
                label: 'Default Value',
                placeholder: 'Type in default value',
                name: FieldConfig.FIELD_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                value: '',
                hideFloatLabel: true,
                showLabel: true,
                appearance: 'legacy'
            },
            {
                label: 'Make field required ?',
                placeholder: 'Place Holder Value',
                name: FieldConfig.REQUIRED_VALUE,
                fieldType: FilterType.CHECKBOX,
                required: false,
                value: true,
                extraClass: 'required-checkbox accent-checkbox',
                showLabel: true,
                appearance: 'legacy'
            }
        ]
    },
    [FilterType.NUMBER]: {
        formName: 'Numbers Configure',
        submitButton: null,
        state: FormState.CREATE,
        formId: Symbol(),
        fields: [
            {
                label: 'Input Field Name',
                placeholder: 'Type Input Field Name',
                name: FieldConfig.LABEL_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                appearance: 'legacy',
                value: 'Number',
                showLabel: true,
                hideFloatLabel: true
            },
            {
                label: 'Place Holder Value',
                placeholder: 'Type in Place Holder Value',
                name: FieldConfig.PLACE_HOLDER_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                hideFloatLabel: true,
                showLabel: true,
                appearance: 'legacy'
            },
            {
                label: 'Field Type',
                placeholder: '  Number',
                name: FieldConfig.SHOW_CASE_FIELD,
                fieldType: FilterType.CONTENT,
                required: false,
                value: 'Number',
                hideFloatLabel: true,
                appearance: 'legacy',
                showLabel: true,
                extraClass: 'content-input form-content-label-accent'
            },
            {
                label: 'Default Value',
                placeholder: 'Type in default value',
                name: FieldConfig.FIELD_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                value: '',
                hideFloatLabel: true,
                showLabel: true,
                appearance: 'legacy'
            },
            {
                label: 'Make field required ?',
                placeholder: 'Place Holder Value',
                name: FieldConfig.REQUIRED_VALUE,
                fieldType: FilterType.CHECKBOX,
                required: false,
                value: true,
                extraClass: 'required-checkbox accent-checkbox',
                showLabel: true,
                appearance: 'legacy'
            }
        ]
    },
    [FilterType.DATE_TIME]: {
        formName: 'Date and Time Configure',
        submitButton: null,
        state: FormState.CREATE,
        formId: Symbol(),
        fields: [
            {
                label: 'Input Field Name',
                placeholder: 'Type Input Field Name',
                name: FieldConfig.LABEL_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                appearance: 'legacy',
                value: 'Date Time',
                showLabel: true,
                hideFloatLabel: true
            },
            {
                label: 'Place Holder Value',
                placeholder: 'Type in Place Holder Value',
                name: FieldConfig.PLACE_HOLDER_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                hideFloatLabel: true,
                showLabel: true,
                appearance: 'legacy'
            },
            {
                label: 'Field Type',
                placeholder: 'Date Time',
                name: FieldConfig.SHOW_CASE_FIELD,
                fieldType: FilterType.CONTENT,
                required: false,
                value: 'Date Time',
                hideFloatLabel: true,
                appearance: 'legacy',
                showLabel: true,
                extraClass: 'content-input form-content-label-accent'
            },
            {
                label: 'Make field required ?',
                placeholder: 'Place Holder Value',
                name: FieldConfig.REQUIRED_VALUE,
                fieldType: FilterType.CHECKBOX,
                required: false,
                value: true,
                showLabel: true,
                extraClass: 'required-checkbox accent-checkbox',
                appearance: 'legacy'
            }
        ]
    },
    [FilterType.DROPDOWN_SINGLE]: {
        formName: 'Short Text Configure',
        submitButton: null,
        state: FormState.CREATE,
        formId: Symbol(),
        fields: [
            {
                label: 'Input Field Name',
                placeholder: 'Type Input Field Name',
                name: FieldConfig.LABEL_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                appearance: 'legacy',
                value: 'Dropdown Single',
                showLabel: true,
                hideFloatLabel: true
            },
            {
                label: 'Place Holder Value',
                placeholder: 'Type in Place Holder Value',
                name: FieldConfig.PLACE_HOLDER_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                hideFloatLabel: true,
                showLabel: true,
                appearance: 'legacy'
            },
            {
                label: 'Field Type',
                placeholder: 'Dropdown Single',
                name: FieldConfig.SHOW_CASE_FIELD,
                fieldType: FilterType.CONTENT,
                required: false,
                value: 'Dropdown Single',
                hideFloatLabel: true,
                appearance: 'legacy',
                showLabel: true,
                extraClass: 'content-input form-content-label-accent'
            },
            {
                label: 'Select Default Value',
                placeholder: 'Select default value',
                name: FieldConfig.FIELD_VALUE,
                fieldType: FilterType.DROPDOWN_SINGLE,
                required: false,
                hideFloatLabel: true,
                showLabel: true,
                appearance: 'legacy'
            },
            {
                label: 'Make field required ?',
                placeholder: 'Place Holder Value',
                name: FieldConfig.REQUIRED_VALUE,
                fieldType: FilterType.CHECKBOX,
                required: false,
                value: true,
                extraClass: 'required-checkbox accent-checkbox',
                showLabel: true,
                appearance: 'legacy'
            }
        ]
    },
    [FilterType.DROPDOWN_MULTIPLE]: {
        formName: 'Short Text Configure',
        submitButton: null,
        state: FormState.CREATE,
        formId: Symbol(),
        fields: [
            {
                label: 'Input Field Name',
                placeholder: 'Type Input Field Name',
                name: FieldConfig.LABEL_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                appearance: 'legacy',
                value: 'Dropdown Multiple',
                showLabel: true,
                hideFloatLabel: true
            },
            {
                label: 'Place Holder Value',
                placeholder: 'Type in Place Holder Value',
                name: FieldConfig.PLACE_HOLDER_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                hideFloatLabel: true,
                showLabel: true,
                appearance: 'legacy'
            },
            {
                label: 'Field Type',
                placeholder: 'Dropdown Multiple',
                name: FieldConfig.SHOW_CASE_FIELD,
                fieldType: FilterType.CONTENT,
                required: false,
                value: 'Dropdown Multiple',
                hideFloatLabel: true,
                appearance: 'legacy',
                showLabel: true,
                extraClass: 'content-input form-content-label-accent'
            },
            {
                label: 'Default Value',
                placeholder: 'Type in default value',
                name: FieldConfig.FIELD_VALUE,
                fieldType: FilterType.DROPDOWN_MULTIPLE,
                required: false,
                hideFloatLabel: true,
                showLabel: true,
                appearance: 'legacy'
            },
            {
                label: 'Make field required ?',
                placeholder: 'Place Holder Value',
                name: FieldConfig.REQUIRED_VALUE,
                fieldType: FilterType.CHECKBOX,
                required: false,
                value: true,
                extraClass: 'required-checkbox accent-checkbox',
                showLabel: true,
                appearance: 'legacy'
            }
        ]
    },
    [FilterType.BOOLEAN]: {
        formName: 'Short Text Configure',
        submitButton: null,
        state: FormState.CREATE,
        formId: Symbol(),
        fields: [
            {
                label: 'Input Field Name',
                placeholder: 'Type Input Field Name',
                name: FieldConfig.LABEL_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                appearance: 'legacy',
                value: 'Boolean',
                showLabel: true,
                hideFloatLabel: true
            },
            {
                label: 'Place Holder Value',
                placeholder: 'Type in Place Holder Value',
                name: FieldConfig.PLACE_HOLDER_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                hideFloatLabel: true,
                showLabel: true,
                appearance: 'legacy'
            },
            {
                label: 'Field Type',
                placeholder: 'Boolean',
                name: FieldConfig.SHOW_CASE_FIELD,
                fieldType: FilterType.CONTENT,
                required: false,
                value: 'Boolean',
                hideFloatLabel: true,
                appearance: 'legacy',
                showLabel: true,
                extraClass: 'content-input form-content-label-accent'
            },
            {
                label: 'Default Value',
                placeholder: 'Type in default value',
                name: FieldConfig.FIELD_VALUE,
                fieldType: FilterType.DROPDOWN_SINGLE,
                required: false,
                hideFloatLabel: true,
                appearance: 'legacy',
                showLabel: true,
                listData: [
                    { id: 'true', label: 'TRUE' },
                    { id: 'false', label: 'FALSE' }
                ]
            },
            {
                label: 'Make field required ?',
                placeholder: 'Place Holder Value',
                name: FieldConfig.REQUIRED_VALUE,
                fieldType: FilterType.CHECKBOX,
                required: false,
                value: true,
                showLabel: true,
                extraClass: 'required-checkbox accent-checkbox',
                appearance: 'legacy'
            }
        ]
    },
    [FilterType.CURRENCY]: {
        formName: 'Currency Configure',
        submitButton: null,
        state: FormState.CREATE,
        formId: Symbol(),
        fields: [
            {
                label: 'Input Field Name',
                placeholder: 'Type Input Field Name',
                name: FieldConfig.LABEL_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                appearance: 'legacy',
                value: 'Currency',
                showLabel: true,
                hideFloatLabel: true
            },
            {
                label: 'Place Holder Value',
                placeholder: 'Type in Place Holder Value',
                name: FieldConfig.PLACE_HOLDER_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                hideFloatLabel: true,
                showLabel: true,
                appearance: 'legacy'
            },
            {
                label: 'Field Type',
                placeholder: 'Currency',
                name: FieldConfig.SHOW_CASE_FIELD,
                fieldType: FilterType.CONTENT,
                required: false,
                value: 'CURRENCY',
                hideFloatLabel: true,
                appearance: 'legacy',
                showLabel: true,
                extraClass: 'content-input form-content-label-accent'
            },
            {
                label: 'Default Value',
                placeholder: 'Type in default value',
                name: FieldConfig.FIELD_VALUE,
                fieldType: FilterType.NUMBER,
                required: false,
                value: '',
                hideFloatLabel: true,
                showLabel: true,
                appearance: 'legacy'
            },
            {
                label: 'Make field required ?',
                placeholder: 'Place Holder Value',
                name: FieldConfig.REQUIRED_VALUE,
                fieldType: FilterType.CHECKBOX,
                required: false,
                value: true,
                showLabel: true,
                extraClass: 'required-checkbox accent-checkbox',
                appearance: 'legacy'
            }
        ]
    },
    [FilterType.TIME]: {
        formName: 'Time Configure',
        submitButton: null,
        state: FormState.CREATE,
        formId: Symbol(),
        fields: [
            {
                label: 'Input Field Name',
                placeholder: 'Type Input Field Name',
                name: FieldConfig.LABEL_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                appearance: 'legacy',
                value: 'Time',
                showLabel: true,
                hideFloatLabel: true
            },
            {
                label: 'Place Holder Value',
                placeholder: 'Type in Place Holder Value',
                name: FieldConfig.PLACE_HOLDER_VALUE,
                fieldType: FilterType.TEXT,
                required: false,
                hideFloatLabel: true,
                showLabel: true,
                appearance: 'legacy'
            },
            {
                label: 'Field Type',
                placeholder: 'Time',
                name: FieldConfig.SHOW_CASE_FIELD,
                fieldType: FilterType.CONTENT,
                required: false,
                value: 'TIME',
                hideFloatLabel: true,
                appearance: 'legacy',
                showLabel: true,
                extraClass: 'content-input form-content-label-accent'
            },
            {
                label: 'Default Value',
                placeholder: 'Type in default value',
                name: FieldConfig.FIELD_VALUE,
                fieldType: FilterType.TIME,
                required: false,
                value: null,
                hideFloatLabel: true,
                showLabel: true,
                appearance: 'legacy'
            },
            {
                label: 'Make field required ?',
                placeholder: 'Place Holder Value',
                name: FieldConfig.REQUIRED_VALUE,
                fieldType: FilterType.CHECKBOX,
                required: false,
                value: true,
                showLabel: true,
                extraClass: 'required-checkbox accent-checkbox',
                appearance: 'legacy'
            }
        ]
    }
};
