import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { UpdateAction } from 'src/app/shared/enums/UpdateAction';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IUpdateAction } from 'src/app/shared/interfaces/update-action/IUpdateAction';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { FilterCacheService } from 'src/app/shared/services/cache/filters-cache/filter-cache.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-azure-create-vm-provisioning',
    templateUrl: './azure-create-vm-provisioning.component.html',
    styleUrls: ['./azure-create-vm-provisioning.component.sass']
})
export class AzureCreateVmProvisioningComponent implements OnInit {
    widgetRef: Widget;

    widgetFilters = [];
    accountIdsFormGenInputs: BehaviorSubject<IFormGeneratorInput> =
        new BehaviorSubject<IFormGeneratorInput>(null);
    accountIdsFormGroup: FormGroup;
    networkInterfaceFormGenInputs: IFormGeneratorInput = null;
    networkInterfaceFormGroup: FormGroup;
    authTypeFormGenInputs: IFormGeneratorInput = null;
    authTypeFormGroup: FormGroup;
    imageFormGenInputs: IFormGeneratorInput = null;
    imageFormGroup: FormGroup;
    imageOfferFormGenInputs: IFormGeneratorInput = null;
    imageOfferFormGroup: FormGroup;
    skuFormGenInputs: IFormGeneratorInput = null;
    skuFormGroup: FormGroup;
    subnetFormGenInputs: IFormGeneratorInput = null;
    subnetFormGroup: FormGroup;
    keyValuePairFormGenInputs: IFormGeneratorInput[] = null;
    keyValuePairFormGroup: Map<Symbol, FormGroup> = new Map();
    lastKeyValuePair: Symbol;
    tags = [];
    updateControlInput: IUpdateAction;
    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    updateSubnetControlInput: IUpdateAction;
    updateSubnetControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    updateControlInput2: IUpdateAction;
    updateControl2: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    updateImageControlInput: IUpdateAction;
    updateImageControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    updateSkuControlInput: IUpdateAction;
    updateSkuControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);

    accountIds;
    regions;
    accountIdKey;
    regionKey;
    networkInterfaceData;
    subnetData;
    vitualNetworkType: string;
    resetButton: IButtonGeneratorInput = {
        buttonName: 'Reset',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.RAISED,
        function: this.reset.bind(this)
    };
    submitButton: IButtonGeneratorInput = {
        buttonName: 'Submit',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.RAISED,
        showLoader: true,
        function: (buttonRef) => {
            this.submit(buttonRef);
        }
    };
    constructor(
        widgetData: WidgetInjectedData,
        private filtersCacheService: FilterCacheService,
        private userCache: UserDataCacheService,
        private configCache: ConfigCacheService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.widgetRef.showViewIcon.next(false);
        this.widgetRef.hideRefreshIcon.next(true);
        this.keyValuePairFormGenInputs = [this.getKeyValueFormGenInput()];
        this.widgetRef.widgetData.widgetInfo.schedulingAutomation.widgetFilters.forEach(
            (element) => {
                this.widgetFilters = element.filters;
            }
        );

        this.setUpBasics();
    }
    setUpBasics() {
        const customeInputs = {
            showKey: 'label',
            responseValueKey: 'dataList'
        };
        Helper.generateFormInput(
            this.widgetFilters,
            this.widgetRef.httpService,
            this.filtersCacheService,
            customeInputs,
            null,
            null,
            null,
            null,
            null,
            this.userCache,
            this.configCache
        ).subscribe((res) => {
            if (res) {
                res.fields.forEach((ele) => {
                    if (
                        ele.name.includes('Resource') ||
                        ele.name.includes('Ids') ||
                        ele.name.includes('regions')
                    ) {
                        if (ele.name.includes('Ids')) {
                            const index = res.fields.findIndex((ele) =>
                                ele.name.includes('Ids')
                            );
                            if (index !== 0) {
                                res.fields.splice(index, 1);
                                res.fields.splice(0, 0, ele);
                            }
                        }
                    }
                });
                res.fields.push({
                    label: 'VM Name',
                    placeholder: 'VM Name',
                    name: 'vmName',
                    fieldType: FilterType.TEXT,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'VM Name is required'
                        }
                    ]
                });
                this.accountIdsFormGenInputs.next(res);
                this.accountIdsFormGenInputs.subscribe();
            }
        });
        this.authTypeFormGenInputs = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Disk Type',
                    placeholder: 'Disk Type',
                    name: 'diskType',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    listData: this.diskTypeList,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Disk Type is required'
                        }
                    ]
                },
                {
                    label: 'Username',
                    placeholder: 'Username',
                    name: 'username',
                    fieldType: FilterType.TEXT,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Username is required'
                        }
                    ]
                },
                {
                    label: 'Authentication Type',
                    placeholder: 'Authentication Type',
                    name: 'authType',
                    fieldType: FilterType.RADIO,
                    listData: [
                        { id: 'SSH', label: 'SSH' },
                        { id: 'PASSWORD', label: 'Password' }
                    ],
                    value: 'SSH',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Authentication is required'
                        }
                    ]
                },
                {
                    label: 'SSH Public Key',
                    placeholder: 'SSH Public Key',
                    name: 'publicKey',
                    fieldType: FilterType.TEXT,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Publc Key is required'
                        }
                    ],
                    hiddenDependency: [
                        {
                            controlName: 'authType',
                            validations: [
                                {
                                    validator: CustomValidators.required
                                },
                                {
                                    validator:
                                        CustomValidators.controlValueMatch(
                                            'SSH'
                                        )
                                }
                            ]
                        }
                    ]
                },
                {
                    label: 'Password',
                    placeholder: 'Password',
                    name: 'password',
                    fieldType: FilterType.PASSWORD,
                    required: true,
                    validations: Helper.getPasswordValidators(),
                    hiddenDependency: [
                        {
                            controlName: 'authType',
                            validations: [
                                {
                                    validator: CustomValidators.required
                                },
                                {
                                    validator:
                                        CustomValidators.controlValueMatch(
                                            'PASSWORD'
                                        )
                                }
                            ]
                        }
                    ]
                },
                {
                    label: 'Confirm Password',
                    placeholder: 'Confirm Password',
                    name: 'confirmPassword',
                    fieldType: FilterType.PASSWORD,
                    required: true,
                    validations: Helper.getPasswordValidators(
                        'equal',
                        'password'
                    ),
                    hiddenDependency: [
                        {
                            controlName: 'authType',
                            validations: [
                                {
                                    validator: CustomValidators.required
                                },
                                {
                                    validator:
                                        CustomValidators.controlValueMatch(
                                            'PASSWORD'
                                        )
                                }
                            ]
                        }
                    ]
                }
            ]
        };
    }
    depFields(events) {
        this.accountIdKey = Object.keys(events).find((ele) =>
            ele.includes('Ids')
        );
        this.regionKey = Object.keys(events).find((ele) =>
            ele.includes('regions')
        );
        const tempId = events[this.accountIdKey];
        const tempRegion = events[this.regionKey];
        if (this.accountIds !== tempId || this.regions !== tempRegion) {
            this.accountIds = tempId;
            this.regions = tempRegion;
            if (this.accountIds && this.regions) {
                this.createImage();
                this.networkInterfaceFormGenInputs = {
                    formName: '',
                    state: FormState.CREATE,
                    submitButton: null,
                    fields: [
                        {
                            label: 'Network Interface Type',
                            placeholder: '',
                            name: 'interfaceType',
                            fieldType: FilterType.RADIO,
                            listData: [
                                { id: 'existing', label: 'Existing' },
                                { id: 'createNew', label: 'Create New' }
                            ],
                            value: 'existing',

                            showKey: 'label'
                        },
                        {
                            label: 'Network Interface',
                            placeholder: 'Network Interface',
                            name: 'networkInterface',
                            fieldType: FilterType.DROPDOWN_SINGLE,
                            required: true,
                            apiInfo: this.widgetRef?.widgetData?.widgetInfo?.[
                                'provisioning'
                            ]?.['networkInterfaces']
                                ? this.widgetRef.widgetData.widgetInfo[
                                      'provisioning'
                                  ]['networkInterfaces']
                                : '',
                            apiInput: {
                                [this.accountIdKey]: [this.accountIds],
                                [this.regionKey]: [this.regions]
                            },
                            responseValueKey: 'dataList',
                            validations: [
                                {
                                    validator: CustomValidators.required,
                                    errorMessage:
                                        'Network interface is required'
                                }
                            ],
                            hiddenDependency: [
                                {
                                    controlName: 'interfaceType',
                                    validations: [
                                        {
                                            validator: CustomValidators.required
                                        },
                                        {
                                            validator:
                                                CustomValidators.controlValueMatch(
                                                    'existing'
                                                )
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            label: 'Virtual Network Type',
                            placeholder: '',
                            name: 'virtualNetworkType',
                            fieldType: FilterType.RADIO,
                            listData: [
                                { id: 'existing', label: 'Existing' },
                                { id: 'createNew', label: 'Create New' }
                            ],
                            value: 'existing',
                            hiddenDependency: [
                                {
                                    controlName: 'interfaceType',
                                    validations: [
                                        {
                                            validator: CustomValidators.required
                                        },
                                        {
                                            validator:
                                                CustomValidators.controlValueMatch(
                                                    'createNew'
                                                )
                                        }
                                    ]
                                }
                            ],

                            showKey: 'label'
                        },
                        {
                            label: 'Virtual Network',
                            placeholder: 'Select Virtual Network',
                            name: 'virtualNetwork',
                            fieldType: FilterType.DROPDOWN_SINGLE,
                            required: true,
                            apiInfo:
                                this.widgetRef.widgetData.widgetInfo[
                                    'provisioning'
                                ]['virtualNetworks'],
                            apiInput: {
                                [this.accountIdKey]: [this.accountIds],
                                [this.regionKey]: [this.regions]
                            },
                            hiddenDependency: [
                                {
                                    controlName: 'interfaceType',
                                    validations: [
                                        {
                                            validator: CustomValidators.required
                                        },
                                        {
                                            validator:
                                                CustomValidators.controlValueMatch(
                                                    'createNew'
                                                )
                                        }
                                    ]
                                },

                                {
                                    controlName: 'virtualNetworkType',
                                    validations: [
                                        {
                                            validator: CustomValidators.required
                                        },
                                        {
                                            validator:
                                                CustomValidators.controlValueMatch(
                                                    'existing'
                                                )
                                        }
                                    ]
                                }
                            ],
                            responseValueKey: 'dataList',
                            validations: [
                                {
                                    validator: CustomValidators.required,
                                    errorMessage: 'Virtual Network is required'
                                }
                            ]
                        },
                        {
                            label: 'Name',
                            placeholder: 'Name',
                            name: 'vnName',
                            fieldType: FilterType.TEXT,
                            required: true,
                            validations: [
                                {
                                    validator: CustomValidators.required,
                                    errorMessage: 'Name is required'
                                }
                            ],
                            hiddenDependency: [
                                {
                                    controlName: 'virtualNetworkType',
                                    validations: [
                                        {
                                            validator: CustomValidators.required
                                        },
                                        {
                                            validator:
                                                CustomValidators.controlValueMatch(
                                                    'createNew'
                                                )
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            label: 'Address prefix',
                            placeholder: 'Address Prefix',
                            name: 'vnAddressPrefix',
                            fieldType: FilterType.TEXT,
                            required: true,
                            validations: [
                                {
                                    validator: CustomValidators.required,
                                    errorMessage: 'Address prefix is required'
                                }
                            ],
                            hiddenDependency: [
                                {
                                    controlName: 'virtualNetworkType',
                                    validations: [
                                        {
                                            validator: CustomValidators.required
                                        },
                                        {
                                            validator:
                                                CustomValidators.controlValueMatch(
                                                    'createNew'
                                                )
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            label: 'Network Interface Name',
                            placeholder: 'Network Interface Name',
                            name: 'networkInterfaceName',
                            fieldType: FilterType.TEXT,
                            required: true,
                            validations: [
                                {
                                    validator: CustomValidators.required,
                                    errorMessage:
                                        'Network interface name is required'
                                }
                            ],
                            hiddenDependency: [
                                {
                                    controlName: 'interfaceType',
                                    validations: [
                                        {
                                            validator: CustomValidators.required
                                        },
                                        {
                                            validator:
                                                CustomValidators.controlValueMatch(
                                                    'createNew'
                                                )
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                };
                this.updateControlInput = {
                    action: UpdateAction.REFRESH,
                    controls: ['networkInterface', 'virtualNetwork']
                };
                if (this.networkInterfaceFormGroup) {
                    this.widgetRef.changeDetectorRef.detectChanges();
                    this.updateControl.next(this.updateControlInput);
                }
            }
        }
    }
    createImage() {
        this.imageFormGenInputs = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'VM Size',
                    placeholder: 'VM Size',
                    name: 'vmSize',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    apiInput: {
                        [this.accountIdKey]: [this.accountIds],
                        [this.regionKey]: [this.regions]
                    },
                    apiInfo: this.widgetRef?.widgetData?.widgetInfo?.[
                        'provisioning'
                    ]?.['vmSize']
                        ? this.widgetRef.widgetData.widgetInfo['provisioning'][
                              'vmSize'
                          ]
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'VM size is required'
                        }
                    ],
                    responseValueKey: 'dataList'
                },
                {
                    label: 'Image Id Type',
                    placeholder: '',
                    name: 'imageIdType',
                    fieldType: FilterType.RADIO,
                    listData: [
                        {
                            id: 'imageInAccount',
                            label: 'Images in Account'
                        },
                        { id: 'marketPlace', label: 'Market Place' }
                    ],
                    value: 'imageInAccount',
                    showKey: 'label'
                },
                {
                    label: 'Image Id',
                    placeholder: 'Image Id',
                    name: 'imageId',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    apiInput: {
                        [this.accountIdKey]: [this.accountIds],
                        [this.regionKey]: [this.regions]
                    },
                    hiddenDependency: [
                        {
                            controlName: 'imageIdType',
                            validations: [
                                {
                                    validator: CustomValidators.required
                                },
                                {
                                    validator:
                                        CustomValidators.controlValueMatch(
                                            'imageInAccount'
                                        )
                                }
                            ]
                        }
                    ],
                    apiInfo: this.widgetRef?.widgetData?.widgetInfo?.[
                        'provisioning'
                    ]?.['imageId']
                        ? this.widgetRef.widgetData.widgetInfo['provisioning'][
                              'imageId'
                          ]
                        : '',
                    responseValueKey: 'dataList',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Image id is required'
                        }
                    ]
                },
                {
                    label: 'Image Publisher',
                    placeholder: 'Select Image Publisher',
                    name: 'imagePublisher',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    apiInput: {
                        [this.accountIdKey]: [this.accountIds],
                        [this.regionKey]: [this.regions]
                    },
                    hiddenDependency: [
                        {
                            controlName: 'imageIdType',
                            validations: [
                                {
                                    validator: CustomValidators.required
                                },
                                {
                                    validator:
                                        CustomValidators.controlValueMatch(
                                            'marketPlace'
                                        )
                                }
                            ]
                        }
                    ],
                    apiInfo: this.widgetRef?.widgetData?.widgetInfo?.[
                        'provisioning'
                    ]?.['imagePublisher']
                        ? this.widgetRef.widgetData.widgetInfo['provisioning'][
                              'imagePublisher'
                          ]
                        : '',
                    responseValueKey: 'dataList',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Image publisher is required'
                        }
                    ]
                }
            ]
        };
        this.updateControlInput2 = {
            action: UpdateAction.REFRESH,
            controls: ['vmSize', 'imageId', 'imagePublisher']
        };
        if (this.imageFormGroup) {
            this.widgetRef.changeDetectorRef.detectChanges();
            this.updateControl2.next(this.updateControlInput2);
        }
    }
    imagePublisherChanged(event) {
        if (event && event.imagePublisher) {
            this.imageOfferFormGenInputs = {
                formName: '',
                state: FormState.CREATE,
                submitButton: null,
                fields: [
                    {
                        label: 'Image Offer',
                        placeholder: 'Select Image Offer',
                        name: 'imageOffer',
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        required: true,
                        apiInput: {
                            [this.accountIdKey]: [this.accountIds],
                            [this.regionKey]: [this.regions],
                            configurationProvision: {
                                publisher: event.imagePublisher
                            }
                        },
                        apiInfo: this.widgetRef?.widgetData?.widgetInfo?.[
                            'provisioning'
                        ]?.['imagePublisherOffer']
                            ? this.widgetRef.widgetData.widgetInfo[
                                  'provisioning'
                              ]['imagePublisherOffer']
                            : '',
                        responseValueKey: 'dataList',
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Image offer is required'
                            }
                        ]
                    }
                ]
            };
            this.updateImageControlInput = {
                action: UpdateAction.REFRESH,
                controls: ['imageOffer']
            };
            if (this.imageOfferFormGroup) {
                this.widgetRef.changeDetectorRef.detectChanges();
                this.updateImageControl.next(this.updateImageControlInput);
            }
        }
    }
    imageOfferChanged(event) {
        if (event && event.imageOffer) {
            this.skuFormGenInputs = {
                formName: '',
                state: FormState.CREATE,
                submitButton: null,
                fields: [
                    {
                        label: 'Image SKU',
                        placeholder: 'Select Image SKU',
                        name: 'sku',
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        required: true,
                        apiInput: {
                            [this.accountIdKey]: [this.accountIds],
                            [this.regionKey]: [this.regions],
                            configurationProvision: {
                                publisher:
                                    this.imageFormGroup.get('imagePublisher')
                                        .value,
                                offer: event.imageOffer
                            }
                        },
                        apiInfo: this.widgetRef?.widgetData?.widgetInfo?.[
                            'provisioning'
                        ]?.['sku']
                            ? this.widgetRef.widgetData.widgetInfo[
                                  'provisioning'
                              ]['sku']
                            : '',
                        responseValueKey: 'dataList',
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'SKU is required'
                            }
                        ]
                    }
                ]
            };
            this.updateSkuControlInput = {
                action: UpdateAction.REFRESH,
                controls: ['sku']
            };
            if (this.skuFormGroup) {
                this.widgetRef.changeDetectorRef.detectChanges();
                this.updateSkuControl.next(this.updateSkuControlInput);
            }
        }
    }
    subnet(event) {
        if (
            event &&
            (event.virtualNetwork ||
                (event.virtualNetworkType === 'createNew' &&
                    this.vitualNetworkType !== event.virtualNetworkType))
        ) {
            this.vitualNetworkType = event.virtualNetworkType;
            this.subnetFormGenInputs = {
                formName: '',
                state: FormState.CREATE,
                submitButton: null,
                fields: [
                    {
                        label: 'Subnet Type',
                        placeholder: '',
                        name: 'subnetType',
                        fieldType: FilterType.RADIO,
                        listData: [
                            {
                                id: 'existing',
                                label: 'Existing',
                                disabled:
                                    event &&
                                    event.virtualNetworkType === 'createNew'
                                        ? true
                                        : false
                            },
                            { id: 'createNew', label: 'Create New' }
                        ],
                        value:
                            event && event.virtualNetworkType === 'createNew'
                                ? 'createNew'
                                : 'existing',

                        showKey: 'label'
                    },
                    {
                        label: 'Subnet',
                        placeholder: 'Select Subnet',
                        name: 'subnet',
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        required: true,
                        apiInfo:
                            event && event.virtualNetwork
                                ? this.widgetRef?.widgetData?.widgetInfo?.[
                                      'provisioning'
                                  ]?.['subnet']
                                    ? this.widgetRef.widgetData.widgetInfo[
                                          'provisioning'
                                      ]['subnet']
                                    : ''
                                : '',
                        apiInput:
                            event && event.virtualNetwork
                                ? {
                                      [this.accountIdKey]: [this.accountIds],
                                      [this.regionKey]: [this.regions],
                                      configurationProvision: {
                                          vnName: event.virtualNetwork
                                      }
                                  }
                                : '',
                        hiddenDependency: [
                            {
                                controlName: 'subnetType',
                                validations: [
                                    {
                                        validator: CustomValidators.required
                                    },
                                    {
                                        validator:
                                            CustomValidators.controlValueMatch(
                                                'existing'
                                            )
                                    }
                                ]
                            }
                        ],
                        responseValueKey: 'dataList',
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Subnet is required'
                            }
                        ]
                    },
                    {
                        label: 'Name',
                        placeholder: 'Name',
                        name: 'subnetName',
                        fieldType: FilterType.TEXT,
                        required: true,
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Name is required'
                            }
                        ],
                        hiddenDependency: [
                            {
                                controlName: 'subnetType',
                                validations: [
                                    {
                                        validator: CustomValidators.required
                                    },
                                    {
                                        validator:
                                            CustomValidators.controlValueMatch(
                                                'createNew'
                                            )
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        label: 'Address prefix',
                        placeholder: 'Address Prefix',
                        name: 'subnetAddress',
                        fieldType: FilterType.TEXT,
                        required: true,
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Address prefix is required'
                            }
                        ],
                        hiddenDependency: [
                            {
                                controlName: 'subnetType',
                                validations: [
                                    {
                                        validator: CustomValidators.required
                                    },
                                    {
                                        validator:
                                            CustomValidators.controlValueMatch(
                                                'createNew'
                                            )
                                    }
                                ]
                            }
                        ]
                    }
                ]
            };
            if (event && event.virtualNetworkType !== 'createNew') {
                this.updateSubnetControlInput = {
                    action: UpdateAction.REFRESH,
                    controls: ['subnet']
                };
                if (this.subnetFormGroup && event.virtualNetwork) {
                    this.widgetRef.changeDetectorRef.detectChanges();
                    this.updateSubnetControl.next(
                        this.updateSubnetControlInput
                    );
                }
            }
            if (event && event.virtualNetworkType === 'createNew') {
                this.updateSubnetControlInput = {
                    action: UpdateAction.UPDATE_VALUE,
                    controls: ['subnetType'],
                    value: 'createNew'
                };
                if (this.subnetFormGroup) {
                    this.widgetRef.changeDetectorRef.detectChanges();
                    this.updateSubnetControl.next(
                        this.updateSubnetControlInput
                    );
                }
            }
        }
    }
    submit(buttonRef) {
        if (buttonRef.loader) {
            return;
        }

        Helper.markAllFieldAsTouched(this.accountIdsFormGroup);
        if (this.accountIdsFormGroup.invalid) {
            this.accountIdsFormGroup.updateValueAndValidity();
            return;
        }
        Helper.markAllFieldAsTouched(this.imageFormGroup);
        if (this.imageFormGroup.invalid) {
            this.imageFormGroup.updateValueAndValidity();
            return;
        }

        Helper.markAllFieldAsTouched(this.networkInterfaceFormGroup);
        if (this.networkInterfaceFormGroup.invalid) {
            this.networkInterfaceFormGroup.updateValueAndValidity();
            return;
        }

        if (this.subnetFormGroup) {
            Helper.markAllFieldAsTouched(this.subnetFormGroup);
            if (this.subnetFormGroup.invalid) {
                this.subnetFormGroup.updateValueAndValidity();
                return;
            }
        }
        if (this.imageOfferFormGroup) {
            Helper.markAllFieldAsTouched(this.imageOfferFormGroup);
            if (this.imageOfferFormGroup.invalid) {
                this.imageOfferFormGroup.updateValueAndValidity();
                return;
            }
        }
        if (this.skuFormGroup) {
            Helper.markAllFieldAsTouched(this.skuFormGroup);
            if (this.skuFormGroup.invalid) {
                this.skuFormGroup.updateValueAndValidity();
                return;
            }
        }

        Helper.markAllFieldAsTouched(this.authTypeFormGroup);
        if (this.authTypeFormGroup.invalid) {
            this.authTypeFormGroup.updateValueAndValidity();
            return;
        }

        this.tags = [];
        this.lastKeyValuePair =
            this.keyValuePairFormGenInputs[
                this.keyValuePairFormGenInputs.length - 1
            ].formId;
        this.keyValuePairFormGroup.forEach((value, index) => {
            if (this.lastKeyValuePair !== index) {
                this.tags.push(value.value);
            }
        });
        buttonRef.loader = true;
        this.hitApis(buttonRef);
    }
    hitApis(buttonRef) {
        if (
            this.networkInterfaceFormGroup.get('interfaceType').value ===
            'createNew'
        ) {
            if (
                this.networkInterfaceFormGroup.get('virtualNetworkType')
                    .value === 'createNew'
            ) {
                // create Virtual Network start
                const inputs = this.prepareVnInputs();
                const apiArgs = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo['provisioning'][
                        'createVirtualNetwork'
                    ]
                );

                apiArgs.input = inputs;
                apiArgs.function = () => {
                    this.createSubnet(buttonRef);
                };
                apiArgs.errorFunction = (error) => {
                    buttonRef.loader = false;
                    this.resetKeyValuePair();
                    Helper.showErrorMessage(
                        this.widgetRef.notificationsService,
                        error,
                        'Error While Creating Virtual Network'
                    );
                    return;
                };
                new HitApi(
                    apiArgs,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            } else {
                this.createSubnet(buttonRef);
            }
        } else {
            this.createVm(buttonRef);
        }
    }
    createSubnet(buttonRef) {
        if (this.subnetFormGroup.get('subnetType').value === 'createNew') {
            const inputs = this.prepareSubnetInputs();
            const apiArgs = Helper.generateHitApiConfig(
                this.widgetRef.widgetData.widgetInfo['provisioning'][
                    'createSubnet'
                ]
            );
            apiArgs.input = inputs;
            apiArgs.function = (response) => {
                this.subnetData = response;
                this.createNetInterface(buttonRef);
            };
            apiArgs.errorFunction = (error) => {
                buttonRef.loader = false;
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    'Error While Creating Subnet'
                );
            };
            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        } else {
            this.createNetInterface(buttonRef);
        }
    }
    prepareSubnetInputs() {
        const createSubnetInput = { ...this.accAndRegInput() };
        createSubnetInput['configurationProvision'] = {};
        createSubnetInput['configurationProvision']['subnetName'] =
            this.subnetFormGroup.get('subnetName').value;
        createSubnetInput['configurationProvision']['subnetAddressPrefixes'] =
            this.subnetFormGroup.get('subnetAddress').value;
        createSubnetInput['configurationProvision']['azureResourceGroup'] =
            this.accountIdsFormGroup.get('azureResourceGroup').value;
        if (
            this.networkInterfaceFormGroup.get('virtualNetworkType').value ===
            'existing'
        ) {
            createSubnetInput['configurationProvision']['vnName'] =
                this.networkInterfaceFormGroup.get('virtualNetwork').value;
        } else if (
            this.networkInterfaceFormGroup.get('virtualNetworkType').value ===
            'createNew'
        ) {
            createSubnetInput['configurationProvision']['vnName'] =
                this.networkInterfaceFormGroup.get('vnName').value;
        }
        return createSubnetInput;
    }
    createNetInterface(buttonRef) {
        const inputs = this.prepareNetInterfaceInputs();
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['provisioning'][
                'createNetworkInterface'
            ]
        );
        apiArgs.input = inputs;
        apiArgs.function = (response) => {
            this.networkInterfaceData = response;

            this.createVm(buttonRef);
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            this.resetKeyValuePair();
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error While Network Interface'
            );
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    prepareNetInterfaceInputs() {
        const createNetIntInput = { ...this.accAndRegInput() };
        createNetIntInput['configurationProvision'] = {};
        createNetIntInput['configurationProvision']['nicName'] =
            this.networkInterfaceFormGroup.get('networkInterfaceName')?.value
                ? this.networkInterfaceFormGroup.get('networkInterfaceName')
                      ?.value
                : '';
        createNetIntInput['configurationProvision']['azureResourceGroup'] =
            this.accountIdsFormGroup.get('azureResourceGroup')?.value
                ? this.accountIdsFormGroup.get('azureResourceGroup').value
                : '';
        if (this.keyValuePairFormGroup) {
            createNetIntInput['configurationProvision']['vmTags'] = this.tags;
        }
        if (this.subnetFormGroup.get('subnetType').value === 'existing') {
            createNetIntInput['configurationProvision']['subnetId'] =
                this.subnetFormGroup.get('subnet').value;
        } else if (
            this.subnetFormGroup.get('subnetType').value === 'createNew'
        ) {
            createNetIntInput['configurationProvision']['subnetId'] =
                this.subnetData['dataList'][0];
        }
        return createNetIntInput;
    }
    accAndRegInput() {
        const obj = {
            [this.accountIdKey]: [this.accountIds],
            [this.regionKey]: [this.regions]
        };
        return obj;
    }
    createVm(buttonRef) {
        const input = this.vmInputs();
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.create
        );
        apiArgs.input = input;
        apiArgs.function = () => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'Request to create virtual machine has been initiated successfully.'
            );
            this.reset();
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error While Creating Virtual Network '
            );
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    vmInputs() {
        const inputData = {
            configurationProvision: {
                azureResourceGroup:
                    this.accountIdsFormGroup.get('azureResourceGroup').value,
                vmName: this.accountIdsFormGroup.get('vmName').value,
                vmSize: this.imageFormGroup.get('vmSize').value,
                diskType: this.authTypeFormGroup.get('diskType').value,
                username: this.authTypeFormGroup.get('username').value
            },
            ...this.accAndRegInput()
        };
        if (
            this.networkInterfaceFormGroup.get('interfaceType').value ===
            'existing'
        ) {
            inputData['configurationProvision']['networkInterfaceId'] =
                this.networkInterfaceFormGroup.get('networkInterface').value;
        } else if (
            this.networkInterfaceFormGroup.get('interfaceType').value ===
            'createNew'
        ) {
            inputData['configurationProvision']['networkInterfaceId'] =
                this.networkInterfaceData['dataList'][0];
        }
        if (this.keyValuePairFormGroup) {
            inputData['configurationProvision']['vmTags'] = this.tags;
        }

        if (this.imageFormGroup.get('imageIdType').value === 'marketPlace') {
            inputData['configurationProvision']['publisher'] =
                this.imageFormGroup.get('imagePublisher').value;
            inputData['configurationProvision']['offer'] =
                this.imageOfferFormGroup.get('imageOffer').value;
            inputData['configurationProvision']['sku'] =
                this.skuFormGroup.get('sku').value;
        } else {
            inputData['configurationProvision']['imageId'] =
                this.imageFormGroup.get('imageId').value;
        }

        if (this.authTypeFormGroup.get('authType').value === 'PASSWORD') {
            inputData['configurationProvision']['adminPassword'] =
                this.authTypeFormGroup.get('password').value;
        } else {
            inputData['configurationProvision']['sshPublicKey'] =
                this.authTypeFormGroup.get('publicKey').value;
        }
        return inputData;
    }
    prepareVnInputs() {
        const createVnInput = {
            ...this.accAndRegInput()
        };
        createVnInput['configurationProvision'] = {};
        createVnInput['configurationProvision']['vnName'] =
            this.networkInterfaceFormGroup.get('vnName').value;
        createVnInput['configurationProvision']['vnAddressPrefixes'] =
            this.networkInterfaceFormGroup.get('vnAddressPrefix').value;
        createVnInput['configurationProvision']['azureResourceGroup'] =
            this.accountIdsFormGroup.get('azureResourceGroup').value;
        if (this.keyValuePairFormGroup) {
            createVnInput['configurationProvision']['vmTags'] = this.tags;
        }
        return createVnInput;
    }

    reset() {
        this.accountIdsFormGroup.reset();
        if (this.imageFormGroup) {
            this.imageFormGroup.reset();
        }
        if (this.networkInterfaceFormGroup) {
            this.networkInterfaceFormGroup.reset();
        }
        this.authTypeFormGroup.reset();
        this.authTypeFormGroup.get('authType').setValue('SSH');
        if (this.imageOfferFormGroup) {
            this.imageOfferFormGroup.reset();
        }
        if (this.skuFormGroup) {
            this.skuFormGroup.reset();
        }
        this.resetKeyValuePair();
    }
    resetKeyValuePair() {
        if (this.keyValuePairFormGenInputs.length !== 1) {
            this.keyValuePairFormGenInputs.splice(
                0,
                this.keyValuePairFormGenInputs.length - 1
            );
        }
        this.lastKeyValuePair =
            this.keyValuePairFormGenInputs[
                this.keyValuePairFormGenInputs.length - 1
            ].formId;

        this.keyValuePairFormGroup.forEach((fg, id) => {
            if (id !== this.lastKeyValuePair) {
                this.keyValuePairFormGroup.delete(id);
            } else {
                fg.reset();
            }
        });
    }
    diskTypeList = [
        {
            id: 'Standard_LRS',
            label: 'Standard HDD'
        },
        {
            id: 'Premium_LRS',
            label: 'Premium SSD'
        },
        {
            id: 'StandardSSD_LRS',
            label: 'Standard SSD'
        },
        {
            id: 'UltraSSD_LRS',
            label: 'Ultra SSD'
        }
    ];
    getKeyValueFormGenInput(): IFormGeneratorInput {
        return {
            formId: Symbol(),
            formName: 'Key',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'Key',
                    fieldType: FilterType.TEXT,
                    label: 'Key',
                    placeholder: 'Key',
                    required: false
                },
                {
                    name: 'Value',
                    fieldType: FilterType.TEXT,
                    label: 'Value',
                    placeholder: 'Value',
                    required: false
                }
            ]
        };
    }
    idleKeyValueForms() {
        this.keyValuePairFormGenInputs = this.keyValuePairFormGenInputs.map(
            (fg, index) => {
                if (index !== this.keyValuePairFormGenInputs.length - 1) {
                    fg.state = FormState.IDLE;
                }
                return Helper.dereference(fg);
            }
        );
        this.widgetRef.changeDetectorRef.detectChanges();
    }
    lastKeyValueFormUpdate(index: number, formId: Symbol) {
        const tag = this.keyValuePairFormGroup.get(formId).value;

        if (!tag.Key || !tag.Value) {
            this.widgetRef.notificationsService.showSnackBar(
                'Key Or Value Can not Be Left Blank',
                true
            );
            return;
        }
        if (this.keyValuePairFormGroup.has(formId)) {
            const fg = this.keyValuePairFormGroup.get(formId);
            if (fg.valid) {
                if (
                    this.keyValuePairFormGenInputs &&
                    this.keyValuePairFormGenInputs.length
                ) {
                    if (this.getKey().includes(fg.get('Key').value)) {
                        this.widgetRef.notificationsService.showSnackBar(
                            'Configuration for this key is already present',
                            true
                        );
                    } else {
                        this.keyValuePairFormGenInputs.push(
                            this.getKeyValueFormGenInput()
                        );
                        this.idleKeyValueForms();
                    }
                }
            }
        }
    }
    getKey(): string[] {
        const key = [];
        this.keyValuePairFormGenInputs.forEach((fg, index) => {
            if (index !== this.keyValuePairFormGenInputs.length - 1) {
                if (this.keyValuePairFormGroup.has(fg.formId)) {
                    key.push(
                        this.keyValuePairFormGroup.get(fg.formId).get('Key')
                            .value
                    );
                }
            }
        });
        return key;
    }
    deleteKeyValuePairFormGroup(index: number, formId: Symbol) {
        this.keyValuePairFormGroup.delete(formId);
        this.keyValuePairFormGenInputs = Helper.removeIndexfromArray(
            index,
            this.keyValuePairFormGenInputs
        );
    }
}
