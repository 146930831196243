import { Component, Input, OnInit } from '@angular/core';
import { GraphType } from 'src/app/shared/enums/GraphType';
import { IGraphData } from 'src/app/shared/interfaces/graph/IGraphData';

@Component({
    selector: 'app-graph-generator',
    templateUrl: './graph-generator.component.html',
    styleUrls: ['./graph-generator.component.sass']
})
export class GraphGeneratorComponent implements OnInit {
    @Input() graphData: IGraphData;
    GraphType = GraphType;

    constructor() {}

    ngOnInit(): void {}
}
