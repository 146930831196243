<div class="wrapper" #wrapper>
    <div
        class="close-button"
        (click)="modalService.closeModal(null, modalData.modalId)"
        appIcon
        [data]="crossIcon"
    ></div>
    <app-horizontal-scrolling-tab-group (onTabChange)="updateActiveTab($event)">
        <ng-template
            appHorizontalScrollingTab
            *ngFor="let tab of tabs"
            [tabLabel]="tab.label"
        >
            <div class="content-wrapper">
                <!-- Table -->
                <div
                    class="other-info"
                    *ngIf="tab['otherInfo']"
                    [innerHtml]="tab['otherInfo'][0]['innerHtml']"
                ></div>
                <app-only-table-generator
                    *ngIf="tab.tableInput && tab.tableData"
                    [tableInput]="tab.tableInput"
                    [tableData]="tab.tableData"
                    [hideControlBar]="true"
                >
                </app-only-table-generator>

                <!-- accordionData -->
                <div
                    class="accordian-data-wrapper"
                    *ngIf="
                        recievedData['data'] &&
                        recievedData['data'][activeTab] &&
                        recievedData['data'][activeTab]['accordionData']
                    "
                >
                    <div
                        class="accordian-wrapper"
                        *ngFor="let header of tab['accordianData']['headers']"
                    >
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{ header }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <app-only-table-generator
                                    *ngIf="
                                        tab['accordianData'][header][
                                            'tableInput'
                                        ] &&
                                        tab['accordianData'][header][
                                            'tableData'
                                        ]
                                    "
                                    [tableInput]="
                                        tab['accordianData'][header][
                                            'tableInput'
                                        ]
                                    "
                                    [tableData]="
                                        tab['accordianData'][header][
                                            'tableData'
                                        ]
                                    "
                                    [hideControlBar]="true"
                                >
                                </app-only-table-generator>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </ng-template>
    </app-horizontal-scrolling-tab-group>
</div>
