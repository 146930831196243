import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    OnInit,
    QueryList,
    ViewChildren
} from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';

@Component({
    selector: 'app-expandable-table-more-info-modal',
    templateUrl: './expandable-table-more-info-modal.component.html',
    styleUrls: ['./expandable-table-more-info-modal.component.sass']
})
export class ExpandableTableMoreInfoModalComponent
    implements OnInit, AfterViewInit
{
    expandableTableExtraInfoData = {};
    getKeys = Object.keys;
    getValues = Object.values;
    containsModalData = false;
    tableKeys = [];
    tabDescription: string;
    showData: { [key: string]: Boolean }[] = [];
    totalTabs: number;
    scrollSubject: EventEmitter<any> = new EventEmitter<any>();
    scrollSubscription: Subscription;
    tabOffsets: number[] = [];
    activeIndex: number = 0;
    allKeys = [];
    @ViewChildren('verticalTabContent')
    tabDivList: QueryList<ElementRef<HTMLElement>>;
    @HostListener('scroll', ['$event']) onScroll($event) {
        this.scrollSubject.next($event);
    }

    crossIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fa fa-times'
    };
    constructor(
        public modalData: ModalInjectedData,
        public modalService: ModalService
    ) {
        this.containsModalData = modalData.data['containsModalData'];
        this.expandableTableExtraInfoData = modalData.data['modalData'];
        this.tableKeys = modalData.data['visibleKeys'];
        this.tabDescription = this.getKeys(
            this.expandableTableExtraInfoData
        )[0];
        this.allKeys = this.getKeys(this.expandableTableExtraInfoData);
    }

    getLength() {
        this.getKeys(this.expandableTableExtraInfoData).forEach(
            (head, index) => {
                if (
                    this.expandableTableExtraInfoData[head]['data'] &&
                    this.expandableTableExtraInfoData[head]['data'].length > 0
                ) {
                    this.totalTabs = index;
                }
            }
        );
    }
    initVerticalTabOffsets() {
        this.tabDivList.forEach((elementRef, index) => {
            this.tabOffsets[index] = elementRef.nativeElement.offsetTop - 61;
        });
    }
    initScrollSubject() {
        this.scrollSubscription = this.scrollSubject
            .pipe(debounceTime(150))
            .subscribe((event) => {
                const scrollTopPosition = event.target.scrollTop;
                const bottomPosition = event.target.scrollHeight;
                const offsetHeight = event.target.offsetHeight;
                let maxIndex = 0;
                for (let index = 0; index < this.tabOffsets.length; index++) {
                    if (
                        scrollTopPosition >= this.tabOffsets[index] &&
                        index > maxIndex
                    ) {
                        maxIndex = index;
                    }
                }
                if (scrollTopPosition >= bottomPosition - offsetHeight) {
                    maxIndex = this.tabOffsets.length - 1;
                }
                if (maxIndex !== this.activeIndex) {
                    this.activeIndex = maxIndex;
                    this.tabDescription = this.allKeys[this.activeIndex];
                }
            });
    }
    ngAfterViewInit(): void {
        this.initScrollSubject();
        this.initVerticalTabOffsets();
    }
    ngOnInit(): void {
        this.setHeadingValues();
        this.getLength();
    }

    showOrNot(head) {
        for (const index in Object.keys(this.showData)) {
            if (Object.keys(this.showData[index])[0] === head) {
                this.showData[index][head] = !this.showData[index][head];
            }
        }
    }
    setHeadingValues() {
        for (const heading of this.getKeys(this.expandableTableExtraInfoData)) {
            this.showData.push({ [heading]: true });
        }
    }
    ifKeyExistInObject(key) {
        return key in this.expandableTableExtraInfoData;
    }
    transformValue(value) {
        return value !== null
            ? typeof value === 'string'
                ? value.trim().replace(/\n/gi, ' ')
                : value
            : '';
    }
    changeTabStyle(tab, index) {
        this.scrollSubscription.unsubscribe();
        this.activeIndex = index;
        this.tabDescription = tab;
        Helper.doScroll(tab, 'start');
        setTimeout(() => {
            this.initScrollSubject();
        }, 750);
    }
}
