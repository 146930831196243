import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
    // This class is used to detect the state of form fields. It was needed to show error outline on fields.
    isErrorState(
        control: FormControl | null,
        form: FormGroupDirective | NgForm | null
    ): boolean {
        return !!(
            control &&
            control.invalid &&
            (control.dirty || control.touched)
        );
    }
}
