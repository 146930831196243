import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';
import { IAggregateApiResponse } from 'src/app/shared/interfaces/api/portlets/IAggregateApiResponse';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';

@Component({
    selector: 'app-aggregate',
    templateUrl: './aggregate.component.html',
    styleUrls: ['./aggregate.component.sass'],
})
export class AggregateComponent implements OnInit, AfterViewInit {
    // AGGREGATE: 1.0
    @ViewChild('dynamicLabel') private dynamicLabel: ElementRef<HTMLDivElement>;
    applyCustomClass = false;
    widgetRef: Widget;
    cardData = {
        currency: '',
        value: '',
        startDate: '',
        endDate: '',
        cardColorClass: '',
    };
    extraInfo;
    headingCurveIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'heading_curve',
    };

    cartIcon: IIcon = {
        type: IconType.SVG,
        class: 'cart_icon',
    };

    cartIconFilled: IIcon = {
        type: IconType.SVG,
        class: 'cart_icon_filled',
    };

    refreshIcon: IIcon = {
        type: IconType.SVG,
        class: 'refresh',
    };

    constructor(
        widgetData: WidgetInjectedData,
        private el: ElementRef,
        private renderer: Renderer2,
        private globalDataService: GlobalDataService,
        private cdRef: ChangeDetectorRef
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {}

    handleResize(event) {
        if (event.contentRect.width < 150) {
            this.renderer.addClass(event.target, 'small');
        } else {
            this.renderer.removeClass(event.target, 'small');
        }
    }

    bindData(data: IAggregateApiResponse) {
        if (data['dataMap']['Cost']) {
            this.cardData.value = data['dataMap']['Cost'];
        } else {
            // \s in regex refers to single white space and \s+ refers to any number of whitespaces.
            // So this code below splits the string on basis whitespaces and filters values that have no whitespace.
            this.cardData.currency = data['dataMap']['Cost']
                .split(/(\s+)/)
                .filter((e) => e.trim().length > 0)[0];
            this.cardData.value = data['dataMap']['Cost']
                .split(/(\s+)/)
                .filter((e) => e.trim().length > 0)[1];
        }
        if ('extraInfo' in data['dataMap']) {
            this.extraInfo = data['dataMap']['extraInfo'];
        } else {
            this.extraInfo = null;
        }
        this.cardData.startDate = data['dataMap']['Start Date'];
        this.cardData.endDate = data['dataMap']['End Date'];
        this.widgetRef.endLoader();
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
        this.globalDataService.windowResizeEvent.subscribe(() => {
            this.updatePreviewOverlayPosition();
        });
    }

    private updatePreviewOverlayPosition() {
        const currentdynamicLabel =
            this.dynamicLabel?.nativeElement?.getBoundingClientRect();
        if (currentdynamicLabel?.width < 253) {
            this.applyCustomClass = true;
        } else {
            this.applyCustomClass = false;
        }
        this.cdRef.detectChanges();
    }
}
