import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class ControlValueMatchValidator extends CustomValidator {
    key = CustomValidatorKey.DEPENDENT_FIELD_VALIDATOR;

    constructor(private value: string) {
        super();
    }
    validatorFunction(control: AbstractControl): ValidationErrors {
        if (control.value === this.value) {
            return null;
        }
        const obj = {};
        obj[this.key] = true;
        return obj;
    }
}
