import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class MaxLengthValidator extends CustomValidator {
    key = CustomValidatorKey.MAX_LENGTH;

    constructor(private maxLength: number) {
        super();
    }

    validatorFunction(control: AbstractControl): ValidationErrors {
        return Validators.maxLength(this.maxLength)(control);
    }
}
