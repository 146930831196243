import { AfterContentInit, Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[appHorizontalScrollingTab]',
})
export class HorizontalScrollingTabDirective implements AfterContentInit {
    @Input('tabLabel') label: string;
    @Input('showTabLabelTooltip') showTooltip: boolean = false;
    @Input('tabLabelTooltip') tooltipText: string;

    hasLabel: boolean;
    constructor(private templateRef: TemplateRef<any>) {}

    ngAfterContentInit(): void {
        if (this.label) {
            if (!this.tooltipText) {
                this.tooltipText = this.label;
            }
            this.hasLabel = true;
        } else {
            this.hasLabel = false;
        }
    }
}
