<div class="form-container tw-overflow-y-auto">
    <app-form-generator
        *ngIf="customerInfoFirstFormGenInput"
        [formGeneratorInput]="customerInfoFirstFormGenInput"
        (formGroupRef)="customerInfoFirstFormGroup = $event"
        (valuesChange)="addViewField($event)"
    ></app-form-generator>
    <ng-container *ngIf="aclId && !edit">
        <ng-container *ngTemplateOutlet="first"> </ng-container>
    </ng-container>
    <ng-template #first>
        <app-form-generator
            [formGeneratorInput]="viewFormGenInput"
            (formGroupRef)="viewFormGroup = $event"
            (updateControl)="updateControl = $event"
        ></app-form-generator>
    </ng-template>

    <app-form-generator
        *ngIf="retentionPeriodFormGenInput"
        [formGeneratorInput]="retentionPeriodFormGenInput"
        (formGroupRef)="retentionPeriodFormGroup = $event"
    ></app-form-generator>
</div>
<app-button-generator
    class="tw-ml-auto tw-mr-5 tw-mb-3"
    [buttonGenInput]="nextButtonGenInput"
    (buttonRef)="save()"
></app-button-generator>
