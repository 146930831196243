import { AlphaNumWithUnderscoreValidator } from '../validators/AlphaNumWithUnderscoreValidator';
import { caseInsensitiveKeyAlreadyExists } from '../validators/caseInsensitiveKeyAlreadyExistsValidator';
import { CIDRValidator } from '../validators/CIDRValidator';
import { ControlValueInValidator } from '../validators/ControlValueInValidator';
import { ControlValueMatchValidator } from '../validators/ControlValueMatchValidator';
import { ControlValueNotInValidator } from '../validators/ControlValueNotInValidator';
import { DomainInEmailValidator } from '../validators/DomainInEmailValidator';
import { DomainValidator } from '../validators/DomainValidator';
import { EmailListValidator } from '../validators/EmailListValidator';
import { EmailValidator } from '../validators/EmailValidator';
import { EqualityPasswordValidator } from '../validators/EqualityPasswordValidator';
import { EqualityValidator } from '../validators/EqualityValidator';
import { GcpValueValidator } from '../validators/GcpValueValidator';
import { GreaterThanValidator } from '../validators/GreaterThanValidator';
import { IPRange } from '../validators/IPRange';
import { KeyAlreadyExistsValidator } from '../validators/KeyAlreadyExistsValidator';
import { LesserThanValidator } from '../validators/LesserThanValidator';
import { MaxLengthValidator } from '../validators/MaxLengthValidator';
import { MaxValueValidator } from '../validators/MaxValueValidator';
import { MinLengthValidator } from '../validators/MinLengthValidator';
import { MinValueValidator } from '../validators/MinValueValidator';
import { NoSpecialCharactersValidator } from '../validators/NoSpecialCharactersValidator';
import { NoWhiteSpaceValidator } from '../validators/NoWhiteSpaceValidator';
import { OtherJsonControlKeyValueValidator } from '../validators/OtherJsonControlKeyValueValidator';
import { PhoneNumberValidator } from '../validators/PhoneNumberValidator';
import { RegexValidator } from '../validators/RegexValidator';
import { RemoveSpaceFromString } from '../validators/RemoveSpaceFromString';
import { RequiredTrueValidator } from '../validators/RequiredTrueValidator';
import { RequiredValidator } from '../validators/RequiredValidator';
import { UnequalityValidator } from '../validators/UnequalityValidator';
import { UrlValidator } from '../validators/UrlValidator';
import { UsernameValidator } from '../validators/UsernameValidator';
import { GcpCapitalLetterValidator } from './../validators/GcpCapitalLetterValidator';
import { GcpKeyNumberValidator } from './../validators/GcpKeyNumberValidator';
import { GcpSpecialCharacterValidator } from './../validators/GcpSpecialCharacterValidator';
import { GcpValueSpecialCharacterValidator } from './../validators/GcpValueSpecialCharacterValidator';
import { JsonValidator } from './../validators/JsonValidator';
import { MatChipAllowEmailValidator } from './../validators/MatChipAllowEmailValidator';
import { MultiCIDRValidator } from './../validators/MultiCIDRValidator';
import { NumberValidator } from './../validators/NumberValidator';
import { PasswordValidator } from './../validators/PasswordValidator';

export class CustomValidators {
    // Existing validators
    static get required() {
        return new RequiredValidator();
    }
    static get gcpCapitalLetterValidator() {
        return new GcpCapitalLetterValidator();
    }
    static get gcpKeySpecialCharacterValidator() {
        return new GcpSpecialCharacterValidator();
    }
    static get gcpNumberValidator() {
        return new GcpKeyNumberValidator();
    }
    static get gcpValueValidator() {
        return new GcpValueValidator();
    }

    static get requiredTrue() {
        return new RequiredTrueValidator();
    }

    static maxLength(maxLength: number) {
        return new MaxLengthValidator(maxLength);
    }

    static ipRange(controlName: string, range: number, startIP = false) {
        return new IPRange(controlName, range, startIP);
    }

    static minLength(minLength: number) {
        return new MinLengthValidator(minLength);
    }

    static maxValue(maxValue: number) {
        return new MaxValueValidator(maxValue);
    }

    static minValue(minValue: number) {
        return new MinValueValidator(minValue);
    }
    static regexValidator(regex: string) {
        return new RegexValidator(regex);
    }
    // Custom Validators
    static get noWhiteSpace() {
        return new NoWhiteSpaceValidator();
    }
    static get gcpValueSpecialCharacter() {
        return new GcpValueSpecialCharacterValidator();
    }

    static get url() {
        return new UrlValidator();
    }

    static get emailList() {
        return new EmailListValidator();
    }
    static get email() {
        return new EmailValidator();
    }

    static get domain() {
        return new DomainValidator();
    }

    static get matChipAllowEmail() {
        return new MatChipAllowEmailValidator();
    }

    static equal(controlNames: string[]) {
        return new EqualityValidator(controlNames);
    }

    static equalPassword(siblingPasswordControlName: string) {
        return new EqualityPasswordValidator(siblingPasswordControlName);
    }

    static unequal(controlName: string) {
        return new UnequalityValidator(controlName);
    }

    static get password() {
        return new PasswordValidator();
    }

    static get noSpecialCharacters() {
        return new NoSpecialCharactersValidator();
    }

    static get username() {
        return new UsernameValidator();
    }
    static get json() {
        return new JsonValidator();
    }
    static controlValueMatch(value: string) {
        return new ControlValueMatchValidator(value);
    }
    static controlValueIn(values: string[]) {
        return new ControlValueInValidator(values);
    }
    static controlValueNotIn(values: string[], caseSensitive: boolean = false) {
        return new ControlValueNotInValidator(values, caseSensitive);
    }
    static keyAlreadyExists(values, caseSensitive: boolean = true) {
        return new KeyAlreadyExistsValidator(values, caseSensitive);
    }
    static caseInsensitiveKeyAlreadyExists(
        values,
        allKeysAlreadyExists,
        keys,
        keyIndex,
        update
    ) {
        return new caseInsensitiveKeyAlreadyExists(
            values,
            allKeysAlreadyExists,
            keys,
            keyIndex,
            update
        );
    }

    static get alphaNumWithUnderscoreValidator() {
        return new AlphaNumWithUnderscoreValidator();
    }
    static get phoneNumber() {
        return new PhoneNumberValidator();
    }
    static otherJsonControlKeyValueValidator(jsonValue, value: string) {
        return new OtherJsonControlKeyValueValidator(jsonValue, value);
    }

    static get CIDR() {
        return new CIDRValidator();
    }

    static get numberValidator() {
        return new NumberValidator();
    }
    static checkDomainInEmail(value: string) {
        return new DomainInEmailValidator(value);
    }

    static get multiCIDRValidator() {
        return new MultiCIDRValidator();
    }

    static greaterThanControl(controlNames: string[]) {
        return new GreaterThanValidator(controlNames);
    }

    static lesserThanControl(controlNames: string[]) {
        return new LesserThanValidator(controlNames);
    }
    static get removeSpaceFromString() {
        return new RemoveSpaceFromString();
    }
}
