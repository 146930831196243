<div class="wrapper" *ngIf="moreInfoData">
    <div class="modal-heading">
        <div class="tab-container">
            <ng-container
                *ngFor="let headingName of getKeys(moreInfoData); index as i"
            >
                <div
                    class="each-tab each-tab-parent clickable"
                    (click)="changeTabStyle(headingName)"
                    [ngClass]="
                        tabDescription === headingName ? 'active' : 'inactive'
                    "
                >
                    <span class="selectedTab">
                        {{ headingName | camelCaseToNormalName }}
                    </span>
                </div>
                <span *ngIf="i < totalTabs" class="separate"></span>
            </ng-container>
        </div>
        <div class="shadow">
            <div
                class="close"
                (click)="
                    modalService.closeModal(null, modalInjectedData.modalId)
                "
                appIcon
                [data]="crossIcon"
            ></div>
        </div>
    </div>
    <div class="warning-message" *ngIf="warningMessageTemplate">
        <ng-container
            [ngTemplateOutlet]="warningMessageTemplate"
        ></ng-container>
    </div>
    <div class="sections">
        <ng-container *ngFor="let heading of getKeys(moreInfoData); index as i">
            <div class="section">
                <div
                    class="section-heading"
                    id="{{ heading }}"
                    #tableHead
                    *ngIf="!noAccordion"
                >
                    <span>{{ heading | camelCaseToNormalName }}</span>
                    <i
                        class="clpse clickable"
                        (click)="toShowFieldOrNot(heading)"
                        [ngClass]="
                            showData[i][heading]
                                ? 'fas fa-angle-up'
                                : 'fas fa-angle-down'
                        "
                    ></i>
                </div>
                <ng-container *ngIf="!isShowTableData">
                    <div class="section-description">
                        <table *ngIf="showData[i][heading]">
                            <div
                                class="xd-container xd-container-1"
                                [class.no-flow]="noAccordion"
                            >
                                <thead>
                                    <ng-container
                                        *ngIf="
                                            isArray(moreInfoData[heading]);
                                            else headingBlock
                                        "
                                    >
                                        <tr
                                            *ngFor="
                                                let keyName of getKeys(
                                                    moreInfoData[heading][0]
                                                )
                                            "
                                        >
                                            <th>
                                                {{
                                                    keyName
                                                        | camelCaseToNormalName
                                                }}
                                            </th>
                                            <td
                                                *ngFor="
                                                    let value of moreInfoData[
                                                        heading
                                                    ];
                                                    index as i
                                                "
                                            >
                                                <ng-container
                                                    *ngFor="
                                                        let obj of getKeys(
                                                            moreInfoData[
                                                                heading
                                                            ][i]
                                                        )
                                                    "
                                                >
                                                    <span>
                                                        {{
                                                            moreInfoData[
                                                                heading
                                                            ][i][obj] &&
                                                            moreInfoData[
                                                                heading
                                                            ][0][obj].trim()
                                                                ? moreInfoData[
                                                                      heading
                                                                  ][0][obj]
                                                                : "-"
                                                        }}
                                                    </span>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-template #headingBlock>
                                        <tr
                                            *ngFor="
                                                let keyName of getKeys(
                                                    moreInfoData[heading]
                                                )
                                            "
                                        >
                                            <th>
                                                <span
                                                    *ngIf="
                                                        ignoreCamelCaseToNormalPipe;
                                                        else noPipe
                                                    "
                                                >
                                                    {{
                                                        keyName
                                                            | camelCaseToNormalName
                                                    }}
                                                </span>
                                                <ng-template #noPipe>
                                                    {{ keyName }}
                                                </ng-template>
                                            </th>
                                            <td>
                                                <ng-container
                                                    *ngIf="
                                                        !isArray(
                                                            moreInfoData[
                                                                heading
                                                            ][keyName]
                                                        )
                                                    "
                                                >
                                                    {{
                                                        moreInfoData[heading][
                                                            keyName
                                                        ] &&
                                                        validateTrim(
                                                            moreInfoData[
                                                                heading
                                                            ][keyName]
                                                        )
                                                            ? moreInfoData[
                                                                  heading
                                                              ][keyName]
                                                            : "-"
                                                    }}
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="
                                                        isArray(
                                                            moreInfoData[
                                                                heading
                                                            ][keyName]
                                                        )
                                                    "
                                                >
                                                    <ul
                                                        [ngClass]="{
                                                            'google-msp-section-0-view':
                                                                keyName ===
                                                                'Customer Name'
                                                        }"
                                                    >
                                                        <li
                                                            *ngFor="
                                                                let item of moreInfoData[
                                                                    heading
                                                                ][keyName]
                                                            "
                                                        >
                                                            {{ item }}
                                                        </li>
                                                    </ul>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </thead>
                            </div>
                        </table>
                    </div></ng-container
                >
                <ng-container *ngIf="isShowTableData">
                    <div class="section-description-table-data">
                        <table>
                            <tr
                                *ngIf="
                                    moreInfoData &&
                                    moreInfoData[heading]?.header?.length
                                "
                            >
                                <ng-container
                                    *ngFor="
                                        let h of moreInfoData[heading]?.header;
                                        let i = index
                                    "
                                >
                                    <th class="no-break-word">
                                        {{ h }}
                                    </th>
                                </ng-container>
                            </tr>
                            <ng-container
                                *ngFor="let r of moreInfoData[heading]?.rows"
                            >
                                <tr>
                                    <ng-container
                                        *ngFor="let v of r; let i = index"
                                    >
                                        <td [ngClass]="{ 'td-label': i === 0 }">
                                            {{ v }}
                                        </td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </table>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>
