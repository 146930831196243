import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { IBackendFormGeneratorInput } from './../../../interfaces/form-generator/IBackendFormGeneratorInput';
import { MultiStepFormService } from './../../../services/modal/multi-step-form/multi-step-form.service';

@Component({
    selector: 'app-form-generator-modal',
    templateUrl: './form-generator-modal.component.html',
    styleUrls: ['./form-generator-modal.component.sass']
})
export class FormGeneratorModalComponent implements OnInit {
    formGenInput: IFormGeneratorInput = null;
    formGroupRef: FormGroup = null;
    bodyTemplate: TemplateRef<any>;
    multiStepForm: boolean;
    widgetRef: Widget;
    backendFormGenInput: IBackendFormGeneratorInput;
    isFirst: boolean = false;
    isLast: boolean = false;
    stepNumber: number;
    onStepEntry: Function;
    onStepExit: Function;
    onStepBack: Function;
    preserveCurrentStepData: boolean;
    currentStepData: any;
    previousStepData: any;
    templateCallback: any;
    valuesChange: Function;
    resetSubcription: Subscription;

    constructor(
        public modalInputData: ModalInjectedData,
        public modalService: ModalService,
        public multiStepFormService: MultiStepFormService
    ) {}

    ngOnInit(): void {
        const modalData = this.modalInputData.data;
        if (modalData['multiStepForm']) {
            this.multiStepForm = modalData['multiStepForm'];
            this.backendFormGenInput = modalData['backendFormGenInput'];
            this.isFirst = modalData['isFirst'];
            this.isLast = modalData['isLast'];
            this.stepNumber = modalData['stepNumber'];
            this.onStepEntry = modalData['onStepEntry'];
            this.onStepExit = modalData['onStepExit'];
            this.onStepBack = modalData['onStepBack'];
            this.templateCallback = modalData['templateCallback'];
            this.valuesChange = modalData['valuesChange'];
            const preserveStepData = modalData['preserveStepData'];
            if (preserveStepData) {
                this.preserveCurrentStepData = preserveStepData(
                    this.stepNumber
                );
            }
            if (preserveStepData && this.preserveCurrentStepData) {
                this.currentStepData = this.multiStepFormService.stepData
                    .get(this.modalInputData.modalId)
                    .get(this.stepNumber);
            }
            this.prepareFormGenInput();
        } else {
            this.formGenInput = modalData;
        }
        this.resetSubcription = this.modalService.resetModal.subscribe(() => {
            const resetControls = this.formGenInput.fields.map((field) => {
                if (field.preventFieldReset) {
                    return field.name;
                }
            });
            if (resetControls.length) {
                Object.keys(this.formGroupRef.controls).map((control) => {
                    if (!resetControls.includes(control)) {
                        this.formGroupRef.controls[control].reset();
                    }
                });
            } else {
                this.formGroupRef.reset();
            }
        });
    }

    prepareFormGenInput() {
        if (this.onStepEntry) {
            this.previousStepData =
                this.isFirst && this.isLast
                    ? null
                    : this.multiStepFormService.stepData
                          .get(this.modalInputData.modalId)
                          .get(this.stepNumber - 1);
            this.formGenInput = this.onStepEntry(
                this.stepNumber,
                this.backendFormGenInput,
                this.previousStepData,
                this.currentStepData
                    ? this.currentStepData
                    : {
                          formGroupRef: this.formGroupRef,
                          formGenInput: this.formGenInput
                      }
            );
            this.bodyTemplate = this.templateCallback
                ? this.templateCallback(this.stepNumber)
                : null;

            if (!this.isLast && this.formGenInput) {
                this.formGenInput.submitButton.function = (
                    buttonRef: IButtonGeneratorInput,
                    formGroup: FormGroup
                ) => {
                    let proceed = true;
                    if (this.onStepExit) {
                        proceed = this.onStepExit(this.stepNumber, formGroup);
                    }
                    if (proceed) {
                        this.storeCurrentStepData();
                        this.nextStep();
                    }
                };
            }
            if (this.formGenInput && this.formGenInput.backButton) {
                this.formGenInput.backButton.function = (
                    buttonRef: IButtonGeneratorInput,
                    formGroup: FormGroup
                ) => {
                    if (this.preserveCurrentStepData) {
                        this.storeCurrentStepData();
                    }
                    let proceed = true;
                    if (this.onStepBack) {
                        proceed = this.onStepBack(
                            this.stepNumber,
                            this.formGroupRef
                        );
                    }
                    if (proceed) {
                        this.previousStep();
                    }
                };
            }
        }
    }

    storeCurrentStepData() {
        if (this.stepNumber) {
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .set(this.stepNumber, {
                    formGroupRef: this.formGroupRef,
                    formGenInput: this.formGenInput
                });
        }
    }
    nextStep() {
        this.multiStepFormService.nextStep(this.modalInputData.modalId);
    }

    previousStep() {
        this.multiStepFormService.previousStep(this.modalInputData.modalId);
    }

    ngOnDestroy() {
        this.resetSubcription.unsubscribe();
    }
}
