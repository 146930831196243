<app-table-generator
    *ngIf="tableGenInputs"
    [tableInput]="tableGenInputs"
    [widgetRef]="widgetRef"
    (selectionChanged)="getSelectedRow()"
    (gridRef)="gridRef = $event"
    [overviewTemplateStart]="overviewTemplateStart"
>
</app-table-generator>

<ng-template #overviewTemplateStart>
    <div class="tw-flex tw-mr-auto">
        <app-button-generator
            *ngIf="editButton"
            [buttonGenInput]="editButton"
        ></app-button-generator>
    </div>
</ng-template>
