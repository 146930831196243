<app-table-generator
    [tableInput]="tableGenInput"
    [widgetRef]="widgetRef"
    (gridRef)="agGrid = $event"
    (selectionChanged)="onSelectionChanged()"
></app-table-generator>
<ng-template #actionTemplate>
    <li class="text" *ngFor="let data of selectedData">
        {{
            data.azureMCAAccountAttributes &&
            data.azureMCAAccountAttributes.subscriptionName
                ? data.azureMCAAccountAttributes.subscriptionName
                : data.accountName
        }}
    </li>
    <span
        class="error"
        *ngIf="selectedData[0]['type'] === 'MASTER' && isDeleteAction"
    >
        Please note this action will delete the Subscriptions associated with
        the Master Account.</span
    >
</ng-template>
