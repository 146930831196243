import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { SupportedFormat } from './../../../../enums/SupportedFormat';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { ConfigCacheService } from './../../../../services/cache/config-cache/config-cache.service';

interface IWidget {
    id: string;
    name: string;
    supportedFormats: SupportedFormat[];
}

@Component({
    selector: 'app-create-alert-first-step',
    templateUrl: './create-alert-first-step.component.html',
    styleUrls: ['./create-alert-first-step.component.sass']
})
export class CreateAlertFirstStepComponent implements OnInit {
    alertNameFormGenInput: IFormGeneratorInput = null;
    alertNameFormGroup: FormGroup;
    widgetsSelected: string[] = [];
    widgetsSelectedMap: Map<string, string> = new Map();
    noWidgetsSelected = false;
    SupportedFormat = SupportedFormat;
    GlobalConfiguration = GlobalConfiguration;
    ApiUrls = ApiUrls;
    edit = false;
    stepData = null;

    nextButton: IButtonGeneratorInput = {
        buttonName: 'Next',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        function: this.goToStepTwo.bind(this)
    };
    constructor(
        private modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        public configCacheService: ConfigCacheService
    ) {}

    ngOnInit(): void {
        if (
            this.modalInputData &&
            this.modalInputData.data &&
            this.modalInputData.data['edit']
        ) {
            this.edit = true;
            this.stepData = this.modalInputData.data['stepData'];
            this.widgetsSelected = this.stepData['widgetIds'];
        }
        let alertName = this.edit ? this.stepData['alertName'] : null;
        if (
            this.multiStepFormService.stepData &&
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(1)
        ) {
            const stepData = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1);
            this.widgetsSelected = stepData['widgetsSelected'];
            this.widgetsSelectedMap = stepData['widgetsSelectedMap'];
            alertName = stepData['alertName'];
        }
        this.alertNameFormGenInput = {
            formName: 'AlertName',
            state: this.edit ? FormState.IDLE : FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    name: 'alertName',
                    fieldType: FilterType.TEXT,
                    label: 'Alert Name',
                    placeholder: 'Enter Alert Name',
                    required: true,
                    value: alertName ? alertName : '',
                    disabled: this.edit,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Alert name is required'
                        },
                        {
                            validator: CustomValidators.noSpecialCharacters,
                            errorMessage:
                                'Alert name can not contain special characters'
                        }
                    ]
                }
            ]
        };
    }

    goToStepTwo() {
        Helper.markAllFieldAsTouched(this.alertNameFormGroup);
        if (!this.alertNameFormGroup || this.alertNameFormGroup.invalid) {
            return;
        }
        if (!this.widgetsSelected || !this.widgetsSelected.length) {
            this.noWidgetsSelected = true;
            return;
        }

        this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .set(1, {
                alertName: this.alertNameFormGroup.get('alertName').value,
                widgetsSelected: this.widgetsSelected,
                widgetsSelectedMap: this.widgetsSelectedMap
            });
        this.multiStepFormService.nextStep(this.modalInputData.modalId);
    }
}
