import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import * as $ from 'jquery';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { SanitizeTypes } from './../../../enums/SanitizeTypes';
import { UserDataCacheService } from './../../../services/user-data-cache/user-data-cache.service';

@Component({
    selector: 'app-iframe-opener-modal',
    templateUrl: './iframe-opener-modal.component.html',
    styleUrls: ['./iframe-opener-modal.component.sass']
})
export class IframeOpenerModalComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    iframeData = null;
    SanitizeTypes = SanitizeTypes;
    stepData;
    defaultEmail;
    url;
    closeButtonColor: string;
    @ViewChild('closeButton') closeButtonRef: ElementRef;

    resetFavicon: Function;

    constructor(
        private modalInputData: ModalInjectedData,
        public modalService: ModalService,
        private userDataCacheService: UserDataCacheService
    ) {}

    ngOnInit(): void {
        this.stepData = this.modalInputData.data;
        this.defaultEmail = this.userDataCacheService.emailId;

        if (this.modalInputData.data.whiteLabelData) {
            this.url = this.modalInputData.data.url;
            if (this.modalInputData.data.whiteLabelData) {
                this.resetFavicon = Helper.switchFaviconTo(
                    this.modalInputData.data.whiteLabelData.orgProprietary
                        .miniLogo
                );
            }
            if (this.modalInputData.data.closeButtonColor) {
                this.closeButtonColor =
                    this.modalInputData.data.closeButtonColor;
            }
        } else if (typeof this.modalInputData.data === 'string') {
            this.iframeData = this.modalInputData.data.substring(
                this.modalInputData.data.indexOf('<iframe'),
                this.modalInputData.data.indexOf('/iframe>') + 8
            );
        } else {
            this.iframeData = this.modalInputData.data.widgetCode;
            if (!this.modalInputData.data.isHtml) {
                this.iframeData = this.iframeData.substring(
                    this.modalInputData.data.indexOf('<iframe'),
                    this.modalInputData.data.indexOf('/iframe>') + 8
                );
            }
        }
    }

    ngAfterViewInit() {
        const frame = document.getElementById('preview');
        if (frame) {
            frame.onload = () => {
                frame['contentWindow'].postMessage(
                    {
                        whiteLabelData: this.modalInputData.data.whiteLabelData,
                        pseudoConsoleConfig:
                            this.modalInputData.data?.pseudoConsoleConfig ||
                            null,
                        pseudoConsoleView: true
                    },
                    '*'
                );
            };
        }

        $($('input[name=Email]:first')[0]).val(this.defaultEmail);
        if (this.stepData && this.stepData['configuredColumnData']) {
            $('input[name="First Name"]').val(
                this.stepData['configuredColumnData']
                    ? this.stepData['tableRowData'][
                          this.stepData['configuredColumnData']['nameColumn']
                      ]
                    : ''
            );

            $('input[name=Subject]').val(
                this.stepData['configuredColumnData']
                    ? this.stepData['tableRowData'][
                          this.stepData['configuredColumnData']['subjectColumn']
                      ]
                    : ''
            );
            $('#CASECF4').val(
                this.stepData['configuredColumnData']
                    ? this.stepData['tableRowData'][
                          this.stepData['configuredColumnData']['cloudColumn']
                      ]
                    : ''
            );
            $('#Category').val(
                this.stepData['configuredColumnData']
                    ? this.stepData['tableRowData'][
                          this.stepData['configuredColumnData'][
                              'categoryColumn'
                          ]
                      ]
                    : ''
            );
            $('#CASECF1').val(
                this.stepData['configuredColumnData']
                    ? this.stepData['tableRowData'][
                          this.stepData['configuredColumnData'][
                              'cloudServiceColumn'
                          ]
                      ]
                    : ''
            );
        }
        if (
            this.stepData &&
            this.stepData['configuredColumnData'] &&
            !this.stepData['isPartner']
        ) {
            $($('input[name=Email]:first')[0]).parent('td').parent('tr').hide();
            $('#CASECF6').parent('td').parent('tr').hide();
            $('input[name="First Name"]').parent('td').parent('tr').hide();
            $('input[name=Subject]').parent('td').parent('tr').hide();
            $('#CASECF4').parent('td').parent('tr').hide();
            $('#Category').parent('td').parent('tr').hide();
            $('#CASECF1').parent('td').parent('tr').hide();
        }

        if (this.closeButtonColor && this.closeButtonRef) {
            const styleList = [
                `--buttonColor: ${Helper.addOpacityToColor(
                    this.closeButtonColor,
                    50
                )}`,
                `--buttonHoverColor: ${Helper.addOpacityToColor(
                    this.closeButtonColor,
                    100
                )}`
            ];
            this.closeButtonRef.nativeElement.style = styleList.join('; ');
        }
    }

    ngOnDestroy(): void {
        if (this.resetFavicon) {
            this.resetFavicon();
        }
        Helper.clearPseudoConsoleCache();
    }
}
