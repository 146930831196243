<div class="provider-container">
    <div class="request-detail-container">
        <div class="heading">{{ "Request Detail" }}</div>
        <div class="content">
            <app-form-generator
                [formGeneratorInput]="requestDetailFormGenInputs"
                (formGroupRef)="requestDetailFormGroup = $event"
            ></app-form-generator>
        </div>
    </div>
    <div class="provider-detail-container">
        <div class="env-variables">
            <div class="file-label heading">{{ "Provider File Details" }}</div>

            <div class="button">
                <span class="text" (click)="addDetails()">Add Variable</span>
            </div>
        </div>
        <div class="info" *ngIf="!showLoader">
            <span appIcon [data]="infoHeading" class="info-icon"></span
            ><span>{{ infoMessage }}</span>
        </div>

        <ng-container
            *ngFor="
                let formGenInput of providerFilesFormGenInputs;
                let i = index;
                let last = last
            "
        >
            <div class="variables">
                <div class="fields-container">
                    <app-form-generator
                        [formGeneratorInput]="formGenInput"
                        [intitalFormGroup]="
                            providerFilesFormGroup.has(formGenInput.formId)
                                ? providerFilesFormGroup.get(
                                      formGenInput.formId
                                  )
                                : null
                        "
                        (formGroupRef)="
                            providerFilesFormGroup.set(
                                formGenInput.formId,
                                $event
                            )
                        "
                        (updateControl)="updateControl = $event"
                    ></app-form-generator>
                </div>

                <div
                    *ngIf="!last"
                    class="cross-icon svg-secondary-button-fill"
                    [matTooltip]="'Remove File'"
                    appIcon
                    [data]="removeFile"
                    (click)="deleteProviderDetail(i, formGenInput.formId)"
                ></div>
            </div>
        </ng-container>
        <div class="overlay" *ngIf="showLoader">
            <div class="overlay-content">
                <div
                    appIcon
                    [data]="spinnerLoader"
                    class="overlay-loader"
                ></div>
            </div>
        </div>
    </div>
</div>
<div class="controls">
    <app-button-generator
        [buttonGenInput]="nextButtonGenInput"
    ></app-button-generator>
</div>
