import { Component, OnInit } from '@angular/core';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { CloudLabel } from 'src/app/shared/enums/CloudLabel';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';

@Component({
    selector: 'app-delete-resource-tags-v2',
    templateUrl: './delete-resource-tags-v2.component.html',
    styleUrls: ['./delete-resource-tags-v2.component.sass']
})
export class DeleteResourceTagsV2Component implements OnInit {
    resourceTagKeys: any;
    selectedInstances = [];
    widgetRef: Widget;
    loaderGroup = {
        deleteLoader: false,
        deleteAllLoader: false
    };
    activeInstance = 0;
    buttonInputs: IButtonGeneratorInput[];
    constructor(
        private modalInputData: ModalInjectedData,
        private notificationsService: NotificationsService
    ) {
        this.resourceTagKeys = this.modalInputData.data['resourceTagKeys'];
        this.selectedInstances =
            this.modalInputData.data['inputDataFoDeletingResourceTag'][
                'selectedNodes'
            ];

        this.widgetRef = modalInputData.data['widgetRef'];

        this.resourceTagKeys = this.resourceTagKeys.map((key) => {
            const obj = {
                label: key,
                id: key
            };
            return obj;
        });
    }

    ngOnInit(): void {
        this.initializeForms();
        this.buttonInputs = [
            {
                buttonName: 'Delete all',
                buttonColorType: ButtonColorType.WARN,
                buttonType: ButtonType.RAISED,
                customClass: 'first-button',
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.deleteAll(buttonRef);
                }
            },
            {
                buttonName: 'Delete',
                buttonColorType: ButtonColorType.WARN,
                buttonType: ButtonType.RAISED,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.delete(buttonRef);
                }
            }
        ];
    }

    extractTags(index) {
        const extractedTags = [];
        this.selectedInstances[index]['extraTags'].map((each) => {
            each = each.split('|')[0];
            const obj = {
                label: each,
                id: each
            };
            extractedTags.push(obj);
        });

        this.selectedInstances[index]['appliedTags'].map((each) => {
            each = each.split('|')[0];
            const obj = {
                label: each,
                id: each
            };
            extractedTags.push(obj);
        });

        this.selectedInstances[index]['missingTags'].map((each) => {
            if (each.split('|').length > 1) {
                if (each.split('|')[1] !== 'Value Not Found') {
                    each = each.split('|')[0];
                    const obj = {
                        label: each,
                        id: each
                    };
                    extractedTags.push(obj);
                }
            }
        });

        return extractedTags;
    }

    initializeForms() {
        this.selectedInstances.forEach((element, index) => {
            element.form = {
                formName: 'Convention Form',
                submitButton: null,
                state: FormState.CREATE,
                fields: [
                    {
                        fieldType: FilterType.DROPDOWN_MULTIPLE,
                        label: 'Configuration Name',
                        appearance: 'outline',
                        name: 'configurationName',
                        required: true,
                        placeholder: 'Select Resource Tag Keys',
                        listData: this.extractTags(index)
                    }
                ]
            };
            element.selectedValues = null;
        });
    }

    deleteAll(buttonRef: IButtonGeneratorInput) {
        if (
            this.selectedInstances[this.activeInstance]?.selectedValues
                ?.length === 0
        ) {
            this.widgetRef.notificationsService.showSnackBar(
                'Select Atleast One Resource Tag',
                true
            );
            return;
        }

        this.loaderGroup.deleteAllLoader = true;
        buttonRef.loader = true;
        const dataForApi = {};
        dataForApi['resourceTaggingList'] = this.prepareDataForApi(true);

        dataForApi['resourceTaggingList'].forEach((element) => {
            element['tagsToDelete'] =
                this.selectedInstances[this.activeInstance].selectedValues;
        });

        this.modalInputData.data.function(
            dataForApi,
            this.modalInputData.modalId
        );
    }

    delete(buttonRef: IButtonGeneratorInput) {
        const selectedValue = [];
        this.selectedInstances.forEach((instance) => {
            if (instance.selectedValues && instance.selectedValues.length) {
                selectedValue.push(instance.selectedValues);
            }
        });
        if (!selectedValue.length) {
            this.widgetRef.notificationsService.showSnackBar(
                'Select Atleast One Resource Tag',
                true
            );
            return;
        }
        this.loaderGroup.deleteLoader = true;
        buttonRef.loader = true;
        const dataForApi = {};
        dataForApi['resourceTaggingList'] = [];

        dataForApi['resourceTaggingList'] = this.prepareDataForApi();

        dataForApi['resourceTaggingList'].forEach((element, index) => {
            element['tagsToDelete'] =
                this.selectedInstances[index].selectedValues;
        });

        this.modalInputData.data.function(
            dataForApi,
            this.modalInputData.modalId
        );
    }

    prepareDataForApi(isDeleteAll = false) {
        const data = [];

        if (
            this.widgetRef &&
            this.widgetRef.apiResponse &&
            this.widgetRef.apiResponse['dataMap'] &&
            this.widgetRef.apiResponse['dataMap']['tableKeys'] &&
            this.widgetRef.apiResponse['dataMap']['tableKeys'].length
        ) {
            this.selectedInstances.forEach((element, index) => {
                const keyName = {};
                this.widgetRef.apiResponse['dataMap']['tableKeys'].map(
                    (each) => {
                        keyName[each] = element[each];
                    }
                );
                if (Object.keys(keyName).length) {
                    data.push(keyName);
                }
            });
        } else {
            if (
                this.widgetRef.widgetData.widgetInfo.cloudIcon ===
                CloudLabel.AWS
            ) {
                this.selectedInstances.map((element, index) => {
                    data.push({
                        resourceId: element.identifier,
                        regionName: element.region,
                        accountNumber: element.accountId
                    });
                });
            }
        }
        return data;
    }
}
