<div class="json-creator-container">
    <div class="json-creator-head">
        <span> Key </span>
        <span> Value </span>
        <span></span>
    </div>
    <div class="json-creator-content">
        <div class="selected-list" *ngIf="selectedList.length > 0">
            <div
                class="add-new"
                *ngFor="let item of selectedList; let i = index"
            >
                <span
                    ><input
                        #editedKey
                        (blur)="editPair(editedKey, editedValue, item, i)"
                        type="text"
                        [value]="item.split('|')[0]"
                /></span>

                <span
                    ><input
                        #editedValue
                        (blur)="editPair(editedKey, editedValue, item, i)"
                        type="text"
                        [value]="item.split('|')[1]"
                /></span>

                <app-button-generator
                    [buttonGenInput]="buttonInputs[1]"
                    (buttonRef)="deletePair(item.split('|')[0], i)"
                >
                </app-button-generator>
            </div>
        </div>
        <div class="add-new">
            <span>
                <input
                    (focus)="deleteExistingKey(key)"
                    (blur)="editPair(key, value)"
                    type="text"
                    #key
                    placeholder="Key"
                />
            </span>
            <span>
                <input
                    (focus)="deleteExistingKey(key)"
                    (blur)="editPair(key, value)"
                    type="text"
                    #value
                    placeholder="Value"
                />
            </span>

            <app-button-generator
                [buttonGenInput]="buttonInputs[0]"
                (buttonRef)="add(key, value)"
            >
            </app-button-generator>
        </div>
    </div>
</div>
