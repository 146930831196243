<div class="container">
    <div class="mat-accordion">
        <ng-container *ngFor="let accordion of List; first as isFirst">
            <mat-expansion-panel [expanded]="isFirst">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ accordion.heading }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <ul *ngFor="let value of accordion.value | keyvalue">
                        <li>
                            {{ value.value }}
                        </li>
                    </ul>
                </div>
            </mat-expansion-panel>
        </ng-container>
    </div>
</div>
