import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';

@Component({
    selector: 'app-assessment-create-user-step-one',
    templateUrl: './assessment-create-user-step-one.component.html',
    styleUrls: ['./assessment-create-user-step-one.component.sass']
})
export class AssessmentCreateUserStepOneComponent implements OnInit {
    userInfoFormGenInput: IFormGeneratorInput = null;
    userInfoFormGroup: FormGroup;
    edit: boolean = false;
    stepData: IStepData;
    password: string;
    confirmPassword: string;
    emailValidations = [
        {
            validator: CustomValidators.required,
            errorMessage: 'Email is required'
        },
        {
            validator: CustomValidators.email,
            errorMessage: 'Email is invalid'
        }
    ];

    buttonGenInput: IButtonGeneratorInput;
    widgetRef: Widget;
    constructor(
        private modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        private listHttpService: ListHttpService
    ) {}

    ngOnInit(): void {
        this.widgetRef = this.modalInputData.data['widgetRef'];
        if (
            this.modalInputData &&
            this.modalInputData.data &&
            this.modalInputData.data.edit
        ) {
            this.edit = true;
            this.stepData = this.modalInputData.data.userData;
        }
        if (
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(1)
        ) {
            this.stepData = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1);
        }

        this.settingUpForms();
    }
    settingUpForms() {
        this.userInfoFormGenInput = {
            formName: 'Create User',
            state: FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    label: 'Email Id',
                    placeholder: 'Email Id',
                    name: 'email',
                    value: this.stepData ? this.stepData.email : '',
                    fieldType: FilterType.EMAIL,
                    required: true,
                    disabled: this.edit,
                    appearance: 'legacy',
                    showLabel: true,
                    validations: this.emailValidations
                },
                {
                    label: 'First Name',
                    placeholder: 'First Name',
                    name: 'firstName',
                    value: this.stepData ? this.stepData.firstName : '',
                    fieldType: FilterType.TEXT,
                    required: true,
                    appearance: 'legacy',
                    showLabel: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'First name is required'
                        }
                    ]
                },
                {
                    label: 'Last Name',
                    placeholder: 'Last Name',
                    name: 'lastName',
                    value: this.stepData ? this.stepData.lastName : '',
                    fieldType: FilterType.TEXT,
                    required: true,
                    appearance: 'legacy',
                    showLabel: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Last name is required'
                        }
                    ]
                },
                {
                    label: 'Assessments',
                    placeholder: 'Assessments',
                    name: 'assessmentIds',
                    value: this.stepData && this.stepData['assessmentIds']
                        ? this.stepData['assessmentIds']
                        : '',
                    getKey:null,
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    required: true,
                    appearance: 'legacy',
                    showLabel: true,
                    apiInfo: this.listHttpService.createUserAssessmentListInfo,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Assessment is required'
                        }
                    ]
                },
                {
                    label: 'Phone Number',
                    placeholder: 'Phone Number',
                    name: 'phoneNumber',
                    value: this.stepData ? this.stepData.phoneNumber : '',
                    fieldType: FilterType.NUMBER,
                    required: false,
                    appearance: 'legacy',
                    showLabel: true
                }
            ]
        };
        if (!this.edit) {
            this.userInfoFormGenInput.fields.push({
                label: 'Password',
                placeholder: 'Password',
                name: 'password',
                fieldType: FilterType.PASSWORD,
                required: true,
                value: this.stepData ? this.stepData['password'] : '',
                appearance: 'legacy',
                showLabel: true,
                suffixIcon: {
                    iconData: {
                        type: IconType.MATICON,
                        class: 'edit'
                    },
                    hoverText: 'Generate password',
                    function: (
                        buttonRef: IButtonGeneratorInput,
                        formGroup: FormGroup
                    ) => {
                        this.generatePassword(formGroup);
                    }
                },
                validations: Helper.getPasswordValidators()
            });

            this.userInfoFormGenInput.fields.push({
                label: 'Confirm Password',
                placeholder: 'Confirm password',
                name: 'confirmPassword',
                fieldType: FilterType.PASSWORD,
                required: true,
                value: this.stepData ? this.stepData['password'] : '',
                appearance: 'legacy',
                showLabel: true,
                validations: Helper.getPasswordValidators('equal', 'password')
            });
        }
        this.buttonGenInput = {
            buttonName: this.edit ? 'Update' : 'Next',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: null,
            showLoader: true
        };
    }
    generatePassword(formGroup: FormGroup) {
        let password = Helper.generateUniqueKey(16);
        password += 'a@B1';
        formGroup.get('password').setValue(password);
        formGroup.get('confirmPassword').setValue(password);
    }
    save() {
        Helper.markAllFieldAsTouched(this.userInfoFormGroup);
        if (this.userInfoFormGroup.invalid) {
            this.userInfoFormGroup.updateValueAndValidity();
            return;
        } else {
            this.multiStepFormService.nextStep(this.modalInputData.modalId);
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .set(1, this.userInfoFormGroup.getRawValue());
        }
    }
    updateUser(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }
        Helper.markAllFieldAsTouched(this.userInfoFormGroup);
        if (this.userInfoFormGroup.invalid) {
            this.userInfoFormGroup.updateValueAndValidity();
            return;
        }
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.update
        );
        const assessmentIds = this.userInfoFormGroup.value?.assessmentIds.map((assessmentId) => {
            return assessmentId.id;
        });
        this.userInfoFormGroup.value.assessmentIds = assessmentIds;
        apiArgs.input = this.userInfoFormGroup.value;
        apiArgs.input = {
            ...apiArgs.input
        };
        apiArgs.function = () => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'User Updated Successfully.'
            );
            this.widgetRef.refreshWidget();
            this.widgetRef.modalService.closeModal(
                null,
                this.modalInputData.modalId
            );
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
            this.widgetRef.modalService.closeModal(
                null,
                this.modalInputData.modalId
            );
        };
        apiArgs.url = apiArgs.url.replace('{id}', this.stepData['id']);
        buttonRef.loader = true;

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
export interface IStepData {
    id: string;
    createdOn: Date;
    updatedOn: Date;
    createdBy: ICreatedBy;
    lastModifiedBy: ICreatedBy;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    root: boolean;
    customerLevelFullAccess: boolean;
    defaultView: string;
    userType: string;
    enableTwoFactorAuth: boolean;
    assessmentId: string;
}
export interface ICreatedBy {
    email: string;
    tenantId: string;
}
