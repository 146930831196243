import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { FilterCacheService } from 'src/app/shared/services/cache/filters-cache/filter-cache.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { CustomValidators } from './../../../../classes/CustomValidators';
import { Helper } from './../../../../classes/Helper';
import { HitApi } from './../../../../classes/HitApi';
import { WidgetInjectedData } from './../../../../classes/WidgetInjectedData';
import { FilterStoreKey } from './../../../../enums/FilterStoreKey';
import { IconType } from './../../../../enums/IconType';
import { ViewType } from './../../../../enums/ViewType';
import { IDropdownData } from './../../../../interfaces/dropdown-data/IDropdownData';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { ConfigCacheService } from './../../../../services/cache/config-cache/config-cache.service';
import { SideMenuCacheService } from './../../../../services/cache/side-menu-cache/side-menu-cache.service';

@Component({
    selector: 'app-filters-configuration',
    templateUrl: './filters-configuration.component.html',
    styleUrls: ['./filters-configuration.component.sass']
})
export class FiltersConfigurationComponent implements OnInit, AfterViewInit {
    widgetRef: Widget;
    attributes: IDropdownData[] = null;
    filtersLoader = false;
    filters = null;
    attributeSelected = null;
    errorLoadingFiltersData = null;
    FilterStoreKey = FilterStoreKey;
    attrFormGenInput: IFormGeneratorInput = null;
    attrFormGroup: FormGroup = null;
    filterConfigDataFetched = false;

    resetSaveButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Reset & Save',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        showLoader: true,
        loader: false,
        function: null
    };

    saveButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Save',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        showLoader: true,
        loader: false,
        function: null
    };

    constructor(
        widgetData: WidgetInjectedData,
        private configCacheService: ConfigCacheService,
        private sideMenuCacheService: SideMenuCacheService,
        private filtersService: FiltersService,
        private filterCacheService: FilterCacheService,
        private userDataCache: UserDataCacheService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(false);

        // Update Header Icon
        this.widgetRef.headerIcon.next({
            class: 'fas fa-filter',
            type: IconType.FONTAWSOME
        });

        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set();
        this.widgetRef.visibleSections.next(visibleSections);

        // get configured filters
        this.getConfiguredFilters();
    }

    getConfiguredFilters() {
        const apiArgs: IHitApi = {
            url: ApiUrls.GET_CONFIGURATION,
            input: {},
            uniqueIdentity: Symbol(),
            requestType: RequestType.GET,
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (response) => {
                this.filterConfigDataFetched = true;

                if (response.filterConfigured) {
                    this.widgetRef.filterService.filtersData.set(
                        FilterStoreKey.CONFIGURATION,
                        new Map(Object.entries(response.filters))
                    );
                }
            },
            errorFunction: (error) => {
                this.filterConfigDataFetched = true;
            }
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    setUpFormInputs() {
        this.attrFormGenInput = {
            formName: 'Attribute',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    name: 'attribute',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'Select Attribute',
                    placeholder: 'Select Attribute',
                    listData: this.attributes,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Attribute is required'
                        }
                    ]
                }
            ]
        };
    }

    bindData(data) {
        // Resetting
        this.attributes = null;
        this.filtersLoader = false;
        this.filters = null;
        this.attributeSelected = null;
        this.errorLoadingFiltersData = null;
        this.attrFormGenInput = null;
        this.attrFormGroup = null;

        this.attributes = this.sideMenuCacheService.firstLevelData[
            this.configCacheService.viewId
        ].attributes.map((attribute) => {
            const attr: IDropdownData = {
                id: attribute.id,
                label: attribute.name
            };
            return attr;
        });
        this.setUpFormInputs();
        this.widgetRef.endLoader();
    }

    attributeChanged() {
        this.errorLoadingFiltersData = null;
        if (
            this.attrFormGroup &&
            this.attrFormGroup.value &&
            this.attrFormGroup.value.attribute &&
            this.attrFormGroup.value.attribute !== this.attributeSelected
        ) {
            this.filters = null;
            this.attributeSelected = this.attrFormGroup.value.attribute;
            this.filtersLoader = true;
            const args = Helper.generateHitApiConfig(
                this.widgetRef.widgetData.widgetInfo.list
            );
            args.intactUrl = args.url;
            args.url = args.url.replace(
                '{attributeId}',
                this.attributeSelected
            );
            args.input = {};
            args.function = (response) => {
                if (this.attributeSelected === response['attributeId']) {
                    if (response.filterList && response.filterList.length) {
                        this.filters = response.filterList;
                    } else {
                        this.filters = [];
                    }
                    setTimeout(() => {
                        this.filtersLoader = false;
                        this.widgetRef.changeDetectorRef.detectChanges();
                    }, 1000);
                }
            };
            args.errorFunction = (error) => {
                this.errorLoadingFiltersData =
                    Helper.extractErrorMessage(error);
                this.filtersLoader = false;
                this.widgetRef.changeDetectorRef.detectChanges();
            };
            new HitApi(
                args,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        } else if (this.attributeSelected && !this.attrFormGroup.value) {
            this.attributeSelected = null;
            this.filtersLoader = false;
            this.filters = null;
            this.widgetRef.changeDetectorRef.detectChanges();
        }
    }

    saveConfig(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader || this.resetSaveButtonGenInput.loader) {
            return;
        }
        if (!this.attributeSelected || this.filtersLoader) {
            return;
        }
        buttonRef.loader = true;
        this.widgetRef.filterService.convertTempToMain(
            FilterStoreKey.CONFIGURATION,
            false
        );
        const tempFilters = this.widgetRef.filterService.filtersData.get(
            FilterStoreKey.CONFIGURATION
        );

        const filters = {};
        tempFilters.forEach((value, key) => {
            if (
                value.filterInfo &&
                value.filterInfo.type === FilterType.DATE_RANGE
            ) {
                value.value.id = this.filtersService.selectedDateDropdownId;
            }
            const keys = key.split('.'),
                last = keys.pop();
            keys.reduce((r, a) => (r[a] = r[a] || {}), filters)[last] = value;
        });

        const args = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.create
        );
        args.input = { filters };
        args.function = (response) => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'Configuration saved successfully'
            );
            this.filtersService.filtersData.set(
                FilterStoreKey.WEBSITE_FILTERS,
                new Map(Object.entries(filters))
            );
            const filterData = {
                [FilterStoreKey.WEBSITE_FILTERS]: args.input['filters']
            };
            this.filterCacheService.setFiltersInfo(
                this.userDataCache.emailId,
                this.configCacheService.viewId,
                filterData
            );
            this.filterCacheService.setDefaultFilters(
                this.userDataCache.emailId,
                this.configCacheService.viewId,
                args.input
            );
            this.widgetRef.changeDetectorRef.detectChanges();
        };
        args.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error saving configuration'
            );
            this.widgetRef.changeDetectorRef.detectChanges();
        };
        new HitApi(
            args,
            this.widgetRef.httpService,
            this.widgetRef.httpService.ngZone
        ).hitApi();
    }

    resetConfig(buttonRef: IButtonGeneratorInput) {
        if (this.saveButtonGenInput.loader || buttonRef.loader) {
            return;
        }
        buttonRef.loader = true;
        // this.widgetRef.filterService.convertTempToMain(
        //     FilterStoreKey.CONFIGURATION,
        //     false
        // );

        const args = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.delete
        );
        args.input = {};
        args.function = (response) => {
            this.resetFilters();
            this.filterCacheService.setDefaultFilters(
                this.userDataCache.emailId,
                this.configCacheService.viewId,
                {}
            );
            this.filtersService.filtersData.delete(
                FilterStoreKey.CONFIGURATION
            );
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'Configuration reset successfully'
            );
            this.widgetRef.changeDetectorRef.detectChanges();
        };
        args.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error resetting configuration'
            );
            this.widgetRef.changeDetectorRef.detectChanges();
        };
        new HitApi(
            args,
            this.widgetRef.httpService,
            this.widgetRef.httpService.ngZone
        ).hitApi();
    }

    resetFilters() {
        const widgetSymbol = new Set<Symbol>();
        this.filtersService.filtersDataTemp.set(
            FilterStoreKey.CONFIGURATION,
            new Map(this.filtersService.filtersDefaultValue)
        );
        this.filtersService.convertTempToMain(
            FilterStoreKey.CONFIGURATION,
            true
        );
        this.filtersService.filtersDataTemp.set(
            FilterStoreKey.WEBSITE_FILTERS,
            new Map(this.filtersService.filtersDefaultValue)
        );
        this.filtersService.convertTempToMain(
            FilterStoreKey.WEBSITE_FILTERS,
            true
        );
        this.filtersService.resetFilters.next(null);
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this), false);
    }
}
