import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild
} from '@angular/core';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { IconType } from 'src/app/shared/enums/IconType';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IColumnData } from 'src/app/shared/interfaces/table-generator/IColumnData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { Helper } from './../../../classes/Helper';

@Component({
    selector: 'app-checks-v2-more-info-modal',
    templateUrl: './checks-v2-more-info-modal.component.html',
    styleUrls: ['./checks-v2-more-info-modal.component.sass']
})
export class ChecksV2MoreInfoModalComponent implements OnInit, AfterViewInit {
    tabs: {
        label: string;
        tableInput?: ITableGeneratorInput;
        tableData?: any;
        accordianData?: any;
        otherInfo?: any;
    }[] = [];
    recievedData: any;
    activeTab: string = null;
    tableInput: ITableGeneratorInput = null;
    tableData: any[] = [];
    crossIcon: IIcon = {
        type: IconType.SVG,
        class: 'cross',
        extraClass: 'svg-white-fill'
    };
    widgetRef: Widget;
    @ViewChild('wrapper') wrapper: ElementRef;
    constructor(
        public modalData: ModalInjectedData,
        public modalService: ModalService
    ) {
        this.recievedData = this.modalData.data.itemData[0]['moreInfo'];
        this.widgetRef = this.modalData.data.widgetRef;
    }

    ngOnInit() {
        this.setUpBasics();
    }

    ngAfterViewInit(): void {
        if (this.wrapper) {
            this.wrapper.nativeElement.style = `--lightNegativeColor: ${Helper.addOpacityToColor(
                Helper.getCssVarValue('negativeColor'),
                25
            )}`;
        }
    }

    setUpBasics() {
        this.prepareTabs();
    }

    prepareTabs() {
        if (this.recievedData['data']) {
            Object.keys(this.recievedData['data']).forEach((rdataKey) => {
                const tabConfig = this.changeTableInput(
                    rdataKey,
                    this.recievedData['data'][rdataKey]
                );
                this.tabs.push(tabConfig);
            });
        }
    }

    changeTableInput(
        tabLabel,
        rdata
    ): {
        label: string;
        tableInput?: ITableGeneratorInput;
        tableData?: any;
        accordianData?: any;
        otherInfo?: any;
    } {
        let finalTableData = null;
        const accordianData: any = {};
        let otherInfo: any = null;
        const columns: IColumnData[] = [];
        const tableInput: ITableGeneratorInput = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            columns,
            tableAutoHeight: true
        };

        if (tabLabel === 'User Info') {
            tableInput.headerHeight = 0;
            const keys = Object.keys(rdata['tableData']['data']);
            const values = Object.values(rdata['tableData']['data']);
            const newTableData = [];
            for (let i = 0; i < keys.length; i++) {
                newTableData.push({ col1: keys[i], col2: values[i] });
            }
            finalTableData = newTableData;
            columns.push({
                columnName: 'col-1',
                columnKey: 'col1',
                cellClass: 'table-cell-bgColor'
            });
            columns.push({ columnName: 'col-2', columnKey: 'col2' });
        }
        if (tabLabel === 'Assigned Roles') {
            const rows: [] = rdata['tableData']['rows'];
            finalTableData = rdata['tableData']['data'];

            rows.forEach((row) => {
                const colObj: IColumnData = {
                    columnName: row,
                    columnKey: row
                };
                if (row === 'Role Name') {
                    colObj.cellClass = (cellClassParams) => {
                        return cellClassParams.data.extraClass;
                    };
                }
                columns.push(colObj);
            });
            otherInfo = rdata['otherInfo'];
        }

        if (tabLabel === 'Project Roles') {
            const tableInp: ITableGeneratorInput = { ...tableInput };

            const accordianHeaders = Object.keys(rdata['accordionData']);
            accordianData['headers'] = accordianHeaders;
            accordianHeaders.map((header) => {
                const rows =
                    rdata['accordionData'][header]['tableData']['rows'];
                tableInp.columns = [];
                rows.forEach((row) => {
                    const colObj: IColumnData = {
                        columnName: row,
                        columnKey: row
                    };
                    if (row === 'Role Name') {
                        colObj.cellClass = (cellClassParams) => {
                            return cellClassParams.data.extraClass;
                        };
                    }
                    tableInp.columns.push(colObj);
                });
                accordianData[header] = {
                    tableInput: tableInp,
                    tableData:
                        rdata['accordionData'][header]['tableData']['data']
                };
                if (
                    rdata['accordionData'][header] &&
                    rdata['accordionData'][header]['otherInfo']
                ) {
                    otherInfo = rdata['accordionData'][header]['otherInfo'];
                }
            });
        }

        return {
            label: tabLabel,
            tableInput,
            tableData: finalTableData,
            otherInfo,
            accordianData
        };
    }

    updateActiveTab(tab) {
        this.activeTab = tab;
    }
}
